import { BaseSyntheticEvent, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import I18n from 'utilities/i18n';
import { Grid, TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import http from '../../../utilities/httpService';
import { toast } from 'react-toastify';
import environment from 'environment';
import {
  Order,
  ProductCatalogueAssignmentStateModel,
  ProductCatalogueAssignmentType,
  ProductCatalogueAssignmentTypeDescription,
} from 'models/product-catalogue';
import DateTimePickerInput from 'common/DatetimePicker/DateTimePickerInput';
import { Moment } from 'moment';
import RadioGroupInput from 'common/RadioGroup/RadioGroupInput';
import {
  BaseModel,
  PaginationResponseModel,
  RadioGroupBaseModel,
} from 'models/pagination-model';
import CustomizedSelectPaginate from 'common/CustomSelect/CustomSelectPaginate';
import FormControl, { CustomizedInputs } from 'common/formControl/formControl';
import NumericInput from 'common/NumericInput/NumericInput';
import {
  getAvailableWarehouseQuantity,
  loadDeckPackOrdersOptions,
  loadShippingOrdersOptions,
} from 'components/product-catalogue-consumption/ProductConsumptionApiService';
import moment from 'moment';

export default function AddAssignment(props: any) {
  const { isOpen, handleClose, title, entityId, editData } = props;
  const defaultOrderValue = {
    value: '',
    label: I18n('Placeholders.SelectOrder'),
  } as BaseModel;
  const today = new Date().toISOString();
  const [values, setValues] = useState<ProductCatalogueAssignmentStateModel>({
    productCatalogueId: entityId,
    assignDate: new Date(),
    assignedTo: null,
    assignedBy: null,
    assignedByType: ProductCatalogueAssignmentType.Warehouse,
    assignedToType: ProductCatalogueAssignmentType.ShippingOrder,
    remarks: '',
    id: 0,
    quantity: '',
  });

  const showMessageRefresh = () => {
    toast.success(
      values.id
        ? i18nMessages.updatedSuccessfully
        : i18nMessages.savedSuccessfully
    );
    setDefaultValuesForControls();
    props.refreshData();
  };

  let assignmentTypeList: Array<RadioGroupBaseModel> = [];
  const getAssignmentTypeList = () => {
    assignmentTypeList = [];
    ProductCatalogueAssignmentTypeDescription.forEach(
      (value: string, key: number) => {
        /* as of now added this disabled condition for Deck&pack as not developed*/
        if (key === ProductCatalogueAssignmentType.DecPackOrder) {
          assignmentTypeList.push({
            value: key.toString(),
            label: I18n(value),
          } as RadioGroupBaseModel);
        } else {
          assignmentTypeList.push({
            value: key.toString(),
            label: I18n(value),
          } as RadioGroupBaseModel);
        }
      }
    );
  };
  getAssignmentTypeList();

  const setDefaultValuesForControls = () => {
    setValues({ ...values, quantity: '' });
    setValues({ ...values, remarks: '' });
  };

  const [errors, setErrors] = useState({
    quantity: '',
    remarks: '',
    assignBy: '',
    assignTo: '',
    assignDate: '',
  });
  const [warehouseQuantity, setWarehouseQuantity] = useState(0);
  const [resourceQuantity, setResourceQuantity] = useState(0);

  const i18nMessages = {
    commonFieldIsRequired: I18n('Common.FieldIsRequired'),
    supplierLimitExeed: I18n(
      'ProductCatalogues.ProductPurchases.Validation.SupplierMaxLength'
    ),
    commonInvalidQuantity: I18n('Common.EnteredQuantityIsNotValid'),
    somethingWentWrong: I18n('Common.SomethingWentWrong'),
    updatedSuccessfully: I18n(
      'ProductCatalogues.Assignments.UpdatedSuccessfully'
    ),
    savedSuccessfully: I18n('ProductCatalogues.Assignments.SavedSuccessfully'),
    bothTypeNotSame: I18n('ProductCatalogues.Assignments.BothTypeNotSame'),
    InvalidDate: I18n('Common.InvalidDate'),
  };

  const validate = (fieldValues: any = values) => {
    let temp: any = { ...errors };
    if ('quantity' in fieldValues) {
      temp.quantity = +fieldValues.quantity
        ? ''
        : i18nMessages.commonFieldIsRequired;
    }
    if ('assignedBy' in fieldValues) {
      if (
        +fieldValues.assignedByType ===
        ProductCatalogueAssignmentType.ShippingOrder
      ) {
        temp.assignBy =
          fieldValues.assignedBy && +fieldValues.assignedBy.value
            ? ''
            : i18nMessages.commonFieldIsRequired;
      } else {
        temp.assignBy = '';
      }
    }
    if ('assignedTo' in fieldValues) {
      if (
        +fieldValues.assignedToType ===
        ProductCatalogueAssignmentType.ShippingOrder
      ) {
        temp.assignTo =
          fieldValues.assignedTo && +fieldValues.assignedTo.value
            ? ''
            : i18nMessages.commonFieldIsRequired;
      } else {
        temp.assignTo = '';
      }
    }
    if ('assignDate' in fieldValues) {
      temp.assignDate = fieldValues.assignDate
        ? ''
        : i18nMessages.commonFieldIsRequired;

      if (temp.assignDate == '') {
        let isDateValid = moment(fieldValues.assignDate).isValid();
        if (!isDateValid) {
          temp.assignDate = i18nMessages.InvalidDate;
        }
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === '');
    }
  };

  const submit = async () => {
    if (validate()) {
      const assignment = {
        productCatalogueId: values.productCatalogueId,
        assignedByType: values.assignedByType,
        assignedToType: values.assignedToType,
        assignDate: values.assignDate,
        assignedById:
          (+values.assignedByType ===
            ProductCatalogueAssignmentType.ShippingOrder ||
            +values.assignedByType ===
              ProductCatalogueAssignmentType.DecPackOrder) &&
          values &&
          values.assignedBy
            ? +values.assignedBy.value
            : null,
        assignedToId:
          (+values.assignedToType ===
            ProductCatalogueAssignmentType.ShippingOrder ||
            +values.assignedToType ===
              ProductCatalogueAssignmentType.DecPackOrder) &&
          values &&
          values.assignedTo
            ? +values.assignedTo.value
            : null,
        quantity: values.quantity,
        remarks: values.remarks,
        id: values.id,
        isManual: true,
      };
      const apiUrl =
        editData === null
          ? new URL(environment.api.baseUrl + 'ProductCatalogueAssignment')
          : new URL(
              environment.api.baseUrl +
                'ProductCatalogueAssignment/' +
                values.id
            );
      try {
        const result: any =
          editData === null
            ? await http.post(apiUrl.toString(), assignment)
            : await http.put(apiUrl.toString(), assignment);
        if (editData == null && result !== null && result.data != null) {
          showMessageRefresh();
        } else if (editData != null && result !== null && result.data) {
          showMessageRefresh();
        } else {
          toast.error(i18nMessages.somethingWentWrong);
        }
      } catch (error: any) {
        if (error.response) {
          let temp = { ...errors };
          if (error.response.data) {
            for (const item of error.response.data.errors) {
              if (
                item.field === 'Quantity' &&
                item.errorMessage === 'ValidQuantity'
              ) {
                temp.quantity = i18nMessages.commonInvalidQuantity;
              }
            }
          }
          setErrors({
            ...temp,
          });
        }
      }
    }
  };

  const getAvailableResourceQuantity = async (purchaseOrderId: number) => {
    const apiUrl = new URL(
      environment.api.baseUrl +
        `ProductCatalogues/GetResourceQuantityAsync?productCatalogueId=${entityId}&&purchaseOrderId=${purchaseOrderId}`
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result) {
        setResourceQuantity(result.data ? result.data.quantity : 0);
      }
    } catch (error) {}
  };

  const fillEditData = async () => {
    setValues({
      productCatalogueId: entityId,
      assignDate: editData.assignDate,
      assignedBy:
        +editData.assignedByType === ProductCatalogueAssignmentType.Warehouse
          ? null
          : ({
              label: editData.assignedByName,
              value: editData.assignedById,
            } as BaseModel),
      assignedTo:
        +editData.assignedToType === ProductCatalogueAssignmentType.Warehouse
          ? null
          : ({
              label: editData.assignedToName,
              value: editData.assignedToId,
            } as BaseModel),
      assignedByType: editData.assignedByType,
      assignedToType: editData.assignedToType,
      remarks: editData.remarks,
      id: editData.id,
      quantity: editData.quantity,
    });
    if (
      +editData.assignedByType === ProductCatalogueAssignmentType.ShippingOrder
    ) {
      await getAvailableResourceQuantity(
        editData.assignById ? editData.assignById : 0
      );
    }
  };
  useEffect(() => {
    if (editData) {
      fillEditData();
    } else {
      setValues({
        productCatalogueId: entityId,
        assignDate: new Date(),
        assignedTo: null,
        assignedBy: null,
        assignedByType: ProductCatalogueAssignmentType.Warehouse,
        assignedToType: ProductCatalogueAssignmentType.ShippingOrder,
        remarks: '',
        id: 0,
        quantity: '',
      });
    }
    getAvailableWarehouseQuantity(entityId).then((response) => {
      setWarehouseQuantity(response);
    });
  }, [editData]);

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={'sm'}
        fullWidth={true}
      >
        <DialogTitle>
          {title}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className="grid-wrap">
            <Grid item lg={12} md={12} sm={12}>
              <DateTimePickerInput
                displayLabel={I18n(
                  'ProductCatalogues.Assignments.AssignedDate'
                )}
                name="assignDate"
                value={values.assignDate}
                defaultValue={today}
                handleChange={(event: Moment) => {
                  setValues({
                    ...values,
                    assignDate: event != null ? event.toDate() : event,
                  });
                }}
                readOnly={editData !== null}
                errorValue={true}
                errorMessage={errors.assignDate}
              ></DateTimePickerInput>
            </Grid>
            <Grid item xs={12}>
              <RadioGroupInput
                displayLabel={I18n(
                  'ProductCatalogues.Assignments.AssignedByType'
                )}
                name="assignedByType"
                value={values.assignedByType}
                handleChange={(event: BaseSyntheticEvent) => {
                  setValues({
                    ...values,
                    assignedByType: +event.target.value,
                    assignedBy:
                      +event.target.value ===
                      ProductCatalogueAssignmentType.Warehouse
                        ? defaultOrderValue
                        : values.assignedBy,
                    assignedToType:
                      +event.target.value ===
                      ProductCatalogueAssignmentType.Warehouse
                        ? ProductCatalogueAssignmentType.ShippingOrder
                        : ProductCatalogueAssignmentType.Warehouse,
                    assignedTo:
                      +event.target.value ===
                      ProductCatalogueAssignmentType.Warehouse
                        ? defaultOrderValue
                        : values.assignedTo,
                  });
                  setResourceQuantity(0);
                }}
                options={assignmentTypeList}
                readOnly={editData !== null}
              ></RadioGroupInput>

              <strong>
                {(+values.assignedByType ===
                ProductCatalogueAssignmentType.Warehouse
                  ? `${I18n(
                      'ProductCatalogues.WarehouseQuantity'
                    )} : ${warehouseQuantity}`
                  : `${I18n(
                      'ProductCatalogues.ResourceQuantity'
                    )} : ${resourceQuantity}`) && editData === null}
              </strong>
            </Grid>
            {values.assignedByType ===
              ProductCatalogueAssignmentType.ShippingOrder && (
              <Grid item xs={12}>
                <CustomizedSelectPaginate
                  additional={{
                    page: 0,
                  }}
                  value={values.assignedBy}
                  loadOptions={loadShippingOrdersOptions}
                  handleChange={async (newValue: any) => {
                    setValues({ ...values, assignedBy: newValue });
                    await getAvailableResourceQuantity(
                      newValue && newValue.value ? newValue.value : 0
                    );
                  }}
                  placeholder={defaultOrderValue.label}
                  isSearchable={true}
                  displayLabel={I18n(
                    'ProductCatalogues.Assignments.AssignedBy'
                  )}
                  errorValue={true}
                  errorMessage={errors.assignBy}
                  readOnly={editData !== null}
                  debounceTimeout={500}
                  isMultiSelect={false}
                  cacheUniqs={[values.assignedByType]}
                />
              </Grid>
            )}
            {values.assignedByType ===
              ProductCatalogueAssignmentType.DecPackOrder && (
              <Grid item xs={12}>
                <CustomizedSelectPaginate
                  additional={{
                    page: 0,
                  }}
                  value={values.assignedBy}
                  loadOptions={loadDeckPackOrdersOptions}
                  handleChange={async (newValue: any) => {
                    setValues({ ...values, assignedBy: newValue });
                    await getAvailableResourceQuantity(
                      newValue && newValue.value ? newValue.value : 0
                    );
                  }}
                  placeholder={defaultOrderValue.label}
                  isSearchable={true}
                  displayLabel={I18n(
                    'ProductCatalogues.Assignments.AssignedBy'
                  )}
                  errorValue={true}
                  errorMessage={errors.assignBy}
                  readOnly={editData !== null}
                  debounceTimeout={500}
                  isMultiSelect={false}
                  cacheUniqs={[values.assignedByType]}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <RadioGroupInput
                displayLabel={I18n(
                  'ProductCatalogues.Assignments.AssignedToType'
                )}
                name="assignedToType"
                value={values.assignedToType}
                handleChange={(event: BaseSyntheticEvent) => {
                  if (
                    (values.assignedByType ===
                      ProductCatalogueAssignmentType.Warehouse &&
                      +event.target.value ===
                        ProductCatalogueAssignmentType.Warehouse) ||
                    (values.assignedByType !==
                      ProductCatalogueAssignmentType.Warehouse &&
                      +event.target.value !==
                        ProductCatalogueAssignmentType.Warehouse)
                  ) {
                    toast.error(i18nMessages.bothTypeNotSame);
                    return;
                  }
                  setValues({
                    ...values,
                    assignedToType: +event.target.value,
                    assignedTo:
                      +event.target.value ===
                      ProductCatalogueAssignmentType.Warehouse
                        ? defaultOrderValue
                        : values.assignedTo,
                    assignedByType:
                      +event.target.value ===
                      ProductCatalogueAssignmentType.Warehouse
                        ? ProductCatalogueAssignmentType.ShippingOrder
                        : ProductCatalogueAssignmentType.Warehouse,
                    assignedBy:
                      +event.target.value ===
                      ProductCatalogueAssignmentType.Warehouse
                        ? defaultOrderValue
                        : values.assignedBy,
                  });
                  setResourceQuantity(0);
                }}
                options={assignmentTypeList}
                readOnly={editData !== null}
              ></RadioGroupInput>
            </Grid>
            {+values.assignedToType ===
              ProductCatalogueAssignmentType.ShippingOrder && (
              <Grid item xs={12}>
                <CustomizedSelectPaginate
                  required
                  additional={{
                    page: 0,
                  }}
                  value={values.assignedTo}
                  loadOptions={loadShippingOrdersOptions}
                  handleChange={(newValue: any) =>
                    setValues({ ...values, assignedTo: newValue })
                  }
                  placeholder={defaultOrderValue.label}
                  isSearchable={true}
                  displayLabel={I18n(
                    'ProductCatalogues.Assignments.AssignedTo'
                  )}
                  errorValue={true}
                  errorMessage={errors.assignTo}
                  debounceTimeout={500}
                  isMultiSelect={false}
                  readOnly={editData !== null}
                  cacheUniqs={[values.assignedToType]}
                />
              </Grid>
            )}
            {+values.assignedToType ===
              ProductCatalogueAssignmentType.DecPackOrder && (
              <Grid item xs={12}>
                <CustomizedSelectPaginate
                  required
                  additional={{
                    page: 0,
                  }}
                  value={values.assignedTo}
                  loadOptions={loadDeckPackOrdersOptions}
                  handleChange={(newValue: any) =>
                    setValues({ ...values, assignedTo: newValue })
                  }
                  placeholder={defaultOrderValue.label}
                  isSearchable={true}
                  displayLabel={I18n(
                    'ProductCatalogues.Assignments.AssignedTo'
                  )}
                  errorValue={true}
                  errorMessage={errors.assignTo}
                  debounceTimeout={500}
                  isMultiSelect={false}
                  readOnly={editData !== null}
                  cacheUniqs={[values.assignedToType]}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <CustomizedInputs
                required
                displayLabel={I18n('ProductCatalogues.Assignments.Quantity')}
                inputProps={{ maxLength: 10 }}
                name="quantity"
                handleChange={(event: any) => {
                  setValues({
                    ...values,
                    quantity: event.target.value,
                  });
                }}
                errorValue={true}
                errorMessage={errors.quantity}
                value={values.quantity}
                allowNegative={false}
                readOnly={editData !== null}
                inputType="number"
              ></CustomizedInputs>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                margin="normal"
                displayLabel={I18n('ProductCatalogues.Assignments.Remarks')}
                id="Remarks"
                name="remarks"
                inputType="text"
                inputProps={{ maxLength: 150 }}
                multiline={false}
                handleChange={(event: any) => {
                  setValues({
                    ...values,
                    remarks: event.target.value,
                  });
                }}
                value={values.remarks}
              ></FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="btn-wrap">
          <Button
            onClick={handleClose}
            color="neutral"
            disableElevation
            variant="contained"
          >
            {I18n('Common.Cancel')}
          </Button>
          <Button
            onClick={submit}
            autoFocus
            color="primary"
            disableElevation
            variant="contained"
          >
            {I18n('Common.Save')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
