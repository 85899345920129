import React from 'react';

function AccessDenied(props: any) {
  return (
    <div>
      <p>You are not authorized to access this page.</p>
    </div>
  );
}

export default AccessDenied;
