import { useCallback, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import I18n from 'utilities/i18n';
import environment from 'environment';
import http from '../../utilities/httpService';
import CustomizedInputs from 'common/formControl/formControl';
import { Grid } from '@mui/material';

export default function ViewCustomerModal(props: any) {
  const { isOpen, handleClose, title, entityId } = props;
  const [values, setValues] = useState({
    name: '',
    address: '',
    address2: '',
    street: '',
    postalCode: '',
    cityId: '',
    countryId: '',
    city: '',
    country: '',
    vatNumber: '',
    eori: '',
  });
  const i18nMessages = {
    Cancel: I18n('Common.Cancel'),
    Name: I18n('Customer.Name'),
    Street: I18n('CustomerLocation.Street'),
    Address: I18n('CustomerLocation.Address'),
    PostalCode: I18n('Customer.PostalCode'),
    Country: I18n('Customer.Country'),
    City: I18n('Customer.City'),
    VatNumber: I18n('Customer.VatNumber'),
    EORI: I18n('Customer.EORI'),
  };

  const getCustomerData = async (id: string) => {
    const apiUrl = new URL(environment.api.baseUrl + 'Customer/' + id);
    const apiUrlLocation = new URL(
      environment.api.baseUrl + 'Locations/GetLocationByCustomerId/' + id
    );
    try {
      const result: any = await http.get(apiUrl.toString());
      const resultLocation: any = await http.get(apiUrlLocation.toString());
      if (result && resultLocation) {
        setValues({
          ...values,
          name: result.data.name,
          vatNumber: result.data.vatNumber,
          eori: result.data.eori,
          street: resultLocation.data.street,
          address2: resultLocation.data.address2,
          postalCode: resultLocation.data.zipCode,
          city: resultLocation.data.city,
          country: resultLocation.data.country,
        });
      }
    } catch (error: any) {}
  };

  useEffect(() => {
    if (entityId && entityId !== undefined && Number(entityId) > 0) {
      getCustomerData(entityId);
    }
  }, [entityId]);

  const resetClose = () => {
    handleClose();
  };
  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={resetClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={'sm'}
        fullWidth={true}
      >
        <DialogTitle>
          {title}
          <IconButton
            aria-label="close"
            onClick={resetClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className="grid-wrap two-col">
            <Grid item xs={6}>
              <CustomizedInputs
                margin="normal"
                displayLabel={i18nMessages.Name}
                name="name"
                id="name"
                inputType="text"
                value={values?.name}
                readOnly={true}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomizedInputs
                margin="normal"
                displayLabel={i18nMessages.Street}
                name="street"
                id="street"
                inputType="text"
                value={values?.street}
                readOnly={true}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomizedInputs
                margin="normal"
                displayLabel={i18nMessages.Address}
                name="address2"
                id="address2"
                inputType="text"
                value={values?.address2}
                readOnly={true}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomizedInputs
                margin="normal"
                displayLabel={i18nMessages.PostalCode}
                name="postalCode"
                id="postalCode"
                inputType="text"
                value={values?.postalCode}
                readOnly={true}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomizedInputs
                margin="normal"
                displayLabel={i18nMessages.City}
                name="city"
                id="city"
                inputType="text"
                value={values?.city}
                readOnly={true}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomizedInputs
                margin="normal"
                displayLabel={i18nMessages.Country}
                name="country"
                id="country"
                inputType="text"
                value={values?.country}
                readOnly={true}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomizedInputs
                margin="normal"
                displayLabel={i18nMessages.VatNumber}
                name="vatNumber"
                id="vatNumber"
                inputType="text"
                value={values?.vatNumber}
                readOnly={true}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomizedInputs
                margin="normal"
                displayLabel={i18nMessages.EORI}
                name="eori"
                id="eori"
                inputType="text"
                value={values?.eori}
                readOnly={true}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
