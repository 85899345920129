import {
  Box,
  Button,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from '@mui/material';
import FormAccordian from 'common/formControl/formAccordian';
import React, { useEffect, useState } from 'react';
import FormControl from 'common/formControl/formControl';
import I18n from 'utilities/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import AddIcon from '@mui/icons-material/Add';
import { useParams } from 'react-router-dom';
import environment from 'environment';
import http from '../../../utilities/httpService';
import { toast } from 'react-toastify';
import PricingChip from './PricingChip';
import { hasPermission } from 'utilities/protectedRoute';
import { Permission } from 'Permissions';
import CustomSelectPaginate from 'common/CustomSelect/CustomSelectPaginate';
import { loadCustomerOptions } from 'components/shipping-orders/ShippingApiService';
import { BaseModel } from 'models/pagination-model';

const Pricing = (props: any) => {
  const { entityId } = useParams();
  const i18nMessages = {
    commonFieldIsRequired: I18n('Common.FieldIsRequired'),
    customerName: I18n('Customer.CustomerName'),
  };
  const defaultCustomerValue = {
    value: '',
    label: I18n('Placeholders.SelectCustomer'),
  } as BaseModel;
  const defaultAdditional: any = {
    page: 1,
  };
  const [deliveryBaseRateValue, setDeliveryBaseRateValue] = useState(
    props?.BaseRateResponse?.deliveryBaseRate
  );
  const [fuelBaseRateValue, setFuelBaseRateValue] = useState(
    props?.BaseRateResponse?.fuelBaseRateValue
  );
  const [transportTypeId, setTransportTypeId] = useState(props.transportTypeId);
  const [pricingDetailsExpanded, setPricingDetailsExpanded] =
    useState<boolean>(true);
  const [isPricingDetailsEdit, setIsPricingDetailsEdit] =
    useState<boolean>(false);

  const [pricingZoneDetailsExpanded, setPricingZoneDetailsExpanded] =
    useState<boolean>(true);
  const [isPricingZoneDetailsEdit, setIsPricingZoneDetailsEdit] =
    useState<boolean>(false);
  const [
    specialPricingZoneDetailsExpanded,
    setSpecialPricingZoneDetailsExpanded,
  ] = useState<boolean>(true);
  const [isSpecialPricingZoneDetailsEdit, setIsSpecialPricingZoneDetailsEdit] =
    useState<boolean>(false);
  const [rows, setRows] = useState(props?.ZoneResponse);
  const [specialPricingRows, setSpecialPricingRows] = useState(
    props?.SpecialPrice
  );
  const [canEdit, setCanEdit] = useState(
    hasPermission(Permission.canEditTransportCatalogue)
  );

  const [deliveryBaseRate, setDeliveryBaseRate] = useState('DeliveryBaseRate');
  const [kg, setKg] = useState('Kg');
  const [fuelBaseRate, setFuleBaseRate] = useState('FuelBaseRate');

  const [errors, setErrors] = useState({
    deliveryBaseRate: '',
    fuelBaseRate: '',
    customer: '',
  });

  const handelPricingDetailsEdit = () => {
    setIsPricingDetailsEdit(!isPricingDetailsEdit);
  };
  const handlePricingCollapseClick = () => {
    setPricingDetailsExpanded(!pricingDetailsExpanded);
  };

  const handelPricingZoneDetailsEdit = () => {
    setIsPricingZoneDetailsEdit(!isPricingZoneDetailsEdit);
  };
  const handleZoneExpandCollapseClick = () => {
    setPricingZoneDetailsExpanded(!pricingZoneDetailsExpanded);
  };

  const handelSpecialPricingZoneDetailsEdit = () => {
    setIsSpecialPricingZoneDetailsEdit(!isSpecialPricingZoneDetailsEdit);
  };
  const handleSpecialZoneExpandCollapseClick = () => {
    setSpecialPricingZoneDetailsExpanded(!specialPricingZoneDetailsExpanded);
  };

  const validate = () => {
    let temp = { ...errors };
    temp.deliveryBaseRate = deliveryBaseRateValue
      ? ''
      : i18nMessages.commonFieldIsRequired;
    temp.fuelBaseRate = fuelBaseRateValue
      ? ''
      : i18nMessages.commonFieldIsRequired;
    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === '');
  };
  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (validate()) {
      const apiUrl = new URL(environment.api.baseUrl + `TransportBaseRate`);
      try {
        const finalData = {
          deliveryBaseRate: deliveryBaseRateValue,
          fuelBaseRate: fuelBaseRateValue,
          transportCatalogueId: Number(entityId),
          transportTypeId,
        };
        const result: any = await http.post(apiUrl.toString(), finalData);
        if (result) {
          toast.success('Saved Successfully');
          setIsPricingDetailsEdit(false);
        } else {
          toast.error(I18n('Common.ErrorOccurred'));
        }
      } catch (error: any) {}
    }
  };
  const handleZoneSubmit = async (event: any) => {
    event.preventDefault();
    const apiUrl = new URL(environment.api.baseUrl + `TransportZonePrice`);
    try {
      var finalObj = {
        transportZonePriceList: rows,
        transportCatalogueId: Number(entityId),
        transportTypeId,
      };

      const result: any = await http.post(apiUrl.toString(), finalObj);
      if (result) {
        toast.success('Saved Successfully');
        setIsPricingZoneDetailsEdit(false);
      } else {
        toast.error(I18n('Common.ErrorOccurred'));
      }
    } catch (error: any) {}
  };

  const handleZoneSpecialPriceSubmit = async (event: any) => {
    event.preventDefault();
    const apiUrl = new URL(
      environment.api.baseUrl + `TransportZoneSpecialPrice`
    );
    try {
      specialPricingRows.forEach((element: any) => {
        element.customerId = +element.customerId;
      });

      var finalObj = {
        transportZoneSpecialPriceList: specialPricingRows,
        transportCatalogueId: Number(entityId),
        transportTypeId,
      };

      const result: any = await http.post(apiUrl.toString(), finalObj);
      if (result) {
        toast.success(I18n('Common.SavedSuccessfully'));
        setIsSpecialPricingZoneDetailsEdit(false);
      } else {
        toast.error(I18n('Common.ErrorOccurred'));
      }
    } catch (error: any) {}
  };
  const onValueChange = (e: any, data: any) => {
    if (data === 'deliveryBaseRate') {
      setDeliveryBaseRateValue(e.target.value);
    } else {
      setFuelBaseRateValue(e.target.value);
    }
  };

  const handleZoneChange = (idx: any) => (e: any) => {
    const { name, value } = e.target;
    const finalrows: any = [...rows];
    finalrows[idx][name] = value;
    setRows(finalrows);
  };

  const handleSpecialPricingZoneChange = (idx: any) => (e: any) => {
    const { name, value } = e.target;
    const finalrows: any = [...specialPricingRows];
    finalrows[idx][name] = value;
    setSpecialPricingRows(finalrows);
  };

  const handleSpecialPricingCustomerChange = (idx: any, newValue: any) => {
    const finalrows: any = [...specialPricingRows];
    finalrows[idx]['customerId'] = newValue.value;
    finalrows[idx]['customerName'] = newValue.label;
    setSpecialPricingRows(finalrows);
  };

  const handleAddRow = (e: any) => {
    e.preventDefault();
    const item = {
      kg1: '',
      kg2: '',
      deliveryRate: '',
      fuelRate: '',
      transportCatalogueId: Number(entityId),
      transportTypeId,
    };
    setRows([...rows, item]);
  };

  const handleSpecialPricingAddRow = (e: any) => {
    e.preventDefault();
    const item = {
      customerId: '',
      customerName: '',
      kg1: '',
      kg2: '',
      deliveryRate: '',
      fuelRate: '',
      transportCatalogueId: Number(entityId),
      transportTypeId,
    };
    setSpecialPricingRows([...specialPricingRows, item]);
  };

  const handleRemoveSpecificRow = (idx: any) => () => {
    const rowsdata = [...rows];
    rowsdata.splice(idx, 1);
    setRows(rowsdata);
  };

  const handleSpecialPricingRemoveSpecificRow = (idx: any) => () => {
    const rowsdata = [...specialPricingRows];
    rowsdata.splice(idx, 1);
    setSpecialPricingRows(rowsdata);
  };

  const chipClicked = (selectedId: any, name: any, value: any) => {
    const finalrows: any = [...rows];
    finalrows[selectedId][name] = finalrows[selectedId][name] + value;
    setRows(finalrows);
  };

  const specialPricingChipClicked = (
    selectedId: any,
    name: any,
    value: any
  ) => {
    const finalrows: any = [...specialPricingRows];
    finalrows[selectedId][name] = finalrows[selectedId][name] + value;
    setSpecialPricingRows(finalrows);
  };

  useEffect(() => {
    setDeliveryBaseRateValue(props?.BaseRateResponse?.deliveryBaseRate);
    setFuelBaseRateValue(props?.BaseRateResponse?.fuelBaseRate);
    setTransportTypeId(props?.transportTypeId);
    setRows(props?.ZoneResponse);
    setSpecialPricingRows(props?.SpecialPrice);
  }, [props]);

  const PricingDetailsData = () => {
    return (
      <>
        <Grid item lg={4} md={8}>
          <FormControl
            margin="normal"
            displayLabel={I18n('TransportCatalogue.deliveryBaseRate')}
            id="deliveryBaseRate"
            inputType="text"
            name="deliveryBaseRate"
            value={deliveryBaseRateValue}
            inputProps={{ maxLength: 10 }}
            multiline={false}
            handleChange={(e: any) => onValueChange(e, 'deliveryBaseRate')}
            readOnly={!isPricingDetailsEdit}
            errorValue={true}
            errorMessage={errors.deliveryBaseRate}
          ></FormControl>
        </Grid>
        <Grid item lg={4} md={8}>
          <FormControl
            margin="normal"
            displayLabel={I18n('TransportCatalogue.fuelBaseRate')}
            id="fuelBaseRate"
            inputType="text"
            name="fuelBaseRate"
            value={fuelBaseRateValue}
            inputProps={{ maxLength: 10 }}
            multiline={false}
            handleChange={(e: any) => onValueChange(e, 'fuelBaseRate')}
            readOnly={!isPricingDetailsEdit}
            errorValue={true}
            errorMessage={errors.fuelBaseRate}
          ></FormControl>
        </Grid>
      </>
    );
  };
  const PricingZoneDetailsData = () => {
    return (
      <Grid item lg={12}>
        <Table className="basic-table" id="tab_logic" width="100%">
          <TableHead>
            <TableRow>
              <TableCell align="left">
                {I18n('TransportCatalogue.Kg')}
              </TableCell>
              <TableCell align="left">
                {I18n('TransportCatalogue.DeliveryPrice')}
              </TableCell>
              <TableCell align="left">
                {I18n('TransportCatalogue.FuelPrice')}
              </TableCell>
              <TableCell align="left">
                {isPricingZoneDetailsEdit ? (
                  <Button
                    className="icon-btn"
                    disableElevation
                    onClick={handleAddRow}
                  >
                    <AddIcon />
                  </Button>
                ) : (
                  <></>
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((item: any, idx: any) => (
              <TableRow key={idx}>
                <TableCell>
                  <Box display="flex" alignItems="center" className="input-50">
                    <FormControl
                      className="no-label small-input-control"
                      margin="normal"
                      name="kg1"
                      inputType="text"
                      value={item?.kg1}
                      handleChange={handleZoneChange(idx)}
                      readOnly={!isPricingZoneDetailsEdit}
                    />
                    <div className="text-center"> - </div>
                    <FormControl
                      className="no-label small-input-control"
                      margin="normal"
                      name="kg2"
                      inputType="text"
                      value={item?.kg2}
                      handleChange={handleZoneChange(idx)}
                      readOnly={!isPricingZoneDetailsEdit}
                    />
                  </Box>
                </TableCell>
                <TableCell>
                  {isPricingZoneDetailsEdit ? (
                    <PricingChip
                      name="deliveryRate"
                      selectedId={idx}
                      handleClick={chipClicked}
                    ></PricingChip>
                  ) : (
                    ''
                  )}
                  <FormControl
                    className="no-label small-input-control"
                    margin="normal"
                    inputType="text"
                    name="deliveryRate"
                    value={item.deliveryRate}
                    handleChange={handleZoneChange(idx)}
                    readOnly={!isPricingZoneDetailsEdit}
                  />
                </TableCell>
                <TableCell>
                  {isPricingZoneDetailsEdit ? (
                    <PricingChip
                      name="fuelRate"
                      handleClick={chipClicked}
                      selectedId={idx}
                    ></PricingChip>
                  ) : (
                    ''
                  )}
                  <FormControl
                    className="no-label small-input-control"
                    margin="normal"
                    inputType="text"
                    name="fuelRate"
                    handleChange={handleZoneChange(idx)}
                    value={item.fuelRate}
                    readOnly={!isPricingZoneDetailsEdit}
                  />
                </TableCell>
                {isPricingZoneDetailsEdit ? (
                  <TableCell>
                    <Button
                      color="info"
                      className="small-btn"
                      disableElevation
                      variant="contained"
                      onClick={handleRemoveSpecificRow(idx)}
                    >
                      <FontAwesomeIcon icon={faTrash} className="fa-md mr-2" />
                    </Button>
                  </TableCell>
                ) : (
                  ''
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    );
  };

  const SpecialPricingZoneDetailsData = () => {
    return (
      <Grid item lg={12}>
        <Table className="basic-table" id="tab_logic" width="100%">
          <TableHead>
            <TableRow>
              <TableCell align="left">
                {I18n('Customer.CustomerName')}
              </TableCell>
              <TableCell align="left">
                {I18n('TransportCatalogue.Kg')}
              </TableCell>
              <TableCell align="left">
                {I18n('TransportCatalogue.DeliveryPrice')}
              </TableCell>
              <TableCell align="left">
                {I18n('TransportCatalogue.FuelPrice')}
              </TableCell>
              <TableCell align="left">
                {isSpecialPricingZoneDetailsEdit ? (
                  <Button
                    className="icon-btn"
                    disableElevation
                    onClick={handleSpecialPricingAddRow}
                  >
                    <AddIcon />
                  </Button>
                ) : (
                  <></>
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {specialPricingRows?.map((item: any, idx: any) => (
              <TableRow key={idx}>
                <TableCell width={'30%'}>
                  <CustomSelectPaginate
                    readOnly={!isSpecialPricingZoneDetailsEdit}
                    additional={defaultAdditional}
                    value={{
                      label: item?.customerName,
                      value: item?.customerId,
                    }}
                    loadOptions={(search: any, prevOptions: any, page: any) =>
                      loadCustomerOptions(
                        search,
                        prevOptions,
                        page,
                        item?.customerId
                      )
                    }
                    handleChange={(newValue: BaseModel) => {
                      handleSpecialPricingCustomerChange(idx, newValue);
                    }}
                    placeholder={defaultCustomerValue.label}
                    isSearchable={true}
                    displayLabel={''}
                    errorValue={true}
                    errorMessage={errors.customer}
                    debounceTimeout={500}
                    isMultiSelect={false}
                  />
                </TableCell>
                <TableCell>
                  <Box display="flex" alignItems="center" className="input-55">
                    <FormControl
                      className="no-label small-input-control"
                      margin="normal"
                      name="kg1"
                      inputType="text"
                      value={item?.kg1}
                      handleChange={handleSpecialPricingZoneChange(idx)}
                      readOnly={!isSpecialPricingZoneDetailsEdit}
                    />
                    <div className="text-center text-55"> - </div>
                    <FormControl
                      className="no-label small-input-control"
                      margin="normal"
                      name="kg2"
                      inputType="text"
                      value={item?.kg2}
                      handleChange={handleSpecialPricingZoneChange(idx)}
                      readOnly={!isSpecialPricingZoneDetailsEdit}
                    />
                  </Box>
                </TableCell>
                <TableCell>
                  {isSpecialPricingZoneDetailsEdit ? (
                    <PricingChip
                      name="deliveryRate"
                      selectedId={idx}
                      handleClick={specialPricingChipClicked}
                    ></PricingChip>
                  ) : (
                    ''
                  )}
                  <FormControl
                    className="no-label small-input-control"
                    margin="normal"
                    inputType="text"
                    name="deliveryRate"
                    value={item.deliveryRate}
                    handleChange={handleSpecialPricingZoneChange(idx)}
                    readOnly={!isSpecialPricingZoneDetailsEdit}
                  />
                </TableCell>
                <TableCell>
                  {isSpecialPricingZoneDetailsEdit ? (
                    <PricingChip
                      name="fuelRate"
                      handleClick={specialPricingChipClicked}
                      selectedId={idx}
                    ></PricingChip>
                  ) : (
                    ''
                  )}
                  <FormControl
                    className="no-label small-input-control"
                    margin="normal"
                    inputType="text"
                    name="fuelRate"
                    handleChange={handleSpecialPricingZoneChange(idx)}
                    value={item.fuelRate}
                    readOnly={!isSpecialPricingZoneDetailsEdit}
                  />
                </TableCell>
                {isSpecialPricingZoneDetailsEdit ? (
                  <TableCell>
                    <Button
                      color="info"
                      className="small-btn"
                      disableElevation
                      variant="contained"
                      onClick={handleSpecialPricingRemoveSpecificRow(idx)}
                    >
                      <FontAwesomeIcon icon={faTrash} className="fa-md mr-2" />
                    </Button>
                  </TableCell>
                ) : (
                  ''
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    );
  };

  return (
    <Grid container spacing={2} className="grid-wrap">
      <Grid item lg={12} md={10}>
        <FormAccordian
          title={I18n('TransportCatalogue.baseRate')}
          showActionButton={canEdit}
          details={PricingDetailsData()}
          expanded={pricingDetailsExpanded}
          isEdit={isPricingDetailsEdit}
          handleEditClick={handelPricingDetailsEdit}
          handleExpandCollapseClick={handlePricingCollapseClick}
          isSaveButton={isPricingDetailsEdit}
          onSaveClick={handleSubmit}
        ></FormAccordian>
      </Grid>
      <Grid item lg={12} md={10}>
        <FormAccordian
          showActionButton={canEdit}
          title={props.zonesName}
          details={PricingZoneDetailsData()}
          expanded={pricingZoneDetailsExpanded}
          isEdit={isPricingZoneDetailsEdit}
          handleEditClick={handelPricingZoneDetailsEdit}
          handleExpandCollapseClick={handleZoneExpandCollapseClick}
          isSaveButton={isPricingZoneDetailsEdit}
          onSaveClick={handleZoneSubmit}
        ></FormAccordian>
      </Grid>
      <Grid item lg={12} md={10}>
        <FormAccordian
          showActionButton={canEdit}
          title={I18n('ProductCatalogues.Tabs.SpecialPricing')}
          details={SpecialPricingZoneDetailsData()}
          expanded={specialPricingZoneDetailsExpanded}
          isEdit={isSpecialPricingZoneDetailsEdit}
          handleEditClick={handelSpecialPricingZoneDetailsEdit}
          handleExpandCollapseClick={handleSpecialZoneExpandCollapseClick}
          isSaveButton={isSpecialPricingZoneDetailsEdit}
          onSaveClick={handleZoneSpecialPriceSubmit}
        ></FormAccordian>
      </Grid>
    </Grid>
  );
};

export default Pricing;
