import React, { useEffect, useState } from 'react';

import { Button, CssBaseline, Box, Grid, Typography, Tab } from '@mui/material';
import http from '../../utilities/httpService';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CustomizedInputs from 'common/formControl/formControl';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import { ReactComponent as LeftImage } from '../../assets/images/login-img.svg';
import BgImage from '../../assets/images/login-bg.png';
import auth from '../../auth/authentication';
import '../login/login.scss';
import RouteEnum from 'models/RouteEnum';
import environment from 'environment';
import { toast } from 'react-toastify';
import I18n from 'utilities/i18n';
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import axios from 'axios';

function ResetPassword(props: any) {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState({
    confirmPassword: '',
    newPassword: '',
  });
  let navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const currentUser = await auth.getCurrentUser();
      if (currentUser) {
        window.location.href = RouteEnum.Root;
      }
    })();
  }, []);

  const [value, setValue] = React.useState('1');

  const handleNewPasswordChange = (password: string) => {
    setNewPassword(password);
  };

  const handleConfirmPasswordChange = (password: string) => {
    setConfirmPassword(password);
  };

  const theme = createTheme({
    typography: {
      fontFamily: 'Sofia Pro',
    },
  });

  const i18nMessages = {
    newPasswordRequired: I18n('Auth.NewPasswordRequired'),
    confirmPasswordRequired: I18n('Auth.ConfirmPasswordRequired'),
    passwordMismatch: I18n('Auth.PasswordMismatch'),
    errorOccurred: I18n('Auth.ErrorOccurred'),
    passwordStrength: I18n('Auth.PasswordStrength'),
    LinkExpired: I18n('Auth.LinkExpired'),
    PasswordChanged: I18n('Auth.PasswordChanged'),
  };

  const validate = () => {
    errors.confirmPassword = '';
    errors.newPassword = '';
    let temp = { ...errors };
    if (newPassword === '') {
      temp.newPassword = i18nMessages.newPasswordRequired;
    }
    if (confirmPassword === '') {
      temp.confirmPassword = i18nMessages.confirmPasswordRequired;
    }
    if (
      newPassword.length < 6 ||
      /^[A-Z]*$/.test(newPassword) ||
      /^[a-z]*$/.test(newPassword) ||
      /^[0-9]*$/.test(newPassword)
    ) {
      temp.newPassword = i18nMessages.passwordStrength;
    }
    if (newPassword !== confirmPassword) {
      temp.confirmPassword = i18nMessages.passwordMismatch;
    }

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === '');
  };

  const onSendLink = async (event: any) => {
    if (validate()) {
      const url = window.location.href;
      const email = url.split('&')[1].split('=')[1];
      const token = url.split('?')[1].split('=')[1];
      const apiUrl = new URL(
        environment.api.baseUrl + 'Authenticate/ResetPassword'
      );
      try {
        const tokenObj = {
          newPassword,
          email,
          token,
        };
        const fpInstance = axios.create();
        return fpInstance
          .post(apiUrl.toString(), tokenObj)
          .then((result) => {
            if (result) {
              localStorage.setItem('passwordChanged', 'true');
              navigate(RouteEnum.Login);
            }
            return result;
          })
          .catch((error) => {
            if (!errors.confirmPassword && !errors.newPassword) {
              localStorage.setItem('linkExpired', 'true');
              navigate(RouteEnum.Login);
            }
          });
      } catch (err) {}
    }
  };

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Grid
          container
          component="main"
          sx={{
            px: 3,
            py: 3,
            minHeight: '100vh',
            backgroundImage: `url(${BgImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
          alignItems="center"
          justifyContent="center"
        >
          <CssBaseline />
          <Grid
            item
            sm={12}
            md={6}
            textAlign="center"
            className="left-image-outer"
          >
            <LeftImage></LeftImage>
          </Grid>
          <Grid item sm={12} md={6}>
            <div className="login-form-wrap">
              <Typography variant="h1" sx={{ mb: 3 }} className="primarytxt">
                {I18n('Auth.LoginToMyHazgo')}
              </Typography>
              <TabContext value={value}>
                <Box>
                  <TabList aria-label="lab API tabs example">
                    <Tab label={I18n('Auth.ResetPassword')} value="1" />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <CustomizedInputs
                    margin="normal"
                    displayLabel={I18n('Auth.NewPassword')}
                    required
                    id="newPassword"
                    inputType="password"
                    errorValue={true}
                    errorMessage={errors.newPassword}
                    handleChange={(event: any) =>
                      handleNewPasswordChange(event.target.value)
                    }
                  />
                  <CustomizedInputs
                    margin="normal"
                    displayLabel={I18n('Auth.ConfirmPassword')}
                    required
                    id="confirmPassword"
                    inputType="password"
                    errorValue={true}
                    errorMessage={errors.confirmPassword}
                    handleChange={(event: any) =>
                      handleConfirmPasswordChange(event.target.value)
                    }
                  />
                  <Button
                    color="primary"
                    disableElevation
                    variant="contained"
                    fullWidth
                    sx={{ mt: 3, mb: 2 }}
                    onClick={onSendLink}
                  >
                    {I18n('Auth.ResetPassword')}
                  </Button>
                  <Link to={RouteEnum.Login}>{I18n('Auth.BackToLogin')}</Link>
                </TabPanel>
              </TabContext>
            </div>
          </Grid>
        </Grid>
      </ThemeProvider>
    </div>
  );
}

export default ResetPassword;
