import React, { useState, useEffect } from 'react';
import http from '../../utilities/httpService';
import { toast } from 'react-toastify';
import RouteEnum from 'models/RouteEnum';
import CustomizedInputs from 'common/formControl/formControl';
import CustomizedPhone from 'common/formControl/formPhone';
import Grid from '@mui/material/Grid';
import environment from 'environment';
import I18n from 'utilities/i18n';
import {
  BaseModel,
  PreferredLangSelect,
  PreferredLanguage,
  ImageUploadViewModel,
  DocumentEntity,
  ApiResponse,
} from 'models/pagination-model';
import { InputLabel } from '@mui/material';
import ProfilePicture from 'common/ImageUploadPreview/ProfilePicture';
import FormAccordian from 'common/formControl/formAccordian';
import CustomizedSelect from 'common/CustomSelect/CustomSelect';
import { UserProfilePicture } from 'models/user-profile';
import ConfirmationPopup from 'common/confirmationPopup/confirmationPopup';
import auth from '../../auth/authentication';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function MyProfile(props: any) {
  const langValue = {
    value: '1',
    label: I18n('Common.Language.EN'),
  } as BaseModel;

  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    role: '',
    phoneNumber: '',
    preferredLanguage: '',
    status: '',
    email: '',
    phone: '',
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const [values, setValues] = useState({
    id: 0,
    firstName: '',
    lastName: '',
    role: '',
    phoneNumber: '',
    preferredLanguage: '',
    status: 0,
    email: '',
    phone: '',
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const [userDetailsExpanded, setUserDetailsExpanded] = useState<boolean>(true);
  const [isUserDetailsEdit, setUserDetailsIsEdit] = useState<boolean>(false);
  const [preferredLangEdit, setPreferredLangEdit] = useState<boolean>(false);
  const [photoExpanded, setPhotoExpanded] = useState<boolean>(true);
  const [isEditPhotos, setIsEditPhotos] = useState<boolean>(false);
  const [preferredLangExpanded, setPreferredLangExpanded] =
    useState<boolean>(true);
  const [images, setImages] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFailResolution, setOpenFailResolution] = useState(false);

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (validate()) {
      const apiUrl = new URL(environment.api.baseUrl + 'Profile/UpdateProfile');
      const requestData = {
        ...values,
        phone: getPhoneValue(values.phone),
        phoneNumber: getPhoneValue(values.phoneNumber),
      };
      try {
        const result: any = await http.put(apiUrl.toString(), requestData);

        if (result.data) {
          if (!result.data.succedded) {
            toast.error(
              result.data.message
                ? result.data.message
                : i18nMessage.somethingWentWrong
            );
          } else {
            setOpen(result.data.isPasswordChanged);
            toast.success(i18nMessage.savedSuccess);
          }
        }
      } catch (error: any) {
        if (error.response) {
          let temp = { ...errors };
          for (var item of error.response.data.errors) {
            if (item.field === i18nMessage.phoneNumber) {
              temp.phoneNumber = item.errorMessage;
            }
          }
          setErrors({
            ...temp,
          });
        }
      }
    }
  };

  const onValueChange = (event: any) => {
    const { name, value } = event.target;
    validate({ [name]: value });
    setValues({
      ...values,
      [name.toString()]: value,
    });
  };

  const getPhoneValue = (phone: any) => {
    return phone && phone.replace(/[^\d]/g, '') != ''
      ? phone.replace(/[^\d\+]/g, '')
      : null;
  };

  const phoneOnChange = (phone: any) => {
    let phoneValue: string = getPhoneValue(phone);
    setValues({ ...values, phone: phoneValue });
    setErrors({
      ...errors,
      phone: '',
    });
  };

  const mobileOnChange = (phone: any) => {
    let phoneValue: string = getPhoneValue(phone);
    setValues({ ...values, phoneNumber: phoneValue });
    setErrors({
      ...errors,
      phone: '',
    });
  };

  let langList: Array<BaseModel> = [];
  const getLangList = () => {};
  PreferredLangSelect.forEach((value: string, key: number) => {
    langList.push({
      value: key.toString(),
      label: I18n(value),
    } as BaseModel);
  });
  getLangList();

  const onSelectChange = (name: string, data: any) => {
    setValues({
      ...values,
      [name]: Number(data?.value),
    });
  };

  const getSelectedLanguage = (key: string) => {
    return {
      value: key,
      label: langauage(key),
    };
  };

  const handleOk = () => {
    setOpen(false);
    auth.logout();
  };

  const handleClose = () => {
    setOpen(false);
    auth.logout();
  };

  const handleCloseSnakbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    setOpenSuccess(false);
    setOpenFailResolution(false);
  };

  const i18nMessage = {
    fieldRequired: I18n('Common.FieldRequired'),
    invalidPhone: I18n('Common.InvalidPhone'),
    phoneNumber: I18n('Common.PhoneNumberVal'),
    confirmPasswordRequired: I18n('Auth.ConfirmPasswordRequired'),
    passwordMismatch: I18n('Auth.PasswordMismatch'),
    errorOccurred: I18n('Auth.ErrorOccurred'),
    passwordStrength: I18n('Auth.PasswordStrength'),
    profilePicture: I18n('UsersProfile.ProfilePicture'),
    savedSuccess: I18n('UsersProfile.ProfileUpdated'),
    commonFieldIsRequired: I18n('Common.FieldIsRequired'),
    somethingWentWrong: I18n('Common.SomethingWentWrong'),
    noImagesSelected: I18n('Common.NoImagesSelectedToUpload'),
    imageSaveSuccess: I18n('Common.ImageSaveSuccess'),
    passwordUpdatedLoginAgain: I18n('UsersProfile.PasswordUpdatedLoginAgain'),
    imageResolutionExceedError: I18n('Common.ImageResolutionExceedError'),
  };

  const i18nLabel = {
    langEN: I18n('Common.Language.EN'),
    langFR: I18n('Common.Language.FR'),
    langNL: I18n('Common.Language.NL'),
    preferredLanguage: I18n('UsersProfile.PreferredLanguage'),
    myProfile: I18n('UsersProfile.MyProfile'),
    firstName: I18n('UsersProfile.FirstName'),
    lastName: I18n('UsersProfile.LastName'),
    email: I18n('UsersProfile.Email'),
    phone: I18n('UsersProfile.Phone'),
    mobile: I18n('UsersProfile.Mobile'),
    password: I18n('Auth.Password'),
    newPassword: I18n('Auth.NewPassword'),
    confirmPassword: I18n('Auth.ConfirmPassword'),
  };

  const validate = (fieldValues: any = values) => {
    let temp = { ...errors };

    if ('firstName' in fieldValues) {
      temp.firstName =
        fieldValues.firstName !== '' ? '' : i18nMessage.fieldRequired;
    }
    if ('lastName' in fieldValues) {
      temp.lastName =
        fieldValues.lastName !== '' ? '' : i18nMessage.fieldRequired;
    }
    if ('phoneNumber' in fieldValues) {
      let num: string = fieldValues.phoneNumber?.replace(/[^\d]/g, '');
      if (!num) {
        temp.phoneNumber = i18nMessage.fieldRequired;
      } else if (num.length < 9) {
        temp.phoneNumber = i18nMessage.invalidPhone;
      } else {
        temp.phoneNumber = '';
      }
    }
    if ('phone' in fieldValues) {
      let num: string = fieldValues.phone?.replace(/[^\d]/g, '');
      if (num && num.length < 9) {
        temp.phone = i18nMessage.invalidPhone;
      } else {
        temp.phone = '';
      }
    }
    if ('newPassword' in fieldValues && fieldValues.newPassword !== '') {
      if ('currentPassword' in fieldValues) {
        temp.currentPassword =
          fieldValues.currentPassword !== '' ? '' : i18nMessage.fieldRequired;
      }

      if (
        fieldValues.newPassword.length < 6 ||
        /^[A-Z]*$/.test(fieldValues.newPassword) ||
        /^[a-z]*$/.test(fieldValues.newPassword) ||
        /^[0-9]*$/.test(fieldValues.newPassword)
      ) {
        temp.newPassword = i18nMessage.passwordStrength;
      } else {
        temp.newPassword = '';
      }

      if (
        'confirmPassword' in fieldValues &&
        fieldValues.newPassword !== fieldValues.confirmPassword
      ) {
        temp.confirmPassword =
          fieldValues.confirmPassword !== ''
            ? i18nMessage.passwordMismatch
            : i18nMessage.confirmPasswordRequired;
      } else {
        temp.confirmPassword = '';
      }
    } else {
      temp.currentPassword = '';
      temp.newPassword = '';
      temp.confirmPassword = '';
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === '');
    }
  };

  const handleCurrentPasswordChange = (password: string) => {
    setValues({ ...values, currentPassword: password });
  };

  const handleNewPasswordChange = (password: string) => {
    setValues({ ...values, newPassword: password });
  };

  const handleConfirmPasswordChange = (password: string) => {
    setValues({ ...values, confirmPassword: password });
  };

  const fileDataFunction = (data: ImageUploadViewModel[]) => {
    setImages(data);
  };

  const deleteFileFunction = (index: number) => {
    const localImages: any[] = [...images];
    localImages.splice(index, 1);
    setImages(localImages);
  };

  const editedFileDataFunction = (updatedImages: ImageUploadViewModel[]) => {
    setImages(updatedImages);
  };

  const getUserData = async () => {
    const apiUrl = new URL(environment.api.baseUrl + 'Profile/GetMyProfile');
    try {
      const result: any = await http.get(apiUrl.toString());
      if (result) {
        setValues({ ...values, ...result.data });
        getPictures(result.data.id);
      }
    } catch (error: any) {}
  };

  useEffect(() => {
    getUserData();
  }, []);

  const UserDetailsData = () => {
    return (
      <>
        <Grid item md={4} sm={6} xs={12}>
          <CustomizedInputs
            margin="normal"
            displayLabel={i18nLabel.firstName}
            id="firstName"
            name="firstName"
            inputType="text"
            errorValue={errors.firstName}
            errorMessage={errors.firstName}
            handleChange={onValueChange}
            value={values?.firstName}
            readOnly={!isUserDetailsEdit}
            required={isUserDetailsEdit}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <CustomizedInputs
            margin="normal"
            displayLabel={i18nLabel.lastName}
            id="lastName"
            name="lastName"
            inputType="text"
            errorValue={errors.lastName}
            errorMessage={errors.lastName}
            handleChange={onValueChange}
            value={values?.lastName}
            readOnly={!isUserDetailsEdit}
            required={isUserDetailsEdit}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <CustomizedInputs
            margin="normal"
            displayLabel={i18nLabel.email}
            name="email"
            id="email"
            inputType="text"
            errorValue={errors.email}
            errorMessage={errors.email}
            handleChange={onValueChange}
            value={values?.email}
            readOnly={true}
            required={isUserDetailsEdit}
          />
        </Grid>

        <Grid item md={4} sm={6} xs={12}>
          {isUserDetailsEdit ? (
            <CustomizedPhone
              title={i18nLabel.phone}
              name="phone"
              errorValue={errors.phone}
              errorMessage={errors.phone}
              onChange={(phone: any) => phoneOnChange(phone)}
              value={values?.phone}
            ></CustomizedPhone>
          ) : (
            <CustomizedInputs
              margin="normal"
              displayLabel={i18nLabel.phone}
              name="phone"
              id="phone"
              inputType="text"
              value={values?.phone}
              readOnly={!isUserDetailsEdit}
            ></CustomizedInputs>
          )}
        </Grid>

        <Grid item md={4} sm={6} xs={12}>
          {isUserDetailsEdit ? (
            <CustomizedPhone
              title={i18nLabel.mobile}
              value={values?.phoneNumber}
              name="phoneNumber"
              errorValue={errors.phoneNumber}
              errorMessage={errors.phoneNumber}
              onChange={(phone: any) => mobileOnChange(phone)}
              required={isUserDetailsEdit}
            ></CustomizedPhone>
          ) : (
            <CustomizedInputs
              margin="normal"
              displayLabel={i18nLabel.mobile}
              name="phoneNumber"
              id="phoneNumber"
              inputType="text"
              value={values?.phoneNumber}
              readOnly={!isUserDetailsEdit}
            />
          )}
        </Grid>
        <Grid item md={4} sm={6} xs={12}></Grid>
        {isUserDetailsEdit ? (
          <>
            <Grid item md={4} sm={6} xs={12}>
              <CustomizedInputs
                margin="normal"
                displayLabel={i18nLabel.password}
                id="currentPassword"
                inputType="password"
                errorValue={errors.currentPassword}
                errorMessage={errors.currentPassword}
                handleChange={(event: any) =>
                  handleCurrentPasswordChange(event.target.value)
                }
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <CustomizedInputs
                margin="normal"
                displayLabel={i18nLabel.newPassword}
                id="newPassword"
                inputType="password"
                errorValue={errors.newPassword}
                errorMessage={errors.newPassword}
                handleChange={(event: any) =>
                  handleNewPasswordChange(event.target.value)
                }
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <CustomizedInputs
                margin="normal"
                displayLabel={i18nLabel.confirmPassword}
                id="confirmPassword"
                inputType="password"
                errorValue={errors.confirmPassword}
                errorMessage={errors.confirmPassword}
                handleChange={(event: any) =>
                  handleConfirmPasswordChange(event.target.value)
                }
              />
            </Grid>
          </>
        ) : (
          <></>
        )}
      </>
    );
  };

  const handelUserDetailsEdit = () => {
    setUserDetailsIsEdit(!isUserDetailsEdit);
  };
  const handleUserDetailsExpandCollapseClick = () => {
    setUserDetailsExpanded(!userDetailsExpanded);
  };

  const handelPreferredLangEdit = () => {
    setPreferredLangEdit(!preferredLangEdit);
  };
  const handelPreferredLangExpandCollapseClick = () => {
    setPreferredLangExpanded(!preferredLangExpanded);
  };

  const handlePhotosExpandCollapseClick = (event: React.SyntheticEvent) => {
    setPhotoExpanded(!photoExpanded);
  };

  const handlePhotosEditClick = (event: React.SyntheticEvent) => {
    setIsEditPhotos(!isEditPhotos);
  };

  const close = () => {
    window.location.href = RouteEnum.MyProfile;
  };

  const langauage = (lang: any) => {
    if (lang === PreferredLanguage.EN) {
      return i18nLabel.langEN;
    } else if (lang === PreferredLanguage.FR) {
      return i18nLabel.langFR;
    } else {
      return i18nLabel.langNL;
    }
  };

  const PreferredLangDetails = () => {
    return (
      <>
        <Grid item lg={12}>
          {preferredLangEdit ? (
            <CustomizedSelect
              placeholder={langValue.label}
              options={langList}
              isSearchable={false}
              defaultValue={getSelectedLanguage(values?.preferredLanguage)}
              displayLabel={i18nLabel.preferredLanguage}
              handleChange={(newValue: BaseModel) =>
                onSelectChange('preferredLanguage', newValue)
              }
            ></CustomizedSelect>
          ) : (
            <CustomizedInputs
              margin="normal"
              displayLabel={i18nLabel.preferredLanguage}
              name="preferredLanguage"
              id="preferredLanguage"
              inputType="text"
              value={langauage(values?.preferredLanguage)}
              readOnly={!preferredLangEdit}
            />
          )}
        </Grid>
      </>
    );
  };

  const getPictures = (userId: number) => {
    getProfileImage(userId).then((x) => {
      if (x.result && x.data) {
        let pictures = [];
        let pic = {
          id: x.data.id,
          previewUrl: environment.api.uploadedFileUrl + x.data.imageUrl,
          path: x.data.imageUrl,
        };
        pictures.push(pic);
        /* Object conversion from backend viewModel to ImagePreview component Object */
        setImages(pictures);
      }
    });
  };

  const handlePhotoSaveClick = async () => {
    if (images && images.length && validatePictures(images.length)) {
      const imagesResult = await saveUserProfilePicture(
        images,
        DocumentEntity.UserProfilePicture,
        {
          imageUrl: '',
          userId: values.id,
        } as UserProfilePicture
      );
      if (imagesResult && imagesResult.result) {
        toast.success(i18nMessage.imageSaveSuccess);
      } else {
        toast.error(i18nMessage.somethingWentWrong);
      }
    } else if (!images || images.length == 0) {
      toast.error(i18nMessage.noImagesSelected);
      return;
    }
  };

  const PhotosSection = () => {
    return (
      <>
        <Grid item lg={12} md={12}>
          <InputLabel shrink htmlFor="bootstrap-input">
            {i18nMessage.profilePicture}
          </InputLabel>

          <ProfilePicture
            readOnly={!isEditPhotos}
            disableKeyboardNav={true}
            limit={1}
            fileData={fileDataFunction}
            fileRemove={deleteFileFunction}
            editedFileData={editedFileDataFunction}
            images={images}
          />
        </Grid>
      </>
    );
  };

  async function getProfileImage(userId: number) {
    const apiUrl = new URL(
      environment.api.baseUrl + `Profile/GetProfileImageAsync/${userId}`
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result) {
        return {
          data: result.data,
          result: result.status === 200,
        } as any;
      } else {
        return {
          data: [],
          result: false,
        } as any;
      }
    } catch (error) {
      return {
        data: [],
        result: false,
      } as any;
    }
  }

  /* To Upload a Image File */
  async function uploadProfilePicture(
    image?: File,
    documentEntityType?: DocumentEntity
  ) {
    try {
      const apiUrl = new URL(environment.api.baseUrl + `Profile/UploadPicture`);
      const data = new FormData();
      if (image) {
        data.append('file', image);
      }
      const response = await http.post(apiUrl.toString(), data);
      return {
        result: response && response.data,
        data: response.data,
      } as ApiResponse<string>;
    } catch (error) {
      console.log(documentEntityType);
      return {
        result: false,
        data: '',
      } as ApiResponse<string>;
    }
  }

  const validatePictures = (imagesLength: number) => {
    let isValidImage = true;
    for (let index = 0; index < imagesLength; index++) {
      var img: any = document.getElementById(`profile-pic-${index}`);
      //or however you get a handle to the IMG
      var width = img?.naturalWidth;
      var height = img?.naturalHeight;

      if (width !== 600 || height !== 500) {
        isValidImage = false;
        setOpenFailResolution(true);
        break;
      }
    }
    return isValidImage;
  };

  /* Method to upload DeckPack Collecting Shipment Data and save to API */
  async function saveUserProfilePicture(
    images: ImageUploadViewModel[],
    documentEntityType: DocumentEntity,
    userProfilePicture: UserProfilePicture
  ) {
    try {
      for (let index = 0; index < images.length; index++) {
        const imageResult = await uploadProfilePicture(
          images[index].image,
          documentEntityType
        );
        if (imageResult && imageResult.result) {
          userProfilePicture.imageUrl = imageResult.data;
          const apiUrl = new URL(
            environment.api.baseUrl + `Profile/UpdateProfilePictureAsync`
          );
          const result = await http.put(apiUrl.toString(), userProfilePicture);
          if (result) {
            return {
              data: result.data,
              result: result.status === 200,
            } as ApiResponse<boolean>;
          } else {
            return {
              data: false,
              result: false,
            } as ApiResponse<boolean>;
          }
        }
      }
    } catch (error) {}
  }

  return (
    <>
      <Grid container spacing={2} className="grid-wrap">
        <Grid item lg={12} md={10}>
          <FormAccordian
            title={i18nLabel.myProfile}
            details={UserDetailsData()}
            expanded={userDetailsExpanded}
            isEdit={isUserDetailsEdit}
            handleEditClick={handelUserDetailsEdit}
            handleExpandCollapseClick={handleUserDetailsExpandCollapseClick}
            isCancelButton={true && isUserDetailsEdit}
            onCancelClick={close}
            isSaveButton={isUserDetailsEdit}
            onSaveClick={handleSubmit}
          ></FormAccordian>
        </Grid>
        <Grid item lg={6} md={10}>
          <FormAccordian
            title={i18nLabel.preferredLanguage}
            details={PreferredLangDetails()}
            expanded={preferredLangExpanded}
            isEdit={preferredLangEdit}
            handleEditClick={handelPreferredLangEdit}
            handleExpandCollapseClick={handelPreferredLangExpandCollapseClick}
            isSaveButton={preferredLangEdit}
            onSaveClick={handleSubmit}
          ></FormAccordian>
        </Grid>
        <Grid item lg={6} md={10}>
          <FormAccordian
            title={i18nMessage.profilePicture}
            className="inner-heading"
            details={PhotosSection()}
            expanded={photoExpanded}
            isEdit={isEditPhotos}
            handleEditClick={handlePhotosEditClick}
            handleExpandCollapseClick={handlePhotosExpandCollapseClick}
            isSaveButton={isEditPhotos}
            onSaveClick={handlePhotoSaveClick}
          ></FormAccordian>
        </Grid>

        <ConfirmationPopup
          isOpen={open}
          message={i18nMessage.passwordUpdatedLoginAgain}
          handleClose={handleClose}
          handleOk={handleOk}
          hasCancel={false}
        ></ConfirmationPopup>
      </Grid>
      <Snackbar
        open={openFailResolution}
        autoHideDuration={6000}
        onClose={handleCloseSnakbar}
      >
        <Alert
          onClose={handleCloseSnakbar}
          severity="error"
          sx={{ width: '100%' }}
        >
          {i18nMessage.imageResolutionExceedError}
        </Alert>
      </Snackbar>
    </>
  );
}

export default MyProfile;
