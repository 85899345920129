import {
  Box,
  Breadcrumbs,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Link,
  Typography,
} from '@mui/material';
import CustomizedSelect from 'common/CustomSelect/CustomSelect';
import CustomSelectPaginate from 'common/CustomSelect/CustomSelectPaginate';
import DocumentList from 'common/Document/DocumentList';
import FormAccordianSave from 'common/formControl/formAccordianSave';
import CustomizedInputs from 'common/formControl/formControl';
import Status from 'common/WoStatus/Status';
import environment from 'environment';
import { DnPWorkOrders } from 'models/deck-pack-orders';
import {
  EntityTypeEnum,
  DocumentEntity,
  BaseModel,
  DGDDescription,
  ActivityOwner,
  OrderType,
  WorkOrderStatus,
  MasterCategoryCode,
} from 'models/pagination-model';
import RouteEnum from 'models/RouteEnum';
import { Permission } from 'Permissions';
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import I18n from 'utilities/i18n';
import { hasPermission } from 'utilities/protectedRoute';
import http from '../../../utilities/httpService';
import { loadUserOptions } from '../DeckPackApiService';
import DecpackStatus from './DecpackStatus';
import WO3DetailsModal from './WO3DetailsModal';
import CreatedByandUpdatedBy from 'common/CreatedByandUpdatedBy/CreatedByandUpdatedBy';
import moment from 'moment';

function DecPackDGDWOCreation() {
  const { woId, id } = useParams();
  let navigate = useNavigate();
  const defaultAdditional: any = {
    page: 1,
  };

  const breadcrumbs = [
    <Typography key="3" color="text.primary">
      {I18n('Nav.Order')}
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={() => navigate(RouteEnum.DecPackOrdersList)}
        className="cpointer"
      >
        {I18n('Nav.DecPackOrders')}
      </Link>
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={() => navigate(RouteEnum.ViewDecPackOrders + '/' + id + '/1')}
        className="cpointer"
      >
        {I18n('ShippingOrders.WorkOrders')}
      </Link>
    </Typography>,
  ];
  const defaultValue = {
    value: '0',
    label: I18n('Placeholders.Select'),
  } as BaseModel;
  // const [selectedAssignmentTo, setSelectedAssignmentTo] =
  //   useState(defaultValue);

  const [isStatusSaveCallApi, setIsStatusSaveCallApi] =
    useState(false); /* To call Submit APi Status from Status Component */
  const [validateStatus, setValidateStatus] = useState<boolean>(false);
  const [decPackOrderDetails, setDecPackOrderDetails] = useState<any>({});
  const [isSaveNext, setIsSaveNext] = useState<boolean>(true);
  const i18nMessages = {
    Information: I18n('ShippingOrders.Information'),
    Document: I18n('ShippingOrders.Document'),
    Status: I18n('ShippingOrders.Status'),
    DGPlus: I18n('ShippingOrders.DGPlus'),
    CreateDGD: I18n('ShippingOrders.CreateDGD'),
    AllDataAvailable: I18n('ShippingOrders.AllDataAvailable'),
    statusSavedSuccess: I18n('Common.SavedSuccessfully'),
    commonFieldIsRequired: I18n('Common.FieldIsRequired'),
    assignedTo: I18n('DecPackOrders.WorkOrder.AssignedTo'),
    title: I18n('DecPackOrders.WorkOrder.DGDCreationTitle'),
    ShowWO3Details: I18n('DecPackOrders.WorkOrder.ShowWO3Details'),
    WO3Details: I18n('DecPackOrders.WorkOrder.WO3Details'),
  };
  const [canEdit, setCanEdit] = useState(
    hasPermission(Permission.canEditDecPackOrders)
  );

  const [isMobile, setIsMobile] = useState(false);
  const [values, setValues] = useState<any>({
    id: 0,
    availableData: 0,
    status: 0,
  });
  const [details, setDetails] = useState<any>({
    createdBy: '',
    createdDate: '',
    updatedBy: '',
    updatedDate: '',
  });

  const [assigneeData, setAssigneeData] = useState<any>({
    assignedToId: '',
    assignedToName: '',
    assignNextToId: '',
    assignNextToName: '',
  });
  const [WO3Id, setWO3Id] = useState<number>(0);
  const [WO3ModalOpen, setWO3ModalOpen] = useState(false);
  const [isWO3Completed, setIsWO3Completed] = useState<boolean>(false);

  const [errors, setErrors] = useState({
    availableData: '',
  });

  let entityWorkOrderStatusList: Array<BaseModel> = [];
  const getStatusList = () => {
    entityWorkOrderStatusList = [defaultValue];
    DGDDescription.forEach((value: string, key: number) => {
      entityWorkOrderStatusList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getStatusList();

  const onAvailableChange = (data: any) => {
    setValues({
      ...values,
      availableData: Number(data?.value),
      status:
        Number(data?.value) === 2
          ? WorkOrderStatus.InProgress
          : WorkOrderStatus.Done,
    });
  };

  const openWO3DetailseModal = useCallback(() => {
    setWO3ModalOpen(true);
  }, []);

  const handleCloseForAdd = () => {
    setWO3ModalOpen(false);
  };

  const openDgplusScreen = async (copyMe: any) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      window.open(
        'http://dgplus2.nl/',
        '_blank',
        'toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=4000,height=4000'
      );
    } catch (err) {
      console.log('Error in copy');
    }
  };

  const InformationData = () => {
    return (
      <>
        <Grid item lg={2} md={8}>
          <FormControl>
            <InputLabel shrink htmlFor="bootstrap-input">
              {i18nMessages.CreateDGD}
            </InputLabel>
            <Box sx={{ mt: 3 }}>
              <Button
                variant="contained"
                onClick={() =>
                  openDgplusScreen(decPackOrderDetails.hazgoReference)
                }
                disabled={!canEdit}
              >
                {i18nMessages.DGPlus}
              </Button>
            </Box>
          </FormControl>
        </Grid>
        <Grid item lg={4} md={8}>
          <CustomizedSelect
            placeholder={defaultValue.label}
            options={entityWorkOrderStatusList}
            isSearchable={false}
            value={entityWorkOrderStatusList[values?.availableData]}
            displayLabel={i18nMessages.AllDataAvailable}
            handleChange={(newValue: BaseModel) => {
              onAvailableChange(newValue);
              if (newValue.value !== '0') {
                setErrors({
                  ...errors,
                  availableData: '',
                });
              }
            }}
            errorValue={true}
            errorMessage={errors.availableData}
            readOnly={!canEdit}
          ></CustomizedSelect>
        </Grid>

        {isWO3Completed ? (
          <Grid item lg={2} md={8}>
            <FormControl>
              <InputLabel shrink htmlFor="bootstrap-input">
                {i18nMessages.WO3Details}
              </InputLabel>
              <Box sx={{ mt: 3 }}>
                <Button
                  variant="contained"
                  disableElevation
                  onClick={openWO3DetailseModal}
                >
                  {i18nMessages.ShowWO3Details}
                </Button>
                <div className="">
                  <div className="content-block table-fix">
                    <WO3DetailsModal
                      isOpen={WO3ModalOpen}
                      handleClose={handleCloseForAdd}
                      wo3Id={WO3Id}
                    ></WO3DetailsModal>
                  </div>
                </div>
              </Box>
            </FormControl>
          </Grid>
        ) : (
          <></>
        )}
      </>
    );
  };

  const DocumentData = () => {
    return (
      <Grid xl={12} md={12}>
        <DocumentList
          permission={Permission.canEditDecPackOrders}
          addPermission={Permission.canAddDecPackOrders}
          deletePermission={Permission.canDeleteDecPackOrders}
          entityType={EntityTypeEnum.DecPackWODGD}
          allowedDocuments={1}
          paramsId={id}
          isHeaderHide={true}
          documentEntityType={DocumentEntity.WODGD}
          acceptedFiles={['.pdf']}
          acceptedFilesNames={['PDF']}
          categoryCode={MasterCategoryCode.DGDDocument}
        />
      </Grid>
    );
  };

  const validate = (fieldValues: any = values) => {
    let temp = { ...errors };
    if ('availableData' in fieldValues) {
      temp.availableData = fieldValues.availableData
        ? ''
        : i18nMessages.commonFieldIsRequired;
    }
    setErrors({
      ...temp,
    });
    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === '');
    }
  };

  // TODO: why this call
  const StatusAPICall = async () => {
    const finalValue = {
      workOrderNo: DnPWorkOrders.WO2,
      status: 3,
      decPackInitialOrderId: Number(id),
      woDate: new Date().toISOString(),
      activityOwner: ActivityOwner.BackOffice,
      // assignedToName:
      //   selectedAssignmentTo.value != '0' ? selectedAssignmentTo.label : '',
      // assignedToId:
      //   selectedAssignmentTo.value != '0' ? selectedAssignmentTo.value : '',
    };
    const apiUrl = new URL(
      environment.api.baseUrl + 'DecPackOrder/UpdateWOStatus'
    );
    try {
      const result: any = await http.put(apiUrl.toString(), finalValue);
      if (result) {
        //window.location.reload();
      }
    } catch (error: any) {}
  };

  // Get WO Details
  const getWOStatus = async (woNo: number) => {
    const apiUrl = new URL(
      environment.api.baseUrl + 'DecPackOrder/GetDecPackWO/' + id + '/' + woNo
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result && result.data) {
        setDetails({
          ...details,
          createdBy: result.data.createdBy,
          updatedBy: result.data.updatedBy,
          createdDate: result.data.createdDate,
          updatedDate: result.data.updatedDate,
        });

        setAssigneeData({
          ...assigneeData,
          assignedToId: result.data.assignedToId,
          assignedToName: result.data.assignedToName,
          assignNextToId: result.data.assignedNextToId,
          assignNextToName: result.data.assignedNextToName,
        });

        setIsMobile(result.data.isMobile);
        if (woNo === DnPWorkOrders.WO3) {
          setWO3Id(result.data.id);
        }
        return result.data;
      }
    } catch (error) {}
  };

  const getDGDWO = async () => {
    let decPackDetails = await getDecPackOrderDetails();
    setDecPackOrderDetails(decPackDetails);

    const apiUrl = new URL(
      environment.api.baseUrl + 'DecPackDGDCreationWorkOrder/' + id + '/' + woId
    );
    try {
      const result = await http.get(apiUrl.toString());
      var woStatusObj = await getWOStatus(DnPWorkOrders.WO2);
      setIsWO3Completed(woStatusObj?.isWO3Completed);

      if (result && result.data) {
        setValues({
          ...values,
          id: result.data.id,
          availableData:
            result.data.isAllDataAvailable == undefined
              ? 1
              : result.data.isAllDataAvailable == true
              ? 1
              : 2,
          status: woStatusObj?.status,
        });
        // const apiUrl = new URL(
        //   environment.api.baseUrl + `Users/${result.data.assignedToId}`
        // );
        // const resultForUser = await http.get(apiUrl.toString());
        // if (resultForUser && resultForUser.data) {
        //   // setSelectedAssignmentTo({
        //   //   label: `${resultForUser.data.firstName} ${resultForUser.data.lastName}`,
        //   //   value: resultForUser.data.id,
        //   // });
        // }
      } else {
        // HAZ-772 #6 Default yes to be selected "All data available to complete DGD?"
        // If yes selected then "Status" has to be Done
        setValues({
          ...values,
          availableData: 1,
          status: WorkOrderStatus.Done,
        });
      }
    } catch (error) {}
  };

  const getDecPackOrderDetails = async () => {
    const apiUrl = new URL(environment.api.baseUrl + `DecPackOrder/${id}`);
    try {
      const result = await http.get(apiUrl.toString());
      if (result && result.data) {
        return result.data;
      }
    } catch (error) {}
  };

  useEffect(() => {
    getDGDWO();
    getWOStatus(DnPWorkOrders.WO3);
  }, []);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setIsSaveNext(false);
    if (validate()) {
      setValidateStatus(true);
    } else {
      handleErrorStatusSave();
    }
  };

  const handleSuccessStatusSave = async () => {
    if (validate()) {
      let apiUrl;
      let result;

      const finalValue = {
        id: values.id,
        decPackWorkOrdersId: Number(woId),
        isAllDataAvailable: values.availableData === 1 ? true : false,
        decPackInitialOrderId: Number(id),
      };

      try {
        setIsStatusSaveCallApi(true);
        if (values.id == undefined || values.id == 0) {
          apiUrl = new URL(
            environment.api.baseUrl + 'DecPackDGDCreationWorkOrder'
          );

          result = await http.post(apiUrl.toString(), finalValue);
        } else {
          apiUrl = new URL(
            environment.api.baseUrl + 'DecPackDGDCreationWorkOrder/' + values.id
          );

          result = await http.put(apiUrl.toString(), finalValue);
        }

        if (result) {
          toast.success(i18nMessages.statusSavedSuccess);
          if (values.availableData === 1) {
            StatusAPICall();
          }
        }
      } catch (error: any) {}
    }
    handleErrorStatusSave();
  };

  /* Validate Status Dropdown Error call from Status Component */
  const handleErrorStatusSave = () => {
    setValidateStatus(false);
    setIsStatusSaveCallApi(false);
  };
  const onSaveNextClick = async (event: any) => {
    event.preventDefault();
    setValidateStatus(true);
    setIsSaveNext(true);
  };

  return (
    <>
      <div className="heading-section">
        <div className="heading-section-left">
          <Typography variant="h3" sx={{ mb: 3 }}>
            {i18nMessages.title}
          </Typography>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>
      </div>
      <div className="main-content-section">
        <FormAccordianSave
          title={i18nMessages.Information}
          className="inner-heading"
          details={InformationData()}
        ></FormAccordianSave>
        <FormAccordianSave
          title={i18nMessages.Document}
          className="inner-heading"
          details={DocumentData()}
        ></FormAccordianSave>
        <Grid item lg={12} md={12}>
          <DecpackStatus
            permission={Permission.canEditDecPackOrders}
            woId={DnPWorkOrders.WO2}
            activityOwner={ActivityOwner.BackOffice}
            orderId={id}
            statusValue={values.status}
            orderType={OrderType.DecPackOrder}
            isStatusSaveCallApi={isStatusSaveCallApi}
            validateStatus={validateStatus}
            handleSuccessStatusSave={handleSuccessStatusSave}
            handleErrorStatusSave={handleErrorStatusSave}
            isSaveNext={isSaveNext}
            isMobile={isMobile}
            workOrderNo={DnPWorkOrders.WO2}
            assigneeData={assigneeData}
            handleStatusChange={(value: number) =>
              setValues({ ...values, status: value })
            }
          />
        </Grid>
        <div className="btn-wrap form-btn-wrap">
          {canEdit ? (
            <Button
              color="primary"
              disableElevation
              variant="contained"
              onClick={onSaveNextClick}
            >
              {I18n('Common.SaveNext')}
            </Button>
          ) : (
            <></>
          )}
          &nbsp; &nbsp; &nbsp;
          {canEdit ? (
            <Button
              type="submit"
              color="primary"
              disableElevation
              variant="contained"
              onClick={handleSubmit}
            >
              {I18n('Common.Save')}
            </Button>
          ) : (
            <></>
          )}
        </div>
        <CreatedByandUpdatedBy
          createdBy={details.createdBy}
          createdDate={details.createdDate}
          updatedBy={details.updatedBy}
          updatedDate={details.updatedDate}
        ></CreatedByandUpdatedBy>
      </div>
    </>
  );
}

export default DecPackDGDWOCreation;
