import * as React from 'react';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';

interface ChipData {
  key: number;
  label: string;
}

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

export default function PricingChip(props: any) {
  const { handleClick, selectedId, name } = props;
  const [chipData, setChipData] = React.useState<readonly ChipData[]>([
    { key: 0, label: '[KG]' },
    { key: 1, label: '[DeliveryBaseRate]' },
    { key: 2, label: '[FuelBaseRate]' },
  ]);

  return (
    <Paper
      sx={{
        border: 0,
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
        listStyle: 'none',
        p: 0.5,
        m: 0,
        borderRadius: 0,
        boxShadow: 'none',
      }}
      component="ul"
    >
      {chipData.map((data) => {
        return (
          <ListItem key={data.key}>
            <Chip
              variant="outlined"
              size="small"
              label={data.label}
              onClick={() => handleClick(selectedId, name, data.label)}
            />
          </ListItem>
        );
      })}
    </Paper>
  );
}
