import { useCallback, useState, useEffect } from 'react';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { Stack, Button } from '@mui/material';
import I18n from 'utilities/i18n';
import environment from 'environment';
import http from '../../../src/utilities/httpService';

export default function PDFViewer(props: any) {
  const { docs, isPDFDoc = true } = props;
  const [files, setFiles] = useState<any[]>([]);
  const i18nMessages = {
    noDocuments: I18n('Common.NoDocuments'),
    noDocumentPreview: I18n('Common.NoDocumentPreview'),
    download: I18n('Common.Download'),
  };

  const saveDocument = useCallback(
    (documentUrl: string) => async () => {
      window.open(documentUrl);
    },
    []
  );

  useEffect(() => {
    if (docs && isPDFDoc && docs.length > 0) {
      getDGDFile(docs[0]);
    }
  }, [docs]);

  const getDGDFile = async (fileObj: any) => {
    const apiUrl = new URL(
      environment.api.baseUrl + 'Documents/DownloadFileAsync'
    );
    try {
      apiUrl.searchParams.set('$fileUrl', fileObj?.filePath);

      const result = await http.get(apiUrl.toString(), {
        responseType: 'blob',
      });
      if (result) {
        const file = new Blob([result.data], { type: 'application/pdf' }); //Build a URL from the file
        const fileURL = window.URL.createObjectURL(file); //Open the URL on new Window
        setFiles([
          {
            uri: fileURL,
            fileName: fileObj?.fileName,
          },
        ]);
      }
    } catch (error) {}
  };

  return isPDFDoc && docs.length > 0 && files?.length > 0 ? (
    <DocViewer
      documents={files}
      pluginRenderers={DocViewerRenderers}
      prefetchMethod="GET"
      theme={{
        disableThemeScrollbar: false,
      }}
      config={{
        header: {
          disableHeader: true,
          disableFileName: true,
          retainURLParams: true,
        },
        pdfZoom: {
          defaultZoom: 1.1,
          zoomJump: 0.2,
        },
      }}
    />
  ) : (
    <Stack height="100%" alignItems="center" justifyContent="center">
      {isPDFDoc ? (
        i18nMessages.noDocuments
      ) : docs.length > 0 ? (
        <>
          {i18nMessages.noDocumentPreview}
          {docs.map((d: any) => {
            return (
              <Button
                color="secondary"
                className="small-btn"
                disableElevation
                onClick={saveDocument(d.uri)}
                variant="contained"
                sx={{
                  top: 8,
                }}
              >
                {i18nMessages.download}
              </Button>
            );
          })}
        </>
      ) : (
        <></>
      )}
    </Stack>
  );
}
