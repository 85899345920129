import React, { useEffect, useState } from 'react';
import {
  Breadcrumbs,
  Link,
  Typography,
  Grid,
  Checkbox,
  FormControlLabel,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import CustomSelectPaginate from 'common/CustomSelect/CustomSelectPaginate';
import I18n from 'utilities/i18n';
import { useNavigate, useParams } from 'react-router-dom';
import RouteEnum from 'models/RouteEnum';
import FormAccordianSave from 'common/formControl/formAccordianSave';
import { BaseModel, OrderType, ActivityOwner } from 'models/pagination-model';
import CustomizedInputs from 'common/formControl/formControl';
import AddIcon from '@mui/icons-material/Add';
import environment from 'environment';
import http from '../../../utilities/httpService';
import { DnPWorkOrders } from 'models/deck-pack-orders';
import { toast } from 'react-toastify';
import { hasPermission } from 'utilities/protectedRoute';
import { Permission } from 'Permissions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import NumericInput from 'common/NumericInput/NumericInput';
import DecpackStatus from './DecpackStatus';
import CreatedByandUpdatedBy from 'common/CreatedByandUpdatedBy/CreatedByandUpdatedBy';

function DecPackWOMaterial() {
  const { woId, id } = useParams();
  let navigate = useNavigate();
  const defaultValue = {
    value: '0',
    label: I18n('Placeholders.Select'),
  } as BaseModel;
  // const [selectedAssignmentTo, setSelectedAssignmentTo] =
  //   useState(defaultValue);
  const [details, setDetails] = useState<any>({
    createdBy: '',
    createdDate: '',
    updatedBy: '',
    updatedDate: '',
  });

  const [assigneeData, setAssigneeData] = useState<any>({
    assignedToId: '',
    assignedToName: '',
    assignNextToId: '',
    assignNextToName: '',
  });
  const [isMobile, setIsMobile] = useState(false);
  const breadcrumbs = [
    <Typography key="3" color="text.primary">
      {I18n('Nav.Order')}
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={() => navigate(RouteEnum.DecPackOrdersList)}
        className="cpointer"
      >
        {I18n('Nav.DecPackOrders')}
      </Link>
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={() => navigate(RouteEnum.ViewDecPackOrders + '/' + id + '/1')}
        className="cpointer"
      >
        {I18n('ShippingOrders.WorkOrders')}
      </Link>
    </Typography>,
  ];

  const [canEdit, setCanEdit] = useState(
    hasPermission(Permission.canEditDecPackOrders)
  );

  const i18nMessages = {
    Title: I18n('DecPackOrders.WorkOrder.Material'),
    Document: I18n('DecPackOrders.WOMaterial.MaterialNeeded'),
    SpecialInstruction: I18n('DecPackOrders.WOMaterial.SpecialInstruction'),
    Assign: I18n('DecPackOrders.WOMaterial.Assign'),
    Instruction: I18n('DecPackOrders.WOMaterial.Instruction'),
    AssignTo: I18n('DecPackOrders.WOMaterial.AssignTo'),
    StrongMaterial: I18n('DecPackOrders.WOMaterial.StrongMaterial'),
    Openfield: I18n('DecPackOrders.WOMaterial.Openfield'),
    SpecificTypeOfPackaging: I18n(
      'DecPackOrders.WOMaterial.SpecificTypeOfPackaging'
    ),
    Packaging: I18n('DecPackOrders.WOMaterial.Packaging'),
    Quantity: I18n('DecPackOrders.WOMaterial.Quantity'),
    FieldRequired: I18n('DecPackOrders.WOMaterial.FieldRequired'),
    AddSuccess: I18n('Common.SavedSuccessfully'),
    SomethingWentWrong: I18n('DecPackOrders.WOMaterial.SomethingWentWrong'),
    UpdateSuccess: I18n('Notes.UpdatedSuccessfully'),
    ProductQuantityNotAvailable: I18n(
      'DecPackOrders.WOMaterial.ProductQuantityNotAvailable'
    ),
    duplicateProductsNotAllowed: I18n('Order.DuplicateProductsNotAllowed'),
    quantityAtAllLevels: I18n('Order.QuantityAtAllLevels'),
    quantityCannotBeZero: I18n('Order.QuantityCannotBeZero'),
    productAtAllLevels: I18n('DecPackOrders.WOMaterial.ProductAtAllLevels'),
  };

  const defaultAdditional: any = {
    page: 1,
  };

  const [isNew, setIsNew] = useState<boolean>(true);
  const [wOMaterialId, setWOMaterialId] = useState<number>();
  const [isSaveNext, setIsSaveNext] = useState<boolean>(true);
  const [rows, setRows]: any = useState([]);

  const [values, setValues] = useState<any>({
    decPackWorkOrdersId: 0,
    decPackInitialOrderId: 0,
    strongOverpackMaterial: false,
    openField: '',
    instructions: '',
    status: 0,
  });

  const [errors, setErrors] = useState({});

  const [isStatusSaveCallApi, setIsStatusSaveCallApi] =
    useState(false); /* To call Submit APi Status from Status Component */
  const [validateStatus, setValidateStatus] = useState<boolean>(false);
  /* validateStatus - To validate Status Dropdown from Status Component */

  const validate = (fieldValues: any = values) => {
    let temp = { ...errors };

    setErrors({
      ...temp,
    });
    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === '');
    }
  };

  const handleAddRow = (e: any) => {
    e.preventDefault();
    const item = {
      shippingMaterialId: 0,
      productCatalogueId: 0,
      productCatalougeLabel: '',
      quantity: 0,
    };
    setRows([...rows, item]);
  };

  const handlePackagingChange = (idx: any) => (e: any) => {
    const { label, value } = e;
    const finalrows: any = [...rows];
    finalrows[idx]['productCatalogueId'] = value;
    finalrows[idx]['productCatalougeLabel'] = label;
    setRows(finalrows);
  };

  const handleQuantityChange = (idx: any) => (e: any) => {
    const { value } = e.target;
    const finalrows: any = [...rows];
    finalrows[idx]['quantity'] = value;
    setRows(finalrows);
  };

  const checkDuplicateProducts = (a: any) => {
    return _.uniq(a.map((x: any) => x.productCode)).length !== a.length;
  };

  const submit = async () => {
    const specificPackagingList = [] as any;
    rows.map(function (item: any) {
      specificPackagingList.push({
        shippingMaterialId: item['shippingMaterialId'],
        productCatalogueId: +item.productCatalogueId,
        quantity: item['quantity'],
      });
    });

    let response = true;

    if (specificPackagingList && specificPackagingList.length > 0) {
      for (let i = 0; i < specificPackagingList.length; i++) {
        if (specificPackagingList[i].productCatalogueId === 0) {
          response = false;
          toast.error(i18nMessages.productAtAllLevels);
          break;
        }
        if (specificPackagingList[i].quantity === '') {
          response = false;
          toast.error(i18nMessages.quantityAtAllLevels);
          break;
        } else {
          if (+specificPackagingList[i].quantity === 0) {
            response = false;
            toast.error(i18nMessages.quantityCannotBeZero);

            break;
          }
        }
      }
    }

    if (!response) {
      handleErrorStatusSave(false);
      /* To set Validation and Submit APi Status False */
      return;
    }

    if (
      checkDuplicateProducts(
        specificPackagingList.map((x: any) => {
          return { productCode: x.productCatalogueId };
        })
      )
    ) {
      toast.error(i18nMessages.duplicateProductsNotAllowed);
      handleErrorStatusSave(false);
      /* To set Validation and Submit APi Status False */
      return;
    }

    const finalValue = {
      decPackWorkOrdersId: Number(woId),
      decPackInitialOrderId: Number(id),
      id: wOMaterialId,
      strongOverpackMaterial: values.strongOverpackMaterial,
      openField: values.openField,
      instructions: values.instructions,
      specificPackagingList,
    };
    setIsStatusSaveCallApi(true);
    if (isNew) {
      const apiUrl = new URL(environment.api.baseUrl + 'DecPackWOMaterial');
      try {
        const result: any = await http.post(apiUrl.toString(), finalValue);
        if (result) {
          toast.success(i18nMessages.AddSuccess);
          handleErrorStatusSave(false);
          /* To set Validation and Submit APi Status False */
          getDecPackWOMaterial();
        }
      } catch (error: any) {
        if (
          error.response.data &&
          error.response.data.message == 'ProductQuantityNotAvailable'
        ) {
          toast.error(i18nMessages.ProductQuantityNotAvailable);
        } else {
          toast.error(i18nMessages.SomethingWentWrong);
        }
        handleErrorStatusSave(false);
        /* To set Validation and Submit APi Status False */
      }
    } else {
      const apiUrl = new URL(
        environment.api.baseUrl + 'DecPackWOMaterial/' + wOMaterialId
      );
      try {
        const result: any = await http.put(apiUrl.toString(), finalValue);
        if (result) {
          toast.success(i18nMessages.UpdateSuccess);
          getDecPackWOMaterial();
          handleErrorStatusSave(false);
          /* To set Validation and Submit APi Status False */
        }
      } catch (error: any) {
        if (
          error.response.data &&
          error.response.data.message == 'ProductQuantityNotAvailable'
        ) {
          toast.error(i18nMessages.ProductQuantityNotAvailable);
        } else {
          toast.error(i18nMessages.SomethingWentWrong);
        }
        handleErrorStatusSave(false);
      }
    }
  };

  const handleSaveClick = async () => {
    setValidateStatus(true);
    setIsSaveNext(false);
  };

  const getProducts = async (search: string, pageNo: number) => {
    let response: any;
    const apiUrl = new URL(environment.api.baseUrl + 'ProductCatalogues');
    try {
      var searchExpression = search
        ? `productCode~like~${search} or name~like~${search} and status~=~1 `
        : 'status~=~1';
      apiUrl.searchParams.set('$filter', searchExpression);
      apiUrl.searchParams.set('$orderby', 'name');
      apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
      apiUrl.searchParams.set('$top', '10');
      apiUrl.searchParams.set('$orderbydirection', 'desc');
      const result = await http.get(apiUrl.toString());
      if (result) {
        response = {
          TotalRecords: result.data.totalCount,
          Values: result.data.values,
        } as any;
      } else {
        response = {
          TotalRecords: 0,
          Values: [],
        } as any;
      }
    } catch (error) {
      response = {
        TotalRecords: 0,
        Values: [],
      } as any;
    }
    return response;
  };

  const loadOptions = async (
    search: string,
    loadedOptions: any,
    { page }: any
  ) => {
    const response = await getProducts(search, page);
    const hasMore = Math.ceil(response.TotalRecords / 10) > page;

    return {
      options: response.Values.map((x: any) => {
        return {
          value: x.id.toString(),
          label: x.productCode + '-' + x.name,
        } as BaseModel;
      }),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const getWOStatus = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl +
        'DecPackOrder/GetDecPackWO/' +
        id +
        '/' +
        DnPWorkOrders.WO5
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result) {
        setDetails({
          ...details,
          createdBy: result.data.createdBy,
          updatedBy: result.data.updatedBy,
          createdDate: result.data.createdDate,
          updatedDate: result.data.updatedDate,
        });

        setAssigneeData({
          ...assigneeData,
          assignedToId: result.data.assignedToId,
          assignedToName: result.data.assignedToName,
          assignNextToId: result.data.assignedNextToId,
          assignNextToName: result.data.assignedNextToName,
        });

        setIsMobile(result.data.isMobile);
        return result.data.status;
      }
    } catch (error) {}
  };

  const getDecPackWOMaterial = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl + 'DecPackWOMaterial/' + woId + '/' + id
    );
    try {
      const result = await http.get(apiUrl.toString());
      var status = await getWOStatus();

      if (result && result.data) {
        setIsNew(false);
        setWOMaterialId(result.data.id);

        setValues({
          ...values,
          decPackWorkOrdersId: result.data.decPackWorkOrdersId,
          decPackInitialOrderId: result.data.decPackInitialOrderId,
          strongOverpackMaterial: result.data.strongOverpackMaterial,
          openField: result.data.openField,
          instructions: result.data.instructions,
          status,
        });

        //await getAssignedToUser(result.data.assignedTo);

        if (result.data.specificPackagingList !== undefined) {
          let packagingLst: any[] = [];
          result.data.specificPackagingList.map(function (item: any) {
            let packaging = {
              value: item.productCatalogueId.toString(),
              label: item.productCode,
            } as BaseModel;

            packagingLst.push({
              shippingMaterialId: item.shippingMaterialId,
              packaging,
              quantity: item.quantity,
              productCatalogueId: item.productCatalogueId.toString(),
              productCatalougeLabel: item.productCode,
            });
          });
          setRows(packagingLst);
        }
      } else {
        setIsNew(true);
        setValues({
          ...values,
          status,
        });
      }
    } catch (error) {}
  };

  const deleteAddProduct = (idx: number) => {
    let reducedArr = [...rows];
    reducedArr.splice(idx, 1);
    setRows(reducedArr);
  };

  useEffect(() => {
    getDecPackWOMaterial();
  }, []);

  /* Validate Status Dropdown Success call from Status Component */
  const handleSuccessStatusSave = async () => {
    if (validate()) {
      await submit();
    } else {
      handleErrorStatusSave(false);
      /* To set Validation and Submit APi Status False */
    }
  };

  /* Validate Status Dropdown Error call from Status Component */
  const handleErrorStatusSave = (data: any) => {
    setValidateStatus(false);
    setIsStatusSaveCallApi(false);
  };

  const SpecificTypeOfPackagingData = () => {
    return (
      <Grid item lg={12}>
        <Table className="basic-table" id="tab_logic" width="100%">
          <TableHead>
            <TableRow>
              <TableCell align="left">{i18nMessages.Packaging}</TableCell>
              <TableCell align="left">{i18nMessages.Quantity}</TableCell>
              <TableCell align="left">
                {canEdit ? (
                  <Button
                    className="icon-btn"
                    disableElevation
                    onClick={handleAddRow}
                  >
                    <AddIcon />
                  </Button>
                ) : (
                  <></>
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((item: any, idx: any) => (
              <TableRow key={idx}>
                <TableCell>
                  <CustomSelectPaginate
                    readOnly={item.shippingMaterialId != '0' || !canEdit}
                    additional={defaultAdditional}
                    value={{
                      label: item?.productCatalougeLabel,
                      value: item?.productCatalogueId,
                    }}
                    isDisplayLabelClass={true}
                    loadOptions={loadOptions}
                    handleChange={handlePackagingChange(idx)}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base: any) => ({
                        ...base,
                        zIndex: 9999,
                      }),
                    }}
                    isSearchable={true}
                    errorValue={true}
                    debounceTimeout={500}
                    isMultiSelect={false}
                  />
                </TableCell>
                <TableCell>
                  <NumericInput
                    id="quantity"
                    inputProps={{ min: 0 }}
                    handleChange={handleQuantityChange(idx)}
                    value={item?.quantity}
                    allowNegative={false}
                    disabled={!canEdit}
                  />
                </TableCell>

                {rows.length > 0 && canEdit && (
                  <TableCell>
                    <Button
                      color="info"
                      className="input-size-btn"
                      disableElevation
                      variant="contained"
                      onClick={() => deleteAddProduct(idx)}
                    >
                      <FontAwesomeIcon icon={faTrash} className="fa-md" />
                    </Button>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    );
  };

  const MaterialData = () => {
    return (
      <>
        <Grid container spacing={2} className="grid-wrap">
          <Grid item xs={6}>
            {/* <div>{i18nMessages.SpecificTypeOfPackaging}</div> */}
            {SpecificTypeOfPackagingData()}
          </Grid>
          <Grid item xs={6}></Grid>
        </Grid>
      </>
    );
  };

  const SpecialInstructionData = () => {
    return (
      <Grid item lg={12} md={12}>
        <CustomizedInputs
          margin="normal"
          displayLabel={i18nMessages.Instruction}
          value={values.instructions}
          id="instructions"
          inputType="instructions"
          handleChange={(event: any) =>
            setValues({ ...values, instructions: event.target.value })
          }
          readOnly={!canEdit}
        />
      </Grid>
    );
  };

  const onSaveNextClick = async (event: any) => {
    event.preventDefault();
    setValidateStatus(true);
    setIsSaveNext(true);
  };

  return (
    <>
      <div className="heading-section">
        <div className="heading-section-left">
          <Typography variant="h3" sx={{ mb: 3 }}>
            {i18nMessages.Title}
          </Typography>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>
      </div>
      <div className="main-content-section">
        <Grid container spacing={2} className="grid-wrap">
          <Grid item lg={12} md={12}>
            <FormAccordianSave
              title={i18nMessages.Document}
              className="inner-heading"
              details={MaterialData()}
              showActionButton={canEdit}
            ></FormAccordianSave>
          </Grid>
          <Grid item lg={12} md={12}>
            <FormAccordianSave
              title={i18nMessages.SpecialInstruction}
              className="inner-heading"
              details={SpecialInstructionData()}
              showActionButton={canEdit}
            ></FormAccordianSave>
          </Grid>

          <Grid item lg={12} md={12}>
            <DecpackStatus
              permission={Permission.canEditDecPackOrders}
              woId={DnPWorkOrders.WO5}
              orderId={id}
              statusValue={values.status}
              activityOwner={ActivityOwner.BackOffice}
              orderType={OrderType.DecPackOrder}
              isStatusSaveCallApi={isStatusSaveCallApi}
              validateStatus={validateStatus}
              handleSuccessStatusSave={handleSuccessStatusSave}
              handleErrorStatusSave={handleErrorStatusSave}
              isSaveNext={isSaveNext}
              isMobile={isMobile}
              workOrderNo={DnPWorkOrders.WO5}
              assigneeData={assigneeData}
              handleStatusChange={(value: number) =>
                setValues({ ...values, status: value })
              }
            />
            <div className="btn-wrap form-btn-wrap">
              {canEdit ? (
                <Button
                  color="primary"
                  disableElevation
                  variant="contained"
                  onClick={onSaveNextClick}
                >
                  {I18n('Common.SaveNext')}
                </Button>
              ) : (
                <></>
              )}
              &nbsp; &nbsp; &nbsp;
              {canEdit ? (
                <Button
                  type="submit"
                  color="primary"
                  disableElevation
                  variant="contained"
                  onClick={handleSaveClick}
                >
                  {I18n('Common.Save')}
                </Button>
              ) : (
                <></>
              )}
            </div>
            <CreatedByandUpdatedBy
              createdBy={details.createdBy}
              createdDate={details.createdDate}
              updatedBy={details.updatedBy}
              updatedDate={details.updatedDate}
            ></CreatedByandUpdatedBy>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default DecPackWOMaterial;
