const enLabels = {
  Auth: {
    Email: 'Email',
    Password: 'Password',
    LinkSent: 'Password reset link is sent to your registered email address.',
    NewPassword: 'New Password',
    ConfirmPassword: 'Confirm Password',
    NewPasswordRequired: 'New password is required',
    ConfirmPasswordRequired: 'Confirm password is required',
    PasswordMismatch: 'Password Mismatch',
    PasswordChanged: 'Password successfully changed.',
    ErrorOccurred: 'Error occurred.Please try again.',
    PasswordStrength:
      'Please enter a strong password containing digits , capital and small alphabets and having minimum 6 characters.',
    UserNotExist: 'Email does not exist',
    ResetPassword: 'Reset Password',
    BackToLogin: 'Back to Login',
    LoginToMyHazgo: 'Login to MyHazgo',
    SendResetLink: 'Send Reset Link',
    ForgotPassword: 'Forgot Password ?',
    EnterEmail: 'Please enter email',
    LoggedOut: 'Logged out successfully.',
    LinkExpired: 'Reset Link has expired.Please generate a new link.',
  },
  Zone: {
    AddZone: 'Add Zone',
    EditZone: 'Edit Zone',
    ZoneName: 'Zone Name',
    Country: 'Select Countries',
    Add: 'Add',
    Cancel: 'Cancel',
    Save: 'Save',
    AddSuccess: 'Zone has been added successfully.',
    ThisZoneIsDefault: 'You can not add the default zone name.',
    ZoneNameIsAlreadyThere: 'This zone name already exists.',
    UpdateSuccess: 'Zone has been updated successfully.',
  },
  Order: {
    DeleteOrder: 'Delete Order',
    DeleteMessage: 'Are you sure you want to delete order?',
    OrderDetail: 'Order Detail',
    OrderId: 'Order Id',
    OrderTitle: 'Order Title',
    OrderDate: 'Order Date',
    OrderType: 'Order Type',
    Supplier: 'Supplier',
    Total: 'Total(Incl VAT)',
    Status: 'Status',
    Search: 'Search using ProductCode',
    DownloadSuccess: 'Downloaded successfully',
    OrderDeleteMessage: 'Purchase order delete successfully',
    AddOrder: 'Add Order',
    SupplierName: 'Supplier Name',
    AddSuccess: 'Order has been added successfully.',
    OrderNumber: 'Order Number',
    ContactPerson: 'Order Contact Person',
    ServiceOptions: 'Goods/Services Delivery/Pickup',
    Pickup: 'Pickup',
    Delivery: 'Delivery',
    LicensePlate: 'LicensePlate',
    NumberOrQuantity: 'Number/Quantity',
    CostCenter: 'Cost Center',
    Service: 'Service',
    OrderDescription: 'Order Description',
    Description: 'Description',
    Go: 'Go',
    Reset: 'Reset',
    Cancel: 'Cancel',
    Save: 'Save',
    ProductCode: 'ProductCode',
    Name: 'Name',
    StandardPrice: 'StandardPrice',
    Id: 'Id',
    UnitPrice: 'Unit Price',
    TotalPrice: 'Total Price',
    SubTotalExlVat: 'Sub Total (EXL. VAT)',
    BtwTax: 'BTW(TAX)%',
    Btw: 'BTW',
    TotalIncBtw: 'Total (Inc BTW)',
    AtleastAddProduct: 'Atleast Add 1 Product',
    DuplicateProductsNotAllowed: 'Duplicate Products Not allowed',
    QuantityAtAllLevels: 'Quantity is required for all products',
    UnitPriceAtAllLevels: 'Unit Price is required for all Products',
    QuantityCannotBeZero: 'Quantity Cannot be Zero for any product',
    UnitPriceCannotBeZero: 'Unit Price Cannot be Zero for any product',
    OrderSettingsMustExist: 'Order Settings does not exist',
    CreatedBy: 'Created By',
    LastUpdated: 'Last Updated On',
    UpdateOrderSuccess: 'Order Updated Successfully',
    Approve: 'Approve',
    Reject: 'Reject',
    Deliver: 'Deliver Order',
    CancelOrder: 'Cancel Order',
    StatusUpdatedSuccessfully: 'Status Updated Successfully',
    Notes: 'Notes',
    DuplicateOrderNoExists: 'Duplicate Order No Exists',
    OrderSettingsDoesNotExist: 'Order Settings Does Not Exist',
    PublicTrackingTitle: 'Tracking',
    PublicTrackingReference: 'Tracking Reference',
    PublicTrackingDetails: 'Tracking Details',
  },
  Nav: {
    SignUp: 'Sign up',
    Login: 'Login',
    Logout: 'Logout',
    OrderSetting: 'Order Setting',
    OrderType: 'Order Type',
    Order: 'Order',
    Offer: 'Offer',
    Home: 'Home',
    APISample: 'API Sample',
    Link1: 'Link 1',
    Link2: 'Link 2',
    Crud: 'Crud',
    ServiceCatalogue: 'Service Catalogue',
    ProductCatalogue: 'Product Catalogue',
    Masters: 'Masters',
    TransportCatalogue: 'Transport Catalogue',
    Customers: 'Customers',
    Users: 'Users',
    Roles: 'Roles',
    AddRole: 'Add Role',
    EditRole: 'Edit Role',
    Zones: 'Zones',
    TariffCustoms: 'Tariff Customs',
    AddTariff: 'Add Tariff',
    AddZone: 'Add Zone',
    Dashboard: 'Dashboard',
    UnApprovedCustomers: 'Unapproved Customers',
    ProductPurchase: 'Product Purchase',
    Reference: 'Reference',
    Partners: 'Partners',
    PurchaseOrder: 'Purchase Order',
    PurchaseOrderSetting: 'Purchase Order Setting',
    ShippingOrders: 'Shipping Orders',
    ShippingOffers: 'Shipping Offers',
    Questionnaire: 'Questionnaires',
    Products: 'Products',
    Configuration: 'Configuration',
    Shipping: 'Shipping',
    Orders: 'Orders',
    Offers: 'Offers',
    DecPackOrders: 'Dec & Pack Orders',
    DecPackOffers: 'Dec & Pack Offers',
    MyProfile: 'My Profile',
    DnpWorkOrders: 'Dnp WorkOrders',
    WorkOrders: 'Work Orders',
    ShippingWorkOrders: 'Shipping WorkOrders',
    Airline: 'Airline',
    State: 'State',
    Restrictions: 'Restrictions',
    Labels: 'Labels',
    ExchangeRates: 'Exchange Rates',
    UserManagement: 'User Management',
  },
  Users: {
    Users: 'Users',
  },
  OrderSetting: {
    UpdateFormat: 'Update Order No Format',
    OrderNumberFormat: 'Order No Format',
    OrderNoStartingNumber: 'Order No Starting Number',
    SetBTW: 'Set BTW (TAX) %',
    Save: 'Save',
    UpdateSuccess: 'Order Setting has been updated successfully.',
  },
  OrderType: {
    OrderTypeMustBeUnique: 'This order type is already exist',
    OrderTypeMustBeUniqueError: 'OrderTypeMustBeUnique',
    ErrorOccurred: 'Error occurred while updating the record',
    updateSuccess: 'Order type has been updated successfully.',
    ZeroSet: 'Zero or minus amount is not allowed',
    OrderTypeDetails: 'Order Type Details',
    AddOrderType: 'Add Order Type',
    OrderTypes: 'Order Type',
    AddSuccess: 'Order type has been added successfully.',
    ApprovalLevel: 'Approval Levels',
    ThresholdApproval: 'Threshold Approval',
    ThresholdAmount: 'Threshold Amount',
    SameUser: 'Please select another user',
    AmountError: 'Amount should be greater than above threshold value',
    Level: 'Level',
    Status: 'Status',
    LastUpdatedBy: 'Last Updated By',
    LastUpdatedOn: 'Last Updated On',
    Action: 'Action',
    AssignUser: 'Assign User',
    DeleteModal: {
      Message: 'You want to remove this order type?',
      Heading: 'Are you sure?',
      Cancel: 'Cancel',
      Ok: 'OK',
    },
  },
  TransportCatalogue: {
    ErrorOccurred: 'Error occurred while updating the record',
    DeleteSuccess: 'Transport catalogue has been deleted successfully.',
    DeleteConfirmation:
      'Are you sure you want to delete this transport catalogue?',
    TransCode: 'Trans Code',
    Category: 'Category',
    FromZone: 'From Zone',
    ToZone: 'To Zone',
    TransportType: 'Transport Type',
    DeleteTransportCatalogue: 'Delete Transport Catalogue',
    AddTransportCatalogue: 'Add TransportCatalogue',
    TransportCode: 'Transport Code',
    Details: 'Transport Catalogue Details',
    airPricing: 'Air Pricing',
    roadPricing: 'Road Pricing',
    trainPricing: 'Train Pricing',
    shipPricing: 'Ship Pricing',
    baseRate: 'Base Rate',
    deliveryBaseRate: 'Delivery Base Rate',
    fuelBaseRate: ' Fuel Base Rate',
    Kg: 'KG',
    DeliveryPrice: 'Delivery Price ',
    FuelPrice: 'Fuel Price',
    ToZoneCounries: 'To Zone Countries',
    Countries: 'Countries',
    AddSuccess: 'Transport catalogue has been added successfully.',
    FromZoneName: 'From Zone Name',
    ToZoneName: 'To Zone Name',
    BothZoneSame: 'Both Zones are same.',
    Notes: 'Notes',
    Documents: 'Documents',
    Detail: 'Details',
    UpdateSuccess: 'Transport catalogue has been updated successfully.',
    UniqueTransportCode: 'Transport Code must be unique.',
    UniqueZoneCombination: 'This combination is already exist.',
    OwnPricing: 'Own Pricing',
  },

  ServiceCatalogues: {
    ServiceCode: 'Service Code',
    Category: 'Categories',
    ServiceName: 'Service Name',
    Description: 'Description',
    StandardPrice: 'Standard Price',
    LastCostBuyPrice: 'Last Cost / Buy Price',
    ShippingStandardPriceFrom: 'Shipping Standard Price (From)',
    ShippingStandardPriceTo: 'Shipping Standard Price (To)',
    DecPackStandardPriceFrom: 'DecPack Standard Price (From)',
    DecPackStandardPriceTo: 'DecPack Standard Price (To)',
    AddServiceCatalouge: 'Add Service Catalogue',
    AddSuccess: 'Service catalogue has been added successfully.',
    UpdateSuccess: 'Service catalogue has been updated successfully.',
    DeleteSuccess: 'Service catalogue has been deleted successfully.',
    DeleteConfirmation:
      'Are you sure you want to delete this service catalogue?',
    PricingType: 'Pricing Type',
    Details: 'Service Catalogue Details',
    Tabs: {
      Details: 'Details',
      SpecialPricing: 'Special Price',
      AllowedOrderCategory: 'Allowed Order Category',
      Documents: 'Documents',
      Notes: 'Notes',
    },
    ShippingStandardPrice: 'Shipping Standard Price',
    DecPackStandardPrice: 'DecPack Standard Price',
    Department: 'Department',
  },
  LabelMaster: {
    Shipper: 'Shipper',
    Consignee: 'Consignee',
    Address: 'Address',
    Address2: 'Address 2',
    PostalCode: 'Postal Code',
    EmergencyPhoneNumber: 'Emergency Phone',
    City: 'City',
    Country: 'Country',
    DeleteConfirmation: 'Are you sure you want to delete this label?',
    LabelSaveSuccess: 'Label detail saved successfully',
    LabelUpdateSuccess: 'Label updated successfully',
    LabelNotExist: 'Label does not exist',
    LabelSaveFail: 'Something went wrong, please try again later!',
    AddLabel: 'Add Label',
    EditLabel: 'Edit Label',
    ShipperDetail: 'Shipper Details',
    ConsigneeDetail: 'Consignee Details',
    OtherDetail: 'Other Details',
    Validation: {
      CountryRequired: 'Country is required.',
      CityRequired: 'City is required.',
      PostalCodeRequired: 'Postal Code is required.',
      PostalCodeMaxLength: 'Maximum 20 characters allowed for Postal Code.',
      AddressRequired: 'Address is required.',
      AddressMaxLength: 'Maximum 250 characters allowed for Address.',
      ConsigneeRequired: 'Consignee is required',
      ConsigneeMaxLength: 'Maximum 250 characters allowed for Consignee',
      ShipperRequired: 'Shipper is required',
      ShipperMaxLength: 'Maximum 250 characters allowed for Shipper',
      ConditionalRequired: 'Enter atleast one field value',
    },
  },
  ExchangeRate: {
    FromCurrency: 'From Currency',
    ToCurrency: 'To Currency',
    Rate: 'Rate',
    DeleteConfirmation: 'Are you sure you want to delete exchange rate?',
    DeleteSuccess: 'Exchange rate deleted successfully',
    ExchangeRateSaveSuccess: 'Exchange rate saved successfully',
    ExchangeRateUpdateSuccess: 'Exchange rate updated successfully',
    ExchangeRateNotExist: 'Exchange rate does not exist',
    ExchangeRateSaveFail: 'Something went wrong, please try again later!',
    AddExchangeRate: 'Add Exchange Rate',
    EditExchangeRate: 'Edit Exchange Rate',
    Validation: {
      FromCurrencyRequired: 'From currency is required',
      ToCurrencyRequired: 'To currency is required',
      RateRequired: 'Rate is required',
      MappingAlreadyExist: 'The exchange rate already exists!',
    },
  },
  CustomerLocation: {
    Country: 'Country',
    City: 'City',
    ZipCode: 'Postal Code',
    Address: 'Address',
    Address2: 'Address 2',
    IsMainAddress: 'Is Main Address',
    AddLocation: 'Add Location',
    EditLocation: 'Edit Location',
    Search: 'Search',
    Street: 'Street',
    DestinationCompany: 'Destination Company',
    PickupCompany: 'Pickup Company',
    DeleteConfirmation: 'Are you sure you want to delete this location?',
    Validation: {
      CountryRequired: 'Country is required.',
      CityRequired: 'City is required.',
      ZipCodeRequired: 'Postal Code is required.',
      ZipCodeMaxLength: 'Maximum 20 characters allowed for Postal Code.',
      AddressRequired: 'Address is required.',
      AddressMaxLength: 'Maximum 250 characters allowed for Address.',
      DestinationCompanyRequired: 'Destination Company is required',
      DestinationCompanyMaxLength:
        'Maximum 250 characters allowed for Destination Company',
      StreetCompanyRequired: 'Address is required',
      StreetCompanyMaxLength: 'Maximum 250 characters allowed for Address',
      PickupCompanyRequired: 'Pickup Company is required',
      PickupCompanyMaxLength:
        'Maximum 250 characters allowed for Pickup Company',
    },
  },
  Document: {
    Document: 'Document',
    Language: 'Language',
    AddedBy: 'Added By',
    AddedOn: 'Added On',
    ViewLevel: 'View Level',
    AddDocument: 'Add Document',
    SaveSuccessMessage: 'Document saved successfully',
    DeleteConfirmation: 'Are you sure you want to delete this document?',
    FileSizeExceeded: 'File is too big. Size limit is 10 MB.',
    FileNotDownloaded: 'Unable to download.Please try again.',
    Validation: {
      DocumentTagRequired: 'Document Tag is required.',
      DocumentNameRequired: 'Document Name is required.',
      DocumentNameMaxLength: 'Maximum 50 characters allowed for Document Name.',
      LanguageRequired: 'Language is required.',
      ViewLevelRequired: 'View Level is required.',
      FileRequired: 'File is required.',
    },
  },
  ViewLevel: {
    Label: 'View Level',
    Options: {
      InternalManagement: 'Internal(Management)',
      InternalOperational: 'Internal(Operational)',
      InternalAll: 'Internal(All)',
      External: 'External',
    },
    ExternalInfoMessage: 'This document will be displayed to customers.',
  },
  Common: {
    IsMainAddressError: 'Already data with IsMainAddress is selected.',
    Driver: 'Driver',
    DecNPack: 'Dec & Pack',
    ToDo: 'ToDo',
    InProgress: 'InProgress',
    Export: 'Export',
    SingleUse: 'SingleUse',
    Reusable: 'Reusable',
    Pickup: 'Pickup',
    Delivery: 'Delivery',
    Approved: 'Approved',
    Pending: 'Pending',
    Actions: 'Actions',
    Status: 'Status',
    Active: 'Active',
    Inactive: 'Inactive',
    Rejected: 'Rejected',
    Yes: 'Yes',
    No: 'No',
    Reset: 'Reset',
    Apply: 'Apply',
    ErrorOccurred: 'Error Occurred',
    Operations: 'Operations',
    Accounting: 'Accounting',
    Helpdesk: 'Helpdesk',
    Details: 'Details',
    Save: 'Save',
    Filter: 'Filter',
    Add: 'Add',
    FieldRequired: 'This field is required.',
    InvalidEmail: 'Invalid email address entered',
    InvalidPhone: 'Invalid phone number entered',
    EmployeeCode: 'EmployeeCode',
    Email: 'Email',
    PhoneNumberVal: 'PhoneNumber',
    DeleteRole: 'You want to remove this role?',
    Language: {
      EN: 'EN',
      FR: 'FR',
      NL: 'NL',
    },
    Cancel: 'Cancel',
    SavedSuccessfully: 'Saved Successfully',
    En: 'English',
    NL: 'Nederlands',
    FR: 'Français',
    All: 'All',
    Internal: 'Internal',
    Search: 'Search',
    SearchbyName: 'Search by Name',
    Department: 'Department',
    NoRecords: 'No Records',
    NoDocuments: 'No Documents',
    Download: 'Download',
    NoDocumentPreview: 'Document preview is not available!',
    FieldIsRequired: 'This field is required.',
    InvalidDate: 'Invalid Date',
    InvalidTime: 'Invalid Time',
    Standard: 'Standard',
    Special: 'Special',
    SomethingWentWrong: 'Something went wrong,please try againg later.',
    Edit: 'Edit',
    Delete: 'Delete',
    Allowed: 'Allowed',
    Specific: 'Specific',
    SpecificCategories: 'Specific Categories',
    InvalidQuantityDelete: `Available quantity will be affected by this record. so, you can't delete this record.`,
    EnteredQuantityIsNotValid: 'Entered Quantity is not valid.',
    UniqueServiceCode: 'Service Code must be unique.',
    UniqueServiceName: 'Service Name must be unique.',
    InvalidOtp: 'Invalid Otp Entered',
    OtpExpired: 'Otp Expired.Please generate new Otp',
    AllowedOrderCatagorySaveSuccess:
      'Allowed order category has been saved successfully.',
    Cancelled: 'Cancelled',
    Delivered: 'Delivered',
    PartiallyDelivered: 'Partially Delivered',
    Draft: 'Draft',
    Previous: 'Previous',
    Next: 'Save & Next',
    Submit: 'Submit',
    New: 'New',
    InProcess: 'In Process',
    Done: 'Done',
    OrderCompleted: 'Order Completed',
    Invoiced: 'Invoiced',
    OnHold: 'OnHold',
    SentForApproval: 'Sent For Approval',
    InvoiceApproved: 'Invoice Approved',
    Closed: 'Closed',
    fileSizeExceedErr: 'File is too big. Size limit is 10 MB.',
    UploadFileExceedError: 'File is too big. Size limit is #maxFileSize#.',
    ImageResolutionExceedError: 'Please upload image with 600 X 500 resolution',
    fileTypeErr: 'Invalid file format please upload a valid file.',
    UploadFileFormatError:
      'Invalid file format please upload a valid #acceptedFiles# file.',
    DuplicateRecord: 'Record already exist',
    IgnoreDateFilter: 'Ignore Date Filter',
    IgnorePriceFilter: 'Ignore Price Filter',
    ZeroValidation: 'Please enter value greater than 0.',
    MaxAllowed: 'Max Documents allowed are ',
    DocumentTag: 'Document Tag',
    NoImagesSelectedToUpload: 'No Images selected for Upload.',
    ImageSaveSuccess: 'Image have been saved successfully.',
    EditPicture: 'Edit Picture',
    WOAlreadyInProgress: 'Another Work Order Already in Progress',
    Booking: 'WO-Booking',
    DGDCreation: 'WO-DGD Creation',
    WODocumentation: 'WO-Documentation',
    WOPickup: 'WO-Pickup',
    Inbound: 'WO-Inbound',
    Packaging: 'WO-Packaging',
    Outbound: 'WO-Outbound',
    WODelivery: 'WO-Delivery',
    DocumentCheck: 'WO-Document check',
    PhysicalCheck: 'WO-Physical check',
    Past: 'Past',
    Today: 'Today',
    Future: 'Future',
    DateRange: 'Date Range',
    Shipping: 'Shipping',
    RequiredField: 'Enter required fields',
    CitySavedSuccessfully: 'Added new city',
    SaveNext: 'Save and Next',
    FileUploadMessage:
      'Minimum logo size should be 300x300 px, & maximum file size should not be more then 2MB',
    Web: 'Web',
    Mobile: 'Mobile',
    AwaitingAssignment: 'Awaiting Assignment',
    Attentions: {
      HighPriority: 'High Priority',
      Secure: 'Secure',
      AwaitingInfo: 'Awaiting Info',
      OnHold: 'On Hold',
      AwaitingInstructionFromClient: 'Awaiting instructions from client',
    },
    UpdateRequiredField: 'Please update the required fields.',
    NA: 'NA',
    ClientNotAvailable: 'Client Not Available',
    ClientNotReady: 'Client Not Ready to sign',
    Signature: 'Signature',
    SignedOffBy: 'Signed Off By',
    Update: 'Update',
    SentBy: 'Sent By',
    ApprovedBy: 'Approved By',
    Own: 'Own',
    Location: 'Location',
    SameAsAbove: 'Same As Above',
    ApproveOrder: 'Approve Order',
    SendToCustomer: 'Send To Customer',
    InvalidFileFormat: 'Invalid File Format',
  },
  Approval: {
    OrderOwner: 'Order Owner',
    SendForApproval: 'Send For Approval',
    SentForApproval: 'Sent For Approval',
    Approver: 'Approver',
    Approve: 'Approve',
    Approved: 'Approved',
    SentBy: 'Sent By',
    ApprovedBy: 'Approved By',
    SendForApprovalConfirmation:
      'Are you sure you want to send order for approval?',
    SendForApprovalConfirmationDetails: 'Send Order for Approval',
    ApprovalConfirmMessage: 'Are you sure you want to approve?',
    ApprovalConfirmTitle: 'Order Approval',
    TotalProfitLoss: 'Total profit/loss',
    VAT: 'VAT',
    VATPercent: 'VAT Percent',
    VATDetails: 'VAT Details',
    InvalidVatPercent:
      'Please update the VAT % for this customer to either 0 or 21 % in customer module',
  },
  Notes: {
    Description: 'Note',
    IsInternalOnly: 'Is Internal Only?',
    AddedOn: 'Added On',
    AddedBy: 'Added By',
    DeletedSuccessfully: 'Deleted Successfully',
    DeleteMessage: 'Are you sure you want to delete Note?',
    DeleteNote: 'Delete Note',
    AddNote: 'Add Note',
    EditNote: 'Edit Note',
    Save: 'Save',
    Cancel: 'Cancel',
    SavedSuccessfully: 'Saved Successfully',
    UpdatedSuccessfully: 'Updated Successfully',
    ErrorOccurred: 'Error Occurred',
    FieldRequired: 'Field is required',
  },
  CreditNote: {
    Label: 'Credit Note',
    Description: 'Description',
    Note: 'Note',
    Amount: 'Amount',
    DeletedSuccessfully: 'Deleted Successfully',
    DeleteMessage: 'Are you sure you want to delete Note?',
    DeleteNote: 'Delete Note',
    AddNote: 'Add Note',
    EditNote: 'Edit Note',
    Save: 'Save',
    Cancel: 'Cancel',
    SavedSuccessfully: 'Saved Successfully',
    UpdatedSuccessfully: 'Updated Successfully',
    ErrorOccurred: 'Error Occurred',
    FieldRequired: 'Field is required',
    DescriptionFieldRequired: 'Description is required',
    AmountFieldRequired: 'Amount is required',
  },
  ContactPerson: {
    Name: 'Name',
    FirstName: 'First Name',
    LastName: 'Last Name',
    Function: 'Function',
    Phone: 'Phone',
    Mobile: 'Mobile',
    Email: 'Email',
    SiteInformation: 'Site Information',
    SystemAccess: 'System Access',
    Status: 'Status',
    DeletedSuccessfully: 'Deleted Successfully',
    DeleteMessage: 'Are you sure you want to delete Contact Person?',
    DeleteContactPerson: 'Delete Contact Person',
    ErrorOccurred: 'Error Occured',
    ContactPersonCannotBeDeleted: 'Contact Person cannot be Deleted',
    SavedSuccessfully: 'Saved Successfully',
    AddContactPerson: 'Add Contact Person',
    FieldRequired: 'Field is required',
    InvalidEmailAddress: 'Invalid email address entered',
    AccessLevel: 'Access Level',
    Location: 'Location',
  },
  Placeholders: {
    SelectAssignmentType: 'Select Type',
    SelectPartner: 'Select Partner',
    selectProductType: 'Select Type',
    SelectStatus: 'Select Status',
    Select: 'Select',
    SelectDepartment: 'Select Department',
    SelectCustomer: 'Select Customer',
    SelectLanguage: 'Select Language',
    SelectUser: 'Select User',
    SelectCategory: 'Select Category',
    SelectValidity: 'Select Validity',
    SelectOrder: 'Select Order',
    SelectZone: 'Select Zone',
    SelectTransportType: 'Select Transport Type',
    SelectLocation: 'Select Location',
    SelectContact: 'Select Contact',
    SelectService: 'Select Service',
    SelectRejection: 'Select Rejection Reason',
    SelectShippingType: 'Select Shipping Type',
    SelectOfferStatus: 'Select Offer Status',
    SelectTrackingType: 'Select Type',
    SelectDecPackType: 'Select DecPack Type',
    SelectPartnerType: 'Select Partner Type',
    SelectTracking: 'Select Tracking',
    SelectLabelType: 'Select Label Type',
    SelectLayoutType: 'Select Layout Type',
    SelectConsignee: 'Select Consignee',
    SelectShipper: 'Select Shipper',
    SelectDestinationCompany: 'Select Destination Company',
    SelectPickupCompany: 'Select Pickup Company',
  },
  UserList: {
    EmpCode: 'Emp Code',
    Name: 'Name',
    Role: 'Role',
    Roles: 'Roles',
    Department: 'Department',
    Mobile: 'Mobile',
    Email: 'Email',
    Language: 'Language',
    Status: 'Status',
    DeleteModal: {
      Message: 'You want to remove this user?',
      Heading: 'Are you sure?',
      Cancel: 'Cancel',
      Ok: 'OK',
    },
  },
  UsersAdd: {
    AddUsers: 'Add Users',
    AdditionalDetails: 'Additional Details',
    UserDetails: 'User Details',
    EmpCode: 'Emp Code',
    Name: 'Name',
    Email: 'Email',
    Role: 'Role',
    Department: 'Department',
    Mobile: 'Mobile',
    PreferredLanguage: 'Preferred Language',
    Status: 'Status',
    Cancel: 'Cancel',
    Add: 'Add',
    EmployeeCode: 'Employee Code',
    PhoneNumber: 'Phone Number',
    DepartmentSelect: {
      Shipping: 'Shipping',
      DecPack: 'DecPack',
      Both: 'Both',
    },
    FirstName: 'First Name',
    LastName: 'Last Name',
    Phone: 'Phone',
    UserType: 'User Type',
    UserCreatedSuccss: 'User created successfully',
  },
  UsersEdit: {
    Save: 'Save',
    UserDetails: 'User details',
    PreferredLanguage: 'Preferred language',
    Status: 'Status',
    RolesAssigned: 'Role Assigned Successfully',
    RolesDeleted: 'Role deleted successfully',
    RolesDetails: 'Role Details',
    UserUpdatedSuccss: 'User updated successfully',
  },
  UsersProfile: {
    MyProfile: 'My Profile',
    PreferredLanguage: 'Preferred language',
    Status: 'Status',
    ProfilePicture: 'Profile Picture',
    FirstName: 'First Name',
    LastName: 'Last Name',
    Phone: 'Phone',
    Email: 'Email',
    Mobile: 'Mobile',
    ProfileUpdated: 'Profile updated successfully',
    PasswordUpdatedLoginAgain:
      'Password has been successfully changed. Please log in with changed password',
  },
  Customer: {
    Tabs: {
      Details: 'Details',
      ContactPerson: 'Contact Person',
      Location: 'Addressbook',
      Documents: 'Documents',
      Notes: 'Notes',
      SpecialPricing: 'Special Pricing',
      PricingType: 'Pricing Type',
    },
    Name: 'Name',
    VatDeferment: 'Vat Deferment Account',
    VatNumber: 'Vat Number',
    EORI: 'EORI',
    Address: 'Address',
    PCCity: 'PC, City',
    Country: 'Country',
    Tag: 'Department',
    Approved: 'Approved',
    Status: 'Status',
    SearchbyName: 'Search by Name',
    Logo: 'Logo',
    Details: 'Details',
    FinancialBasic: 'Financial Basic',
    ContactDetails: 'Contact Details',
    Invoicing: 'Invoicing',
    PostalCode: 'Postal Code',
    City: 'City',
    Website: 'Website',
    SameAsVat: 'Same as VAT',
    IsDebtor: 'Is Debtor',
    GeneralTelephone: 'General Telephone',
    GeneralEmail: 'General Email',
    InvoiceAddressDifferent: 'Invoice Address - Is Different?',
    InvoiceEmail: 'Invoice Email',
    InvoicingCycle: 'Invoicing Cycle',
    BillingCurrency: 'Billing Currency',
    CustomerType: 'Customer Type',
    VatPercentage: 'VAT %',
    Shipping: 'Shipping',
    DecPack: 'DecPack',
    Both: 'Both',
    IsDifferent: 'Is Different',
    CustomerName: 'Customer Name',
    InvoicePostalCode: 'Invoice Postal Code',
    InvoiceCity: 'Invoice City',
    InvoiceCountry: 'Invoice Country',
    InvoiceAddress: 'Invoice Address',
    Approve: 'Approve',
    Reject: 'Reject',
    UpdatedSuccessfully: 'Updated Successfully',
    NoRowsSelected: 'No Rows Selected',
    ResendForApproval: 'Resent For Approval Successfully',
    Validation: {
      CustomerNameRequired: 'Customer Name is required.',
      CustomerNameMaxLength: 'Maximum 50 characters allowed for Customer Name.',
      AddressMaxLength: 'Maximum 250 characters allowed for Address.',
      PostalCodeMaxLength: 'Maximum 20 characters allowed for Postal Code.',
      InvoiceAddressMaxLength:
        'Maximum 250 characters allowed for Invoice Address.',
      InvoicePostalCodeMaxLength:
        'Maximum 20 characters allowed for Invoice Postal Code.',
      WebsiteMaxLenght: 'Maximum 255 characters allowed for Website.',
      VatNumberUnique: 'Vat Number must be unique.',
      VatDefermentMaxLength:
        'Maximum 30 characters allowed for Vat Deferment Account.',
      VatNumberMaxLength: 'Maximum 30 characters allowed for Vat Number.',
      EORIMaxLength: 'Maximum 30 characters allowed for EORI.',
      TelephoneMaxLength: 'Maximum 20 characters allowed for TelePhone.',
      StatusRequired: 'Status is required.',
      EmailMaxLength: 'Maximum 255 characters allowed for Email.',
      InvalidEmail: 'Invalid email address entered',
      InvoiceEmailMaxLength:
        'Maximum 255 characters allowed for Invoice Email.',
      CustomerFirstNameRequired: 'Customer First Name is required.',
      CustomerFirstNameMaxLength:
        'Maximum 50 characters allowed for Customer First Name.',
      CustomerLastNameRequired: 'Customer Last Name is required.',
      CustomerLastNameMaxLength:
        'Maximum 50 characters allowed for Customer Last Name.',
      InvalidVatPercent: 'Please update the VAT % to either 0 or 21 %',
      InvalidCustomerSpecialPricing:
        'Special Pricing for Entity already exists.',
    },
    AddCustomer: 'Add Customer',
    ViewCustomer: 'Customer Details',
    CustomerDetails: 'Customer Details',
    DeletedSuccessfully: 'Deleted Successfully',
    DeleteMessage: 'Are you sure you want to delete customer?',
    DeleteCustomer: 'Delete Customer',
    ErrorOccurred: 'Error Occurred',
    FirstName: 'First Name',
    LastName: 'Last Name',
    ToolTips: {
      ResendForApproval: 'Resent For Approval',
    },
    SearchAddress: 'Search Address',
  },
  RoleList: {
    Name: 'Name',
    Status: 'Status',
    DeleteMessage: 'Are you sure you want to delete role?',
    DeleteRole: 'Delete Role',
  },
  Role: {
    Module: 'Module',
    View: 'View',
    Add: 'Add',
    Edit: 'Edit',
    Delete: 'Delete',
    Approver: 'Approver',
  },
  TariffCustomsList: {
    DeleteMessage: 'Are you sure, you want to delete tariff record?',
    DeleteTariffTitle: 'Delete Tariff Detail',
    choose_tariff_file: 'Choose Tariff File',
    Cancel: 'Cancel',
    Save: 'Save',
  },
  AddTariff: {
    cnKey: 'CN Key',
    cn: 'Commodity Code',
    cnLevel: 'Level',
    t_SU_SU: 'T SU SU',
    description: 'Description',
    parentCNName: 'Parent CN(Custom Number)',
    parent: 'Parent',
    status: 'Status',
  },
  Questionnaire: {
    questionnaireDetail: 'Questionnaire Details',
    add: 'Add',
    subject: 'Subject',
    description: 'Description',
    Language: 'Language',
    save: 'Save',
    cancel: 'Cancel',
    AddQuesionnaire: 'Add Questionnaire',
    EditQuesionnaire: 'Edit Questionnaire',
    preferredLanguage: 'Preferred Language',
    status: 'Status',
    questionnaireAddMsg: 'Questionnaire Added successfully',
    questionnaireUpdateMsg: 'Questionnaire Updated successfully',
    fieldRequired: 'This field is required',
    chnageStatusErrMsg:
      'You cannnot change the status, because its associated with any workprder',
    OptionsFieldErrorMsg:
      'Please enter all options properly, else remove from screen.',
    OptionsFieldMin2Err:
      'You should enter minimum 2 options, in case of Dropdown Or Multiple Choice.',
    DeleteMessage: 'Are you sure you want to delete Questionnaire?',
    DeleteQuestionnaireTitle: 'Delete Questionnaire',
    DeletionSuccessMsg: 'Questionnaire deleted successfully',
    CopySuccessMsg: 'Questionnaire copied successfully',
    CopyQuestionnaireTitle: 'Copy Questionnaire',
    CopyQuestionnaireMsg:
      'Are you sure, you want to create duplicate copy of Questionnaire?',
    QueDeletionSuccessMsg: 'Question deleted successfully',
    DeleteQuestionConfirmationMessage:
      'Are you sure you want to delete Question?',
    DeleteQuestionConfirmationTitle: 'Delete Question',
    PrepareQuestionnaire: 'Prepare Questionnaire',
    AddQuesion: 'Add Question',
    EditQuesion: 'Edit Question',
    AddYourQue: 'Add Your Question',
    AnswerType: 'Answer Type',
    Options: 'Options',
    QuestionAddSuccessMsg: 'Question Added Successfully',
    QuestionUpdateSuccessMsg: 'Question Updated Successfully',
    NoQuestionsFound: 'No Questions Found',
    QuestionType: {
      MultipleChoice: 'Multiple Choice',
      Dropdown: 'Dropdown',
      YesOrNo: 'Yes/No',
      Text: 'Text',
    },
    QueWoSync: {
      QuestionnaireWOAssociation: 'Questionnaire WO Association',
      QuestionnaireName: 'Questionnaire Name',
      WorkorderName: 'Workorder / Type Name',
      AddQueWOAsociation: 'Add Questionnaire WO Asociation',
      EditQueWOAsociation: 'Edit Questionnaire WO Asociation',
      SelectQuestionnaire: 'Select Questionnaire',
      SelectWorkorder: 'Select Workorder',
      SelectType: 'Select Type',
      Workorder: 'Workorder',
      Type: 'Type',
      Questionnaire: 'Questionnaire',
      MappingAddMsg: 'Mapping Details Added Successfully',
      MappingUpdateMsg: 'Mapping Details Updated Successfully',
      DeleteMappingModalMsg: 'Are you sure you want to delete this mapping?',
      DeleteMappingModalTitle: 'Delete Mapping',
      DeletionMappingMsg: 'Mapping deleted successfully',
      DeletionMappingErrMsg:
        'You cannnot remove this Questionnaire, because its mapped with workorder.',
    },
  },
  Validations: {
    Required: ' is required',
  },
  SpecialPricing: {
    Customer: 'Customer',
    SellingPrice: 'Special Selling Price',
    AddedOn: 'Added On',
    AddedBy: 'Added By',
    DeletedSuccessfully: 'Special Pricing has been deleted successfully.',
    DeleteMessage: 'Are you sure you want to delete special-pricing?',
    DeleteSpecialPricing: 'Delete Special Pricing?',
    AddSpecialPricing: 'Add Special Pricing',
    EditSpecialPricing: 'Edit Special Pricing',
    Save: 'Save',
    Cancel: 'Cancel',
    SavedSuccessfully: 'Special Pricing has been saved successfully.',
    UpdatedSuccessfully: 'Special Pricing has been updated successfully.',
    ShippingSellingPrice: 'Shipping Special Selling Price',
    DecPackSellingPrice: 'DecPack Special Selling Price',
    PricingType: 'Pricing Type',
  },
  ProductCatalogues: {
    AddProductCatalouge: 'Add Product Catalogue',
    Tabs: {
      Details: 'Details',
      SpecialPricing: 'Special Pricing',
      AllowedOrderCategory: 'Allowed Order Category',
      Documents: 'Documents',
      Notes: 'Notes',
      Purchases: 'Purchases',
      Consumptions: 'Consumptions',
      CurrentCheckout: 'Current Checkout',
      Assignment: 'Assignment',
      RemoveStock: 'Remove Stock',
    },
    ProductPurchases: {
      Date: 'Date',
      Quantity: 'Quantity',
      Supplier: 'Purchased From',
      PricePerUnit: 'Price Per Unit',
      Remarks: 'Remarks',
      CreatedBy: 'Added By',
      SearchBySupplier: 'Search by Supplier',
      DeleteProductPurchase: 'Delete Product Purchase',
      DeleteMessage: 'Are you sure you want to delete Purchase?',
      DeletedSuccessfully: 'Deleted Successfully',
      ErrorOccurred: 'Error Occurred',
      EditProductPurchase: 'Edit Product Purchase',
      EditedSuccessfully: 'Edited Successfully',
      AddProductPurchase: 'Add Product Purchase',
      AddedSuccessfully: 'Added Successfully',
      SavedSuccessfully: 'Purchase has been saved successfully',
      UpdatedSuccessfully: 'Purchase has been updated successfully',
      Validation: {
        QuantityRequired: 'Quntity is required',
        PricePerUnitRequired: 'Price Per Unit is required',
        SupplierRequired: 'Supplier is required',
        SupplierMaxLength: 'Maximum 50 characters allowed for Supplier.',
        RemarksMaxLength: 'Maximum 150 characters allowed for Remarks.',
      },
    },
    RemoveStock: {
      Date: 'Date',
      Quantity: 'Quantity',
      CatalogueId: 'Catalouge Id',
      Remarks: 'Remarks',
      CreatedBy: 'Added By',
      DeleteRemoveStock: 'Delete Remove Stock',
      DeleteMessage: 'Are you sure you want to delete Stock?',
      DeletedSuccessfully: 'Deleted Successfully',
      ErrorOccurred: 'Error Occurred',
      EditRemoveStock: 'Edit Remove Stock',
      EditedSuccessfully: 'Edited Successfully',
      AddRemoveStock: 'Add Remove Stock',
      AddedSuccessfully: 'Added Successfully',
      SavedSuccessfully: 'Remove stock has been saved successfully',
      UpdatedSuccessfully: 'Remove stock has been updated successfully',
    },
    ProductConsumptions: {
      Date: 'Date',
      Quantity: 'Quantity',
      OrderNumber: 'Order Ref No',
      CustomerName: 'Customer',
      SellingPrice: 'Selling Price',
      Remarks: 'Remarks',
      CreatedBy: 'Added By',
      DeleteProductConsumption: 'Delete Product Consumption',
      DeleteMessage: 'Are you sure you want to delete Consumption?',
      DeletedSuccessfully: 'Deleted Successfully',
      ErrorOccurred: 'Error Occurred',
      EditProductConsumption: 'Edit Product Consumption',
      EditedSuccessfully: 'Edited Successfully',
      AddProductConsumption: 'Add Product Consumption',
      AddedSuccessfully: 'Added Successfully',
      SavedSuccessfully: 'Consumption has been saved successfully',
      UpdatedSuccessfully: 'Consumption has been updated successfully',
      Validation: {
        QuantityRequired: 'Quntity is required',
        PricePerUnitRequired: 'Price Per Unit is required',
        SupplierRequired: 'Supplier is required',
        RemarksMaxLength: 'Maximum 150 characters allowed for Remarks.',
      },
    },
    CurrentCheckouts: {
      purchaseOrderId: 'Order Id',
      OrderNumber: 'Order Number',
      Quantity: 'Quantity',
      RestoreMessage: 'Are you sure you want to restore Checkout?',
      RestoreCurrentCheckout: 'Restore Current Checkout',
    },
    Assignments: {
      AssignedDate: 'Assigned Date',
      AssignedBy: 'Assgined By',
      AssignedTo: 'Assgined To',
      AssignedByType: 'Assgined By Type',
      AssignedToType: 'Assgined To Type',
      Warehouse: 'Warehouse',
      Order: 'Order',
      ShippingOrder: 'Shipping Order',
      DecPackOrder: 'DecPack Order',
      Add: 'Add Assignment',
      Edit: 'Edit Assignment',
      Remarks: 'Remarks',
      Quantity: 'Quantity',
      SavedSuccessfully: 'Assignment has been saved successfully',
      UpdatedSuccessfully: 'Assignment has been updated successfully',
      BothTypeNotSame: `Assigned by and Assigned to can't be same`,
    },
    SingleUse: 'Single use',
    Reusables: 'Reusables',
    Details: 'Product Catalogue Details',
    AddProductCatalogue: 'Add Product Catalogue',
    ProductCode: 'Product code',
    ProductName: 'Product Name',
    Dimentions: 'Dimensions',
    ProductCatalogueType: 'ProductCatalogue Type',
    Type: 'Type',
    Category: 'Category',
    Description: 'Description',
    ThresholdLimit: 'Threshold Limit',
    Weight: 'Weight',
    PricingType: 'Pricing Type',
    AddSuccess: 'Product catalogue has been added successfully.',
    UpdateSuccess: 'Product catalogue has been updated successfully.',
    DeleteSuccess: 'Product catalogue has been deleted successfully.',
    Pricing: 'Pricing & Inventory',
    StandardPrice: 'Standard Price',
    LastCostBuyPrice: 'Last Cost / Buy Price',
    LastPurchasedFrom: 'Last Purchased From',
    InWarehouse: 'Available in Warehouse',
    AvailableinWH: 'Available in WH',
    DeleteConfirmation:
      'Are you sure you want to delete this product catalogue?',
    WarehouseQuantity: 'Warehouse Quantity',
    InvalidQuantity: 'Entered Quantity is not valid.',
    ResourceQuantity: 'Resource Quantity',
    UniqueProductCode: 'Product Code must be unique.',
    UniqueProductName: 'Product Name must be unique.',
    DeleteValidation:
      'Product catalogue are assigned to orders, please assign back to warehouse first before deleting',
    ShowInDPBOM: 'Show in DnP BOM',
    ShippingStandardPrice: 'Shipping Standard Price',
    DecPackStandardPrice: 'DecPack Standard Price',
    Department: 'Department',
  },
  Partner: {
    SearchAddress: 'Search Address',
    Type: 'Partner Type',
    Tracking: 'Tracking',
    Name: 'Name',
    VatNumber: 'Vat Number',
    Telephone: 'Telephone',
    Category: 'Category',
    Email: 'Email',
    Country_City: 'Country-City',
    Location_Branches: 'Location/Branches',
    Status: 'Status',
    DeletedSuccessfully: 'Deleted Successfully',
    DeleteMessage: 'Are you sure you want to delete Partner?',
    DeletePartner: 'Delete Partner',
    ErrorOccurred: 'Error Occurred',
    ResendForApproval: 'Resent For Approval Successfully',
    SearchbyName: 'Search by Name',
    AddPartner: 'Add Partner',
    SupplierName: 'Supplier Name',
    EORI: 'EORI',
    Address: 'Address',
    PCCity: 'PC, City',
    Country: 'Country',
    Tag: 'Tag',
    Approved: 'Approved',
    Logo: 'Logo',
    Details: 'Details',
    FinancialBasic: 'Financial Basic',
    ContactDetails: 'Contact Details',
    Invoicing: 'Invoicing',
    PostalCode: 'Postal Code',
    City: 'City',
    Website: 'Website',
    SameAsVat: 'Same as VAT',
    IsDebtor: 'Is Debtor',
    GeneralTelephone: 'General Telephone',
    GeneralEmail: 'General Email',
    AdminEmail: 'Portal Admin Email',
    InvoiceAddressDifferent: 'Invoice Address - Is Different?',
    InvoiceEmail: 'Invoice Email',
    Shipping: 'Shipping',
    DecPack: 'DecPack',
    IsDifferent: 'Is Different',
    InvoicePostalCode: 'Invoice Postal Code',
    InvoiceCity: 'Invoice City',
    InvoiceCountry: 'Invoice Country',
    InvoiceAddress: 'Invoice Address',
    PartnerDetails: 'Partner Details',
    DHL: 'DHL',
    FedEx: 'FedEx',
    Others: 'Others',
    UpdatedSuccessfully: 'Partner has been updated successfully',
    TransportTypes: 'Transport Types',
    Tabs: {
      Category: 'Category',
      Details: 'Details',
      ContactPerson: 'Contact Person',
      Location: 'Addressbook',
      Documents: 'Documents',
      Notes: 'Notes',
      TransportTypes: 'Transport Types',
      AllowedOrderCategory: 'Allowed Order Category',
    },
    Validation: {
      PartnerNameRequired: 'Partner Name is required.',
      PartnerNameMaxLength: 'Maximum 50 characters allowed for Partner Name.',
      PartnerNameMinLength: 'Minimum 3 characters required for Partner Name.',
      AddressMaxLength: 'Maximum 250 characters allowed for Address.',
      PostalCodeMaxLength: 'Maximum 20 characters allowed for Postal Code.',
      InvoiceAddressMaxLength:
        'Maximum 250 characters allowed for Invoice Address.',
      InvoicePostalCodeMaxLength:
        'Maximum 20 characters allowed for Invoice Postal Code.',
      WebsiteMaxLenght: 'Maximum 255 characters allowed for Website.',
      VatNumberMaxLength: 'Maximum 30 characters allowed for Vat Number.',
      EORIMaxLength: 'Maximum 30 characters allowed for EORI.',
      TelephoneMaxLength: 'Maximum 20 characters allowed for TelePhone.',
      StatusRequired: 'Status is required.',
      EmailMaxLength: 'Maximum 255 characters allowed for Email.',
      InvalidEmail: 'Invalid email address entered',
      InvoiceEmailMaxLength:
        'Maximum 255 characters allowed for Invoice Email.',
      EmptyCatagory: 'Can not save empty Category',
      UniqueName: 'Supplier name must be unique.',
    },
    CategoryTab: {
      Air: 'Air',
      Road: 'Road',
      Train: 'Train',
      Ship: 'Ship',
      Other: 'Other',
    },
  },
  ShippingOrders: {
    CopyLevel: 'Copy Level',
    Standard: 'Standard',
    Extensive: 'Extensive',
    Add: 'Add Shipping Order',
    AddOffer: 'Add Shipping Offer',
    Edit: 'Edit Shipping Order',
    Details: 'Shipping Order Details',
    OfferDetails: 'Shipping Offer Details',
    HazGoReference: 'Hazgo Reference',
    OfferReference: 'Offer Reference',
    OfferExpired: 'Offer Expired',
    RejectionCode: 'Rejection Code',
    Accepted: 'Accepted',
    Rejected: 'Rejected',
    Open: 'Open',
    SubCategory: 'Sub Category',
    OfferSent: 'Offer Sent',
    RejectionReason: 'Rejection Reason',
    Expired: 'Expired',
    NotExpired: 'Not Expired',
    ConvertOrder: 'Convert to Order',
    ReferenceNumber: 'Reference Number',
    Totals: 'Totals',
    InProgress: 'InProgress',
    TotalNumOfPieces: 'Total No Of Pieces',
    ShipmentContent: 'Shipment Content',
    WorkOrders: 'Work Orders',
    CustomsDetails: 'Customs Details',
    TermsOfDeliveryAndPayment: 'Terms Of Delivery And Payment',
    Importer: 'Importer',
    Exporter: 'Exporter',
    AddTermsOfDeliveryAndPayment: 'Add Terms Of Delivery And Payment',
    AddImporter: 'Add Importer',
    AddExporter: 'Add Exporter',
    ViewImporter: 'View Importer',
    ViewExporter: 'View Exporter',
    NameMaxLength: 'Maximum 30 characters allowed for Name.',
    OrderCreatedFrom: 'Order is created from customer portal',
    InternalTrackingNumber: 'Internal Tracking Number',
    PriceRange: 'Price Range',
    ConvertToOrderConfirmation:
      'Are you sure you want to convert this offer to order?',
    OfferCategoryValidation: 'Please update offer category for this customer',
    Tabs: {
      Details: 'Details',
      Packages: 'Packages',
      WorkOrder: 'Work Order',
      BillableItems: 'Billable Items',
      Tracking: 'Tracking',
      Documents: 'Documents',
      Notes: 'Notes',
      Customs: 'Customs',
      NotificationDetails: 'Notification Details',
      Approval: 'Approval',
      Labels: 'Labels',
    },
    Steps: {
      BasicDetails: 'Basic Details',
      PackageDetails: 'Package Details',
      Materials: 'Materials',
      Documents: 'Documents',
      Notes: 'Notes',
    },
    ShippingPackage: {
      BoxNo: 'Box No',
      BoxType: 'Box Type',
      Dimension: 'Dimension(cm)',
      Height: 'Height(cm)',
      Width: 'Width(cm)',
      Depth: 'Depth(cm)',
      Items: 'Items',
      Weight: 'Weight(Kg)',
      GeneralCargo: 'General Cargo',
      DangerousGoods: 'Dangerous Goods',
      None: 'None',
      TemperatureControlled: 'Temperature Controlled',
      DeleteConfirmation:
        'Are you sure you want to delete this Package Details?',
      AddShippingPackage: 'ADD BOX/PACKAGE',
      EditShippingPackage: 'Edit BOX/PACKAGE',
      Box: 'BOX(Product)',
      CommodityCode: 'Commodity Code',
      HSCODE: 'HS Code *',
      Description: 'Description *',
      Quantity: 'Quantity *',
      ItemVaue: 'Item Value *',
      ValueCurrency: 'Value Currency *',
      NetWeight: 'Net Weight *',
      GrossWeight: 'Gross Weight *',
      CountryOfOrigin: 'Country Of Origin *',
      Checks: 'Checks',
      NumberOfPackages: 'Number Of Packages',
      UNNumber: 'UN Number',
      PrimaryClass: 'Primary Class',
      SecondaryClass: 'Secondary Class',
      CAO: 'CAO',
      KgOfDryIce: 'Kg of DryIce',
      DataLogger: 'Data Logger',
      DataLoggerNumber: 'Data Logger Number',
      Commodity: 'Add Commodity',
      ItemDescription: 'ItemDescription',
      ItemValue: 'Item Value',
      Unit: 'Unit *',
      YourOwnPackage: 'Your Own Package',
      PackagingRequired: 'Packaging Required?',
      Instruction: 'Instruction',
      ProductRequired: 'Atleast one product is required',
      ErrorOccurred: 'Error Occurred',
      ProductQuantityNotAvailable: 'Package Product or Dry Ice not available',
      Temperature: 'Temperature',
      InnerBox: 'Inner Box',
      Product: 'Product',
      Remarks: 'Remarks',
      DeleteInnerBox: 'Delete InnerBox',
      DeleteInnerBoxDescription: 'Are you sure you want to delete innerbox?',
      OuterBox: 'Outer Box',
      InnerBoxCount: 'No of InnerBox',
    },
    WorkOrder: {
      WONo: 'WO No',
      Type: 'Type',
      WODate: 'WO Date',
      ActivityOwner: 'Activity Owner',
      LastUpdateOn: 'Last Update on',
      Status: 'Status',
      BackOffice: 'BackOffice',
      Driver: 'Driver',
      Partner: 'Partner',
      WareHouse: 'WareHouse',
      WarehouseStaff: 'Warehouse Staff',
      DecNPack: 'Dec & Pack',
      AssignedTo: 'Assigned To',
      DGDCreation: 'WO2 - DGD Creation',
      WorkorderOutbound: 'WO - Outbound',
      OutboundDetails: 'Outbound Details',
      WorkorderDocCheck: 'WO - Document Check',
      WorkorderPhysicalCheck: 'WO - Physical Check',
      Checks: 'Checks',
      SelectAnyOption: 'Select Any Option',
      SelectMultipleOption: 'Select Any Options(Multiple Selection)',
      NoQuestionnaireMapMsg: 'None of Questionnaire mapped with this workorder',
      AnswersSubmittedSuccessMsg:
        'Answers for Questionnaire Submitted Successfully',
      NotSubmittedErrMsg: 'None of answer attended from Questionnaire',
      ExecutionNotRequired: 'Execution Not Required',
      PhysicalCheckAddSuccessMsg:
        'Physical check workorder has been added successfully',
      PhysicalCheckUpdateSuccessMsg:
        'Physical check workorder has been updated successfully',
      DocumentCheckAddSuccessMsg:
        'Document check workorder has been added successfully',
      DocumentCheckUpdateSuccessMsg:
        'Document check workorder has been updated successfully',
      ActualDateTime: 'Actual Date & Time',
      ExpectedDateTime: 'Expected Date & Time',
    },
    OrderDetails: 'Order Details',
    Location: 'Location',
    Contact: 'Contact',
    Cancel: 'Cancel Order',
    OrderReference: 'Customer reference',
    Service: 'Service',
    OrderCategory: 'Order Category',
    OfferCategory: 'Offer Category',
    Validity: 'Validity',
    PickupAddress: 'Pick Up Address',
    DeliveryAddress: 'Delivery Address',
    IsSameAsCustomer: 'Is Same as Customer',
    PickupFrom: 'Pickup From',
    DeliveryTo: 'Delivery To',
    ExpectedPickupDateTime: 'Expected Pick Up Date and Time',
    ExpectedDeliveryDateTime: 'Expected Delivery Date and Time',
    AddSuccess: 'Shipping order has been created successfully.',
    AddSuccessOffer: 'Shipping offer has been created successfully.',
    UpdateSuccessOffer: 'Shipping offer has been updated successfully.',
    InOrder: 'In Order',
    InOffer: 'In Offer',
    Type: 'Type',
    OfferStatus: 'Offer Status',
    DeleteMessage: 'Are you sure you want to delete shipping order?',
    DeleteOfferMessage: 'Are you sure you want to delete shipping offer?',
    DeleteTitle: 'Delete Shipping Order',
    DeleteOfferTitle: 'Delete Shipping Offer',
    UpdateSuccess: 'Shipping order has been updated successfully.',
    UpdateOfferSuccess: 'Shipping offer has been updated successfully.',
    DeleteSuccess: 'Shipping order has been deleted successfully.',
    DeleteConfirmation: 'Are you sure you want to delete this shipping order?',
    CancelConfirmation: 'Are you sure you want to cancel this shipping order?',
    CancelSuccess: 'Shipping order cancelled successfully',
    RejectOfferConfirmation:
      'Are you sure you want to reject this shipping offer?',
    RejectOfferSuccess: 'Shipping offer has been rejected successfully.',
    OrderDate: 'Order Date',
    OfferDate: 'Offer Date',
    Customer: 'Customer',
    Category: 'Category',
    Attention: 'Attention',
    HazgoReference: 'Hazgo Reference',
    WoDoneVsTotal: 'WO Done vs Total',
    Partner: 'Partner',
    PartnerTrackingNo: 'Partner Tracking No',
    FromTo: 'From - To',
    Status: 'Status',
    TotalWeight: 'Total Weight',
    TotalNoOfPackages: 'Total No of Package',
    ErrorOccurred: 'Error Occurred',
    Information: 'Information',
    InformationRequired: 'Information Required',
    Document: 'Document',
    DGPlus: 'DG Plus',
    EuDatabase: 'EU-Database',
    CreateDGD: 'Create DGD',
    AllDataAvailable: 'All data available to complete DGD?',
    DGDTitle: 'WO-DGD Creation',
    ToolTip: {
      DownloadSampleFile: 'Download Sample File',
    },
    PickupDatetimeIsGreaterErr:
      'Expected delivery date and time should greater than pickup date and time',
    DestinationCompany: 'Destination Company',
    PickupCompany: 'Pickup Company',
    OrderCopySuccessful: 'Shipping order has been copied successfully.',
    CopyConfirmation: 'Action once performed, cannot be reverted. Proceed?',
  },
  Booking: {
    BookingTitle: 'WO1 - Booking',
    Information: 'Information',
    Partner: 'Partner',
    Service: 'Service',
    TrackingNumber: 'Tracking number',
    TotalCost: 'Total Cost',
    TotalPrice: 'Total Price',
    SupplierCost: 'Supplier Cost',
    Cost: 'Cost',
    Save: 'Save',
    Cancel: 'Cancel',
    Calculate: 'Calculate',
    EditBooking: 'Edit Booking',
    AddBooking: 'Add Booking',
    Status: 'Status',
    StatusSavedSuccess: 'Status saved successfully.',
    DeleteBooking: 'Delete Booking Order',
    DeleteConfirmation: 'Are you sure you want to delete this booking order?',
    DeleteSuccess: 'Booking work order has been deleted successfully.',
    UpdateSuccess: 'Booking work order updated successfully',
    AddSuccess: 'Booking work order added successfully',
    Notes: 'Notes',
    TransportType: 'Transport Type',
    Airline: 'Airline',
    DestinationCountry: 'Destination Country',
    TransitCountry: 'Transit Country',
    AirlineRestrictions: 'Airline Restrictions',
    CountryRestrictions: 'Country Restrictions',
    Restrictions: 'Restrictions',
  },
  Delivery: {
    Instructions: 'Instructions',
    AssignedTo: 'Assigned To',
    Title: 'WO-Delivery',
    Remarks: 'Remarks',
    Document: 'Document',
    DeliveryDetails: 'Delivery Details',
    DeliveryDone: 'Delivery Done',
    ActualDeliveryDateTime: 'Actual Delivery Date Time',
    FieldRequired: 'This field is required.',
    AddSuccess: 'Delivery done successfully.',
    SomethingWentWrong: 'Something went wrong.',
    UpdateSuccess: 'Delivery updated successfully.',
    POD: 'POD',
    SendPOD: 'Send Delivery Note To Customer',
  },
  LedgerInfo: {
    Title: 'Ledger Info',
    LedgerAccount: 'Ledger Account',
    LedgerAccountDescription: 'Ledger Account Description',
  },
  ItemGroupInfo: {
    Title: 'Item Group Info',
    ItemGroup: 'Item Group',
  },
  Packaging: {
    ErrorWhileUpdatingStatus: 'Something went wrong while updating status.',
    SomethingWentWrong: 'Something went wrong.',
    AddSuccess: 'Packaging added successfully.',
    UpdateSuccess: 'Packaging updated successfully.',
    FieldRequired: 'Field is required',
    ActualPackagingDateTime: 'Actual Packaging DateTime',
    Remarks: 'Remarks',
    Title: 'WO-Packaging',
    AssignedTo: 'Assigned To',
    AssignmentType: 'Assignment Type',
    Instructions: 'Instructions',
    PackagingDetails: 'Packaging Details',
    PackagingDone: 'Packaging Done',
    Picture: 'Picture',
  },

  ShippingMaterials: {
    Add: 'Add Material',
    Edit: 'Edit Material',
    Type: 'Type',
    Category: 'Category',
    ItemCode: 'Item Code',
    Name: 'Name',
    Quantity: 'Quantity',
    Note: 'Note',
    SellingPrice: 'Selling Price',
    Delete: 'Delete',
    ProductCatalogue: 'Product',
    ServiceCatalogue: 'Service',
    TransportCatalogue: 'Transport',
    DeleteMessage: 'Are you sure you want to delete material entry?',
    DeleteTitle: 'Delete Material',
    BoxDetails: 'Box Details',
    IsFromPackage: 'IsFromPackage',
    NotProcessed: 'Please check downloaded file',
    ProcessedSuccessfully: 'File Processed Successfully',
    ProductQuantityNotAvailable: 'Product Quantity Not available',
    IncorrectTransportDetail: 'Transport Detail is required',
    ZeroNotAllowed: 'Zero not allowed',
    CostPrice: 'Cost Price',
    SellingPriceLessThanCostPrice: 'Selling Price is less than Cost Price',
    SendProformaInvoice: 'Send Proforma Invoice',
    EmailNotVailable: 'Email is not available',
    QuantityDetails: 'Quantity Details',
    WareHouseQuantity: 'WareHouse Quantity',
    RequestedQuantity: 'Requested Quantity',
    Delta: 'Delta(Current - Requested)',
    Threshold: 'Threshold Quantity',
    EstimatedCost: 'Estimated Cost',
    ActualCost: 'Actual Cost',
  },
  Tracking: {
    Add: 'Add Tracking',
    Edit: 'Edit Tracking',
    Internal: 'Internal',
    External: 'External',
    StatusChangeDate: 'Status Change Date',
    Status: 'Status',
    Type: 'Type',
    Notes: 'Notes',
    AddSuccess: 'Shipping order has been created successfully.',
    InOrder: 'In Order',
    InOffer: 'In Offer',
    DeleteMessage: 'Are you sure you want to delete tracking?',
    DeleteTitle: 'Delete Tracking',
    UpdateSuccess: 'Tracking has been updated successfully.',
    DeleteSuccess: 'Tracking has been deleted successfully.',
    DeleteConfirmation: 'Are you sure you want to delete this tracking?',
  },
  DecPackOrders: {
    DeleteMessage: 'Are you sure you want to delete Dec&Pack order?',
    DeleteTitle: 'Delete Dec&Pack Order',
    Details: 'Details',
    DeckPack: 'Dec & Pack',
    RejectOfferConfirmation:
      'Are you sure you want to reject this DecPack offer?',
    OrderOwner: 'Order Owner',
    SendForApproval: 'Send For Approval',
    SendForApprovalConfirmation:
      'Are you sure you want to send order for approval?',
    SendForApprovalConfirmationDetails: 'Send Order for Approval',
    MobileRemarks: 'Mobile Remarks',
    Table: {
      OfferReference: 'Offer Reference',
      OrderDate: 'OrderDate',
      OfferDate: 'Offer Date',
      Customer: 'Customer',
      ServiceType: 'Type of Service',
      Reference: 'Reference',
      Location: 'Location',
      WoDoneVsTotal: 'Wo Done Vs Total',
      Type: 'Type',
      Pickup: 'Pickup',
      Contacts: 'Contacts',
      InProgressWO: 'In-Progress WO',
      FinalDestination: 'Final Destination',
      Status: 'Status',
      Attention: 'Attention',
      HazGoReference: 'HazGo Reference',
      CustomerReference: 'Customer Reference',
      ExecutionDate: 'Execution Date',
      Shipper: 'Shipper',
      AssignedTo: 'Assigned To',
      ExecutionDateTime: 'Execution Date/Time',
      ExecutionTime: 'Execution Time',
    },
    Tabs: {
      Details: 'Details',
      WorkOrder: 'Work Order',
      BillableItems: 'Billable Items',
      Documents: 'Documents',
      Notes: 'Notes',
      Approval: 'Approval',
    },
    ToolTip: {
      DownloadSampleFile: 'Download Sample File',
    },
    DGCheck: 'DG Check',
    DGDCreation: 'DGD Creation',
    NonDGOrder: 'Non DG Order',
    Shop: 'Shop',
    DeleteSuccess: 'Deleted Successfully',
    RejectOffer: 'Reject Offer',
    OfferRejectSuccess: 'Offer Rejected Successfully',
    DeleteConfirmation: 'Are you sure you want to delete this DecPack order?',
    CancelConfirmation: 'Are you sure you want to cancel this DecPack order?',
    CancelSuccess: 'DecPack order cancelled successfully',
    QuestionnaireAnswersSubmitted:
      'Answers for Questionnaire Submitted Successfully',
    QuestionnaireAnswersMandatory: 'Questionnaire are Mandatory',
    CheckQuestionsMandatory: 'Questions are Mandatory',
    WorkOrder: {
      WONo: 'WO No',
      Type: 'Type',
      WODate: 'WO Date',
      ActivityOwner: 'Activity Owner',
      LastUpdateOn: 'Last Update on',
      Status: 'Status',
      BackOffice: 'BackOffice',
      Driver: 'Driver',
      MobileUser: 'Mobile User',
      Partner: 'Partner',
      AssignedTo: 'Assigned To',
      DGDCreationTitle: 'WO 2 - DGD Creation',
      SecurityCheckTitle: 'WO S - Security Check RA',
      CheckDocumentation: 'WO 4 - Check Documentation',
      CustomerRefTitle: 'WO 11 - Customer Reference',
      Material: 'WO 5 - Material',
      PhotoCheck: 'WO 9 - Photo Check',
      TypeCheck: 'Type Check',
      CheckQuestion: 'Check Question',
      Details: 'Details',
      SpecialInstruction: 'Special Instructions',
      Assign: 'Assign',
      UNNumberCheck: 'UN Number Check',
      ExtractDGPlus: 'Extract from DGPlus',
      Comment: 'Comment',
      ShowAirLine: 'Show Airlines',
      ShowCountry: 'Show Countries',
      Instruction: 'Instruction',
      FourDigitPin: 'Enter 4 digit UN',
      BillOfMaterial: 'WO 8 - Bill of Material',
      ApprovalBillOfMaterial: 'WO 10 - Approval Bill of Material',
      ApprovalInvoice: 'W0 12 - Approval Invoice',
      Execution: 'Execution',
      OrderExecution: 'WO 6 - Order Execution',
      AirLine: 'Airline',
      Country: 'Country',
      ShipmentNotAvailableInWarehouse: 'Shipment not available in warehouse',
      Date: 'Date',
      Photos: 'Photos',
      requiredErrMsg: 'This field is required',
      NoQuestionnaireMapMsg: 'No Questionnaire',
      detailsUpdateMsg: 'Details Updated successfully',
      detailsAddMsg: 'Details Added successfully',
      invalidDateErrMsg: 'Invalid Date',
      WO6ltrDeliveryOrderTitle: 'Work Order 6ter - Delivery of order',
      CustomeName: 'Customer Name',
      DateTime: 'Date Time',
      Delivery: 'Delivery',
      WOOutboundProcess: 'WO Outbound Process',
      OBO: 'WO OBO - Outbound Process',
      DGDDocument: 'DGD Document',
      Questionnaire: 'Questionnaire *',
      FileNames: {
        DGDDocPDF: 'DGD Document.pdf',
      },
      DGPlusData: 'DGPlus Details',
      WO3Details: 'WO3 Details',
      ShowWO3Details: 'Show WO3 Details',
      InvoiceNotes: 'Invoice Notes',
      ProformaNotes: 'Proforma Notes',
    },
    DGPlusDetails: {
      Name: 'Name',
      UN: 'UN',
      Class: 'Class',
      Subrisk: 'Subrisk',
      Packinggroup: 'Packinggroup',
      Exceptedquantities: 'Excepted quantities',
      Paxpackinginstruction: 'Passenger Packing Instructions',
      Paxmaxquantity: 'Passenger Max Quantity',
      Caopackinginstruction: 'Cargo Packing Instructions',
      Caomaxquantity: 'Cargo Max Quantity',
    },
    CheckDocumentation: {
      CreateLabels: 'Create Labels',
      LayoutType: 'Layout Type',
      ShippingLabel: 'Shipping Label',
      OverpackLabel: 'Overpack Label',
      Other: 'Other',
      Dymo: 'DYMO',
      Zebra: 'ZEBRA',
      OtherLabelFreeText: 'Free Text',
      OverPackId: 'Overpack Id',
      TotalQuantity: 'Total Quantity',
      Consignee: 'Consignee',
      Shipper: 'Shipper',
      UnNumber: 'UN Number',
      TechnicalName: 'Technical Name',
      NetQuantity: 'Quantity',
      AddSuccess: 'Label Added Successfully.',
      SomethingWentWrong: 'Something Went Wrong.',
      UpdateSuccess: 'Label Updated Successfully.',
      MyHazGoRef: 'myHazgo Ref',
      CustomerRef: 'Customer Ref',
      NumberOfPieces: 'Number of Pieces',
      PSN: 'PSN',
      SelectPSN: 'Select PSN',
    },
    Validation: {
      CustomerNameRequired: 'Customer Name is required.',
      CommentRequired: 'Comment is required.',
      DateRequired: 'Date is required.',
    },
    WOMaterial: {
      MaterialNeeded: 'Material Needed',
      SpecialInstruction: 'Special Instruction',
      Assign: 'Assign',
      Instruction: 'Instruction',
      AssignTo: 'Assigned To',
      StrongMaterial: 'Strong Overpack Material',
      Openfield: 'Open field',
      SpecificTypeOfPackaging: 'Specific type of packaging',
      Packaging: 'Packaging',
      Quantity: 'Quantity',
      FieldRequired: 'This field is required.',
      AddSuccess: 'Delivery done successfully.',
      SomethingWentWrong: 'Something went wrong.',
      UpdateSuccess: 'Delivery updated successfully.',
      ProductQuantityNotAvailable: 'Product Quantity Not available',
      ProductAtAllLevels: 'Product must be selected at all levels',
    },
    PhotoCheck: {
      Photos: 'Photos',
      DGD: 'DGD',
      SpecialInstruction: 'Special Instructions',
      PhotoCheck: 'Photo Check',
      ViewDocument: 'Click here to view Document :',
      Instruction1: 'Instruction 1 :',
      Instruction2: 'Instruction 2 :',
    },
    WOSecurityCheckQA1:
      'Is the shipper registered in the "Union database on supply chain security" registered as RA or KC?',
    WOSecurityCheckQA2:
      'Is the person delivering the consignment a trained driver of the RA or a driver of a subcontracted haulier who signed a 6E Haulier agreement with the RA or KC?',
    DecPackQuestions: {
      WOIBO: {
        SecureShipment: 'SECURE SHIPMENT *',
        SecurityGeneral: 'SECURITY - GENERAL *',
        Shipment: 'SHIPMENT *',
        SecureShipmentQA1: `Transporter:`,
        SecureShipmentQA2: `Licence plate:`,
        SecureShipmentQA3: `Driver's name:`,
        SecureShipmentQA4: 'Is the vehicle sealed/locked before unloading?',
        SecurityGeneralQA1: `No signs of tampering with the shipment?`,
        SecurityGeneralQA2: `No shipment tampered evident packed?`,
        ShipmentQA1: `Number of pieces:`,
        ShipmentQA2: `Not Damaged?`,
        ShipmentQA3: `Shipment accepted`,
        Title: 'Wo - IBO',
        SaveSuccess: 'Wo - IBO answers have been saved successfully.',
        SaveError: 'Wo - IBO answers have not been saved successfully.',
        CMRNote: 'Please make notes on CMR',
        AddDamagePictures: 'Add Damage Picture',
        TransportDocument: 'Transport Documents',
        NoShippingDocsAvailable: 'No Shipping Docs Available',
        TransportDocumentSaveSuccess:
          'Transport Documents have been saved successfully.',
        TransportDocumentSaveError:
          'Transport Documents have not been saved successfully.',
        CreateWarehouseLabel: 'CREATE WAREHOUSE LABEL',
        WarehouseLabel: 'Warehouse Label',
        DetailsRequired: 'Secutity details and Shipment required',
        AllDetailsRequired:
          'Secure Shipment,Security details and Shipment required',
      },
      WOCheckDocumentation: {
        WOCheckDocumentationQA1: 'DGD Check ok?',
        WOCheckDocumentationQA2: 'AWB Check ok?',
        WOCheckDocumentationQA3: 'Airline restrictions ok?',
        WOCheckDocumentationQA4: 'State restrictions ok?',
      },
      WOExecution: {
        WOExecutionQA1: 'DGD Check ok?',
        WOExecutionQA2: 'AWB Check ok?',
        WOExecutionQA3: 'Airline restrictions ok?',
        WOExecutionQA4: 'State restrictions ok?',
        WOExecutionQA5: 'Shipment ready for carriage?',
        NoDocsAvailable: 'No Docs Available',
      },
    },
    CustomerName: 'Customer Name',
    DateTime: 'Date Time',
  },
  WorkOrder: {
    Title: 'WO-Inbound',
    AssignmentTo: 'Assigned To',
    AssignmentType: 'Assignment Type',
    InboundDetails: 'Inbound Details',
    Instructions: 'Instructions',
    Remarks: 'Remarks',
    InboundDone: 'Inbound Done',
    ActualInboundDateTime: 'Actual Inbound DateTime',
    Driver: 'Driver',
    Partner: 'Partner',
    WarehouseStaff: 'Warehouse Staff',
    FieldRequired: 'Field is required',
    AddSuccess: 'Work order inbound has been added successfully.',
    UpdateSuccess: 'Work order has been updated successfully.',
    DGDCreation: 'DGD Creation',
    SecurityCheckRA: 'Security Check RA',
    Inboundprocess: 'Inbound process',
    Collectingshipmentdata: 'Collecting shipment data',
    CheckDocumentation: 'Check Documentation',
    Material: 'Material',
    Executionorder: 'Execution order',
    Deliveryorderassigning: 'Delivery Order Assigning',
    Deliveryorder: 'Delivery Order',
    Irregularitycontactclient: 'Irregularity Contact Client',
    BillofMaterial: 'Bill of Material',
    PhotoCheck: 'Photo Check',
    Outboundprocess: 'Outbound Process',
    ApprovalBillofMaterial: 'Approval Bill of Material',
    InputmissingCustomerreference: 'Input Missing Customer Reference',
    ApprovalInvoice: 'Approval Invoice',
    ApproveInvoice: 'Approve Invoice',
    InfoRemindCustomerRef: 'Send reminder to update customer reference number',
    UpdatedSuccessfully: 'Updated Successfully',
    ErrorOccurred: 'Error Occurred',
    SendProformaInvoice: 'Send Proforma Invoice',
    SendAnyway: 'Send anyway',
    ApproveAnyway: 'Approve anyway',
    SendSuccessfully: 'Proforma invoice sent successfully',
    InvoiceApprovedSuccess: 'Invoice approved successfully',
    SendEmailSuccessfully: 'Email sent successfully',
    Notes: 'Notes',
    OrderReference: 'Order Reference',
  },
  WorkorderPickup: {
    WorkorderPickup: 'Workorder Pickup',
    Assigned: 'Assigned',
    PickupDetails: 'Pick Up Details',
    Status: 'Status',
    AssignmentType: 'Assignment Type',
    Instructions: 'Instructions',
    AssignedTo: 'Assigned To',
    requiredErrMsg: 'This field is required',
    Driver: 'Driver',
    Partner: 'Partner',
    PickupDone: 'Pick Up Done',
    ActualPickDateTime: 'Actual Pick DateTime',
    Remarks: 'Remarks',
    WaitTime: 'Wait Timer(sec)',
    Packaging: 'Packaging',
    PackagingDone: 'Packaging to be done at client site',
    AddSuccess: 'Work order pickup has been added successfully.',
    UpdateSuccess: 'Work order pickup has been updated successfully.',
    AssignedNextTo: 'Assigned Next WO To',
  },
  WorkorderOutbound: {
    WorkorderOutbound: 'Workorder Outbound',
    AssignedSection: 'Assigned To',
    OutboundSection: 'Outbound Details',
    AssignedTo: 'Assigned To',
    Instructions: 'Instructions',
    Remarks: 'Remarks',
    ActualOutboundDateTime: 'Actual Outbound DateTime',
    requiredErrMsg: 'This field is required',
    detailsUpdateMsg: 'Details Updated successfully',
    OutboundDone: 'Outbound Done',
  },
  DecPack: {
    AddDecPack: 'Add Dec & Pack',
    OrderBasics: 'Order Basics',
    OfferBasics: 'Offer Basics',
    CustomerDetails: 'Customer Details',
    GeneralDetails: 'General Details',
    HazgoReference: 'Hazgo Reference',
    TypeOfService: 'Type of Service',
    DGCheck: 'DG Check',
    DGDCreation: 'DGD Creation',
    NonDGOrder: 'Non DG Order',
    Shop: 'Shop',
    ExecutionDate: 'Execution Date',
    StartExecutionTime: 'Start Execution Time',
    EndExecutionTime: 'End Execution Time',
    SelectExecutionTime: 'Select Execution Time - AM/PM/Specific Time',
    Am: 'AM',
    Pm: 'PM',
    SpecificTime: 'Specific Time',
    None: 'None',
    HighPriority: 'High Priority',
    Customer: 'Customer',
    Contact: 'Contact',
    CustomerReference: 'Customer Reference',
    Shipper: 'Shipper',
    Location: 'Location',
    WareHouse: 'WareHouse',
    GroundHandler: 'Ground Handler',
    DetailsWarehouse: 'Details of Location ',
    InboundDetails: 'Inbound Details',
    OutboundDetails: 'Outbound Details',
    Name: 'Name',
    SecuredShipment: 'Secured Shipment?',
    DriverName: 'Transporter Details - Driver Name',
    LicensePlate: 'License Plate',
    Transporter: 'Transporter',
    NumberofPieces: 'Number of Pieces',
    Pickupaddress: 'Pick Up Address',
    ExpectedInboundDate: 'Expected Inbound Date',
    DeliveryLocation: 'Delivery - Location',
    Airline: 'Airline',
    FinalDestination: 'Final Destination',
    TransitDestination: 'Transit Destination',
    SpecialInstructions: 'Special Instructions',
    EstimatedDuration: 'Estimated Duration(Mins)',
    Interventie: 'Interventie',
    Status: 'Status',
    WoDone: 'WO Done vs Total',
    Consignee: 'Consignee',
    CompanyName: 'Company Name',
    KcRaAgent: 'KC/RA Agent',
    PickUpBy: 'Pick up by',
    DeliveryReference: 'Delivery Reference',
  },
  WOCollectionData: {
    Title: 'WO 3 - Collecting Shipment Data',
    Document: 'Document',
    ShipmentData: 'Shipment Data',
    Photos: 'Photos',
    Shipment: 'Shipment',
    MoveToWarehouse: 'Request to move shipment to warehouse.',
    FieldRequired: 'This field is required.',
    AddSuccess: 'Delivery done successfully.',
    SomethingWentWrong: 'Something went wrong.',
    UpdateSuccess: 'Delivery updated successfully.',
    ShipmentDataUpdateSuccess: 'Shipment Data has been updated successfully.',
    ShipmentDataUpdateFail: 'Shipment Image not save.Please try again.',
  },
  DecPackWO6Bis: {
    Title: 'Work Order 6bis - Delivery of Order (Assigning)',
    Delivery: 'Delivery',
    AssignedTo: 'Assigned To',
    Location: 'Location',
    SpecialInstructions: 'Special instructions',
    FieldRequired: 'This field is required.',
    AddSuccess: 'Delivery done successfully.',
    SomethingWentWrong: 'Something went wrong.',
    UpdateSuccess: 'Delivery updated successfully.',
  },
  SendEmail: {
    SendEmailTitle: 'Send an email',
    Send: 'Send',
    Cancel: 'Cancel',
    Email: 'Email',
    Language: 'Language',
    SendSuccessfully: 'Email sent successfully',
    ErrorOccurred: 'Error occurred. Please try again.',
  },
  Cities: {
    AddDialogTitle: 'Add City',
    City: 'City',
    Validation: {
      CityRequired: 'City is required.',
    },
    AddedSuccessfully: 'City has been added successfully.',
    AlreadyExist: 'City already exists.',
    ErrorOccurred: 'Error occurred.Please try again.',
  },
  IrregularityContactClient: {
    Title: 'Irregularity - Contact Client',
    Wo4Comment: 'WO4 Comments',
    ContactCustomerViaEmail: 'Contact customer via mail',
    ContactCustomerViaEmailSuccess:
      'Contact customer via mail status has been saved successfully.',
    IboNoQuestions: `Questions of WOIBO `,
    Wo3Caption: 'Request to move shipment to warehouse ',
    Wo6Caption: `Questions of WO6 `,
    Required: 'Please select contact customer via mail',
    WosCaption: `Questions of WOS `,
    Wo6CaptionShipmentNA: `Shipment not available in warehouse `,
  },
  State: {
    Name: 'Name',
    DeletedSuccessfully: 'Deleted Successfully',
    DeleteMessage: 'Are you sure you want to delete State?',
    DeleteState: 'Delete State',
    EditState: 'Edit State',
    AddState: 'Add State',
    Restriction: 'Restriction',
    ApplicableTo: 'Applicable To',
    All: 'All',
    WO4: 'WO4',
    WO6: 'WO6',
    ShippingWOBooking: 'Shipping WO-Booking',
    ProperDataValidate: 'Please enter proper data',
    FieldRequired: 'field is required',
    Country: 'Country',
    StateRestrictions: 'State Restrictions',
  },
  Airline: {
    Name: 'Name',
    DeletedSuccessfully: 'Deleted Successfully',
    DeleteMessage: 'Are you sure you want to delete Airline?',
    DeleteAirline: 'Delete Airline',
    EditAirline: 'Edit Airline',
    AddAirline: 'Add Airline',
    Restriction: 'Restriction',
    ApplicableTo: 'Applicable To',
    All: 'All',
    WO4: 'WO4',
    WO6: 'WO6',
    ShippingWOBooking: 'Shipping WO-Booking',
    ProperDataValidate: 'Please enter proper data',
    FieldRequired: 'field is required',
    AirlineRestrictions: 'Airline Restrictions',
  },
  CountryRegion: {
    Base: 'Base',
    Europe: 'Europe',
    Export: 'Export',
  },
};

export default enLabels;
