import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import I18n from 'utilities/i18n';
import { Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import http from '../../utilities/httpService';
import { toast } from 'react-toastify';
import environment from 'environment';
import FormControl from 'common/formControl/formControl';
import DecimalInput from 'common/DecimalInput/DecimalInput';
import { ProductPurchase } from 'models/product-catalogue';

export default function AddProductPurchase(props: any) {
  const { isOpen, handleClose, title, entityId, editData } = props;

  const [values, setValues] = useState<ProductPurchase>({
    productCatalogueId: entityId,
    date: new Date(),
    quantity: '',
    supplier: '',
    pricePerUnit: '',
    remarks: '',
    id: 0,
    isManual: true,
  });

  const showMessageRefresh = () => {
    toast.success(
      values.id
        ? i18nMessages.updatedSuccessfully
        : i18nMessages.savedSuccessfully
    );
    setDefaultValuesForControls();
    props.refreshData();
  };

  const setDefaultValuesForControls = () => {
    setValues({ ...values, quantity: '' });
    setValues({ ...values, pricePerUnit: '' });
    setValues({ ...values, supplier: '' });
    setValues({ ...values, remarks: '' });
  };

  const [errors, setErrors] = useState({
    quantity: '',
    pricePerUnit: '',
    supplier: '',
    remarks: '',
  });

  const i18nMessages = {
    commonFieldIsRequired: I18n('Common.FieldIsRequired'),
    commonInvalidQuantity: I18n('Common.EnteredQuantityIsNotValid'),
    updatedSuccessfully: I18n(
      'ProductCatalogues.ProductPurchases.UpdatedSuccessfully'
    ),
    savedSuccessfully: I18n(
      'ProductCatalogues.ProductPurchases.SavedSuccessfully'
    ),
  };

  const validate = (fieldValues: any = values) => {
    let temp: any = { ...errors };
    if ('quantity' in fieldValues) {
      temp.quantity = +fieldValues.quantity
        ? ''
        : i18nMessages.commonFieldIsRequired;
    }
    if ('pricePerUnit' in fieldValues) {
      temp.pricePerUnit = fieldValues.pricePerUnit
        ? ''
        : i18nMessages.commonFieldIsRequired;
    }
    if ('supplier' in fieldValues) {
      temp.supplier = fieldValues.supplier
        ? ''
        : i18nMessages.commonFieldIsRequired;
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === '');
    }
  };

  const submit = async () => {
    if (validate()) {
      var purchase = {
        quantity: values.quantity,
        pricePerUnit: values.pricePerUnit,
        supplier: values.supplier,
        remarks: values.remarks,
        productCatalogueId: values.productCatalogueId,
        date: values.date,
        isManual: values.isManual,
      };

      const apiUrl =
        editData === null
          ? new URL(environment.api.baseUrl + 'ProductPurchases')
          : new URL(environment.api.baseUrl + 'ProductPurchases/' + values.id);
      try {
        const result: any =
          editData === null
            ? await http.post(apiUrl.toString(), purchase)
            : await http.put(apiUrl.toString(), purchase);

        if (
          editData == null &&
          result !== null &&
          result.data != null &&
          result.data.success
        ) {
          showMessageRefresh();
        } else if (editData != null && result !== null && result.data) {
          showMessageRefresh();
        } else {
          toast.error(I18n('Common.ErrorOccurred'));
        }
      } catch (error: any) {
        if (error.response) {
          let temp = { ...errors };
          if (error.response.data) {
            for (const item of error.response.data.errors) {
              if (
                item.field === 'Quantity' &&
                item.errorMessage === 'ValidQuantity'
              ) {
                temp.quantity = i18nMessages.commonInvalidQuantity;
              }
            }
          }
          setErrors({
            ...temp,
          });
        }
      }
    }
  };

  const close = () => {
    setDefaultValuesForControls();
    props.handleClose();
  };

  useEffect(() => {
    if (editData) {
      setValues({
        productCatalogueId: editData.productCatalogueId,
        date: editData.date,
        quantity: editData.quantity,
        pricePerUnit: editData.pricePerUnit,
        supplier: editData.supplier,
        remarks: editData.remarks,
        id: editData.id,
        isManual: editData.isManual,
      });
    } else {
      setValues({
        productCatalogueId: entityId,
        date: new Date(),
        quantity: '',
        supplier: '',
        pricePerUnit: '',
        remarks: '',
        id: 0,
        isManual: true,
      });
    }
  }, [editData]);

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={'sm'}
        fullWidth={true}
      >
        <DialogTitle>
          {title}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className="grid-wrap">
            <Grid item lg={6} md={8}>
              <DecimalInput
                required
                displayLabel={I18n(
                  'ProductCatalogues.ProductPurchases.Quantity'
                )}
                name="quantity"
                inputProps={{ maxLength: 10 }}
                handleChange={(event: any) => {
                  setValues({
                    ...values,
                    quantity: event.target.value,
                  });
                }}
                errorValue={true}
                errorMessage={errors.quantity}
                value={values.quantity}
              ></DecimalInput>
            </Grid>
            <Grid item lg={6} md={8}>
              <DecimalInput
                required
                displayLabel={I18n(
                  'ProductCatalogues.ProductPurchases.PricePerUnit'
                )}
                name="pricePerUnit"
                inputProps={{ maxLength: 10 }}
                handleChange={(event: any) => {
                  setValues({
                    ...values,
                    pricePerUnit: event.target.value,
                  });
                }}
                errorValue={true}
                errorMessage={errors.pricePerUnit}
                value={values.pricePerUnit}
              ></DecimalInput>
            </Grid>
            <Grid item lg={6} md={8}>
              <FormControl
                required
                margin="normal"
                displayLabel={I18n(
                  'ProductCatalogues.ProductPurchases.Supplier'
                )}
                id="Supplier"
                name="supplier"
                inputType="text"
                inputProps={{ maxLength: 50 }}
                multiline={false}
                handleChange={(event: any) => {
                  setValues({
                    ...values,
                    supplier: event.target.value,
                  });
                }}
                errorValue={true}
                errorMessage={errors.supplier}
                value={values.supplier}
              ></FormControl>
            </Grid>
            <Grid item lg={6} md={8}>
              <FormControl
                margin="normal"
                displayLabel={I18n(
                  'ProductCatalogues.ProductPurchases.Remarks'
                )}
                id="Remarks"
                name="remarks"
                inputType="text"
                inputProps={{ maxLength: 150 }}
                multiline={false}
                handleChange={(event: any) => {
                  setValues({
                    ...values,
                    remarks: event.target.value,
                  });
                }}
                errorValue={true}
                errorMessage={errors.remarks}
                value={values.remarks}
              ></FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="btn-wrap">
          <Button
            onClick={close}
            color="neutral"
            disableElevation
            variant="contained"
          >
            {' '}
            {I18n('Common.Cancel')}
          </Button>
          <Button
            onClick={submit}
            autoFocus
            color="primary"
            disableElevation
            variant="contained"
          >
            {I18n('Common.Save')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
