import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import RouteEnum from 'models/RouteEnum';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import I18n from 'utilities/i18n';
import Link from '@mui/material/Link';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import BasicDetails from './BasicDetails';
import './AddShippingOrder.scss';
import { ShippingOrderDTO, WizardSubmitType } from 'models/shipping-orders';
import ShippingMaterial from './ShippingMaterials';
import ConfirmationPopup from 'common/confirmationPopup/confirmationPopup';
import environment from 'environment';
import http from '../../utilities/httpService';
import { toast } from 'react-toastify';
import { ApiResponse, EntityTypeEnum } from 'models/pagination-model';
import { useLocation } from 'react-router-dom';
import ShippingPackageList from './ShippingPackage/ShippingPackageList';
import OrderDocumentList from './ShippingOrderDocuments/OrderDocumentList';
import { Permission } from 'Permissions';
import { hasPermission } from 'utilities/protectedRoute';
import {
  getContactByUserId,
  getCustomerDetailById,
  sendOrderCreateEmail,
} from './ShippingApiService';
import { Utilities } from 'utilities/Utilities';
import NotificationDetails from 'common/Notification/NotificationDetails';
export default function AddShippingOrder() {
  const steps = [
    I18n('ShippingOrders.Steps.BasicDetails'),
    I18n('ShippingOrders.Steps.PackageDetails'),
    I18n('ShippingOrders.Steps.Materials'),
    I18n('ShippingOrders.Tabs.NotificationDetails'),
  ];
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const handleListClick = () => {
    navigate(RouteEnum.ShippingOrdersList);
  };
  const [isDeleteModalOpen, setDeleteModal] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(id);
  const loggedInUserId = localStorage.getItem('userId');
  const customerPortalUser = Utilities.getIsCPUser();
  const [contactPerson, setContactPerson] = useState(null);
  const [customerDetails, setCustomerDetails] = useState(null);
  const breadcrumbs = [
    <Typography key="3" color="text.primary">
      {I18n('Customer.Shipping')}
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={handleListClick}
        className="cpointer"
      >
        {I18n('Nav.Order')}
      </Link>
    </Typography>,
    <Typography key="3" color="text.primary">
      {I18n('ShippingOrders.Add')}
    </Typography>,
  ];

  useEffect(() => {
    if (customerPortalUser) {
      getContactByUser();
    }
  }, []);

  const getContactByUser = async () => {
    if (loggedInUserId) {
      const contactPerson = await getContactByUserId(Number(loggedInUserId));
      if (contactPerson?.result) {
        setContactPerson(contactPerson.data);
        getCustomerById(Number(contactPerson.data.entityId));
      }
    }
  };

  const getCustomerById = async (id: number) => {
    const customerDetails = await getCustomerDetailById(id);
    if (customerDetails?.result) {
      setCustomerDetails(customerDetails.data);
    }
  };

  const handleSubmit = async (event: any) => {
    setWizardSubmitType(WizardSubmitType.Submit);
    if (activeStep === 0) {
      setIsBasicDetailsSubmit(true);
      event.preventDefault();
    } else if (activeStep === 2) {
      setIsShippingMaterialSubmit(true);
      event.preventDefault();
    } else if (activeStep === 5) {
      setIsNotificationDetailsSubmit(true);
      event.preventDefault();
    } else {
      performWizardSubmit(WizardSubmitType.Submit);
    }
  };
  const [isBasicDetailsSubmit, setIsBasicDetailsSubmit] =
    useState<boolean>(false);
  const [isShippingMaterialSubmit, setIsShippingMaterialSubmit] =
    useState<boolean>(false);
  const [basicDetails, setBasicDetails] = useState<ShippingOrderDTO | null>(
    null
  );
  const [wizardSubmitType, setWizardSubmitType] =
    useState<WizardSubmitType | null>(null);
  const [activeStep, setActiveStep] = useState(0); //TODO
  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({});
  const [isNotificationDetailsSubmit, setIsNotificationDetailsSubmit] =
    useState<boolean>(false);
  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    if (customerPortalUser) {
      return activeStep === totalSteps() - 1;
    } else {
      return activeStep === totalSteps() - 2;
    }
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    handleComplete();
    if (activeStep === 0) {
      setIsBasicDetailsSubmit(true);
      setWizardSubmitType(WizardSubmitType.Next);
    } else if (activeStep === 5) {
      setIsNotificationDetailsSubmit(true);
      setWizardSubmitType(WizardSubmitType.Next);
    } else if (activeStep === 2) {
      setIsShippingMaterialSubmit(true);
      setWizardSubmitType(WizardSubmitType.Next);
    } else {
      setWizardSubmitType(WizardSubmitType.Next);
      performWizardSubmit(WizardSubmitType.Next);
    }
  };

  const performWizardSubmit = (
    action: WizardSubmitType,
    newShippingOrderId: number = 0
  ) => {
    switch (action) {
      case WizardSubmitType.Next:
        const newActiveStep =
          isLastStep() && !allStepsCompleted()
            ? // It's the last step, but not all steps have been completed,
              // find the first step that has been completed
              steps.findIndex((step, i) => !(i in completed))
            : customerPortalUser && activeStep === 1
            ? activeStep + 2
            : activeStep + 1;
        setActiveStep(newActiveStep);
        break;
      case WizardSubmitType.Previous:
        if (customerPortalUser && activeStep === 3) {
          setActiveStep((prevActiveStep) => prevActiveStep - 2);
        } else {
          setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
        break;
      case WizardSubmitType.Submit:
        sendOrderConfirmMail(
          newShippingOrderId > 0 ? newShippingOrderId : Number(id)
        );
        if (customerPortalUser) {
          navigate(RouteEnum.ShippingOrdersList);
        } else {
          navigate(
            RouteEnum.ViewShippingOrders +
              '/' +
              (newShippingOrderId > 0 ? newShippingOrderId.toString() : id) +
              '/2'
          );
        }
        break;
      default:
        break;
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      setIsBasicDetailsSubmit(true);
      setWizardSubmitType(WizardSubmitType.Previous);
    } else if (activeStep === 2) {
      setIsShippingMaterialSubmit(true);
      setWizardSubmitType(WizardSubmitType.Previous);
    } else if (activeStep === 5) {
      setIsNotificationDetailsSubmit(true);
      setWizardSubmitType(WizardSubmitType.Previous);
    } else {
      setWizardSubmitType(WizardSubmitType.Previous);
      performWizardSubmit(WizardSubmitType.Previous);
    }
  };
  const handleCancelOrder = () => {
    setDeleteModal(true);
    setSelectedOrderId(id);
  };
  const handleBasicDetailsSubmit = (
    response: ApiResponse<ShippingOrderDTO | null>
  ) => {
    if (response.result) {
      if (id && +id) {
        if (response.result) {
          performWizardSubmit(
            wizardSubmitType ? wizardSubmitType : WizardSubmitType.Next
          );
        }
      } else {
        setBasicDetails(response.data);
        if (response.result && response.data) {
          navigate(RouteEnum.AddShippingOrders + `/${response.data.id}`);
          performWizardSubmit(
            wizardSubmitType ? wizardSubmitType : WizardSubmitType.Next,
            response.data.id
          );
        }
      }
    }
  };

  const handleNotificationDetailsSubmit = (response: any) => {
    if (response && response.result) {
      performWizardSubmit(
        wizardSubmitType ? wizardSubmitType : WizardSubmitType.Submit
      );
    }
  };

  const handleSetIsSubmit = () => {
    setIsBasicDetailsSubmit(false);
  };

  const handleSetIsSubmitShippingMaterial = () => {
    setIsShippingMaterialSubmit(false);
    performWizardSubmit(
      wizardSubmitType ? wizardSubmitType : WizardSubmitType.Next
    );
  };

  const handleSetIsNotificationSubmit = () => {
    setIsNotificationDetailsSubmit(false);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const handleCloseForDelete = () => {
    setDeleteModal(false);
  };

  const handleOkForDelete = () => {
    setDeleteModal(false);
    deleteData(selectedOrderId);
  };
  const i18nMessages = {
    deletedSuccessfully: I18n('ContactPerson.DeletedSuccessfully'),
    errorOccurred: I18n('ContactPerson.ErrorOccurred'),
    MaterialsStep: I18n('ShippingOrders.Steps.Materials'),
    NotificationDetails: I18n('ShippingOrders.Tabs.NotificationDetails'),
    somethingWentWrong: I18n('Common.SomethingWentWrong'),
    CommonSaveSuccessMsg: I18n('Common.SavedSuccessfully'),
    sendEmailSuccessfully: I18n('SendEmail.SendSuccessfully'),
  };
  const deleteData = async (id: any) => {
    const apiUrl = new URL(environment.api.baseUrl + 'Shipping/' + id);
    try {
      const result: any = await http.delete(apiUrl.toString());
      if (result != null && result.data != null && result.data.success) {
        toast.success(i18nMessages.deletedSuccessfully);
        navigate(RouteEnum.ShippingOrdersList);
      } else {
        toast.error(i18nMessages.errorOccurred);
      }
    } catch (error: any) {}
  };

  const onCancel = () => {
    navigate(RouteEnum.ShippingOrdersList);
  };

  const sendOrderConfirmMail = (orderId: number) => {
    if (customerPortalUser) {
      sendOrderCreateEmail(Number(orderId)).then(
        (response: { result: any }) => {
          if (response && response.result) {
            toast.success(i18nMessages.sendEmailSuccessfully);
          } else {
            toast.error(i18nMessages.somethingWentWrong);
          }
        }
      );
    }
  };

  return (
    <div>
      <div className="heading-section">
        <div className="heading-section-left">
          <Typography variant="h3" sx={{ mb: 3 }}>
            {I18n('ShippingOrders.Add')}
          </Typography>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>
      </div>
      <div className="main-content-section">
        <form className="form-content">
          <div className="inner-section">
            <Box
              display="flex"
              justifyContent="center"
              className="stepper-outer"
            >
              <Stepper
                nonLinear
                activeStep={activeStep}
                className="stepwizard"
                connector={null}
              >
                {steps.map((label, index) => {
                  const stepProps: { completed?: boolean } = {};
                  const labelProps: {
                    optional?: React.ReactNode;
                    error?: boolean;
                  } = {};
                  if (completed[index]) {
                    stepProps.completed = true;
                  }
                  return (
                    <>
                      {(label === i18nMessages.MaterialsStep &&
                        customerPortalUser) ||
                      (label === i18nMessages.NotificationDetails &&
                        !customerPortalUser) ? (
                        <></>
                      ) : (
                        <Step key={label} {...stepProps}>
                          <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                      )}
                    </>
                  );
                })}
              </Stepper>
            </Box>
            {activeStep === 0 && (
              <BasicDetails
                isSubmit={isBasicDetailsSubmit}
                handleSubmit={handleBasicDetailsSubmit}
                setIsSubmit={handleSetIsSubmit}
                editPermission={Permission.canEditShippingOrders}
                addPermission={Permission.canAddShippingOrders}
                deletePermission={Permission.canDeleteShippingOrders}
                cpContactPerson={contactPerson}
                customerDetails={customerDetails}
              ></BasicDetails>
            )}
            {activeStep === 1 && <ShippingPackageList></ShippingPackageList>}
            {activeStep === 2 && (
              <>
                <ShippingMaterial
                  shippingOrderId={id ? id : 0}
                  isAddShippingMaterial={true}
                  permission={Permission.canEditShippingOrders}
                  isOffer={false}
                  isSubmit={isShippingMaterialSubmit}
                  setIsSubmit={handleSetIsSubmitShippingMaterial}
                ></ShippingMaterial>
              </>
            )}
            {activeStep === 3 && (
              <NotificationDetails
                shippingOrderId={id ? id : 0}
                isSubmit={isNotificationDetailsSubmit}
                setIsSubmit={handleSetIsNotificationSubmit}
                isAddShipping={true}
                handleSubmitAdd={handleNotificationDetailsSubmit}
              />
            )}
          </div>
          <Box
            display="flex"
            justifyContent="space-between"
            mt={4}
            className="btn-wrap"
          >
            <Box>
              {!(activeStep === 0) && (
                <Button
                  color="primary"
                  disableElevation
                  variant="outlined"
                  onClick={handleBack}
                >
                  {I18n('Common.Previous')}
                </Button>
              )}
            </Box>
            {hasPermission(Permission.canAddShippingOrders) ? (
              <Box>
                {hasPermission(Permission.canDeleteShippingOrders) ? (
                  <Button
                    color="neutral"
                    disableElevation
                    variant="contained"
                    disabled={Number(id) && Number(id) > 0 ? false : true}
                    onClick={handleCancelOrder}
                  >
                    {I18n('ShippingOrders.Cancel')}
                  </Button>
                ) : (
                  <>
                    {customerPortalUser && (
                      <Button
                        color="neutral"
                        disableElevation
                        variant="contained"
                        onClick={onCancel}
                      >
                        {I18n('Common.Cancel')}
                      </Button>
                    )}
                  </>
                )}
                <Button
                  color="primary"
                  disableElevation
                  variant="contained"
                  onClick={handleSubmit}
                >
                  {I18n('Common.Submit')}
                </Button>
                {!isLastStep() && (
                  <Button
                    color="secondary"
                    disableElevation
                    variant="contained"
                    onClick={handleNext}
                  >
                    {I18n('Common.Next')}
                  </Button>
                )}
              </Box>
            ) : (
              <></>
            )}
          </Box>
        </form>
      </div>

      <ConfirmationPopup
        isOpen={isDeleteModalOpen}
        data={selectedOrderId}
        message={I18n('ShippingOrders.DeleteMessage')}
        handleClose={handleCloseForDelete}
        handleOk={handleOkForDelete}
        title={I18n('ShippingOrders.DeleteTitle')}
      ></ConfirmationPopup>
    </div>
  );
}
