import I18n from 'utilities/i18n';
import { useEffect, useCallback, useState } from 'react';
import http from '../../utilities/httpService';
import { EntityTypeEnum, OrderByDirection } from 'models/pagination-model';
import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faPlus } from '@fortawesome/free-solid-svg-icons';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Location as EntityLocation } from 'models/location';
import InputAdornment from '@mui/material/InputAdornment';
import CustomDataGrid from 'common/datagrid/datagrid';
import { useParams } from 'react-router-dom';
import CustomizedInputs from 'common/formControl/formControl';
import Typography from '@mui/material/Typography';
import {
  GridActionsCellItem,
  GridColumns,
  GridPaginationInitialState,
  GridRowId,
  GridRowParams,
  GridSortingInitialState,
  GridSortModel,
} from '@mui/x-data-grid';
import environment from 'environment';
import AddLocation from './AddLocation';
import EditLocation from './EditLocation';
import ConfirmationPopup from 'common/confirmationPopup/confirmationPopup';
import { toast } from 'react-toastify';
import { hasPermission } from 'utilities/protectedRoute';

interface LocationProps {
  entityType: EntityTypeEnum;
  isHidden?: boolean;
  addPermission: string;
  editPermission: string;
  deletePermission: string;
}

function LocationList(props: LocationProps) {
  const isHidden = props.isHidden;
  const [isDeleteModalOpen, setDeleteModal] = useState(false);
  let { entityId } = useParams();
  const [isAddModalOpen, setAddModal] = useState(false);
  const [isEditModalOpen, setEditModal] = useState(false);
  const [data, setData] = useState(null);
  const [selectedLocationID, setLocationId] = useState();
  const [canAdd, setCanAdd] = useState(hasPermission(props.addPermission));
  const [canEdit, setCanEdit] = useState(hasPermission(props.editPermission));
  const [canDelete, setCanDelete] = useState(
    hasPermission(props.deletePermission)
  );
  const [gridData, setGridData] = useState({
    isLoading: true,
    sortOrder: OrderByDirection.Descending,
    sortBy: 'country.name',
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [10, 20, 50, 100],
    pageSize: 10,
    page: 0,
    searchExpression:
      'EntityType~=~' + props.entityType + ' and EntityId~=~' + entityId,
  });
  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));
  const getCustomerLocations = async () => {
    updateGridData('isLoading', true);

    const apiUrl = new URL(environment.api.baseUrl + 'Locations');
    try {
      apiUrl.searchParams.set('$filter', gridData.searchExpression);
      apiUrl.searchParams.set('$orderby', gridData.sortBy);
      apiUrl.searchParams.set(
        '$skip',
        (gridData.page * gridData.pageSize).toString()
      );
      apiUrl.searchParams.set('$top', gridData.pageSize.toString());
      apiUrl.searchParams.set(
        '$orderbydirection',
        gridData.sortOrder.toString()
      );
      const result = await http.get(apiUrl.toString());
      if (result) {
        updateGridData('totalRows', result.data.totalCount);
        updateGridData('rows', result.data.values);
        updateGridData('isLoading', false);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getCustomerLocations();
  }, [
    gridData.page,
    gridData.pageSize,
    gridData.sortBy,
    gridData.sortOrder,
    gridData.searchExpression,
  ]);

  const handleChangePage = (pageNo: number) => {
    updateGridData('page', pageNo);
  };

  const handleChangeBasicSearch = (searchExpression: any) => {
    updateGridData(
      'searchExpression',
      'city.name~like~' +
        searchExpression.target.value +
        ' or country.name~like~' +
        searchExpression.target.value +
        ' or zipcode~like~' +
        searchExpression.target.value +
        ' or address~like~' +
        searchExpression.target.value +
        ' and EntityType~=~' +
        props.entityType +
        ' and EntityId~=~' +
        entityId
    );
  };
  const handleChangePageSize = (pageSize: number) => {
    updateGridData('pageSize', pageSize);
  };
  type Row = (typeof gridData.rows)[number];
  const deleteCustomerLocation = useCallback(
    (id: any) => () => {
      setDeleteModal(true);
      setLocationId(id);
    },
    []
  );

  const editCustomerLocation = (id: GridRowId) => async () => {
    const apiUrl = new URL(environment.api.baseUrl + 'Locations/' + id);
    try {
      const result = await http.get(apiUrl.toString());
      if (result) {
        setData(result.data);
        setEditModal(true);
      }
    } catch {}
  };

  const columns: GridColumns<Row> = [
    {
      field: 'pickupCompany',
      headerName: I18n('CustomerLocation.PickupCompany'),
      width: 168,
      hide: isHidden,
      hideSortIcons: true,
    },
    {
      field: 'destinationCompany',
      headerName: I18n('CustomerLocation.DestinationCompany'),
      width: 168,
      hide: isHidden,
      hideSortIcons: true,
    },
    {
      field: 'street',
      headerName: I18n('CustomerLocation.Address'),
      flex: 1,
    },
    {
      field: 'address2',
      headerName: I18n('CustomerLocation.Address2'),
      flex: 1,
    },
    {
      field: 'zipCode',
      headerName: I18n('Partner.PostalCode'),
      flex: 1,
    },
    {
      field: 'city',
      headerName: I18n('CustomerLocation.City'),
      flex: 1,
    },
    {
      field: 'country',
      headerName: I18n('CustomerLocation.Country'),
      flex: 1,
    },
    {
      field: 'isMainAddress',
      headerName: I18n('CustomerLocation.IsMainAddress'),
      width: 130,
      renderCell: (params) => {
        return params.formattedValue ? I18n('Common.Yes') : I18n('Common.No');
      },
      hide: isHidden,
      sortable: false,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: I18n('Common.Actions'),
      width: 100,
      getActions: (params: GridRowParams<EntityLocation>) => {
        const actionButtons = [];
        if (canEdit) {
          actionButtons.push(
            <GridActionsCellItem
              onPointerEnterCapture={false}
              onPointerLeaveCapture={false}
              placeholder={''}
              icon={<EditIcon />}
              label="Edit"
              onClick={editCustomerLocation(params.id)}
            />
          );
        }
        if (EntityTypeEnum.Customer === params.row.entityType) {
          if (canDelete && params.row.isFromDetailScreen !== true) {
            actionButtons.push(
              <GridActionsCellItem
                onPointerEnterCapture={false}
                onPointerLeaveCapture={false}
                placeholder={''}
                icon={<DeleteIcon />}
                label="Delete"
                onClick={deleteCustomerLocation(params.id)}
              />
            );
          } else {
            actionButtons.push(<div style={{ width: '30px' }}></div>);
          }
        } else {
          if (canDelete) {
            actionButtons.push(
              <GridActionsCellItem
                onPointerEnterCapture={false}
                onPointerLeaveCapture={false}
                placeholder={''}
                icon={<DeleteIcon />}
                label="Delete"
                onClick={deleteCustomerLocation(params.id)}
              />
            );
          }
        }

        return actionButtons;
      },
    },
  ];

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    if (sortModel && sortModel.length) {
      if (sortModel[0].field === 'city') {
        updateGridData('sortBy', 'city.name');
      } else if (sortModel[0].field === 'country') {
        updateGridData('sortBy', 'country.name');
      } else {
        updateGridData('sortBy', sortModel[0].field);
      }
      updateGridData(
        'sortOrder',
        sortModel[0].sort === 'asc'
          ? OrderByDirection.Ascending
          : OrderByDirection.Descending
      );
    }
  }, []);

  const addLocation = useCallback(() => {
    setAddModal(true);
  }, []);

  const handleCloseForAdd = () => {
    setAddModal(false);
  };

  const handleCloseForEdit = () => {
    setEditModal(false);
  };

  const OnSave = async () => {
    handleCloseForAdd();
    handleCloseForEdit();
    await getCustomerLocations();
  };

  const handleCloseForDelete = async () => {
    setDeleteModal(false);
  };

  const handleOkForDelete = () => {
    setDeleteModal(false);
    deleteLocation(selectedLocationID);
  };

  const deleteLocation = async (id: any) => {
    updateGridData('isLoading', true);
    const apiUrl = new URL(environment.api.baseUrl + 'Locations/' + id);
    try {
      const result: any = await http.delete(apiUrl.toString());
      if (result) {
        toast.success('Location deleted successfully');
        updateGridData('isLoading', false);
        await getCustomerLocations();
      }
    } catch (error: any) {}
  };

  return (
    <div>
      <Typography variant="h5" className="inner-heading has-button">
        {I18n('Customer.Tabs.Location')}
        <div className="inner-btn-wrap">
          <div className="text-right container-search">
            <div className="search-control-outer">
              <CustomizedInputs
                margin="normal"
                displayLabel=""
                inputType="text"
                placeholderText="Search"
                handleChange={handleChangeBasicSearch}
                className="search-control small-form-control"
                adornmentValue={
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={faSearch} />
                  </InputAdornment>
                }
              />
            </div>
          </div>
          {canAdd ? (
            <Button
              color="primary"
              className="small-btn"
              disableElevation
              onClick={addLocation}
              variant="contained"
            >
              <FontAwesomeIcon icon={faPlus} className="fa-md" />
            </Button>
          ) : (
            <></>
          )}
        </div>
      </Typography>

      <div className="">
        <div className="content-block table-fix">
          <CustomDataGrid
            rowCount={gridData.totalRows}
            data={gridData.rows}
            columns={columns}
            pageNo={gridData.page}
            pageSize={gridData.pageSize}
            onSortModelChange={handleSortModelChange}
            onPageChange={handleChangePage}
            onPageSizeChange={handleChangePageSize}
            rowsPerPageOptions={gridData.rowsPerPageOptions}
            initialState={{
              sorting: {
                sortModel: [{ field: 'country.name', sort: 'desc' }],
              } as GridSortingInitialState,
              pagination: {
                page: gridData.page,
                pageSize: gridData.pageSize,
              } as GridPaginationInitialState,
            }}
          ></CustomDataGrid>
        </div>

        <AddLocation
          isOpen={isAddModalOpen}
          entityType={props.entityType}
          entityId={entityId}
          OnSave={OnSave}
          handleClose={handleCloseForAdd}
          title={I18n('CustomerLocation.AddLocation')}
          isHidden={isHidden}
        ></AddLocation>

        <EditLocation
          isOpen={isEditModalOpen}
          entityType={props.entityType}
          entityId={entityId}
          handleClose={handleCloseForEdit}
          OnSave={OnSave}
          data={data}
          title={I18n('CustomerLocation.EditLocation')}
          isHidden={isHidden}
        ></EditLocation>

        <ConfirmationPopup
          isOpen={isDeleteModalOpen}
          data={selectedLocationID}
          message={I18n('CustomerLocation.DeleteConfirmation')}
          handleClose={handleCloseForDelete}
          handleOk={handleOkForDelete}
        ></ConfirmationPopup>
      </div>
    </div>
  );
}

export default LocationList;
