import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Badge, Breadcrumbs, Chip, Stack } from '@mui/material';
import I18n from 'utilities/i18n';
import Link from '@mui/material/Link';
import { useNavigate, useParams } from 'react-router-dom';
import RouteEnum from 'models/RouteEnum';
import Note from 'common/notes/Notes';
import DocumentList from '../../common/Document/DocumentList';
import { DocumentEntity, EntityTypeEnum } from 'models/pagination-model';
import ShippingOrderDetails from './ShippingOrderDetails';
import ShippingMaterial from './ShippingMaterials';
import ShippingPackageList from './ShippingPackage/ShippingPackageList';
import WorkOrderList from './WrokOrder/WorkOrderList';
import OrderTrackings from 'common/OrderTracking/OrderTrackings';
import { ProductCatalogueAssignmentType } from 'models/product-catalogue';
import { Permission } from 'Permissions';
import environment from 'environment';
import http from '../../utilities/httpService';
import { useEffect, useState } from 'react';
import { Utilities } from 'utilities/Utilities';
import NotificationDetails from 'common/Notification/NotificationDetails';
import OrderApproval from 'components/OrderApproval/OrderApproval';
import ShippingLabels from 'common/ShippingLabels/ShippingLabels';
export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function ShippingOrderTabs() {
  const i18nMessages = {
    notes: I18n('ShippingOrders.Tabs.Notes'),
  };
  let { id, tabId } = useParams();
  const [isOrderEnable, setIsOrderEnable] = useState(true);

  const handleOrderStatus = (value: boolean) => {
    setIsOrderEnable(value);
  };

  const getOrderState = () => {
    if (customerPortalUser !== undefined && customerPortalUser) {
      return customerPortalUser;
    } else {
      return !isOrderEnable;
    }
  };

  let navigate = useNavigate();
  let tabIndex = 0;
  let tabPanIndex = 0;

  const handleListClick = () => {
    navigate(RouteEnum.ShippingOrdersList);
  };
  const breadcrumbs = [
    <Typography key="3" color="text.primary">
      {I18n('Nav.Order')}
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={handleListClick}
        className="cpointer"
      >
        {I18n('Nav.ShippingOrders')}
      </Link>
    </Typography>,
    <Typography key="3" color="text.primary">
      {I18n('ShippingOrders.Details')}
    </Typography>,
  ];
  const [value, setValue] = React.useState(tabId ? Number(tabId) : 0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [totalDocumentValue, settotalDocumentValue] = React.useState(0);
  const [totalNotesValue, settotalNotesValue] = React.useState(0);

  const [externalDocumentValue, setexternalDocumentValue] = React.useState(0);
  const [externalNotesValue, setexternalNotesValue] = React.useState(0);

  const [isPackageExist, setIsPackageExist] = React.useState(false);
  const customerPortalUser = Utilities.getIsCPUser();
  const [isNotificationDetailsSubmit, setIsNotificationDetailsSubmit] =
    React.useState<boolean>(false);

  const getDocumentsValues = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl +
        `Shipping/GetDocumentShippingCount/${id}/${EntityTypeEnum.ShippingOrders}`
    );
    const result = await http.get(apiUrl.toString());
    if (result) {
      settotalDocumentValue(result.data.item1);
      setexternalDocumentValue(result.data.item2);
    }
  };

  const getNotesShippingCount = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl +
        `Shipping/GetNotesShippingCount/${id}/${EntityTypeEnum.ShippingOrders}`
    );
    const result = await http.get(apiUrl.toString());
    if (result) {
      settotalNotesValue(result.data.item1);
      setexternalNotesValue(result.data.item2);
    }
  };

  const RefreshDocumentCount = async () => {
    await getDocumentsValues();
  };

  const RefreshNotesCount = async () => {
    await getNotesShippingCount();
  };

  const getPackageDetailsCount = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl + `Shipping/GetPackageDetailsCount/${id}`
    );
    const result = await http.get(apiUrl.toString());
    if (result) {
      setIsPackageExist(result.data);
    }
  };

  const handleSetIsNotificationSubmit = () => {
    setIsNotificationDetailsSubmit(false);
  };

  useEffect(() => {
    getDocumentsValues();
    getNotesShippingCount();
    getPackageDetailsCount();
  }, []);

  return (
    <div>
      <div className="heading-section">
        <div className="heading-section-left">
          <Typography variant="h3" sx={{ mb: 3 }}>
            {I18n('ShippingOrders.Details')}
          </Typography>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>
      </div>
      <div className="main-content-section width-100">
        <Box>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            className="tab-bordered"
          >
            <Tab
              label={I18n('ShippingOrders.Tabs.Details')}
              {...a11yProps(tabIndex)}
            />
            <Tab
              label={I18n('ShippingOrders.Tabs.Packages')}
              {...a11yProps(++tabIndex)}
            />
            {!customerPortalUser && (
              <Tab
                label={I18n('ShippingOrders.Tabs.WorkOrder')}
                {...a11yProps(++tabIndex)}
              />
            )}
            <Tab
              label={I18n('ShippingOrders.Tabs.Tracking')}
              {...a11yProps(++tabIndex)}
            />
            {!customerPortalUser && (
              <Tab
                label={I18n('ShippingOrders.Tabs.BillableItems')}
                {...a11yProps(++tabIndex)}
              />
            )}
            <Tab
              label={
                <React.Fragment>
                  <Stack direction="row" spacing={2}>
                    {I18n('ShippingOrders.Tabs.Documents')}
                    {!customerPortalUser && (
                      <>
                        <Chip
                          label={totalDocumentValue}
                          color="primary"
                          className="tabBadge"
                        />
                        <Chip
                          label={externalDocumentValue}
                          color="error"
                          className="tabBadge"
                        />
                      </>
                    )}
                  </Stack>
                </React.Fragment>
              }
              {...a11yProps(++tabIndex)}
            />
            {!customerPortalUser && (
              <Tab
                label={I18n('ShippingOrders.Tabs.Labels')}
                {...a11yProps(++tabIndex)}
              />
            )}
            <Tab
              label={
                <React.Fragment>
                  <Stack direction="row" spacing={2}>
                    {i18nMessages.notes}
                    {!customerPortalUser && (
                      <>
                        <Chip
                          label={totalNotesValue}
                          color="primary"
                          className="tabBadge"
                        />
                        <Chip
                          label={externalNotesValue}
                          color="error"
                          className="tabBadge"
                        />
                      </>
                    )}
                  </Stack>
                </React.Fragment>
              }
              {...a11yProps(++tabIndex)}
            />
            {customerPortalUser && (
              <Tab
                label={I18n('ShippingOrders.Tabs.NotificationDetails')}
                {...a11yProps(++tabIndex)}
              />
            )}
            {!customerPortalUser && (
              <Tab
                label={I18n('ShippingOrders.Tabs.Approval')}
                {...a11yProps(++tabIndex)}
              />
            )}
          </Tabs>
        </Box>

        <TabPanel value={value} index={tabPanIndex}>
          <ShippingOrderDetails
            handleOrderStatus={handleOrderStatus}
          ></ShippingOrderDetails>
        </TabPanel>
        <TabPanel value={value} index={++tabPanIndex}>
          <ShippingPackageList
            isSearchable={true}
            isReadOnly={customerPortalUser}
          ></ShippingPackageList>
        </TabPanel>
        {!customerPortalUser && (
          <TabPanel value={value} index={++tabPanIndex}>
            <WorkOrderList isOrderEnable={isOrderEnable}></WorkOrderList>
          </TabPanel>
        )}
        <TabPanel value={value} index={++tabPanIndex}>
          <OrderTrackings
            entityId={id ? +id : 0}
            entityType={ProductCatalogueAssignmentType.ShippingOrder}
            redirect={''}
            editPermission={Permission.canEditShippingOrders}
            addPermission={Permission.canAddShippingOrders}
            deletePermission={Permission.canDeleteShippingOrders}
            isOrderEnable={isOrderEnable}
          ></OrderTrackings>
        </TabPanel>
        {!customerPortalUser && (
          <TabPanel value={value} index={++tabPanIndex}>
            <ShippingMaterial
              shippingOrderId={id ? id : 0}
              isAddShippingMaterial={false}
              isOffer={false}
              permission={Permission.canEditShippingOrders}
              handleOrderStatus={handleOrderStatus}
              excludeOtherTracking={true}
            ></ShippingMaterial>
          </TabPanel>
        )}
        <TabPanel value={value} index={++tabPanIndex}>
          <DocumentList
            permission={Permission.canEditShippingOrders}
            addPermission={Permission.canAddShippingOrders}
            deletePermission={Permission.canDeleteShippingOrders}
            entityType={EntityTypeEnum.ShippingOrders}
            paramsId={id}
            documentEntityType={DocumentEntity.ShippingOrders}
            refreshCount={RefreshDocumentCount}
            isViewOnly={getOrderState()}
          />
        </TabPanel>
        {!customerPortalUser && (
          <TabPanel value={value} index={++tabPanIndex}>
            <ShippingLabels
              permission={Permission.canEditShippingOrders}
              orderId={id}
              isOrderEnable={isOrderEnable}
            ></ShippingLabels>
          </TabPanel>
        )}
        <TabPanel value={value} index={++tabPanIndex}>
          <Note
            permission={Permission.canEditShippingOrders}
            addPermission={Permission.canAddShippingOrders}
            deletePermission={Permission.canDeleteShippingOrders}
            paramsId={id}
            entityType={EntityTypeEnum.ShippingOrders}
            refreshCount={RefreshNotesCount}
            isViewOnly={getOrderState()}
          />
        </TabPanel>
        {customerPortalUser && (
          <TabPanel value={value} index={customerPortalUser ? 5 : 7}>
            <NotificationDetails
              shippingOrderId={id ? id : 0}
              isAddShipping={false}
              isSubmit={false}
              setIsSubmit={handleSetIsNotificationSubmit}
              isViewOnly={getOrderState()}
            />
          </TabPanel>
        )}
        {!customerPortalUser && (
          <TabPanel value={value} index={++tabPanIndex}>
            <OrderApproval
              approvalPermission={Permission.canApproveShippingOrders}
              entityId={id ? id : 0}
              entityType={EntityTypeEnum.ShippingOrders}
              isOrderEnable={isOrderEnable}
              handleIsOrderEnable={handleOrderStatus}
            />
          </TabPanel>
        )}
      </div>
    </div>
  );
}
