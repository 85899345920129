import React, { useEffect, useState, useCallback } from 'react';
import http from '../../utilities/httpService';
import { useNavigate } from 'react-router-dom';
import RouteEnum from 'models/RouteEnum';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import InputAdornment from '@mui/material/InputAdornment';
import CustomizedInputs from 'common/formControl/formControl';
import CustomBreadcrumbs from 'common/Breadcrumbs/Breadcrumbs';
import CustomDatePicker from 'common/dateAdapter/dateAdapter';
import { hasPermission } from 'utilities/protectedRoute';
import { Permission } from 'Permissions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faPlus,
  faFileExcel,
  faFilter,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { ProtectedRoles, RolesListModel } from 'models/rolesListModel';

import Popper, { PopperPlacementType } from '@mui/material/Popper';
import { Button, Grid } from '@mui/material/';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import { toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import environment from 'environment';
import ConfirmationPopup from 'common/confirmationPopup/confirmationPopup';
import I18n from 'utilities/i18n';
import {
  GridActionsCellItem,
  GridColumns,
  GridEventListener,
  GridPaginationInitialState,
  GridRenderCellParams,
  GridRowParams,
  GridSortingInitialState,
  GridSortModel,
} from '@mui/x-data-grid';
import CustomDataGrid from 'common/datagrid/datagrid';
import {
  BaseModel,
  EntityStatus,
  EntityStatusDescription,
  OrderByDirection,
} from 'models/pagination-model';
import CustomizedSelect from 'common/CustomSelect/CustomSelect';
import { debounce } from 'lodash';

const RolesList = () => {
  const defaultValue = {
    value: '0',
    label: I18n('Placeholders.SelectStatus'),
  } as BaseModel;

  const [isDeleteModalOpen, setDeleteModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState<PopperPlacementType>();
  const [selectedRoleID, setRoleId] = useState();
  const [statusFilter, setStatusFilter] = useState(defaultValue);
  const [nameFilter, setNameFilter] = useState('');
  const [advanceSearchExpression, setAdvanceSearchExpression] = useState('');
  const [basicSearchExpression, setBasicSearchExpression] = useState('');

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
  };

  const breadcrumbs = [
    <Typography key="3" color="text.primary">
      {I18n('Nav.Configuration')}
    </Typography>,
    <Typography key="3" color="text.primary">
      {I18n('Nav.Roles')}
    </Typography>,
  ];
  const [gridData, setGridData] = React.useState({
    sortOrder: OrderByDirection.Descending,
    sortBy: 'name',
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [10, 20, 50, 100],
    pageSize: 10,
    page: 0,
    searchExpression: '',
  });

  type Row = (typeof gridData.rows)[number];

  const deleteRole = useCallback(
    (id: any) => () => {
      setDeleteModal(true);
      setRoleId(id);
    },
    []
  );

  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));

  let navigate = useNavigate();

  const getRoles = async () => {
    const apiUrl = new URL(environment.api.baseUrl + 'Roles');
    try {
      let finalSearchTerm = '';
      if (basicSearchExpression && advanceSearchExpression) {
        finalSearchTerm = `${basicSearchExpression} or ${advanceSearchExpression}`;
      } else if (basicSearchExpression && !advanceSearchExpression) {
        finalSearchTerm = basicSearchExpression;
      } else if (!basicSearchExpression && advanceSearchExpression) {
        finalSearchTerm = advanceSearchExpression;
      }
      apiUrl.searchParams.set('$filter', finalSearchTerm);
      apiUrl.searchParams.set('$orderby', gridData.sortBy);
      apiUrl.searchParams.set(
        '$skip',
        (gridData.page * gridData.pageSize).toString()
      );
      apiUrl.searchParams.set('$top', gridData.pageSize.toString());
      apiUrl.searchParams.set(
        '$orderbydirection',
        gridData.sortOrder.toString()
      );
      const result = await http.get(apiUrl.toString());

      if (result) {
        updateGridData('totalRows', result.data.totalCount);
        updateGridData('rows', result.data.values);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getRoles();
  }, [
    gridData.page,
    gridData.pageSize,
    gridData.sortBy,
    gridData.sortOrder,
    gridData.searchExpression,
    advanceSearchExpression,
    basicSearchExpression,
  ]);

  const getStatusDescription = (status: number) => {
    return I18n(EntityStatusDescription.get(status));
  };
  const columns: GridColumns<Row> = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      renderCell: (params) => {
        return params.formattedValue == EntityStatus.Active
          ? I18n('Common.Active')
          : I18n('Common.Inactive');
      },
    },
    {
      field: 'Actions',
      headerName: 'Actions',
      type: 'actions',
      getActions: (params: GridRowParams<RolesListModel>) => {
        const actionButtons = [];
        if (params.row.status !== EntityStatus.Active) {
          actionButtons.push(
            <>
              {hasPermission(Permission.canDeleteRole) ? (
                <GridActionsCellItem
                  onPointerEnterCapture={false}
                  onPointerLeaveCapture={false}
                  icon={<DeleteIcon />}
                  placeholder={''}
                  label="Delete"
                  onClick={deleteRole(params.id)}
                />
              ) : (
                <></>
              )}
            </>
          );
        }
        return actionButtons;
      },
    },
  ];

  const removeRole = async (id: any) => {
    const apiUrl = new URL(environment.api.baseUrl + 'Roles/' + id);
    try {
      const result: any = await http.delete(apiUrl.toString());
      if (result != null && result.data != null && result.data.success) {
        toast.success('Role deleted successfully');
        getRoles();
      }
    } catch (error: any) {}
  };

  const popperHandleClick =
    (newPlacement: PopperPlacementType) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setOpen((prev) => placement !== newPlacement || !prev);
      setPlacement(newPlacement);
    };

  const handleOnRowClick: GridEventListener<'rowClick'> = (params) => {
    if (ProtectedRoles.includes(String(params.row.name).trim())) {
      return;
    }
    navigate('/editRole/' + params.id);
  };

  const handleAddRole = () => {
    navigate(RouteEnum.AddRole);
  };

  const handleChangePage = (pageNo: number) => {
    updateGridData('page', pageNo);
  };

  const handleClose = () => {
    setDeleteModal(false);
  };

  const handleOk = () => {
    setDeleteModal(false);
    removeRole(selectedRoleID);
  };

  const handleChangeBasicSearch = debounce(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setBasicSearchExpression(`name~like~${event.target.value}`);
    },
    500
  );
  const handleChangePageSize = (pageSize: number) => {
    updateGridData('pageSize', pageSize);
  };

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    if (sortModel && sortModel.length) {
      updateGridData('sortBy', sortModel[0].field);
      updateGridData(
        'sortOrder',
        sortModel[0].sort === 'asc'
          ? OrderByDirection.Ascending
          : OrderByDirection.Descending
      );
    }
  }, []);

  let entityStatusList: Array<BaseModel> = [];
  const getStatusList = () => {
    entityStatusList = [defaultValue];
    EntityStatusDescription.forEach((value: string, key: number) => {
      entityStatusList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getStatusList();

  const handleResetClick = () => {
    setNameFilter('');
    setStatusFilter(defaultValue);
    setAdvanceSearchExpression('');
    setOpen(false);
  };

  const handleAdvanceSearchFilterClick = () => {
    let searchExpression = '';
    searchExpression += nameFilter
      ? appendAdvanceSearchExpressions(searchExpression, 'and') +
        `Name~like~${nameFilter}`
      : '';
    searchExpression += +statusFilter.value
      ? appendAdvanceSearchExpressions(searchExpression, 'and') +
        `Status~=~${statusFilter.value}`
      : '';
    setAdvanceSearchExpression(searchExpression);
    setOpen(false);
  };

  const appendAdvanceSearchExpressions = (
    searchExpression: string,
    type: string
  ) => {
    let searchExpressionLocal: string = searchExpression;
    switch (type) {
      case 'and':
        searchExpressionLocal = searchExpressionLocal ? ` and ` : ``;
        break;
      case 'or':
        searchExpressionLocal = searchExpressionLocal ? ` or ` : ``;
        break;
      default:
        break;
    }
    return searchExpressionLocal;
  };

  return (
    <div>
      <div className="heading-section">
        <div className="heading-section-left">
          <Typography variant="h3" sx={{ mb: 3 }}>
            {I18n('Nav.Roles')}
          </Typography>
          <CustomBreadcrumbs breadcrumbs={breadcrumbs} />
        </div>
        <div className="heading-section-right">
          <div className="search-control-outer">
            <CustomizedInputs
              margin="normal"
              displayLabel=""
              required
              inputType="text"
              placeholderText="Search"
              className="search-control small-form-control"
              handleChange={handleChangeBasicSearch}
              adornmentValue={
                <InputAdornment position="start">
                  <FontAwesomeIcon icon={faSearch} />
                </InputAdornment>
              }
            />
          </div>
          <div className="header-btn-wrap">
            <Popper
              open={open}
              anchorEl={anchorEl}
              placement={placement}
              transition
              className="filter-popper"
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper>
                    <Typography variant="h5" className="filter-heading">
                      Filter
                      <IconButton
                        aria-label="delete"
                        onClick={popperHandleClick('bottom-end')}
                      >
                        <FontAwesomeIcon
                          icon={faTimes}
                          className="fa-sm close-icon"
                        />
                      </IconButton>
                    </Typography>
                    <div className="filter-content form-small-gap">
                      <Grid container spacing={2} className="grid-wrap">
                        <Grid item xs={6}>
                          <CustomizedInputs
                            margin="normal"
                            displayLabel={I18n('RoleList.Name')}
                            id="Name"
                            inputType="text"
                            inputProps={{ maxLength: 30 }}
                            multiline={false}
                            handleChange={(
                              event: React.ChangeEvent<
                                HTMLTextAreaElement | HTMLInputElement
                              >
                            ) => setNameFilter(event.target.value)}
                            value={nameFilter}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <CustomizedSelect
                            placeholder={defaultValue.label}
                            options={entityStatusList}
                            isSearchable={false}
                            displayLabel={I18n('Common.Status')}
                            handleChange={(newValue: BaseModel) =>
                              setStatusFilter(newValue)
                            }
                            value={statusFilter}
                          ></CustomizedSelect>
                        </Grid>
                      </Grid>
                      <div className="btn-wrap">
                        <Button
                          color="neutral"
                          disableElevation
                          variant="contained"
                          onClick={handleResetClick}
                        >
                          {I18n('Common.Reset')}
                        </Button>
                        <Button
                          color="primary"
                          disableElevation
                          variant="contained"
                          onClick={handleAdvanceSearchFilterClick}
                        >
                          {I18n('Common.Apply')}
                        </Button>
                      </div>
                    </div>
                  </Paper>
                </Fade>
              )}
            </Popper>
            <Button
              color="info"
              onClick={popperHandleClick('bottom-end')}
              disableElevation
              variant="outlined"
              className="icon-btn is-filtered"
            >
              <FontAwesomeIcon icon={faFilter} className="fa-lg" />
            </Button>
            {hasPermission(Permission.canAddRole) ? (
              <Button
                color="info"
                className="small-btn"
                disableElevation
                variant="contained"
                onClick={handleAddRole}
              >
                <FontAwesomeIcon icon={faPlus} className="fa-md mr-2" />
                Add
              </Button>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div className="main-content-section">
        <div className="content-block table-fix  ">
          <CustomDataGrid
            rowCount={gridData.totalRows}
            data={gridData.rows}
            columns={columns}
            pageNo={gridData.page}
            pageSize={gridData.pageSize}
            onSortModelChange={handleSortModelChange}
            onPageChange={handleChangePage}
            onPageSizeChange={handleChangePageSize}
            onRowClick={handleOnRowClick}
            rowsPerPageOptions={gridData.rowsPerPageOptions}
            initialState={{
              sorting: {
                sortModel: [{ field: 'name', sort: 'desc' }],
              } as GridSortingInitialState,
              pagination: {
                page: gridData.page,
                pageSize: gridData.pageSize,
              } as GridPaginationInitialState,
            }}
          />
          <ConfirmationPopup
            isOpen={isDeleteModalOpen}
            data={selectedRoleID}
            message={I18n('RoleList.DeleteMessage')}
            handleClose={handleClose}
            handleOk={handleOk}
            title={I18n('RoleList.DeleteRole')}
          ></ConfirmationPopup>
        </div>
      </div>
    </div>
  );
};

export default RolesList;
