import {
  GridActionsCellItem,
  GridColumns,
  GridPaginationInitialState,
  GridRenderEditCellParams,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowsProp,
  GridSortModel,
  GridSortingInitialState,
  GridToolbarContainer,
  useGridApiContext,
} from '@mui/x-data-grid';
import CustomDataGridInline from 'common/datagrid/CustomDataGridInline';
import { BaseModel, OrderByDirection } from 'models/pagination-model';
import { useCallback, useEffect, useState } from 'react';
import I18n from 'utilities/i18n';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { toast } from 'react-toastify';
import CustomizedInputs from 'common/formControl/formControl';
import CustomSelectPaginate from 'common/CustomSelect/CustomSelectPaginate';
import { loadProducts } from '../ShippingApiService';
import environment from 'environment';
import http from '../../../utilities/httpService';
import ConfirmationPopup from 'common/confirmationPopup/confirmationPopup';

export function InnerBox(props: any) {
  const {
    canDelete,
    canEdit,
    canAdd,
    shippingOrderId,
    shippingPackageId,
    ownPackageChecked,
  } = props;
  const defaultValue = {
    value: '',
    label: I18n('Placeholders.Select'),
  } as BaseModel;
  const defaultAdditional: any = {
    page: 1,
  };
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [focusedAdd, setFocusedAdd] = useState(false);
  const [isDeleteModalOpen, setDeleteModal] = useState(false);
  const [selectedId, setId] = useState();

  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));
  const [gridData, setGridData] = useState({
    sortOrder: OrderByDirection.Descending,
    sortBy: 'CreatedDate',
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [10, 20, 50, 100],
    pageSize: 10,
    page: 0,
    searchExpression: '',
  });

  const i18nMessages = {
    fieldRequired: I18n('ContactPerson.FieldRequired'),
    save: I18n('Common.Save'),
    deletedSuccessfully: I18n('ContactPerson.DeletedSuccessfully'),
    errorOccurred: I18n('ContactPerson.ErrorOccurred'),
    notProcessed: I18n('ShippingMaterials.NotProcessed'),
    savedSuccessfully: I18n('Common.SavedSuccessfully'),
    deleteInnerBox: I18n('ShippingOrders.ShippingPackage.DeleteInnerBox'),
    deleteInnerBoxDescription: I18n(
      'ShippingOrders.ShippingPackage.DeleteInnerBoxDescription'
    ),
  };

  const handleChangePage = (pageNo: number) => {
    updateGridData('page', pageNo);
  };

  const handleChangePageSize = (pageSize: number) => {
    updateGridData('pageSize', pageSize);
  };
  type Row = (typeof gridData.rows)[number];

  interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (
      newModel: (oldModel: GridRowModesModel) => GridRowModesModel
    ) => void;
  }

  function EditToolbar(props: EditToolbarProps) {
    const handleClick = () => {
      var existingRow = gridData.rows.find((x: any) => x.id === 0);
      if (existingRow === undefined) {
        const id = 0;
        var temp: any = [];
        for (let i = 0; i < gridData.rows.length; i++) {
          temp.push(gridData.rows[i]);
        }
        temp.push({
          id: 0,
          productCatalogueId: defaultValue,
          height: '',
          width: '',
          depth: '',
          remarks: '',
          isNew: true,
        });
        updateGridData('rows', temp);
        updateGridData('totalRows', temp.length);
        setRowModesModel((oldModel) => ({
          ...oldModel,
          [id]: {
            mode: GridRowModes.Edit,
            fieldToFocus: 'materialTypeDescription',
          },
        }));
        setFocusedAdd(true);
      }
    };

    return (
      <GridToolbarContainer>
        {canAdd ? (
          <Button
            color="primary"
            id="addInnerBox"
            startIcon={<AddIcon />}
            onClick={handleClick}
          >
            {I18n('Common.Add')}
          </Button>
        ) : (
          <></>
        )}
      </GridToolbarContainer>
    );
  }

  function HeightEditComponent(props: GridRenderEditCellParams) {
    const { id, value, field } = props;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const apiRef = useGridApiContext();

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value; // The new value entered by the user
      apiRef.current.setEditCellValue({ id, field, value: newValue });
      // allow only in case of edit
    };
    return (
      <CustomizedInputs
        margin="normal"
        id="height"
        name="height"
        inputType="text"
        className="custom-select-wrap mt-0"
        inputProps={{ maxLength: 50 }}
        multiline={true}
        handleChange={handleValueChange}
        value={props.row.height}
        disabled={true}
      ></CustomizedInputs>
    );
  }

  function WidthEditComponent(props: GridRenderEditCellParams) {
    const { id, value, field } = props;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const apiRef = useGridApiContext();

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value; // The new value entered by the user
      apiRef.current.setEditCellValue({ id, field, value: newValue });
      // allow only in case of edit
    };
    return (
      <CustomizedInputs
        margin="normal"
        id="width"
        name="width"
        inputType="text"
        className="custom-select-wrap mt-0"
        inputProps={{ maxLength: 50 }}
        multiline={true}
        handleChange={handleValueChange}
        value={props.row.width}
        disabled={true}
      ></CustomizedInputs>
    );
  }

  function DepthEditComponent(props: GridRenderEditCellParams) {
    const { id, value, field } = props;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const apiRef = useGridApiContext();

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value; // The new value entered by the user
      apiRef.current.setEditCellValue({ id, field, value: newValue });
      // allow only in case of edit
    };
    return (
      <CustomizedInputs
        margin="normal"
        id="depth"
        name="depth"
        inputType="text"
        className="custom-select-wrap mt-0"
        inputProps={{ maxLength: 50 }}
        multiline={true}
        handleChange={handleValueChange}
        value={props.row.depth}
        disabled={true}
      ></CustomizedInputs>
    );
  }

  function RemarksEditComponent(props: GridRenderEditCellParams) {
    const { id, value, field } = props;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const apiRef = useGridApiContext();

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value; // The new value entered by the user
      apiRef.current.setEditCellValue({ id, field, value: newValue });
      // allow only in case of edit
    };
    return (
      <CustomizedInputs
        margin="normal"
        id="remarks"
        name="remarks"
        inputType="text"
        inputProps={{ maxLength: 500 }}
        multiline={true}
        handleChange={handleValueChange}
        value={props.row.remarks}
        disabled={true}
      ></CustomizedInputs>
    );
  }

  const getProductCatalogueById = async (entityId: number) => {
    const apiUrl = new URL(
      environment.api.baseUrl + `ProductCatalogues/${entityId}`
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result && result.data) {
        return {
          dimension1: result.data.dimension1,
          dimension2: result.data.dimension2,
          dimension3: result.data.dimension3,
        };
      }
    } catch (error) {}
  };

  function ProductEditComponent(props: GridRenderEditCellParams) {
    const { id, value, field } = props;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const apiRef = useGridApiContext();
    const handleValueChange = async (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const newValue: any = event; // The new value entered by the user
      apiRef.current.setEditCellValue({ id, field, value: newValue });
      var result = await getProductCatalogueById(+newValue.value);
      if (result) {
        apiRef.current.setEditCellValue({
          id,
          field: 'height',
          value: result.dimension1.toString(),
        });
        apiRef.current.setEditCellValue({
          id,
          field: 'width',
          value: result.dimension2.toString(),
        });
        apiRef.current.setEditCellValue({
          id,
          field: 'depth',
          value: result.dimension3.toString(),
        });
      }
    };

    return (
      <CustomSelectPaginate
        key={props?.row?.selectedProduct?.value}
        additional={defaultAdditional}
        value={props?.row?.selectedProduct}
        loadOptions={(search: string, prevOptions: any, page: any) =>
          loadProducts(search, prevOptions, page)
        }
        handleChange={handleValueChange}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
        }}
        placeholder={defaultValue.label}
        isSearchable={true}
        errorValue={true}
        debounceTimeout={500}
        isMultiSelect={false}
        autoFocus={focusedAdd}
      />
    );
  }

  const columns: GridColumns<Row> = [
    {
      field: 'selectedProduct',
      headerName: I18n('ShippingOrders.ShippingPackage.Product'),
      flex: 1,
      sortable: false,
      editable: true,
      renderCell: (params: any) => {
        return params?.row?.selectedProduct?.label;
      },
      renderEditCell: (params) => {
        console.log(params.row.selectedProduct);
        return rowModesModel[params.id]?.mode === GridRowModes.Edit ? (
          <ProductEditComponent {...params} />
        ) : (
          <></>
        );
      },
    },
    {
      field: 'height',
      headerName: I18n('ShippingOrders.ShippingPackage.Height'),
      sortable: false,
      editable: true,
      flex: 1,
      renderEditCell: (params) => {
        return <HeightEditComponent {...params} />;
      },
    },
    {
      field: 'width',
      headerName: I18n('ShippingOrders.ShippingPackage.Width'),
      sortable: false,
      editable: true,
      flex: 1,
      renderEditCell: (params) => {
        return <WidthEditComponent {...params} />;
      },
    },
    {
      field: 'depth',
      headerName: I18n('ShippingOrders.ShippingPackage.Depth'),
      sortable: false,
      editable: true,
      flex: 1,
      renderEditCell: (params) => {
        return <DepthEditComponent {...params} />;
      },
    },
    {
      field: 'remarks',
      headerName: I18n('ShippingOrders.ShippingPackage.Remarks'),
      sortable: false,
      editable: true,
      hide: !ownPackageChecked,
      flex: 1,
      renderEditCell: (params) => {
        return <RemarksEditComponent {...params} />;
      },
    },
    {
      field: 'actions',
      headerName: I18n('Common.Actions'),
      type: 'actions',
      width: 150,
      getActions: (params: any) => {
        const actionButtons = [];

        const isInEditMode =
          rowModesModel[params.id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          actionButtons.push(
            <GridActionsCellItem
              onPointerEnterCapture={false}
              onPointerLeaveCapture={false}
              icon={<SaveIcon />}
              placeholder={''}
              label="Save"
              onClick={handleSaveClick(params.id)}
            />
          );
          actionButtons.push(
            <GridActionsCellItem
              onPointerEnterCapture={false}
              onPointerLeaveCapture={false}
              icon={<CancelIcon />}
              placeholder={''}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(params.id)}
              color="inherit"
            />
          );
        } else {
          if (canEdit) {
            actionButtons.push(
              <GridActionsCellItem
                onPointerEnterCapture={false}
                onPointerLeaveCapture={false}
                icon={<EditIcon />}
                placeholder={''}
                label="Edit"
                className="textPrimary"
                onClick={handleEditClick(params.id)}
                color="inherit"
              />
            );
          }
          if (canDelete) {
            actionButtons.push(
              <GridActionsCellItem
                onPointerEnterCapture={false}
                onPointerLeaveCapture={false}
                icon={<DeleteIcon />}
                placeholder={''}
                label="Delete"
                onClick={handleDeleteClick(params.id)}
                color="inherit"
              />
            );
          }
        }
        return actionButtons;
      },
    },
  ];

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    setFocusedAdd(true);
  };

  const handleSaveClick = (id: GridRowId) => () => {
    updateGridData(
      'rows',
      gridData.rows.filter(function (row: any) {
        return row.id !== 0;
      })
    );
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    deleteInnerBox(id);
  };

  const deleteInnerBox = (id: any) => {
    setDeleteModal(true);
    setId(id);
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setFocusedAdd(false);
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow: any = gridData.rows.find((row: any) => row.id === id);
    if (editedRow!.isNew) {
      updateGridData(
        'rows',
        gridData.rows.filter((row: any) => row.id !== id)
      );
    }
    getInnerBox();
  };

  const keydown = (event: any) => {
    if (event.altKey && event.keyCode == 65) {
      var addButton = document.getElementById('addInnerBox');
      addButton?.click();
    }
  };

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    if (sortModel && sortModel.length) {
      updateGridData('sortBy', sortModel[0].field);
      updateGridData(
        'sortOrder',
        sortModel[0].sort === 'asc'
          ? OrderByDirection.Ascending
          : OrderByDirection.Descending
      );
    }
  }, []);

  const setModels = (newModel: any) => {
    var result = false;
    Object.keys(newModel).forEach(function (key) {
      var value = newModel[key];
      if (
        value.field === 'selectedProduct' &&
        value.cellToFocusAfter === undefined
      ) {
        result = true;
      }
    });
    if (result) {
      return;
    }

    if (newModel[0] === undefined) {
      setRowModesModel(newModel);
    } else if (
      newModel[0] !== undefined &&
      newModel[0].cellToFocusAfter === 'right'
    ) {
      updateGridData(
        'rows',
        gridData.rows.filter(function (row: any) {
          return row.id !== 0;
        })
      );
      setRowModesModel(newModel);
    }
  };

  const validate = (fieldValues: any) => {
    if ('selectedProduct' in fieldValues) {
      if (
        fieldValues.selectedProduct === undefined ||
        fieldValues.selectedProduct === ''
      ) {
        return 'Product' + i18nMessages.fieldRequired;
      }
    }

    if ('height' in fieldValues) {
      if (fieldValues.height === undefined || fieldValues.height === '') {
        return 'Height ' + i18nMessages.fieldRequired;
      }
    }

    if ('width' in fieldValues) {
      if (fieldValues.width === undefined || fieldValues.width === '') {
        return 'Width ' + i18nMessages.fieldRequired;
      }
    }

    if ('depth' in fieldValues) {
      if (fieldValues.depth === undefined || fieldValues.depth === '') {
        return 'Depth ' + i18nMessages.fieldRequired;
      }
    }

    return '';
  };

  const save = async (values: any) => {
    var validateMessage = '';
    validateMessage = validate(values);
    if (validateMessage === '') {
      const finalObj = {
        Id: values.id,
        ShippingOrderId: +shippingOrderId,
        ShippingPackageId: +shippingPackageId == 0 ? null : +shippingPackageId,
        ProductCatalogueId: +values.selectedProduct.value,
        ProductName: values.selectedProduct.label,
        Height: values.height,
        Width: values.width,
        Depth: values.depth,
        Remarks: values.remarks,
      };

      const apiUrl =
        values.id === 0
          ? new URL(environment.api.baseUrl + 'ShippingPackageInnerBox')
          : new URL(
              environment.api.baseUrl + 'ShippingPackageInnerBox/' + values.id
            );
      try {
        const result: any =
          values.id === 0
            ? await http.post(apiUrl.toString(), finalObj)
            : await http.put(apiUrl.toString(), finalObj);

        if (result !== null && result.data != null) {
          toast.success(i18nMessages.savedSuccessfully);
        } else {
          validateMessage = i18nMessages.errorOccurred;
        }
      } catch (error: any) {
        validateMessage = i18nMessages.errorOccurred;
      }
    }
    return validateMessage;
  };

  async function handleProcessRowUpdate(newRow: GridRowModel) {
    setFocusedAdd(false);
    let updatedRow: any = {};
    if (newRow.id === undefined) {
      updatedRow = { ...newRow, isNew: false, id: 0 };
    } else {
      updatedRow = { ...newRow, isNew: false };
    }
    var validation = await save(updatedRow);

    if (validation === '') {
      if (newRow.id === undefined) {
        var temp: any = [];
        for (var i = 0; i < gridData.rows.length; i++) {
          temp.push(gridData.rows[i]);
        }
        temp.push(updatedRow);
        updateGridData('rows', temp);
        updateGridData(
          'searchExpression',
          (Math.random() + 1).toString(36).substring(7)
        );
        return updatedRow;
      } else {
        updateGridData(
          'rows',
          gridData.rows.map((row: any) =>
            row.id === newRow.id ? updatedRow : row
          )
        );
        updateGridData(
          'searchExpression',
          (Math.random() + 1).toString(36).substring(7)
        );
        return updatedRow;
      }
    } else {
      if (updatedRow.id === 0) {
        var temp1: any = [];
        for (let i = 0; i < gridData.rows.length; i++) {
          temp1.push(gridData.rows[i]);
        }

        var existingRecord = {
          ...updatedRow,
          isNew: true,
          isManual: true,
        };
        updatedRow.isNew = true;
        updatedRow.isManual = true;
        temp1.push(updatedRow);
        updateGridData('rows', temp1);
        updateGridData('totalRows', temp1.length);
        setTimeout(() => {
          var oldData: any = [];
          for (var i = 0; i < temp1.length; i++) {
            if (temp1[i].id == 0) {
              oldData.push(existingRecord);
            } else {
              oldData.push(temp1[i]);
            }
          }
          updateGridData('rows', oldData);

          setTimeout(() => {
            setRowModesModel({
              ...rowModesModel,
              0: {
                mode: GridRowModes.Edit,
                fieldToFocus: 'selectedProduct',
              },
            });
          }, 200);
        }, 200);
      } else {
        setTimeout(() => {
          setRowModesModel({
            ...rowModesModel,
            [updatedRow.id]: {
              mode: GridRowModes.Edit,
              fieldToFocus: 'selectedProduct',
            },
          });
        }, 200);
      }
      toast.error(validation);
      return updatedRow;
    }
  }

  const handleProcessRowUpdateError = useCallback((error: Error) => {
    //toast.error(error.message);
  }, []);

  const handleClose = () => {
    setDeleteModal(false);
  };

  const handleOk = () => {
    setDeleteModal(false);
    deleteData(selectedId);
  };

  const deleteData = async (id: any) => {
    const apiUrl = new URL(
      environment.api.baseUrl + 'ShippingPackageInnerBox/' + id
    );
    try {
      const result: any = await http.delete(apiUrl.toString());
      if (result) {
        toast.success(i18nMessages.deletedSuccessfully);

        getInnerBox();
      } else {
        toast.error(i18nMessages.errorOccurred);
      }
    } catch (error: any) {}
  };

  const getInnerBox = async () => {
    const apiUrl = new URL(environment.api.baseUrl + 'ShippingPackageInnerBox');
    try {
      apiUrl.searchParams.set('$filter', '');
      apiUrl.searchParams.set(
        '$skip',
        (gridData.page * gridData.pageSize).toString()
      );
      apiUrl.searchParams.set('$top', gridData.pageSize.toString());
      apiUrl.searchParams.set(
        '$orderbydirection',
        gridData.sortOrder.toString()
      );
      apiUrl.searchParams.set('shippingOrderId', shippingOrderId);
      apiUrl.searchParams.set('shippingPackageId', shippingPackageId);

      const result = await http.get(apiUrl.toString());
      if (result) {
        for (var i = 0; i < result.data.values.length; i++) {
          result.data.values[i].selectedProduct = {
            value: result.data.values[i].productCatalogueId,
            label: result.data.values[i].productName,
          };
        }
        updateGridData('totalRows', result.data.totalCount);
        updateGridData('rows', result.data.values);
      }
    } catch (error) {}
  };

  useEffect(() => {
    document.addEventListener('keydown', keydown, false);
    getInnerBox();
  }, [
    gridData.page,
    gridData.pageSize,
    gridData.sortBy,
    gridData.sortOrder,
    gridData.searchExpression,
    props.shippingOrderId,
  ]);

  return (
    <div onKeyDown={keydown}>
      <div className="">
        <div className="content-block table-fix">
          <CustomDataGridInline
            rowCount={gridData.totalRows}
            data={gridData.rows}
            columns={columns}
            onSortModelChange={handleSortModelChange}
            pageNo={gridData.page}
            pageSize={gridData.pageSize}
            rowModesModel={rowModesModel}
            onRowModesModelChange={(newModel: any) => {
              setModels(newModel);
            }}
            onPageChange={handleChangePage}
            onPageSizeChange={handleChangePageSize}
            rowsPerPageOptions={gridData.rowsPerPageOptions}
            initialState={{
              sorting: {
                sortModel: [{ field: 'updatedDate', sort: 'desc' }],
              } as GridSortingInitialState,
              pagination: {
                page: gridData.page,
                pageSize: gridData.pageSize,
              } as GridPaginationInitialState,
            }}
            components={{
              Toolbar: EditToolbar,
            }}
            componentsProps={{
              toolbar: { setRows, setRowModesModel },
            }}
            processRowUpdate={handleProcessRowUpdate}
            onProcessRowUpdateError={handleProcessRowUpdateError}
          ></CustomDataGridInline>

          <ConfirmationPopup
            isOpen={isDeleteModalOpen}
            data={selectedId}
            title={i18nMessages.deleteInnerBox}
            handleClose={handleClose}
            handleOk={handleOk}
            message={i18nMessages.deleteInnerBoxDescription}
          ></ConfirmationPopup>
        </div>
      </div>
    </div>
  );
}
