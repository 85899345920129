import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

// import "bootstrap/dist/css/bootstrap.css";
import './styles/app.scss';

import 'react-toastify/dist/ReactToastify.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Store } from 'redux';
import { Provider, useSelector } from 'react-redux';
import { createBrowserHistory, History } from 'history';
import rootStore from './stores/rootStore';
import App from 'App';
import environment from 'environment';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { createTheme, ThemeProvider } from '@mui/material/styles';

(async (window: Window): Promise<void> => {
  const initialState: any = {};
  const history: History = createBrowserHistory({
    basename: environment.route.baseRoute,
  });
  const store: Store = rootStore(initialState, history);

  const theme = createTheme({
    typography: {
      fontFamily: 'Sofia Pro',
    },
    palette: {
      neutral: {
        main: '#F5F8FA',
        contrastText: '#6281AA',
      },
    },
  });

  const rootEl: HTMLElement | null = document.getElementById('root');
  const render = (Component: any, el: HTMLElement | null): void => {
    ReactDOM.render(
      <Provider store={store}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <Component history={history} dispatch={store.dispatch} />
          </ThemeProvider>
        </BrowserRouter>
      </Provider>,
      el
    );
  };

  render(App, rootEl);
})(window);

serviceWorkerRegistration.register();
