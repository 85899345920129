import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import I18n from 'utilities/i18n';
import { useEffect, useCallback, useState } from 'react';
import http from '../../../utilities/httpService';
import {
  EntityTypeEnum,
  OrderByDirection,
  ViewLevel,
} from 'models/pagination-model';
import { Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faPlus } from '@fortawesome/free-solid-svg-icons';
import DeleteIcon from '@mui/icons-material/Delete';
import { Document as EntityDocument } from 'models/document';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from 'common/formControl/formControl';
import CustomDataGrid from 'common/datagrid/datagrid';
import DownloadIcon from '@mui/icons-material/Download';
import CachedIcon from '@mui/icons-material/Cached';
import { useParams } from 'react-router-dom';
import CustomizedInputs from 'common/formControl/formControl';
import {
  GridActionsCellItem,
  GridColumns,
  GridPaginationInitialState,
  GridRowId,
  GridRowParams,
  GridSortingInitialState,
  GridSortModel,
} from '@mui/x-data-grid';
import environment from 'environment';
import moment from 'moment';
import FileUpload from 'common/fileupload/fileupload';
import OrderDocumentAdd from './OrderDocumentAdd';
import ConfirmationPopup from 'common/confirmationPopup/confirmationPopup';
import { toast } from 'react-toastify';
import { hasPermission } from 'utilities/protectedRoute';
import { Permission } from 'Permissions';
import saveAs from 'common/fileSaver/FileSaver';
import ApiUrlConstants from 'constants/api.constants';
import { generateShippingDocuments } from 'components/shipping-orders/ShippingApiService';
import { Utilities } from 'utilities/Utilities';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';

interface DocumentProps {
  entityType: EntityTypeEnum;
  shippingOrderId: any;
  isSendOfferEmail?: boolean;
}

function OrderDocumentList(props: DocumentProps) {
  const paramsData = useParams();
  const [isPackageExist, setIsPackageExist] = useState(false);
  const [isDeleteModalOpen, setDeleteModal] = useState(false);
  const [selectedDocumentID, setDocumentId] = useState();
  const [gridData, setGridData] = useState({
    sortOrder: OrderByDirection.Descending,
    sortBy: 'isSystemGenerated',
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [10, 20, 50, 100],
    pageSize: 10,
    page: 0,
    searchExpression:
      'EntityType~=~' +
      props.entityType +
      ' and EntityId~=~' +
      props.shippingOrderId,
    isLoading: true,
  });
  const [files, setFiles] = useState([]);
  const [isAddModalOpen, setAddModal] = useState(false);
  const i18nMessages = {
    fileNotDownloaded: I18n('Document.FileNotDownloaded'),
    sendEmailSuccessfully: I18n('WorkOrder.SendEmailSuccessfully'),
  };
  const customerPortalUser = Utilities.getIsCPUser();
  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));
  const getDocuments = async () => {
    updateGridData('isLoading', true);

    const apiUrl = new URL(environment.api.baseUrl + 'Documents');
    try {
      apiUrl.searchParams.set('$filter', gridData.searchExpression);
      apiUrl.searchParams.set('$orderby', gridData.sortBy);
      apiUrl.searchParams.set(
        '$skip',
        (gridData.page * gridData.pageSize).toString()
      );
      apiUrl.searchParams.set('$top', gridData.pageSize.toString());
      apiUrl.searchParams.set(
        '$orderbydirection',
        gridData.sortOrder.toString()
      );
      const result = await http.get(apiUrl.toString());
      if (result) {
        updateGridData('totalRows', result.data.totalCount);
        updateGridData('rows', result.data.values);
        updateGridData('isLoading', false);
      }
    } catch (error) {}
  };

  const OnSave = async () => {
    handleCloseForAdd();
    setFiles([]);
    await getDocuments();
  };

  const handleCloseForAdd = () => {
    setAddModal(false);
  };

  const sendOfferEmail = async () => {
    let apiUrl = null;
    if (props.entityType === EntityTypeEnum.ShippingOrders) {
      apiUrl = new URL(
        environment.api.baseUrl +
          'ShippingOffers/SendShippingOfferEmail/' +
          props.shippingOrderId
      );
      await http.put(apiUrl.toString());
    } else if (props.entityType === EntityTypeEnum.DecPackOrders) {
    }
    toast.success(i18nMessages.sendEmailSuccessfully);
  };

  useEffect(() => {
    getPackageDetailsCount();
    getDocuments();
  }, [
    gridData.page,
    gridData.pageSize,
    gridData.sortBy,
    gridData.sortOrder,
    gridData.searchExpression,
    files,
  ]);

  const handleChangePage = (pageNo: number) => {
    updateGridData('page', pageNo);
  };

  const handleChangeBasicSearch = (searchExpression: any) => {
    updateGridData(
      'searchExpression',
      'name~like~' +
        searchExpression.target.value +
        ' or createdBy~like~' +
        searchExpression.target.value +
        ' and EntityType~=~' +
        props.entityType +
        ' and EntityId~=~' +
        props.shippingOrderId
    );
  };
  const handleChangePageSize = (pageSize: number) => {
    updateGridData('pageSize', pageSize);
  };
  type Row = (typeof gridData.rows)[number];
  const deleteDocument = useCallback(
    (id: any) => () => {
      setDeleteModal(true);
      setDocumentId(id);
    },
    []
  );

  const getPackageDetailsCount = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl +
        `Shipping/GetPackageDetailsCount/${paramsData.id}`
    );
    const result = await http.get(apiUrl.toString());
    if (result) {
      setIsPackageExist(result.data);
    }
  };

  const saveDocument = useCallback(
    (
        documentUrl: string,
        documentName: string,
        isSystemGenerated: boolean,
        id: number
      ) =>
      async () => {
        if (!isSystemGenerated) {
          let result = null;
          result = await downloadDocuments(documentUrl);
          if (result) {
            const file = new Blob([result.data], { type: result.data.type }); //Build a URL from the file
            const fileURL = window.URL.createObjectURL(file); //Open the URL on new Window
            const link = document.createElement('a');
            link.href = fileURL;
            link.setAttribute('download', documentName);
            link.click();
            document.body.appendChild(link);
          }
        } else {
          const result = await generateShippingDocuments(
            documentName,
            props.shippingOrderId
          );
          if (result) {
            if (result.data.type == 'application/pdf') {
              const file = new Blob([result.data], {
                type: 'application/pdf',
              }); //Build a URL from the file
              const fileURL = window.URL.createObjectURL(file); //Open the URL on new Window
              const link = document.createElement('a');
              link.href = fileURL;
              link.setAttribute('download', documentName);
              link.click();
              document.body.appendChild(link);
            } else if (result.data.type == 'application/vnd.ms-excel') {
              const file = new Blob([result.data], {
                type: 'application/vnd.ms-excel',
              }); //Build a URL from the file
              const fileURL = window.URL.createObjectURL(file); //Open the URL on new Window
              const link = document.createElement('a');
              link.href = fileURL;
              link.setAttribute('download', `CustomsInvoice.xls`);
              link.click();
              document.body.appendChild(link);
            }
          } else {
            toast.error(i18nMessages.fileNotDownloaded);
          }
        }
      },
    []
  );

  const downloadDocuments = async (url: string) => {
    const apiUrl = new URL(
      environment.api.baseUrl + ApiUrlConstants.DownloadImageUrl + url
    );
    try {
      const result = await http.get(apiUrl.toString(), {
        responseType: 'blob',
      });
      return result;
    } catch (error) {
      toast.error(i18nMessages.fileNotDownloaded);
      return null;
    }
  };

  const columns: GridColumns<Row> = [
    {
      field: 'documentTagName',
      headerName: I18n('Common.DocumentTag'),
      flex: 1,
    },
    {
      field: 'name',
      headerName: I18n('Document.Document'),
      flex: 1,
    },
    {
      field: 'createdBy',
      headerName: I18n('Document.AddedBy'),
      flex: 1,
    },
    {
      field: 'createdDate',
      headerName: I18n('Document.AddedOn'),
      flex: 1,
      valueFormatter: (params) => moment(params?.value).format('DD/MM/YYYY'),
    },
    {
      field: 'viewLevel',
      headerName: I18n('ViewLevel.Label'),
      flex: 1,
      hide: customerPortalUser,
      renderCell: (params) => {
        if (params.formattedValue === ViewLevel.InternalManagement) {
          return (
            <Tooltip title={I18n('ViewLevel.Options.InternalManagement')}>
              <div>{I18n('ViewLevel.Options.InternalManagement')}</div>
            </Tooltip>
          );
        } else if (params.formattedValue === ViewLevel.InternalOperational) {
          return (
            <Tooltip title={I18n('ViewLevel.Options.InternalOperational')}>
              <div>{I18n('ViewLevel.Options.InternalOperational')}</div>
            </Tooltip>
          );
        } else if (params.formattedValue === ViewLevel.External) {
          return (
            <Tooltip title={I18n('ViewLevel.Options.External')}>
              <div>{I18n('ViewLevel.Options.External')}</div>
            </Tooltip>
          );
        } else if (params.formattedValue === ViewLevel.InternalAll) {
          return (
            <Tooltip title={I18n('ViewLevel.Options.InternalAll')}>
              <div>{I18n('ViewLevel.Options.InternalAll')}</div>
            </Tooltip>
          );
        } else {
          return '-';
        }
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: I18n('Common.Actions'),
      flex: 1,
      getActions: (params: GridRowParams<EntityDocument>) => {
        const actionButtons = [];
        actionButtons.push(
          <>
            {hasPermission(Permission.canViewShippingOrders) ? (
              <GridActionsCellItem
                onPointerEnterCapture={false}
                onPointerLeaveCapture={false}
                icon={
                  isPackageExist && params.row.name === 'Customs Invoice' ? (
                    <Tooltip title="Generate">
                      <CachedIcon />
                    </Tooltip>
                  ) : params.row.isSystemGenerated &&
                    params.row.name !== 'Customs Invoice' ? (
                    <Tooltip title="Generate">
                      <CachedIcon />
                    </Tooltip>
                  ) : !Boolean(params.row.isSystemGenerated) ? (
                    <Tooltip title="Download">
                      <DownloadIcon />
                    </Tooltip>
                  ) : (
                    <></>
                  )
                }
                label="Download"
                placeholder={''}
                onClick={saveDocument(
                  params.row.documentUrl,
                  params.row.name,
                  params.row.isSystemGenerated,
                  params.row.id
                )}
              />
            ) : (
              <></>
            )}
            {props.isSendOfferEmail &&
            params.row.isSystemGenerated &&
            params.row.name === 'Offer' ? (
              <GridActionsCellItem
                onPointerEnterCapture={false}
                onPointerLeaveCapture={false}
                icon={
                  <Tooltip title="Send Email">
                    <ForwardToInboxIcon />
                  </Tooltip>
                }
                placeholder={''}
                label="SendEmail"
                onClick={sendOfferEmail}
              />
            ) : (
              <></>
            )}
            {hasPermission(Permission.canDeleteShippingOrders) &&
            !Boolean(params.row.isSystemGenerated) ? (
              <GridActionsCellItem
                onPointerEnterCapture={false}
                onPointerLeaveCapture={false}
                icon={<DeleteIcon />}
                placeholder={''}
                label="Delete"
                onClick={deleteDocument(params.id)}
              />
            ) : (
              <></>
            )}
          </>
        );
        return actionButtons;
      },
    },
  ];

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    if (sortModel && sortModel.length) {
      if (sortModel[0].field === 'documentTagName') {
        updateGridData('sortBy', 'MasterCategory.Name');
      } else {
        updateGridData('sortBy', sortModel[0].field);
      }
      updateGridData(
        'sortOrder',
        sortModel[0].sort === 'asc'
          ? OrderByDirection.Ascending
          : OrderByDirection.Descending
      );
    }
  }, []);

  const handleSaveUpload = (files: any) => {
    if (files && files.length > 0) {
      setFiles(files);
      setAddModal(true);
    }
  };

  const handleCloseForDelete = async () => {
    setDeleteModal(false);
  };

  const handleOkForDelete = () => {
    setDeleteModal(false);
    deleteDocumentFunction(selectedDocumentID);
  };

  const deleteDocumentFunction = async (id: any) => {
    updateGridData('isLoading', true);
    const apiUrl = new URL(environment.api.baseUrl + 'Documents/' + id);
    try {
      const result: any = await http.delete(apiUrl.toString());
      if (result) {
        toast.success('Documents deleted successfully');
        updateGridData('isLoading', false);
        await getDocuments();
      }
    } catch (error: any) {}
  };

  return (
    <div>
      <div className="">
        {hasPermission(Permission.canAddShippingOrders) ? (
          <FileUpload
            initialFiles={files}
            filesLimit={1}
            onSave={handleSaveUpload}
            showPreviews={false}
            maxFileSize={10485760}
          />
        ) : (
          <></>
        )}
        <Typography variant="h5" className="inner-heading has-button">
          {I18n('Customer.Tabs.Documents')}
          <div className="inner-btn-wrap">
            <div className="text-right container-search">
              <div className="search-control-outer">
                <CustomizedInputs
                  margin="normal"
                  displayLabel=""
                  inputType="text"
                  placeholderText="Search"
                  handleChange={handleChangeBasicSearch}
                  className="search-control small-form-control"
                  adornmentValue={
                    <InputAdornment position="start">
                      <FontAwesomeIcon icon={faSearch} />
                    </InputAdornment>
                  }
                />
              </div>
            </div>
          </div>
        </Typography>
        <div className="content-block table-fix  ">
          <CustomDataGrid
            rowCount={gridData.totalRows}
            data={gridData.rows}
            columns={columns}
            pageNo={gridData.page}
            pageSize={gridData.pageSize}
            onSortModelChange={handleSortModelChange}
            onPageChange={handleChangePage}
            onPageSizeChange={handleChangePageSize}
            rowsPerPageOptions={gridData.rowsPerPageOptions}
            initialState={{
              sorting: {
                sortModel: [{ field: gridData.sortBy, sort: 'desc' }],
              } as GridSortingInitialState,
              pagination: {
                page: gridData.page,
                pageSize: gridData.pageSize,
              } as GridPaginationInitialState,
            }}
          ></CustomDataGrid>
        </div>

        <OrderDocumentAdd
          files={files}
          isOpen={isAddModalOpen}
          entityType={props.entityType}
          entityId={props.shippingOrderId}
          OnSave={OnSave}
          handleClose={handleCloseForAdd}
          title={I18n('Document.AddDocument')}
        ></OrderDocumentAdd>

        <ConfirmationPopup
          isOpen={isDeleteModalOpen}
          data={selectedDocumentID}
          message={I18n('Document.DeleteConfirmation')}
          handleClose={handleCloseForDelete}
          handleOk={handleOkForDelete}
        ></ConfirmationPopup>
      </div>
    </div>
  );
}

export default OrderDocumentList;
