import React, { useState } from 'react';
import http from '../../utilities/httpService';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import RouteEnum from 'models/RouteEnum';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import environment from 'environment';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import I18n from 'utilities/i18n';
import {
  BaseModel,
  EntityStatusDescription,
  MasterCategoryEnumType,
  PricingTypeDescription,
  PaginationResponseModel,
  ProductCatalogueTypeDescription,
  EntityStatus,
  DepartmentSelect,
} from 'models/pagination-model';
import DimentionsInputs from 'common/formControl/formDimentions';
import { Customer } from 'models/service-catalogue';
import { ProductCatalogueStateModel } from 'models/product-catalogue';
import { CustomizedSelect } from 'common/CustomSelect/CustomSelect';
import FormControl from 'common/formControl/formControl';
import DecimalInput from 'common/DecimalInput/DecimalInput';
import Link from '@mui/material/Link';
import CustomSelectPaginateAdd from 'common/CustomSelect/CustomSelectPaginateAdd';
import {
  PostCatagoryDropDown,
  loadItemGroupOptions,
  loadLedgerOptions,
} from 'common/DropDownAddAPI/DropDownApiService';
import CustomSelectPaginate from 'common/CustomSelect/CustomSelectPaginate';
import { Checkbox, FormControlLabel } from '@mui/material';

function ProductCatalougeAdd(props: any) {
  const navigate = useNavigate();
  const handleListClick = () => {
    navigate(RouteEnum.ProductCatalogue);
  };
  const breadcrumbs = [
    <Typography key="3" color="text.primary">
      {I18n('Nav.Configuration')}
    </Typography>,
    <Typography key="3" color="text.primary">
      {I18n('Nav.Products')}
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={handleListClick}
        className="cpointer"
      >
        {I18n('Nav.ProductCatalogue')}
      </Link>
    </Typography>,
    <Typography key="3" color="text.primary">
      {I18n('ProductCatalogues.AddProductCatalouge')}
    </Typography>,
  ];

  const [errors, setErrors] = useState({
    productCode: '',
    category: '',
    shortDescription: '',
    productName: '',
    shippingStandardPrice: '',
    decPackStandardPrice: '',
    lastCostBuyPrice: '',
    lastPurchasedFrom: '',
    status: '',
    pricingType: '',
    productCatalogueType: '',
    name: '',
    dimensions: '',
    ledgerAccount: '',
    itemGroup: '',
    department: '',
  });
  const [cacheUniq, setCacheUniq] = useState(0);
  const defaultCategoryValue = {
    value: '0',
    label: I18n('Placeholders.SelectCategory'),
  } as BaseModel;

  const defaultValue = {
    value: '0',
    label: I18n('Placeholders.SelectStatus'),
  } as BaseModel;

  const defaultDropDownValue = {
    value: '0',
    label: I18n('Placeholders.Select'),
  } as BaseModel;

  const defaultDepartmentValue = {
    value: '0',
    label: I18n('Placeholders.SelectDepartment'),
  } as BaseModel;

  const departmentValue = {
    value: '0',
    label: I18n('Placeholders.SelectDepartment'),
  };

  const [values, setValues] = useState<ProductCatalogueStateModel>({
    name: '',
    productCatalogueType: 1,
    shippingStandardPrice: '',
    decPackStandardPrice: '',
    lastCostBuyPrice: '',
    lastPurchasedFrom: '',
    status: EntityStatus.Active,
    productCode: '',
    dimension1: 0,
    dimension2: 0,
    dimension3: 0,
    productCatalogueCategoryId: Number(defaultCategoryValue.value),
    productCatalogueCategoryName: defaultCategoryValue.label,
    category: defaultCategoryValue,
    shortDescription: '',
    weight: 0,
    pricingType: 1,
    thresholdLimit: 0,
    warehouseQuantity: 0,
    ledgerAccount: defaultDropDownValue,
    itemGroup: defaultDropDownValue,
    showInDPBOM: false,
    department: defaultDepartmentValue,
    departmentType: 0,
  });

  const i18nMessages = {
    addProductCataloguesuccess: I18n('ProductCatalogues.AddSuccess'),
    commonFieldIsRequired: I18n('Common.FieldIsRequired'),
    productCode: I18n('ProductCatalogues.ProductCode'),
    productName: I18n('ProductCatalogues.ProductName'),
    uniqueProductCode: I18n('ProductCatalogues.UniqueProductCode'),
    uniqueProductName: I18n('ProductCatalogues.UniqueProductName'),
    zeroValidation: I18n('Common.ZeroValidation'),
    CommonSaveSuccessMsg: I18n('Common.SavedSuccessfully'),
    CommonErrOccuredMsg: I18n('Common.ErrorOccurred'),
    LedgerAccountDescription: I18n('LedgerInfo.LedgerAccountDescription'),
    LedgerAccount: I18n('LedgerInfo.LedgerAccount'),
    ItemGroup: I18n('ItemGroupInfo.ItemGroup'),
    ShowInDPBOM: I18n('ProductCatalogues.ShowInDPBOM'),
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (validate()) {
      const apiUrl = new URL(environment.api.baseUrl + 'ProductCatalogues');
      try {
        setValues({
          ...values,
        });
        let data: any = Object.assign({}, values);
        data.productCatalogueCategoryId = Number(values.category.value);
        data.shippingStandardPrice = values.shippingStandardPrice;
        data.decPackStandardPrice = values.decPackStandardPrice;
        data.ledgerAccountId = +values.ledgerAccount.value;
        data.itemGroupId = +values.itemGroup.value;
        const result: any = await http.post(apiUrl.toString(), data);
        if (result) {
          toast.success(i18nMessages.addProductCataloguesuccess);
          navigate(RouteEnum.ProductCatalogue);
        }
      } catch (error: any) {
        if (error.response) {
          let temp = { ...errors };
          if (error.response.data) {
            for (const item of error.response.data.errors) {
              if (
                item.field === 'ProductCode' &&
                item.errorMessage === 'UniqueProductCode'
              ) {
                temp.productCode = i18nMessages.uniqueProductCode;
              }
              if (
                item.field === 'Name' &&
                item.errorMessage === 'UniqueProductName'
              ) {
                temp.name = i18nMessages.uniqueProductName;
              }
            }
          }
          setErrors({
            ...temp,
          });
        }
      }
    }
  };

  const onStatusChange = (data: any) => {
    setValues({ ...values, status: Number(data?.value) });
  };
  const onPricingTypeChange = (data: any) => {
    setValues({ ...values, pricingType: Number(data?.value) });
  };

  const onProductCatalougeTypeChange = (data: any) => {
    setValues({ ...values, productCatalogueType: Number(data?.value) });
  };
  const onCreateOption = async (inputValue: any) => {
    let name = {};
    name['name'] = inputValue;
    name['entityType'] = MasterCategoryEnumType.ProductCatalogue;
    const newOption: any = await PostCatagoryDropDown(
      name,
      i18nMessages.CommonSaveSuccessMsg,
      i18nMessages.CommonErrOccuredMsg
    );
    const increaseUniq = (uniq: any) => uniq + 1;
    setCacheUniq(increaseUniq);
    setValues({ ...values, category: newOption });
    getOrderCategories('', 1);
  };
  const getOrderCategories = async (search: string, pageNo: number) => {
    let response: PaginationResponseModel<Customer>;
    const apiUrl = new URL(
      environment.api.baseUrl + 'Dropdown/GetMasterCategories'
    );
    try {
      let searchExpression = `entityType~=~${MasterCategoryEnumType.ProductCatalogue}`;
      searchExpression += search ? ` and name~like~${search}` : '';
      apiUrl.searchParams.set('$filter', searchExpression);
      apiUrl.searchParams.set('$orderby', 'name');
      apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
      apiUrl.searchParams.set('$top', '10');
      apiUrl.searchParams.set('$orderbydirection', 'desc');
      const result = await http.get(apiUrl.toString());
      if (result) {
        response = {
          TotalRecords: result.data.totalCount,
          Values: result.data.values,
        } as PaginationResponseModel<Customer>;
      } else {
        response = {
          TotalRecords: 0,
          Values: [],
        } as PaginationResponseModel<Customer>;
      }
    } catch (error) {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<Customer>;
    }
    return response;
  };

  const loadCategoryOptions = async (
    search: string,
    loadedOptions: any,
    { page }: any
  ) => {
    const response = await getOrderCategories(search, page);
    const hasMore = Math.ceil(response.TotalRecords / 10) > page;

    return {
      options: response.Values.map((x) => {
        return {
          value: x.id.toString(),
          label: x.name,
        } as BaseModel;
      }),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const validate = (fieldValues: any = values) => {
    let temp = { ...errors };
    if ('name' in fieldValues) {
      temp.name = fieldValues.name ? '' : i18nMessages.commonFieldIsRequired;
    }
    if ('category' in fieldValues) {
      if (+fieldValues.category.value === +defaultCategoryValue.value) {
        temp.category = i18nMessages.commonFieldIsRequired;
      } else {
        temp.category = fieldValues.category
          ? ''
          : i18nMessages.commonFieldIsRequired;
      }
    }
    if ('productCode' in fieldValues) {
      temp.productCode = fieldValues.productCode
        ? ''
        : i18nMessages.commonFieldIsRequired;
    }
    if ('shippingStandardPrice' in fieldValues) {
      temp.shippingStandardPrice = +fieldValues.shippingStandardPrice
        ? ''
        : i18nMessages.commonFieldIsRequired;
      if (fieldValues.shippingStandardPrice) {
        temp.shippingStandardPrice =
          fieldValues.shippingStandardPrice &&
          +fieldValues.shippingStandardPrice === 0
            ? i18nMessages.zeroValidation
            : '';
      }
    }
    if ('decPackStandardPrice' in fieldValues) {
      temp.decPackStandardPrice = +fieldValues.decPackStandardPrice
        ? ''
        : i18nMessages.commonFieldIsRequired;
      if (fieldValues.decPackStandardPrice) {
        temp.decPackStandardPrice =
          fieldValues.decPackStandardPrice &&
          +fieldValues.decPackStandardPrice === 0
            ? i18nMessages.zeroValidation
            : '';
      }
    }
    if ('lastCostBuyPrice' in fieldValues) {
      temp.lastCostBuyPrice = fieldValues.lastCostBuyPrice
        ? ''
        : i18nMessages.commonFieldIsRequired;
    }
    if ('status' in fieldValues) {
      temp.status = fieldValues.status
        ? ''
        : i18nMessages.commonFieldIsRequired;
    }
    if ('shortDescription' in fieldValues) {
      temp.shortDescription = fieldValues.shortDescription
        ? ''
        : i18nMessages.commonFieldIsRequired;
    }
    if (
      'ledgerAccount' in fieldValues &&
      values.ledgerAccount.value == defaultDropDownValue.value
    ) {
      temp.ledgerAccount = i18nMessages.commonFieldIsRequired;
    } else {
      temp.ledgerAccount = '';
    }

    if (
      'itemGroup' in fieldValues &&
      values.itemGroup.value == defaultDropDownValue.value
    ) {
      temp.itemGroup = i18nMessages.commonFieldIsRequired;
    } else {
      temp.itemGroup = '';
    }

    if (
      'departmentType' in fieldValues &&
      values.departmentType == +defaultDropDownValue.value
    ) {
      temp.department = i18nMessages.commonFieldIsRequired;
    } else {
      temp.department = '';
    }

    setErrors({
      ...temp,
    });
    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === '');
    }
  };

  let entityStatusList: Array<BaseModel> = [];
  const getStatusList = () => {
    entityStatusList = [defaultValue];
    EntityStatusDescription.forEach((value: string, key: number) => {
      entityStatusList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getStatusList();

  let pricingTypeList: Array<BaseModel> = [];
  const getPricingTypeList = () => {
    pricingTypeList = [];
    PricingTypeDescription.forEach((value: string, key: number) => {
      pricingTypeList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getPricingTypeList();

  let ProductCatalogueList: Array<BaseModel> = [];
  const getProductCatalogueType = () => {
    ProductCatalogueList = [];
    ProductCatalogueTypeDescription.forEach((value: string, key: number) => {
      ProductCatalogueList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getProductCatalogueType();

  const onValueChange = (event: any) => {
    const { name, value } = event.target;

    validate({ [name]: value });
    setValues({
      ...values,
      [name.toString()]: value,
    });
  };
  const defaultAdditional: any = {
    page: 1,
  };

  let departmentList: Array<BaseModel> = [];
  const getDepartmentList = () => {};
  DepartmentSelect.forEach((value: string, key: number) => {
    departmentList.push({
      value: key.toString(),
      label: I18n(value),
    } as BaseModel);
  });
  getDepartmentList();

  return (
    <div>
      <div className="heading-section">
        <div className="heading-section-left">
          <Typography variant="h3" sx={{ mb: 3 }}>
            {I18n('ProductCatalogues.AddProductCatalogue')}
          </Typography>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>
      </div>
      <div className="main-content-section">
        <form className="model-content form-content" onSubmit={handleSubmit}>
          <div className="inner-section">
            <Typography variant="h5" className="inner-heading">
              {I18n('ProductCatalogues.AddProductCatalogue')}
            </Typography>
            <Grid container spacing={2} className="grid-wrap">
              <Grid item lg={4} md={8}>
                <CustomizedSelect
                  defaultValue={ProductCatalogueList[0]}
                  options={ProductCatalogueList}
                  isSearchable={false}
                  displayLabel={I18n('ProductCatalogues.ProductCatalogueType')}
                  handleChange={(newValue: BaseModel) =>
                    onProductCatalougeTypeChange(newValue)
                  }
                ></CustomizedSelect>
              </Grid>
              <Grid item lg={4} md={8}>
                <FormControl
                  required
                  margin="normal"
                  displayLabel={I18n('ProductCatalogues.ProductCode')}
                  id="ProductCode"
                  name="productCode"
                  inputProps={{ maxLength: 10 }}
                  multiline={false}
                  handleChange={onValueChange}
                  errorValue={true}
                  errorMessage={errors.productCode}
                ></FormControl>
              </Grid>
              <Grid item lg={4} md={8}>
                <FormControl
                  margin="normal"
                  required
                  displayLabel={I18n('ProductCatalogues.ProductName')}
                  id="name"
                  name="name"
                  inputType="text"
                  inputProps={{ maxLength: 30 }}
                  handleChange={onValueChange}
                  multiline={false}
                  errorValue={true}
                  errorMessage={errors.name}
                ></FormControl>
              </Grid>
              <Grid item lg={4} md={8}>
                <FormControl
                  margin="normal"
                  required
                  displayLabel={I18n('ProductCatalogues.Description')}
                  id="Description"
                  name="shortDescription"
                  inputType="text"
                  inputProps={{ maxLength: 150 }}
                  multiline={true}
                  handleChange={onValueChange}
                  errorValue={true}
                  errorMessage={errors.shortDescription}
                ></FormControl>
              </Grid>
              <Grid item lg={4} md={8}>
                <CustomSelectPaginateAdd
                  required
                  key={JSON.stringify(values.category)}
                  additional={defaultAdditional}
                  value={values.category}
                  loadOptions={loadCategoryOptions}
                  isSearchable={true}
                  displayLabel={I18n('ProductCatalogues.Category')}
                  placeholder={defaultCategoryValue.label}
                  handleChange={(newValue: any) => {
                    setValues({ ...values, category: newValue });
                    if (newValue !== '') {
                      setErrors({
                        ...errors,
                        category: '',
                      });
                    }
                  }}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                  }}
                  debounceTimeout={500}
                  isMultiSelect={false}
                  cacheUniqs={cacheUniq}
                  onCreateOption={onCreateOption}
                  errorValue={true}
                  errorMessage={errors.category}
                  readOnly={false}
                ></CustomSelectPaginateAdd>
              </Grid>

              <Grid item lg={4} md={8}>
                <DimentionsInputs
                  margin="normal"
                  displayLabel={I18n('ProductCatalogues.Dimentions')}
                  id="dimensions"
                  name="dimensions"
                  inputType="text"
                  handleChange1={(event: any) => {
                    setValues({
                      ...values,
                      dimension1: Number(event.target.value),
                    });
                  }}
                  handleChange2={(event: any) => {
                    setValues({
                      ...values,
                      dimension2: Number(event.target.value),
                    });
                  }}
                  handleChange3={(event: any) => {
                    setValues({
                      ...values,
                      dimension3: Number(event.target.value),
                    });
                  }}
                  inputProps={{ maxLength: 30 }}
                  errorValue={true}
                  errorMessage={errors.dimensions}
                ></DimentionsInputs>
              </Grid>
              <Grid item lg={4} md={8}>
                <DecimalInput
                  displayLabel={I18n('ProductCatalogues.Weight')}
                  inputProps={{ maxLength: 10 }}
                  name="weight"
                  handleChange={(event: any) => {
                    setValues({
                      ...values,
                      weight: Number(event.target.value),
                    });
                  }}
                ></DecimalInput>
              </Grid>
              <Grid item lg={4} md={8}>
                <CustomizedSelect
                  defaultValue={pricingTypeList[0]}
                  options={pricingTypeList}
                  isSearchable={false}
                  displayLabel={I18n('ProductCatalogues.PricingType')}
                  handleChange={(newValue: BaseModel) =>
                    onPricingTypeChange(newValue)
                  }
                ></CustomizedSelect>
              </Grid>
              <Grid item lg={4} md={8}>
                <CustomizedSelect
                  required
                  placeholder={departmentValue.label}
                  options={departmentList}
                  isSearchable={false}
                  displayLabel={I18n('ProductCatalogues.Department')}
                  errorValue={errors.department}
                  errorMessage={errors.department}
                  handleChange={(newValue: BaseModel) =>
                    setValues({
                      ...values,
                      department: newValue,
                      departmentType: Number(newValue.value),
                    })
                  }
                ></CustomizedSelect>
              </Grid>
              <Grid item lg={4} md={8}>
                <DecimalInput
                  required
                  displayLabel={I18n('ProductCatalogues.ShippingStandardPrice')}
                  inputProps={{ maxLength: 10 }}
                  name="shippingStandardPrice"
                  handleChange={(event: any) => {
                    setValues({
                      ...values,
                      shippingStandardPrice: event.target.value,
                    });
                  }}
                  errorValue={errors.shippingStandardPrice}
                  errorMessage={errors.shippingStandardPrice}
                ></DecimalInput>
              </Grid>
              <Grid item lg={4} md={8}>
                <DecimalInput
                  required
                  displayLabel={I18n('ProductCatalogues.DecPackStandardPrice')}
                  inputProps={{ maxLength: 10 }}
                  name="decPackStandardPrice"
                  handleChange={(event: any) => {
                    setValues({
                      ...values,
                      decPackStandardPrice: event.target.value,
                    });
                  }}
                  errorValue={errors.decPackStandardPrice}
                  errorMessage={errors.decPackStandardPrice}
                ></DecimalInput>
              </Grid>
              <Grid item lg={4} md={8}>
                <DecimalInput
                  required
                  displayLabel={I18n('ProductCatalogues.LastCostBuyPrice')}
                  inputProps={{ maxLength: 10 }}
                  name="lastCostBuyPrice"
                  handleChange={(event: any) => {
                    setValues({
                      ...values,
                      lastCostBuyPrice: event.target.value,
                    });
                  }}
                  errorValue={true}
                  errorMessage={errors.lastCostBuyPrice}
                ></DecimalInput>
              </Grid>
              <Grid item lg={4} md={8}>
                <FormControl
                  margin="normal"
                  displayLabel={I18n('ProductCatalogues.LastPurchasedFrom')}
                  id="lastPurchasedFrom"
                  name="lastPurchasedFrom"
                  inputType="text"
                  inputProps={{ maxLength: 50 }}
                  multiline={true}
                  handleChange={onValueChange}
                  errorValue={true}
                  errorMessage={errors.lastPurchasedFrom}
                ></FormControl>
              </Grid>
              <Grid item lg={4} md={8}>
                <DecimalInput
                  displayLabel={I18n('ProductCatalogues.ThresholdLimit')}
                  inputProps={{ maxLength: 10 }}
                  name="thresholdLimit"
                  handleChange={(event: any) => {
                    setValues({
                      ...values,
                      thresholdLimit: Number(event.target.value),
                    });
                  }}
                ></DecimalInput>
              </Grid>
              <Grid item lg={4} md={6} sm={12}>
                <CustomSelectPaginate
                  required
                  readOnly={false}
                  key={values.ledgerAccount.value}
                  additional={defaultAdditional}
                  value={values.ledgerAccount}
                  loadOptions={(search: string, prevOptions: any, page: any) =>
                    loadLedgerOptions(search, prevOptions, page)
                  }
                  handleChange={async (newValue: any) => {
                    setValues({
                      ...values,
                      ledgerAccount: newValue,
                    });
                  }}
                  placeholder={defaultValue.label}
                  isSearchable={true}
                  displayLabel={i18nMessages.LedgerAccount}
                  errorValue={true}
                  errorMessage={errors.ledgerAccount}
                  debounceTimeout={500}
                  isMultiSelect={false}
                />
              </Grid>
              <Grid item lg={4} md={6} sm={12}>
                <CustomSelectPaginate
                  required
                  readOnly={false}
                  key={values.itemGroup.value}
                  additional={defaultAdditional}
                  value={values.itemGroup}
                  loadOptions={(search: string, prevOptions: any, page: any) =>
                    loadItemGroupOptions(search, prevOptions, page)
                  }
                  handleChange={async (newValue: any) => {
                    setValues({
                      ...values,
                      itemGroup: newValue,
                    });
                  }}
                  placeholder={defaultValue.label}
                  isSearchable={true}
                  displayLabel={i18nMessages.ItemGroup}
                  errorValue={true}
                  errorMessage={errors.itemGroup}
                  debounceTimeout={500}
                  isMultiSelect={false}
                />
              </Grid>
              <Grid item lg={4} md={8}>
                <CustomizedSelect
                  required
                  placeholder={defaultValue.label}
                  options={entityStatusList}
                  isSearchable={false}
                  displayLabel={I18n('Common.Status')}
                  handleChange={(newValue: BaseModel) =>
                    onStatusChange(newValue)
                  }
                  errorValue={true}
                  errorMessage={errors.status}
                ></CustomizedSelect>
              </Grid>
              <Grid item lg={4} md={6} sm={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="showInDPBOM"
                      onChange={(event: any) => {
                        setValues({
                          ...values,
                          showInDPBOM: event.target.checked,
                        });
                      }}
                      checked={values.showInDPBOM}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label={i18nMessages.ShowInDPBOM}
                />
              </Grid>
            </Grid>
          </div>

          <div className="btn-wrap form-btn-wrap">
            <Button
              color="neutral"
              disableElevation
              variant="contained"
              onClick={() => navigate(RouteEnum.ProductCatalogue)}
            >
              {I18n('UsersAdd.Cancel')}
            </Button>
            <Button
              type="submit"
              color="primary"
              disableElevation
              variant="contained"
            >
              {I18n('UsersAdd.Add')}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ProductCatalougeAdd;
