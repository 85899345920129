import CustomizedSelect from 'common/CustomSelect/CustomSelect';
import { BaseModel } from 'models/pagination-model';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { translateTo } from 'stores/actions';

function I18nSelector() {
  const dispatch = useDispatch();
  const defaultLanguage: BaseModel = { value: 'en', label: 'English' };
  const languageOptions: Array<BaseModel> = [
    { value: 'fr', label: 'French' },
    { value: 'nl', label: 'Dutch' },
  ];

  let selectedLanguageObject = languageOptions.find(
    (x: BaseModel) => x.value === localStorage.getItem('selectedLanguage')
  );
  if (!selectedLanguageObject) {
    selectedLanguageObject = defaultLanguage;
  }
  const [selectedLanguage, setSelectedLanguage] = useState<
    BaseModel | undefined
  >(selectedLanguageObject);

  useEffect(() => {
    const payload = localStorage.getItem('payload');
    if (payload !== null) {
      let value = { value: '', label: '' };
      if (payload === 'fr') {
        value = { value: payload, label: 'French' };
      } else if (payload === 'nl') {
        value = { value: payload, label: 'Dutch' };
      } else if (payload === 'en') {
        value = { value: payload, label: 'English' };
      }

      localStorage.setItem('selectedLanguage', value.value);
      setSelectedLanguage(value);
    }
    dispatch(
      translateTo(
        selectedLanguage && selectedLanguage.value
          ? selectedLanguage.value
          : defaultLanguage.value
      )
    );
  }, []);

  const handleSelectLanguage = (value: BaseModel) => {
    if (value) {
      setSelectedLanguage(value);
      localStorage.setItem('selectedLanguage', value.value);
      localStorage.removeItem('type');
      localStorage.removeItem('payload');
      dispatch(translateTo(value.value));
    }
  };

  return (
    <>
      <CustomizedSelect
        options={[defaultLanguage, ...languageOptions]}
        isSearchable={false}
        handleChange={handleSelectLanguage}
        defaultValue={selectedLanguage}
      ></CustomizedSelect>
    </>
  );
}

export default I18nSelector;
