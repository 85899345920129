import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  InputLabel,
  Link,
  Typography,
} from '@mui/material';
import FormAccordianSave from 'common/formControl/formAccordianSave';
import RadioGroupInput from 'common/RadioGroup/RadioGroupInput';
import Status from 'common/WoStatus/Status';
import {
  DeckPackSecureShipmentDetails,
  DecPackQuestions,
  DecPackQuestionsDescription,
  DecPackQuestionsDto,
  DecPackQuestionsDtoStateModel,
  DecPackWOIBODTO,
  DnPWorkOrders,
  QuestionType,
  DecPackDetailsWODTO,
} from 'models/deck-pack-orders';
import {
  ActivityOwner,
  BaseModel,
  CustomerLocation,
  DocumentEntity,
  EntityTypeEnum,
  ImageUploadViewModel,
  OrderType,
  RadioGroupBaseModel,
  WorkOrderStatus,
} from 'models/pagination-model';
import RouteEnum from 'models/RouteEnum';
import { BaseSyntheticEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import I18n from 'utilities/i18n';
import {
  getDecPackById,
  getWorkOrderIBODetails,
  getWorkOrderQuestions,
  getWorkOrderStatus,
  savedecPackWOIBOAnswers,
  getWorkOrderDetails,
} from '../DeckPackApiService';
import FormControl from 'common/formControl/formControl';
import ImageUploadPreview from 'common/ImageUploadPreview/ImageUploadPreview';
import environment from 'environment';
import {
  getWorkOrderImages,
  saveWorkOrderImages,
} from 'common/ImageUploadPreview/WorkOrderImagesApiService';
import { hasPermission } from 'utilities/protectedRoute';
import { Permission } from 'Permissions';
import ApiUrlConstants from 'constants/api.constants';
import CreateLabels from 'common/create-labels/CreateLabels';
import DecpackStatus from './DecpackStatus';
import CreatedByandUpdatedBy from 'common/CreatedByandUpdatedBy/CreatedByandUpdatedBy';
import MobileDetails from 'common/MobileDetails/MobileDetails';

export default function WoIbo() {
  const { woId, id } = useParams();
  let navigate = useNavigate();
  const [canEdit, setCanEdit] = useState(
    hasPermission(Permission.canEditDecPackOrders)
  );
  const [isStatusSaveCallApi, setIsStatusSaveCallApi] =
    useState(false); /* To call Submit APi Status from Status Component */
  const [validateStatus, setValidateStatus] = useState<boolean>(false);
  var [resultSaved, setResultSaved] = useState<boolean>(false);
  const [details, setDetails] = useState<any>({
    createdBy: '',
    createdDate: '',
    updatedBy: '',
    updatedDate: '',
  });

  const [assigneeData, setAssigneeData] = useState<any>({
    assignedToId: '',
    assignedToName: '',
    assignNextToId: '',
    assignNextToName: '',
  });
  const [isMobile, setIsMobile] = useState(false);
  const breadcrumbs = [
    <Typography key="3" color="text.primary">
      {I18n('Nav.Order')}
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={() => navigate(RouteEnum.DecPackOrdersList)}
        className="cpointer"
      >
        {I18n('Nav.DecPackOrders')}
      </Link>
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={() => navigate(RouteEnum.ViewDecPackOrders + '/' + id + '/1')}
        className="cpointer"
      >
        {I18n('ShippingOrders.WorkOrders')}
      </Link>
    </Typography>,
  ];
  const workOrderNo: number = DnPWorkOrders.WOIBO;
  const i18nMessages = {
    Status: I18n('ShippingOrders.Status'),
    commonFieldIsRequired: I18n('Common.FieldIsRequired'),
    somethingWentWrong: I18n('Common.SomethingWentWrong'),
    title: I18n('DecPackOrders.DecPackQuestions.WOIBO.Title'),
    shipmentTitle: I18n('DecPackOrders.DecPackQuestions.WOIBO.Shipment'),
    secureShipmentTitle: I18n(
      'DecPackOrders.DecPackQuestions.WOIBO.SecureShipment'
    ),
    createWarehouseLabel: I18n(
      'DecPackOrders.DecPackQuestions.WOIBO.CreateWarehouseLabel'
    ),
    securityGeneralTitle: I18n(
      'DecPackOrders.DecPackQuestions.WOIBO.SecurityGeneral'
    ),
    iboSaveSuccess: I18n('DecPackOrders.DecPackQuestions.WOIBO.SaveSuccess'),
    iboSaveError: I18n('DecPackOrders.DecPackQuestions.WOIBO.SaveError'),
    commentCaption: I18n('DecPackOrders.WorkOrder.Comment'),
    CMRNoteTitle: I18n('DecPackOrders.DecPackQuestions.WOIBO.CMRNote'),
    Picture: I18n('Packaging.Picture'),
    addDamagePictures: I18n(
      'DecPackOrders.DecPackQuestions.WOIBO.AddDamagePictures'
    ),
    NoImagesSelected: I18n('Common.NoImagesSelectedToUpload'),
    transportDocumentsTitle: I18n(
      'DecPackOrders.DecPackQuestions.WOIBO.TransportDocument'
    ),
    NoShippingDocsAvailable: I18n(
      'DecPackOrders.DecPackQuestions.WOIBO.NoShippingDocsAvailable'
    ),
    transportDocumentSaveSuccess: I18n(
      'DecPackOrders.DecPackQuestions.WOIBO.TransportDocumentSaveSuccess'
    ),
    transportDocumentSaveError: I18n(
      'DecPackOrders.DecPackQuestions.WOIBO.TransportDocumentSaveError'
    ),
    DetailsError: I18n('DecPackOrders.DecPackQuestions.WOIBO.DetailsRequired'),
    AllDetailsError: I18n(
      'DecPackOrders.DecPackQuestions.WOIBO.AllDetailsRequired'
    ),
    CommonSaveSuccessMsg: I18n('Common.SavedSuccessfully'),
  };
  const [status, setStatus] = useState('');
  const [isComment, setIsComment] = useState(false);
  const [comment, setComment] = useState('');
  const [isUpload, setIsUpload] = useState(false);
  const [images, setImages] = useState<ImageUploadViewModel[]>([]);
  const [transportDocumentImages, setTransportDocumentImages] = useState<
    ImageUploadViewModel[]
  >([]);
  const [questions, setQuestions] = useState<DecPackQuestionsDtoStateModel[]>(
    []
  );
  const [answers, setAnswers] = useState<DecPackWOIBODTO[]>([]);
  const [secureShipmentDetails, setSecureShipmentDetails] =
    useState<DeckPackSecureShipmentDetails>();
  const [mobileDetails, setMobileDetails] = useState<DecPackDetailsWODTO>({
    mobileRemarks: '',
    waitTime: 0,
  } as DecPackDetailsWODTO);

  const [isLabelSaveCallApi, setIsLabelSaveCallApi] = useState(false);
  const [validateLabel, setValidateLabel] = useState<boolean>(false);
  const [isSaveNext, setIsSaveNext] = useState<boolean>(true);
  let masterQuestionListList: Array<BaseModel> = [];
  const getQuestionsList = () => {
    DecPackQuestionsDescription.forEach((value: string, key: number) => {
      masterQuestionListList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getQuestionsList();
  let answerList: Array<RadioGroupBaseModel> = [];
  const getAnswerList = () => {
    answerList.push({
      value: '1',
      label: I18n('Common.Yes'),
    } as RadioGroupBaseModel);
    answerList.push({
      value: '0',
      label: I18n('Common.No'),
    } as RadioGroupBaseModel);
  };
  getAnswerList();
  const getWOQuestions = async () => {
    try {
      const response = await getWorkOrderQuestions(workOrderNo);
      if (response && response.result) {
        getWorkOrderIBODetailsById(response.data as Array<DecPackQuestionsDto>);
        setQuestions(
          (response.data as Array<DecPackQuestionsDto>).map((x) => {
            const questionValue = masterQuestionListList.find(
              (y) => +y.value == x.questionKey
            );
            return {
              id: x.id,
              sequenceNo: x.sequenceNo,
              questionKey: x.questionKey,
              questionType: x.questionType,
              workOrderNo: x.workOrderNo,
              questionName: questionValue?.label,
            } as DecPackQuestionsDtoStateModel;
          })
        );
      }
    } catch (error) {
      //toast.error(i18nMessages.somethingWentWrong);
    }
  };

  const getWOStatus = async () => {
    try {
      const response = await getWorkOrderStatus(workOrderNo, id ? +id : 0);
      if (response && response.result) {
        setStatus(response.data.status);
        setIsMobile(response.data.isMobile);
        setDetails({
          ...details,
          createdBy: response.data.createdBy,
          updatedBy: response.data.updatedBy,
          createdDate: response.data.createdDate,
          updatedDate: response.data.updatedDate,
        });

        setAssigneeData({
          ...assigneeData,
          assignedToId: response.data.assignedToId,
          assignedToName: response.data.assignedToName,
          assignNextToId: response.data.assignedNextToId,
          assignNextToName: response.data.assignedNextToName,
        });
      }
    } catch (error) {}
  };

  const setAnswerData = (value: boolean, questionId: number) => {
    let tempData: DecPackWOIBODTO[] = [];
    if (answers && answers.some((x) => +x.decPackQuestionsId === +questionId)) {
      tempData = [...answers];
      const index = tempData.findIndex(
        (x) => +x.decPackQuestionsId === +questionId
      );
      tempData[index].answer = +value;
    } else {
      tempData = [
        ...answers,
        {
          answer: +value,
          decPackQuestionsId: questionId,
          decPackWorkOrdersId: woId ? +woId : 0,
        } as DecPackWOIBODTO,
      ];
    }
    setAnswers(tempData);
  };

  const getWorkOrderIBODetailsById = (
    questions: Array<DecPackQuestionsDto>
  ) => {
    getWorkOrderIBODetails(woId ? +woId : 0).then((x) => {
      if (x.result) {
        setAnswers(x.data);
        if (
          x.data &&
          x.data.length &&
          questions &&
          questions.length &&
          x.data.some(
            (x) =>
              x.decPackQuestionsId ===
                questions.find(
                  (x) =>
                    x.questionKey === DecPackQuestions.WOIBOShipmentQA2 &&
                    x.questionType === QuestionType.WOIBOShipment
                )?.id && +x.answer === 0
          )
        ) {
          setIsUpload(true);
        } else {
          setIsUpload(false);
        }
        getDamagePictures();
        getTranportDocuments();
        if (
          x.data &&
          x.data.length &&
          questions.some(
            (y) =>
              y.questionType === QuestionType.WOIBOShipment &&
              y.questionKey === DecPackQuestions.WOIBOShipmentQA1
          ) &&
          x.data.some(
            (x) =>
              x.decPackQuestionsId ===
                questions.find(
                  (y) =>
                    y.questionKey === DecPackQuestions.WOIBOShipmentQA1 &&
                    y.questionType === QuestionType.WOIBOShipment
                )?.id && +x.answer === 0
          )
        ) {
          const index = x.data.findIndex(
            (x) =>
              x.decPackQuestionsId ===
              questions.find(
                (y) =>
                  y.questionKey === DecPackQuestions.WOIBOShipmentQA1 &&
                  y.questionType === QuestionType.WOIBOShipment
              )?.id
          );
          setComment(x.data[index].comment);
          setIsComment(true);
        } else {
          setIsComment(false);
        }
      }
    });
  };

  const getWorkOrderDetail = () => {
    getWorkOrderDetails(id ? Number(id) : 0, workOrderNo).then((x) => {
      if (x.result && x.data) {
        setMobileDetails(x.data);
      }
    });
  };

  const getDamagePictures = () => {
    getWorkOrderImages(
      EntityTypeEnum.DecPackOrders,
      woId ? +woId : 0,
      id ? +id : 0,
      DocumentEntity.DeckPackIBODamage
    ).then((x) => {
      if (x.result) {
        /* Object conversion from backend viewModel to ImagePreview component Object */
        setImages(
          x.data.map((y) => {
            return {
              documentTag: {
                label: y.documentTagName,
                value: y.documentTag?.toString(),
              } as BaseModel,
              error: '',
              id: y.id,
              previewUrl:
                environment.api.baseUrl +
                ApiUrlConstants.DownloadImageUrl +
                y.imageUrl,
              path: y.imageUrl,
            } as ImageUploadViewModel;
          })
        );
      }
    });
  };

  const getTranportDocuments = () => {
    getWorkOrderImages(
      EntityTypeEnum.DecPackOrders,
      woId ? +woId : 0,
      id ? +id : 0,
      DocumentEntity.DeckPackIBOTransportDocuments
    ).then((x) => {
      if (x.result) {
        /* Object conversion from backend viewModel to ImagePreview component Object */
        setTransportDocumentImages(
          x.data.map((y) => {
            return {
              documentTag: {
                label: y.documentTagName,
                value: y.documentTag?.toString(),
              } as BaseModel,
              error: '',
              id: y.id,
              previewUrl:
                environment.api.baseUrl +
                ApiUrlConstants.DownloadImageUrl +
                y.imageUrl,
              path: y.imageUrl,
            } as ImageUploadViewModel;
          })
        );
      }
    });
  };

  useEffect(() => {
    getWOQuestions();
    getWOStatus();
    getDecPackByDetailsId();
    getWorkOrderDetail();
  }, []);

  const getDecPackByDetailsId = async () => {
    const response: any = await getDecPackById(id ? +id : 0);
    if (response) {
      setSecureShipmentDetails(response);
    } else {
      toast.error(i18nMessages.somethingWentWrong);
    }
  };

  const appendQuestionNameWithDetails = (
    question: DecPackQuestionsDtoStateModel
  ) => {
    switch (question.questionKey) {
      case DecPackQuestions.WOIBOSecureShipmentQA1:
        return secureShipmentDetails && secureShipmentDetails?.Transporter
          ? `${question.questionName} ${secureShipmentDetails.Transporter}`
          : `${question.questionName}`;
      case DecPackQuestions.WOIBOSecureShipmentQA2:
        return secureShipmentDetails && secureShipmentDetails?.LicensePlate
          ? `${question.questionName} ${secureShipmentDetails.LicensePlate}`
          : `${question.questionName}`;
      case DecPackQuestions.WOIBOSecureShipmentQA3:
        return secureShipmentDetails && secureShipmentDetails?.DriverName
          ? `${question.questionName} ${secureShipmentDetails.DriverName}`
          : `${question.questionName}`;

      default:
        return question.questionName;
    }
  };
  const AppendDetails = (question: DecPackQuestionsDtoStateModel) => {
    if (question.questionKey === DecPackQuestions.WOIBOShipmentQA1) {
      // return secureShipmentDetails?.NumberofPieces;
      return secureShipmentDetails && secureShipmentDetails?.NumberofPieces
        ? `${question.questionName} ${secureShipmentDetails.NumberofPieces}`
        : `${question.questionName}`;
    } else {
      return question.questionName;
    }
  };

  const SecureShipmentData = () => {
    return (
      <Grid container spacing={1} className="grid-wrap two-col">
        {questions
          .filter((x) => x.questionType === QuestionType.WOIBOSecureShipment)
          .map((question: DecPackQuestionsDtoStateModel) => (
            <Grid item xs={4}>
              <RadioGroupInput
                displayLabel={appendQuestionNameWithDetails(question)}
                name={`question${question.id}`}
                value={Number(
                  answers.find((x) => +x.decPackQuestionsId === +question.id)
                    ?.answer
                )}
                handleChange={(event: BaseSyntheticEvent) => {
                  setAnswerData(event.target.value, question.id);
                }}
                options={answerList}
                readOnly={!canEdit}
              ></RadioGroupInput>
            </Grid>
          ))}
      </Grid>
    );
  };
  const SecurityGeneralData = () => {
    return (
      <Grid container spacing={1} className="grid-wrap two-col">
        {questions
          .filter((x) => x.questionType === QuestionType.WOIBOSecurityGeneral)
          .map((question: DecPackQuestionsDtoStateModel) => (
            <Grid item xs={4}>
              <RadioGroupInput
                displayLabel={question.questionName}
                name={`question${question.id}`}
                value={Number(
                  answers.find((x) => +x.decPackQuestionsId === +question.id)
                    ?.answer
                )}
                handleChange={(event: BaseSyntheticEvent) => {
                  setAnswerData(event.target.value, question.id);
                }}
                options={answerList}
                readOnly={!canEdit}
              ></RadioGroupInput>
            </Grid>
          ))}
      </Grid>
    );
  };

  const fileDataFunction = (data: ImageUploadViewModel[]) => {
    setImages([...images, ...data]);
  };
  const editedFileDataFunction = (updatedImages: ImageUploadViewModel[]) => {
    setImages([...updatedImages]);
  };
  const editedTransportDocumentsFileDataFunction = (
    updatedImages: ImageUploadViewModel[]
  ) => {
    setTransportDocumentImages([...updatedImages]);
  };
  const deleteFileFunction = (index: number) => {
    const localImages: any[] = [...images];
    localImages.splice(index, 1);
    setImages(localImages);
  };

  const fileTransportDataFunction = (data: ImageUploadViewModel[]) => {
    setTransportDocumentImages([...transportDocumentImages, ...data]);
  };
  const deleteTransportFileFunction = (index: number) => {
    const localImages: any[] = [...transportDocumentImages];
    localImages.splice(index, 1);
    setTransportDocumentImages(localImages);
  };
  const ShipmentData = () => {
    return (
      <Grid container spacing={1} className="grid-wrap two-col">
        {questions
          .filter((x) => x.questionType === QuestionType.WOIBOShipment)
          .map((question: DecPackQuestionsDtoStateModel) => (
            <Grid item xs={4}>
              <RadioGroupInput
                displayLabel={AppendDetails(question)}
                name={`question${question.id}`}
                value={Number(
                  answers.find((x) => +x.decPackQuestionsId === +question.id)
                    ?.answer
                )}
                handleChange={(event: BaseSyntheticEvent) => {
                  setAnswerData(event.target.value, question.id);
                  if (
                    question &&
                    question.questionType !== null &&
                    +question.questionType === QuestionType.WOIBOShipment &&
                    +question.questionKey ===
                      DecPackQuestions.WOIBOShipmentQA1 &&
                    +event.target.value === 0
                  ) {
                    setIsComment(true);
                  } else if (
                    question &&
                    question.questionType !== null &&
                    +question.questionType === QuestionType.WOIBOShipment &&
                    +question.questionKey ===
                      DecPackQuestions.WOIBOShipmentQA1 &&
                    +event.target.value === 1
                  ) {
                    setIsComment(false);
                    setComment('');
                  }
                  if (
                    question &&
                    question.questionType !== null &&
                    +question.questionType === QuestionType.WOIBOShipment &&
                    +question.questionKey ===
                      DecPackQuestions.WOIBOShipmentQA2 &&
                    +event.target.value === 1
                  ) {
                    setIsUpload(false);
                  } else if (
                    question &&
                    question.questionType !== null &&
                    +question.questionType === QuestionType.WOIBOShipment &&
                    +question.questionKey ===
                      DecPackQuestions.WOIBOShipmentQA2 &&
                    +event.target.value === 0
                  ) {
                    setIsUpload(true);
                  }
                }}
                options={answerList}
                readOnly={!canEdit}
              ></RadioGroupInput>
              {isComment &&
                question &&
                question.questionType !== null &&
                +question.questionType === QuestionType.WOIBOShipment &&
                +question.questionKey === DecPackQuestions.WOIBOShipmentQA1 && (
                  <FormControl
                    margin="normal"
                    displayLabel={`${i18nMessages.commentCaption} (${i18nMessages.CMRNoteTitle})`}
                    id="comment"
                    name="comment"
                    inputType="text"
                    inputProps={{ maxLength: 150 }}
                    multiline={true}
                    handleChange={(event: BaseSyntheticEvent) => {
                      setComment(event.target.value);
                    }}
                    errorValue={question.error}
                    errorMessage={question.error}
                    readOnly={!canEdit}
                    value={comment}
                  ></FormControl>
                )}
              {isUpload &&
                question &&
                question.questionType !== null &&
                +question.questionType === QuestionType.WOIBOShipment &&
                +question.questionKey === DecPackQuestions.WOIBOShipmentQA2 && (
                  <Grid item lg={12}>
                    {canEdit && !(images && images.length) && (
                      <InputLabel shrink htmlFor="bootstrap-input">
                        {i18nMessages.Picture}({i18nMessages.CMRNoteTitle})
                      </InputLabel>
                    )}

                    <ImageUploadPreview
                      title={i18nMessages.addDamagePictures}
                      readOnly={!canEdit}
                      disableKeyboardNav={true}
                      limit={10}
                      fileData={fileDataFunction}
                      fileRemove={deleteFileFunction}
                      editedFileData={editedFileDataFunction}
                      images={images}
                    />
                  </Grid>
                )}
            </Grid>
          ))}
      </Grid>
    );
  };

  const TransportDocumentData = () => {
    return (
      <>
        <Grid
          sx={{ mb: 2 }}
          container
          spacing={1}
          className="grid-wrap two-col mb-2"
        >
          <Grid item lg={12}>
            <ImageUploadPreview
              readOnly={!canEdit}
              disableKeyboardNav={true}
              limit={10}
              fileData={fileTransportDataFunction}
              fileRemove={deleteTransportFileFunction}
              editedFileData={editedTransportDocumentsFileDataFunction}
              images={transportDocumentImages}
            />
          </Grid>
        </Grid>
        {!(transportDocumentImages && transportDocumentImages.length) && (
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item lg={12}>
              {i18nMessages.NoShippingDocsAvailable}
            </Grid>
          </Grid>
        )}
      </>
    );
  };

  const validateCommentField = () => {
    let isValid = false;
    const localQuestions = [...questions];
    if (
      questions.some(
        (x) =>
          x.questionType === QuestionType.WOIBOShipment &&
          x.questionKey === DecPackQuestions.WOIBOShipmentQA1
      ) &&
      answers.some(
        (x) =>
          x.decPackQuestionsId ===
            questions.find(
              (y) =>
                y.questionKey === DecPackQuestions.WOIBOShipmentQA1 &&
                y.questionType === QuestionType.WOIBOShipment
            )?.id &&
          +x.answer === 0 &&
          !comment
      )
    ) {
      const index = localQuestions.findIndex(
        (x) =>
          x.questionKey === DecPackQuestions.WOIBOShipmentQA1 &&
          x.questionType === QuestionType.WOIBOShipment
      );
      localQuestions[index].error = i18nMessages.commonFieldIsRequired;
      setQuestions([...localQuestions]);
      isValid = false;
      return;
    } else {
      const index = localQuestions.findIndex(
        (x) =>
          x.questionKey === DecPackQuestions.WOIBOShipmentQA1 &&
          x.questionType === QuestionType.WOIBOShipment
      );
      fillComment();
      localQuestions[index].error = '';
      isValid = true;
    }
    setQuestions([...localQuestions]);
    return isValid;
  };

  const fillComment = () => {
    if (
      questions &&
      questions.length &&
      answers.some(
        (x) =>
          x.decPackQuestionsId ===
            questions.find(
              (x) =>
                x.questionKey === DecPackQuestions.WOIBOShipmentQA1 &&
                x.questionType === QuestionType.WOIBOShipment
            )?.id && +x.answer === 0
      )
    ) {
      const localAnswers = [...answers];
      const index = localAnswers.findIndex(
        (x) =>
          x.decPackQuestionsId ===
            questions.find(
              (x) =>
                x.questionKey === DecPackQuestions.WOIBOShipmentQA1 &&
                x.questionType === QuestionType.WOIBOShipment
            )?.id && +x.answer === 0
      );
      localAnswers[index].comment = comment;
      setAnswers([...localAnswers]);
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setIsSaveNext(false);
    let isValid =
      status == WorkOrderStatus.Done.toString()
        ? validate() && validateCommentField() && validateAnswerImage()
        : true;
    if (isValid) {
      setValidateStatus(true);
      setValidateLabel(true);
    }
  };
  const validate = () => {
    if (
      secureShipmentDetails?.SecuredShipment &&
      secureShipmentDetails?.locationType === CustomerLocation.WareHouse
    ) {
      if (answers.length < 9) {
        toast.error(i18nMessages.AllDetailsError);
        return false;
      } else {
        return true;
      }
    } else {
      if (answers.length < 5) {
        toast.error(i18nMessages.DetailsError);
        return false;
      } else {
        return true;
      }
    }
  };

  const handleSaveClick = async () => {
    if (answers && answers.length) {
      const transportDocsImagesResult = await saveWorkOrderImages(
        transportDocumentImages,
        EntityTypeEnum.DecPackOrders,
        DocumentEntity.DeckPackIBOTransportDocuments,
        false,
        id ? +id : 0,
        woId ? +woId : 0
      );
      if (transportDocsImagesResult && transportDocsImagesResult.result) {
      } else {
        toast.error(i18nMessages.transportDocumentSaveError);
      }
      const result = await savedecPackWOIBOAnswers(answers);
      if (result && result.result) {
        setIsStatusSaveCallApi(true);
        const imagesResult = await saveWorkOrderImages(
          images,
          EntityTypeEnum.DecPackOrders,
          DocumentEntity.DeckPackIBODamage,
          false,
          id ? +id : 0,
          woId ? +woId : 0
        );
        if (imagesResult && imagesResult.result) {
          getWorkOrderIBODetailsById(questions);
        }
        toast.success(i18nMessages.iboSaveSuccess);
      } else {
        toast.error(i18nMessages.iboSaveError);
      }
    } else {
      setIsStatusSaveCallApi(true);
    }
  };

  const handleLabelStatusSave = async () => {
    try {
      setIsLabelSaveCallApi(true);
    } catch (error: any) {
      handleErrorStatusSave(false);
    }
  };

  const validateAnswerImage = () => {
    if (
      !(images && images.length) &&
      questions.some(
        (x) =>
          x.questionType === QuestionType.WOIBOShipment &&
          x.questionKey === DecPackQuestions.WOIBOShipmentQA2
      ) &&
      answers.some(
        (x) =>
          x.decPackQuestionsId ===
            questions.find(
              (x) =>
                x.questionKey === DecPackQuestions.WOIBOShipmentQA2 &&
                x.questionType === QuestionType.WOIBOShipment
            )?.id && +x.answer === 0
      )
    ) {
      toast.error(i18nMessages.NoImagesSelected);
      return false;
    }
    return true;
  };

  const handleSuccessStatusSave = async () => {
    try {
      await handleSaveClick();
    } catch (error: any) {
      toast.error(i18nMessages.somethingWentWrong);
    }
    handleErrorStatusSave(false);
  };

  /* Validate Status Dropdown Error call from Status Component */
  const handleErrorStatusSave = (data: any) => {
    setValidateStatus(false);
    setValidateLabel(false);
    setIsStatusSaveCallApi(false);
    setIsLabelSaveCallApi(false);
  };

  const onSaveNextClick = async (event: any) => {
    event.preventDefault();
    if (validate() && validateCommentField() && validateAnswerImage()) {
      setValidateStatus(true);
      setValidateLabel(true);
    }
    setIsSaveNext(true);
  };

  const refreshData = async () => {
    handleErrorStatusSave(false);
    getWOStatus();
  };

  return (
    <>
      <div className="heading-section">
        <div className="heading-section-left">
          <Typography variant="h3" sx={{ mb: 3 }}>
            {i18nMessages.title}
          </Typography>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>
      </div>
      <div className="main-content-section">
        <Grid xs={2} m={1}>
          {hasPermission(Permission.canViewDecPackOrders) ? (
            <CreateLabels
              permission={Permission.canEditDecPackOrders}
              woId={woId}
              orderId={id}
              isWarehouse={true}
              isAccordian={true}
              isLabelSaveCallApi={isLabelSaveCallApi}
              validateLabel={validateLabel}
              handleLabelStatusSave={handleLabelStatusSave}
              handleErrorStatusSave={handleErrorStatusSave}
              entityType={EntityTypeEnum.DecPackOrders}
              workOrderNo={DnPWorkOrders.WOIBO}
            />
          ) : (
            <></>
          )}
        </Grid>
        {secureShipmentDetails &&
          secureShipmentDetails?.SecuredShipment &&
          +secureShipmentDetails?.locationType ===
            CustomerLocation.WareHouse && (
            <FormAccordianSave
              title={i18nMessages.secureShipmentTitle}
              className="inner-heading"
              details={SecureShipmentData()}
            ></FormAccordianSave>
          )}
        <FormAccordianSave
          title={i18nMessages.securityGeneralTitle}
          className="inner-heading"
          details={SecurityGeneralData()}
        ></FormAccordianSave>
        <FormAccordianSave
          title={i18nMessages.shipmentTitle}
          className="inner-heading"
          details={ShipmentData()}
        ></FormAccordianSave>
        <FormAccordianSave
          title={i18nMessages.transportDocumentsTitle}
          className="inner-heading"
          details={TransportDocumentData()}
        ></FormAccordianSave>
        <MobileDetails
          mobileRemarks={mobileDetails.mobileRemarks}
          waitTime={mobileDetails.waitTime}
        ></MobileDetails>
        <Grid item lg={12} md={12}>
          <DecpackStatus
            permission={Permission.canEditDecPackOrders}
            woId={workOrderNo}
            activityOwner={ActivityOwner.Driver}
            orderId={id}
            statusValue={status}
            orderType={OrderType.DecPackOrder}
            isStatusSaveCallApi={isStatusSaveCallApi}
            validateStatus={validateStatus}
            handleSuccessStatusSave={handleSuccessStatusSave}
            handleErrorStatusSave={handleErrorStatusSave}
            isSaveNext={isSaveNext}
            isMobile={isMobile}
            workOrderNo={DnPWorkOrders.WOIBO}
            assigneeData={assigneeData}
            handleStatusChange={(value: number) => setStatus(value.toString())}
          />
        </Grid>
        <div className="btn-wrap form-btn-wrap">
          {canEdit ? (
            <Button
              color="primary"
              disableElevation
              variant="contained"
              onClick={onSaveNextClick}
            >
              {I18n('Common.SaveNext')}
            </Button>
          ) : (
            <></>
          )}
          &nbsp; &nbsp; &nbsp;
          {canEdit ? (
            <Button
              type="submit"
              color="primary"
              disableElevation
              variant="contained"
              onClick={handleSubmit}
            >
              {I18n('Common.Save')}
            </Button>
          ) : (
            <></>
          )}
        </div>
        <CreatedByandUpdatedBy
          createdBy={details.createdBy}
          createdDate={details.createdDate}
          updatedBy={details.updatedBy}
          updatedDate={details.updatedDate}
        ></CreatedByandUpdatedBy>
      </div>
    </>
  );
}
