import moment from 'moment';

function CreatedByandUpdatedBy(props: any) {
  let { createdBy, updatedBy, createdDate, updatedDate } = props;
  return (
    <div className="createdby-updatedby">
      <div>
        <span>
          {`Created by: `} {createdBy}
          {createdDate
            ? `(` + moment(createdDate).format('DD/MM/YYYY hh:mm:ss') + `)`
            : ''}
        </span>
      </div>
      <div>
        <span>
          {`Updated by: `} {updatedBy}{' '}
          {updatedDate
            ? `(` + moment(updatedDate).format('DD/MM/YYYY hh:mm:ss') + `)`
            : ''}
        </span>
      </div>
    </div>
  );
}
export default CreatedByandUpdatedBy;
