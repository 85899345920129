import I18n from 'utilities/i18n';
import { useEffect, useCallback, useState } from 'react';
import http from '../../utilities/httpService';
import { EntityTypeEnum, OrderByDirection } from 'models/pagination-model';
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CustomizedInputs from 'common/formControl/formControl';
import environment from 'environment';
import { toast } from 'react-toastify';
import {
  GridActionsCellItem,
  GridColumns,
  GridPaginationInitialState,
  GridRenderEditCellParams,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridRowsProp,
  GridSortingInitialState,
  GridSortModel,
  GridToolbarContainer,
  MuiEvent,
  useGridApiContext,
} from '@mui/x-data-grid';
import ConfirmationPopup from 'common/confirmationPopup/confirmationPopup';
import DecimalInput from 'common/DecimalInput/DecimalInput';
import CustomDataGridInline from 'common/datagrid/CustomDataGridInline';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

export default function CreditNote(props: any) {
  const { canAdd, canDelete, canEdit } = props;
  const [isDeleteModalOpen, setDeleteModal] = useState(false);
  const [selectedId, setId] = useState();
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [refreshGrid, setRefreshGrid] = useState(false);
  const [gridData, setGridData] = useState({
    sortOrder: OrderByDirection.Descending,
    sortBy: 'CreatedDate',
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [10, 20, 50, 100],
    pageSize: 10,
    page: 0,
    searchExpression: '',
  });

  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));
  const getCreditNote = async () => {
    const apiUrl = new URL(environment.api.baseUrl + 'CreditNote');
    try {
      let searchExpression = `EntityId~=~${props.paramsId} and EntityType~=~${props.entityType}`;
      searchExpression += gridData.searchExpression
        ? ` and Description~like~${gridData.searchExpression}`
        : '';

      apiUrl.searchParams.set('$filter', searchExpression);
      apiUrl.searchParams.set('$orderby', gridData.sortBy);
      apiUrl.searchParams.set(
        '$skip',
        (gridData.page * gridData.pageSize).toString()
      );
      apiUrl.searchParams.set('$top', gridData.pageSize.toString());
      apiUrl.searchParams.set(
        '$orderbydirection',
        gridData.sortOrder.toString()
      );
      const result = await http.get(apiUrl.toString());
      if (result) {
        updateGridData('totalRows', result.data.totalCount);
        updateGridData('rows', result.data.values);
      }
      if (
        props.entityType == EntityTypeEnum.ShippingOrders &&
        props.refreshCount
      ) {
        props.refreshCount();
      }
    } catch (error) {}
  };

  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    if (!params.row.isManual) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleSaveClick = (id: GridRowId) => () => {
    updateGridData(
      'rows',
      gridData.rows.filter(function (row: any) {
        return row.id !== 0;
      })
    );
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const keydown = (event: any) => {
    if (event.altKey && event.keyCode == 65) {
      var addButton = document.getElementById('add-credit-note');
      addButton?.click();
    }
  };

  useEffect(() => {
    //document.addEventListener('keydown', keydown, false);
    getCreditNote();
  }, [
    gridData.page,
    gridData.pageSize,
    gridData.sortBy,
    gridData.sortOrder,
    gridData.searchExpression,
    refreshGrid,
  ]);

  const handleChangePage = (pageNo: number) => {
    updateGridData('page', pageNo);
  };

  const handleChangePageSize = (pageSize: number) => {
    updateGridData('pageSize', pageSize);
  };
  type Row = (typeof gridData.rows)[number];

  const deleteCreditNote = (id: any) => {
    setDeleteModal(true);
    setId(id);
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    deleteCreditNote(id);
  };

  const columns: GridColumns<Row> = [
    {
      field: 'description',
      headerName: I18n('CreditNote.Description'),
      flex: 1,
      sortable: true,
      editable: true,
      renderEditCell: (params) => {
        return <DescriptionEditComponent {...params} />;
      },
    },
    {
      field: 'note',
      headerName: I18n('CreditNote.Note'),
      flex: 1,
      sortable: true,
      editable: true,
      renderEditCell: (params) => {
        return <NoteEditComponent {...params} />;
      },
    },
    {
      field: 'amount',
      headerName: I18n('CreditNote.Amount'),
      flex: 1,
      sortable: true,
      editable: true,
      renderEditCell: (params) => {
        return <AmountComponent {...params} />;
      },
      renderCell: (params) => {
        return `${params.value > 0 ? '-' : ''}${params.value
          .toString()
          .replaceAll('.', ',')}`; // Show amount in minus
      },
    },
    {
      field: 'actions',
      headerName: I18n('Common.Actions'),
      type: 'actions',
      width: 150,
      getActions: (params: any) => {
        const actionButtons = [];

        const isInEditMode =
          rowModesModel[params.id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          actionButtons.push(
            <GridActionsCellItem
              onPointerEnterCapture={false}
              onPointerLeaveCapture={false}
              placeholder={''}
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(params.id)}
            />
          );
          actionButtons.push(
            <GridActionsCellItem
              onPointerEnterCapture={false}
              onPointerLeaveCapture={false}
              placeholder={''}
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(params.id)}
              color="inherit"
            />
          );
        } else {
          if (canEdit) {
            actionButtons.push(
              <GridActionsCellItem
                onPointerEnterCapture={false}
                onPointerLeaveCapture={false}
                placeholder={''}
                icon={<EditIcon />}
                label="Edit"
                className="textPrimary"
                onClick={handleEditClick(params.id)}
                color="inherit"
              />
            );
          }
          if (canDelete) {
            actionButtons.push(
              <GridActionsCellItem
                onPointerEnterCapture={false}
                onPointerLeaveCapture={false}
                placeholder={''}
                icon={<DeleteIcon />}
                label="Delete"
                onClick={handleDeleteClick(params.id)}
                color="inherit"
              />
            );
          }
        }
        return actionButtons;
      },
    },
  ];

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  function DescriptionEditComponent(props: GridRenderEditCellParams) {
    const { id, value, field } = props;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const apiRef = useGridApiContext();

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      apiRef.current.setEditCellValue({ id, field, value: newValue });
    };
    return (
      <CustomizedInputs
        margin="normal"
        autoFocus={true}
        required
        id="description"
        inputType="text"
        inputProps={{ maxLength: 500 }}
        handleChange={handleValueChange}
        value={value}
        multiline={false}
      ></CustomizedInputs>
    );
  }

  function NoteEditComponent(props: GridRenderEditCellParams) {
    const { id, value, field } = props;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const apiRef = useGridApiContext();

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      apiRef.current.setEditCellValue({ id, field, value: newValue });
    };
    return (
      <CustomizedInputs
        margin="normal"
        required
        id="note"
        inputType="text"
        inputProps={{ maxLength: 500 }}
        handleChange={handleValueChange}
        value={value}
        multiline={false}
      ></CustomizedInputs>
    );
  }

  function AmountComponent(props: GridRenderEditCellParams) {
    const { id, value, field } = props;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const apiRef = useGridApiContext();

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      apiRef.current.setEditCellValue({ id, field, value: newValue });
    };
    return (
      <DecimalInput
        inputProps={{ maxLength: 10 }}
        name="rate"
        handleChange={handleValueChange}
        value={value}
        decimalScaleValue={2}
        allowNegativeValue={false}
      ></DecimalInput>
    );
  }

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow: any = gridData.rows.find((row: any) => row.id === id);
    if (editedRow!.isNew) {
      updateGridData(
        'rows',
        gridData.rows.filter((row: any) => row.id !== id)
      );
    }
    getCreditNote();
  };

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    if (sortModel && sortModel.length) {
      updateGridData('sortBy', sortModel[0].field);
      updateGridData(
        'sortOrder',
        sortModel[0].sort === 'asc'
          ? OrderByDirection.Ascending
          : OrderByDirection.Descending
      );
    }
  }, []);

  const handleClose = () => {
    setDeleteModal(false);
  };

  const handleOk = () => {
    setDeleteModal(false);
    deleteData(selectedId);
  };

  const i18nMessages = {
    deletedSuccessfully: I18n('CreditNote.DeletedSuccessfully'),
    errorOccurred: I18n('CreditNote.ErrorOccurred'),
    yes: I18n('Common.Yes'),
    no: I18n('Common.No'),
    fieldRequired: I18n('CreditNote.FieldRequired'),
    descriptionFieldRequired: I18n('CreditNote.DescriptionFieldRequired'),
    amountFieldRequired: I18n('CreditNote.AmountFieldRequired'),
    savedSuccessfully: I18n('CreditNote.SavedSuccessfully'),
  };

  const setModels = (newModel: any) => {
    if (newModel[0] === undefined) {
      setRowModesModel(newModel);
    } else if (
      newModel[0] !== undefined &&
      newModel[0].cellToFocusAfter === 'right'
    ) {
      updateGridData(
        'rows',
        gridData.rows.filter(function (row: any) {
          return row.id !== 0;
        })
      );
      setRowModesModel(newModel);
    }
  };

  interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (
      newModel: (oldModel: GridRowModesModel) => GridRowModesModel
    ) => void;
  }

  function EditToolbar(props: EditToolbarProps) {
    const handleClick = () => {
      var existingRow = gridData.rows.find((x: any) => x.id === 0);
      if (existingRow === undefined) {
        const id = 0;
        var temp: any = [];
        for (let i = 0; i < gridData.rows.length; i++) {
          temp.push(gridData.rows[i]);
        }
        temp.push({
          id: 0,
          description: '',
          note: '',
          amount: '',
        });
        updateGridData('rows', temp);
        updateGridData('totalRows', temp.length);
        setRowModesModel((oldModel) => ({
          ...oldModel,
          [id]: {
            mode: GridRowModes.Edit,
            fieldToFocus: 'description',
          },
        }));
      }
    };

    return (
      <GridToolbarContainer>
        {canAdd ? (
          <Button
            color="primary"
            id="add-credit-note"
            startIcon={<AddIcon />}
            onClick={handleClick}
          >
            {I18n('Common.Add')}
          </Button>
        ) : (
          <></>
        )}
      </GridToolbarContainer>
    );
  }

  const validate = (fieldValues: any) => {
    if ('description' in fieldValues) {
      if (fieldValues.description === '') {
        return i18nMessages.descriptionFieldRequired;
      }
    }
    if ('amount' in fieldValues) {
      if (fieldValues.amount === '') {
        return i18nMessages.amountFieldRequired;
      }
    }
    return '';
  };

  const save = async (values: any) => {
    var validateMessage = '';
    validateMessage = validate(values);

    if (validateMessage === '') {
      const creditNoteDto = {
        id: values.id,
        entityType: props.entityType,
        entityId: Number(props.paramsId),
        description: values.description,
        note: values.note,
        amount: values.amount ? values.amount.toString().replace(/,/g, '.') : 0,
      };

      const apiUrl = new URL(environment.api.baseUrl + 'CreditNote');
      try {
        const result: any =
          creditNoteDto.id === 0
            ? await http.post(apiUrl.toString(), creditNoteDto)
            : await http.put(apiUrl.toString(), creditNoteDto);

        if (result && result.data && result.data.isSuccess) {
          toast.success(i18nMessages.savedSuccessfully);
        } else {
          validateMessage = i18nMessages.errorOccurred;
        }
      } catch (error: any) {
        validateMessage = i18nMessages.errorOccurred;
      }
    }
    return validateMessage;
  };

  async function handleProcessRowUpdate(newRow: GridRowModel) {
    let updatedRow: any = {};
    if (newRow.id === undefined) {
      updatedRow = { ...newRow, isNew: false, id: 0 };
    } else {
      updatedRow = { ...newRow, isNew: false };
    }
    var validation = await save(updatedRow);

    if (validation === '') {
      if (newRow.id === undefined) {
        var temp: any = [];
        for (var i = 0; i < gridData.rows.length; i++) {
          temp.push(gridData.rows[i]);
        }

        temp.push(updatedRow);
        updateGridData('rows', temp);
        setRefreshGrid(!refreshGrid);
        return updatedRow;
      } else {
        updateGridData(
          'rows',
          gridData.rows.map((row: any) =>
            row.id === newRow.id ? updatedRow : row
          )
        );
        setRefreshGrid(!refreshGrid);
        return updatedRow;
      }
    } else {
      if (updatedRow.id === 0) {
        var temp1: any = [];
        for (let i = 0; i < gridData.rows.length; i++) {
          temp1.push(gridData.rows[i]);
        }

        var existingRecord = {
          ...updatedRow,
          isNew: true,
          isManual: true,
        };

        updatedRow.isNew = true;
        updatedRow.isManual = true;

        temp1.push(updatedRow);
        updateGridData('rows', temp1);
        updateGridData('totalRows', temp1.length);
        setTimeout(() => {
          var oldData: any = [];
          for (var i = 0; i < temp1.length; i++) {
            if (temp1[i].id == 0) {
              oldData.push(existingRecord);
            } else {
              oldData.push(temp1[i]);
            }
          }
          updateGridData('rows', oldData);

          setTimeout(() => {
            setRowModesModel({
              ...rowModesModel,
              0: {
                mode: GridRowModes.Edit,
                fieldToFocus: 'description',
              },
            });
          }, 200);
        }, 200);
      } else {
        setTimeout(() => {
          setRowModesModel({
            ...rowModesModel,
            [updatedRow.id]: {
              mode: GridRowModes.Edit,
              fieldToFocus: 'description',
            },
          });
        }, 200);
      }
      toast.error(validation);
      return updatedRow;
    }
  }

  const handleProcessRowUpdateError = (error: Error) => {};

  const deleteData = async (id: any) => {
    const apiUrl = new URL(environment.api.baseUrl + 'CreditNote/' + id);
    try {
      const result: any = await http.delete(apiUrl.toString());
      if (result.data) {
        getCreditNote();

        toast.success(i18nMessages.deletedSuccessfully);
      } else {
        toast.error(i18nMessages.errorOccurred);
      }
    } catch (error: any) {}
  };

  return (
    <div>
      <Typography variant="h5" className="inner-heading">
        {I18n('CreditNote.Label')}
      </Typography>
      <div className="">
        <div className="content-block table-fix">
          <CustomDataGridInline
            rowCount={gridData.totalRows}
            data={gridData.rows}
            columns={columns}
            onSortModelChange={handleSortModelChange}
            pageNo={gridData.page}
            pageSize={gridData.pageSize}
            rowModesModel={rowModesModel}
            onRowModesModelChange={(newModel: any) => {
              setModels(newModel);
            }}
            onPageChange={handleChangePage}
            onPageSizeChange={handleChangePageSize}
            onRowEditStart={handleRowEditStart}
            rowsPerPageOptions={gridData.rowsPerPageOptions}
            initialState={{
              sorting: {
                sortModel: [{ field: 'updatedDate', sort: 'desc' }],
              } as GridSortingInitialState,
              pagination: {
                page: gridData.page,
                pageSize: gridData.pageSize,
              } as GridPaginationInitialState,
            }}
            components={{
              Toolbar: EditToolbar,
            }}
            componentsProps={{
              toolbar: { setRows, setRowModesModel },
            }}
            processRowUpdate={handleProcessRowUpdate}
            onProcessRowUpdateError={handleProcessRowUpdateError}
          ></CustomDataGridInline>

          <ConfirmationPopup
            isOpen={isDeleteModalOpen}
            data={selectedId}
            message={I18n('CreditNote.DeleteMessage')}
            handleClose={handleClose}
            handleOk={handleOk}
            title={I18n('CreditNote.DeleteCreditNote')}
          ></ConfirmationPopup>
        </div>
      </div>
    </div>
  );
}
