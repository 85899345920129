import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import http from '../../utilities/httpService';
import { useState } from 'react';
import { debounce } from 'lodash';
import environment from 'environment';
import './AddressAutocompleteInput.scss';
export const AddressAutocompleteInputs = (props: any) => {
  const [options, setOptions] = useState<readonly string[]>([]);
  const {
    value,
    displayLabel,
    errorValue,
    errorMessage,
    className = '',
    inputProps,
    required,
    handleChange,
    readOnly,
    disabled,
  } = props;

  const defaultValues = {
    country: '',
    postcode: '',
    place: '',
  };

  const handleTextChange = debounce(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value.length > 0) {
        const apiUrl =
          new URL(`https://api.mapbox.com/geocoding/v5/mapbox.places/
       ${event.target.value}.json?`);
        apiUrl.searchParams.set('autocomplete', 'true');
        apiUrl.searchParams.set('language', 'nl,en');
        apiUrl.searchParams.set('types', 'address,postcode');
        apiUrl.searchParams.set(
          'access_token',
          `${environment.api.mapboxToken}`
        );
        const response = await http.get(apiUrl.toString());
        if (
          response &&
          response.data.features &&
          response.data.features.length
        ) {
          setOptions(response.data.features);
          handleChange(event.target.value, defaultValues);
        } else {
          setOptions([]);
          handleChange('', defaultValues);
        }
      }
    },
    500
  );

  const parsePlacesGeo = (geoData: any) => {
    let place,
      region,
      country,
      postcode,
      street,
      returnStr,
      longitude,
      latitude;
    if (geoData.context) {
      geoData.context.forEach((v: any, i: number) => {
        if (v.id.indexOf('place') >= 0) {
          place = v.text_en;
        }
        if (v.id.indexOf('postcode') >= 0) {
          postcode = v.text_en;
        }
        if (v.id.indexOf('region') >= 0) {
          region = v.text_en;
        }
        if (v.id.indexOf('country') >= 0) {
          country = v.text_en;
        }
      });
    }
    if (geoData.geometry?.coordinates) {
      longitude = String(geoData.geometry?.coordinates[0]);
      latitude = String(geoData.geometry?.coordinates[1]);
    }
    if (
      geoData?.id.indexOf('postcode') >= 0 &&
      (!postcode || postcode === undefined)
    ) {
      postcode = geoData.text_en;
    }
    street = geoData.address;
    if (geoData?.id.indexOf('address') >= 0) {
      street = `${geoData.text_en} ${geoData.address ? geoData.address : ''}`;
    } else if (geoData?.properties?.address) {
      street = geoData?.properties?.address;
    }
    if (postcode && region && country) {
      returnStr = `${geoData.address} ${geoData.text}, ${place} ${region} ${postcode}, ${country}`;
    } else {
      returnStr = geoData.place_name;
    }
    return {
      number: street,
      address: geoData.text_en,
      place,
      region,
      postcode,
      country,
      concat: returnStr,
      complete: geoData.place_name_en,
      longitude,
      latitude,
    };
  };
  return (
    <FormControl
      variant="standard"
      fullWidth
      sx={{ mb: 2 }}
      error={errorMessage}
    >
      <InputLabel shrink htmlFor="bootstrap-input">
        {displayLabel}
        {required ? '*' : ''}
      </InputLabel>
      <Autocomplete
        freeSolo
        id={displayLabel}
        readOnly={readOnly}
        disabled={disabled}
        value={value}
        onInputChange={(event, newInputValue) => {
          if (newInputValue !== '') {
            let selectedPlace = options.find(
              (option: any) => option.place_name === newInputValue
            );
            if (selectedPlace) {
              let parsedSelected = parsePlacesGeo(selectedPlace);
              handleChange(parsedSelected.complete, parsedSelected);
            } else {
              handleChange(newInputValue, defaultValues);
            }
          } else {
            handleChange(newInputValue, defaultValues);
          }
        }}
        disableClearable
        options={options.map((option: any) => option.place_name)}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              ...inputProps,
              type: 'search',
            }}
            onChange={handleTextChange}
            className={className}
          />
        )}
      />
      {errorValue ? <FormHelperText>{errorMessage}</FormHelperText> : ''}
    </FormControl>
  );
};

export default AddressAutocompleteInputs;
