import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from 'react-select';
import { FormHelperText } from '@mui/material';

export const CustomizedSelect = (props: any) => {
  const {
    displayLabel,
    options,
    handleChange,
    defaultValue,
    isSearchable,
    placeholder,
    value,
    name,
    errorValue,
    required,
    readOnly,
    errorMessage,
    isMulti = false,
    isDisplayLabelClass = false,
    autoFocus = false,
    onFocus,
    isOptionDisabled,
    menuPlacement = 'bottom',
  } = props;
  return (
    <FormControl
      variant="standard"
      fullWidth
      sx={{ mb: 2 }}
      error={errorMessage}
    >
      <InputLabel shrink htmlFor="bootstrap-input">
        {displayLabel}
        <span> {required ? '*' : ''}</span>
      </InputLabel>
      <Select
        autoFocus={autoFocus}
        name={name}
        options={options}
        onChange={handleChange}
        defaultValue={defaultValue}
        isSearchable={true}
        placeholder={placeholder}
        value={value}
        className={
          displayLabel || isDisplayLabelClass
            ? 'custom-select-wrap'
            : 'custom-select-wrap mt-0'
        }
        classNamePrefix="custom-select"
        menuPortalTarget={document.body}
        isMulti={isMulti}
        styles={{
          menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
        }}
        isDisabled={readOnly}
        closeMenuOnSelect={!isMulti}
        onFocus={onFocus}
        menuPlacement={menuPlacement}
        isOptionDisabled={isOptionDisabled}
      />
      {errorValue ? <FormHelperText>{errorMessage}</FormHelperText> : ''}
    </FormControl>
  );
};
export default CustomizedSelect;
