import { faBedPulse } from '@fortawesome/free-solid-svg-icons';
import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Link,
  Typography,
} from '@mui/material';
import FormAccordian from 'common/formControl/formAccordian';

import Status from 'common/WoStatus/Status';
import environment from 'environment';
import { DnPWorkOrders } from 'models/deck-pack-orders';
import {
  EntityTypeEnum,
  DocumentEntity,
  BaseModel,
  DGDDescription,
  ActivityOwner,
  OrderType,
} from 'models/pagination-model';
import RouteEnum from 'models/RouteEnum';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import I18n from 'utilities/i18n';
import http from '../../../utilities/httpService';
import DecPackShippingMaterial from '../DecPackShippingMaterial';
import CreatedByandUpdatedBy from 'common/CreatedByandUpdatedBy/CreatedByandUpdatedBy';

function WO12() {
  const { woId, id } = useParams();
  const [status, setStatus] = useState('');
  let navigate = useNavigate();

  const [expandedInvoice, setExpandedInvoice] = useState<boolean>(true);
  const [editInvoice, setEditInvoice] = useState<boolean>(false);

  const handleExpandedInvoiceClick = (event: React.SyntheticEvent) => {
    setExpandedInvoice(!expandedInvoice);
  };

  const handleDetailsEditClick = (event: React.SyntheticEvent) => {
    setEditInvoice(!editInvoice);
  };
  const [invoiceValue, setInvoiceValues] = useState<boolean>(false);

  const InvoiceData = () => {
    return (
      <>
        <Grid item lg={12} md={12}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(event: any) =>
                  setInvoiceValues(event.target.checked)
                }
                inputProps={{ 'aria-label': 'controlled' }}
                checked={invoiceValue}
                disabled={!editInvoice}
              />
            }
            label={i18nMessages.detailsTitle}
          />
        </Grid>
      </>
    );
  };

  const breadcrumbs = [
    <Typography key="3" color="text.primary">
      {I18n('Nav.Order')}
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={() => navigate(RouteEnum.DecPackOrdersList)}
        className="cpointer"
      >
        {I18n('Nav.DecPackOrders')}
      </Link>
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={() => navigate(RouteEnum.ViewDecPackOrders + '/' + id + '/1')}
        className="cpointer"
      >
        {I18n('ShippingOrders.WorkOrders')}
      </Link>
    </Typography>,
  ];
  const defaultValue = {
    value: '0',
    label: I18n('Placeholders.Select'),
  } as BaseModel;

  const i18nMessages = {
    title: I18n('DecPackOrders.WorkOrder.ApprovalInvoice'),
    detailsTitle: I18n('WorkOrder.ApprovalInvoice'),
    updatedSuccessfully: I18n('WorkOrder.UpdatedSuccessfully'),
    errorOccurred: I18n('WorkOrder.ErrorOccurred'),
  };

  const [values, setValues] = useState<any>({
    id: 0,
    availableData: 0,
    status: 0,
    assignedTo: '',
  });
  const [details, setDetails] = useState<any>({
    createdBy: '',
    createdDate: '',
    updatedBy: '',
    updatedDate: '',
  });

  let entityWorkOrderStatusList: Array<BaseModel> = [];
  const getStatusList = () => {
    entityWorkOrderStatusList = [defaultValue];
    DGDDescription.forEach((value: string, key: number) => {
      entityWorkOrderStatusList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getStatusList();

  const getWOStatus = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl +
        'DecPackOrder/GetDecPackWO/' +
        id +
        '/' +
        DnPWorkOrders.WO10
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result) {
        setStatus(result.data.status);
        setDetails({
          ...details,
          createdBy: result.data.createdBy,
          updatedBy: result.data.updatedBy,
          createdDate: result.data.createdDate,
          updatedDate: result.data.updatedDate,
        });
        return result.data.status;
      }
    } catch (error) {}
  };
  const getDecPackById = async () => {
    const apiUrl = new URL(environment.api.baseUrl + `DecPackOrder/${id}`);
    try {
      const result = await http.get(apiUrl.toString());

      if (result && result.data) {
        setInvoiceValues(result.data.approvedInvoice);
      }
    } catch (error) {}
  };
  const handleSaveClick = async () => {
    try {
      const apiUrl = new URL(
        environment.api.baseUrl + 'DecPackOrder/UpdateInvoice'
      );

      var data = {
        id,
        approvedInvoice: invoiceValue,
      };

      const result: any = await http.put(apiUrl.toString(), data);
      if (result) {
        toast.success(i18nMessages.updatedSuccessfully);
      } else {
        toast.error(i18nMessages.errorOccurred);
      }
    } catch (error: any) {
      toast.error(i18nMessages.errorOccurred);
    }
  };

  useEffect(() => {
    getWOStatus();
    getDecPackById();
  }, []);

  return (
    <>
      <div className="heading-section">
        <div className="heading-section-left">
          <Typography variant="h3" sx={{ mb: 3 }}>
            {i18nMessages.title}
          </Typography>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>
      </div>
      <div className="main-content-section">
        <DecPackShippingMaterial
          shippingOrderId={id ? id : 0}
          isAddShippingMaterial={false}
          showAlertMessage={true}
        ></DecPackShippingMaterial>

        <Grid item lg={6} md={6}>
          <FormAccordian
            title={i18nMessages.detailsTitle}
            className="inner-heading"
            details={InvoiceData()}
            expanded={expandedInvoice}
            isEdit={editInvoice}
            handleEditClick={handleDetailsEditClick}
            handleExpandCollapseClick={handleExpandedInvoiceClick}
            isSaveButton={editInvoice}
            onSaveClick={handleSaveClick}
          ></FormAccordian>
        </Grid>
        <Grid item lg={12} md={12}>
          <Status
            woId={DnPWorkOrders.WO12}
            activityOwner={ActivityOwner.BackOffice}
            assignedToName={''}
            orderId={id}
            statusValue={status}
            orderType={OrderType.DecPackOrder}
          />
        </Grid>
        <CreatedByandUpdatedBy
          createdBy={details.createdBy}
          createdDate={details.createdDate}
          updatedBy={details.updatedBy}
          updatedDate={details.updatedDate}
        ></CreatedByandUpdatedBy>
      </div>
    </>
  );
}

export default WO12;
