import TabList from '@mui/lab/TabList';
import TabContext from '@mui/lab/TabContext';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import RouteEnum from 'models/RouteEnum';
import { Link } from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import I18n from 'utilities/i18n';
import { Breadcrumbs } from '@mui/material';
import TabPanel from '@mui/lab/TabPanel';
import Note from 'common/notes/Notes';
import TransportCatalogueDetails from './TransportCatalougeDetails';
import {
  TransportType,
  DocumentEntity,
  EntityTypeEnum,
  PricingType,
} from 'models/pagination-model';
import AirPricingTab from './pricing/AirPricingTab';
import OwnPricingTab from './pricing/OwnPricingTab';
import http from '../../utilities/httpService';
import environment from 'environment';
import RoadPricingTab from './pricing/RoadPricingTab';
import ShipPricingTab from './pricing/ShipPricingTab';
import TrainPricingTab from './pricing/TrainPricingTab';
import DocumentList from 'common/Document/DocumentList';
import { Permission } from 'Permissions';

export default function TransportCatalogueTabs() {
  const [transportType, setTransportType]: any[] = useState([]);
  const [Zones, setZones] = useState('');

  const navigate = useNavigate();
  const handleListClick = () => {
    navigate(RouteEnum.Transport);
  };

  const i18nMessages = {
    noteTabTitle: I18n('TransportCatalogue.Notes'),
    documentTabTitle: I18n('TransportCatalogue.Documents'),
    airPricing: I18n('TransportCatalogue.airPricing'),
    roadPricing: I18n('TransportCatalogue.roadPricing'),
    trainPricing: I18n('TransportCatalogue.trainPricing'),
    shipPricing: I18n('TransportCatalogue.shipPricing'),
    detailsTabTitle: I18n('TransportCatalogue.Detail'),
    ownPricing: I18n('TransportCatalogue.OwnPricing'),
  };
  const breadcrumbs = [
    <Typography key="3" color="text.primary">
      {I18n('Nav.Configuration')}
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={handleListClick}
        className="cpointer"
      >
        {I18n('Nav.TransportCatalogue')}
      </Link>
    </Typography>,
    <Typography key="3" color="text.primary">
      {I18n('TransportCatalogue.Details')}
    </Typography>,
  ];
  const [value, setValue] = React.useState<string>('1');
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const { entityId } = useParams();
  const getTransportCatalogueById = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl + `TransportCatalogues/${entityId}`
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result && result.data) {
        setZones(result.data.fromZoneName + ' to ' + result.data.toZoneName);
        const item = result.data.transportTypes.map((data: any) => {
          return data.id;
        });
        setTransportType(item);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getTransportCatalogueById();
  }, []);

  return (
    <div>
      <div className="heading-section">
        <div className="heading-section-left">
          <Typography variant="h3" sx={{ mb: 3 }}>
            {I18n('Nav.TransportCatalogue')}
          </Typography>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>
      </div>
      <div className="main-content-section width-100">
        <TabContext value={value}>
          <Box>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              className="tab-bordered"
            >
              <Tab label={i18nMessages.detailsTabTitle} value="1" />
              {transportType?.includes(TransportType.Air) ? (
                <Tab label={i18nMessages.airPricing} value="2" />
              ) : (
                ''
              )}
              {transportType?.includes(TransportType.Road) ? (
                <Tab label={i18nMessages.roadPricing} value="3" />
              ) : (
                ''
              )}
              {transportType?.includes(TransportType.Train) ? (
                <Tab label={i18nMessages.trainPricing} value="4" />
              ) : (
                ''
              )}

              {transportType?.includes(TransportType.Ship) ? (
                <Tab label={i18nMessages.shipPricing} value="5" />
              ) : (
                ''
              )}

              {transportType?.includes(TransportType.Own) ? (
                <Tab label={i18nMessages.ownPricing} value="6" />
              ) : (
                ''
              )}

              <Tab label={i18nMessages.documentTabTitle} value="7" />
              <Tab label={i18nMessages.noteTabTitle} value="8" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <TransportCatalogueDetails />
          </TabPanel>
          <TabPanel value="2">
            <AirPricingTab
              entityType={EntityTypeEnum.TransportCatalogue}
              transportTypeId="1"
              zonesName={Zones}
            ></AirPricingTab>
          </TabPanel>
          <TabPanel value="3">
            <RoadPricingTab
              entityType={EntityTypeEnum.TransportCatalogue}
              transportTypeId="2"
              zonesName={Zones}
            ></RoadPricingTab>
          </TabPanel>
          <TabPanel value="4">
            <TrainPricingTab
              entityType={EntityTypeEnum.TransportCatalogue}
              transportTypeId="3"
              zonesName={Zones}
            ></TrainPricingTab>
          </TabPanel>
          <TabPanel value="5">
            <ShipPricingTab
              entityType={EntityTypeEnum.TransportCatalogue}
              transportTypeId="4"
              zonesName={Zones}
            ></ShipPricingTab>
          </TabPanel>
          <TabPanel value="6">
            <OwnPricingTab
              entityType={EntityTypeEnum.TransportCatalogue}
              transportTypeId="5"
              zonesName={Zones}
            ></OwnPricingTab>
          </TabPanel>
          <TabPanel value="7">
            <DocumentList
              permission={Permission.canEditTransportCatalogue}
              entityType={EntityTypeEnum.TransportCatalogue}
              addPermission={Permission.canAddTransportCatalogue}
              deletePermission={Permission.canDeleteTransportCatalogue}
              paramsId={entityId}
              documentEntityType={DocumentEntity.TransportCatalogue}
            />
          </TabPanel>
          <TabPanel value="8">
            <Note
              permission={Permission.canEditTransportCatalogue}
              addPermission={Permission.canAddTransportCatalogue}
              deletePermission={Permission.canDeleteTransportCatalogue}
              paramsId={entityId}
              entityType={EntityTypeEnum.TransportCatalogue}
            />
          </TabPanel>
        </TabContext>
      </div>
    </div>
  );
}
