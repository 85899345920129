import I18n from 'utilities/i18n';
import { useEffect, useCallback, useState } from 'react';
import http from '../../utilities/httpService';
import {
  OrderByDirection,
  KeyValueBaseModel,
  ApiResponseModel,
  ResponseType,
} from 'models/pagination-model';
import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faPlus } from '@fortawesome/free-solid-svg-icons';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from 'common/formControl/formControl';
import Typography from '@mui/material/Typography';
import {
  GridActionsCellItem,
  GridColumns,
  GridPaginationInitialState,
  GridRenderEditCellParams,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridRowsProp,
  GridSortingInitialState,
  GridSortModel,
  GridToolbarContainer,
  MuiEvent,
  useGridApiContext,
} from '@mui/x-data-grid';
import environment from 'environment';
import ConfirmationPopup from 'common/confirmationPopup/confirmationPopup';
import { toast } from 'react-toastify';
import { Permission } from 'Permissions';
import { hasPermission } from 'utilities/protectedRoute';
import { Breadcrumbs } from '@mui/material';
import CustomizedSelect from 'common/CustomSelect/CustomSelect';
import { getSettings } from 'components/customer/CustomerApiService';
import { SettingsType } from 'models/customer';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';
import DecimalInput from 'common/DecimalInput/DecimalInput';
import CustomDataGridInline from 'common/datagrid/CustomDataGridInline';

function ExchangeRateList() {
  const breadcrumbs = [
    <Typography key="3" color="text.primary">
      {I18n('Nav.Configuration')}
    </Typography>,
    <Typography key="3" color="text.primary">
      {I18n('Nav.ExchangeRates')}
    </Typography>,
  ];

  const [isDeleteModalOpen, setDeleteModal] = useState(false);
  const [selectedExchangeRateId, setExchangeRateId] = useState();
  const [refreshGrid, setRefreshGrid] = useState(false);
  const [gridData, setGridData] = useState({
    isLoading: true,
    sortOrder: OrderByDirection.Descending,
    sortBy: '',
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [10, 20, 50, 100],
    pageSize: 10,
    page: 0,
    searchExpression: '',
  });
  const [currencyList, setCurrencyList] = useState<KeyValueBaseModel[]>([]);
  const [defaultCurrency, setDefaultCurrency] = useState<KeyValueBaseModel>({
    key: '',
    label: '',
    value: '',
  });
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const defaultValue = {
    value: '',
    label: I18n('Placeholders.Select'),
  } as KeyValueBaseModel;

  const i18nMessages = {
    FromCurrencyRequired: I18n('ExchangeRate.Validation.FromCurrencyRequired'),
    ToCurrencyRequired: I18n('ExchangeRate.Validation.ToCurrencyRequired'),
    RateRequired: I18n('ExchangeRate.Validation.RateRequired'),
    MappingAlreadyExist: I18n('ExchangeRate.Validation.MappingAlreadyExist'),
    AddSuccess: I18n('ExchangeRate.ExchangeRateSaveSuccess'),
    SomethingWentWrong: I18n(
      'DecPackOrders.CheckDocumentation.SomethingWentWrong'
    ),
    UpdateSuccess: I18n('ExchangeRate.ExchangeRateUpdateSuccess'),
    DeleteSuccess: I18n('ExchangeRate.DeleteSuccess'),
    DeleteConfirmation: I18n('ExchangeRate.DeleteConfirmation'),
    Save: I18n('Common.Save'),
    Cancel: I18n('Common.Cancel'),
    Update: I18n('Common.Update'),
    Edit: I18n('Common.Edit'),
    Delete: I18n('Common.Delete'),
  };

  const getSettingsList = async () => {
    let responseCurrencyMaster = await getSettings(
      '',
      1,
      SettingsType.CurrencyMaster
    );
    let options = responseCurrencyMaster.Values.map((x: any) => {
      return {
        value: x.id.toString(),
        label: x.value,
        key: x.key,
      } as KeyValueBaseModel;
    });
    setCurrencyList(options);

    let defaultOption = options.filter((item: any) => item.key === 'EUR');
    setDefaultCurrency(defaultOption[0]);
  };

  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));

  const getExchangeRates = async () => {
    updateGridData('isLoading', true);

    const apiUrl = new URL(environment.api.baseUrl + 'ExchangeRate');
    try {
      apiUrl.searchParams.set('$filter', gridData.searchExpression);
      apiUrl.searchParams.set('$orderby', gridData.sortBy);
      apiUrl.searchParams.set(
        '$skip',
        (gridData.page * gridData.pageSize).toString()
      );
      apiUrl.searchParams.set('$top', gridData.pageSize.toString());
      apiUrl.searchParams.set(
        '$orderbydirection',
        gridData.sortOrder.toString()
      );
      const result = await http.get(apiUrl.toString());
      if (result) {
        updateGridData('totalRows', result.data.totalCount);
        updateGridData('rows', result.data.values);
        updateGridData('isLoading', false);
      }
    } catch (error) {}
  };

  const keydown = (event: any) => {
    if (event.altKey && event.keyCode == 65) {
      var addButton = document.getElementById('addRecord');
      addButton?.click();
    }
  };

  useEffect(() => {
    getExchangeRates();
  }, [
    gridData.page,
    gridData.pageSize,
    gridData.sortBy,
    gridData.sortOrder,
    gridData.searchExpression,
    refreshGrid,
  ]);

  useEffect(() => {
    getSettingsList();
  }, []);

  const handleChangePage = (pageNo: number) => {
    updateGridData('page', pageNo);
  };

  const handleChangeBasicSearch = (searchExpression: any) => {
    updateGridData(
      'searchExpression',
      'fromCurrencyMaster.value~like~' +
        searchExpression.target.value +
        ' or toCurrencyMaster.value~like~' +
        searchExpression.target.value
    );
  };
  const handleChangePageSize = (pageSize: number) => {
    updateGridData('pageSize', pageSize);
  };

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    if (sortModel && sortModel.length) {
      if (sortModel[0].field === 'fromCurrencyName') {
        updateGridData('sortBy', 'fromCurrencyMaster.value');
      } else if (sortModel[0].field === 'toCurrencyName') {
        updateGridData('sortBy', 'toCurrencyMaster.value');
      } else {
        updateGridData('sortBy', sortModel[0].field);
      }
      updateGridData(
        'sortOrder',
        sortModel[0].sort === 'asc'
          ? OrderByDirection.Ascending
          : OrderByDirection.Descending
      );
    }
  }, []);

  const handleCloseForDelete = async () => {
    setDeleteModal(false);
  };

  const handleOkForDelete = () => {
    setDeleteModal(false);
    deleteExchangeRate(selectedExchangeRateId);
  };

  const deleteExchangeRate = async (id: any) => {
    updateGridData('isLoading', true);
    const apiUrl = new URL(environment.api.baseUrl + 'ExchangeRate/' + id);
    try {
      const result: any = await http.delete(apiUrl.toString());
      if (result) {
        toast.success(i18nMessages.DeleteSuccess);
        updateGridData('isLoading', false);
        setRefreshGrid(!refreshGrid);
      }
    } catch (error: any) {}
  };

  type Row = (typeof gridData.rows)[number];

  const validate = (fieldValues: any) => {
    if ('fromCurrencyId' in fieldValues) {
      if (fieldValues.fromCurrencyId === defaultValue.value) {
        return i18nMessages.FromCurrencyRequired;
      }
    }
    if ('toCurrencyId' in fieldValues) {
      if (fieldValues.toCurrencyId === defaultValue.value) {
        return i18nMessages.ToCurrencyRequired;
      }
    }
    if ('rate' in fieldValues) {
      if (fieldValues.rate === '') {
        return i18nMessages.RateRequired;
      }
    }
    return '';
  };

  const saveDetails = async (values: any) => {
    var validateMessage = '';
    // Required validation
    validateMessage = validate(values);
    if (validateMessage === '') {
      const exchangeRateMapping = {
        Id: values.id,
        FromCurrencyId: +values.fromCurrencyId,
        ToCurrencyId: +values.toCurrencyId,
        Rate: values.rate ? values.rate.toString().replace(/,/g, '.') : 0,
      };

      const apiUrl = new URL(environment.api.baseUrl + 'ExchangeRate');
      try {
        const result: any =
          exchangeRateMapping.Id === 0
            ? await http.post(apiUrl.toString(), exchangeRateMapping)
            : await http.put(apiUrl.toString(), exchangeRateMapping);

        if (result.data !== '') {
          var response: any = {
            responseType: result.data.responseType,
            data: result.data.result,
            isSuccess: result.data.isSuccess,
          } as ApiResponseModel<any>;

          if (response.isSuccess) {
            if (response.responseType === ResponseType.AddSuccess) {
              toast.success(i18nMessages.AddSuccess);
            }
            if (response.responseType === ResponseType.UpdateSuccess) {
              toast.success(i18nMessages.UpdateSuccess);
            }
          } else {
            if (response.responseType === ResponseType.Duplicate) {
              validateMessage = i18nMessages.MappingAlreadyExist;
            }
          }
        }
      } catch (error: any) {
        validateMessage = i18nMessages.SomethingWentWrong;
      }
    }
    return validateMessage;
  };

  async function handleProcessRowUpdate(newRow: GridRowModel) {
    let updatedRow: any = {};
    if (newRow.id === undefined) {
      updatedRow = { ...newRow, isNew: false, id: 0 };
    } else {
      updatedRow = { ...newRow, isNew: false };
    }

    var validation = await saveDetails(updatedRow);

    if (validation === '') {
      if (newRow.id === undefined) {
        var temp: any = [];
        for (var i = 0; i < gridData.rows.length; i++) {
          temp.push(gridData.rows[i]);
        }

        updatedRow.isNew = true;
        temp.push(updatedRow);
        updateGridData('rows', temp);
        setRefreshGrid(!refreshGrid);

        return updatedRow;
      } else {
        updateGridData(
          'rows',
          gridData.rows.map((row: any) =>
            row.id === newRow.id ? updatedRow : row
          )
        );
        setRefreshGrid(!refreshGrid);
        return updatedRow;
      }
    } else {
      if (updatedRow.id === 0) {
        var temp1: any = [];
        for (let i = 0; i < gridData.rows.length; i++) {
          temp1.push(gridData.rows[i]);
        }

        var existingRecord = {
          ...updatedRow,
          isNew: true,
        };
        updatedRow.isNew = true;
        temp1.push(updatedRow);
        updateGridData('rows', temp1);
        updateGridData('totalRows', temp1.length);
        setTimeout(() => {
          var oldData: any = [];
          for (var i = 0; i < temp1.length; i++) {
            if (temp1[i].id == 0) {
              oldData.push(existingRecord);
            } else {
              oldData.push(temp1[i]);
            }
          }
          updateGridData('rows', oldData);

          setTimeout(() => {
            setRowModesModel({
              ...rowModesModel,
              0: {
                mode: GridRowModes.Edit,
                fieldToFocus: 'rate',
              },
            });
          }, 200);
        }, 200);
      } else {
        setTimeout(() => {
          setRowModesModel({
            ...rowModesModel,
            [updatedRow.id]: {
              mode: GridRowModes.Edit,
              fieldToFocus: 'rate',
            },
          });
        }, 200);
      }
      toast.error(validation);
      return updatedRow;
    }
  }

  const handleProcessRowUpdateError = useCallback((error: Error) => {
    //toast.error(error.message);
  }, []);

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    updateGridData(
      'rows',
      gridData.rows.filter(function (row: any) {
        return row.id !== 0;
      })
    );
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: any) => () => {
    setDeleteModal(true);
    setExchangeRateId(id);
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow: any = gridData.rows.find((row: any) => row.id === id);
    if (editedRow!.isNew) {
      updateGridData(
        'rows',
        gridData.rows.filter((row: any) => row.id !== id)
      );
    }
    getExchangeRates();
  };

  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    if (hasPermission(Permission.canEditExchangeRate)) {
      event.defaultMuiPrevented = true;
    }
  };

  interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (
      newModel: (oldModel: GridRowModesModel) => GridRowModesModel
    ) => void;
  }

  function EditToolbar(props: EditToolbarProps) {
    const handleClick = () => {
      var existingRow = gridData.rows.find((x: any) => x.id === 0);
      if (existingRow === undefined) {
        const id = 0;
        var temp: any = [];
        for (let i = 0; i < gridData.rows.length; i++) {
          temp.push(gridData.rows[i]);
        }
        temp.push({
          id: 0,
          fromCurrencyId: defaultCurrency.value,
          fromCurrencyName: defaultCurrency.label,
          toCurrencyId: defaultValue.value,
          toCurrencyName: defaultValue.label,
          rate: 0,
          isNew: true,
        });
        updateGridData('rows', temp);
        updateGridData('totalRows', temp.length);
        setRowModesModel((oldModel) => ({
          ...oldModel,
          [id]: {
            mode: GridRowModes.Edit,
            fieldToFocus: 'rate',
          },
        }));
      }
    };

    return (
      <GridToolbarContainer>
        {hasPermission(Permission.canAddExchangeRate) ? (
          <Button
            color="primary"
            id="addRecord"
            startIcon={<AddIcon />}
            onClick={handleClick}
          >
            {I18n('Common.Add')}
          </Button>
        ) : (
          <></>
        )}
      </GridToolbarContainer>
    );
  }

  function FromCurrencyEditComponent(props: GridRenderEditCellParams) {
    const { id, field } = props;
    const [fromCurrency, setFromCurrency] = useState<KeyValueBaseModel>(
      props.row.fromCurrencyId
        ? ({
            value: props.row.fromCurrencyId,
            label: props.row.fromCurrencyName,
          } as KeyValueBaseModel)
        : defaultCurrency
    );

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const apiRef = useGridApiContext();
    const handleValueChange = (newValue: KeyValueBaseModel) => {
      apiRef.current.setEditCellValue({
        id,
        field: 'fromCurrencyId',
        value: newValue.value,
      });
      apiRef.current.setEditCellValue({
        id,
        field,
        value: newValue.label,
        debounceMs: 200,
      });
      setFromCurrency(newValue);
    };

    return (
      <CustomizedSelect
        key={id + '_fromCurrency'}
        placeholder={defaultCurrency.label}
        options={currencyList}
        isSearchable={false}
        handleChange={handleValueChange}
        errorValue={true}
        value={fromCurrency}
        isDisplayLabelClass={true}
      ></CustomizedSelect>
    );
  }

  function ToCurrencyEditComponent(props: GridRenderEditCellParams) {
    const { id, field } = props;
    const [toCurrency, setToCurrency] = useState<KeyValueBaseModel>(
      props.row.toCurrencyId
        ? ({
            value: props.row.toCurrencyId,
            label: props.row.toCurrencyName,
          } as KeyValueBaseModel)
        : defaultValue
    );

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const apiRef = useGridApiContext();
    const handleValueChange = (newValue: KeyValueBaseModel) => {
      apiRef.current.setEditCellValue({
        id,
        field: 'toCurrencyId',
        value: newValue.value,
      });
      apiRef.current.setEditCellValue({
        id,
        field,
        value: newValue.label,
        debounceMs: 200,
      });
      setToCurrency(newValue);
    };

    return (
      <CustomizedSelect
        key={id + '_toCurrency'}
        placeholder={defaultValue.label}
        options={currencyList}
        isSearchable={false}
        handleChange={handleValueChange}
        errorValue={true}
        value={toCurrency}
        isDisplayLabelClass={true}
      ></CustomizedSelect>
    );
  }

  function RateEditComponent(props: GridRenderEditCellParams) {
    const { id, value, field } = props;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const apiRef = useGridApiContext();

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value; // The new value entered by the user
      apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    return (
      <DecimalInput
        inputProps={{ maxLength: 10 }}
        autoFocus={true}
        name="rate"
        handleChange={handleValueChange}
        value={value}
        decimalScaleValue={2}
      ></DecimalInput>
    );
  }

  const inlineColumns: GridColumns<Row> = [
    {
      field: 'fromCurrencyName',
      headerName: I18n('ExchangeRate.FromCurrency'),
      flex: 1,
      sortable: false,
      editable: true,
      renderEditCell: (params) => {
        return params.formattedValue;
        // return rowModesModel[params.id]?.mode === GridRowModes.Edit ? (
        //   <FromCurrencyEditComponent {...params} />
        // ) : (
        //   params.formattedValue
        // );
      },
    },
    {
      field: 'fromCurrencyId',
      hide: true,
      flex: 1,
      editable: true,
      renderCell: (params: any) => {
        return params.formattedValue;
      },
    },
    {
      field: 'toCurrencyId',
      hide: true,
      flex: 1,
      editable: true,
      renderCell: (params: any) => {
        return params.formattedValue;
      },
    },
    {
      field: 'rate',
      headerName: I18n('ExchangeRate.Rate'),
      sortable: true,
      editable: true,
      flex: 1,
      renderEditCell: (params) => {
        return <RateEditComponent {...params} />;
      },
      renderCell: (params) => {
        return params.value.toString().replaceAll('.', ',');
      },
    },
    {
      field: 'toCurrencyName',
      headerName: I18n('ExchangeRate.ToCurrency'),
      flex: 1,
      sortable: false,
      editable: true,
      renderEditCell: (params) => {
        return rowModesModel[params.id]?.mode === GridRowModes.Edit ? (
          <ToCurrencyEditComponent {...params} />
        ) : (
          params.formattedValue
        );
      },
    },
    {
      field: 'actions',
      headerName: I18n('Common.Actions'),
      type: 'actions',
      width: 150,
      getActions: (params: any) => {
        const actionButtons = [];
        const isInEditMode =
          rowModesModel[params.id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          actionButtons.push(
            <GridActionsCellItem
              onPointerEnterCapture={false}
              onPointerLeaveCapture={false}
              placeholder={''}
              icon={<SaveIcon />}
              label={i18nMessages.Save}
              onClick={handleSaveClick(params.id)}
            />
          );
          actionButtons.push(
            <GridActionsCellItem
              onPointerEnterCapture={false}
              onPointerLeaveCapture={false}
              placeholder={''}
              icon={<CancelIcon />}
              label={i18nMessages.Cancel}
              className="textPrimary"
              onClick={handleCancelClick(params.id)}
              color="inherit"
            />
          );
        } else {
          if (hasPermission(Permission.canEditExchangeRate)) {
            actionButtons.push(
              <GridActionsCellItem
                onPointerEnterCapture={false}
                onPointerLeaveCapture={false}
                placeholder={''}
                icon={<EditIcon />}
                label={i18nMessages.Edit}
                className="textPrimary"
                onClick={handleEditClick(params.id)}
                color="inherit"
              />
            );
          }
          if (hasPermission(Permission.canDeleteExchangeRate)) {
            actionButtons.push(
              <GridActionsCellItem
                onPointerEnterCapture={false}
                onPointerLeaveCapture={false}
                placeholder={''}
                icon={<DeleteIcon />}
                label="Delete"
                onClick={handleDeleteClick(params.id)}
                color="inherit"
              />
            );
          }
        }
        return actionButtons;
      },
    },
  ];

  const setModels = (newModel: any) => {
    // to stop set Row Models from  type dropdown Change
    var result = false;
    Object.keys(newModel).forEach(function (key) {
      var value = newModel[key];
      if (
        (value.field === 'fromCurrencyName' ||
          value.field === 'toCurrencyName') &&
        value.cellToFocusAfter === undefined
      ) {
        result = true;
      }
    });
    if (result) {
      return;
    }

    if (newModel[0] === undefined) {
      setRowModesModel(newModel);
    } else if (
      newModel[0] !== undefined &&
      newModel[0].cellToFocusAfter === 'right'
    ) {
      updateGridData(
        'rows',
        gridData.rows.filter(function (row: any) {
          return row.id !== 0;
        })
      );
      setRowModesModel(newModel);
    }
  };

  return (
    <div onKeyDown={keydown}>
      <div className="heading-section">
        <div className="heading-section-left">
          <Typography variant="h3" sx={{ mb: 3 }}>
            {I18n('Nav.ExchangeRates')}
          </Typography>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>

        <div className="heading-section-right">
          <div className="search-control-outer">
            <FormControl
              margin="normal"
              displayLabel=""
              required
              inputType="text"
              placeholderText={I18n('Common.Search')}
              className="search-control small-form-control"
              handleChange={handleChangeBasicSearch}
              adornmentValue={
                <InputAdornment position="start">
                  <FontAwesomeIcon icon={faSearch} />
                </InputAdornment>
              }
            ></FormControl>
          </div>
        </div>
      </div>

      <div className="main-content-section">
        <div className="content-block table-fix">
          <CustomDataGridInline
            rowCount={gridData.totalRows}
            data={gridData.rows}
            columns={inlineColumns}
            onSortModelChange={handleSortModelChange}
            pageNo={gridData.page}
            pageSize={gridData.pageSize}
            rowModesModel={rowModesModel}
            onRowModesModelChange={(newModel: any) => {
              setModels(newModel);
            }}
            onPageChange={handleChangePage}
            onPageSizeChange={handleChangePageSize}
            onRowEditStart={handleRowEditStart}
            rowsPerPageOptions={gridData.rowsPerPageOptions}
            initialState={{
              sorting: {
                sortModel: [{ field: 'updatedDate', sort: 'desc' }],
              } as GridSortingInitialState,
              pagination: {
                page: gridData.page,
                pageSize: gridData.pageSize,
              } as GridPaginationInitialState,
            }}
            components={{
              Toolbar: EditToolbar,
            }}
            componentsProps={{
              toolbar: { setRows, setRowModesModel },
            }}
            processRowUpdate={handleProcessRowUpdate}
            onProcessRowUpdateError={handleProcessRowUpdateError}
          ></CustomDataGridInline>
        </div>

        <ConfirmationPopup
          isOpen={isDeleteModalOpen}
          data={selectedExchangeRateId}
          message={i18nMessages.DeleteConfirmation}
          handleClose={handleCloseForDelete}
          handleOk={handleOkForDelete}
        ></ConfirmationPopup>
      </div>
    </div>
  );
}

export default ExchangeRateList;
