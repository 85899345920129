import { logout, refreshToken } from 'auth/authentication';
import axios from 'axios';
import jwt from 'jwt-decode';

axios.interceptors.request.use(
  async (config) => {
    // spinning start to show
    // UPDATE: Add this code to show global loading indicator
    document.body.classList.add('loading-indicator');
    var token = localStorage.getItem('token');
    if (token) {
      let decodedToken: any = jwt(token);
      if (decodedToken.exp < Date.now() / 1000) {
        var data = await refreshToken();
        if (data.succedded) {
          config.headers.common['authorization'] = 'Bearer ' + data.token;
        } else {
          logout();
        }
      } else {
        const userToken = JSON.parse(token);
        config.headers.common['authorization'] = 'Bearer ' + userToken;
      }
    } else {
      logout();
    }
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (value) {
    // spinning hide
    // UPDATE: Add this code to hide global loading indicator
    document.body.classList.remove('loading-indicator');
    return value;
  },
  (error) => {
    document.body.classList.remove('loading-indicator');
    console.log('response interceptor error');
    console.log(error);

    return Promise.reject(error);
  }
);

const axiosExport = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
};
export default axiosExport;
