import FileUpload from 'common/fileupload/fileupload';
import React, { useCallback, useEffect, useState } from 'react';
import ImageViewer from 'react-simple-image-viewer';
import environment from 'environment';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Grid } from '@mui/material';
import { ImageUploadViewModel } from 'models/pagination-model';

function ProfilePicture(props: any) {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [images, setImages] = useState<ImageUploadViewModel[]>([]);
  const [imageURLs, setImageURLs] = useState<string[]>([]);
  const [dropzoneKey, setDropzoneKey] = useState(0);
  const [previewImages, setPreviewImages] = useState<ImageUploadViewModel[]>(
    []
  );

  useEffect(() => {
    const newImageUrls: string[] = [];
    if (props.images && props.images.length) {
      props.images.forEach((item: ImageUploadViewModel) => {
        newImageUrls.push(
          item && item.image && !item.id
            ? URL.createObjectURL(item.image)
            : environment.api.uploadedFileUrl + item.path
        );
      });
      setImageURLs([...newImageUrls]);
    }
    setPreviewImages([...props.images]);
  }, [props.images]);

  useEffect(() => {
    if (images && images.length) {
      props.fileData(images);
      setImages([]);
      setDropzoneKey(dropzoneKey + 1);
    }
  }, [images]);

  const handleSaveUpload = async (files: File[]) => {
    if (files && files.length > 0 && files.length <= props.limit) {
      setImages(
        files.map((x) => {
          return {
            previewUrl: URL.createObjectURL(x),
            image: x,
          } as ImageUploadViewModel;
        })
      );
    }
  };

  const openImageViewer = useCallback((index: any) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const handleFileDelete = (index: number) => {
    props.fileRemove(index);
  };

  return (
    <div>
      {!props.readOnly && (
        <FileUpload
          filesLimit={props.limit}
          onSave={handleSaveUpload}
          showPreviews={false}
          maxFileSize={2097152}
          acceptedFiles={['image/jpeg', 'image/png', 'image/jpg', 'image/bmp']}
          dropzoneKey={dropzoneKey}
        />
      )}
      <Grid container spacing={2} className="grid-wrap">
        {previewImages.map((src: ImageUploadViewModel, index: number) => (
          <Grid item xs={4} style={{ alignItems: 'center' }}>
            <div className="text-center">
              <Box position="relative" display="inline-block" mr={2}>
                <img
                  src={src.previewUrl}
                  onClick={() => openImageViewer(index)}
                  width="100"
                  height="100"
                  key={index}
                  id={`profile-pic-${index}`}
                  style={{ margin: '2px' }}
                  alt=""
                  className="text-center"
                />
                {!props.readOnly && (
                  <CloseIcon
                    className="ImageClose"
                    onClick={() => handleFileDelete(index)}
                  />
                )}
              </Box>
            </div>
          </Grid>
        ))}
      </Grid>
      {isViewerOpen && (
        <ImageViewer
          src={imageURLs}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: 'rgba(0,0,0,0.9)',
          }}
          closeOnClickOutside={true}
        />
      )}
    </div>
  );
}

export default ProfilePicture;
