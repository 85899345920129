import {
  Box,
  Breadcrumbs,
  Button,
  FormControl,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import FormAccordian from 'common/formControl/formAccordian';
import RadioGroupInput from 'common/RadioGroup/RadioGroupInput';
import Status from 'common/WoStatus/Status';
import environment from 'environment';
import {
  DecPackQuestions,
  DecPackQuestionsDescription,
  DecPackQuestionsDto,
  DnPWorkOrders,
} from 'models/deck-pack-orders';
import {
  BaseModel,
  DGDDescription,
  ActivityOwner,
  RadioGroupBaseModel,
  OrderType,
} from 'models/pagination-model';
import RouteEnum from 'models/RouteEnum';
import React, { useState, useEffect, BaseSyntheticEvent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import I18n from 'utilities/i18n';
import http from '../../../utilities/httpService';
import {
  getWorkOrderQuestions,
  getWorkOrderStatus,
} from '../DeckPackApiService';
import CustomizedInputs from 'common/formControl/formControl';
import { hasPermission } from 'utilities/protectedRoute';
import { Permission } from 'Permissions';
import FormAccordianSave from 'common/formControl/formAccordianSave';
import DecpackStatus from './DecpackStatus';
import CreatedByandUpdatedBy from 'common/CreatedByandUpdatedBy/CreatedByandUpdatedBy';

function CustomerReference() {
  const { woId, id } = useParams();
  let navigate = useNavigate();
  const [isSaveNext, setIsSaveNext] = useState<boolean>(true);
  const [status, setStatus] = useState<any>(1);
  const [isStatusSaveCallApi, setIsStatusSaveCallApi] = useState(false); // To call Submit APi Status from Status Component
  const [validateStatus, setValidateStatus] = useState<boolean>(false); // validateStatus - To validate Status Dropdown from Status Component

  const [canEdit, setCanEdit] = useState(
    hasPermission(Permission.canEditDecPackOrders)
  );
  const [details, setDetails] = useState<any>({
    createdBy: '',
    createdDate: '',
    updatedBy: '',
    updatedDate: '',
  });

  const [assigneeData, setAssigneeData] = useState<any>({
    assignedToId: '',
    assignedToName: '',
    assignNextToId: '',
    assignNextToName: '',
  });
  const [isMobile, setIsMobile] = useState(false);

  const breadcrumbs = [
    <Typography key="3" color="text.primary">
      {I18n('Nav.Order')}
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={() => navigate(RouteEnum.DecPackOrdersList)}
        className="cpointer"
      >
        {I18n('Nav.DecPackOrders')}
      </Link>
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={() => navigate(RouteEnum.ViewDecPackOrders + '/' + id + '/1')}
        className="cpointer"
      >
        {I18n('ShippingOrders.WorkOrders')}
      </Link>
    </Typography>,
  ];

  const i18nMessages = {
    CustomerReference: I18n('DecPack.CustomerReference'),
    title: I18n('DecPackOrders.WorkOrder.CustomerRefTitle'),
    requiredErrMsg: I18n('DecPackOrders.WorkOrder.requiredErrMsg'),
    detailsUpdateMsg: I18n('DecPackOrders.WorkOrder.detailsUpdateMsg'),
    somethingWentWrong: I18n('Common.SomethingWentWrong'),
  };

  const onValueChange = (event: any) => {
    const { name, value } = event.target;
    validate({ [name]: value });
    setValues({
      ...values,
      [name.toString()]: value,
    });
  };

  const CustomerReferenceSection = () => {
    return (
      <>
        <Grid item lg={4} md={4}>
          <CustomizedInputs
            required
            margin="normal"
            displayLabel={I18n('DecPack.CustomerReference')}
            name="customerReference"
            id="customerReference"
            inputType="text"
            handleChange={onValueChange}
            errorValue={errors.customerReference}
            errorMessage={errors.customerReference}
            value={values?.customerReference}
            readOnly={!canEdit}
          />
        </Grid>
      </>
    );
  };

  const [errors, setErrors] = useState({
    customerReference: '',
  });

  const [values, setValues] = useState<any>({
    customerReference: '',
    contactPersonId: null,
    locationId: null,
    deliveryLocationId: null,
  });

  // Method to validate in input fields
  const validate = (fieldValues: any = values) => {
    let errMessages = { ...errors };

    if ('customerReference' in fieldValues) {
      errMessages.customerReference = fieldValues.customerReference
        ? ''
        : i18nMessages.requiredErrMsg;
    }

    setErrors({
      ...errMessages,
    });

    if (fieldValues === values) {
      return Object.values(errMessages).every((x) => x === '');
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setValidateStatus(true);
    setIsSaveNext(false);
  };

  const getWOStatus = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl +
        'DecPackOrder/GetDecPackWO/' +
        id +
        '/' +
        DnPWorkOrders.WO11
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result && result.data) {
        setDetails({
          ...details,
          createdBy: result.data.createdBy,
          updatedBy: result.data.updatedBy,
          createdDate: result.data.createdDate,
          updatedDate: result.data.updatedDate,
        });
        setAssigneeData({
          ...assigneeData,
          assignedToId: result.data.assignedToId,
          assignedToName: result.data.assignedToName,
          assignNextToId: result.data.assignedNextToId,
          assignNextToName: result.data.assignedNextToName,
        });

        setIsMobile(result.data.isMobile);
        setStatus(result.data.status);
      }
    } catch (error) {}
  };

  const getDecPackById = async () => {
    const apiUrl = new URL(environment.api.baseUrl + `DecPackOrder/${id}`);
    try {
      const result = await http.get(apiUrl.toString());
      if (result && result.data) {
        setValues({ ...values, ...result.data });
      }
    } catch (error) {}
  };

  // Validate Status Dropdown Success call from Status Component
  const handleSuccessStatusSave = async () => {
    if (validate()) {
      setIsStatusSaveCallApi(true);
      try {
        // PUT Api
        if (id) {
          const apiUrl = new URL(
            environment.api.baseUrl + `DecPackOrder/${id}`
          );
          const result: any = await http.put(apiUrl.toString(), values);
          if (result) {
            toast.success(i18nMessages.detailsUpdateMsg);
            handleErrorStatusSave(); // To set Validation and Submit APi Status False
          }
        }
      } catch (error: any) {
        toast.success(i18nMessages.somethingWentWrong);
      }
    } else {
      handleErrorStatusSave(); /* To set Validation and Submit APi Status False */
    }
  };

  // Validate Status Dropdown Error call from Status Component
  const handleErrorStatusSave = () => {
    setValidateStatus(false);
    setIsStatusSaveCallApi(false);
  };

  useEffect(() => {
    getWOStatus();
    getDecPackById();
  }, []);

  const refreshData = async () => {
    handleErrorStatusSave();
    getWOStatus();
  };

  return (
    <>
      <div className="heading-section">
        <div className="heading-section-left">
          <Typography variant="h3" sx={{ mb: 3 }}>
            {i18nMessages.title}
          </Typography>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>
      </div>
      <div className="main-content-section">
        <FormAccordianSave
          title={i18nMessages.CustomerReference}
          details={CustomerReferenceSection()}
          showActionButton={!canEdit}
          className="inner-heading"
        ></FormAccordianSave>
        <Grid item lg={12} md={12}>
          <DecpackStatus
            permission={Permission.canEditDecPackOrders}
            woId={DnPWorkOrders.WO11}
            activityOwner={ActivityOwner.BackOffice}
            orderId={id}
            statusValue={status}
            orderType={OrderType.DecPackOrder}
            isStatusSaveCallApi={isStatusSaveCallApi}
            validateStatus={validateStatus}
            handleSuccessStatusSave={handleSuccessStatusSave}
            handleErrorStatusSave={handleErrorStatusSave}
            isSaveNext={isSaveNext}
            isMobile={isMobile}
            showAssigned={false}
            workOrderNo={DnPWorkOrders.WO11}
            assigneeData={assigneeData}
            handleStatusChange={(value: number) => setStatus(value.toString())}
          />
        </Grid>
        <div className="btn-wrap form-btn-wrap">
          {canEdit ? (
            <Button
              type="submit"
              color="primary"
              disableElevation
              variant="contained"
              onClick={handleSubmit}
            >
              {I18n('Common.Save')}
            </Button>
          ) : (
            <></>
          )}
        </div>
        <CreatedByandUpdatedBy
          createdBy={details.createdBy}
          createdDate={details.createdDate}
          updatedBy={details.updatedBy}
          updatedDate={details.updatedDate}
        ></CreatedByandUpdatedBy>
      </div>
    </>
  );
}

export default CustomerReference;
