import { useState, useEffect, useCallback } from 'react';
import http from '../../../utilities/httpService';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import CustomizedInputs from 'common/formControl/formControl';
import CustomizedPhone from 'common/formControl/formPhone';
import Grid from '@mui/material/Grid';
import FormControl from 'common/formControl/formControl';
import environment from 'environment';
import I18n from 'utilities/i18n';
import {
  BaseModel,
  CustomerTags,
  CustomerTagsDescription,
  EntityStatus,
  EntityStatusDescription,
  OrderByDirection,
  PaginationResponseModel,
  PartnerType,
  PartnerTypeDescription,
} from 'models/pagination-model';

import FormAccordian from 'common/formControl/formAccordian';
import CustomizedSelect from 'common/CustomSelect/CustomSelect';
import FileUpload from 'common/fileupload/fileupload';
import { Checkbox, FormControlLabel } from '@mui/material';
import DefualtLogo from '../../../assets/images/logo.png';
import AddressAutocompleteInputs from 'common/AddressAutocomplete/AddressAutocompleteInput';
import AddCity from 'common/Cities/AddCity';
import CustomSelectPaginateAdd from 'common/CustomSelect/CustomSelectPaginateAdd';
import {
  PostCityDropDown,
  PostCountryDropDown,
  GetTransportTypeOption,
} from 'common/DropDownAddAPI/DropDownApiService';
import { Customer } from 'models/service-catalogue';
import { hasPermission } from 'utilities/protectedRoute';
import { Permission } from 'Permissions';
import { ZipCodeTriggeredFrom } from 'models/location';

function EditPartnerDetails(props: any) {
  const params = useParams();
  const defaultTransportTypeValue = {
    value: '0',
    label: I18n('Placeholders.SelectPartnerType'),
  } as BaseModel;

  const defaultDropDownValue = {
    value: '0',
    label: I18n('Placeholders.Select'),
  } as BaseModel;

  const defaultPartnerTypeValue = {
    value: '0',
    label: I18n('Placeholders.SelectTracking'),
  } as BaseModel;

  const defaultValue = {
    value: '0',
    label: I18n('Placeholders.SelectStatus'),
  } as BaseModel;

  const [transportTypeList, setTransportTypeList] = useState<BaseModel[]>([]);
  const [errors, setErrors] = useState({
    name: '',
    address: '',
    postalCode: '',
    city: '',
    country: '',
    webSite: '',
    logo: '',
    vatNumber: '',
    eori: '',
    telephone: '',
    email: '',
    invoiceEmail: '',
    invoiceAddress: '',
    invoiceStreet: '',
    invoicePostalCode: '',
    invoiceCountry: '',
    invoiceCity: '',
    status: '',
    taggedTo: '',
    fileUpload: '',
    adminEmail: '',
    address2: '',
    street: '',
    partnerType: '',
    transportType: '',
  });
  const [values, setValues] = useState<any>({
    type: defaultPartnerTypeValue,
    partnerType: defaultPartnerTypeValue.value,
    name: '',
    address: '',
    address2: '',
    street: '',
    postalCode: '',
    cityId: '',
    countryId: '',
    city: '',
    country: '',
    webSite: '',
    logo: '',
    vatNumber: '',
    eori: '',
    isDebtor: false,
    sameVat: false,
    telephone: '',
    email: '',
    invoiceEmail: '',
    isInvoiceAddress: false,
    invoiceAddress: '',
    invoiceAddress2: '',
    invoiceStreet: '',
    invoicePostalCode: '',
    invoiceCountry: '',
    invoiceCity: '',
    invoiceCityId: '',
    invoiceCountryId: '',
    status: Number(EntityStatus.Active),
    statusDescription: '',
    taggedTo: Number(CustomerTags.Shipping),
    taggedToDescription: '',
    adminEmail: '',
    transportType: defaultTransportTypeValue,
    transportTypeId: defaultTransportTypeValue.value,
  });
  const i18nTexts = {
    CustomerPostalCode: I18n('Partner.PostalCode'),
    CustomerCountry: I18n('Partner.Country'),
    CustomerCity: I18n('Partner.City'),
    CustomerUpdatedSuccessFully: I18n('Partner.UpdatedSuccessfully'),
    CustomerTag: I18n('Partner.Tag'),
    dhlValue: I18n('Partner.DHL'),
    fedExValue: I18n('Partner.FedEx'),
    othersValue: I18n('Partner.Others'),
    Address2: I18n('CustomerLocation.Address2'),
    Street: I18n('CustomerLocation.Street'),
  };
  const [cityList, setCityList] = useState<BaseModel[]>([defaultDropDownValue]);
  const [countryList, setCountryList] = useState<BaseModel[]>([
    defaultDropDownValue,
  ]);
  const [selectedCountry, setSelectedCountry] = useState(defaultDropDownValue);
  const [selectedCity, setSelectedCity] = useState(defaultDropDownValue);
  const [selectedInvoiceCountry, setSelectedInvoiceCountry] =
    useState(defaultDropDownValue);
  const [selectedInvoiceCity, setSelectedInvoiceCity] =
    useState(defaultDropDownValue);
  const [selectedTag, setSelectedTag] = useState(defaultDropDownValue);
  const [selectedStatus, setSelectedStatus] = useState(defaultValue);

  const [logoExpanded, setlogoExpanded] = useState<boolean>(true);
  const [isLogoEdit, setLogoEdit] = useState<boolean>(false);
  const [detailsExpanded, setDetailsExpanded] = useState<boolean>(true);
  const [isDetailsEdit, setDetailsEdit] = useState<boolean>(false);

  const [financeEdit, setFinanceEdit] = useState<boolean>(false);
  const [financeExpanded, setFinanceExpanded] = useState<boolean>(true);

  const [contactDetailsEdit, setContactDetailsEdit] = useState<boolean>(false);
  const [contactDetailsExpanded, setContactDetailsExpanded] =
    useState<boolean>(true);

  const [statusEdit, setStatusEdit] = useState<boolean>(false);
  const [statusExpanded, setStatusExpanded] = useState<boolean>(true);

  const [invoiceEdit, setInvoiceEdit] = useState<boolean>(false);
  const [invoiceExpanded, setInvoiceExpanded] = useState<boolean>(true);

  const [tagEdit, setTagEdit] = useState<boolean>(false);
  const [tagExpanded, setTagExpanded] = useState<boolean>(true);
  const [isInvoiceAddress, setIsInvoiceAddress] = useState<boolean>(false);
  const [files, setFiles] = useState<any>([]);
  const [selectedPartnerType, setPartnerType] = useState(PartnerType.Others);
  const [canEdit, setCanEdit] = useState(
    hasPermission(Permission.canEditPartner)
  );

  const defaultAdditional: any = {
    page: 1,
  };
  const [cacheUniqCountry, setCacheUniqCountry] = useState(0);
  const [cacheUniq, setCacheUniq] = useState(0);
  let customerTagsList: Array<BaseModel> = [];
  const getCustomerTagsList = () => {
    customerTagsList = [defaultDropDownValue];
    CustomerTagsDescription.forEach((value: string, key: number) => {
      customerTagsList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getCustomerTagsList();

  let partnerTypeList: Array<BaseModel> = [];
  const getPartnerTypeList = () => {
    PartnerTypeDescription.forEach((value: string, key: number) => {
      partnerTypeList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };

  const getCountries = async (search: string = '', pageNo: number = 1) => {
    let response: PaginationResponseModel<Customer>;
    const apiUrl = new URL(environment.api.baseUrl + 'Country');
    try {
      let searchExpression = '';
      searchExpression += search ? `name~like~${search}` : '';
      apiUrl.searchParams.set('$filter', searchExpression);
      apiUrl.searchParams.set('$orderby', 'name');
      apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
      apiUrl.searchParams.set('$top', '1000');
      apiUrl.searchParams.set('$orderbydirection', 'desc');
      const result = await http.get(apiUrl.toString());
      if (result) {
        response = {
          TotalRecords: result.data.totalCount,
          Values: result.data.values,
        } as PaginationResponseModel<Customer>;

        let options = result.data.values.map((x: any) => {
          return {
            value: x.id.toString(),
            label: x.name,
          } as BaseModel;
        });
        setCountryList(options);
      } else {
        response = {
          TotalRecords: 0,
          Values: [],
        } as PaginationResponseModel<Customer>;
      }
    } catch (error) {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<Customer>;
    }
    return response;
  };

  let cityLst: Array<BaseModel> = [];
  const getCityList = (cities: any[]) => {
    cityLst = [defaultDropDownValue];
    cities.forEach((city: any) => {
      cityLst.push({
        value: city.key,
        label: city.value,
      } as BaseModel);
    });

    return cityLst;
  };

  const getCities = async (search: string = '', pageNo: number = 1) => {
    let cityLst: any[] = [];
    let response: PaginationResponseModel<BaseModel>;
    const apiUrl = new URL(environment.api.baseUrl + 'Cities');
    try {
      if (search && search !== undefined) {
        var searchExpression = '';
        searchExpression += search ? `name~like~${search}` : '';
        apiUrl.searchParams.set('$filter', searchExpression);
      }
      apiUrl.searchParams.set('$orderby', 'name');
      if (pageNo > 1) {
        apiUrl.searchParams.set('$skip', ((pageNo - 1) * 500).toString());
        apiUrl.searchParams.set('$top', '500');
      } else {
        apiUrl.searchParams.set('$top', '10000');
        apiUrl.searchParams.set('$skip', '0');
      }

      apiUrl.searchParams.set(
        '$orderbydirection',
        OrderByDirection.Ascending.toString()
      );

      const result = await http.get(apiUrl.toString());
      if (result) {
        result.data.values.map((item: any) => {
          cityLst.push({
            key: item['id'],
            value: item['name'],
          });
        });

        let data = getCityList(cityLst);
        setCityList(data);

        response = {
          TotalRecords: result.data.totalCount,
          Values: data,
        } as PaginationResponseModel<BaseModel>;
      } else {
        response = {
          TotalRecords: 0,
          Values: [],
        } as PaginationResponseModel<BaseModel>;
      }
    } catch (error) {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<BaseModel>;
    }
    return response;
  };

  let entityStatusList: Array<BaseModel> = [];
  const getStatusList = () => {
    EntityStatusDescription.forEach((value: string, key: number) => {
      entityStatusList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getStatusList();

  const getTransportTypeDropDown = async () => {
    const response = await GetTransportTypeOption();
    var options = response.Values.map((x: any) => {
      return {
        value: x.id.toString(),
        label: x.transportType,
      } as BaseModel;
    });
    setTransportTypeList(options);
  };

  const resetAccordians = () => {
    setLogoEdit(false);
    setDetailsEdit(false);
    setFinanceEdit(false);
    setContactDetailsEdit(false);
    setStatusEdit(false);
    setInvoiceEdit(false);
    setTagEdit(false);
  };

  const handleFileUpload = async () => {
    var filePath = await UploadFile(files);
    if (filePath) {
      handleSubmit(filePath);
    } else {
      if (values.logo !== '' || filePath === undefined) {
        setLogoEdit(false);
      } else {
        toast.error(i18nMessages.commonError);
      }
    }
  };

  const UploadFile = async (file: any) => {
    if (file && file.length > 0) {
      const apiUrl = new URL(environment.api.baseUrl + 'Documents/3/upload');
      const data = new FormData();
      data.append('file', file[0]);
      const response = await http.post(apiUrl.toString(), data);

      return response.data;
    }
  };

  const handleSubmit = async (uploadedFile?: string) => {
    if (validate()) {
      const apiUrl = new URL(
        environment.api.baseUrl + 'Partners/' + params.entityId
      );
      try {
        values.isDebtor = Boolean(values.isDebtor);
        values.status = Number(values.status);
        values.taggedTo = Number(values.taggedTo);
        if (typeof uploadedFile === 'string') {
          values.logo = uploadedFile;
        }
        if (values.countryId === '') {
          values.countryId = null;
        }
        if (values.cityId === '') {
          values.cityId = null;
        }
        if (values.invoiceCountryId === '') {
          values.invoiceCountryId = null;
        }
        if (values.invoiceCityId === '') {
          values.invoiceCityId = null;
        }
        if (values.taggedTo === 0) {
          values.taggedTo = null;
        }
        if (values.sameVat === true) {
          values.eori = values.vatNumber;
        }
        const result: any = await http.put(apiUrl.toString(), values);
        if (result && result.data) {
          toast.success(i18nTexts.CustomerUpdatedSuccessFully);
          resetAccordians();
          getPartnerData(params.entityId);
        }
      } catch (error: any) {
        if (error.response) {
          let temp = { ...errors };
          if (error.response.data) {
            for (const item of error.response.data.errors) {
              if (
                item.field === 'Name' &&
                item.errorMessage === 'UniqueSupplierName'
              ) {
                temp.name = i18nMessages.UniqueName;
              }
            }
          }
          setErrors({
            ...temp,
          });
        }
      }
    }
  };

  const onSameAsVaTChange = (event: any) => {
    onValueChange(event);
    if (values.vatNumber && event.target.checked === true) {
      setValues({
        ...values,
        eori: values.vatNumber,
        sameVat: true,
      });
    } else {
      setValues({
        ...values,
        eori: '',
        sameVat: false,
      });
    }
  };

  const onIsDebtorChange = (event: any) => {
    setValues({
      ...values,
      isDebtor: event.target.checked,
    });
  };

  const onValueChange = (event: any) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name.toString()]: value,
    });
  };

  const onInvoiceAddressChange = (event: any) => {
    if (!event.target.checked) {
      setValues({
        ...values,
        invoiceCity: values.city,
        invoiceCityId: values.cityId,
        invoiceCountry: values.country,
        invoiceCountryId: values.countryId,
        invoiceAddress: values.address,
        invoicePostalCode: values.postalCode,
        invoiceAddress2: values.invoiceAddress2,
        invoiceStreet: values.invoiceStreet,
        isInvoiceAddress: event.target.checked,
      });

      setSelectedInvoiceCity({
        value: values.cityId,
        label: values.city,
      });
      setSelectedInvoiceCountry({
        value: values.countryId,
        label: values.country,
      });
      setIsInvoiceAddress(false);
    } else {
      setValues({
        ...values,
        invoiceCity: '',
        invoiceCityId: '',
        invoiceCountry: '',
        invoiceCountryId: '',
        invoiceAddress: '',
        invoiceAddress2: '',
        invoiceStreet: '',
        invoicePostalCode: '',
        isInvoiceAddress: event.target.checked,
      });

      setSelectedInvoiceCity(defaultDropDownValue);
      setSelectedInvoiceCountry(defaultDropDownValue);
      setIsInvoiceAddress(true);
    }
  };

  const phoneOnChange = (phone: any) => {
    const phoneNum: string = phone.replace(/[^\d\+]/g, '');
    setValues({ ...values, telephone: phoneNum });
  };

  const onStatusChange = (data: any) => {
    setValues({ ...values, statusDescription: data?.label });
    setValues({ ...values, status: data?.value });
    setSelectedStatus({ value: data?.value, label: data?.label });
  };

  const onCityChange = (data: any) => {
    setValues({
      ...values,
      city: data?.label === defaultDropDownValue.label ? null : data?.label,
    });
    setValues({
      ...values,
      cityId: data?.value === defaultDropDownValue.value ? null : data?.value,
    });
    setSelectedCity({ value: data?.value, label: data?.label });
  };

  const onCountryChange = (data: any) => {
    setValues({
      ...values,
      country: data?.label === defaultDropDownValue.label ? null : data?.label,
    });
    setValues({
      ...values,
      countryId:
        data?.value === defaultDropDownValue.value ? null : data?.value,
    });
    setSelectedCountry({ value: data?.value, label: data?.label });
  };

  const onInvoiceCityChange = (data: any) => {
    setValues({
      ...values,
      invoiceCity:
        data?.label === defaultDropDownValue.label ? null : data?.label,
    });
    setValues({
      ...values,
      invoiceCityId:
        data?.value === defaultDropDownValue.value ? null : data?.value,
    });
    setSelectedInvoiceCity({ value: data?.value, label: data?.label });
  };

  const onInvoiceCountryChange = (data: any) => {
    setValues({
      ...values,
      invoiceCountry:
        data?.label === defaultDropDownValue.label ? null : data?.label,
    });
    setValues({
      ...values,
      invoiceCountryId:
        data?.value === defaultDropDownValue.value ? null : data?.value,
    });
    setSelectedInvoiceCountry({ value: data?.value, label: data?.label });
  };

  const onTagChange = (data: any) => {
    setValues({ ...values, taggedToDescription: data?.label });
    setValues({ ...values, taggedTo: data?.value });
    setSelectedTag({ value: data?.value, label: data?.label });
  };

  const validate = () => {
    let temp: any = {};
    let isValid = true;
    if (!values.name) {
      temp.name = i18nMessages.partnerNameRequired;
      isValid = false;
    } else if (values.name.length > 50) {
      temp.name = i18nMessages.partnerNameMaxLength;
      isValid = false;
    } else if (values.name.length < 3) {
      temp.name = i18nMessages.partnerNameMinLength;
      isValid = false;
    }

    if (values.address && values.address.length > 250) {
      temp.address = i18nMessages.addressMaxLength;
      isValid = false;
    }

    if (values.invoiceAddress && values.invoiceAddress.length > 250) {
      temp.invoiceAddress = i18nMessages.invoiceAddressMaxLength;
      isValid = false;
    }

    if (values.invoicePostalCode && values.invoicePostalCode.length > 20) {
      temp.invoicePostalCode = i18nMessages.invoicePostalCodeMaxLength;
      isValid = false;
    }

    if (values.website && values.website.length > 255) {
      temp.website = i18nMessages.websiteMaxLenght;
      isValid = false;
    }

    if (!values.vatNumber) {
      temp.vatNumber = i18nMessages.commonFieldIsRequired;
      isValid = false;
    } else if (values.vatNumber.length > 30) {
      temp.vatNumber = i18nMessages.vatNumberMaxLength;
      isValid = false;
    }

    if (values.eori && values.eori.length > 30) {
      temp.eori = i18nMessages.eORIMaxLength;
      isValid = false;
    }

    if (values.telephone && values.telephone.length > 20) {
      temp.telephone = i18nMessages.telephoneMaxLength;
      isValid = false;
    }

    if (selectedStatus.value === defaultValue.value) {
      temp.status = i18nMessages.statusRequired;
      isValid = false;
    }

    if (values.email && values.email.length > 255) {
      temp.email = i18nMessages.emailMaxLength;
      isValid = false;
    } else if (values.email && !/$^|.+@.+..+/.test(values.email)) {
      temp.email = i18nMessages.invalidEmail;
      isValid = false;
    }

    if (values.adminEmail && values.adminEmail.length > 255) {
      temp.adminEmail = i18nMessages.emailMaxLength;
      isValid = false;
    } else if (values.adminEmail && !/$^|.+@.+..+/.test(values.adminEmail)) {
      temp.adminEmail = i18nMessages.invalidEmail;
      isValid = false;
    }

    if (values.invoiceEmail && values.invoiceEmail.length > 255) {
      temp.invoiceEmail = i18nMessages.invoiceEmailMaxLength;
      isValid = false;
    } else if (
      values.invoiceEmail &&
      !/$^|.+@.+..+/.test(values.invoiceEmail)
    ) {
      temp.invoiceEmail = i18nMessages.invalidEmail;
      isValid = false;
    }

    if (!values.street) {
      temp.street = i18nMessages.commonFieldIsRequired;
      isValid = false;
    }

    if (!values.countryId || values.countryId == defaultDropDownValue.value) {
      temp.country = i18nMessages.commonFieldIsRequired;
      isValid = false;
    }

    if (!values.cityId || values.cityId == defaultDropDownValue.value) {
      temp.city = i18nMessages.commonFieldIsRequired;
      isValid = false;
    }

    if (!values.postalCode.trim() || values.postalCode.length > 20) {
      temp.postalCode =
        values.postalCode && values.postalCode.trim()
          ? values.postalCode.length > 20
            ? i18nMessages.postalCodeMaxLength
            : ''
          : i18nMessages.commonFieldIsRequired;
      isValid = false;
    }

    if (isInvoiceAddress) {
      if (!values.invoiceStreet) {
        temp.invoiceStreet = i18nMessages.commonFieldIsRequired;
        isValid = false;
      }

      if (
        !values.invoiceCityId ||
        values.invoiceCityId == defaultDropDownValue.value
      ) {
        temp.invoiceCity = i18nMessages.commonFieldIsRequired;
        isValid = false;
      }

      if (
        !values.invoiceCountryId ||
        values.invoiceCountryId == defaultDropDownValue.value
      ) {
        temp.invoiceCountry = i18nMessages.commonFieldIsRequired;
        isValid = false;
      }

      if (
        !values.invoicePostalCode.trim() ||
        values.invoicePostalCode.length > 20
      ) {
        temp.invoicePostalCode =
          values.invoicePostalCode && values.invoicePostalCode.trim()
            ? values.invoicePostalCode.length > 20
              ? i18nMessages.invoicePostalCodeMaxLength
              : ''
            : i18nMessages.commonFieldIsRequired;
        isValid = false;
      }
      if (
        !values.transportTypeId ||
        values.transportTypeId == defaultTransportTypeValue.value
      ) {
        temp.transportType = i18nMessages.commonFieldIsRequired;
        isValid = false;
      }
      if (
        !values.partnerType ||
        values.partnerType == defaultPartnerTypeValue.value
      ) {
        temp.partnerType = i18nMessages.commonFieldIsRequired;
        isValid = false;
      }
    }

    setErrors({
      ...temp,
    });

    return isValid;
  };

  const getPartnerData = async (id: string | undefined) => {
    const apiUrl = new URL(environment.api.baseUrl + 'Partners/' + id);
    try {
      const result: any = await http.get(apiUrl.toString());

      if (result) {
        if (result.data.eori !== null && result.data.eori !== '') {
          values.sameVat = true;
        } else {
          values.sameVat = false;
        }
        setValues({
          ...values,
          ...result.data,
          transportType:
            result.data.transportTypeId && result.data.transportTypeId > 0
              ? {
                  value: result.data.transportTypeId,
                  label: result.data.transportTypeName,
                }
              : defaultTransportTypeValue,
          type:
            result.data.partnerType && result.data.partnerType > 0
              ? {
                  value: result.data.partnerType,
                  label: getPartnerTypeValue(result.data.partnerType),
                }
              : defaultTransportTypeValue,
        });
        if (result.data.cityId) {
          setSelectedCity({
            value: result.data.cityId,
            label: result.data.city,
          });
        } else {
          setSelectedCity(defaultDropDownValue);
        }

        if (result.data.countryId) {
          setSelectedCountry({
            value: result.data.countryId,
            label: result.data.country,
          });
        } else {
          setSelectedCountry(defaultDropDownValue);
        }
        setSelectedStatus({
          value: result.data.status,
          label: result.data.statusDescription,
        });

        if (result.data.taggedTo) {
          setSelectedTag({
            value: result.data.taggedTo,
            label: result.data.taggedToDescription,
          });
        } else {
          setSelectedTag(defaultDropDownValue);
        }

        setIsInvoiceAddress(result.data.isInvoiceAddress);

        if (result.data.invoiceCityId) {
          setSelectedInvoiceCity({
            value: result.data.invoiceCityId,
            label: result.data.invoiceCity,
          });
        } else {
          setSelectedInvoiceCity(defaultDropDownValue);
        }

        if (result.data.invoiceCountryId) {
          setSelectedInvoiceCountry({
            value: result.data.invoiceCountryId,
            label: result.data.invoiceCountry,
          });
        } else {
          setSelectedInvoiceCountry(defaultDropDownValue);
        }
      }
    } catch (error: any) {}
  };

  const handleSaveUpload = (files: any) => {
    if (files && files.length > 0) {
      setFiles(files);
    }
  };

  const handleFileDelete = (file: any) => {
    var a = files.filter((x: any) => {
      return x.lastModified !== file.lastModified;
    });

    setFiles(a);
  };

  const handelLogoEdit = () => {
    setLogoEdit(!isLogoEdit);
  };
  const handleLogoExpandCollapseClick = () => {
    setlogoExpanded(!logoExpanded);
  };

  const handelDetailsEdit = () => {
    setDetailsEdit(!isDetailsEdit);
  };
  const handleDetailsExpandCollapseClick = () => {
    setDetailsExpanded(!detailsExpanded);
  };

  const handelFinanceEdit = () => {
    setFinanceEdit(!financeEdit);
  };
  const handelFinanceExpandCollapseClick = () => {
    setFinanceExpanded(!financeExpanded);
  };

  const handelContactDetailsEdit = () => {
    setContactDetailsEdit(!contactDetailsEdit);
  };
  const handelContactDetailsCollapseClick = () => {
    setContactDetailsExpanded(!contactDetailsExpanded);
  };

  const handelStatusEdit = () => {
    setStatusEdit(!statusEdit);
  };
  const handelStatusCollapseClick = () => {
    setStatusExpanded(!statusExpanded);
  };

  const handelInvoiceEdit = () => {
    setInvoiceEdit(!invoiceEdit);
  };
  const handelInvoiceCollapseClick = () => {
    setInvoiceExpanded(!invoiceExpanded);
  };

  const handelTagEdit = () => {
    setTagEdit(!tagEdit);
  };
  const handelTagCollapseClick = () => {
    setTagExpanded(!tagExpanded);
  };
  const replaceImage = (error: any) => {
    error.target.src = DefualtLogo;
  };

  //load countries
  const [isAddCityModalOpen, setIsAddCityModal] = useState(false);
  const [locationData, setLocationData] = useState({
    country: defaultDropDownValue,
    postcode: '',
    place: defaultDropDownValue,
    invoicePlace: defaultDropDownValue,
  });

  //Autocomplete
  const autocompleteHandleChange = async (
    value: string,
    selectedPlace: any
  ) => {
    const autoSelectValues = {
      country: {
        value: values.countryId,
        label: values.country,
      } as BaseModel,
      postcode: values.postalCode,
      place: {
        value: values.cityId,
        label: values.city,
      } as BaseModel,
    };
    //Country
    if (selectedPlace.country && selectedPlace.country !== undefined) {
      let selectedCountry = countryList.find(
        (option: any) => option.label === selectedPlace.country
      );
      autoSelectValues.country = selectedCountry
        ? selectedCountry
        : defaultDropDownValue;

      setLocationData({
        ...locationData,
        country: autoSelectValues.country,
      });

      setSelectedCountry({
        value: autoSelectValues.country?.value,
        label: autoSelectValues.country?.label,
      });
    }
    //City or Place
    if (selectedPlace.place && selectedPlace.place !== undefined) {
      let selectedCity = cityList.find(
        (option: any) => option.label === selectedPlace.place
      );

      //Add new city in db
      if (!selectedCity || selectedCity === undefined) {
        selectedCity = await addNewCity(selectedPlace.place);
      }

      autoSelectValues.place = selectedCity
        ? selectedCity
        : defaultDropDownValue;

      setLocationData({
        ...locationData,
        place: selectedCity
          ? selectedCity
          : ({ label: selectedPlace.place, value: '0' } as BaseModel),
      });

      setSelectedCity({
        value: autoSelectValues.place?.value,
        label: autoSelectValues.place?.label,
      });
    }
    //Postal code
    if (selectedPlace.postcode && selectedPlace.postcode !== undefined) {
      autoSelectValues.postcode = selectedPlace.postcode;

      setLocationData({
        ...locationData,
        postcode: autoSelectValues.postcode,
      });
    }

    setValues({
      ...values,
      address: value,
      street: selectedPlace.number ? selectedPlace.number : values.street,
      postalCode: autoSelectValues.postcode,
      country:
        autoSelectValues.country?.label === defaultDropDownValue.label
          ? null
          : autoSelectValues.country?.label,
      countryId:
        autoSelectValues.country?.value === defaultDropDownValue.value
          ? null
          : autoSelectValues.country?.value,
      city:
        autoSelectValues.place?.label === defaultDropDownValue.label
          ? null
          : autoSelectValues.place?.label,
      cityId:
        autoSelectValues.place?.value === defaultDropDownValue.value
          ? null
          : autoSelectValues.place?.value,
    });
  };

  //Add City
  useEffect(() => {
    if (!isAddCityModalOpen) {
      setCityByLocationData();
    }
  }, [isAddCityModalOpen]);

  const onAddCity = useCallback(() => {
    setIsAddCityModal(true);
  }, []);

  const onCloseAddCity = () => {
    setIsAddCityModal(false);
  };

  const onSubmitCity = async (cityModel: any) => {
    await getCities();
    onCloseAddCity();
  };

  //Autocomplete Invoice
  const autocompleteHandleChangeInvoice = async (
    value: string,
    selectedPlace: any
  ) => {
    const autoSelectValues = {
      invoiceCountry: {
        value: values.invoiceCountryId,
        label: values.invoiceCountry,
      } as BaseModel,
      invoicePostcode: values?.invoicePostalCode,
      invoicePlace: {
        value: values.invoiceCityId,
        label: values.invoiceCity,
      } as BaseModel,
    };
    //Country
    if (selectedPlace.country && selectedPlace.country !== undefined) {
      let selectedCountry = countryList.find(
        (option: any) => option.label === selectedPlace.country
      );
      autoSelectValues.invoiceCountry = selectedCountry
        ? selectedCountry
        : defaultDropDownValue;

      setLocationData({
        ...locationData,
        country: autoSelectValues.invoiceCountry,
      });

      setSelectedInvoiceCountry({
        value: autoSelectValues.invoiceCountry?.value,
        label: autoSelectValues.invoiceCountry?.label,
      });
    }
    //City or Place
    if (selectedPlace.place && selectedPlace.place !== undefined) {
      let selectedCity = cityList.find(
        (option: any) => option.label === selectedPlace.place
      );

      //Add new city in db
      if (!selectedCity || selectedCity === undefined) {
        selectedCity = await addNewCity(selectedPlace.place);
      }

      autoSelectValues.invoicePlace = selectedCity
        ? selectedCity
        : defaultDropDownValue;

      setLocationData({
        ...locationData,
        invoicePlace: selectedCity
          ? selectedCity
          : ({ label: selectedPlace.place, value: '0' } as BaseModel),
        place: selectedCity
          ? selectedCity
          : ({ label: selectedPlace.place, value: '0' } as BaseModel),
      });
      setSelectedInvoiceCity({
        value: autoSelectValues.invoicePlace?.value,
        label: autoSelectValues.invoicePlace?.label,
      });
    }
    //Postal code
    if (selectedPlace.postcode && selectedPlace.postcode !== undefined) {
      autoSelectValues.invoicePostcode = selectedPlace.postcode;

      setLocationData({
        ...locationData,
        postcode: autoSelectValues.invoicePostcode,
      });
    }

    setValues({
      ...values,
      invoiceAddress: value,
      invoiceStreet: selectedPlace.number
        ? selectedPlace.number
        : values.street,
      invoicePostalCode: autoSelectValues.invoicePostcode,
      invoiceCountry:
        autoSelectValues.invoiceCountry?.label === defaultDropDownValue.label
          ? null
          : autoSelectValues.invoiceCountry?.label,
      invoiceCountryId:
        autoSelectValues.invoiceCountry?.value === defaultDropDownValue.value
          ? null
          : autoSelectValues.invoiceCountry?.value,
      invoiceCity:
        autoSelectValues.invoicePlace?.label === defaultDropDownValue.label
          ? null
          : autoSelectValues.invoicePlace?.label,
      invoiceCityId:
        autoSelectValues.invoicePlace?.value === defaultDropDownValue.value
          ? null
          : autoSelectValues.invoicePlace?.value,
    });
  };

  const setCityByLocationData = () => {
    if (locationData.place && locationData.place !== undefined) {
      let selectedCity = cityList.find(
        (option: any) => option.label === locationData.place.label
      );
      if (selectedCity && selectedCity !== undefined) {
        setValues({
          ...values,
          city:
            selectedCity?.label === defaultDropDownValue.label
              ? null
              : selectedCity?.label,
          cityId:
            selectedCity?.value === defaultDropDownValue.value
              ? null
              : selectedCity?.value,
        });
        setSelectedCity({
          value: selectedCity?.value,
          label: selectedCity?.label,
        });
      }
    }
    if (locationData.invoicePlace && locationData.invoicePlace !== undefined) {
      let selectedCity = cityList.find(
        (option: any) => option.label === locationData.invoicePlace.label
      );
      if (
        selectedCity &&
        selectedCity !== undefined &&
        selectedCity.label !== i18nMessages.Select
      ) {
        setValues({
          ...values,
          invoiceCity:
            selectedCity?.label === defaultDropDownValue.label
              ? null
              : selectedCity?.label,
          invoiceCityId:
            selectedCity?.value === defaultDropDownValue.value
              ? null
              : selectedCity?.value,
        });
        setSelectedInvoiceCity({
          value: selectedCity?.value,
          label: selectedCity?.label,
        });
      }
    }
  };

  const addNewCity = async (value: string) => {
    const name = {
      code: value,
      name: value,
      otherComments: value,
      statusId: 1,
    };
    const newOption: any = await PostCityDropDown(
      name,
      i18nMessages.CityCommonSaveSuccessMsg,
      i18nMessages.CommonErrOccuredMsg
    );

    return newOption;
  };

  const onBlurZipCode = async (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    triggerFrom: ZipCodeTriggeredFrom
  ) => {
    const apiUrl = new URL(
      environment.api.baseUrl +
        'Locations/GetLocationByZipCode/' +
        String(event.target.value)
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result) {
        if (result.data) {
          const city: BaseModel = {
            label: String(result.data.city),
            value: String(result.data.cityId),
          };
          const country: BaseModel = {
            label: String(result.data.country),
            value: String(result.data.countryId),
          };
          if (triggerFrom === ZipCodeTriggeredFrom.PartnerDetails) {
            setValues({
              ...values,
              city: city.label,
              cityId: city.value,
              country: country.label,
              countryId: country.value,
            });
            setSelectedCity(city);
            setSelectedCountry(country);
          } else if (triggerFrom === ZipCodeTriggeredFrom.PartnerInvoice) {
            setValues({
              ...values,
              invoiceCity: city.label,
              invoiceCityId: city.value,
              invoiceCountry: country.label,
              invoiceCountryId: country.value,
            });
            setSelectedInvoiceCity(city);
            setSelectedInvoiceCountry(country);
          }
        } else {
        }
      } else {
        toast.error(I18n('Common.ErrorOccurred'));
      }
    } catch (error: any) {
      if (triggerFrom === ZipCodeTriggeredFrom.PartnerDetails) {
        setValues({
          ...values,
          city: '',
          cityId: '',
          country: '',
          countryId: '',
        });
        setSelectedCity(defaultDropDownValue);
        setSelectedCountry(defaultDropDownValue);
      } else if (triggerFrom === ZipCodeTriggeredFrom.PartnerInvoice) {
        setValues({
          ...values,
          invoiceCity: '',
          invoiceCityId: '',
          invoiceCountry: '',
          invoiceCountryId: '',
        });
        setSelectedInvoiceCity(defaultDropDownValue);
        setSelectedInvoiceCountry(defaultDropDownValue);
      }
    }
  };

  const LogoData = () => {
    return (
      <>
        <Grid item lg={12} md={12}>
          {!isLogoEdit ? (
            <img
              onError={replaceImage}
              src={environment.api.uploadedFileUrl + '/' + values.logo}
              alt="Logo"
              height={'100px'}
              width={'100px'}
            />
          ) : (
            <FileUpload
              filesLimit={1}
              validatePixel={true}
              onSave={handleSaveUpload}
              showPreviews={true}
              maxFileSize={2097152}
              onDelete={handleFileDelete}
              errorValue={I18n(errors.fileUpload)}
              acceptedFiles={[
                'image/jpeg',
                'image/png',
                'image/jpg',
                'image/bmp',
              ]}
            />
          )}
        </Grid>
      </>
    );
  };

  const loadCitiesOptions = async (
    search: string,
    loadedOptions: any,
    { page }: any
  ) => {
    const response = await getCities(search, page);
    const hasMore = Math.ceil(response.TotalRecords / 500) > page;

    return {
      options: response.Values.map((x) => {
        return {
          value: x.value.toString(),
          label: x.label,
        } as BaseModel;
      }),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const onCreateOption = async (inputValue: any, isInvoice: boolean) => {
    const name = {
      code: inputValue,
      name: inputValue,
      otherComments: inputValue,
      statusId: 1,
    };
    const newOption: any = await PostCityDropDown(
      name,
      i18nMessages.CityCommonSaveSuccessMsg,
      i18nMessages.CommonErrOccuredMsg
    );
    const increaseUniq = (uniq: any) => uniq + 1;
    setCacheUniq(increaseUniq);
    if (isInvoice) {
      onInvoiceCityChange(newOption);
    } else {
      onCityChange(newOption);
    }
    getCities('', 1);
  };

  const loadCountryOptions = async (
    search: string,
    loadedOptions: any,
    { page }: any,
    loadInstruction: boolean = false
  ) => {
    const response = await getCountries(search, page);
    const hasMore = Math.ceil(response.TotalRecords / 10) > page;

    return {
      options: response.Values.map((x: any) => {
        return {
          value: x.id.toString(),
          label: loadInstruction ? x.description : x.name,
        } as BaseModel;
      }),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const onCreateOptionCountry = async (inputValue: any, isInvoice: boolean) => {
    let name = {};
    name['name'] = inputValue;
    const newOption: any = await PostCountryDropDown(
      name,
      i18nMessages.CommonSaveSuccessMsg,
      i18nMessages.CommonErrOccuredMsg
    );
    const increaseUniq = (uniq: any) => uniq + 1;
    setCacheUniqCountry(increaseUniq);
    if (isInvoice) {
      onInvoiceCountryChange(newOption);
    } else {
      onCountryChange(newOption);
    }
    getCountries();
  };

  const Details = () => {
    return (
      <>
        <Grid item lg={4} md={4}>
          <CustomizedSelect
            placeholder={defaultTransportTypeValue.label}
            options={transportTypeList}
            isSearchable={false}
            displayLabel={I18n('Partner.Type')}
            handleChange={(newValue: BaseModel) => {
              setValues({
                ...values,
                transportType: newValue,
                transportTypeId: newValue.value,
              });
            }}
            value={values.transportType}
            errorValue={errors.transportType}
            errorMessage={errors.transportType}
            required={true}
            readOnly={!isDetailsEdit}
          ></CustomizedSelect>
        </Grid>
        <Grid item lg={4} md={4}>
          <CustomizedInputs
            margin="normal"
            displayLabel={I18n('Partner.SupplierName')}
            name="name"
            id="name"
            inputType="text"
            inputProps={{ maxLength: 50 }}
            errorValue={errors.name}
            errorMessage={errors.name}
            handleChange={onValueChange}
            value={values?.name}
            required={true}
            readOnly={!isDetailsEdit}
          />
        </Grid>
        <Grid item lg={4} md={4}>
          <FormControl
            margin="normal"
            displayLabel={I18n('Partner.Tracking')}
            id="partnerType"
            inputType="text"
            multiline={false}
            value={getPartnerTypeValue(+values.partnerType)}
            readOnly={true}
            required={true}
          />
          {/* Edit is disabled due to dependency with WO-Booking Tracking Service */}
        </Grid>
        <Grid item lg={8} md={8}>
          <AddressAutocompleteInputs
            name="address"
            // value={values?.address}
            inputProps={{ maxLength: 250 }}
            multiline={true}
            displayLabel={I18n('Partner.SearchAddress')}
            className={`${!isDetailsEdit ? 'autocomplete-readonly' : ''}`}
            handleChange={autocompleteHandleChange}
            readOnly={!isDetailsEdit}
          ></AddressAutocompleteInputs>
        </Grid>
        <Grid item lg={4} md={4}>
          <FormControl
            margin="normal"
            displayLabel={I18n('CustomerLocation.Street')}
            id="street"
            name="street"
            inputType="text"
            inputProps={{ maxLength: 250 }}
            handleChange={onValueChange}
            value={values.street}
            multiline={false}
            readOnly={!isDetailsEdit}
            required={true}
            errorValue={errors.street}
            errorMessage={errors.street}
          ></FormControl>
        </Grid>
        <Grid item lg={8} md={8}>
          <FormControl
            margin="normal"
            displayLabel={I18n('CustomerLocation.Address2')}
            id="address2"
            name="address2"
            inputType="text"
            inputProps={{ maxLength: 250 }}
            handleChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) =>
              setValues({
                ...values,
                address2: event.target.value,
              })
            }
            value={values.address2}
            multiline={false}
            readOnly={!isDetailsEdit}
          ></FormControl>
        </Grid>
        <Grid item lg={4} md={4}>
          <CustomizedInputs
            margin="normal"
            displayLabel={i18nTexts.CustomerPostalCode}
            name="postalCode"
            id="postalCode"
            inputType="text"
            errorValue={true}
            errorMessage={errors.postalCode}
            inputProps={{ maxLength: 20 }}
            handleChange={onValueChange}
            value={values?.postalCode}
            readOnly={!isDetailsEdit}
            required={true}
            handleBlur={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => onBlurZipCode(event, ZipCodeTriggeredFrom.PartnerDetails)}
          />
        </Grid>
        <Grid item lg={4} md={4}>
          {isDetailsEdit ? (
            <div className="add-button-address">
              <CustomSelectPaginateAdd
                key={JSON.stringify(selectedCity)}
                additional={defaultAdditional}
                value={selectedCity}
                loadOptions={loadCitiesOptions}
                handleChange={(newValue: BaseModel) => onCityChange(newValue)}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                }}
                placeholder={defaultDropDownValue.label}
                isSearchable={true}
                displayLabel={I18n('CustomerLocation.City')}
                errorValue={true}
                errorMessage={errors.city}
                required={true}
                readOnly={false}
                debounceTimeout={500}
                isMultiSelect={false}
                cacheUniqs={cacheUniq}
                onCreateOption={(inputValue: any) =>
                  onCreateOption(inputValue, false)
                }
              ></CustomSelectPaginateAdd>
            </div>
          ) : (
            <FormControl
              margin="normal"
              displayLabel={i18nTexts.CustomerCity}
              id="cityId"
              inputType="text"
              multiline={false}
              handleChange={onValueChange}
              value={values?.city}
              readOnly={!isDetailsEdit}
              required={true}
            />
          )}
        </Grid>
        <Grid item lg={4} md={4}>
          {isDetailsEdit ? (
            <CustomizedSelect
              placeholder={defaultDropDownValue.label}
              options={countryList}
              isSearchable={true}
              displayLabel={i18nMessages.country}
              handleChange={(newValue: BaseModel) => onCountryChange(newValue)}
              value={selectedCountry}
              errorValue={true}
              errorMessage={errors.country}
              required={true}
            ></CustomizedSelect>
          ) : (
            <FormControl
              margin="normal"
              displayLabel={i18nTexts.CustomerCountry}
              id="name"
              multiline={false}
              inputType="text"
              handleChange={onValueChange}
              value={values?.country}
              readOnly={!isDetailsEdit}
              required={true}
            />
          )}
        </Grid>
        <Grid item lg={4} md={4}>
          <CustomizedInputs
            margin="normal"
            displayLabel={I18n('Partner.Website')}
            name="webSite"
            id="webSite"
            inputType="text"
            errorValue={I18n(errors.webSite)}
            errorMessage={I18n(errors.webSite)}
            handleChange={onValueChange}
            inputProps={{ maxLength: 255 }}
            value={values?.webSite}
            readOnly={!isDetailsEdit}
          />
        </Grid>
      </>
    );
  };

  const FinanceBasic = () => {
    return (
      <>
        <Grid item lg={12}>
          <CustomizedInputs
            margin="normal"
            displayLabel={I18n('Partner.VatNumber')}
            name="vatNumber"
            id="vatNumber"
            inputType="text"
            value={values?.vatNumber}
            inputProps={{ maxLength: 30 }}
            readOnly={!financeEdit}
            errorValue={errors.vatNumber}
            errorMessage={errors.vatNumber}
            handleChange={onValueChange}
            required={true}
          />
        </Grid>
        <Grid item lg={6}>
          <CustomizedInputs
            margin="normal"
            displayLabel={I18n('Partner.EORI')}
            name="eori"
            id="eori"
            inputType="text"
            value={values?.eori}
            readOnly={!financeEdit}
            inputProps={{ maxLength: 30 }}
            errorValue={I18n(errors.eori)}
            errorMessage={I18n(errors.eori)}
            handleChange={onValueChange}
          />
        </Grid>
        <Grid item lg={6}>
          <FormControlLabel
            labelPlacement="end"
            control={
              <Checkbox
                name="SameAsVat"
                checked={values?.sameVat}
                onChange={onSameAsVaTChange}
                inputProps={{ 'aria-label': 'controlled' }}
                disabled={!financeEdit}
              />
            }
            label={I18n('Partner.SameAsVat')}
          />
        </Grid>
      </>
    );
  };

  const ContactDetails = () => {
    return (
      <>
        <Grid item lg={6}>
          {contactDetailsEdit ? (
            <CustomizedPhone
              title={I18n('Partner.GeneralTelephone')}
              value={values?.telephone}
              name="telephone"
              errorValue={I18n(errors.telephone)}
              errorMessage={I18n(errors.telephone)}
              onChange={(phone: any) => phoneOnChange(phone)}
            ></CustomizedPhone>
          ) : (
            <CustomizedInputs
              margin="normal"
              displayLabel={I18n('Partner.GeneralTelephone')}
              name="telephone"
              id="telephone"
              inputType="text"
              value={values?.telephone}
              readOnly={!contactDetailsEdit}
            />
          )}
        </Grid>

        <Grid item lg={6}>
          <CustomizedInputs
            margin="normal"
            displayLabel={I18n('Partner.GeneralEmail')}
            name="email"
            id="email"
            inputType="text"
            value={values?.email}
            readOnly={!contactDetailsEdit}
            errorValue={I18n(errors.email)}
            errorMessage={I18n(errors.email)}
            inputProps={{ maxLength: 255 }}
            handleChange={onValueChange}
          />
        </Grid>
        <Grid item lg={6}>
          <CustomizedInputs
            margin="normal"
            displayLabel={I18n('Partner.AdminEmail')}
            id="Email"
            name="adminEmail"
            inputType="text"
            errorValue={errors.adminEmail}
            errorMessage={errors.adminEmail}
            inputProps={{ maxLength: 255 }}
            handleChange={onValueChange}
            value={values.adminEmail}
            readOnly={!contactDetailsEdit}
            multiline={false}
          ></CustomizedInputs>
        </Grid>
      </>
    );
  };

  const StatusDetails = () => {
    return (
      <>
        <Grid item lg={12}>
          {statusEdit ? (
            <CustomizedSelect
              placeholder={defaultValue.label}
              options={entityStatusList}
              isSearchable={false}
              displayLabel={I18n('Common.Status')}
              handleChange={(newValue: BaseModel) => onStatusChange(newValue)}
              value={selectedStatus}
              errorValue={I18n(errors.status)}
            ></CustomizedSelect>
          ) : (
            <FormControl
              margin="normal"
              displayLabel={I18n('Common.Status')}
              id="status"
              inputType="text"
              multiline={false}
              value={values?.statusDescription}
              readOnly={!statusEdit}
            />
          )}
        </Grid>
      </>
    );
  };

  // const TagDetails = () => {
  //   return (
  //     <>
  //       <Grid item lg={12}>
  //         {tagEdit ? (
  //           <CustomizedSelect
  //             placeholder={defaultDropDownValue.label}
  //             options={customerTagsList}
  //             isSearchable={false}
  //             displayLabel={i18nTexts.CustomerTag}
  //             handleChange={(newValue: BaseModel) => onTagChange(newValue)}
  //             value={selectedTag}
  //           ></CustomizedSelect>
  //         ) : (
  //           <CustomizedInputs
  //             margin="normal"
  //             displayLabel={i18nTexts.CustomerTag}
  //             name="taggedTo"
  //             id="taggedTo"
  //             inputType="text"
  //             value={values?.taggedToDescription}
  //             readOnly={!tagEdit}
  //           />
  //         )}
  //       </Grid>
  //     </>
  //   );
  // };

  const i18nMessages = {
    country: I18n('Partner.Country'),
    city: I18n('Partner.City'),
    address: I18n('Partner.SearchAddress'),
    postalCode: I18n('Partner.PostalCode'),
    save: I18n('Common.Save'),
    cancel: I18n('Common.Cancel'),
    invoiceEmail: I18n('Partner.InvoiceEmail'),
    isDifferent: I18n('Partner.IsDifferent'),
    isAddressDifferent: I18n('Partner.InvoiceAddressDifferent'),
    invoicing: I18n('Partner.Invoicing'),
    commonError: I18n('Common.ErrorOccurred'),
    AddCity: I18n('Cities.AddDialogTitle'),
    Select: I18n('Placeholders.Select'),
    UniqueName: I18n('Partner.Validation.UniqueName'),
    dhlValue: I18n('Partner.DHL'),
    fedExValue: I18n('Partner.FedEx'),
    othersValue: I18n('Partner.Others'),
    partnerNameRequired: I18n('Partner.Validation.PartnerNameRequired'),
    partnerNameMaxLength: I18n('Partner.Validation.PartnerNameMaxLength'),
    partnerNameMinLength: I18n('Partner.Validation.PartnerNameMinLength'),
    addressMaxLength: I18n('Partner.Validation.AddressMaxLength'),
    postalCodeMaxLength: I18n('Partner.Validation.PostalCodeMaxLength'),
    invoiceAddressMaxLength: I18n('Partner.Validation.InvoiceAddressMaxLength'),
    invoicePostalCodeMaxLength: I18n(
      'Partner.Validation.InvoicePostalCodeMaxLength'
    ),
    websiteMaxLenght: I18n('Partner.Validation.WebsiteMaxLenght'),
    vatNumberMaxLength: I18n('Partner.Validation.VatNumberMaxLength'),
    eORIMaxLength: I18n('Partner.Validation.EORIMaxLength'),
    telephoneMaxLength: I18n('Partner.Validation.TelephoneMaxLength'),
    statusRequired: I18n('Partner.Validation.StatusRequired'),
    emailMaxLength: I18n('Partner.Validation.EmailMaxLength'),
    invalidEmail: I18n('Partner.Validation.InvalidEmail'),
    invoiceEmailMaxLength: I18n('Partner.Validation.InvoiceEmailMaxLength'),
    CommonSaveSuccessMsg: I18n('Common.SavedSuccessfully'),
    CommonErrOccuredMsg: I18n('Common.ErrorOccurred'),
    Address2: I18n('CustomerLocation.Address2'),
    Street: I18n('CustomerLocation.Street'),
    commonFieldIsRequired: I18n('Common.FieldIsRequired'),
    CityCommonSaveSuccessMsg: I18n('Common.CitySavedSuccessfully'),
  };

  const getPartnerTypeValue = (type: PartnerType) => {
    switch (type) {
      case PartnerType.DHL:
        return i18nMessages.dhlValue;
      case PartnerType.FedEx:
        return i18nMessages.fedExValue;
      case PartnerType.Others:
        return i18nMessages.othersValue;
      default:
        break;
    }
  };
  const InvoiceDetails = () => {
    return (
      <>
        <Grid item lg={6}>
          <FormControlLabel
            control={
              <Checkbox
                name="isInvoiceAddress"
                onChange={onInvoiceAddressChange}
                checked={values?.isInvoiceAddress}
                inputProps={{ 'aria-label': 'controlled' }}
                disabled={!invoiceEdit}
              />
            }
            label={i18nMessages.isAddressDifferent}
          />
        </Grid>

        <Grid item lg={6}>
          <CustomizedInputs
            margin="normal"
            displayLabel={i18nMessages.invoiceEmail}
            name="invoiceEmail"
            id="invoiceEmail"
            inputType="text"
            value={values?.invoiceEmail}
            readOnly={!invoiceEdit}
            handleChange={onValueChange}
            inputProps={{ maxLength: 255 }}
            errorValue={I18n(errors.invoiceEmail)}
            errorMessage={I18n(errors.invoiceEmail)}
          />
        </Grid>

        {isInvoiceAddress ? (
          <>
            <Grid item lg={12} md={12}>
              <AddressAutocompleteInputs
                name="invoiceAddress"
                // value={values?.invoiceAddress}
                inputProps={{ maxLength: 250 }}
                multiline={true}
                displayLabel={i18nMessages.address}
                className={`${!invoiceEdit ? 'autocomplete-readonly' : ''}`}
                errorValue={I18n(errors.invoiceAddress)}
                errorMessage={I18n(errors.invoiceAddress)}
                handleChange={autocompleteHandleChangeInvoice}
                readOnly={!invoiceEdit}
              ></AddressAutocompleteInputs>
            </Grid>
            <Grid item lg={6} md={6}>
              <CustomizedInputs
                margin="normal"
                displayLabel={i18nMessages.Street}
                name="invoiceStreet"
                id="invoiceStreet"
                inputType="text"
                inputProps={{ maxLength: 20 }}
                handleChange={onValueChange}
                value={values?.invoiceStreet}
                readOnly={!invoiceEdit}
                errorValue={true}
                errorMessage={errors.invoiceStreet}
                required={true}
              />
            </Grid>
            <Grid item lg={6} md={6}>
              <CustomizedInputs
                margin="normal"
                displayLabel={i18nMessages.Address2}
                name="invoiceAddress2"
                id="invoiceAddress2"
                inputType="text"
                inputProps={{ maxLength: 250 }}
                handleChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ) =>
                  setValues({
                    ...values,
                    invoiceAddress2: event.target.value,
                  })
                }
                value={values?.invoiceAddress2}
                readOnly={!invoiceEdit}
              />
            </Grid>
            <Grid item lg={6} md={6}>
              <CustomizedInputs
                margin="normal"
                displayLabel={i18nMessages.postalCode}
                name="invoicePostalCode"
                id="invoicePostalCode"
                inputType="text"
                handleChange={onValueChange}
                inputProps={{ maxLength: 20 }}
                value={values?.invoicePostalCode}
                readOnly={!invoiceEdit}
                errorValue={true}
                errorMessage={errors.invoicePostalCode}
                required={true}
                handleBlur={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ) => onBlurZipCode(event, ZipCodeTriggeredFrom.PartnerInvoice)}
              />
            </Grid>
            <Grid item lg={6} md={6}>
              {invoiceEdit ? (
                <div className="add-button-address">
                  <CustomSelectPaginateAdd
                    key={JSON.stringify(selectedInvoiceCity)}
                    additional={defaultAdditional}
                    value={selectedInvoiceCity}
                    loadOptions={loadCitiesOptions}
                    handleChange={(newValue: BaseModel) =>
                      onInvoiceCityChange(newValue)
                    }
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                    }}
                    placeholder={defaultDropDownValue.label}
                    isSearchable={true}
                    displayLabel={i18nMessages.city}
                    readOnly={false}
                    debounceTimeout={500}
                    isMultiSelect={false}
                    cacheUniqs={cacheUniq}
                    onCreateOption={(inputValue: any) =>
                      onCreateOption(inputValue, true)
                    }
                    menuPlacement={'top'}
                    errorValue={true}
                    errorMessage={errors.invoiceCity}
                    required={true}
                  ></CustomSelectPaginateAdd>
                </div>
              ) : (
                <CustomizedInputs
                  margin="normal"
                  displayLabel={i18nMessages.city}
                  name="invoiceCity"
                  id="invoiceCity"
                  inputType="text"
                  handleChange={onValueChange}
                  value={values?.invoiceCity}
                  readOnly={!invoiceEdit}
                  errorValue={true}
                  errorMessage={errors.invoiceCity}
                  required={true}
                />
              )}
            </Grid>
            <Grid item lg={6} md={6}>
              {invoiceEdit ? (
                <CustomizedSelect
                  placeholder={defaultDropDownValue.label}
                  options={countryList}
                  isSearchable={true}
                  displayLabel={i18nMessages.country}
                  handleChange={(newValue: BaseModel) =>
                    onInvoiceCountryChange(newValue)
                  }
                  value={selectedInvoiceCountry}
                  menuPlacement={'top'}
                  errorValue={true}
                  errorMessage={errors.invoiceCountry}
                  required={true}
                ></CustomizedSelect>
              ) : (
                <CustomizedInputs
                  margin="normal"
                  displayLabel={i18nMessages.country}
                  name="invoiceCountry"
                  id="invoiceCountry"
                  inputType="text"
                  handleChange={onValueChange}
                  value={values?.invoiceCountry}
                  readOnly={!invoiceEdit}
                  errorValue={true}
                  errorMessage={errors.invoiceCountry}
                  required={true}
                />
              )}
            </Grid>
          </>
        ) : (
          <></>
        )}
      </>
    );
  };

  getPartnerTypeList();

  useEffect(() => {
    getCountries();
    getCities();
    getPartnerData(params.entityId);
    getTransportTypeDropDown();
  }, []);

  return (
    <>
      <Grid container spacing={2} className="grid-wrap">
        <Grid item lg={3} md={3}>
          <FormAccordian
            title={'Logo'}
            details={LogoData()}
            showActionButton={canEdit}
            expanded={logoExpanded}
            isEdit={isLogoEdit}
            handleEditClick={handelLogoEdit}
            handleExpandCollapseClick={handleLogoExpandCollapseClick}
            isSaveButton={isLogoEdit}
            onSaveClick={handleFileUpload}
          ></FormAccordian>
        </Grid>
        <Grid item lg={9} md={9}>
          <FormAccordian
            title={I18n('Partner.Details')}
            details={Details()}
            showActionButton={canEdit}
            expanded={detailsExpanded}
            isEdit={isDetailsEdit}
            handleEditClick={handelDetailsEdit}
            handleExpandCollapseClick={handleDetailsExpandCollapseClick}
            isSaveButton={isDetailsEdit}
            onSaveClick={handleSubmit}
          ></FormAccordian>
        </Grid>
        <Grid item lg={4} md={4}>
          <FormAccordian
            title={I18n('Partner.FinancialBasic')}
            details={FinanceBasic()}
            showActionButton={canEdit}
            expanded={financeExpanded}
            isEdit={financeEdit}
            handleEditClick={handelFinanceEdit}
            handleExpandCollapseClick={handelFinanceExpandCollapseClick}
            isSaveButton={financeEdit}
            onSaveClick={handleSubmit}
          ></FormAccordian>
        </Grid>
        <Grid item lg={5} md={5}>
          <FormAccordian
            title={I18n('Partner.ContactDetails')}
            details={ContactDetails()}
            showActionButton={canEdit}
            expanded={contactDetailsExpanded}
            isEdit={contactDetailsEdit}
            handleEditClick={handelContactDetailsEdit}
            handleExpandCollapseClick={handelContactDetailsCollapseClick}
            isSaveButton={contactDetailsEdit}
            onSaveClick={handleSubmit}
          ></FormAccordian>
        </Grid>
        <Grid item lg={3} md={3}>
          <FormAccordian
            title={I18n('Partner.Status')}
            details={StatusDetails()}
            showActionButton={canEdit}
            expanded={statusExpanded}
            isEdit={statusEdit}
            handleEditClick={handelStatusEdit}
            handleExpandCollapseClick={handelStatusCollapseClick}
            isSaveButton={statusEdit}
            onSaveClick={handleSubmit}
          ></FormAccordian>
        </Grid>

        <Grid item lg={4} md={4}>
          <FormAccordian
            title={i18nMessages.invoicing}
            details={InvoiceDetails()}
            showActionButton={canEdit}
            expanded={invoiceExpanded}
            isEdit={invoiceEdit}
            handleEditClick={handelInvoiceEdit}
            handleExpandCollapseClick={handelInvoiceCollapseClick}
            isSaveButton={invoiceEdit}
            onSaveClick={handleSubmit}
          ></FormAccordian>
        </Grid>
      </Grid>
      {isAddCityModalOpen && (
        <AddCity
          isOpen={isAddCityModalOpen}
          onClose={onCloseAddCity}
          onSubmit={onSubmitCity}
          title={i18nMessages.AddCity}
          locationData={locationData}
        ></AddCity>
      )}
    </>
  );
}

export default EditPartnerDetails;
