import {
  BaseModel,
  EntityStatus,
  PricingType,
  ProductCatalogueType,
} from './pagination-model';

export interface ProductCatalogues {
  id: number;
  ProductCode: string;
  categoryId: number;
  categoryName: string;
  productName: string;
  description: string;
  standardPrice: number;
  status: EntityStatus;
  isDryIce: boolean;
}

export interface ProductCatalogueStateModel {
  id?: number;
  productCode: string;
  category: BaseModel;
  productCatalogueCategoryId: number;
  productCatalogueCategoryName: BaseModel['label'];
  shortDescription: string;
  name: string;
  shippingStandardPrice: string;
  decPackStandardPrice: string;
  lastCostBuyPrice: string;
  lastPurchasedFrom: string;
  status: EntityStatus;
  pricingType: PricingType;
  productCatalogueType: ProductCatalogueType;
  weight: number;
  thresholdLimit: number;
  warehouseQuantity: number;
  dimension1: number;
  dimension2: number;
  dimension3: number;
  ledgerAccount: BaseModel;
  itemGroup: BaseModel;
  showInDPBOM: false;
  department: BaseModel;
  departmentType: number;
}
export interface ProductPurchase {
  id: number;
  productCatalogueId: number;
  date: Date;
  quantity: string;
  supplier: string;
  pricePerUnit: string;
  remarks: string;
  isManual: Boolean;
}

export interface ProductConsumptionStateModel {
  id: number;
  productCatalogueId: number;
  date: Date;
  quantity: string;
  order: BaseModel;
  orderId: string;
  orderNumber: string;
  customer: BaseModel;
  customerId: string;
  customerName: string;
  sellingPrice: string;
  remarks: string;
  orderType: ProductCatalogueAssignmentType;
}

export interface ProductConsumptionResponseDto extends ProductConsumptionDto {
  createdBy: string;
  createdDate: string;
  updatedBy: string;
  updatedDate: string;
}

export interface ProductConsumptionDto extends ProductConsumptionDtoBase {
  id: number;
}

export interface ProductConsumptionDtoBase {
  productCatalogueId: number;
  date: string | Date;
  quantity: number;
  orderId: number | null;
  orderNumber: string;
  customerId: number | null;
  customerName: string;
  sellingPrice: number;
  remarks: string;
  orderType: ProductCatalogueAssignmentType | null;
  isManual: boolean;
}

export interface Order {
  id: number;
  orderNumber: string;
}

export interface ProductCatalogueRemoveStock {
  id: number;
  productCatalogueId: number;
  date: Date;
  remarks: string;
  quantity: string;
}

export enum ProductCatalogueAssignmentType {
  Warehouse = 1,
  ShippingOrder = 2,
  DecPackOrder = 3,
}

export const ProductCatalogueAssignmentTypeDescription = new Map<
  number,
  string
>([
  [
    ProductCatalogueAssignmentType.Warehouse,
    'ProductCatalogues.Assignments.Warehouse',
  ],
  [
    ProductCatalogueAssignmentType.ShippingOrder,
    'ProductCatalogues.Assignments.ShippingOrder',
  ],
  [
    ProductCatalogueAssignmentType.DecPackOrder,
    'ProductCatalogues.Assignments.DecPackOrder',
  ],
]);

export const ProductCatalogueConsumptionTypeDescription = new Map<
  number,
  string
>([
  [
    ProductCatalogueAssignmentType.ShippingOrder,
    'ProductCatalogues.Assignments.ShippingOrder',
  ],
  [
    ProductCatalogueAssignmentType.DecPackOrder,
    'ProductCatalogues.Assignments.DecPackOrder',
  ],
]);

export interface ProductCatalogueAssignmentStateModel {
  id: number;
  productCatalogueId: number;
  assignDate: Date;
  remarks: string;
  quantity: string;
  assignedBy: BaseModel | null;
  assignedTo: BaseModel | null;
  assignedByType: ProductCatalogueAssignmentType;
  assignedToType: ProductCatalogueAssignmentType;
}

export interface ProductCatalogueAssignmentResponseDto
  extends ProductCatalogueAssignmentDto {
  assignedByName: string;
  assignedToName: string;
}

export interface ProductCatalogueAssignmentDto
  extends ProductCatalogueAssignmentDtoBase {
  id: number;
}

export interface ProductCatalogueAssignmentDtoBase {
  productCatalogueId: number;
  assignDate: string;
  assignedByType: ProductCatalogueAssignmentType;
  assignedToType: ProductCatalogueAssignmentType;
  assignedById: number | null;
  assignedToId: number | null;
  quantity: number;
  remarks: string;
}
