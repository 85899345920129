import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import I18n from 'utilities/i18n';
import { CustomizedInputs } from 'common/formControl/formControl';
import { Grid } from '@mui/material';
import {
  AdvanceModel,
  BaseModel,
  OrderByDirection,
  PaginationResponseModel,
} from 'models/pagination-model';
import environment from 'environment';
import http from '../../../utilities/httpService';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CustomSelectPaginate from 'common/CustomSelect/CustomSelectPaginate';
import { Customer, TariffCustoms } from 'models/service-catalogue';
import NumericInput from 'common/NumericInput/NumericInput';
import CustomSelectPaginateAdd from 'common/CustomSelect/CustomSelectPaginateAdd';
import {
  PostUnitDropDown,
  PostCountryDropDown,
  PostCurrencyDropDown,
} from 'common/DropDownAddAPI/DropDownApiService';
import TariffCustomDailog from './TariffCustomDailog';

export default function Commodity(props: any) {
  const defaultDropDownValue = {
    value: '0',
    label: I18n('Placeholders.Select'),
  } as BaseModel;
  const { isOpen, handleClose, title } = props;
  const [errors, setErrors] = useState<any>({});
  const [selectedUnit, setSelectedUnit] = useState(defaultDropDownValue);
  const [selectedCountry, setSelectedCountry] = useState(defaultDropDownValue);
  const [isOpenHSN, setOpenHSN] = useState(false);
  const [selectedCurrency, setSelectedCurrency] =
    useState(defaultDropDownValue);
  const [gridData, setGridData] = useState({
    isLoading: true,
    sortOrder: OrderByDirection.Descending,
    sortBy: 'HSCODE',
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [10, 20, 50, 100],
    pageSize: 10,
    page: 0,
    searchExpression: '',
  });
  const myStyle = {
    position: 'relative' as 'relative',
    top: '20px',
  };
  const [cacheUniqCountry, setCacheUniqCountry] = useState(0);
  const defaultAdditional: any = {
    page: 1,
  };

  const i18nMessages = {
    CommonSaveSuccessMsg: I18n('Common.SavedSuccessfully'),
    CommonErrOccuredMsg: I18n('Common.ErrorOccurred'),
  };

  const [values, setValues] = useState({
    id: 0,
    hscode: defaultDropDownValue,
    description: '',
    quantity: 0,
    unitId: 0,
    currencyId: 0,
    itemValue: 0,
    netWeight: 0,
    grossWeight: 0,
    currency: '',
    countryOfOrigin: '',
    originCountryId: 0,
  });

  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));

  const validate = () => {
    let temp: any = {};
    let isValid = true;

    if (values.hscode.value === defaultDropDownValue.value) {
      temp.hscode = 'Common.FieldRequired';
      isValid = false;
    }
    if (values.description === '') {
      temp.description = 'Common.FieldRequired';
      isValid = false;
    }
    if (values.quantity === 0) {
      temp.quantity = 'Common.FieldRequired';
      isValid = false;
    }
    if (selectedUnit.value === defaultDropDownValue.value) {
      temp.unit = 'Common.FieldRequired';
      isValid = false;
    }
    if (selectedCurrency.value === defaultDropDownValue.value) {
      temp.currency = 'Common.FieldRequired';
      isValid = false;
    }
    if (values.itemValue === 0) {
      temp.itemValue = 'Common.FieldRequired';
      isValid = false;
    }
    if (values.netWeight === 0) {
      temp.netWeight = 'Common.FieldRequired';
      isValid = false;
    }
    if (values.grossWeight === 0) {
      temp.grossWeight = 'Common.FieldRequired';
      isValid = false;
    }
    if (selectedCountry.value === defaultDropDownValue.value) {
      temp.country = 'Common.FieldRequired';
      isValid = false;
    }

    setErrors(temp);

    if (isValid) {
      return true;
    } else {
      return false;
    }
  };

  const submit = async () => {
    if (validate()) {
      values.unitId = Number(selectedUnit.value);
      values.currencyId = Number(selectedCurrency.value);
      values.originCountryId = Number(selectedCountry.value);
      values.currency = selectedCurrency.label;
      values.countryOfOrigin = selectedCountry.label;
      values.quantity = Number(values.quantity);
      values.itemValue = Number(values.itemValue);
      values.netWeight = Number(values.netWeight);
      values.grossWeight = Number(values.grossWeight);

      props.OnSave(values);
      resetForm();
    }
  };

  const resetForm = () => {
    setValues({
      id: 0,
      hscode: defaultDropDownValue,
      description: '',
      quantity: 0,
      unitId: 0,
      currencyId: 0,
      itemValue: 0,
      netWeight: 0,
      grossWeight: 0,
      currency: '',
      countryOfOrigin: '',
      originCountryId: 0,
    });
    setSelectedCountry(defaultDropDownValue);
    setSelectedCurrency(defaultDropDownValue);
    setSelectedUnit(defaultDropDownValue);
  };

  const onValueChange = (event: any) => {
    let { name, value } = event.target;
    setValues({
      ...values,
      [name.toString()]: value,
    });
  };

  const getCurrencyMaster = async (search: string, pageNo: number) => {
    let response: PaginationResponseModel<Customer>;
    const apiUrl = new URL(
      environment.api.baseUrl + 'ShippingPackage/CurrencyMaster'
    );
    try {
      let searchExpression = '';
      searchExpression += search ? `name~like~${search}` : '';
      apiUrl.searchParams.set('$filter', searchExpression);
      apiUrl.searchParams.set('$orderby', 'name');
      apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
      apiUrl.searchParams.set('$top', '10');
      apiUrl.searchParams.set('$orderbydirection', 'desc');
      const result = await http.get(apiUrl.toString());
      if (result) {
        response = {
          TotalRecords: result.data.totalCount,
          Values: result.data.values,
        } as PaginationResponseModel<Customer>;
      } else {
        response = {
          TotalRecords: 0,
          Values: [],
        } as PaginationResponseModel<Customer>;
      }
    } catch (error) {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<Customer>;
    }
    return response;
  };

  const loadCountryOptions = async (
    search: string,
    loadedOptions: any,
    { page }: any,
    loadInstruction: boolean = false
  ) => {
    const response = await getCountries(search, page);
    const hasMore = Math.ceil(response.TotalRecords / 10) > page;

    return {
      options: response.Values.map((x: any) => {
        return {
          value: x.id.toString(),
          label: loadInstruction ? x.description : x.name,
        } as BaseModel;
      }),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const loadUnitOptions = async (
    search: string,
    loadedOptions: any,
    { page }: any
  ) => {
    const response = await getUnitMaster(search, page);
    const hasMore = Math.ceil(response.TotalRecords / 10) > page;

    return {
      options: response.Values.map((x: any) => {
        return {
          value: x.id.toString(),
          label: x.name,
        } as BaseModel;
      }),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const onCreateOptionUnit = async (inputValue: any) => {
    let name = {};
    name['name'] = inputValue;
    const newOption: any = await PostUnitDropDown(
      name,
      i18nMessages.CommonSaveSuccessMsg,
      i18nMessages.CommonErrOccuredMsg
    );
    const increaseUniq = (uniq: any) => uniq + 1;
    setCacheUniqCountry(increaseUniq);
    setSelectedUnit(newOption);
    getUnitMaster('', 1);
  };

  const loadCurrencyOptions = async (
    search: string,
    loadedOptions: any,
    { page }: any
  ) => {
    const response = await getCurrencyMaster(search, page);
    const hasMore = Math.ceil(response.TotalRecords / 10) > page;

    return {
      options: response.Values.map((x: any) => {
        return {
          value: x.id.toString(),
          label: x.name,
        } as BaseModel;
      }),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const onCreateOptionCurrency = async (inputValue: any) => {
    let name = {};
    name['name'] = inputValue;
    const newOption: any = await PostCurrencyDropDown(
      name,
      i18nMessages.CommonSaveSuccessMsg,
      i18nMessages.CommonErrOccuredMsg
    );
    const increaseUniq = (uniq: any) => uniq + 1;
    setCacheUniqCountry(increaseUniq);
    setSelectedCurrency(newOption);
    getCurrencyMaster('', 1);
  };

  const onCreateOptionCountry = async (inputValue: any) => {
    let name = {};
    name['name'] = inputValue;
    const newOption: any = await PostCountryDropDown(
      name,
      i18nMessages.CommonSaveSuccessMsg,
      i18nMessages.CommonErrOccuredMsg
    );
    const increaseUniq = (uniq: any) => uniq + 1;
    setCacheUniqCountry(increaseUniq);
    setSelectedCountry(newOption);
    getCountries('', 1);
  };

  const getCountries = async (search: string, pageNo: number) => {
    let response: PaginationResponseModel<Customer>;
    const apiUrl = new URL(environment.api.baseUrl + 'Country');
    try {
      let searchExpression = '';
      searchExpression += search ? `name~like~${search}` : '';
      apiUrl.searchParams.set('$filter', searchExpression);
      apiUrl.searchParams.set('$orderby', 'name');
      apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
      apiUrl.searchParams.set('$top', '10');
      apiUrl.searchParams.set('$orderbydirection', 'desc');
      const result = await http.get(apiUrl.toString());
      if (result) {
        response = {
          TotalRecords: result.data.totalCount,
          Values: result.data.values,
        } as PaginationResponseModel<Customer>;
      } else {
        response = {
          TotalRecords: 0,
          Values: [],
        } as PaginationResponseModel<Customer>;
      }
    } catch (error) {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<Customer>;
    }
    return response;
  };

  const getUnitMaster = async (search: string, pageNo: number) => {
    let response: PaginationResponseModel<Customer>;
    const apiUrl = new URL(
      environment.api.baseUrl + 'ShippingPackage/UnitMaster'
    );
    try {
      let searchExpression = '';
      searchExpression += search ? `name~like~${search}` : '';
      apiUrl.searchParams.set('$filter', searchExpression);
      apiUrl.searchParams.set('$orderby', 'name');
      apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
      apiUrl.searchParams.set('$top', '10');
      apiUrl.searchParams.set('$orderbydirection', 'desc');
      const result = await http.get(apiUrl.toString());
      if (result) {
        response = {
          TotalRecords: result.data.totalCount,
          Values: result.data.values,
        } as PaginationResponseModel<Customer>;
      } else {
        response = {
          TotalRecords: 0,
          Values: [],
        } as PaginationResponseModel<Customer>;
      }
    } catch (error) {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<Customer>;
    }
    return response;
  };

  const loadTariffCustomsOptions = async (
    search: string,
    loadedOptions: any,
    { page }: any
  ) => {
    const response = await getTariffCustoms(search, page);
    const hasMore = Math.ceil(response.TotalRecords / 10) > page;
    return {
      options: response.Values.map((x) => {
        return {
          value: x.id.toString(),
          label: x.cn,
          description: x.description,
        } as AdvanceModel;
      }),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const getTariffCustoms = async (search: string, pageNo: number) => {
    let response: PaginationResponseModel<TariffCustoms>;
    const apiUrl = new URL(environment.api.baseUrl + 'TariffCustoms');
    try {
      let searchExpression = ``;
      searchExpression += search ? `cn~like~${search}` : '';
      apiUrl.searchParams.set('$filter', searchExpression);
      apiUrl.searchParams.set('$orderby', 'cn');
      apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
      apiUrl.searchParams.set('$top', '10');
      apiUrl.searchParams.set(
        '$orderbydirection',
        OrderByDirection.Descending.toString()
      );
      const result = await http.get(apiUrl.toString());
      if (result) {
        response = {
          TotalRecords: result.data.totalCount,
          Values: result.data.values,
        } as PaginationResponseModel<TariffCustoms>;
      } else {
        response = {
          TotalRecords: 0,
          Values: [],
        } as PaginationResponseModel<TariffCustoms>;
      }
    } catch (error) {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<TariffCustoms>;
    }
    return response;
  };

  const handleFormClose = () => {
    resetForm();
    handleClose();
  };

  const handleAddHSNCode = () => {
    setOpenHSN(true);
  };

  const handleCloseForAddHSN = () => {
    setOpenHSN(false);
  };

  useEffect(() => {}, []);

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleFormClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={'lg'}
        fullWidth={true}
      >
        <DialogTitle>
          {title}
          <IconButton
            aria-label="close"
            onClick={handleFormClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className="grid-wrap two-col">
            <Grid item xs={3}>
              <CustomSelectPaginate
                additional={defaultAdditional}
                value={values.hscode}
                loadOptions={(search: string, prevOptions: any, page: any) =>
                  loadTariffCustomsOptions(search, prevOptions, page)
                }
                handleChange={(newValue: AdvanceModel) => {
                  setValues({
                    ...values,
                    hscode: newValue,
                    description: newValue.description,
                  });
                }}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                }}
                placeholder={defaultDropDownValue.label}
                isSearchable={true}
                displayLabel={I18n('ShippingOrders.ShippingPackage.HSCODE')}
                errorValue={true}
                errorMessage={I18n(errors.hscode)}
                readOnly={false}
                debounceTimeout={500}
                isMultiSelect={false}
                required={true}
              />
            </Grid>
            <Grid item xs={1}>
              <div style={myStyle}>
                <Button
                  onClick={handleAddHSNCode}
                  variant="outlined"
                  color="primary"
                  disableElevation
                >
                  {I18n('Common.Add')}
                </Button>
              </div>
            </Grid>
            <Grid item xs={8}>
              <CustomizedInputs
                margin="normal"
                displayLabel={I18n(
                  'ShippingOrders.ShippingPackage.ItemDescription'
                )}
                name="description"
                id="description"
                inputType="text"
                value={values.description}
                inputProps={{ maxLength: 200 }}
                errorValue={I18n(errors.description)}
                errorMessage={I18n(errors.description)}
                handleChange={onValueChange}
                required={true}
              />
            </Grid>

            <Grid item xs={2}>
              <NumericInput
                displayLabel={I18n('ShippingOrders.ShippingPackage.Quantity')}
                name="quantity"
                id="quantity"
                inputProps={{ min: 0 }}
                errorValue={I18n(errors.quantity)}
                errorMessage={I18n(errors.quantity)}
                handleChange={onValueChange}
                required={true}
                allowNegative={false}
              />
            </Grid>
            <Grid item xs={2}>
              <CustomSelectPaginateAdd
                key={JSON.stringify(selectedUnit)}
                additional={defaultAdditional}
                value={selectedUnit}
                loadOptions={loadUnitOptions}
                handleChange={(newValue: BaseModel) =>
                  setSelectedUnit(newValue)
                }
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                }}
                placeholder={defaultDropDownValue.label}
                isSearchable={true}
                displayLabel={I18n('ShippingOrders.ShippingPackage.Unit')}
                errorValue={true}
                errorMessage={I18n(errors.unit)}
                readOnly={false}
                debounceTimeout={500}
                isMultiSelect={false}
                cacheUniqs={cacheUniqCountry}
                required={true}
                onCreateOption={(inputValue: any) =>
                  onCreateOptionUnit(inputValue)
                }
              ></CustomSelectPaginateAdd>
            </Grid>
            <Grid item xs={2}>
              <CustomSelectPaginateAdd
                key={JSON.stringify(selectedCurrency)}
                additional={defaultAdditional}
                value={selectedCurrency}
                loadOptions={loadCurrencyOptions}
                handleChange={(newValue: BaseModel) =>
                  setSelectedCurrency(newValue)
                }
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                }}
                placeholder={defaultDropDownValue.label}
                isSearchable={true}
                displayLabel={I18n(
                  'ShippingOrders.ShippingPackage.ValueCurrency'
                )}
                errorValue={true}
                errorMessage={I18n(errors.unit)}
                readOnly={false}
                debounceTimeout={500}
                isMultiSelect={false}
                cacheUniqs={cacheUniqCountry}
                required={true}
                onCreateOption={(inputValue: any) =>
                  onCreateOptionCurrency(inputValue)
                }
              ></CustomSelectPaginateAdd>
            </Grid>
            <Grid item xs={2}>
              <NumericInput
                name="itemValue"
                id="itemValue"
                displayLabel={I18n('ShippingOrders.ShippingPackage.ItemValue')}
                inputProps={{ min: 0 }}
                errorValue={I18n(errors.itemValue)}
                errorMessage={I18n(errors.itemValue)}
                handleChange={onValueChange}
                required={true}
                allowNegative={false}
              />
            </Grid>
            <Grid item xs={1}>
              <NumericInput
                name="netWeight"
                id="netWeight"
                displayLabel={I18n('ShippingOrders.ShippingPackage.NetWeight')}
                inputProps={{ min: 0 }}
                errorValue={I18n(errors.netWeight)}
                errorMessage={I18n(errors.netWeight)}
                handleChange={onValueChange}
                required={true}
                allowNegative={false}
              />
            </Grid>
            <Grid item xs={1}>
              <NumericInput
                name="grossWeight"
                id="grossWeight"
                displayLabel={I18n(
                  'ShippingOrders.ShippingPackage.GrossWeight'
                )}
                inputProps={{ min: 0 }}
                errorValue={I18n(errors.grossWeight)}
                errorMessage={I18n(errors.grossWeight)}
                handleChange={onValueChange}
                required={true}
                allowNegative={false}
              />
            </Grid>
            <Grid item xs={2}>
              <CustomSelectPaginateAdd
                key={JSON.stringify(selectedCountry)}
                additional={defaultAdditional}
                value={selectedCountry}
                loadOptions={loadCountryOptions}
                handleChange={(newValue: BaseModel) =>
                  setSelectedCountry(newValue)
                }
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                }}
                placeholder={defaultDropDownValue.label}
                isSearchable={true}
                displayLabel={I18n(
                  'ShippingOrders.ShippingPackage.CountryOfOrigin'
                )}
                errorValue={true}
                readOnly={false}
                debounceTimeout={500}
                isMultiSelect={false}
                cacheUniqs={cacheUniqCountry}
                onCreateOption={(inputValue: any) =>
                  onCreateOptionCountry(inputValue)
                }
              ></CustomSelectPaginateAdd>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="btn-wrap">
          <Button
            onClick={handleFormClose}
            color="neutral"
            disableElevation
            variant="contained"
          >
            {' '}
            {I18n('Common.Cancel')}
          </Button>
          <Button
            onClick={submit}
            autoFocus
            color="primary"
            disableElevation
            variant="contained"
          >
            {I18n('Common.Save')}
          </Button>
        </DialogActions>
        <TariffCustomDailog
          isOpen={isOpenHSN}
          handleClose={handleCloseForAddHSN}
          OnSave={handleCloseForAddHSN}
          title={I18n('Nav.AddTariff')}
        ></TariffCustomDailog>
      </Dialog>
    </div>
  );
}
