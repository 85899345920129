const languageReducer = (state: any = 'en', action: any) => {
  const type = localStorage.getItem('type');
  const payload = localStorage.getItem('payload');

  if (payload !== null) {
    action.type = type;
    action.payload = payload;
    state = payload;
  }

  switch (action.type) {
    case 'TRANSLATE_TO':
      return action.payload;
    default:
      return state;
  }
};

export default languageReducer;
