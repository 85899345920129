import {
  ApiResponse,
  BaseModel,
  CommonListRequestModel,
  EntityTypeEnum,
  EntityStatus,
  ApprovalStatus,
  MasterCategoryEnumType,
  PaginationResponseModel,
  BOMDropdownType,
  BillableMaterialTypeDescription,
  CategoryResponseModel,
  CategoryBaseModel,
  ShippingContactType,
  Department,
  CustomerCategorModel,
  CopyState,
} from 'models/pagination-model';
import { Customer } from 'models/service-catalogue';
import environment from 'environment';
import http from '../../utilities/httpService';
import { Location as EntityLocation } from 'models/location';
import { ContactPersonModel } from 'models/contactPersonModel';
import {
  ShippingCustomsDetailsModel,
  ShippingNotificationDetailsModel,
  ShippingOrderBaseDTO,
  ShippingOrderDTO,
  ShippingOrderListDTO,
  ShippingOrderStatus,
  ShippingOrdersStateModel,
  ShippingType,
} from 'models/shipping-orders';
import { toast } from 'react-toastify';
import I18n from 'utilities/i18n';
import { Permission } from 'Permissions';
import { hasPermission } from 'utilities/protectedRoute';

const itemCodeSpliter = ' - ';
let typeList: Array<BaseModel> = [];
const getShippingMaterialList = () => {
  BillableMaterialTypeDescription.forEach((value: string, key: number) => {
    typeList.push({
      value: key.toString(),
      label: I18n(value),
    } as BaseModel);
  });
};
getShippingMaterialList();

const getCustomers = async (search: string, pageNo: number, id: number) => {
  let response: PaginationResponseModel<Customer>;
  const apiUrl = new URL(environment.api.baseUrl + 'Customer');
  try {
    let searchExpression;
    if (id !== undefined && id) {
      searchExpression =
        'TaggedTo~=~1 OR TaggedTo~=~3 and Status~=~' +
        EntityStatus.Active +
        ' and ApprovalStatus~=~' +
        ApprovalStatus.Approved +
        ` OR id~=~` +
        id;
    } else {
      searchExpression =
        'TaggedTo~=~1 OR TaggedTo~=~3 and Status~=~' +
        EntityStatus.Active +
        ' and ApprovalStatus~=~' +
        ApprovalStatus.Approved;
    }

    searchExpression += search ? ` and name~like~${search}` : '';
    apiUrl.searchParams.set('$filter', searchExpression);
    apiUrl.searchParams.set('$orderby', 'name');
    apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
    apiUrl.searchParams.set('$top', '10');
    apiUrl.searchParams.set('$orderbydirection', 'desc');
    const result = await http.get(apiUrl.toString());
    if (result) {
      response = {
        TotalRecords: result.data.totalCount,
        Values: result.data.values,
      } as PaginationResponseModel<Customer>;
    } else {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<Customer>;
    }
  } catch (error) {
    response = {
      TotalRecords: 0,
      Values: [],
    } as PaginationResponseModel<Customer>;
  }
  return response;
};

export async function loadCustomerOptions(
  search: string,
  loadedOptions: any,
  { page }: any,
  id?: any
) {
  const response = await getCustomers(search, page, id);
  const hasMore = Math.ceil(response.TotalRecords / 10) > page;

  return {
    options: response.Values.map((x) => {
      return {
        value: x.id.toString(),
        label: x.name,
      } as BaseModel;
    }),
    hasMore,
    additional: {
      page: page + 1,
    },
  };
}

export async function loadCustomerCategoryOptions(
  search: string,
  loadedOptions: any,
  { page }: any,
  id?: any
) {
  const response = await getCustomers(search, page, id);
  const hasMore = Math.ceil(response.TotalRecords / 10) > page;

  return {
    options: response.Values.map((x: Customer) => {
      return {
        value: x.id.toString(),
        label: x.name,
        categoryId: x.categoryId,
        categoryName: x.categoryName,
      } as CustomerCategorModel;
    }),
    hasMore,
    additional: {
      page: page + 1,
    },
  };
}

export const getLocations = async (
  customerId: number,
  search: string,
  pageNo: number,
  checkIfCustomerPortal: boolean = false
) => {
  let response: PaginationResponseModel<EntityLocation>;
  const apiUrl = new URL(environment.api.baseUrl + 'Locations');
  try {
    let searchExpression = '';
    searchExpression += search
      ? `Address~like~${search} or Address2~like~${search} or Street~like~${search} or ZipCode~like~${search} or DestinationCompany~like~${search} or PickupCompany~like~${search} or City.name~like~${search} or Country.name~like~${search} and`
      : '';
    searchExpression += ` EntityType~=~${EntityTypeEnum.Customer} and EntityId~=~${customerId}`;

    apiUrl.searchParams.set('$filter', searchExpression);
    apiUrl.searchParams.set('$orderby', 'address');
    apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
    apiUrl.searchParams.set('$top', '10');
    apiUrl.searchParams.set('$orderbydirection', 'desc');
    apiUrl.searchParams.set(
      'checkIfCustomerPortal',
      checkIfCustomerPortal.toString()
    );
    const result = await http.get(apiUrl.toString());
    if (result) {
      response = {
        TotalRecords: result.data.totalCount,
        Values: result.data.values,
      } as PaginationResponseModel<EntityLocation>;
    } else {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<EntityLocation>;
    }
  } catch (error) {
    response = {
      TotalRecords: 0,
      Values: [],
    } as PaginationResponseModel<EntityLocation>;
  }
  return response;
};

export async function loadLocationOptions(
  customerId: number,
  search: string,
  loadedOptions: any,
  { page }: any
) {
  const response = await getLocations(customerId, search, page);
  const hasMore = Math.ceil(response.TotalRecords / 10) > page;
  return {
    options: response.Values.map((x) => {
      return {
        value: x.id.toString(),
        label: `${x.street ? `${x.street},` : ''}${
          x.address2 ? `${x.address2},` : ''
        }${x.zipCode ? `${x.zipCode},` : ''}${x.city},${x.country}`,
      } as BaseModel;
    }),
    hasMore,
    additional: {
      page: page + 1,
    },
  };
}

export async function loadDestinationCompanyOptions(
  customerId: number,
  search: string,
  loadedOptions: any,
  { page }: any
) {
  const response = await getLocations(customerId, search, page);
  const hasMore = Math.ceil(response.TotalRecords / 10) > page;
  return {
    options: response.Values.filter(
      (x) => x.destinationCompany && x.destinationCompany !== undefined
    ).map((x) => {
      return {
        value: x.id.toString(),
        label: `${x.destinationCompany}`,
      } as BaseModel;
    }),
    hasMore,
    additional: {
      page: page + 1,
    },
  };
}

export async function loadPickupCompanyOptions(
  customerId: number,
  search: string,
  loadedOptions: any,
  { page }: any,
  checkIfCustomerPortal: boolean = false
) {
  const response = await getLocations(
    customerId,
    search,
    page,
    checkIfCustomerPortal
  );
  const hasMore = Math.ceil(response.TotalRecords / 10) > page;
  return {
    options: response.Values.filter(
      (x) => x.pickupCompany && x.pickupCompany !== undefined
    ).map((x) => {
      return {
        value: x.id.toString(),
        label: `${x.pickupCompany}`,
      } as BaseModel;
    }),
    hasMore,
    additional: {
      page: page + 1,
    },
  };
}

const getContacts = async (
  customerId: number,
  search: string,
  pageNo: number
) => {
  let response: PaginationResponseModel<ContactPersonModel>;
  const apiUrl = new URL(environment.api.baseUrl + 'ContactPerson');
  try {
    let searchExpression =
      'EntityType~=~' +
      EntityTypeEnum.Customer +
      ' and EntityId~=~' +
      customerId +
      ' and Status~=~' +
      EntityStatus.Active;
    searchExpression += search
      ? ` and FirstName~like~${search} OR LastName~like~${search}`
      : '';
    apiUrl.searchParams.set('$filter', searchExpression);
    apiUrl.searchParams.set('$orderby', 'firstName');
    apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
    apiUrl.searchParams.set('$top', '10');
    apiUrl.searchParams.set('$orderbydirection', 'desc');
    const result = await http.get(apiUrl.toString());
    if (result) {
      response = {
        TotalRecords: result.data.totalCount,
        Values: result.data.values,
      } as PaginationResponseModel<ContactPersonModel>;
    } else {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<ContactPersonModel>;
    }
  } catch (error) {
    response = {
      TotalRecords: 0,
      Values: [],
    } as PaginationResponseModel<ContactPersonModel>;
  }
  return response;
};

export async function loadContactOptions(
  customerId: number,
  search: string,
  loadedOptions: any,
  { page }: any
) {
  const response = await getContacts(customerId, search, page);
  const hasMore = Math.ceil(response.TotalRecords / 10) > page;
  return {
    options: response.Values.map((x) => {
      return {
        value: x.id.toString(),
        label: `${x.firstName + ' ' + x.lastName}`,
      } as BaseModel;
    }),
    hasMore,
    additional: {
      page: page + 1,
    },
  };
}

const getRemainingContacts = async (
  customerId: number,
  search: string,
  pageNo: number,
  id: string,
  shippingContactType: ShippingContactType
) => {
  let response: PaginationResponseModel<ContactPersonModel>;
  const apiUrl = new URL(
    environment.api.baseUrl + 'Shipping/GetShippingOrderContact'
  );
  try {
    let searchExpression =
      'EntityType~=~' +
      EntityTypeEnum.Customer +
      ' and EntityId~=~' +
      customerId +
      ' and Status~=~' +
      EntityStatus.Active;
    searchExpression += search ? ` and FirstName~like~${search}` : '';
    apiUrl.searchParams.set('$filter', searchExpression);
    apiUrl.searchParams.set('$orderby', 'firstName');
    apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
    apiUrl.searchParams.set('$top', '10');
    apiUrl.searchParams.set('$orderbydirection', 'desc');
    apiUrl.searchParams.set('$id', id.toString());
    apiUrl.searchParams.set(
      '$shippingContactType',
      shippingContactType.toString()
    );

    const result = await http.get(apiUrl.toString());
    if (result) {
      response = {
        TotalRecords: result.data.totalCount,
        Values: result.data.values,
      } as PaginationResponseModel<ContactPersonModel>;
    } else {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<ContactPersonModel>;
    }
  } catch (error) {
    response = {
      TotalRecords: 0,
      Values: [],
    } as PaginationResponseModel<ContactPersonModel>;
  }
  return response;
};

export async function loadRemainingContactOptions(
  customerId: number,
  search: string,
  loadedOptions: any,
  { page }: any,
  id: string,
  shippingContactType: ShippingContactType
) {
  const response = await getRemainingContacts(
    customerId,
    search,
    page,
    id,
    shippingContactType
  );
  const hasMore = Math.ceil(response.TotalRecords / 10) > page;
  return {
    options: response.Values.map((x) => {
      return {
        value: x.id.toString(),
        label: `${x.firstName + ' ' + x.lastName}`,
      } as BaseModel;
    }),
    hasMore,
    additional: {
      page: page + 1,
    },
  };
}

const getCategories = async (
  categoryType: MasterCategoryEnumType,
  search: string,
  pageNo: number
) => {
  let response: PaginationResponseModel<CategoryResponseModel>;
  const apiUrl = new URL(
    environment.api.baseUrl + 'Dropdown/GetMasterCategories'
  );
  try {
    let searchExpression = `entityType~=~${categoryType}`;
    searchExpression += search ? ` and name~like~${search}` : '';
    apiUrl.searchParams.set('$filter', searchExpression);
    apiUrl.searchParams.set('$orderby', 'name');
    apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
    apiUrl.searchParams.set('$top', '10');
    apiUrl.searchParams.set('$orderbydirection', 'desc');
    const result = await http.get(apiUrl.toString());
    if (result) {
      response = {
        TotalRecords: result.data.totalCount,
        Values: result.data.values,
      } as PaginationResponseModel<CategoryResponseModel>;
    } else {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<CategoryResponseModel>;
    }
  } catch (error) {
    response = {
      TotalRecords: 0,
      Values: [],
    } as PaginationResponseModel<CategoryResponseModel>;
  }
  return response;
};

const getRejectionMaster = async (search: string, pageNo: number) => {
  let response: PaginationResponseModel<any>;
  const apiUrl = new URL(environment.api.baseUrl + 'RejectionMaster');
  try {
    let searchExpression = ``;
    searchExpression += search
      ? `code~like~${search} or description~like~${search}`
      : '';
    apiUrl.searchParams.set('$filter', searchExpression);
    apiUrl.searchParams.set('$orderby', 'code');
    apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
    apiUrl.searchParams.set('$top', '10');
    apiUrl.searchParams.set('$orderbydirection', 'desc');
    const result = await http.get(apiUrl.toString());
    if (result) {
      response = {
        TotalRecords: result.data.totalCount,
        Values: result.data.values,
      } as PaginationResponseModel<any>;
    } else {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<any>;
    }
  } catch (error) {
    response = {
      TotalRecords: 0,
      Values: [],
    } as PaginationResponseModel<any>;
  }
  return response;
};

export async function loadCategoryOptions(
  categoryType: MasterCategoryEnumType,
  search: string,
  loadedOptions: any,
  { page }: any
) {
  const response = await getCategories(categoryType, search, page);
  const hasMore = Math.ceil(response.TotalRecords / 10) > page;

  return {
    options: response.Values.map((x) => {
      return {
        value: x.id.toString(),
        label: x.name,
        code: x.code,
      } as CategoryBaseModel;
    }),
    hasMore,
    additional: {
      page: page + 1,
    },
  };
}

export async function loadRejectionMasterOptions(
  search: string,
  loadedOptions: any,
  { page }: any
) {
  const response = await getRejectionMaster(search, page);
  const hasMore = Math.ceil(response.TotalRecords / 10) > page;

  return {
    options: response.Values.map((x: any) => {
      return {
        value: x.id.toString(),
        label: x.code + ' - ' + x.description,
      } as BaseModel;
    }),
    hasMore,
    additional: {
      page: page + 1,
    },
  };
}

export async function saveShippingOrder(
  id: number,
  shippingOrderStateModel: ShippingOrdersStateModel,
  isEdit: boolean = true
) {
  const request = {
    categoryId:
      shippingOrderStateModel &&
      shippingOrderStateModel?.category &&
      shippingOrderStateModel?.category.value
        ? +shippingOrderStateModel.category.value
        : 0,
    customerId:
      shippingOrderStateModel &&
      shippingOrderStateModel?.customer &&
      shippingOrderStateModel?.customer.value
        ? +shippingOrderStateModel.customer.value
        : 0,
    customerLocationId:
      shippingOrderStateModel &&
      shippingOrderStateModel?.customerLocation &&
      shippingOrderStateModel?.customerLocation.value
        ? +shippingOrderStateModel.customerLocation.value
        : 0,
    orderReference: shippingOrderStateModel.orderReference,
    serviceId:
      shippingOrderStateModel &&
      shippingOrderStateModel?.service &&
      shippingOrderStateModel?.service.value
        ? +shippingOrderStateModel.service.value
        : 0,
    pickUpCustomerId:
      shippingOrderStateModel &&
      shippingOrderStateModel?.pickUpCustomer &&
      shippingOrderStateModel?.pickUpCustomer.value
        ? +shippingOrderStateModel.pickUpCustomer.value
        : 0,
    pickUpCustomerLocationId:
      shippingOrderStateModel &&
      shippingOrderStateModel?.pickUpCustomerLocation &&
      shippingOrderStateModel?.pickUpCustomerLocation.value
        ? +shippingOrderStateModel.pickUpCustomerLocation.value
        : 0,
    expectedPickupDateTime: shippingOrderStateModel.expectedPickupDateTime,
    deliveryCustomerId:
      shippingOrderStateModel &&
      shippingOrderStateModel?.deliveryCustomer &&
      shippingOrderStateModel?.deliveryCustomer.value
        ? +shippingOrderStateModel.deliveryCustomer.value
        : 0,
    deliveryLocationId:
      shippingOrderStateModel &&
      shippingOrderStateModel?.deliveryLocation &&
      shippingOrderStateModel?.deliveryLocation.value
        ? +shippingOrderStateModel.deliveryLocation.value
        : 0,
    expectedDeliveryDateTime: shippingOrderStateModel.expectedDeliveryDateTime,
    type: ShippingType.InOrder,
    status:
      shippingOrderStateModel &&
      shippingOrderStateModel?.status &&
      shippingOrderStateModel?.status.value
        ? +shippingOrderStateModel.status.value
        : 0,
    isSameAsCustomer: shippingOrderStateModel.isSameAsCustomer,
    selectedContacts: shippingOrderStateModel.selectedContacts,
    deliveryContacts: shippingOrderStateModel.deliveryContacts,
    pickupContacts: shippingOrderStateModel.pickupContacts,
    subCategory: shippingOrderStateModel.subCategory,
  } as ShippingOrderDTO;
  try {
    var contacts: any = [];
    request.selectedContacts.map((x: any) => {
      contacts.push({
        contactPersonId: Number(x.value),
        contactPersonName: x.label,
      });
    });
    request.shippingOrderContacts = contacts;

    var deliveryContacts: any = [];
    request.deliveryContacts.map((x: any) => {
      deliveryContacts.push({
        contactPersonId: Number(x.value),
        contactPersonName: x.label,
      });
    });
    request.deliveryContacts = deliveryContacts;

    var pickupContacts: any = [];
    request.pickupContacts.map((x: any) => {
      pickupContacts.push({
        contactPersonId: Number(x.value),
        contactPersonName: x.label,
      });
    });
    request.pickupContacts = pickupContacts;
    request.id = id;

    if (isEdit && id && +id) {
      const apiUrl = new URL(environment.api.baseUrl + 'Shipping/' + id);
      const result: any = await http.put(apiUrl.toString(), request);
      if (result) {
        return {
          result: result.data,
          data: null,
        } as ApiResponse<null>;
      } else {
        return {
          result: false,
          data: null,
        } as ApiResponse<null>;
      }
    } else {
      const apiUrl = new URL(environment.api.baseUrl + 'Shipping');
      const result: any = await http.post(apiUrl.toString(), request);
      if (result) {
        return {
          result: true,
          data: result.data as ShippingOrderDTO,
        } as ApiResponse<ShippingOrderDTO>;
      } else {
        return {
          result: false,
          data: null,
        } as ApiResponse<null>;
      }
    }
  } catch (error: any) {}
  return {
    result: false,
    data: null,
  } as ApiResponse<null>;
}

export const getProductCataloguesById = async (id: Number) => {
  let apiUrl: any;
  apiUrl = new URL(environment.api.baseUrl + 'ProductCatalogues/' + id);
  return await http.get(apiUrl.toString());
};

export const getTransportCatalogueById = async (id: Number) => {
  let apiUrl: any;
  apiUrl = new URL(environment.api.baseUrl + 'TransportCatalogues/' + id);
  return await http.get(apiUrl.toString());
};

const getItemCodes = async (
  search: string,
  pageNo: number,
  type: MasterCategoryEnumType,
  category: number,
  departmentType: Department | null
) => {
  let response: PaginationResponseModel<Customer>;
  let apiUrl: any;
  let searchExpression: any;
  if (
    +MasterCategoryEnumType[type] == MasterCategoryEnumType.ProductCatalogue
  ) {
    apiUrl = new URL(environment.api.baseUrl + 'ProductCatalogues');
    searchExpression = `productCatalogueCategoryId~=~${category} and status~=~1`;
    searchExpression += search ? ` and productCode~like~${search}` : '';
    apiUrl.searchParams.set('$orderby', 'productCode');
  }
  if (
    +MasterCategoryEnumType[type] === MasterCategoryEnumType.ServiceCatalogue
  ) {
    apiUrl = new URL(environment.api.baseUrl + 'ServiceCatalogues');
    searchExpression = `categoryId~=~${category} and statusId~=~1`;
    searchExpression += search ? ` and serviceCode~like~${search}` : '';
    apiUrl.searchParams.set('$orderby', 'serviceCode');
  }

  //TODO: Make change for Transport catalogue
  if (
    +MasterCategoryEnumType[type] === MasterCategoryEnumType.TransportCatalogue
  ) {
    apiUrl = new URL(environment.api.baseUrl + 'TransportCatalogues');
    searchExpression = `categoryId~=~${category} and statusId~=~1`;
    searchExpression += search ? ` and transportCode~like~${search}` : '';
    apiUrl.searchParams.set('$orderby', 'transportCode');
  }

  try {
    apiUrl.searchParams.set('$filter', searchExpression);

    apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
    apiUrl.searchParams.set('$top', '10');
    apiUrl.searchParams.set('$orderbydirection', 'desc');
    const result = await http.get(apiUrl.toString());
    if (result) {
      result.data.values.map((x: any) => {
        x.name =
          +MasterCategoryEnumType[type] ===
          MasterCategoryEnumType.ProductCatalogue
            ? x.productCode
            : +MasterCategoryEnumType[type] ===
              MasterCategoryEnumType.ServiceCatalogue
            ? x.serviceCode
            : x.transportCode;
      });
      response = {
        TotalRecords: result.data.totalCount,
        Values: result.data.values,
      } as PaginationResponseModel<Customer>;
    } else {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<Customer>;
    }
  } catch (error) {
    response = {
      TotalRecords: 0,
      Values: [],
    } as PaginationResponseModel<Customer>;
  }
  return response;
};
export async function loadItemCodes(
  search: string,
  loadedOptions: any,
  { page }: any,
  type: MasterCategoryEnumType,
  category: number,
  departmentType: Department | null
) {
  const response = await getItemCodes(
    search,
    page,
    type,
    category,
    departmentType
  );
  const hasMore = Math.ceil(response.TotalRecords / 10) > page;
  return {
    options: response.Values.map((x) => {
      return {
        value: x.id.toString(),
        label: x.name,
      } as BaseModel;
    }),
    hasMore,
    additional: {
      page: page + 1,
    },
  };
}

export async function loadItems(
  search: string,
  loadedOptions: any,
  { page }: any,
  type: BOMDropdownType,
  dataRow: any,
  isCategoryFilter: boolean,
  departmentType: Department | null
) {
  const response = await loadItemValues(
    search,
    page,
    dataRow,
    isCategoryFilter,
    departmentType
  );
  const hasMore = Math.ceil(response.totalCount / 10) > page;

  var result;
  switch (type) {
    case BOMDropdownType.Category:
      result = response.values.map((x: any) => {
        return {
          value: x.categoryId.toString(),
          label: x.categoryName,
        } as BaseModel;
      });
      break;
    case BOMDropdownType.Item:
      result = response.values.map((x: any) => {
        var typeEnity = typeList.find((t) => t.value == x.entityType);
        let optionLabel;
        if (x.entityType == MasterCategoryEnumType.TransportCatalogue) {
          optionLabel = `${x.code}${
            typeEnity ? itemCodeSpliter + typeEnity.label : ''
          }`;
        } else {
          optionLabel = `${x.name}${itemCodeSpliter}${x.code}${
            typeEnity ? itemCodeSpliter + typeEnity.label : ''
          }`;
        }

        return {
          value: x.id.toString(),
          label: optionLabel,
        } as BaseModel;
      });
      break;

    default:
      break;
  }

  return {
    options: result,
    hasMore,
    additional: {
      page: page + 1,
    },
  };
}

async function loadItemValues(
  search: string,
  page: any,
  dataRow: any,
  isCategoryFilter: boolean,
  departmentType: Department | null
) {
  const apiUrl = new URL(environment.api.baseUrl + `Dropdown/GetItemCodes`);
  try {
    apiUrl.searchParams.set('$filter', search);
    apiUrl.searchParams.set('$skip', ((page - 1) * 10).toString());
    apiUrl.searchParams.set('$top', '10');
    apiUrl.searchParams.set('$orderbydirection', 'desc');
    apiUrl.searchParams.set('isCategoryFilter', isCategoryFilter ? '1' : '0');
    apiUrl.searchParams.set(
      'departmentType',
      departmentType ? departmentType.toString() : Department.Both.toString()
    );
    // if (
    //   dataRow.materialTypeDescription &&
    //   dataRow.materialTypeDescription.value
    // ) {
    //   apiUrl.searchParams.set('type', dataRow.materialTypeDescription.value);
    // }

    // if (
    //   isCategoryFilter &&
    //   dataRow.categoryDescription &&
    //   dataRow.categoryDescription.value
    // ) {
    //   apiUrl.searchParams.set('categoryId', dataRow.categoryDescription.value);
    // }

    const result = await http.get(apiUrl.toString());
    if (result && result.data) {
      return result.data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
}

export async function loadItemValuesByid(
  itemCode: string,
  categoryId?: number
) {
  const apiUrl = new URL(environment.api.baseUrl + `Dropdown/GetItemCodesById`);
  try {
    apiUrl.searchParams.set(
      'categoryId',
      categoryId ? categoryId.toString() : ''
    );
    let items = itemCode.split(itemCodeSpliter);
    let item: any;
    if (items.length > 2) {
      var type = items[items.length - 1];
      if (type == 'Transport') {
        var codes = items.splice(0, items.length - 1);
        if (codes) {
          item = codes.join(itemCodeSpliter);
        }
      } else {
        item = items[items.length - 2];
      }
    } else if (items.length == 2) {
      item = items[0];
    } else {
      item = itemCode;
    }
    apiUrl.searchParams.set('itemCode', item);
    const result = await http.get(apiUrl.toString());
    if (result && result.data) {
      return result.data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
}

export async function getBasicDetailsById(id: number) {
  const apiUrl = new URL(environment.api.baseUrl + `Shipping/${id}`);
  try {
    const result = await http.get(apiUrl.toString());
    if (result && result.data) {
      // var contacts: any = [];
      // result.data.selectedContacts.map((x: any) => {
      //   contacts.push({
      //     value: Number(x.contactPersonId),
      //     label: x.contactPersonName,
      //   });
      // });
      // result.data.selectedContacts = contacts;
      return result.data as ShippingOrderDTO;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
}

export const CheckIsOfferConverted = async (id: number) => {
  let apiUrl = new URL(environment.api.baseUrl + 'ShippingOffers/' + id);

  try {
    const result = await http.get(apiUrl.toString());
    if (result && result.data.isShippingOfferConverted) {
      return true;
    }
  } catch (error) {
    return false;
  }
};

export async function deleteShippingOrder(id: number) {
  const apiUrl = new URL(environment.api.baseUrl + 'Shipping/' + id);
  try {
    const result: any = await http.delete(apiUrl.toString());
    if (result && result.data) {
      return {
        result: result.data,
        data: null,
      } as ApiResponse<null>;
    } else {
      return {
        result: false,
        data: null,
      } as ApiResponse<null>;
    }
  } catch (error: any) {
    return {
      result: false,
      data: null,
    } as ApiResponse<null>;
  }
}

export async function updateOrderStatus(id: number, status: string) {
  const apiUrl = new URL(
    environment.api.baseUrl + `Shipping/UpdateOrderStatus/${id}/${status}`
  );
  try {
    const result = await http.put(apiUrl.toString());
    if (result && result.data) {
      return result.data;
    }
    return false;
  } catch (error) {
    return false;
  }
}

export async function rejectShippingOffer(id: number, rejectionId: number) {
  const apiUrl = new URL(
    environment.api.baseUrl +
      'ShippingOffers/ShippingOfferReject/' +
      id +
      '/' +
      rejectionId
  );
  try {
    const result: any = await http.put(apiUrl.toString());
    if (result && result.data) {
      return {
        result: result.data,
        data: null,
      } as ApiResponse<null>;
    } else {
      return {
        result: false,
        data: null,
      } as ApiResponse<null>;
    }
  } catch (error: any) {
    return {
      result: false,
      data: null,
    } as ApiResponse<null>;
  }
}

export async function getShippingOrders(
  request: CommonListRequestModel<ShippingOrderListDTO>,
  categoryFilter: string = '',
  typeFilter: string = '',
  serviceFilter: string = '',
  statusFilter: string = ''
) {
  let basicSearchExpression = request.basicSearchExpression;
  let advanceSearchExpression = request.advanceSearchExpression;
  const apiUrl = new URL(environment.api.baseUrl + 'Shipping');
  try {
    let finalSearchTerm = '';
    if (basicSearchExpression && advanceSearchExpression) {
      finalSearchTerm = `${advanceSearchExpression} and ${basicSearchExpression}`;
    } else if (basicSearchExpression && !advanceSearchExpression) {
      finalSearchTerm = basicSearchExpression;
    } else if (!basicSearchExpression && advanceSearchExpression) {
      finalSearchTerm = advanceSearchExpression;
    }
    apiUrl.searchParams.set('$filter', finalSearchTerm);
    apiUrl.searchParams.set('$orderby', request.gridData.sortBy);
    apiUrl.searchParams.set(
      '$skip',
      (request.gridData.page * request.gridData.pageSize).toString()
    );
    apiUrl.searchParams.set('$top', request.gridData.pageSize.toString());
    apiUrl.searchParams.set(
      '$orderbydirection',
      request.gridData.sortOrder.toString()
    );
    apiUrl.searchParams.set(
      'categoryFilter',
      categoryFilter && categoryFilter?.toString()
    );
    apiUrl.searchParams.set('typeFilter', typeFilter);
    apiUrl.searchParams.set('serviceFilter', serviceFilter);
    apiUrl.searchParams.set('statusFilter', statusFilter);

    const result = await http.get(apiUrl.toString());
    if (result) {
      request.gridData.rows = result.data.values;
      request.gridData.totalRows = result.data.totalCount;
    }
    return request;
  } catch (error) {}
}

export async function getShippingOffers(
  request: CommonListRequestModel<ShippingOrderListDTO>,
  validityFilter: string = '',
  offerStatusFilter: string = '',
  rejectionFilter: string = '',
  statusFilter: string = '',
  categoryFilter: string = '',
  serviceFilter: string = '',
  priceRangeFilter: string = ''
) {
  let basicSearchExpression = request.basicSearchExpression;
  let advanceSearchExpression = request.advanceSearchExpression;
  const apiUrl = new URL(environment.api.baseUrl + 'ShippingOffers');
  try {
    let finalSearchTerm = '';
    if (basicSearchExpression && advanceSearchExpression) {
      finalSearchTerm = `${advanceSearchExpression} and ${basicSearchExpression}`;
    } else if (basicSearchExpression && !advanceSearchExpression) {
      finalSearchTerm = basicSearchExpression;
    } else if (!basicSearchExpression && advanceSearchExpression) {
      finalSearchTerm = advanceSearchExpression;
    }
    apiUrl.searchParams.set('$filter', finalSearchTerm);
    apiUrl.searchParams.set('$orderby', request.gridData.sortBy);
    apiUrl.searchParams.set(
      '$skip',
      (request.gridData.page * request.gridData.pageSize).toString()
    );
    apiUrl.searchParams.set('$top', request.gridData.pageSize.toString());
    apiUrl.searchParams.set(
      '$orderbydirection',
      request.gridData.sortOrder.toString()
    );
    apiUrl.searchParams.set(
      'validityFilter',
      validityFilter && validityFilter?.toString()
    );
    apiUrl.searchParams.set('offerStatusFilter', offerStatusFilter);
    apiUrl.searchParams.set('rejectionFilter', rejectionFilter);
    apiUrl.searchParams.set('statusFilter', statusFilter);
    apiUrl.searchParams.set('categoryFilter', categoryFilter);
    apiUrl.searchParams.set('serviceFilter', serviceFilter);
    apiUrl.searchParams.set('priceRangeFilter', priceRangeFilter);

    const result = await http.get(apiUrl.toString());
    if (result) {
      request.gridData.rows = result.data.values;
      request.gridData.totalRows = result.data.totalCount;
    }
    return request;
  } catch (error) {}
}

export async function ExportShippingOrders(
  request: CommonListRequestModel<ShippingOrderListDTO>,
  categoryFilter: string = '',
  typeFilter: string = '',
  serviceFilter: string = '',
  statusFilter: string = ''
) {
  let basicSearchExpression = request.basicSearchExpression;
  let advanceSearchExpression = request.advanceSearchExpression;
  const apiUrl = new URL(
    environment.api.baseUrl + 'Shipping/ExportShippingOrders'
  );
  try {
    let finalSearchTerm = '';
    if (basicSearchExpression && advanceSearchExpression) {
      finalSearchTerm = `${advanceSearchExpression} and ${basicSearchExpression}`;
    } else if (basicSearchExpression && !advanceSearchExpression) {
      finalSearchTerm = basicSearchExpression;
    } else if (!basicSearchExpression && advanceSearchExpression) {
      finalSearchTerm = advanceSearchExpression;
    }
    apiUrl.searchParams.set('$filter', finalSearchTerm);
    apiUrl.searchParams.set('$orderby', request.gridData.sortBy);
    apiUrl.searchParams.set(
      '$orderbydirection',
      request.gridData.sortOrder.toString()
    );
    apiUrl.searchParams.set(
      'categoryFilter',
      categoryFilter && categoryFilter?.toString()
    );
    apiUrl.searchParams.set('typeFilter', typeFilter);
    apiUrl.searchParams.set('serviceFilter', serviceFilter);
    apiUrl.searchParams.set('statusFilter', statusFilter);

    const result = await http.get(apiUrl.toString(), {
      responseType: 'blob',
    });

    return result;
  } catch (error) {}
}

export async function ExportAllShippingOrders(
  request: CommonListRequestModel<ShippingOrderListDTO>,
  typeFilter: string = '',
  activityOwnerFilter: string = '',
  statusFilter: string = ''
) {
  let basicSearchExpression = request.basicSearchExpression;
  let advanceSearchExpression = request.advanceSearchExpression;
  const apiUrl = new URL(
    environment.api.baseUrl + 'Shipping/GetExportShippingWorkOrderListing'
  );
  try {
    let finalSearchTerm = '';
    if (basicSearchExpression && advanceSearchExpression) {
      finalSearchTerm = `${advanceSearchExpression} and ${basicSearchExpression}`;
    } else if (basicSearchExpression && !advanceSearchExpression) {
      finalSearchTerm = basicSearchExpression;
    } else if (!basicSearchExpression && advanceSearchExpression) {
      finalSearchTerm = advanceSearchExpression;
    }
    apiUrl.searchParams.set('$filter', finalSearchTerm);
    apiUrl.searchParams.set('$orderby', request.gridData.sortBy);
    apiUrl.searchParams.set(
      '$orderbydirection',
      request.gridData.sortOrder.toString()
    );
    apiUrl.searchParams.set('typeFilter', typeFilter);
    apiUrl.searchParams.set('activityOwnerFilter', activityOwnerFilter);
    apiUrl.searchParams.set('statusFilter', statusFilter);

    const result = await http.get(apiUrl.toString(), {
      responseType: 'blob',
    });

    return result;
  } catch (error) {}
}

const getAssignTo = async (search: string, pageNo: number) => {
  let response: PaginationResponseModel<Customer>;
  const apiUrl = new URL(environment.api.baseUrl + 'Partners');
  try {
    let searchExpression = 'Status~=~' + EntityStatus.Active;
    searchExpression += search ? ` and name~like~${search}` : '';
    apiUrl.searchParams.set('$filter', searchExpression);
    apiUrl.searchParams.set('$orderby', 'name');
    apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
    apiUrl.searchParams.set('$top', '10');
    apiUrl.searchParams.set('$orderbydirection', 'desc');
    const result = await http.get(apiUrl.toString());
    if (result) {
      response = {
        TotalRecords: result.data.totalCount,
        Values: result.data.values,
      } as PaginationResponseModel<Customer>;
    } else {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<Customer>;
    }
  } catch (error) {
    response = {
      TotalRecords: 0,
      Values: [],
    } as PaginationResponseModel<Customer>;
  }
  return response;
};

export async function loadAssignedToOptions(
  search: string,
  loadedOptions: any,
  { page }: any
) {
  const response = await getAssignTo(search, page);
  const hasMore = Math.ceil(response.TotalRecords / 10) > page;

  return {
    options: response.Values.map((x) => {
      return {
        value: x.id.toString(),
        label: x.name,
      } as BaseModel;
    }),
    hasMore,
    additional: {
      page: page + 1,
    },
  };
}
export async function copyShippingOrder(id: number, copyState: CopyState) {
  const apiUrl = new URL(
    environment.api.baseUrl +
      'Shipping/CopyShippingOrder/' +
      id +
      '/' +
      copyState
  );
  try {
    const result: any = await http.get(apiUrl.toString());
    if (result && result.data && result.data.success) {
      return {
        result: result.data.success,
        data: result.data.message,
      } as ApiResponse<string>;
    } else {
      return {
        result: false,
        data: null,
      } as ApiResponse<null>;
    }
  } catch (error: any) {
    return {
      result: false,
      data: null,
    } as ApiResponse<null>;
  }
}

export async function getLocationById(id: number) {
  const apiUrl = new URL(environment.api.baseUrl + 'Locations/' + id);
  try {
    const result: any = await http.get(apiUrl.toString());
    if (result) {
      if (result && result.data) {
        return {
          result: true,
          data: result.data,
        } as ApiResponse<any>;
      } else {
        return {
          result: false,
          data: null,
        } as ApiResponse<any>;
      }
    }
  } catch (error: any) {
    return {
      result: false,
      data: null,
    } as ApiResponse<any>;
  }
}

export async function getAllShippingOrders(
  request: CommonListRequestModel<ShippingOrderListDTO>,
  typeFilter: string = '',
  activityOwnerFilter: string = '',
  statusFilter: string = ''
) {
  let basicSearchExpression = request.basicSearchExpression;
  let advanceSearchExpression = request.advanceSearchExpression;
  const apiUrl = new URL(
    environment.api.baseUrl + 'Shipping/GetAllShippingWorkOrderListing'
  );
  try {
    let finalSearchTerm = '';
    if (basicSearchExpression && advanceSearchExpression) {
      finalSearchTerm = `${advanceSearchExpression} and ${basicSearchExpression}`;
    } else if (basicSearchExpression && !advanceSearchExpression) {
      finalSearchTerm = basicSearchExpression;
    } else if (!basicSearchExpression && advanceSearchExpression) {
      finalSearchTerm = advanceSearchExpression;
    }
    apiUrl.searchParams.set('$filter', finalSearchTerm);
    apiUrl.searchParams.set('$orderby', request.gridData.sortBy);
    apiUrl.searchParams.set(
      '$skip',
      (request.gridData.page * request.gridData.pageSize).toString()
    );
    apiUrl.searchParams.set('$top', request.gridData.pageSize.toString());
    apiUrl.searchParams.set(
      '$orderbydirection',
      request.gridData.sortOrder.toString()
    );
    apiUrl.searchParams.set('typeFilter', typeFilter);
    apiUrl.searchParams.set('activityOwnerFilter', activityOwnerFilter);
    apiUrl.searchParams.set('statusFilter', statusFilter);

    const result = await http.get(apiUrl.toString());
    if (result) {
      request.gridData.rows = result.data.values;
      request.gridData.totalRows = result.data.totalCount;
    }
    return request;
  } catch (error) {}
}

export async function loadDeliveryPaymentOptions(
  search: string,
  loadedOptions: any,
  { page }: any
) {
  const response = await getDeliveryPayment(search, page);
  const hasMore = Math.ceil(response.TotalRecords / 10) > page;

  return {
    options: response.Values.map((x) => {
      return {
        value: x.id.toString(),
        label: x.name,
      } as BaseModel;
    }),
    hasMore,
    additional: {
      page: page + 1,
    },
  };
}

const getDeliveryPayment = async (search: string, pageNo: number) => {
  let response: PaginationResponseModel<Customer>;
  const apiUrl = new URL(environment.api.baseUrl + 'TermsOfDeliveryAndPayment');
  try {
    let searchExpression = search ? `name~like~${search}` : '';
    apiUrl.searchParams.set('$filter', searchExpression);
    apiUrl.searchParams.set('$orderby', 'name');
    apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
    apiUrl.searchParams.set('$top', '10');
    apiUrl.searchParams.set('$orderbydirection', 'desc');
    const result = await http.get(apiUrl.toString());
    if (result) {
      response = {
        TotalRecords: result.data.totalCount,
        Values: result.data.values,
      } as PaginationResponseModel<Customer>;
    } else {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<Customer>;
    }
  } catch (error) {
    response = {
      TotalRecords: 0,
      Values: [],
    } as PaginationResponseModel<Customer>;
  }
  return response;
};

export async function loadImporterOptions(
  search: string,
  loadedOptions: any,
  { page }: any
) {
  const response = await getImporter(search, page);
  const hasMore = Math.ceil(response.TotalRecords / 10) > page;

  return {
    options: response.Values.map((x) => {
      return {
        value: x.id.toString(),
        label: x.name,
      } as BaseModel;
    }),
    hasMore,
    additional: {
      page: page + 1,
    },
  };
}

const getImporter = async (search: string, pageNo: number) => {
  let response: PaginationResponseModel<Customer>;
  const apiUrl = new URL(environment.api.baseUrl + 'ImporterExporter');
  try {
    let searchExpression = search ? `name~like~${search}` : '';
    apiUrl.searchParams.set('$filter', searchExpression);
    apiUrl.searchParams.set('$orderby', 'name');
    apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
    apiUrl.searchParams.set('$top', '10');
    apiUrl.searchParams.set('$orderbydirection', 'desc');
    apiUrl.searchParams.set('$entitytype', '1');
    const result = await http.get(apiUrl.toString());

    if (result) {
      response = {
        TotalRecords: result.data.totalCount,
        Values: result.data.values,
      } as PaginationResponseModel<Customer>;
    } else {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<Customer>;
    }
  } catch (error) {
    response = {
      TotalRecords: 0,
      Values: [],
    } as PaginationResponseModel<Customer>;
  }
  return response;
};

export async function loadExporterOptions(
  search: string,
  loadedOptions: any,
  { page }: any
) {
  const response = await getExporter(search, page);
  const hasMore = Math.ceil(response.TotalRecords / 10) > page;

  return {
    options: response.Values.map((x) => {
      return {
        value: x.id.toString(),
        label: x.name,
      } as BaseModel;
    }),
    hasMore,
    additional: {
      page: page + 1,
    },
  };
}

const getExporter = async (search: string, pageNo: number) => {
  let response: PaginationResponseModel<Customer>;
  const apiUrl = new URL(environment.api.baseUrl + 'ImporterExporter');
  try {
    let searchExpression = search ? `name~like~${search}` : '';
    apiUrl.searchParams.set('$filter', searchExpression);
    apiUrl.searchParams.set('$orderby', 'name');
    apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
    apiUrl.searchParams.set('$top', '10');
    apiUrl.searchParams.set('$orderbydirection', 'desc');
    apiUrl.searchParams.set('$entitytype', '2');
    const result = await http.get(apiUrl.toString());
    if (result) {
      response = {
        TotalRecords: result.data.totalCount,
        Values: result.data.values,
      } as PaginationResponseModel<Customer>;
    } else {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<Customer>;
    }
  } catch (error) {
    response = {
      TotalRecords: 0,
      Values: [],
    } as PaginationResponseModel<Customer>;
  }
  return response;
};

export async function saveCustomsDetails(
  id: number,
  shippingCustomsDetailsModel: ShippingCustomsDetailsModel
) {
  const request = {
    importerId:
      shippingCustomsDetailsModel &&
      shippingCustomsDetailsModel?.importer &&
      shippingCustomsDetailsModel?.importer.value
        ? +shippingCustomsDetailsModel.importer.value
        : 0,
    exporterId:
      shippingCustomsDetailsModel &&
      shippingCustomsDetailsModel?.exporter &&
      shippingCustomsDetailsModel?.exporter.value
        ? +shippingCustomsDetailsModel.exporter.value
        : 0,
    deliveryPaymentId:
      shippingCustomsDetailsModel &&
      shippingCustomsDetailsModel?.deliveryPayment &&
      shippingCustomsDetailsModel?.deliveryPayment.value
        ? +shippingCustomsDetailsModel.deliveryPayment.value
        : 0,
  } as ShippingOrderDTO;
  try {
    request.id = id;
    if (id && +id) {
      const apiUrl = new URL(
        environment.api.baseUrl + 'Shipping/UpdateCustomsDetails'
      );
      const result: any = await http.put(apiUrl.toString(), request);
      if (result) {
        return {
          result: result.data,
          data: null,
        } as ApiResponse<null>;
      } else {
        return {
          result: false,
          data: null,
        } as ApiResponse<null>;
      }
    }
  } catch (error: any) {}
  return {
    result: false,
    data: null,
  } as ApiResponse<null>;
}

export const SaveDeliveryPayment: any = async (
  Datavalue: any,
  strSuccessMsg: any,
  strErrMsg: any
) => {
  const apiUrl = new URL(environment.api.baseUrl + 'TermsOfDeliveryAndPayment');
  try {
    const result: any = await http.post(apiUrl.toString(), Datavalue);
    if (result) {
      toast.success(strSuccessMsg);
      const newOption = {
        label: result.data.name,
        value: result.data.id,
      };
      return newOption;
    } else {
      toast.error(strErrMsg);
    }
  } catch (error: any) {}
};

const getTimeZone = () => {
  var offset = new Date().getTimezoneOffset(),
    o = Math.abs(offset);
  return (offset > 0 ? '-' : '') + o;
};

export const generateShippingDocuments = async (
  documentName: string,
  paramsId: string
) => {
  const apiUrl = new URL(
    environment.api.baseUrl +
      'DownloadShippingOrderDocuments/DownloadShippingDocuments/' +
      paramsId +
      '/' +
      documentName +
      '/' +
      getTimeZone()
  );

  try {
    const result = await http.get(apiUrl.toString(), {
      responseType: 'blob',
    });
    return result;
  } catch (error) {
    return null;
  }
};

export async function saveShippingOffer(
  id: number,
  shippingOrderStateModel: ShippingOrdersStateModel,
  isEdit: boolean = true
) {
  const request = {
    categoryId:
      shippingOrderStateModel &&
      shippingOrderStateModel?.category &&
      shippingOrderStateModel?.category.value
        ? +shippingOrderStateModel.category.value
        : 0,
    customerId:
      shippingOrderStateModel &&
      shippingOrderStateModel?.customer &&
      shippingOrderStateModel?.customer.value
        ? +shippingOrderStateModel.customer.value
        : 0,
    customerLocationId:
      shippingOrderStateModel &&
      shippingOrderStateModel?.customerLocation &&
      shippingOrderStateModel?.customerLocation.value
        ? +shippingOrderStateModel.customerLocation.value
        : 0,
    orderReference: shippingOrderStateModel.orderReference,
    serviceId:
      shippingOrderStateModel &&
      shippingOrderStateModel?.service &&
      shippingOrderStateModel?.service.value
        ? +shippingOrderStateModel.service.value
        : 0,
    pickUpCustomerId:
      shippingOrderStateModel &&
      shippingOrderStateModel?.pickUpCustomer &&
      shippingOrderStateModel?.pickUpCustomer.value
        ? +shippingOrderStateModel.pickUpCustomer.value
        : 0,
    pickUpCustomerLocationId:
      shippingOrderStateModel &&
      shippingOrderStateModel?.pickUpCustomerLocation &&
      shippingOrderStateModel?.pickUpCustomerLocation.value
        ? +shippingOrderStateModel.pickUpCustomerLocation.value
        : 0,
    expectedPickupDateTime: shippingOrderStateModel.expectedPickupDateTime,
    deliveryCustomerId:
      shippingOrderStateModel &&
      shippingOrderStateModel?.deliveryCustomer &&
      shippingOrderStateModel?.deliveryCustomer.value
        ? +shippingOrderStateModel.deliveryCustomer.value
        : 0,
    deliveryLocationId:
      shippingOrderStateModel &&
      shippingOrderStateModel?.deliveryLocation &&
      shippingOrderStateModel?.deliveryLocation.value
        ? +shippingOrderStateModel.deliveryLocation.value
        : 0,
    expectedDeliveryDateTime: shippingOrderStateModel.expectedDeliveryDateTime,
    type:
      shippingOrderStateModel &&
      shippingOrderStateModel?.type &&
      shippingOrderStateModel?.type.value
        ? +shippingOrderStateModel.type.value
        : 0,
    status:
      shippingOrderStateModel &&
      shippingOrderStateModel?.status &&
      shippingOrderStateModel?.status.value
        ? +shippingOrderStateModel.status.value
        : 0,
    isSameAsCustomer: shippingOrderStateModel.isSameAsCustomer,
    selectedContacts: shippingOrderStateModel.selectedContacts,
    deliveryContacts: shippingOrderStateModel.deliveryContacts,
    pickupContacts: shippingOrderStateModel.pickupContacts,
    subCategory: shippingOrderStateModel.subCategory,
  } as ShippingOrderDTO;
  try {
    var contacts: any = [];
    request.selectedContacts.map((x: any) => {
      contacts.push({
        contactPersonId: Number(x.value),
        contactPersonName: x.label,
      });
    });
    request.shippingOrderContacts = contacts;

    var deliveryContacts: any = [];
    request.deliveryContacts.map((x: any) => {
      deliveryContacts.push({
        contactPersonId: Number(x.value),
        contactPersonName: x.label,
      });
    });
    request.deliveryContacts = deliveryContacts;

    var pickupContacts: any = [];
    request.pickupContacts.map((x: any) => {
      pickupContacts.push({
        contactPersonId: Number(x.value),
        contactPersonName: x.label,
      });
    });
    request.pickupContacts = pickupContacts;
    request.id = id;

    if (isEdit && id && +id) {
      const apiUrl = new URL(environment.api.baseUrl + 'ShippingOffers/' + id);
      const result: any = await http.put(apiUrl.toString(), request);
      if (result) {
        return {
          result: result.data,
          data: null,
        } as ApiResponse<null>;
      } else {
        return {
          result: false,
          data: null,
        } as ApiResponse<null>;
      }
    } else {
      const apiUrl = new URL(environment.api.baseUrl + 'ShippingOffers');
      const result: any = await http.post(apiUrl.toString(), request);
      if (result) {
        return {
          result: true,
          data: result.data as ShippingOrderDTO,
        } as ApiResponse<ShippingOrderDTO>;
      } else {
        return {
          result: false,
          data: null,
        } as ApiResponse<null>;
      }
    }
  } catch (error: any) {}
  return {
    result: false,
    data: null,
  } as ApiResponse<null>;
}

export const getContactByUserId = async (userId: number) => {
  const apiUrl = new URL(
    environment.api.baseUrl + 'ContactPerson/GetByUserId/' + userId
  );
  try {
    const result = await http.get(apiUrl.toString());
    if (result) {
      if (result && result.data) {
        return {
          result: true,
          data: result.data,
        } as ApiResponse<any>;
      } else {
        return {
          result: false,
          data: null,
        } as ApiResponse<any>;
      }
    }
  } catch (error) {
    return {
      result: false,
      data: null,
    } as ApiResponse<any>;
  }
};

export const getCustomerDetailById = async (id: number) => {
  const apiUrl = new URL(environment.api.baseUrl + 'Customer/' + id);
  try {
    const result: any = await http.get(apiUrl.toString());
    if (result) {
      if (result && result.data) {
        return {
          result: true,
          data: result.data,
        } as ApiResponse<any>;
      } else {
        return {
          result: false,
          data: null,
        } as ApiResponse<any>;
      }
    }
  } catch (error: any) {
    return {
      result: false,
      data: null,
    } as ApiResponse<any>;
  }
};

export const CheckOrderEnable = (status: ShippingOrderStatus) => {
  let isEditable = true;
  if (
    (status == ShippingOrderStatus.SentForApproval &&
      !hasPermission(Permission.canApproveShippingOrders)) ||
    status == ShippingOrderStatus.InvoiceApproved ||
    status == ShippingOrderStatus.Closed ||
    status == ShippingOrderStatus.Cancelled
  ) {
    isEditable = false;
  }

  return isEditable;
};

export async function saveNotificationDetails(
  id: number,
  shippingNotificationDetailsModel: ShippingNotificationDetailsModel
) {
  const request = {
    notificationEmail: shippingNotificationDetailsModel.notificationEmail,
  } as ShippingOrderDTO;
  try {
    request.id = id;
    if (id && +id) {
      const apiUrl = new URL(
        environment.api.baseUrl + 'Shipping/UpdateNotificationDetails'
      );
      const result: any = await http.put(apiUrl.toString(), request);
      if (result) {
        return {
          result: result.data,
          data: null,
        } as ApiResponse<null>;
      } else {
        return {
          result: false,
          data: null,
        } as ApiResponse<null>;
      }
    }
  } catch (error: any) {}
  return {
    result: false,
    data: null,
  } as ApiResponse<null>;
}

export async function sendOrderCreateEmail(id: number) {
  const request = {
    id,
  } as ShippingOrderDTO;
  try {
    if (id && +id) {
      const apiUrl = new URL(
        environment.api.baseUrl + 'Shipping/SendOrderCreatedEmail'
      );
      const result: any = await http.post(apiUrl.toString(), request);
      if (result) {
        return {
          result: result.data,
          data: null,
        } as ApiResponse<null>;
      } else {
        return {
          result: false,
          data: null,
        } as ApiResponse<null>;
      }
    }
  } catch (error: any) {}
  return {
    result: false,
    data: null,
  } as ApiResponse<null>;
}

export async function updateTransportCatalogue(id: number) {
  const request = {
    id,
  } as ShippingOrderDTO;
  try {
    if (id && +id) {
      const apiUrl = new URL(
        environment.api.baseUrl +
          'ShippingOffers/UpdateTransportCatalogue/' +
          id
      );
      const result: any = await http.post(apiUrl.toString(), request);
      if (result) {
        return {
          result: result.data,
          data: null,
        } as ApiResponse<null>;
      } else {
        return {
          result: false,
          data: null,
        } as ApiResponse<null>;
      }
    }
  } catch (error: any) {}
  return {
    result: false,
    data: null,
  } as ApiResponse<null>;
}

export async function ConvertShippingOffer(orderId: number) {
  const apiUrl = new URL(
    environment.api.baseUrl + 'ShippingOffers/ShippingOfferConvert/' + orderId
  );
  try {
    const result: any = await http.put(apiUrl.toString());
    if (result && result.data) {
      return {
        result: result.data,
        data: null,
      } as ApiResponse<null>;
    } else {
      return {
        result: false,
        data: null,
      } as ApiResponse<null>;
    }
  } catch (error: any) {
    return {
      result: false,
      data: null,
    } as ApiResponse<null>;
  }
}

export async function loadProducts(
  search: string,
  loadedOptions: any,
  { page }: any
) {
  const response = await loadProductItemValues(search, page);
  const hasMore = Math.ceil(response.totalCount / 10) > page;

  var result = response.values.map((x: any) => {
    return {
      value: x.id.toString(),
      label: x.name,
    } as BaseModel;
  });

  return {
    options: result,
    hasMore,
    additional: {
      page: page + 1,
    },
  };
}

async function loadProductItemValues(search: string, page: any) {
  const apiUrl = new URL(environment.api.baseUrl + `ProductCatalogues`);
  try {
    let searchExpression = `departmentType~=~${Department.Shipping} or departmentType~=~${Department.Both}`;
    searchExpression += search ? ` and name~like~${search}` : '';

    apiUrl.searchParams.set('$filter', searchExpression);
    apiUrl.searchParams.set('$skip', ((page - 1) * 10).toString());
    apiUrl.searchParams.set('$top', '10');
    apiUrl.searchParams.set('$orderbydirection', 'desc');

    const result = await http.get(apiUrl.toString());
    if (result && result.data) {
      return result.data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
}

export async function loadSpecialPriceProducts(
  search: string,
  loadedOptions: any,
  { page }: any
) {
  const response = await loadSpecialPriceProductItemValues(search, page);
  const hasMore = Math.ceil(response.totalCount / 10) > page;

  var result = response.values.map((x: any) => {
    return {
      value: x.id.toString(),
      label: x.name,
    } as BaseModel;
  });

  return {
    options: result,
    hasMore,
    additional: {
      page: page + 1,
    },
  };
}

async function loadSpecialPriceProductItemValues(search: string, page: any) {
  const apiUrl = new URL(environment.api.baseUrl + `ProductCatalogues`);
  try {
    apiUrl.searchParams.set(
      '$filter',
      'pricingType~=~2 and name~like~' + search
    );
    apiUrl.searchParams.set('$skip', ((page - 1) * 10).toString());
    apiUrl.searchParams.set('$top', '10');
    apiUrl.searchParams.set('$orderbydirection', 'desc');

    const result = await http.get(apiUrl.toString());
    if (result && result.data) {
      return result.data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
}

export async function loadSpecialPricingServices(
  search: string,
  loadedOptions: any,
  { page }: any
) {
  const response = await loadSpecialPricingServiceItemValues(search, page);
  const hasMore = Math.ceil(response.totalCount / 10) > page;

  var result = response.values.map((x: any) => {
    return {
      value: x.id.toString(),
      label: x.serviceName,
    } as BaseModel;
  });

  return {
    options: result,
    hasMore,
    additional: {
      page: page + 1,
    },
  };
}

async function loadSpecialPricingServiceItemValues(search: string, page: any) {
  const apiUrl = new URL(environment.api.baseUrl + `ServiceCatalogues`);
  try {
    apiUrl.searchParams.set(
      '$filter',
      'pricingType~=~2 and serviceName~like~' + search
    );
    apiUrl.searchParams.set('$skip', ((page - 1) * 10).toString());
    apiUrl.searchParams.set('$top', '10');
    apiUrl.searchParams.set('$orderbydirection', 'desc');

    const result = await http.get(apiUrl.toString());
    if (result && result.data) {
      return result.data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
}

export async function loadSpecialPricingTransportCatalogues(
  search: string,
  loadedOptions: any,
  { page }: any
) {
  const response = await loadSpecialPricingTransportItemValues(search, page);
  const hasMore = Math.ceil(response.totalCount / 10) > page;

  var result = response.values.map((x: any) => {
    return {
      value: x.id.toString(),
      label: x.transportCode,
    } as BaseModel;
  });

  return {
    options: result,
    hasMore,
    additional: {
      page: page + 1,
    },
  };
}

async function loadSpecialPricingTransportItemValues(
  search: string,
  page: any
) {
  const apiUrl = new URL(environment.api.baseUrl + `TransportCatalogues`);
  try {
    apiUrl.searchParams.set('$filter', 'transportCode~like~' + search);
    apiUrl.searchParams.set('$skip', ((page - 1) * 10).toString());
    apiUrl.searchParams.set('$top', '10');
    apiUrl.searchParams.set('$orderbydirection', 'desc');

    const result = await http.get(apiUrl.toString());
    if (result && result.data) {
      return result.data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
}
