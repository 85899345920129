import { useCallback } from 'react';
import { useEffect, useState } from 'react';
import RouteEnum from 'models/RouteEnum';
import I18n from 'utilities/i18n';
import environment from 'environment';
import http from '../../../utilities/httpService';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { WorkOrderStatus, ActivityOwner } from 'models/pagination-model';
import DragDropTable from 'common/DragNDropTable/DragDropTable';
import { DataType } from 'ka-table/enums';
import { hasPermission } from 'utilities/protectedRoute';
import { Permission } from 'Permissions';

function WorkOrderList(props: any) {
  const { id } = useParams();
  const { isOrderEnable } = props;
  const navigate = useNavigate();
  const [gridData, setGridData] = useState([]);
  const columns: any = [
    { key: 'drag', style: { width: 1 }, isEditable: false, title: '' },
    {
      key: 'wONo',
      title: I18n('ShippingOrders.WorkOrder.WONo'),
      dataType: DataType.String,
      visible: false,
    },
    {
      key: 'workOrderId',
      title: I18n('ShippingOrders.WorkOrder.WONo'),
      dataType: DataType.String,
      visible: false,
    },
    {
      key: 'workOrderNo',
      title: I18n('ShippingOrders.WorkOrder.WONo'),
      dataType: DataType.String,
      isEditable: false,
    },
    {
      key: 'type',
      title: I18n('ShippingOrders.WorkOrder.Type'),
      dataType: DataType.String,
      isEditable: false,
    },
    {
      key: 'activityOwner',
      title: I18n('ShippingOrders.WorkOrder.ActivityOwner'),
      dataType: DataType.String,
      isEditable: false,
    },
    {
      key: 'expectedDateTime',
      title: I18n('ShippingOrders.WorkOrder.ExpectedDateTime'),
      dataType: DataType.String,
      isEditable: false,
    },
    {
      key: 'actualDateTime',
      title: I18n('ShippingOrders.WorkOrder.ActualDateTime'),
      dataType: DataType.String,
      isEditable: false,
    },
    {
      key: 'assignedToName',
      title: I18n('ShippingOrders.WorkOrder.AssignedTo'),
      dataType: DataType.String,
      isEditable: false,
    },
    // {
    //   key: 'updatedDate',
    //   title: I18n('ShippingOrders.WorkOrder.LastUpdateOn'),
    //   dataType: DataType.String,
    //   isEditable: false,
    // },
    {
      key: 'status',
      title: I18n('ShippingOrders.WorkOrder.Status'),
      dataType: DataType.String,
      isEditable: false,
    },
    {
      key: 'executionNotRequired',
      title: I18n('ShippingOrders.WorkOrder.ExecutionNotRequired'),
      width: '10%',
      dataType: DataType.Boolean,
    },
    {
      key: 'actions',
      title: I18n('Common.Actions'),
      dataType: DataType.String,
    },
  ];

  const onClick = (data: any) => {
    data.wONo = Number(data.wONo);
    if (data.wONo === 1) {
      navigate(
        RouteEnum.ViewShippingOrders + '/' + id + '/Booking/' + data.workOrderId
      );
    }
    if (data.wONo === 2) {
      navigate(RouteEnum.ViewShippingOrders + '/' + id + '/DGD/' + data.wONo);
    }
    if (data.wONo === 3) {
      navigate(
        RouteEnum.ViewShippingOrders +
          '/' +
          id +
          '/pickup/' +
          data.wONo +
          '/' +
          data.workOrderId
      );
    }
    if (data.wONo === 6) {
      navigate(
        RouteEnum.ViewShippingOrders +
          '/' +
          id +
          '/outbound/' +
          data.wONo +
          '/' +
          data.workOrderId
      );
    }
    if (data.wONo === 4) {
      navigate(
        RouteEnum.ViewShippingOrders +
          '/' +
          id +
          '/inbound/' +
          data.wONo +
          '/' +
          data.workOrderId
      );
    }
    if (data.wONo === 5) {
      navigate(
        RouteEnum.ViewShippingOrders +
          '/' +
          id +
          '/Packaging/' +
          data.wONo +
          '/' +
          data.workOrderId
      );
    }
    if (data.wONo === 7) {
      navigate(
        RouteEnum.ViewShippingOrders +
          '/' +
          id +
          '/Delivery/' +
          data.wONo +
          '/' +
          data.workOrderId
      );
    }
    if (data.wONo === 8) {
      navigate(
        RouteEnum.ViewShippingOrders +
          '/' +
          id +
          '/document-check/' +
          data.wONo +
          '/' +
          data.workOrderId
      );
    }
    if (data.wONo === 9) {
      navigate(
        RouteEnum.ViewShippingOrders +
          '/' +
          id +
          '/physical-check/' +
          data.wONo +
          '/' +
          data.workOrderId
      );
    }
  };

  const i18nMessages = {
    BackOffice: I18n('ShippingOrders.WorkOrder.BackOffice'),
    Driver: I18n('ShippingOrders.WorkOrder.Driver'),
    Partner: I18n('ShippingOrders.WorkOrder.Partner'),
    DecNPack: I18n('ShippingOrders.WorkOrder.DecNPack'),
    WareHouse: I18n('DecPack.WareHouse'),
    ToDo: I18n('Common.ToDo'),
    InProgress: I18n('Common.InProgress'),
    Done: I18n('Common.Done'),
  };

  const updateSequence = async (action: any) => {
    const apiUrl = new URL(
      environment.api.baseUrl +
        'Shipping/WorkOrderSequence/' +
        id +
        '/' +
        action.rowKeyValue +
        '/' +
        action.targetRowKeyValue
    );
    try {
      const result = await http.put(apiUrl.toString());
      if (result) {
        var data = getData(result.data);
        setGridData(data);
      }
    } catch (error) {}
  };

  const getData = (data: any) => {
    var temp: any = [];
    data.forEach((element: any) => {
      temp.push({
        id: element.sequenceNo,
        workOrderId: element.id,
        wONo: element.workOrderNo,
        workOrderNo: 'WO' + element.workOrderNo,
        type: element.type,
        workOrderDate: element.workOrderDate
          ? moment(element.workOrderDate).format('DD/MM/YYYY HH:mm')
          : '',
        expectedDateTime: element.expectedDateTime
          ? moment(element.expectedDateTime).format('DD/MM/YYYY HH:mm')
          : '',
        actualDateTime: element.actualDateTime
          ? moment(element.actualDateTime).format('DD/MM/YYYY HH:mm')
          : '',
        activityOwner:
          element.activityOwner === ActivityOwner.BackOffice
            ? i18nMessages.BackOffice
            : element.activityOwner === ActivityOwner.Driver
            ? i18nMessages.Driver
            : element.activityOwner === ActivityOwner.Partner
            ? i18nMessages.Partner
            : element.activityOwner === ActivityOwner.DecNPack
            ? i18nMessages.DecNPack
            : element.activityOwner === ActivityOwner.WareHouseStaff
            ? i18nMessages.WareHouse
            : element.workOrderDate
            ? '-'
            : '',
        assignedToName: element.assignedToName,
        updatedDate: moment(element?.updatedDate).format('DD/MM/YYYY HH:mm'),
        status:
          element.status === WorkOrderStatus.ToDo
            ? i18nMessages.ToDo
            : element.status === WorkOrderStatus.InProgress
            ? i18nMessages.InProgress
            : i18nMessages.Done,
        executionNotRequired: element.executionNotRequired,
      });
    });

    return temp;
  };

  const getWorkOrder = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl + 'Shipping/GetShippingWO/' + id
    );
    try {
      apiUrl.searchParams.set('$top', '10');
      const result = await http.get(apiUrl.toString());
      if (result) {
        var data = getData(result.data);
        setGridData(data);
      }
    } catch (error) {}
  };

  const handleLabelClick = async (row: any) => {
    const apiUrl = new URL(
      environment.api.baseUrl +
        'Shipping/WOExecution/' +
        id +
        '/' +
        row.wONo +
        '/' +
        Number(!row.executionNotRequired)
    );

    const result = await http.put(apiUrl.toString());
    if (result) {
      var data = getData(result.data);
      setGridData(data);
    }
  };

  useEffect(() => {
    getWorkOrder();
  }, []);

  return (
    <div className="main-content-section">
      <div className="content-block table-fix">
        <DragDropTable
          columns={columns}
          data={gridData}
          updateSequence={updateSequence}
          onClick={onClick}
          handleLabelClick={handleLabelClick}
          rowReordering={
            (hasPermission(Permission.canAddShippingOrders) ||
              hasPermission(Permission.canEditShippingOrders)) &&
            isOrderEnable
          }
          isOrderEnable={isOrderEnable}
        />
      </div>
    </div>
  );
}

export default WorkOrderList;
