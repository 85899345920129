import FormControl from '@mui/material/FormControl';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';

export const TimePickerInput = (props: any) => {
  const {
    displayLabel,
    handleChange,
    value,
    readOnly,
    errorValue,
    errorMessage,
    required,
  } = props;
  return (
    <FormControl
      variant="standard"
      fullWidth
      sx={{ mb: 2 }}
      error={errorMessage}
    >
      <InputLabel shrink htmlFor="bootstrap-input">
        {displayLabel}
        <span> {required ? '*' : ''}</span>
      </InputLabel>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <TimePicker
          format="HH:mm"
          value={value ? moment(value) : ''}
          onChange={handleChange}
          disabled={readOnly}
        />
      </LocalizationProvider>
      {errorValue ? <FormHelperText>{errorMessage}</FormHelperText> : ''}
    </FormControl>
  );
};
export default TimePickerInput;
