import { useCallback, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import I18n from 'utilities/i18n';
import FormControl from 'common/formControl/formControl';
import { Grid } from '@mui/material';
import CustomizedSelect from 'common/CustomSelect/CustomSelect';
import {
  BaseModel,
  ShippingMaterialTypeDescription,
  PaginationResponseModel,
  MasterCategoryEnumType,
  BillableMaterialTypeDescription,
  Category,
  Department,
} from 'models/pagination-model';
import environment from 'environment';
import { toast } from 'react-toastify';
import http from '../../utilities/httpService';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CustomSelectPaginate from 'common/CustomSelect/CustomSelectPaginate';
import NumericInput from 'common/NumericInput/NumericInput';
import {
  loadCategoryOptions,
  loadItemCodes,
  getTransportCatalogueById,
} from './ShippingApiService';
import DecimalInput from 'common/DecimalInput/DecimalInput';
export default function AddshippingMaterial(props: any) {
  const {
    isOpen,
    handleClose,
    title,
    shippingOrderId,
    editData,
    isAddShippingMaterial,
  } = props;
  const defaultAdditional: any = {
    page: 1,
  };
  const defaultValue = {
    value: '',
    label: I18n('Placeholders.Select'),
  } as BaseModel;
  const [zoneHeader, setZoneHeader] = useState('');
  const [tranpostTypes, setTransportTypes] = useState(defaultValue);

  const [errors, setErrors] = useState({
    type: '',
    category: '',
    itemCode: '',
    quantity: '',
    transportType: '',
  });
  const i18nMessages = {
    fieldRequired: I18n('ContactPerson.FieldRequired'),
    invalidEmailAddress: I18n('ContactPerson.InvalidEmailAddress'),
    errorOccurred: I18n('ContactPerson.ErrorOccurred'),
    savedSuccessfully: I18n('ContactPerson.SavedSuccessfully'),
    productCatalogue: I18n('ShippingMaterials.ProductCatalogue'),
    serviceCatalogue: I18n('ShippingMaterials.ServiceCatalogue'),
    transportCatalogue: I18n('ShippingMaterials.TransportCatalogue'),
    productQuantityNotAvailable: I18n(
      'ShippingMaterials.ProductQuantityNotAvailable'
    ),
    save: I18n('Notes.Save'),
    cancel: I18n('Notes.Cancel'),
    type: I18n('ShippingMaterials.Type'),
    category: I18n('ShippingMaterials.Category'),
    itemCode: I18n('ShippingMaterials.ItemCode'),
    quantity: I18n('ProductCatalogues.Assignments.Quantity'),
    sellingprice: I18n('ShippingMaterials.SellingPrice'),
    note: I18n('ShippingMaterials.Note'),
    air: I18n('Partner.CategoryTab.Air'),
    road: I18n('Partner.CategoryTab.Road'),
    train: I18n('Partner.CategoryTab.Train'),
    ship: I18n('Partner.CategoryTab.Ship'),
    other: I18n('Partner.CategoryTab.Other'),
    transportType: I18n('TransportCatalogue.TransportType'),
    zeroNotAllowed: I18n('ShippingMaterials.ZeroNotAllowed'),
  };

  const validate = (fieldValues: any = values) => {
    let temp = { ...errors };
    if ('quantity' in fieldValues) {
      temp.quantity =
        fieldValues.quantity === ''
          ? i18nMessages.fieldRequired
          : +fieldValues.quantity === 0
          ? i18nMessages.zeroNotAllowed
          : '';
    }
    if ('type' in fieldValues) {
      temp.type =
        fieldValues.type.value === '' ? i18nMessages.fieldRequired : '';
    }
    if ('category' in fieldValues) {
      temp.category =
        fieldValues.category.value === '' ? i18nMessages.fieldRequired : '';
    }
    if ('itemCode' in fieldValues) {
      temp.itemCode =
        fieldValues.itemCode.value === '' ? i18nMessages.fieldRequired : '';
    }

    if ('transportType' in fieldValues) {
      temp.transportType =
        Number(fieldValues.type.value) ===
          MasterCategoryEnumType.TransportCatalogue &&
        fieldValues.transportType.value === ''
          ? i18nMessages.fieldRequired
          : '';
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === '');
    }
  };

  const [values, setValues] = useState({
    type: defaultValue,
    category: defaultValue,
    itemCode: defaultValue,
    quantity: '',
    sellingPrice: 0,
    note: '',
    transportType: defaultValue,
  });

  let typeList: Array<BaseModel> = [];
  const getShippingMaterialList = () => {
    typeList = [defaultValue];
    if (isAddShippingMaterial) {
      ShippingMaterialTypeDescription.forEach((value: string, key: number) => {
        typeList.push({
          value: key.toString(),
          label: I18n(value),
        } as BaseModel);
      });
    } else {
      BillableMaterialTypeDescription.forEach((value: string, key: number) => {
        typeList.push({
          value: key.toString(),
          label: I18n(value),
        } as BaseModel);
      });
    }
  };

  getShippingMaterialList();

  const submit = async () => {
    if (validate()) {
      const shippingMaterialDTO = {
        Id: editData == null ? 0 : editData.id,
        ShippingOrderId: +shippingOrderId,
        MaterialType: +values.type.value,
        CategoryId: +values.category.value,
        Quantity: +values.quantity,
        SellingPrice: values.sellingPrice.toString().replace(/,/g, '.'),
        Note: values.note,
        EntityId: +values.itemCode.value,
        IsManual: true,
        ShippingPackageId: null,
        TransportType:
          +values.type.value === MasterCategoryEnumType.TransportCatalogue
            ? +values.transportType.value
            : null,
      };

      const apiUrl =
        editData === null
          ? new URL(environment.api.baseUrl + 'ShippingMaterial')
          : new URL(
              environment.api.baseUrl + 'ShippingMaterial/' + editData.row.id
            );
      try {
        const result: any =
          editData === null
            ? await http.post(apiUrl.toString(), shippingMaterialDTO)
            : await http.put(apiUrl.toString(), shippingMaterialDTO);

        if (
          editData == null &&
          result !== null &&
          result.data != null &&
          result.data.success
        ) {
          showMessageRefresh();
        } else if (editData != null && result !== null && result.data) {
          showMessageRefresh();
        } else {
          toast.error(i18nMessages.errorOccurred);
        }
      } catch (error: any) {
        toast.error(i18nMessages.productQuantityNotAvailable);
      }
    }
  };

  const showMessageRefresh = () => {
    toast.success(i18nMessages.savedSuccessfully);
    setDefaultValuesForControls();
    props.refreshData();
  };

  const setDefaultValuesForControls = () => {
    setValues({
      type: defaultValue,
      transportType: defaultValue,
      category: defaultValue,
      itemCode: defaultValue,
      quantity: '',
      sellingPrice: 0,
      note: '',
    });
  };

  const close = () => {
    setDefaultValuesForControls();
    props.handleClose();
  };

  const setTypeValue = (id: string) => {
    return {
      value: id,
      label:
        +id == MasterCategoryEnumType.ProductCatalogue
          ? i18nMessages.productCatalogue
          : +id == MasterCategoryEnumType.ServiceCatalogue
          ? i18nMessages.serviceCatalogue
          : i18nMessages.transportCatalogue,
    } as BaseModel;
  };

  const setTransportType = (id: string) => {
    return {
      value: id,
      label:
        +id == Category.Air
          ? i18nMessages.air
          : +id == Category.Other
          ? i18nMessages.other
          : +id == Category.Road
          ? i18nMessages.road
          : +id == Category.Ship
          ? i18nMessages.ship
          : +id == Category.Train
          ? i18nMessages.train
          : '',
    } as BaseModel;
  };

  const setItemCode = async (newValue: any) => {
    setValues({
      ...values,
      itemCode: newValue,
    });
    var result = await getTransportCatalogueById(newValue.value);

    if (result) {
      setZoneHeader(
        '(' + result.data.fromZoneName + '-' + result.data.toZoneName + ')'
      );

      var temp: any = [];
      result.data.transportTypes.forEach((element: any) => {
        temp.push({
          value: element.id,
          label: element.name,
        });
      });
      setTransportTypes(temp);
    }
  };

  useEffect(() => {
    if (editData) {
      setValues({
        type: setTypeValue(editData.row.materialType.toString()),
        transportType:
          editData.row.transportType &&
          setTransportType(editData.row.transportType.toString()),
        category: {
          value: editData.row.categoryId,
          label: editData.row.categoryDescription,
        },
        itemCode: {
          value: editData.row.entityId,
          label: editData.row.itemCode,
        },
        quantity: editData.row.quantity,
        sellingPrice: editData.row.sellingPrice.toString().replaceAll('.', ','),
        note: editData.row.note,
      });
    }
  }, [editData]);
  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={'sm'}
        fullWidth={true}
      >
        <DialogTitle>
          {title}
          <IconButton
            aria-label="close"
            onClick={close}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className="grid-wrap two-col">
            <Grid item lg={12}>
              <CustomizedSelect
                placeholder={defaultValue.label}
                options={typeList}
                isSearchable={false}
                displayLabel={i18nMessages.type}
                handleChange={(newValue: BaseModel) => {
                  setValues({
                    ...values,
                    type: newValue,
                    category: defaultValue,
                    itemCode: defaultValue,
                  });
                }}
                errorValue={true}
                errorMessage={errors.type}
                value={values.type}
                readOnly={editData != null}
              ></CustomizedSelect>
            </Grid>
            <Grid item lg={12}>
              <CustomSelectPaginate
                key={values.type.value}
                additional={defaultAdditional}
                value={values.category}
                loadOptions={(search: string, prevOptions: any, page: any) =>
                  loadCategoryOptions(
                    MasterCategoryEnumType[values.type.value],
                    search,
                    prevOptions,
                    page
                  )
                }
                handleChange={(newValue: BaseModel) => {
                  setValues({
                    ...values,
                    category: newValue,
                    itemCode: defaultValue,
                  });
                }}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                }}
                placeholder={defaultValue.label}
                isSearchable={true}
                displayLabel={i18nMessages.category}
                errorValue={true}
                errorMessage={errors.category}
                debounceTimeout={500}
                isMultiSelect={false}
                readOnly={editData != null}
              />
            </Grid>

            <Grid item lg={12}>
              <CustomSelectPaginate
                key={values.category.value}
                additional={defaultAdditional}
                value={values.itemCode}
                loadOptions={(search: string, prevOptions: any, page: any) =>
                  loadItemCodes(
                    search,
                    prevOptions,
                    page,
                    MasterCategoryEnumType[values.type.value],
                    +values.category.value,
                    Department.Shipping
                  )
                }
                handleChange={(newValue: BaseModel) => {
                  setItemCode(newValue);
                }}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                }}
                placeholder={defaultValue.label}
                isSearchable={true}
                displayLabel={
                  Number(values.type.value) ===
                  MasterCategoryEnumType.TransportCatalogue
                    ? I18n('ShippingMaterials.ItemCode') + ' ' + zoneHeader
                    : I18n('ShippingMaterials.ItemCode')
                }
                errorValue={true}
                errorMessage={errors.itemCode}
                debounceTimeout={500}
                isMultiSelect={false}
                readOnly={editData != null}
              />
            </Grid>
            {Number(values.type.value) ===
            MasterCategoryEnumType.TransportCatalogue ? (
              <Grid item lg={12}>
                <CustomizedSelect
                  placeholder={defaultValue.label}
                  options={tranpostTypes}
                  isSearchable={false}
                  displayLabel={i18nMessages.transportType}
                  handleChange={(newValue: BaseModel) => {
                    setValues({
                      ...values,
                      transportType: newValue,
                    });
                  }}
                  errorValue={true}
                  errorMessage={errors.transportType}
                  value={values.transportType}
                  readOnly={editData != null}
                ></CustomizedSelect>
              </Grid>
            ) : (
              <></>
            )}
            <Grid item lg={12}>
              <NumericInput
                displayLabel={i18nMessages.quantity}
                inputProps={{ maxLength: 10 }}
                name="quantity"
                handleChange={(event: any) => {
                  setValues({
                    ...values,
                    quantity: event.target.value,
                  });
                }}
                errorValue={true}
                errorMessage={errors.quantity}
                value={values.quantity}
                allowNegative={false}
                readOnly={false}
              ></NumericInput>
            </Grid>

            {editData != null && !isAddShippingMaterial && (
              <Grid item lg={12}>
                <DecimalInput
                  displayLabel={i18nMessages.sellingprice}
                  inputProps={{ maxLength: 10 }}
                  name="sellingPrice"
                  handleChange={(event: any) =>
                    setValues({ ...values, sellingPrice: event.target.value })
                  }
                  value={values.sellingPrice}
                ></DecimalInput>

                {/* <FormControl
                  margin="normal"
                  displayLabel={i18nMessages.sellingprice}
                  id="sellingPrice"
                  name="sellingPrice"
                  inputType="text"
                  inputProps={{ maxLength: 50 }}
                  multiline={true}
                  handleChange={(event: any) =>
                    setValues({ ...values, sellingPrice: event.target.value })
                  }
                  value={values.sellingPrice}
                ></FormControl> */}
              </Grid>
            )}

            <Grid item lg={12}>
              <FormControl
                margin="normal"
                displayLabel={i18nMessages.note}
                id="note"
                name="note"
                inputType="text"
                inputProps={{ maxLength: 50 }}
                multiline={true}
                handleChange={(event: any) =>
                  setValues({ ...values, note: event.target.value })
                }
                value={values.note}
              ></FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="btn-wrap">
          <Button
            onClick={close}
            color="neutral"
            disableElevation
            variant="contained"
          >
            {' '}
            {i18nMessages.cancel}
          </Button>
          <Button
            onClick={submit}
            autoFocus
            color="primary"
            disableElevation
            variant="contained"
          >
            {i18nMessages.save}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
