import React, { useState } from 'react';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Popover from '@mui/material/Popover';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from 'moment';
import { DateRangePicker } from 'react-date-range';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

export const CustomDateRangePicker = (props: any) => {
  const { displayLabel, fromDate, toDate } = props;
  const dateFormat = 'DD/MM/YYYY';
  const timeData = moment.utc().format('hh:mm:ss');
  const [dateRangeState, setDateRangeState] = useState({
    displayCalendar: false,
    inputValue:
      moment(new Date(fromDate)).format(dateFormat) +
      ' - ' +
      moment(new Date(toDate.split('T')[0] + 'T' + timeData + 'Z')).format(
        dateFormat
      ),
    anchorEl: null,
    fromDate: new Date(fromDate),
    toDate: new Date(toDate.split('T')[0] + 'T' + timeData + 'Z'),
    key: 'selection',
  });

  const onAdornmentClick = (e: any) => {
    setDateRangeState({
      ...dateRangeState,
      displayCalendar: true,
      anchorEl: e.currentTarget,
    });
    props.handleChange(dateRangeState.fromDate, dateRangeState.toDate);
  };

  const onInputChange = (e: any) => {
    const inputValue = e.target.value;
    const { fromDate, toDate } = processInputValue(inputValue);
    setDateRangeState({ ...dateRangeState, inputValue, fromDate, toDate });
    props.handleChange(fromDate, toDate);
  };

  const onPopoverClose = (e: any, reason: any) => {
    setDateRangeState({
      ...dateRangeState,
      displayCalendar: false,
      anchorEl: null,
    });
    props.handleChange(dateRangeState.fromDate, dateRangeState.toDate);
  };

  const onSelectDateRanges = ({ selection }: any) => {
    let { startDate, endDate } = selection;

    startDate = moment(startDate);
    startDate = startDate.isValid() ? startDate.toDate() : undefined;

    endDate = moment(endDate);
    endDate = endDate.isValid() ? endDate.toDate() : undefined;

    let inputValue = '';
    if (startDate) {
      inputValue += moment(startDate).format(dateFormat);
    }
    if (endDate) {
      inputValue += ' - ' + moment(endDate).format(dateFormat);
    }

    setDateRangeState({
      ...dateRangeState,
      fromDate: startDate,
      toDate: endDate,
      inputValue,
    });
    props.handleChange(startDate, endDate);
  };

  const processInputValue = (value: any) => {
    let [fromDate, toDate] = value.split('-').map((elm: any) => elm.trim());

    fromDate = moment(fromDate, dateFormat);
    fromDate = fromDate.isValid() ? fromDate.toDate() : undefined;

    toDate = moment(toDate, dateFormat);
    toDate = toDate.isValid() ? toDate.toDate() : undefined;

    return { fromDate, toDate };
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div>
        <FormControl variant="standard" fullWidth sx={{ mb: 2 }}>
          <InputLabel shrink htmlFor="bootstrap-input">
            {displayLabel}
          </InputLabel>
          <InputBase
            fullWidth={true}
            value={dateRangeState.inputValue}
            className="datepicker-wrap"
            // endAdornment={
            //   <InputAdornment position="end">
            //     <CalendarMonthIcon onClick={onAdornmentClick} />
            //   </InputAdornment>
            // }
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={onAdornmentClick}>
                  <CalendarMonthIcon />
                </IconButton>
              </InputAdornment>
            }
            onChange={onInputChange}
          />
        </FormControl>
        <Popover
          open={dateRangeState.displayCalendar}
          anchorEl={dateRangeState.anchorEl}
          elevation={1}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={onPopoverClose}
        >
          <div>
            <DateRangePicker
              ranges={[
                {
                  startDate: dateRangeState.fromDate,
                  endDate: dateRangeState.toDate,
                  key: 'selection',
                },
              ]}
              onChange={onSelectDateRanges}
              staticRanges={undefined}
              inputRanges={undefined}
              maxDate={new Date()}
              showMonthAndYearPickers={true}
              moveRangeOnFirstSelection={false}
              showDateDisplay={false}
              scroll={{ enabled: true }}
            />
          </div>
        </Popover>
      </div>
    </LocalizationProvider>
  );
};
export default CustomDateRangePicker;
