import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import http from '../../../utilities/httpService';
import environment from 'environment';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import I18n from 'utilities/i18n';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ActivityOwner,
  BaseModel,
  DocumentEntity,
  EntityTypeEnum,
  ImageUploadViewModel,
  OrderType,
  ViewLevel,
} from 'models/pagination-model';
import {
  getDeckPackCollectingShipmentDataImage,
  getWorkOrderImages,
  saveShipmentDataImages,
  saveWorkOrderImages,
} from 'common/ImageUploadPreview/WorkOrderImagesApiService';
import ApiUrlConstants from 'constants/api.constants';
import DgdBackgroundImage from '../../../assets/images/WorkOrder/dgd-background.png';
import DocumentList from 'common/Document/DocumentList';
import { Permission } from 'Permissions';
import { hasPermission } from 'utilities/protectedRoute';
import FormAccordianSave from 'common/formControl/formAccordianSave';
import CustomImageEditor from 'common/CustomImageEditor/CustomImageEditor';
import ImageUploadPreview from 'common/ImageUploadPreview/ImageUploadPreview';
import {
  DecPackWOCollectionShipmentDataImagesDTO,
  DnPWorkOrders,
} from 'models/deck-pack-orders';
import FormControl from 'common/formControl/formControl';

const WO3DetailsModal = (props: any) => {
  const { woId, id } = useParams();
  const { isOpen, handleClose, wo3Id } = props;

  const i18nMessages = {
    Document: I18n('WOCollectionData.Document'),
    ShipmentData: I18n('WOCollectionData.ShipmentData'),
    noImagesSelected: I18n('Common.NoImagesSelectedToUpload'),
    Photos: I18n('WOCollectionData.Photos'),
    NoShippingDocsAvailable: I18n(
      'DecPackOrders.DecPackQuestions.WOExecution.NoDocsAvailable'
    ),
    Shipment: I18n('WOCollectionData.Shipment'),
    ShipmentDataUpdateFail: I18n('WOCollectionData.ShipmentDataUpdateFail'),
    MoveToWarehouse: I18n('WOCollectionData.MoveToWarehouse'),
    SpecialInstructions: I18n('DecPack.SpecialInstructions'),
  };

  const [existingImageDataUrl, setExistingImageDataUrl] =
    useState<string>(DgdBackgroundImage);
  const [getImageDataUrl, setImageDataUrl] = useState<boolean>(false);
  const [images, setImages] = useState<ImageUploadViewModel[]>([]);
  const [isNew, setIsNew] = useState<boolean>(true);
  const [wOCollectionDataId, setWOCollectionDataId] = useState<number>();
  const [specialInstruction, setSpecialInstruction] = useState('');
  const [values, setValues] = useState<any>({
    decPackWorkOrderId: 0,
    decPackInitialOrderId: 0,
    isMoveToWarehouse: false,
    images: [] as any,
    signatures: [] as any,
    status: 0,
  });

  const fileDataFunction = (data: any) => {
    setImages([...images, ...data]);
  };

  const deleteFileFunction = (index: number) => {
    const localImages: any[] = [...images];
    localImages.splice(index, 1);
    setImages(localImages);
  };

  const editedFileDataFunction = (updatedImages: ImageUploadViewModel[]) => {
    setImages([...updatedImages]);
  };

  const close = () => {
    props.handleClose();
  };

  const onMoveToWarehouseCheckBoxChange = (event: any) => {
    setValues({ ...values, isMoveToWarehouse: event.target.checked });
  };

  const getPictures = () => {
    getWorkOrderImages(
      EntityTypeEnum.DecPackOrders,
      wo3Id ? +wo3Id : 0,
      id ? +id : 0,
      DocumentEntity.DecPackCollectionImages
    ).then((x) => {
      if (x.result) {
        /* Object conversion from backend viewModel to ImagePreview component Object */
        setImages(
          x.data.map((y) => {
            return {
              documentTag: {
                label: y.documentTagName,
                value: y.documentTag?.toString(),
              } as BaseModel,
              error: '',
              id: y.id,
              previewUrl:
                environment.api.baseUrl +
                ApiUrlConstants.DownloadImageUrl +
                y.imageUrl,
              path: y.imageUrl,
            } as ImageUploadViewModel;
          })
        );
      }
    });
  };

  const getWOCollectionData = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl + 'DecPackWOCollectionData/' + wo3Id + '/' + id
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result) {
        //var status = await getWOStatus();
        setIsNew(false);
        setWOCollectionDataId(result.data.id);

        var modifiedSigns = [] as any;
        result.data.signatureUrls.map((element: any) => {
          modifiedSigns.push(element);
        });

        getPictures();

        setValues({
          ...values,
          decPackWorkOrderId: result.data.decPackWorkOrderId,
          decPackInitialOrderId: result.data.decPackInitialOrderId,
          isMoveToWarehouse: result.data.isMoveToWarehouse,
          signatures: modifiedSigns,
        });
      }
    } catch (error) {
      setIsNew(true);
    }
  };

  const getDecPackById = async () => {
    const apiUrl = new URL(environment.api.baseUrl + `DecPackOrder/${id}`);
    try {
      const result = await http.get(apiUrl.toString());
      if (result && result.data) {
        setSpecialInstruction(result.data.specialInstructions);
      }
    } catch (error) {}
  };

  const getDeckPackCollectingShipmentDataImageApi = async () => {
    const result = await getDeckPackCollectingShipmentDataImage(
      wo3Id ? +wo3Id : 0,
      id ? +id : 0,
      DocumentEntity.DecPackCollectionShipmentData
    );
    if (result && result.data) {
      setExistingImageDataUrl(
        environment.api.baseUrl +
          ApiUrlConstants.DownloadImageUrl +
          result.data.imageUrl
      );
    }
  };

  const DocumentData = () => {
    return (
      <Grid xl={12} md={12}>
        <DocumentList
          permission={Permission.canEditDecPackOrders}
          addPermission={Permission.canAddDecPackOrders}
          deletePermission={Permission.canDeleteDecPackOrders}
          entityType={EntityTypeEnum.DecPackOrders}
          paramsId={id}
          isHeaderHide={true}
          documentEntityType={DocumentEntity.DecPackCollectionData}
          isViewOnly={true}
          viewLevel={ViewLevel.InternalOperational}
        />
      </Grid>
    );
  };

  const ShipmentData = () => {
    return (
      <>
        <Grid
          item
          lg={12}
          md={12}
          sx={{
            height: 'auto',
          }}
        >
          {
            <img
              src={existingImageDataUrl}
              alt={i18nMessages.noImagesSelected}
              style={{
                maxWidth: '100%',
                height: 'auto',
                width: '100%', // Optional, to ensure the image takes up the full width of its container
              }}
            />
          }
        </Grid>
      </>
    );
  };

  const PhotosSection = () => {
    return (
      <>
        <Grid
          sx={{ mb: 2 }}
          container
          spacing={1}
          className="grid-wrap two-col mb-2"
        >
          <Grid item lg={12}>
            <ImageUploadPreview
              readOnly={true}
              disableKeyboardNav={true}
              limit={10}
              fileData={fileDataFunction}
              fileRemove={deleteFileFunction}
              editedFileData={editedFileDataFunction}
              images={images}
            />
          </Grid>
        </Grid>
        {!(images && images.length) && (
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item lg={12}>
              {i18nMessages.NoShippingDocsAvailable}
            </Grid>
          </Grid>
        )}
      </>
    );
  };

  const Shipment = () => {
    return (
      <>
        <Grid item lg={12} md={12}>
          <FormControlLabel
            labelPlacement="end"
            control={
              <Checkbox
                name="MoveToWarehouse"
                checked={values.isMoveToWarehouse}
                onChange={onMoveToWarehouseCheckBoxChange}
                inputProps={{ 'aria-label': 'controlled' }}
                disabled={true}
              />
            }
            label={i18nMessages.MoveToWarehouse}
          />
        </Grid>
      </>
    );
  };

  const InstructionsSection = () => {
    return (
      <>
        <Grid item lg={12} md={12}>
          <FormControl
            margin="normal"
            id="SpecialInstructions"
            name="SpecialInstructions"
            inputType="text"
            inputProps={{ maxLength: 30 }}
            multiline={false}
            readOnly={true}
            value={specialInstruction}
          ></FormControl>
        </Grid>
      </>
    );
  };

  useEffect(() => {
    if (isOpen) {
      getWOCollectionData();
      getDeckPackCollectingShipmentDataImageApi();
      getDecPackById();
    }
  }, [isOpen]);

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={(_, reason) => {
          if (reason !== 'backdropClick') {
            close();
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={'lg'}
        fullWidth={true}
      >
        <DialogTitle>
          {I18n('DecPackOrders.WorkOrder.WO3Details')}
          <IconButton
            aria-label="close"
            onClick={close}
            sx={{
              position: 'absolute',
              right: 0,
              top: 0,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className="grid-wrap two-col">
            <Grid item xs={12}>
              <FormAccordianSave
                title={i18nMessages.Document}
                className="inner-heading"
                details={DocumentData()}
              ></FormAccordianSave>
              <FormAccordianSave
                title={i18nMessages.ShipmentData}
                className="inner-heading"
                details={ShipmentData()}
              ></FormAccordianSave>
              <FormAccordianSave
                title={i18nMessages.Photos}
                className="inner-heading"
                details={PhotosSection()}
              ></FormAccordianSave>
              <FormAccordianSave
                title={i18nMessages.Shipment}
                className="inner-heading"
                details={Shipment()}
              ></FormAccordianSave>
              <FormAccordianSave
                title={i18nMessages.SpecialInstructions}
                className="inner-heading"
                details={InstructionsSection()}
              ></FormAccordianSave>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="btn-wrap">
          <Button
            onClick={close}
            color="neutral"
            disableElevation
            variant="contained"
          >
            {I18n('Common.Cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default WO3DetailsModal;
