import I18n from 'utilities/i18n';
import { useEffect, useCallback, useState } from 'react';
import http from '../../utilities/httpService';
import { CommonProps, OrderByDirection } from 'models/pagination-model';
import { hasPermission } from 'utilities/protectedRoute';
import { Permission } from 'Permissions';
import { Button, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faPlus } from '@fortawesome/free-solid-svg-icons';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InputAdornment from '@mui/material/InputAdornment';
import CustomizedInputs from 'common/formControl/formControl';
import CustomDataGrid from 'common/datagrid/datagrid';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import {
  GridActionsCellItem,
  GridColumns,
  GridPaginationInitialState,
  GridRowParams,
  GridSortingInitialState,
  GridSortModel,
} from '@mui/x-data-grid';
import environment from 'environment';
import ConfirmationPopup from 'common/confirmationPopup/confirmationPopup';
import { toast } from 'react-toastify';
import AddProductPurchase from './AddProductPurchase';

function ProductPurchaseList(props: CommonProps) {
  const { entityId } = useParams();

  const [isDeleteModalOpen, setDeleteModal] = useState(false);
  const [isAddModalOpen, setAddModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [selectedId, setId] = useState();

  const [gridData, setGridData] = useState({
    sortOrder: OrderByDirection.Descending,
    sortBy: 'date',
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [10, 20, 50, 100],
    pageSize: 10,
    page: 0,
    searchExpression: '',
  });

  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));

  const getProductPurchases = async () => {
    const apiUrl = new URL(environment.api.baseUrl + 'ProductPurchases');
    try {
      apiUrl.searchParams.set(
        '$filter',
        gridData.searchExpression !== ''
          ? `ProductCatalogueId~=~${entityId} and Supplier~like~${gridData.searchExpression}`
          : `ProductCatalogueId~=~${entityId}`
      );
      apiUrl.searchParams.set('$orderby', gridData.sortBy);
      apiUrl.searchParams.set(
        '$skip',
        (gridData.page * gridData.pageSize).toString()
      );
      apiUrl.searchParams.set('$top', gridData.pageSize.toString());
      apiUrl.searchParams.set(
        '$orderbydirection',
        gridData.sortOrder.toString()
      );
      const result = await http.get(apiUrl.toString());
      if (result) {
        updateGridData('totalRows', result.data.totalCount);
        updateGridData('rows', result.data.values);
      }
    } catch (error) {}
  };

  const i18nMessages = {
    editedSuccessfully: I18n(
      'ProductCatalogues.ProductPurchases.EditedSuccessfully'
    ),
    deletedSuccessfully: I18n(
      'ProductCatalogues.ProductPurchases.DeletedSuccessfully'
    ),
    invalidQuantityDelete: I18n('Common.InvalidQuantityDelete'),
    errorOccurred: I18n('ProductCatalogues.ProductPurchases.ErrorOccurred'),
  };

  useEffect(() => {
    getProductPurchases();
  }, [
    gridData.page,
    gridData.pageSize,
    gridData.sortBy,
    gridData.sortOrder,
    gridData.searchExpression,
  ]);

  const handleChangePage = (pageNo: number) => {
    updateGridData('page', pageNo);
  };

  const handleChangeBasicSearch = (searchExpression: any) => {
    updateGridData('searchExpression', searchExpression.target.value);
  };

  const handleChangePageSize = (pageSize: number) => {
    updateGridData('pageSize', pageSize);
  };

  type Row = (typeof gridData.rows)[number];
  const deleteProductPurchase = useCallback(
    (id: any) => () => {
      setDeleteModal(true);
      setId(id);
    },
    []
  );

  const addProductPurchase = useCallback(() => {
    setEditData(null);
    setAddModal(true);
  }, []);

  const editProductPurchase = useCallback(
    (data: any) => () => {
      setEditData(data);
      setAddModal(true);
    },
    []
  );

  const refreshData = async () => {
    handleCloseForAdd();
    await getProductPurchases();
  };

  const columns: GridColumns<Row> = [
    {
      field: 'date',
      headerName: I18n('ProductCatalogues.ProductPurchases.Date'),
      minWidth: 150,
      valueFormatter: (params) => moment(params?.value).format('DD/MM/YYYY'),
    },
    {
      field: 'quantity',
      headerName: I18n('ProductCatalogues.ProductPurchases.Quantity'),
      minWidth: 150,
    },
    {
      field: 'supplier',
      headerName: I18n('ProductCatalogues.ProductPurchases.Supplier'),
      flex: 1,
    },
    {
      field: 'pricePerUnit',
      headerName: I18n('ProductCatalogues.ProductPurchases.PricePerUnit'),
      minWidth: 150,
      renderCell: (params: any) => {
        return params.value.toString().replaceAll('.', ',');
      },
    },
    {
      field: 'remarks',
      headerName: I18n('ProductCatalogues.ProductPurchases.Remarks'),
      flex: 1,
    },
    {
      field: 'createdBy',
      headerName: I18n('ProductCatalogues.ProductPurchases.CreatedBy'),
      flex: 1,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: I18n('Common.Actions'),
      width: 150,
      getActions: (params: GridRowParams) => {
        const actionButtons = [];
        actionButtons.push(
          <>
            {params.row.isManual &&
            hasPermission(Permission.canEditProductCatalogueManagement) ? (
              <GridActionsCellItem
                onPointerEnterCapture={false}
                onPointerLeaveCapture={false}
                placeholder={''}
                icon={<EditIcon />}
                label={I18n('Common.Edit')}
                onClick={editProductPurchase(params.row)}
              />
            ) : (
              <></>
            )}
            {params.row.isManual &&
            hasPermission(Permission.canDeleteProductCatalogueManagement) ? (
              <GridActionsCellItem
                onPointerEnterCapture={false}
                onPointerLeaveCapture={false}
                placeholder={''}
                icon={<DeleteIcon />}
                label="Delete"
                onClick={deleteProductPurchase(params.id)}
              />
            ) : (
              <></>
            )}
          </>
        );
        return actionButtons;
      },
    },
  ];

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    if (sortModel && sortModel.length) {
      updateGridData('sortBy', sortModel[0].field);
      updateGridData(
        'sortOrder',
        sortModel[0].sort === 'asc'
          ? OrderByDirection.Ascending
          : OrderByDirection.Descending
      );
    }
  }, []);

  const handleCloseForDelete = () => {
    setDeleteModal(false);
  };

  const handleOkForDelete = () => {
    setDeleteModal(false);
    deleteData(selectedId);
  };

  const handleCloseForAdd = () => {
    setAddModal(false);
  };

  const deleteData = async (id: any) => {
    const apiUrl = new URL(environment.api.baseUrl + 'ProductPurchases/' + id);
    try {
      const result: any = await http.delete(apiUrl.toString());
      if (result != null && result.data != null && result.data.success) {
        toast.success(i18nMessages.deletedSuccessfully);
        getProductPurchases();
      } else {
        toast.error(i18nMessages.errorOccurred);
      }
    } catch (error: any) {
      if (error.response) {
        if (error.response.data) {
          for (const item of error.response.data.errors) {
            if (
              item.field === 'Quantity' &&
              item.errorMessage === 'ValidQuantity'
            ) {
              toast.error(i18nMessages.invalidQuantityDelete);
              return;
            }
          }
        }
      }
    }
  };

  return (
    <div>
      <Typography variant="h5" className="inner-heading has-button">
        {I18n('ProductCatalogues.Tabs.Purchases')}
        <div className="inner-btn-wrap">
          <div className="text-right container-search">
            <div className="search-control-outer">
              <CustomizedInputs
                margin="normal"
                displayLabel=""
                inputType="text"
                placeholderText="Search"
                handleChange={handleChangeBasicSearch}
                className="search-control small-form-control"
                adornmentValue={
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={faSearch} />
                  </InputAdornment>
                }
              />
            </div>
          </div>
          {hasPermission(Permission.canAddProductCatalogueManagement) ? (
            <Button
              color="primary"
              className="small-btn"
              disableElevation
              onClick={addProductPurchase}
              variant="contained"
            >
              <FontAwesomeIcon icon={faPlus} className="fa-md" />
            </Button>
          ) : (
            <></>
          )}
        </div>
      </Typography>
      <div className="">
        <div className="content-block table-fix">
          <CustomDataGrid
            rowCount={gridData.totalRows}
            data={gridData.rows}
            columns={columns}
            pageNo={gridData.page}
            pageSize={gridData.pageSize}
            onSortModelChange={handleSortModelChange}
            onPageChange={handleChangePage}
            onPageSizeChange={handleChangePageSize}
            rowsPerPageOptions={gridData.rowsPerPageOptions}
            initialState={{
              sorting: {
                sortModel: [{ field: 'date', sort: 'desc' }],
              } as GridSortingInitialState,
              pagination: {
                page: gridData.page,
                pageSize: gridData.pageSize,
              } as GridPaginationInitialState,
            }}
          ></CustomDataGrid>

          <ConfirmationPopup
            isOpen={isDeleteModalOpen}
            data={selectedId}
            message={I18n('ProductCatalogues.ProductPurchases.DeleteMessage')}
            handleClose={handleCloseForDelete}
            handleOk={handleOkForDelete}
            title={I18n(
              'ProductCatalogues.ProductPurchases.DeleteProductPurchase'
            )}
          ></ConfirmationPopup>
          <AddProductPurchase
            isOpen={isAddModalOpen}
            editData={editData}
            entityId={entityId}
            handleClose={handleCloseForAdd}
            title={
              editData == null
                ? I18n('ProductCatalogues.ProductPurchases.AddProductPurchase')
                : I18n('ProductCatalogues.ProductPurchases.EditProductPurchase')
            }
            refreshData={refreshData}
          ></AddProductPurchase>
        </div>
      </div>
    </div>
  );
}

export default ProductPurchaseList;
