import I18n from 'utilities/i18n';
import { useEffect, useCallback, useState, MouseEvent } from 'react';
import http from '../../utilities/httpService';
import {
  ApprovalStatus,
  BaseModel,
  EntityStatus,
  EntityStatusDescription,
  OrderByDirection,
  PartnerType,
  PartnerTypeDescription,
} from 'models/pagination-model';
import {
  Breadcrumbs,
  Button,
  Fade,
  Grid,
  IconButton,
  Paper,
  Popper,
  PopperPlacementType,
  Typography,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faPlus,
  faFilter,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import DeleteIcon from '@mui/icons-material/Delete';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from 'common/formControl/formControl';
import CustomDataGrid from 'common/datagrid/datagrid';
import { useNavigate } from 'react-router-dom';
import {
  GridActionsCellItem,
  GridColumns,
  GridEventListener,
  GridPaginationInitialState,
  GridRowId,
  GridRowParams,
  GridSortingInitialState,
  GridSortModel,
} from '@mui/x-data-grid';
import environment from 'environment';
import CustomizedSelect from 'common/CustomSelect/CustomSelect';
import ConfirmationPopup from 'common/confirmationPopup/confirmationPopup';
import { toast } from 'react-toastify';
import { Permission } from 'Permissions';
import { hasPermission } from 'utilities/protectedRoute';
import RouteEnum from 'models/RouteEnum';
import RestorePageIcon from '@mui/icons-material/RestorePage';

function PartnerList() {
  const breadcrumbs = [
    <Typography key="3" color="text.primary">
      {I18n('Nav.Reference')}
    </Typography>,
    <Typography key="3" color="text.primary">
      {I18n('Nav.Partners')}
    </Typography>,
  ];
  const [gridData, setGridData] = useState({
    sortOrder: OrderByDirection.Descending,
    sortBy: 'name',
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [10, 20, 50, 100],
    pageSize: 10,
    page: 0,
    searchExpression: '',
  });
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isDeleteModalOpen, setDeleteModal] = useState(false);
  const [selectedId, setId] = useState();
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState<PopperPlacementType>();
  const [basicSearchExpression, setBasicSearchExpression] = useState('');
  const [advanceSearchExpression, setAdvanceSearchExpression] = useState('');
  const defaultCountryValue = {
    value: '0',
    label: I18n('Placeholders.Select'),
  } as BaseModel;
  const [countryList, setCountryList] = useState<BaseModel[]>([]);
  const defaultValue = {
    value: '0',
    label: I18n('Placeholders.SelectStatus'),
  } as BaseModel;
  const [statusFilter, setStatusFilter] = useState(defaultValue);
  const [countryFilter, setCountryFilter] =
    useState<BaseModel>(defaultCountryValue);
  const [vatFilter, setVatFilter] = useState('');
  const getPartnerTypeValue = (type: PartnerType) => {
    switch (type) {
      case PartnerType.DHL:
        return i18nMessages.dhlValue;
      case PartnerType.FedEx:
        return i18nMessages.fedExValue;
      case PartnerType.Others:
        return i18nMessages.othersValue;
      default:
        break;
    }
  };
  let navigate = useNavigate();

  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));
  const getPartners = async () => {
    const apiUrl = new URL(environment.api.baseUrl + 'Partners');
    try {
      let finalSearchTerm = '';
      if (basicSearchExpression && advanceSearchExpression) {
        finalSearchTerm = `${basicSearchExpression} and ${advanceSearchExpression}`;
      } else if (basicSearchExpression && !advanceSearchExpression) {
        finalSearchTerm = basicSearchExpression;
      } else if (!basicSearchExpression && advanceSearchExpression) {
        finalSearchTerm = advanceSearchExpression;
      }

      apiUrl.searchParams.set('$filter', finalSearchTerm);
      apiUrl.searchParams.set('$orderby', gridData.sortBy);
      apiUrl.searchParams.set(
        '$skip',
        (gridData.page * gridData.pageSize).toString()
      );
      apiUrl.searchParams.set('$top', gridData.pageSize.toString());
      apiUrl.searchParams.set(
        '$orderbydirection',
        gridData.sortOrder.toString()
      );
      const result = await http.get(apiUrl.toString());
      if (result) {
        updateGridData('totalRows', result.data.totalCount);
        updateGridData('rows', result.data.values);
      }
    } catch (error) {}
  };

  const getCountries = async () => {
    let countryLst: string[] = [];

    const apiUrl = new URL(environment.api.baseUrl + 'Country');
    try {
      apiUrl.searchParams.set('$orderby', 'name');
      apiUrl.searchParams.set('$skip', '0');
      apiUrl.searchParams.set('$top', '5000');
      apiUrl.searchParams.set(
        '$orderbydirection',
        OrderByDirection.Ascending.toString()
      );

      const result = await http.get(apiUrl.toString());
      if (result) {
        result.data.values.map(function (item: any) {
          countryLst.push(item['name']);
        });

        let data = getCountryList(countryLst);
        setCountryList(data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getPartners();
    getCountries();
  }, [
    gridData.page,
    gridData.pageSize,
    gridData.sortBy,
    gridData.sortOrder,
    gridData.searchExpression,
    advanceSearchExpression,
    basicSearchExpression,
  ]);

  const handleChangePage = (pageNo: number) => {
    updateGridData('page', pageNo);
  };

  const handleChangeBasicSearch = (searchExpression: any) => {
    let search = searchExpression.target.value;
    let searchString = '';
    searchString += search ? ` and name~like~${search}` : '';
    setBasicSearchExpression(searchString);
  };
  const handleChangePageSize = (pageSize: number) => {
    updateGridData('pageSize', pageSize);
  };

  const handleResend = async (params: any) => {
    const apiUrl = new URL(
      environment.api.baseUrl + 'Partners/' + params.row.id
    );
    try {
      const result: any = await http.get(apiUrl.toString());

      if (
        result.data &&
        result.data.approvalStatus === ApprovalStatus.Rejected
      ) {
        result.data.approvalStatus = ApprovalStatus.Pending;

        var response = await http.put(apiUrl.toString(), result.data);
        if (response) {
          toast.success(i18nMessages.ResendForApproval);
          getPartners();
        } else {
          toast.error(i18nMessages.errorOccurred);
        }
      }
    } catch (error: any) {}
  };

  type Row = (typeof gridData.rows)[number];
  const deletePartner = useCallback(
    (id: any) => () => {
      setDeleteModal(true);
      setId(id);
    },
    []
  );
  const columns: GridColumns<Row> = [
    {
      field: 'transportTypeName',
      headerName: I18n('Partner.Type'),
      flex: 1,
    },
    {
      field: 'name',
      headerName: I18n('Partner.Name'),
      flex: 1,
    },
    {
      field: 'partnerType',
      headerName: I18n('Partner.Tracking'),
      flex: 1,
      renderCell: (params: any) => {
        return getPartnerTypeValue(+params.row.partnerType);
      },
    },
    {
      field: 'categories',
      headerName: I18n('Partner.TransportTypes'),
      minWidth: 130,
      sortable: false,
    },
    {
      field: 'vatNumber',
      headerName: I18n('Partner.VatNumber'),
      flex: 1,
    },
    {
      field: 'telephone',
      headerName: I18n('Partner.Telephone'),
      flex: 1,
    },
    {
      field: 'email',
      headerName: I18n('Partner.Email'),
      flex: 1,
    },
    {
      field: 'country-city',
      headerName: I18n('Partner.Country_City'),
      flex: 1,
      renderCell: (params: any) => {
        let data = params.row;
        if (data?.country || data?.city) {
          return data?.country + ' - ' + data?.city;
        } else {
          return '';
        }
      },
    },
    {
      field: 'locations',
      headerName: I18n('Partner.Location_Branches'),
      flex: 1,
      renderCell: (params: any) => (
        <div className="partner-location">{params?.row?.locations}</div>
      ),
    },
    {
      field: 'statusDescription',
      headerName: I18n('Partner.Status'),
      maxWidth: 80,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: I18n('Common.Actions'),
      width: 100,
      getActions: (params: GridRowParams) => {
        const actionButtons = [];
        if (params.row.status !== EntityStatus.Active) {
          actionButtons.push(
            <>
              {hasPermission(Permission.canDeletePartner) ? (
                <GridActionsCellItem
                  onPointerEnterCapture={false}
                  onPointerLeaveCapture={false}
                  placeholder={''}
                  icon={<DeleteIcon />}
                  label="Delete"
                  onClick={deletePartner(params.id)}
                />
              ) : (
                <></>
              )}
            </>
          );
        }
        if (params.row.approvalStatus === ApprovalStatus.Rejected) {
          actionButtons.push(
            <GridActionsCellItem
              onPointerEnterCapture={false}
              onPointerLeaveCapture={false}
              placeholder={''}
              icon={<RestorePageIcon />}
              label="ResendForApproval"
              onClick={(data) => handleResend(params)}
            />
          );
        }
        return actionButtons;
      },
    },
  ];

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    if (sortModel && sortModel.length) {
      if (sortModel[0].field === 'country-city') {
        updateGridData('sortBy', 'country.name');
      } else if (sortModel[0].field === 'statusDescription') {
        updateGridData('sortBy', 'status');
      } else if (sortModel[0].field === 'approvalStatusDescription') {
        updateGridData('sortBy', 'approvalStatus');
      } else if (sortModel[0].field === 'taggedToDescription') {
        updateGridData('sortBy', 'taggedTo');
      } else if (sortModel[0].field === 'transportTypeName') {
        updateGridData('sortBy', 'partnerTransportType.transportType');
      } else {
        updateGridData('sortBy', sortModel[0].field);
      }
      updateGridData(
        'sortOrder',
        sortModel[0].sort === 'asc'
          ? OrderByDirection.Ascending
          : OrderByDirection.Descending
      );
    }
  }, []);

  const handleOnRowClick = (params: { id: string }) => {
    navigate('/partners/' + params.id);
  };

  const popperHandleClick =
    (newPlacement: PopperPlacementType) =>
    (event: MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setOpen((prev) => placement !== newPlacement || !prev);
      setPlacement(newPlacement);
    };

  let countryLst: Array<BaseModel> = [];
  const getCountryList = (countries: string[]) => {
    countryLst = [defaultCountryValue];
    countries.forEach((value: string) => {
      countryLst.push({
        value,
        label: value,
      } as BaseModel);
    });

    return countryLst;
  };

  let entityStatusList: Array<BaseModel> = [];
  const getStatusList = () => {
    entityStatusList = [defaultValue];
    EntityStatusDescription.forEach((value: string, key: number) => {
      entityStatusList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getStatusList();

  const handleResetClick = () => {
    setVatFilter('');
    setCountryFilter(defaultCountryValue);
    setStatusFilter(defaultValue);
    setAdvanceSearchExpression('');
    setOpen(false);
  };

  const handleAdvanceSearchFilterClick = () => {
    let searchExpression = '';
    searchExpression += vatFilter ? `VatNumber~like~${vatFilter}` : '';
    searchExpression +=
      countryFilter && countryFilter?.value && countryFilter?.value !== '0'
        ? appendAdvanceSearchExpressions(searchExpression, 'and') +
          `Country.Name~like~${countryFilter.value}`
        : '';
    searchExpression += +statusFilter.value
      ? appendAdvanceSearchExpressions(searchExpression, 'and') +
        `Status~=~${statusFilter.value}`
      : '';
    setAdvanceSearchExpression(searchExpression);
    setOpen(false);
  };

  const appendAdvanceSearchExpressions = (
    searchExpression: string,
    type: string
  ) => {
    let searchExpressionLocal: string = searchExpression;
    switch (type) {
      case 'and':
        searchExpressionLocal = searchExpressionLocal ? ` and ` : ``;
        break;
      case 'or':
        searchExpressionLocal = searchExpressionLocal ? ` or ` : ``;
        break;
      default:
        break;
    }
    return searchExpressionLocal;
  };
  const handleClose = () => {
    setDeleteModal(false);
  };

  const handleOk = () => {
    setDeleteModal(false);
    deleteData(selectedId);
  };

  const i18nMessages = {
    deletedSuccessfully: I18n('Partner.DeletedSuccessfully'),
    errorOccurred: I18n('Partner.ErrorOccurred'),
    ResendForApproval: I18n('Partner.ResendForApproval'),
    dhlValue: I18n('Partner.DHL'),
    fedExValue: I18n('Partner.FedEx'),
    othersValue: I18n('Partner.Others'),
  };

  const deleteData = async (id: any) => {
    const apiUrl = new URL(environment.api.baseUrl + 'Partners/' + id);
    try {
      const result: any = await http.delete(apiUrl.toString());
      if (result != null && result.data != null && result.data.success) {
        toast.success(i18nMessages.deletedSuccessfully);
        getPartners();
      } else {
        toast.error(i18nMessages.errorOccurred);
      }
    } catch (error: any) {}
  };

  const addClicked = () => {
    navigate(RouteEnum.PartnerAdd);
  };

  return (
    <div>
      <div className="heading-section">
        <div className="heading-section-left">
          <Typography variant="h3" sx={{ mb: 3 }}>
            {I18n('Nav.Partners')}
          </Typography>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>
        <div className="heading-section-right">
          <div className="search-control-outer">
            <FormControl
              margin="normal"
              displayLabel=""
              required
              inputType="text"
              placeholderText={I18n('Partner.SearchbyName')}
              className="search-control small-form-control"
              handleChange={handleChangeBasicSearch}
              adornmentValue={
                <InputAdornment position="start">
                  <FontAwesomeIcon icon={faSearch} />
                </InputAdornment>
              }
            ></FormControl>
          </div>
          <div className="header-btn-wrap">
            <Popper
              open={open}
              anchorEl={anchorEl}
              placement={placement}
              transition
              className="filter-popper"
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper>
                    <Typography variant="h5" className="filter-heading">
                      Filter
                      <IconButton
                        aria-label="delete"
                        onClick={popperHandleClick('bottom-end')}
                      >
                        <FontAwesomeIcon
                          icon={faTimes}
                          className="fa-sm close-icon"
                        />
                      </IconButton>
                    </Typography>
                    <div className="filter-content form-small-gap">
                      <Grid container spacing={2} className="grid-wrap two-col">
                        <Grid item xs={12}>
                          <FormControl
                            margin="normal"
                            displayLabel={I18n('Partner.VatNumber')}
                            id="VatNumber"
                            inputType="text"
                            inputProps={{ maxLength: 30 }}
                            handleChange={(
                              event: React.ChangeEvent<
                                HTMLTextAreaElement | HTMLInputElement
                              >
                            ) => setVatFilter(event.target.value)}
                            value={vatFilter}
                            multiline={false}
                          ></FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <CustomizedSelect
                            placeholder={defaultCountryValue.label}
                            options={countryList}
                            isSearchable={false}
                            displayLabel={I18n('Partner.Country')}
                            handleChange={(newValue: any) =>
                              setCountryFilter(newValue)
                            }
                            value={countryFilter}
                          ></CustomizedSelect>
                        </Grid>
                        <Grid item xs={12}>
                          <CustomizedSelect
                            placeholder={defaultValue.label}
                            options={entityStatusList}
                            isSearchable={false}
                            displayLabel={I18n('Common.Status')}
                            handleChange={(newValue: BaseModel) =>
                              setStatusFilter(newValue)
                            }
                            value={statusFilter}
                          ></CustomizedSelect>
                        </Grid>
                      </Grid>
                      <div className="btn-wrap">
                        <Button
                          color="neutral"
                          disableElevation
                          variant="contained"
                          onClick={handleResetClick}
                        >
                          {I18n('Common.Reset')}
                        </Button>
                        <Button
                          color="primary"
                          disableElevation
                          variant="contained"
                          onClick={handleAdvanceSearchFilterClick}
                        >
                          {I18n('Common.Apply')}
                        </Button>
                      </div>
                    </div>
                  </Paper>
                </Fade>
              )}
            </Popper>
            <Button
              color="info"
              onClick={popperHandleClick('bottom-end')}
              disableElevation
              variant="outlined"
              className={`icon-btn ${
                advanceSearchExpression ? 'is-filtered' : ''
              }`}
            >
              <FontAwesomeIcon icon={faFilter} className="fa-lg" />
            </Button>
            {hasPermission(Permission.canAddPartner) ? (
              <Button
                color="info"
                className="small-btn"
                disableElevation
                variant="contained"
                onClick={addClicked}
              >
                <FontAwesomeIcon icon={faPlus} className="fa-md mr-2" />
                Add
              </Button>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div className="main-content-section">
        <div className="content-block table-fix">
          <CustomDataGrid
            rowCount={gridData.totalRows}
            data={gridData.rows}
            columns={columns}
            pageNo={gridData.page}
            pageSize={gridData.pageSize}
            onSortModelChange={handleSortModelChange}
            onPageChange={handleChangePage}
            onPageSizeChange={handleChangePageSize}
            rowsPerPageOptions={gridData.rowsPerPageOptions}
            onRowClick={handleOnRowClick}
            initialState={{
              sorting: {
                sortModel: [{ field: 'name', sort: 'desc' }],
              } as GridSortingInitialState,
              pagination: {
                page: gridData.page,
                pageSize: gridData.pageSize,
              } as GridPaginationInitialState,
            }}
          ></CustomDataGrid>

          <ConfirmationPopup
            isOpen={isDeleteModalOpen}
            data={selectedId}
            message={I18n('Partner.DeleteMessage')}
            handleClose={handleClose}
            handleOk={handleOk}
            title={I18n('Partner.DeletePartner')}
          ></ConfirmationPopup>
        </div>
      </div>
    </div>
  );
}

export default PartnerList;
