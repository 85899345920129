import I18n from 'utilities/i18n';
import { useEffect, useCallback, useState } from 'react';
import http from '../../../utilities/httpService';
import { OrderByDirection } from 'models/pagination-model';
import { Button, Grid, InputAdornment, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faPlus } from '@fortawesome/free-solid-svg-icons';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CustomDataGrid from 'common/datagrid/datagrid';
import { useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import CustomSelectPaginateAdd from 'common/CustomSelect/CustomSelectPaginateAdd';
import {
  GridActionsCellItem,
  GridColumns,
  GridPaginationInitialState,
  GridRowId,
  GridRowParams,
  GridSortingInitialState,
  GridSortModel,
} from '@mui/x-data-grid';
import environment from 'environment';
import ConfirmationPopup from 'common/confirmationPopup/confirmationPopup';
import { toast } from 'react-toastify';
import { Permission } from 'Permissions';
import { hasPermission } from 'utilities/protectedRoute';
import ShippingPackage from './ShippingPackage';
import { CustomizedInputs } from 'common/formControl/formControl';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import AddImporterExporter from '../AddImporterExporter';
import {
  CheckIsOfferConverted,
  CheckOrderEnable,
  getBasicDetailsById,
  loadDeliveryPaymentOptions,
  loadExporterOptions,
  loadImporterOptions,
  saveCustomsDetails,
  SaveDeliveryPayment,
} from '../ShippingApiService';
import { BaseModel } from 'models/pagination-model';
import { ShippingCustomsDetailsModel } from 'models/shipping-orders';
import ViewImporterExporterModal from '../ViewImporterExporterModel';
import { Utilities } from 'utilities/Utilities';

interface ShippingPackageProps {
  isSearchable?: boolean;
  isReadOnly?: boolean;
}

function ShippingPackageList({
  isSearchable,
  isReadOnly,
}: ShippingPackageProps) {
  const params = useParams();
  const [isDeleteModalOpen, setDeleteModal] = useState(false);
  const [isAddModalOpen, setAddModal] = useState(false);
  const [isEditModalOpen, setEditModal] = useState(false);
  const [data, setData] = useState(null);
  const [selectedShippingPackageID, setShippingPackageId] = useState();
  const [canAdd, setCanAdd] = useState(
    hasPermission(Permission.canAddShippingOffers) ||
      hasPermission(Permission.canAddShippingOrders)
  );

  const [canEdit, setCanEdit] = useState(
    hasPermission(Permission.canEditShippingOffers) ||
      hasPermission(Permission.canEditShippingOrders)
  );

  const [canDelete, setCanDelete] = useState(
    hasPermission(Permission.canDeleteShippingOffers) ||
      hasPermission(Permission.canDeleteShippingOrders)
  );

  const [canView, setCanView] = useState(
    hasPermission(Permission.canViewShippingOffers) ||
      hasPermission(Permission.canViewShippingOrders)
  );

  const [gridData, setGridData] = useState({
    sortOrder: OrderByDirection.Descending,
    sortBy: 'productCatalogue.productCode',
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [10, 20, 50, 100],
    pageSize: 10,
    page: 0,
    searchExpression: '',
  });
  const [viewImporterExporterDetails, setViewImporterExporterDetails] =
    useState({
      isShowImporterExporterDetails: false,
      titleDetails: '',
      entityId: 0,
    });
  const customerPortalUser = Utilities.getIsCPUser();
  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));

  const getShippingPackage = async () => {
    const apiUrl = new URL(environment.api.baseUrl + 'ShippingPackage');
    try {
      if (gridData.searchExpression) {
        apiUrl.searchParams.set(
          '$filter',
          gridData.searchExpression + ' and ' + 'ShippingOrderId~=~' + params.id
        );
      } else {
        apiUrl.searchParams.set('$filter', 'ShippingOrderId~=~' + params.id);
      }
      apiUrl.searchParams.set('$orderby', gridData.sortBy);
      apiUrl.searchParams.set(
        '$skip',
        (gridData.page * gridData.pageSize).toString()
      );
      apiUrl.searchParams.set('$top', gridData.pageSize.toString());
      apiUrl.searchParams.set(
        '$orderbydirection',
        gridData.sortOrder.toString()
      );
      const result = await http.get(apiUrl.toString());
      if (result) {
        updateGridData('totalRows', result.data.totalCount);
        updateGridData('rows', result.data.values);
        updateGridData('isLoading', false);
        if (result.data.values.length > 0) {
          var quantity = 0;
          for (let i = 0; i < result.data.values.length; i++) {
            quantity += result.data.values[i].shippingCommodity.length;
          }
          if (quantity === 0) {
            setIsPackageExist(false);
          } else {
            setIsPackageExist(true);
          }
        } else {
          setIsPackageExist(false);
        }
      }
    } catch (error) {}
  };

  const defaultAdditional: any = {
    page: 1,
  };

  const { id } = useParams();
  const [cacheUniqImporter, setCacheUniqImporter] = useState(0);
  const [cacheUniqExporter, setCacheUniqExporter] = useState(0);
  const [cacheUniqDeliveryPayment, setCacheUniqDeliveryPayment] = useState(0);

  const [isPackageExist, setIsPackageExist] = useState(false);
  const defaultValue = {
    value: '',
    label: I18n('Placeholders.Select'),
  } as BaseModel;

  const [errors, setErrors] = useState({
    importer: '',
    exporter: '',
    deliveryPayment: '',
  });

  const [values, setValues] = useState<ShippingCustomsDetailsModel>({
    importer: defaultValue,
    exporter: defaultValue,
    deliveryPayment: defaultValue,
  });
  const [AddImporterExporterModalOpen, setAddImporterExporterModalOpen] =
    useState({
      isAddModalOpen: false,
      entityId: 0,
      entityName: '',
    });

  const handleCloseForImporterExporter = () => {
    setAddImporterExporterModalOpen({
      isAddModalOpen: false,
      entityId: 0,
      entityName: '',
    });
  };

  const i18nMessages = {
    UpdateSuccess: I18n('ShippingOrders.UpdateSuccess'),
    somethingWentWrong: I18n('Common.SomethingWentWrong'),
    commonFieldIsRequired: I18n('Common.FieldIsRequired'),
    CommonSaveSuccessMsg: I18n('Common.SavedSuccessfully'),
    CommonErrOccuredMsg: I18n('Common.ErrorOccurred'),
    ViewImporter: I18n('ShippingOrders.ViewImporter'),
    ViewExporter: I18n('ShippingOrders.ViewExporter'),
  };

  const onImporterExporterDetails = (id: any, title: any) => {
    setViewImporterExporterDetails({
      isShowImporterExporterDetails: true,
      titleDetails: title,
      entityId: id,
    });
  };

  const handleCloseForShowImporterExporterDetails = () => {
    setViewImporterExporterDetails({
      isShowImporterExporterDetails: false,
      titleDetails: '',
      entityId: 0,
    });
  };
  const getDetails = async () => {
    getBasicDetailsById(Number(id)).then(async (response: any) => {
      if (response) {
        if (
          response.importerId !== undefined &&
          response.exporterId !== undefined &&
          response.deliveryPaymentId !== undefined
        ) {
          setValues({
            importer: {
              label: response.importerName,
              value: response.importerId.toString(),
            } as BaseModel,
            exporter: {
              label: response.exporterName,
              value: response.exporterId.toString(),
            } as BaseModel,
            deliveryPayment: {
              label: response.deliveryPaymentName,
              value: response.deliveryPaymentId.toString(),
            } as BaseModel,
          });
        }
        if (response.isShippingOfferConverted) {
          setCanEdit(false);
          setCanDelete(false);
          setCanAdd(false);
        } else {
          if (canEdit) {
            const orderState = CheckOrderEnable(+response.status);
            if (!orderState) {
              setCanEdit(false);
              setCanDelete(false);
              setCanAdd(false);
            }
          }
        }
      }
    });
  };

  const validate = (fieldValues: ShippingCustomsDetailsModel = values) => {
    let temp = { ...errors };

    if ('importer' in fieldValues) {
      temp.importer = fieldValues.importer.value
        ? ''
        : i18nMessages.commonFieldIsRequired;
    }
    if ('exporter' in fieldValues) {
      temp.exporter = fieldValues.exporter.value
        ? ''
        : i18nMessages.commonFieldIsRequired;
    }
    if ('deliveryPayment' in fieldValues) {
      temp.deliveryPayment = fieldValues.deliveryPayment.value
        ? ''
        : i18nMessages.commonFieldIsRequired;
    }

    setErrors({
      ...temp,
    });
    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === '');
    }
  };

  const onCreateDeliveryPaymentOption = async (inputValue: any) => {
    let name = {};
    name['name'] = inputValue;
    const newOption: any = await SaveDeliveryPayment(
      name,
      i18nMessages.CommonSaveSuccessMsg,
      i18nMessages.CommonErrOccuredMsg
    );
    const increaseUniq = (uniq: any) => uniq + 1;
    setCacheUniqDeliveryPayment(increaseUniq);
    setValues({
      ...values,
      deliveryPayment: {
        label: newOption.label,
        value: newOption.value.toString(),
      } as BaseModel,
    });
  };

  const onCreateImporter = async (inputValue: any) => {
    const increaseUniq = (uniq: any) => uniq + 1;
    setCacheUniqImporter(increaseUniq);
    setAddImporterExporterModalOpen({
      isAddModalOpen: true,
      entityId: 1,
      entityName: inputValue,
    });
  };

  const onCreateExporter = async (inputValue: any) => {
    const increaseUniq = (uniq: any) => uniq + 1;
    setCacheUniqExporter(increaseUniq);
    setAddImporterExporterModalOpen({
      isAddModalOpen: true,
      entityId: 2,
      entityName: inputValue,
    });
  };

  const handleSaveClick = () => {
    if (validate()) {
      saveCustomsDetails(Number(id), values).then(
        (response: { result: any }) => {
          if (response && response.result) {
            toast.success(i18nMessages.CommonSaveSuccessMsg);
          } else {
            toast.error(i18nMessages.somethingWentWrong);
          }
        }
      );
    }
  };

  const OnSaveImporterExporter = async (response: any) => {
    if (response && response?.data) {
      if (AddImporterExporterModalOpen.entityId === 1) {
        setValues({
          ...values,
          importer: {
            label: response.data.name,
            value: response.data.id.toString(),
          } as BaseModel,
        });
      } else if (AddImporterExporterModalOpen.entityId === 2) {
        setValues({
          ...values,
          exporter: {
            label: response.data.name,
            value: response.data.id.toString(),
          } as BaseModel,
        });
      }
    }
    handleCloseForImporterExporter();
  };

  const getPackageDetailsCount = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl + `Shipping/GetPackageDetailsCount/${id}`
    );
    const result = await http.get(apiUrl.toString());
    if (result) {
      setIsPackageExist(result.data);
    }
  };

  useEffect(() => {
    getDetails();
    getShippingPackage();
    getPackageDetailsCount();
  }, [
    gridData.page,
    gridData.pageSize,
    gridData.sortBy,
    gridData.sortOrder,
    gridData.searchExpression,
    params.id,
  ]);

  const handleChangePage = (pageNo: number) => {
    updateGridData('page', pageNo);
  };

  const handleChangePageSize = (pageSize: number) => {
    updateGridData('pageSize', pageSize);
  };
  type Row = (typeof gridData.rows)[number];
  const deleteShippingPackage = useCallback(
    (id: any) => () => {
      setDeleteModal(true);
      setShippingPackageId(id);
    },
    []
  );

  const editShippingPackage = (id: GridRowId) => async () => {
    const apiUrl = new URL(environment.api.baseUrl + 'ShippingPackage/' + id);
    try {
      const result = await http.get(apiUrl.toString());
      if (result) {
        setData(result.data);
        setEditModal(true);
      }
    } catch {}
  };

  const columns: GridColumns<Row> = [
    {
      field: 'productCode',
      headerName: I18n('ShippingOrders.ShippingPackage.BoxNo'),
      flex: 1,
    },
    {
      field: 'productName',
      headerName: I18n('ShippingOrders.ShippingPackage.BoxType'),
      flex: 1,
    },
    {
      field: 'dimension',
      headerName: I18n('ShippingOrders.ShippingPackage.Dimension'),
      flex: 1,
      sortable: false,
      renderCell: (params: any) => {
        return (
          params.row.dimension1 +
          ' X ' +
          params.row.dimension2 +
          ' X ' +
          params.row.dimension3
        );
      },
    },
    {
      field: 'itemValue',
      headerName: I18n('ShippingOrders.ShippingPackage.Items'),
      flex: 1,
      sortable: false,
      renderCell: (params: any) => {
        return (
          params.row.shippingCommodity && params.row.shippingCommodity.length
        );
      },
    },
    {
      field: 'weight',
      headerName: I18n('ShippingOrders.ShippingPackage.Weight'),
      flex: 1,
    },
    {
      field: 'innerBoxCount',
      headerName: I18n('ShippingOrders.ShippingPackage.InnerBoxCount'),
      flex: 1,
    },
    {
      field: 'generalCheck',
      sortable: false,
      headerName: I18n('ShippingOrders.ShippingPackage.GeneralCargo'),
      flex: 1,
      renderCell: (params) => {
        return params.formattedValue ? I18n('Common.Yes') : I18n('Common.No');
      },
    },
    {
      field: 'dangerousGoods',
      sortable: false,
      headerName: I18n('ShippingOrders.ShippingPackage.DangerousGoods'),
      flex: 1,
      renderCell: (params: any) => {
        return params.row.isDangerousGoodsCheck
          ? I18n('Common.Yes')
          : I18n('Common.No');
      },
    },
    {
      field: 'temperatureControlled',
      sortable: false,
      headerName: I18n('ShippingOrders.ShippingPackage.TemperatureControlled'),
      flex: 1,
      renderCell: (params: any) => {
        return params.row.isTemperatureControlCheck
          ? I18n('Common.Yes')
          : I18n('Common.No');
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: I18n('Common.Actions'),
      width: 100,
      getActions: (params: GridRowParams<any>) => {
        const actionButtons = [];
        actionButtons.push(
          <>
            {canEdit ? (
              <GridActionsCellItem
                onPointerEnterCapture={false}
                onPointerLeaveCapture={false}
                icon={<EditIcon />}
                placeholder={''}
                label="Edit"
                onClick={editShippingPackage(params.id)}
              />
            ) : (
              <></>
            )}

            {canDelete ? (
              <GridActionsCellItem
                onPointerEnterCapture={false}
                onPointerLeaveCapture={false}
                icon={<DeleteIcon />}
                placeholder={''}
                label="Delete"
                onClick={deleteShippingPackage(params.id)}
              />
            ) : (
              <></>
            )}
          </>
        );
        return actionButtons;
      },
    },
  ];

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    if (sortModel && sortModel.length) {
      if (sortModel[0].field === 'productCode') {
        updateGridData('sortBy', 'productCatalogue.productCode');
      } else if (sortModel[0].field === 'country') {
        updateGridData('sortBy', 'country.name');
      } else {
        updateGridData('sortBy', sortModel[0].field);
      }
      updateGridData(
        'sortOrder',
        sortModel[0].sort === 'asc'
          ? OrderByDirection.Ascending
          : OrderByDirection.Descending
      );
    }
  }, []);

  const addShippingPackage = useCallback(() => {
    setAddModal(true);
  }, []);

  const handleCloseForAdd = () => {
    setAddModal(false);
  };

  const handleCloseForEdit = () => {
    setEditModal(false);
  };

  const OnSave = async () => {
    handleCloseForAdd();
    handleCloseForEdit();
    await getShippingPackage();
  };

  const handleCloseForDelete = async () => {
    setDeleteModal(false);
  };

  const handleOkForDelete = () => {
    setDeleteModal(false);
    deletePackageDetails(selectedShippingPackageID);
  };

  const deletePackageDetails = async (id: any) => {
    updateGridData('isLoading', true);
    const apiUrl = new URL(environment.api.baseUrl + 'ShippingPackage/' + id);
    try {
      const result: any = await http.delete(apiUrl.toString());
      if (result) {
        toast.success('ShippingPackage deleted successfully');
        updateGridData('isLoading', false);
        await getShippingPackage();
      }
    } catch (error: any) {}
  };

  const handleChangePackageSearch = (searchExpression: any) => {
    updateGridData(
      'searchExpression',
      'ProductName~like~' +
        searchExpression.target.value +
        ' or Weight~like~' +
        searchExpression.target.value
    );
  };

  return (
    <div>
      <Typography variant="h5" className="inner-heading has-button">
        {I18n('ShippingOrders.Steps.PackageDetails')}
        <div className="inner-btn-wrap">
          {isSearchable && (
            <div className="text-right container-search">
              <div className="search-control-outer">
                <CustomizedInputs
                  margin="normal"
                  displayLabel=""
                  inputType="text"
                  placeholderText="Search"
                  handleChange={handleChangePackageSearch}
                  className="search-control small-form-control"
                  adornmentValue={
                    <InputAdornment position="start">
                      <FontAwesomeIcon icon={faSearch} />
                    </InputAdornment>
                  }
                />
              </div>
            </div>
          )}
          {canAdd && !isReadOnly ? (
            <Button
              color="primary"
              className="small-btn"
              disableElevation
              onClick={addShippingPackage}
              variant="contained"
            >
              <FontAwesomeIcon icon={faPlus} className="fa-md" />
            </Button>
          ) : (
            <></>
          )}
        </div>
      </Typography>
      <div className="">
        <div className="content-block table-fix">
          <CustomDataGrid
            rowCount={gridData.totalRows}
            data={gridData.rows}
            columns={columns}
            pageNo={gridData.page}
            pageSize={gridData.pageSize}
            onSortModelChange={handleSortModelChange}
            onPageChange={handleChangePage}
            onPageSizeChange={handleChangePageSize}
            rowsPerPageOptions={gridData.rowsPerPageOptions}
            initialState={{
              sorting: {
                sortModel: [{ field: 'country.name', sort: 'desc' }],
              } as GridSortingInitialState,
              pagination: {
                page: gridData.page,
                pageSize: gridData.pageSize,
              } as GridPaginationInitialState,
            }}
          ></CustomDataGrid>
        </div>

        <ShippingPackage
          isOpen={isAddModalOpen}
          OnSave={OnSave}
          shippingOrderId={params.id}
          handleClose={handleCloseForAdd}
          title={I18n('ShippingOrders.ShippingPackage.AddShippingPackage')}
        ></ShippingPackage>

        <ShippingPackage
          data={data}
          isOpen={isEditModalOpen}
          OnSave={OnSave}
          shippingOrderId={params.id}
          handleClose={handleCloseForEdit}
          title={I18n('ShippingOrders.ShippingPackage.EditShippingPackage')}
        ></ShippingPackage>

        <ConfirmationPopup
          isOpen={isDeleteModalOpen}
          data={selectedShippingPackageID}
          message={I18n('ShippingOrders.ShippingPackage.DeleteConfirmation')}
          handleClose={handleCloseForDelete}
          handleOk={handleOkForDelete}
        ></ConfirmationPopup>
      </div>
      {isPackageExist ? (
        <>
          <Typography variant="h5" className="inner-heading">
            {I18n('ShippingOrders.CustomsDetails')}
          </Typography>
          <div className="inner-section">
            <Grid container spacing={2} className="grid-wrap ">
              <Grid item xs={4}>
                <div className="DPCustomerDetails">
                  <CustomSelectPaginateAdd
                    required
                    key={JSON.stringify(values.importer)}
                    readOnly={customerPortalUser ? isReadOnly : !canEdit}
                    additional={defaultAdditional}
                    value={values.importer}
                    loadOptions={(
                      search: string,
                      prevOptions: any,
                      page: any
                    ) => loadImporterOptions(search, prevOptions, page)}
                    isSearchable={true}
                    displayLabel={I18n('ShippingOrders.Importer')}
                    placeholder={defaultValue.label}
                    handleChange={(newValue: any) =>
                      setValues({ ...values, importer: newValue })
                    }
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                    }}
                    debounceTimeout={500}
                    isMultiSelect={false}
                    cacheUniqs={cacheUniqImporter}
                    onCreateOption={onCreateImporter}
                    errorValue={true}
                    errorMessage={errors.importer}
                  ></CustomSelectPaginateAdd>
                  {Number(values.importer.value) > 0 && canView && (
                    <Tooltip title={i18nMessages.ViewImporter}>
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        onClick={() =>
                          onImporterExporterDetails(
                            values.importer.value,
                            i18nMessages.ViewImporter
                          )
                        }
                        className={`fa-md icon-edit`}
                      />
                    </Tooltip>
                  )}
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className="DPCustomerDetails">
                  <CustomSelectPaginateAdd
                    required
                    key={JSON.stringify(values.exporter)}
                    readOnly={customerPortalUser ? isReadOnly : !canEdit}
                    additional={defaultAdditional}
                    value={values.exporter}
                    loadOptions={(
                      search: string,
                      prevOptions: any,
                      page: any
                    ) => loadExporterOptions(search, prevOptions, page)}
                    isSearchable={true}
                    displayLabel={I18n('ShippingOrders.Exporter')}
                    placeholder={defaultValue.label}
                    handleChange={(newValue: any) =>
                      setValues({ ...values, exporter: newValue })
                    }
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                    }}
                    debounceTimeout={500}
                    isMultiSelect={false}
                    cacheUniqs={cacheUniqExporter}
                    onCreateOption={onCreateExporter}
                    errorValue={true}
                    errorMessage={errors.exporter}
                  ></CustomSelectPaginateAdd>
                  {Number(values.exporter.value) > 0 && canView && (
                    <Tooltip title={i18nMessages.ViewExporter}>
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        onClick={() =>
                          onImporterExporterDetails(
                            values.exporter.value,
                            i18nMessages.ViewExporter
                          )
                        }
                        className={`fa-md icon-edit`}
                      />
                    </Tooltip>
                  )}
                </div>
              </Grid>
              <Grid item xs={4}>
                <CustomSelectPaginateAdd
                  required
                  key={JSON.stringify(values.deliveryPayment)}
                  readOnly={customerPortalUser ? isReadOnly : !canEdit}
                  additional={defaultAdditional}
                  value={values.deliveryPayment}
                  loadOptions={(search: string, prevOptions: any, page: any) =>
                    loadDeliveryPaymentOptions(search, prevOptions, page)
                  }
                  isSearchable={true}
                  displayLabel={I18n(
                    'ShippingOrders.TermsOfDeliveryAndPayment'
                  )}
                  placeholder={defaultValue.label}
                  handleChange={(newValue: any) =>
                    setValues({ ...values, deliveryPayment: newValue })
                  }
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                  }}
                  debounceTimeout={500}
                  isMultiSelect={false}
                  cacheUniqs={cacheUniqDeliveryPayment}
                  onCreateOption={onCreateDeliveryPaymentOption}
                  errorValue={true}
                  errorMessage={errors.deliveryPayment}
                ></CustomSelectPaginateAdd>
              </Grid>
            </Grid>
            <AddImporterExporter
              isOpen={AddImporterExporterModalOpen.isAddModalOpen}
              entityId={AddImporterExporterModalOpen.entityId}
              entityName={AddImporterExporterModalOpen.entityName}
              OnSave={OnSaveImporterExporter}
              handleClose={handleCloseForImporterExporter}
              title={
                AddImporterExporterModalOpen.entityId === 1
                  ? I18n('ShippingOrders.AddImporter')
                  : I18n('ShippingOrders.AddExporter')
              }
            ></AddImporterExporter>
            <div className="btn-wrap form-btn-wrap">
              {canEdit ? (
                <Button
                  color="primary"
                  disableElevation
                  variant="contained"
                  onClick={handleSaveClick}
                >
                  {I18n('Common.Save')}
                </Button>
              ) : (
                <></>
              )}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      <ViewImporterExporterModal
        isOpen={viewImporterExporterDetails.isShowImporterExporterDetails}
        entityId={viewImporterExporterDetails.entityId}
        handleClose={handleCloseForShowImporterExporterDetails}
        title={viewImporterExporterDetails.titleDetails}
      />
    </div>
  );
}

export default ShippingPackageList;
