import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import I18n from 'utilities/i18n';
import FormControl from 'common/formControl/formControl';
import { Box, FormHelperText, Grid, Typography } from '@mui/material';
import CustomizedSelect from 'common/CustomSelect/CustomSelect';
import {
  BaseModel,
  ViewLevelDescription,
  Language,
  LanguageDescription,
  MasterCategoryEnumType,
  ViewLevel,
  CategoryBaseModel,
  MasterCategoryCode,
} from 'models/pagination-model';
import environment from 'environment';
import { toast } from 'react-toastify';
import http from '../../utilities/httpService';
import FileUpload from 'common/fileupload/fileupload';
import {
  getDefaultDocumentTag,
  loadCategoryOptions,
} from 'components/deckpack-orders/DeckPackApiService';
import { PostCatagoryDropDown } from 'common/DropDownAddAPI/DropDownApiService';
import CustomSelectPaginateAdd from 'common/CustomSelect/CustomSelectPaginateAdd';
import { Utilities } from 'utilities/Utilities';

export default function DocumentAdd(props: any) {
  const defaultDropDownValue = {
    value: '0',
    label: I18n('Placeholders.Select'),
  } as BaseModel;

  const defaultTagDropDownValue = {
    value: '0',
    label: I18n('Placeholders.Select'),
  } as CategoryBaseModel;

  const defaultValueLangDropDown = {
    value: Language.En.toString(),
    label: I18n('Common.En'),
  } as BaseModel;

  const defaultViewLevelValue = {
    value: ViewLevel.InternalOperational.toString(),
    label: I18n('ViewLevel.Options.InternalOperational'),
  } as BaseModel;

  const externalViewLevelValue = {
    value: ViewLevel.External.toString(),
    label: I18n('ViewLevel.Options.External'),
  } as BaseModel;

  const internalOperationalViewLevelValue = {
    value: ViewLevel.InternalOperational.toString(),
    label: I18n('ViewLevel.Options.InternalOperational'),
  } as BaseModel;

  const {
    isOpen,
    handleClose,
    title,
    entityType,
    entityId,
    files,
    documentEntityType,
    allowedDocuments,
    totalFiles,
    categoryCode,
    isFromDnPWO4 = false,
  } = props;
  const customerPortalUser = Utilities.getIsCPUser();
  let languageList: Array<BaseModel> = [];
  let viewLevelList: Array<BaseModel> = [];
  const getDefaultData = () => {
    languageList = [defaultDropDownValue];
    LanguageDescription.forEach((value: string, key: number) => {
      languageList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });

    viewLevelList = [];
    ViewLevelDescription.forEach((value: string, key: number) => {
      viewLevelList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };

  getDefaultData();

  const [data, setData] = useState<any>(null);
  const [selectedLanguage, setSelectedLanguage] = useState(
    defaultValueLangDropDown
  );
  const [selectedViewLevel, setSelectedViewLevel] = useState(
    defaultViewLevelValue
  );
  const [selectedDocumentTag, setSelectedDocumentTag] = useState(
    defaultTagDropDownValue
  );
  const [uploadedfiles, setFiles] = useState<any>([]);
  const [errors, setErrors] = useState<any>({});
  const [cacheUniq, setCacheUniq] = useState(0);
  const i18nMessages = {
    MaxAllowed: I18n('Common.MaxAllowed'),
    documentTag: I18n('Common.DocumentTag'),
    CommonSaveSuccessMsg: I18n('Common.SavedSuccessfully'),
    DocumentSaveSuccessMsg: I18n('Document.SaveSuccessMessage'),
    CommonErrOccuredMsg: I18n('Common.ErrorOccurred'),
    ExternalInfoMessage: I18n('ViewLevel.ExternalInfoMessage'),
  };

  const getDefaultTag = async () => {
    if (categoryCode) {
      let tagList = await getDefaultDocumentTag(
        MasterCategoryEnumType.DocumentCategory,
        categoryCode,
        1
      );

      if (tagList && tagList.TotalRecords > 0 && tagList.Values) {
        let tag = tagList.Values[0];
        setSelectedDocumentTag({
          label: tag.name,
          value: tag.id.toString(),
          code: tag.code,
        });
      }
    }
  };

  const submit = async () => {
    if (validate()) {
      var filePath = await UploadFile(uploadedfiles);
      if (filePath) {
        const apiUrl = new URL(environment.api.baseUrl + 'Documents');
        try {
          var document = {
            name: data.name,
            documentUrl: filePath,
            language: Number(selectedLanguage.value),
            viewLevel: Number(selectedViewLevel.value),
            entityId,
            entityType: Number(entityType),
            documentTag:
              selectedDocumentTag?.value === '0'
                ? null
                : selectedDocumentTag?.value,
          };
          const result: any = await http.post(apiUrl.toString(), document);
          if (result) {
            toast.success(i18nMessages.DocumentSaveSuccessMsg);
            props.OnSave();
          } else {
            toast.error(i18nMessages.CommonErrOccuredMsg);
          }
        } catch (error: any) {}
      }
    }
  };

  const UploadFile = async (file: any) => {
    if (file && file.length > 0) {
      const apiUrl = new URL(
        environment.api.baseUrl + 'Documents/' + documentEntityType + '/upload'
      );

      if (allowedDocuments && allowedDocuments <= totalFiles) {
        toast.error(i18nMessages.MaxAllowed + allowedDocuments);
        return;
      }
      const data = new FormData();
      data.append('file', file[0]);
      const response = await http.post(apiUrl.toString(), data);

      return response.data;
    }
  };

  const onCreateOption = async (inputValue: any) => {
    let name = {};
    name['name'] = inputValue;
    name['entityType'] = MasterCategoryEnumType.DocumentCategory;
    const newOption: any = await PostCatagoryDropDown(
      name,
      i18nMessages.CommonSaveSuccessMsg,
      i18nMessages.CommonErrOccuredMsg
    );
    const increaseUniq = (uniq: any) => uniq + 1;
    setCacheUniq(increaseUniq);
    setSelectedDocumentTag(newOption);
  };

  const handleSaveUpload = (files: any) => {
    setFiles(files);
  };

  const handleFileDelete = (file: any) => {
    var a = uploadedfiles.filter(function (x: any) {
      return x.lastModified !== file.lastModified;
    });

    setFiles(a);
  };

  const validate = () => {
    let temp: any = {};
    let isValid = true;
    if (!data?.name) {
      temp.name = 'Document.Validation.DocumentNameRequired';
      isValid = false;
    } else if (data?.name?.length > 50) {
      temp.name = 'Document.Validation.DocumentNameMaxLength';
      isValid = false;
    }

    if (selectedLanguage.value === defaultDropDownValue.value) {
      temp.language = 'Document.Validation.LanguageRequired';
      isValid = false;
    }
    if (selectedViewLevel.value === defaultDropDownValue.value) {
      temp.viewLevel = 'Document.Validation.ViewLevelRequired';
      isValid = false;
    }
    if (!uploadedfiles || uploadedfiles.length === 0) {
      temp.fileupload = 'Document.Validation.FileRequired';
      isValid = false;
    }

    setErrors(temp);

    if (isValid) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    setFiles(files);
    setData(null);
    getDefaultTag();
    setSelectedLanguage(defaultValueLangDropDown);
    setSelectedDocumentTag(defaultTagDropDownValue);
    if (customerPortalUser) {
      setSelectedViewLevel(externalViewLevelValue);
    } else if (isFromDnPWO4) {
      setSelectedViewLevel(internalOperationalViewLevelValue);
    } else {
      setSelectedViewLevel(defaultViewLevelValue);
    }
  }, [files]);

  const ViewLevelInfoMessage = () => {
    return selectedViewLevel &&
      selectedViewLevel.value == ViewLevel.External.toString() ? (
      <Typography component="div" variant="body1" fontSize={13}>
        <Box sx={{ color: 'error.main' }}>
          {i18nMessages.ExternalInfoMessage}
        </Box>
      </Typography>
    ) : (
      <></>
    );
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className="grid-wrap">
            <Grid item xs={12}>
              <FormControl
                margin="normal"
                displayLabel={I18n('Document.Document')}
                id="Name"
                inputType="text"
                inputProps={{ maxLength: 500 }}
                handleChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ) => setData({ ...data, name: event.target.value })}
                value={data?.name}
                multiline={false}
                errorValue={I18n(errors.name)}
                errorMessage={I18n(errors.name)}
                required={true}
              ></FormControl>
            </Grid>
            <Grid item lg={6}>
              <CustomSelectPaginateAdd
                key={JSON.stringify(selectedDocumentTag)}
                additional={{
                  page: 1,
                }}
                value={selectedDocumentTag}
                loadOptions={(search: string, prevOptions: any, page: any) =>
                  loadCategoryOptions(
                    MasterCategoryEnumType.DocumentCategory,
                    search,
                    prevOptions,
                    page
                  )
                }
                isSearchable={true}
                displayLabel={i18nMessages.documentTag}
                placeholder={defaultDropDownValue.label}
                handleChange={(newValue: CategoryBaseModel) => {
                  setSelectedDocumentTag(newValue);
                  if (!customerPortalUser) {
                    if (
                      newValue.code &&
                      newValue.code === MasterCategoryCode.ExternalTag
                    ) {
                      setSelectedViewLevel(externalViewLevelValue);
                    } else {
                      setSelectedViewLevel(defaultViewLevelValue);
                    }
                  }
                }}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                }}
                debounceTimeout={500}
                isMultiSelect={false}
                cacheUniqs={cacheUniq}
                onCreateOption={onCreateOption}
                readOnly={false}
              ></CustomSelectPaginateAdd>
            </Grid>
            {!customerPortalUser && (
              <Grid item xs={6}>
                <CustomizedSelect
                  placeholder={defaultDropDownValue.label}
                  options={viewLevelList}
                  isSearchable={false}
                  displayLabel={I18n('ViewLevel.Label')}
                  handleChange={(newValue: BaseModel) =>
                    setSelectedViewLevel(newValue)
                  }
                  value={selectedViewLevel}
                  errorValue={I18n(errors.viewLevel)}
                  errorMessage={I18n(errors.viewLevel)}
                  required={true}
                ></CustomizedSelect>
              </Grid>
            )}
            {!customerPortalUser && (
              <Grid item xs={12}>
                <ViewLevelInfoMessage />
              </Grid>
            )}
            <Grid item xs={12}>
              <FileUpload
                filesLimit={1}
                initialFiles={files}
                onSave={handleSaveUpload}
                showPreviews={true}
                maxFileSize={10485760}
                onDelete={handleFileDelete}
                errorValue={I18n(errors.fileupload)}
                errorMessage={I18n(errors.fileupload)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="neutral"
            variant="contained"
            disableElevation
          >
            {' '}
            {I18n('Common.Cancel')}
          </Button>
          <Button
            onClick={submit}
            autoFocus
            color="primary"
            variant="contained"
            disableElevation
          >
            {I18n('Common.Save')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
