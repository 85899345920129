import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import RouteEnum from 'models/RouteEnum';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import I18n from 'utilities/i18n';
import Link from '@mui/material/Link';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import BasicDetails from './BasicDetails';
import './AddShippingOffer.scss';
import { ShippingOrderDTO, WizardSubmitType } from 'models/shipping-orders';
import ShippingMaterial from '../shipping-orders/ShippingMaterials';
import ConfirmationPopup from 'common/confirmationPopup/confirmationPopup';
import environment from 'environment';
import http from '../../utilities/httpService';
import { toast } from 'react-toastify';
import { ApiResponse, EntityTypeEnum } from 'models/pagination-model';
import { useLocation } from 'react-router-dom';
import ShippingPackageList from '../shipping-orders/ShippingPackage/ShippingPackageList';
import OrderDocumentList from '../shipping-orders/ShippingOrderDocuments/OrderDocumentList';
import { Permission } from 'Permissions';
import { hasPermission } from 'utilities/protectedRoute';
import Note from 'common/notes/Notes';
import { updateTransportCatalogue } from 'components/shipping-orders/ShippingApiService';

export default function AddShippingOffer() {
  const steps = [
    I18n('ShippingOrders.Steps.BasicDetails'),
    I18n('ShippingOrders.Steps.PackageDetails'),
    I18n('ShippingOrders.Steps.Materials'),
    I18n('ShippingOrders.Steps.Documents'),
    I18n('ShippingOrders.Steps.Notes'),
  ];
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const handleListClick = () => {
    navigate(RouteEnum.ShippingOffersList);
  };
  const [isDeleteModalOpen, setDeleteModal] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(id);

  const breadcrumbs = [
    <Typography key="3" color="text.primary">
      {I18n('Customer.Shipping')}
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={handleListClick}
        className="cpointer"
      >
        {I18n('Nav.Offer')}
      </Link>
    </Typography>,
    <Typography key="3" color="text.primary">
      {I18n('ShippingOrders.AddOffer')}
    </Typography>,
  ];
  const handleSubmit = async (event: any) => {
    setWizardSubmitType(WizardSubmitType.Submit);
    if (activeStep === 0) {
      setIsBasicDetailsSubmit(true);
      event.preventDefault();
    } else if (activeStep === 2) {
      setIsNotesSubmit(true);
      event.preventDefault();
    } else {
      performWizardSubmit(WizardSubmitType.Submit);
    }
  };
  const [isBasicDetailsSubmit, setIsBasicDetailsSubmit] =
    useState<boolean>(false);
  const [isNotesSubmit, setIsNotesSubmit] = useState<boolean>(false);
  const [basicDetails, setBasicDetails] = useState<ShippingOrderDTO | null>(
    null
  );
  const [wizardSubmitType, setWizardSubmitType] =
    useState<WizardSubmitType | null>(null);
  const [activeStep, setActiveStep] = useState(0); //TODO
  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    handleComplete();
    if (activeStep === 0) {
      setIsBasicDetailsSubmit(true);
      setWizardSubmitType(WizardSubmitType.Next);
    } else if (activeStep === 2) {
      setIsNotesSubmit(true);
      setWizardSubmitType(WizardSubmitType.Next);
    } else {
      setWizardSubmitType(WizardSubmitType.Next);
      performWizardSubmit(WizardSubmitType.Next);
    }
  };

  const performWizardSubmit = (
    action: WizardSubmitType,
    newShippingOfferId: number = 0
  ) => {
    switch (action) {
      case WizardSubmitType.Next:
        const newActiveStep =
          isLastStep() && !allStepsCompleted()
            ? // It's the last step, but not all steps have been completed,
              // find the first step that has been completed
              steps.findIndex((step, i) => !(i in completed))
            : activeStep + 1;
        setActiveStep(newActiveStep);
        break;
      case WizardSubmitType.Previous:
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        break;
      case WizardSubmitType.Submit:
        saveTransportCatalogue(Number(id));
        navigate(
          RouteEnum.ViewShippingOffers +
            '/' +
            (newShippingOfferId > 0 ? newShippingOfferId.toString() : id) +
            '/2'
        );
        break;
      default:
        break;
    }
  };

  const saveTransportCatalogue = (orderId: number) => {
    updateTransportCatalogue(Number(orderId)).then(
      (response: { result: any }) => {}
    );
  };

  const handleBack = () => {
    if (activeStep === 0) {
      setIsBasicDetailsSubmit(true);
      setWizardSubmitType(WizardSubmitType.Previous);
    } else if (activeStep === 2) {
      setIsNotesSubmit(true);
      setWizardSubmitType(WizardSubmitType.Previous);
    } else {
      setWizardSubmitType(WizardSubmitType.Previous);
      performWizardSubmit(WizardSubmitType.Previous);
    }
  };
  const handleCancelOrder = () => {
    setDeleteModal(true);
  };
  const handleBasicDetailsSubmit = (
    response: ApiResponse<ShippingOrderDTO | null>
  ) => {
    if (response.result) {
      if (id && +id) {
        if (response.result) {
          performWizardSubmit(
            wizardSubmitType ? wizardSubmitType : WizardSubmitType.Next
          );
        }
      } else {
        setBasicDetails(response.data);
        if (response.result && response.data) {
          navigate(RouteEnum.AddShippingOffers + `/${response.data.id}`);
          performWizardSubmit(
            wizardSubmitType ? wizardSubmitType : WizardSubmitType.Next,
            response.data.id
          );
        }
      }
    }
  };

  const handleSetIsSubmit = () => {
    setIsBasicDetailsSubmit(false);
  };

  const handleNoteSetIsSubmit = () => {
    setIsNotesSubmit(false);
    performWizardSubmit(
      wizardSubmitType ? wizardSubmitType : WizardSubmitType.Next
    );
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const handleCloseForDelete = () => {
    setDeleteModal(false);
  };

  const handleOkForDelete = () => {
    setDeleteModal(false);
    deleteData(selectedOrderId);
  };
  const i18nMessages = {
    deletedSuccessfully: I18n('ContactPerson.DeletedSuccessfully'),
    errorOccurred: I18n('ContactPerson.ErrorOccurred'),
  };
  const deleteData = async (id: any) => {
    const apiUrl = new URL(environment.api.baseUrl + 'Shipping/' + id);
    try {
      const result: any = await http.delete(apiUrl.toString());
      if (result != null && result.data != null && result.data.success) {
        toast.success(i18nMessages.deletedSuccessfully);
        navigate(RouteEnum.ShippingOffersList);
      } else {
        toast.error(i18nMessages.errorOccurred);
      }
    } catch (error: any) {}
  };

  return (
    <div>
      <div className="heading-section">
        <div className="heading-section-left">
          <Typography variant="h3" sx={{ mb: 3 }}>
            {I18n('ShippingOrders.AddOffer')}
          </Typography>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>
      </div>
      <div className="main-content-section">
        <form className="form-content">
          <div className="inner-section">
            <Box
              display="flex"
              justifyContent="center"
              className="stepper-outer"
            >
              <Stepper
                nonLinear
                activeStep={activeStep}
                className="stepwizard"
                connector={null}
              >
                {steps.map((label, index) => {
                  const stepProps: { completed?: boolean } = {};
                  const labelProps: {
                    optional?: React.ReactNode;
                    error?: boolean;
                  } = {};
                  if (completed[index]) {
                    stepProps.completed = true;
                  }
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </Box>
            {activeStep === 0 && (
              <BasicDetails
                isSubmit={isBasicDetailsSubmit}
                handleSubmit={handleBasicDetailsSubmit}
                setIsSubmit={handleSetIsSubmit}
                editPermission={Permission.canEditShippingOffers}
                addPermission={Permission.canAddShippingOffers}
                deletePermission={Permission.canDeleteShippingOffers}
              ></BasicDetails>
            )}
            {activeStep === 1 && <ShippingPackageList></ShippingPackageList>}
            {activeStep === 2 && (
              <ShippingMaterial
                isSubmit={isNotesSubmit}
                setIsSubmit={handleNoteSetIsSubmit}
                permission={Permission.canEditShippingOffers}
                shippingOrderId={id ? id : 0}
                isAddShippingMaterial={true}
                isOffer={true}
              ></ShippingMaterial>
            )}
            {activeStep === 3 && (
              <OrderDocumentList
                entityType={EntityTypeEnum.ShippingOrders}
                shippingOrderId={id ? id : 0}
                isSendOfferEmail={true}
              ></OrderDocumentList>
            )}
            {activeStep === 4 && (
              <Note
                permission={Permission.canEditShippingOffers}
                addPermission={Permission.canAddShippingOffers}
                deletePermission={Permission.canDeleteShippingOffers}
                paramsId={id}
                entityType={EntityTypeEnum.ShippingOrders}
              />
            )}
          </div>
          <Box
            display="flex"
            justifyContent="space-between"
            mt={4}
            className="btn-wrap"
          >
            <Box>
              <Button
                color="primary"
                disableElevation
                variant="outlined"
                disabled={activeStep === 0}
                onClick={handleBack}
              >
                {I18n('Common.Previous')}
              </Button>
            </Box>
            {hasPermission(Permission.canAddShippingOffers) ? (
              <Box>
                {hasPermission(Permission.canDeleteShippingOffers) ? (
                  <Button
                    color="neutral"
                    disableElevation
                    variant="contained"
                    disabled={Number(id) && Number(id) > 0 ? false : true}
                    onClick={handleCancelOrder}
                  >
                    {I18n('Common.Cancel')}
                  </Button>
                ) : (
                  <></>
                )}
                <Button
                  color="primary"
                  disableElevation
                  variant="contained"
                  onClick={handleSubmit}
                >
                  {I18n('Common.Submit')}
                </Button>
                <Button
                  color="secondary"
                  disableElevation
                  variant="contained"
                  disabled={activeStep === 4}
                  onClick={handleNext}
                >
                  {I18n('Common.Next')}
                </Button>
              </Box>
            ) : (
              <></>
            )}
          </Box>
        </form>
      </div>

      <ConfirmationPopup
        isOpen={isDeleteModalOpen}
        data={selectedOrderId}
        message={I18n('ShippingOrders.DeleteOfferMessage')}
        handleClose={handleCloseForDelete}
        handleOk={handleOkForDelete}
        title={I18n('ShippingOrders.DeleteOfferTitle')}
      ></ConfirmationPopup>
    </div>
  );
}
