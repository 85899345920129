import React, { Suspense, lazy } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import MainNav from 'common/main-nav/MainNav';
import RouteEnum from 'models/RouteEnum';
import { ToastContainer } from 'react-toastify';
import Image from './assets/images/hazgo-bg.png';
import ForgotPassword from 'components/forgot-password/ForgotPassword';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import ServiceCatalogue from 'components/service-catalogue/ServiceCatalogue';
import ProductCatalogueAdd from 'components/product-catalogue/ProductCatalogueAdd';
import ServiceCatalougeAdd from 'components/service-catalogue/ServiceCatalougeAdd';
import FormCustomer from 'components/customer/FormCustomer';
import CustomerList from 'components/customer/CustomerList';
import { Permission } from 'Permissions';
import AddCustomerDetails from 'components/customer/CustomerAdd';
import AddRoles from 'components/roles/add-role';
import RolesList from 'components/roles/Roles';
import EditRole from 'components/roles/EditRole';
import CustomerApprovalList from 'components/customer-approval/CustomerApprovalList';
import ServiceCatalogueTabs from 'components/service-catalogue/ServiceCatelogueTabs';
import ProductCatalogueList from 'components/product-catalogue/ProductCatalogueList';
import ProductCatelogueTabs from 'components/product-catalogue/ProductCatelogueTabs';
import PartnerList from 'components/partners/PartnerList';
import AddPartnerDetails from 'components/partners/PartnerAdd';
import FormPartner from 'components/partners/FormPartner';
import ResetPassword from 'components/reset-password/ResetPassword';
import TransportCatalogueAdd from 'components/transport-catalogue/TransportCatalogueAdd';
import TransportCatalogueTabs from 'components/transport-catalogue/TransportCatalougeTabs';
import AddShippingOrder from 'components/shipping-orders/AddShippingOrder';
import ShippingOrderList from 'components/shipping-orders/ShippingOrderList';
import ShippingOrderTabs from 'components/shipping-orders/ShippingOrderTabs';
import DGDCreation from 'components/shipping-orders/WrokOrder/DGDCreation';
import ShippingOrderBooking from 'components/shipping-orders/ShippingOrderBooking';
import WorkOrderPackaging from 'components/shipping-orders/WrokOrder/WorkOrderPackaging';
import WorkOrderInbound from 'components/shipping-orders/WorkOrder/WorkOrderInbound';
import WorkOrderDelivery from 'components/shipping-orders/WrokOrder/WorkOrderDelivery';
import DeckPackOrderList from 'components/deckpack-orders/DeckPackOrderList';
import DeckPackOrderTabs from 'components/deckpack-orders/DeckPackOrderTabs';
import SecurityCheckRA from 'components/deckpack-orders/WorkOrders/SecurityCheckRA';
import CustomerReference from 'components/deckpack-orders/WorkOrders/CustomerReference';
import DecPackWOOrderDelivery from 'components/deckpack-orders/WorkOrders/DecPackWOOrderDelivery';
import AddDecpack from 'components/deckpack-orders/AddDecpack';
import DecPackDGDWOCreation from 'components/deckpack-orders/WorkOrders/DecPackDGDWOCreation';
import WoIbo from 'components/deckpack-orders/WorkOrders/WoIBO';
import DecPackWOCollectionData from 'components/deckpack-orders/WorkOrders/DecPackWOCollectionData';
import CheckDocumentations from 'components/deckpack-orders/WorkOrders/CheckDocumentations';
import Execution from 'components/deckpack-orders/WorkOrders/Execution';
import DecPackOBOWOCreation from 'components/deckpack-orders/WorkOrders/DecPackOBOWO';
import WO10 from 'components/deckpack-orders/WorkOrders/WO10';
import WO12 from 'components/deckpack-orders/WorkOrders/WO12';
import DecPackWO6Bis from 'components/deckpack-orders/WorkOrders/DecPackWO6Bis';
import DecPackWOMaterial from 'components/deckpack-orders/WorkOrders/DecPackWOMaterial';
import PhotoCheck from 'components/deckpack-orders/WorkOrders/PhotoCheck';
import WO8 from 'components/deckpack-orders/WorkOrders/WO8';
import CreateWarehouseLabel from 'components/deckpack-orders/WorkOrders/CreateWarehouseLabel';
import MyProfile from 'components/profile/MyProfile';
import DeckPackAllOrderList from 'components/deckpack-orders/DecPackAllOrdersList';
import ShipppingAllOrdersListing from 'components/shipping-orders/shippingAllOrdersListing';
import DeckPackIrregularityContactClient from 'components/deckpack-orders/WorkOrders/DeckPackIrregularityContactClient';
import StateList from 'common/State/StateList';
import AirlineList from 'components/Airline/AirlineList';
import LabelList from 'components/labelMaster/LabelList';
import ExchangeRateList from 'components/exchangeRates/ExchangeRateList';
import ShippingOfferList from 'components/shipping-offers/ShippingOfferList';
import AddShippingOffer from 'components/shipping-offers/AddShippingOffer';
import ShippingOfferTabs from 'components/shipping-offers/ShippingOfferTabs';
import DeckPackOfferList from 'components/decpack-offers/DeckPackOfferList';
import AddDecPackOffer from 'components/decpack-offers/AddDecPackOffer';
import DeckPackOfferTabs from 'components/decpack-offers/DeckPackOfferTabs';

const PublicTracking = lazy(
  () => import('./components/public-tracking/PublicTracking')
);
const Login = lazy(() => import('./components/login/Login'));
const Logout = lazy(() => import('./components/logout/Logout'));
const NotFound = lazy(() => import('./common/not-found/NotFound'));
const ProtectedRoute = lazy(() => import('./utilities/protectedRoute'));

const TransportCatalogueList = lazy(
  () => import('./components/transport-catalogue/TransportCatalogueList')
);
const OrderSetting = lazy(
  () => import('./components/order-setting/OrderSetting')
);
const Zones = lazy(() => import('components/zones/Zones'));
const OrderList = lazy(() => import('components/order/OrderList'));
const OrderAdd = lazy(() => import('components/order/OrderAdd'));
const OrderTypeAdd = lazy(() => import('components/order-type/OrderTypeAdd'));
const OrderTypeList = lazy(() => import('components/order-type/OrderTypeList'));
const ORderTypeEdit = lazy(() => import('components/order-type/OrderTypeEdit'));
const OrderEdit = lazy(() => import('./components/order/OrderEdit'));
const ApiSample = lazy(() => import('./common/api-sample/apiSample'));
const UsersList = lazy(() => import('./common/crud/usersList'));
const TariffCustomsList = lazy(
  () => import('components/tariff-customs/TariffCustomsList')
);
const AddTariff = lazy(() => import('components/tariff-customs/AddTariff'));

const QuestionnaireTabs = lazy(
  () => import('components/questionnaire/QuestionnaireTabs')
);

const QuestionnaireDetail = lazy(
  () => import('components/questionnaire/QuestionnaireDetail')
);
const WorkorderPickup = lazy(
  () => import('components/workorder-pickup/WorkorderPickup')
);
const WorkorderOutbound = lazy(
  () => import('components/shipping-orders/WrokOrder/WorkorderOutbound')
);

const WorkorderDocumentCheck = lazy(
  () => import('components/shipping-orders/WrokOrder/WorkorderDocumentCheck')
);
const WorkorderPhysicalCheck = lazy(
  () => import('components/shipping-orders/WrokOrder/WorkorderPhysicalCheck')
);

const UsersAdd = lazy(() => import('./common/crud/usersAdd'));
const UserDetails = lazy(() => import('./common/crud/userDetails'));
const UsersEdit = lazy(() => import('./common/crud/usersEdit'));

const Home = lazy(() => import('./components/home/Home'));

const Signup = lazy(() => import('./components/signup/Signup'));
const Authorize = lazy(() => import('./common/authorize/Authorize'));
const styles = {
  paperContainer: {
    // height: 70,
    backgroundImage: `url(${Image})`,
  },
};
export default class App extends React.Component {
  state = {
    cognitoGroups: [],
  };

  render() {
    return (
      <React.Fragment>
        <Suspense fallback={<div>Loading...</div>}>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            closeOnClick
            pauseOnHover
          />
          {!window.location.href.includes(RouteEnum.Login) &&
            !window.location.href.includes(RouteEnum.ResetPassword) &&
            !window.location.href.includes(RouteEnum.PublicTracking) && (
              <div className="main-nav-wrap" style={styles.paperContainer}>
                <MainNav />
              </div>
            )}
          <CssBaseline />
          <Container
            fixed
            className={`${
              window.location.href.includes(RouteEnum.Login) ||
              window.location.href.includes(RouteEnum.ResetPassword) ||
              window.location.href.includes(RouteEnum.PublicTracking)
                ? 'disable-gutters'
                : 'main-body-container'
            }`}
          >
            <main>
              <div
                className={`${
                  !window.location.href.includes(RouteEnum.Login) &&
                  !window.location.href.includes(RouteEnum.ResetPassword) &&
                  !window.location.href.includes(RouteEnum.PublicTracking) &&
                  'nav-offset'
                }`}
              >
                <Routes>
                  <Route path={RouteEnum.Signup} element={<Signup />} />
                  <Route path={RouteEnum.Authorize} element={<Authorize />} />
                  <Route path={RouteEnum.Login} element={<Login />} />
                  <Route path={RouteEnum.ApiSample} element={<ApiSample />} />
                  <Route path={RouteEnum.NotFound} element={<NotFound />} />
                  <Route path="*" element={<NotFound />} />
                  <Route
                    path={RouteEnum.ForgotPassword}
                    element={<ForgotPassword />}
                  />
                  <Route
                    path={RouteEnum.ResetPassword}
                    element={<ResetPassword />}
                  />
                  <Route path={RouteEnum.Logout} element={<Logout />} />

                  {/* Accessible for any logged-in user */}
                  <Route element={<ProtectedRoute />}>
                    <Route path={RouteEnum.Home} element={<Home />} />
                    <Route path={RouteEnum.MyProfile} element={<MyProfile />} />
                  </Route>

                  {/* Dec & Pack */}
                  <Route
                    element={
                      <ProtectedRoute
                        permission={Permission.canAddDecPackOrders}
                      />
                    }
                  >
                    <Route
                      path={RouteEnum.AddDeckPackOrders}
                      element={<AddDecpack />}
                    />
                  </Route>
                  <Route
                    element={
                      <ProtectedRoute
                        permission={Permission.canViewDecPackOrders}
                      />
                    }
                  >
                    <Route
                      path={RouteEnum.DecPackOrdersList}
                      element={<DeckPackOrderList />}
                    />
                    <Route
                      path={RouteEnum.AllDecPackOrdersList}
                      element={<DeckPackAllOrderList />}
                    />
                    <Route
                      path={RouteEnum.ViewDecPackOrders + '/:id'}
                      element={<DeckPackOrderTabs />}
                    />
                    <Route
                      path={RouteEnum.ViewDecPackOrders + '/:id' + '/:tabId'}
                      element={<DeckPackOrderTabs />}
                    />
                    <Route
                      path={
                        RouteEnum.ViewDecPackOrders +
                        '/:id' +
                        '/SecurityCheckRA/:woId'
                      }
                      element={<SecurityCheckRA />}
                    />
                    <Route
                      path={RouteEnum.ViewDecPackOrders + '/:id/InBound/:woId'}
                      element={<WoIbo />}
                    />
                    <Route
                      path={RouteEnum.CreateWarehouseLabel + '/:woId/:id'}
                      element={<CreateWarehouseLabel />}
                    />
                    <Route
                      path={
                        RouteEnum.ViewDecPackOrders +
                        '/:id' +
                        '/DGDCreation/:woId'
                      }
                      element={<DecPackDGDWOCreation />}
                    />
                    <Route
                      path={
                        RouteEnum.ViewDecPackOrders +
                        '/:id' +
                        '/DecPackWOCollectionData/:woId'
                      }
                      element={<DecPackWOCollectionData />}
                    />
                    <Route
                      path={
                        RouteEnum.ViewDecPackOrders +
                        '/:id' +
                        '/DecPackWO6Bis/:woId'
                      }
                      element={<DecPackWO6Bis />}
                    />
                    <Route
                      path={
                        RouteEnum.ViewDecPackOrders +
                        '/:id' +
                        '/CheckDocumentations/:woId'
                      }
                      element={<CheckDocumentations />}
                    />
                    <Route
                      path={
                        RouteEnum.ViewDecPackOrders +
                        '/:id' +
                        '/CustomerReference/:woId'
                      }
                      element={<CustomerReference />}
                    />
                    <Route
                      path={
                        RouteEnum.ViewDecPackOrders +
                        '/:id' +
                        '/DecPackWOOrderDelivery/:woId'
                      }
                      element={<DecPackWOOrderDelivery />}
                    />
                    <Route
                      path={
                        RouteEnum.ViewDecPackOrders +
                        '/:id' +
                        '/Execution/:woId'
                      }
                      element={<Execution />}
                    />
                    <Route
                      path={
                        RouteEnum.ViewDecPackOrders +
                        '/:id' +
                        '/DecPackWOMaterial/:woId'
                      }
                      element={<DecPackWOMaterial />}
                    />
                    <Route
                      path={
                        RouteEnum.ViewDecPackOrders +
                        '/:id' +
                        '/PhotoCheck/:woId'
                      }
                      element={<PhotoCheck />}
                    />
                    <Route
                      path={
                        RouteEnum.ViewDecPackOrders + '/:id' + '/OBOWO/:woId'
                      }
                      element={<DecPackOBOWOCreation />}
                    />
                    <Route
                      path={
                        RouteEnum.ViewDecPackOrders + '/:id' + '/WOTEN/:woId'
                      }
                      element={<WO10 />}
                    />
                    <Route
                      path={
                        RouteEnum.ViewDecPackOrders + '/:id' + '/WOTWELVE/:woId'
                      }
                      element={<WO12 />}
                    />
                    <Route
                      path={
                        RouteEnum.ViewDecPackOrders + '/:id' + '/WOEIGHT/:woId'
                      }
                      element={<WO8 />}
                    />
                    <Route
                      path={
                        RouteEnum.ViewDecPackOrders +
                        '/:id/' +
                        RouteEnum.DeckPackIrregularityContactClient +
                        '/:woId'
                      }
                      element={<DeckPackIrregularityContactClient />}
                    />
                  </Route>

                  {/* DecPack Offers */}
                  <Route
                    element={
                      <ProtectedRoute
                        permission={Permission.canViewDecPackOffers}
                      />
                    }
                  >
                    <Route
                      path={RouteEnum.DecPackOffersList}
                      element={<DeckPackOfferList />}
                    />
                    <Route
                      path={RouteEnum.ViewDecPackOffers + '/:id'}
                      element={<DeckPackOfferTabs />}
                    />
                    <Route
                      path={RouteEnum.ViewDecPackOffers + '/:id' + '/:tabId'}
                      element={<DeckPackOfferTabs />}
                    />
                  </Route>
                  <Route
                    element={
                      <ProtectedRoute
                        permission={Permission.canAddDecPackOffers}
                      />
                    }
                  >
                    <Route
                      path={RouteEnum.AddDecPackOffers}
                      element={<AddDecPackOffer />}
                    />
                  </Route>
                  {/* DecPack Offers */}

                  {/* Shipping */}
                  <Route
                    element={
                      <ProtectedRoute
                        permission={Permission.canAddShippingOrders}
                      />
                    }
                  >
                    <Route
                      path={RouteEnum.AddShippingOrders + `/:id`}
                      element={<AddShippingOrder />}
                    />
                  </Route>
                  <Route
                    element={
                      <ProtectedRoute
                        permission={Permission.canViewShippingOrders}
                      />
                    }
                  >
                    <Route
                      path={RouteEnum.ShippingOrdersList}
                      element={<ShippingOrderList />}
                    />
                    <Route
                      path={RouteEnum.AllShippingOrders}
                      element={<ShipppingAllOrdersListing />}
                    />
                    <Route
                      path={RouteEnum.ViewShippingOrders + '/:id'}
                      element={<ShippingOrderTabs />}
                    />
                    <Route
                      path={RouteEnum.ViewShippingOrders + '/:id' + '/:tabId'}
                      element={<ShippingOrderTabs />}
                    />
                    <Route
                      path={
                        RouteEnum.ViewShippingOrders + '/:id' + '/DGD/:woId'
                      }
                      element={<DGDCreation />}
                    />
                    <Route
                      path={
                        RouteEnum.ViewShippingOrders +
                        '/:id' +
                        '/inbound/:woId/:shippingWoId'
                      }
                      element={<WorkOrderInbound />}
                    />
                    <Route
                      path={
                        RouteEnum.ViewShippingOrders +
                        '/:id' +
                        '/Packaging/:woId/:shippingWoId'
                      }
                      element={<WorkOrderPackaging />}
                    />
                    <Route
                      path={
                        RouteEnum.ViewShippingOrders +
                        '/:shippingOrderId' +
                        '/pickup/:workOrderNo/:shippingWoId'
                      }
                      element={<WorkorderPickup />}
                    />
                    <Route
                      path={
                        RouteEnum.ViewShippingOrders +
                        '/:id' +
                        '/Delivery/:woId/:shippingWoId'
                      }
                      element={<WorkOrderDelivery />}
                    />
                    <Route
                      path={
                        RouteEnum.ViewShippingOrders +
                        '/:shippingOrderId' +
                        '/outbound/:workOrderNo/:shippingWoId'
                      }
                      element={<WorkorderOutbound />}
                    />
                    <Route
                      path={
                        RouteEnum.ViewShippingOrders +
                        '/:shippingOrderId' +
                        '/document-check/:workOrderNo/:shippingWoId'
                      }
                      element={<WorkorderDocumentCheck />}
                    />
                    <Route
                      path={
                        RouteEnum.ViewShippingOrders +
                        '/:shippingOrderId' +
                        '/physical-check/:workOrderNo/:shippingWoId'
                      }
                      element={<WorkorderPhysicalCheck />}
                    />
                    <Route
                      path={
                        RouteEnum.ViewShippingOrders + '/:id' + '/Booking/:woId'
                      }
                      element={<ShippingOrderBooking />}
                    />
                  </Route>

                  {/* Shipping Offers */}
                  <Route
                    element={
                      <ProtectedRoute
                        permission={Permission.canAddShippingOffers}
                      />
                    }
                  >
                    <Route
                      path={RouteEnum.AddShippingOffers + `/:id`}
                      element={<AddShippingOffer />}
                    />
                  </Route>
                  <Route
                    element={
                      <ProtectedRoute
                        permission={Permission.canViewShippingOffers}
                      />
                    }
                  >
                    <Route
                      path={RouteEnum.ShippingOffersList}
                      element={<ShippingOfferList />}
                    />
                    <Route
                      path={RouteEnum.ViewShippingOffers + '/:id'}
                      element={<ShippingOfferTabs />}
                    />
                    <Route
                      path={RouteEnum.ViewShippingOffers + '/:id' + '/:tabId'}
                      element={<ShippingOfferTabs />}
                    />
                  </Route>
                  {/* Shipping Offers */}

                  {/* Reference > Customers > Customers */}
                  <Route
                    element={
                      <ProtectedRoute permission={Permission.canViewCustomer} />
                    }
                  >
                    <Route path={''} element={<CustomerList />} />
                    <Route
                      path={RouteEnum.Customer}
                      element={<CustomerList />}
                    />
                  </Route>
                  <Route
                    element={
                      <ProtectedRoute permission={Permission.canViewCustomer} />
                    }
                  >
                    <Route
                      path={RouteEnum.Customer + '/:entityId/:customerType'}
                      element={<FormCustomer />}
                    />
                  </Route>
                  <Route
                    element={
                      <ProtectedRoute permission={Permission.canAddCustomer} />
                    }
                  >
                    <Route
                      path={RouteEnum.CustomerAdd}
                      element={<AddCustomerDetails />}
                    />
                  </Route>

                  {/* Reference > Customers > Unapproved Customers */}
                  <Route
                    element={
                      <ProtectedRoute
                        permission={Permission.canViewCustomerApproval}
                      />
                    }
                  >
                    <Route
                      path={RouteEnum.CustomerApproval}
                      element={<CustomerApprovalList />}
                    />
                    <Route
                      path={
                        RouteEnum.CustomerApproval + '/:entityId/:customerType'
                      }
                      element={<FormCustomer />}
                    />
                  </Route>

                  {/* Reference > Partners */}
                  <Route
                    element={
                      <ProtectedRoute permission={Permission.canViewPartner} />
                    }
                  >
                    <Route
                      path={RouteEnum.PartnerList}
                      element={<PartnerList />}
                    />
                    <Route
                      path={RouteEnum.PartnerList + '/:entityId'}
                      element={<FormPartner />}
                    />
                  </Route>
                  <Route
                    element={
                      <ProtectedRoute permission={Permission.canAddPartner} />
                    }
                  >
                    <Route
                      path={RouteEnum.PartnerAdd}
                      element={<AddPartnerDetails />}
                    />
                  </Route>

                  {/* Reference > Label Master */}
                  <Route
                    element={
                      <ProtectedRoute
                        permission={Permission.canViewLabelMaster}
                      />
                    }
                  >
                    <Route
                      path={RouteEnum.LabelMasterList}
                      element={<LabelList />}
                    />
                  </Route>

                  {/* Configuration > User Management > Users */}
                  <Route
                    element={
                      <ProtectedRoute permission={Permission.canViewUser} />
                    }
                  >
                    <Route path={RouteEnum.UsersList} element={<UsersList />} />
                    <Route
                      path={RouteEnum.UsersEdit + '/:id'}
                      element={<UsersEdit />}
                    />
                    <Route
                      path={RouteEnum.UserDetails + '/:id'}
                      element={<UserDetails />}
                    />
                  </Route>
                  <Route
                    element={
                      <ProtectedRoute permission={Permission.canAddUser} />
                    }
                  >
                    <Route path={RouteEnum.AddUser} element={<UsersAdd />} />
                  </Route>

                  {/* Configuration > User Management > Roles */}
                  <Route
                    element={
                      <ProtectedRoute permission={Permission.canAddRole} />
                    }
                  >
                    <Route path={RouteEnum.AddRole} element={<AddRoles />} />
                  </Route>
                  <Route
                    element={
                      <ProtectedRoute permission={Permission.canViewRole} />
                    }
                  >
                    <Route
                      path={RouteEnum.EditRole + '/:id'}
                      element={<EditRole />}
                    />
                    <Route path={RouteEnum.Roles} element={<RolesList />} />
                  </Route>

                  {/* Configuration > Product Catalogue */}
                  <Route
                    element={
                      <ProtectedRoute
                        permission={
                          Permission.canViewProductCatalogueManagement
                        }
                      />
                    }
                  >
                    <Route
                      path={RouteEnum.ViewProductCatalogue + '/:entityId'}
                      element={<ProductCatelogueTabs />}
                    />
                    <Route
                      path={RouteEnum.ProductCatalogue}
                      element={<ProductCatalogueList />}
                    />
                  </Route>
                  <Route
                    element={
                      <ProtectedRoute
                        permission={Permission.canAddProductCatalogueManagement}
                      />
                    }
                  >
                    <Route
                      path={RouteEnum.ProductCatalogueAdd}
                      element={<ProductCatalogueAdd />}
                    />
                  </Route>

                  {/* Configuration > Service Catalogue */}
                  <Route
                    element={
                      <ProtectedRoute
                        permission={Permission.canViewServiceCatalogue}
                      />
                    }
                  >
                    <Route
                      path={RouteEnum.ServiceCatalogue}
                      element={<ServiceCatalogue />}
                    />
                    <Route
                      path={RouteEnum.ServiceCatalogueAdd}
                      element={<ServiceCatalougeAdd />}
                    />
                    <Route
                      path={RouteEnum.ViewServiceCatalogue + '/:entityId'}
                      element={<ServiceCatalogueTabs />}
                    />
                  </Route>

                  {/* Configuration > Transport Catalogue */}
                  <Route
                    element={
                      <ProtectedRoute
                        permission={Permission.canAddTransportCatalogue}
                      />
                    }
                  >
                    <Route
                      path={RouteEnum.TransportCatalogueAdd}
                      element={<TransportCatalogueAdd />}
                    />
                  </Route>
                  <Route
                    element={
                      <ProtectedRoute
                        permission={Permission.canViewTransportCatalogue}
                      />
                    }
                  >
                    <Route
                      path={RouteEnum.Transport}
                      element={<TransportCatalogueList />}
                    />
                    <Route
                      path={RouteEnum.TransportCatalogueView + '/:entityId'}
                      element={<TransportCatalogueTabs />}
                    />
                  </Route>

                  {/* Configuration > Restrictions */}
                  <Route
                    element={
                      <ProtectedRoute permission={Permission.canViewAirline} />
                    }
                  >
                    <Route path={RouteEnum.Airline} element={<AirlineList />} />
                  </Route>
                  <Route
                    element={
                      <ProtectedRoute permission={Permission.canViewState} />
                    }
                  >
                    <Route path={RouteEnum.State} element={<StateList />} />
                  </Route>

                  {/* Configuration > Zones */}
                  <Route
                    element={
                      <ProtectedRoute permission={Permission.canViewZone} />
                    }
                  >
                    <Route path={RouteEnum.Zones} element={<Zones />} />
                  </Route>

                  {/* Configuration > Questionnaire */}
                  <Route
                    element={
                      <ProtectedRoute
                        permission={Permission.canViewQuestionnaire}
                      />
                    }
                  >
                    <Route
                      path={RouteEnum.Questionnaire}
                      element={<QuestionnaireTabs />}
                    />
                    <Route
                      path={RouteEnum.QuestionnaireDetail + '/:id'}
                      element={<QuestionnaireDetail />}
                    />
                  </Route>

                  {/* Configuration > Tariff Customs */}
                  <Route
                    element={
                      <ProtectedRoute
                        permission={Permission.canViewTariffCustoms}
                      />
                    }
                  >
                    <Route
                      path={RouteEnum.TariffCustoms}
                      element={<TariffCustomsList />}
                    />
                  </Route>
                  <Route
                    element={
                      <ProtectedRoute
                        permission={Permission.canAddTariffCustoms}
                      />
                    }
                  >
                    <Route path={RouteEnum.AddTariff} element={<AddTariff />} />
                  </Route>

                  {/* Configuration > Order Type */}
                  <Route
                    element={
                      <ProtectedRoute
                        permission={Permission.canViewOrderType}
                      />
                    }
                  >
                    <Route
                      path={RouteEnum.OrderTypeEdit + '/:id'}
                      element={<ORderTypeEdit />}
                    />
                    <Route
                      path={RouteEnum.OrderType}
                      element={<OrderTypeList />}
                    />
                  </Route>
                  <Route
                    element={
                      <ProtectedRoute permission={Permission.canAddOrderType} />
                    }
                  >
                    <Route
                      path={RouteEnum.OrderTypeAdd}
                      element={<OrderTypeAdd />}
                    />
                  </Route>

                  {/* Configuration > Purchase Order */}
                  <Route
                    element={
                      <ProtectedRoute permission={Permission.canViewOrder} />
                    }
                  >
                    <Route path={RouteEnum.Order} element={<OrderList />} />
                    <Route
                      path={RouteEnum.OrderEdit + '/:id'}
                      element={<OrderEdit />}
                    />
                  </Route>
                  <Route
                    element={
                      <ProtectedRoute permission={Permission.canAddOrder} />
                    }
                  >
                    <Route path={RouteEnum.AddOrder} element={<OrderAdd />} />
                  </Route>

                  {/* Configuration > Purchase Order Settings */}
                  <Route
                    element={
                      <ProtectedRoute
                        permission={Permission.canViewPurchaseOrderSettings}
                      />
                    }
                  >
                    <Route
                      path={RouteEnum.OrderSetting}
                      element={<OrderSetting />}
                    />
                  </Route>

                  {/* Configuration > Exchange Rates */}
                  <Route
                    element={
                      <ProtectedRoute
                        permission={Permission.canViewExchangeRate}
                      />
                    }
                  >
                    <Route
                      path={RouteEnum.ExchangeRates}
                      element={<ExchangeRateList />}
                    />
                  </Route>

                  {/* Public Tracking Module */}
                  <Route
                    path={RouteEnum.PublicTracking}
                    element={<PublicTracking />}
                  />
                </Routes>
              </div>
            </main>
          </Container>
        </Suspense>
      </React.Fragment>
    );
  }
}
