import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {
  Breadcrumbs,
  Button,
  FormControl,
  InputAdornment,
} from '@mui/material';
import I18n from 'utilities/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faPlus } from '@fortawesome/free-solid-svg-icons';
import LocationList from 'common/location/LocationList';
import ContactPerson from 'common/contactPerson/ContactPerson';
import {
  DocumentEntity,
  EntityTypeEnum,
  CustomerTypesList,
} from 'models/pagination-model';
import Note from 'common/notes/Notes';
import EditCustomerDetails from './detail/EditCustomerDetails';
import Link from '@mui/material/Link';
import { useNavigate, useParams } from 'react-router-dom';
import RouteEnum from 'models/RouteEnum';
import DocumentList from 'common/Document/DocumentList';
import { Permission } from 'Permissions';
import CustomerSpecialPricingList from './specialpricing/CustomerSpecialPricingList';
import { Utilities } from 'utilities/Utilities';

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function FormCustomer() {
  let { entityId, customerType } = useParams();
  let navigate = useNavigate();
  const customerPortalUser = Utilities.getIsCPUser();
  const handleListClick = () => {
    navigate(RouteEnum.Customer);
  };

  const handleUnapprovedCustomerClick = () => {
    if (!customerPortalUser) {
      navigate(RouteEnum.CustomerApproval);
    }
  };

  const breadcrumbs = [
    <Typography key="3" color="text.primary">
      {I18n('Nav.Reference')}
    </Typography>,
    <Typography key="3" color="text.primary">
      {Number(customerType) == Number(CustomerTypesList.ApprovedCustomer) ? (
        <Link
          underline="hover"
          key="1"
          color="inherit"
          onClick={handleListClick}
          className="cpointer"
        >
          {I18n('Nav.Customers')}
        </Link>
      ) : (
        <Link
          underline="hover"
          key="1"
          color="inherit"
          onClick={handleUnapprovedCustomerClick}
          className="cpointer"
        >
          {I18n('Nav.UnApprovedCustomers')}
        </Link>
      )}
    </Typography>,
    <Typography key="3" color="text.primary">
      {I18n('Customer.CustomerDetails')}
    </Typography>,
  ];
  const [value, setValue] = React.useState(0);
  const [showSpecialPricingTab, setShowSpecialPricingTab] =
    React.useState(false);
  const [customerName, setCustomerName] = React.useState('');

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleSpecialPricing = (
    hasSpecialPricing: boolean,
    customerName: string
  ) => {
    setShowSpecialPricingTab(hasSpecialPricing);
    setCustomerName(customerName);
  };

  return (
    <div>
      <div className="heading-section">
        <div className="heading-section-left">
          <Typography variant="h3" sx={{ mb: 3 }}>
            {I18n('Customer.CustomerDetails')}
          </Typography>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>
      </div>
      <div className="main-content-section width-100">
        <Box>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            className="tab-bordered"
          >
            <Tab label={I18n('Customer.Tabs.Details')} {...a11yProps(0)} />
            <Tab
              label={I18n('Customer.Tabs.ContactPerson')}
              {...a11yProps(1)}
            />
            <Tab label={I18n('Customer.Tabs.Location')} {...a11yProps(2)} />
            <Tab label={I18n('Customer.Tabs.Documents')} {...a11yProps(3)} />
            <Tab label={I18n('Customer.Tabs.Notes')} {...a11yProps(4)} />
            {showSpecialPricingTab ? (
              <Tab
                label={I18n('Customer.Tabs.SpecialPricing')}
                {...a11yProps(5)}
              />
            ) : (
              <></>
            )}
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <EditCustomerDetails handleSpecialPricing={handleSpecialPricing} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ContactPerson
            addPermission={
              Number(customerType) == Number(CustomerTypesList.ApprovedCustomer)
                ? Permission.canAddCustomer
                : Permission.canAddCustomerApproval
            }
            editPermission={
              Number(customerType) == Number(CustomerTypesList.ApprovedCustomer)
                ? Permission.canEditCustomer
                : Permission.canEditCustomerApproval
            }
            deletePermission={
              Number(customerType) == Number(CustomerTypesList.ApprovedCustomer)
                ? Permission.canDeleteCustomer
                : Permission.canDeleteCustomerApproval
            }
            entityType={EntityTypeEnum.Customer}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <LocationList
            addPermission={
              Number(customerType) == Number(CustomerTypesList.ApprovedCustomer)
                ? Permission.canAddCustomer
                : Permission.canAddCustomerApproval
            }
            editPermission={
              Number(customerType) == Number(CustomerTypesList.ApprovedCustomer)
                ? Permission.canEditCustomer
                : Permission.canEditCustomerApproval
            }
            deletePermission={
              Number(customerType) == Number(CustomerTypesList.ApprovedCustomer)
                ? Permission.canDeleteCustomer
                : Permission.canDeleteCustomerApproval
            }
            entityType={EntityTypeEnum.Customer}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <DocumentList
            permission={
              Number(customerType) == Number(CustomerTypesList.ApprovedCustomer)
                ? Permission.canEditCustomer
                : Permission.canEditCustomerApproval
            }
            addPermission={
              Number(customerType) == Number(CustomerTypesList.ApprovedCustomer)
                ? Permission.canAddCustomer
                : Permission.canAddCustomerApproval
            }
            deletePermission={
              Number(customerType) == Number(CustomerTypesList.ApprovedCustomer)
                ? Permission.canDeleteCustomer
                : Permission.canDeleteCustomerApproval
            }
            entityType={EntityTypeEnum.Customer}
            paramsId={entityId}
            documentEntityType={DocumentEntity.Customer}
          />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Note
            permission={
              Number(customerType) == Number(CustomerTypesList.ApprovedCustomer)
                ? Permission.canEditCustomer
                : Permission.canEditCustomerApproval
            }
            addPermission={
              Number(customerType) == Number(CustomerTypesList.ApprovedCustomer)
                ? Permission.canAddCustomer
                : Permission.canAddCustomerApproval
            }
            deletePermission={
              Number(customerType) == Number(CustomerTypesList.ApprovedCustomer)
                ? Permission.canDeleteCustomer
                : Permission.canDeleteCustomerApproval
            }
            paramsId={entityId}
            entityType={EntityTypeEnum.Customer}
          />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <CustomerSpecialPricingList customerName={customerName} />
        </TabPanel>
      </div>
    </div>
  );
}
