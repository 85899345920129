import moment from 'moment';

export function getTimezoneOffset(value: Date) {
  return value.getTimezoneOffset() * 60000;
}

export class Utilities {
  static makeLocalAppearUTC = (value: string) => {
    const dateTime = new Date(value);
    const utcFromLocal = new Date(
      dateTime.getTime() + getTimezoneOffset(dateTime)
    );
    return utcFromLocal;
  };

  //Created Common method to convert into hazgo generic datetime format
  static convertHazgoDataTimeFormat(dateTime: string | Date) {
    return moment(dateTime).local().format('DD/MM/YYYY HH:mm');
  }
  //Created Common method to convert into hazgo generic datetime format
  static convertHazgoDataFormat(dateTime: string | Date) {
    return moment(dateTime).format('DD/MM/YYYY');
  }

  /* Time must be 24 hour format to concat date and time */
  static getAsDate(day: string, time: string): Date {
    const date = new Date(day);
    date.setHours(+time.split(':')[0]);
    date.setMinutes(+time.split(':')[1]);
    return date;
  }

  static urltoFile(url: string, filename: string, mimeType: string) {
    return fetch(url)
      .then((res) => {
        return res.arrayBuffer();
      })
      .then((buf) => {
        return new File([buf], filename, { type: mimeType });
      });
  }

  static base64MimeType(encoded: string) {
    let result = null;
    if (typeof encoded !== 'string') {
      return result;
    }
    const mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
    if (mime && mime.length) {
      result = mime[1];
    }
    return result;
  }

  static async getBase64Image(img: string) {
    return await fetch(img).then(async (res) => {
      // Read the Blob as DataURL using the FileReader API
      return await new Promise(async (resolve, reject) => {
        const fr = new FileReader();
        fr.onload = () => {
          resolve(fr.result);
        };
        fr.onerror = reject;
        fr.readAsDataURL(await res.blob());
      });
    });
  }

  static randomNameGenerator() {
    const chars =
      '0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const passwordLength = 12;
    let password = '';
    for (let i = 0; i <= passwordLength; i++) {
      let randomNumber = Math.floor(Math.random() * chars.length);
      password += chars.substring(randomNumber, randomNumber + 1);
    }
    return password;
  }

  static createUUID() {
    function S4() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }
    return (
      S4() +
      S4() +
      '-' +
      S4() +
      '-4' +
      S4().substr(0, 3) +
      '-' +
      S4() +
      '-' +
      S4() +
      S4() +
      S4()
    ).toLowerCase();
  }

  static encodeText(text: string): string {
    const buffer = Buffer.from(text, 'binary');
    const base64 = buffer.toString('base64');
    return base64;
  }

  static decodeText(encodedText: string): string {
    const buffer = Buffer.from(encodedText, 'base64');
    const text = buffer.toString('binary');
    return text;
  }

  static getIsCPUser() {
    let cpUser = '';
    if (localStorage.getItem('cpUser')) {
      cpUser = Utilities.decodeText(String(localStorage.getItem('cpUser')));
    }
    return cpUser === 'true' ? true : false;
  }

  static getTimeZone = () => {
    var offset = new Date().getTimezoneOffset(),
      o = Math.abs(offset);
    return (offset > 0 ? '-' : '') + o;
  };

  static generateUniqueNumber(): string {
    const date = new Date();
    const uniqueNumber = date.valueOf().toString().slice(-5);
    return uniqueNumber;
  }
}
