import { useEffect, useState } from 'react';
import { ITableProps, kaReducer, Table } from 'ka-table';
import { EditingMode } from 'ka-table/enums';
import { DispatchFunc } from 'ka-table/types';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import EditIcon from '@mui/icons-material/Edit';
import { Button } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';

const tablePropsInit: ITableProps = {
  columns: [],
  data: [],
  editingMode: EditingMode.Cell,
  rowKeyField: 'id',
  columnResizing: true,
  rowReordering: true,
};

const DragDropTable = (props: any) => {
  const { columns, data, rowReordering = true, isOrderEnable = true } = props;
  tablePropsInit.columns = columns;
  tablePropsInit.data = data;
  tablePropsInit.rowReordering = rowReordering;

  const [columnChooserProps, changeColumnChooserProps] =
    useState<ITableProps>(tablePropsInit);
  const dispatch: DispatchFunc = (action: any) => {
    changeColumnChooserProps((prevState: ITableProps) =>
      kaReducer(prevState, action)
    );
    if (
      action.rowKeyValue !== undefined &&
      action.targetRowKeyValue !== undefined
    ) {
      props.updateSequence(action);
    }
  };

  const onClickEdit = (row: any) => {
    props.onClick(row);
  };

  const handleLabelClick = (row: any) => {
    props.handleLabelClick(row);
  };

  useEffect(() => {
    tablePropsInit.data = data;
    changeColumnChooserProps(tablePropsInit);
  }, [data]);

  return (
    <div className="rows-reordering">
      <Table
        {...columnChooserProps}
        childComponents={{
          cell: {
            elementAttributes: (props) => ({
              className: props.rowData.executionNotRequired ? 'grayout' : '',
              onClick: (e, extendedEvent) => {
                if (
                  extendedEvent?.childProps?.column?.key ==
                  'executionNotRequired'
                ) {
                  return;
                }

                if (!props.rowData.executionNotRequired && isOrderEnable) {
                  onClickEdit(props.rowData);
                }
              },
            }),
            content: (props: any) => {
              switch (props.column.key) {
                case 'drag':
                  return <DragIndicatorIcon />;
                case 'executionNotRequired':
                  return (
                    <>
                      <input
                        id="demo_box_2"
                        className="css-checkbox"
                        type="checkbox"
                        disabled={!isOrderEnable}
                        checked={props.rowData.executionNotRequired}
                      />
                      {isOrderEnable ? (
                        <label
                          className="css-label"
                          onClick={() => handleLabelClick(props.rowData)}
                        />
                      ) : (
                        <label className="css-label" />
                      )}
                    </>
                  );
                case 'actions':
                  return (
                    <>
                      {!props.rowData.executionNotRequired ? (
                        <GridActionsCellItem
                          onPointerEnterCapture={false}
                          onPointerLeaveCapture={false}
                          placeholder={''}
                          icon={<EditIcon />}
                          label="Edit"
                          disabled={!isOrderEnable}
                          onClick={() => onClickEdit(props.rowData)}
                        />
                      ) : (
                        <></>
                      )}
                    </>
                  );
              }
            },
          },
        }}
        dispatch={dispatch}
      />
    </div>
  );
};

export default DragDropTable;
