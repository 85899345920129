import I18n from 'utilities/i18n';
import { useEffect, useCallback, useState } from 'react';
import http from '../../../utilities/httpService';
import { CommonProps, OrderByDirection } from 'models/pagination-model';
import { Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import InputAdornment from '@mui/material/InputAdornment';
import CustomizedInputs from 'common/formControl/formControl';
import CustomDataGrid from 'common/datagrid/datagrid';
import { useParams } from 'react-router-dom';

import {
  GridColumns,
  GridPaginationInitialState,
  GridSortingInitialState,
  GridSortModel,
} from '@mui/x-data-grid';
import environment from 'environment';
import ConfirmationPopup from 'common/confirmationPopup/confirmationPopup';

function ProductPurchaseList(props: CommonProps) {
  const { entityId } = useParams();

  const [isRestoreModalOpen, setRestoreModal] = useState(false);
  const [selectedId, setId] = useState();

  const [gridData, setGridData] = useState({
    sortOrder: OrderByDirection.Descending,
    sortBy: 'OrderId',
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [10, 20, 50, 100],
    pageSize: 10,
    page: 0,
    searchExpression: '',
  });

  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));

  const getCurrentCheckouts = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl + 'ProductCatalogueCurrentCheckout'
    );
    try {
      apiUrl.searchParams.set('$filter', `ProductCatalogueId~=~${entityId}`);
      apiUrl.searchParams.set('$searchExpression', gridData.searchExpression);
      apiUrl.searchParams.set('$orderby', gridData.sortBy);
      apiUrl.searchParams.set(
        '$skip',
        (gridData.page * gridData.pageSize).toString()
      );
      apiUrl.searchParams.set('$top', gridData.pageSize.toString());
      apiUrl.searchParams.set(
        '$orderbydirection',
        gridData.sortOrder.toString()
      );
      const result = await http.get(apiUrl.toString());
      if (result) {
        updateGridData('totalRows', result.data.totalCount);
        updateGridData('rows', result.data.values);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getCurrentCheckouts();
  }, [
    gridData.page,
    gridData.pageSize,
    gridData.sortBy,
    gridData.sortOrder,
    gridData.searchExpression,
  ]);

  const handleChangePage = (pageNo: number) => {
    updateGridData('page', pageNo);
  };

  const handleChangeBasicSearch = (searchExpression: any) => {
    updateGridData('searchExpression', searchExpression.target.value);
  };

  const handleChangePageSize = (pageSize: number) => {
    updateGridData('pageSize', pageSize);
  };

  type Row = (typeof gridData.rows)[number];
  const restoreProductPurchase = useCallback(
    (id: any) => () => {
      setRestoreModal(true);
      setId(id);
    },
    []
  );

  const columns: GridColumns<Row> = [
    {
      field: 'orderNumber',
      headerName: I18n('ProductCatalogues.CurrentCheckouts.OrderNumber'),
      flex: 1,
    },
    {
      field: 'quantity',
      headerName: I18n('ProductCatalogues.CurrentCheckouts.Quantity'),
      flex: 1,
    },
  ];

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    if (sortModel && sortModel.length) {
      updateGridData('sortBy', sortModel[0].field);
      updateGridData(
        'sortOrder',
        sortModel[0].sort === 'asc'
          ? OrderByDirection.Ascending
          : OrderByDirection.Descending
      );
    }
  }, []);

  const handleCloseForRestore = () => {
    setRestoreModal(false);
  };

  //   const handleOkForRestore = () => {
  //     setRestoreModal(false);
  //     restoreData(selectedId);
  //   };

  return (
    <div>
      <Typography variant="h5" className="inner-heading has-button">
        {I18n('ProductCatalogues.Tabs.CurrentCheckout')}
        <div className="inner-btn-wrap">
          <div className="text-right container-search">
            <div className="search-control-outer">
              <CustomizedInputs
                margin="normal"
                displayLabel=""
                inputType="text"
                placeholderText="Search"
                handleChange={handleChangeBasicSearch}
                className="search-control small-form-control"
                adornmentValue={
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={faSearch} />
                  </InputAdornment>
                }
              />
            </div>
          </div>
        </div>
      </Typography>
      <div className="">
        <div className="content-block table-fix">
          <CustomDataGrid
            rowCount={gridData.totalRows}
            data={gridData.rows}
            columns={columns}
            pageNo={gridData.page}
            pageSize={gridData.pageSize}
            onSortModelChange={handleSortModelChange}
            onPageChange={handleChangePage}
            onPageSizeChange={handleChangePageSize}
            rowsPerPageOptions={gridData.rowsPerPageOptions}
            initialState={{
              sorting: {
                sortModel: [{ field: 'OrderId', sort: 'desc' }],
              } as GridSortingInitialState,
              pagination: {
                page: gridData.page,
                pageSize: gridData.pageSize,
              } as GridPaginationInitialState,
            }}
          ></CustomDataGrid>

          <ConfirmationPopup
            isOpen={isRestoreModalOpen}
            data={selectedId}
            message={I18n('ProductCatalogues.CurrentCheckouts.RestoreMessage')}
            handleClose={handleCloseForRestore}
            //handleOk={handleOkForRestore}
            title={I18n(
              'ProductCatalogues.CurrentCheckouts.RestoreCurrentCheckout'
            )}
          ></ConfirmationPopup>
        </div>
      </div>
    </div>
  );
}

export default ProductPurchaseList;
