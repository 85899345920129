import { useState, useEffect, BaseSyntheticEvent } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import environment from 'environment';
import I18n from 'utilities/i18n';
import http from '../../utilities/httpService';
import { toast } from 'react-toastify';
import RadioGroupInput from 'common/RadioGroup/RadioGroupInput';
import FormControl from 'common/formControl/formControl';
import CustomizedSelect from 'common/CustomSelect/CustomSelect';
import { CardContent, Box, Button, Stack } from '@mui/material';
import {
  BaseModel,
  CheckDocumentationType,
  QuestioType,
  WorkOrderStatus,
  Workorders,
} from 'models/pagination-model';
import FormAccordianSave from 'common/formControl/formAccordianSave';

export default function QuestionStepper(props: any) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);

  const i18nMessages = {
    NoQuestionnaireMapMsg: I18n(
      'DecPackOrders.WorkOrder.NoQuestionnaireMapMsg'
    ),
    SelectAnyOption: I18n('Placeholders.Select'),
    QuestionnaireAnswersSubmitted: I18n(
      'DecPackOrders.QuestionnaireAnswersSubmitted'
    ),
    QuestionnaireAnswersMandatory: I18n(
      'DecPackOrders.QuestionnaireAnswersMandatory'
    ),
    somethingWentWrong: I18n('Common.SomethingWentWrong'),
    requiredErrMsg: I18n('DecPackOrders.WorkOrder.requiredErrMsg'),
    Questionnaire: I18n('DecPackOrders.WorkOrder.Questionnaire'),
  };

  const [questionList, setQuestionList] = useState<any>([]);
  const [answersListing, setAnswersListing] = useState<any>([]);
  const [isAllAnswered, setIsAllAnswered] = useState<boolean>(false);
  const [answerValues, setAnswerValues] = useState<any>({});
  const [errors, setErrors] = useState<any>({});

  // Use effect
  useEffect(() => {
    getAnswersListing();
  }, []);

  useEffect(() => {
    if (props.isQuestionnaireCallSaveApi) {
      handleSubmit();
    }
  }, [props.isQuestionnaireCallSaveApi]);

  useEffect(() => {
    if (props.validateQuestionnaire) {
      let isValid = props.status == WorkOrderStatus.Done ? validate() : true;
      if (isValid) {
        props.handleSuccessQuestionnaireSave();
      } else {
        props.handleErrorQuestionnaireSave();
      }
    }
  }, [props.validateQuestionnaire]);

  const validate = () => {
    let isValid = true;

    if (props.isHidden) {
      return true;
    }

    if (questionList && questionList.length > 0) {
      questionList.forEach((questionDetails: any) => {
        let answerKey =
          questionDetails.id +
          '_' +
          questionDetails.typeCheckId +
          '_' +
          questionDetails.questionnaireId;
        if (answerValues.hasOwnProperty(answerKey)) {
          let ans = answerValues[answerKey];
          if (
            questionDetails.questionTypeId === QuestioType.YesOrNo ||
            questionDetails.questionTypeId === QuestioType.Text
          ) {
            ans = answerValues[answerKey];
            isValid = ans && isValid;
          } else if (questionDetails.questionTypeId === QuestioType.Dropdown) {
            ans = JSON.parse(answerValues[answerKey]);
            isValid = ans && isValid;
          } else if (
            questionDetails.questionTypeId === QuestioType.MultipleChoice
          ) {
            if (answerValues[answerKey]) {
              ans = JSON.parse(answerValues[answerKey]);
              isValid = ans && ans.length > 0 && isValid;
            } else {
              isValid = false;
            }
          }
        } else {
          isValid = false;
        }
      });
    }

    if (!isValid) {
      toast.error(i18nMessages.QuestionnaireAnswersMandatory);
    }

    return isValid;
  };
  const validateNext = (activeQuestionNo: number) => {
    let temp: any = {};
    let isValid = true;
    let activeQuestion = questionList[activeQuestionNo];
    let answerKey =
      activeQuestion.id +
      '_' +
      activeQuestion.typeCheckId +
      '_' +
      activeQuestion.questionnaireId;
    if (!answerValues[answerKey]) {
      temp[answerKey] = i18nMessages.requiredErrMsg;
      isValid = false;
    }
    setErrors(temp);

    return isValid;
  };

  const handleNext = () => {
    if (validateNext(activeStep) || props.readOnly) {
      setIsAllAnswered(activeStep + 1 === questionList.length - 1);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getAnswersListing = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl +
        `WorkOrderAnswer/GetAnswers/${props.orderId}/${props.woId}/${props.woNo}`
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result) {
        if (result && result.data) {
          if (result.data.length > 0) {
            let newAnswerListing: any = [];
            let newAnswerValues: any = [];

            let answersListing = result.data;

            answersListing.forEach((value: any, index: number) => {
              var ansKey =
                value.questionId +
                '_' +
                value.typeCheckId +
                '_' +
                value.questionaireId;
              newAnswerListing[ansKey] = value;
              newAnswerValues[ansKey] = value.answer;
            });
            setAnswersListing(answersListing);
            setAnswerValues(newAnswerValues);
          }
        }
      }
    } catch (error: any) {
      toast.error(i18nMessages.somethingWentWrong);
    }
    await getQuestionsListing();
  };

  const handleSubmit = async () => {
    // if (validate()) {
    if (Object.keys(answerValues).length > 0) {
      let inputAnswersListing: any = [];

      try {
        if (answersListing.length > 0) {
          // PUT API
          Object.keys(answerValues).filter(function (key: string) {
            let answerKey = key.split('_');
            let qId = answerKey?.length > 0 ? answerKey[0] : 0;
            let typeId = answerKey?.length > 1 ? answerKey[1] : 0;
            let questionaireId = answerKey?.length > 2 ? answerKey[2] : 0;
            inputAnswersListing.push({
              workOrderId: Number(props.woId),
              orderId: Number(props.orderId),
              orderType: typeId == 0 ? Workorders.DecPackWOOrderExecution : 0,
              typeCheckId: typeId,
              questionId: Number(qId),
              answer:
                typeof answerValues[key] == 'object'
                  ? JSON.stringify(answerValues[key])
                  : String(answerValues[key]),
              questionaireId: Number(questionaireId),
              id: 0,
            });
          });

          const apiUrl = new URL(
            environment.api.baseUrl +
              `WorkOrderAnswer/${props.orderId}/${props.woId}/${props.woNo}`
          );
          await http.put(apiUrl.toString(), inputAnswersListing);
        } else {
          // POST API
          Object.keys(answerValues).filter(function (key: string) {
            let answerKey = key.split('_');
            let qId = answerKey?.length > 0 ? answerKey[0] : 0;
            let typeId = answerKey?.length > 1 ? answerKey[1] : 0;
            let questionaireId = answerKey?.length > 2 ? answerKey[2] : 0;
            inputAnswersListing.push({
              workOrderId: Number(props.woId),
              orderId: Number(props.orderId),
              orderType: typeId == 0 ? Workorders.DecPackWOOrderExecution : 0,
              typeCheckId: typeId,
              questionId: Number(qId),
              answer:
                typeof answerValues[key] == 'object'
                  ? JSON.stringify(answerValues[key])
                  : String(answerValues[key]),
              questionaireId: Number(questionaireId),
            });
          });

          const apiUrl = new URL(environment.api.baseUrl + 'WorkOrderAnswer');
          await http.post(apiUrl.toString(), inputAnswersListing);
        }
      } catch (error: any) {
        toast.error(i18nMessages.somethingWentWrong);
      }
    }
    // }
  };

  let handleDynamicFieldsChange = (questionDetail: any, newValue: any) => {
    let updatedAnswer;
    if (
      questionDetail.questionTypeId === QuestioType.YesOrNo ||
      questionDetail.questionTypeId === QuestioType.Text
    ) {
      updatedAnswer = newValue.target.value;
    } else if (
      questionDetail.questionTypeId === QuestioType.MultipleChoice ||
      questionDetail.questionTypeId === QuestioType.Dropdown
    ) {
      updatedAnswer = JSON.stringify(newValue);
    }
    let newAnswerValues = answerValues;
    var ansKey =
      questionDetail.id +
      '_' +
      questionDetail.typeCheckId +
      '_' +
      questionDetail.questionnaireId;
    newAnswerValues[ansKey] = updatedAnswer;
    setAnswerValues(newAnswerValues);

    let temp: any = {};
    temp[ansKey] = '';
    setErrors(temp);
  };

  let getAnswerValue = (questionDetails: any) => {
    let strPreviousAnswer = '';
    let answerKey =
      questionDetails.id +
      '_' +
      questionDetails.typeCheckId +
      '_' +
      questionDetails.questionnaireId;
    if (Object.keys(answerValues).length > 0 && answerValues[answerKey]) {
      if (
        questionDetails.questionTypeId === QuestioType.YesOrNo ||
        questionDetails.questionTypeId === QuestioType.Text
      ) {
        strPreviousAnswer = answerValues[answerKey];
      } else if (
        questionDetails.questionTypeId === QuestioType.MultipleChoice ||
        questionDetails.questionTypeId === QuestioType.Dropdown
      ) {
        strPreviousAnswer = answerValues[answerKey]
          ? JSON.parse(answerValues[answerKey])
          : '';
      }
    }
    return strPreviousAnswer;
  };

  const getOptions = (optionsList: any, qId: any) => {
    let options: Array<any> = [];
    if (optionsList.length > 0) {
      optionsList.map((element: any, index: any) => {
        options.push({
          value: element.id,
          label: element.options,
        });
      });
    }
    return options;
  };

  const yesOrNoAnswerList = [
    { value: CheckDocumentationType.Yes.toString(), label: I18n('Common.Yes') },
    { value: CheckDocumentationType.No.toString(), label: I18n('Common.No') },
  ];

  // API calling methods
  const getQuestionsListing = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl +
        `WorkOrderAnswer/GetQuestions/${props.orderId}/${props.woId}/${props.woNo}`
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result && result.data) {
        let questionList = result.data;
        setQuestionList(questionList);
      }
    } catch (error: any) {
      toast.error(i18nMessages.somethingWentWrong);
    }
  };

  const QuestionnaireSection = () => {
    return (
      <>
        {questionList && questionList.length > 0 ? (
          <Box sx={{ flexGrow: 1 }}>
            <Paper
              square
              elevation={0}
              sx={{
                display: 'flex',
                alignItems: 'center',
                height: 50,
                pl: 2,
                bgcolor: 'background.default',
              }}
            >
              <Typography>
                {Number(activeStep) + 1 + ') '}
                {questionList[activeStep].question}
              </Typography>
            </Paper>
            <Box sx={{ height: 255, width: '100%', p: 2 }}>
              {
                <>
                  {questionList[activeStep].questionTypeId ==
                  QuestioType.MultipleChoice ? (
                    <CardContent className="mt-2">
                      {questionList[activeStep].options &&
                      questionList[activeStep].options.length > 0 ? (
                        <CustomizedSelect
                          options={getOptions(
                            questionList[activeStep].options,
                            questionList[activeStep].id
                          )}
                          defaultValue={getAnswerValue(
                            questionList[activeStep]
                          )}
                          placeholder={i18nMessages.SelectAnyOption}
                          id={'answers' + activeStep}
                          key={'answers' + activeStep}
                          name={'answers' + activeStep}
                          isMulti={true}
                          handleChange={(newValue: BaseModel) =>
                            handleDynamicFieldsChange(
                              questionList[activeStep],
                              newValue
                            )
                          }
                          isSearchable={true}
                          errorValue={true}
                          errorMessage={
                            errors[
                              questionList[activeStep].id +
                                '_' +
                                questionList[activeStep].typeCheckId +
                                '_' +
                                questionList[activeStep].questionnaireId
                            ]
                          }
                          readOnly={props.readOnly}
                        ></CustomizedSelect>
                      ) : (
                        <></>
                      )}
                    </CardContent>
                  ) : (
                    <></>
                  )}
                  {questionList[activeStep].questionTypeId ==
                  QuestioType.Dropdown ? (
                    <CardContent className="mt-2">
                      {questionList[activeStep].options &&
                      questionList[activeStep].options.length > 0 ? (
                        <CustomizedSelect
                          options={getOptions(
                            questionList[activeStep].options,
                            questionList[activeStep].id
                          )}
                          defaultValue={getAnswerValue(
                            questionList[activeStep]
                          )}
                          placeholder={i18nMessages.SelectAnyOption}
                          id={'answers' + activeStep}
                          key={'answers' + activeStep}
                          name={'answers' + activeStep}
                          handleChange={(newValue: BaseModel) =>
                            handleDynamicFieldsChange(
                              questionList[activeStep],
                              newValue
                            )
                          }
                          errorValue={true}
                          errorMessage={
                            errors[
                              questionList[activeStep].id +
                                '_' +
                                questionList[activeStep].typeCheckId +
                                '_' +
                                questionList[activeStep].questionnaireId
                            ]
                          }
                          readOnly={props.readOnly}
                        ></CustomizedSelect>
                      ) : (
                        <></>
                      )}
                    </CardContent>
                  ) : (
                    <></>
                  )}
                  {questionList[activeStep].questionTypeId ==
                  QuestioType.YesOrNo ? (
                    <CardContent className="mt-2">
                      <RadioGroupInput
                        defaultValue={getAnswerValue(questionList[activeStep])}
                        options={yesOrNoAnswerList}
                        id={'answers' + activeStep}
                        key={'answers' + activeStep}
                        name={'answers' + activeStep}
                        handleChange={(e: BaseSyntheticEvent) =>
                          handleDynamicFieldsChange(questionList[activeStep], e)
                        }
                        errorValue={true}
                        errorMessage={
                          errors[
                            questionList[activeStep].id +
                              '_' +
                              questionList[activeStep].typeCheckId +
                              '_' +
                              questionList[activeStep].questionnaireId
                          ]
                        }
                        readOnly={props.readOnly}
                      ></RadioGroupInput>
                    </CardContent>
                  ) : (
                    <></>
                  )}
                  {questionList[activeStep].questionTypeId ==
                  QuestioType.Text ? (
                    <CardContent className="mt-2">
                      <FormControl
                        margin="normal"
                        inputType="text"
                        inputProps={{ maxLength: 1000 }}
                        multiline={true}
                        className="mb-0 mt-1"
                        id={'answers' + activeStep}
                        key={'answers' + activeStep}
                        name={'answers' + activeStep}
                        defaultValue={getAnswerValue(questionList[activeStep])}
                        handleChange={(e: any) =>
                          handleDynamicFieldsChange(questionList[activeStep], e)
                        }
                        errorValue={true}
                        errorMessage={
                          errors[
                            questionList[activeStep].id +
                              '_' +
                              questionList[activeStep].typeCheckId +
                              '_' +
                              questionList[activeStep].questionnaireId
                          ]
                        }
                        readOnly={props.readOnly}
                      ></FormControl>
                    </CardContent>
                  ) : (
                    <></>
                  )}
                </>
              }
            </Box>
            <MobileStepper
              variant="text"
              steps={questionList.length}
              position="static"
              activeStep={activeStep}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNext}
                  disabled={activeStep === questionList.length - 1}
                >
                  Next
                  {theme.direction === 'rtl' ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  {theme.direction === 'rtl' ? (
                    <KeyboardArrowRight />
                  ) : (
                    <KeyboardArrowLeft />
                  )}
                  Back
                </Button>
              }
            />
          </Box>
        ) : (
          <>
            <Stack height="100%" alignItems="center" justifyContent="center">
              {i18nMessages.NoQuestionnaireMapMsg}
            </Stack>
          </>
        )}
      </>
    );
  };

  return !props.isHidden ? (
    <FormAccordianSave
      title={i18nMessages.Questionnaire}
      details={QuestionnaireSection()}
      isSaveButton={isAllAnswered}
    ></FormAccordianSave>
  ) : (
    <></>
  );
}
