import { BaseSyntheticEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import I18n from 'utilities/i18n';
import CustomSelectPaginate from 'common/CustomSelect/CustomSelectPaginate';
import {
  AddLocationEntity,
  BaseModel,
  EntityTypeEnum,
  MasterCategoryEnumType,
  ShippingContactType,
} from 'models/pagination-model';
import { toast } from 'react-toastify';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  AddressSegments,
  ShippingOrdersStateModel,
  ShippingOrderStatusDescription,
  ShippingOrderTypeDescription,
  ShippingType,
} from 'models/shipping-orders';
import FormControl from 'common/formControl/formControl';
import DateTimePickerInput from 'common/DatetimePicker/DateTimePickerInput';
import { Moment } from 'moment';
import {
  saveShippingOrder,
  getBasicDetailsById,
  loadCategoryOptions,
  loadContactOptions,
  loadCustomerOptions,
  loadLocationOptions,
  getLocationById,
  loadDestinationCompanyOptions,
  loadRemainingContactOptions,
  getLocations,
  loadPickupCompanyOptions,
} from './ShippingApiService';
import { CustomizedSelect } from 'common/CustomSelect/CustomSelect';
import CustomSelectPaginateAdd from 'common/CustomSelect/CustomSelectPaginateAdd';
import AddLocation from 'common/location/AddLocation';
import { PostCatagoryDropDown } from 'common/DropDownAddAPI/DropDownApiService';
import AddContactPerson from 'common/contactPerson/add-contact-person';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import ViewCustomerModal from 'common/customer/ViewCustomerModal';
import { Permission } from 'Permissions';
import { hasPermission } from 'utilities/protectedRoute';
import { Utilities } from 'utilities/Utilities';
import EditLocation from 'common/location/EditLocation';

export default function BasicDetails(props: any) {
  let { id } = useParams();
  const today = new Date().toISOString();
  const {
    isSubmit,
    handleSubmit,
    setIsSubmit,
    cpContactPerson,
    customerDetails,
  } = props;
  const customerPortalUser = Utilities.getIsCPUser();
  const [cacheUniq, setCacheUniq] = useState(0);
  const [cacheUniqContact, setCacheUniqContact] = useState(0);
  const [cacheUniqPickupContact, setCacheUniqPickupContact] = useState(0);
  const [cacheUniqDeliveryContact, setCacheUniqDeliveryContact] = useState(0);
  const [isEditModalOpen, setEditModal] = useState(false);
  const [locationId, setLocationId] = useState(0);
  const [data, setData] = useState(null);
  const defaultAdditional: any = {
    page: 1,
  };
  const defaultCustomerValue = {
    value: '',
    label: I18n('Placeholders.SelectCustomer'),
  } as BaseModel;
  const defaultLocationValue = {
    value: '',
    label: I18n('Placeholders.SelectLocation'),
  } as BaseModel;
  const defaultContactValue = {
    value: '',
    label: I18n('Placeholders.SelectContact'),
  } as BaseModel;
  const defaultServiceValue = {
    value: '',
    label: I18n('Placeholders.SelectService'),
  } as BaseModel;
  const defaultOrderCategoryValue = {
    value: '',
    label: I18n('Placeholders.SelectCategory'),
  } as BaseModel;
  const defaultShippingTypeValue = {
    value: ShippingType.InOrder.toString(),
    label: I18n('ShippingOrders.InOrder'),
  } as BaseModel;
  const defaultDestinationCompanyValue = {
    value: '',
    label: I18n('Placeholders.SelectDestinationCompany'),
  } as BaseModel;
  const defaultPickupCompanyValue = {
    value: '',
    label: I18n('Placeholders.SelectPickupCompany'),
  } as BaseModel;
  const defaultAddressSegments = {
    country: '',
    city: '',
    postalCode: '',
    address: '',
  } as AddressSegments;
  let shippingTypeList: Array<BaseModel> = [];
  const getShippingTypes = () => {
    ShippingOrderTypeDescription.forEach((value: string, key: number) => {
      shippingTypeList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getShippingTypes();
  let entityStatusList: Array<BaseModel> = [];
  const getStatusList = () => {
    entityStatusList = [];
    ShippingOrderStatusDescription.forEach((value: string, key: number) => {
      entityStatusList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getStatusList();
  const i18nMessages = {
    commonFieldIsRequired: I18n('Common.FieldIsRequired'),
    InvalidDate: I18n('Common.InvalidDate'),
    somethingWentWrong: I18n('Common.SomethingWentWrong'),
    AddSuccess: I18n('ShippingOrders.AddSuccess'),
    UpdateSuccess: I18n('ShippingOrders.UpdateSuccess'),
    inOrderValue: I18n('ShippingOrders.InOrder'),
    inOfferValue: I18n('ShippingOrders.InOffer'),
    PickupDatetimeIsGreaterErr: I18n(
      'ShippingOrders.PickupDatetimeIsGreaterErr'
    ),
    CommonSaveSuccessMsg: I18n('Common.SavedSuccessfully'),
    CommonErrOccuredMsg: I18n('Common.ErrorOccurred'),
    viewCustomer: I18n('Customer.ViewCustomer'),
    SameAsAbove: I18n('Common.SameAsAbove'),
  };
  const [values, setValues] = useState<ShippingOrdersStateModel>({
    customer: defaultCustomerValue,
    customerLocation: defaultLocationValue,
    orderReference: '',
    category: defaultOrderCategoryValue,
    subCategory: '',
    service: defaultServiceValue,
    pickUpCustomer: defaultCustomerValue,
    pickUpCustomerLocation: defaultPickupCompanyValue,
    expectedPickupDateTime: new Date(),
    deliveryCustomer: defaultCustomerValue,
    deliveryLocation: defaultDestinationCompanyValue,
    expectedDeliveryDateTime: new Date(),
    type: defaultShippingTypeValue,
    status: {} as BaseModel,
    isSameAsCustomer: false,
    customerAddress: defaultAddressSegments,
    pickupCustomerAddress: defaultAddressSegments,
    deliveryCustomerAddress: defaultAddressSegments,
    selectedContacts: '',
    deliveryContacts: '',
    pickupContacts: '',
    internalTrackingNumber: '',
    isShippingOfferConverted: false,
  });

  const [errors, setErrors] = useState({
    customer: '',
    customerLocation: '',
    selectedContacts: '',
    category: '',
    subCategory: '',
    service: '',
    pickUpCustomer: '',
    pickUpCustomerLocation: '',
    pickUpContact: '',
    expectedPickupDateTime: '',
    deliveryCustomer: '',
    deliveryLocation: '',
    deliveryContact: '',
    expectedDeliveryDateTime: '',
    status: '',
    type: '',
  });

  const [AddLocationModalOpen, setAddLocationModalOpen] = useState({
    isAddModalOpen: false,
    newLocation: '',
    triggerFrom: AddLocationEntity.Default,
    entityId: '',
    newDestination: '',
    newPickupCompany: '',
  });

  const [AddContactModalOpen, setAddContactModalOpen] = useState({
    isAddModalOpen: false,
    newContact: '',
    triggerFrom: 0,
    entityId: '',
  });

  const [viewCustomerDetails, setViewCustomerDetails] = useState({
    isShowCustomerDetails: false,
    newCustomerName: '',
  });

  const myStyle = {
    position: 'relative' as 'relative',
    top: '20px',
  };

  let getExpectedDatetimeIsGreater = (
    strPickupDatetime: any,
    strDeliveryDatetime: any
  ) => {
    let expectedDatetimeIsGreater = true;
    if (moment(strPickupDatetime) >= moment(strDeliveryDatetime)) {
      expectedDatetimeIsGreater = false;
    }
    return expectedDatetimeIsGreater;
  };

  const validate = (fieldValues: ShippingOrdersStateModel = values) => {
    let temp = { ...errors };
    if ('customer' in fieldValues) {
      temp.customer = fieldValues.customer.value
        ? ''
        : i18nMessages.commonFieldIsRequired;
    }
    if ('customerLocation' in fieldValues) {
      temp.customerLocation = fieldValues.customerLocation.value
        ? ''
        : i18nMessages.commonFieldIsRequired;
    }
    if (!cpContactPerson) {
      if ('service' in fieldValues) {
        temp.service = fieldValues.service.value
          ? ''
          : i18nMessages.commonFieldIsRequired;
      }
      if ('category' in fieldValues) {
        temp.category = fieldValues.category.value
          ? ''
          : i18nMessages.commonFieldIsRequired;
      }
    }
    if ('pickUpCustomer' in fieldValues) {
      temp.pickUpCustomer = fieldValues.pickUpCustomer.value
        ? ''
        : i18nMessages.commonFieldIsRequired;
    }
    if ('pickUpCustomer' in fieldValues) {
      temp.pickUpCustomer = fieldValues.pickUpCustomer.value
        ? ''
        : i18nMessages.commonFieldIsRequired;
    }
    if ('pickUpCustomerLocation' in fieldValues) {
      temp.pickUpCustomerLocation = fieldValues.pickUpCustomerLocation.value
        ? ''
        : i18nMessages.commonFieldIsRequired;
    }
    if ('pickUpContact' in fieldValues) {
      temp.pickUpContact = fieldValues.pickupContacts.value
        ? ''
        : i18nMessages.commonFieldIsRequired;
    }
    if ('expectedPickupDateTime' in fieldValues) {
      temp.expectedPickupDateTime = fieldValues.expectedPickupDateTime
        ? ''
        : i18nMessages.commonFieldIsRequired;

      if (temp.expectedPickupDateTime == '') {
        let isExpectedPickupDateValid = moment(
          fieldValues.expectedPickupDateTime
        ).isValid();
        if (!isExpectedPickupDateValid) {
          temp.expectedPickupDateTime = i18nMessages.InvalidDate;
        }
      }

      if (temp.expectedPickupDateTime == '') {
        let expectedDatetimeIsGreater = getExpectedDatetimeIsGreater(
          fieldValues.expectedPickupDateTime,
          fieldValues.expectedDeliveryDateTime
        );

        temp.expectedPickupDateTime = expectedDatetimeIsGreater
          ? ''
          : i18nMessages.PickupDatetimeIsGreaterErr;
      }
    }
    if ('deliveryCustomer' in fieldValues) {
      temp.deliveryCustomer = fieldValues.deliveryCustomer.value
        ? ''
        : i18nMessages.commonFieldIsRequired;
    }
    if ('deliveryLocation' in fieldValues) {
      temp.deliveryLocation = fieldValues.deliveryLocation.value
        ? ''
        : i18nMessages.commonFieldIsRequired;
    }
    if ('deliveryContact' in fieldValues) {
      temp.deliveryContact = fieldValues.deliveryContacts.value
        ? ''
        : i18nMessages.commonFieldIsRequired;
    }
    if ('expectedDeliveryDateTime' in fieldValues) {
      temp.expectedDeliveryDateTime = fieldValues.expectedDeliveryDateTime
        ? ''
        : i18nMessages.commonFieldIsRequired;

      if (temp.expectedDeliveryDateTime == '') {
        let isExpectedDeliveryDateValid = moment(
          fieldValues.expectedDeliveryDateTime
        ).isValid();
        if (!isExpectedDeliveryDateValid) {
          temp.expectedDeliveryDateTime = i18nMessages.InvalidDate;
        }
      }

      if (temp.expectedDeliveryDateTime == '') {
        let expectedDatetimeIsGreater = getExpectedDatetimeIsGreater(
          fieldValues.expectedPickupDateTime,
          fieldValues.expectedDeliveryDateTime
        );

        temp.expectedDeliveryDateTime = expectedDatetimeIsGreater
          ? ''
          : i18nMessages.PickupDatetimeIsGreaterErr;
      }
    }
    if ('type' in fieldValues) {
      temp.type = fieldValues.type.value
        ? ''
        : i18nMessages.commonFieldIsRequired;
    }
    if ('selectedContacts' in fieldValues) {
      if (fieldValues.selectedContacts.length > 0) {
        temp.selectedContacts = '';
      } else {
        temp.selectedContacts = i18nMessages.commonFieldIsRequired;
      }
    }
    setErrors({
      ...temp,
    });
    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === '');
    }
  };

  useEffect(() => {
    if (id && +id) {
      getBasicDetailsById(+id).then(async (response) => {
        if (response) {
          setValues({
            customer: {
              label: response.customerName,
              value: response.customerId.toString(),
            } as BaseModel,
            customerLocation: {
              label: response.customerLocationName,
              value: response.customerLocationId.toString(),
            } as BaseModel,
            category: {
              label: response.categoryName,
              value: response.categoryId.toString(),
            } as BaseModel,
            service: {
              label: response.serviceName,
              value: response.serviceId.toString(),
            } as BaseModel,
            pickUpCustomer: {
              label: response.pickUpCustomerName,
              value: response.pickUpCustomerId.toString(),
            } as BaseModel,
            pickUpCustomerLocation: {
              label: response.pickUpCustomerLocationName,
              value: response.pickUpCustomerLocationId.toString(),
            } as BaseModel,
            expectedPickupDateTime: response.expectedPickupDateTime,
            deliveryCustomer: {
              label: response.deliveryCustomerName,
              value: response.deliveryCustomerId.toString(),
            } as BaseModel,
            deliveryLocation: {
              label: response.deliveryLocationName,
              value: response.deliveryLocationId.toString(),
            } as BaseModel,
            expectedDeliveryDateTime: response.expectedDeliveryDateTime,
            type: {
              label:
                shippingTypeList &&
                shippingTypeList.length &&
                shippingTypeList.some((x) => +x.value === response.type)
                  ? (shippingTypeList.find((x) => +x.value === response.type)
                      ?.label as string)
                  : '',
              value: Number(response.type).toString(),
            } as BaseModel,
            orderReference: response.orderReference,
            status: {
              label:
                entityStatusList &&
                entityStatusList.length &&
                entityStatusList.some((x) => +x.value === response.status)
                  ? (entityStatusList.find((x) => +x.value === response.status)
                      ?.label as string)
                  : '',
              value: response.status.toString(),
            } as BaseModel,
            isSameAsCustomer: response.isSameAsCustomer,
            isShippingOfferConverted: response.isShippingOfferConverted,
            subCategory: response.subCategory,
            customerAddress:
              response.customerLocationId &&
              response.customerLocationId !== undefined
                ? await getLocationData(Number(response.customerLocationId))
                : defaultAddressSegments,
            pickupCustomerAddress:
              response.pickUpCustomerLocationId &&
              response.pickUpCustomerLocationId !== undefined
                ? await getLocationData(
                    Number(response.pickUpCustomerLocationId)
                  )
                : defaultAddressSegments,
            deliveryCustomerAddress:
              response.deliveryLocationId &&
              response.deliveryLocationId !== undefined
                ? await getLocationData(Number(response.deliveryLocationId))
                : defaultAddressSegments,
            selectedContacts: response?.shippingOrderContacts?.map((x: any) => {
              return {
                label: x.contactPersonName,
                value: x.contactPersonId + '',
              } as BaseModel;
            }),
            deliveryContacts: response?.deliveryContacts?.map((x: any) => {
              return {
                label: x.contactPersonName,
                value: x.contactPersonId + '',
              } as BaseModel;
            }),
            pickupContacts: response?.pickupContacts?.map((x: any) => {
              return {
                label: x.contactPersonName,
                value: x.contactPersonId + '',
              } as BaseModel;
            }),
            internalTrackingNumber: '',
          });
        }
      });
    }
  }, []);

  useEffect(() => {
    if (isSubmit) {
      if (validate()) {
        saveShippingOrder(id && +id ? +id : 0, values, false).then(
          (response) => {
            if (response && response.result) {
              toast.success(
                id && +id ? i18nMessages.UpdateSuccess : i18nMessages.AddSuccess
              );
            } else {
              toast.error(i18nMessages.somethingWentWrong);
            }
            handleSubmit(response);
            setIsSubmit(false);
          }
        );
      } else {
        setIsSubmit(false);
      }
    }
  }, [isSubmit]);

  useEffect(() => {
    if (customerPortalUser) {
      if (customerDetails && customerDetails !== undefined) {
        const cpCustomer = {
          label: customerDetails.name,
          value: customerDetails.id,
        } as BaseModel;

        const cpContact = {
          label: String(
            cpContactPerson.firstName + ' ' + cpContactPerson.lastName
          ),
          value: cpContactPerson.id.toString(),
        } as BaseModel;

        PrePopulateLocation(cpCustomer, cpContact);
      }
    }
  }, [customerDetails]);

  const handleCheckboxChange = async (event: BaseSyntheticEvent) => {
    var isChecked = event.target.checked;

    if (isChecked) {
      if (values.customerLocation && values.customerLocation.value) {
        var location = await getLocationById(+values.customerLocation.value);
        if (location && location?.data) {
          if (location?.data?.pickupCompany) {
            setValues({
              ...values,
              pickUpCustomerLocation: isChecked
                ? {
                    label: location?.data?.pickupCompany,
                    value: location.data.id.toString(),
                  }
                : defaultLocationValue,
              isSameAsCustomer: isChecked ? true : false,
              pickupCustomerAddress: {
                country: isChecked ? values.customerAddress.country : '',
                city: isChecked ? values.customerAddress.city : '',
                postalCode: isChecked ? values.customerAddress.postalCode : '',
                address: isChecked ? values.customerAddress.address : '',
                address2: isChecked ? values.customerAddress.address2 : '',
                street: isChecked ? values.customerAddress.street : '',
              },
              pickupContacts: values.selectedContacts,
            });
          } else {
            setLocationId(location.data.id);
            setData(location.data);
            setEditModal(true);
          }
        }
      }
    } else {
      setValues({
        ...values,
        pickUpCustomerLocation: defaultLocationValue,
        isSameAsCustomer: isChecked,
        pickupCustomerAddress: {
          country: isChecked ? values.customerAddress.country : '',
          city: isChecked ? values.customerAddress.city : '',
          postalCode: isChecked ? values.customerAddress.postalCode : '',
          address: isChecked ? values.customerAddress.address : '',
          address2: isChecked ? values.customerAddress.address2 : '',
          street: isChecked ? values.customerAddress.street : '',
        },
        pickupContacts: [],
      });
    }
  };

  const onCreateCustomerLocation = async (inputValue: any) => {
    if (values?.customer?.value && Number(values.customer.value) > 0) {
      const increaseUniq = (uniq: any) => uniq + 1;
      setCacheUniq(increaseUniq);
      setAddLocationModalOpen({
        isAddModalOpen: true,
        newLocation: inputValue,
        triggerFrom: AddLocationEntity.ShippingCustomerLocation,
        entityId: values.customer.value,
        newDestination: '',
        newPickupCompany: '',
      });
    }
  };
  const onCreateCustomerContact = async (inputValue: any) => {
    if (values?.customer?.value && Number(values.customer.value) > 0) {
      const increaseUniq = (uniq: any) => uniq + 1;
      setCacheUniqContact(increaseUniq);
      setAddContactModalOpen({
        isAddModalOpen: true,
        newContact: inputValue,
        triggerFrom: 1,
        entityId: values.customer.value,
      });
    }
  };

  const onCreatePickUpLocation = async (inputValue: any) => {
    if (
      values?.pickUpCustomer?.value &&
      Number(values.pickUpCustomer.value) > 0
    ) {
      const increaseUniq = (uniq: any) => uniq + 1;
      setCacheUniq(increaseUniq);
      setAddLocationModalOpen({
        isAddModalOpen: true,
        newLocation: '',
        triggerFrom: AddLocationEntity.ShippingPickupLocation,
        entityId: values.pickUpCustomer.value,
        newDestination: '',
        newPickupCompany: inputValue,
      });
    }
  };

  const onCreatePickupContact = async (inputValue: any) => {
    if (
      values?.pickUpCustomer?.value &&
      Number(values.pickUpCustomer.value) > 0
    ) {
      const increaseUniq = (uniq: any) => uniq + 1;
      setCacheUniqPickupContact(increaseUniq);
      setAddContactModalOpen({
        isAddModalOpen: true,
        newContact: inputValue,
        triggerFrom: 2,
        entityId: values.pickUpCustomer.value,
      });
    }
  };

  const onCreateDeliveryLocation = async (inputValue: any) => {
    if (
      values?.deliveryCustomer?.value &&
      Number(values.deliveryCustomer.value) > 0
    ) {
      const increaseUniq = (uniq: any) => uniq + 1;
      setCacheUniq(increaseUniq);
      setAddLocationModalOpen({
        isAddModalOpen: true,
        newLocation: '',
        triggerFrom: AddLocationEntity.ShippingDeliveryLocation,
        entityId: values.deliveryCustomer.value,
        newDestination: inputValue,
        newPickupCompany: '',
      });
    }
  };

  const onCreateDeliveryContact = async (inputValue: any) => {
    if (
      values?.deliveryCustomer?.value &&
      Number(values.deliveryCustomer.value) > 0
    ) {
      const increaseUniq = (uniq: any) => uniq + 1;
      setCacheUniqDeliveryContact(increaseUniq);
      setAddContactModalOpen({
        isAddModalOpen: true,
        newContact: inputValue,
        triggerFrom: 3,
        entityId: values.deliveryCustomer.value,
      });
    }
  };

  const handleCloseForAdd = () => {
    setAddLocationModalOpen({
      isAddModalOpen: false,
      newLocation: '',
      triggerFrom: AddLocationEntity.Default,
      entityId: '',
      newDestination: '',
      newPickupCompany: '',
    });
  };

  const OnSave = async (response: any, triggerFrom: number) => {
    if (response && response?.data) {
      if (triggerFrom === AddLocationEntity.ShippingCustomerLocation) {
        // Added from Customer location Dropdown
        setValues({
          ...values,
          customerLocation: {
            label: `${response.data.street ? `${response.data.street},` : ''}${
              response.data.address
            }${response.data.address2 ? `${response.data.address2},` : ''}${
              response.data.zipCode ? `${response.data.zipCode},` : ''
            }${response.data.city.label},${response.data.country.label}`,
            value: response.data.id,
          } as BaseModel,
          customerAddress:
            response.data.id && response.data.id !== undefined
              ? await getLocationData(Number(response.data.id))
              : defaultAddressSegments,
        });
      } else if (triggerFrom === AddLocationEntity.ShippingPickupLocation) {
        // Added from Pickup location dropdown
        setValues({
          ...values,
          pickUpCustomerLocation: {
            label: `${String(response.data.pickupCompany)}`,
            value: response.data.id,
          } as BaseModel,
          pickupCustomerAddress:
            response.data.id && response.data.id !== undefined
              ? await getLocationData(Number(response.data.id))
              : defaultAddressSegments,
        });
      } else if (triggerFrom === AddLocationEntity.ShippingDeliveryLocation) {
        // Added from delivery location dropdown
        setValues({
          ...values,
          deliveryLocation: {
            label: `${String(response.data.destinationCompany)}`,
            value: response.data.id,
          } as BaseModel,
          deliveryCustomerAddress:
            response.data.id && response.data.id !== undefined
              ? await getLocationData(Number(response.data.id))
              : defaultAddressSegments,
        });
      }
    }
    handleCloseForAdd();
  };

  const OnSaveContact = async (response: any, triggerFrom: number) => {
    if (response && response?.data) {
      if (triggerFrom === 1) {
        setValues({
          ...values,
          selectedContacts: [
            ...values.selectedContacts,
            {
              label: String(
                response.data.firstName + ' ' + response.data.lastName
              ),
              value: response.data.id.toString(),
            } as BaseModel,
          ],
        });
      } else if (triggerFrom === 2) {
        setValues({
          ...values,
          pickupContacts: [
            ...values.pickupContacts,
            {
              label: String(
                response.data.firstName + ' ' + response.data.lastName
              ),
              value: response.data.id.toString(),
            } as BaseModel,
          ],
        });
      } else if (triggerFrom === 3) {
        setValues({
          ...values,
          deliveryContacts: [
            ...values.deliveryContacts,
            {
              label: String(
                response.data.firstName + ' ' + response.data.lastName
              ),
              value: response.data.id.toString(),
            } as BaseModel,
          ],
        });
      }
    }
    handleCloseForAddContact();
  };

  const getLocationData = async (id: number) => {
    let location = await getLocationById(Number(id));
    let addressSegments = defaultAddressSegments;
    if (location?.data) {
      addressSegments = {
        country: location?.data?.country ? location.data.country : '',
        city: location?.data?.city ? location.data.city : '',
        postalCode: location?.data?.zipCode ? location.data.zipCode : '',
        address: `${
          location?.data?.street ? `${location?.data?.street},` : ''
        }${location?.data?.address2 ? `${location?.data?.address2},` : ''}${
          location?.data?.zipCode ? `${location?.data?.zipCode},` : ''
        }${location?.data?.city},${location?.data?.country}`,
        address2: location?.data?.address2 ? location?.data?.address2 : '',
        street: location?.data?.street ? location?.data?.street : '',
      };
    }
    return addressSegments;
  };

  const handleCloseForAddContact = () => {
    setAddContactModalOpen({
      isAddModalOpen: false,
      newContact: '',
      triggerFrom: 0,
      entityId: '',
    });
  };

  const refreshData = async () => {
    handleCloseForAddContact();
  };
  const onCreateOption = async (inputValue: any) => {
    let name = {};
    name['name'] = inputValue;
    name['entityType'] = MasterCategoryEnumType.AllowedOrderCategory;
    const newOption: any = await PostCatagoryDropDown(
      name,
      i18nMessages.CommonSaveSuccessMsg,
      i18nMessages.CommonErrOccuredMsg
    );
    const increaseUniq = (uniq: any) => uniq + 1;
    setCacheUniq(increaseUniq);
    setValues({ ...values, category: newOption });
  };

  const PrePopulateLocation = async (newValue: any, cpContact: any = null) => {
    const response = await getLocations(newValue.value, '', 1);
    const locationData: any = response.Values.filter((x: any) => {
      return x.isMainAddress === true;
    });
    const location = locationData.map((x: any) => {
      return {
        value: x.id.toString(),
        label: `${x.street ? `${x.street},` : ''}${
          x.address2 ? `${x.address2},` : ''
        }${x.zipCode ? `${x.zipCode},` : ''}${x.city},${x.country}`,
      } as BaseModel;
    });
    setValues({
      ...values,
      customerLocation: location[0],
      customerAddress: {
        country: locationData[0]?.country ? locationData[0].country : '',
        city: locationData[0]?.city ? locationData[0].city : '',
        postalCode: locationData[0]?.zipCode ? locationData[0].zipCode : '',
        address: locationData[0]?.address ? locationData[0].address : '',
        address2: locationData[0]?.address2 ? locationData[0].address2 : '',
        street: locationData[0]?.street ? locationData[0].street : '',
      },
      customer: newValue,
      selectedContacts:
        customerPortalUser && cpContact && cpContact !== undefined
          ? [cpContact]
          : [],
      deliveryCustomer: newValue,
      deliveryLocation: defaultDestinationCompanyValue,
      deliveryContacts: [],
      deliveryCustomerAddress: defaultAddressSegments,
      pickUpCustomer: newValue,
      pickUpCustomerLocation: defaultPickupCompanyValue,
      pickupContacts: [],
      pickupCustomerAddress: defaultAddressSegments,
      type: customerPortalUser
        ? ({
            label: i18nMessages.inOrderValue,
            value: String(ShippingType.InOrder),
          } as BaseModel)
        : values.type,
    });

    const increaseUniq = (uniq: any) => uniq + 1;
    setCacheUniqContact(increaseUniq);
    setCacheUniqDeliveryContact(increaseUniq);
    setCacheUniqPickupContact(increaseUniq);
    setCacheUniq(increaseUniq);
  };

  const onCustomerDetails = () => {
    setViewCustomerDetails({
      isShowCustomerDetails: true,
      newCustomerName: '',
    });
  };

  const handleCloseForShowCustomerDetails = () => {
    setViewCustomerDetails({
      isShowCustomerDetails: false,
      newCustomerName: '',
    });
  };

  const handleCloseForEdit = () => {
    setEditModal(false);
    setLocationId(0);
  };

  const OnLocationSave = async () => {
    var location = await getLocationById(locationId);
    if (location && location.data) {
      if (location?.data?.pickupCompany) {
        setValues({
          ...values,
          pickUpCustomerLocation: {
            label: location?.data?.pickupCompany,
            value: location.data.id.toString(),
          },
          isSameAsCustomer: true,
          pickupCustomerAddress: {
            country: location?.data?.country ? location.data.country : '',
            city: location?.data?.city ? location.data.city : '',
            postalCode: location?.data?.zipCode ? location?.data.zipCode : '',
            address: `${
              location?.data?.street ? `${location?.data?.street},` : ''
            }${location?.data?.address2 ? `${location?.data?.address2},` : ''}${
              location?.data?.zipCode ? `${location?.data?.zipCode},` : ''
            }${location?.data?.city},${location?.data?.country}`,
            address2: location?.data?.address2 ? location.data.address2 : '',
            street: location?.data?.street ? location.data.street : '',
          },
          pickupContacts: values.selectedContacts,
        });
      }
    }
    handleCloseForEdit();
  };

  return (
    <div>
      <Typography variant="h5" className="inner-heading">
        {I18n('ShippingOrders.OrderDetails')}
      </Typography>
      <div className="inner-section">
        <Grid container spacing={2} className="grid-wrap ">
          {!customerPortalUser && (
            <Grid item xs={4}>
              <div className="DPCustomerDetails">
                <CustomSelectPaginate
                  required
                  additional={defaultAdditional}
                  value={values.customer}
                  loadOptions={loadCustomerOptions}
                  handleChange={(newValue: BaseModel) => {
                    PrePopulateLocation(newValue);
                  }}
                  placeholder={defaultCustomerValue.label}
                  isSearchable={true}
                  displayLabel={I18n(
                    'ProductCatalogues.ProductConsumptions.CustomerName'
                  )}
                  errorValue={true}
                  errorMessage={errors.customer}
                  debounceTimeout={500}
                  isMultiSelect={false}
                />
                {Number(values.customer.value) > 0 &&
                  hasPermission(Permission.canViewCustomer) && (
                    <Tooltip title={i18nMessages.viewCustomer}>
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        onClick={onCustomerDetails}
                        className={`fa-md icon-edit`}
                      />
                    </Tooltip>
                  )}
              </div>
            </Grid>
          )}
          {!customerPortalUser && (
            <Grid item xs={8}>
              <CustomSelectPaginateAdd
                required
                key={JSON.stringify(values.customerLocation)}
                additional={defaultAdditional}
                value={values.customerLocation}
                loadOptions={(search: string, prevOptions: any, page: any) =>
                  loadLocationOptions(
                    values.customer && +values.customer.value
                      ? +values.customer.value
                      : 0,
                    search,
                    prevOptions,
                    page
                  )
                }
                handleChange={async (newValue: any) => {
                  let location = await getLocationById(Number(newValue.value));
                  setValues({
                    ...values,
                    customerLocation: newValue,
                    customerAddress: {
                      country: location?.data?.country
                        ? location.data.country
                        : '',
                      city: location?.data?.city ? location.data.city : '',
                      postalCode: location?.data?.zipCode
                        ? location.data.zipCode
                        : '',
                      address: location?.data?.address
                        ? location.data.address
                        : '',
                      address2: location?.data?.address2
                        ? location.data.address2
                        : '',
                      street: location?.data?.street
                        ? location.data.street
                        : '',
                    },
                  });
                }}
                placeholder={defaultLocationValue.label}
                isSearchable={true}
                displayLabel={I18n('ShippingOrders.Location')}
                errorValue={true}
                errorMessage={errors.customerLocation}
                debounceTimeout={500}
                isMultiSelect={false}
                cacheUniqs={cacheUniq}
                onCreateOption={onCreateCustomerLocation}
              />
            </Grid>
          )}
          {!customerPortalUser && (
            <Grid item xs={4} md={4} lg={4}>
              <CustomSelectPaginateAdd
                required
                key={cacheUniqContact + 'cc'}
                placeholder={defaultContactValue.label}
                value={values.selectedContacts}
                name="selectedContacts"
                id="selectedContacts"
                loadOptions={(search: string, prevOptions: any, page: any) =>
                  loadRemainingContactOptions(
                    values.customer && +values.customer.value
                      ? +values.customer.value
                      : 0,
                    search,
                    prevOptions,
                    page,
                    id ? id?.toString() : '',
                    ShippingContactType.CustomerContact
                  )
                }
                handleChange={(newValue: any) => {
                  setValues({ ...values, selectedContacts: newValue });
                  if (newValue !== '') {
                    setErrors({
                      ...errors,
                      selectedContacts: '',
                    });
                  }
                }}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                }}
                additional={defaultAdditional}
                isSearchable={true}
                displayLabel={I18n('ShippingOrders.Contact')}
                errorValue={true}
                errorMessage={errors.selectedContacts}
                debounceTimeout={500}
                isMultiSelect={true}
                cacheUniqs={cacheUniqContact}
                onCreateOption={onCreateCustomerContact}
              />
            </Grid>
          )}
          <Grid item xs={4}>
            <FormControl
              margin="normal"
              value={values.orderReference}
              displayLabel={I18n('ShippingOrders.OrderReference')}
              id="OrderReference"
              name="orderReference"
              inputType="text"
              inputProps={{ maxLength: 30 }}
              multiline={false}
              handleChange={(event: BaseSyntheticEvent) =>
                setValues({ ...values, orderReference: event.target.value })
              }
            ></FormControl>
          </Grid>
          {!customerPortalUser && (
            <Grid item xs={4}>
              <CustomSelectPaginate
                required
                additional={defaultAdditional}
                value={values.service}
                loadOptions={(search: string, prevOptions: any, page: any) =>
                  loadCategoryOptions(
                    MasterCategoryEnumType.ShippingService,
                    search,
                    prevOptions,
                    page
                  )
                }
                handleChange={(newValue: any) =>
                  setValues({ ...values, service: newValue })
                }
                placeholder={defaultServiceValue.label}
                isSearchable={true}
                displayLabel={I18n('ShippingOrders.Service')}
                errorValue={true}
                errorMessage={errors.service}
                debounceTimeout={500}
                isMultiSelect={false}
              />
            </Grid>
          )}
          {!customerPortalUser && (
            <>
              <Grid item xs={4}>
                <CustomSelectPaginateAdd
                  required
                  key={JSON.stringify(values.category)}
                  additional={defaultAdditional}
                  value={values.category}
                  loadOptions={(search: string, prevOptions: any, page: any) =>
                    loadCategoryOptions(
                      MasterCategoryEnumType.AllowedOrderCategory,
                      search,
                      prevOptions,
                      page
                    )
                  }
                  handleChange={(newValue: any) =>
                    setValues({ ...values, category: newValue })
                  }
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                  }}
                  placeholder={defaultOrderCategoryValue.label}
                  isSearchable={true}
                  displayLabel={I18n('ShippingOrders.OrderCategory')}
                  errorValue={true}
                  errorMessage={errors.category}
                  debounceTimeout={500}
                  isMultiSelect={false}
                  cacheUniqs={cacheUniq}
                  onCreateOption={onCreateOption}
                ></CustomSelectPaginateAdd>
              </Grid>
              <Grid item xs={4}>
                <FormControl
                  margin="normal"
                  value={values.subCategory}
                  displayLabel={I18n('ShippingOrders.SubCategory')}
                  id="SubCategory"
                  name="subCategory"
                  inputType="text"
                  inputProps={{ maxLength: 30 }}
                  multiline={false}
                  handleChange={(event: BaseSyntheticEvent) =>
                    setValues({ ...values, subCategory: event.target.value })
                  }
                ></FormControl>
              </Grid>
            </>
          )}
        </Grid>
      </div>
      <div className="inner-section">
        <Grid container spacing={2} className="grid-wrap two-col">
          <Grid item xs={6}>
            <Typography variant="h5" className="inner-heading">
              {I18n('ShippingOrders.PickupAddress')}
              <FormControlLabel
                style={{ display: 'contents' }}
                className="mt-0"
                control={
                  <Checkbox
                    name="SameAsAbove"
                    checked={values?.isSameAsCustomer}
                    onChange={handleCheckboxChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label={i18nMessages.SameAsAbove}
              />
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h5" className="inner-heading">
              {I18n('ShippingOrders.DeliveryAddress')}
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2} className="grid-wrap two-col">
          <Grid item xs={6}>
            <Grid container spacing={2} className="grid-wrap two-col">
              {/* <Grid item xs={6}>
                <CustomSelectPaginate
                  required
                  additional={defaultAdditional}
                  value={values.pickUpCustomer}
                  loadOptions={loadCustomerOptions}
                  handleChange={(newValue: BaseModel) => {
                    setValues({
                      ...values,
                      pickUpCustomer: newValue,
                      pickUpCustomerLocation: defaultLocationValue,
                      pickupContacts: [],
                      pickupCustomerAddress: defaultAddressSegments,
                    });
                  }}
                  placeholder={defaultCustomerValue.label}
                  isSearchable={true}
                  displayLabel={I18n('ShippingOrders.PickupFrom')}
                  errorValue={true}
                  errorMessage={errors.pickUpCustomer}
                  debounceTimeout={500}
                  isMultiSelect={false}
                />
              </Grid> */}
              <Grid item xs={6}>
                <CustomSelectPaginateAdd
                  required
                  key={values.pickUpCustomerLocation.value}
                  additional={defaultAdditional}
                  value={values.pickUpCustomerLocation}
                  loadOptions={(search: string, prevOptions: any, page: any) =>
                    loadPickupCompanyOptions(
                      values.pickUpCustomer && +values.pickUpCustomer.value
                        ? +values.pickUpCustomer.value
                        : 0,
                      search,
                      prevOptions,
                      page,
                      true
                    )
                  }
                  handleChange={async (newValue: any) => {
                    let location = await getLocationById(
                      Number(newValue.value)
                    );

                    setValues({
                      ...values,
                      pickUpCustomerLocation: newValue,
                      pickupCustomerAddress: {
                        country: location?.data.country
                          ? location?.data.country
                          : '',
                        city: location?.data.city ? location?.data.city : '',
                        postalCode: location?.data.zipCode
                          ? location?.data.zipCode
                          : '',
                        address: `${
                          location?.data?.street
                            ? `${location?.data?.street},`
                            : ''
                        }${
                          location?.data?.address2
                            ? `${location?.data?.address2},`
                            : ''
                        }${
                          location?.data?.zipCode
                            ? `${location?.data?.zipCode},`
                            : ''
                        }${location?.data?.city},${location?.data?.country}`,
                        address2: location?.data?.address2
                          ? location.data.address2
                          : '',
                        street: location?.data?.street
                          ? location.data.street
                          : '',
                      },
                    });
                  }}
                  placeholder={defaultPickupCompanyValue.label}
                  isSearchable={true}
                  displayLabel={I18n('ShippingOrders.PickupCompany')}
                  errorValue={true}
                  errorMessage={errors.pickUpCustomerLocation}
                  debounceTimeout={500}
                  isMultiSelect={false}
                  cacheUniqs={cacheUniq}
                  onCreateOption={onCreatePickUpLocation}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomSelectPaginateAdd
                  key={cacheUniqPickupContact + '_pc'}
                  additional={defaultAdditional}
                  value={values.pickupContacts}
                  loadOptions={(search: string, prevOptions: any, page: any) =>
                    loadRemainingContactOptions(
                      values.pickUpCustomer && +values.pickUpCustomer.value
                        ? +values.pickUpCustomer.value
                        : 0,
                      search,
                      prevOptions,
                      page,
                      id ? id?.toString() : '',
                      ShippingContactType.PickupContact
                    )
                  }
                  handleChange={(newValue: any) =>
                    setValues({ ...values, pickupContacts: newValue })
                  }
                  placeholder={defaultContactValue.label}
                  isSearchable={true}
                  displayLabel={I18n('ShippingOrders.Contact')}
                  errorValue={true}
                  errorMessage={errors.pickUpContact}
                  debounceTimeout={500}
                  isMultiSelect={true}
                  cacheUniqs={cacheUniqPickupContact}
                  onCreateOption={onCreatePickupContact}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <FormControl
                  margin="normal"
                  value={values.pickupCustomerAddress.address}
                  displayLabel={I18n('ShippingOrders.Location')}
                  id="pickupCustomerLocation"
                  name="pickupCustomerLocation"
                  inputType="text"
                  inputProps={{ maxLength: 250 }}
                  multiline={false}
                  disabled
                ></FormControl>
              </Grid> */}
              <Grid item xs={6}>
                <FormControl
                  margin="normal"
                  value={values.pickupCustomerAddress.street}
                  displayLabel={I18n('CustomerLocation.Address')}
                  id="address"
                  name="address"
                  inputType="text"
                  inputProps={{ maxLength: 30 }}
                  multiline={false}
                  disabled
                ></FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  margin="normal"
                  value={values.pickupCustomerAddress.address2}
                  displayLabel={I18n('CustomerLocation.Address2')}
                  id="address2"
                  name="address2"
                  inputType="text"
                  inputProps={{ maxLength: 30 }}
                  multiline={false}
                  disabled
                ></FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  margin="normal"
                  value={values.pickupCustomerAddress.postalCode}
                  displayLabel={I18n('CustomerLocation.ZipCode')}
                  id="zipCode"
                  name="zipCode"
                  inputType="text"
                  inputProps={{ maxLength: 30 }}
                  multiline={false}
                  disabled
                ></FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  margin="normal"
                  value={values.pickupCustomerAddress.city}
                  displayLabel={I18n('CustomerLocation.City')}
                  id="city"
                  name="city"
                  inputType="text"
                  inputProps={{ maxLength: 30 }}
                  multiline={false}
                  disabled
                ></FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  margin="normal"
                  value={values.pickupCustomerAddress.country}
                  displayLabel={I18n('CustomerLocation.Country')}
                  id="country"
                  name="country"
                  inputType="text"
                  inputProps={{ maxLength: 30 }}
                  multiline={false}
                  disabled
                ></FormControl>
              </Grid>
              <Grid item xs={6}>
                <DateTimePickerInput
                  required
                  displayLabel={I18n('ShippingOrders.ExpectedPickupDateTime')}
                  name="expectedPickupDateTime"
                  value={values.expectedPickupDateTime}
                  defaultValue={today}
                  errorValue={true}
                  errorMessage={errors.expectedPickupDateTime}
                  handleChange={(event: Moment) => {
                    setValues({
                      ...values,
                      expectedPickupDateTime:
                        event != null ? event.toDate() : '',
                    });
                  }}
                ></DateTimePickerInput>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={2} className="grid-wrap two-col">
              <Grid item xs={6}>
                <CustomSelectPaginateAdd
                  required
                  key={values.deliveryLocation.value}
                  additional={defaultAdditional}
                  value={values.deliveryLocation}
                  loadOptions={(search: string, prevOptions: any, page: any) =>
                    loadDestinationCompanyOptions(
                      values.deliveryCustomer && +values.deliveryCustomer.value
                        ? +values.deliveryCustomer.value
                        : 0,
                      search,
                      prevOptions,
                      page
                    )
                  }
                  handleChange={async (newValue: any) => {
                    let location = await getLocationById(
                      Number(newValue.value)
                    );

                    setValues({
                      ...values,
                      deliveryLocation: newValue,
                      deliveryCustomerAddress: {
                        country: location?.data?.country
                          ? location.data.country
                          : '',
                        city: location?.data?.city ? location.data.city : '',
                        postalCode: location?.data?.zipCode
                          ? location.data.zipCode
                          : '',
                        address: `${
                          location?.data?.street
                            ? `${location?.data?.street},`
                            : ''
                        }${
                          location?.data?.address2
                            ? `${location?.data?.address2},`
                            : ''
                        }${
                          location?.data?.zipCode
                            ? `${location?.data?.zipCode},`
                            : ''
                        }${location?.data?.city},${location?.data?.country}`,
                        address2: location?.data?.address2
                          ? location.data.address2
                          : '',
                        street: location?.data?.street
                          ? location.data.street
                          : '',
                      },
                    });
                  }}
                  placeholder={defaultDestinationCompanyValue.label}
                  isSearchable={true}
                  displayLabel={I18n('ShippingOrders.DestinationCompany')}
                  errorValue={true}
                  errorMessage={errors.deliveryLocation}
                  debounceTimeout={500}
                  isMultiSelect={false}
                  cacheUniqs={cacheUniq}
                  onCreateOption={onCreateDeliveryLocation}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomSelectPaginateAdd
                  key={cacheUniqDeliveryContact + '_dc'}
                  additional={defaultAdditional}
                  value={values.deliveryContacts}
                  loadOptions={(search: string, prevOptions: any, page: any) =>
                    loadRemainingContactOptions(
                      values.deliveryCustomer && +values.deliveryCustomer.value
                        ? +values.deliveryCustomer.value
                        : 0,
                      search,
                      prevOptions,
                      page,
                      id ? id?.toString() : '',
                      ShippingContactType.DeliveryContact
                    )
                  }
                  handleChange={(newValue: any) =>
                    setValues({ ...values, deliveryContacts: newValue })
                  }
                  placeholder={defaultContactValue.label}
                  isSearchable={true}
                  displayLabel={I18n('ShippingOrders.Contact')}
                  errorValue={true}
                  errorMessage={errors.deliveryContact}
                  debounceTimeout={500}
                  isMultiSelect={true}
                  cacheUniqs={cacheUniqDeliveryContact}
                  onCreateOption={onCreateDeliveryContact}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <FormControl
                  margin="normal"
                  value={values.deliveryCustomerAddress.address}
                  displayLabel={I18n('ShippingOrders.Location')}
                  id="deliveryCustomerLocation"
                  name="deliveryCustomerLocation"
                  inputType="text"
                  inputProps={{ maxLength: 250 }}
                  multiline={false}
                  disabled
                ></FormControl>
              </Grid> */}
              <Grid item xs={6}>
                <FormControl
                  margin="normal"
                  value={values.deliveryCustomerAddress.street}
                  displayLabel={I18n('CustomerLocation.Address')}
                  id="address"
                  name="address"
                  inputType="text"
                  inputProps={{ maxLength: 30 }}
                  multiline={false}
                  disabled
                ></FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  margin="normal"
                  value={values.deliveryCustomerAddress.address2}
                  displayLabel={I18n('CustomerLocation.Address2')}
                  id="address2"
                  name="address2"
                  inputType="text"
                  inputProps={{ maxLength: 30 }}
                  multiline={false}
                  disabled
                ></FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  margin="normal"
                  value={values.deliveryCustomerAddress.postalCode}
                  displayLabel={I18n('CustomerLocation.ZipCode')}
                  id="zipCode"
                  name="zipCode"
                  inputType="text"
                  inputProps={{ maxLength: 30 }}
                  multiline={false}
                  disabled
                ></FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  margin="normal"
                  value={values.deliveryCustomerAddress.city}
                  displayLabel={I18n('CustomerLocation.City')}
                  id="city"
                  name="city"
                  inputType="text"
                  inputProps={{ maxLength: 30 }}
                  multiline={false}
                  disabled
                ></FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  margin="normal"
                  value={values.deliveryCustomerAddress.country}
                  displayLabel={I18n('CustomerLocation.Country')}
                  id="country"
                  name="country"
                  inputType="text"
                  inputProps={{ maxLength: 30 }}
                  multiline={false}
                  disabled
                ></FormControl>
              </Grid>
              <Grid item xs={6}>
                <DateTimePickerInput
                  required
                  displayLabel={I18n('ShippingOrders.ExpectedDeliveryDateTime')}
                  name="expectedDeliveryDateTime"
                  value={values.expectedDeliveryDateTime}
                  defaultValue={today}
                  errorValue={true}
                  errorMessage={errors.expectedDeliveryDateTime}
                  handleChange={(event: Moment) => {
                    setValues({
                      ...values,
                      expectedDeliveryDateTime:
                        event != null ? event.toDate() : '',
                    });
                  }}
                ></DateTimePickerInput>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <AddContactPerson
        isOpen={AddContactModalOpen.isAddModalOpen}
        editData={null}
        entityType={EntityTypeEnum.Customer}
        entityId={AddContactModalOpen.entityId}
        handleClose={handleCloseForAddContact}
        title={I18n('ContactPerson.AddContactPerson')}
        refreshData={refreshData}
        OnSave={OnSaveContact}
        triggerFrom={AddContactModalOpen.triggerFrom}
        newContact={AddContactModalOpen.newContact}
      ></AddContactPerson>
      <AddLocation
        isOpen={AddLocationModalOpen.isAddModalOpen}
        entityType={EntityTypeEnum.Customer}
        entityId={AddLocationModalOpen.entityId}
        OnSave={OnSave}
        handleClose={handleCloseForAdd}
        title={I18n('CustomerLocation.AddLocation')}
        isHidden={true}
        newLocation={AddLocationModalOpen.newLocation}
        triggerFrom={AddLocationModalOpen.triggerFrom}
        newDestination={AddLocationModalOpen.newDestination}
        newPickupCompany={AddLocationModalOpen.newPickupCompany}
      ></AddLocation>
      <EditLocation
        isOpen={isEditModalOpen}
        entityType={EntityTypeEnum.Customer}
        entityId={+values.customer.value}
        handleClose={handleCloseForEdit}
        OnSave={OnLocationSave}
        data={data}
        title={I18n('CustomerLocation.EditLocation')}
        isfromShippingPickupCompany={true}
      ></EditLocation>
      <ViewCustomerModal
        isOpen={viewCustomerDetails.isShowCustomerDetails}
        entityId={values.customer.value}
        handleClose={handleCloseForShowCustomerDetails}
        title={i18nMessages.viewCustomer}
      />
    </div>
  );
}
