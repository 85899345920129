import { useState } from 'react';
import http from '../../utilities/httpService';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import RouteEnum from 'models/RouteEnum';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import environment from 'environment';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import I18n from 'utilities/i18n';
import {
  BaseModel,
  EntityStatusDescription,
  MasterCategoryEnumType,
  PricingTypeDescription,
  PaginationResponseModel,
  DepartmentSelect,
} from 'models/pagination-model';
import { Customer, ServiceCatalogueStateModel } from 'models/service-catalogue';
import CustomizedSelect, {
  CustomizedSelect as StatusSelect,
} from 'common/CustomSelect/CustomSelect';
import { CustomizedSelect as PricingTypeSelect } from 'common/CustomSelect/CustomSelect';
import CustomizedInputs from 'common/formControl/formControl';
import DecimalInput from 'common/DecimalInput/DecimalInput';
import Link from '@mui/material/Link';
import CustomSelectPaginateAdd from 'common/CustomSelect/CustomSelectPaginateAdd';
import {
  PostCatagoryDropDown,
  loadItemGroupOptions,
  loadLedgerOptions,
} from 'common/DropDownAddAPI/DropDownApiService';
import CustomSelectPaginate from 'common/CustomSelect/CustomSelectPaginate';
import { Checkbox, FormControlLabel } from '@mui/material';

function ServiceCatalougeAdd(props: any) {
  const navigate = useNavigate();
  const handleListClick = () => {
    navigate(RouteEnum.ServiceCatalogue);
  };
  const [cacheUniq, setCacheUniq] = useState(0);
  const breadcrumbs = [
    <Typography key="3" color="text.primary">
      {I18n('Nav.Configuration')}
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={handleListClick}
        className="cpointer"
      >
        {I18n('Nav.ServiceCatalogue')}
      </Link>
    </Typography>,
    <Typography key="3" color="text.primary">
      {I18n('ServiceCatalogues.AddServiceCatalouge')}
    </Typography>,
  ];

  const [errors, setErrors] = useState({
    serviceCode: '',
    category: '',
    description: '',
    serviceName: '',
    shippingStandardPrice: '',
    decPackStandardPrice: '',
    lastCostBuyPrice: '',
    statusId: '',
    pricingType: '',
    ledgerAccount: '',
    itemGroup: '',
    department: '',
  });
  const defaultCategoryValue = {
    value: '0',
    label: I18n('Placeholders.SelectCategory'),
  } as BaseModel;

  const defaultDropDownValue = {
    value: '0',
    label: I18n('Placeholders.Select'),
  } as BaseModel;

  const defaultDepartmentValue = {
    value: '0',
    label: I18n('Placeholders.SelectDepartment'),
  } as BaseModel;

  const [values, setValues] = useState<ServiceCatalogueStateModel>({
    serviceCode: '',
    category: defaultCategoryValue,
    description: '',
    serviceName: '',
    shippingStandardPrice: '',
    decPackStandardPrice: '',
    lastCostBuyPrice: '',
    statusId: 1,
    pricingType: 1,
    ledgerAccount: defaultDropDownValue,
    itemGroup: defaultDropDownValue,
    showInDPBOM: false,
    department: defaultDepartmentValue,
    departmentType: 0,
  });

  const defaultValue = {
    value: '0',
    label: I18n('Placeholders.SelectStatus'),
  } as BaseModel;

  const i18nMessages = {
    addServiceCatalougeSuccess: I18n('ServiceCatalogues.AddSuccess'),
    commonFieldIsRequired: I18n('Common.FieldIsRequired'),
    uniqueServiceCode: I18n('Common.UniqueServiceCode'),
    uniqueServiceName: I18n('Common.UniqueServiceName'),
    CommonSaveSuccessMsg: I18n('Common.SavedSuccessfully'),
    CommonErrOccuredMsg: I18n('Common.ErrorOccurred'),
    LedgerAccountDescription: I18n('LedgerInfo.LedgerAccountDescription'),
    LedgerAccount: I18n('LedgerInfo.LedgerAccount'),
    ItemGroup: I18n('ItemGroupInfo.ItemGroup'),
    ShowInDPBOM: I18n('ProductCatalogues.ShowInDPBOM'),
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (validate()) {
      const apiUrl = new URL(environment.api.baseUrl + 'ServiceCatalogues');
      try {
        var obj = {
          id: 0,
          categoryName: '',
          categoryId:
            values && values?.category && values?.category.value
              ? +values.category.value
              : 0,
          description: values.description,
          serviceCode: values.serviceCode,
          serviceName: values.serviceName,
          shippingStandardPrice: Number(
            values.shippingStandardPrice.replace(/,/g, '.')
          ),
          decPackStandardPrice: Number(
            values.decPackStandardPrice.replace(/,/g, '.')
          ),
          lastCostBuyPrice: Number(values.lastCostBuyPrice.replace(/,/g, '.')),
          statusId: Number(values.statusId),
          externalId: null,
          syncDate: null,
          pricingType: Number(values.pricingType),
          isStorage: false,
          ledgerAccountId: +values.ledgerAccount.value,
          itemGroupId: +values.itemGroup.value,
          showInDPBOM: values.showInDPBOM,
          departmentType: values.departmentType,
        };

        const result: any = await http.post(apiUrl.toString(), obj);
        if (result) {
          toast.success(i18nMessages.addServiceCatalougeSuccess);
          navigate(RouteEnum.ServiceCatalogue);
        }
      } catch (error: any) {
        if (error.response) {
          let temp = { ...errors };
          if (error.response.data) {
            for (const item of error.response.data.errors) {
              if (item.field === 'ServiceCode') {
                temp.serviceCode = i18nMessages.uniqueServiceCode;
              }
              if (item.field === 'ServiceName') {
                temp.serviceName = i18nMessages.uniqueServiceName;
              }
            }
          }
          setErrors({
            ...temp,
          });
        }
      }
    }
  };

  const onStatusChange = (data: any) => {
    setValues({ ...values, statusId: Number(data?.value) });
  };
  const onPricingTypeChange = (data: any) => {
    setValues({ ...values, pricingType: Number(data?.value) });
  };

  const getOrderCategories = async (search: string, pageNo: number) => {
    let response: PaginationResponseModel<Customer>;
    const apiUrl = new URL(
      environment.api.baseUrl + 'Dropdown/GetMasterCategories'
    );
    try {
      let searchExpression = `entityType~=~${MasterCategoryEnumType.ServiceCatalogue}`;
      searchExpression += search ? ` and name~like~${search}` : '';
      apiUrl.searchParams.set('$filter', searchExpression);
      apiUrl.searchParams.set('$orderby', 'name');
      apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
      apiUrl.searchParams.set('$top', '10');
      apiUrl.searchParams.set('$orderbydirection', 'desc');
      const result = await http.get(apiUrl.toString());
      if (result) {
        response = {
          TotalRecords: result.data.totalCount,
          Values: result.data.values,
        } as PaginationResponseModel<Customer>;
      } else {
        response = {
          TotalRecords: 0,
          Values: [],
        } as PaginationResponseModel<Customer>;
      }
    } catch (error) {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<Customer>;
    }
    return response;
  };

  const loadCategoryOptions = async (
    search: string,
    loadedOptions: any,
    { page }: any
  ) => {
    const response = await getOrderCategories(search, page);
    const hasMore = Math.ceil(response.TotalRecords / 10) > page;

    return {
      options: response.Values.map((x) => {
        return {
          value: x.id.toString(),
          label: x.name,
        } as BaseModel;
      }),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const validate = (fieldValues: any = values) => {
    let temp = { ...errors };
    if ('serviceName' in fieldValues) {
      temp.serviceName = fieldValues.serviceName
        ? ''
        : i18nMessages.commonFieldIsRequired;
    }
    if ('category' in fieldValues) {
      if (fieldValues.category === defaultCategoryValue) {
        temp.category = i18nMessages.commonFieldIsRequired;
      } else {
        temp.category = fieldValues.category
          ? ''
          : i18nMessages.commonFieldIsRequired;
      }
    }
    if ('serviceCode' in fieldValues) {
      temp.serviceCode = fieldValues.serviceCode
        ? ''
        : i18nMessages.commonFieldIsRequired;
    }
    if ('shippingStandardPrice' in fieldValues) {
      temp.shippingStandardPrice = fieldValues.shippingStandardPrice
        ? ''
        : i18nMessages.commonFieldIsRequired;
    }
    if ('decPackStandardPrice' in fieldValues) {
      temp.decPackStandardPrice = fieldValues.decPackStandardPrice
        ? ''
        : i18nMessages.commonFieldIsRequired;
    }
    if (
      'ledgerAccount' in fieldValues &&
      values.ledgerAccount.value == defaultDropDownValue.value
    ) {
      temp.ledgerAccount = i18nMessages.commonFieldIsRequired;
    } else {
      temp.ledgerAccount = '';
    }

    if (
      'itemGroup' in fieldValues &&
      values.itemGroup.value == defaultDropDownValue.value
    ) {
      temp.itemGroup = i18nMessages.commonFieldIsRequired;
    } else {
      temp.itemGroup = '';
    }

    if (
      'department' in fieldValues &&
      values.department.value == defaultDropDownValue.value
    ) {
      temp.department = i18nMessages.commonFieldIsRequired;
    } else {
      temp.department = '';
    }

    setErrors({
      ...temp,
    });
    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === '');
    }
  };

  let entityStatusList: Array<BaseModel> = [];
  const getStatusList = () => {
    entityStatusList = [defaultValue];
    EntityStatusDescription.forEach((value: string, key: number) => {
      entityStatusList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getStatusList();

  let pricingTypeList: Array<BaseModel> = [];
  const getPricingTypeList = () => {
    pricingTypeList = [];
    PricingTypeDescription.forEach((value: string, key: number) => {
      pricingTypeList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getPricingTypeList();

  let departmentList: Array<BaseModel> = [];
  const getDepartmentList = () => {};
  DepartmentSelect.forEach((value: string, key: number) => {
    departmentList.push({
      value: key.toString(),
      label: I18n(value),
    } as BaseModel);
  });
  getDepartmentList();

  const onValueChange = (event: any) => {
    const { name, value } = event.target;

    validate({ [name]: value });
    setValues({
      ...values,
      [name.toString()]: value,
    });
  };
  const defaultAdditional: any = {
    page: 1,
  };
  const onCreateOption = async (inputValue: any) => {
    let name = {};
    name['name'] = inputValue;
    name['entityType'] = MasterCategoryEnumType.ServiceCatalogue;
    const newOption: any = await PostCatagoryDropDown(
      name,
      i18nMessages.CommonSaveSuccessMsg,
      i18nMessages.CommonErrOccuredMsg
    );
    const increaseUniq = (uniq: any) => uniq + 1;
    setCacheUniq(increaseUniq);
    setValues({ ...values, category: newOption });
    getOrderCategories('', 1);
  };

  return (
    <div>
      <div className="heading-section">
        <div className="heading-section-left">
          <Typography variant="h3" sx={{ mb: 3 }}>
            {I18n('ServiceCatalogues.AddServiceCatalouge')}
          </Typography>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>
      </div>
      <div className="main-content-section">
        <form className="model-content form-content" onSubmit={handleSubmit}>
          <div className="inner-section">
            <Typography variant="h5" className="inner-heading">
              {I18n('ServiceCatalogues.AddServiceCatalouge')}
            </Typography>
            <Grid container spacing={2} className="grid-wrap">
              <Grid item lg={4} md={8}>
                <CustomizedInputs
                  margin="normal"
                  displayLabel={I18n('ServiceCatalogues.ServiceName')}
                  id="ServiceName"
                  name="serviceName"
                  required
                  inputType="text"
                  inputProps={{ maxLength: 30 }}
                  handleChange={onValueChange}
                  multiline={false}
                  errorValue={true}
                  errorMessage={errors.serviceName}
                ></CustomizedInputs>
              </Grid>
              <Grid item lg={4} md={8}>
                <CustomizedInputs
                  margin="normal"
                  displayLabel={I18n('ServiceCatalogues.ServiceCode')}
                  id="ServiceCode"
                  inputType="text"
                  name="serviceCode"
                  required
                  inputProps={{ maxLength: 10 }}
                  multiline={false}
                  handleChange={onValueChange}
                  errorValue={true}
                  errorMessage={errors.serviceCode}
                ></CustomizedInputs>
              </Grid>
              <Grid item lg={4} md={8}>
                <CustomSelectPaginateAdd
                  additional={defaultAdditional}
                  key={JSON.stringify(values.category)}
                  value={values.category}
                  handleChange={(newValue: any) =>
                    setValues({ ...values, category: newValue })
                  }
                  loadOptions={loadCategoryOptions}
                  isSearchable={true}
                  displayLabel={I18n('ServiceCatalogues.Category')}
                  placeholder={defaultCategoryValue.label}
                  debounceTimeout={500}
                  isMultiSelect={false}
                  cacheUniqs={cacheUniq}
                  onCreateOption={onCreateOption}
                  errorValue={true}
                  errorMessage={errors.category}
                  readOnly={false}
                  required={true}
                ></CustomSelectPaginateAdd>
              </Grid>
              <Grid item lg={4} md={8}>
                <CustomizedSelect
                  required
                  placeholder={defaultDepartmentValue.label}
                  options={departmentList}
                  isSearchable={false}
                  displayLabel={I18n('ServiceCatalogues.Department')}
                  errorValue={errors.department}
                  errorMessage={errors.department}
                  handleChange={(newValue: BaseModel) =>
                    setValues({
                      ...values,
                      department: newValue,
                      departmentType: Number(newValue.value),
                    })
                  }
                ></CustomizedSelect>
              </Grid>
              <Grid item lg={4} md={8}>
                <DecimalInput
                  displayLabel={I18n('ServiceCatalogues.ShippingStandardPrice')}
                  inputProps={{ maxLength: 10 }}
                  required
                  name="shippingStandardPrice"
                  handleChange={(event: any) => {
                    setValues({
                      ...values,
                      shippingStandardPrice: event.target.value,
                    });
                  }}
                  errorValue={true}
                  errorMessage={errors.shippingStandardPrice}
                ></DecimalInput>
              </Grid>
              <Grid item lg={4} md={8}>
                <DecimalInput
                  displayLabel={I18n('ServiceCatalogues.DecPackStandardPrice')}
                  inputProps={{ maxLength: 10 }}
                  required
                  name="decPackStandardPrice"
                  handleChange={(event: any) => {
                    setValues({
                      ...values,
                      decPackStandardPrice: event.target.value,
                    });
                  }}
                  errorValue={true}
                  errorMessage={errors.decPackStandardPrice}
                ></DecimalInput>
              </Grid>
              <Grid item lg={4} md={8}>
                <StatusSelect
                  placeholder={defaultValue.label}
                  options={entityStatusList}
                  isSearchable={false}
                  displayLabel={I18n('Common.Status')}
                  handleChange={(newValue: BaseModel) =>
                    onStatusChange(newValue)
                  }
                  errorValue={true}
                  errorMessage={errors.statusId}
                ></StatusSelect>
              </Grid>
              <Grid item lg={4} md={8}>
                <CustomizedInputs
                  margin="normal"
                  displayLabel={I18n('ServiceCatalogues.Description')}
                  id="Description"
                  name="description"
                  inputType="text"
                  inputProps={{ maxLength: 50 }}
                  multiline={true}
                  handleChange={onValueChange}
                  errorValue={true}
                  errorMessage={errors.description}
                ></CustomizedInputs>
              </Grid>
              <Grid item lg={4} md={8}>
                <PricingTypeSelect
                  defaultValue={pricingTypeList[0]}
                  options={pricingTypeList}
                  isSearchable={false}
                  displayLabel={I18n('ServiceCatalogues.PricingType')}
                  handleChange={(newValue: BaseModel) =>
                    onPricingTypeChange(newValue)
                  }
                ></PricingTypeSelect>
              </Grid>
              <Grid item lg={4} md={8}>
                <DecimalInput
                  displayLabel={I18n('ServiceCatalogues.LastCostBuyPrice')}
                  inputProps={{ maxLength: 10 }}
                  name="lastCostBuyPrice"
                  handleChange={(event: any) => {
                    setValues({
                      ...values,
                      lastCostBuyPrice: event.target.value,
                    });
                  }}
                  errorValue={true}
                  errorMessage={errors.lastCostBuyPrice}
                ></DecimalInput>
              </Grid>
              <Grid item lg={4} md={6} sm={12}>
                <CustomSelectPaginate
                  required
                  readOnly={false}
                  key={values.ledgerAccount.value}
                  additional={defaultAdditional}
                  value={values.ledgerAccount}
                  loadOptions={(search: string, prevOptions: any, page: any) =>
                    loadLedgerOptions(search, prevOptions, page)
                  }
                  handleChange={async (newValue: any) => {
                    setValues({
                      ...values,
                      ledgerAccount: newValue,
                    });
                  }}
                  placeholder={defaultValue.label}
                  isSearchable={true}
                  displayLabel={i18nMessages.LedgerAccount}
                  errorValue={true}
                  errorMessage={errors.ledgerAccount}
                  debounceTimeout={500}
                  isMultiSelect={false}
                />
              </Grid>
              <Grid item lg={4} md={6} sm={12}>
                <CustomSelectPaginate
                  required
                  readOnly={false}
                  key={values.itemGroup.value}
                  additional={defaultAdditional}
                  value={values.itemGroup}
                  loadOptions={(search: string, prevOptions: any, page: any) =>
                    loadItemGroupOptions(search, prevOptions, page)
                  }
                  handleChange={async (newValue: any) => {
                    setValues({
                      ...values,
                      itemGroup: newValue,
                    });
                  }}
                  placeholder={defaultValue.label}
                  isSearchable={true}
                  displayLabel={i18nMessages.ItemGroup}
                  errorValue={true}
                  errorMessage={errors.itemGroup}
                  debounceTimeout={500}
                  isMultiSelect={false}
                />
              </Grid>
              <Grid item lg={4} md={6} sm={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="showInDPBOM"
                      onChange={(event: any) => {
                        setValues({
                          ...values,
                          showInDPBOM: event.target.checked,
                        });
                      }}
                      checked={values.showInDPBOM}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label={i18nMessages.ShowInDPBOM}
                />
              </Grid>
            </Grid>
          </div>

          <div className="btn-wrap form-btn-wrap">
            <Button
              color="neutral"
              disableElevation
              variant="contained"
              onClick={() => navigate(RouteEnum.ServiceCatalogue)}
            >
              {I18n('UsersAdd.Cancel')}
            </Button>
            <Button
              type="submit"
              color="primary"
              disableElevation
              variant="contained"
            >
              {I18n('UsersAdd.Add')}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ServiceCatalougeAdd;
