import I18n from 'utilities/i18n';
import { useEffect, useState } from 'react';
import { EntityTypeEnum } from 'models/pagination-model';
import { Checkbox, FormControlLabel, Grid, Button } from '@mui/material';
import environment from 'environment';
import http from '../../../utilities/httpService';
import { BaseModel, CategorySelect } from 'models/pagination-model';
import { useNavigate, useParams } from 'react-router-dom';
import RouteEnum from 'models/RouteEnum';
import { toast } from 'react-toastify';
import { Permission } from 'Permissions';
import { hasPermission } from 'utilities/protectedRoute';

interface DocumentProps {
  entityType: EntityTypeEnum;
}

function Category(props: DocumentProps) {
  let langList: Array<BaseModel> = [];
  CategorySelect.forEach((value: string, key: number) => {
    langList.push({
      value: key.toString(),
      label: I18n(value),
    } as BaseModel);
  });
  const [checkedState, setCheckedState] = useState(
    new Array(langList.length).fill(false)
  );
  const { entityId } = useParams();
  const [values, setValues] = useState<any>([]);
  const [canEdit, setCanEdit] = useState(
    hasPermission(Permission.canEditPartner)
  );
  let navigate = useNavigate();

  const i18nMessages = {
    savedSuccessfully: I18n('Common.SavedSuccessfully'),
    emptyCatagory: I18n('Partner.Validation.EmptyCatagory'),
  };

  const getCategories = async () => {
    const apiUrl = new URL(environment.api.baseUrl + 'TransportCategories');
    try {
      apiUrl.searchParams.set('$filter', `PartnerId~=~` + entityId);
      apiUrl.searchParams.set('$skip', '0');
      apiUrl.searchParams.set('$top', '5000');
      apiUrl.searchParams.set('$orderbydirection', 'desc');
      const result = await http.get(apiUrl.toString());

      if (result.data.values.length !== 0) {
        setValues(result.data.values);
        const id = result.data.values.map((data: any) => {
          return data.categoryId;
        });

        const updatedCheckedState = checkedState.map((item, index) =>
          id.includes(index + 1) ? true : false
        );
        setCheckedState(updatedCheckedState);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getCategories();
  }, []);

  const oncheckboxchange = (event: any, position: any) => {
    const { checked, id } = event.target;

    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );

    setCheckedState(updatedCheckedState);
    if (values.length !== 0) {
      values.map((data: any) => {
        if (data.categoryId !== Number(id) && checked === true) {
          const d = (obj: any) => obj.categoryId === Number(id);
          const alreadyExist = values.some(d);
          if (!alreadyExist) {
            values.push({
              partnerId: Number(entityId),
              categoryId: Number(id),
            });
          }
        } else if (data.categoryId === Number(id) && checked === false) {
          const index = values.findIndex(
            (x: any) => x.categoryId === Number(id)
          );
          values.splice(index, 1);
        }
        return setValues(values);
      });
    } else {
      values.push({
        partnerId: Number(entityId),
        categoryId: Number(id),
      });
      setValues(values);
    }
  };
  const handleSubmit = async () => {
    const apiUrl = new URL(environment.api.baseUrl + 'TransportCategories');
    try {
      if (values.length !== 0) {
        const result: any = await http.post(apiUrl.toString(), values);
        if (result) {
          toast.success(i18nMessages.savedSuccessfully);
          navigate(RouteEnum.PartnerList);
        }
      } else {
        toast.error(i18nMessages.emptyCatagory);
      }
    } catch (error: any) {}
  };
  return (
    <div>
      {langList.map((data, index) => {
        return (
          <>
            <Grid item lg={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!canEdit}
                    name={data.label}
                    id={data.value}
                    onChange={(e) => oncheckboxchange(e, index)}
                    checked={checkedState[index]}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label={data.label}
              />
            </Grid>
          </>
        );
      })}
      <Grid item lg={6}>
        {canEdit ? (
          <Button
            onClick={handleSubmit}
            autoFocus
            color="primary"
            disableElevation
            variant="contained"
          >
            {I18n('Common.Save')}
          </Button>
        ) : (
          <></>
        )}
      </Grid>
    </div>
  );
}

export default Category;
