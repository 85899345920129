import {
  OrderTrackingResponseDto,
  TrackingTypeDescription,
} from 'models/order-tracking';
import {
  ApiResponse,
  CommonListRequestModel,
  MasterCategoryEnumType,
  OrderByDirection,
  ResponseDTO,
} from 'models/pagination-model';
import http from '../../utilities/httpService';
import environment from 'environment';
import { Customer } from 'models/service-catalogue';
import axios from 'axios';

export async function getTrackings(
  entityId: number,
  request: CommonListRequestModel<OrderTrackingResponseDto>
) {
  let basicSearchExpression = request.basicSearchExpression;
  let advanceSearchExpression = request.advanceSearchExpression;
  const apiUrl = new URL(environment.api.baseUrl + 'OrderTracking');
  try {
    let finalSearchTerm = '';
    if (basicSearchExpression && advanceSearchExpression) {
      finalSearchTerm = `${basicSearchExpression} or ${advanceSearchExpression}`;
    } else if (basicSearchExpression && !advanceSearchExpression) {
      finalSearchTerm = basicSearchExpression;
    } else if (!basicSearchExpression && advanceSearchExpression) {
      finalSearchTerm = advanceSearchExpression;
    }
    apiUrl.searchParams.set('$filter', finalSearchTerm);
    apiUrl.searchParams.set('$orderby', request.gridData.sortBy);
    apiUrl.searchParams.set(
      '$skip',
      (request.gridData.page * request.gridData.pageSize).toString()
    );
    apiUrl.searchParams.set('$top', request.gridData.pageSize.toString());
    apiUrl.searchParams.set(
      '$orderbydirection',
      request.gridData.sortOrder.toString()
    );
    const result = await http.get(apiUrl.toString());
    if (result) {
      request.gridData.rows = result.data.values;
      request.gridData.totalRows = result.data.totalCount;
    }
    return request;
  } catch (error) {}
}
export async function getStatusSuggestions(search: string) {
  const instance = axios.create();
  let token = localStorage.getItem('token');

  let userToken = token && JSON.parse(token);
  const apiUrl = new URL(
    environment.api.baseUrl + 'Dropdown/GetMasterCategories'
  );
  try {
    let searchExpression = `entityType~=~${MasterCategoryEnumType.OrderTrackingStatusSuggesions}`;
    searchExpression += search ? ` and name~like~${search}` : '';
    apiUrl.searchParams.set('$filter', searchExpression);
    apiUrl.searchParams.set('$orderby', 'name');
    apiUrl.searchParams.set('$skip', (0).toString());
    apiUrl.searchParams.set('$top', '100');
    apiUrl.searchParams.set('$orderbydirection', 'asc');
    // const result = await http.get(apiUrl.toString());
    // if (result && result.data && result.data.values) {
    //   return (result.data.values as Array<Customer>).map((z) => z.name);
    // }

    return instance
      .get(apiUrl.toString(), {
        headers: {
          Authorization: 'Bearer ' + userToken,
        },
      })
      .then((response) => {
        if (response && response.data && response.data.values) {
          return (response.data.values as Array<Customer>).map((z) => z.name);
        }
      });
  } catch (error) {}
  return [];
}

export async function saveTracking(
  id: number,
  orderTracking: OrderTrackingResponseDto
) {
  try {
    if (id && +id) {
      const apiUrl = new URL(environment.api.baseUrl + 'OrderTracking/' + id);
      const result: any = await http.put(apiUrl.toString(), orderTracking);
      if (result) {
        return {
          result: result.data,
          data: null,
        } as ApiResponse<null>;
      }
    } else {
      const apiUrl = new URL(environment.api.baseUrl + 'OrderTracking');
      const result: any = await http.post(apiUrl.toString(), orderTracking);
      if (result) {
        return {
          result: true,
          data: result.data as ResponseDTO,
        } as ApiResponse<ResponseDTO>;
      }
    }
  } catch (error: any) {}
  return {
    result: false,
    data: null,
  } as ApiResponse<null>;
}

export async function deleteOrderTrackingApi(id: number) {
  const apiUrl = new URL(environment.api.baseUrl + 'OrderTracking/' + id);
  try {
    const result: any = await http.delete(apiUrl.toString());
    if (result) {
      return {
        result: true,
        data: result.data as boolean,
      } as ApiResponse<boolean>;
    }
  } catch (error: any) {}
  return {
    result: false,
    data: null,
  } as ApiResponse<null>;
}

export async function getPublicTrackingData(
  trackingNumber: string,
  request: CommonListRequestModel<OrderTrackingResponseDto>
) {
  const apiUrl = new URL(
    environment.api.baseUrl +
      'OrderTracking/GetPublicTrackingByTrackingNumber/' +
      trackingNumber
  );
  let basicSearchExpression = request.basicSearchExpression;
  let advanceSearchExpression = request.advanceSearchExpression;
  let finalSearchTerm = '';

  if (basicSearchExpression && advanceSearchExpression) {
    finalSearchTerm = `${basicSearchExpression} or ${advanceSearchExpression}`;
  } else if (basicSearchExpression && !advanceSearchExpression) {
    finalSearchTerm = basicSearchExpression;
  } else if (!basicSearchExpression && advanceSearchExpression) {
    finalSearchTerm = advanceSearchExpression;
  }

  apiUrl.searchParams.set('$filter', finalSearchTerm);
  apiUrl.searchParams.set('$orderby', request.gridData.sortBy);
  apiUrl.searchParams.set(
    '$skip',
    (request.gridData.page * request.gridData.pageSize).toString()
  );
  apiUrl.searchParams.set('$top', request.gridData.pageSize.toString());
  apiUrl.searchParams.set(
    '$orderbydirection',
    request.gridData.sortOrder.toString()
  );

  const trackingInstance = axios.create();
  return trackingInstance.get(apiUrl.toString()).then((result) => {
    if (result) {
      request.gridData.rows = result.data.values;
      request.gridData.totalRows = result.data.totalCount;
    }
    return request;
  });
}
