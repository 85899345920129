import {
  Breadcrumbs,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import RouteEnum from 'models/RouteEnum';
import { Permission } from 'Permissions';
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import I18n from 'utilities/i18n';
import { hasPermission } from 'utilities/protectedRoute';
import {
  getContactCustomerViaEmailById,
  getDeckpackWorkOrderHistory,
  getDecPackById,
  getWorkOrderStatus,
  saveContactCustomerViaEmail,
} from '../DeckPackApiService';
import { Virtuoso } from 'react-virtuoso';
import {
  DeckPackIrregularityContactClientDto,
  DeckPackWoHistoryGroupedMasterValuesDto,
  DeckPackWoHistoryMasterValuesDto,
  DecPackQuestionsDescription,
  DnPWorkOrders,
  FieldMasterType,
  DecPackQuestions,
  DeckPackSecureShipmentDetails,
  DecPackQuestionsDtoStateModel,
} from 'models/deck-pack-orders';
import {
  ActivityOwner,
  BaseModel,
  ImageUploadViewModel,
  OrderType,
  RadioGroupBaseModel,
  WorkOrderStatus,
} from 'models/pagination-model';
import ImageUploadPreview from 'common/ImageUploadPreview/ImageUploadPreview';
import environment from 'environment';
import ApiUrlConstants from 'constants/api.constants';
import FormAccordian from 'common/formControl/formAccordian';
import { Utilities } from 'utilities/Utilities';
import Status from 'common/WoStatus/Status';
import DecpackStatus from './DecpackStatus';
import CreatedByandUpdatedBy from 'common/CreatedByandUpdatedBy/CreatedByandUpdatedBy';

function DeckPackIrregularityContactClient() {
  const { woId, id } = useParams();
  const [historyData, setHistoryData] = useState<
    DeckPackWoHistoryGroupedMasterValuesDto[]
  >([]);
  const [pageSize, setPageSize] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [canEdit, setCanEdit] = useState(
    hasPermission(Permission.canEditDecPackOrders)
  );
  const [isSaveNext, setIsSaveNext] = useState<boolean>(true);
  const [contactCustomerViaEmail, setContactCustomerViaEmail] =
    useState<boolean>(false);
  const [isStatusSaveCallApi, setIsStatusSaveCallApi] =
    useState(false); /* To call Submit APi Status from Status Component */
  const [validateStatus, setValidateStatus] = useState<boolean>(false);
  const [status, setStatus] = useState('');
  const [details, setDetails] = useState<any>({
    createdBy: '',
    createdDate: '',
    updatedBy: '',
    updatedDate: '',
  });
  const [assigneeData, setAssigneeData] = useState<any>({
    assignedToId: '',
    assignedToName: '',
    assignNextToId: '',
    assignNextToName: '',
  });
  const [isMobile, setIsMobile] = useState(false);

  const i18nMessages = {
    title: I18n('IrregularityContactClient.Title'),
    wo4Comment: I18n('IrregularityContactClient.Wo4Comment'),
    MoveToWarehouse: I18n('WOCollectionData.MoveToWarehouse'),
    contactCustomerViaEmail: I18n(
      'IrregularityContactClient.ContactCustomerViaEmail'
    ),
    somethingWentWrong: I18n('Common.SomethingWentWrong'),
    contactCustomerViaEmailSuccess: I18n(
      'IrregularityContactClient.ContactCustomerViaEmailSuccess'
    ),
    yes: I18n('Common.Yes'),
    no: I18n('Common.No'),
    na: I18n('Common.NA'),
    IboNoQuestions: I18n('IrregularityContactClient.IboNoQuestions'),
    Wo3Caption: I18n('IrregularityContactClient.Wo3Caption'),
    Wo6Caption: I18n('IrregularityContactClient.Wo6Caption'),
    WosCaption: I18n('IrregularityContactClient.WosCaption'),
    IrregularityContactClientRequired: I18n(
      'IrregularityContactClient.Required'
    ),
    Wo6CaptionShipmentNA: I18n(
      'IrregularityContactClient.Wo6CaptionShipmentNA'
    ),
    SavedSuccessfully: I18n('Common.SavedSuccessfully'),
  };
  const [secureShipmentDetails, setSecureShipmentDetails] =
    useState<DeckPackSecureShipmentDetails>();
  let masterQuestionListList: Array<BaseModel> = [];
  const getQuestionsList = () => {
    DecPackQuestionsDescription.forEach((value: string, key: number) => {
      masterQuestionListList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getQuestionsList();
  let navigate = useNavigate();
  const breadcrumbs = [
    <Typography key="3" color="text.primary">
      {I18n('Nav.Order')}
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={() => navigate(RouteEnum.DecPackOrdersList)}
        className="cpointer"
      >
        {I18n('Nav.DecPackOrders')}
      </Link>
    </Typography>,

    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={() => navigate(RouteEnum.ViewDecPackOrders + '/' + id + '/1')}
        className="cpointer"
      >
        {I18n('ShippingOrders.WorkOrders')}
      </Link>
    </Typography>,
    <Typography key="3" color="text.primary">
      {i18nMessages.title}
    </Typography>,
  ];
  useEffect(() => {
    getContactCustomerViaEmail();
    getWOStatus();
    getDecPackByDetailsId();
  }, []);

  const getContactCustomerViaEmail = () => {
    getContactCustomerViaEmailById(Number(woId), Number(id)).then((dto) => {
      if (dto && dto.result && dto.data) {
        setContactCustomerViaEmail(dto.data?.isContactCustomerViaMail);
      }
    });
  };

  useEffect(() => {
    getHistoryData();
  }, [page]);

  const handleSubmit = async () => {
    setValidateStatus(true);
    setIsSaveNext(false);
  };

  const getHistoryData = async () => {
    await getDeckpackWorkOrderHistory(
      (page - 1) * pageSize,
      pageSize,
      Number(woId)
    ).then((x) => {
      if (x.result && x.data) {
        setHistoryData([
          ...historyData,
          ...x.data?.deckPackWoHistoryGroupedMasterValues,
        ]);
        setTotalRows(x.data?.totalRecords);
      }
    });
  };
  const loadMore = useCallback(
    async (pageNo: number) => {
      setPage(pageNo);
    },
    [setHistoryData]
  );

  const getDecPackByDetailsId = async () => {
    const response: any = await getDecPackById(id ? +id : 0);
    if (response) {
      setSecureShipmentDetails(response);
    } else {
      toast.error(i18nMessages.somethingWentWrong);
    }
  };

  const appendQuestionNameWithDetails = (question: BaseModel) => {
    switch (Number(question.value)) {
      case DecPackQuestions.WOIBOSecureShipmentQA1:
        return secureShipmentDetails && secureShipmentDetails?.Transporter
          ? `${question.label} ${secureShipmentDetails.Transporter}`
          : `${question.label}`;
      case DecPackQuestions.WOIBOSecureShipmentQA2:
        return secureShipmentDetails && secureShipmentDetails?.LicensePlate
          ? `${question.label} ${secureShipmentDetails.LicensePlate}`
          : `${question.label}`;
      case DecPackQuestions.WOIBOSecureShipmentQA3:
        return secureShipmentDetails && secureShipmentDetails?.DriverName
          ? `${question.label} ${secureShipmentDetails.DriverName}`
          : `${question.label}`;
      case DecPackQuestions.WOIBOShipmentQA1:
        return secureShipmentDetails && secureShipmentDetails?.NumberofPieces
          ? `${question.label} ${secureShipmentDetails.NumberofPieces}`
          : `${question.label}`;
      default:
        return question?.label;
    }
  };

  const getCheckAnswerLabel = (answer: string) => {
    if (
      String(answer).toLowerCase() === 'false' ||
      String(answer).toLowerCase() === '0' ||
      String(answer).toLowerCase() === 'no'
    ) {
      return i18nMessages.no;
    }
    if (
      String(answer).toLowerCase() === 'true' ||
      String(answer).toLowerCase() === '1' ||
      String(answer).toLowerCase() === 'yes'
    ) {
      return i18nMessages.yes;
    }
    if (String(answer).toLowerCase() === 'na') {
      return i18nMessages.na;
    }
  };

  const rowData = (row: DeckPackWoHistoryGroupedMasterValuesDto) => {
    return (
      <div className="p-2">
        <div>
          {row.deckPackWoHistoryMasterValues &&
            row.deckPackWoHistoryMasterValues.some(
              (x) =>
                x.workOrderNo === DnPWorkOrders.WOS &&
                x.fieldType === FieldMasterType.Question
            ) && (
              <div className="mb-2">
                {row.deckPackWoHistoryMasterValues
                  .filter(
                    (x) =>
                      x.workOrderNo === DnPWorkOrders.WOS &&
                      x.fieldType === FieldMasterType.Question
                  )
                  .map((question: DeckPackWoHistoryMasterValuesDto) => (
                    <Grid item xs={12}>
                      <Typography variant="h5" className="label-heading">
                        {i18nMessages.WosCaption}
                      </Typography>
                      <Typography variant="subtitle1">
                        {
                          masterQuestionListList.find(
                            (x) => +x.value === question.decPackQuestionId
                          )?.label
                        }
                        {` ${getCheckAnswerLabel(question.answer)}`}
                      </Typography>
                    </Grid>
                  ))}
              </div>
            )}
          {/* Add blank div to set alignment if not */}
          {!(
            row.deckPackWoHistoryMasterValues &&
            row.deckPackWoHistoryMasterValues.some(
              (x) =>
                x.workOrderNo === DnPWorkOrders.WOS &&
                x.fieldType === FieldMasterType.Question
            )
          ) && <Grid item lg={3} md={3}></Grid>}
          {row.deckPackWoHistoryMasterValues &&
            row.deckPackWoHistoryMasterValues.some(
              (x) =>
                x.workOrderNo === DnPWorkOrders.WO4 &&
                x.fieldType === FieldMasterType.TextField
            ) && (
              <div className="mb-2">
                <Typography variant="h5" className="label-heading">
                  {i18nMessages.wo4Comment}
                </Typography>
                <Typography variant="subtitle1">
                  {row.deckPackWoHistoryMasterValues.find(
                    (x) =>
                      x.workOrderNo === DnPWorkOrders.WO4 &&
                      x.fieldType === FieldMasterType.TextField
                  )?.answer
                    ? row.deckPackWoHistoryMasterValues.find(
                        (x) =>
                          x.workOrderNo === DnPWorkOrders.WO4 &&
                          x.fieldType === FieldMasterType.TextField
                      )?.answer
                    : '-'}
                </Typography>
              </div>
            )}
          {/* Add blank div to set alignment if not */}
          {!(
            row.deckPackWoHistoryMasterValues &&
            row.deckPackWoHistoryMasterValues.some(
              (x) =>
                x.workOrderNo === DnPWorkOrders.WO4 &&
                x.fieldType === FieldMasterType.TextField
            )
          ) && <Grid item lg={3} md={3}></Grid>}
          {row.deckPackWoHistoryMasterValues &&
            row.deckPackWoHistoryMasterValues.some(
              (x) =>
                x.workOrderNo === DnPWorkOrders.WO3 &&
                x.fieldType === FieldMasterType.Checkbox
            ) && (
              <div className="mb-2">
                <Typography variant="h5" className="label-heading">
                  {i18nMessages.Wo3Caption}
                </Typography>
                <Typography variant="subtitle1">
                  {row.deckPackWoHistoryMasterValues.find(
                    (x) =>
                      x.workOrderNo === DnPWorkOrders.WO3 &&
                      x.fieldType === FieldMasterType.Checkbox
                  )?.answer === 'True'
                    ? i18nMessages.yes
                    : i18nMessages.no}
                </Typography>
              </div>
            )}
          {/* Add blank div to set alignment if not */}
          {!(
            row.deckPackWoHistoryMasterValues &&
            row.deckPackWoHistoryMasterValues.some(
              (x) =>
                x.workOrderNo === DnPWorkOrders.WO3 &&
                x.fieldType === FieldMasterType.Checkbox
            )
          ) && <Grid item lg={3} md={3}></Grid>}
          {row.deckPackWoHistoryMasterValues &&
            row.deckPackWoHistoryMasterValues.some(
              (x) =>
                x.workOrderNo === DnPWorkOrders.WO6 &&
                (x.fieldType === FieldMasterType.Question ||
                  x.fieldType === FieldMasterType.Checkbox)
            ) && (
              <div className="mb-2">
                {row.deckPackWoHistoryMasterValues &&
                  row.deckPackWoHistoryMasterValues.some(
                    (x) =>
                      x.workOrderNo === DnPWorkOrders.WO6 &&
                      x.fieldType === FieldMasterType.Checkbox
                  ) && (
                    <div className="mb-2">
                      <Typography variant="h5" className="label-heading">
                        {i18nMessages.Wo6CaptionShipmentNA}
                      </Typography>
                      <Typography variant="subtitle1">
                        {row.deckPackWoHistoryMasterValues.find(
                          (x) =>
                            x.workOrderNo === DnPWorkOrders.WO6 &&
                            x.fieldType === FieldMasterType.Checkbox
                        )?.answer === 'True'
                          ? i18nMessages.yes
                          : i18nMessages.no}
                      </Typography>
                    </div>
                  )}
                {row.deckPackWoHistoryMasterValues
                  .filter(
                    (x) =>
                      x.workOrderNo === DnPWorkOrders.WO6 &&
                      x.fieldType === FieldMasterType.Question
                  )
                  .map((question: DeckPackWoHistoryMasterValuesDto) => (
                    <Grid item xs={12}>
                      <Typography variant="h5" className="label-heading">
                        {i18nMessages.Wo6Caption}
                      </Typography>
                      <Typography variant="subtitle1">
                        {
                          masterQuestionListList.find(
                            (x) => +x.value === question.decPackQuestionId
                          )?.label
                        }
                        {` ${getCheckAnswerLabel(question.answer)}`}
                        {row.deckPackWoHistoryMasterValues.some(
                          (x) =>
                            x.workOrderNo === DnPWorkOrders.WO6 &&
                            x.fieldType === FieldMasterType.TextField &&
                            x.comment?.toLowerCase().trim()
                        )
                          ? '- (' +
                            row.deckPackWoHistoryMasterValues.find(
                              (x) =>
                                x.workOrderNo === DnPWorkOrders.WO6 &&
                                x.fieldType === FieldMasterType.TextField
                            )?.comment +
                            ')'
                          : ''}
                      </Typography>
                    </Grid>
                  ))}
                <ImageUploadPreview
                  readOnly={true}
                  disableKeyboardNav={true}
                  images={row.woExecutionImages.map((y) => {
                    return {
                      documentTag: {
                        label: y.documentTagName,
                        value: y.documentTag?.toString(),
                      } as BaseModel,
                      error: '',
                      id: y.id,
                      previewUrl:
                        environment.api.baseUrl +
                        ApiUrlConstants.DownloadImageUrl +
                        y.imageUrl,
                      path: y.imageUrl,
                    } as ImageUploadViewModel;
                  })}
                />
              </div>
            )}
          {/* Add blank div to set alignment if not */}
          {!(
            row.deckPackWoHistoryMasterValues &&
            row.deckPackWoHistoryMasterValues.some(
              (x) =>
                x.workOrderNo === DnPWorkOrders.WO6 &&
                x.fieldType === FieldMasterType.Question
            )
          ) && <Grid item lg={3} md={3}></Grid>}
          {row.deckPackWoHistoryMasterValues &&
            row.deckPackWoHistoryMasterValues.some(
              (x) =>
                x.workOrderNo === DnPWorkOrders.WOIBO &&
                x.fieldType === FieldMasterType.Question
            ) && (
              <div>
                <Typography variant="h5" className="label-heading">
                  {i18nMessages.IboNoQuestions}
                </Typography>
                <Grid container spacing={2} className="grid-wrap">
                  {row.deckPackWoHistoryMasterValues
                    .filter(
                      (x) =>
                        x.workOrderNo === DnPWorkOrders.WOIBO &&
                        x.fieldType === FieldMasterType.Question
                    )
                    .map((question: DeckPackWoHistoryMasterValuesDto) => (
                      <Grid item xs={12}>
                        <Typography variant="subtitle1">
                          {appendQuestionNameWithDetails(
                            masterQuestionListList.find(
                              (x) => +x.value === question.decPackQuestionId
                            ) as BaseModel
                          )}
                          {` ${getCheckAnswerLabel(question.answer)}`}
                          {question.decPackQuestionId ===
                          DecPackQuestions.WOIBOShipmentQA1
                            ? '- (' + question.comment + ')'
                            : ''}
                        </Typography>
                      </Grid>
                    ))}
                </Grid>
                <ImageUploadPreview
                  readOnly={true}
                  disableKeyboardNav={true}
                  images={row.woIboImages.map((y) => {
                    return {
                      documentTag: {
                        label: y.documentTagName,
                        value: y.documentTag?.toString(),
                      } as BaseModel,
                      error: '',
                      id: y.id,
                      previewUrl:
                        environment.api.baseUrl +
                        ApiUrlConstants.DownloadImageUrl +
                        y.imageUrl,
                      path: y.imageUrl,
                    } as ImageUploadViewModel;
                  })}
                />
              </div>
            )}
        </div>
      </div>
    );
  };

  const handleSuccessStatusSave = async () => {
    if (status == WorkOrderStatus.Done.toString() && !contactCustomerViaEmail) {
      toast.error(i18nMessages.IrregularityContactClientRequired);
      handleErrorStatusSave();
      return;
    }
    try {
      if (
        status == WorkOrderStatus.Done.toString() &&
        !contactCustomerViaEmail
      ) {
        const response = await saveContactCustomerViaEmail({
          decPackInitialOrderId: +Number(id),
          decPackWorkOrderId: +Number(woId),
          isContactCustomerViaMail: contactCustomerViaEmail,
        } as DeckPackIrregularityContactClientDto);
        if (response && response.result) {
          toast.success(i18nMessages.contactCustomerViaEmailSuccess);
          handleErrorStatusSave();
        } else {
          toast.error(i18nMessages.somethingWentWrong);
          handleErrorStatusSave();
        }
      } else {
        toast.success(i18nMessages.SavedSuccessfully);
      }
      setIsStatusSaveCallApi(true);
    } catch (error: any) {
      handleErrorStatusSave();
    }
  };

  /* Validate Status Dropdown Error call from Status Component */
  const handleErrorStatusSave = () => {
    setValidateStatus(false);
    setIsStatusSaveCallApi(false);
  };

  const getWOStatus = async () => {
    try {
      const response = await getWorkOrderStatus(
        DnPWorkOrders.WO7,
        id ? +id : 0
      );
      if (response && response.result) {
        setStatus(response.data.status);
        setDetails({
          ...details,
          createdBy: response.data.createdBy,
          updatedBy: response.data.updatedBy,
          createdDate: response.data.createdDate,
          updatedDate: response.data.updatedDate,
        });

        setAssigneeData({
          ...assigneeData,
          assignedToId: response.data.assignedToId,
          assignedToName: response.data.assignedToName,
          assignNextToId: response.data.assignedNextToId,
          assignNextToName: response.data.assignedNextToName,
        });
        setIsMobile(response.data.isMobile);
      }
    } catch (error) {}
  };
  const refreshData = async () => {
    handleErrorStatusSave();
    getWOStatus();
  };
  return (
    <>
      <div className="heading-section">
        <div className="heading-section-left">
          <Typography variant="h3" sx={{ mb: 3 }}>
            {i18nMessages.title}
          </Typography>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>
      </div>
      <div className="main-content-section">
        {/* <div className="btn-wrap form-btn-wrap"> */}
        <Grid container spacing={2} className="grid-wrap">
          <Grid item lg={12} md={12}>
            <FormControlLabel
              labelPlacement="end"
              control={
                <Checkbox
                  name="contactCustomerViaEmail"
                  checked={contactCustomerViaEmail}
                  onChange={(event: any) => {
                    setContactCustomerViaEmail(event.target.checked);
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label={i18nMessages.contactCustomerViaEmail}
            />
          </Grid>
          <Grid item lg={12} md={12} sx={{ mb: 2 }}>
            <Virtuoso
              style={{
                height: 400,
              }}
              endReached={() => {
                if (page === 0 || page * pageSize < totalRows) {
                  loadMore(page + 1);
                }
              }}
              data={historyData}
              itemContent={(
                index,
                row: DeckPackWoHistoryGroupedMasterValuesDto
              ) => {
                return (
                  <FormAccordian
                    title={
                      row.deckPackWoHistoryMasterValues &&
                      row.deckPackWoHistoryMasterValues.length
                        ? Utilities.convertHazgoDataTimeFormat(
                            row.deckPackWoHistoryMasterValues[0].createdDate
                          )
                        : null
                    }
                    details={rowData(row)}
                    showActionButton={!canEdit}
                    className="inner-heading"
                  ></FormAccordian>
                );
              }}
            />
          </Grid>
          <Grid item lg={12} md={12}>
            <DecpackStatus
              permission={Permission.canEditDecPackOrders}
              woId={DnPWorkOrders.WO7}
              activityOwner={ActivityOwner.BackOffice}
              orderId={id}
              statusValue={status}
              orderType={OrderType.DecPackOrder}
              isStatusSaveCallApi={isStatusSaveCallApi}
              validateStatus={validateStatus}
              handleSuccessStatusSave={handleSuccessStatusSave}
              handleErrorStatusSave={handleErrorStatusSave}
              isSaveNext={isSaveNext}
              isMobile={isMobile}
              workOrderNo={DnPWorkOrders.WO7}
              assigneeData={assigneeData}
              handleStatusChange={(value: number) =>
                setStatus(value.toString())
              }
            />
          </Grid>
        </Grid>
        <div className="btn-wrap form-btn-wrap">
          {canEdit ? (
            <Button
              type="submit"
              color="primary"
              disableElevation
              variant="contained"
              onClick={handleSubmit}
            >
              {I18n('Common.Save')}
            </Button>
          ) : (
            <></>
          )}
        </div>
        <CreatedByandUpdatedBy
          createdBy={details.createdBy}
          createdDate={details.createdDate}
          updatedBy={details.updatedBy}
          updatedDate={details.updatedDate}
        ></CreatedByandUpdatedBy>
      </div>
    </>
  );
}

export default DeckPackIrregularityContactClient;
