import {
  DataGrid,
  GridOverlay,
  GridSortModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import I18n from 'utilities/i18n';

function CustomDataGrid(props: any) {
  const {
    pageNo,
    pageSize,
    data,
    rowCount,
    onPageChange,
    columns,
    onSortModelChange,
    onPageSizeChange,
    loading,
    initialState,
    rowsPerPageOptions,
    onRowClick,
    checkboxSelection,
    selectedValues,
    selectionModel,
    paginationMode = 'server',
    sortingMode = 'server',
    columnVisibilityModel,
    handleColumnVisibilityModelChange,
    hideFooter,
    isRowSelectable,
  } = props;
  // Some API clients return undefined while loading
  // Following lines are here to prevent `rowCountState` from being undefined during the loading
  const [rowCountState, setRowCountState] = useState(rowCount || 0);

  const customNoRowsOverlay = () => {
    return (
      <GridOverlay>
        <div>{I18n('Common.NoRecords')}</div>
      </GridOverlay>
    );
  };

  useEffect(() => {
    setRowCountState((prevRowCountState: number) =>
      rowCount !== undefined ? rowCount : prevRowCountState
    );
  }, [rowCount, setRowCountState]);
  return (
    <div style={{ display: 'flex', height: '100%' }}>
      <div style={{ flexGrow: 1 }} className="datatable-wrap">
        <DataGrid
          autoHeight
          isRowSelectable={isRowSelectable}
          checkboxSelection={checkboxSelection}
          selectionModel={selectionModel}
          onSelectionModelChange={(ids: any) => {
            if (checkboxSelection) {
              const selectedIDs = new Set(ids);
              const selectedRowData = data.filter((row: any) =>
                selectedIDs.has(row.id)
              );
              selectedValues(selectedRowData);
            }
          }}
          rows={data}
          rowCount={rowCountState}
          loading={loading}
          rowsPerPageOptions={rowsPerPageOptions}
          pagination
          page={pageNo}
          pageSize={pageSize}
          paginationMode={paginationMode}
          sortingMode={sortingMode}
          onPageSizeChange={(newPageSize: any) => onPageSizeChange(newPageSize)}
          onPageChange={(newPage: any) => onPageChange(newPage)}
          onSortModelChange={(sortModel: GridSortModel) =>
            onSortModelChange(sortModel)
          }
          columns={columns}
          disableColumnMenu
          initialState={initialState}
          onRowClick={onRowClick}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
          hideFooter={hideFooter}
          components={{
            Toolbar: ({
              columnVisibilityToolbar,
              exportToolbar,
              filterButtonToolbar,
              densitySelectorToolbar,
            }) => <CustomToolbar {...props} />,
            NoRowsOverlay: customNoRowsOverlay,
          }}
        />
      </div>
    </div>
  );
}
export default CustomDataGrid;
export function CustomToolbar(props: any) {
  const {
    columnVisibilityToolbar,
    exportToolbar,
    filterButtonToolbar,
    densitySelectorToolbar,
  } = props;
  return (
    <GridToolbarContainer>
      {columnVisibilityToolbar && (
        <GridToolbarColumnsButton
          onPointerEnterCapture={false}
          onPointerLeaveCapture={false}
          placeholder={''}
        />
      )}
      {exportToolbar && <GridToolbarExport />}
      {filterButtonToolbar && (
        <GridToolbarFilterButton
          onPointerEnterCapture={false}
          onPointerLeaveCapture={false}
          placeholder={''}
        />
      )}
      {densitySelectorToolbar && (
        <GridToolbarDensitySelector
          onPointerEnterCapture={false}
          onPointerLeaveCapture={false}
          placeholder={''}
        />
      )}
    </GridToolbarContainer>
  );
}
