import {
  Box,
  Breadcrumbs,
  Button,
  FormControl,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import CreateLabels from 'common/create-labels/CreateLabels';
import CustomizedSelectPaginate from 'common/CustomSelect/CustomSelectPaginate';
import DocumentList from 'common/Document/DocumentList';
import { PostTypeCheckDropDown } from 'common/DropDownAddAPI/DropDownApiService';
import FormAccordianSave from 'common/formControl/formAccordianSave';
import CustomizedInputs from 'common/formControl/formControl';
import RadioGroupInput from 'common/RadioGroup/RadioGroupInput';
import StateRestrictionPopup from 'common/State/StateRestrictionPopup';
import Status from 'common/WoStatus/Status';
import AirlineRestrictionPopup from 'components/Airline/AirlineRestrictionPopup';
import environment from 'environment';
import {
  DecPackQuestions,
  DecPackQuestionsDescription,
  DecPackQuestionsDto,
  DnPWorkOrders,
} from 'models/deck-pack-orders';
import {
  EntityTypeEnum,
  DocumentEntity,
  BaseModel,
  DGDDescription,
  ActivityOwner,
  OrderType,
  RadioGroupBaseModel,
  AirlineRestrictionModule,
  StateRestrictionModule,
  Workorders,
  CheckDocumentationType,
  WorkOrderStatus,
} from 'models/pagination-model';
import RouteEnum from 'models/RouteEnum';
import { Permission } from 'Permissions';
import React, { useState, useEffect, BaseSyntheticEvent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import I18n from 'utilities/i18n';
import { hasPermission } from 'utilities/protectedRoute';
import http from '../../../utilities/httpService';
import { postAirLine, postCountry } from '../AddDecpackApiService';
import {
  getDecPackById,
  getDGPlusData,
  getWorkOrderQuestions,
  loadUserOptions,
} from '../DeckPackApiService';
import DGPlus from './DGPLus';
import CustomSelectPaginate from 'common/CustomSelect/CustomSelectPaginate';
import DecpackStatus from './DecpackStatus';
import CreatedByandUpdatedBy from 'common/CreatedByandUpdatedBy/CreatedByandUpdatedBy';

function CheckDocumentations() {
  const { woId, id } = useParams();
  const [isAddModalOpen, setAddModal] = useState(false);
  const [dgplusData, setDgplusData] = useState([]);
  const [isCommentFieldVisible, setCommentFieldVisible] = useState(false);
  const [isOpenAirline, setIsOpenAirline] = useState(false);
  const [airlineId, setAirLineId] = useState(0);
  const [isOpenState, setIsOpenState] = useState(false);
  const [stateIds, setStateIds] = useState<number[]>([]);
  const [details, setDetails] = useState<any>({
    createdBy: '',
    createdDate: '',
    updatedBy: '',
    updatedDate: '',
  });
  const [assigneeData, setAssigneeData] = useState<any>({
    assignedToId: '',
    assignedToName: '',
    assignNextToId: '',
    assignNextToName: '',
  });
  const defaultValue = {
    value: '0',
    label: I18n('Placeholders.Select'),
  } as BaseModel;
  const defaultAdditional: any = {
    page: 1,
  };
  // const [selectedAssignmentTo, setSelectedAssignmentTo] =
  //   useState(defaultValue);

  let navigate = useNavigate();
  const breadcrumbs = [
    <Typography key="3" color="text.primary">
      {I18n('Nav.Order')}
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={() => navigate(RouteEnum.DecPackOrdersList)}
        className="cpointer"
      >
        {I18n('Nav.DecPackOrders')}
      </Link>
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={() => navigate(RouteEnum.ViewDecPackOrders + '/' + id + '/1')}
        className="cpointer"
      >
        {I18n('ShippingOrders.WorkOrders')}
      </Link>
    </Typography>,
  ];

  const i18nMessages = {
    UNNumberCheck: I18n('DecPackOrders.WorkOrder.UNNumberCheck'),
    Assign: I18n('DecPackOrders.WorkOrder.Assign'),
    SpecialInstruction: I18n('DecPackOrders.WorkOrder.SpecialInstruction'),
    Details: I18n('DecPackOrders.WorkOrder.Details'),
    TypeCheck: I18n('DecPackOrders.WorkOrder.TypeCheck'),
    CheckQuestion: I18n('DecPackOrders.WorkOrder.CheckQuestion'),
    Document: I18n('ShippingOrders.Document'),
    Status: I18n('ShippingOrders.Status'),
    DGPlus: I18n('ShippingOrders.DGPlus'),
    CreateDGD: I18n('ShippingOrders.CreateDGD'),
    AllDataAvailable: I18n('ShippingOrders.AllDataAvailable'),
    statusSavedSuccess: I18n('Common.SavedSuccessfully'),
    commonFieldIsRequired: I18n('Common.FieldIsRequired'),
    assignedTo: I18n('DecPackOrders.WorkOrder.AssignedTo'),
    title: I18n('DecPackOrders.WorkOrder.CheckDocumentation'),
    ExtractDGPlus: I18n('DecPackOrders.WorkOrder.ExtractDGPlus'),
    somethingWentWrong: I18n('Common.SomethingWentWrong'),
    comment: I18n('DecPackOrders.WorkOrder.Comment'),
    showAirLine: I18n('DecPackOrders.WorkOrder.ShowAirLine'),
    showCountry: I18n('DecPackOrders.WorkOrder.ShowCountry'),
    instruction: I18n('DecPackOrders.WorkOrder.Instruction'),
    fourDigitPin: I18n('DecPackOrders.WorkOrder.FourDigitPin'),
    CommonSaveSuccessMsg: I18n('Common.SavedSuccessfully'),
    CommonErrOccuredMsg: I18n('Common.ErrorOccurred'),
    AirlineRestriction: I18n('Airline.AirlineRestrictions'),
    StateRestriction: I18n('State.StateRestrictions'),
  };

  const [cacheUniq, setCacheUniq] = useState(0);
  const [canEdit, setCanEdit] = useState(
    hasPermission(Permission.canEditDecPackOrders)
  );
  const [isStatusSaveCallApi, setIsStatusSaveCallApi] =
    useState(false); /* To call Submit APi Status from Status Component */
  const [validateStatus, setValidateStatus] = useState<boolean>(false);
  const [isLabelSaveCallApi, setIsLabelSaveCallApi] =
    useState(false); /* To call Submit APi Status from Status Component */
  const [validateLabel, setValidateLabel] = useState<boolean>(false);
  const [values, setValues] = useState<any>({
    id: 0,
    decPackTypeCheckMasterIds: 0,
    airLineId: 0,
    airLineName: '',
    countryId: 0,
    instruction: '',
    decPackInitialOrderId: id,
    decPackWorkOrdersId: woId,
    comment: '',
    unNumber: '',
    countryName: '',
    status: '',
  });

  const [errors, setErrors] = useState({
    decPackTypeCheckMasterIds: '',
    airLineId: '',
    countryId: '',
    instruction: '',
    comment: '',
    unNumber: '',
  });

  const [typeList, setTypeList] = useState([]);
  const [selectedType, setSelectedType] = useState<any>([]);
  const [questions, setQuestions] = useState<string[]>([]);
  const [isSaveNext, setIsSaveNext] = useState<boolean>(true);
  const [isMobile, setIsMobile] = useState(false);
  const onCreateOption = async (inputValue: any) => {
    let name = {};
    name['name'] = inputValue;
    const newOption: any = await postAirLine(name);
    const increaseUniq = (uniq: any) => uniq + 1;
    setCacheUniq(increaseUniq);
    setValues({
      ...values,
      airLineId: newOption.value,
      airLineName: newOption.label,
    });
  };

  const loadTypeOptions = async (
    search: string,
    loadedOptions: any,
    { page }: any
  ) => {
    const response = await getTypeDropdown(true);
    const hasMore = Math.ceil(response.length / 10) > page;

    return {
      options: response.map((x: any) => {
        return {
          label: x.label,
          value: x.value,
        } as BaseModel;
      }),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const onCreateOptionType = async (inputValue: any) => {
    let name = {};
    name['name'] = inputValue;
    const newOption: any = await PostTypeCheckDropDown(
      name,
      i18nMessages.CommonSaveSuccessMsg,
      i18nMessages.CommonErrOccuredMsg
    );
    const increaseUniq = (uniq: any) => uniq + 1;
    setCacheUniq(increaseUniq);
    setSelectedType((selectedType: any[]) => [...selectedType, newOption]);
  };

  const onCreateCountryOption = async (inputValue: any) => {
    let name = {};
    name['name'] = inputValue;
    const newOption: any = await postCountry(name);
    const increaseUniq = (uniq: any) => uniq + 1;
    setCacheUniq(increaseUniq);
    setValues({
      ...values,
      countryId: newOption.value,
      countryName: newOption.label,
    });
  };

  const addDGPlus = async () => {
    if (values.unNumber) {
      var dgdata: any = await getDGPlusDataFromUN();
      setDgplusData(dgdata);
      setAddModal(true);
    }
  };

  const handleCloseForAdd = () => {
    setAddModal(false);
  };

  let entityWorkOrderStatusList: Array<BaseModel> = [];
  const getStatusList = () => {
    entityWorkOrderStatusList = [defaultValue];
    DGDDescription.forEach((value: string, key: number) => {
      entityWorkOrderStatusList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getStatusList();

  let answerList: Array<RadioGroupBaseModel> = [];
  const getAnswerList = () => {
    answerList.push({
      value: CheckDocumentationType.Yes.toString(),
      label: I18n('Common.Yes'),
    } as RadioGroupBaseModel);
    answerList.push({
      value: CheckDocumentationType.No.toString(),
      label: I18n('Common.No'),
    } as RadioGroupBaseModel);
    answerList.push({
      value: CheckDocumentationType.NA.toString(),
      label: I18n('Common.NA'),
    } as RadioGroupBaseModel);
  };
  getAnswerList();

  let masterQuestionListList: Array<BaseModel> = [];
  const getQuestionsList = () => {
    DecPackQuestionsDescription.forEach((value: string, key: number) => {
      masterQuestionListList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getQuestionsList();

  const [answers, setAnswers] = useState([
    {
      decPackQuestionId: 0,
      decPackQuestionKey: DecPackQuestions.WOCheckDocumentationQA1,
      answer: CheckDocumentationType.No.toString(),
      decPackInitialOrderId: Number(id),
      decPackWorkOrdersId: Number(woId),
    },
    {
      decPackQuestionId: 0,
      decPackQuestionKey: DecPackQuestions.WOCheckDocumentationQA2,
      answer: CheckDocumentationType.No.toString(),
      decPackInitialOrderId: Number(id),
      decPackWorkOrdersId: Number(woId),
    },
    {
      decPackQuestionId: 0,
      decPackQuestionKey: DecPackQuestions.WOCheckDocumentationQA3,
      answer: CheckDocumentationType.No.toString(),
      decPackInitialOrderId: Number(id),
      decPackWorkOrdersId: Number(woId),
    },
    {
      decPackQuestionId: 0,
      decPackQuestionKey: DecPackQuestions.WOCheckDocumentationQA4,
      answer: CheckDocumentationType.No.toString(),
      decPackInitialOrderId: Number(id),
      decPackWorkOrdersId: Number(woId),
    },
  ]);

  const setAnswerData = (value: boolean, questionId: number) => {
    var temp: any = [];
    for (var i = 0; i < answers.length; i++) {
      if (answers[i].decPackQuestionKey == questionId) {
        answers[i].answer = String(value);
        temp.push(answers[i]);
      } else {
        temp.push(answers[i]);
      }
    }
    setAnswers(temp);
    ChangeCommentFieldVisibility();
  };

  const ChangeCommentFieldVisibility = () => {
    if (answers.length > 0) {
      setCommentFieldVisible(false);
      answers.forEach((objValue: any, key: any) => {
        if (objValue.answer == CheckDocumentationType.No.toString()) {
          setCommentFieldVisible(true);
        }
      });
    }
  };

  const getDGPlusDataFromUN = async () => {
    return await getDGPlusData(values.unNumber);
  };

  const TypeCheckData = () => {
    return (
      <CustomizedSelectPaginate
        key={JSON.stringify(selectedType)}
        additional={defaultAdditional}
        value={selectedType}
        loadOptions={loadTypeOptions}
        placeholder={defaultValue.label}
        handleChange={(newValue: BaseModel) => {
          setSelectedType(newValue);
          if (newValue.value === '') {
            setErrors({
              ...errors,
              decPackTypeCheckMasterIds: i18nMessages.commonFieldIsRequired,
            });
          } else {
            setErrors({
              ...errors,
              decPackTypeCheckMasterIds: '',
            });
          }
        }}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
        }}
        cacheUniqs={cacheUniq}
        onCreateOption={onCreateOptionType}
        isSearchable={true}
        errorValue={true}
        errorMessage={errors.decPackTypeCheckMasterIds}
        readOnly={!canEdit}
        debounceTimeout={500}
        isMultiSelect={true}
      ></CustomizedSelectPaginate>
    );
  };

  const CheckQuestionData = () => {
    return (
      <>
        {questions.map((question: any) => (
          <Grid item lg={6} md={6}>
            <RadioGroupInput
              displayLabel={question.name}
              name={`question${question.id}`}
              value={Number(
                answers.find((x) => x.decPackQuestionKey == question.id)?.answer
              )}
              handleChange={(event: BaseSyntheticEvent) => {
                setAnswerData(event.target.value, question.id);
              }}
              options={answerList}
              readOnly={!canEdit}
            ></RadioGroupInput>
          </Grid>
        ))}
        {isCommentFieldVisible ? (
          <Grid item lg={12} md={12}>
            <CustomizedInputs
              displayLabel={i18nMessages.comment}
              handleChange={(newValue: any) => {
                setValues({ ...values, comment: newValue.target.value });
              }}
              errorValue={true}
              errorMessage={errors.comment}
              value={values.comment}
              readOnly={!canEdit}
              required
            ></CustomizedInputs>
          </Grid>
        ) : (
          <></>
        )}
      </>
    );
  };

  const handleAirlineClose = () => {
    setIsOpenAirline(false);
  };

  const handleAirlineOpen = () => {
    setIsOpenAirline(true);
  };

  const handleStateClose = () => {
    setIsOpenState(false);
  };

  const handleStateOpen = () => {
    setIsOpenState(true);
  };

  const DetailsData = () => {
    return (
      <>
        <Grid item lg={6} md={6}>
          <AirlineRestrictionPopup
            isOpen={isOpenAirline}
            handleClose={handleAirlineClose}
            title={i18nMessages.AirlineRestriction}
            moduleRestriction={AirlineRestrictionModule.DecPackWO4}
            airlineId={airlineId}
          />
          <FormControl>
            <Box sx={{ mt: 3 }}>
              <Button variant="contained" onClick={handleAirlineOpen}>
                {i18nMessages.AirlineRestriction}
              </Button>
            </Box>
          </FormControl>
        </Grid>
        <Grid item lg={6} md={6}>
          <StateRestrictionPopup
            isOpen={isOpenState}
            handleClose={handleStateClose}
            title={i18nMessages.StateRestriction}
            moduleRestriction={StateRestrictionModule.DecPackWO4}
            stateIds={stateIds}
          />
          <FormControl>
            <Box sx={{ mt: 3 }}>
              <Button variant="contained" onClick={handleStateOpen}>
                {i18nMessages.StateRestriction}
              </Button>
            </Box>
          </FormControl>
        </Grid>
      </>
    );
  };

  const SpecialInstructionData = () => {
    return (
      <>
        <Grid item lg={12} md={12}>
          <CustomizedInputs
            placeholder={defaultValue.label}
            displayLabel={i18nMessages.instruction}
            handleChange={(newValue: any) => {
              setValues({ ...values, instruction: newValue.target.value });
            }}
            value={values.instruction}
            errorValue={true}
            readOnly={!canEdit}
            inputProps={{ maxLength: 250 }}
          ></CustomizedInputs>
        </Grid>
      </>
    );
  };

  const UnNumberData = () => {
    return (
      <>
        <Grid item lg={6} md={6}>
          <CustomizedInputs
            placeholder={defaultValue.label}
            displayLabel={i18nMessages.fourDigitPin}
            handleChange={(newValue: any) => {
              setValues({ ...values, unNumber: newValue.target.value });
            }}
            errorValue={true}
            inputProps={{ maxLength: 4 }}
            value={values.unNumber}
            readOnly={!canEdit}
          ></CustomizedInputs>
        </Grid>
        <Grid item lg={6} md={6}>
          <FormControl>
            <Box sx={{ mt: 3 }}>
              <Button
                variant="contained"
                onClick={addDGPlus}
                disabled={!canEdit}
              >
                {i18nMessages.ExtractDGPlus}
              </Button>
            </Box>
          </FormControl>
        </Grid>
      </>
    );
  };

  const DocumentData = () => {
    return (
      <Grid xl={12} md={12}>
        <DocumentList
          permission={Permission.canEditDecPackOrders}
          addPermission={Permission.canAddDecPackOrders}
          deletePermission={Permission.canDeleteDecPackOrders}
          allowedDocuments={1}
          entityType={EntityTypeEnum.DecPackWODGD}
          paramsId={id}
          isHeaderHide={true}
          documentEntityType={DocumentEntity.WODGD}
          isFromDnPWO4={true}
        />
      </Grid>
    );
  };

  const validate = (fieldValues: any = values) => {
    let temp = { ...errors };

    if (!selectedType || selectedType.length == 0) {
      temp.decPackTypeCheckMasterIds = i18nMessages.commonFieldIsRequired;
    }

    let ansData = answers
      .map((data) => data.answer.toString())
      .includes(CheckDocumentationType.No.toString());
    if (ansData === true && values.comment === '') {
      temp.comment = i18nMessages.commonFieldIsRequired;
    } else {
      temp.comment = '';
    }

    setErrors(temp);

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === '');
    }
  };

  const handleSaveClick = async () => {
    let isValid = values.status == WorkOrderStatus.Done ? validate() : true;
    if (isValid) {
      let apiUrl;
      let result;
      const finalValue = {
        id: values.id,
        decPackWorkOrdersId: Number(woId),
        decPackInitialOrderId: Number(id),
        decPackTypeCheckMasterIds: selectedType
          ? selectedType.filter((x: any) => x).map((y: any) => y.value)
          : null,
        instruction: values.instruction,
        // decPackAgents:
        //   selectedAssignmentTo.value != '0' ? selectedAssignmentTo.value : '',
        comment: isCommentFieldVisible ? values.comment : '',
        unNumber: values.unNumber,
      };

      try {
        if (values.id == 0) {
          apiUrl = new URL(
            environment.api.baseUrl + 'DecPackCheckDocumentation'
          );

          result = await http.post(apiUrl.toString(), finalValue);
          if (result) {
            var data = await getWO(typeList);
            await UpdateWOAnswers(data.id);
          }
        } else {
          apiUrl = new URL(
            environment.api.baseUrl + 'DecPackCheckDocumentation'
          );

          result = await http.put(apiUrl.toString(), finalValue);
          await UpdateWOAnswers(values.id);
        }

        if (result) {
          toast.success(i18nMessages.statusSavedSuccess);
        }
      } catch (error: any) {}
    }
    handleErrorStatusSave();
  };

  const getWOStatus = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl +
        'DecPackOrder/GetDecPackWO/' +
        id +
        '/' +
        DnPWorkOrders.WO4
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result) {
        setDetails({
          ...details,
          createdBy: result.data.createdBy,
          updatedBy: result.data.updatedBy,
          createdDate: result.data.createdDate,
          updatedDate: result.data.updatedDate,
        });

        setAssigneeData({
          ...assigneeData,
          assignedToId: result.data.assignedToId,
          assignedToName: result.data.assignedToName,
          assignNextToId: result.data.assignedNextToId,
          assignNextToName: result.data.assignedNextToName,
        });

        setIsMobile(result.data.isMobile);
        return result.data.status;
      }
    } catch (error) {}
  };

  const getWOQuestions = async () => {
    try {
      const response = await getWorkOrderQuestions(DnPWorkOrders.WO4);
      if (response && response.result) {
        setQuestions(
          (response.data as Array<DecPackQuestionsDto>).map((x: any) => {
            const questionValue = masterQuestionListList.find(
              (y) => +y.value == x.questionKey
            );
            return {
              id: questionValue?.value,
              name: questionValue?.label,
            } as any;
          })
        );
        var temp: any = [];
        response.data.forEach((question) => {
          for (var i = 0; i < answers.length; i++) {
            if (question.questionKey == answers[i].decPackQuestionKey) {
              answers[i].decPackQuestionId = question.id;
              temp.push(answers[i]);
              break;
            }
          }
        });
        setAnswers(temp);
        ChangeCommentFieldVisibility();
      }
    } catch {
      toast.error(i18nMessages.somethingWentWrong);
    }
  };
  const getTypeDropdown = async (isfromDropDown: boolean) => {
    const apiUrl = new URL(
      environment.api.baseUrl +
        'DecPackCheckDocumentation/DecPackTypeCheckMaster'
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result && result.data) {
        var temp: any = [];
        result.data.forEach((element: any) => {
          temp.push({
            value: element.id,
            label: element.name,
          });
        });
        setTypeList(temp);
        if (!isfromDropDown) {
          await getWO(temp);
        }
        return temp;
      }
    } catch (error) {}
  };

  const getWO = async (types: any) => {
    const apiUrl = new URL(
      environment.api.baseUrl + 'DecPackCheckDocumentation/' + id + '/' + woId
    );
    try {
      const result = await http.get(apiUrl.toString());
      var status = await getWOStatus();

      if (result && result.data) {
        setValues({
          ...values,
          id: result.data.id,
          decPackTypeCheckMasterIds: result.data.decPackTypeCheckMasterIds,
          airLineId: result.data.airLineId,
          airLineName: result.data.airLineName,
          countryId: result.data.countryId,
          instruction: result.data.instruction,
          decPackInitialOrderId: result.data.decPackInitialOrderId,
          decPackWorkOrdersId: result.data.decPackWorkOrdersId,
          //  decPackAgents: result.data.decPackAgents,
          comment: result.data.comment,
          unNumber: result.data.unNumber,
          countryName: result.data.countryName,
          status,
        });

        if (result.data.decPackTypeCheckMasterIds) {
          var dropDown: any = [];
          types.forEach((type: any) => {
            result.data.decPackTypeCheckMasterIds.forEach(
              (decPackTypeCheckMasterId: any) => {
                if (decPackTypeCheckMasterId == type.value) {
                  dropDown.push({ label: type.label, value: type.value });
                }
              }
            );
          });
          setSelectedType(dropDown);
        }
        //  await getDecPackAgents(result.data.decPackAgents);

        await getWOAnswers(result.data.id);
        return result.data;
      } else {
        setValues({
          ...values,
          status,
        });
      }
    } catch (error) {}
  };

  const getDecPackAgents = async (id: string) => {
    const apiUrl = new URL(environment.api.baseUrl + `Users/${id}`);
    const resultForUser = await http.get(apiUrl.toString());
    if (resultForUser && resultForUser.data && resultForUser.data.firstName) {
      // setSelectedAssignmentTo({
      //   label: `${resultForUser.data.firstName} ${resultForUser.data.lastName}`,
      //   value: resultForUser.data.id,
      // });
    }
  };

  const getWOAnswers = async (decPackCheckDocumentationId: number) => {
    const apiUrl = new URL(
      environment.api.baseUrl +
        'DecPackCheckDocumentation/CheckDocumentationAnswers/' +
        decPackCheckDocumentationId
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result && result.data.length > 0) {
        var temp: any = [];
        for (var i = 0; i < result.data.length; i++) {
          for (var j = 0; i < answers.length; j++) {
            if (
              result.data[i].decPackQuestionId == answers[j].decPackQuestionId
            ) {
              answers[j].answer = result.data[i].answer;
              temp.push(answers[j]);
              break;
            }
          }
        }
        setAnswers(temp);
        ChangeCommentFieldVisibility();
      }
    } catch (error) {}
  };

  const UpdateWOAnswers = async (id: number) => {
    const apiUrl = new URL(
      environment.api.baseUrl +
        'DecPackCheckDocumentation/CheckDocumentationAnswers/' +
        id
    );
    try {
      var finalData: any = [];
      answers.forEach((element) => {
        finalData.push({
          decPackCheckDocumentationId: id,
          answer: +element.answer,
          decPackQuestionId: element.decPackQuestionId,
        });
      });
      await http.put(apiUrl.toString(), finalData);
    } catch (error) {}
  };

  const fetchDecPackOrder = async () => {
    var data = await getDecPackById(Number(id));
    if (data != null) {
      setAirLineId(data.Airline.value);

      var states: number[] = [
        data.FinalDestination.value,
        data.TransitDestination.value,
      ];

      setStateIds(states);
    }
  };

  useEffect(() => {
    getWOQuestions();
    getTypeDropdown(false);
    fetchDecPackOrder();
  }, []);

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    setIsSaveNext(false);
    let isValid = values.status == WorkOrderStatus.Done ? validate() : true;
    if (isValid) {
      setValidateStatus(true);
      setValidateLabel(true);
    } else {
      handleErrorStatusSave();
    }
  };

  const handleSuccessStatusSave = async () => {
    try {
      await handleSaveClick();
      setIsStatusSaveCallApi(true);
    } catch (error: any) {
      handleErrorStatusSave();
    }
  };

  const handleLabelStatusSave = async () => {
    try {
      setIsLabelSaveCallApi(true);
    } catch (error: any) {
      handleErrorStatusSave();
    }
  };

  /* Validate Status Dropdown Error call from Status Component */
  const handleErrorStatusSave = () => {
    setValidateStatus(false);
    setIsStatusSaveCallApi(false);
    setValidateLabel(false);
    setIsLabelSaveCallApi(false);
  };

  const onSaveNextClick = async (event: any) => {
    event.preventDefault();
    if (validate()) {
      setValidateStatus(true);
      setValidateLabel(true);
    } else {
      handleErrorStatusSave();
    }
    setIsSaveNext(true);
  };

  const refreshData = async () => {
    handleErrorStatusSave();
    getWOStatus();
  };
  return (
    <>
      <div className="heading-section">
        <div className="heading-section-left">
          <Typography variant="h3" sx={{ mb: 3 }}>
            {i18nMessages.title}
          </Typography>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>
      </div>
      <div className="main-content-section">
        <Grid container spacing={2} className="grid-wrap">
          <Grid item lg={12} md={12}>
            <FormAccordianSave
              title={i18nMessages.Document}
              className="inner-heading"
              details={DocumentData()}
            ></FormAccordianSave>
          </Grid>
          <Grid item lg={6} md={6}>
            <FormAccordianSave
              title={i18nMessages.TypeCheck + ' *'}
              className="inner-heading"
              details={TypeCheckData()}
            ></FormAccordianSave>
          </Grid>
          <Grid item lg={6} md={6}>
            <FormAccordianSave
              title={i18nMessages.CheckQuestion}
              className="inner-heading"
              details={CheckQuestionData()}
            ></FormAccordianSave>
          </Grid>
          <Grid item lg={6} md={6}>
            <FormAccordianSave
              title={i18nMessages.Details}
              className="inner-heading"
              details={DetailsData()}
            ></FormAccordianSave>
          </Grid>
          <Grid item lg={6} md={6}>
            <FormAccordianSave
              title={i18nMessages.SpecialInstruction}
              className="inner-heading"
              details={SpecialInstructionData()}
            ></FormAccordianSave>
          </Grid>
          <Grid item lg={12} md={12}>
            <FormAccordianSave
              title={i18nMessages.UNNumberCheck}
              className="inner-heading"
              details={UnNumberData()}
            ></FormAccordianSave>
          </Grid>
          {/* <Grid item lg={6} md={6}>
            <FormAccordianSave
              title={i18nMessages.Assign}
              className="inner-heading"
              details={AssignData()}
            ></FormAccordianSave>
          </Grid> */}
          <Grid item lg={12} md={12}>
            <DecpackStatus
              permission={Permission.canEditDecPackOrders}
              woId={DnPWorkOrders.WO4}
              activityOwner={ActivityOwner.BackOffice}
              orderId={id}
              statusValue={values.status}
              orderType={OrderType.DecPackOrder}
              isStatusSaveCallApi={isStatusSaveCallApi}
              validateStatus={validateStatus}
              handleSuccessStatusSave={handleSuccessStatusSave}
              handleErrorStatusSave={handleErrorStatusSave}
              isSaveNext={isSaveNext}
              isMobile={isMobile}
              workOrderNo={DnPWorkOrders.WO4}
              showAssigned={true}
              assigneeData={assigneeData}
              handleStatusChange={(value: number) =>
                setValues({ ...values, status: value })
              }
            />
          </Grid>
          <Grid item lg={12} md={12}>
            {hasPermission(Permission.canViewDecPackOrders) ? (
              <CreateLabels
                permission={Permission.canEditDecPackOrders}
                woId={woId}
                orderId={id}
                isWarehouse={false}
                isAccordian={true}
                isLabelSaveCallApi={isLabelSaveCallApi}
                validateLabel={validateLabel}
                handleLabelStatusSave={handleLabelStatusSave}
                handleErrorStatusSave={handleErrorStatusSave}
                entityType={EntityTypeEnum.DecPackOrders}
                workOrderNo={DnPWorkOrders.WO4}
              />
            ) : (
              <></>
            )}
          </Grid>
        </Grid>

        <DGPlus
          data={dgplusData}
          isOpen={isAddModalOpen}
          handleClose={handleCloseForAdd}
          title={I18n('DecPackOrders.WorkOrder.DGPlusData')}
        ></DGPlus>
        <div className="btn-wrap form-btn-wrap">
          {canEdit ? (
            <Button
              color="primary"
              disableElevation
              variant="contained"
              onClick={onSaveNextClick}
            >
              {I18n('Common.SaveNext')}
            </Button>
          ) : (
            <></>
          )}
          &nbsp; &nbsp; &nbsp;
          {canEdit ? (
            <Button
              type="submit"
              color="primary"
              disableElevation
              variant="contained"
              onClick={handleSubmit}
            >
              {I18n('Common.Save')}
            </Button>
          ) : (
            <></>
          )}
        </div>
        <CreatedByandUpdatedBy
          createdBy={details.createdBy}
          createdDate={details.createdDate}
          updatedBy={details.updatedBy}
          updatedDate={details.updatedDate}
        ></CreatedByandUpdatedBy>
      </div>
    </>
  );
}

export default CheckDocumentations;
