import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { debounce } from 'lodash';
export const AutocompleteInputs = (props: any) => {
  const {
    value,
    displayLabel,
    errorValue,
    errorMessage,
    className = '',
    inputProps,
    required,
    handleChange,
    handleOptionChange,
    readOnly,
    disabled,
    options,
    autoFocus,
    open,
    loading,
    onFocus,
  } = props;

  const handleTextChange = debounce(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      handleOptionChange(event.target.value);
      handleChange(event.target.value);
    },
    500
  );
  return (
    <FormControl
      variant="standard"
      fullWidth
      sx={{ mb: 2 }}
      error={errorMessage}
    >
      <InputLabel shrink htmlFor="bootstrap-input">
        {displayLabel}
        {required ? '*' : ''}
      </InputLabel>
      <Autocomplete
        freeSolo
        id={displayLabel}
        readOnly={readOnly}
        disabled={disabled}
        value={value}
        onInputChange={(event, newInputValue) => {
          handleChange(newInputValue);
        }}
        open={open}
        loading={loading}
        disableClearable
        options={options}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              ...inputProps,
              type: 'search',
            }}
            onFocus={onFocus}
            autoFocus={autoFocus}
            onChange={handleTextChange}
            className={className}
          />
        )}
      />
      {errorValue ? <FormHelperText>{errorMessage}</FormHelperText> : ''}
    </FormControl>
  );
};

export default AutocompleteInputs;
