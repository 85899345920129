import I18n from 'utilities/i18n';
import './ServiceCatalogue.scss';
import { useEffect, useState } from 'react';
import http from '../../utilities/httpService';
import environment from 'environment';
import { useNavigate, useParams } from 'react-router-dom';
import CustomizedSelect, {
  CustomizedSelect as StatusSelect,
} from 'common/CustomSelect/CustomSelect';
import { CustomizedSelect as PricingTypeSelect } from 'common/CustomSelect/CustomSelect';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import {
  BaseModel,
  EntityStatusDescription,
  PricingType,
  PricingTypeDescription,
  PaginationResponseModel,
  MasterCategoryEnumType,
  DepartmentSelect,
} from 'models/pagination-model';
import CustomizedInputs from 'common/formControl/formControl';
import DecimalInput from 'common/DecimalInput/DecimalInput';
import { ServiceCatalogues, Customer } from 'models/service-catalogue';
import RouteEnum from 'models/RouteEnum';
import FormAccordian from 'common/formControl/formAccordian';
import { toast } from 'react-toastify';
import CustomSelectPaginate from 'common/CustomSelect/CustomSelectPaginate';
import CustomSelectPaginateAdd from 'common/CustomSelect/CustomSelectPaginateAdd';
import {
  PostCatagoryDropDown,
  loadItemGroupOptions,
  loadLedgerOptions,
} from 'common/DropDownAddAPI/DropDownApiService';
import { hasPermission } from 'utilities/protectedRoute';
import { Permission } from 'Permissions';

function ServiceCatalogueDetails() {
  const defaultValue = {
    value: '0',
    label: I18n('Placeholders.SelectStatus'),
  } as BaseModel;
  const defaultCategoryValue = {
    value: '0',
    label: I18n('Placeholders.SelectCategory'),
  } as BaseModel;
  const defaultDropdownValue = {
    value: '0',
    label: I18n('Placeholders.Select'),
  } as BaseModel;
  const defaultDepartmentValue = {
    value: '0',
    label: I18n('Placeholders.SelectDepartment'),
  } as BaseModel;

  let pricingTypeList: Array<BaseModel> = [];
  const getPricingTypeList = () => {
    pricingTypeList = [];
    PricingTypeDescription.forEach((value: string, key: number) => {
      pricingTypeList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getPricingTypeList();
  const { entityId } = useParams();
  let navigate = useNavigate();
  const [expanded, setExpanded] = useState<boolean>(true);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [serviceCode, setServiceCode] = useState('');
  const [category, setCategory] = useState<BaseModel>(defaultCategoryValue);
  const [description, setDescription] = useState('');
  const [serviceName, setServiceName] = useState('');
  const [shippingStandardPrice, setShippingStandardPrice] = useState('');
  const [decPackStandardPrice, setDecPackStandardPrice] = useState('');
  const [lastCostBuyPrice, setLastCostBuyPrice] = useState('');
  const [status, setStatus] = useState<BaseModel>(defaultValue);
  const [pricingType, setPricingType] = useState<BaseModel>(pricingTypeList[0]);
  const [cacheUniq, setCacheUniq] = useState(0);
  const [canEdit, setCanEdit] = useState(
    hasPermission(Permission.canEditServiceCatalogue)
  );
  const [ledgerAccount, setLedgerAccount] = useState(defaultDropdownValue);
  const [itemGroup, setItemGroup] = useState(defaultDropdownValue);
  const [showInDPBOM, setShowInDPBOM] = useState(false);
  const [department, setDepartment] = useState<BaseModel>(
    defaultDepartmentValue
  );

  const i18nMessages = {
    updateServiceCatalougeSuccess: I18n('ServiceCatalogues.UpdateSuccess'),
    serviceCataloguesDetailsTitle: I18n('ServiceCatalogues.Details'),
    commonFieldIsRequired: I18n('Common.FieldIsRequired'),
    uniqueServiceCode: I18n('Common.UniqueServiceCode'),
    uniqueServiceName: I18n('Common.UniqueServiceName'),
    CommonSaveSuccessMsg: I18n('Common.SavedSuccessfully'),
    CommonErrOccuredMsg: I18n('Common.ErrorOccurred'),
    LedgerAccountDescription: I18n('LedgerInfo.LedgerAccountDescription'),
    LedgerAccount: I18n('LedgerInfo.LedgerAccount'),
    ItemGroup: I18n('ItemGroupInfo.ItemGroup'),
    ShowInDPBOM: I18n('ProductCatalogues.ShowInDPBOM'),
  };
  const defaultAdditional: any = {
    page: 1,
  };
  const [errors, setErrors] = useState({
    serviceCode: '',
    category: '',
    description: '',
    serviceName: '',
    shippingStandardPrice: '',
    decPackStandardPrice: '',
    lastCostBuyPrice: '',
    statusId: '',
    pricingType: '',
    ledgerAccount: '',
    itemGroups: '',
    department: '',
  });

  const validate = () => {
    let temp = { ...errors };
    temp.serviceName = serviceName ? '' : i18nMessages.commonFieldIsRequired;
    temp.category =
      category.value !== defaultCategoryValue.value
        ? ''
        : i18nMessages.commonFieldIsRequired;
    temp.serviceCode = serviceCode ? '' : i18nMessages.commonFieldIsRequired;
    temp.shippingStandardPrice = shippingStandardPrice
      ? ''
      : i18nMessages.commonFieldIsRequired;
    temp.decPackStandardPrice = decPackStandardPrice
      ? ''
      : i18nMessages.commonFieldIsRequired;
    temp.ledgerAccount =
      ledgerAccount.value == defaultDropdownValue.value
        ? i18nMessages.commonFieldIsRequired
        : '';
    temp.itemGroups =
      itemGroup.value == defaultDropdownValue.value
        ? i18nMessages.commonFieldIsRequired
        : '';
    temp.department =
      department.value == defaultDepartmentValue.value
        ? i18nMessages.commonFieldIsRequired
        : '';
    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === '');
  };

  const handleEditClick = (event: React.SyntheticEvent) => {
    setIsEdit(!isEdit);
  };
  const handleExpandCollapseClick = (event: React.SyntheticEvent) => {
    setExpanded(!expanded);
  };

  const onStatusChange = (data: BaseModel) => {
    setStatus(data);
  };
  const onPricingTypeChange = (data: BaseModel) => {
    setPricingType(data);
  };

  const getOrderCategories = async (search: string, pageNo: number) => {
    let response: PaginationResponseModel<Customer>;
    const apiUrl = new URL(
      environment.api.baseUrl + 'Dropdown/GetMasterCategories'
    );
    try {
      let searchExpression = `entityType~=~${MasterCategoryEnumType.ServiceCatalogue}`;
      searchExpression += search ? ` and name~like~${search}` : '';
      apiUrl.searchParams.set('$filter', searchExpression);
      apiUrl.searchParams.set('$orderby', 'name');
      apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
      apiUrl.searchParams.set('$top', '10');
      apiUrl.searchParams.set('$orderbydirection', 'desc');
      const result = await http.get(apiUrl.toString());
      if (result) {
        response = {
          TotalRecords: result.data.totalCount,
          Values: result.data.values,
        } as PaginationResponseModel<Customer>;
      } else {
        response = {
          TotalRecords: 0,
          Values: [],
        } as PaginationResponseModel<Customer>;
      }
    } catch (error) {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<Customer>;
    }
    return response;
  };

  const loadCategoryOptions = async (
    search: string,
    loadedOptions: any,
    { page }: any
  ) => {
    const response = await getOrderCategories(search, page);
    const hasMore = Math.ceil(response.TotalRecords / 10) > page;

    return {
      options: response.Values.map((x) => {
        return {
          value: x.id.toString(),
          label: x.name,
        } as BaseModel;
      }),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const handleSaveClick = () => {
    if (validate()) {
      saveServiceCatalogue({
        id: entityId ? entityId : 0,
        categoryName: '',
        categoryId: category.value ? category.value : 0,
        description,
        serviceCode,
        serviceName,
        shippingStandardPrice: Number(shippingStandardPrice.replace(/,/g, '.')),
        decPackStandardPrice: Number(decPackStandardPrice.replace(/,/g, '.')),
        lastCostBuyPrice: Number(lastCostBuyPrice.replace(/,/g, '.')),
        statusId: Number(status.value),
        externalId: null,
        syncDate: null,
        pricingType: Number(pricingType.value),
        departmentType: Number(department.value),
        isStorage: false,
        ledgerAccount,
        itemGroup,
        showInDPBOM,
        department,
      } as ServiceCatalogues);
    }
  };
  let entityStatusList: Array<BaseModel> = [];
  const getStatusList = () => {
    entityStatusList = [defaultValue];
    EntityStatusDescription.forEach((value: string, key: number) => {
      entityStatusList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getStatusList();
  let departmentList: Array<BaseModel> = [];
  const getDepartmentList = () => {};
  DepartmentSelect.forEach((value: string, key: number) => {
    departmentList.push({
      value: key.toString(),
      label: I18n(value),
    } as BaseModel);
  });
  getDepartmentList();

  const getServiceCatalogueById = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl + `ServiceCatalogues/${entityId}`
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result && result.data) {
        setServiceCode(result.data.serviceCode);
        setServiceName(result.data.serviceName);
        setCategory({
          label: result.data.categoryName,
          value: result.data.categoryId,
        } as BaseModel);
        setDescription(result.data.description);
        setShippingStandardPrice(
          result.data.shippingStandardPrice.toString().replaceAll('.', ',')
        );
        setDecPackStandardPrice(
          result.data.decPackStandardPrice.toString().replaceAll('.', ',')
        );
        setLastCostBuyPrice(
          result.data.lastCostBuyPrice.toString().replaceAll('.', ',')
        );
        setStatus({
          label: EntityStatusDescription.get(result.data.statusId) || '',
          value: Number(result.data.statusId).toString(),
        } as BaseModel);
        setPricingType({
          label: EntityStatusDescription.get(result.data.pricingType) || '',
          value: Number(result.data.pricingType).toString(),
        } as BaseModel);
        setLedgerAccount({
          label: result.data.ledgerAccountCode,
          value: result.data.ledgerAccountId.toString(),
        });
        setItemGroup({
          label: result.data.itemGroupCode,
          value: result.data.itemGroupId.toString(),
        });
        setShowInDPBOM(result.data.showInDPBOM);
        var departmentLabel = departmentList.find(
          (x) => x.value == result.data.departmentType
        )?.label;
        setDepartment({
          label: departmentLabel || '',
          value: Number(result.data.departmentType).toString(),
        } as BaseModel);
      }
    } catch (error) {}
  };
  const saveServiceCatalogue = async (serviceCatalogue: ServiceCatalogues) => {
    const apiUrl = new URL(
      environment.api.baseUrl + 'ServiceCatalogues/Update?id=' + entityId
    );
    try {
      var obj: any = serviceCatalogue;
      obj.ledgerAccountId = +serviceCatalogue.ledgerAccount.value;
      obj.itemGroupId = +serviceCatalogue.itemGroup.value;
      const result: any = await http.put(apiUrl.toString(), serviceCatalogue);
      if (result) {
        toast.success(i18nMessages.updateServiceCatalougeSuccess);
        navigate(RouteEnum.ServiceCatalogue);
      }
    } catch (error: any) {
      if (error.response) {
        let temp = { ...errors };
        if (error.response.data) {
          for (const item of error.response.data.errors) {
            if (item.field === 'ServiceCode') {
              temp.serviceCode = i18nMessages.uniqueServiceCode;
            }
            if (item.field === 'ServiceName') {
              temp.serviceName = i18nMessages.uniqueServiceName;
            }
          }
        }
        setErrors({
          ...temp,
        });
      }
    }
  };
  const onCreateOption = async (inputValue: any) => {
    let name = {};
    name['name'] = inputValue;
    name['entityType'] = MasterCategoryEnumType.ServiceCatalogue;
    const newOption: any = await PostCatagoryDropDown(
      name,
      i18nMessages.CommonSaveSuccessMsg,
      i18nMessages.CommonErrOccuredMsg
    );
    const increaseUniq = (uniq: any) => uniq + 1;
    setCacheUniq(increaseUniq);
    setCategory(newOption);
    getOrderCategories('', 1);
  };
  const ServiceCatalougeData = () => {
    return (
      <>
        <Grid item lg={4} md={8}>
          <CustomizedInputs
            margin="normal"
            displayLabel={I18n('ServiceCatalogues.ServiceName')}
            id="ServiceName"
            required
            inputType="text"
            inputProps={{ maxLength: 30 }}
            handleChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setServiceName(event.target.value)}
            value={serviceName}
            multiline={false}
            readOnly={!isEdit}
            errorValue={true}
            errorMessage={errors.serviceName}
          ></CustomizedInputs>
        </Grid>
        <Grid item lg={4} md={8}>
          <CustomizedInputs
            margin="normal"
            value={serviceCode}
            readOnly={!isEdit}
            required
            displayLabel={I18n('ServiceCatalogues.ServiceCode')}
            id="ServiceCode"
            inputType="text"
            name="serviceCode"
            inputProps={{ maxLength: 10 }}
            multiline={false}
            handleChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setServiceCode(event.target.value)}
            errorValue={true}
            errorMessage={errors.serviceCode}
          ></CustomizedInputs>
        </Grid>
        <Grid item lg={4} md={8}>
          <CustomSelectPaginateAdd
            key={JSON.stringify(category)}
            additional={defaultAdditional}
            value={category}
            handleChange={(newValue: any) => setCategory(newValue)}
            loadOptions={loadCategoryOptions}
            isSearchable={true}
            displayLabel={I18n('ServiceCatalogues.Category')}
            placeholder={defaultCategoryValue.label}
            debounceTimeout={500}
            isMultiSelect={false}
            cacheUniqs={cacheUniq}
            onCreateOption={onCreateOption}
            errorValue={true}
            errorMessage={errors.category}
            readOnly={!isEdit}
          ></CustomSelectPaginateAdd>
        </Grid>
        <Grid item lg={4} md={8}>
          <CustomizedSelect
            placeholder={department.label}
            options={departmentList}
            value={department}
            isSearchable={false}
            displayLabel={I18n('ServiceCatalogues.Department')}
            errorValue={errors.department}
            errorMessage={errors.department}
            handleChange={(newValue: BaseModel) => setDepartment(newValue)}
            readOnly={!isEdit}
          ></CustomizedSelect>
        </Grid>
        <Grid item lg={4} md={8}>
          <DecimalInput
            displayLabel={I18n('ServiceCatalogues.ShippingStandardPrice')}
            inputProps={{ maxLength: 10 }}
            required
            handleChange={(event: any) => {
              setShippingStandardPrice(event.target.value);
            }}
            value={shippingStandardPrice}
            readOnly={!isEdit}
            errorValue={true}
            errorMessage={errors.shippingStandardPrice}
          ></DecimalInput>
        </Grid>
        <Grid item lg={4} md={8}>
          <DecimalInput
            displayLabel={I18n('ServiceCatalogues.DecPackStandardPrice')}
            inputProps={{ maxLength: 10 }}
            required
            handleChange={(event: any) => {
              setDecPackStandardPrice(event.target.value);
            }}
            value={decPackStandardPrice}
            readOnly={!isEdit}
            errorValue={true}
            errorMessage={errors.decPackStandardPrice}
          ></DecimalInput>
        </Grid>
        <Grid item lg={4} md={8}>
          <StatusSelect
            placeholder={defaultValue.label}
            options={entityStatusList}
            isSearchable={false}
            displayLabel={I18n('Common.Status')}
            handleChange={(newValue: BaseModel) => onStatusChange(newValue)}
            readOnly={!isEdit}
            value={entityStatusList.find((x) => x.value === status.value)}
            errorValue={true}
            errorMessage={errors.statusId}
          ></StatusSelect>
        </Grid>
        <Grid item lg={4} md={8}>
          <CustomizedInputs
            margin="normal"
            displayLabel={I18n('ServiceCatalogues.Description')}
            id="Description"
            inputType="text"
            inputProps={{ maxLength: 50 }}
            multiline={true}
            handleChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setDescription(event.target.value)}
            value={description}
            readOnly={!isEdit}
            errorValue={true}
            errorMessage={errors.description}
          ></CustomizedInputs>
        </Grid>
        <Grid item lg={4} md={8}>
          <PricingTypeSelect
            defaultValue={pricingTypeList[0]}
            options={pricingTypeList}
            isSearchable={false}
            displayLabel={I18n('ServiceCatalogues.PricingType')}
            handleChange={(newValue: BaseModel) =>
              onPricingTypeChange(newValue)
            }
            readOnly={!isEdit}
            value={pricingTypeList.find((x) => x.value === pricingType.value)}
          ></PricingTypeSelect>
        </Grid>
        <Grid item lg={4} md={8}>
          <DecimalInput
            displayLabel={I18n('ServiceCatalogues.LastCostBuyPrice')}
            inputProps={{ maxLength: 10 }}
            required
            handleChange={(event: any) => {
              setLastCostBuyPrice(event.target.value);
            }}
            value={lastCostBuyPrice}
            readOnly={!isEdit}
            errorValue={true}
            errorMessage={errors.lastCostBuyPrice}
          ></DecimalInput>
        </Grid>
        <Grid item lg={4} md={6} sm={12}>
          <CustomSelectPaginate
            required
            readOnly={!isEdit}
            key={+ledgerAccount.value}
            additional={defaultAdditional}
            value={ledgerAccount}
            loadOptions={(search: string, prevOptions: any, page: any) =>
              loadLedgerOptions(search, prevOptions, page)
            }
            handleChange={async (newValue: any) => {
              setLedgerAccount(newValue);
            }}
            placeholder={defaultValue.label}
            isSearchable={true}
            displayLabel={i18nMessages.LedgerAccount}
            errorValue={true}
            errorMessage={errors.ledgerAccount}
            debounceTimeout={500}
            isMultiSelect={false}
          />
        </Grid>
        <Grid item lg={4} md={6} sm={12}>
          <CustomSelectPaginate
            required
            readOnly={!isEdit}
            key={+itemGroup.value}
            additional={defaultAdditional}
            value={itemGroup}
            loadOptions={(search: string, prevOptions: any, page: any) =>
              loadItemGroupOptions(search, prevOptions, page)
            }
            handleChange={async (newValue: any) => {
              setItemGroup(newValue);
            }}
            placeholder={defaultValue.label}
            isSearchable={true}
            displayLabel={i18nMessages.ItemGroup}
            errorValue={true}
            errorMessage={errors.itemGroups}
            debounceTimeout={500}
            isMultiSelect={false}
          />
        </Grid>
        <Grid item lg={4} md={8} sm={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="showInDPBOM"
                onChange={(event: any) => {
                  setShowInDPBOM(event.target.checked);
                }}
                checked={showInDPBOM}
                inputProps={{ 'aria-label': 'controlled' }}
                disabled={!isEdit}
              />
            }
            label={i18nMessages.ShowInDPBOM}
          />
        </Grid>
      </>
    );
  };

  useEffect(() => {
    getServiceCatalogueById();
  }, []);
  return (
    <div>
      <FormAccordian
        title={i18nMessages.serviceCataloguesDetailsTitle}
        showActionButton={canEdit}
        className="inner-heading"
        details={ServiceCatalougeData()}
        expanded={expanded}
        isEdit={isEdit}
        handleEditClick={handleEditClick}
        handleExpandCollapseClick={handleExpandCollapseClick}
        isSaveButton={isEdit}
        onSaveClick={handleSaveClick}
      ></FormAccordian>
    </div>
  );
}

export default ServiceCatalogueDetails;
