const frLabels = {
  Auth: {
    Email: 'E-mail',
    Password: 'Mot de passe',
    LinkSent:
      'Un lien de réinitialisation de mot de passe a été envoyé à votre adresse e-mail enregistrée.',
    NewPassword: 'Nouveau mot de passe',
    ConfirmPassword: 'Confirmez le mot de passe',
    NewPasswordRequired: 'Un nouveau mot de passe est requis',
    ConfirmPasswordRequired: 'Une confirmation du mot de passe est requise',
    PasswordMismatch: 'Mot de passe invalide',
    PasswordChanged: 'Le mot de passe a bien été modifié.',
    ErrorOccurred: "Une erreur s'est produite. Veuillez réessayer.",
    PasswordStrength:
      'Veuillez saisir un mot de passe fort contenant des chiffres, des lettres majuscules et  minuscules et au moins 6 caractères.',
    UserNotExist: "L'e-mail n'existe pas",
    ResetPassword: 'Réinitialiser le mot de passe',
    BackToLogin: 'Retour à la connexion',
    LoginToMyHazgo: 'Se connecter à MyHazgo',
    SendResetLink: 'Envoyer un lien de réinitialisation',
    ForgotPassword: 'Mot de passe oublié ?',
    EnterEmail: "Veuillez saisir l'e-mail",
    LoggedOut: 'Vous êtes bien déconnecté(e).',
    LinkExpired:
      'Le lien de réinitialisation a expiré. Veuillez générer un nouveau lien.',
  },
  Zone: {
    AddZone: 'Ajouter une zone',
    EditZone: 'Modifier la zone',
    ZoneName: 'Nom de zone',
    Country: 'Sélectionnez des pays',
    Add: 'Ajouter',
    Cancel: 'Annuler',
    Save: 'Enregistrer',
    AddSuccess: 'La zone a bien été ajoutée.',
    ThisZoneIsDefault: 'Vous ne pouvez ajouter le nom de zone par défaut.',
    ZoneNameIsAlreadyThere: 'Ce nom de zone existe déjà.',
    UpdateSuccess: 'La zone a bien été actualisée.',
  },
  Order: {
    DeleteOrder: 'Supprimer le bon',
    DeleteMessage: 'Voulez-vous vraiment supprimer le bon ?',
    OrderDetail: 'Détails du bon',
    OrderId: 'Identifiant de bon',
    OrderTitle: 'Titre du bon',
    OrderDate: 'Date du bon',
    OrderType: 'Type de bon',
    Supplier: 'Fournisseur',
    Total: 'Total (TVA incl.)',
    Status: 'État',
    Search: "Rechercher à l'aide du CodeProduit",
    DownloadSuccess: 'Téléchargé avec succès',
    OrderDeleteMessage: 'Le bon de commande a bien été supprimé',
    AddOrder: 'Ajouter un bon',
    SupplierName: 'Nom du fournisseur',
    AddSuccess: 'Le bon a bien été ajouté.',
    OrderNumber: 'Numéro de bon',
    ContactPerson: 'Contact du bon',
    ServiceOptions: 'Livraison/enlèvement marchandises/services',
    Pickup: 'Enlèvement',
    Delivery: 'Livraison',
    LicensePlate: "Plaque d'immatriculation",
    NumberOrQuantity: 'Nombre/quantité',
    CostCenter: 'Centre de coûts',
    Service: 'Service',
    OrderDescription: 'Description du bon',
    Description: 'Description',
    Go: 'Accéder',
    Reset: 'Réinitialiser',
    Cancel: 'Annuler',
    Save: 'Enregistrer',
    ProductCode: 'CodeProduit',
    Name: 'Nom',
    StandardPrice: 'PrixStandard',
    Id: 'Identifiant',
    UnitPrice: 'Prix unitaire',
    TotalPrice: 'Prix total',
    SubTotalExlVat: 'Sous-total (hors TVA)',
    BtwTax: '% de TVA (TAX)',
    Btw: 'TVA',
    TotalIncBtw: 'Total (TVA incl.)',
    AtleastAddProduct: 'Ajouter au moins 1 produit',
    DuplicateProductsNotAllowed: 'Des produits en double ne sont pas autorisés',
    QuantityAtAllLevels: 'La quantité est obligatoire pour tous les produits',
    UnitPriceAtAllLevels:
      'Le prix unitaire est obligatoire pour tous les produits',
    QuantityCannotBeZero: 'La quantité ne peut être zéro pour un produit',
    UnitPriceCannotBeZero: 'Le prix unitaire ne peut être zéro pour un produit',
    OrderSettingsMustExist: "Paramètres de bon n'existe pas",
    CreatedBy: 'Créé par',
    LastUpdated: 'Dernière actualisation le',
    UpdateOrderSuccess: 'Le bon a bien été actualisé',
    Approve: 'Approuver',
    Reject: 'Rejeter',
    Deliver: 'Bon de livraison',
    CancelOrder: 'Annuler le bon',
    StatusUpdatedSuccessfully: "L'état a bien été actualisé",
    Notes: 'Notes',
    DuplicateOrderNoExists: "Un bon en double n'existe pas",
    OrderSettingsDoesNotExist: "Paramètres de bon n'existe pas",
    PublicTrackingTitle: 'Suivi',
    PublicTrackingReference: 'Référence de suivi',
    PublicTrackingDetails: 'Détails de suivi',
  },
  Nav: {
    SignUp: "S'enregistrer",
    Login: 'Connexion',
    Logout: 'Déconnexion',
    OrderSetting: 'Paramètre de bon',
    OrderType: 'Type de bon',
    Order: 'Bon',
    Offer: 'Devis',
    Home: 'Accueil',
    APISample: 'Exemple API',
    Link1: 'Lien 1',
    Link2: 'Lien 2',
    Crud: 'Crud',
    ServiceCatalogue: 'Catalogue de services',
    ProductCatalogue: 'Catalogue de produits',
    Masters: 'Maîtres',
    TransportCatalogue: 'Catalogue de transports',
    Customers: 'Clients',
    Users: 'Utilisateurs',
    Roles: 'Rôles',
    AddRole: 'Ajouter un rôle',
    EditRole: 'Modifier le rôle',
    Zones: 'Zones',
    TariffCustoms: 'Tarif douanier',
    AddTariff: 'Ajouter un tarif',
    AddZone: 'Ajouter une zone',
    Dashboard: 'Tableau de bord',
    UnApprovedCustomers: 'Clients non approuvés',
    ProductPurchase: 'Achat de produit',
    Reference: 'Référence',
    Partners: 'Partenaires',
    PurchaseOrder: 'Bon de commande',
    PurchaseOrderSetting: 'Paramètre de bon de commande',
    ShippingOrders: "Bordereaux d'expédition",
    ShippingOffers: "Devis d'expédition",
    Questionnaire: 'Questionnaires',
    Products: 'Produits',
    Configuration: 'Configuration',
    Shipping: 'Expédition',
    Orders: 'Bons',
    Offers: 'Devis',
    DecPackOrders: 'Bons Dec & Pack',
    DecPackOffers: 'Devis Dec & Pack',
    MyProfile: 'Mon profil',
    DnpWorkOrders: 'Bons de travaux Dnp',
    WorkOrders: 'Bons de travaux',
    ShippingWorkOrders: "Bons de travaux d'expédition",
    Airline: 'Compagnie aérienne',
    State: 'État',
    Restrictions: 'Restrictions',
    Labels: 'Étiquettes',
    ExchangeRates: 'Taux de change',
    UserManagement: 'User Management-FR',
  },
  Users: {
    Users: 'Utilisateurs',
  },
  OrderSetting: {
    UpdateFormat: 'Actualiser le format de n° du bon',
    OrderNumberFormat: 'Format de n° du bon',
    OrderNoStartingNumber: 'Numéro initial n° de bon',
    SetBTW: 'Définir le % de TVA (TAX)',
    Save: 'Enregistrer',
    UpdateSuccess: 'Le paramètre de bon a bien été actualisé.',
  },
  OrderType: {
    OrderTypeMustBeUnique: 'Ce type de bon existe déjà',
    OrderTypeMustBeUniqueError: 'TypeBonDoitÊtreUnique',
    ErrorOccurred: "Une erreur s'est produite en actualisant l'enregistrement",
    updateSuccess: 'Le type de bon a bien été actualisé.',
    ZeroSet: "Un montant nul ou négatif n'est pas autorisé",
    OrderTypeDetails: 'Détails du type de bon',
    AddOrderType: 'Ajouter un type de bon',
    OrderTypes: 'Type de bon',
    AddSuccess: 'Le type de bon a bien été ajouté.',
    ApprovalLevel: "Niveaux d'approbation",
    ThresholdApproval: 'Approbation de seuil',
    ThresholdAmount: 'Montant seuil',
    SameUser: 'Veuillez sélectionner un autre utilisateur',
    AmountError: 'Le montant doit être supérieur à la valeur seuil ci-dessus',
    Level: 'Niveau',
    Status: 'État',
    LastUpdatedBy: 'Dernière actualisation par',
    LastUpdatedOn: 'Dernière actualisation le',
    Action: 'Action',
    AssignUser: 'Attribuer un utilisateur',
    DeleteModal: {
      Message: 'Voulez-vous supprimer ce type de bon ?',
      Heading: 'En êtes-vous sûr ?',
      Cancel: 'Annuler',
      Ok: "D'accord",
    },
  },
  TransportCatalogue: {
    ErrorOccurred: "Une erreur s'est produite en actualisant l'enregistrement",
    DeleteSuccess: 'Le catalogue de transports a bien été supprimé.',
    DeleteConfirmation:
      'Voulez-vous vraiment supprimer ce catalogue de transports ?',
    TransCode: 'Code Trans',
    Category: 'Catégorie',
    FromZone: 'À partir de la zone',
    ToZone: 'Vers la zone',
    TransportType: 'Type de transport',
    DeleteTransportCatalogue: 'Supprimer le catalogue de transports',
    AddTransportCatalogue: 'Ajouter un catalogue de transports',
    TransportCode: 'Code de transport',
    Details: 'Détails du catalogue de transports',
    airPricing: 'Tarif aérien',
    roadPricing: 'Tarif routier',
    trainPricing: 'Tarif ferroviaire',
    shipPricing: 'Tarif maritime',
    baseRate: 'Taux de base',
    deliveryBaseRate: 'Taux de base de livraison',
    fuelBaseRate: 'Taux de base de carburant',
    Kg: 'KG',
    DeliveryPrice: 'Prix de livraison ',
    FuelPrice: 'Prix de carburant',
    ToZoneCounries: 'Vers des pays de la zone',
    Countries: 'Pays',
    AddSuccess: 'Le catalogue de transports a bien été ajouté.',
    FromZoneName: 'À partir du nom de zone',
    ToZoneName: 'Vers le nom de zone',
    BothZoneSame: 'Les deux zones sont identiques.',
    Notes: 'Notes',
    Documents: 'Documents',
    Detail: 'Détails',
    UpdateSuccess: 'Le catalogue de transports a bien été actualisé.',
    UniqueTransportCode: 'Le code de transport doit être unique.',
    UniqueZoneCombination: 'Cette combinaison existe déjà.',
    OwnPricing: 'Propre tarif',
  },
  ServiceCatalogues: {
    ServiceCode: "Indicatif d'entretien",
    Category: 'Catégorie',
    ServiceName: 'Nom du service',
    Description: 'Description',
    StandardPrice: 'Prix standard',
    LastCostBuyPrice: "Dernier coût / Prix d'achat",
    ShippingStandardPriceFrom: 'Prix standard expédition (à partir de)',
    ShippingStandardPriceTo: 'Prix standard expédition (vers)',
    DecPackStandardPriceFrom: 'DecPack prix standard (à partir de)',
    DecPackStandardPriceTo: 'Prix standard DecPack (vers)',
    AddServiceCatalouge: 'Ajouter un catalogue de services',
    AddSuccess: 'Le catalogue de services a bien été ajouté.',
    UpdateSuccess: 'Le catalogue de services a bien été actualisé.',
    DeleteSuccess: 'Le catalogue de services a bien été supprimé.',
    DeleteConfirmation:
      'Voulez-vous vraiment supprimer ce catalogue de services ?',
    PricingType: 'Type de tarif',
    Details: 'Détails du catalogue de services',
    Tabs: {
      Details: 'Détails',
      SpecialPricing: 'Prix spécial',
      AllowedOrderCategory: 'Catégorie de bons autorisée',
      Documents: 'Documents',
      Notes: 'Notes',
    },
    ShippingStandardPrice: 'Prix standard expédition',
    DecPackStandardPrice: 'Prix standard DecPack',
    Department: 'Département',
  },
  LabelMaster: {
    Shipper: 'Expéditeur',
    Consignee: 'Destinataire',
    Address: 'Adresse',
    Address2: 'Adresse 2',
    PostalCode: 'Code postal',
    EmergencyPhoneNumber: "Numéro d'appel d'urgence",
    City: 'Ville',
    Country: 'Pays',
    DeleteConfirmation: 'Voulez-vous vraiment supprimer cette étiquette ?',
    LabelSaveSuccess: "Les détails de l'étiquette ont bien été enregistrés",
    LabelUpdateSuccess: "L'étiquette a bien été actualisée",
    LabelNotExist: "L'étiquette n'existe pas",
    LabelSaveFail:
      "Un problème s'est produit, veuillez réessayer ultérieurement",
    AddLabel: 'Ajouter une étiquette',
    EditLabel: "Modifier l'étiquette",
    ShipperDetail: "Détails de l'expéditeur",
    ConsigneeDetail: 'Détails du destinataire',
    OtherDetail: 'Autres détails',
    Validation: {
      CountryRequired: 'Le pays est obligatoire.',
      CityRequired: 'La ville est obligatoire.',
      PostalCodeRequired: 'Le code postal est obligatoire.',
      PostalCodeMaxLength:
        'Maximum 20 caractères autorisés pour le code postal.',
      AddressRequired: "L'adresse est obligatoire.",
      AddressMaxLength: "Maximum 250 caractères autorisés pour l'adresse.",
      ConsigneeRequired: 'Le destinataire est obligatoire',
      ConsigneeMaxLength:
        'Maximum 250 caractères autorisés pour le destinataire.',
      ShipperRequired: "L'expéditeur est obligatoire",
      ShipperMaxLength: "Maximum 250 caractères autorisés pour l'expéditeur.",
      ConditionalRequired: 'Saisissez au moins 1 valeur de champ',
    },
  },
  ExchangeRate: {
    FromCurrency: 'De la devise',
    ToCurrency: 'Vers la devise',
    Rate: 'Taux',
    DeleteConfirmation: 'Voulez-vous vraiment supprimer le taux de change ?',
    DeleteSuccess: 'Le taux de change a bien été supprimé',
    ExchangeRateSaveSuccess: 'Le taux de change a bien été enregistré',
    ExchangeRateUpdateSuccess: 'Le taux de change a bien été actualisé',
    ExchangeRateNotExist: "Le taux de change n'existe pas",
    ExchangeRateSaveFail:
      "Un problème s'est produit, veuillez réessayer ultérieurement",
    AddExchangeRate: 'Ajouter un taux de change',
    EditExchangeRate: 'Modifier le taux de change',
    Validation: {
      FromCurrencyRequired: 'De la devise est obligatoire',
      ToCurrencyRequired: 'Vers la devise est obligatoire',
      RateRequired: 'Le taux est obligatoire',
      MappingAlreadyExist: 'Le taux de change existe déjà !',
    },
  },
  CustomerLocation: {
    Country: 'Pays',
    City: 'Ville',
    ZipCode: 'Code postal',
    Address: 'Adresse',
    Address2: 'Adresse 2',
    IsMainAddress: "Est l'adresse principale",
    AddLocation: 'Ajouter un lieu',
    EditLocation: 'Modifier le lieu',
    Search: 'Rechercher',
    Street: 'Rue',
    DestinationCompany: 'Entreprise de destination',
    PickupCompany: "Entreprise d'enlèvement",
    DeleteConfirmation: 'Voulez-vous vraiment supprimer ce lieu ?',
    Validation: {
      CountryRequired: 'Le pays est obligatoire.',
      CityRequired: 'La ville est obligatoire.',
      ZipCodeRequired: 'Le code postal est obligatoire.',
      ZipCodeMaxLength: 'Maximum 20 caractères autorisés pour le code postal.',
      AddressRequired: "L'adresse est obligatoire.",
      AddressMaxLength: "Maximum 250 caractères autorisés pour l'adresse.",
      DestinationCompanyRequired: "L'entreprise de destination est obligatoire",
      DestinationCompanyMaxLength:
        "Maximum 250 caractères autorisés pour l'entreprise de destination",
      StreetCompanyRequired: "L'adresse est obligatoire",
      StreetCompanyMaxLength: "Maximum 250 caractères autorisés pour l'adresse",
      PickupCompanyRequired: "L'entreprise d'enlèvement est obligatoire",
      PickupCompanyMaxLength:
        "Maximum 250 caractères autorisés pour l'entreprise d'enlèvement",
    },
  },
  Document: {
    Document: 'Document',
    Language: 'Langue',
    AddedBy: 'Ajouté par',
    AddedOn: 'Ajouté le',
    ViewLevel: "Niveau d'affichage",
    AddDocument: 'Ajouter un document',
    SaveSuccessMessage: 'Le document a bien été enregistré',
    DeleteConfirmation: 'Voulez-vous vraiment supprimer ce document ?',
    FileSizeExceeded:
      'Le fichier est trop volumineux. La taille est limitée à 10 Mo.',
    FileNotDownloaded: 'Impossible de télécharger. Veuillez réessayer.',
    Validation: {
      DocumentTagRequired: "L'étiquette de document est obligatoire.",
      DocumentNameRequired: 'Le nom de document est obligatoire.',
      DocumentNameMaxLength:
        'Maximum 50 caractères autorisés pour le nom de document.',
      LanguageRequired: 'La langue est obligatoire.',
      ViewLevelRequired: "Le niveau d'affichage est obligatoire.",
      FileRequired: 'Le fichier est obligatoire.',
    },
  },
  ViewLevel: {
    Label: "Niveau d'affichage",
    Options: {
      InternalManagement: 'Interne (direction)',
      InternalOperational: 'Interne (opérationnel)',
      InternalAll: 'Interne (tous)',
      External: 'Externe',
    },
    ExternalInfoMessage: 'Ce document sera affiché pour les clients.',
  },
  Common: {
    IsMainAddressError:
      'Des données avec EstLadressePrincipale sont déjà sélectionnées.',
    Driver: 'Chauffeur',
    DecNPack: 'Dec & Pack',
    ToDo: 'À faire',
    InProgress: 'EnCours',
    Export: 'Exporter',
    SingleUse: 'UsageUnique',
    Reusable: 'Réutilisable',
    Pickup: 'Enlèvement',
    Delivery: 'Livraison',
    Approved: 'Approuvé',
    Pending: 'En attente',
    Actions: 'Actions',
    Status: 'État',
    Active: 'Actif',
    Inactive: 'Inactif',
    Rejected: 'Rejeté',
    Yes: 'Oui',
    No: 'Non',
    Reset: 'Réinitialiser',
    Apply: 'Appliquer',
    ErrorOccurred: "Une erreur s'est produite",
    Operations: 'Opérations',
    Accounting: 'Comptabilité',
    Helpdesk: "Service d'assistance",
    Details: 'Détails',
    Save: 'Enregistrer',
    Filter: 'Filtrer',
    Add: 'Ajouter',
    FieldRequired: 'Ce champ est obligatoire.',
    InvalidEmail: 'Adresse e-mail non valide saisie',
    InvalidPhone: 'Numéro de téléphone non valide saisi',
    EmployeeCode: 'CodeEmployé',
    Email: 'E-mail',
    PhoneNumberVal: 'NuméroDeTéléphone',
    DeleteRole: 'Voulez-vous supprimer ce rôle ?',
    Language: {
      EN: 'EN',
      FR: 'FR',
      NL: 'NL',
    },
    Cancel: 'Annuler',
    SavedSuccessfully: 'Enregistré avec succès',
    En: 'English',
    NL: 'Nederlands',
    FR: 'Français',
    All: 'Tous',
    Internal: 'Interne',
    Search: 'Rechercher',
    SearchbyName: 'Rechercher par nom',
    Department: 'Département',
    NoRecords: 'Aucun enregistrement',
    NoDocuments: 'Aucun document',
    Download: 'Télécharger',
    NoDocumentPreview: "La prévisualisation de document n'est pas disponible !",
    FieldIsRequired: 'Ce champ est obligatoire.',
    InvalidDate: 'Date non valide',
    InvalidTime: 'Heure non valide',
    Standard: 'Standard',
    Special: 'Spécial',
    SomethingWentWrong:
      "Un problème s'est produit, veuillez réessayer ultérieurement.",
    Edit: 'Modifier',
    Delete: 'Supprimer',
    Allowed: 'Autorisé',
    Specific: 'Spécifique',
    SpecificCategories: 'Catégories spécifiques',
    InvalidQuantityDelete: `La quantité disponible sera affectée par cet enregistrement. Vous ne pouvez donc pas supprimer cet enregistrement.`,
    EnteredQuantityIsNotValid: "La quantité saisie n'est pas valide.",
    UniqueServiceCode: 'Le code de service doit être unique.',
    UniqueServiceName: 'Le nom de service doit être unique.',
    InvalidOtp: 'OTP non valide saisi',
    OtpExpired: 'OTP expiré. Veuillez générer un nouvel OTP',
    AllowedOrderCatagorySaveSuccess:
      'La catégorie de bons autorisée a bien été enregistrée.',
    Cancelled: 'Annulé',
    Delivered: 'Livré',
    PartiallyDelivered: 'Livré partiellement',
    Draft: 'Brouillon',
    Previous: 'Précédent',
    Next: 'Enregistrer et Suivant',
    Submit: 'Envoyer',
    New: 'Nouveau',
    InProcess: 'En cours',
    Done: 'Terminé',
    OrderCompleted: 'Bon terminé',
    Invoiced: 'Facturé',
    OnHold: 'En attente',
    SentForApproval: 'Envoyé pour approbation',
    InvoiceApproved: 'Facture approuvée',
    Closed: 'Fermé',
    fileSizeExceedErr:
      'Le fichier est trop volumineux. La taille est limitée à 10 Mo.',
    UploadFileExceedError:
      'Le fichier est trop volumineux. La taille est limitée à #maxFileSize#.',
    ImageResolutionExceedError:
      'Veuillez télécharger une image avec une résolution de 600 X 500',
    fileTypeErr:
      'Format de fichier non valide. Veuillez télécharger un fichier valide.',
    UploadFileFormatError:
      'Format de fichier non valide. Veuillez télécharger un fichier #acceptedFiles# valide.',
    DuplicateRecord: "L'enregistrement existe déjà",
    IgnoreDateFilter: 'Ignorer filtre de date',
    IgnorePriceFilter: 'Ignorer filtre de prix',
    ZeroValidation: 'Veuillez saisir une valeur supérieure à 0.',
    MaxAllowed: 'Le nombre max. de documents autorisés est de ',
    DocumentTag: 'Balise de document',
    NoImagesSelectedToUpload: 'Aucune image sélectionnée à télécharger',
    ImageSaveSuccess: "L'image a bien été enregistrée",
    EditPicture: 'Modifier la photo',
    WOAlreadyInProgress: 'Un autre bon de travaux est déjà en cours',
    Booking: 'WO-Réservation',
    DGDCreation: 'WO-Création DGD',
    WODocumentation: 'WO-Documentation',
    WOPickup: 'WO-Enlèvement',
    Inbound: 'WO-Entrant',
    Packaging: 'WO-Emballage',
    Outbound: 'WO-Sortant',
    WODelivery: 'WO-Livraison',
    DocumentCheck: 'WO-Contrôle document',
    PhysicalCheck: 'WO-Contrôle physique',
    Past: 'Passé',
    Today: "Aujourd'hui",
    Future: 'Futur',
    DateRange: 'Plage de dates',
    RequiredField: 'Saisissez les champs obligatoires',
    CitySavedSuccessfully: 'A ajouté une nouvelle ville',
    SaveNext: 'Enregistrer et Suivant',
    FileUploadMessage:
      'La taille minimale du logo doit être de 300x300 px, et la taille maximale du fichier ne doit être supérieure à 2 Mo',
    Web: 'Web',
    Mobile: 'Portable',
    AwaitingAssignment: "En attente d'attribution",
    Attentions: {
      HighPriority: 'Priorité élevée',
      Secure: 'Sécurisé',
      AwaitingInfo: "En attente d'informations",
      OnHold: 'En attente',
      AwaitingInstructionFromClient: "En attente d'instructions du client",
    },
    NA: 'NA',
    ClientNotAvailable: 'Client pas disponible',
    ClientNotReady: 'Client pas prêt à signer',
    Signature: 'Signature',
    SignedOffBy: 'Signé par',
    Update: 'Actualiser',
    SentBy: 'Envoyé par',
    ApprovedBy: 'Approuvé par',
    Own: 'Propre',
    Location: 'Lieu',
    SameAsAbove: 'Comme ci-dessus',
    ApproveOrder: 'Approuver le bon',
    SendToCustomer: 'Envoyer au client',
    InvalidFileFormat: 'Format de fichier non valide',
  },
  Approval: {
    OrderOwner: 'Propriétaire du bon',
    SendForApproval: 'Envoyer pour approbation',
    SentForApproval: 'Envoyé pour approbation',
    Approver: 'Approbateur',
    Approve: 'Approuver',
    Approved: 'Approuvé',
    SentBy: 'Envoyé par',
    ApprovedBy: 'Approuvé par',
    SendForApprovalConfirmation:
      'Voulez-vous vraiment envoyer le bon pour approbation ?',
    SendForApprovalConfirmationDetails: 'Envoyer le bon pour approbation',
    ApprovalConfirmMessage: 'Voulez-vous vraiment approuver ?',
    ApprovalConfirmTitle: 'Approbation de bon',
    TotalProfitLoss: 'Total profit/perte',
    VAT: 'TVA',
    VATPercent: 'Pourcentage de TVA',
    VATDetails: 'Détails de TVA',
    InvalidVatPercent:
      'Veuillez actualiser le % de TVA pour ce client à 0 ou à 21 % dans le module client',
  },
  Notes: {
    Description: 'Note',
    IsInternalOnly: 'Est interne uniquement ?',
    AddedOn: 'Ajouté le',
    AddedBy: 'Ajouté par',
    DeletedSuccessfully: 'Supprimé avec succès',
    DeleteMessage: 'Voulez-vous vraiment supprimer la note ?',
    DeleteNote: 'Supprimer la note',
    AddNote: 'Ajouter une note',
    EditNote: 'Modifier la note',
    Save: 'Enregistrer',
    Cancel: 'Annuler',
    SavedSuccessfully: 'Enregistré avec succès',
    UpdatedSuccessfully: 'Actualisé avec succès',
    ErrorOccurred: "Une erreur s'est produite",
    FieldRequired: 'Le champ est obligatoire',
  },
  CreditNote: {
    Label: 'Note de crédit',
    Description: 'Description',
    Note: 'Note',
    Amount: 'Montant',
    DeletedSuccessfully: 'Supprimé avec succès',
    DeleteMessage: 'Voulez-vous vraiment supprimer la note ?',
    DeleteNote: 'Supprimer la note',
    AddNote: 'Ajouter une note',
    EditNote: 'Modifier la note',
    Save: 'Enregistrer',
    Cancel: 'Annuler',
    SavedSuccessfully: 'Enregistré avec succès',
    UpdatedSuccessfully: 'Actualisé avec succès',
    ErrorOccurred: "Une erreur s'est produite",
    FieldRequired: 'Le champ est obligatoire',
    DescriptionFieldRequired: 'Description est obligatoire',
    AmountFieldRequired: 'Montant est obligatoire',
  },
  ContactPerson: {
    Name: 'Nom',
    FirstName: 'Prénom',
    LastName: 'Nom',
    Function: 'Fonction',
    Phone: 'Téléphone',
    Mobile: 'Portable',
    Email: 'E-mail',
    SiteInformation: 'Informations sur le site',
    SystemAccess: 'Accès au système',
    Status: 'État',
    DeletedSuccessfully: 'Supprimé avec succès',
    DeleteMessage: 'Voulez-vous vraiment supprimer le contact ?',
    DeleteContactPerson: 'Supprimer le contact',
    ErrorOccurred: "Une erreur s'est produite",
    ContactPersonCannotBeDeleted: 'Impossible de supprimer le contact',
    SavedSuccessfully: 'Enregistré avec succès',
    AddContactPerson: 'Ajouter un contact',
    FieldRequired: 'Le champ est obligatoire',
    InvalidEmailAddress: 'Adresse e-mail non valide saisie',
    AccessLevel: "Niveau d'accès",
    Location: 'Lieu',
  },
  Placeholders: {
    SelectAssignmentType: 'Sélectionnez un type',
    SelectPartner: 'Sélectionnez un partenaire',
    selectProductType: 'Sélectionnez un type',
    SelectStatus: 'Sélectionnez un état',
    Select: 'Sélectionner',
    SelectDepartment: 'Sélectionnez un département',
    SelectMajorRole: 'Sélectionnez un rôle majeur',
    Actions: 'Actions',
    Yes: 'Oui',
    No: 'Non',
    ErrorOccurred: "Une erreur s'est produite",
    Operations: 'Opérations',
    Accounting: 'Comptabilité',
    Helpdesk: "Service d'assistance",
    Save: 'Enregistrer',
    Cancel: 'Annuler',
    SavedSuccessfully: 'Enregistré avec succès',
    DeleteConfirmation: 'Voulez-vous vraiment supprimer ?',
    Status: 'État',
    Active: 'Actif',
    Inactive: 'Inactif',
    Reset: 'Réinitialiser',
    Apply: 'Appliquer',
    En: 'Anglais',
    NL: 'Néerlandais',
    FR: 'Français',
    All: 'Tous',
    Internal: 'Interne',
    Country: 'Pays',
    City: 'Ville',
    ZipCode: 'Code postal',
    Address: 'Adresse',
    IsMainAddress: "EstL'adressePrincipale",
    Search: 'Rechercher',
    NoRecords: 'Aucun enregistrement',
    NoDocuments: 'Aucun document',
    Download: 'Télécharger',
    NoDocumentPreview: "La prévisualisation de document n'est pas disponible !",
    SelectCustomer: 'Sélectionnez un client',
    SelectLanguage: 'Sélectionnez une langue',
    SelectUser: 'Sélectionnez un utilisateur',
    SelectCategory: 'Sélectionnez une catégorie',
    SelectValidity: 'Sélectionnez une validité',
    SelectOrder: 'Sélectionne un bon',
    SelectZone: 'Sélectionnez une zone',
    SelectTransportType: 'Sélectionnez un type de transport',
    SelectLocation: 'Sélectionnez un lieu',
    SelectContact: 'Sélectionnez un contact',
    SelectService: 'Sélectionnez un service',
    SelectRejection: 'Sélectionnez un motif de rejet',
    SelectShippingType: "Sélectionnez un type d'expédition",
    SelectOfferStatus: "Sélectionnez l'état du bon",
    SelectTrackingType: 'Sélectionnez un type',
    SelectDecPackType: 'Sélectionnez un type de DecPack',
    SelectPartnerType: 'Sélectionnez un type de partenaire',
    SelectTracking: 'Sélectionnez un suivi',
    SelectLabelType: "Sélectionnez un type d'étiquette",
    SelectLayoutType: 'Sélectionnez un type de disposition',
    SelectConsignee: 'Sélectionnez un destinataire',
    SelectShipper: 'Sélectionnez un expéditeur',
    SelectDestinationCompany: 'Sélectionnez une entreprise de destination',
    SelectPickupCompany: "Sélectionnez une entreprise d'enlèvement",
  },
  UserList: {
    EmpCode: 'Code emp',
    Name: 'Nom',
    Role: 'Rôle',
    Roles: 'Rôles',
    Department: 'Département',
    Mobile: 'Portable',
    Email: 'E-mail',
    Language: 'Langue',
    Status: 'État',
    DeleteModal: {
      Message: 'Vous voulez supprimer cet utilisateur',
      Heading: 'En êtes-vous sûr ?',
      Cancel: 'Annuler',
      Ok: "D'accord",
    },
  },
  UsersAdd: {
    AddUsers: 'AjouterUtilisateurs',
    AdditionalDetails: 'Détails supplémentaires',
    UserDetails: "Détails de l'utilisateur",
    EmpCode: 'Code emp',
    Name: 'Nom',
    Email: 'E-mail',
    Role: 'Rôle',
    Department: 'Département',
    Mobile: 'Portble',
    PreferredLanguage: 'Langue préférée',
    Status: 'État',
    Cancel: 'Annuler',
    Add: 'Ajouter',
    EmployeeCode: "Code d'employé",
    PhoneNumber: 'Numéro de téléphone',
    DepartmentSelect: {
      Shipping: 'Expédition',
      DecPack: 'DecPack',
      Both: 'Les deux',
    },
    FirstName: 'Prénom',
    LastName: 'Nom',
    Phone: 'Téléphone',
    UserType: "Type d'utilisateur",
    UserCreatedSuccss: "L'utilisateur a bien été créé",
  },
  UsersEdit: {
    Save: 'Enregistrer',
    UserDetails: "Détails de l'utilisateur",
    PreferredLanguage: 'Langue préférée',
    Status: 'État',
    RolesAssigned: 'Le rôle a bien été attribué',
    RolesDeleted: 'Le rôle a bien été supprimé',
    RolesDetails: 'Détails du rôle',
    UserUpdatedSuccss: "L'utilisateur a bien été actualisé",
  },
  UsersProfile: {
    MyProfile: 'Mon profil',
    PreferredLanguage: 'Langue préférée',
    Status: 'État',
    ProfilePicture: 'Photo de profil',
    FirstName: 'Prénom',
    LastName: 'Nom',
    Phone: 'Téléphone',
    Email: 'E-mail',
    Mobile: 'Portable',
    ProfileUpdated: 'Le profil a bien été actualisé',
    PasswordUpdatedLoginAgain:
      'Le mot de passe a bien été modifié. Veuillez vous connecter avec le mot de passe modifié',
  },
  Customer: {
    Tabs: {
      Details: 'Détails',
      ContactPerson: 'Contact',
      Location: "Carnet d'adresses",
      Documents: 'Documents',
      Notes: 'Notes',
      SpecialPricing: 'Tarif spécial',
      PricingType: 'Type de tarif',
    },
    Name: 'Nom',
    VatDeferment: 'Compte de report de TVA',
    VatNumber: 'Numéro de TVA',
    EORI: 'EORI',
    Address: 'Adresse',
    PCCity: 'CP, Ville',
    Country: 'Pays',
    Tag: 'Département',
    Approved: 'Approuvé',
    Status: 'État',
    SearchbyName: 'Rechercher par nom',
    Logo: 'Logo',
    Details: 'Détails',
    FinancialBasic: 'Base financière',
    ContactDetails: 'Détails de contact',
    Invoicing: 'Facturation',
    PostalCode: 'Code postal',
    City: 'Ville',
    Website: 'Site Web',
    SameAsVat: 'Identique à la TVA',
    IsDebtor: 'Est le débiteur',
    GeneralTelephone: 'Téléphone général',
    GeneralEmail: 'E-mail général',
    InvoiceAddressDifferent: 'Adresse de facturation - Est différente ?',
    InvoiceEmail: 'E-mail facture',
    InvoicingCycle: 'Cycle de facturation',
    BillingCurrency: 'Devise de facturation',
    CustomerType: 'Type de client',
    VatPercentage: '% de TVA',
    Shipping: 'Expédition',
    DecPack: 'DecPack',
    Both: 'Les deux',
    IsDifferent: 'Est différent',
    CustomerName: 'Nom du client',
    InvoicePostalCode: 'Code postal facture',
    InvoiceCity: 'Ville facture',
    InvoiceCountry: 'Pays facture',
    InvoiceAddress: 'Adresse facture',
    Approve: 'Approuver',
    Reject: 'Rejeter',
    UpdatedSuccessfully: 'Actualisé avec succès',
    NoRowsSelected: 'Aucune ligne sélectionnée',
    ResendForApproval: 'Renvoyé avec succès pour approbation',
    Validation: {
      CustomerNameRequired: 'Le nom du client est obligatoire.',
      CustomerNameMaxLength:
        'Maximum 50 caractères autorisés pour le nom du client.',
      AddressMaxLength: "Maximum 250 caractères autorisés pour l'adresse.",
      PostalCodeMaxLength:
        'Maximum 20 caractères autorisés pour le code postal.',
      InvoiceAddressMaxLength:
        "Maximum 250 caractères autorisés pour l'adresse de facture.",
      InvoicePostalCodeMaxLength:
        'Maximum 20 caractères autorisés pour le code postal de facture.',
      WebsiteMaxLenght: 'Maximum 255 caractères autorisés pour le site Web.',
      VatNumberUnique: 'Le numéro de TVA doit être unique.',
      VatDefermentMaxLength:
        'Maximum 30 caractères autorisés pour le compte de report de TVA.',
      VatNumberMaxLength:
        'Maximum 30 caractères autorisés pour le numéro de TVA.',
      EORIMaxLength: 'Maximum 30 caractères autorisés pour EORI.',
      TelephoneMaxLength: 'Maximum 20 caractères autorisés pour le téléphone.',
      StatusRequired: "L'état est obligatoire.",
      EmailMaxLength: "Maximum 255 caractères autorisés pour l'e-mail.",
      InvalidEmail: 'Adresse e-mail non valide saisie',
      InvoiceEmailMaxLength:
        "Maximum 255 caractères autorisés pour l'e-mail de facture.",
      CustomerFirstNameRequired: 'Le nom du client est obligatoire.',
      CustomerFirstNameMaxLength:
        'Maximum 50 caractères autorisés pour le prénom du client.',
      CustomerLastNameRequired: 'Le nom du client est obligatoire.',
      CustomerLastNameMaxLength:
        'Maximum 50 caractères autorisés pour le nom du client.',
      InvalidVatPercent: 'Veuillez actualiser le % de TVA à 0 ou à 21 %',
      InvalidCustomerSpecialPricing:
        "Un tarif spécial pour l'entité existe déjà",
    },
    AddCustomer: 'Ajouter un client',
    ViewCustomer: 'Détails du client',
    CustomerDetails: 'Détails du client',
    DeletedSuccessfully: 'Supprimé avec succès',
    DeleteMessage: 'Voulez-vous vraiment supprimer le client ?',
    DeleteCustomer: 'Supprimer le client',
    ErrorOccurred: "Une erreur s'est produite",
    FirstName: 'Prénom',
    LastName: 'Nom',
    ToolTips: {
      ResendForApproval: 'Renvoyé pour approbation',
    },
    SearchAddress: 'Rechercher une adresse',
  },
  RoleList: {
    Name: 'Nom',
    Status: 'État',
    DeleteMessage: 'Voulez-vous vraiment supprimer la note ?',
    DeleteRole: 'Supprimer le rôle',
  },
  Role: {
    Module: 'Module',
    View: 'Afficher',
    Add: 'Ajouter',
    Edit: 'Modifier',
    Delete: 'Supprimer',
    Approver: 'Approbateur',
  },
  TariffCustomsList: {
    DeleteMessage:
      "Voulez-vous vraiment supprimer l'enregistrement tarifaire ?",
    DeleteTariffTitle: 'Supprimer le détail du tarif',
    choose_tariff_file: 'Choisir le fichier de tarif',
    Cancel: 'Annuler',
    Save: 'Enregistrer',
  },
  AddTariff: {
    cnKey: 'Touche CN',
    cn: 'Code de marchandise',
    cnLevel: 'Niveau',
    t_SU_SU: 'T SU SU',
    description: 'Description',
    parentCNName: 'CN (numéro de douane) parent',
    parent: 'Parent',
    status: 'État',
  },
  Questionnaire: {
    questionnaireDetail: 'Détails du questionnaire',
    add: 'Ajouter',
    subject: 'Objet',
    description: 'Description',
    Language: 'Langue',
    save: 'Enregistrer',
    cancel: 'Annuler',
    AddQuesionnaire: 'Ajouter un questionnaire',
    EditQuesionnaire: 'Modifier le questionnaire',
    preferredLanguage: 'Langue préférée',
    status: 'État',
    questionnaireAddMsg: 'Le questionnaire a bien été ajouté',
    questionnaireUpdateMsg: 'Le questionnaire a bien été actualisé',
    fieldRequired: 'Ce champ est obligatoire',
    chnageStatusErrMsg:
      "Vous ne pouvez modifier l'état, car il est associé à un bon de travaux",
    OptionsFieldErrorMsg:
      "Veuillez saisir correctement toutes les options, ou les supprimer de l'écran.",
    OptionsFieldMin2Err:
      'Vous devez saisir au moins 2 options, en cas de liste déroulante ou de choix multiple.',
    DeleteMessage: 'Voulez-vous vraiment supprimer le questionnaire ?',
    DeleteQuestionnaireTitle: 'Supprimer le questionnaire',
    DeletionSuccessMsg: 'Le questionnaire a bien été supprimé',
    CopySuccessMsg: 'Le questionnaire a bien été copié',
    CopyQuestionnaireTitle: 'Copier le questionnaire',
    CopyQuestionnaireMsg:
      'Voulez-vous vraiment créer un duplicata du questionnaire ?',
    QueDeletionSuccessMsg: 'La question a bien été supprimée',
    DeleteQuestionConfirmationMessage:
      'Voulez-vous vraiment supprimer la question ?',
    DeleteQuestionConfirmationTitle: 'Supprimer la question',
    PrepareQuestionnaire: 'Préparer le questionnaire',
    AddQuesion: 'Ajouter une question',
    EditQuesion: 'Modifier la question',
    AddYourQue: 'Ajouter votre question',
    AnswerType: 'Type de réponse',
    Options: 'Options',
    QuestionAddSuccessMsg: 'La question a bien été ajoutée',
    QuestionUpdateSuccessMsg: 'La question a bien été actualisée',
    NoQuestionsFound: "Aucune question n'a été trouvée",
    QuestionType: {
      MultipleChoice: 'Choix multiple',
      Dropdown: 'Liste déroulante',
      YesOrNo: 'Oui/Non',
      Text: 'Texte',
    },
    QueWoSync: {
      QuestionnaireWOAssociation: 'Questionnaire Association WO',
      QuestionnaireName: 'Nom de questionnaire',
      WorkorderName: 'Nom du bon de travaux / type',
      AddQueWOAsociation: 'Ajouter un questionnaire Association WO',
      EditQueWOAsociation: 'Modifier le questionnaire Association WO',
      SelectQuestionnaire: 'Sélectionnez un questionnaire',
      SelectWorkorder: 'Sélectionnez un bon de travaux',
      SelectType: 'Sélectionnez un type',
      Workorder: 'Bon de travaux',
      Type: 'Type',
      Questionnaire: 'Questionnaire',
      MappingAddMsg:
        'Les détails de mise en correspondance ont bien été ajoutés',
      MappingUpdateMsg:
        'Les détails de mise en correspondance ont bien été actualisés',
      DeleteMappingModalMsg:
        'Voulez-vous vraiment supprimer cette mise en correspondance ?',
      DeleteMappingModalTitle: 'Supprimer la mise en correspondance',
      DeletionMappingMsg: 'La mise en correspondance a bien été supprimée',
      DeletionMappingErrMsg:
        'Vous ne pouvez supprimer ce questionnaire, car il est mis en correspondance avec un bon de travaux.',
    },
  },
  Validations: {
    Required: ' est obligatoire',
  },
  SpecialPricing: {
    Customer: 'Client',
    SellingPrice: 'Prix de vente spécial',
    AddedOn: 'Ajouté le',
    AddedBy: 'Ajouté par',
    DeletedSuccessfully: 'Le tarif spécial a bien été supprimé.',
    DeleteMessage: 'Voulez-vous vraiment supprimer le tarif spécial ?',
    DeleteSpecialPricing: 'Supprimer le tarif spécial ?',
    AddSpecialPricing: 'Ajouter un tarif spécial',
    EditSpecialPricing: 'Modifier le tarif spécial',
    Save: 'Enregistrer',
    Cancel: 'Annuler',
    SavedSuccessfully: 'Le tarif spécial a bien été enregistré.',
    UpdatedSuccessfully: 'Le tarif spécial a bien été actualisé.',
    ShippingSellingPrice: "Prix de vente spécial d'expédition",
    DecPackSellingPrice: 'Prix de vente spécial DecPack',
    PricingType: 'Type de tarif',
  },
  ProductCatalogues: {
    AddProductCatalouge: 'Ajouter un catalogue de produits',
    Tabs: {
      Details: 'Détails',
      SpecialPricing: 'Tarif spécial',
      AllowedOrderCategory: 'Catégorie de bons autorisée',
      Documents: 'Documents',
      Notes: 'Notes',
      Purchases: 'Achats',
      Consumptions: 'Consommations',
      CurrentCheckout: 'Validation actuelle',
      Assignment: 'Attribution',
      RemoveStock: 'Sortir le stock',
    },
    ProductPurchases: {
      Date: 'Date',
      Quantity: 'Quantité',
      Supplier: 'Acheté à',
      PricePerUnit: 'Prix unitaire',
      Remarks: 'Remarques',
      CreatedBy: 'Ajouté par',
      SearchBySupplier: 'Rechercher par fournisseur',
      DeleteProductPurchase: "Supprimer l'achat de produit",
      DeleteMessage: "Voulez-vous vraiment supprimer l'achat ?",
      DeletedSuccessfully: 'Supprimé avec succès',
      ErrorOccurred: "Une erreur s'est produite",
      EditProductPurchase: "Modifier l'achat de produit",
      EditedSuccessfully: 'Modifié avec succès',
      AddProductPurchase: 'Ajouter un achat de produit',
      AddedSuccessfully: 'Ajouté avec succès',
      SavedSuccessfully: "L'achat a bien été enregistré.",
      UpdatedSuccessfully: "L'achat a bien été actualisé.",
      Validation: {
        QuantityRequired: 'La quantité est obligatoire',
        PricePerUnitRequired: 'Le prix unitaire est obligatoire',
        SupplierRequired: 'Le fournisseur est obligatoire',
        SupplierMaxLength:
          'Maximum 50 caractères autorisés pour le fournisseur.',
        RemarksMaxLength:
          'Maximum 150 caractères autorisés pour les remarques.',
      },
    },
    RemoveStock: {
      Date: 'Date',
      Quantity: 'Quantité',
      CatalogueId: 'Identifiant de catalogue',
      Remarks: 'Remarques',
      CreatedBy: 'Ajouté par',
      DeleteRemoveStock: 'Supprimer sortir le stock',
      DeleteMessage: 'Voulez-vous vraiment supprimer le stock ?',
      DeletedSuccessfully: 'Supprimé avec succès',
      ErrorOccurred: "Une erreur s'est produite",
      EditRemoveStock: 'Modifier sortir le stock',
      EditedSuccessfully: 'Modifié avec succès',
      AddRemoveStock: 'Ajouter sortir le stock',
      AddedSuccessfully: 'Ajouté avec succès',
      SavedSuccessfully: 'Sortir le stock a bien été enregistré.',
      UpdatedSuccessfully: 'Sortir le stock a bien été actualisé.',
    },
    ProductConsumptions: {
      Date: 'Date',
      Quantity: 'Quantité',
      OrderNumber: 'N° de réf. du bon',
      CustomerName: 'Client',
      SellingPrice: 'Prix de vente',
      Remarks: 'Remarques',
      CreatedBy: 'Ajouté par',
      DeleteProductConsumption: 'Supprimer la consommation de produit',
      DeleteMessage: 'Voulez-vous vraiment supprimer la consommation ?',
      DeletedSuccessfully: 'Supprimé avec succès',
      ErrorOccurred: "Une erreur s'est produite",
      EditProductConsumption: 'Modifier la consommation de produit',
      EditedSuccessfully: 'Modifié avec succès',
      AddProductConsumption: 'Ajouter une consommation de produit',
      AddedSuccessfully: 'Ajouté avec succès',
      SavedSuccessfully: 'La consommation a bien été enregistrée',
      UpdatedSuccessfully: 'La consommation a bien été actualisée',
      Validation: {
        QuantityRequired: 'La quantité est obligatoire',
        PricePerUnitRequired: 'Le prix unitaire est obligatoire',
        SupplierRequired: 'Le fournisseur est obligatoire',
        RemarksMaxLength:
          'Maximum 150 caractères autorisés pour les remarques.',
      },
    },
    CurrentCheckouts: {
      purchaseOrderId: 'Identifiant de bon',
      OrderNumber: 'Numéro de bon',
      Quantity: 'Quantité',
      RestoreMessage: 'Voulez-vous vraiment restaurer la validation ?',
      RestoreCurrentCheckout: 'Restaurer la validation actuelle',
    },
    Assignments: {
      AssignedDate: "Date d'attribution",
      AssignedBy: 'Attribué par',
      AssignedTo: 'Attribué à',
      AssignedByType: 'Attribué par le type',
      AssignedToType: 'Attribué au type',
      Warehouse: 'Entrepôt',
      Order: 'Bon',
      ShippingOrder: "Bordereau d'expédition",
      DecPackOrders: 'Bon DecPack',
      Add: 'Ajouter une attribution',
      Edit: "Modifier l'attribution",
      Remarks: 'Remarques',
      Quantity: 'Quantité',
      SavedSuccessfully: "L'attribution a bien été enregistrée",
      UpdatedSuccessfully: "L'attribution a bien été actualisée",
      BothTypeNotSame: `Attribué par et Attribué à ne peuvent être identiques`,
    },
    SingleUse: 'Usage unique',
    Reusables: 'Réutilisables',
    Details: 'Détails du catalogue de produits',
    AddProductCatalogue: 'Ajouter un catalogue de produits',
    ProductCode: 'Code de produit',
    ProductName: 'Nom de produit',
    Dimentions: 'Dimensions',
    ProductCatalogueType: 'Type de catalogue de produits',
    Type: 'Type',
    Category: 'Catégorie',
    Description: 'Description',
    ThresholdLimit: 'Seuil limite',
    Weight: 'Poids',
    PricingType: 'Type de tarif',
    AddSuccess: 'Le catalogue de produits a bien été ajouté.',
    UpdateSuccess: 'Le catalogue de produits a bien été actualisé.',
    DeleteSuccess: 'Le catalogue de produits a bien été supprimé.',
    Pricing: 'Tarif et inventaire',
    StandardPrice: 'Prix standard',
    LastCostBuyPrice: "Dernier coût / Prix d'achat",
    LastPurchasedFrom: 'Dernier achat de',
    InWarehouse: 'Disponible en entrepôt',
    AvailableinWH: 'Disponible en entrepôt',
    DeleteConfirmation:
      'Voulez-vous vraiment supprimer ce catalogue de produits ?',
    WarehouseQuantity: 'Quantité entrepôt',
    InvalidQuantity: "La quantité saisie n'est pas valide.",
    ResourceQuantity: 'Quantité de ressources',
    UniqueProductCode: 'Le code de produit doit être unique.',
    UniqueProductName: 'Le nom de produit doit être unique.',
    DeleteValidation:
      "Le catalogue de produits est attribué à des bons, veuillez l'attribuer à nouveau à l'entrepôt avant de le supprimer",
    ShowInDPBOM: 'Afficher dans DnP BOM',
    ShippingStandardPrice: 'Prix standard expédition',
    DecPackStandardPrice: 'Prix standard DecPack',
    Department: 'Département',
  },
  Partner: {
    SearchAddress: 'Rechercher une adresse',
    Type: 'Type de partenaire',
    Tracking: 'Suivi',
    Name: 'Nom',
    VatNumber: 'Numéro de TVA',
    Telephone: 'Téléphone',
    Category: 'Catégorie',
    Email: 'E-mail',
    Country_City: 'Pays-Ville',
    Location_Branches: 'Lieu/Succursales',
    Status: 'État',
    DeletedSuccessfully: 'Supprimé avec succès',
    DeleteMessage: 'Voulez-vous vraiment supprimer le partenaire ?',
    DeletePartner: 'Supprimer le partenaire',
    ErrorOccurred: "Une erreur s'est produite",
    ResendForApproval: 'Renvoyé avec succès pour approbation',
    SearchbyName: 'Rechercher par nom',
    AddPartner: 'Ajouter un partenaire',
    SupplierName: 'Nom du fournisseur',
    EORI: 'EORI',
    Address: 'Adresse',
    PCCity: 'CP, Ville',
    Country: 'Pays',
    Tag: 'Balise',
    Approved: 'Approuvé',
    Logo: 'Logo',
    Details: 'Détails',
    FinancialBasic: 'Base financière',
    ContactDetails: 'Détails de contact',
    Invoicing: 'Facturation',
    PostalCode: 'Code postal',
    City: 'Ville',
    Website: 'Site Web',
    SameAsVat: 'Identique à la TVA',
    IsDebtor: 'Est le débiteur',
    GeneralTelephone: 'Téléphone général',
    GeneralEmail: 'E-mail général',
    AdminEmail: 'E-mail administrateur du portail',
    InvoiceAddressDifferent: 'Adresse de facturation - Est différente ?',
    InvoiceEmail: 'E-mail facture',
    Shipping: 'Expédition',
    DecPack: 'DecPack',
    IsDifferent: 'Est différent',
    InvoicePostalCode: 'Code postal facture',
    InvoiceCity: 'Ville facture',
    InvoiceCountry: 'Pays facture',
    InvoiceAddress: 'Adresse facture',
    PartnerDetails: 'Détails du partenaire',
    DHL: 'DHL',
    FedEx: 'FedEx',
    Others: 'Autres',
    UpdatedSuccessfully: 'Le partenaire a bien été actualisé',
    TransportTypes: 'Types de transport',
    Tabs: {
      Category: 'Catégorie',
      Details: 'Détails',
      ContactPerson: 'Contact',
      Location: "Carnet d'adresses",
      Documents: 'Documents',
      Notes: 'Notes',
      TransportTypes: 'Types de transport',
      AllowedOrderCategory: 'Catégorie de bons autorisée',
    },
    Validation: {
      PartnerNameRequired: 'Le nom du partenaire est obligatoire.',
      PartnerNameMaxLength:
        'Maximum 50 caractères autorisés pour le nom du partenaire.',
      PartnerNameMinLength:
        'Minimum 3 caractères obligatoires pour le nom du partenaire.',
      AddressMaxLength: "Maximum 250 caractères autorisés pour l'adresse.",
      PostalCodeMaxLength:
        'Maximum 20 caractères autorisés pour le code postal.',
      InvoiceAddressMaxLength:
        "Maximum 250 caractères autorisés pour l'adresse de facture.",
      InvoicePostalCodeMaxLength:
        'Maximum 20 caractères autorisés pour le code postal de facture.',
      WebsiteMaxLenght: 'Maximum 255 caractères autorisés pour le site Web.',
      VatNumberMaxLength:
        'Maximum 30 caractères autorisés pour le numéro de TVA.',
      EORIMaxLength: 'Maximum 30 caractères autorisés pour EORI.',
      TelephoneMaxLength: 'Maximum 20 caractères autorisés pour le téléphone.',
      StatusRequired: "L'état est obligatoire.",
      EmailMaxLength: "Maximum 255 caractères autorisés pour l'e-mail.",
      InvalidEmail: 'Adresse e-mail non valide saisie',
      InvoiceEmailMaxLength:
        "Maximum 255 caractères autorisés pour l'e-mail de facture.",
      EmptyCatagory: "Impossible d'enregistrer une catégorie vide",
      UniqueName: 'Le nom de fournisseur doit être unique.',
    },
    CategoryTab: {
      Air: 'Air',
      Road: 'Route',
      Train: 'Train',
      Ship: 'Bateau',
      Other: 'Autre',
    },
  },
  ShippingOrders: {
    CopyLevel: 'Niveau de copie',
    Standard: 'Standard',
    Extensive: 'Extensive',
    Add: "Ajouter un bordereau d'expédition",
    AddOffer: "Ajouter un devis d'expédition",
    Edit: "Modifier le bordereau d'expédition",
    Details: "Détails du bordereau d'expédition",
    OfferDetails: "Détails du devis d'expédition",
    HazGoReference: 'Référence Hazgo',
    OfferReference: 'Référence du devis',
    SubCategory: 'Sous-catégorie',
    OfferExpired: 'Devis expiré',
    RejectionCode: 'Code de rejet',
    Accepted: 'Accepté',
    Rejected: 'Rejeté',
    Open: 'Ouvrir',
    OfferSent: 'Offre envoyée',
    RejectionReason: 'Motif de rejet',
    Expired: 'Expiré',
    NotExpired: 'Pas expiré',
    ReferenceNumber: 'Numéro de référence',
    ConvertOrder: 'Convertir en bon',
    Totals: 'Totaux',
    InProgress: 'EnCours',
    TotalNumOfPieces: 'Nombre total de pièces',
    ShipmentContent: "Contenu de l'expédition",
    WorkOrders: 'Bons de travaux',
    CustomsDetails: 'Détails des douanes',
    TermsOfDeliveryAndPayment: 'Conditions de livraison et de paiement',
    Importer: 'Importateur',
    Exporter: 'Exportateur',
    AddTermsOfDeliveryAndPayment:
      'Ajouter des conditions de livraison et de paiement',
    AddImporter: 'Ajouter un importateur',
    AddExporter: 'Ajouter un exportateur',
    ViewImporter: "Afficher l'importateur",
    ViewExporter: "Afficher l'exportateur",
    NameMaxLength: 'Maximum 30 caractères autorisés pour Nom.',
    OrderCreatedFrom: 'Le bon a été créé à partir du portail client',
    InternalTrackingNumber: 'Numéro de suivi interne',
    PriceRange: 'Fourchette de prix',
    ConvertToOrderConfirmation:
      'Voulez-vous vraiment convertir ce devis en bon ?',
    OfferCategoryValidation:
      "Veuillez mettre à jour la catégorie d'offre pour ce client",
    Tabs: {
      Details: 'Détails',
      Packages: 'Colis',
      WorkOrder: 'Bon de travaux',
      BillableItems: 'Articles facturables',
      Tracking: 'Suivi',
      Documents: 'Documents',
      Notes: 'Notes',
      Customs: 'Douanes',
      NotificationDetails: 'Détails de notification',
      Approval: 'Approbation',
      Labels: 'Étiquettes',
    },
    Steps: {
      BasicDetails: 'Détails de base',
      PackageDetails: 'Détails du colis',
      Materials: 'Matériaux',
      Documents: 'Documents',
      Notes: 'Notes',
    },
    ShippingPackage: {
      BoxNo: 'N° de boîte',
      BoxType: 'Type de boîte',
      Dimension: 'Dimensions (cm)',
      Height: 'Hauteur (cm)',
      Width: 'Largeur (cm)',
      Depth: 'Profondeur (cm)',
      Items: 'Articles',
      Weight: 'Poids (kg)',
      GeneralCargo: 'Marchandises générales',
      DangerousGoods: 'Marchandises dangereuses',
      None: 'Aucun',
      TemperatureControlled: 'Température contrôlée',
      DeleteConfirmation:
        'Voulez-vous vraiment supprimer les détails de ce colis ?',
      AddShippingPackage: 'AJOUTER BOÎTE/COLIS',
      EditShippingPackage: 'Modifier BOÎTE/COLIS',
      Box: 'BOÎTE (Produit)',
      CommodityCode: 'Code de marchandise',
      HSCODE: 'Code HS *',
      Description: 'Description *',
      Quantity: 'Quantité *',
      ItemVaue: "Valeur d'article *",
      ValueCurrency: 'Valeur devise *',
      NetWeight: 'Poids net *',
      GrossWeight: 'Poids brut *',
      CountryOfOrigin: "Pays d'(origine *",
      Checks: 'Contrôles',
      NumberOfPackages: 'Nombre de colis',
      UNNumber: 'Numéro UN',
      PrimaryClass: 'Classe primaire',
      SecondaryClass: 'Classe secondaire',
      CAO: 'CAO',
      KgOfDryIce: 'Kg de DryIce',
      DataLogger: 'Enregistreur de données',
      DataLoggerNumber: "Numéro d'enregistreur de données",
      Commodity: 'Ajouter une marchandise',
      ItemDescription: 'DescriptionArticle',
      ItemValue: "Valeur d'article",
      Unit: 'Unité *',
      YourOwnPackage: 'Votre propre colis',
      PackagingRequired: 'Emballage obligatoire ?',
      Instruction: 'Instruction',
      ProductRequired: 'Au moins 1 produit est obligatoire',
      ErrorOccurred: "Une erreur s'est produite",
      ProductQuantityNotAvailable:
        'Produit ou glace carbonique colis pas disponible',
      Temperature: 'Température',
      InnerBox: 'Boîte intérieure',
      Product: 'Produit',
      Remarks: 'Remarques',
      DeleteInnerBox: 'Supprimer la boîte intérieure',
      DeleteInnerBoxDescription:
        'Voulez-vous vraiment supprimer la boîte intérieure ?',
      OuterBox: 'Boîte extérieure',
      InnerBoxCount: 'N° de boîte intérieure',
    },
    WorkOrder: {
      WONo: 'N° bon de travaux',
      Type: 'Type',
      WODate: 'Date WO',
      ActivityOwner: "Propriétaire de l'activité",
      LastUpdateOn: 'Dernière actualisation le',
      Status: 'État',
      BackOffice: 'BackOffice',
      Driver: 'Chauffeur',
      MobileUser: 'Mobile User-FR',
      Partner: 'Partenaire',
      WareHouse: 'Entrepôt',
      WarehouseStaff: "Personnel d'entrepôt",
      DecNPack: 'Dec & Pack',
      AssignedTo: 'Attribué à',
      DGDCreation: 'WO2 - Création DGD',
      WorkorderOutbound: 'WO - Sortant',
      OutboundDetails: 'Détails sortant',
      WorkorderDocCheck: 'WO - Contrôle de document',
      WorkorderPhysicalCheck: 'WO - Contrôle physique',
      Checks: 'Contrôles',
      SelectAnyOption: 'Sélectionnez une option',
      SelectMultipleOption: 'Sélectionnez une option/sélection multiple)',
      NoQuestionnaireMapMsg:
        'Aucun questionnaire mis en correspondance avec ce bon de travaux',
      AnswersSubmittedSuccessMsg:
        'Les réponses au questionnaire ont bien été envoyées',
      NotSubmittedErrMsg: 'Aucune réponse prévue du questionnaire',
      ExecutionNotRequired: 'Exécution pas obligatoire',
      PhysicalCheckAddSuccessMsg:
        'Le contrôle physique du bon de travaux a bien été ajouté',
      PhysicalCheckUpdateSuccessMsg:
        'Le contrôle physique du bon de travaux a bien été actualisé',
      DocumentCheckAddSuccessMsg:
        'Le contrôle de document du bon de travaux a bien été ajouté',
      DocumentCheckUpdateSuccessMsg:
        'Le contrôle de document du bon de travaux a bien été actualisé',
      ActualDateTime: 'Date et heure réelles',
      ExpectedDateTime: 'Date et heure prévues',
    },
    OrderDetails: 'Détails du bon',
    Location: 'Lieu',
    Contact: 'Contact',
    Cancel: 'Annuler le bon',
    OrderReference: 'Référence client',
    Service: 'Service',
    OrderCategory: 'Catégorie de bon',
    OfferCategory: 'Catégorie de devis',
    Validity: 'Validité',
    PickupAddress: "Adresse d'enlèvement",
    DeliveryAddress: 'Adresse de livraison',
    IsSameAsCustomer: 'Est identique au client',
    PickupFrom: 'Enlèvement à partir de',
    DeliveryTo: 'Livraison à',
    ExpectedPickupDateTime: "Date et heure d'enlèvement prévues",
    ExpectedDeliveryDateTime: 'Date et heure de livraison prévues',
    AddSuccess: "Le bordereau d'expédition a bien été créé.",
    InOrder: 'Bon soumis',
    InOffer: 'Devis soumis',
    Type: 'Type',
    OfferStatus: 'État du devis',
    DeleteMessage: "Voulez-vous vraiment supprimer le bordereau d'expédition ?",
    DeleteOfferMessage:
      "Voulez-vous vraiment supprimer le devis d'expédition ?",
    DeleteTitle: "Supprimer le bordereau d'expédition",
    DeleteOfferTitle: "Supprimer le devis d'expédition",
    UpdateSuccess: "Le bordereau d'expédition a bien été actualisé.",
    AddSuccessOffer: "Le devis d'expédition a bien été créé.",
    UpdateSuccessOffer: "Le devis d'expédition a bien été actualisé.",
    UpdateOfferSuccess: "Le devis d'expédition a bien été actualisé.",
    DeleteSuccess: "Le bordereau d'expédition a bien été supprimé.",
    DeleteConfirmation:
      "Voulez-vous vraiment supprimer ce bordereau d'expédition ?",
    CancelConfirmation:
      "Voulez-vous vraiment annuler ce bordereau d'expédition ?",
    CancelSuccess: "Le bordereau d'expédition a bien été annulé",
    RejectOfferConfirmation:
      "Voulez-vous vraiment rejeter ce devis d'expédition ?",
    RejectOfferSuccess: "Le devis d'expédition a bien été rejeté.",
    OrderDate: 'Date du bon',
    OfferDate: 'Date de devis',
    Customer: 'Client',
    Category: 'Catégorie',
    Attention: 'Attention-FR',
    HazgoReference: 'Référence Hazgo',
    WoDoneVsTotal: 'WO Terminé vs Total',
    Partner: 'Partenaire',
    PartnerTrackingNo: 'N° de suivi partenaire',
    FromTo: 'À partir de - Vers',
    Status: 'État',
    TotalWeight: 'Poids total',
    TotalNoOfPackages: 'Nombre total de colis',
    ErrorOccurred: "Une erreur s'est produite",
    Information: 'Informations',
    Document: 'Document',
    DGPlus: 'DG Plus',
    EuDatabase: 'Base de données UE',
    CreateDGD: 'Créer DGD',
    AllDataAvailable: 'Toutes les données disponibles pour compléter DGD ?',
    DGDTitle: 'WO-Création DGD',
    ToolTip: {
      DownloadSampleFile: "Télécharger l'exemple de fichier",
    },
    PickupDatetimeIsGreaterErr:
      "La date et l'heure de livraison prévues doivent être postérieures à la date et à l'heure d'enlèvement",
    DestinationCompany: 'Entreprise de destination',
    PickupCompany: "Entreprise d'enlèvement",
    OrderCopySuccessful: "Le bordereau d'expédition a bien été copié.",
    CopyConfirmation:
      'Action exécutée une fois, ne peut être annulée. Continuer ?',
  },
  Booking: {
    BookingTitle: 'WO1 - Réservation',
    Information: 'Informations',
    Partner: 'Partenaire',
    Service: 'Service',
    TrackingNumber: 'Numéro de suivi',
    TotalCost: 'Coût total',
    TotalPrice: 'Prix total',
    SupplierCost: 'Coût fournisseur',
    Cost: 'Coût',
    Save: 'Enregistrer',
    Cancel: 'Annuler',
    Calculate: 'Calculer',
    EditBooking: 'Modifier la réservation',
    AddBooking: 'Ajouter une réservation',
    Status: 'État',
    StatusSavedSuccess: "L'état a bien été enregistré",
    DeleteBooking: 'Supprimer le bon de réservation',
    DeleteConfirmation:
      'Voulez-vous vraiment supprimer ce bon de réservation ?',
    DeleteSuccess: 'Le bon de travaux de réservation a bien été supprimé.',
    UpdateSuccess: 'Le bon de travaux de réservation a bien été actualisé',
    AddSuccess: 'Le bon de travaux de réservation a bien été ajouté',
    Notes: 'Notes',
    TransportType: 'Type de transport',
    Airline: 'Compagnie aérienne',
    DestinationCountry: 'Pays de destination',
    TransitCountry: 'Pays de transit',
    AirlineRestrictions: 'Restrictions compagnie aérienne',
    CountryRestrictions: 'Restrictions pays',
    Restrictions: 'Restrictions',
  },

  Delivery: {
    Instructions: 'Instructions',
    AssignedTo: 'Attribué à',
    Title: 'WO-Livraison',
    Remarks: 'Remarques',
    Document: 'Document',
    DeliveryDetails: 'Détails de livraison',
    DeliveryDone: 'Livraison terminée',
    ActualDeliveryDateTime: 'Date Heure réelles de livraison',
    FieldRequired: 'Ce champ est obligatoire.',
    AddSuccess: 'Livraison terminée avec succès.',
    SomethingWentWrong: "Un problème s'est produit.",
    UpdateSuccess: 'La livraison a bien été actualisée.',
    POD: 'POD',
    SendPOD: 'Envoyer un bon de livraison au client',
  },
  LedgerInfo: {
    Title: 'Informations Ledger',
    LedgerAccount: 'Compte Ledger',
    LedgerAccountDescription: 'Description de compte Ledger',
  },
  ItemGroupInfo: {
    Title: "Informations sur le groupe d'articles",
    ItemGroup: "Groupe d'articles",
  },
  Packaging: {
    ErrorWhileUpdatingStatus:
      "Un problème s'est produit en actualisant l'état.",
    SomethingWentWrong: "Un problème s'est produit.",
    AddSuccess: "L'emballage a bien été ajouté",
    UpdateSuccess: "L'emballage a bien été actualisé",
    FieldRequired: 'Le champ est obligatoire',
    ActualPackagingDateTime: 'DateHeure réelles emballage',
    Remarks: 'Remarques',
    Title: 'WO-Emballage',
    AssignedTo: 'Attribué à',
    AssignmentType: "Type d'attribution",
    Instructions: 'Instructions',
    PackagingDetails: "Détails d'emballage",
    PackagingDone: 'Emballage terminé',
    Picture: 'Photo',
  },

  ShippingMaterials: {
    Add: 'Ajouter un matériau',
    Edit: 'Modifier le matériau',
    Type: 'Type',
    Category: 'Catégorie',
    ItemCode: "Code d'article",
    Name: 'Nom',
    Quantity: 'Quantité',
    Note: 'Note',
    SellingPrice: 'Prix de vente',
    Delete: 'Supprimer',
    ProductCatalogue: 'Produit',
    ServiceCatalogue: 'Service',
    TransportCatalogue: 'Transport',
    DeleteMessage: 'Voulez-vous vraiment supprimer la saisie de matériau ?',
    DeleteTitle: 'Supprimer le matériau',
    BoxDetails: 'Détails boîte',
    IsFromPackage: 'EstDuColis',
    NotProcessed: 'Veuillez contrôler le fichier téléchargé',
    ProcessedSuccessfully: 'Le fichier a bien été traité',
    ProductQuantityNotAvailable: 'Quantité de produit pas disponible',
    IncorrectTransportDetail: 'Les détails du transport sont obligatoires',
    ZeroNotAllowed: 'Zéro pas autorisé',
    CostPrice: "Prix d'achat",
    SellingPriceLessThanCostPrice:
      "Le prix de vente est intérieur au prix d'achat",
    SendProformaInvoice: 'Envoyer une facture pro forma',
    EmailNotVailable: "L'e-mail n'est pas disponible",
    QuantityDetails: 'Détails quantité',
    WareHouseQuantity: 'Quantité entrepôt',
    RequestedQuantity: 'Quantité demandée',
    Delta: 'Delta (actuel - demandé)',
    Threshold: 'Quantité seuil',
    EstimatedCost: 'Coût estimé',
    ActualCost: 'Coût réel',
  },
  Tracking: {
    Add: 'Ajouter un suivi',
    Edit: 'Modifier le suivi',
    Internal: 'Interne',
    External: 'Externe',
    StatusChangeDate: "Date de changement d'état",
    Status: 'État',
    Type: 'Type',
    Notes: 'Notes',
    AddSuccess: "Le bordereau d'expédition a bien été créé.",
    InOrder: 'Bon soumis',
    InOffer: 'Devis soumis',
    DeleteMessage: 'Voulez-vous vraiment supprimer le suivi ?',
    DeleteTitle: 'Supprimer le suivi',
    UpdateSuccess: 'La suivi a bien été actualisé.',
    DeleteSuccess: 'La suivi a bien été supprimé.',
    DeleteConfirmation: 'Voulez-vous vraiment supprimer ce suivi ?',
  },
  DecPackOrders: {
    DeleteMessage: 'Voulez-vous vraiment supprimer le bon Dec&Pack ?',
    DeleteTitle: 'Supprimer le bon Dec&Pack',
    Details: 'Détails',
    DeckPack: 'Dec & Pack',
    RejectOfferConfirmation: 'Voulez-vous vraiment rejeter ce devis DecPack ?',
    OrderOwner: 'Propriétaire du bon',
    SendForApproval: 'Envoyé pour approbation',
    SendForApprovalConfirmation:
      'Voulez-vous vraiment envoyer le bon pour approbation ?',
    SendForApprovalConfirmationDetails: 'Envoyer le bon pour approbation',
    MobileRemarks: 'Remarques portable',
    Table: {
      OfferReference: 'Référence du devis',
      OrderDate: 'DateDevis',
      OfferDate: 'Date de devis',
      Customer: 'Client',
      ServiceType: 'Type de service',
      Reference: 'Référence',
      Location: 'Lieu',
      WoDoneVsTotal: 'Wo terminé vs Total',
      Type: 'Type',
      Pickup: 'Enlèvement',
      Contacts: 'Contacts',
      InProgressWO: 'En cours WO',
      FinalDestination: 'Destination finale',
      Status: 'État',
      Attention: 'Attention',
      HazGoReference: 'Référence HazGo',
      CustomerReference: 'Référence client',
      ExecutionDate: "Date d'exécution",
      Shipper: 'Expéditeur',
      AssignedTo: 'Attribué à',
      ExecutionDateTime: "Date/heure d'exécution",
      ExecutionTime: "Heure d'exécution",
    },
    Tabs: {
      Details: 'Détails',
      WorkOrder: 'Bon de travaux',
      BillableItems: 'Articles facturables',
      Documents: 'Documents',
      Notes: 'Notes',
      Approval: 'Approbation',
    },
    ToolTip: {
      DownloadSampleFile: "Télécharger l'exemple de fichier",
    },
    DGCheck: 'Contrôle DG',
    DGDCreation: 'Création DGD',
    NonDGOrder: 'Bon non DG',
    Shop: 'Boutique',
    DeleteSuccess: 'Supprimé avec succès',
    RejectOffer: 'Rejet devis',
    OfferRejectSuccess: 'Le devis a bien été rejeté',
    DeleteConfirmation: 'Voulez-vous vraiment supprimer ce bon DecPack ?',
    CancelConfirmation: 'Voulez-vous vraiment annuler ce bon DecPack ?',
    CancelSuccess: 'Le bon DecPack a bien été annulé',
    QuestionnaireAnswersSubmitted:
      'Les réponses au questionnaire ont bien été envoyées',
    QuestionnaireAnswersMandatory: 'Le questionnaire est obligatoire',
    CheckQuestionsMandatory: 'Les questions sont obligatoires',
    WorkOrder: {
      WONo: 'N° de WO',
      Type: 'Type',
      WODate: 'Date de WO',
      ActivityOwner: "Propriétaire de l'activité",
      LastUpdateOn: 'Dernière actualisation le',
      Status: 'État',
      BackOffice: 'BackOffice',
      Driver: 'Chauffeur',
      Partner: 'Partenaire',
      AssignedTo: 'Attribué à',
      DGDCreationTitle: 'WO 2 - Création DGD',
      SecurityCheckTitle: 'WO S - Contrôle de sécurité RA',
      CheckDocumentation: 'WO 4 - Contrôle de documentation',
      CustomerRefTitle: 'WO 11 - Référence client',
      Material: 'WO 5 - Matériau',
      PhotoCheck: 'WO - Contrôle de photo',
      TypeCheck: 'Contrôle de type',
      CheckQuestion: 'Question de contrôle',
      Details: 'Détails',
      SpecialInstruction: 'Instructions spéciales',
      Assign: 'Attribuer',
      UNNumberCheck: 'Contrôle de numéro UN',
      ExtractDGPlus: 'Extraire de DGPlus',
      Comment: 'Commentaire',
      ShowAirLine: 'Afficher compagnies aériennes',
      ShowCountry: 'Afficher pays',
      Instruction: 'Instructions',
      FourDigitPin: 'Saisissez UN à 4 chiffres',
      BillOfMaterial: 'WO 8 - Connaissement',
      ApprovalBillOfMaterial: 'WO 10 - Approbation connaissement',
      ApprovalInvoice: 'W0 12 - Approbation facture',
      Execution: 'Exécution',
      OrderExecution: 'WO 6 - Exécution de bon',
      AirLine: 'Compagnie aérienne',
      Country: 'Pays',
      ShipmentNotAvailableInWarehouse:
        "Expédition pas disponible dans l'entrepôt",
      Date: 'Date',
      Photos: 'Photos',
      requiredErrMsg: 'Ce champ est obligatoire',
      NoQuestionnaireMapMsg: 'Pas de questionnaire',
      detailsUpdateMsg: 'Les détails ont bien été actualisés',
      detailsAddMsg: 'Les détails ont bien été ajoutés',
      invalidDateErrMsg: 'Date non valide',
      WO6ltrDeliveryOrderTitle: 'Bon de travaux 6ter - Livraison du bon',
      CustomeName: 'Nom du client',
      DateTime: 'Date Heure',
      Delivery: 'Livraison',
      WOOutboundProcess: 'WO Processus sortant',
      OBO: 'WO OBO - Processus sortant',
      DGDDocument: 'Document DGD',
      Questionnaire: 'Questionnaire *',
      FileNames: {
        DGDDocPDF: 'Document DGD.pdf',
      },
      DGPlusData: 'Détails DGPlus',
      WO3Details: 'Détails WO3',
      ShowWO3Details: 'Afficher les détails WO3',
      InvoiceNotes: 'Notes de facture',
      ProformaNotes: 'Notes pro forma',
    },
    DGPlusDetails: {
      Name: 'Nom',
      UN: 'UN',
      Class: 'Classe',
      Subrisk: 'Sous-risque',
      Packinggroup: "Groupe d'emballages",
      Exceptedquantities: 'Quantités exceptées',
      Paxpackinginstruction: "Instructions d'emballage passager",
      Paxmaxquantity: 'Quantité max. passager',
      Caopackinginstruction: "Instructions d'emballage marchandises",
      Caomaxquantity: 'Quantité max. marchandises',
    },
    CheckDocumentation: {
      CreateLabels: 'Créer des étiquettes',
      LayoutType: 'Type de disposition',
      ShippingLabel: "Étiquette d'expédition",
      OverpackLabel: 'Étiquette de suremballage',
      Other: 'Autre',
      Dymo: 'DYMO',
      Zebra: 'ZEBRA',
      OtherLabelFreeText: 'Texte libre',
      OverPackId: 'Identifiant de suremballage',
      TotalQuantity: 'Quantité totale',
      Consignee: 'Destinataire',
      Shipper: 'Expéditeur',
      UnNumber: 'Numéro UN',
      TechnicalName: 'Nom technique',
      NetQuantity: 'Quantité',
      AddSuccess: "L'étiquette a bien été ajoutée",
      SomethingWentWrong: "Un problème s'est produit.",
      UpdateSuccess: "L'étiquette a bien été actualisée",
      MyHazGoRef: 'Réf. myHazgo',
      CustomerRef: 'Réf. client',
      NumberOfPieces: 'Nombre de pièces',
      PSN: 'PSN',
      SelectPSN: 'Sélectionner PSN',
    },
    Validation: {
      CustomerNameRequired: 'Le nom du client est obligatoire.',
      CommentRequired: 'Un commentaire est obligatoire',
      DateRequired: 'La date est obligatoire.',
    },
    WOMaterial: {
      MaterialNeeded: 'Matériau nécessaire',
      SpecialInstruction: 'Instructions spéciales',
      Assign: 'Attribuer',
      Instruction: 'Instruction',
      AssignTo: 'Attribué à',
      StrongMaterial: 'Matériau de suremballage solide',
      Openfield: 'Champ libre',
      SpecificTypeOfPackaging: "Type spécifique d'emballage",
      Packaging: 'Emballage',
      Quantity: 'Quantité',
      FieldRequired: 'Ce champ est obligatoire.',
      AddSuccess: 'Livraison terminée avec succès.',
      SomethingWentWrong: "Un problème s'est produit.",
      UpdateSuccess: 'La livraison a bien été actualisée.',
      ProductQuantityNotAvailable: 'Quantité de produit pas disponible',
      ProductAtAllLevels: 'Un produit doit être sélectionné à tous les niveaux',
    },
    PhotoCheck: {
      Photos: 'Photos',
      DGD: 'DGD',
      SpecialInstruction: 'Instructions spéciales',
      PhotoCheck: 'Contrôle de photo',
      ViewDocument: 'Cliquez ici pour afficher le document :',
      Instruction1: 'Instruction 1 :',
      Instruction2: 'Instruction 2 :',
    },
    WOSecurityCheckQA1:
      "L'expéditeur est-il enregistré dans la « base de données de l'Union relative à la sécurité de la chaîne d'approvisionnement » enregistrée en tant que RA ou KC ?",
    WOSecurityCheckQA2:
      'La personne chargée de la livraison est-elle un chauffeur formé du RA ou un transporteur sous-traitant qui a signé un accord de transport avec le RA ou KC ?',
    DecPackQuestions: {
      WOIBO: {
        SecureShipment: 'EXPÉDITION SÉCURISÉE *',
        SecurityGeneral: 'SÉCURITÉ - GÉNÉRALE *',
        Shipment: 'EXPÉDITION *',
        SecureShipmentQA1: `Transporteur :`,
        SecureShipmentQA2: `Plaque d'immatriculation :`,
        SecureShipmentQA3: `Nom du chauffeur :`,
        SecureShipmentQA4:
          'Le véhicule est-il scellé/verrouillé avant le déchargement ?',
        SecurityGeneralQA1: `Aucune trace d'altération avec l'expédition ?`,
        SecurityGeneralQA2: `Aucune bague d'inviolabilité d'expédition emballée ?`,
        ShipmentQA1: `Nombre de pièces :`,
        ShipmentQA2: `Pas endommagé ?`,
        ShipmentQA3: `Expédition acceptée`,
        Title: 'Wo - IBO',
        SaveSuccess: 'Les réponses Wo - IBO ont bien été enregistrées.',
        SaveError: "Les réponses Wo - IBO n'ont pas été enregistrées.",
        CMRNote: 'Veuillez prendre des notes sur le CMR',
        AddDamagePictures: 'Ajouter une photo des dommages',
        TransportDocument: 'Documents de transport',
        NoShippingDocsAvailable: "Aucun document d'expédition disponible",
        TransportDocumentSaveSuccess:
          'Les documents de transport ont bien été enregistrés.',
        TransportDocumentSaveError:
          "Les documents de transport n'ont pas été enregistrés.",
        CreateWarehouseLabel: "CRÉER ÉTIQUETTE D'ENTREPÔT",
        WarehouseLabel: "Étiquette d'entrepôt",
        DetailsRequired: 'Détails de sécurité et Expédition obligatoires',
        AllDetailsRequired:
          'Expédition sécurisée, Détails de sécurité et Expédition obligatoires',
      },
      WOCheckDocumentation: {
        WOCheckDocumentationQA1: 'Contrôle DGD ok ?',
        WOCheckDocumentationQA2: 'Contrôle AWB ok ?',
        WOCheckDocumentationQA3: 'Restrictions compagnie aérienne ok ?',
        WOCheckDocumentationQA4: 'Restrictions état ok ?',
      },
      WOExecution: {
        WOExecutionQA1: 'Contrôle DGD ok ?',
        WOExecutionQA2: 'Contrôle AWB ok ?',
        WOExecutionQA3: 'Restrictions compagnie aérienne ok ?',
        WOExecutionQA4: 'Restrictions état ok ?',
        WOExecutionQA5: 'Expédition prête pour le transport ?',
        NoDocsAvailable: 'Aucun document disponible',
      },
    },
    CustomerName: 'Nom du client',
    DateTime: 'Date heure',
  },
  WorkOrder: {
    Title: 'WO-Entrant',
    AssignmentTo: 'Attribué à',
    AssignmentType: "Type d'attribution",
    InboundDetails: 'Détails entrant',
    Instructions: 'Instructions',
    Remarks: 'Remarques',
    InboundDone: 'Entrant terminé',
    ActualInboundDateTime: 'DateHeure réelles entrant',
    Driver: 'Chauffeur',
    Partner: 'Partenaire',
    FieldRequired: 'Le champ est obligatoire',
    AddSuccess: 'Le bon de travaux entrant a bien été ajouté.',
    UpdateSuccess: 'Le bon de travaux a bien été actualisé.',
    DGDCreation: 'Création DGD',
    SecurityCheckRA: 'Contrôle de sécurité RA',
    Inboundprocess: 'Processus entrant',
    Collectingshipmentdata: "Collecte données d'expédition",
    CheckDocumentation: 'Contrôler la documentation',
    Material: 'Matériau',
    Executionorder: 'Exécution bon',
    Deliveryorderassigning: 'Attribution bon de livraison',
    Deliveryorder: 'Bon de livraison',
    Irregularitycontactclient: 'Irrégularité Contact Client',
    BillofMaterial: 'Connaissement',
    PhotoCheck: 'Contrôle de photo',
    Outboundprocess: 'Processus sortant',
    ApprovalBillofMaterial: 'Approbation connaissement',
    InputmissingCustomerreference: 'Saisie manquante référence client',
    ApprovalInvoice: 'Approbation facture',
    ApproveInvoice: 'Approuver la facture',
    InfoRemindCustomerRef:
      'Envoyer un rappel pour actualiser le numéro de référence client',
    UpdatedSuccessfully: 'Actualisé avec succès',
    ErrorOccurred: "Une erreur s'est produite",
    SendProformaInvoice: 'Envoyer une facture pro forma',
    SendAnyway: 'Envoyer quand même',
    ApproveAnyway: 'Approuver quand même',
    SendSuccessfully: 'La facture pro forma a bien été envoyée',
    InvoiceApprovedSuccess: 'La facture a bien été approuvée',
    SendEmailSuccessfully: "L'e-mail a bien été envoyé",
    Notes: 'Notes',
    OrderReference: 'Référence de bon',
  },
  WorkorderPickup: {
    WorkorderPickup: 'Bon de travaux enlèvement',
    Assigned: 'Attribué',
    PickupDetails: "Détails de l'enlèvement",
    Status: 'État',
    AssignmentType: "Type d'attribution",
    Instructions: 'Instructions',
    AssignedTo: 'Attribué à',
    requiredErrMsg: 'Ce champ est obligatoire',
    Driver: 'Chauffeur',
    Partner: 'Partenaire',
    PickupDone: 'Enlèvement terminé',
    ActualPickDateTime: 'Date Heure réelles enlèvement',
    Remarks: 'Remarques',
    WaitTime: "Temps d'attente (s)",
    Packaging: 'Emballage',
    PackagingDone: "L'emballage doit être terminé sur le site du client",
    AddSuccess: "Le bon de travaux d'enlèvement a bien été ajouté.",
    UpdateSuccess: "Le bon de travaux d'enlèvement a bien été actualisé.",
    AssignedNextTo: 'Attribué WO To suivant',
  },
  WorkorderOutbound: {
    WorkorderOutbound: 'Bon de travaux sortant',
    AssignedSection: 'Attribué à',
    OutboundSection: 'Détails sortant',
    AssignedTo: 'Attribué à',
    Instructions: 'Instructions',
    Remarks: 'Remarques',
    ActualOutboundDateTime: 'Date Heure réelles sortant',
    requiredErrMsg: 'Ce champ est obligatoire',
    detailsUpdateMsg: 'Les détails ont bien été actualisés',
    OutboundDone: 'Sortant terminé',
  },
  DecPack: {
    AddDecPack: 'Ajouter Dec & Pack',
    OfferBasics: 'Bases devis',
    OrderBasics: 'Bases bon',
    CustomerDetails: 'Détails du client',
    GeneralDetails: 'Détails généraux',
    HazgoReference: 'Référence Hazgo',
    TypeOfService: 'Type de service',
    DGCheck: 'Contrôle DG',
    DGDCreation: 'Création DGD',
    NonDGOrder: 'Bon non DG',
    Shop: 'Boutique',
    ExecutionDate: "Date d'exécution",
    StartExecutionTime: "Heure de début d'exécution",
    EndExecutionTime: "Heure de fin d'exécution",
    SelectExecutionTime:
      "Sélectionnez l'heure d'exécution - Heure AM/PM/Spécifique",
    Am: 'AM',
    Pm: 'PM',
    SpecificTime: 'Heure spécifique',
    None: 'Aucun',
    HighPriority: 'Priorité élevée',
    Customer: 'Client',
    Contact: 'Contact',
    CustomerReference: 'Référence client',
    Shipper: 'Expéditeur',
    Location: 'Lieu',
    WareHouse: 'Entrepôt',
    GroundHandler: 'Entreprise de services au sol',
    DetailsWarehouse: 'Détails du lieu ',
    InboundDetails: 'Détails entrants',
    OutboundDetails: 'Détails sortants',
    Name: 'Nom',
    SecuredShipment: 'Expédition sécurisée ?',
    DriverName: 'Détails du transporteur - Nom du chauffeur',
    LicensePlate: "Plaque d'immatriculation",
    Transporter: 'Transporteur',
    NumberofPieces: 'Nombre de pièces',
    Pickupaddress: "Adresse d'enlèvement",
    ExpectedInboundDate: 'Date entrant prévue',
    DeliveryLocation: 'Livraison - Lieu',
    Airline: 'Compagnie aérienne',
    FinalDestination: 'Destination finale',
    TransitDestination: 'Destination de transit',
    SpecialInstruction: 'Instructions spéciales',
    EstimatedDuration: 'Durée estimée (min)',
    Interventie: 'Intervention',
    Status: 'État',
    WoDone: 'WO Terminé vs Total',
    Consignee: 'Destinataire',
    CompanyName: 'Raison sociale',
    KcRaAgent: 'Agent KC/AR',
    PickUpBy: 'Récupérer par',
    DeliveryReference: 'Référence de livraison',
  },
  WOCollectionData: {
    Title: "WO 3 - Collecte données d'expédition",
    Document: 'Document',
    ShipmentData: "Données d'expédition",
    Photos: 'Photos',
    Shipment: 'Expédition',
    MoveToWarehouse: "Demande de déplacer l'expédition dans l'entrepôt.",
    FieldRequired: 'Ce champ est obligatoire.',
    AddSuccess: 'Livraison terminée avec succès.',
    SomethingWentWrong: "Un problème s'est produit.",
    UpdateSuccess: 'La livraison a bien été actualisée.',
    ShipmentDataUpdateSuccess:
      "Les données d'expédition ont bien été actualisées.",
    ShipmentDataUpdateFail:
      "L'image d'expédition n'a pas été enregistrée. Veuillez réessayer.",
  },
  DecPackWO6Bis: {
    Title: 'Bon de travaux 6bis - Livraison du bon (Attribution)',
    Delivery: 'Livraison',
    AssignedTo: 'Attribué à',
    Location: 'Lieu',
    SpecialInstruction: 'Instructions spéciales',
    FieldRequired: 'Ce champ est obligatoire.',
    AddSuccess: 'Livraison terminée avec succès.',
    SomethingWentWrong: "Un problème s'est produit.",
    UpdateSuccess: 'La livraison a bien été actualisée.',
  },
  SendEmail: {
    SendEmailTitle: 'Envoyer un e-mail',
    Send: 'Envoyer',
    Cancel: 'Annuler',
    Email: 'E-mail',
    Language: 'Langue',
    SendSuccessfully: 'E-mail envoyé avec succès',
    ErrorOccurred: "Une erreur s'est produite. Veuillez réessayer.",
  },
  Cities: {
    AddDialogTitle: 'Ajouter une ville',
    City: 'Ville',
    Validation: {
      CityRequired: 'La ville est obligatoire.',
    },
    AddedSuccessfully: 'La ville a bien été ajoutée.',
    AlreadyExist: 'La ville existe déjà.',
    ErrorOccurred: "Une erreur s'est produite. Veuillez réessayer.",
  },
  IrregularityContactClient: {
    Title: 'Irrégularité - Contact Client',
    Wo4Comment: 'Commentaires WO4',
    ContactCustomerViaEmail: 'Contact client par e-mail',
    ContactCustomerViaEmailSuccess:
      "L'état du contact client par e-mail a bien été enregistré.",
    IboNoQuestions: `Questions de WOIBO `,
    Wo3Caption: "Demande de déplacer l'expédition dans l'entrepôt",
    Wo6Caption: `Questions de WO6 `,
    Required: 'Veuillez sélectionner le contact client par e-mail',
    WosCaption: `Questions de WOS `,
    Wo6CaptionShipmentNA: `Expédition pas disponible dans l'entrepôt `,
  },
  State: {
    Name: 'Nom',
    DeletedSuccessfully: 'Supprimé avec succès',
    DeleteMessage: "Voulez-vous vraiment supprimer l'état ?",
    DeleteState: "Supprimer l'état",
    EditState: "Modifier l'état",
    AddState: 'Ajouter un état',
    Restriction: 'Restriction',
    ApplicableTo: 'Applicable à',
    All: 'Tous',
    WO4: 'WO4',
    WO6: 'WO6',
    ShippingWOBooking: 'Expédition WO-Réservation',
    ProperDataValidate: 'Veuillez saisir des données correctes',
    FieldRequired: 'Le champ est obligatoire',
    Country: 'Pays',
    StateRestrictions: 'Restrictions état',
  },
  Airline: {
    Name: 'Nom',
    DeletedSuccessfully: 'Supprimé avec succès',
    DeleteMessage: 'Voulez-vous vraiment supprimer la compagnie aérienne ?',
    DeleteAirline: 'Supprimer la compagnie aérienne',
    EditAirline: 'Modifier la compagnie aérienne',
    AddAirline: 'Ajouter une compagnie aérienne',
    Restriction: 'Restriction',
    ApplicableTo: 'Applicable à',
    All: 'Tous',
    WO4: 'WO4',
    WO6: 'WO6',
    ShippingWOBooking: 'Expédition WO-Réservation',
    ProperDataValidate: 'Veuillez saisir des données correctes',
    FieldRequired: 'Le champ est obligatoire',
    AirlineRestrictions: 'Restrictions compagnie aérienne',
  },
};

export default frLabels;
