import {
  BaseModel,
  PaginationResponseModel,
  EntityTypeEnum,
  EntityStatus,
  ApprovalStatus,
} from 'models/pagination-model';
import environment from 'environment';
import { Customer } from 'models/service-catalogue';
import http from '../../utilities/httpService';
import { Location as EntityLocation } from 'models/location';
import { ContactPersonModel } from 'models/contactPersonModel';
import { toast } from 'react-toastify';
import I18n from 'utilities/i18n';

const i18nMessages = {
  saveSuccessfully: I18n('Common.SavedSuccessfully'),
  errorOccured: I18n('Common.ErrorOccurred'),
};

const getCustomers = async (search: string, pageNo: number, id: number) => {
  let response: PaginationResponseModel<Customer>;
  const apiUrl = new URL(environment.api.baseUrl + 'Customer');
  try {
    let searchExpression;
    if (id !== undefined || '') {
      searchExpression =
        'TaggedTo~=~2 OR TaggedTo~=~3 and Status~=~' +
        EntityStatus.Active +
        ' and ApprovalStatus~=~' +
        ApprovalStatus.Approved +
        ` OR id~=~` +
        id;
    } else {
      searchExpression =
        'TaggedTo~=~2 OR TaggedTo~=~3 and Status~=~' +
        EntityStatus.Active +
        ' and ApprovalStatus~=~' +
        ApprovalStatus.Approved;
    }

    searchExpression += search ? ` and name~like~${search}` : '';
    apiUrl.searchParams.set('$filter', searchExpression);
    apiUrl.searchParams.set('$orderby', 'name');
    apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
    apiUrl.searchParams.set('$top', '10');
    apiUrl.searchParams.set('$orderbydirection', 'desc');
    const result = await http.get(apiUrl.toString());
    if (result) {
      response = {
        TotalRecords: result.data.totalCount,
        Values: result.data.values,
      } as PaginationResponseModel<Customer>;
    } else {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<Customer>;
    }
  } catch (error) {
    response = {
      TotalRecords: 0,
      Values: [],
    } as PaginationResponseModel<Customer>;
  }
  return response;
};

export async function loadCustomerOptions(
  search: string,
  loadedOptions: any,
  { page }: any,
  id?: any
) {
  const response = await getCustomers(search, page, id);
  const hasMore = Math.ceil(response.TotalRecords / 10) > page;

  return {
    options: response.Values.map((x) => {
      return {
        value: x.id.toString(),
        label: x.name,
      } as BaseModel;
    }),
    hasMore,
    additional: {
      page: page + 1,
    },
  };
}

const getCountries = async (search: string, pageNo: number) => {
  let response: PaginationResponseModel<Customer>;
  const apiUrl = new URL(environment.api.baseUrl + 'Country');
  try {
    let searchExpression = '';
    searchExpression += search ? `name~like~${search}` : '';
    apiUrl.searchParams.set('$filter', searchExpression);
    apiUrl.searchParams.set('$orderby', 'name');
    apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
    apiUrl.searchParams.set('$top', '10');
    apiUrl.searchParams.set('$orderbydirection', 'desc');
    const result = await http.get(apiUrl.toString());
    if (result) {
      response = {
        TotalRecords: result.data.totalCount,
        Values: result.data.values,
      } as PaginationResponseModel<Customer>;
    } else {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<Customer>;
    }
  } catch (error) {
    response = {
      TotalRecords: 0,
      Values: [],
    } as PaginationResponseModel<Customer>;
  }
  return response;
};

export async function loadCountryOptions(
  search: string,
  loadedOptions: any,
  { page }: any,
  loadInstruction: boolean = false
) {
  const response = await getCountries(search, page);
  const hasMore = Math.ceil(response.TotalRecords / 10) > page;

  return {
    options: response.Values.map((x: any) => {
      return {
        value: x.id.toString(),
        label: loadInstruction ? x.description : x.name,
      } as BaseModel;
    }),
    hasMore,
    additional: {
      page: page + 1,
    },
  };
}

const getLocations = async (
  customerId: number,
  search: string,
  pageNo: number
) => {
  let response: PaginationResponseModel<EntityLocation>;
  const apiUrl = new URL(environment.api.baseUrl + 'Locations');
  try {
    let searchExpression = '';
    searchExpression += search
      ? `Address~like~${search} or Address2~like~${search} or Street~like~${search} or ZipCode~like~${search} or City.name~like~${search} or Country.name~like~${search} and `
      : '';
    searchExpression += ` EntityType~=~${EntityTypeEnum.Customer} and EntityId~=~${customerId}`;

    apiUrl.searchParams.set('$filter', searchExpression);
    apiUrl.searchParams.set('$orderby', 'address');
    apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
    apiUrl.searchParams.set('$top', '10');
    apiUrl.searchParams.set('$orderbydirection', 'desc');
    const result = await http.get(apiUrl.toString());
    if (result) {
      response = {
        TotalRecords: result.data.totalCount,
        Values: result.data.values,
      } as PaginationResponseModel<EntityLocation>;
    } else {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<EntityLocation>;
    }
  } catch (error) {
    response = {
      TotalRecords: 0,
      Values: [],
    } as PaginationResponseModel<EntityLocation>;
  }
  return response;
};
export async function loadLocationOptions(
  customerId: number,
  search: string,
  loadedOptions: any,
  { page }: any
) {
  const response = await getLocations(customerId, search, page);
  const hasMore = Math.ceil(response.TotalRecords / 10) > page;
  return {
    options: response.Values.map((x) => {
      return {
        value: x.id.toString(),
        label: `${x.street ? `${x.street},` : ''}${
          x.address2 ? `${x.address2},` : ''
        }${x.zipCode ? `${x.zipCode},` : ''}${x.city},${x.country}`,
      } as BaseModel;
    }),
    hasMore,
    additional: {
      page: page + 1,
    },
  };
}

const getContacts = async (
  customerId: number,
  search: string,
  pageNo: number,
  id?: number
) => {
  let response: PaginationResponseModel<ContactPersonModel>;
  const apiUrl = new URL(environment.api.baseUrl + 'ContactPerson');
  try {
    let searchExpression;
    if (id !== undefined || '') {
      searchExpression =
        'EntityType~=~' +
        EntityTypeEnum.Customer +
        ' and EntityId~=~' +
        customerId +
        ' and Status~=~' +
        EntityStatus.Active +
        ` OR id~=~` +
        id;
    } else {
      searchExpression =
        'EntityType~=~' +
        EntityTypeEnum.Customer +
        ' and EntityId~=~' +
        customerId +
        ' and Status~=~' +
        EntityStatus.Active;
    }
    searchExpression += search
      ? ` and firstName~like~${search} OR lastName~like~${search}`
      : '';
    apiUrl.searchParams.set('$filter', searchExpression);
    apiUrl.searchParams.set('$orderby', 'firstName');
    apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
    apiUrl.searchParams.set('$top', '10');
    apiUrl.searchParams.set('$orderbydirection', 'desc');
    const result = await http.get(apiUrl.toString());
    if (result) {
      response = {
        TotalRecords: result.data.totalCount,
        Values: result.data.values,
      } as PaginationResponseModel<ContactPersonModel>;
    } else {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<ContactPersonModel>;
    }
  } catch (error) {
    response = {
      TotalRecords: 0,
      Values: [],
    } as PaginationResponseModel<ContactPersonModel>;
  }
  return response;
};

export async function loadContactOptions(
  customerId: number,
  search: string,
  loadedOptions: any,
  { page }: any,
  id?: number
) {
  const response = await getContacts(customerId, search, page, id);
  const hasMore = Math.ceil(response.TotalRecords / 10) > page;
  return {
    options: response.Values.map((x) => {
      return {
        value: x.id.toString(),
        label: `${x.firstName + ' ' + x.lastName}`,
      } as BaseModel;
    }),
    hasMore,
    additional: {
      page: page + 1,
    },
  };
}

export async function loadAirlineOptions(
  search: string,
  loadedOptions: any,
  { page }: any,
  loadInstruction: boolean = false,
  restrictionModule: number = 0
) {
  const response = await getAirline(search, page, restrictionModule);
  const hasMore = Math.ceil(response.TotalRecords / 10) > page;
  return {
    options: response.Values.map((x: any) => {
      return {
        value: x.id.toString(),
        label: loadInstruction ? `${x.description}` : `${x.name}`,
      } as BaseModel;
    }),
    hasMore,
    additional: {
      page: page + 1,
    },
  };
}

const getAirline = async (
  search: string,
  pageNo: number,
  restrictionModule: number = 0
) => {
  let response: any;
  const apiUrl = new URL(environment.api.baseUrl + 'AirLine');
  try {
    let searchExpression = search ? `name~like~${search}` : '';
    if (restrictionModule > 0) {
      if (searchExpression === '') {
        searchExpression = 'RestrictionModule~!=~' + restrictionModule;
      } else {
        searchExpression += ' and RestrictionModule~!=~' + restrictionModule;
      }
    }

    apiUrl.searchParams.set('$filter', searchExpression);
    apiUrl.searchParams.set('$orderby', 'name');
    apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
    apiUrl.searchParams.set('$top', '10');
    apiUrl.searchParams.set('$orderbydirection', 'desc');
    const result = await http.get(apiUrl.toString());
    if (result) {
      response = {
        TotalRecords: result.data.totalCount,
        Values: result.data.values,
      } as PaginationResponseModel<any>;
    } else {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<any>;
    }
  } catch (error) {
    response = {
      TotalRecords: 0,
      Values: [],
    } as PaginationResponseModel<any>;
  }
  return response;
};

export const postAirLine: any = async (Datavalue: any) => {
  const apiUrl = new URL(environment.api.baseUrl + 'AirLine');
  try {
    const result: any = await http.post(apiUrl.toString(), Datavalue);
    if (result) {
      toast.success(i18nMessages.saveSuccessfully);
      getAirline('', 1);
      const newOption = {
        label: result.data.name,
        value: result.data.id,
      };
      return newOption;
    } else {
      toast.error(i18nMessages.errorOccured);
    }
  } catch (error: any) {}
};

export const postCountry: any = async (Datavalue: any) => {
  const apiUrl = new URL(environment.api.baseUrl + 'Country');
  try {
    const result: any = await http.post(apiUrl.toString(), Datavalue);
    if (result) {
      toast.success(i18nMessages.saveSuccessfully);
      getCountries('', 1);
      const newOption = {
        label: result.data.name,
        value: result.data.id,
      };
      return newOption;
    } else {
      toast.error(i18nMessages.errorOccured);
    }
  } catch (error: any) {}
};

const getRemainingContacts = async (
  customerId: number,
  search: string,
  pageNo: number,
  id: string
) => {
  let response: PaginationResponseModel<ContactPersonModel>;
  const apiUrl = new URL(
    environment.api.baseUrl + 'DecPackOrder/GetDecPackOrderContact'
  );
  try {
    let searchExpression =
      'EntityType~=~' +
      EntityTypeEnum.Customer +
      ' and EntityId~=~' +
      customerId +
      ' and Status~=~' +
      EntityStatus.Active;
    searchExpression += search ? ` and FirstName~like~${search}` : '';
    apiUrl.searchParams.set('$filter', searchExpression);
    apiUrl.searchParams.set('$orderby', 'firstName');
    apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
    apiUrl.searchParams.set('$top', '10');
    apiUrl.searchParams.set('$orderbydirection', 'desc');
    apiUrl.searchParams.set('$id', id.toString());

    const result = await http.get(apiUrl.toString());
    if (result) {
      response = {
        TotalRecords: result.data.totalCount,
        Values: result.data.values,
      } as PaginationResponseModel<ContactPersonModel>;
    } else {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<ContactPersonModel>;
    }
  } catch (error) {
    response = {
      TotalRecords: 0,
      Values: [],
    } as PaginationResponseModel<ContactPersonModel>;
  }
  return response;
};

export async function loadRemainingContactOptions(
  customerId: number,
  search: string,
  loadedOptions: any,
  { page }: any,
  id: string
) {
  const response = await getRemainingContacts(customerId, search, page, id);
  const hasMore = Math.ceil(response.TotalRecords / 10) > page;
  return {
    options: response.Values.map((x) => {
      return {
        value: x.id.toString(),
        label: `${x.firstName + ' ' + x.lastName}`,
      } as BaseModel;
    }),
    hasMore,
    additional: {
      page: page + 1,
    },
  };
}
