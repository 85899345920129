import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import auth from '../../auth/authentication';
import RouteEnum from '../../models/RouteEnum';
import I18nSelector from 'common/i18nSelector/i18nSelector';
import I18n from 'utilities/i18n';
import { ReactComponent as Logo } from '../../assets/images/hazgo-logo-blue.svg';
import { ReactComponent as LogoWhite } from '../../assets/images/hazgo-logo-white.svg';
import MenuIcon from '@mui/icons-material/Menu';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { Box } from '@mui/system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHouse,
  faTruckFast,
  faBoxOpen,
  faCalendarDays,
  faUser,
  faUserGear,
} from '@fortawesome/free-solid-svg-icons';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Permission } from 'Permissions';
import { Menu, MenuItem, MenuButton, SubMenu } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { hasPermission } from 'utilities/protectedRoute';
import environment from 'environment';
import axios from 'axios';
import { Utilities } from 'utilities/Utilities';

const userName = localStorage.getItem('user');
const customerPortalUser = Utilities.getIsCPUser();
const MainNav = (props: any) => {
  const currentUser = auth.getCurrentUser();
  const navigate = useNavigate();
  const [permissions, setPermissions] = useState(
    localStorage.getItem('permissions') || ''
  );

  const handleOnRedirectServiceCatalogue = () => {
    navigate(RouteEnum.ServiceCatalogue);
  };

  const handleLogout = () => {
    auth.logout();
    localStorage.setItem('loggedOut', 'true');
    window.location.href = RouteEnum.Login;
  };

  // // return focus to the button when we transitioned from !open -> open
  const [isNavOpen, setIsNavOpen] = useState(false); // initiate isNavOpen state with false
  const [isLoading, setIsLoading] = useState(true);
  const i18TitleMessages = {
    zones: I18n('Nav.Zones'),
    orderType: I18n('Nav.OrderType'),
    serviceCatalogue: I18n('Nav.ServiceCatalogue'),
    productCatalogue: I18n('Nav.ProductCatalogue'),
    customers: I18n('Nav.Customers'),
    roles: I18n('Nav.Roles'),
    users: I18n('Nav.Users'),
    products: I18n('Nav.Products'),
    dashboard: I18n('Nav.Dashboard'),
    configuration: I18n('Nav.Configuration'),
    reference: I18n('Nav.Reference'),
    partners: I18n('Nav.Partners'),
    order: I18n('Nav.PurchaseOrder'),
    orderSetting: I18n('Nav.PurchaseOrderSetting'),
    TariffCustoms: I18n('Nav.TariffCustoms'),
    transport: I18n('Nav.TransportCatalogue'),
    shipping: I18n('Nav.Shipping'),
    orders: I18n('Nav.Orders'),
    offers: I18n('Nav.Offers'),
    Questionnaire: I18n('Nav.Questionnaire'),
    myProfile: I18n('Nav.MyProfile'),
    dnpWorkOrders: I18n('Nav.DnpWorkOrders'),
    shippingWorkOrders: I18n('Nav.ShippingWorkOrders'),
    Airline: I18n('Nav.Airline'),
    State: I18n('Nav.State'),
    Restrictions: I18n('Nav.Restrictions'),
    Labels: I18n('Nav.Labels'),
    ExchangeRates: I18n('Nav.ExchangeRates'),
    UserManagement: I18n('Nav.UserManagement'),
  };

  const i18nText = {
    ServiceCatalogue: I18n('Nav.ServiceCatalogue'),
    Customer: I18n('Nav.Customer'),
    UnApprovedCustomers: I18n('Nav.UnApprovedCustomers'),
  };

  const fetchPermissions = () => {
    var permission = localStorage.getItem('permissions');
    if (permission === null || permission === '') {
      var token: any = localStorage.getItem('token') ?? null;
      const userToken = JSON.parse(token);
      const permissionInstance = axios.create();
      return permissionInstance
        .get(
          environment.api.baseUrl + 'Roles/GetRoleModulePermissionsForUser',
          {
            headers: {
              Authorization: 'Bearer ' + userToken,
            },
          }
        )
        .then((response) => {
          if (response.data) {
            localStorage.setItem('permissions', response.data);
            setIsLoading(false);
          }
        });
    }
  };

  useEffect(() => {
    fetchPermissions();
  }, [isLoading]);

  return (
    <React.Fragment>
      <CssBaseline />
      <div className="navbar-outer">
        <Container fixed>
          <div className={isNavOpen ? 'showMenuNav' : 'hideMenuNav'}>
            <nav className="navbar navbar-expand-lg">
              <div className="logo-outer nav-minWidth">
                <LogoWhite />
              </div>
              <div
                className="collapse navbar-collapse justify-content-center"
                id="navbarNavAltMarkup"
              >
                <div className="navbar-nav">
                  <div className="logo-outer">
                    <Logo />
                  </div>

                  <NavLink
                    className="nav-item nav-link"
                    onClick={(e) => e.preventDefault()}
                    to={RouteEnum.Home}
                  >
                    <FontAwesomeIcon icon={faHouse} className="menu-icon" />
                    {i18TitleMessages.dashboard}
                  </NavLink>

                  {/* DEC & PACK ORDER MAIN MENU */}
                  {hasPermission(Permission.canViewOrder) ||
                  hasPermission(Permission.canViewDecPackOrders) ||
                  hasPermission(Permission.canViewDecPackOffers) ? (
                    <Menu
                      menuButton={({ open }) => (
                        <MenuButton className="nav-item nav-link">
                          <FontAwesomeIcon
                            icon={faBoxOpen}
                            className="menu-icon"
                          />{' '}
                          Dec &amp; Pack
                          <span className="arrow-icon">
                            {open ? <ExpandLess /> : <ExpandMore />}
                          </span>
                        </MenuButton>
                      )}
                      transition
                      align="center"
                      offsetY={10}
                    >
                      {hasPermission(Permission.canViewDecPackOrders) ? (
                        <MenuItem
                          onClick={(e) => navigate(RouteEnum.DecPackOrdersList)}
                        >
                          {i18TitleMessages.orders}
                        </MenuItem>
                      ) : (
                        <></>
                      )}

                      {hasPermission(Permission.canViewDecPackOrders) ? (
                        <MenuItem
                          onClick={(e) =>
                            navigate(RouteEnum.AllDecPackOrdersList)
                          }
                        >
                          {i18TitleMessages.dnpWorkOrders}
                        </MenuItem>
                      ) : (
                        <></>
                      )}

                      {hasPermission(Permission.canViewDecPackOffers) ? (
                        <MenuItem
                          onClick={(e) => navigate(RouteEnum.DecPackOffersList)}
                        >
                          {i18TitleMessages.offers}
                        </MenuItem>
                      ) : (
                        <></>
                      )}
                    </Menu>
                  ) : (
                    <></>
                  )}

                  {/* SHIPPING MAIN MENU */}
                  {hasPermission(Permission.canViewShippingOrders) ||
                  hasPermission(Permission.canViewShippingOffers) ? (
                    <Menu
                      menuButton={({ open }) => (
                        <MenuButton className="nav-item nav-link">
                          <FontAwesomeIcon
                            icon={faTruckFast}
                            className="menu-icon"
                          />
                          {i18TitleMessages.shipping}
                          <span className="arrow-icon">
                            {open ? <ExpandLess /> : <ExpandMore />}
                          </span>
                        </MenuButton>
                      )}
                      transition
                      align="center"
                      offsetY={10}
                    >
                      {hasPermission(Permission.canViewShippingOrders) ? (
                        <MenuItem
                          onClick={() => navigate(RouteEnum.ShippingOrdersList)}
                        >
                          {i18TitleMessages.orders}
                        </MenuItem>
                      ) : null}

                      {hasPermission(Permission.canViewShippingOrders) &&
                      !customerPortalUser ? (
                        <MenuItem
                          onClick={() => navigate(RouteEnum.AllShippingOrders)}
                        >
                          {i18TitleMessages.shippingWorkOrders}
                        </MenuItem>
                      ) : null}

                      {hasPermission(Permission.canViewShippingOffers) ? (
                        <MenuItem
                          onClick={() => navigate(RouteEnum.ShippingOffersList)}
                        >
                          {i18TitleMessages.offers}
                        </MenuItem>
                      ) : null}
                    </Menu>
                  ) : (
                    <></>
                  )}

                  {/* CUSTOMER & PARTNER MAIN MENU */}
                  {hasPermission(Permission.canViewCustomer) ||
                  hasPermission(Permission.canViewCustomerApproval) ||
                  hasPermission(Permission.canViewLabelMaster) ||
                  hasPermission(Permission.canViewPartner) ? (
                    <Menu
                      menuButton={({ open }) => (
                        <MenuButton className="nav-item nav-link">
                          <FontAwesomeIcon
                            icon={faCalendarDays}
                            className="menu-icon"
                          />
                          {i18TitleMessages.reference}
                          <span className="arrow-icon">
                            {open ? <ExpandLess /> : <ExpandMore />}
                          </span>
                        </MenuButton>
                      )}
                      transition
                      align="center"
                      offsetY={10}
                    >
                      {hasPermission(Permission.canViewCustomer) ||
                      hasPermission(Permission.canViewCustomerApproval) ? (
                        <SubMenu label={i18TitleMessages.customers}>
                          {hasPermission(Permission.canViewCustomer) ? (
                            <MenuItem
                              onClick={() => navigate(RouteEnum.Customer)}
                            >
                              {i18TitleMessages.customers}
                            </MenuItem>
                          ) : null}
                          {hasPermission(Permission.canViewCustomerApproval) ? (
                            <MenuItem
                              onClick={() =>
                                navigate(RouteEnum.CustomerApproval)
                              }
                            >
                              {i18nText.UnApprovedCustomers}
                            </MenuItem>
                          ) : null}
                        </SubMenu>
                      ) : (
                        <></>
                      )}

                      {hasPermission(Permission.canViewPartner) ? (
                        <MenuItem
                          onClick={() => navigate(RouteEnum.PartnerList)}
                        >
                          {i18TitleMessages.partners}
                        </MenuItem>
                      ) : null}

                      {hasPermission(Permission.canViewLabelMaster) ? (
                        <MenuItem
                          onClick={() => navigate(RouteEnum.LabelMasterList)}
                        >
                          {i18TitleMessages.Labels}
                        </MenuItem>
                      ) : null}
                    </Menu>
                  ) : (
                    <></>
                  )}

                  {/* CONFIGURATION MAIN MENU */}
                  {(hasPermission(Permission.canViewServiceCatalogue) ||
                    hasPermission(Permission.canViewTransportCatalogue) ||
                    hasPermission(Permission.canViewZone) ||
                    hasPermission(Permission.canViewTariffCustoms) ||
                    hasPermission(Permission.canViewQuestionnaire) ||
                    hasPermission(Permission.canViewRole) ||
                    hasPermission(Permission.canViewUser) ||
                    hasPermission(
                      Permission.canViewProductCatalogueManagement
                    ) ||
                    hasPermission(Permission.canViewOrderType) ||
                    hasPermission(Permission.canViewPurchaseOrderSettings) ||
                    hasPermission(Permission.canViewAirline) ||
                    hasPermission(Permission.canViewExchangeRate) ||
                    hasPermission(Permission.canViewState)) &&
                  !customerPortalUser ? (
                    <Menu
                      menuButton={({ open }) => (
                        <MenuButton className="nav-item nav-link">
                          <FontAwesomeIcon
                            icon={faUserGear}
                            className="menu-icon"
                          />
                          {i18TitleMessages.configuration}
                          <span className="arrow-icon">
                            {open ? <ExpandLess /> : <ExpandMore />}
                          </span>
                        </MenuButton>
                      )}
                      transition
                      align="center"
                      offsetY={10}
                    >
                      {hasPermission(Permission.canViewServiceCatalogue) ? (
                        <MenuItem onClick={handleOnRedirectServiceCatalogue}>
                          {i18TitleMessages.serviceCatalogue}
                        </MenuItem>
                      ) : null}
                      {hasPermission(Permission.canViewTransportCatalogue) ? (
                        <MenuItem
                          onClick={(e) => navigate(RouteEnum.Transport)}
                        >
                          {i18TitleMessages.transport}
                        </MenuItem>
                      ) : null}
                      {hasPermission(Permission.canViewZone) ? (
                        <MenuItem onClick={(e) => navigate(RouteEnum.Zones)}>
                          {i18TitleMessages.zones}
                        </MenuItem>
                      ) : null}

                      {hasPermission(Permission.canViewTariffCustoms) ? (
                        <MenuItem
                          onClick={(e) => navigate(RouteEnum.TariffCustoms)}
                        >
                          {i18TitleMessages.TariffCustoms}
                        </MenuItem>
                      ) : null}
                      {hasPermission(Permission.canViewQuestionnaire) ? (
                        <MenuItem
                          onClick={(e) => navigate(RouteEnum.Questionnaire)}
                        >
                          {i18TitleMessages.Questionnaire}
                        </MenuItem>
                      ) : null}
                      {hasPermission(Permission.canViewAirline) ||
                      hasPermission(Permission.canViewState) ? (
                        <SubMenu label={i18TitleMessages.Restrictions}>
                          {hasPermission(Permission.canViewAirline) ? (
                            <MenuItem
                              onClick={(e) => navigate(RouteEnum.Airline)}
                            >
                              {i18TitleMessages.Airline}
                            </MenuItem>
                          ) : null}
                          {hasPermission(Permission.canViewState) ? (
                            <MenuItem
                              onClick={(e) => navigate(RouteEnum.State)}
                            >
                              {i18TitleMessages.State}
                            </MenuItem>
                          ) : null}
                        </SubMenu>
                      ) : (
                        <></>
                      )}
                      {hasPermission(Permission.canViewRole) ||
                      hasPermission(Permission.canViewUser) ? (
                        <SubMenu label={i18TitleMessages.UserManagement}>
                          {hasPermission(Permission.canViewRole) ? (
                            <MenuItem
                              onClick={(e) => navigate(RouteEnum.Roles)}
                            >
                              {i18TitleMessages.roles}
                            </MenuItem>
                          ) : null}
                          {hasPermission(Permission.canViewUser) ? (
                            <MenuItem
                              onClick={(e) => navigate(RouteEnum.UsersList)}
                            >
                              {i18TitleMessages.users}
                            </MenuItem>
                          ) : null}
                        </SubMenu>
                      ) : (
                        <></>
                      )}
                      {hasPermission(
                        Permission.canViewProductCatalogueManagement
                      ) ||
                      hasPermission(Permission.canViewOrderType) ||
                      hasPermission(Permission.canViewPurchaseOrderSettings) ? (
                        <SubMenu label={i18TitleMessages.products}>
                          {hasPermission(
                            Permission.canViewProductCatalogueManagement
                          ) ? (
                            <MenuItem
                              onClick={() =>
                                navigate(RouteEnum.ProductCatalogue)
                              }
                            >
                              {i18TitleMessages.productCatalogue}
                            </MenuItem>
                          ) : null}

                          {hasPermission(Permission.canViewOrderType) ? (
                            <MenuItem
                              onClick={(e) => navigate(RouteEnum.OrderType)}
                            >
                              {i18TitleMessages.orderType}
                            </MenuItem>
                          ) : null}
                          {hasPermission(Permission.canViewOrder) ? (
                            <MenuItem
                              onClick={(e) => navigate(RouteEnum.Order)}
                            >
                              {i18TitleMessages.order}
                            </MenuItem>
                          ) : (
                            <></>
                          )}
                          {hasPermission(
                            Permission.canViewPurchaseOrderSettings
                          ) ? (
                            <MenuItem
                              onClick={(e) => navigate(RouteEnum.OrderSetting)}
                            >
                              {i18TitleMessages.orderSetting}
                            </MenuItem>
                          ) : null}
                        </SubMenu>
                      ) : (
                        <></>
                      )}
                      {hasPermission(Permission.canViewExchangeRate) ? (
                        <MenuItem
                          onClick={(e) => navigate(RouteEnum.ExchangeRates)}
                        >
                          {i18TitleMessages.ExchangeRates}
                        </MenuItem>
                      ) : null}
                    </Menu>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
                className="nav-minWidth"
              >
                <div className="language">
                  <I18nSelector />
                </div>
                <div className="user-wrap">
                  <Menu
                    menuButton={({ open }) => (
                      <MenuButton className="nav-item nav-link transparent-btn">
                        <FontAwesomeIcon icon={faUser} className="main-icon" />
                        <span className="arrow-icon">
                          {open ? <ExpandLess /> : <ExpandMore />}
                        </span>
                      </MenuButton>
                    )}
                    transition
                    align="end"
                    offsetY={10}
                  >
                    <MenuItem className="user-info">
                      <FontAwesomeIcon
                        icon={faUser}
                        className="user-info-icon"
                      />{' '}
                      {userName && userName?.replaceAll('"', '')}
                    </MenuItem>
                    <MenuItem onClick={(e) => navigate(RouteEnum.MyProfile)}>
                      {i18TitleMessages.myProfile}
                    </MenuItem>
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  </Menu>
                </div>
                <div
                  className="hamburger-wrap transparent-btn nav-link"
                  onClick={() => setIsNavOpen((prev) => !prev)}
                >
                  <MenuIcon></MenuIcon>
                </div>
              </Box>
            </nav>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MainNav;
