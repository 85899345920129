import {
  Accordion,
  AccordionSummary,
  Grid,
  Typography,
  AccordionDetails,
  Box,
} from '@mui/material';
import React from 'react';

export const FormAccordianSave = (props: any) => {
  const { title, details, additionalDetails } = props;

  return (
    <div>
      <Accordion expanded={true} className="custom-accordion">
        <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
          <Grid
            xs={12}
            container
            justifyContent="space-between"
            alignItems="center"
            flexDirection={{ xs: 'column', sm: 'row' }}
            spacing={0}
          >
            <Grid xs={7}>
              <Typography variant="h5">{title}</Typography>
            </Grid>
            <Grid xs={5}>{additionalDetails}</Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Box component="form" noValidate autoComplete="off">
            <Grid container spacing={2} className="grid-wrap two-col">
              {details}
            </Grid>
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default FormAccordianSave;
