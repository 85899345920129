import { Breadcrumbs, Button, Grid, Link, Typography } from '@mui/material';
import FormAccordian from 'common/formControl/formAccordian';
import Status from 'common/WoStatus/Status';
import environment from 'environment';
import { DnPWorkOrders } from 'models/deck-pack-orders';
import {
  ActivityOwner,
  OrderType,
  ImageUploadViewModel,
  BaseModel,
  WorkOrderStatus,
} from 'models/pagination-model';
import RouteEnum from 'models/RouteEnum';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import I18n from 'utilities/i18n';
import http from '../../../utilities/httpService';
import CustomizedInputs from 'common/formControl/formControl';
import DateTimePickerInput from 'common/DatetimePicker/DateTimePickerInput';
import { Moment } from 'moment';
import { hasPermission } from 'utilities/protectedRoute';
import { Permission } from 'Permissions';
import FormAccordianSave from 'common/formControl/formAccordianSave';
import moment from 'moment';
import DecpackStatus from './DecpackStatus';
import { faCircleStop } from '@fortawesome/free-solid-svg-icons';
import CreatedByandUpdatedBy from 'common/CreatedByandUpdatedBy/CreatedByandUpdatedBy';
import MobileDetails from 'common/MobileDetails/MobileDetails';
import ApiUrlConstants from 'constants/api.constants';
import ImageUploadPreview from 'common/ImageUploadPreview/ImageUploadPreview';

function DecPackWOOrderDelivery() {
  const { woId, id } = useParams();
  let navigate = useNavigate();

  const today = new Date().toISOString();
  const [status, setStatus] = useState<any>(1);
  const [decPackWO6terData, setDecPackWO6terData] = useState<any>({});
  const [canEdit, setCanEdit] = useState(
    hasPermission(Permission.canEditDecPackOrders)
  );
  const [isStatusSaveCallApi, setIsStatusSaveCallApi] =
    useState(false); /* To call Submit APi Status from Status Component */
  const [validateStatus, setValidateStatus] = useState<boolean>(false);
  /* validateStatus - To validate Status Dropdown from Status Component */
  const [isSaveNext, setIsSaveNext] = useState<boolean>(true);
  const [details, setDetails] = useState<any>({
    createdBy: '',
    createdDate: '',
    updatedBy: '',
    updatedDate: '',
  });

  const [assigneeData, setAssigneeData] = useState<any>({
    assignedToId: '',
    assignedToName: '',
    assignNextToId: '',
    assignNextToName: '',
  });
  const [isMobile, setIsMobile] = useState(false);
  const [signatureUrl, setSignatureUrl] = useState<ImageUploadViewModel[]>([]);

  const breadcrumbs = [
    <Typography key="3" color="text.primary">
      {I18n('Nav.Order')}
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={() => navigate(RouteEnum.DecPackOrdersList)}
        className="cpointer"
      >
        {I18n('Nav.DecPackOrders')}
      </Link>
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={() => navigate(RouteEnum.ViewDecPackOrders + '/' + id + '/1')}
        className="cpointer"
      >
        {I18n('ShippingOrders.WorkOrders')}
      </Link>
    </Typography>,
  ];

  const i18nMessages = {
    title: I18n('DecPackOrders.WorkOrder.WO6ltrDeliveryOrderTitle'),
    requiredErrMsg: I18n('DecPackOrders.WorkOrder.requiredErrMsg'),
    detailsUpdateMsg: I18n('DecPackOrders.WorkOrder.detailsUpdateMsg'),
    detailsAddMsg: I18n('DecPackOrders.WorkOrder.detailsAddMsg'),
    invalidDateErrMsg: I18n('DecPackOrders.WorkOrder.invalidDateErrMsg'),
    commonFieldIsRequired: I18n('Common.FieldIsRequired'),
    InvalidDate: I18n('Common.InvalidDate'),
    ClientNotAvailable: I18n('Common.ClientNotAvailable'),
    ClientNotReady: I18n('Common.ClientNotReady'),
    SignedOffBy: I18n('Common.SignedOffBy'),
  };

  const onValueChange = (event: any) => {
    const { name, value } = event.target;
    validate({ [name]: value });
    setValues({
      ...values,
      [name.toString()]: value,
    });
  };

  const DeliverySection = () => {
    return (
      <>
        <Grid item lg={6} md={6}>
          <CustomizedInputs
            margin="normal"
            displayLabel={I18n('DecPackOrders.WorkOrder.CustomeName')}
            name="customerName"
            id="customerName"
            inputType="text"
            handleChange={onValueChange}
            required={true}
            errorValue={errors.customerName}
            errorMessage={errors.customerName}
            value={values?.customerName}
            readOnly={!canEdit}
          />
        </Grid>
        <Grid item lg={6} md={6}>
          <DateTimePickerInput
            displayLabel={I18n('DecPackOrders.WorkOrder.DateTime')}
            name="date"
            id="date"
            value={values.date}
            defaultValue={today}
            handleChange={(event: Moment) => {
              setValues({
                ...values,
                date: event != null ? event.toDate() : '',
              });
            }}
            errorValue={true}
            errorMessage={errors.date}
            readOnly={!canEdit}
          ></DateTimePickerInput>
        </Grid>
      </>
    );
  };

  const [errors, setErrors] = useState({
    customerName: '',
    date: '',
  });

  const [values, setValues] = useState<any>({
    customerName: '',
    date: today,
    decPackInitialOrderId: id,
    decPackWorkOrdersId: woId,
    signedOffByURL: '',
    mobileRemarks: '',
    waitTime: 0,
  });

  // Method to validate in input fields
  const validate = (fieldValues: any = values) => {
    let errMessages = { ...errors };

    if ('customerName' in fieldValues) {
      errMessages.customerName = fieldValues.customerName
        ? ''
        : i18nMessages.requiredErrMsg;
    }

    if ('date' in fieldValues) {
      errMessages.date = fieldValues.date
        ? ''
        : i18nMessages.commonFieldIsRequired;

      if (errMessages.date == '') {
        let isDateValid = moment(fieldValues.date).isValid();
        if (!isDateValid) {
          errMessages.date = i18nMessages.InvalidDate;
        }
      }
    }

    setErrors({
      ...errMessages,
    });

    if (fieldValues === values) {
      return Object.values(errMessages).every((x) => x === '');
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setValidateStatus(true);
    setIsSaveNext(false);
  };

  const getWOStatus = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl +
        'DecPackOrder/GetDecPackWO/' +
        id +
        '/' +
        DnPWorkOrders.Wo6ter
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result && result.data) {
        setDetails({
          ...details,
          createdBy: result.data.createdBy,
          updatedBy: result.data.updatedBy,
          createdDate: result.data.createdDate,
          updatedDate: result.data.updatedDate,
        });

        setAssigneeData({
          ...assigneeData,
          assignedToId: result.data.assignedToId,
          assignedToName: result.data.assignedToName,
          assignNextToId: result.data.assignedNextToId,
          assignNextToName: result.data.assignedNextToName,
        });

        setIsMobile(result.data.isMobile);
        setStatus(result.data.status);
      }
    } catch (error) {}
  };

  const getDecPackWO6ter = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl + `DeckPackWO6ter/${id}/${woId}`
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result && result.data != '') {
        setDecPackWO6terData(result.data);
        setValues({
          ...values,
          customerName: result.data.customerName,
          date: result.data.date,
          decPackInitialOrderId: result.data.decPackInitialOrderId,
          decPackWorkOrdersId: result.data.decPackWorkOrdersId,
          mobileRemarks: result.data.mobileRemarks
            ? result.data.mobileRemarks
            : values.mobileRemarks,
          signedOffByURL: result.data.signedOffByURL
            ? result.data.signedOffByURL
            : values.signedOffByURL,
          waitTime: result.data.waitTime
            ? result.data.waitTime
            : values.waitTime,
        });
        if (result.data.signedOffByURL) {
          setSignatureUrl([
            {
              documentTag: {
                label: '',
                value: '',
              } as BaseModel,
              error: '',
              previewUrl:
                environment.api.baseUrl +
                ApiUrlConstants.DownloadImageUrl +
                result.data.signedOffByURL,
              path: result.data.signedOffByURL,
              imageUrl: result.data.signedOffByURL,
            } as ImageUploadViewModel,
          ]);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    getWOStatus();
    getDecPackWO6ter();
  }, []);

  /* Validate Status Dropdown Success call from Status Component */
  const handleSuccessStatusSave = async () => {
    let isValid = status == WorkOrderStatus.Done ? validate() : true;
    if (isValid) {
      setIsStatusSaveCallApi(true);
      if (Object.keys(decPackWO6terData).length > 0) {
        // PUT API
        try {
          const apiUrl = new URL(
            environment.api.baseUrl + `DeckPackWO6ter/${id}/${woId}`
          );
          const result: any = await http.put(apiUrl.toString(), values);
          if (result) {
            toast.success(i18nMessages.detailsUpdateMsg);
            handleErrorStatusSave();
            /* To set Validation and Submit APi Status False */
          }
        } catch (error: any) {
          alert('something went wrong');
          handleErrorStatusSave();
          /* To set Validation and Submit APi Status False */
        }
      } else {
        // POST API
        try {
          const apiUrl = new URL(environment.api.baseUrl + 'DeckPackWO6ter');
          const result: any = await http.post(apiUrl.toString(), values);
          if (result) {
            toast.success(i18nMessages.detailsAddMsg);
            setDecPackWO6terData(result.data);
            handleErrorStatusSave();
            /* To set Validation and Submit APi Status False */
          }
        } catch (error: any) {
          alert('something went wrong');
          handleErrorStatusSave();
          /* To set Validation and Submit APi Status False */
        }
      }
    } else {
      handleErrorStatusSave();
      /* To set Validation and Submit APi Status False */
    }
  };

  /* Validate Status Dropdown Error call from Status Component */
  const handleErrorStatusSave = () => {
    setValidateStatus(false);
    setIsStatusSaveCallApi(false);
  };

  const onSaveNextClick = async (event: any) => {
    event.preventDefault();
    setStatus(WorkOrderStatus.Done.toString());
    setValidateStatus(true);
    setIsSaveNext(true);
  };

  const refreshData = async () => {
    handleErrorStatusSave();
    getWOStatus();
  };

  const SignatureDetails = () => {
    return (
      <>
        <Grid item lg={12} md={12}>
          {signatureUrl.length > 0 ? (
            <ImageUploadPreview
              readOnly={true}
              disableKeyboardNav={true}
              limit={10}
              isImageOnly={true}
              images={signatureUrl}
              hideTags={true}
            />
          ) : (
            <></>
          )}
        </Grid>
      </>
    );
  };

  return (
    <>
      <div className="heading-section">
        <div className="heading-section-left">
          <Typography variant="h3" sx={{ mb: 3 }}>
            {i18nMessages.title}
          </Typography>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>
      </div>
      <div className="main-content-section">
        <Grid item lg={12} md={12}>
          <FormAccordianSave
            title={I18n('DecPackOrders.WorkOrder.Delivery')}
            className="inner-heading"
            details={DeliverySection()}
            showActionButton={canEdit}
          ></FormAccordianSave>
          <FormAccordianSave
            title={i18nMessages.SignedOffBy}
            details={SignatureDetails()}
            showActionButton={false}
            className="inner-heading"
          ></FormAccordianSave>
          <MobileDetails
            mobileRemarks={values.mobileRemarks}
            waitTime={values.waitTime}
          ></MobileDetails>
          <DecpackStatus
            permission={Permission.canEditDecPackOrders}
            woId={DnPWorkOrders.Wo6ter}
            activityOwner={ActivityOwner.Driver}
            orderId={id}
            statusValue={status}
            orderType={OrderType.DecPackOrder}
            isStatusSaveCallApi={isStatusSaveCallApi}
            validateStatus={validateStatus}
            handleSuccessStatusSave={handleSuccessStatusSave}
            handleErrorStatusSave={handleErrorStatusSave}
            isSaveNext={isSaveNext}
            isMobile={isMobile}
            workOrderNo={DnPWorkOrders.Wo6ter}
            assigneeData={assigneeData}
            handleStatusChange={(value: number) => setStatus(value.toString())}
          />
          <div className="btn-wrap form-btn-wrap">
            {canEdit ? (
              <Button
                color="primary"
                disableElevation
                variant="contained"
                onClick={onSaveNextClick}
              >
                {I18n('Common.SaveNext')}
              </Button>
            ) : (
              <></>
            )}
            &nbsp; &nbsp; &nbsp;
            {canEdit ? (
              <Button
                type="submit"
                color="primary"
                disableElevation
                variant="contained"
                onClick={handleSubmit}
              >
                {I18n('Common.Save')}
              </Button>
            ) : (
              <></>
            )}
          </div>
          <CreatedByandUpdatedBy
            createdBy={details.createdBy}
            createdDate={details.createdDate}
            updatedBy={details.updatedBy}
            updatedDate={details.updatedDate}
          ></CreatedByandUpdatedBy>
        </Grid>
      </div>
    </>
  );
}

export default DecPackWOOrderDelivery;
