import Typography from '@mui/material/Typography';
import I18n from 'utilities/i18n';
import { useEffect, useCallback, useState } from 'react';
import http from '../../utilities/httpService';
import {
  BaseModel,
  BillableMaterialTypeDescription,
  BOMDropdownType,
  Department,
  EntityTypeEnum,
  MasterCategoryEnumType,
  OrderByDirection,
  ShippingMaterialTypeDescription,
  TansportCategoriesDescription,
} from 'models/pagination-model';
import { Button, Grid, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import environment from 'environment';
import { toast } from 'react-toastify';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

import {
  GridActionsCellItem,
  GridColumns,
  GridPaginationInitialState,
  GridRenderEditCellParams,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridRowsProp,
  GridSortingInitialState,
  GridSortModel,
  GridToolbarContainer,
  MuiEvent,
  useGridApiContext,
} from '@mui/x-data-grid';
import ConfirmationPopup from 'common/confirmationPopup/confirmationPopup';
import FileUpload from 'common/fileupload/fileupload';
import AddDecPackShippingMaterial from './AddDecPackShippingMaterial';
import { CheckOrderEnable, getDecPackById } from './DeckPackApiService';
import { hasPermission } from 'utilities/protectedRoute';
import { Permission } from 'Permissions';
import CustomDataGridInline from 'common/datagrid/CustomDataGridInline';
import CustomizedSelect from 'common/CustomSelect/CustomSelect';
import CustomSelectPaginate from 'common/CustomSelect/CustomSelectPaginate';
import {
  getTransportCatalogueById,
  loadItems,
  loadItemValuesByid,
} from 'components/shipping-orders/ShippingApiService';
import NumericInput from 'common/NumericInput/NumericInput';
import DecimalInput from 'common/DecimalInput/DecimalInput';
import CustomizedInputs from 'common/formControl/formControl';
import DecPackMaterialQuantityDetailsPopup from 'components/decpack-offers/DecPackMaterialQuantityDetailsPopup';
import FormAccordianSave from 'common/formControl/formAccordianSave';
import CreditNote from 'common/CreditNote/CreditNote';

function DecPackShippingMaterial(props: any) {
  const {
    shippingOrderId,
    isSubmit,
    handleSubmit,
    setIsSubmit,
    isAddShippingMaterial,
    hideCostPrice = true,
    showAlertMessage = false,
    showFileUpload = true,
    approvedBOM,
    isOffer,
    isFromWO = false,
  } = props;
  const [files, setFiles] = useState([]);
  const [rows, setRows] = useState([]);
  const [notes, setNotes] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [wareHouseQuantity, setWareHouseQuantity] = useState('');
  const [requestedQuantity, setRequestedQuantity] = useState('');
  const [threshold, setThreshold] = useState('');
  const [isDeleteModalOpen, setDeleteModal] = useState(false);
  const [selectedId, setId] = useState();
  const [isAddModalOpen, setAddModal] = useState(false);
  const [isApproved, setIsApproved] = useState(true);
  const [customerCurrency, setCustomerCurrency] = useState('');
  const [isUploadButtonOpen, setUploadButtonOpen] = useState(false);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [editData, setEditData] = useState(null);
  const [quantityFocus, setQuantityFocus] = useState(false);
  const [focusedCat, setFocusedCat] = useState(false);
  const [gridData, setGridData] = useState({
    sortOrder: OrderByDirection.Descending,
    sortBy: 'Quantity',
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [10, 20, 50, 100],
    pageSize: 10,
    page: 0,
    searchExpression: '',
  });
  const defaultValue = {
    value: '',
    label: I18n('Placeholders.Select'),
  } as BaseModel;
  const [tranpostTypes, setTransportTypes] = useState(defaultValue);
  const [focused, setFocused] = useState(false);
  const [focusedAdd, setFocusedAdd] = useState(false);
  const [canEdit, setCanEdit] = useState(
    hasPermission(Permission.canEditDecPackOffers) ||
      hasPermission(Permission.canEditDecPackOrders)
  );
  const [canAdd, setCanAdd] = useState(
    hasPermission(Permission.canAddDecPackOffers) ||
      hasPermission(Permission.canAddDecPackOrders)
  );

  const [canDelete, setCanDelete] = useState(
    hasPermission(Permission.canDeleteDecPackOffers) ||
      hasPermission(Permission.canDeleteDecPackOrders)
  );

  const [canApprove, setCanApprove] = useState(
    hasPermission(Permission.canApproveDecPackOrders)
  );

  const defaultAdditional: any = {
    page: 1,
  };
  let typeList: Array<BaseModel> = [];
  const getShippingMaterialList = () => {
    typeList = [defaultValue];
    if (isAddShippingMaterial) {
      ShippingMaterialTypeDescription.forEach((value: string, key: number) => {
        typeList.push({
          value: key.toString(),
          label: I18n(value),
        } as BaseModel);
      });
    } else {
      BillableMaterialTypeDescription.forEach((value: string, key: number) => {
        typeList.push({
          value: key.toString(),
          label: I18n(value),
        } as BaseModel);
      });
    }
  };

  let transportTypeList: Array<BaseModel> = [];
  const transportTypeListData = () => {
    TansportCategoriesDescription.forEach((value: string, key: number) => {
      transportTypeList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };

  getShippingMaterialList();
  transportTypeListData();
  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));

  const getShippingMaterial = async () => {
    const apiUrl = new URL(environment.api.baseUrl + 'DecPackShippingMaterial');
    try {
      apiUrl.searchParams.set('$filter', `OrderId~=~${shippingOrderId} `);
      apiUrl.searchParams.set('$orderby', gridData.sortBy);
      apiUrl.searchParams.set(
        '$skip',
        (gridData.page * gridData.pageSize).toString()
      );
      apiUrl.searchParams.set('$top', gridData.pageSize.toString());
      apiUrl.searchParams.set(
        '$orderbydirection',
        gridData.sortOrder.toString()
      );
      const result = await http.get(apiUrl.toString());
      if (result) {
        setTotalSellingPrice(result.data.item2);
        setTotalCostPrice(result.data.item3);
        updateGridData('totalRows', result.data.item1.totalCount);
        updateGridData('rows', result.data.item1.values);

        props.sendData(result);
      }
    } catch (error) {}
  };

  const getDecPackByDetailsId = async () => {
    // if (approvedBOM === undefined) {
    const response: any = await getDecPackById(shippingOrderId);
    if (response) {
      setIsApproved(response.approvedBOM);
      setCustomerCurrency(response.CustomerCurrency);
      setNotes(response.bomNotes);
      if (response.isDecPackOfferConverted) {
        setCanEdit(false);
        setCanDelete(false);
        setCanAdd(false);
      } else {
        const orderState = CheckOrderEnable(+response.status);
        if (!orderState) {
          setCanEdit(false);
          setCanDelete(false);
          setCanAdd(false);
        }
      }
    }
    // } else {
    //   setIsApproved(approvedBOM);
    // }
  };

  const refreshData = async () => {
    handleCloseForAdd();
    await getShippingMaterial();
  };
  const handleChangePage = (pageNo: number) => {
    updateGridData('page', pageNo);
  };
  const [totalSellingPrice, setTotalSellingPrice] = useState(false);
  const [totalCostPrice, setTotalCostPrice] = useState(false);
  const handleChangeBasicSearch = (searchExpression: any) => {
    updateGridData('searchExpression', searchExpression.target.value);
  };
  const handleChangePageSize = (pageSize: number) => {
    updateGridData('pageSize', pageSize);
  };
  type Row = (typeof gridData.rows)[number];
  const deleteShippingMaterial = (id: any) => {
    setDeleteModal(true);
    setId(id);
  };
  const editShippingMaterial = useCallback(
    (data: any) => () => {
      setEditData(data);
      setAddModal(true);
    },
    []
  );

  const i18nMessages = {
    deletedSuccessfully: I18n('ContactPerson.DeletedSuccessfully'),
    errorOccurred: I18n('ContactPerson.ErrorOccurred'),
    invalidFileFormat: I18n('Common.InvalidFileFormat'),
    notProcessed: I18n('ShippingMaterials.NotProcessed'),
    processedSuccessfully: I18n('ShippingMaterials.ProcessedSuccessfully'),
    sellingPriceLessThanCostPrice: I18n(
      'ShippingMaterials.ProcessedSuccessfully'
    ),
    savedSuccessfully: I18n('Common.SavedSuccessfully'),
    save: I18n('Common.Save'),
    costPrice: I18n('ShippingMaterials.CostPrice'),
    fieldRequired: I18n('ContactPerson.FieldRequired'),
    productQuantityNotAvailable: I18n(
      'ShippingMaterials.ProductQuantityNotAvailable'
    ),
    zeroNotAllowed: I18n('ShippingMaterials.ZeroNotAllowed'),
    incorrectTransportDetail: I18n(
      'ShippingMaterials.IncorrectTransportDetail'
    ),
    QuantityDetails: I18n('ShippingMaterials.QuantityDetails'),
    Notes: I18n('WorkOrder.Notes'),
  };

  function TypeEditComponent(props: GridRenderEditCellParams) {
    const { id, field } = props;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const apiRef = useGridApiContext();
    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event;
      apiRef.current.setEditCellValue({
        id,
        field: 'categoryDescription',
        value: defaultValue,
      });
      apiRef.current.setEditCellValue({
        id,
        field: 'itemCode',
        value: defaultValue,
      });
      apiRef.current.setEditCellValue({ id, field, value: newValue });
    };
    return (
      <CustomizedSelect
        autoFocus={focusedAdd}
        placeholder={defaultValue.label}
        options={typeList}
        isSearchable={false}
        handleChange={handleValueChange}
        errorValue={true}
        value={props?.row?.materialTypeDescription}
        isDisplayLabelClass={true}
      ></CustomizedSelect>
    );
  }

  const setCategoryValues = async (categoryId: number) => {
    var result = await loadItemValuesByid('', categoryId);

    if (result) {
      return result;
    } else {
      return null;
    }
  };

  function CategoryEditComponent(props: GridRenderEditCellParams) {
    const { id, value, field } = props;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const apiRef = useGridApiContext();
    const handleValueChange = async (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const newValue: any = event; // The new value entered by the user
      apiRef.current.setEditCellValue({ id, field, value: newValue });
      apiRef.current.setEditCellValue({
        id,
        field: 'itemCode',
        value: defaultValue,
      });

      var response: any = await setCategoryValues(+newValue.value);
      if (response) {
        var type = typeList.find((x) => x.value == response.entityType);
        apiRef.current.setEditCellValue({
          id,
          field: 'materialTypeDescription',
          value: type,
        });
        apiRef.current.setEditCellValue({ id, field, value: newValue });
        setFocusedCat(true);
        setFocusedAdd(false);
      }
    };
    var catergoryType: MasterCategoryEnumType;

    if (props?.row?.materialTypeDescription?.value) {
      catergoryType =
        MasterCategoryEnumType[
          props.row.materialTypeDescription
            .value as keyof typeof MasterCategoryEnumType
        ];
    }
    return (
      <CustomSelectPaginate
        key={props?.row?.materialTypeDescription.value}
        additional={defaultAdditional}
        value={props?.row?.categoryDescription}
        loadOptions={(search: string, prevOptions: any, page: any) =>
          loadItems(
            search,
            prevOptions,
            page,
            BOMDropdownType.Category,
            props?.row,
            false,
            Department.DecPack
          )
        }
        handleChange={handleValueChange}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
        }}
        placeholder={defaultValue.label}
        isSearchable={true}
        errorValue={true}
        debounceTimeout={500}
        isMultiSelect={false}
        autoFocus={focusedCat}
      />
    );
  }

  const setItemCodeValues = async (itemCode: string) => {
    var result = await loadItemValuesByid(itemCode);

    if (result) {
      return result;
    } else {
      return null;
    }
  };

  function ItemCodeEditComponent(props: GridRenderEditCellParams) {
    const { id, value, field } = props;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const apiRef = useGridApiContext();

    const handleValueChange = async (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const newValue: any = event; // The new value entered by the user
      apiRef.current.setEditCellValue({ id, field, value: newValue });

      var response: any = await setItemCodeValues(newValue.label);
      if (response) {
        apiRef.current.setEditCellValue({
          id,
          field: 'categoryDescription',
          value: { value: response.categoryId, label: response.categoryName },
        });

        var type = typeList.find((x) => x.value == response.entityType);
        apiRef.current.setEditCellValue({
          id,
          field: 'materialTypeDescription',
          value: type,
        });

        if (
          isOffer &&
          Number(type?.value) == MasterCategoryEnumType.ServiceCatalogue
        ) {
          apiRef.current.setEditCellValue({
            id,
            field: 'quantity',
            value: '1',
          });
        }

        if (Number(type?.value) == MasterCategoryEnumType.TransportCatalogue) {
          var result = await getTransportCatalogueById(+response.id);

          if (result) {
            var temp: any = [];

            result.data.transportTypes.forEach((element: any) => {
              temp.push({
                value: element.id.toString(),
                label: element.name,
              });
            });
            setTransportTypes(temp);
            if (temp && temp.length == 1) {
              apiRef.current.setEditCellValue({
                id,
                field: 'transportTypeDescription',
                value: temp[0],
              });
            }
          }
        }
        setFocused(true);
      }
    };
    var catergoryType: MasterCategoryEnumType;

    if (props?.row?.materialTypeDescription?.value) {
      catergoryType =
        MasterCategoryEnumType[
          props.row.materialTypeDescription
            .value as keyof typeof MasterCategoryEnumType
        ];
    }
    return (
      <CustomSelectPaginate
        key={props?.row?.categoryDescription.value}
        additional={defaultAdditional}
        value={props.row.itemCode}
        loadOptions={(search: string, prevOptions: any, page: any) =>
          loadItems(
            search,
            prevOptions,
            page,
            BOMDropdownType.Item,
            props?.row,
            true,
            Department.DecPack
          )
        }
        handleChange={handleValueChange}
        menuPortalTarget={document.body}
        isDisplayLabelClass={true}
        styles={{
          menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
        }}
        placeholder={defaultValue.label}
        isSearchable={true}
        errorValue={true}
        debounceTimeout={500}
        isMultiSelect={false}
        autoFocus={focused}
      />
    );
  }

  function TransportTypeEditComponent(props: GridRenderEditCellParams) {
    const { id, value, field } = props;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const apiRef = useGridApiContext();

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event; // The new value entered by the user
      apiRef.current.setEditCellValue({ id, field, value: newValue });
      //set category and itemcode
    };
    var catergoryType: MasterCategoryEnumType;

    if (props?.row?.materialTypeDescription?.value) {
      catergoryType =
        MasterCategoryEnumType[
          props.row.materialTypeDescription
            .value as keyof typeof MasterCategoryEnumType
        ];
    }
    return (
      <CustomizedSelect
        options={tranpostTypes}
        isSearchable={false}
        handleChange={handleValueChange}
        errorValue={true}
        isDisplayLabelClass={true}
      ></CustomizedSelect>
    );
  }

  async function getSellingPriceForMaterial(shippingMaterialId: any) {
    const apiUrl = new URL(
      environment.api.baseUrl +
        'DecPackShippingMaterial/GetSellingPriceForMaterial/' +
        shippingMaterialId
    );
    try {
      const result: any = await http.get(apiUrl.toString());
      if (result != null) {
        return result.data;
      } else {
        return 0;
      }
    } catch (error: any) {}
  }

  function QuantityEditComponent(props: GridRenderEditCellParams) {
    const { id, value, field } = props;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const apiRef = useGridApiContext();

    const handleValueChange = async (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const newValue = event.target.value; // The new value entered by the user
      apiRef.current.setEditCellValue({ id, field, value: newValue });
      if (Number(newValue) > 0) {
        var sellingPrice = await getSellingPriceForMaterial(id);
        apiRef.current.setEditCellValue({
          id,
          field: 'sellingPrice',
          value: sellingPrice * Number(newValue),
        });
      }
    };
    return (
      <NumericInput
        inputProps={{ maxLength: 10 }}
        name="quantity"
        handleChange={handleValueChange}
        errorValue={true}
        value={props.row.quantity}
        allowNegative={false}
        readOnly={false}
        autoFocus={quantityFocus}
      ></NumericInput>
    );
  }

  function SellingPriceEditComponent(props: GridRenderEditCellParams) {
    const { id, value, field } = props;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const apiRef = useGridApiContext();

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value; // The new value entered by the user
      apiRef.current.setEditCellValue({ id, field, value: newValue });
      // allow only in case of edit
    };
    return (
      <DecimalInput
        inputProps={{ maxLength: 10 }}
        name="sellingPrice"
        handleChange={handleValueChange}
        value={props.row.sellingPrice}
      ></DecimalInput>
    );
  }

  function NoteEditComponent(props: GridRenderEditCellParams) {
    const { id, value, field } = props;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const apiRef = useGridApiContext();

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value; // The new value entered by the user
      apiRef.current.setEditCellValue({ id, field, value: newValue });
      // allow only in case of edit
    };
    return (
      <CustomizedInputs
        margin="normal"
        id="note"
        name="note"
        inputType="text"
        inputProps={{ maxLength: 50 }}
        multiline={true}
        handleChange={handleValueChange}
        value={props.row.note}
      ></CustomizedInputs>
    );
  }

  const showQuantityBox = (params: any) => {
    return (
      Number(params.row.materialTypeDescription.value) ===
        MasterCategoryEnumType.TransportCatalogue ||
      Number(params.row.materialType) ===
        MasterCategoryEnumType.TransportCatalogue
    );
  };

  const handleOpenQuantityDetails = (row: any) => {
    setWareHouseQuantity(row.wareHouseQuantity);
    setRequestedQuantity(row.quantity);
    setThreshold(row.threshold);
    setIsOpen(true);
  };

  const handleCloseQuantityDetails = () => {
    setWareHouseQuantity('');
    setRequestedQuantity('');
    setThreshold('');
    setIsOpen(false);
  };

  const columns: GridColumns<Row> = [
    {
      field: 'materialTypeDescription',
      headerName: I18n('ShippingMaterials.Type'),
      flex: 1,
      sortable: false,
      editable: true,
      hide: true,
      renderEditCell: (params) => {
        return params.row.isNew &&
          rowModesModel[params.id]?.mode === GridRowModes.Edit ? (
          <TypeEditComponent {...params} />
        ) : (
          params.formattedValue
        );
      },
    },
    {
      field: 'categoryDescription',
      headerName: I18n('ShippingMaterials.Category'),
      sortable: false,
      editable: true,
      flex: 1,
      hide: true,
      renderEditCell: (params) => {
        return params.row.isNew &&
          rowModesModel[params.id]?.mode === GridRowModes.Edit ? (
          <CategoryEditComponent {...params} />
        ) : (
          params.formattedValue
        );
      },
    },
    {
      field: 'itemCode',
      headerName: I18n('ShippingMaterials.ItemCode'),
      sortable: false,
      editable: true,
      flex: 1,
      renderEditCell: (params) => {
        return params.row.isNew &&
          rowModesModel[params.id]?.mode === GridRowModes.Edit ? (
          <ItemCodeEditComponent {...params} />
        ) : (
          params.formattedValue
        );
      },
    },
    {
      field: 'transportTypeDescription',
      headerName: I18n('TransportCatalogue.TransportType'),
      sortable: false,
      editable: true,
      flex: 1,
      renderEditCell: (params) => {
        return params.row.isNew &&
          Number(params.row.materialTypeDescription.value) ===
            MasterCategoryEnumType.TransportCatalogue &&
          rowModesModel[params.id]?.mode === GridRowModes.Edit ? (
          <TransportTypeEditComponent {...params} />
        ) : params.formattedValue && params.formattedValue.value != '' ? (
          params.formattedValue.label
        ) : (
          ''
        );
      },
    },
    {
      field: 'name',
      headerName: I18n('ShippingMaterials.Name'),
      sortable: false,
      flex: 1,
    },
    {
      field: 'quantity',
      headerName: I18n('ShippingMaterials.Quantity'),
      sortable: false,
      editable: true,
      renderCell: (params: any) => {
        return params.formattedValue === 0 ? (
          '-'
        ) : (
          <>
            {isOffer &&
            Number(params.row.materialType) ==
              MasterCategoryEnumType.ProductCatalogue &&
            params.row.wareHouseQuantity - params.row.quantity <
              params.row.threshold ? (
              <Tooltip title={i18nMessages.QuantityDetails}>
                <FontAwesomeIcon
                  style={{ paddingRight: '8px' }}
                  icon={faInfoCircle}
                  onClick={() => handleOpenQuantityDetails(params.row)}
                  className={`fa-md icon-edit`}
                />
              </Tooltip>
            ) : (
              <></>
            )}
            {params.formattedValue}
          </>
        );
      },
      flex: 1,
      renderEditCell: (params) => {
        return showQuantityBox(params) ? (
          <>-</>
        ) : (
          <QuantityEditComponent {...params} />
        );
      },
    },
    {
      field: 'sellingPrice',
      headerName:
        I18n('ShippingMaterials.SellingPrice') +
        (customerCurrency ? ' (' + customerCurrency + ')' : ''),
      sortable: false,
      editable: true,
      flex: 1,
      hide: isAddShippingMaterial,
      renderEditCell: (params) => {
        return !params.row.isNew &&
          rowModesModel[params.id]?.mode === GridRowModes.Edit ? (
          <SellingPriceEditComponent {...params} />
        ) : (
          params.formattedValue
        );
      },
    },
    {
      field: 'costPrice',
      headerName:
        i18nMessages.costPrice +
        (customerCurrency ? ' (' + customerCurrency + ')' : ''),
      sortable: false,
      flex: 1,
      hide: hideCostPrice,
      editable: false,
      renderCell: (params: any) => {
        return params?.value?.toString()?.replaceAll('.', ',');
      },
    },
    {
      field: 'note',
      headerName: I18n('ShippingMaterials.Note'),
      sortable: true,
      editable: true,
      flex: 1,
      renderEditCell: (params) => {
        return <NoteEditComponent {...params} />;
      },
    },
    {
      field: 'actions',
      headerName: I18n('Common.Actions'),
      type: 'actions',
      width: 150,
      hide: isApproved && !canApprove,
      getActions: (params: any) => {
        const actionButtons = [];
        if (params.row.isManual) {
          const isInEditMode =
            rowModesModel[params.id]?.mode === GridRowModes.Edit;
          if (isInEditMode) {
            actionButtons.push(
              <GridActionsCellItem
                onPointerEnterCapture={false}
                onPointerLeaveCapture={false}
                placeholder={''}
                icon={<SaveIcon />}
                label="Save"
                onClick={handleSaveClick(params.id)}
              />
            );
            actionButtons.push(
              <GridActionsCellItem
                onPointerEnterCapture={false}
                onPointerLeaveCapture={false}
                placeholder={''}
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(params.id)}
                color="inherit"
              />
            );
          } else {
            if (canEdit) {
              actionButtons.push(
                <GridActionsCellItem
                  onPointerEnterCapture={false}
                  onPointerLeaveCapture={false}
                  placeholder={''}
                  icon={<EditIcon />}
                  label="Edit"
                  className="textPrimary"
                  onClick={handleEditClick(params)}
                  color="inherit"
                />
              );
            }
            if (canDelete) {
              actionButtons.push(
                <GridActionsCellItem
                  onPointerEnterCapture={false}
                  onPointerLeaveCapture={false}
                  placeholder={''}
                  icon={<DeleteIcon />}
                  label="Delete"
                  onClick={handleDeleteClick(params.id)}
                  color="inherit"
                />
              );
            }
          }
        }
        return actionButtons;
      },
    },
  ];

  const handleClose = () => {
    setDeleteModal(false);
  };

  const handleCloseForAdd = () => {
    setEditData(null);
    setAddModal(false);
  };

  const handleOk = () => {
    setDeleteModal(false);
    deleteData(selectedId);
  };

  const addShippingMaterial = useCallback(() => {
    setAddModal(true);
  }, []);

  const deleteData = async (id: any) => {
    const apiUrl = new URL(
      environment.api.baseUrl + 'DecPackShippingMaterial/' + id
    );
    try {
      const result: any = await http.delete(apiUrl.toString());
      if (result != null && result.data != null && result.data.success) {
        toast.success(i18nMessages.deletedSuccessfully);

        getShippingMaterial();
      } else {
        toast.error(i18nMessages.errorOccurred);
      }
    } catch (error: any) {}
  };

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    if (sortModel && sortModel.length) {
      updateGridData('sortBy', sortModel[0].field);
      updateGridData(
        'sortOrder',
        sortModel[0].sort === 'asc'
          ? OrderByDirection.Ascending
          : OrderByDirection.Descending
      );
    }
  }, []);

  const handleSaveUpload = async (files: any) => {
    if (files && files.length > 0) {
      setFiles(files);
      const apiUrl = new URL(
        environment.api.baseUrl +
          `DecPackShippingMaterial/UploadMaterialFile/${shippingOrderId}`
      );
      try {
        const data = new FormData();
        data.append('formFile', files[0]);
        const result = await http.post(apiUrl.toString(), data, {
          responseType: 'blob',
        });

        if (result.status === 208) {
          toast.warn(i18nMessages.notProcessed);
          const file = new Blob([result.data], { type: files[0].type }); //Build a URL from the file
          const fileURL = window.URL.createObjectURL(file); //Open the URL on new Window
          const link = document.createElement('a');
          link.href = fileURL;
          link.setAttribute('download', `${files[0].name}`);
          link.click();
          document.body.appendChild(link);
        }
        if (result.status === 200) {
          toast.success(i18nMessages.processedSuccessfully);
        }
        await getShippingMaterial();
        setFiles([]);
      } catch {
        toast.error(i18nMessages.invalidFileFormat);
      }
    }
  };

  const handleFileDelete = (file: any) => {
    var a = files.filter(function (x: any) {
      return x.lastModified !== file.lastModified;
    });
    setFiles(a);
  };

  const downloadSampleFile = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl + 'DecPackShippingMaterial/DownloadMaterialSample'
    );

    try {
      let result = await http.get(apiUrl.toString(), {
        responseType: 'blob',
      });
      if (result) {
        const file = new Blob([result.data], {
          type: 'application/vnd.ms-excel',
        });
        const fileURL = window.URL.createObjectURL(file); //Open the URL on new Window
        const link = document.createElement('a');
        link.href = fileURL;
        link.setAttribute('download', `MaterialSample.xls`);
        link.click();
        document.body.appendChild(link);
      }
    } catch {}
  };

  const mystyle = {
    justifyContent: 'right',
    display: 'flex',
    fontWeight: 'bold',
  };

  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    if (!params.row.isManual) {
      event.defaultMuiPrevented = true;
    }
  };

  interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (
      newModel: (oldModel: GridRowModesModel) => GridRowModesModel
    ) => void;
  }

  function EditToolbar(props: EditToolbarProps) {
    const handleClick = () => {
      var existingRow = gridData.rows.find((x: any) => x.id === 0);
      if (existingRow === undefined) {
        const id = 0;
        var temp: any = [];
        for (let i = 0; i < gridData.rows.length; i++) {
          temp.push(gridData.rows[i]);
        }
        temp.push({
          id: 0,
          materialTypeDescription: defaultValue,
          categoryDescription: defaultValue,
          itemCode: '',
          name: '',
          quantity: '',
          sellingPrice: '',
          note: '',
          isManual: true,
          isNew: true,
          transportTypeDescription: defaultValue,
        });
        updateGridData('rows', temp);
        updateGridData('totalRows', temp.length);
        setRowModesModel((oldModel) => ({
          ...oldModel,
          [id]: {
            mode: GridRowModes.Edit,
            fieldToFocus: 'materialTypeDescription',
          },
        }));
        setFocused(true);
      }
    };

    return (
      <GridToolbarContainer>
        {canAdd && (!isApproved || canApprove) ? (
          <Button
            color="primary"
            id="addRecord"
            startIcon={<AddIcon />}
            onClick={handleClick}
          >
            {I18n('Common.Add')}
          </Button>
        ) : (
          <></>
        )}
      </GridToolbarContainer>
    );
  }

  const showMessageRefresh = () => {
    toast.success(i18nMessages.savedSuccessfully);
    //getShippingMaterial();
  };

  const save = async (values: any) => {
    var validateMessage = '';
    validateMessage = validate(values);
    if (validateMessage === '') {
      let itemCodeId;
      if (values.id === 0) {
        var result = await loadItemValuesByid(values.itemCode.label);
        if (result) {
          itemCodeId = result.id;
        }
      }
      const shippingMaterialDTO = {
        Id: values.id,
        OrderId: +shippingOrderId,
        MaterialType:
          values.id === 0
            ? +values.materialTypeDescription.value
            : values.materialType,
        CategoryId:
          values.id === 0
            ? +values.categoryDescription.value
            : +values.categoryId,
        Quantity: +values.quantity,
        SellingPrice:
          values.id > 0 ? values.sellingPrice.toString().replace(/,/g, '.') : 0,
        Note: values.note,
        EntityId: values.id === 0 ? +itemCodeId : values.entityId,
        IsManual: true,
        ShippingPackageId: null,
        TransportType:
          values.id === 0
            ? +values.materialTypeDescription.value ===
              MasterCategoryEnumType.TransportCatalogue
              ? +values.transportTypeDescription.value
              : null
            : values.materialType === MasterCategoryEnumType.TransportCatalogue
            ? +values.transportType
            : null,
      };

      const apiUrl =
        values.id === 0
          ? new URL(environment.api.baseUrl + 'DecPackShippingMaterial')
          : new URL(
              environment.api.baseUrl + 'DecPackShippingMaterial/' + values.id
            );
      try {
        const result: any =
          values.id === 0
            ? await http.post(apiUrl.toString(), shippingMaterialDTO)
            : await http.put(apiUrl.toString(), shippingMaterialDTO);

        if (result !== null && result.data != null) {
          showMessageRefresh();
        } else {
          validateMessage = i18nMessages.errorOccurred;
        }
      } catch (error: any) {
        if (
          error.response?.status == 400 &&
          error.response?.data &&
          error.response?.data?.success == false &&
          error.response?.data?.message
        ) {
          validateMessage =
            error.response?.data?.message === 'IncorrectTransportDetail'
              ? i18nMessages.incorrectTransportDetail
              : i18nMessages.productQuantityNotAvailable;
        } else {
          validateMessage = i18nMessages.productQuantityNotAvailable;
        }
      }
    }
    return validateMessage;
  };

  const validate = (fieldValues: any) => {
    var isTransportType =
      (Number(fieldValues.materialType) ===
        MasterCategoryEnumType.TransportCatalogue &&
        'transportType' in fieldValues) ||
      Number(fieldValues.materialTypeDescription.value) ===
        MasterCategoryEnumType.TransportCatalogue;

    // if ('materialTypeDescription' in fieldValues) {
    //   if (fieldValues.materialTypeDescription.value === '') {
    //     return 'Type ' + i18nMessages.fieldRequired;
    //   }
    // }
    // if ('type' in fieldValues) {
    //   if (fieldValues.type.value === '') {
    //     return 'Type ' + i18nMessages.fieldRequired;
    //   }
    // }
    // if ('categoryDescription' in fieldValues) {
    //   if (fieldValues.categoryDescription.value === '') {
    //     return 'Category ' + i18nMessages.fieldRequired;
    //   }
    // }
    // if ('category' in fieldValues) {
    //   if (fieldValues.category.value === '') {
    //     return 'Category ' + i18nMessages.fieldRequired;
    //   }
    // }
    if ('itemCode' in fieldValues) {
      if (
        (!fieldValues.itemCode || fieldValues.itemCode.value === '') &&
        !isTransportType
      ) {
        return 'ItemCode ' + i18nMessages.fieldRequired;
      }
    }

    if (fieldValues.id !== 0 && 'sellingPrice' in fieldValues) {
      if (fieldValues.sellingPrice === '') {
        return 'SellingPrice ' + i18nMessages.fieldRequired;
      }
    }

    if ('transportType' in fieldValues) {
      if (
        Number(fieldValues.materialType) ===
          MasterCategoryEnumType.TransportCatalogue &&
        fieldValues.transportType === ''
      ) {
        return 'TransportType ' + i18nMessages.fieldRequired;
      }
    } else {
      if (
        Number(fieldValues.materialTypeDescription.value) ===
          MasterCategoryEnumType.TransportCatalogue &&
        fieldValues.transportTypeDescription.value === ''
      ) {
        return 'TransportType ' + i18nMessages.fieldRequired;
      }
    }

    // if ('quantity' in fieldValues) {
    //   if (fieldValues.quantity === '' && !isTransportType) {
    //     return 'Quantity ' + i18nMessages.fieldRequired;
    //   } else if (+fieldValues.quantity === 0 && !isTransportType) {
    //     return 'Quantity ' + i18nMessages.zeroNotAllowed;
    //   }
    // }

    return '';
  };

  const handleEditClick = (params: any) => async () => {
    setRowModesModel({
      ...rowModesModel,
      [params.id]: { mode: GridRowModes.Edit },
    });
    setQuantityFocus(true);
  };

  const handleSaveClick = (id: GridRowId) => () => {
    updateGridData(
      'rows',
      gridData.rows.filter(function (row: any) {
        return row.id !== 0;
      })
    );
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    deleteShippingMaterial(id);
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setFocused(false);
    setQuantityFocus(false);
    setFocusedAdd(false);
    setFocusedCat(false);
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow: any = gridData.rows.find((row: any) => row.id === id);
    if (editedRow!.isNew) {
      updateGridData(
        'rows',
        gridData.rows.filter((row: any) => row.id !== id)
      );
    }
    getShippingMaterial();
  };

  async function handleProcessRowUpdate(newRow: GridRowModel) {
    setFocused(false);
    setQuantityFocus(false);
    setFocusedAdd(false);
    setFocusedCat(false);
    let updatedRow: any = {};
    if (newRow.id === undefined) {
      updatedRow = { ...newRow, isNew: false, id: 0 };
    } else {
      updatedRow = { ...newRow, isNew: false };
    }
    var validation = await save(updatedRow);

    if (validation === '') {
      if (newRow.id === undefined) {
        var temp: any = [];
        for (var i = 0; i < gridData.rows.length; i++) {
          temp.push(gridData.rows[i]);
        }

        updatedRow.materialTypeDescription =
          updatedRow.materialTypeDescription.label;
        updatedRow.categoryDescription = updatedRow.categoryDescription.label;
        updatedRow.itemCode = updatedRow.itemCode.label;
        if (updatedRow.transportTypeDescription) {
          updatedRow.transportTypeDescription =
            updatedRow.transportTypeDescription.label;
        }
        temp.push(updatedRow);
        updateGridData('rows', temp);
        updateGridData(
          'searchExpression',
          (Math.random() + 1).toString(36).substring(7)
        );
        return updatedRow;
      } else {
        updateGridData(
          'rows',
          gridData.rows.map((row: any) =>
            row.id === newRow.id ? updatedRow : row
          )
        );
        updateGridData(
          'searchExpression',
          (Math.random() + 1).toString(36).substring(7)
        );
        return updatedRow;
      }
    } else {
      if (updatedRow.id === 0) {
        var temp1: any = [];
        for (let i = 0; i < gridData.rows.length; i++) {
          temp1.push(gridData.rows[i]);
        }

        var existingRecord = {
          ...updatedRow,
          isNew: true,
          isManual: true,
        };

        updatedRow.materialTypeDescription =
          updatedRow.materialTypeDescription.label;
        updatedRow.categoryDescription = updatedRow.categoryDescription.label;
        updatedRow.itemCode = updatedRow.itemCode.label;
        if (updatedRow.transportTypeDescription) {
          updatedRow.transportTypeDescription =
            updatedRow.transportTypeDescription.label;
        }
        updatedRow.isNew = true;
        updatedRow.isManual = true;
        temp1.push(updatedRow);
        updateGridData('rows', temp1);
        updateGridData('totalRows', temp1.length);
        setTimeout(() => {
          var oldData: any = [];
          for (var i = 0; i < temp1.length; i++) {
            if (temp1[i].id == 0) {
              oldData.push(existingRecord);
            } else {
              oldData.push(temp1[i]);
            }
          }
          updateGridData('rows', oldData);

          setTimeout(() => {
            setRowModesModel({
              ...rowModesModel,
              0: {
                mode: GridRowModes.Edit,
                fieldToFocus: 'materialTypeDescription',
              },
            });
          }, 200);
        }, 200);
      } else {
        setTimeout(() => {
          setRowModesModel({
            ...rowModesModel,
            [updatedRow.id]: {
              mode: GridRowModes.Edit,
              fieldToFocus: 'materialTypeDescription',
            },
          });
        }, 200);
      }
      toast.error(validation);
      return updatedRow;
    }
  }

  const handleProcessRowUpdateError = (error: Error) => {
    // toast.error(error.message);
    // if (currentRow && currentRow.id == 0) {
    //   var temp: any = [];
    //   for (let i = 0; i < gridData.rows.length; i++) {
    //     temp.push(gridData.rows[i]);
    //   }
    //   temp.push(currentRow);
    //   updateGridData('rows', temp);
    //   updateGridData('totalRows', temp.length);
    //   setRowModesModel((oldModel) => ({
    //     ...oldModel,
    //     [0]: {
    //       mode: GridRowModes.Edit,
    //       fieldToFocus: 'materialTypeDescription',
    //     },
    //   }));
    // }
  };

  const setModels = (newModel: any) => {
    if (newModel[0] === undefined) {
      setRowModesModel(newModel);
    } else if (
      newModel[0] !== undefined &&
      newModel[0].cellToFocusAfter === 'right'
    ) {
      updateGridData(
        'rows',
        gridData.rows.filter(function (row: any) {
          return row.id !== 0;
        })
      );
      setRowModesModel(newModel);
    }
  };
  const keydown = (event: any) => {
    if (event.altKey && event.keyCode == 65) {
      var addButton = document.getElementById('addRecord');
      addButton?.click();
    }
  };

  const NotesData = () => {
    return (
      <>
        <Grid item lg={12} md={12}>
          <CustomizedInputs
            placeholder={defaultValue.label}
            displayLabel={i18nMessages.Notes}
            handleChange={(newValue: any) => {
              setNotes(newValue.target.value);
            }}
            value={notes}
            readOnly={!canEdit}
          ></CustomizedInputs>
        </Grid>
      </>
    );
  };

  useEffect(() => {
    document.addEventListener('keydown', keydown, false);
    getShippingMaterial();
    getDecPackByDetailsId();
  }, [
    gridData.page,
    gridData.pageSize,
    gridData.sortBy,
    gridData.sortOrder,
    gridData.searchExpression,
    props.shippingOrderId,
    approvedBOM,
  ]);

  const saveNotes = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl + 'DecPackOrder/UpdateBOMNotes'
    );
    try {
      var value = {
        orderId: props.shippingOrderId,
        BomNote: notes,
      };
      await http.put(apiUrl.toString(), value);
      toast.success(i18nMessages.savedSuccessfully);
    } catch {}
  };

  return (
    <div>
      {(!isApproved || canApprove) && canAdd && showFileUpload ? (
        <FileUpload
          filesLimit={1}
          initialFiles={files}
          onSave={handleSaveUpload}
          showPreviews={false}
          maxFileSize={10485760}
          acceptedFiles={[
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          ]}
          onDelete={handleFileDelete}
        />
      ) : (
        <></>
      )}
      {/* <Typography variant="h5" className="inner-heading has-button">
        {I18n('DecPackOrders.Tabs.BillableItems')}
        <div className="inner-btn-wrap">
          {!isApproved && canEdit ? (
            <Button
              color="primary"
              className="small-btn"
              disableElevation
              onClick={addShippingMaterial}
              variant="contained"
            >
              <FontAwesomeIcon icon={faPlus} className="fa-md" />
            </Button>
          ) : (
            <></>
          )}
        </div>
      </Typography> */}
      <Typography variant="h5" className="inner-heading has-button">
        {I18n('DecPackOrders.Tabs.BillableItems')}
        {!isApproved && canAdd && showFileUpload ? (
          <div className="inner-btn-wrap">
            <Tooltip
              title={I18n('DecPackOrders.ToolTip.DownloadSampleFile')}
              arrow
            >
              <Button
                color="secondary"
                className="small-btn"
                disableElevation
                onClick={downloadSampleFile}
                variant="contained"
                sx={{
                  right: 8,
                }}
              >
                <FontAwesomeIcon icon={faFileExcel} className="fa-md" />
              </Button>
            </Tooltip>
          </div>
        ) : (
          <></>
        )}
      </Typography>
      <div className="">
        <div style={mystyle}>
          Total Selling Price
          {customerCurrency ? ' (' + customerCurrency + ')' : ''}:{' '}
          {totalSellingPrice
            ? totalSellingPrice.toString().replaceAll('.', ',')
            : totalSellingPrice}
        </div>
        <div className="content-block table-fix">
          <CustomDataGridInline
            rowCount={gridData.totalRows}
            data={gridData.rows}
            columns={columns}
            onSortModelChange={handleSortModelChange}
            pageNo={gridData.page}
            pageSize={gridData.pageSize}
            rowModesModel={rowModesModel}
            onRowModesModelChange={(newModel: any) => {
              setModels(newModel);
            }}
            onPageChange={handleChangePage}
            onPageSizeChange={handleChangePageSize}
            onRowEditStart={handleRowEditStart}
            rowsPerPageOptions={gridData.rowsPerPageOptions}
            initialState={{
              sorting: {
                sortModel: [{ field: 'updatedDate', sort: 'desc' }],
              } as GridSortingInitialState,
              pagination: {
                page: gridData.page,
                pageSize: gridData.pageSize,
              } as GridPaginationInitialState,
            }}
            components={{
              Toolbar: EditToolbar,
            }}
            componentsProps={{
              toolbar: { setRows, setRowModesModel },
            }}
            processRowUpdate={handleProcessRowUpdate}
            onProcessRowUpdateError={handleProcessRowUpdateError}
          ></CustomDataGridInline>

          {!isOffer && !isFromWO ? (
            <div className="mt-5">
              <CreditNote
                canEdit={canEdit}
                canAdd={canAdd}
                canDelete={canDelete}
                paramsId={shippingOrderId ? shippingOrderId : 0}
                entityType={EntityTypeEnum.DecPackOrders}
              />
            </div>
          ) : (
            <></>
          )}

          {!isFromWO ? (
            <Grid item lg={6} md={6}>
              <FormAccordianSave
                title={i18nMessages.Notes}
                details={NotesData()}
                showActionButton={!canEdit}
                className="inner-heading"
              ></FormAccordianSave>
            </Grid>
          ) : (
            <></>
          )}
          <Grid item lg={11} md={11}></Grid>
          <Grid item lg={1} md={1}>
            {!isAddShippingMaterial && canEdit && !isFromWO ? (
              <Button
                color="primary"
                className="small-btn"
                style={{ float: 'right' }}
                disableElevation
                variant="contained"
                onClick={saveNotes}
                sx={{
                  right: 8,
                }}
              >
                {i18nMessages.save}
              </Button>
            ) : (
              <></>
            )}
          </Grid>

          <ConfirmationPopup
            isOpen={isDeleteModalOpen}
            data={selectedId}
            message={I18n('ShippingMaterials.DeleteMessage')}
            handleClose={handleClose}
            handleOk={handleOk}
            title={I18n('ShippingMaterials.DeleteTitle')}
          ></ConfirmationPopup>

          <AddDecPackShippingMaterial
            isOpen={isAddModalOpen}
            editData={editData}
            isAddShippingMaterial={isAddShippingMaterial}
            shippingOrderId={shippingOrderId}
            handleClose={handleCloseForAdd}
            title={I18n('ShippingMaterials.AddContactPerson')}
            refreshData={refreshData}
          ></AddDecPackShippingMaterial>

          <DecPackMaterialQuantityDetailsPopup
            isOpen={isOpen}
            handleClose={handleCloseQuantityDetails}
            title={i18nMessages.QuantityDetails}
            wareHouseQuantity={wareHouseQuantity}
            requestedQuantity={requestedQuantity}
            threshold={threshold}
          ></DecPackMaterialQuantityDetailsPopup>
        </div>
      </div>
    </div>
  );
}

export default DecPackShippingMaterial;
