import React, { useEffect, useState } from 'react';

import { Button, CssBaseline, Box, Grid, Typography, Tab } from '@mui/material';
import http from '../../utilities/httpService';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CustomizedInputs from 'common/formControl/formControl';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import { ReactComponent as LeftImage } from '../../assets/images/login-img.svg';
import BgImage from '../../assets/images/login-bg.png';
import auth from '../../auth/authentication';
import '../login/login.scss';
import RouteEnum from 'models/RouteEnum';
import environment from 'environment';
import { toast } from 'react-toastify';
import I18n from 'utilities/i18n';
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

function ForgotPassword(props: any) {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  let navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const currentUser = await auth.getCurrentUser();
      if (currentUser) {
        window.location.href = RouteEnum.Root;
      }
    })();
  }, []);

  const [value, setValue] = React.useState('1');

  const handleEmailChange = (email: string) => {
    setEmail(email);
  };

  const theme = createTheme({
    typography: {
      fontFamily: 'Sofia Pro',
    },
  });

  const i18nMessages = {
    errorOccurred: I18n('Auth.ErrorOccurred'),
    userNotExist: I18n('Auth.UserNotExist'),
  };

  const onSendLink = async (event: any) => {
    event.preventDefault();
    const apiUrl = new URL(
      environment.api.baseUrl + 'Authenticate/ForgotPassword?email=' + email
    );
    try {
      const result = await http.post(apiUrl.toString());
      if (result) {
        localStorage.setItem('linkSent', 'true');
        window.location.href = RouteEnum.Login;
      }
    } catch (err) {
      const error: any = err;
      toast.error(i18nMessages.userNotExist);
    }
  };

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Grid
          container
          component="main"
          sx={{
            px: 3,
            py: 3,
            minHeight: '100vh',
            backgroundImage: `url(${BgImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
          alignItems="center"
          justifyContent="center"
        >
          <CssBaseline />
          <Grid
            item
            sm={12}
            md={6}
            textAlign="center"
            className="left-image-outer"
          >
            <LeftImage></LeftImage>
          </Grid>
          <Grid item sm={12} md={6}>
            <div className="login-form-wrap">
              <Typography variant="h1" sx={{ mb: 3 }} className="primarytxt">
                {I18n('Auth.LoginToMyHazgo')}
              </Typography>
              <TabContext value={value}>
                <Box>
                  <TabList aria-label="lab API tabs example">
                    <Tab label={I18n('Auth.ResetPassword')} value="1" />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <form onSubmit={onSendLink}>
                    <CustomizedInputs
                      margin="normal"
                      displayLabel={I18n('Auth.Email')}
                      required
                      id="email"
                      inputType="email"
                      handleChange={(event: any) =>
                        handleEmailChange(event.target.value)
                      }
                    />
                    <Button
                      color="primary"
                      disableElevation
                      variant="contained"
                      type="submit"
                      fullWidth
                      sx={{ mt: 3, mb: 2 }}
                    >
                      {I18n('Auth.SendResetLink')}
                    </Button>
                  </form>
                  <Link to={RouteEnum.Login}>{I18n('Auth.BackToLogin')}</Link>
                </TabPanel>
              </TabContext>
            </div>
          </Grid>
        </Grid>
      </ThemeProvider>
    </div>
  );
}

export default ForgotPassword;
