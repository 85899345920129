export interface Location {
  id: number;
  country: string;
  city: string;
  zipCode: string;
  address: string;
  address2: string;
  street: string;
  destinationCompany: string;
  pickupCompany: string;
  isMainAddress: boolean;
  isFromDetailScreen: boolean;
  entityType: number;
}

export enum ZipCodeTriggeredFrom {
  CustomerDetails = 1,
  CustomerInvoice = 2,
  PartnerDetails = 3,
  PartnerInvoice = 4,
  Shipper = 5,
  Consignee = 6,
}
