import React, { useState } from 'react';
import http from '../../utilities/httpService';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import RouteEnum from 'models/RouteEnum';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import environment from 'environment';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import I18n from 'utilities/i18n';
import {
  BaseModel,
  EntityStatusDescription,
  MasterCategoryEnumType,
  PaginationResponseModel,
} from 'models/pagination-model';
import { Customer } from 'models/service-catalogue';
import CustomizedSelect from 'common/CustomSelect/CustomSelect';
import Link from '@mui/material/Link';
import CustomSelectPaginate from 'common/CustomSelect/CustomSelectPaginate';
import FormAccordian from 'common/formControl/formAccordian';
import CustomizedInputs from 'common/formControl/formControl';
import FormControl from 'common/formControl/formControl';
import CustomSelectPaginateAdd from 'common/CustomSelect/CustomSelectPaginateAdd';
import {
  PostCatagoryDropDown,
  loadItemGroupOptions,
  loadLedgerOptions,
} from 'common/DropDownAddAPI/DropDownApiService';

function TransportCatalougeAdd(props: any) {
  const navigate = useNavigate();
  const handleListClick = () => {
    navigate(RouteEnum.Transport);
  };
  const [cacheUniq, setCacheUniq] = useState(0);
  const breadcrumbs = [
    <Typography key="3" color="text.primary">
      {I18n('Nav.Configuration')}
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={handleListClick}
        className="cpointer"
      >
        {I18n('Nav.TransportCatalogue')}
      </Link>
    </Typography>,
    <Typography key="3" color="text.primary">
      {I18n('TransportCatalogue.AddTransportCatalogue')}
    </Typography>,
  ];

  const [errors, setErrors] = useState({
    transportCode: '',
    category: '',
    transportType: '',
    fromZone: '',
    toZone: '',
    status: '',
    ledgerAccount: '',
    itemGroup: '',
  });
  const defaultCategoryValue = {
    value: '0',
    label: I18n('Placeholders.SelectCategory'),
  } as BaseModel;

  const defaultZoneValue = {
    value: '0',
    label: I18n('Placeholders.SelectZone'),
  } as BaseModel;

  const defaultDropDownValue = {
    value: '0',
    label: I18n('Placeholders.Select'),
  } as BaseModel;

  const defaultTransportValue = {
    value: '0',
    label: '',
  } as BaseModel;

  const [values, setValues] = useState<any>({
    transportCode: '',
    category: defaultCategoryValue,
    toZone: defaultZoneValue,
    fromZone: defaultZoneValue,
    transportType: [defaultTransportValue],
    status: 0,
    ledgerAccount: defaultDropDownValue,
    itemGroup: defaultDropDownValue,
  });

  const [countries, setCountries] = useState('');
  const [countriesExpanded, setCountriesExpanded] = useState<boolean>(true);
  const [isFurtherEnable, setIsFurtherEnable] = useState(false);
  const defaultValue = {
    value: '0',
    label: I18n('Placeholders.SelectStatus'),
  } as BaseModel;

  const i18nMessages = {
    TransportCatalogueToZoneCountry: I18n('TransportCatalogue.ToZoneCounries'),
    addTransportCatalougeSuccess: I18n('TransportCatalogue.AddSuccess'),
    commonFieldIsRequired: I18n('Common.FieldIsRequired'),
    TransportCatalogueCountries: I18n('TransportCatalogue.Countries'),
    bothZoneShouldNotBeSame: I18n('TransportCatalogue.BothZoneSame'),
    uniqueTransportCode: I18n('TransportCatalogue.UniqueTransportCode'),
    uniqueZoneCombination: I18n('TransportCatalogue.UniqueZoneCombination'),
    CommonSaveSuccessMsg: I18n('Common.SavedSuccessfully'),
    CommonErrOccuredMsg: I18n('Common.ErrorOccurred'),
    LedgerAccountDescription: I18n('LedgerInfo.LedgerAccountDescription'),
    LedgerAccount: I18n('LedgerInfo.LedgerAccount'),
    ItemGroup: I18n('ItemGroupInfo.ItemGroup'),
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (validate()) {
      const apiUrl = new URL(environment.api.baseUrl + 'TransportCatalogues');
      try {
        let transportValues = values.transportType.map((x: any) => {
          return {
            name: x.label,
            id: Number(x.value),
          };
        });

        const finalValue = {
          transportCode: values.transportCode,
          categoryId: Number(values.category.value),
          fromZoneId: Number(values.fromZone.value),
          toZoneId: Number(values.toZone.value),
          transportTypes: transportValues,
          statusId: values.status,
          ledgerAccountId: +values.ledgerAccount.value,
          itemGroupId: +values.itemGroup.value,
        };
        const result: any = await http.post(apiUrl.toString(), finalValue);
        if (result) {
          toast.success(i18nMessages.addTransportCatalougeSuccess);
          navigate(RouteEnum.Transport);
        }
      } catch (error: any) {
        if (error.response) {
          let temp = { ...errors };
          if (error.response.data) {
            for (const item of error.response.data.errors) {
              if (
                item.field === 'TransportCode' &&
                item.errorMessage === 'UniqueTransportCode'
              ) {
                temp.transportCode = i18nMessages.uniqueTransportCode;
              } else if (
                item.field === 'FromZoneId' &&
                item.errorMessage === 'UniqueZoneCombination'
              ) {
                temp.fromZone = i18nMessages.uniqueZoneCombination;
                temp.toZone = i18nMessages.uniqueZoneCombination;
              }
            }
          }
          setErrors({
            ...temp,
          });
        }
      }
    }
  };
  const onCreateOption = async (inputValue: any) => {
    let name = {};
    name['name'] = inputValue;
    name['entityType'] = MasterCategoryEnumType.TransportCatalogue;
    const newOption: any = await PostCatagoryDropDown(
      name,
      i18nMessages.CommonSaveSuccessMsg,
      i18nMessages.CommonErrOccuredMsg
    );
    const increaseUniq = (uniq: any) => uniq + 1;
    setCacheUniq(increaseUniq);
    setValues({ ...values, category: newOption });
    getOrderCategories('', 1);
  };
  const onStatusChange = (data: any) => {
    setValues({ ...values, status: Number(data?.value) });
  };

  const getOrderCategories = async (search: string, pageNo: number) => {
    let response: PaginationResponseModel<Customer>;
    const apiUrl = new URL(
      environment.api.baseUrl + 'Dropdown/GetMasterCategories'
    );
    try {
      let searchExpression = `entityType~=~${MasterCategoryEnumType.TransportCatalogue}`;
      searchExpression += search ? ` and name~like~${search}` : '';
      apiUrl.searchParams.set('$filter', searchExpression);
      apiUrl.searchParams.set('$orderby', 'name');
      apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
      apiUrl.searchParams.set('$top', '10');
      apiUrl.searchParams.set('$orderbydirection', 'desc');
      const result = await http.get(apiUrl.toString());
      if (result) {
        response = {
          TotalRecords: result.data.totalCount,
          Values: result.data.values,
        } as PaginationResponseModel<Customer>;
      } else {
        response = {
          TotalRecords: 0,
          Values: [],
        } as PaginationResponseModel<Customer>;
      }
    } catch (error) {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<Customer>;
    }
    return response;
  };

  const loadCategoryOptions = async (
    search: string,
    loadedOptions: any,
    { page }: any
  ) => {
    const response = await getOrderCategories(search, page);
    const hasMore = Math.ceil(response.TotalRecords / 10) > page;

    return {
      options: response.Values.map((x) => {
        return {
          value: x.id.toString(),
          label: x.name,
        } as BaseModel;
      }),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const getZones = async (search: string, pageNo: number) => {
    let response: PaginationResponseModel<Customer>;
    const apiUrl = new URL(environment.api.baseUrl + 'Zone');
    try {
      let searchExpression = '';
      searchExpression += search ? ` and name~like~${search}` : '';
      apiUrl.searchParams.set('$filter', searchExpression);
      apiUrl.searchParams.set('$orderby', 'name');
      apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
      apiUrl.searchParams.set('$top', '10');
      apiUrl.searchParams.set('$orderbydirection', 'desc');
      const result = await http.get(apiUrl.toString());
      if (result) {
        response = {
          TotalRecords: result.data.totalCount,
          Values: result.data.values,
        } as PaginationResponseModel<Customer>;
      } else {
        response = {
          TotalRecords: 0,
          Values: [],
        } as PaginationResponseModel<Customer>;
      }
    } catch (error) {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<Customer>;
    }
    return response;
  };

  const loadZoneOptions = async (
    search: string,
    loadedOptions: any,
    { page }: any
  ) => {
    const response = await getZones(search, page);
    const hasMore = Math.ceil(response.TotalRecords / 10) > page;

    return {
      options: response.Values.map((x) => {
        return {
          value: x.id.toString(),
          label: x.name,
        } as BaseModel;
      }),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const getTransportTypes = async (search: string, pageNo: number) => {
    let response: PaginationResponseModel<Customer>;
    const apiUrl = new URL(environment.api.baseUrl + 'TransportTypes');
    try {
      let searchExpression = '';
      searchExpression += search ? `name~like~${search}` : '';
      apiUrl.searchParams.set('$filter', searchExpression);
      apiUrl.searchParams.set('$orderby', 'name');
      apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
      apiUrl.searchParams.set('$top', '10');
      apiUrl.searchParams.set('$orderbydirection', 'desc');
      const result = await http.get(apiUrl.toString());
      if (result) {
        response = {
          TotalRecords: result.data.totalCount,
          Values: result.data.values,
        } as PaginationResponseModel<Customer>;
      } else {
        response = {
          TotalRecords: 0,
          Values: [],
        } as PaginationResponseModel<Customer>;
      }
    } catch (error) {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<Customer>;
    }
    return response;
  };

  const loadTransportTypeOptions = async (
    search: string,
    loadedOptions: any,
    { page }: any
  ) => {
    const response = await getTransportTypes(search, page);
    const hasMore = Math.ceil(response.TotalRecords / 10) > page;

    return {
      options: response.Values.map((x) => {
        return {
          value: x.id.toString(),
          label: x.name,
        } as BaseModel;
      }),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const validate = (fieldValues: any = values) => {
    let temp = { ...errors };

    if ('transportCode' in fieldValues) {
      temp.transportCode = fieldValues.transportCode
        ? ''
        : i18nMessages.commonFieldIsRequired;
    }

    if ('fromZone' in fieldValues) {
      temp.fromZone =
        fieldValues.fromZone.value === '0'
          ? i18nMessages.commonFieldIsRequired
          : '';
    }

    if ('toZone' in fieldValues) {
      if (fieldValues.toZone.value === '0') {
        temp.toZone = i18nMessages.commonFieldIsRequired;
      } else if (fieldValues.toZone.value === fieldValues.fromZone.value) {
        temp.toZone = i18nMessages.bothZoneShouldNotBeSame;
        temp.fromZone = i18nMessages.bothZoneShouldNotBeSame;
      } else {
        temp.toZone = '';
      }
    }

    if ('category' in fieldValues) {
      temp.category =
        fieldValues.category.value === '0'
          ? i18nMessages.commonFieldIsRequired
          : '';
    }

    if ('status' in fieldValues) {
      temp.status = fieldValues.status
        ? ''
        : i18nMessages.commonFieldIsRequired;
    }

    if ('transportType' in fieldValues) {
      temp.transportType = fieldValues.transportType.find(
        (o: any) => o.value === '0'
      )
        ? i18nMessages.commonFieldIsRequired
        : '';
    }

    if (
      'ledgerAccount' in fieldValues &&
      values.ledgerAccount.value == defaultDropDownValue.value
    ) {
      temp.ledgerAccount = i18nMessages.commonFieldIsRequired;
    } else {
      temp.ledgerAccount = '';
    }

    if (
      'itemGroup' in fieldValues &&
      values.itemGroup.value == defaultDropDownValue.value
    ) {
      temp.itemGroup = i18nMessages.commonFieldIsRequired;
    } else {
      temp.itemGroup = '';
    }

    setErrors({
      ...temp,
    });
    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === '');
    }
  };

  let entityStatusList: Array<BaseModel> = [];
  const getStatusList = () => {
    entityStatusList = [defaultValue];
    EntityStatusDescription.forEach((value: string, key: number) => {
      entityStatusList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getStatusList();

  const defaultAdditional: any = {
    page: 1,
  };

  const getSpecificZone = async (zoneId: any[]) => {
    const apiUrl = new URL(environment.api.baseUrl + 'Zone/' + zoneId);
    try {
      const result = await http.get(apiUrl.toString());
      if (result) {
        return result.data;
      }
    } catch {}
  };

  const getZoneDetails = async (newValue: any) => {
    setIsFurtherEnable(true);
    const specificZone = await getSpecificZone(newValue.value);
    const ctrys = specificZone.selectedCountries.map((item: any) => ({
      id: item.countryId,
      name: item.countryName,
    }));
    setCountries(ctrys);
  };

  const handleCountriesExpandCollapseClick = (event: React.SyntheticEvent) => {
    setCountriesExpanded(!countriesExpanded);
  };

  const ToZoneCountriesData = (countries: any) => {
    return (
      <>
        {countries &&
          countries.map((element: any, index: any) => (
            <Grid item={true} lg={2} md={2} xs={2} key={`grid${index}`}>
              <CustomizedInputs
                margin="normal"
                name="country"
                id="country"
                inputType="text"
                value={element.name}
                disabled
                inputProps={{ maxLength: 250 }}
              />
            </Grid>
          ))}
      </>
    );
  };

  const onValueChange = (event: any) => {
    const { name, value } = event.target;

    validate({ [name]: value });
    setValues({
      ...values,
      [name.toString()]: value,
    });
  };

  return (
    <div>
      <div className="heading-section">
        <div className="heading-section-left">
          <Typography variant="h3" sx={{ mb: 3 }}>
            {I18n('TransportCatalogue.AddTransportCatalogue')}
          </Typography>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>
      </div>
      <div className="main-content-section">
        <form className="model-content form-content" onSubmit={handleSubmit}>
          <div className="inner-section">
            <Typography variant="h5" className="inner-heading">
              {I18n('TransportCatalogue.AddTransportCatalogue')}
            </Typography>
            <Grid container spacing={2} className="grid-wrap">
              <Grid item lg={4} md={8}>
                <FormControl
                  required
                  margin="normal"
                  displayLabel={I18n('TransportCatalogue.TransCode')}
                  id="TransportCode"
                  name="transportCode"
                  inputProps={{ maxLength: 10 }}
                  multiline={false}
                  handleChange={onValueChange}
                  errorValue={true}
                  errorMessage={errors.transportCode}
                ></FormControl>
              </Grid>
              <Grid item lg={4} md={8}>
                <CustomSelectPaginateAdd
                  required
                  key={JSON.stringify(values.category)}
                  additional={defaultAdditional}
                  value={values.category}
                  loadOptions={loadCategoryOptions}
                  isSearchable={true}
                  displayLabel={I18n('TransportCatalogue.Category')}
                  placeholder={defaultCategoryValue.label}
                  handleChange={(newValue: any) => {
                    setValues({ ...values, category: newValue });
                    if (newValue !== '') {
                      setErrors({
                        ...errors,
                        category: '',
                      });
                    }
                  }}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                  }}
                  debounceTimeout={500}
                  isMultiSelect={false}
                  cacheUniqs={cacheUniq}
                  onCreateOption={onCreateOption}
                  errorValue={true}
                  errorMessage={errors.category}
                  readOnly={false}
                ></CustomSelectPaginateAdd>
              </Grid>
              <Grid item lg={4} md={8}>
                <CustomSelectPaginate
                  required
                  additional={defaultAdditional}
                  value={values.fromZone}
                  loadOptions={loadZoneOptions}
                  handleChange={(newValue: any) => {
                    setValues({ ...values, fromZone: newValue });
                    if (newValue !== '') {
                      setErrors({
                        ...errors,
                        fromZone: '',
                      });
                    }
                  }}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                  }}
                  placeholder={defaultZoneValue.label}
                  isSearchable={true}
                  displayLabel={I18n('TransportCatalogue.FromZone')}
                  errorValue={true}
                  errorMessage={errors.fromZone}
                  readOnly={false}
                  debounceTimeout={500}
                  isMultiSelect={false}
                />
              </Grid>
              <Grid item lg={4} md={8}>
                <CustomSelectPaginate
                  required
                  additional={defaultAdditional}
                  value={values.toZone}
                  loadOptions={loadZoneOptions}
                  handleChange={(newValue: any) => {
                    setValues({ ...values, toZone: newValue });
                    getZoneDetails(newValue);
                    if (newValue !== '') {
                      setErrors({
                        ...errors,
                        toZone: '',
                      });
                    }
                  }}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                  }}
                  placeholder={defaultZoneValue.label}
                  isSearchable={true}
                  displayLabel={I18n('TransportCatalogue.ToZone')}
                  errorValue={true}
                  errorMessage={errors.toZone}
                  readOnly={false}
                  debounceTimeout={500}
                  isMultiSelect={false}
                />
              </Grid>
              <Grid item lg={4} md={8}>
                <CustomSelectPaginate
                  required
                  additional={defaultAdditional}
                  loadOptions={loadTransportTypeOptions}
                  handleChange={(newValue: any) => {
                    setValues({ ...values, transportType: newValue });
                    if (newValue !== '') {
                      setErrors({
                        ...errors,
                        transportType: '',
                      });
                    }
                  }}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                  }}
                  id="transportType"
                  isSearchable={true}
                  displayLabel={I18n('TransportCatalogue.TransportType')}
                  errorValue={true}
                  errorMessage={errors.transportType}
                  debounceTimeout={500}
                  isMultiSelect={true}
                />
              </Grid>
              <Grid item lg={4} md={8}>
                <CustomizedSelect
                  required
                  placeholder={defaultValue.label}
                  options={entityStatusList}
                  isSearchable={false}
                  displayLabel={I18n('Common.Status')}
                  handleChange={(newValue: BaseModel) => {
                    onStatusChange(newValue);
                    if (newValue.value !== '0') {
                      setErrors({
                        ...errors,
                        status: '',
                      });
                    }
                  }}
                  errorValue={true}
                  errorMessage={errors.status}
                ></CustomizedSelect>
              </Grid>
              <Grid item lg={4} md={6} sm={12}>
                <CustomSelectPaginate
                  required
                  readOnly={false}
                  key={values.ledgerAccount.value}
                  additional={defaultAdditional}
                  value={values.ledgerAccount}
                  loadOptions={(search: string, prevOptions: any, page: any) =>
                    loadLedgerOptions(search, prevOptions, page)
                  }
                  handleChange={async (newValue: any) => {
                    setValues({
                      ...values,
                      ledgerAccount: newValue,
                    });
                  }}
                  placeholder={defaultValue.label}
                  isSearchable={true}
                  displayLabel={i18nMessages.LedgerAccount}
                  errorValue={true}
                  errorMessage={errors.ledgerAccount}
                  debounceTimeout={500}
                  isMultiSelect={false}
                />
              </Grid>
              <Grid item lg={4} md={6} sm={12}>
                <CustomSelectPaginate
                  required
                  readOnly={false}
                  key={values.itemGroup.value}
                  additional={defaultAdditional}
                  value={values.itemGroup}
                  loadOptions={(search: string, prevOptions: any, page: any) =>
                    loadItemGroupOptions(search, prevOptions, page)
                  }
                  handleChange={async (newValue: any) => {
                    setValues({
                      ...values,
                      itemGroup: newValue,
                    });
                  }}
                  placeholder={defaultValue.label}
                  isSearchable={true}
                  displayLabel={i18nMessages.ItemGroup}
                  errorValue={true}
                  errorMessage={errors.itemGroup}
                  debounceTimeout={500}
                  isMultiSelect={false}
                />
              </Grid>
              <Grid item lg={12} md={12}>
                {isFurtherEnable && (
                  <>
                    <FormAccordian
                      title={i18nMessages.TransportCatalogueToZoneCountry}
                      className="inner-heading"
                      details={ToZoneCountriesData(countries)}
                      expanded={countriesExpanded}
                      handleExpandCollapseClick={
                        handleCountriesExpandCollapseClick
                      }
                      showActionButton={false}
                    ></FormAccordian>
                  </>
                )}
              </Grid>
            </Grid>
          </div>

          <div className="btn-wrap form-btn-wrap">
            <Button
              color="neutral"
              disableElevation
              variant="contained"
              onClick={() => navigate(RouteEnum.Transport)}
            >
              {I18n('UsersAdd.Cancel')}
            </Button>
            <Button
              type="submit"
              color="primary"
              disableElevation
              variant="contained"
            >
              {I18n('UsersAdd.Add')}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default TransportCatalougeAdd;
