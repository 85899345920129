import React, { useCallback } from 'react';
import CustomDataGrid from '../../common/datagrid/datagrid';
import EditIcon from '@mui/icons-material/Edit';
import { useEffect, useState } from 'react';
import RouteEnum from '../../models/RouteEnum';
import I18n from '../../utilities/i18n';
import {
  GridActionsCellItem,
  GridColumns,
  GridEventListener,
  GridRowParams,
} from '@mui/x-data-grid';
import http from '../../utilities/httpService';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import {
  WorkOrderStatus,
  ActivityOwner,
  BaseModel,
} from '../../models/pagination-model';
import environment from 'environment';
import {
  DnPWorkOrders,
  DnPWorkOrdersTypeDescription,
} from 'models/deck-pack-orders';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClock,
  faPause,
  faCommentDots,
} from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from '@mui/material';
import { CheckOrderEnable, getDecPackById } from './DeckPackApiService';

function DeckPackWorkOrderList(props: any) {
  const { isOrderEnable, handleOrderStatus } = props;
  const { id } = useParams();
  const navigate = useNavigate();
  const [nextWO, setNextWO] = useState('');
  const [gridData, setGridData] = useState({
    isLoading: true,
    rows: [],
    totalRows: 0,
    pageSize: 10,
    rowsPerPageOptions: [10, 20, 50, 100],
    page: 0,
  });
  const i18nMessages = {
    DGDCreation: I18n('WorkOrder.DGDCreation'),
    SecurityCheckRA: I18n('WorkOrder.SecurityCheckRA'),
    Inboundprocess: I18n('WorkOrder.Inboundprocess'),
    Collectingshipmentdata: I18n('WorkOrder.Collectingshipmentdata'),
    CheckDocumentation: I18n('WorkOrder.CheckDocumentation'),
    Material: I18n('WorkOrder.Material'),

    Executionorder: I18n('WorkOrder.Executionorder'),
    Deliveryorderassigning: I18n('WorkOrder.Deliveryorderassigning'),
    Deliveryorder: I18n('WorkOrder.Deliveryorder'),
    Irregularitycontactclient: I18n('WorkOrder.Irregularitycontactclient'),
    BillofMaterial: I18n('WorkOrder.BillofMaterial'),
    PhotoCheck: I18n('WorkOrder.PhotoCheck'),

    Outboundprocess: I18n('WorkOrder.Outboundprocess'),
    ApprovalBillofMaterial: I18n('WorkOrder.ApprovalBillofMaterial'),
    InputmissingCustomerreference: I18n(
      'WorkOrder.InputmissingCustomerreference'
    ),
    ApprovalInvoice: I18n('WorkOrder.ApprovalInvoice'),
    Attention: I18n('DecPackOrders.Table.Attention'),
    AwaitingAssignment: I18n('Common.AwaitingAssignment'),
    TODO: I18n('Common.ToDo'),
    Inprogress: I18n('Common.InProgress'),
    Done: I18n('Common.Done'),
    AwaitingInfo: I18n('Common.Attentions.AwaitingInfo'),
    AwaitingInstructionFromClient: I18n(
      'Common.Attentions.AwaitingInstructionFromClient'
    ),
    OnHold: I18n('Common.Attentions.OnHold'),
    MobileRemarks: I18n('DecPackOrders.MobileRemarks'),
  };

  let workOrderDescriptionList: Array<BaseModel> = [];
  const getWorkOrderTypes = () => {
    DnPWorkOrdersTypeDescription.forEach((value: string, key: number) => {
      workOrderDescriptionList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getWorkOrderTypes();

  type Row = (typeof gridData.rows)[number];
  const columns: GridColumns<Row> = [
    {
      field: 'attention',
      type: 'actions',
      headerName: i18nMessages.Attention,
      width: 100,
      sortable: false,
      getActions: (params: GridRowParams<any>) => {
        const actionButtons = [];
        actionButtons.push(
          <>
            {params.row.isAwaitingInfo ? (
              <FontAwesomeIcon
                icon={faClock}
                title={
                  params.row.workOrderNo === DnPWorkOrders.WO7
                    ? i18nMessages.AwaitingInstructionFromClient
                    : i18nMessages.AwaitingInfo
                }
                size="lg"
              />
            ) : (
              <></>
            )}
            {params.row.isOnHold ? (
              <FontAwesomeIcon
                icon={faPause}
                title={i18nMessages.OnHold}
                color="orange"
                size="lg"
              />
            ) : (
              <></>
            )}
            {params.row.hasMobileRemark ? (
              <FontAwesomeIcon
                icon={faCommentDots}
                title={i18nMessages.MobileRemarks}
                size="lg"
              />
            ) : (
              <></>
            )}
          </>
        );

        return actionButtons;
      },
    },
    {
      field: 'workOrderNoName',
      headerName: I18n('DecPackOrders.WorkOrder.WONo'),
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return params.formattedValue;
      },
    },
    {
      field: 'type',
      headerName: I18n('DecPackOrders.WorkOrder.Type'),
      width: 300,
      sortable: false,
      renderCell: (params: any) => {
        let typeDescription =
          workOrderDescriptionList.find(
            (x: any) => x.value == +params?.row.workOrderNo
          )?.label ?? '';
        return (
          <Tooltip title={typeDescription}>
            <div>{typeDescription}</div>
          </Tooltip>
        );
      },
    },
    {
      field: 'workOrderDate',
      headerName: I18n('DecPackOrders.WorkOrder.WODate'),
      flex: 1,
      sortable: false,
      valueFormatter: (params) =>
        params.value ? moment(params?.value).format('DD/MM/YYYY') : '',
    },
    {
      field: 'activityOwner',
      headerName: I18n('DecPackOrders.WorkOrder.ActivityOwner'),
      flex: 1,
      sortable: false,
      renderCell: (params: any) => {
        if (params.formattedValue === ActivityOwner.BackOffice) {
          return I18n('DecPackOrders.WorkOrder.BackOffice');
        } else if (params.formattedValue === ActivityOwner.Driver) {
          return I18n('DecPackOrders.WorkOrder.MobileUser');
        } else if (params.formattedValue === ActivityOwner.Partner) {
          return I18n('DecPackOrders.WorkOrder.Partner');
        } else {
          return ' ';
        }
      },
    },
    {
      field: 'assignedToName',
      headerName: I18n('DecPackOrders.WorkOrder.AssignedTo'),
      flex: 1,
      sortable: false,
    },
    {
      field: 'updatedDate',
      headerName: I18n('DecPackOrders.WorkOrder.LastUpdateOn'),
      flex: 1,
      sortable: false,
      valueFormatter: (params) => moment(params?.value).format('DD/MM/YYYY'),
    },
    {
      field: 'status',
      headerName: I18n('DecPackOrders.WorkOrder.Status'),
      flex: 1,
      sortable: false,
      renderCell: (params: any) => {
        if (params.formattedValue === WorkOrderStatus.ToDo) {
          return i18nMessages.TODO;
        } else if (params.formattedValue === WorkOrderStatus.InProgress) {
          return i18nMessages.Inprogress;
        } else if (params.formattedValue === WorkOrderStatus.Done) {
          return i18nMessages.Done;
        } else {
          return i18nMessages.AwaitingAssignment;
        }
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: I18n('Common.Actions'),
      width: 80,
      getActions: (params: GridRowParams) => {
        const actionButtons = [];

        // actionButtons.push(
        //   <GridActionsCellItem
        //     icon={<EditIcon />}
        //     label="Edit"
        //     onClick={editWO(params)}
        //   />
        // );

        (params.row.status === WorkOrderStatus.Done ||
          params.row.workOrderNo == nextWO) &&
        isOrderEnable ? (
          actionButtons.push(
            <GridActionsCellItem
              onPointerEnterCapture={false}
              onPointerLeaveCapture={false}
              placeholder={''}
              icon={<EditIcon />}
              label="Edit"
              onClick={() => editWO(params)}
            />
          )
        ) : (
          <></>
        );

        return actionButtons;
      },
    },
  ];

  const editWO = (data: any) => {
    if (data.row.workOrderNo === DnPWorkOrders.WO2) {
      navigate(
        RouteEnum.ViewDecPackOrders + '/' + id + '/DGDCreation/' + data.row.id
      );
    }
    if (data.row.workOrderNo === DnPWorkOrders.WOS) {
      navigate(
        RouteEnum.ViewDecPackOrders +
          '/' +
          id +
          '/SecurityCheckRA/' +
          data.row.id
      );
    }
    if (data.row.workOrderNo === DnPWorkOrders.WOIBO) {
      navigate(
        RouteEnum.ViewDecPackOrders + '/' + id + '/InBound/' + data.row.id
      );
    }
    if (data.row.workOrderNo === DnPWorkOrders.WO3) {
      navigate(
        RouteEnum.ViewDecPackOrders +
          '/' +
          id +
          '/DecPackWOCollectionData/' +
          data.row.id
      );
    }
    if (data.row.workOrderNo === DnPWorkOrders.WO4) {
      navigate(
        RouteEnum.ViewDecPackOrders +
          '/' +
          id +
          '/CheckDocumentations/' +
          data.row.id
      );
    }
    if (data.row.workOrderNo === DnPWorkOrders.WO5) {
      navigate(
        RouteEnum.ViewDecPackOrders +
          '/' +
          id +
          '/DecPackWOMaterial/' +
          data.row.id
      );
    }
    if (data.row.workOrderNo === DnPWorkOrders.WO6) {
      navigate(
        RouteEnum.ViewDecPackOrders + '/' + id + '/Execution/' + data.row.id
      );
    }
    if (data.row.workOrderNo === DnPWorkOrders.WO6bis) {
      navigate(
        RouteEnum.ViewDecPackOrders + '/' + id + '/DecPackWO6Bis/' + data.row.id
      );
    }
    if (data.row.workOrderNo === DnPWorkOrders.Wo6ter) {
      navigate(
        RouteEnum.ViewDecPackOrders +
          '/' +
          id +
          '/DecPackWOOrderDelivery/' +
          data.row.id
      );
    }
    if (data.row.workOrderNo === DnPWorkOrders.WO7) {
      navigate(
        `${RouteEnum.ViewDecPackOrders}/${id}/${RouteEnum.DeckPackIrregularityContactClient}/${data.row.id}`
      );
    }
    if (data.row.workOrderNo === DnPWorkOrders.WO8) {
      navigate(
        RouteEnum.ViewDecPackOrders + '/' + id + '/WOEIGHT/' + data.row.id
      );
    }
    if (data.row.workOrderNo === DnPWorkOrders.WO9) {
      navigate(
        RouteEnum.ViewDecPackOrders + '/' + id + '/PhotoCheck/' + data.row.id
      );
    }
    if (data.row.workOrderNo === DnPWorkOrders.WOOBO) {
      navigate(
        RouteEnum.ViewDecPackOrders + '/' + id + '/OBOWO/' + data.row.id
      );
    }
    if (data.row.workOrderNo === DnPWorkOrders.WO10) {
      navigate(
        RouteEnum.ViewDecPackOrders + '/' + id + '/WOTEN/' + data.row.id
      );
    }
    if (data.row.workOrderNo === DnPWorkOrders.WO11) {
      navigate(
        RouteEnum.ViewDecPackOrders +
          '/' +
          id +
          '/CustomerReference/' +
          data.row.id
      );
    }
    // if (data.row.workOrderNo === DnPWorkOrders.WO12) {
    //   navigate(
    //     RouteEnum.ViewDecPackOrders + '/' + id + '/WOTWELVE/' + data.row.id
    //   );
    // }
  };

  const getWorkOrder = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl + 'DecPackOrder/GetDecPackWO/' + id
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result) {
        result.data.decPackWorkOrdersDto.map((item: any) => {
          if (item.type === 'CheckDocumentation') {
            localStorage.setItem('DocumentId', item.id);
          }
          if (item.type === 'Executionorder') {
            localStorage.setItem('ImageId', item.id);
          }
        });

        updateGridData('rows', result.data.decPackWorkOrdersDto);
        setNextWO(result.data.nextWorkOrder);

        var decPackOrder = await getDecPackById(Number(id));
        if (decPackOrder) {
          handleOrderStatus(CheckOrderEnable(decPackOrder.status));
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    getWorkOrder();
  }, [gridData.page, gridData.pageSize]);

  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));

  const handleChangePage = (pageNo: number) => {
    updateGridData('page', pageNo);
  };

  const handleChangePageSize = (pageSize: number) => {
    updateGridData('pageSize', pageSize);
  };

  const handleOnRowClick: GridEventListener<'rowClick'> = (params: any) => {
    if (
      (params.row.status === WorkOrderStatus.Done ||
        params.row.workOrderNo == nextWO) &&
      isOrderEnable
    ) {
      editWO(params);
    }
  };

  return (
    <div className="main-content-section">
      <div className="content-block table-fix">
        <CustomDataGrid
          rowCount={gridData.totalRows}
          onRowClick={handleOnRowClick}
          data={gridData.rows}
          columns={columns}
          pageNo={gridData.page}
          pageSize={gridData.pageSize}
          onPageChange={handleChangePage}
          onPageSizeChange={handleChangePageSize}
          rowsPerPageOptions={gridData.rowsPerPageOptions}
          hideFooter={true}
        ></CustomDataGrid>
      </div>
    </div>
  );
}

export default DeckPackWorkOrderList;
