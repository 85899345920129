import {
  Breadcrumbs,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputLabel,
  Link,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import I18n from 'utilities/i18n';
import CustomizedSelect from 'common/CustomSelect/CustomSelect';
import FormControl from 'common/formControl/formControl';
import http from '../../../utilities/httpService';
import environment from 'environment';
import { Moment } from 'moment';
import {
  ActivityOwner,
  BaseModel,
  DocumentEntity,
  EntityAssignmentTypeOptionsDescription,
  EntityTypeEnum,
  ImageUploadViewModel,
  OrderType,
  PaginationResponseModel,
  WorkOrderStatus,
} from 'models/pagination-model';
import Status from '../../../common/WoStatus/Status';
import FormAccordianSave from 'common/formControl/formAccordianSave';
import { useNavigate, useParams } from 'react-router-dom';
import DateTimePickerInput from 'common/DatetimePicker/DateTimePickerInput';
import { toast } from 'react-toastify';
import ImageUploadPreview from 'common/ImageUploadPreview/ImageUploadPreview';
import RouteEnum from 'models/RouteEnum';
import {
  getWorkOrderImages,
  saveWorkOrderImages,
} from 'common/ImageUploadPreview/WorkOrderImagesApiService';
import { hasPermission } from 'utilities/protectedRoute';
import { Permission } from 'Permissions';
import ApiUrlConstants from 'constants/api.constants';
import moment from 'moment';
import CustomSelectPaginate from 'common/CustomSelect/CustomSelectPaginate';
import { Customer } from 'models/service-catalogue';
import CreatedByandUpdatedBy from 'common/CreatedByandUpdatedBy/CreatedByandUpdatedBy';
import MobileDetails from 'common/MobileDetails/MobileDetails';

const WorkOrderPackaging = () => {
  const { woId, id, shippingWoId } = useParams();
  let navigate = useNavigate();
  const defaultValue = {
    value: '0',
    label: I18n('Placeholders.Select'),
  } as BaseModel;
  const defaultAdditional: any = {
    page: 1,
  };
  const [selectedAssignmentTo, setSelectedAssignmentTo] =
    useState(defaultValue);
  const [mobileImages, setMobileImages] = useState([]);

  const breadcrumbs = [
    <Typography key="3" color="text.primary">
      {I18n('Nav.Order')}
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={() => navigate(RouteEnum.ShippingOrdersList)}
        className="cpointer"
      >
        {I18n('Nav.ShippingOrders')}
      </Link>
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={() => navigate(RouteEnum.ViewShippingOrders + '/' + id + '/2')}
        className="cpointer"
      >
        {I18n('ShippingOrders.WorkOrders')}
      </Link>
    </Typography>,
  ];
  const [canEdit, setcanEdit] = useState(
    hasPermission(Permission.canEditShippingOrders)
  );
  const [expanded, setExpanded] = useState<boolean>(true);
  const defaultValueForAssignment = {
    value: '',
    label: I18n('Placeholders.SelectAssignmentType'),
  } as BaseModel;

  const [values, setValues] = useState({
    assignmentType: defaultValueForAssignment,
    assignTo: '',
    instructions: '',
    images: [] as any,
    isPackagingDone: false,
    remarks: '',
    actualPackagingDateTime: new Date(),
    mobileRemarks: '',
    waitTime: 0,
  });
  const [details, setDetails] = useState<any>({
    createdBy: '',
    createdDate: '',
    updatedBy: '',
    updatedDate: '',
  });
  const [finalStatus, setFinalStatus] = useState(0);
  const [woPackagingId, setWOPackagingId] = useState<number>();
  const [isNew, setIsNew] = useState<boolean>(true);

  const today = new Date().toISOString();
  const [errors, setErrors] = useState({
    assignmentType: '',
    assignTo: '',
    actualPackagingDateTime: '',
  });
  const [images, setImages] = useState<ImageUploadViewModel[]>([]);
  const [isStatusSaveCallApi, setIsStatusSaveCallApi] =
    useState(false); /* To call Submit APi Status from Status Component */
  const [validateStatus, setValidateStatus] = useState<boolean>(false);
  /* validateStatus - To validate Status Dropdown from Status Component */

  const getShippingWO = async () => {
    var shippingOrderId = Number(id);
    var WOID = Number(woId);

    const apiUrl = new URL(
      environment.api.baseUrl +
        'Shipping/GetShippingWO/' +
        shippingOrderId +
        '/' +
        WOID
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result) {
        setDetails({
          ...details,
          createdBy: result.data.createdBy,
          updatedBy: result.data.updatedBy,
          createdDate: result.data.createdDate,
          updatedDate: result.data.updatedDate,
        });
        return result.data;
      }
    } catch (error) {}
  };

  const getMobileImages = async () => {
    var orderId = Number(id);

    const apiUrl = new URL(
      environment.api.baseUrl +
        `Documents/GetWorkOrderImagesAsync/${EntityTypeEnum.ShippingOrders}/${DocumentEntity.ShippingPackagingImage}/${woId}/${orderId}`
    );

    const result = await http.get(apiUrl.toString());
    if (result && result.data) {
      setMobileImages(result.data);
    }
  };

  const getWOPackaging = async () => {
    const apiUrl = new URL(environment.api.baseUrl + 'ShippingWOPackaging');
    try {
      var finalSearchTerm = `shippingWorkOrderId~=~${woId} and shippingOrderId~=~${id}`;
      apiUrl.searchParams.set('$filter', finalSearchTerm);
      apiUrl.searchParams.set('$skip', '0');
      apiUrl.searchParams.set('$top', '10');

      const result = await http.get(apiUrl.toString());
      let shippingWOData = await getShippingWO();
      if (shippingWOData) {
        setFinalStatus(shippingWOData.status);
      }

      if (result) {
        if (result.data.values.length > 0) {
          setIsNew(false);
          result.data.values.map(async function (item: any) {
            var types = {
              value: item.assignmentType,
              label:
                item.assignmentType === 1
                  ? i18nMessages.Driver
                  : item.assignmentType === 2
                  ? i18nMessages.DecNPack
                  : item.assignmentType === 3
                  ? i18nMessages.Shipping
                  : defaultValueForAssignment.label,
            };

            setWOPackagingId(item.id);
            var modify = [] as any;
            item.imageUrls.map((element: any) => {
              modify.push(element);
            });

            setValues({
              ...values,
              assignmentType: types,
              assignTo: item.assignmentTo,
              instructions: item.instructions,
              isPackagingDone: item.isPackagingDone,
              actualPackagingDateTime: item.actualPackagingDateTime,
              remarks: item.remarks,
              images: modify,
              mobileRemarks: item.mobileRemarks,
              waitTime: item.waitTime,
            });

            if (item.assignmentTo) {
              const apiUrl = new URL(
                environment.api.baseUrl + `Users/${item.assignmentTo}`
              );
              const resultForUser = await http.get(apiUrl.toString());
              if (resultForUser && resultForUser.data) {
                setSelectedAssignmentTo({
                  label: `${resultForUser.data.firstName} ${resultForUser.data.lastName}`,
                  value: resultForUser.data.id,
                });
              }
            }
          });
        } else {
          setIsNew(true);
          var driverLabel = entityAssignmentTypeOptionsList.find(
            (x) => x.value === '1'
          )?.label;
          if (shippingWOData.assignedToId) {
            setValues({
              ...values,
              assignmentType: {
                label: driverLabel ? driverLabel : '',
                value: '1',
              },
              assignTo: shippingWOData.assignedToId,
            });
            setSelectedAssignmentTo({
              value: shippingWOData.assignedToId,
              label: shippingWOData.assignedToName,
            });
          } else {
            setValues({
              ...values,
              assignmentType: {
                label: driverLabel ? driverLabel : '',
                value: '1',
              },
            });
          }
        }
      }
      getPictures();
    } catch (error) {}
  };

  const loadUserOptions = async (
    search: string,
    loadedOptions: any,
    { page }: any
  ) => {
    const response = await getUsers(search, page);
    const hasMore = Math.ceil(response.TotalRecords / 10) > page;

    return {
      options: response.Values.map((x) => {
        return {
          value: x.id.toString(),
          label: x.name,
        } as BaseModel;
      }),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };
  const getUsers = async (search: string, pageNo: number) => {
    let response: PaginationResponseModel<Customer>;
    const apiUrl = new URL(environment.api.baseUrl + 'Users/MobileUsers');
    try {
      let searchExpression = '';
      searchExpression += search
        ? ` firstName~like~${search} or lastName~like~${search}`
        : '';
      apiUrl.searchParams.set('$filter', searchExpression);
      apiUrl.searchParams.set('$orderby', 'firstName');
      apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
      apiUrl.searchParams.set('$top', '10');
      apiUrl.searchParams.set('$orderbydirection', 'asc');
      const result = await http.get(apiUrl.toString());
      if (result.data && result.data.values.length) {
        result.data.values.forEach((z: any) => {
          z.name = `${z.firstName} ${z.lastName}`;
        });
      }
      if (result) {
        response = {
          TotalRecords: result.data.totalCount,
          Values: result.data.values,
        } as PaginationResponseModel<Customer>;
      } else {
        response = {
          TotalRecords: 0,
          Values: [],
        } as PaginationResponseModel<Customer>;
      }
    } catch (error) {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<Customer>;
    }
    return response;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getWOPackaging();
    getMobileImages();
  }, []);

  const i18nMessages = {
    ErrorWhileUpdatingStatus: I18n('Packaging.ErrorWhileUpdatingStatus'),
    SomethingWentWrong: I18n('Packaging.SomethingWentWrong'),
    AddSuccess: I18n('Packaging.AddSuccess'),
    UpdateSuccess: I18n('Packaging.UpdateSuccess'),
    fieldRequired: I18n('Packaging.FieldRequired'),
    Driver: I18n('Common.Driver'),
    Remarks: I18n('Packaging.Remarks'),
    Picture: I18n('Packaging.Picture'),
    DecNPack: I18n('Common.DecNPack'),
    Title: I18n('Packaging.Title'),
    AssignedTo: I18n('Packaging.AssignedTo'),
    AssignmentType: I18n('Packaging.AssignmentType'),
    Instructions: I18n('Packaging.Instructions'),
    PackagingDetails: I18n('Packaging.PackagingDetails'),
    PackagingDone: I18n('Packaging.PackagingDone'),
    ActualPackagingDateTime: I18n('Packaging.ActualPackagingDateTime'),
    NoShippingDocsAvailable: I18n(
      'DecPackOrders.DecPackQuestions.WOExecution.NoDocsAvailable'
    ),
    noImagesSelected: I18n('Common.NoImagesSelectedToUpload'),
    imageSaveSuccess: I18n('Common.ImageSaveSuccess'),
    somethingWentWrong: I18n('Common.SomethingWentWrong'),
    commonFieldIsRequired: I18n('Common.FieldIsRequired'),
    InvalidDate: I18n('Common.InvalidDate'),
    Shipping: I18n('Common.Shipping'),
  };

  let entityAssignmentTypeOptionsList: Array<BaseModel> = [];
  const getAssignmentTypeOptionsList = () => {
    entityAssignmentTypeOptionsList = [defaultValueForAssignment];
    EntityAssignmentTypeOptionsDescription.forEach(
      (value: string, key: number) => {
        entityAssignmentTypeOptionsList.push({
          value: key.toString(),
          label: I18n(value),
        } as BaseModel);
      }
    );
  };
  getAssignmentTypeOptionsList();

  const AssignedTo = () => {
    return (
      <>
        <Grid item lg={4} md={8}>
          <CustomizedSelect
            placeholder={defaultValueForAssignment.label}
            options={entityAssignmentTypeOptionsList}
            isSearchable={false}
            displayLabel={i18nMessages.AssignmentType}
            handleChange={(newValue: any) => {
              setValues({ ...values, assignmentType: newValue, assignTo: '' });
              if (newValue !== '') {
                setErrors({
                  ...errors,
                  assignmentType: '',
                });
              }
              setSelectedAssignmentTo(defaultValue);
            }}
            value={values.assignmentType}
            required={true}
            errorValue={errors.assignmentType}
            errorMessage={errors.assignmentType}
            readOnly={!canEdit}
          ></CustomizedSelect>
        </Grid>

        <Grid item lg={4} md={8}>
          <CustomSelectPaginate
            displayLabel={I18n('WorkorderPickup.AssignedTo')}
            name="assignmentTo"
            id="assignmentTo"
            key={+selectedAssignmentTo.value}
            additional={defaultAdditional}
            value={selectedAssignmentTo}
            loadOptions={loadUserOptions}
            handleChange={(newValue: BaseModel) => {
              setSelectedAssignmentTo(newValue);
              setValues({ ...values, assignTo: newValue.value });
            }}
            isSearchable={true}
            errorValue={true}
            errorMessage={errors.assignTo}
            readOnly={!canEdit}
            debounceTimeout={500}
            isMultiSelect={false}
          />
        </Grid>
        <Grid item lg={4} md={8}>
          <FormControl
            margin="normal"
            displayLabel={i18nMessages.Instructions}
            id="instructions"
            inputType="text"
            inputProps={{ maxLength: 250 }}
            placeholder={'Select'}
            handleChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => {
              setValues({ ...values, instructions: event.target.value });
            }}
            value={values.instructions}
            multiline={false}
            readOnly={!canEdit}
          ></FormControl>
        </Grid>
      </>
    );
  };

  const onPackagingCheckBoxChange = (event: any) => {
    setValues({ ...values, isPackagingDone: event.target.checked });
    if (event.target.checked === true) {
      setFinalStatus(WorkOrderStatus.Done);
    }
  };

  const validate = (fieldValues: any = values) => {
    let temp = { ...errors };

    if ('assignmentType' in fieldValues) {
      temp.assignmentType =
        fieldValues.assignmentType.value.toString() !== '' &&
        fieldValues.assignmentType.value.toString() !== '0'
          ? ''
          : i18nMessages.fieldRequired;
    }

    if ('actualPackagingDateTime' in fieldValues) {
      temp.actualPackagingDateTime = fieldValues.actualPackagingDateTime
        ? ''
        : i18nMessages.commonFieldIsRequired;

      if (temp.actualPackagingDateTime == '') {
        let isDateValid = moment(fieldValues.actualPackagingDateTime).isValid();
        if (!isDateValid) {
          temp.actualPackagingDateTime = i18nMessages.InvalidDate;
        }
      }
    }

    setErrors({
      ...temp,
    });
    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === '');
    }
  };

  const deleteFileFunction = (index: number) => {
    const localImages: any[] = [...images];
    localImages.splice(index, 1);
    setImages(localImages);
  };

  const fileDataFunction = (data: any) => {
    setImages([...images, ...data]);
  };

  const editedFileDataFunction = (updatedImages: ImageUploadViewModel[]) => {
    setImages([...updatedImages]);
  };

  const handleSaveClick = async () => {
    setValidateStatus(true);
  };

  /* Validate Status Dropdown Success call from Status Component */
  const handleSuccessStatusSave = async () => {
    let isValid = finalStatus == WorkOrderStatus.Done ? validate() : true;
    if (isValid) {
      handlePhotoSaveClick();
      setIsStatusSaveCallApi(true);
      const finalValue = {
        shippingWorkOrderId: Number(woId),
        shippingOrderId: Number(id),
        assignmentType: Number(values.assignmentType.value),
        assignmentTo: values.assignTo,
        instructions: values.instructions,
        isPackagingDone: values.isPackagingDone,
        actualPackagingDateTime: values.actualPackagingDateTime,
        imageUrls: values.images.filter(
          (item: any) => typeof item !== 'object'
        ),
        id: woPackagingId,
        mobileRemarks: values.mobileRemarks,
        waitTime: values.waitTime,
      };

      if (isNew) {
        const apiUrl = new URL(environment.api.baseUrl + 'ShippingWOPackaging');
        try {
          const result: any = await http.post(apiUrl.toString(), finalValue);
          if (result) {
            toast.success(i18nMessages.AddSuccess);
            handleErrorStatusSave(false);
            navigate(RouteEnum.ViewShippingOrders + '/' + id + '/2');
          }
        } catch (error: any) {
          toast.error(i18nMessages.SomethingWentWrong);
          handleErrorStatusSave(false);
        }
      } else {
        const apiUrl = new URL(
          environment.api.baseUrl + 'ShippingWOPackaging/' + woPackagingId
        );
        try {
          const result: any = await http.put(apiUrl.toString(), finalValue);
          if (result) {
            toast.success(i18nMessages.UpdateSuccess);
            handleErrorStatusSave(false);
            navigate(RouteEnum.ViewShippingOrders + '/' + id + '/2');
          }
        } catch (error: any) {
          toast.error(i18nMessages.SomethingWentWrong);
          handleErrorStatusSave(false);
        }
      }
    } else {
      handleErrorStatusSave(false);
    }
  };

  /* Validate Status Dropdown Error call from Status Component */
  const handleErrorStatusSave = (result: boolean = false) => {
    setValidateStatus(false);
    setIsStatusSaveCallApi(false);
  };

  const handlePhotoSaveClick = async () => {
    await saveWorkOrderImages(
      images,
      EntityTypeEnum.ShippingOrders,
      DocumentEntity.ShippingPackagingImage,
      false,
      id ? +id : 0,
      woId ? +woId : 0
    );
  };

  const getPictures = () => {
    getWorkOrderImages(
      EntityTypeEnum.ShippingOrders,
      woId ? +woId : 0,
      id ? +id : 0,
      DocumentEntity.ShippingPackagingImage
    ).then((x) => {
      if (x.result) {
        /* Object conversion from backend viewModel to ImagePreview component Object */
        setImages(
          x.data.map((y) => {
            return {
              documentTag: {
                label: y.documentTagName,
                value: y.documentTag?.toString(),
              } as BaseModel,
              error: '',
              id: y.id,
              previewUrl:
                environment.api.baseUrl +
                ApiUrlConstants.DownloadImageUrl +
                y.imageUrl,
              path: y.imageUrl,
              sendToCustomer: y.sendToCustomer,
            } as ImageUploadViewModel;
          })
        );
      }
    });
  };

  const PackagingDetails = () => {
    return (
      <>
        <Grid item lg={2} md={4}>
          <FormControlLabel
            labelPlacement="end"
            control={
              <Checkbox
                name="isPackagingDone"
                checked={values.isPackagingDone}
                onChange={onPackagingCheckBoxChange}
                inputProps={{ 'aria-label': 'controlled' }}
                readOnly={!canEdit}
                disabled={!canEdit}
              />
            }
            label={i18nMessages.PackagingDone}
          />
        </Grid>
        <Grid item lg={4} md={8}>
          <DateTimePickerInput
            displayLabel={i18nMessages.ActualPackagingDateTime}
            name="actualPackagingDateTime"
            value={values.actualPackagingDateTime}
            defaultValue={today}
            handleChange={(event: Moment) => {
              setValues({
                ...values,
                actualPackagingDateTime: event != null ? event.toDate() : event,
              });
            }}
            errorValue={true}
            errorMessage={errors.actualPackagingDateTime}
            readOnly={!canEdit}
          ></DateTimePickerInput>
        </Grid>
        <>
          <Grid
            sx={{ mb: 2 }}
            container
            marginTop={2}
            paddingLeft={2}
            className="grid-wrap two-col mb-2"
          >
            <Grid item lg={12}>
              <InputLabel shrink htmlFor="bootstrap-input">
                {i18nMessages.Picture}
              </InputLabel>
              <ImageUploadPreview
                disableKeyboardNav={true}
                limit={10}
                fileData={fileDataFunction}
                fileRemove={deleteFileFunction}
                editedFileData={editedFileDataFunction}
                images={images}
                readOnly={!canEdit}
                sendToCustomer={true}
              />
            </Grid>
          </Grid>
          {!(images && images.length) && (
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item lg={12}>
                {i18nMessages.NoShippingDocsAvailable}
              </Grid>
            </Grid>
          )}
        </>
      </>
    );
  };
  return (
    <div>
      <div className="heading-section">
        <div className="heading-section-left">
          <Typography variant="h3" sx={{ mb: 3 }}>
            {i18nMessages.Title}
          </Typography>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>
      </div>
      <Grid container spacing={2} className="grid-wrap">
        <div className="main-content-section width-100">
          <FormAccordianSave
            title={i18nMessages.AssignedTo}
            className="inner-heading"
            details={AssignedTo()}
            showActionButton={!canEdit}
          ></FormAccordianSave>
          <FormAccordianSave
            title={i18nMessages.PackagingDetails}
            className="inner-heading"
            details={PackagingDetails()}
            showActionButton={!canEdit}
          ></FormAccordianSave>
          <MobileDetails
            mobileRemarks={values.mobileRemarks}
            waitTime={values.waitTime}
          ></MobileDetails>
          <Status
            permission={Permission.canEditShippingOrders}
            woId={woId}
            orderId={id}
            statusValue={finalStatus}
            activityOwner={
              Number(values.assignmentType.value) === 1
                ? ActivityOwner.Driver
                : Number(values.assignmentType.value) === 3
                ? ActivityOwner.BackOffice
                : Number(values.assignmentType.value) === 2
                ? ActivityOwner.BackOffice
                : ''
            }
            assignedToId={selectedAssignmentTo.value}
            assignedToName={selectedAssignmentTo.label}
            type={selectedAssignmentTo.value}
            orderType={OrderType.ShippingOrder}
            isStatusSaveCallApi={isStatusSaveCallApi}
            validateStatus={validateStatus}
            handleSuccessStatusSave={handleSuccessStatusSave}
            handleErrorStatusSave={handleErrorStatusSave}
            handleStatusChange={(value: number) => setFinalStatus(value)}
          />
          <div className="btn-wrap form-btn-wrap">
            {canEdit ? (
              <Button
                type="submit"
                color="primary"
                disableElevation
                variant="contained"
                onClick={handleSaveClick}
              >
                {I18n('Common.Save')}
              </Button>
            ) : (
              <></>
            )}
          </div>
          <CreatedByandUpdatedBy
            createdBy={details.createdBy}
            createdDate={details.createdDate}
            updatedBy={details.updatedBy}
            updatedDate={details.updatedDate}
          ></CreatedByandUpdatedBy>
        </div>
      </Grid>
    </div>
  );
};

export default WorkOrderPackaging;
