import { FormControl, FormHelperText } from '@mui/material';
import React from 'react';
import { DropzoneArea } from 'react-mui-dropzone';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import I18n from 'utilities/i18n';

const useStyles = makeStyles((theme) =>
  createStyles({
    previewChip: {
      minWidth: 160,
      maxWidth: 210,
    },
  })
);

const FileUpload = (props: any) => {
  const handleChange = (files: any) => {
    if (files.length > 0) {
      props.onSave(files);
    }
  };

  const i18nMessages = {
    commonFileSizeExceedErr: I18n('Common.fileSizeExceedErr'),
    uploadFileExceedError: I18n('Common.UploadFileExceedError'),
    fileTypeErr: I18n('Common.fileTypeErr'),
    commonFileTypeErr: I18n('Common.UploadFileFormatError'),
  };

  const handleFileSizeExceedMessage = (files: any) => {
    return i18nMessages.commonFileSizeExceedErr;
  };

  // Convert bytes to KB, MB, GB
  const formatBytes = (bytes: number, decimals: number = 2) => {
    if (!+bytes) {
      return '0 Bytes';
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  };

  const handleOnDropReject = (
    rejectedFiles: any,
    acceptedFiles: any,
    maxFileSize: any
  ) => {
    let errMsgFileUpload = i18nMessages.commonFileSizeExceedErr;

    if (!acceptedFiles.includes(rejectedFiles.type)) {
      if (props.acceptedFilesNames && props.acceptedFilesNames.length > 0) {
        errMsgFileUpload = i18nMessages.commonFileTypeErr.replace(
          '#acceptedFiles#',
          props.acceptedFilesNames.join(', ')
        );
      } else {
        errMsgFileUpload = i18nMessages.fileTypeErr;
      }
    } else if (rejectedFiles.size > maxFileSize) {
      let errorMessage = i18nMessages.uploadFileExceedError;

      let formattedFileSize = formatBytes(maxFileSize);
      errMsgFileUpload = errorMessage?.replace(
        '#maxFileSize#',
        formattedFileSize
      );
    }

    return errMsgFileUpload;
  };
  const classes = useStyles();
  return (
    <FormControl
      variant="standard"
      fullWidth
      sx={{ mb: 2 }}
      error={props.errorValue}
    >
      <DropzoneArea
        maxFileSize={props.maxFileSize}
        Icon={NoteAddIcon}
        useChipsForPreview
        showPreviews={props.showPreviews}
        showPreviewsInDropzone={false}
        filesLimit={props.filesLimit}
        previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
        previewChipProps={{ classes: { root: classes.previewChip } }}
        previewText="Uploaded Document"
        initialFiles={props.initialFiles}
        onChange={(e) => handleChange(e)}
        onDelete={props.onDelete}
        acceptedFiles={props.acceptedFiles}
        getDropRejectMessage={handleOnDropReject}
        getFileLimitExceedMessage={handleFileSizeExceedMessage}
        key={'dropzone-' + props?.dropzoneKey}
      />
      {props.errorValue ? (
        <FormHelperText>{props.errorValue}</FormHelperText>
      ) : (
        ''
      )}
      {props.validatePixel ? (
        <FormHelperText>{I18n('Common.FileUploadMessage')}</FormHelperText>
      ) : (
        ''
      )}
    </FormControl>
  );
};

export default FileUpload;
