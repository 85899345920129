import I18n from 'utilities/i18n';
import { useEffect, useState } from 'react';
import http from '../../utilities/httpService';
import environment from 'environment';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomizedSelect as StatusSelect } from 'common/CustomSelect/CustomSelect';
import { Grid } from '@mui/material';
import {
  BaseModel,
  EntityStatusDescription,
  PricingTypeDescription,
  PaginationResponseModel,
  MasterCategoryEnumType,
} from 'models/pagination-model';
import FormControl from 'common/formControl/formControl';
import { Customer } from 'models/service-catalogue';
import RouteEnum from 'models/RouteEnum';
import FormAccordian from 'common/formControl/formAccordian';
import { toast } from 'react-toastify';
import CustomSelectPaginate from 'common/CustomSelect/CustomSelectPaginate';
import CustomizedInputs from 'common/formControl/formControl';
import CustomSelectPaginateAdd from 'common/CustomSelect/CustomSelectPaginateAdd';
import {
  PostCatagoryDropDown,
  loadItemGroupOptions,
  loadLedgerOptions,
} from 'common/DropDownAddAPI/DropDownApiService';
import { hasPermission } from 'utilities/protectedRoute';
import { Permission } from 'Permissions';

function TransportCatalogueDetails() {
  const defaultValue = {
    value: '0',
    label: I18n('Placeholders.SelectStatus'),
  } as BaseModel;
  const defaultCategoryValue = {
    value: '0',
    label: I18n('Placeholders.SelectCategory'),
  } as BaseModel;
  const defaultZoneValue = {
    value: '0',
    label: I18n('Placeholders.SelectZone'),
  } as BaseModel;
  const defaultDropdownValue = {
    value: '0',
    label: I18n('Placeholders.Select'),
  } as BaseModel;

  let pricingTypeList: Array<BaseModel> = [];
  const getPricingTypeList = () => {
    pricingTypeList = [];
    PricingTypeDescription.forEach((value: string, key: number) => {
      pricingTypeList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getPricingTypeList();
  const { entityId } = useParams();
  const navigate = useNavigate();
  const [cacheUniq, setCacheUniq] = useState(0);
  const [expanded, setExpanded] = useState<boolean>(true);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  let [countries, setCountries] = useState([]) as any;
  let [selectedCountries, setSelectedCountries] = useState([]) as any;
  const [countriesExpanded, setCountriesExpanded] = useState<boolean>(true);
  const [isCountriesEdit, setIsCountriesEdit] = useState<boolean>(false);
  const [transportCode, setTransportCode] = useState('');
  const [category, setCategory] = useState<BaseModel>(defaultCategoryValue);
  const [fromZone, setFromZone] = useState<BaseModel>(defaultZoneValue);
  const [toZone, setToZone] = useState<BaseModel>(defaultZoneValue);
  let [transportType, setTransportType] = useState([]);
  const [status, setStatus] = useState<BaseModel>(defaultValue);
  const [ledgerAccount, setLedgerAccount] = useState(defaultDropdownValue);
  const [itemGroup, setItemGroup] = useState(defaultDropdownValue);

  const [canEdit, setCanEdit] = useState(
    hasPermission(Permission.canEditTransportCatalogue)
  );
  const i18nMessages = {
    TransportCatalogueDetailTitle: I18n('TransportCatalogue.Details'),
    TransportCatalogueToZoneCountry: I18n('TransportCatalogue.ToZoneCounries'),
    TransportCatalogueCountries: I18n('TransportCatalogue.Countries'),
    updateTransportCatalogueSuccess: I18n('TransportCatalogue.UpdateSuccess'),
    commonFieldIsRequired: I18n('Common.FieldIsRequired'),
    bothZoneShouldNotBeSame: I18n('TransportCatalogue.BothZoneSame'),
    errorOccurred: I18n('TransportCatalogue.ErrorOccurred'),
    uniqueTransportCode: I18n('TransportCatalogue.UniqueTransportCode'),
    uniqueZoneCombination: I18n('TransportCatalogue.UniqueZoneCombination'),
    CommonSaveSuccessMsg: I18n('Common.SavedSuccessfully'),
    CommonErrOccuredMsg: I18n('Common.ErrorOccurred'),
    LedgerAccountDescription: I18n('LedgerInfo.LedgerAccountDescription'),
    LedgerAccount: I18n('LedgerInfo.LedgerAccount'),
    ItemGroup: I18n('ItemGroupInfo.ItemGroup'),
  };
  const defaultAdditional: any = {
    page: 1,
  };

  const [errors, setErrors] = useState({
    transportCode: '',
    fromZone: '',
    toZone: '',
    category: '',
    transportType: '',
    statusId: '',
    ledgerAccount: '',
    itemGroups: '',
  });

  const validate = () => {
    let temp = { ...errors };

    temp.transportCode = transportCode
      ? ''
      : i18nMessages.commonFieldIsRequired;
    temp.category =
      category.value !== defaultCategoryValue.value
        ? ''
        : i18nMessages.commonFieldIsRequired;
    temp.fromZone =
      fromZone.value !== defaultZoneValue.value
        ? ''
        : i18nMessages.commonFieldIsRequired;

    if (toZone.value) {
      if (toZone.value === '0') {
        temp.toZone = i18nMessages.commonFieldIsRequired;
      } else if (toZone.value == fromZone.value) {
        temp.toZone = i18nMessages.bothZoneShouldNotBeSame;
        temp.fromZone = i18nMessages.bothZoneShouldNotBeSame;
      } else {
        temp.toZone = '';
      }
    }

    temp.transportType =
      transportType.length > 0 ? '' : i18nMessages.commonFieldIsRequired;

    temp.statusId =
      status.value !== defaultValue.value
        ? ''
        : i18nMessages.commonFieldIsRequired;

    temp.ledgerAccount =
      ledgerAccount.value == defaultDropdownValue.value
        ? i18nMessages.commonFieldIsRequired
        : '';
    temp.itemGroups =
      itemGroup.value == defaultDropdownValue.value
        ? i18nMessages.commonFieldIsRequired
        : '';

    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === '');
  };

  const handleEditClick = (event: React.SyntheticEvent) => {
    setIsEdit(!isEdit);
  };
  const handleExpandCollapseClick = (event: React.SyntheticEvent) => {
    setExpanded(!expanded);
  };

  const handleCountriesExpandCollapseClick = (event: React.SyntheticEvent) => {
    setCountriesExpanded(!countriesExpanded);
  };

  const onStatusChange = (data: BaseModel) => {
    setStatus(data);
    if (data.value !== '0') {
      setErrors({
        ...errors,
        statusId: '',
      });
    }
  };

  const getTransportCategories = async (search: string, pageNo: number) => {
    let response: PaginationResponseModel<Customer>;
    const apiUrl = new URL(
      environment.api.baseUrl + 'Dropdown/GetMasterCategories'
    );
    try {
      let searchExpression = `entityType~=~${MasterCategoryEnumType.TransportCatalogue}`;
      searchExpression += search ? `name~like~${search}` : '';
      apiUrl.searchParams.set('$filter', searchExpression);
      apiUrl.searchParams.set('$orderby', 'name');
      apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
      apiUrl.searchParams.set('$top', '10');
      apiUrl.searchParams.set('$orderbydirection', 'desc');
      const result = await http.get(apiUrl.toString());
      if (result) {
        response = {
          TotalRecords: result.data.totalCount,
          Values: result.data.values,
        } as PaginationResponseModel<Customer>;
      } else {
        response = {
          TotalRecords: 0,
          Values: [],
        } as PaginationResponseModel<Customer>;
      }
    } catch (error) {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<Customer>;
    }
    return response;
  };

  const loadCategoryOptions = async (
    search: string,
    loadedOptions: any,
    { page }: any
  ) => {
    const response = await getTransportCategories(search, page);
    const hasMore = Math.ceil(response.TotalRecords / 10) > page;

    return {
      options: response.Values.map((x) => {
        return {
          value: x.id.toString(),
          label: x.name,
        } as BaseModel;
      }),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const handleSaveClick = async () => {
    let transportData = transportType?.map((x: any) => {
      return {
        id: Number(x.value),
        name: x.label,
      };
    });

    if (validate()) {
      setIsCountriesEdit(!isCountriesEdit);
      let finalValue = {
        transportCode,
        fromZoneId: fromZone.value,
        toZoneId: toZone.value,
        categoryId: category.value,
        statusId: Number(status.value),
        id: Number(entityId),
        transportTypes: transportData,
        ledgerAccountId: +ledgerAccount.value,
        itemGroupId: +itemGroup.value,
      };
      const apiUrl = new URL(
        environment.api.baseUrl + `TransportCatalogues/${entityId}`
      );
      try {
        var response = await http.put(apiUrl.toString(), finalValue);
        if (response.data === true) {
          toast.success(i18nMessages.updateTransportCatalogueSuccess);
          navigate(RouteEnum.Transport);
        } else {
          toast.error(i18nMessages.errorOccurred);
        }
      } catch (error: any) {
        if (error.response) {
          let temp = { ...errors };
          if (error.response.data) {
            for (const item of error.response.data.errors) {
              if (
                item.field === 'TransportCode' &&
                item.errorMessage === 'UniqueTransportCode'
              ) {
                temp.transportCode = i18nMessages.uniqueTransportCode;
              } else if (
                item.field === 'FromZoneId' &&
                item.errorMessage === 'UniqueZoneCombination'
              ) {
                temp.fromZone = i18nMessages.uniqueZoneCombination;
                temp.toZone = i18nMessages.uniqueZoneCombination;
              }
            }
          }
          setErrors({
            ...temp,
          });
        }
      }
    }
  };
  let entityStatusList: Array<BaseModel> = [];
  const getStatusList = () => {
    entityStatusList = [defaultValue];
    EntityStatusDescription.forEach((value: string, key: number) => {
      entityStatusList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };

  const ToZoneCountriesData = () => {
    return (
      <>
        {countries &&
          countries.map((element: any, index: any) => (
            <Grid item={true} lg={2} md={2} xs={2} key={`grid${index}`}>
              <CustomizedInputs
                margin="normal"
                name="country"
                id="country"
                inputType="text"
                value={element.label}
                disabled
                inputProps={{ maxLength: 250 }}
              />
            </Grid>
          ))}
      </>
    );
  };

  getStatusList();

  const zoneData = async (toZoneId: any) => {
    let tempSpecificToZone = await getSpecificZone(toZoneId);

    countries = tempSpecificToZone;

    let temp = countries.selectedCountries.map((x: any) => {
      return {
        value: x?.countryId?.toString(),
        label: x.countryName,
      } as BaseModel;
    });

    setCountries(temp);
  };

  const getTransportCatalogueById = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl + `TransportCatalogues/${entityId}`
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result && result.data) {
        setTransportCode(result.data.transportCode);
        setCategory({
          label: result.data.categoryName,
          value: result.data.categoryId,
        } as BaseModel);

        setFromZone({
          label: result.data.fromZoneName,
          value: result.data.fromZoneId,
        } as BaseModel);
        setToZone({
          label: result.data.toZoneName,
          value: result.data.toZoneId,
        } as BaseModel);
        setStatus({
          label: EntityStatusDescription.get(result.data.statusId) || '',
          value: Number(result.data.statusId).toString(),
        } as BaseModel);
        transportType = result?.data?.transportTypes?.map((x: any) => {
          return {
            label: x.name.toString(),
            value: x.id.toString(),
          };
        });
        setTransportType(transportType);
        setLedgerAccount({
          label: result.data.ledgerAccountCode,
          value: result.data.ledgerAccountId.toString(),
        });
        setItemGroup({
          label: result.data.itemGroupCode,
          value: result.data.itemGroupId.toString(),
        });
        await zoneData(result.data.toZoneId);
      }
    } catch (error) {}
  };

  const getSpecificZone = async (zoneId: any[]) => {
    const apiUrl = new URL(environment.api.baseUrl + 'Zone/' + zoneId);
    try {
      const result = await http.get(apiUrl.toString());
      if (result) {
        return result.data;
      }
    } catch {}
  };

  const getZones = async (search: string, pageNo: number) => {
    let response: PaginationResponseModel<Customer>;
    const apiUrl = new URL(environment.api.baseUrl + 'Zone');
    try {
      let searchExpression = '';
      searchExpression += search ? ` and name~like~${search}` : '';
      apiUrl.searchParams.set('$filter', searchExpression);
      apiUrl.searchParams.set('$orderby', 'name');
      apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
      apiUrl.searchParams.set('$top', '10');
      apiUrl.searchParams.set('$orderbydirection', 'desc');
      const result = await http.get(apiUrl.toString());
      if (result) {
        response = {
          TotalRecords: result.data.totalCount,
          Values: result.data.values,
        } as PaginationResponseModel<Customer>;
      } else {
        response = {
          TotalRecords: 0,
          Values: [],
        } as PaginationResponseModel<Customer>;
      }
    } catch (error) {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<Customer>;
    }
    return response;
  };

  const loadZoneOptions = async (
    search: string,
    loadedOptions: any,
    { page }: any
  ) => {
    const response = await getZones(search, page);
    const hasMore = Math.ceil(response.TotalRecords / 10) > page;

    return {
      options: response.Values.map((x) => {
        return {
          value: x.id.toString(),
          label: x.name,
        } as BaseModel;
      }),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const getTransportTypes = async (search: string, pageNo: number) => {
    let response: PaginationResponseModel<Customer>;
    const apiUrl = new URL(environment.api.baseUrl + 'TransportTypes');
    try {
      let searchExpression = '';
      searchExpression += search ? `name~like~${search}` : '';
      apiUrl.searchParams.set('$filter', searchExpression);
      apiUrl.searchParams.set('$orderby', 'name');
      apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
      apiUrl.searchParams.set('$top', '10');
      apiUrl.searchParams.set('$orderbydirection', 'desc');
      const result = await http.get(apiUrl.toString());
      if (result) {
        response = {
          TotalRecords: result.data.totalCount,
          Values: result.data.values,
        } as PaginationResponseModel<Customer>;
      } else {
        response = {
          TotalRecords: 0,
          Values: [],
        } as PaginationResponseModel<Customer>;
      }
    } catch (error) {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<Customer>;
    }
    return response;
  };

  const loadTransportTypeOptions = async (
    search: string,
    loadedOptions: any,
    { page }: any
  ) => {
    const response = await getTransportTypes(search, page);
    const hasMore = Math.ceil(response.TotalRecords / 10) > page;

    return {
      options: response.Values.map((x) => {
        return {
          value: x.id.toString(),
          label: x.name,
        } as BaseModel;
      }),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };
  const onCreateOption = async (inputValue: any) => {
    let name = {};
    name['name'] = inputValue;
    name['entityType'] = MasterCategoryEnumType.TransportCatalogue;
    const newOption: any = await PostCatagoryDropDown(
      name,
      i18nMessages.CommonSaveSuccessMsg,
      i18nMessages.CommonErrOccuredMsg
    );
    const increaseUniq = (uniq: any) => uniq + 1;
    getTransportCategories('', 1);
    setCacheUniq(increaseUniq);
    setCategory(newOption);
  };
  const TransportCatalougeData = () => {
    return (
      <>
        <Grid item lg={4} md={8}>
          <FormControl
            required
            margin="normal"
            displayLabel={I18n('TransportCatalogue.TransportCode')}
            id="TransportCode"
            name="transportCode"
            inputType="text"
            inputProps={{ maxLength: 30 }}
            value={transportCode}
            multiline={false}
            handleChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setTransportCode(event.target.value)}
            errorValue={true}
            errorMessage={errors.transportCode}
            readOnly={!isEdit}
          ></FormControl>
        </Grid>
        <Grid item lg={4} md={8}>
          <CustomSelectPaginateAdd
            required
            key={JSON.stringify(category)}
            additional={defaultAdditional}
            value={category}
            loadOptions={loadCategoryOptions}
            isSearchable={true}
            displayLabel={I18n('TransportCatalogue.Category')}
            placeholder={defaultCategoryValue.label}
            handleChange={(newValue: BaseModel) => {
              setCategory(newValue);
              if (newValue.value !== '0') {
                setErrors({
                  ...errors,
                  category: '',
                });
              }
            }}
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
            }}
            debounceTimeout={500}
            isMultiSelect={false}
            cacheUniqs={cacheUniq}
            onCreateOption={onCreateOption}
            errorValue={true}
            errorMessage={errors.category}
            readOnly={!isEdit}
          ></CustomSelectPaginateAdd>
        </Grid>
        <Grid item lg={4} md={8}>
          <CustomSelectPaginate
            required
            additional={defaultAdditional}
            value={fromZone}
            loadOptions={loadZoneOptions}
            handleChange={(newValue: BaseModel) => {
              setFromZone(newValue);
              if (newValue.value !== '0') {
                setErrors({
                  ...errors,
                  fromZone: '',
                });
              }
            }}
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
            }}
            placeholder={defaultZoneValue.label}
            isSearchable={true}
            displayLabel={I18n('TransportCatalogue.FromZone')}
            readOnly={!isEdit}
            debounceTimeout={500}
            isMultiSelect={false}
            errorValue={true}
            errorMessage={errors.fromZone}
          />
        </Grid>
        <Grid item lg={4} md={8}>
          <CustomSelectPaginate
            required
            additional={defaultAdditional}
            value={toZone}
            loadOptions={loadZoneOptions}
            handleChange={(newValue: BaseModel) => {
              setToZone(newValue);
              zoneData(Number(newValue.value));
              setErrors({
                ...errors,
                toZone: '',
              });
            }}
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
            }}
            placeholder={defaultZoneValue.label}
            isSearchable={true}
            displayLabel={I18n('TransportCatalogue.ToZone')}
            readOnly={!isEdit}
            debounceTimeout={500}
            isMultiSelect={false}
            errorValue={true}
            errorMessage={errors.toZone}
          />
        </Grid>
        <Grid item lg={4} md={8}>
          <CustomSelectPaginate
            required
            additional={defaultAdditional}
            value={transportType}
            loadOptions={loadTransportTypeOptions}
            handleChange={(newValue: any) => {
              setTransportType(newValue);
              if (newValue.value !== '0') {
                setErrors({
                  ...errors,
                  transportType: '',
                });
              }
            }}
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
            }}
            placeholder={defaultZoneValue.label}
            isSearchable={true}
            displayLabel={I18n('TransportCatalogue.TransportType')}
            readOnly={!isEdit}
            debounceTimeout={500}
            isMultiSelect={true}
            errorValue={true}
            errorMessage={errors.transportType}
          />
        </Grid>
        <Grid item lg={4} md={8}>
          <StatusSelect
            required
            placeholder={defaultValue.label}
            options={entityStatusList}
            isSearchable={false}
            displayLabel={I18n('Common.Status')}
            handleChange={(newValue: BaseModel) => onStatusChange(newValue)}
            readOnly={!isEdit}
            value={entityStatusList.find((x) => x.value === status.value)}
            errorValue={true}
            errorMessage={errors.statusId}
          ></StatusSelect>
        </Grid>
        <Grid item lg={4} md={6} sm={12}>
          <CustomSelectPaginate
            required
            readOnly={!isEdit}
            key={+ledgerAccount.value}
            additional={defaultAdditional}
            value={ledgerAccount}
            loadOptions={(search: string, prevOptions: any, page: any) =>
              loadLedgerOptions(search, prevOptions, page)
            }
            handleChange={async (newValue: any) => {
              setLedgerAccount(newValue);
            }}
            placeholder={defaultValue.label}
            isSearchable={true}
            displayLabel={i18nMessages.LedgerAccount}
            errorValue={true}
            errorMessage={errors.ledgerAccount}
            debounceTimeout={500}
            isMultiSelect={false}
          />
        </Grid>
        <Grid item lg={4} md={6} sm={12}>
          <CustomSelectPaginate
            required
            readOnly={!isEdit}
            key={+itemGroup.value}
            additional={defaultAdditional}
            value={itemGroup}
            loadOptions={(search: string, prevOptions: any, page: any) =>
              loadItemGroupOptions(search, prevOptions, page)
            }
            handleChange={async (newValue: any) => {
              setItemGroup(newValue);
            }}
            placeholder={defaultValue.label}
            isSearchable={true}
            displayLabel={i18nMessages.ItemGroup}
            errorValue={true}
            errorMessage={errors.itemGroups}
            debounceTimeout={500}
            isMultiSelect={false}
          />
        </Grid>
      </>
    );
  };

  useEffect(() => {
    getTransportCatalogueById();
  }, []);
  return (
    <div>
      <FormAccordian
        title={i18nMessages.TransportCatalogueDetailTitle}
        className="inner-heading"
        showActionButton={canEdit}
        details={TransportCatalougeData()}
        expanded={expanded}
        isEdit={isEdit}
        handleEditClick={handleEditClick}
        handleExpandCollapseClick={handleExpandCollapseClick}
        isSaveButton={isEdit}
        onSaveClick={handleSaveClick}
      ></FormAccordian>

      <FormAccordian
        title={i18nMessages.TransportCatalogueToZoneCountry}
        className="inner-heading"
        details={ToZoneCountriesData()}
        expanded={countriesExpanded}
        showActionButton={false}
        handleExpandCollapseClick={handleCountriesExpandCollapseClick}
        isSaveButton={isCountriesEdit}
        onSaveClick={handleSaveClick}
      ></FormAccordian>
    </div>
  );
}

export default TransportCatalogueDetails;
