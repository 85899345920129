import { useCallback, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import I18n from 'utilities/i18n';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  BaseModel,
  ImageUploadViewModel,
  MasterCategoryEnumType,
} from 'models/pagination-model';
import CustomizedSelectPaginate from 'common/CustomSelect/CustomSelectPaginate';
import { loadCategoryOptions } from 'components/deckpack-orders/DeckPackApiService';
import CloseIcon from '@mui/icons-material/Close';
import ImageViewer from 'react-simple-image-viewer';
import CustomSelectPaginateAdd from 'common/CustomSelect/CustomSelectPaginateAdd';
import { PostCatagoryDropDown } from 'common/DropDownAddAPI/DropDownApiService';

export default function ImageUploadAdd(props: any) {
  const defaultDropDownValue = {
    value: '0',
    label: I18n('Placeholders.Select'),
  } as BaseModel;

  const [currentImage, setCurrentImage] = useState(0);
  const [images, setImages] = useState<ImageUploadViewModel[]>([]);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const i18nMessages = {
    MaxAllowed: I18n('Common.MaxAllowed'),
    documentTag: I18n('Common.DocumentTag'),
    documentTagRequired: I18n('Document.Validation.DocumentTagRequired'),
    CommonSaveSuccessMsg: I18n('Common.SavedSuccessfully'),
    CommonErrOccuredMsg: I18n('Common.ErrorOccurred'),
  };
  const [cacheUniq, setCacheUniq] = useState(0);

  useEffect(() => {
    if (props.images && props.images.length) {
      const localImages: ImageUploadViewModel[] = [
        ...props.images,
      ] as ImageUploadViewModel[];
      localImages.forEach((x) => {
        x.error = x.documentTag ? '' : i18nMessages.documentTagRequired;
        x.documentTag = defaultDropDownValue;
      });
      setImages(localImages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.images]);

  const submit = async () => {
    props.onSave(images);
  };

  const openImageViewer = useCallback((index: any) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);
  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  const onCreateOption = async (inputValue: any, index: number) => {
    let name = {};
    name['name'] = inputValue;
    name['entityType'] = MasterCategoryEnumType.DocumentCategory;
    const newOption: any = await PostCatagoryDropDown(
      name,
      i18nMessages.CommonSaveSuccessMsg,
      i18nMessages.CommonErrOccuredMsg
    );
    const increaseUniq = (uniq: any) => uniq + 1;
    setCacheUniq(increaseUniq);
    const temporaryImages = [...images];
    temporaryImages[index].documentTag = newOption;
    setImages(temporaryImages);
  };

  return (
    <div>
      <Dialog
        open={props.isOpen}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className="grid-wrap">
            {images.map((src: ImageUploadViewModel, index: number) => (
              <Grid
                item
                xs={images && images.length === 1 ? 6 : 4}
                alignItems="center"
                justifyContent="center"
              >
                <Box
                  position="relative"
                  display="inline-block"
                  mr={2}
                  className="image-outer image-card"
                >
                  <img
                    src={src.previewUrl}
                    onClick={() => openImageViewer(index)}
                    width="100"
                    height="100"
                    key={index}
                    style={{ margin: '2px' }}
                    alt=""
                    className="text-center"
                  />
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    className="btn-right2"
                    component="label"
                    onClick={() => props.handleFileDelete(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
                <CustomSelectPaginateAdd
                  key={JSON.stringify(src.documentTag)}
                  additional={{
                    page: 1,
                  }}
                  value={src.documentTag}
                  loadOptions={(search: string, prevOptions: any, page: any) =>
                    loadCategoryOptions(
                      MasterCategoryEnumType.DocumentCategory,
                      search,
                      prevOptions,
                      page
                    )
                  }
                  handleChange={(newValue: BaseModel) => {
                    const temporaryImages = [...images];
                    temporaryImages[index].documentTag = newValue;
                    setImages(temporaryImages);
                  }}
                  placeholder={defaultDropDownValue.label}
                  isSearchable={true}
                  displayLabel={i18nMessages.documentTag}
                  debounceTimeout={500}
                  isMultiSelect={false}
                  name={index}
                  cacheUniqs={cacheUniq}
                  onCreateOption={(inputvalue: any) =>
                    onCreateOption(inputvalue, index)
                  }
                ></CustomSelectPaginateAdd>
                {props.sendToCustomer ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="sendToCustomer"
                        onChange={(e) => {
                          const temporaryImages = [...images];
                          temporaryImages[index].sendToCustomer =
                            e.target.checked;
                          setImages(temporaryImages);
                        }}
                        checked={src.sendToCustomer}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label={I18n('Common.SendToCustomer')}
                  />
                ) : (
                  <></>
                )}
              </Grid>
            ))}
            {isViewerOpen && (
              <ImageViewer
                src={images.map((x) => x.previewUrl)}
                currentIndex={currentImage}
                onClose={closeImageViewer}
                disableScroll={false}
                backgroundStyle={{
                  backgroundColor: 'rgba(0,0,0,0.9)',
                }}
                closeOnClickOutside={true}
              />
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={props.handleClose}
            color="neutral"
            variant="contained"
            disableElevation
          >
            {' '}
            {I18n('Common.Cancel')}
          </Button>
          <Button
            onClick={submit}
            autoFocus
            color="primary"
            variant="contained"
            disableElevation
          >
            {I18n('Common.Save')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
