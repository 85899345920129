import I18n from 'utilities/i18n';
import { useEffect, useCallback, useState } from 'react';
import http from '../../../utilities/httpService';
import { CommonProps, OrderByDirection } from 'models/pagination-model';
import { hasPermission } from 'utilities/protectedRoute';
import { Permission } from 'Permissions';
import { Button, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CustomDataGrid from 'common/datagrid/datagrid';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import {
  GridActionsCellItem,
  GridColumns,
  GridPaginationInitialState,
  GridRowParams,
  GridSortingInitialState,
  GridSortModel,
} from '@mui/x-data-grid';
import environment from 'environment';
import ConfirmationPopup from 'common/confirmationPopup/confirmationPopup';
import { toast } from 'react-toastify';
import AddRemoveStock from './AddRemoveStock';

function RemoveStockList(props: CommonProps) {
  const { entityId } = useParams();

  const [isDeleteModalOpen, setDeleteModal] = useState(false);
  const [isAddModalOpen, setAddModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [selectedId, setId] = useState();

  const [gridData, setGridData] = useState({
    sortOrder: OrderByDirection.Descending,
    sortBy: 'date',
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [10, 20, 50, 100],
    pageSize: 10,
    page: 0,
    searchExpression: '',
  });

  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));
  const getRemoveStocks = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl + 'ProductCatalogueRemoveStock'
    );
    try {
      apiUrl.searchParams.set(
        '$filter',
        gridData.searchExpression !== ''
          ? `ProductCatalogueId~=~${entityId} and PurchasedFrom~like~${gridData.searchExpression}`
          : `ProductCatalogueId~=~${entityId}`
      );
      apiUrl.searchParams.set('$orderby', gridData.sortBy);
      apiUrl.searchParams.set(
        '$skip',
        (gridData.page * gridData.pageSize).toString()
      );
      apiUrl.searchParams.set('$top', gridData.pageSize.toString());
      apiUrl.searchParams.set(
        '$orderbydirection',
        gridData.sortOrder.toString()
      );
      const result = await http.get(apiUrl.toString());
      if (result) {
        updateGridData('totalRows', result.data.totalCount);
        updateGridData('rows', result.data.values);
      }
    } catch (error) {}
  };

  const i18nMessages = {
    editedSuccessfully: I18n(
      'ProductCatalogues.RemoveStock.EditedSuccessfully'
    ),
    deletedSuccessfully: I18n(
      'ProductCatalogues.RemoveStock.DeletedSuccessfully'
    ),
    errorOccurred: I18n('ProductCatalogues.RemoveStock.ErrorOccurred'),
    addRemoveStockTitle: I18n('ProductCatalogues.RemoveStock.AddRemoveStock'),
    editRemoveStockTitle: I18n('ProductCatalogues.RemoveStock.EditRemoveStock'),
    RemoveStockTabTitle: I18n('ProductCatalogues.Tabs.RemoveStock'),
  };

  useEffect(() => {
    //RemoveStockListing API comes here
    getRemoveStocks();
  }, [
    gridData.page,
    gridData.pageSize,
    gridData.sortBy,
    gridData.sortOrder,
    gridData.searchExpression,
  ]);

  const handleChangePage = (pageNo: number) => {
    updateGridData('page', pageNo);
  };

  const handleChangeBasicSearch = (searchExpression: any) => {
    updateGridData('searchExpression', searchExpression.target.value);
  };
  const handleChangePageSize = (pageSize: number) => {
    updateGridData('pageSize', pageSize);
  };

  type Row = (typeof gridData.rows)[number];
  const deleteProductCatalogueRemoveStock = useCallback(
    (id: any) => () => {
      setDeleteModal(true);
      setId(id);
    },
    []
  );

  const addProductCatalogueRemoveStock = useCallback(() => {
    setEditData(null);
    setAddModal(true);
  }, []);

  const editProductCatalogueRemoveStock = useCallback(
    (data: any) => () => {
      setEditData(data);
      setAddModal(true);
    },
    []
  );

  const refreshData = async () => {
    handleCloseForAdd();
    await getRemoveStocks();
  };

  const columns: GridColumns<Row> = [
    {
      field: 'date',
      headerName: I18n('ProductCatalogues.RemoveStock.Date'),
      minWidth: 150,
      valueFormatter: (params) => moment(params?.value).format('DD/MM/YYYY'),
    },
    {
      field: 'quantity',
      headerName: I18n('ProductCatalogues.RemoveStock.Quantity'),
      minWidth: 150,
    },
    {
      field: 'remarks',
      headerName: I18n('ProductCatalogues.RemoveStock.Remarks'),
      flex: 1,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: I18n('Common.Actions'),
      width: 150,
      getActions: (params: GridRowParams) => {
        const actionButtons = [];
        actionButtons.push(
          <>
            {hasPermission(Permission.canEditProductCatalogueManagement) ? (
              <GridActionsCellItem
                onPointerEnterCapture={false}
                onPointerLeaveCapture={false}
                placeholder={''}
                icon={<EditIcon />}
                label={I18n('Common.Edit')}
                onClick={editProductCatalogueRemoveStock(params.row)}
              />
            ) : (
              <></>
            )}
            {hasPermission(Permission.canDeleteProductCatalogueManagement) ? (
              <GridActionsCellItem
                onPointerEnterCapture={false}
                onPointerLeaveCapture={false}
                placeholder={''}
                icon={<DeleteIcon />}
                label="Delete"
                onClick={deleteProductCatalogueRemoveStock(params.id)}
              />
            ) : (
              <></>
            )}
          </>
        );
        return actionButtons;
      },
    },
  ];

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    if (sortModel && sortModel.length) {
      updateGridData('sortBy', sortModel[0].field);
      updateGridData(
        'sortOrder',
        sortModel[0].sort === 'asc'
          ? OrderByDirection.Ascending
          : OrderByDirection.Descending
      );
    }
  }, []);

  const handleCloseForDelete = () => {
    setDeleteModal(false);
  };

  const handleOkForDelete = () => {
    setDeleteModal(false);
    deleteData(selectedId);
  };

  const handleCloseForAdd = () => {
    setAddModal(false);
  };

  const deleteData = async (id: any) => {
    const apiUrl = new URL(
      environment.api.baseUrl + 'ProductCatalogueRemoveStock/' + id
    );
    try {
      const result: any = await http.delete(apiUrl.toString());
      if (result && result.data) {
        toast.success(i18nMessages.deletedSuccessfully);
        getRemoveStocks();
      } else {
        toast.error(i18nMessages.errorOccurred);
      }
    } catch (error: any) {}
  };

  return (
    <div>
      <Typography variant="h5" className="inner-heading has-button">
        {i18nMessages.RemoveStockTabTitle}
        {hasPermission(Permission.canAddProductCatalogueManagement) ? (
          <div className="inner-btn-wrap">
            <Button
              color="primary"
              className="small-btn"
              disableElevation
              onClick={addProductCatalogueRemoveStock}
              variant="contained"
            >
              <FontAwesomeIcon icon={faPlus} className="fa-md" />
            </Button>
          </div>
        ) : (
          <></>
        )}
      </Typography>
      <div className="">
        <div className="content-block table-fix">
          <CustomDataGrid
            rowCount={gridData.totalRows}
            data={gridData.rows}
            columns={columns}
            pageNo={gridData.page}
            pageSize={gridData.pageSize}
            onSortModelChange={handleSortModelChange}
            onPageChange={handleChangePage}
            onPageSizeChange={handleChangePageSize}
            rowsPerPageOptions={gridData.rowsPerPageOptions}
            initialState={{
              sorting: {
                sortModel: [{ field: 'date', sort: 'desc' }],
              } as GridSortingInitialState,
              pagination: {
                page: gridData.page,
                pageSize: gridData.pageSize,
              } as GridPaginationInitialState,
            }}
          ></CustomDataGrid>

          <ConfirmationPopup
            isOpen={isDeleteModalOpen}
            data={selectedId}
            message={I18n('ProductCatalogues.RemoveStock.DeleteMessage')}
            handleClose={handleCloseForDelete}
            handleOk={handleOkForDelete}
            title={I18n('ProductCatalogues.RemoveStock.DeleteRemoveStock')}
          ></ConfirmationPopup>
          {isAddModalOpen && (
            <AddRemoveStock
              isOpen={isAddModalOpen}
              editData={editData}
              entityId={entityId}
              handleClose={handleCloseForAdd}
              title={
                editData == null
                  ? i18nMessages.addRemoveStockTitle
                  : i18nMessages.editRemoveStockTitle
              }
              refreshData={refreshData}
            ></AddRemoveStock>
          )}
        </div>
      </div>
    </div>
  );
}

export default RemoveStockList;
