import { useCallback, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import I18n from 'utilities/i18n';
import FormControl from 'common/formControl/formControl';
import { Grid } from '@mui/material';
import CustomizedSelect from 'common/CustomSelect/CustomSelect';
import {
  BaseModel,
  OrderByDirection,
  PaginationResponseModel,
  ResponseType,
} from 'models/pagination-model';
import environment from 'environment';
import { toast } from 'react-toastify';
import http from '../../utilities/httpService';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddressAutocompleteInputs from 'common/AddressAutocomplete/AddressAutocompleteInput';
import AddCity from 'common/Cities/AddCity';
import CustomSelectPaginateAdd from 'common/CustomSelect/CustomSelectPaginateAdd';
import { PostCityDropDown } from 'common/DropDownAddAPI/DropDownApiService';
import CustomizedPhone from 'common/formControl/formPhone';
import {
  LabelEntity,
  LabelMasterModel,
  saveLabelDetail,
} from './LabelApiService';

export default function LabelDetail(props: any) {
  const defaultValue = {
    value: '',
    label: I18n('Placeholders.Select'),
  } as BaseModel;

  const defaultDropDownValue = {
    value: '0',
    label: I18n('Placeholders.Select'),
  } as BaseModel;
  const [cityList, setCityList] = useState<BaseModel[]>([]);
  const [countryList, setCountryList] = useState<BaseModel[]>([]);
  const {
    selectedLabelId = 0,
    isOpen,
    handleClose,
    isHidden = false,
    newLocation,
    triggerFrom,
    newConsignee,
    newShipper,
  } = props;

  const [postalCode, setPostalCode] = useState('');
  const [address, setAddress] = useState('');
  const [country, setCountry] = useState(defaultDropDownValue);
  const [city, setCity] = useState(defaultDropDownValue);
  const [errors, setErrors] = useState<any>({});

  const [values, setValues] = useState({
    id: 0,
    address: '',
    address2: '',
    selectedCountry: defaultValue,
    selectedCity: defaultValue,
    cityId: '',
    countryId: '',
    postalCode: '',
    street: '',
    consignee: '',
    shipper: '',
    emergencyPhoneNumber: '',
    country: '',
    city: '',
  });
  const [cacheUniq, setCacheUniq] = useState(0);

  let isShipperRequired =
    (newShipper && newShipper !== undefined) ||
    triggerFrom === LabelEntity.Shipper;

  let isConsigneeRequired =
    (newConsignee && newConsignee !== undefined) ||
    triggerFrom === LabelEntity.Consignee;

  useEffect(() => {
    if (newConsignee !== undefined || newShipper !== undefined) {
      setValues({
        ...values,
        address: newLocation !== undefined ? newLocation : values.address,
        consignee: newConsignee !== undefined ? newConsignee : values.consignee,
        shipper: newShipper !== undefined ? newShipper : values.shipper,
      });
    }
  }, [newLocation, newConsignee, newShipper]);

  const getLabelDetail = async (id: number) => {
    const apiUrl = new URL(environment.api.baseUrl + 'LabelMaster/' + id);
    try {
      const result = await http.get(apiUrl.toString());
      if (result && result.data) {
        // SET VALUES
        setValues({
          ...values,
          id: result.data.id,
          consignee: result.data.consignee
            ? result.data.consignee
            : values.consignee,
          shipper: result.data.shipper ? result.data.shipper : values.shipper,
          address2: result.data.address2,
          selectedCity: {
            value: result.data.cityId,
            label: result.data.city,
          },
          selectedCountry: {
            value: result.data.countryId,
            label: result.data.country,
          },
          country: result.data.country,
          city: result.data.city,
          cityId: result.data.cityId,
          postalCode: result.data.postalCode,
          street: result.data.street,
          emergencyPhoneNumber: result.data.emergencyPhoneNumber,
        });
      }
    } catch {}
  };

  const onBlurZipCode = async (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const apiUrl = new URL(
      environment.api.baseUrl +
        'Locations/GetLocationByZipCode/' +
        String(event.target.value)
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result) {
        if (result.data) {
          const city: BaseModel = {
            label: String(result.data.city),
            value: String(result.data.cityId),
          };
          const country: BaseModel = {
            label: String(result.data.country),
            value: String(result.data.countryId),
          };
          setValues({
            ...values,
            selectedCity: city,
            cityId: city.value,
            selectedCountry: country,
            countryId: country.value,
          });
          setCity(city);
          setCountry(country);
        } else {
        }
      } else {
        toast.error(I18n('Common.ErrorOccurred'));
      }
    } catch (error: any) {
      setValues({
        ...values,
        selectedCity: defaultValue,
        cityId: '',
        selectedCountry: defaultValue,
        countryId: '',
      });
      setCity(defaultValue);
    }
  };

  let countryLst: Array<BaseModel> = [];
  const getCountryList = (countries: any[]) => {
    countryLst = [defaultDropDownValue];
    countries.forEach((getCountryList: any) => {
      countryLst.push({
        value: getCountryList.key,
        label: getCountryList.value,
      } as BaseModel);
    });

    return countryLst;
  };

  const getCountries = async () => {
    let countryLst: any[] = [];

    const apiUrl = new URL(environment.api.baseUrl + 'Country');
    try {
      apiUrl.searchParams.set('$orderby', 'name');
      apiUrl.searchParams.set('$skip', '0');
      apiUrl.searchParams.set('$top', '5000');
      apiUrl.searchParams.set(
        '$orderbydirection',
        OrderByDirection.Ascending.toString()
      );

      const result = await http.get(apiUrl.toString());
      if (result) {
        result.data.values.map(function (item: any) {
          countryLst.push({
            key: item['id'],
            value: item['name'],
          });
        });

        let data = getCountryList(countryLst);
        setCountryList(data);
      }
    } catch (error) {}
  };

  let cityLst: Array<BaseModel> = [];
  const getCityList = (cities: any[]) => {
    cityLst = [defaultDropDownValue];
    cities.forEach((city: any) => {
      cityLst.push({
        value: city.key,
        label: city.value,
      } as BaseModel);
    });

    return cityLst;
  };

  const defaultAdditional: any = {
    page: 1,
  };

  const loadCitiesOptions = async (
    search: string,
    loadedOptions: any,
    { page }: any
  ) => {
    const response = await getCities(search, page);
    const hasMore = Math.ceil(response.TotalRecords / 500) > page;

    return {
      options: response.Values.map((x) => {
        return {
          value: x.value.toString(),
          label: x.label,
        } as BaseModel;
      }),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const getCities = async (search: string = '', pageNo: number = 1) => {
    let cityLst: any[] = [];
    let response: PaginationResponseModel<BaseModel>;
    const apiUrl = new URL(environment.api.baseUrl + 'Cities');
    try {
      if (search && search !== undefined) {
        var searchExpression = '';
        searchExpression += search ? `name~like~${search}` : '';
        apiUrl.searchParams.set('$filter', searchExpression);
      }
      apiUrl.searchParams.set('$orderby', 'name');
      if (pageNo > 1) {
        apiUrl.searchParams.set('$skip', ((pageNo - 1) * 500).toString());
        apiUrl.searchParams.set('$top', '500');
      } else {
        apiUrl.searchParams.set('$top', '10000');
        apiUrl.searchParams.set('$skip', '0');
      }

      apiUrl.searchParams.set(
        '$orderbydirection',
        OrderByDirection.Ascending.toString()
      );

      const result = await http.get(apiUrl.toString());
      if (result) {
        result.data.values.map((item: any) => {
          cityLst.push({
            key: item['id'],
            value: item['name'],
          });
        });

        let data = getCityList(cityLst);
        setCityList(data);

        response = {
          TotalRecords: result.data.totalCount,
          Values: data,
        } as PaginationResponseModel<BaseModel>;
      } else {
        response = {
          TotalRecords: 0,
          Values: [],
        } as PaginationResponseModel<BaseModel>;
      }
    } catch (error) {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<BaseModel>;
    }
    return response;
  };

  const onCreateOption = async (inputValue: any) => {
    const name = {
      code: '',
      name: inputValue,
      otherComments: '',
      statusId: 1,
    };
    const newOption: any = await PostCityDropDown(
      name,
      i18nMessages.CityCommonSaveSuccessMsg,
      i18nMessages.CommonErrOccuredMsg
    );
    const increaseUniq = (uniq: any) => uniq + 1;
    setCacheUniq(increaseUniq);
    onCityChange(newOption);
    getCities('', 1);
  };

  const onCityChange = (data: any) => {
    let cityLabel =
      data?.label === defaultDropDownValue.label ? null : data?.label;
    let cityValue =
      data?.value === defaultDropDownValue.value ? null : data?.value;
    setValues({
      ...values,
      cityId: cityValue,
      selectedCity: { value: cityValue, label: cityLabel },
    });
  };

  const validate = () => {
    let temp: any = {};
    let isValid = true;
    if (values.selectedCountry.value === defaultDropDownValue.value) {
      temp.country = i18nMessages.CountryRequired;
      isValid = false;
    }
    if (values.selectedCity.value === defaultDropDownValue.value) {
      temp.city = i18nMessages.CityRequired;
      isValid = false;
    }

    if (!values.postalCode) {
      temp.postalCode = i18nMessages.PostalCodeRequired;
      isValid = false;
    } else if (values.postalCode.length > 20) {
      temp.postalCode = i18nMessages.PostalCodeMaxLength;
    }

    if (!values.street) {
      temp.street = i18nMessages.AddressRequired;
      isValid = false;
    } else if (values.street.length > 250) {
      temp.street = i18nMessages.AddressMaxLength;
    }

    if (
      (newConsignee && newConsignee !== undefined) ||
      triggerFrom === LabelEntity.Consignee
    ) {
      if (!values.consignee) {
        temp.consignee = i18nMessages.ConsigneeRequired;
        isValid = false;
      } else if (values.consignee.length > 250) {
        temp.consignee = i18nMessages.ConsigneeMaxLength;
      }
    }

    if (
      (newShipper && newShipper !== undefined) ||
      triggerFrom === LabelEntity.Shipper
    ) {
      if (!values.shipper) {
        temp.shipper = i18nMessages.ShipperRequired;
        isValid = false;
      } else if (values.shipper.length > 250) {
        temp.shipper = i18nMessages.ShipperMaxLength;
      }
    }

    if (
      (!values.consignee || values.consignee?.trim().length == 0) &&
      (!values.shipper || values.shipper?.trim().length == 0)
    ) {
      temp.shipper = i18nMessages.ConditionalRequired;
      temp.consignee = i18nMessages.ConditionalRequired;
      isValid = false;
    }

    setErrors({
      ...temp,
    });

    if (isValid) {
      return true;
    } else {
      return false;
    }
  };

  const ResetForm = () => {
    setValues({
      ...values,
      id: 0,
      postalCode: '',
      address: '',
      address2: '',
      street: '',
      consignee: '',
      shipper: '',
      emergencyPhoneNumber: '',
      selectedCountry: defaultDropDownValue,
      selectedCity: defaultDropDownValue,
    });
    setErrors({});
  };

  const submit = async () => {
    if (validate()) {
      var label = {
        id: values.id,
        consignee: values.consignee ? String(values.consignee).trim() : '',
        shipper: values.shipper ? String(values.shipper).trim() : '',
        emergencyPhoneNumber: values.emergencyPhoneNumber,
        address2: values.address2 ? String(values.address2).trim() : '',
        street: values.street ? String(values.street).trim() : '',
        postalCode: values.postalCode,
        cityId: Number(values.selectedCity.value),
        countryId: Number(values.selectedCountry.value),
      } as LabelMasterModel;

      saveLabelDetail(label).then((response) => {
        if (response.isSuccess) {
          if (response.responseType === ResponseType.AddSuccess) {
            toast.success(i18nMessages.LabelSaveSuccess);
          }
          if (response.responseType === ResponseType.UpdateSuccess) {
            toast.success(i18nMessages.LabelUpdateSuccess);
          }
          ResetForm();
          if (triggerFrom !== undefined && triggerFrom > 0) {
            response.data.selectedCountry = values.selectedCountry;
            response.data.selectedCity = values.selectedCity;
            props.OnSave(response.data, triggerFrom);
          } else {
            props.OnSave();
          }
        } else {
          toast.success(i18nMessages.CommonErrOccuredMsg);
        }
      });
    }
  };

  useEffect(() => {
    getCountries();
    getCities();
  }, []);

  const i18nMessages = {
    AddCity: I18n('Cities.AddDialogTitle'),
    CommonSaveSuccessMsg: I18n('Common.SavedSuccessfully'),
    CommonErrOccuredMsg: I18n('Common.ErrorOccurred'),
    Save: I18n('Common.Save'),
    Cancel: I18n('Common.Cancel'),
    SearchAddress: I18n('Customer.SearchAddress'),
    CityCommonSaveSuccessMsg: I18n('Common.CitySavedSuccessfully'),
    LabelSaveSuccess: I18n('LabelMaster.LabelSaveSuccess'),
    LabelUpdateSuccess: I18n('LabelMaster.LabelUpdateSuccess'),
    City: I18n('LabelMaster.City'),
    Country: I18n('LabelMaster.Country'),
    Consignee: I18n('LabelMaster.Consignee'),
    Address2: I18n('LabelMaster.Address2'),
    PostalCode: I18n('LabelMaster.PostalCode'),
    Address: I18n('LabelMaster.Address'),
    Shipper: I18n('LabelMaster.Shipper'),
    EmergencyPhoneNumber: I18n('LabelMaster.EmergencyPhoneNumber'),
    ShipperRequired: I18n('LabelMaster.Validation.ShipperRequired'),
    ShipperMaxLength: I18n('LabelMaster.Validation.ShipperMaxLength'),
    CountryRequired: I18n('LabelMaster.Validation.CountryRequired'),
    CityRequired: I18n('LabelMaster.Validation.CityRequired'),
    PostalCodeRequired: I18n('LabelMaster.Validation.PostalCodeRequired'),
    PostalCodeMaxLength: I18n('LabelMaster.Validation.PostalCodeMaxLength'),
    AddressRequired: I18n('LabelMaster.Validation.AddressRequired'),
    AddressMaxLength: I18n('LabelMaster.Validation.AddressMaxLength'),
    ConsigneeRequired: I18n('LabelMaster.Validation.ConsigneeRequired'),
    ConditionalRequired: I18n('LabelMaster.Validation.ConditionalRequired'),
    ConsigneeMaxLength: I18n('LabelMaster.Validation.ConsigneeMaxLength'),
    AddLabel: I18n('LabelMaster.AddLabel'),
    EditLabel: I18n('LabelMaster.EditLabel'),
  };

  const [isAddCityModalOpen, setIsAddCityModal] = useState(false);
  const [locationData, setLocationData] = useState({
    country: defaultDropDownValue,
    postcode: '',
    place: defaultDropDownValue,
  });

  //Autocomplete
  const autocompleteHandleChange = async (
    value: string,
    selectedPlace: any
  ) => {
    const autoSelectValues = {
      country: values.selectedCountry,
      postalCode: values.postalCode,
      city: values.selectedCity,
    };
    //Country
    if (selectedPlace.country && selectedPlace.country !== undefined) {
      let selectedCountryOpt = countryList.find(
        (option: any) => option.label === selectedPlace.country
      );
      autoSelectValues.country = selectedCountryOpt
        ? selectedCountryOpt
        : country;
    }
    //City or Place
    if (selectedPlace.place && selectedPlace.place !== undefined) {
      let selectedCityOpt = cityList.find(
        (option: any) => option.label === selectedPlace.place
      );

      //Add new city in db
      if (!selectedCityOpt || selectedCityOpt === undefined) {
        selectedCityOpt = await addNewCity(selectedPlace.place);
      }

      autoSelectValues.city = selectedCityOpt ? selectedCityOpt : city;
    }
    //Postal code
    if (selectedPlace.postcode && selectedPlace.postcode !== undefined) {
      autoSelectValues.postalCode = selectedPlace.postcode;
      setPostalCode(autoSelectValues.postalCode);
    }
    // setAddress(value);
    setValues({
      ...values,
      address: value,
      street: selectedPlace.number ? selectedPlace.number : values.street,
      selectedCountry: autoSelectValues.country,
      selectedCity: autoSelectValues.city,
      postalCode: autoSelectValues.postalCode,
    });
  };

  const addNewCity = async (value: string) => {
    const name = {
      code: value,
      name: value,
      otherComments: value,
      statusId: 1,
    };
    const newOption: any = await PostCityDropDown(
      name,
      i18nMessages.CityCommonSaveSuccessMsg,
      i18nMessages.CommonErrOccuredMsg
    );

    return newOption;
  };

  const onAddCity = useCallback(() => {
    setIsAddCityModal(true);
  }, []);

  const onCloseAddCity = () => {
    setIsAddCityModal(false);
  };

  const onSubmitCity = async (cityModel: any) => {
    await getCities();
    onCloseAddCity();
  };

  const phoneOnChange = (phone: any) => {
    const phoneNum: string = phone.replace(/[^\d\+]/g, '');
    setValues({ ...values, emergencyPhoneNumber: phoneNum });
  };

  useEffect(() => {
    if (selectedLabelId > 0) {
      getLabelDetail(selectedLabelId);
    }
  }, [selectedLabelId]);

  const resetClose = () => {
    ResetForm();
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={resetClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={'sm'}
        fullWidth={true}
      >
        <DialogTitle>
          {selectedLabelId && selectedLabelId > 0
            ? i18nMessages.EditLabel
            : i18nMessages.AddLabel}
          <IconButton
            aria-label="close"
            onClick={resetClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className="grid-wrap two-col">
            {!isHidden || triggerFrom === LabelEntity.Shipper ? (
              <Grid item xs={6}>
                <FormControl
                  margin="normal"
                  displayLabel={i18nMessages.Shipper}
                  id="shipper"
                  inputType="text"
                  inputProps={{ maxLength: 500 }}
                  handleChange={(
                    event: React.ChangeEvent<
                      HTMLTextAreaElement | HTMLInputElement
                    >
                  ) =>
                    setValues({
                      ...values,
                      shipper: event.target.value,
                    })
                  }
                  value={values.shipper}
                  multiline={false}
                  errorValue={errors.shipper}
                  errorMessage={errors.shipper}
                  required={isShipperRequired}
                ></FormControl>
              </Grid>
            ) : (
              <></>
            )}
            {!isHidden || triggerFrom === LabelEntity.Consignee ? (
              <Grid item xs={6}>
                <FormControl
                  margin="normal"
                  displayLabel={i18nMessages.Consignee}
                  id="consignee"
                  inputType="text"
                  inputProps={{ maxLength: 500 }}
                  handleChange={(
                    event: React.ChangeEvent<
                      HTMLTextAreaElement | HTMLInputElement
                    >
                  ) =>
                    setValues({
                      ...values,
                      consignee: event.target.value,
                    })
                  }
                  value={values.consignee}
                  multiline={false}
                  errorValue={errors.consignee}
                  errorMessage={errors.consignee}
                  required={isConsigneeRequired}
                ></FormControl>
              </Grid>
            ) : (
              <></>
            )}
            <Grid item xs={6}>
              <CustomizedPhone
                title={i18nMessages.EmergencyPhoneNumber}
                value={values.emergencyPhoneNumber}
                name="emergencyPhoneNumber"
                onChange={(emergencyPhoneNumber: any) =>
                  phoneOnChange(emergencyPhoneNumber)
                }
              ></CustomizedPhone>
            </Grid>
            <Grid item xs={12}>
              <AddressAutocompleteInputs
                inputProps={{ maxLength: 500 }}
                displayLabel={i18nMessages.SearchAddress}
                handleChange={autocompleteHandleChange}
                required={false}
                // value={values.address}
              ></AddressAutocompleteInputs>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                margin="normal"
                displayLabel={i18nMessages.Address}
                id="address"
                inputType="text"
                inputProps={{ maxLength: 500 }}
                handleChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ) =>
                  setValues({
                    ...values,
                    street: event.target.value,
                  })
                }
                value={values.street}
                multiline={false}
                errorValue={errors.street}
                errorMessage={errors.street}
                required={true}
              ></FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                margin="normal"
                displayLabel={i18nMessages.Address2}
                id="Address2"
                inputType="text"
                inputProps={{ maxLength: 250 }}
                handleChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ) =>
                  setValues({
                    ...values,
                    address2: event.target.value,
                  })
                }
                value={values.address2}
                multiline={false}
              ></FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl
                margin="normal"
                displayLabel={i18nMessages.PostalCode}
                id="PostalCode"
                inputType="text"
                inputProps={{ maxLength: 20 }}
                handleChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ) =>
                  setValues({
                    ...values,
                    postalCode: event.target.value,
                  })
                }
                handleBlur={onBlurZipCode}
                value={values.postalCode}
                multiline={false}
                errorValue={errors.postalCode}
                errorMessage={errors.postalCode}
                required={true}
              ></FormControl>
            </Grid>
            <Grid item xs={6}>
              <div className="add-button-address">
                <CustomSelectPaginateAdd
                  additional={defaultAdditional}
                  loadOptions={loadCitiesOptions}
                  handleChange={(newValue: BaseModel) =>
                    setValues({
                      ...values,
                      selectedCity: newValue,
                    })
                  }
                  value={values.selectedCity}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                  }}
                  placeholder={defaultDropDownValue.label}
                  isSearchable={true}
                  displayLabel={i18nMessages.City}
                  errorValue={true}
                  errorMessage={errors.city}
                  readOnly={false}
                  debounceTimeout={500}
                  isMultiSelect={false}
                  cacheUniqs={cacheUniq}
                  onCreateOption={(inputValue: any) =>
                    onCreateOption(inputValue)
                  }
                  required={true}
                ></CustomSelectPaginateAdd>
              </div>
            </Grid>
            <Grid item xs={6}>
              <CustomizedSelect
                placeholder={defaultDropDownValue.label}
                options={countryList}
                isSearchable={true}
                displayLabel={i18nMessages.Country}
                handleChange={(newValue: BaseModel) =>
                  setValues({
                    ...values,
                    selectedCountry: newValue,
                  })
                }
                value={values.selectedCountry}
                errorValue={true}
                errorMessage={errors.country}
                required={true}
              ></CustomizedSelect>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="btn-wrap">
          <Button
            onClick={resetClose}
            color="neutral"
            disableElevation
            variant="contained"
          >
            {' '}
            {i18nMessages.Cancel}
          </Button>
          <Button
            onClick={submit}
            autoFocus
            color="primary"
            disableElevation
            variant="contained"
          >
            {i18nMessages.Save}
          </Button>
        </DialogActions>
      </Dialog>
      {isAddCityModalOpen && (
        <AddCity
          isOpen={isAddCityModalOpen}
          onClose={onCloseAddCity}
          onSubmit={onSubmitCity}
          title={i18nMessages.AddCity}
          locationData={locationData}
        ></AddCity>
      )}
    </div>
  );
}
