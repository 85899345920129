import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import I18n from 'utilities/i18n';
import { Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import http from '../../../utilities/httpService';
import { toast } from 'react-toastify';
import environment from 'environment';
import FormControl from 'common/formControl/formControl';
import DecimalInput from 'common/DecimalInput/DecimalInput';
import { ProductCatalogueRemoveStock } from 'models/product-catalogue';

export default function AddRemoveStock(props: any) {
  const { isOpen, handleClose, title, entityId, editData } = props;

  const [values, setValues] = useState<ProductCatalogueRemoveStock>({
    productCatalogueId: entityId,
    date: new Date(),
    remarks: '',
    id: 0,
    quantity: '',
  });

  const showMessageRefresh = () => {
    toast.success(
      values.id
        ? i18nMessages.updatedSuccessfully
        : i18nMessages.savedSuccessfully
    );
    setDefaultValuesForControls();
    props.refreshData();
  };

  const setDefaultValuesForControls = () => {
    setValues({ ...values, quantity: '' });
    setValues({ ...values, remarks: '' });
  };

  const [errors, setErrors] = useState({
    quantity: '',
    remarks: '',
  });
  const [warehouseQuantity, setWarehouseQuantity] = useState(0);

  const i18nMessages = {
    commonFieldIsRequired: I18n('Common.FieldIsRequired'),
    supplierLimitExeed: I18n(
      'ProductCatalogues.ProductPurchases.Validation.SupplierMaxLength'
    ),
    commonInvalidQuantity: I18n('Common.EnteredQuantityIsNotValid'),
    somethingWentWrong: I18n('Common.SomethingWentWrong'),
    updatedSuccessfully: I18n(
      'ProductCatalogues.RemoveStock.UpdatedSuccessfully'
    ),
    savedSuccessfully: I18n('ProductCatalogues.RemoveStock.SavedSuccessfully'),
  };

  const validate = (fieldValues: any = values) => {
    let temp: any = { ...errors };
    if ('quantity' in fieldValues) {
      temp.quantity = +fieldValues.quantity
        ? ''
        : i18nMessages.commonFieldIsRequired;
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === '');
    }
  };

  const submit = async () => {
    if (validate()) {
      const removeStock = {
        quantity: values.quantity,
        remarks: values.remarks,
        productCatalogueId: values.productCatalogueId,
        date: values.date,
        id: values.id,
      };
      const apiUrl =
        editData === null
          ? new URL(environment.api.baseUrl + 'ProductCatalogueRemoveStock')
          : new URL(
              environment.api.baseUrl +
                'ProductCatalogueRemoveStock/' +
                values.id
            );
      try {
        const result: any =
          editData === null
            ? await http.post(apiUrl.toString(), removeStock)
            : await http.put(apiUrl.toString(), removeStock);
        if (editData == null && result !== null && result.data != null) {
          showMessageRefresh();
        } else if (editData != null && result !== null && result.data) {
          showMessageRefresh();
        } else {
          toast.error(i18nMessages.somethingWentWrong);
        }
      } catch (error: any) {
        if (error.response) {
          let temp = { ...errors };
          if (error.response.data) {
            for (const item of error.response.data.errors) {
              if (
                item.field === 'Quantity' &&
                item.errorMessage === 'ValidQuantity'
              ) {
                temp.quantity = i18nMessages.commonInvalidQuantity;
              }
            }
          }
          setErrors({
            ...temp,
          });
        }
      }
    }
  };

  const getAvailableWarehouseQuantity = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl +
        'ProductCatalogues/GetWarehouseQuantityAsync?id=' +
        entityId
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result) {
        setWarehouseQuantity(result.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (editData) {
      setValues({
        productCatalogueId: editData.productCatalogueId,
        quantity: editData.quantity,
        remarks: editData.remarks,
        id: editData.id,
        date: new Date(),
      });
    } else {
      setValues({
        productCatalogueId: entityId,
        quantity: '',
        remarks: '',
        id: 0,
        date: new Date(),
      });
    }
    getAvailableWarehouseQuantity();
  }, [editData]);

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={'sm'}
        fullWidth={true}
      >
        <DialogTitle>
          {title}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className="grid-wrap">
            <Grid item lg={6} md={8}>
              <DecimalInput
                displayLabel={
                  I18n('ProductCatalogues.ProductPurchases.Quantity') +
                  ' - ' +
                  I18n('ProductCatalogues.InWarehouse')
                }
                name="availablequantity"
                value={warehouseQuantity}
                readOnly={true}
              ></DecimalInput>
            </Grid>
            <Grid item lg={6} md={8}>
              <DecimalInput
                required
                displayLabel={I18n(
                  'ProductCatalogues.ProductPurchases.Quantity'
                )}
                name="quantity"
                inputProps={{ maxLength: 10 }}
                handleChange={(event: any) => {
                  setValues({
                    ...values,
                    quantity: event.target.value,
                  });
                }}
                errorValue={true}
                errorMessage={errors.quantity}
                value={values.quantity}
              ></DecimalInput>
            </Grid>
            <Grid item lg={12} md={12}>
              <FormControl
                margin="normal"
                displayLabel={I18n(
                  'ProductCatalogues.ProductPurchases.Remarks'
                )}
                id="Remarks"
                name="remarks"
                inputType="text"
                inputProps={{ maxLength: 150 }}
                multiline={false}
                handleChange={(event: any) => {
                  setValues({
                    ...values,
                    remarks: event.target.value,
                  });
                }}
                value={values.remarks}
              ></FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="btn-wrap">
          <Button
            onClick={handleClose}
            color="neutral"
            disableElevation
            variant="contained"
          >
            {I18n('Common.Cancel')}
          </Button>
          <Button
            onClick={submit}
            autoFocus
            color="primary"
            disableElevation
            variant="contained"
          >
            {I18n('Common.Save')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
