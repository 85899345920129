import I18n from 'utilities/i18n';
import { useEffect, useCallback, useState } from 'react';
import http from '../../../utilities/httpService';
import {
  BaseModel,
  Department,
  EntityTypeEnum,
  OrderByDirection,
  TypeDescription,
} from 'models/pagination-model';
import { Button, Grid, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CustomDataGrid from 'common/datagrid/datagrid';
import { useParams } from 'react-router-dom';
import {
  GridActionsCellItem,
  GridColumns,
  GridPaginationInitialState,
  GridRowParams,
  GridSortingInitialState,
  GridSortModel,
} from '@mui/x-data-grid';
import environment from 'environment';
import ConfirmationPopup from 'common/confirmationPopup/confirmationPopup';
import { Permission } from 'Permissions';
import { hasPermission } from 'utilities/protectedRoute';
import EditIcon from '@mui/icons-material/Edit';
import AddSpecialPricing from 'common/special-pricing/add-special-pricing';
import CustomizedSelect from 'common/CustomSelect/CustomSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Utilities } from 'utilities/Utilities';
import { toast } from 'react-toastify';
import AddTransportSpecialPricing from './AddTransportSpecialPricing';

function CustomerSpecialPricingList(props: any) {
  const { customerName } = props;
  const params = useParams();
  const [gridData, setGridData] = useState({
    sortOrder: OrderByDirection.Descending,
    sortBy: 'EntityType',
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [10, 20, 50, 100],
    pageSize: 10,
    page: 0,
    searchExpression: '',
  });
  const [isDeleteModalOpen, setDeleteModal] = useState(false);
  const [selectedId, setId] = useState<any>({});
  const [isAddModalOpen, setAddModal] = useState(false);
  const [isAddTransportModalOpen, setAddTransportModal] = useState(false);
  const [entityType, setEntityType] = useState('');
  const [editData, setEditData] = useState<any>(null);
  const [addData, setAddData] = useState<any>(null);
  const customerPortalUser = Utilities.getIsCPUser();
  const [open, setOpen] = useState(false);

  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));

  const getCustomerSpecialPricing = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl +
        'Customer/GetCustomerSpecialPricing/' +
        params.entityId
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result) {
        updateGridData('totalRows', result.data.length);
        updateGridData('rows', result.data);
      }
    } catch (error) {}
  };

  const i18nMessages = {
    deletedSuccessfully: I18n('Customer.DeletedSuccessfully'),
    errorOccurred: I18n('Customer.ErrorOccurred'),
    Both: I18n('Customer.DecPack') + ',' + I18n('Customer.Shipping'),
    DecPack: I18n('Customer.DecPack'),
    Shipping: I18n('Customer.Shipping'),
    Status: I18n('Customer.Status'),
    Actions: I18n('Common.Actions'),
    Type: I18n('ShippingMaterials.Type'),
    SpecialPricing: I18n('Customer.Tabs.SpecialPricing'),
    PricingType: I18n('Customer.Tabs.PricingType'),
    ShippingSellingPrice: I18n('SpecialPricing.ShippingSellingPrice'),
    DecPackSellingPrice: I18n('SpecialPricing.DecPackSellingPrice'),
    TransportTypes: I18n('Partner.TransportTypes'),
    Product: I18n('ShippingMaterials.ProductCatalogue'),
    Service: I18n('ShippingMaterials.ServiceCatalogue'),
    Transport: I18n('ShippingMaterials.TransportCatalogue'),
    Add: I18n('Common.Add'),
    Name: I18n('Customer.Name'),
  };

  const defaultValue = {
    value: '',
    label: i18nMessages.Add + ' ' + i18nMessages.SpecialPricing,
  } as BaseModel;

  let typeList: Array<BaseModel> = [];
  const getShippingMaterialList = () => {
    typeList = [];

    TypeDescription.forEach((value: string, key: number) => {
      typeList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };

  getShippingMaterialList();

  useEffect(() => {
    getCustomerSpecialPricing();
  }, [
    gridData.page,
    gridData.pageSize,
    gridData.sortBy,
    gridData.sortOrder,
    gridData.searchExpression,
  ]);

  const handleChangePage = (pageNo: number) => {
    updateGridData('page', pageNo);
  };

  const handleChangePageSize = (pageSize: number) => {
    updateGridData('pageSize', pageSize);
  };

  type Row = (typeof gridData.rows)[number];
  const deleteSpecialPricing = (row: any) => () => {
    setDeleteModal(true);
    setId({
      customerId: row.customerId,
      entityType: row.entityType,
      entityId: row.entityId,
      transportTypeId: row.transportTypeId,
      id: row.id,
    });
  };

  const columns: GridColumns<Row> = [
    {
      field: 'id',
      headerName: 'id',
      flex: 1,
      hide: true,
    },
    {
      field: 'entityType',
      headerName: i18nMessages.Type,
      flex: 1,
      sortable: false,
      renderCell: (params: any) => {
        let respStr = '';
        if (params.formattedValue !== '') {
          respStr =
            params.formattedValue === EntityTypeEnum.ProductCatalogue
              ? i18nMessages.Product
              : params.formattedValue === EntityTypeEnum.ServiceCatalogue
              ? i18nMessages.Service
              : i18nMessages.Transport;
        }
        return (
          <Tooltip title={respStr}>
            <div>{respStr}</div>
          </Tooltip>
        );
      },
    },
    {
      field: 'itemName',
      headerName: i18nMessages.Name,
      flex: 1,
      renderCell: (params: any) => {
        if (params.row.entityType !== EntityTypeEnum.TransportCatalogue) {
          return (
            <Tooltip title={params.row.itemCode + '-' + params.row.itemName}>
              <div>{params.row.itemCode + '-' + params.row.itemName}</div>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip title={params.row.itemName}>
              <div>{params.row.itemName}</div>
            </Tooltip>
          );
        }
      },
    },
    {
      field: 'transportTypeId',
      headerName: 'transportTypeId',
      flex: 1,
      hide: true,
    },
    {
      field: 'transportTypeName',
      headerName: i18nMessages.TransportTypes,
      sortable: false,
      flex: 1,
    },
    {
      field: 'entityId',
      headerName: 'entityId',
      flex: 1,
      hide: true,
    },
    {
      field: 'customerId',
      headerName: 'customerId',
      flex: 1,
      hide: true,
    },
    {
      field: 'customerName',
      headerName: 'customerName',
      flex: 1,
      hide: true,
    },
    {
      field: 'pricingType',
      headerName: i18nMessages.PricingType,
      flex: 1,
      sortable: false,
      renderCell: (params: any) => {
        let respStr = '';
        if (params.formattedValue !== '') {
          respStr =
            params.formattedValue === Department.Both
              ? i18nMessages.Both
              : params.formattedValue === Department.DecPack
              ? i18nMessages.DecPack
              : i18nMessages.Shipping;
        }
        return (
          <Tooltip title={respStr}>
            <div>{respStr}</div>
          </Tooltip>
        );
      },
    },
    {
      field: 'pricingTypeDescription',
      headerName: 'pricingTypeDescription',
      flex: 1,
      hide: true,
    },
    {
      field: 'shippingSellingPrice',
      headerName: i18nMessages.ShippingSellingPrice,
      flex: 1,
      sortable: false,
      renderCell: (params: any) => {
        return params.value ? params.value.toString().replaceAll('.', ',') : '';
      },
    },
    {
      field: 'decPackSellingPrice',
      headerName: i18nMessages.DecPackSellingPrice,
      flex: 1,
      sortable: false,
      renderCell: (params: any) => {
        return params.value ? params.value.toString().replaceAll('.', ',') : '';
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: i18nMessages.Actions,
      flex: 1,
      getActions: (params: GridRowParams) => {
        const actionButtons = [];

        actionButtons.push(
          <>
            {hasPermission(Permission.canEditCustomer) ? (
              <GridActionsCellItem
                onPointerEnterCapture={false}
                onPointerLeaveCapture={false}
                placeholder={''}
                icon={<EditIcon />}
                label={I18n('Common.Edit')}
                onClick={editSpecialPricing(params)}
              />
            ) : (
              <></>
            )}
            {hasPermission(Permission.canDeleteCustomer) ? (
              <GridActionsCellItem
                onPointerEnterCapture={false}
                onPointerLeaveCapture={false}
                placeholder={''}
                icon={<DeleteIcon />}
                label="Delete"
                onClick={deleteSpecialPricing(params.row)}
              />
            ) : (
              <></>
            )}
          </>
        );
        return actionButtons;
      },
    },
  ];

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    if (sortModel && sortModel.length) {
      updateGridData('sortBy', sortModel[0].field);
      updateGridData(
        'sortOrder',
        sortModel[0].sort === 'asc'
          ? OrderByDirection.Ascending
          : OrderByDirection.Descending
      );
    }
  }, []);

  const handleClose = () => {
    setDeleteModal(false);
  };

  const handleOk = () => {
    setDeleteModal(false);
    deleteData(selectedId);
  };

  const deleteData = async (selectedData: any) => {
    const apiUrl = new URL(
      environment.api.baseUrl +
        'Customer/DeleteCustomerSpecialPricing/' +
        selectedData.customerId +
        '/' +
        selectedData.entityType +
        '/' +
        selectedData.entityId +
        '/' +
        selectedData.transportTypeId +
        '/' +
        selectedId.id
    );
    try {
      const result: any = await http.delete(apiUrl.toString());
      if (result != null) {
        toast.success(i18nMessages.deletedSuccessfully);
        getCustomerSpecialPricing();
      } else {
        toast.error(i18nMessages.errorOccurred);
      }
    } catch (error: any) {}
  };

  const editSpecialPricing = useCallback(
    (data: any) => () => {
      setEditData(data);
      if (data.row.entityType == EntityTypeEnum.TransportCatalogue) {
        setAddTransportModal(true);
      } else {
        setAddData(null);
        setEntityType(data?.row?.entityType);
        setAddModal(true);
      }
    },
    []
  );

  const handleCloseForAdd = () => {
    setEditData(null);
    setAddData(null);
    setAddModal(false);
    setAddTransportModal(false);
  };

  const refreshData = async () => {
    handleCloseForAdd();
    await getCustomerSpecialPricing();
  };

  const handleValueChange = (value: any) => {
    setEntityType(value.value);
    setAddData({ label: customerName, value: params.entityId });
    setEditData(null);
    if (value.value == EntityTypeEnum.TransportCatalogue) {
      setAddTransportModal(true);
    } else {
      setAddModal(true);
    }
  };

  return (
    <div>
      <div className="">
        <Grid container spacing={2} className="grid-wrap two-col">
          <Grid item xs={9}></Grid>
          <Grid item xs={3}>
            {hasPermission(Permission.canAddCustomer) && !customerPortalUser ? (
              <CustomizedSelect
                placeholder={i18nMessages.SpecialPricing}
                options={typeList}
                isSearchable={false}
                handleChange={handleValueChange}
                value={defaultValue}
                errorValue={true}
                isDisplayLabelClass={true}
              ></CustomizedSelect>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
        <div className="content-block table-fix">
          <CustomDataGrid
            rowCount={gridData.totalRows}
            data={gridData.rows}
            columns={columns}
            pageNo={gridData.page}
            pageSize={gridData.pageSize}
            onSortModelChange={handleSortModelChange}
            onPageChange={handleChangePage}
            onPageSizeChange={handleChangePageSize}
            rowsPerPageOptions={gridData.rowsPerPageOptions}
            initialState={{
              sorting: {
                sortModel: [{ field: 'UpdatedDate', sort: 'desc' }],
              } as GridSortingInitialState,
              pagination: {
                page: gridData.page,
                pageSize: gridData.pageSize,
              } as GridPaginationInitialState,
            }}
          ></CustomDataGrid>

          <ConfirmationPopup
            isOpen={isDeleteModalOpen}
            data={selectedId}
            message={I18n('SpecialPricing.DeleteMessage')}
            handleClose={handleClose}
            handleOk={handleOk}
            title={I18n('SpecialPricing.DeleteNote')}
          ></ConfirmationPopup>

          <AddSpecialPricing
            isOpen={isAddModalOpen}
            editData={editData}
            addData={addData}
            entityType={entityType}
            entityId={editData?.row?.entityId}
            handleClose={handleCloseForAdd}
            title={
              editData == null
                ? I18n('SpecialPricing.AddSpecialPricing')
                : I18n('SpecialPricing.EditSpecialPricing')
            }
            refreshData={refreshData}
          ></AddSpecialPricing>

          <AddTransportSpecialPricing
            isOpen={isAddTransportModalOpen}
            editData={editData}
            addData={addData}
            entityType={entityType}
            entityId={editData?.row?.entityId}
            handleClose={handleCloseForAdd}
            title={
              editData == null
                ? I18n('SpecialPricing.AddSpecialPricing')
                : I18n('SpecialPricing.EditSpecialPricing')
            }
            refreshData={refreshData}
          ></AddTransportSpecialPricing>
        </div>
      </div>
    </div>
  );
}

export default CustomerSpecialPricingList;
