import I18n from 'utilities/i18n';
import { useEffect, useState } from 'react';
import http from '../../utilities/httpService';
import environment from 'environment';
import { useParams } from 'react-router-dom';
import { CustomizedSelect } from 'common/CustomSelect/CustomSelect';
import {
  AllowedOrderCategoryType,
  PaginationResponseModel,
} from 'models/pagination-model';
import { Grid } from '@mui/material';
import {
  AllowedOrderCategoryTypeDescription,
  BaseModel,
  MasterCategoryEnumType,
} from 'models/pagination-model';
import {
  AllowedOrderCategoryModel,
  AllowedOrderCategoryValues,
  Customer,
  AllowedOrderCategoryStateModel,
} from 'models/service-catalogue';
import FormAccordian from 'common/formControl/formAccordian';
import { toast } from 'react-toastify';
import CustomSelectPaginateAdd from 'common/CustomSelect/CustomSelectPaginateAdd';
import { PostCatagoryDropDown } from 'common/DropDownAddAPI/DropDownApiService';

import CustomSelectPaginate from 'common/CustomSelect/CustomSelectPaginate';
import { hasPermission } from 'utilities/protectedRoute';
function AllowedOrderCategory(props: any) {
  const { entityId } = useParams();
  const [expanded, setExpanded] = useState<boolean>(true);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [canEdit, setCanEdit] = useState(false);
  let allowedOrderCategoryList: Array<BaseModel> = [];
  const getAllowedOrderCategoryList = () => {
    allowedOrderCategoryList = [];
    AllowedOrderCategoryTypeDescription.forEach(
      (value: string, key: number) => {
        allowedOrderCategoryList.push({
          value: key.toString(),
          label: I18n(value),
        } as BaseModel);
      }
    );
  };
  getAllowedOrderCategoryList();
  const [values, setValues] = useState<AllowedOrderCategoryStateModel>({
    allowed: allowedOrderCategoryList[0],
    specificCategories: [],
  });
  const [cacheUniq, setCacheUniq] = useState(0);
  const i18nMessages = {
    allowedOrderCatagorySaveSuccess: I18n(
      'Common.AllowedOrderCatagorySaveSuccess'
    ),
    specificCategoriesLabel: I18n('Common.SpecificCategories'),
    commonFieldIsRequired: I18n('Common.FieldIsRequired'),
    somethingWentWrong: I18n('Common.SomethingWentWrong'),
    CommonSaveSuccessMsg: I18n('Common.SavedSuccessfully'),
    CommonErrOccuredMsg: I18n('Common.ErrorOccurred'),
  };

  const handleEditClick = (event: React.SyntheticEvent) => {
    setIsEdit(!isEdit);
  };
  const handleExpandCollapseClick = (event: React.SyntheticEvent) => {
    setExpanded(!expanded);
  };
  const defaultAdditional: any = {
    page: 1,
  };
  const getOrderCategories = async (search: string, pageNo: number) => {
    let response: PaginationResponseModel<Customer>;
    const apiUrl = new URL(
      environment.api.baseUrl + 'Dropdown/GetMasterCategories'
    );
    try {
      let searchExpression = `entityType~=~${MasterCategoryEnumType.AllowedOrderCategory}`;
      searchExpression += search ? ` and name~like~${search}` : '';
      apiUrl.searchParams.set('$filter', searchExpression);
      apiUrl.searchParams.set('$orderby', 'name');
      apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
      apiUrl.searchParams.set('$top', '10');
      apiUrl.searchParams.set('$orderbydirection', 'desc');
      const result = await http.get(apiUrl.toString());
      if (result) {
        response = {
          TotalRecords: result.data.totalCount,
          Values: result.data.values,
        } as PaginationResponseModel<Customer>;
      } else {
        response = {
          TotalRecords: 0,
          Values: [],
        } as PaginationResponseModel<Customer>;
      }
    } catch (error) {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<Customer>;
    }
    return response;
  };

  const getAllowedOrderCategoryById = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl + 'OrderCategory/GetByIdAsync'
    );
    try {
      apiUrl.searchParams.set('entityId', entityId ? entityId.toString() : '0');
      apiUrl.searchParams.set('entityType', props.entityType.toString());
      const result = await http.get(apiUrl.toString());
      if (result && result.data) {
        const response = result.data as AllowedOrderCategoryModel;
        setValues({
          allowed: allowedOrderCategoryList.find(
            (x) => +x.value === response.categoryType
          ),
          specificCategories: [],
        });
        if (response.categoryType === AllowedOrderCategoryType.Specific) {
          setValues({
            allowed: allowedOrderCategoryList.find(
              (x) => +x.value === response.categoryType
            ),
            specificCategories: response.allowedOrderCategoryValues.map((x) => {
              return {
                label: x.name.toString(),
                value: x.orderCategoryId.toString(),
              };
            }),
          });
        }
      } else {
        toast.error(i18nMessages.somethingWentWrong);
      }
    } catch (error) {
      toast.error(i18nMessages.somethingWentWrong);
    }
  };

  const loadOrderCategoryOptions = async (
    search: string,
    loadedOptions: any,
    { page }: any
  ) => {
    const response = await getOrderCategories(search, page);
    const hasMore = Math.ceil(response.TotalRecords / 10) > page;

    return {
      options: response.Values.map((x) => {
        return {
          value: x.id.toString(),
          label: x.name,
        } as BaseModel;
      }),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const handleSaveClick = () => {
    if (validate()) {
      saveAllowedOrderCategory({
        categoryType: values && values.allowed ? +values.allowed.value : 0,
        entityId: entityId ? +entityId : 0,
        entityType: props.entityType,
        allowedOrderCategoryValues:
          (values && values.allowed ? +values.allowed.value : 0) ===
          AllowedOrderCategoryType.All
            ? []
            : values.specificCategories.map((x: BaseModel) => {
                return {
                  orderCategoryId: x.value ? +x.value : 0,
                  name: '',
                } as AllowedOrderCategoryValues;
              }),
      } as AllowedOrderCategoryModel);
    }
  };

  const saveAllowedOrderCategory = async (
    allowedOrderCategory: AllowedOrderCategoryModel
  ) => {
    const apiUrl = new URL(environment.api.baseUrl + 'OrderCategory');
    try {
      const result: any = await http.post(
        apiUrl.toString(),
        allowedOrderCategory
      );
      if (result) {
        toast.success(i18nMessages.allowedOrderCatagorySaveSuccess);
        getAllowedOrderCategoryById();
        setIsEdit(false);
      }
    } catch (error: any) {}
  };

  const [errors, setErrors] = useState({
    specificCategories: '',
  });

  const validate = (fieldValues: any = values) => {
    let temp = { ...errors };
    if ('specificCategories' in fieldValues) {
      temp.specificCategories =
        (fieldValues.specificCategories &&
          fieldValues.specificCategories.length &&
          +fieldValues.allowed.value === AllowedOrderCategoryType.Specific) ||
        +fieldValues.allowed.value === AllowedOrderCategoryType.All
          ? ''
          : i18nMessages.commonFieldIsRequired;
    }
    setErrors({
      ...temp,
    });
    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === '');
    }
  };
  const onCreateOption = async (inputValue: any) => {
    let name = {};
    name['name'] = inputValue;
    name['entityType'] = MasterCategoryEnumType.AllowedOrderCategory;
    const newOption: any = await PostCatagoryDropDown(
      name,
      i18nMessages.CommonSaveSuccessMsg,
      i18nMessages.CommonErrOccuredMsg
    );
    const increaseUniq = (uniq: any) => uniq + 1;
    setCacheUniq(increaseUniq);
    setValues({ ...values, specificCategories: newOption });
    getOrderCategories('', 1);
  };
  const AllowedOrderCategoryData = () => {
    return (
      <>
        <Grid item lg={4} md={8}>
          <CustomizedSelect
            options={allowedOrderCategoryList}
            isSearchable={false}
            displayLabel={I18n('Common.Allowed')}
            handleChange={(newValue: any) =>
              setValues({ ...values, allowed: newValue })
            }
            value={values.allowed}
            readOnly={!isEdit}
          ></CustomizedSelect>
        </Grid>
        {(values && values.allowed ? +values.allowed.value : 0) ===
          AllowedOrderCategoryType.Specific && (
          <Grid item lg={4} md={8}>
            <CustomSelectPaginateAdd
              key={JSON.stringify(values.specificCategories)}
              additional={defaultAdditional}
              value={values.specificCategories}
              loadOptions={loadOrderCategoryOptions}
              handleChange={(newValue: any) =>
                setValues({ ...values, specificCategories: newValue })
              }
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
              }}
              cacheUniqs={cacheUniq}
              onCreateOption={onCreateOption}
              isSearchable={true}
              displayLabel={i18nMessages.specificCategoriesLabel}
              errorValue={true}
              errorMessage={errors.specificCategories}
              readOnly={!isEdit}
              debounceTimeout={500}
              isMultiSelect={true}
            ></CustomSelectPaginateAdd>
          </Grid>
        )}
      </>
    );
  };

  useEffect(() => {
    getAllowedOrderCategoryById();
    setCanEdit(hasPermission(props.permission));
  }, []);
  return (
    <div>
      <FormAccordian
        title={I18n('ServiceCatalogues.Tabs.AllowedOrderCategory')}
        className="inner-heading"
        showActionButton={canEdit}
        details={AllowedOrderCategoryData()}
        expanded={expanded}
        isEdit={isEdit}
        handleEditClick={handleEditClick}
        handleExpandCollapseClick={handleExpandCollapseClick}
        isSaveButton={isEdit}
        onSaveClick={handleSaveClick}
      ></FormAccordian>
    </div>
  );
}

export default AllowedOrderCategory;
