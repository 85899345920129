import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Breadcrumbs } from '@mui/material';
import I18n from 'utilities/i18n';
import LocationList from 'common/location/LocationList';
import DocumentList from 'common/Document/DocumentList';
import ContactPerson from 'common/contactPerson/ContactPerson';
import { DocumentEntity, EntityTypeEnum } from 'models/pagination-model';
import Note from 'common/notes/Notes';
import EditPartnerDetails from './detail/EditPartnerDetails';
import Link from '@mui/material/Link';
import { useNavigate, useParams } from 'react-router-dom';
import RouteEnum from 'models/RouteEnum';
import Category from './category/Category';
import { Permission } from 'Permissions';
import AllowedOrderCategory from 'common/AllowedOrderCategory/AllowedOrderCategory';

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function FormCustomer() {
  let { entityId } = useParams();
  let navigate = useNavigate();
  const handleListClick = () => {
    navigate(RouteEnum.PartnerList);
  };
  const breadcrumbs = [
    <Typography key="3" color="text.primary">
      {I18n('Nav.Reference')}
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={handleListClick}
        className="cpointer"
      >
        {I18n('Nav.Partners')}
      </Link>
    </Typography>,
    <Typography key="3" color="text.primary">
      {I18n('Partner.PartnerDetails')}
    </Typography>,
  ];
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div>
      <div className="heading-section">
        <div className="heading-section-left">
          <Typography variant="h3" sx={{ mb: 3 }}>
            {I18n('Partner.PartnerDetails')}
          </Typography>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>
      </div>
      <div className="main-content-section width-100">
        <Box>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            className="tab-bordered"
          >
            <Tab label={I18n('Partner.Tabs.Details')} {...a11yProps(0)} />
            <Tab label={I18n('Partner.Tabs.ContactPerson')} {...a11yProps(1)} />
            <Tab label={I18n('Partner.Tabs.Location')} {...a11yProps(2)} />
            <Tab
              label={I18n('Partner.Tabs.TransportTypes')}
              {...a11yProps(3)}
            />
            <Tab
              label={I18n('Partner.Tabs.AllowedOrderCategory')}
              {...a11yProps(4)}
            />
            <Tab label={I18n('Partner.Tabs.Documents')} {...a11yProps(5)} />
            <Tab label={I18n('Partner.Tabs.Notes')} {...a11yProps(6)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <EditPartnerDetails />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ContactPerson
            addPermission={Permission.canAddPartner}
            editPermission={Permission.canEditPartner}
            deletePermission={Permission.canDeletePartner}
            isHidden={true}
            entityType={EntityTypeEnum.Partner}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <LocationList
            addPermission={Permission.canAddPartner}
            editPermission={Permission.canEditPartner}
            deletePermission={Permission.canDeletePartner}
            isHidden={true}
            entityType={EntityTypeEnum.Partner}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Category entityType={EntityTypeEnum.Partner} />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <AllowedOrderCategory
            permission={Permission.canEditPartner}
            entityType={EntityTypeEnum.Partner}
          />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <DocumentList
            permission={Permission.canEditPartner}
            addPermission={Permission.canAddPartner}
            deletePermission={Permission.canDeletePartner}
            entityType={EntityTypeEnum.Partner}
            paramsId={entityId}
            documentEntityType={DocumentEntity.Partner}
          />
        </TabPanel>
        <TabPanel value={value} index={6}>
          <Note
            permission={Permission.canEditPartner}
            addPermission={Permission.canAddPartner}
            deletePermission={Permission.canDeletePartner}
            paramsId={entityId}
            entityType={EntityTypeEnum.Partner}
          />
        </TabPanel>
      </div>
    </div>
  );
}
