import {
  Breadcrumbs,
  Grid,
  Typography,
  Link,
  Button,
  Tooltip,
} from '@mui/material';
import {
  ActivityOwner,
  BaseModel,
  OrderType,
  CustomerLocation,
} from 'models/pagination-model';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import I18n from 'utilities/i18n';
import Status from 'common/WoStatus/Status';
import http from '../../../utilities/httpService';
import environment from 'environment';
import { toast } from 'react-toastify';
import { DnPWorkOrders } from 'models/deck-pack-orders';
import RouteEnum from 'models/RouteEnum';
import FormControl from 'common/formControl/formControl';
import CustomizedSelect from 'common/CustomSelect/CustomSelect';
import { hasPermission } from 'utilities/protectedRoute';
import { Permission } from 'Permissions';
import FormAccordianSave from 'common/formControl/formAccordianSave';
import CustomSelectPaginate from 'common/CustomSelect/CustomSelectPaginate';
import { loadUserOptions } from '../DeckPackApiService';
import DecpackStatus from './DecpackStatus';
import CreatedByandUpdatedBy from 'common/CreatedByandUpdatedBy/CreatedByandUpdatedBy';

const DecPackWO6Bis = () => {
  const { woId, id } = useParams();
  let navigate = useNavigate();
  const [canEdit, setCanEdit] = useState(
    hasPermission(Permission.canEditDecPackOrders)
  );
  const defaultValue = {
    value: '0',
    label: I18n('Placeholders.Select'),
  } as BaseModel;
  // const [selectedAssignmentTo, setSelectedAssignmentTo] =
  //   useState(defaultValue);
  const defaultAdditional: any = {
    page: 1,
  };
  const [details, setDetails] = useState<any>({
    createdBy: '',
    createdDate: '',
    updatedBy: '',
    updatedDate: '',
  });
  const [assigneeData, setAssigneeData] = useState<any>({
    assignedToId: '',
    assignedToName: '',
    assignNextToId: '',
    assignNextToName: '',
  });
  const [isMobile, setIsMobile] = useState(false);

  const breadcrumbs = [
    <Typography key="3" color="text.primary">
      {I18n('Nav.Order')}
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={() => navigate(RouteEnum.DecPackOrdersList)}
        className="cpointer"
      >
        {I18n('Nav.DecPackOrders')}
      </Link>
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={() => navigate(RouteEnum.ViewDecPackOrders + '/' + id + '/1')}
        className="cpointer"
      >
        {I18n('ShippingOrders.WorkOrders')}
      </Link>
    </Typography>,
  ];

  const [isNew, setIsNew] = useState<boolean>(true);
  const [wO6BisId, setWO6BisId] = useState<number>();
  const [isSaveNext, setIsSaveNext] = useState<boolean>(true);
  const i18nMessages = {
    Title: I18n('DecPackWO6Bis.Title'),
    Delivery: I18n('DecPackWO6Bis.Delivery'),
    AssignedTo: I18n('DecPackWO6Bis.AssignedTo'),
    Location: I18n('DecPackWO6Bis.Location'),
    SpecialInstructions: I18n('DecPackWO6Bis.SpecialInstructions'),
    FieldRequired: I18n('DecPackWO6Bis.FieldRequired'),
    AddSuccess: I18n('DecPackWO6Bis.AddSuccess'),
    SomethingWentWrong: I18n('DecPackWO6Bis.SomethingWentWrong'),
    UpdateSuccess: I18n('DecPackWO6Bis.UpdateSuccess'),
  };

  const [values, setValues] = useState<any>({
    decPackWorkOrdersId: 0,
    decPackInitialOrderId: 0,
    location: '',
    specialInstruction: '',
    status: 0,
    createdBy: '',
    createdDate: '',
    updatedBy: '',
    updatedDate: '',
  });

  const [errors, setErrors] = useState({
    location: '',
    specialInstruction: '',
  });

  const [isStatusSaveCallApi, setIsStatusSaveCallApi] =
    useState(false); /* To call Submit APi Status from Status Component */
  const [validateStatus, setValidateStatus] = useState<boolean>(false);
  /* validateStatus - To validate Status Dropdown from Status Component */

  const validate = (fieldValues: any = values) => {
    let temp = { ...errors };

    setErrors({
      ...temp,
    });
    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === '');
    }
  };

  const handleSaveClick = async () => {
    setValidateStatus(true);
    setIsSaveNext(false);
  };

  let entityLocationOptionsList: Array<BaseModel> = [];

  const getWO1Location = async () => {
    const apiUrl = new URL(environment.api.baseUrl + `DecPackOrder/${id}`);
    let location = { label: '', value: '0' } as BaseModel;
    try {
      const result = await http.get(apiUrl.toString());
      if (result && result.data) {
        if (result.data.locationType === CustomerLocation.GroundHandler) {
          location = {
            value: String(0),
            label: result.data.interventie,
          };
        } else if (
          result.data.locationType === CustomerLocation.WareHouse &&
          result.data.deliveryLocationId
        ) {
          location = {
            value: String(result.data.deliveryLocationId),
            label: result.data.deliveryLocationAddress,
          };
        } else if (
          result.data.locationType === CustomerLocation.Customer &&
          result.data.locationId
        ) {
          location = {
            value: String(result.data.locationId),
            label: result.data.locationAddress,
          };
        }
      }
      return location;
    } catch (error) {
      return location;
    }
  };

  const getWOStatus = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl +
        'DecPackOrder/GetDecPackWO/' +
        id +
        '/' +
        DnPWorkOrders.WO6bis
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result) {
        setAssigneeData({
          ...assigneeData,
          assignedToId: result.data.assignedToId,
          assignedToName: result.data.assignedToName,
          assignNextToId: result.data.assignedNextToId,
          assignNextToName: result.data.assignedNextToName,
        });
        return result.data.status;
      }
    } catch (error) {}
  };

  const getDecPackWO6bis = async () => {
    const locationRes = await getWO1Location();
    const apiUrl = new URL(
      environment.api.baseUrl + 'DecPackWO6bis/' + woId + '/' + id
    );
    try {
      const result = await http.get(apiUrl.toString());
      var status = await getWOStatus();

      if (result && result.data) {
        setIsNew(false);
        setWO6BisId(result.data.id);
        setValues({
          ...values,
          decPackWorkOrdersId: result.data.decPackWorkOrdersId,
          decPackInitialOrderId: result.data.decPackInitialOrderId,
          location: {
            value: locationRes.value,
            label: locationRes.label,
          },
          specialInstruction: result.data.specialInstruction,
          status,
          createdBy: result.data.createdBy,
          updatedBy: result.data.updatedBy,
          createdDate: result.data.createdDate,
          updatedDate: result.data.updatedDate,
        });

        setIsMobile(result.data.isMobile);
        // await getAssignedToUser(result.data.assignedTo);
      } else {
        setValues({
          ...values,
          location: {
            value: locationRes.value,
            label: locationRes.label,
          },
          status,
        });
      }
    } catch (error) {
      setIsNew(true);
    }
  };

  useEffect(() => {
    getDecPackWO6bis();
  }, []);

  const Delivery = () => {
    return (
      <>
        <Grid item lg={4} md={4}>
          <Tooltip title={values.location.label} arrow>
            <div>
              <CustomizedSelect
                required
                options={entityLocationOptionsList}
                isSearchable={false}
                displayLabel={i18nMessages.Location}
                value={values.location}
                handleChange={(newValue: BaseModel) => {
                  setValues({ ...values, location: newValue });
                  if (newValue.value !== '0') {
                    setErrors({
                      ...errors,
                      location: '',
                    });
                  }
                }}
                errorValue={errors.location}
                errorMessage={errors.location}
                readOnly={true}
              ></CustomizedSelect>
            </div>
          </Tooltip>
        </Grid>
        <Grid item lg={4} md={4}>
          <FormControl
            margin="normal"
            displayLabel={i18nMessages.SpecialInstructions}
            id="specialInstruction"
            name="specialInstruction"
            inputType="text"
            inputProps={{ maxLength: 250 }}
            multiline={false}
            value={values.specialInstruction}
            handleChange={(event: any) =>
              setValues({ ...values, specialInstruction: event.target.value })
            }
            errorValue={true}
            errorMessage={errors.specialInstruction}
            readOnly={!canEdit}
          ></FormControl>
        </Grid>
      </>
    );
  };

  /* Validate Status Dropdown Success call from Status Component */
  const handleSuccessStatusSave = async () => {
    if (validate()) {
      const finalValue = {
        decPackWorkOrdersId: Number(woId),
        decPackInitialOrderId: Number(id),
        id: wO6BisId,
        locationId:
          Number(values.location.value) === 0 ||
          values.location.value === undefined
            ? null
            : values.location.value.toString(),
        specialInstruction: values.specialInstruction,
      };

      // Save status
      setIsStatusSaveCallApi(true);

      if (isNew) {
        const apiUrl = new URL(environment.api.baseUrl + 'DecPackWO6bis');
        try {
          const result: any = await http.post(apiUrl.toString(), finalValue);
          if (result) {
            toast.success(i18nMessages.AddSuccess);
            getDecPackWO6bis();
          }
        } catch (error: any) {
          toast.error(i18nMessages.SomethingWentWrong);
        }
      } else {
        const apiUrl = new URL(
          environment.api.baseUrl + 'DecPackWO6bis/' + wO6BisId
        );
        try {
          const result: any = await http.put(apiUrl.toString(), finalValue);
          if (result) {
            toast.success(i18nMessages.UpdateSuccess);
            getDecPackWO6bis();
          }
        } catch (error: any) {
          toast.error(i18nMessages.SomethingWentWrong);
        }
      }
    }
    handleErrorStatusSave(false);
    /* To set Validation and Submit APi Status False */
  };

  /* Validate Status Dropdown Error call from Status Component */
  const handleErrorStatusSave = (data: any) => {
    setValidateStatus(false);
    setIsStatusSaveCallApi(false);
  };

  const onSaveNextClick = async (event: any) => {
    event.preventDefault();
    setValidateStatus(true);
    setIsSaveNext(true);
  };

  return (
    <div>
      <div className="heading-section">
        <div className="heading-section-left">
          <Typography variant="h3" sx={{ mb: 6 }}>
            {i18nMessages.Title}
          </Typography>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>
      </div>
      <div className="main-content-section">
        <FormAccordianSave
          title={i18nMessages.Delivery}
          className="inner-heading"
          details={Delivery()}
          showActionButton={canEdit}
        ></FormAccordianSave>
        <DecpackStatus
          permission={Permission.canEditDecPackOrders}
          woId={DnPWorkOrders.WO6bis}
          activityOwner={ActivityOwner.BackOffice}
          orderId={id}
          statusValue={values.status}
          orderType={OrderType.DecPackOrder}
          isStatusSaveCallApi={isStatusSaveCallApi}
          validateStatus={validateStatus}
          handleSuccessStatusSave={handleSuccessStatusSave}
          handleErrorStatusSave={handleErrorStatusSave}
          isSaveNext={isSaveNext}
          isMobile={isMobile}
          workOrderNo={DnPWorkOrders.WO6bis}
          assigneeData={assigneeData}
          handleStatusChange={(value: number) =>
            setValues({ ...values, status: value })
          }
        />
        <div className="btn-wrap form-btn-wrap">
          {canEdit ? (
            <Button
              color="primary"
              disableElevation
              variant="contained"
              onClick={onSaveNextClick}
            >
              {I18n('Common.SaveNext')}
            </Button>
          ) : (
            <></>
          )}
          &nbsp; &nbsp; &nbsp;
          {canEdit ? (
            <Button
              type="submit"
              color="primary"
              disableElevation
              variant="contained"
              onClick={handleSaveClick}
            >
              {I18n('Common.Save')}
            </Button>
          ) : (
            <></>
          )}
        </div>
        <CreatedByandUpdatedBy
          createdBy={values.createdBy}
          createdDate={values.createdDate}
          updatedBy={values.updatedBy}
          updatedDate={values.updatedDate}
        ></CreatedByandUpdatedBy>
      </div>
    </div>
  );
};

export default DecPackWO6Bis;
