import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import I18n from 'utilities/i18n';
import FormControl from 'common/formControl/formControl';
import { Grid } from '@mui/material';
import CustomizedSelect from 'common/CustomSelect/CustomSelect';
import {
  BaseModel,
  ContactPersonFunctionDescription,
  EntityStatus,
  EntityStatusDescription,
  SystemAccess,
  SystemAccessDescription,
  ContactPersonFunction,
  CustomerPortalRoleName,
  AccessLevelDescription,
  AccessLevel,
} from 'models/pagination-model';
import environment from 'environment';
import { toast } from 'react-toastify';
import http from '../../utilities/httpService';
import CustomizedPhone from 'common/formControl/formPhone';
import React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { PreferredLanguage } from 'models/pagination-model';
import { Department } from 'models/pagination-model';
import { UserType } from 'models/pagination-model';
import { Utilities } from 'utilities/Utilities';
import {
  getLocationById,
  loadPickupCompanyOptions,
} from 'components/shipping-orders/ShippingApiService';
import CustomSelectPaginate from 'common/CustomSelect/CustomSelectPaginate';
import { AddressSegments } from 'models/shipping-orders';

export default function AddContactPerson(props: any) {
  const {
    isOpen,
    handleClose,
    title,
    entityType,
    entityId,
    editData,
    isHidden,
    triggerFrom,
    newContact,
  } = props;
  const customerPortalUser = Utilities.getIsCPUser();
  const defaultValue = {
    value: '',
    label: I18n('Placeholders.Select'),
  } as BaseModel;

  const defaultValueForStatus = {
    value: EntityStatus.Active.toString(),
    label: I18n('Common.Active'),
  } as BaseModel;

  const defaultValueForSystemAccess = {
    value: SystemAccess.No.toString(),
    label: I18n('Common.No'),
  } as BaseModel;

  const defaultAdditional: any = {
    page: 1,
  };

  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    accessLevel: '',
    pickUpCustomerLocation: '',
  });
  const i18nMessages = {
    fieldRequired: I18n('ContactPerson.FieldRequired'),
    invalidEmailAddress: I18n('ContactPerson.InvalidEmailAddress'),
    errorOccurred: I18n('ContactPerson.ErrorOccurred'),
    savedSuccessfully: I18n('ContactPerson.SavedSuccessfully'),
    UserCreatedSuccss: I18n('UsersAdd.UserCreatedSuccss'),
    employeeCode: I18n('Common.EmployeeCode'),
    email: I18n('Common.Email'),
    phoneNumber: I18n('Common.PhoneNumberVal'),
    Address: I18n('CustomerLocation.Address'),
    Address2: I18n('CustomerLocation.Address2'),
    ZipCode: I18n('CustomerLocation.ZipCode'),
    City: I18n('CustomerLocation.City'),
    Country: I18n('CustomerLocation.Country'),
  };
  const i18nLabels = {
    FirstName: I18n('ContactPerson.FirstName'),
    LastName: I18n('ContactPerson.LastName'),
    Function: I18n('ContactPerson.Function'),
    Status: I18n('ContactPerson.Status'),
    Phone: I18n('ContactPerson.Phone'),
    Mobile: I18n('ContactPerson.Mobile'),
    SystemAccess: I18n('ContactPerson.SystemAccess'),
    Email: I18n('ContactPerson.Email'),
    SiteInformation: I18n('ContactPerson.SiteInformation'),
    AccessLevel: I18n('ContactPerson.AccessLevel'),
    PickupCompany: I18n('ShippingOrders.PickupCompany'),
  };

  const validate = (fieldValues: any = values) => {
    let temp = { ...errors };

    if ('firstName' in fieldValues) {
      temp.firstName = fieldValues.firstName ? '' : i18nMessages.fieldRequired;
    }
    if ('lastName' in fieldValues) {
      temp.lastName = fieldValues.lastName ? '' : i18nMessages.fieldRequired;
    }
    if ('email' in fieldValues) {
      if (
        !fieldValues.email &&
        systemAccess &&
        systemAccess.value == SystemAccess.Yes.toString()
      ) {
        temp.email = i18nMessages.fieldRequired;
      } else if (fieldValues.email && !/$^|.+@.+..+/.test(fieldValues.email)) {
        temp.email = i18nMessages.invalidEmailAddress;
      } else {
        temp.email = '';
      }
    }

    if ('mobile' in fieldValues) {
      if (
        (!fieldValues.mobile || fieldValues.mobile.length < 9) &&
        systemAccess &&
        systemAccess.value == SystemAccess.Yes.toString()
      ) {
        temp.mobile = i18nMessages.fieldRequired;
      } else {
        temp.mobile = '';
      }
    }

    if (
      systemAccess &&
      systemAccess.value == SystemAccess.Yes.toString() &&
      accessLevel.value == defaultValue.value
    ) {
      temp.accessLevel = i18nMessages.fieldRequired;
    } else {
      temp.accessLevel = '';
    }

    if (
      systemAccess &&
      systemAccess.value == SystemAccess.Yes.toString() &&
      accessLevel.value == AccessLevel.Location.toString() &&
      values.pickupLocation.length == 0
    ) {
      temp.pickUpCustomerLocation = i18nMessages.fieldRequired;
    } else {
      temp.pickUpCustomerLocation = '';
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === '');
    }
  };

  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    mobile: '',
    email: '',
    siteInformation: '',
    userId: null,
    pickupLocation: [],
  });

  const [userValues, setUserValues] = useState({
    employeeCode: '',
    firstName: '',
    lastName: '',
    role: '',
    department: 0,
    userType: 0,
    phoneNumber: '',
    preferredLanguage: '',
    status: 0,
    email: '',
    phone: '',
  });

  const [functionId, setFunctionId] = useState(defaultValue);
  const [accessLevel, setAccessLevel] = useState(defaultValue);
  const [systemAccess, setSystemAccess] = useState(defaultValueForSystemAccess);
  const [status, setStatus] = useState(defaultValueForStatus);

  const phoneOnChange = (phone: any) => {
    const phoneNum: string = phone.replace(/[^\d\+]/g, '');
    setValues({ ...values, phone: phoneNum });
  };

  const mobileOnChange = (phone: any) => {
    const phoneNum: string = phone.replace(/[^\d\+]/g, '');
    setValues({ ...values, mobile: phoneNum });
  };

  let entityfunctionList: Array<BaseModel> = [];
  const getFunctionList = () => {
    entityfunctionList = [defaultValue];
    ContactPersonFunctionDescription.forEach((value: string, key: number) => {
      entityfunctionList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };

  let entityAccessLevelList: Array<BaseModel> = [];
  const getAccessLevelList = () => {
    entityAccessLevelList = [defaultValue];
    AccessLevelDescription.forEach((value: string, key: number) => {
      entityAccessLevelList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };

  let entitySystemAccessList: Array<BaseModel> = [];
  const getSystemAccessList = () => {
    SystemAccessDescription.forEach((value: string, key: number) => {
      entitySystemAccessList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };

  let entityStatusList: Array<BaseModel> = [];
  const getStatusList = () => {
    EntityStatusDescription.forEach((value: string, key: number) => {
      entityStatusList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };

  getSystemAccessList();
  getFunctionList();
  getStatusList();
  getAccessLevelList();

  const getLocation = () => {
    let locations: any = [];
    if (values.pickupLocation && values.pickupLocation.length > 0) {
      values.pickupLocation.forEach((element: any) => {
        locations.push({
          LocationId: +element.value,
          PickupCompany: element.label,
        });
      });
    }
    return locations;
  };

  const submit = async () => {
    if (validate()) {
      const contactPersonBaseDTO = {
        Id: editData == null ? 0 : editData.id,
        EntityType: entityType,
        EntityId: +entityId,
        CreatedBy: '',
        UpdatedBy: '',
        FirstName: values.firstName,
        LastName: values.lastName,
        Phone: values.phone,
        Mobile: values.mobile,
        Email: values.email,
        SiteInformation: values.siteInformation,
        SystemAccess: systemAccess.value == '1' ? true : false,
        Status:
          status.value == '1' ? EntityStatus.Active : EntityStatus.Inactive,
        FunctionId: functionId.value == '' ? null : +functionId.value,
        UserId: null,
        AccessLevel: accessLevel.value == '' ? null : +accessLevel.value,
        Locations: getLocation(),
      };

      let userCreationStatus = null;
      if (editData == null) {
        userCreationStatus = await createUser(true);
      } else {
        userCreationStatus = await createUser(false);
      }

      if (
        userCreationStatus === null &&
        systemAccess &&
        systemAccess.value === SystemAccess.Yes.toString()
      ) {
        return;
      }

      const apiUrl =
        editData === null
          ? new URL(environment.api.baseUrl + 'ContactPerson')
          : new URL(
              environment.api.baseUrl + 'ContactPerson/' + editData.row.id
            );
      try {
        contactPersonBaseDTO.UserId = userCreationStatus?.id ?? values.userId;
        const result: any =
          editData === null
            ? await http.post(apiUrl.toString(), contactPersonBaseDTO)
            : await http.put(apiUrl.toString(), contactPersonBaseDTO);
        if (editData == null && result !== null && result.data != null) {
          if (triggerFrom !== undefined && triggerFrom > 0) {
            props.OnSave(result, triggerFrom);
          } else {
            props.OnSave();
          }
          showMessageRefresh();
        } else if (editData != null && result !== null && result.data) {
          showMessageRefresh();
        } else {
          toast.error(i18nMessages.errorOccurred);
        }
      } catch (error: any) {}
    }
  };

  const showMessageRefresh = () => {
    toast.success(i18nMessages.savedSuccessfully);
    setDefaultValuesForControls();
    props.refreshData();
  };

  const setDefaultValuesForControls = () => {
    setValues({
      firstName: '',
      lastName: '',
      phone: '',
      mobile: '',
      email: '',
      siteInformation: '',
      userId: null,
      pickupLocation: [],
    });
    setFunctionId(defaultValue);
    setAccessLevel(defaultValue);
    setStatus(defaultValueForStatus);
    setSystemAccess(defaultValueForSystemAccess);
    setErrors({
      firstName: '',
      lastName: '',
      email: '',
      mobile: '',
      accessLevel: '',
      pickUpCustomerLocation: '',
    });
  };

  const close = () => {
    setDefaultValuesForControls();
    props.handleClose();
  };

  const setEditFunctionValue = (id: string) => {
    return {
      value: id,
      label: ContactPersonFunction[id],
    } as BaseModel;
  };

  const setEditAccessLevelValue = (id: string) => {
    return {
      value: id,
      label: AccessLevel[id],
    } as BaseModel;
  };

  const setEditAccessValue = (id: string) => {
    return {
      value: id,
      label: SystemAccess[id],
    } as BaseModel;
  };

  const setEditStatusValue = (id: string) => {
    return {
      value: id,
      label: EntityStatus[id],
    } as BaseModel;
  };

  useEffect(() => {
    if (editData) {
      var locations: any = [];
      if (editData.row.locations && editData.row.locations.length > 0) {
        editData.row.locations.forEach((element: any) => {
          locations.push({
            label: element.pickupCompany,
            value: element.locationId.toString(),
          });
        });
      }

      setValues({
        ...editData.row,
        pickupLocation: locations,
      });

      if (
        editData.row.functionId !== undefined &&
        editData.row.functionId !== null
      ) {
        setFunctionId(setEditFunctionValue(editData.row.functionId.toString()));
      }

      if (
        editData.row.accessLevel !== undefined &&
        editData.row.accessLevel !== null
      ) {
        setAccessLevel(
          setEditAccessLevelValue(editData.row.accessLevel.toString())
        );
      }
      setSystemAccess(
        setEditAccessValue(Number(editData.row.systemAccess).toString())
      );
      setStatus(setEditStatusValue(editData.row.status.toString()));
      if (editData && editData.row && Number(editData.row.userId) > 0) {
        getUserData(editData.row.userId);
      } else {
        setUserValues({ ...userValues, employeeCode: '' });
      }
    } else {
      setUserValues({ ...userValues, employeeCode: '' });
    }
    if (newContact !== undefined) {
      setValues({
        ...values,
        firstName: newContact,
      });
    }
  }, [editData, newContact]);

  const getUserData = async (id: string | undefined) => {
    const apiUrl = new URL(environment.api.baseUrl + 'Users/' + id);
    try {
      const result: any = await http.get(apiUrl.toString());
      if (result) {
        setUserValues(result.data);
      }
    } catch (error: any) {}
  };

  const createUser = async (isAddContact: boolean) => {
    let userData = {
      employeeCode:
        userValues && userValues.employeeCode
          ? userValues.employeeCode
          : `CUS${Utilities.generateUniqueNumber()}`,
      firstName: values.firstName,
      lastName: values.lastName,
      department: Department.Shipping,
      userType: UserType.Web,
      phoneNumber: values.mobile,
      preferredLanguage: PreferredLanguage.EN,
      status: EntityStatus.Active,
      email: values.email,
      phone: values.phone,
    };

    if (systemAccess && systemAccess.value === SystemAccess.Yes.toString()) {
      if (values.email && !values.userId) {
        return await addUser(userData);
      } else if (values.userId) {
        return await updateUser(values.userId, userData);
      }
    } else if (
      systemAccess &&
      systemAccess.value === SystemAccess.No.toString()
    ) {
      userData.status = EntityStatus.Inactive;
      if (values.userId) {
        return await updateUser(values.userId, userData);
      } else {
        return userData;
      }
    }
    return null;
  };

  const addUser = async (values: any) => {
    const apiUrl = new URL(environment.api.baseUrl + 'Users');
    try {
      const result: any = await http.post(apiUrl.toString(), values);
      if (result.data) {
        assignRoleToUser(Number(result.data.id));
        return result.data;
      }
    } catch (error: any) {
      userException(error);
      return null;
    }
    return values;
  };

  const updateUser = async (id: number, values: any) => {
    const apiUrl = new URL(environment.api.baseUrl + 'Users/' + id);
    try {
      values.id = id;
      const result: any = await http.put(apiUrl.toString(), values);

      if (result && result.data) {
        return values;
      }
    } catch (error: any) {
      userException(error);
      return null;
    }
    return values;
  };

  const userException = (error: any) => {
    if (error.response) {
      let temp = { ...errors };
      if (error.response.data) {
        for (var item of error.response.data.errors) {
          if (item.field === i18nMessages.employeeCode) {
            toast.error(item.errorMessage);
          }
          if (item.field === i18nMessages.email) {
            toast.error(item.errorMessage);
          }
          if (item.field === i18nMessages.phoneNumber) {
            toast.error(item.errorMessage);
          }
        }
      }
      setErrors({
        ...temp,
      });
    }
  };

  const assignRoleToUser = async (uId: number) => {
    const apiUrl = new URL(environment.api.baseUrl + 'UserRoles/' + uId);
    try {
      const result: any = await http.put(apiUrl.toString(), {
        userId: uId,
        roles: [CustomerPortalRoleName],
      });
      if (result) {
      }
    } catch (error: any) {}
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={'sm'}
        fullWidth={true}
      >
        <DialogTitle>
          {title}
          <IconButton
            aria-label="close"
            onClick={close}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className="grid-wrap two-col">
            <Grid item xs={6}>
              <FormControl
                margin="normal"
                displayLabel={i18nLabels.FirstName}
                id="FirstName"
                required
                inputType="text"
                errorValue={errors.firstName}
                errorMessage={errors.firstName}
                inputProps={{ maxLength: 50 }}
                handleChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ) => setValues({ ...values, firstName: event.target.value })}
                value={values.firstName}
                multiline={false}
              ></FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl
                margin="normal"
                displayLabel={i18nLabels.LastName}
                id="LastName"
                required
                inputType="text"
                errorValue={errors.lastName}
                errorMessage={errors.lastName}
                inputProps={{ maxLength: 50 }}
                handleChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ) => setValues({ ...values, lastName: event.target.value })}
                value={values.lastName}
                multiline={false}
              ></FormControl>
            </Grid>
            {!isHidden ? (
              <Grid item xs={6}>
                <CustomizedSelect
                  placeholder={defaultValue.label}
                  options={entityfunctionList}
                  isSearchable={false}
                  displayLabel={i18nLabels.Function}
                  handleChange={(newValue: BaseModel) =>
                    setFunctionId(newValue)
                  }
                  value={functionId}
                ></CustomizedSelect>
              </Grid>
            ) : (
              <></>
            )}

            <Grid item xs={6}>
              <CustomizedSelect
                placeholder={defaultValue.label}
                options={entityStatusList}
                isSearchable={false}
                displayLabel={i18nLabels.Status}
                handleChange={(newValue: BaseModel) => setStatus(newValue)}
                value={status}
              ></CustomizedSelect>
            </Grid>

            {!isHidden ? (
              <Grid item xs={6}>
                <CustomizedPhone
                  title={i18nLabels.Phone}
                  value={values?.phone}
                  name="phone"
                  onChange={(phone: any) => phoneOnChange(phone)}
                ></CustomizedPhone>
              </Grid>
            ) : (
              <></>
            )}

            <Grid item xs={6}>
              <CustomizedPhone
                title={i18nLabels.Mobile}
                value={values?.mobile}
                name="mobile"
                required={
                  systemAccess &&
                  systemAccess.value == SystemAccess.Yes.toString()
                }
                onChange={(mobile: any) => mobileOnChange(mobile)}
                errorValue={errors.mobile}
                errorMessage={errors.mobile}
              ></CustomizedPhone>
            </Grid>

            {!customerPortalUser && (
              <>
                <Grid item xs={6}>
                  <CustomizedSelect
                    placeholder={defaultValue.label}
                    options={entitySystemAccessList}
                    isSearchable={false}
                    displayLabel={i18nLabels.SystemAccess}
                    handleChange={(newValue: BaseModel) =>
                      setSystemAccess(newValue)
                    }
                    value={systemAccess}
                    required={true}
                  ></CustomizedSelect>
                </Grid>
                {systemAccess &&
                systemAccess.value == SystemAccess.Yes.toString() ? (
                  <>
                    <Grid item xs={6}>
                      <CustomizedSelect
                        placeholder={defaultValue.label}
                        options={entityAccessLevelList}
                        isSearchable={false}
                        displayLabel={i18nLabels.AccessLevel}
                        handleChange={(newValue: BaseModel) =>
                          setAccessLevel(newValue)
                        }
                        value={accessLevel}
                        required={
                          systemAccess &&
                          systemAccess.value == SystemAccess.Yes.toString()
                        }
                        errorValue={errors.accessLevel}
                        errorMessage={errors.accessLevel}
                      ></CustomizedSelect>
                    </Grid>

                    {accessLevel.value == AccessLevel.Location.toString() ? (
                      <>
                        <Grid item xs={12}>
                          <CustomSelectPaginate
                            required
                            readOnly={false}
                            key={
                              values.pickupLocation
                                ? values.pickupLocation.length
                                : 0
                            }
                            additional={defaultAdditional}
                            value={
                              values.pickupLocation ? values.pickupLocation : []
                            }
                            loadOptions={(
                              search: string,
                              prevOptions: any,
                              page: any
                            ) =>
                              loadPickupCompanyOptions(
                                entityId,
                                search,
                                prevOptions,
                                page
                              )
                            }
                            handleChange={async (newValue: any) => {
                              setValues({
                                ...values,
                                pickupLocation: newValue,
                              });
                            }}
                            placeholder={defaultValue.label}
                            isSearchable={true}
                            displayLabel={i18nLabels.PickupCompany}
                            errorValue={true}
                            errorMessage={errors.pickUpCustomerLocation}
                            debounceTimeout={500}
                            isMultiSelect={true}
                          />
                        </Grid>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
            <Grid item xs={6}>
              <FormControl
                margin="normal"
                displayLabel={i18nLabels.Email}
                id="Email"
                required={
                  systemAccess &&
                  systemAccess.value == SystemAccess.Yes.toString()
                }
                inputType="text"
                errorValue={errors.email}
                errorMessage={errors.email}
                inputProps={{ maxLength: 50 }}
                handleChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ) => setValues({ ...values, email: event.target.value })}
                value={values.email}
                multiline={false}
              ></FormControl>
            </Grid>
            {!isHidden ? (
              <Grid item xs={6}>
                <FormControl
                  margin="normal"
                  displayLabel={i18nLabels.SiteInformation}
                  id="SiteInformation"
                  inputType="text"
                  inputProps={{ maxLength: 100 }}
                  handleChange={(
                    event: React.ChangeEvent<
                      HTMLTextAreaElement | HTMLInputElement
                    >
                  ) =>
                    setValues({
                      ...values,
                      siteInformation: event.target.value,
                    })
                  }
                  value={values.siteInformation}
                  multiline={false}
                ></FormControl>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </DialogContent>
        <DialogActions className="btn-wrap">
          <Button
            onClick={close}
            color="neutral"
            disableElevation
            variant="contained"
          >
            {' '}
            {I18n('Notes.Cancel')}
          </Button>
          <Button
            onClick={submit}
            autoFocus
            color="primary"
            disableElevation
            variant="contained"
          >
            {I18n('Notes.Save')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
