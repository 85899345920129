import { Grid } from '@mui/material';
import ImageUploadPreview from 'common/ImageUploadPreview/ImageUploadPreview';
import FormAccordianSave from 'common/formControl/formAccordianSave';
import CustomizedInputs from 'common/formControl/formControl';
import ApiUrlConstants from 'constants/api.constants';
import environment from 'environment';
import { BaseModel, ImageUploadViewModel } from 'models/pagination-model';
import React from 'react';
import I18n from 'utilities/i18n';

function MobileDetails(props: any) {
  let { mobileRemarks, images, waitTime } = props;

  const MobileDetails = () => {
    return (
      <>
        <Grid
          sx={{ mb: 2 }}
          container
          marginTop={2}
          paddingLeft={2}
          className="grid-wrap two-col mb-2"
          display={'block'}
        >
          <Grid item lg={12} md={12} padding={3}>
            <CustomizedInputs
              margin="normal"
              displayLabel={I18n('WorkorderPickup.Remarks')}
              name="mobileRemarks"
              id="mobileRemarks"
              inputType="text"
              value={mobileRemarks}
              readOnly={true}
              multiline={true}
            />
            <br />
            <br />
            <CustomizedInputs
              margin="normal"
              displayLabel={I18n('WorkorderPickup.WaitTime')}
              name="waitTime"
              id="waitTime"
              inputType="text"
              value={waitTime}
              readOnly={true}
              multiline={false}
            />
          </Grid>
          {images?.length > 0 ? (
            <Grid
              item
              lg={4}
              md={8}
              padding={3}
              style={{ display: 'contents' }}
            >
              <ImageUploadPreview
                readOnly={true}
                disableKeyboardNav={true}
                isImageOnly={true}
                images={images.map((y: any) => {
                  return {
                    documentTag: {
                      label: y.documentTagName,
                      value: y.documentTag?.toString(),
                    } as BaseModel,
                    error: '',
                    id: y.id,
                    previewUrl:
                      environment.api.baseUrl +
                      ApiUrlConstants.DownloadImageUrl +
                      y.imageUrl,
                    path: y.imageUrl,
                    imageUrl: y.imageUrl,
                  } as ImageUploadViewModel;
                })}
              />
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </>
    );
  };
  return (
    <FormAccordianSave
      title={'Mobile Details'}
      details={MobileDetails()}
      showActionButton={false}
      className="inner-heading"
    ></FormAccordianSave>
  );
}

export default MobileDetails;
