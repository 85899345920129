import environment, { Environment } from './base';

export const API_URL = 'https://betaapi-myhazgo.hazgo.com/api/api/';
export const UPLOADEDFILEURL =
  'https://betaapi-myhazgo.hazgo.com/backend/wwwroot/';
export const mapboxToken =
  'pk.eyJ1IjoiZGVtZXV0ZXJudiIsImEiOiJjazA5Y3o4MWkwNzI1M2NtZHlneDE2bmtoIn0.QZy6Wvp1topmVDQLppYedA';
const env = environment(API_URL, UPLOADEDFILEURL, mapboxToken);

const stagingEnv: Environment = {
  ...env,
  // override anything that gets added from base.
  api: {
    ...env.api,
    // error200: `${baseApi}/api/v1/error-200`,
    // error500: `${baseApi}/api/v1/error-500`,
  },
  isProduction: false,
  isDevelopment: true,
};

export default stagingEnv;
