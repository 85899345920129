import {
  Breadcrumbs,
  Grid,
  Link,
  Typography,
  InputLabel,
  Button,
  Box,
  FormControl,
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import CustomizedInputs from 'common/formControl/formControl';
import RadioGroupInput from 'common/RadioGroup/RadioGroupInput';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CustomDatePicker from 'common/dateAdapter/dateAdapter';
import ImageUploadPreview from 'common/ImageUploadPreview/ImageUploadPreview';
import environment from 'environment';
import {
  DecPackQuestions,
  DecPackQuestionsDescription,
  DecPackQuestionsDto,
  DnPWorkOrders,
  DecPackServiceType,
} from 'models/deck-pack-orders';
import {
  EntityTypeEnum,
  DocumentEntity,
  BaseModel,
  DGDDescription,
  ActivityOwner,
  RadioGroupBaseModel,
  OrderType,
  ImageUploadViewModel,
  Workorders,
  OrderByDirection,
  CustomerLocation,
  AirlineRestrictionModule,
  StateRestrictionModule,
  CheckDocumentationType,
  WorkOrderStatus,
} from 'models/pagination-model';
import RouteEnum from 'models/RouteEnum';
import React, { useState, useEffect, BaseSyntheticEvent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import I18n from 'utilities/i18n';
import http from '../../../utilities/httpService';
import {
  getDecPackById,
  getWorkOrderQuestions,
  getWorkOrderStatus,
} from '../DeckPackApiService';
import PDFViewer from 'common/PDFViewer/PDFViewer';
import QuestionStepper from 'common/Stepper/QuestionStepper';
import {
  getWorkOrderImages,
  saveWorkOrderImages,
} from 'common/ImageUploadPreview/WorkOrderImagesApiService';
import { hasPermission } from 'utilities/protectedRoute';
import { Permission } from 'Permissions';
import ApiUrlConstants from 'constants/api.constants';
import FormAccordianSave from 'common/formControl/formAccordianSave';
import AirlineRestrictionPopup from 'components/Airline/AirlineRestrictionPopup';
import StateRestrictionPopup from 'common/State/StateRestrictionPopup';
import moment from 'moment';
import DecpackStatus from './DecpackStatus';
import CreatedByandUpdatedBy from 'common/CreatedByandUpdatedBy/CreatedByandUpdatedBy';
import MobileDetails from 'common/MobileDetails/MobileDetails';

function Execution() {
  const { woId, id } = useParams();
  let navigate = useNavigate();
  const breadcrumbs = [
    <Typography key="3" color="text.primary">
      {I18n('Nav.Order')}
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={() => navigate(RouteEnum.DecPackOrdersList)}
        className="cpointer"
      >
        {I18n('Nav.DecPackOrders')}
      </Link>
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={() => navigate(RouteEnum.ViewDecPackOrders + '/' + id + '/1')}
        className="cpointer"
      >
        {I18n('ShippingOrders.WorkOrders')}
      </Link>
    </Typography>,
  ];
  const defaultValue = {
    value: '0',
    label: I18n('Placeholders.Select'),
  } as BaseModel;
  const [isOpenAirline, setIsOpenAirline] = useState(false);
  const [airlineId, setAirLineId] = useState(0);
  const [isOpenState, setIsOpenState] = useState(false);
  const [isSaveNext, setIsSaveNext] = useState<boolean>(true);
  const [stateIds, setStateIds] = useState<number[]>([]);

  const [isMobile, setIsMobile] = useState(false);
  const i18nMessages = {
    document: I18n('ShippingOrders.Document'),
    execution: I18n('DecPackOrders.WorkOrder.Execution'),
    dgdDocument: I18n('DecPackOrders.WorkOrder.DGDDocument'),
    dgdDocFileName: I18n('DecPackOrders.WorkOrder.FileNames.DGDDocPDF'),
    comment: I18n('DecPackOrders.WorkOrder.Comment'),
    status: I18n('ShippingOrders.Status'),
    specialInstruction: I18n('DecPackOrders.WorkOrder.SpecialInstruction'),
    savedSuccess: I18n('Common.SavedSuccessfully'),
    commonFieldIsRequired: I18n('Common.FieldIsRequired'),
    somethingWentWrong: I18n('Common.SomethingWentWrong'),
    title: I18n('DecPackOrders.WorkOrder.OrderExecution'),
    showAirLine: I18n('DecPackOrders.WorkOrder.AirLine'),
    showCountry: I18n('DecPackOrders.WorkOrder.Country'),
    customerName: I18n('DecPackOrders.CustomerName'),
    date: I18n('DecPackOrders.WorkOrder.Date'),
    photos: I18n('DecPackOrders.WorkOrder.Photos'),
    checkQuestionsMandatory: I18n('DecPackOrders.CheckQuestionsMandatory'),
    NoShippingDocsAvailable: I18n(
      'DecPackOrders.DecPackQuestions.WOExecution.NoDocsAvailable'
    ),
    noImagesSelected: I18n('Common.NoImagesSelectedToUpload'),
    imageSaveSuccess: I18n('Common.ImageSaveSuccess'),
    AirlineRestriction: I18n('Airline.AirlineRestrictions'),
    StateRestriction: I18n('State.StateRestrictions'),
    RequiredField: I18n('Common.RequiredField'),
    CommentRequired: I18n('DecPackOrders.Validation.CommentRequired'),
    CustomerNameRequired: I18n('DecPackOrders.Validation.CustomerNameRequired'),
    DateRequired: I18n('DecPackOrders.Validation.DateRequired'),
    InvalidDate: I18n('Common.InvalidDate'),
    SignedOffBy: I18n('Common.SignedOffBy'),
    ShipmentNotAvailableInWarehouse: I18n(
      'DecPackOrders.WorkOrder.ShipmentNotAvailableInWarehouse'
    ),
  };
  const [values, setValues] = useState<any>({
    id: 0,
    isNotAvailableInWarehouse: false,
    customerName: '',
    airLineName: '',
    decPackInitialOrderId: id,
    decPackWorkOrdersId: woId,
    comment: '',
    countryName: '',
    status: '',
    decPackWOExecutionId: 0,
    decPackCheckDocumentationId: 0,
    date: new Date().toISOString(),
    serviceType: 0,
    locationType: 0,
    specialInstructions: '',
    mobileRemarks: '',
    waitTime: 0,
    signedOffByURL: '',
  });
  const [isStatusSaveCallApi, setIsStatusSaveCallApi] =
    useState(false); /* To call Submit APi Status from Status Component */
  const [validateStatus, setValidateStatus] = useState<boolean>(false);
  /* validateStatus - To validate Status Dropdown from Status Component */

  const [isQuestionnaireCallSaveApi, setIsQuestionnaireCallSaveApi] =
    useState(
      false
    ); /* To call Submit APi Questionnaire from Status Component */
  const [validateQuestionnaire, setValidateQuestionnaire] =
    useState<boolean>(false);
  /* validateStatus - To validate Status Dropdown from Status Component */
  const [hideSignOff, setHideSignOff] = useState<boolean>(true);
  const [hideComment, setHideComment] = useState<boolean>(true);
  const [status, setStatus] = useState('');
  const [questions, setQuestions] = useState<string[]>([]);
  const [canEdit, setCanEdit] = useState(
    hasPermission(Permission.canEditDecPackOrders)
  );
  const [details, setDetails] = useState<any>({
    createdBy: '',
    createdDate: '',
    updatedBy: '',
    updatedDate: '',
  });
  const [assigneeData, setAssigneeData] = useState<any>({
    assignedToId: '',
    assignedToName: '',
    assignNextToId: '',
    assignNextToName: '',
  });
  const [answers, setAnswers] = useState([
    {
      decPackQuestionId: DecPackQuestions.WOExecutionQA1,
      questionKey: DecPackQuestions.WOExecutionQA1,
      answer: '',
      decPackWOExecutionId: 0,
      decPackInitialOrderId: Number(id),
      decPackWorkOrdersId: Number(woId),
    },
    {
      decPackQuestionId: DecPackQuestions.WOExecutionQA2,
      questionKey: DecPackQuestions.WOExecutionQA2,
      answer: '',
      decPackWOExecutionId: 0,
      decPackInitialOrderId: Number(id),
      decPackWorkOrdersId: Number(woId),
    },
    {
      decPackQuestionId: DecPackQuestions.WOExecutionQA3,
      questionKey: DecPackQuestions.WOExecutionQA3,
      answer: '',
      decPackWOExecutionId: 0,
      decPackInitialOrderId: Number(id),
      decPackWorkOrdersId: Number(woId),
    },
    {
      decPackQuestionId: DecPackQuestions.WOExecutionQA4,
      questionKey: DecPackQuestions.WOExecutionQA4,
      answer: '',
      decPackWOExecutionId: 0,
      decPackInitialOrderId: Number(id),
      decPackWorkOrdersId: Number(woId),
    },
    {
      decPackQuestionId: DecPackQuestions.WOExecutionQA5,
      questionKey: DecPackQuestions.WOExecutionQA5,
      answer: '',
      decPackWOExecutionId: 0,
      decPackInitialOrderId: Number(id),
      decPackWorkOrdersId: Number(woId),
    },
  ]);
  const [images, setImages] = useState<ImageUploadViewModel[]>([]);
  const [docs, setDocs] = useState([]);
  const [isPDFDoc, setIsPDFDoc] = useState(true);
  const [gridData, setGridData] = useState({
    sortOrder: OrderByDirection.Descending,
    sortBy: 'name',
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [10, 20, 50, 100],
    pageSize: 10,
    page: 0,
    searchExpression:
      'EntityType~=~' + EntityTypeEnum.DecPackWODGD + ' and EntityId~=~' + id,
  });

  const [errors, setErrors] = useState({
    customerName: '',
    date: '',
    comment: '',
  });
  const validate = () => {
    let temp: any = {};
    let isValid = true;

    // Validate photos selection
    if (images.length === 0) {
      toast.error(i18nMessages.noImagesSelected);
      return false;
    }

    if (values.isNotAvailableInWarehouse) {
      return isValid;
    }

    questions.forEach((element: any) => {
      if (element.key == DecPackQuestions.WOExecutionQA5) {
        isValid = isValid && answers.some((x) => x.questionKey == element.key);
      }
    });

    const requiredQuestions: any[] = [
      DecPackQuestions.WOExecutionQA3,
      DecPackQuestions.WOExecutionQA4,
      DecPackQuestions.WOExecutionQA5,
    ];

    if (
      answers.some(
        (x) =>
          x.answer === '' && requiredQuestions.find((k) => k == x.questionKey)
      )
    ) {
      toast.error(i18nMessages.RequiredField);
      return (isValid = false);
    }

    if (!isValid) {
      toast.error(i18nMessages.checkQuestionsMandatory);
      return isValid;
    }

    let readyForCarriage = answers.find(
      (x) => x.questionKey == DecPackQuestions.WOExecutionQA5
    )?.answer;

    let checkCustomerFields =
      !values.isNotAvailableInWarehouse &&
      values.serviceType == DecPackServiceType.DGDCreation &&
      values.locationType != CustomerLocation.WareHouse &&
      readyForCarriage != CheckDocumentationType.No.toString();

    if (checkCustomerFields) {
      temp.customerName = !values.customerName?.replace(/\s/g, '')
        ? i18nMessages.CustomerNameRequired
        : '';
      temp.date = '';

      if (!values.date) {
        temp.date = i18nMessages.DateRequired;
      } else {
        let isDateValid = moment(values.date).isValid();
        if (!isDateValid) {
          temp.date = i18nMessages.InvalidDate;
        }
      }
      isValid = !(temp.customerName || temp.date);
    }

    if (
      answers.some(
        (x: any) =>
          (x.questionKey === DecPackQuestions.WOExecutionQA3 ||
            x.questionKey === DecPackQuestions.WOExecutionQA4) &&
          x.answer == CheckDocumentationType.No.toString()
      ) &&
      (!values.comment || !values.comment.trim())
    ) {
      temp.comment = i18nMessages.CommentRequired;
      isValid = false;
    }

    setErrors({
      ...temp,
    });
    return isValid;
  };

  let entityWorkOrderStatusList: Array<BaseModel> = [];
  const getStatusList = () => {
    entityWorkOrderStatusList = [defaultValue];
    DGDDescription.forEach((value: string, key: number) => {
      entityWorkOrderStatusList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getStatusList();

  let masterQuestionListList: Array<BaseModel> = [];
  const getQuestionsList = () => {
    DecPackQuestionsDescription.forEach((value: string, key: number) => {
      masterQuestionListList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getQuestionsList();

  let answerList: Array<RadioGroupBaseModel> = [];
  const getAnswerList = () => {
    answerList.push({
      value: CheckDocumentationType.Yes.toString(),
      label: I18n('Common.Yes'),
    } as RadioGroupBaseModel);
    answerList.push({
      value: CheckDocumentationType.No.toString(),
      label: I18n('Common.No'),
    } as RadioGroupBaseModel);
    answerList.push({
      value: CheckDocumentationType.NA.toString(),
      label: I18n('Common.NA'),
    } as RadioGroupBaseModel);
  };
  getAnswerList();

  const setAnswerData = (value: boolean, questionKey: number) => {
    var tempData: any = [];
    var tempAnswer = String(value);
    var showComment = false;
    if (answers && answers.some((x) => +x.questionKey == +questionKey)) {
      tempData = [...answers];
      const index = tempData.findIndex(
        (x: any) => +x.questionKey == +questionKey
      );
      tempData[index].answer = tempAnswer;
    } else {
      tempData = [
        ...answers,
        {
          decPackQuestionId: Number(questionKey),
          answer: tempAnswer,
          questionKey: Number(questionKey),
        } as any,
      ];
    }
    setAnswers(tempData);

    var hiddenSignOff =
      values.serviceType != DecPackServiceType.DGDCreation ||
      values.locationType == CustomerLocation.WareHouse;

    if (questionKey == DecPackQuestions.WOExecutionQA5) {
      setHideSignOff(
        tempAnswer == CheckDocumentationType.No.toString() || hiddenSignOff
      );
    }

    // Show/Hide Comment Field Based on Answer of Check Questions
    if (questionKey == DecPackQuestions.WOExecutionQA3) {
      showComment =
        tempAnswer == CheckDocumentationType.No.toString() ||
        answers.some(
          (x) =>
            +x.questionKey == DecPackQuestions.WOExecutionQA4 &&
            x.answer == CheckDocumentationType.No.toString()
        );
      setHideComment(!showComment);
    }
    if (questionKey == DecPackQuestions.WOExecutionQA4) {
      showComment =
        tempAnswer == CheckDocumentationType.No.toString() ||
        answers.some(
          (x) =>
            +x.questionKey == DecPackQuestions.WOExecutionQA3 &&
            x.answer == CheckDocumentationType.No.toString()
        );
      setHideComment(!showComment);
    }
    if (!showComment) {
      setValues({ ...values, comment: '' });
      setErrors({ ...errors, comment: '' });
    }
  };

  let getDefaultAnswer = (questionDetail: any) => {
    let defaultAnswer;
    if (answers.length > 0) {
      answers.forEach((answerDetail: any, key: number) => {
        if (answerDetail.questionKey == questionDetail.key) {
          if (answerDetail.answer !== '') {
            defaultAnswer = answerDetail.answer;
          }
        }
      });
    }
    return Number(defaultAnswer);
  };

  const fileDataFunction = (data: ImageUploadViewModel[]) => {
    setImages([...images, ...data]);
  };

  const deleteFileFunction = (index: number) => {
    const localImages: any[] = [...images];
    localImages.splice(index, 1);
    setImages(localImages);
  };

  const editedFileDataFunction = (updatedImages: ImageUploadViewModel[]) => {
    setImages([...updatedImages]);
  };

  const QuestionnaireData = () => {
    let decPackInitialOrderId = Number(id);
    let decPackWorkOrdersId = Number(woId);
    return (
      <QuestionStepper
        orderId={decPackInitialOrderId}
        woId={decPackWorkOrdersId}
        woNo={Workorders.DecPackWOOrderExecution}
        validateQuestionnaire={validateQuestionnaire}
        isQuestionnaireCallSaveApi={isQuestionnaireCallSaveApi}
        handleSuccessQuestionnaireSave={handleSuccessQuestionnaireSave}
        handleErrorQuestionnaireSave={handleErrorQuestionnaireSave}
        readOnly={!canEdit}
        isHidden={values.isNotAvailableInWarehouse}
        status={status}
      />
    );
  };

  /* Validate Status Dropdown Success call from Status Component */
  const handleSuccessQuestionnaireSave = async () => {
    let isValid = +status == WorkOrderStatus.Done ? validate() : true;
    if (isValid) {
      let apiUrl;
      let result;
      const finalValue = {
        id: values.id,
        decPackWorkOrdersId: Number(woId),
        decPackInitialOrderId: Number(id),
        comment: values.comment,
        isNotAvailableInWarehouse: values.isNotAvailableInWarehouse,
        customerName: values.customerName,
        mobileRemarks: values.mobileRemarks,
        signedOffByURL: values.signedOffByURL,
        waitTime: values.waitTime,
        date: values.date != null ? values.date : null,
      };

      setIsQuestionnaireCallSaveApi(true);
      await handlePhotoSaveClick();
      try {
        let id = 0;
        if (values.id == 0) {
          apiUrl = new URL(environment.api.baseUrl + 'DecPackWOExecution');
          result = await http.post(apiUrl.toString(), finalValue);
          if (result.data.id > 0) {
            setValues({
              ...values,
              id: result.data.id,
            });

            id = result.data.id;
            /* To set Validation and Submit APi Questionnaire False */
          }
        } else {
          apiUrl = new URL(environment.api.baseUrl + 'DecPackWOExecution');
          result = await http.put(apiUrl.toString(), finalValue);
          if (result) {
            id = values.id;
          }
        }

        if (id > 0) {
          if (!finalValue.isNotAvailableInWarehouse) {
            await UpdateWOAnswers(id);
          } else {
            toast.success(i18nMessages.savedSuccess);
            await getPictures();
            setIsStatusSaveCallApi(true);
          }
          handleErrorQuestionnaireSave();
          /* To set Validation and Submit APi Questionnaire False */
        }
      } catch (error: any) {
        toast.error(i18nMessages.somethingWentWrong);
        handleErrorQuestionnaireSave();
        /* To set Validation and Submit APi Questionnaire False */
      }
    } else {
      handleErrorQuestionnaireSave();
      /* To set Validation and Submit APi Questionnaire False */
    }
  };

  /* Validate Status Dropdown Error call from Status Component */
  const handleErrorQuestionnaireSave = () => {
    setValidateQuestionnaire(false);
    setIsQuestionnaireCallSaveApi(false);
    setValidateStatus(false);
    setIsStatusSaveCallApi(false);
  };

  /* Validate Status Dropdown Success call from Status Component */
  const handleSuccessStatusSave = async () => {
    setValidateQuestionnaire(true);
  };

  /* Validate Status Dropdown Error call from Status Component */
  const handleErrorStatusSave = () => {
    handleErrorQuestionnaireSave();
  };

  const handleAirlineClose = () => {
    setIsOpenAirline(false);
  };

  const handleAirlineOpen = () => {
    setIsOpenAirline(true);
  };

  const handleStateClose = () => {
    setIsOpenState(false);
  };

  const handleStateOpen = () => {
    setIsOpenState(true);
  };

  const DGDDocument = () => {
    return (
      <div>
        <Accordion expanded={true} className="custom-accordion">
          <AccordionSummary
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Grid
              xs={12}
              container
              justifyContent="space-between"
              alignItems="center"
              flexDirection={{ xs: 'column', sm: 'row' }}
              spacing={0}
            >
              <Grid xs={10}>
                <Typography variant="h5">{i18nMessages.dgdDocument}</Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Typography height="400px">
              <PDFViewer docs={docs} isPDFDoc={isPDFDoc} />
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  };

  const Execution = () => {
    return (
      <Grid item container spacing={2} className="grid-wrap">
        <Grid item lg={6} md={6}>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(event) => {
                      setValues({
                        ...values,
                        isNotAvailableInWarehouse: event.target.checked,
                      });
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                    checked={values.isNotAvailableInWarehouse}
                    disabled={!canEdit}
                  />
                }
                label={i18nMessages.ShipmentNotAvailableInWarehouse}
              />
            </Grid>
            {!values.isNotAvailableInWarehouse ? (
              <Grid container spacing={2} item lg={12} md={12}>
                <Grid item lg={6} md={6}>
                  <AirlineRestrictionPopup
                    isOpen={isOpenAirline}
                    handleClose={handleAirlineClose}
                    title={i18nMessages.AirlineRestriction}
                    moduleRestriction={AirlineRestrictionModule.DecPackWO6}
                    airlineId={airlineId}
                  />
                  <FormControl>
                    <Box sx={{ mt: 3 }}>
                      <Button variant="contained" onClick={handleAirlineOpen}>
                        {i18nMessages.AirlineRestriction}
                      </Button>
                    </Box>
                  </FormControl>
                </Grid>
                <Grid item lg={6} md={6}>
                  <StateRestrictionPopup
                    isOpen={isOpenState}
                    handleClose={handleStateClose}
                    title={i18nMessages.StateRestriction}
                    moduleRestriction={StateRestrictionModule.DecPackWO6}
                    stateIds={stateIds}
                  />
                  <FormControl>
                    <Box sx={{ mt: 3 }}>
                      <Button variant="contained" onClick={handleStateOpen}>
                        {i18nMessages.StateRestriction}
                      </Button>
                    </Box>
                  </FormControl>
                </Grid>
                <Grid item lg={12} md={12}>
                  <InputLabel shrink htmlFor="bootstrap-input">
                    {i18nMessages.specialInstruction}
                  </InputLabel>
                  <label>{values.specialInstructions}</label>
                </Grid>
                {!hideSignOff ? (
                  <>
                    <Grid item lg={12} md={12}>
                      <CustomizedInputs
                        displayLabel={i18nMessages.customerName}
                        inputProps={{ maxLength: 50 }}
                        handleChange={(newValue: any) => {
                          setValues({
                            ...values,
                            customerName: newValue.target.value,
                          });
                          if (newValue.value !== '') {
                            setErrors({
                              ...errors,
                              customerName: '',
                            });
                          }
                        }}
                        required={true}
                        errorValue={errors.customerName}
                        errorMessage={errors.customerName}
                        value={values.customerName}
                        readOnly={!canEdit}
                      ></CustomizedInputs>
                    </Grid>
                    <Grid item lg={6} md={6}>
                      <CustomDatePicker
                        handleChange={(newValue: any) => {
                          setValues({
                            ...values,
                            date:
                              newValue != null && moment(newValue).isValid()
                                ? newValue.toISOString()
                                : newValue,
                          });
                        }}
                        displayLabel={i18nMessages.date}
                        errorValue={errors.date}
                        errorMessage={errors.date}
                        value={values.date}
                        id="date"
                        readOnly={!canEdit}
                      />
                    </Grid>
                    <Grid item lg={6} md={6}>
                      <InputLabel shrink htmlFor="bootstrap-input">
                        {i18nMessages.SignedOffBy}
                      </InputLabel>
                      {values.signedOffByURL ? (
                        <>
                          <ImageUploadPreview
                            readOnly={true}
                            disableKeyboardNav={true}
                            limit={10}
                            isImageOnly={true}
                            images={[
                              {
                                documentTag: {
                                  label: '',
                                  value: '',
                                } as BaseModel,
                                error: '',
                                previewUrl:
                                  environment.api.baseUrl +
                                  ApiUrlConstants.DownloadImageUrl +
                                  values.signedOffByURL,
                                path: values.signedOffByURL,
                                imageUrl: values.signedOffByURL,
                              } as ImageUploadViewModel,
                            ]}
                            hideTags={true}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
        <Grid item lg={6} md={6}>
          <Grid container spacing={2}>
            <>
              {!values.isNotAvailableInWarehouse ? (
                questions.map((question: any) => (
                  <Grid item lg={6} md={6}>
                    <RadioGroupInput
                      displayLabel={question.name + ' *'}
                      name={`question${question.id}`}
                      value={getDefaultAnswer(question)}
                      handleChange={(event: BaseSyntheticEvent) => {
                        setAnswerData(event.target.value, question.key);
                      }}
                      options={answerList}
                      readOnly={
                        (question.key != DecPackQuestions.WOExecutionQA5 &&
                          question.key != DecPackQuestions.WOExecutionQA4 &&
                          question.key != DecPackQuestions.WOExecutionQA3) ||
                        !canEdit
                      }
                    ></RadioGroupInput>
                  </Grid>
                ))
              ) : (
                <></>
              )}
            </>

            <Grid item lg={12} md={12}>
              {!values.isNotAvailableInWarehouse && !hideComment ? (
                <CustomizedInputs
                  displayLabel={i18nMessages.comment}
                  handleChange={(newValue: any) => {
                    setValues({
                      ...values,
                      comment: newValue.target.value,
                    });
                  }}
                  inputProps={{ maxLength: 200 }}
                  value={values.comment}
                  errorValue={true}
                  errorMessage={errors.comment}
                  required={true}
                  readOnly={!canEdit}
                ></CustomizedInputs>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const getPictures = async () => {
    const x = await getWorkOrderImages(
      EntityTypeEnum.DecPackOrders,
      woId ? +woId : 0,
      id ? +id : 0,
      DocumentEntity.DecPackWOExecution
    );
    if (x.result) {
      /* Object conversion from backend viewModel to ImagePreview component Object */
      setImages(
        x.data.map((y) => {
          return {
            documentTag: {
              label: y.documentTagName,
              value: y.documentTag?.toString(),
            } as BaseModel,
            error: '',
            id: y.id,
            previewUrl:
              environment.api.baseUrl +
              ApiUrlConstants.DownloadImageUrl +
              y.imageUrl,
            path: y.imageUrl,
          } as ImageUploadViewModel;
        })
      );
    }
  };

  const PhotosSection = () => {
    return (
      <>
        <Grid
          sx={{ mb: 2 }}
          container
          spacing={1}
          className="grid-wrap two-col mb-2"
        >
          <Grid item lg={12}>
            <ImageUploadPreview
              disableKeyboardNav={true}
              limit={10}
              fileData={fileDataFunction}
              fileRemove={deleteFileFunction}
              editedFileData={editedFileDataFunction}
              images={images}
              readOnly={!canEdit}
            />
          </Grid>
        </Grid>
        {!(images && images.length) && (
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item lg={12}>
              {i18nMessages.NoShippingDocsAvailable}
            </Grid>
          </Grid>
        )}
      </>
    );
  };

  const handleSaveClick = () => {
    setValidateStatus(true);
    setIsSaveNext(false);
  };

  const getDecPackExecutionWO = async () => {
    let decPackInitialOrderId = Number(id);
    let decPackWorkOrdersId = Number(woId);
    const apiUrl = new URL(
      environment.api.baseUrl +
        'DecPackWOExecution/' +
        decPackInitialOrderId +
        '/' +
        decPackWorkOrdersId
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result && result.data) {
        var status = await getWOStatus();
        var hiddenSignOff = false;
        setValues({
          ...values,
          id: result.data.id,
          airLineName: result.data.airLineName,
          decPackInitialOrderId: result.data.decPackInitialOrderId,
          decPackWorkOrdersId: result.data.decPackWorkOrdersId,
          comment: result.data.comment,
          countryName: result.data.countryName,
          customerName: result.data.customerName,
          isNotAvailableInWarehouse: result.data.isNotAvailableInWarehouse,
          decPackCheckDocumentationId: result.data.decPackCheckDocumentationId,
          date: result.data.date,
          serviceType: result.data.serviceType,
          locationType: result.data.locationType,
          specialInstructions: result.data.specialInstructions,
          mobileRemarks: result.data.mobileRemarks
            ? result.data.mobileRemarks
            : values.mobileRemarks,
          signedOffByURL: result.data.signedOffByURL
            ? result.data.signedOffByURL
            : values.signedOffByURL,
          waitTime: result.data.waitTime
            ? result.data.waitTime
            : values.waitTime,
          status,
        });

        if (
          result.data.serviceType != DecPackServiceType.DGDCreation ||
          result.data.locationType == CustomerLocation.WareHouse
        ) {
          hiddenSignOff = true;
        }

        await getWOAnswers(
          result.data.id,
          result.data.decPackInitialOrderId,
          hiddenSignOff
        );
      }
    } catch (error) {}
    await getPictures();
  };

  const getWOAnswers = async (
    decPackWOExecutionId: number,
    decPackInitialOrderId: number,
    hiddenSignOff: boolean
  ) => {
    const apiUrl = new URL(
      environment.api.baseUrl +
        'DecPackWOExecution/Answers/' +
        decPackWOExecutionId +
        '/' +
        decPackInitialOrderId
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result && result.data.length > 0) {
        setAnswers(
          (result.data as Array<any>).map((x) => {
            const ans = answers.find((y) => +y.questionKey == x.questionKey);
            if (ans) {
              ans.answer = x.answer;
              ans.decPackWOExecutionId = x.decPackWOExecutionId;
              if (x.questionKey == DecPackQuestions.WOExecutionQA5) {
                hiddenSignOff =
                  ans.answer == CheckDocumentationType.No.toString() ||
                  hiddenSignOff; // changed for 861
              }
            }
            return ans as any;
          })
        );

        // Hide Comment Field if any of the check question has No answer
        let hasAnyNoAnswer = (result.data as Array<any>).some(
          (x) =>
            (x.questionKey == DecPackQuestions.WOExecutionQA3 ||
              x.questionKey == DecPackQuestions.WOExecutionQA4) &&
            x.answer == CheckDocumentationType.No.toString() // changed for 861
        );
        setHideComment(!hasAnyNoAnswer);
      }
      setHideSignOff(hiddenSignOff);
    } catch (error) {}
  };

  const getDGDDocument = async () => {
    const apiUrl = new URL(environment.api.baseUrl + 'Documents');
    const uris: any = [];

    const currentDocument: any = {
      uri: '',
      filePath: '',
      fileName: '',
    };
    try {
      apiUrl.searchParams.set('$filter', gridData.searchExpression);
      apiUrl.searchParams.set('$orderby', gridData.sortBy);
      apiUrl.searchParams.set(
        '$skip',
        (gridData.page * gridData.pageSize).toString()
      );
      apiUrl.searchParams.set('$top', gridData.pageSize.toString());
      apiUrl.searchParams.set(
        '$orderbydirection',
        gridData.sortOrder.toString()
      );
      const result = await http.get(apiUrl.toString());
      if (result && result?.data?.values.length > 0) {
        result.data.values.forEach((url: any) => {
          if (url.documentUrl) {
            setIsPDFDoc(url.documentUrl.endsWith('.pdf'));
            currentDocument.uri =
              environment.api.uploadedFileUrl + '/' + url.documentUrl;
            currentDocument.fileName = i18nMessages.dgdDocFileName;
            currentDocument.filePath = url.documentUrl;

            uris.push(currentDocument);
          }
        });
      }
      setDocs(uris);
    } catch (error) {}
  };

  const handlePhotoSaveClick = async () => {
    const imagesResult = await saveWorkOrderImages(
      images,
      EntityTypeEnum.DecPackOrders,
      DocumentEntity.DecPackWOExecution,
      false,
      id ? +id : 0,
      woId ? +woId : 0
    );
    if (imagesResult && imagesResult.result) {
    } else {
      toast.error(i18nMessages.somethingWentWrong);
    }
  };

  const UpdateWOAnswers = async (executionId: any) => {
    const apiUrl = new URL(
      environment.api.baseUrl + 'DecPackWOExecution/Answers/' + executionId
    );
    try {
      var finalData: any = [];
      answers.forEach((element) => {
        finalData.push({
          decPackWOExecutionId: executionId,
          answer: +element.answer,
          decPackQuestionId: element.decPackQuestionId,
          questionKey: element.questionKey,
        });
      });
      const result = await http.put(apiUrl.toString(), finalData);
      if (result.data) {
        toast.success(i18nMessages.savedSuccess);
        await getPictures();
        setIsStatusSaveCallApi(true);
      } else {
        toast.error(i18nMessages.somethingWentWrong);
        handleErrorQuestionnaireSave();
        /* To set Validation and Submit APi Questionnaire False */
      }
    } catch (error) {
      toast.error(i18nMessages.somethingWentWrong);
      handleErrorQuestionnaireSave();
      /* To set Validation and Submit APi Questionnaire False */
    }
  };

  const getWOQuestions = async () => {
    try {
      const response = await getWorkOrderQuestions(DnPWorkOrders.WO6);
      if (response && response.result) {
        setQuestions(
          (response.data as Array<DecPackQuestionsDto>).map((x) => {
            const questionValue = masterQuestionListList.find(
              (y) => +y.value == x.questionKey
            );
            return {
              id: questionValue?.value,
              name: questionValue?.label,
              key: questionValue?.value,
            } as any;
          })
        );
        getDecPackExecutionWO();
      }
    } catch (error) {
      toast.error(i18nMessages.somethingWentWrong);
    }
  };

  const getWOStatus = async () => {
    try {
      const response = await getWorkOrderStatus(
        DnPWorkOrders.WO6,
        id ? +id : 0
      );
      if (response && response.result) {
        setDetails({
          ...details,
          createdBy: response.data.createdBy,
          updatedBy: response.data.updatedBy,
          createdDate: response.data.createdDate,
          updatedDate: response.data.updatedDate,
        });

        setAssigneeData({
          ...assigneeData,
          assignedToId: response.data.assignedToId,
          assignedToName: response.data.assignedToName,
          assignNextToId: response.data.assignedNextToId,
          assignNextToName: response.data.assignedNextToName,
        });

        setIsMobile(response.data.isMobile);
        setStatus(response.data.status);
      }
    } catch (error) {}
  };

  const fetchDecPackOrder = async () => {
    var data = await getDecPackById(Number(id));
    if (data != null) {
      setAirLineId(data.Airline.value);

      var states: number[] = [
        data.FinalDestination.value,
        data.TransitDestination.value,
      ];

      setStateIds(states);
    }
  };

  useEffect(() => {
    getWOQuestions();
    getDGDDocument();
    fetchDecPackOrder();
  }, []);

  const onSaveNextClick = async (event: any) => {
    event.preventDefault();
    setStatus(WorkOrderStatus.Done.toString());
    setValidateStatus(true);
    setIsSaveNext(true);
  };

  const refreshData = async () => {
    handleErrorStatusSave();
    getWOStatus();
  };
  return (
    <>
      <div className="heading-section">
        <div className="heading-section-left">
          <Typography variant="h3" sx={{ mb: 3 }}>
            {i18nMessages.title}
          </Typography>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>
      </div>
      <div className="main-content-section">
        <Grid container spacing={2} className="grid-wrap">
          <Grid item lg={12} md={12}>
            {DGDDocument()}
          </Grid>
          <Grid item lg={12} md={12}>
            <FormAccordianSave
              title={i18nMessages.execution}
              className="inner-heading"
              details={Execution()}
              showActionButton={canEdit}
            ></FormAccordianSave>
            <Grid item lg={12} md={12}>
              {QuestionnaireData()}
            </Grid>
            <FormAccordianSave
              title={i18nMessages.photos}
              className="inner-heading"
              details={PhotosSection()}
              showActionButton={canEdit}
            ></FormAccordianSave>
            <MobileDetails
              mobileRemarks={values.mobileRemarks}
              waitTime={values.waitTime}
            ></MobileDetails>
            <DecpackStatus
              permission={Permission.canEditDecPackOrders}
              woId={DnPWorkOrders.WO6}
              orderId={id}
              statusValue={status}
              activityOwner={ActivityOwner.Driver}
              orderType={OrderType.DecPackOrder}
              isStatusSaveCallApi={isStatusSaveCallApi}
              validateStatus={validateStatus}
              handleSuccessStatusSave={handleSuccessStatusSave}
              handleErrorStatusSave={handleErrorStatusSave}
              isSaveNext={isSaveNext}
              isMobile={isMobile}
              workOrderNo={DnPWorkOrders.WO6}
              assigneeData={assigneeData}
              handleStatusChange={(value: number) =>
                setStatus(value.toString())
              }
            />
            <div className="btn-wrap form-btn-wrap">
              {canEdit ? (
                <Button
                  color="primary"
                  disableElevation
                  variant="contained"
                  onClick={onSaveNextClick}
                >
                  {I18n('Common.SaveNext')}
                </Button>
              ) : (
                <></>
              )}
              &nbsp; &nbsp; &nbsp;
              {canEdit ? (
                <Button
                  type="submit"
                  color="primary"
                  disableElevation
                  variant="contained"
                  onClick={handleSaveClick}
                >
                  {I18n('Common.Save')}
                </Button>
              ) : (
                <></>
              )}
            </div>
            <CreatedByandUpdatedBy
              createdBy={details.createdBy}
              createdDate={details.createdDate}
              updatedBy={details.updatedBy}
              updatedDate={details.updatedDate}
            ></CreatedByandUpdatedBy>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default Execution;
