import { useCallback, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import I18n from 'utilities/i18n';
import FormControl from 'common/formControl/formControl';
import { Grid } from '@mui/material';
import CustomizedSelect from 'common/CustomSelect/CustomSelect';
import {
  AddLocationEntity,
  BaseModel,
  OrderByDirection,
  PaginationResponseModel,
} from 'models/pagination-model';
import environment from 'environment';
import { toast } from 'react-toastify';
import http from '../../utilities/httpService';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from 'react-router-dom';
import AddressAutocompleteInputs from 'common/AddressAutocomplete/AddressAutocompleteInput';
import AddCity from 'common/Cities/AddCity';
import CustomSelectPaginateAdd from 'common/CustomSelect/CustomSelectPaginateAdd';
import { PostCityDropDown } from 'common/DropDownAddAPI/DropDownApiService';

export default function AddImporterExporter(props: any) {
  const params = useParams();
  const defaultValue = {
    value: '',
    label: I18n('Placeholders.Select'),
  } as BaseModel;

  const defaultDropDownValue = {
    value: '0',
    label: I18n('Placeholders.Select'),
  } as BaseModel;
  const [cityList, setCityList] = useState<BaseModel[]>([]);
  const [countryList, setCountryList] = useState<BaseModel[]>([]);
  const { isOpen, handleClose, title, entityId, entityName } = props;

  const [postalCode, setPostalCode] = useState('');
  const [address, setAddress] = useState('');
  const [country, setCountry] = useState(defaultDropDownValue);
  const [city, setCity] = useState(defaultDropDownValue);
  const [errors, setErrors] = useState<any>({});

  const [values, setValues] = useState({
    name: '',
    address: '',
    country: defaultValue,
    city: defaultValue,
    cityId: '',
    postalCode: '',
    street: '',
    eori: '',
    vatNumber: '',
    vatDeferment: '',
  });
  const [cacheUniq, setCacheUniq] = useState(0);

  let countryLst: Array<BaseModel> = [];
  const getCountryList = (countries: any[]) => {
    countryLst = [defaultDropDownValue];
    countries.forEach((getCountryList: any) => {
      countryLst.push({
        value: getCountryList.key,
        label: getCountryList.value,
      } as BaseModel);
    });

    return countryLst;
  };

  const getCountries = async () => {
    let countryLst: any[] = [];

    const apiUrl = new URL(environment.api.baseUrl + 'Country');
    try {
      apiUrl.searchParams.set('$orderby', 'name');
      apiUrl.searchParams.set('$skip', '0');
      apiUrl.searchParams.set('$top', '5000');
      apiUrl.searchParams.set(
        '$orderbydirection',
        OrderByDirection.Ascending.toString()
      );

      const result = await http.get(apiUrl.toString());
      if (result) {
        result.data.values.map(function (item: any) {
          countryLst.push({
            key: item['id'],
            value: item['name'],
          });
        });

        let data = getCountryList(countryLst);
        setCountryList(data);
      }
    } catch (error) {}
  };

  let cityLst: Array<BaseModel> = [];
  const getCityList = (cities: any[]) => {
    cityLst = [defaultDropDownValue];
    cities.forEach((city: any) => {
      cityLst.push({
        value: city.key,
        label: city.value,
      } as BaseModel);
    });

    return cityLst;
  };

  const defaultAdditional: any = {
    page: 1,
  };

  const loadCitiesOptions = async (
    search: string,
    loadedOptions: any,
    { page }: any
  ) => {
    const response = await getCities(search, page);
    const hasMore = Math.ceil(response.TotalRecords / 500) > page;

    return {
      options: response.Values.map((x) => {
        return {
          value: x.value.toString(),
          label: x.label,
        } as BaseModel;
      }),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const getCities = async (search: string = '', pageNo: number = 1) => {
    let cityLst: any[] = [];
    let response: PaginationResponseModel<BaseModel>;
    const apiUrl = new URL(environment.api.baseUrl + 'Cities');
    try {
      if (search && search !== undefined) {
        var searchExpression = '';
        searchExpression += search ? `name~like~${search}` : '';
        apiUrl.searchParams.set('$filter', searchExpression);
      }
      apiUrl.searchParams.set('$orderby', 'name');
      if (pageNo > 1) {
        apiUrl.searchParams.set('$skip', ((pageNo - 1) * 500).toString());
        apiUrl.searchParams.set('$top', '500');
      } else {
        apiUrl.searchParams.set('$top', '10000');
        apiUrl.searchParams.set('$skip', '0');
      }

      apiUrl.searchParams.set(
        '$orderbydirection',
        OrderByDirection.Ascending.toString()
      );

      const result = await http.get(apiUrl.toString());
      if (result) {
        result.data.values.map((item: any) => {
          cityLst.push({
            key: item['id'],
            value: item['name'],
          });
        });

        let data = getCityList(cityLst);
        setCityList(data);

        response = {
          TotalRecords: result.data.totalCount,
          Values: data,
        } as PaginationResponseModel<BaseModel>;
      } else {
        response = {
          TotalRecords: 0,
          Values: [],
        } as PaginationResponseModel<BaseModel>;
      }
    } catch (error) {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<BaseModel>;
    }
    return response;
  };

  const onCreateOption = async (inputValue: any) => {
    const name = {
      code: '',
      name: inputValue,
      otherComments: '',
      statusId: 1,
    };
    const newOption: any = await PostCityDropDown(
      name,
      i18nMessages.CityCommonSaveSuccessMsg,
      i18nMessages.CommonErrOccuredMsg
    );
    const increaseUniq = (uniq: any) => uniq + 1;
    setCacheUniq(increaseUniq);
    onCityChange(newOption);
    getCities('', 1);
  };

  const onCityChange = (data: any) => {
    setValues({
      ...values,
      city: data?.label === defaultDropDownValue.label ? null : data?.label,
      cityId: data?.value === defaultDropDownValue.value ? null : data?.value,
    });

    setCity({ value: data?.value, label: data?.label });
  };

  const validate = () => {
    let temp: any = {};
    let isValid = true;
    if (!values.name) {
      temp.name = i18nMessages.commonFieldIsRequired;
      isValid = false;
    } else if (values.name.length > 30) {
      temp.name = i18nMessages.NameMaxLength;
    }

    if (country.value === defaultDropDownValue.value) {
      temp.country = i18nMessages.commonFieldIsRequired;
      isValid = false;
    }
    if (city.value === defaultDropDownValue.value) {
      temp.city = i18nMessages.commonFieldIsRequired;
      isValid = false;
    }

    if (!values.postalCode) {
      temp.postalCode = i18nMessages.commonFieldIsRequired;
      isValid = false;
    } else if (values.postalCode.length > 20) {
      temp.postalCode = i18nMessages.ZipCodeMaxLength;
    }

    if (!values.address) {
      temp.address = i18nMessages.commonFieldIsRequired;
      isValid = false;
    } else if (values.address.length > 250) {
      temp.address = i18nMessages.AddressMaxLength;
    }

    if (values.vatNumber && values.vatNumber.length > 30) {
      temp.vatNumber = i18nMessages.VatNumberMaxLength;
    }

    if (values.eori && values.eori.length > 30) {
      temp.eori = i18nMessages.EORIMaxLength;
    }

    if (values.vatDeferment.length > 30) {
      temp.vatDeferment = i18nMessages.VatDefermentMaxLength;
    }

    setErrors({
      ...temp,
    });

    if (isValid) {
      return true;
    } else {
      return false;
    }
  };

  const ResetForm = () => {
    setValues({
      ...values,
      name: '',
      address: '',
      postalCode: '',
      street: '',
      eori: '',
      vatNumber: '',
      vatDeferment: '',
    });
    setErrors({});
    setCountry(defaultDropDownValue);
    setCity(defaultDropDownValue);
  };

  const submit = async () => {
    if (validate()) {
      var data = {
        name: values.name,
        cityId: city.value,
        countryId: country.value,
        zipCode: values.postalCode,
        address: values.address ? String(values.address).trim() : '',
        street: values.street ? String(values.street).trim() : '',
        type: entityId,
        eori: values.eori,
        vatNumber: values.vatNumber,
        vatDeferment: values.vatDeferment,
      };

      const apiUrl = new URL(environment.api.baseUrl + 'ImporterExporter');
      try {
        const result: any = await http.post(apiUrl.toString(), data);
        if (result.data !== '') {
          toast.success('Saved Successfully');
          ResetForm();
          props.OnSave(result);
        } else {
          let temp: any = {};
          temp.vatNumber = i18nMessages.VatNumberUnique;
          setErrors({
            ...temp,
          });
          toast.error(i18nMessages.VatNumberUnique);
        }
      } catch (error: any) {
        toast.error(i18nMessages.CommonErrOccuredMsg);
      }
    }
  };

  useEffect(() => {
    setValues({ ...values, name: entityName });
    getCountries();
    getCities();
  }, [entityName]);

  const i18nMessages = {
    AddCity: I18n('Cities.AddDialogTitle'),
    CommonSaveSuccessMsg: I18n('Common.SavedSuccessfully'),
    CommonErrOccuredMsg: I18n('Common.ErrorOccurred'),
    commonFieldIsRequired: I18n('Common.FieldIsRequired'),
    ZipCodeMaxLength: I18n('CustomerLocation.Validation.ZipCodeMaxLength'),
    AddressMaxLength: I18n('CustomerLocation.Validation.AddressMaxLength'),
    NameMaxLength: I18n('CShippingOrders.NameMaxLength'),
    Save: I18n('Common.Save'),
    Cancel: I18n('Common.Cancel'),
    City: I18n('CustomerLocation.City'),
    Country: I18n('CustomerLocation.Country'),
    SearchAddress: I18n('Customer.SearchAddress'),
    IsMainAddress: I18n('CustomerLocation.IsMainAddress'),
    DestinationCompany: I18n('CustomerLocation.DestinationCompany'),
    Street: I18n('CustomerLocation.Street'),
    Address2: I18n('CustomerLocation.Address2'),
    ZipCode: I18n('CustomerLocation.ZipCode'),
    Address: I18n('CustomerLocation.Address'),
    Name: I18n('Order.Name'),
    PickupCompany: I18n('CustomerLocation.PickupCompany'),
    PickupCompanyRequired: I18n(
      'CustomerLocation.Validation.PickupCompanyRequired'
    ),
    PickupCompanyMaxLength: I18n(
      'CustomerLocation.Validation.PickupCompanyMaxLength'
    ),
    CityCommonSaveSuccessMsg: I18n('Common.CitySavedSuccessfully'),
    VatDefermentMaxLength: I18n('Customer.Validation.VatDefermentMaxLength'),
    VatNumberMaxLength: I18n('Customer.Validation.VatNumberMaxLength'),
    EORIMaxLength: I18n('Customer.Validation.EORIMaxLength'),
    EORI: I18n('Customer.EORI'),
    VatNumber: I18n('Customer.VatNumber'),
    VatDeferment: I18n('Customer.VatDeferment'),
    VatNumberUnique: I18n('Customer.Validation.VatNumberUnique'),
  };

  const [isAddCityModalOpen, setIsAddCityModal] = useState(false);
  const [locationData, setLocationData] = useState({
    country: defaultDropDownValue,
    postcode: '',
    place: defaultDropDownValue,
  });

  //Autocomplete
  const autocompleteHandleChange = async (
    value: string,
    selectedPlace: any
  ) => {
    const autoSelectValues = {
      country,
      postcode: postalCode,
      place: city,
    };
    //Country
    if (selectedPlace.country && selectedPlace.country !== undefined) {
      let selectedCountry = countryList.find(
        (option: any) => option.label === selectedPlace.country
      );
      autoSelectValues.country = selectedCountry ? selectedCountry : country;
      setLocationData({
        ...locationData,
        country: autoSelectValues.country,
      });
      setCountry(autoSelectValues.country);
    } else {
      setCountry(defaultDropDownValue);
    }
    //City or Place
    if (selectedPlace.place && selectedPlace.place !== undefined) {
      let selectedCity = cityList.find(
        (option: any) => option.label === selectedPlace.place
      );

      //Add new city in db
      if (!selectedCity || selectedCity === undefined) {
        selectedCity = await addNewCity(selectedPlace.place);
      }

      autoSelectValues.place = selectedCity ? selectedCity : city;

      setLocationData({
        ...locationData,
        place: selectedCity
          ? selectedCity
          : ({ label: selectedPlace.place, value: '0' } as BaseModel),
      });
      setCity(autoSelectValues.place);
    }
    //Postal code
    if (selectedPlace.postcode && selectedPlace.postcode !== undefined) {
      autoSelectValues.postcode = selectedPlace.postcode;
      setLocationData({
        ...locationData,
        postcode: autoSelectValues.postcode,
      });
      setPostalCode(autoSelectValues.postcode);
    }
    // setAddress(value);
    setValues({
      ...values,
      address: selectedPlace.number ? selectedPlace.number : values.address,
      postalCode: autoSelectValues.postcode,
    });
  };

  const addNewCity = async (value: string) => {
    const name = {
      code: value,
      name: value,
      otherComments: value,
      statusId: 1,
    };
    const newOption: any = await PostCityDropDown(
      name,
      i18nMessages.CityCommonSaveSuccessMsg,
      i18nMessages.CommonErrOccuredMsg
    );

    return newOption;
  };

  const onAddCity = useCallback(() => {
    setIsAddCityModal(true);
  }, []);

  const onCloseAddCity = () => {
    setIsAddCityModal(false);
  };

  const onSubmitCity = async (cityModel: any) => {
    await getCities();
    onCloseAddCity();
  };

  //Add City
  useEffect(() => {
    if (!isAddCityModalOpen) {
      setCityByLocationData();
    }
  }, [isAddCityModalOpen]);

  const setCityByLocationData = () => {
    if (locationData.place && locationData.place !== undefined) {
      let selectedCity = cityList.find(
        (option: any) => option.label === locationData.place.label
      );
      if (selectedCity && selectedCity !== undefined) {
        setCity(selectedCity);
      }
    }
  };

  const resetClose = () => {
    ResetForm();
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={resetClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={'sm'}
        fullWidth={true}
      >
        <DialogTitle>
          {title}
          <IconButton
            aria-label="close"
            onClick={resetClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className="grid-wrap two-col">
            <Grid item xs={12}>
              <AddressAutocompleteInputs
                inputProps={{ maxLength: 500 }}
                displayLabel={i18nMessages.SearchAddress}
                handleChange={autocompleteHandleChange}
                required={false}
                // value={values.address}
              ></AddressAutocompleteInputs>
            </Grid>
            <Grid item xs={6}>
              <FormControl
                margin="normal"
                displayLabel={i18nMessages.Name}
                id="name"
                inputType="text"
                inputProps={{ maxLength: 30 }}
                handleChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ) =>
                  setValues({
                    ...values,
                    name: event.target.value,
                  })
                }
                value={values.name}
                multiline={false}
                errorValue={errors.name}
                errorMessage={errors.name}
                required={true}
              ></FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl
                margin="normal"
                displayLabel={i18nMessages.Address}
                id="Address"
                inputType="text"
                inputProps={{ maxLength: 500 }}
                handleChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ) =>
                  setValues({
                    ...values,
                    address: event.target.value,
                  })
                }
                value={values.address}
                multiline={false}
                errorValue={errors.address}
                errorMessage={errors.address}
                required={true}
              ></FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl
                margin="normal"
                displayLabel={I18n('Partner.PostalCode')}
                id="PostalCode"
                inputType="text"
                inputProps={{ maxLength: 20 }}
                handleChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ) =>
                  setValues({
                    ...values,
                    postalCode: event.target.value,
                  })
                }
                value={values.postalCode}
                multiline={false}
                errorValue={errors.postalCode}
                errorMessage={errors.postalCode}
                required={true}
              ></FormControl>
            </Grid>
            <Grid item xs={6}>
              <div className="add-button-address">
                <CustomSelectPaginateAdd
                  additional={defaultAdditional}
                  value={city}
                  loadOptions={loadCitiesOptions}
                  handleChange={(newValue: BaseModel) => onCityChange(newValue)}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                  }}
                  placeholder={defaultDropDownValue.label}
                  isSearchable={true}
                  displayLabel={i18nMessages.City}
                  errorValue={true}
                  errorMessage={errors.city}
                  readOnly={false}
                  debounceTimeout={500}
                  isMultiSelect={false}
                  cacheUniqs={cacheUniq}
                  onCreateOption={(inputValue: any) =>
                    onCreateOption(inputValue)
                  }
                  required={true}
                ></CustomSelectPaginateAdd>
              </div>
            </Grid>
            <Grid item xs={6}>
              <CustomizedSelect
                placeholder={defaultDropDownValue.label}
                options={countryList}
                isSearchable={true}
                displayLabel={i18nMessages.Country}
                handleChange={(newValue: BaseModel) => setCountry(newValue)}
                value={country}
                errorValue={true}
                errorMessage={errors.country}
                required={true}
              ></CustomizedSelect>
            </Grid>
            <Grid item xs={6}>
              <FormControl
                margin="normal"
                displayLabel={i18nMessages.EORI}
                id="eori"
                inputType="text"
                inputProps={{ maxLength: 30 }}
                handleChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ) =>
                  setValues({
                    ...values,
                    eori: event.target.value,
                  })
                }
                value={values.eori}
                multiline={false}
                errorValue={errors.eori}
                errorMessage={errors.eori}
              ></FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl
                margin="normal"
                displayLabel={i18nMessages.VatNumber}
                id="vatNumber"
                inputType="text"
                inputProps={{ maxLength: 30 }}
                handleChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ) =>
                  setValues({
                    ...values,
                    vatNumber: event.target.value,
                  })
                }
                value={values.vatNumber}
                multiline={false}
                errorValue={errors.vatNumber}
                errorMessage={errors.vatNumber}
              ></FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl
                margin="normal"
                displayLabel={i18nMessages.VatDeferment}
                id="vatDeferment"
                inputType="text"
                inputProps={{ maxLength: 30 }}
                handleChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ) =>
                  setValues({
                    ...values,
                    vatDeferment: event.target.value,
                  })
                }
                value={values.vatDeferment}
                multiline={false}
                errorValue={errors.vatDeferment}
                errorMessage={errors.vatDeferment}
                required={false}
              ></FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="btn-wrap">
          <Button
            onClick={resetClose}
            color="neutral"
            disableElevation
            variant="contained"
          >
            {' '}
            {i18nMessages.Cancel}
          </Button>
          <Button
            onClick={submit}
            autoFocus
            color="primary"
            disableElevation
            variant="contained"
          >
            {i18nMessages.Save}
          </Button>
        </DialogActions>
      </Dialog>
      {isAddCityModalOpen && (
        <AddCity
          isOpen={isAddCityModalOpen}
          onClose={onCloseAddCity}
          onSubmit={onSubmitCity}
          title={i18nMessages.AddCity}
          locationData={locationData}
        ></AddCity>
      )}
    </div>
  );
}
