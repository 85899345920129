import Typography from '@mui/material/Typography';
import I18n from 'utilities/i18n';
import './SpecialPricing.scss';
import { useEffect, useCallback, useState } from 'react';
import http from '../../utilities/httpService';
import {
  OrderByDirection,
  EntityTypeEnum,
  EntityPermissionProps,
} from 'models/pagination-model';
import { Button } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faSearch, faPlus } from '@fortawesome/free-solid-svg-icons';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InputAdornment from '@mui/material/InputAdornment';
import CustomizedInputs from 'common/formControl/formControl';
import CustomDataGrid from 'common/datagrid/datagrid';
import { useParams } from 'react-router-dom';
import environment from 'environment';
import { toast } from 'react-toastify';
import {
  GridActionsCellItem,
  GridColumns,
  GridPaginationInitialState,
  GridRowParams,
  GridSortingInitialState,
  GridSortModel,
} from '@mui/x-data-grid';
import { Notes } from 'models/notes';
import ConfirmationPopup from 'common/confirmationPopup/confirmationPopup';
import AddSpecialPricing from './add-special-pricing';
import moment from 'moment';
import { hasPermission } from 'utilities/protectedRoute';

function SpecialPricing(props: EntityPermissionProps) {
  let { entityId } = useParams();
  const [isDeleteModalOpen, setDeleteModal] = useState(false);
  const [isAddModalOpen, setAddModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [selectedId, setId] = useState();
  const [canEdit, setCanEdit] = useState(hasPermission(props.editPermission));
  const [canAdd, setCanAdd] = useState(hasPermission(props.addPermission));
  const [canDelete, setCanDelete] = useState(
    hasPermission(props.deletePermission)
  );
  const [gridData, setGridData] = useState({
    sortOrder: OrderByDirection.Descending,
    sortBy: 'CreatedDate',
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [10, 20, 50, 100],
    pageSize: 10,
    page: 0,
    searchExpression: '',
  });
  const i18nMessages = {
    specialPricingDeletedSuccess: I18n('SpecialPricing.DeletedSuccessfully'),
    somethingWentWrong: I18n('Common.SomethingWentWrong'),
  };
  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));
  const getSpecialPricings = async () => {
    const apiUrl = new URL(environment.api.baseUrl + 'SpecialPricing');
    try {
      apiUrl.searchParams.set(
        '$filter',
        gridData.searchExpression !== ''
          ? `EntityId~=~${entityId} and EntityType~=~${props.entityType} and Customer.Name~like~${gridData.searchExpression}`
          : `EntityId~=~${entityId} and EntityType~=~${props.entityType}`
      );
      apiUrl.searchParams.set('$orderby', gridData.sortBy);
      apiUrl.searchParams.set(
        '$skip',
        (gridData.page * gridData.pageSize).toString()
      );
      apiUrl.searchParams.set('$top', gridData.pageSize.toString());
      apiUrl.searchParams.set(
        '$orderbydirection',
        gridData.sortOrder.toString()
      );
      const result = await http.get(apiUrl.toString());
      if (result) {
        updateGridData('totalRows', result.data.totalCount);
        updateGridData('rows', result.data.values);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getSpecialPricings();
  }, [
    gridData.page,
    gridData.pageSize,
    gridData.sortBy,
    gridData.sortOrder,
    gridData.searchExpression,
  ]);

  const handleChangePage = (pageNo: number) => {
    updateGridData('page', pageNo);
  };

  const handleChangeBasicSearch = (searchExpression: any) => {
    updateGridData('searchExpression', searchExpression.target.value);
  };
  const handleChangePageSize = (pageSize: number) => {
    updateGridData('pageSize', pageSize);
  };
  type Row = (typeof gridData.rows)[number];
  const deleteSpecialPricing = useCallback(
    (id: any) => () => {
      setDeleteModal(true);
      setId(id);
    },
    []
  );

  const addSpecialPricing = useCallback(() => {
    setEditData(null);
    setAddModal(true);
  }, []);

  const editSpecialPricing = useCallback(
    (data: any) => () => {
      setEditData(data);
      setAddModal(true);
    },
    []
  );

  const refreshData = async () => {
    handleCloseForAdd();
    await getSpecialPricings();
  };

  const columns: GridColumns<Row> = [
    {
      field: 'customerName',
      headerName: I18n('SpecialPricing.Customer'),
      flex: 1,
    },
    {
      field: 'pricingType',
      headerName: 'pricingType',
      hide: true,
    },
    {
      field: 'pricingTypeDescription',
      headerName: I18n('SpecialPricing.PricingType'),
      flex: 1,
    },
    {
      field: 'shippingSellingPrice',
      headerName: I18n('SpecialPricing.ShippingSellingPrice'),
      flex: 1,
      renderCell: (params: any) => {
        return params.value ? params.value.toString().replaceAll('.', ',') : '';
      },
    },
    {
      field: 'decPackSellingPrice',
      headerName: I18n('SpecialPricing.DecPackSellingPrice'),
      flex: 1,
      renderCell: (params: any) => {
        return params.value ? params.value.toString().replaceAll('.', ',') : '';
      },
    },
    {
      field: 'updatedBy',
      headerName: I18n('SpecialPricing.AddedBy'),
      width: 200,
    },
    {
      field: 'updatedDate',
      headerName: I18n('SpecialPricing.AddedOn'),
      width: 200,
      valueFormatter: (params) => moment(params?.value).format('DD/MM/YYYY'),
    },
    {
      field: 'actions',
      headerName: I18n('Common.Actions'),
      type: 'actions',
      width: 150,
      getActions: (params: GridRowParams<Notes>) => {
        const actionButtons = [];
        actionButtons.push(
          canEdit ? (
            <GridActionsCellItem
              onPointerEnterCapture={false}
              onPointerLeaveCapture={false}
              placeholder={''}
              icon={<EditIcon />}
              label={I18n('Common.Edit')}
              onClick={editSpecialPricing(params)}
            />
          ) : (
            <></>
          )
        );
        actionButtons.push(
          canDelete ? (
            <GridActionsCellItem
              onPointerEnterCapture={false}
              onPointerLeaveCapture={false}
              placeholder={''}
              icon={<DeleteIcon />}
              label={I18n('Common.Delete')}
              onClick={deleteSpecialPricing(params.id)}
            />
          ) : (
            <></>
          )
        );
        return actionButtons;
      },
    },
  ];

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    if (sortModel && sortModel.length) {
      if (sortModel[0].field === 'customerName') {
        updateGridData('sortBy', 'Customer.Name');
      } else {
        updateGridData('sortBy', sortModel[0].field);
      }
      updateGridData(
        'sortOrder',
        sortModel[0].sort === 'asc'
          ? OrderByDirection.Ascending
          : OrderByDirection.Descending
      );
    }
  }, []);

  const handleClose = () => {
    setDeleteModal(false);
  };

  const handleOk = () => {
    setDeleteModal(false);
    deleteData(selectedId);
  };

  const handleCloseForAdd = () => {
    setEditData(null);
    setAddModal(false);
  };

  const deleteData = async (id: any) => {
    const apiUrl = new URL(environment.api.baseUrl + 'SpecialPricing/' + id);
    try {
      const result: any = await http.delete(apiUrl.toString());
      if (result.data) {
        getSpecialPricings();
        toast.success(i18nMessages.specialPricingDeletedSuccess);
      } else {
        toast.error(i18nMessages.somethingWentWrong);
      }
    } catch (error: any) {}
  };

  return (
    <div>
      <Typography variant="h5" className="inner-heading has-button">
        {I18n('ServiceCatalogues.Tabs.SpecialPricing')}
        <div className="inner-btn-wrap">
          <div className="text-right container-search">
            <div className="search-control-outer">
              <CustomizedInputs
                margin="normal"
                displayLabel=""
                inputType="text"
                placeholderText="Search"
                handleChange={handleChangeBasicSearch}
                className="search-control small-form-control"
                adornmentValue={
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={faSearch} />
                  </InputAdornment>
                }
              />
            </div>
          </div>
          {canAdd ? (
            <Button
              color="primary"
              className="small-btn"
              disableElevation
              onClick={addSpecialPricing}
              variant="contained"
            >
              <FontAwesomeIcon icon={faPlus} className="fa-md" />
            </Button>
          ) : (
            <></>
          )}
        </div>
      </Typography>
      <div className="">
        <div className="content-block table-fix">
          <CustomDataGrid
            rowCount={gridData.totalRows}
            data={gridData.rows}
            columns={columns}
            pageNo={gridData.page}
            pageSize={gridData.pageSize}
            onSortModelChange={handleSortModelChange}
            onPageChange={handleChangePage}
            onPageSizeChange={handleChangePageSize}
            rowsPerPageOptions={gridData.rowsPerPageOptions}
            initialState={{
              sorting: {
                sortModel: [{ field: 'updatedDate', sort: 'desc' }],
              } as GridSortingInitialState,
              pagination: {
                page: gridData.page,
                pageSize: gridData.pageSize,
              } as GridPaginationInitialState,
            }}
          ></CustomDataGrid>

          <ConfirmationPopup
            isOpen={isDeleteModalOpen}
            data={selectedId}
            message={I18n('SpecialPricing.DeleteMessage')}
            handleClose={handleClose}
            handleOk={handleOk}
            title={I18n('SpecialPricing.DeleteNote')}
          ></ConfirmationPopup>
          <AddSpecialPricing
            isOpen={isAddModalOpen}
            editData={editData}
            entityType={props.entityType}
            entityId={entityId}
            handleClose={handleCloseForAdd}
            title={
              editData == null
                ? I18n('SpecialPricing.AddSpecialPricing')
                : I18n('SpecialPricing.EditSpecialPricing')
            }
            refreshData={refreshData}
          ></AddSpecialPricing>
        </div>
      </div>
    </div>
  );
}

export default SpecialPricing;
