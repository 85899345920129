import { useState, useEffect, useCallback } from 'react';
import http from '../../../utilities/httpService';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import CustomizedInputs from 'common/formControl/formControl';
import CustomizedPhone from 'common/formControl/formPhone';
import Grid from '@mui/material/Grid';
import FormControl from 'common/formControl/formControl';
import environment from 'environment';
import I18n from 'utilities/i18n';
import {
  ApprovalStatus,
  BaseModel,
  KeyValueBaseModel,
  CustomerTags,
  CustomerTagsDescription,
  EntityStatus,
  EntityStatusDescription,
  OrderByDirection,
  PaginationResponseModel,
  CustomerTypesList,
  MasterCategoryEnumType,
} from 'models/pagination-model';
import DefualtLogo from '../../../assets/images/logo.png';
import FormAccordian from 'common/formControl/formAccordian';
import CustomizedSelect from 'common/CustomSelect/CustomSelect';
import FileUpload from 'common/fileupload/fileupload';
import { Checkbox, FormControlLabel } from '@mui/material';
import AddressAutocompleteInputs from 'common/AddressAutocomplete/AddressAutocompleteInput';
import AddCity from 'common/Cities/AddCity';
import CustomSelectPaginateAdd from 'common/CustomSelect/CustomSelectPaginateAdd';
import {
  GetLatLongByPostalCode,
  PostCatagoryDropDown,
  PostCityDropDown,
  PostCountryDropDown,
} from 'common/DropDownAddAPI/DropDownApiService';
import { Customer } from 'models/service-catalogue';
import CustomSelectPaginate from 'common/CustomSelect/CustomSelectPaginate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { hasPermission } from 'utilities/protectedRoute';
import { Permission } from 'Permissions';
import { ZipCodeTriggeredFrom } from 'models/location';
import { getSettings } from '../CustomerApiService';
import { SettingsType, CustomerTypes } from 'models/customer';
import NumericInput from 'common/NumericInput/NumericInput';
import { loadCategoryOptions } from 'components/shipping-orders/ShippingApiService';

function EditCustomerDetails(props: any) {
  const { handleSpecialPricing } = props;
  const params = useParams();

  const defaultDropDownValue = {
    value: '0',
    label: I18n('Placeholders.Select'),
  } as BaseModel;

  const defaultValue = {
    value: '0',
    label: I18n('Placeholders.SelectStatus'),
  } as BaseModel;

  const [errors, setErrors] = useState({
    name: '',
    address: '',
    street: '',
    postalCode: '',
    city: '',
    country: '',
    webSite: '',
    logo: '',
    VATNumber: '',
    eori: '',
    telephone: '',
    email: '',
    invoiceEmail: '',
    invoiceAddress: '',
    invoiceStreet: '',
    invoicePostalCode: '',
    invoiceCountry: '',
    invoiceCity: '',
    status: '',
    taggedTo: '',
    fileUpload: '',
    billingCurrency: '',
    invoicingCycle: '',
    customerType: '',
    vatPercentage: '',
    category: '',
  });

  const [values, setValues] = useState<any>({
    name: '',
    address: '',
    address2: '',
    street: '',
    postalCode: '',
    cityId: '',
    countryId: '',
    city: '',
    country: '',
    webSite: '',
    logo: '',
    vatNumber: '',
    eori: '',
    isDebtor: false,
    sameVat: false,
    telephone: '',
    email: '',
    invoiceEmail: '',
    isInvoiceAddress: false,
    invoiceAddress: '',
    invoiceAddress2: '',
    invoiceStreet: '',
    invoicePostalCode: '',
    invoiceCountry: '',
    invoiceCity: '',
    invoiceCityId: '',
    invoiceCountryId: '',
    status: Number(EntityStatus.Active),
    statusDescription: '',
    taggedTo: Number(CustomerTags.Shipping),
    taggedToDescription: '',
    latitude: '',
    longitude: '',
    billingCurrencyId: defaultDropDownValue.value,
    invoicingCycleId: defaultDropDownValue.value,
    customerTypeId: defaultDropDownValue.value,
    billingCurrency: defaultDropDownValue,
    invoicingCycle: defaultDropDownValue,
    customerType: defaultDropDownValue,
    vatPercentage: 0,
    category: defaultDropDownValue,
    categoryId: defaultDropDownValue.value,
    categoryName: '',
  });

  const [cityList, setCityList] = useState<BaseModel[]>([defaultDropDownValue]);
  const [countryList, setCountryList] = useState<BaseModel[]>([
    defaultDropDownValue,
  ]);
  const [customerTypeList, setCustomerTypeList] = useState<BaseModel[]>([]);
  const [invoicingCycleList, setInvoicingCycleList] = useState<BaseModel[]>([]);
  const [currencyList, setCurrencyList] = useState<BaseModel[]>([]);

  const [selectedCountry, setSelectedCountry] = useState(defaultDropDownValue);
  const [selectedCity, setSelectedCity] = useState(defaultDropDownValue);
  const [selectedInvoiceCountry, setSelectedInvoiceCountry] =
    useState(defaultDropDownValue);
  const [selectedInvoiceCity, setSelectedInvoiceCity] =
    useState(defaultDropDownValue);
  const [selectedTag, setSelectedTag] = useState(defaultDropDownValue);
  const [selectedStatus, setSelectedStatus] = useState(defaultValue);

  const [logoExpanded, setlogoExpanded] = useState<boolean>(true);
  const [isLogoEdit, setLogoEdit] = useState<boolean>(false);
  const [detailsExpanded, setDetailsExpanded] = useState<boolean>(true);
  const [isDetailsEdit, setDetailsEdit] = useState<boolean>(false);

  const [financeEdit, setFinanceEdit] = useState<boolean>(false);
  const [financeExpanded, setFinanceExpanded] = useState<boolean>(true);

  const [contactDetailsEdit, setContactDetailsEdit] = useState<boolean>(false);
  const [contactDetailsExpanded, setContactDetailsExpanded] =
    useState<boolean>(true);

  const [statusEdit, setStatusEdit] = useState<boolean>(false);
  const [statusExpanded, setStatusExpanded] = useState<boolean>(true);

  const [invoiceEdit, setInvoiceEdit] = useState<boolean>(false);
  const [invoiceExpanded, setInvoiceExpanded] = useState<boolean>(true);

  const [tagEdit, setTagEdit] = useState<boolean>(false);
  const [tagExpanded, setTagExpanded] = useState<boolean>(true);
  const [isInvoiceAddress, setIsInvoiceAddress] = useState<boolean>(false);
  const [files, setFiles] = useState<any>([]);
  const [canEdit, setCanEdit] = useState(
    hasPermission(
      Number(params.customerType) == Number(CustomerTypesList.ApprovedCustomer)
        ? Permission.canEditCustomer
        : Permission.canEditCustomerApproval
    )
  );

  const defaultAdditional: any = {
    page: 1,
  };
  const [cacheUniqCountry, setCacheUniqCountry] = useState(0);
  const [cacheUniq, setCacheUniq] = useState(0);
  const [existingPostCode, setExistingPostCode] = useState('');

  let customerTagsList: Array<BaseModel> = [];
  const getCustomerTagsList = () => {
    customerTagsList = [defaultDropDownValue];
    CustomerTagsDescription.forEach((value: string, key: number) => {
      customerTagsList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getCustomerTagsList();

  const getCountries = async (search: string = '', pageNo: number = 1) => {
    let response: PaginationResponseModel<Customer>;
    const apiUrl = new URL(environment.api.baseUrl + 'Country');
    try {
      let searchExpression = '';
      searchExpression += search ? `name~like~${search}` : '';
      apiUrl.searchParams.set('$filter', searchExpression);
      apiUrl.searchParams.set('$orderby', 'name');
      apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
      apiUrl.searchParams.set('$top', '1000');
      apiUrl.searchParams.set('$orderbydirection', 'desc');
      const result = await http.get(apiUrl.toString());
      if (result) {
        response = {
          TotalRecords: result.data.totalCount,
          Values: result.data.values,
        } as PaginationResponseModel<Customer>;

        let options = result.data.values.map((x: any) => {
          return {
            value: x.id.toString(),
            label: x.name,
          } as BaseModel;
        });
        setCountryList(options);
      } else {
        response = {
          TotalRecords: 0,
          Values: [],
        } as PaginationResponseModel<Customer>;
      }
    } catch (error) {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<Customer>;
    }
    return response;
  };

  let cityLst: Array<BaseModel> = [];
  const getCityList = (cities: any[]) => {
    cities.forEach((city: any) => {
      cityLst.push({
        value: city.key,
        label: city.value,
      } as BaseModel);
    });

    return cityLst;
  };

  const getCities = async (search: string = '', pageNo: number = 1) => {
    let cityLst: any[] = [];
    let response: PaginationResponseModel<BaseModel>;
    const apiUrl = new URL(environment.api.baseUrl + 'Cities');
    try {
      if (search && search !== undefined) {
        var searchExpression = '';
        searchExpression += search ? `name~like~${search}` : '';
        apiUrl.searchParams.set('$filter', searchExpression);
      }
      apiUrl.searchParams.set('$orderby', 'name');
      if (pageNo > 1) {
        apiUrl.searchParams.set('$skip', ((pageNo - 1) * 500).toString());
        apiUrl.searchParams.set('$top', '500');
      } else {
        apiUrl.searchParams.set('$top', '10000');
        apiUrl.searchParams.set('$skip', '0');
      }

      apiUrl.searchParams.set(
        '$orderbydirection',
        OrderByDirection.Ascending.toString()
      );

      const result = await http.get(apiUrl.toString());
      if (result) {
        result.data.values.map((item: any) => {
          cityLst.push({
            key: item['id'],
            value: item['name'],
          });
        });

        let data = getCityList(cityLst);
        setCityList(data);

        response = {
          TotalRecords: result.data.totalCount,
          Values: data,
        } as PaginationResponseModel<BaseModel>;
      } else {
        response = {
          TotalRecords: 0,
          Values: [],
        } as PaginationResponseModel<BaseModel>;
      }
    } catch (error) {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<BaseModel>;
    }
    return response;
  };

  let entityStatusList: Array<BaseModel> = [];
  const getStatusList = () => {
    EntityStatusDescription.forEach((value: string, key: number) => {
      entityStatusList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getStatusList();

  const resetAccordians = () => {
    setLogoEdit(false);
    setDetailsEdit(false);
    setFinanceEdit(false);
    setContactDetailsEdit(false);
    setStatusEdit(false);
    setInvoiceEdit(false);
    setTagEdit(false);
  };

  const i18nMessages = {
    country: I18n('Customer.Country'),
    city: I18n('Customer.City'),
    address: I18n('Customer.Address'),
    postalCode: I18n('Customer.PostalCode'),
    save: I18n('Common.Save'),
    cancel: I18n('Common.Cancel'),
    invoiceEmail: I18n('Customer.InvoiceEmail'),
    isDifferent: I18n('Customer.IsDifferent'),
    invoicing: I18n('Customer.Invoicing'),
    tag: I18n('Customer.Tag'),
    isAddressDifferent: I18n('Partner.InvoiceAddressDifferent'),
    customerUpdatedSuccessFully: I18n('Customer.UpdatedSuccessfully'),
    invoiceAddress: I18n('Customer.Address'),
    AddCity: I18n('Cities.AddDialogTitle'),
    Select: I18n('Placeholders.Select'),
    commonError: I18n('Common.ErrorOccurred'),
    CommonSaveSuccessMsg: I18n('Common.SavedSuccessfully'),
    CommonErrOccuredMsg: I18n('Common.ErrorOccurred'),
    FieldRequired: I18n('Common.FieldRequired'),
    Address2: I18n('CustomerLocation.Address2'),
    Street: I18n('CustomerLocation.Street'),
    searchAddress: I18n('Customer.SearchAddress'),
    commonFieldIsRequired: I18n('Common.FieldIsRequired'),
    invoicePostalCodeMaxLength: I18n(
      'Partner.Validation.InvoicePostalCodeMaxLength'
    ),
    CityCommonSaveSuccessMsg: I18n('Common.CitySavedSuccessfully'),
    invoicingCycle: I18n('Customer.InvoicingCycle'),
    customerType: I18n('Customer.CustomerType'),
    billingCurrency: I18n('Customer.BillingCurrency'),
    vatPercentage: I18n('Customer.VatPercentage'),
    invalidvatPercentage: I18n('Customer.Validation.InvalidVatPercent'),
    offerCategory: I18n('ShippingOrders.OfferCategory'),
  };

  const handleFileUpload = async () => {
    var filePath = await UploadFile(files);
    if (filePath) {
      handleSubmit(filePath);
    } else {
      if (values.logo !== '' || filePath === undefined) {
        setLogoEdit(false);
      } else {
        toast.error(i18nMessages.commonError);
      }
    }
  };

  const UploadFile = async (file: any) => {
    if (file && file.length > 0) {
      const apiUrl = new URL(environment.api.baseUrl + 'Documents/3/upload');
      const data = new FormData();
      data.append('file', file[0]);
      const response = await http.post(apiUrl.toString(), data);

      return response.data;
    }
  };

  const handleSubmit = async (uploadedFile?: string) => {
    if (validate()) {
      let coordinates = null;
      if (existingPostCode && existingPostCode !== values.postalCode) {
        coordinates = await GetLatLongByPostalCode(
          values.postalCode,
          values.city,
          values.latitude,
          values.longitude
        );
      }

      const apiUrl = new URL(
        environment.api.baseUrl + 'Customer/' + params.entityId
      );
      try {
        values.isDebtor = Boolean(values.isDebtor);
        values.status = Number(values.status);
        values.taggedTo = Number(values.taggedTo);
        values.vatPercentage = Number(values.vatPercentage);
        if (typeof uploadedFile === 'string') {
          values.logo = uploadedFile;
        }
        if (values.countryId === '') {
          values.countryId = null;
        }
        if (values.cityId === '') {
          values.cityId = null;
        }
        if (values.invoiceCountryId === '') {
          values.invoiceCountryId = null;
        }
        if (values.invoiceCityId === '') {
          values.invoiceCityId = null;
        }
        if (values.taggedTo === 0) {
          values.taggedTo = null;
        }
        if (values.sameVat === true) {
          values.eori = values.vatNumber;
        }
        values.latitude =
          coordinates != null ? coordinates.latitude : values.latitude;
        values.longitude =
          coordinates != null ? coordinates.longitude : values.longitude;
        values.categoryId =
          values.categoryId === defaultValue.value ? null : +values.categoryId;

        const result: any = await http.put(apiUrl.toString(), values);
        if (result) {
          toast.success(i18nMessages.customerUpdatedSuccessFully);
          resetAccordians();
          getCustomerData(params.entityId);
        }
      } catch (error: any) {}
    }
  };

  const onSameAsVaTChange = (event: any) => {
    onValueChange(event);
    if (values.vatNumber && event.target.checked === true) {
      setValues({
        ...values,
        eori: values.vatNumber,
        sameVat: true,
      });
    } else {
      setValues({
        ...values,
        eori: '',
        sameVat: false,
      });
    }
  };

  const onIsDebtorChange = (event: any) => {
    setValues({
      ...values,
      isDebtor: event.target.checked,
    });
  };

  const onValueChange = (event: any) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name.toString()]: value,
    });
  };

  const onInvoiceAddressChange = (event: any) => {
    if (!event.target.checked) {
      setValues({
        ...values,
        invoiceCity: values.city,
        invoiceCityId: values.cityId,
        invoiceCountry: values.country,
        invoiceCountryId: values.countryId,
        invoiceAddress: values.address,
        invoicePostalCode: values.postalCode,
        invoiceAddress2: values.invoiceAddress2,
        invoiceStreet: values.invoiceStreet,
        isInvoiceAddress: event.target.checked,
      });

      setSelectedInvoiceCity({
        value: values.cityId,
        label: values.city,
      });
      setSelectedInvoiceCountry({
        value: values.countryId,
        label: values.country,
      });
      setIsInvoiceAddress(false);
    } else {
      setValues({
        ...values,
        invoiceCity: '',
        invoiceCityId: '',
        invoiceCountry: '',
        invoiceCountryId: '',
        invoiceAddress: '',
        invoiceAddress2: '',
        invoiceStreet: '',
        invoicePostalCode: '',
        isInvoiceAddress: event.target.checked,
      });

      setSelectedInvoiceCity(defaultDropDownValue);
      setSelectedInvoiceCountry(defaultDropDownValue);
      setIsInvoiceAddress(true);
    }
  };

  const phoneOnChange = (phone: any) => {
    const phoneNum: string = phone.replace(/[^\d\+]/g, '');
    setValues({ ...values, telephone: phoneNum });
  };

  const onStatusChange = (data: any) => {
    setValues({ ...values, statusDescription: data?.label });
    setValues({ ...values, status: data?.value });
    setSelectedStatus({ value: data?.value, label: data?.label });
  };

  const onCityChange = (data: any) => {
    setValues({
      ...values,
      city: data?.label === defaultDropDownValue.label ? null : data?.label,
      cityId: data?.value === defaultDropDownValue.value ? null : data?.value,
    });
    setSelectedCity({ value: data?.value, label: data?.label });
  };

  const onCountryChange = (data: any) => {
    setValues({
      ...values,
      country: data?.label === defaultDropDownValue.label ? null : data?.label,
      countryId:
        data?.value === defaultDropDownValue.value ? null : data?.value,
    });
    setSelectedCountry({ value: data?.value, label: data?.label });
  };

  const onInvoiceCityChange = (data: any) => {
    setValues({
      ...values,
      invoiceCity:
        data?.label === defaultDropDownValue.label ? null : data?.label,
      invoiceCityId:
        data?.value === defaultDropDownValue.value ? null : data?.value,
    });
    setSelectedInvoiceCity({ value: data?.value, label: data?.label });
  };

  const onInvoiceCountryChange = (data: any) => {
    setValues({
      ...values,
      invoiceCountry:
        data?.label === defaultDropDownValue.label ? null : data?.label,
      invoiceCountryId:
        data?.value === defaultDropDownValue.value ? null : data?.value,
    });
    setSelectedInvoiceCountry({ value: data?.value, label: data?.label });
  };

  const onTagChange = (data: any) => {
    setValues({
      ...values,
      taggedTo: data?.value,
      taggedToDescription: data?.label,
      category:
        data.value == CustomerTags.Shipping.toString() ||
        data.value == CustomerTags.Both.toString()
          ? values.category
          : defaultDropDownValue,
      categoryId:
        data.value == CustomerTags.Shipping.toString() ||
        data.value == CustomerTags.Both.toString()
          ? values.category.value
          : defaultDropDownValue.value,
    });
    setSelectedTag({ value: data?.value, label: data?.label });
  };

  const validate = () => {
    let temp: any = {};
    let isValid = true;
    if (!values.name) {
      temp.name = 'Customer.Validation.CustomerNameRequired';
      isValid = false;
    } else if (values.name.length > 50) {
      temp.name = 'Customer.Validation.CustomerNameMaxLength';
      isValid = false;
    }

    if (values.address && values.address.length > 250) {
      temp.address = 'Customer.Validation.AddressMaxLength';
      isValid = false;
    }

    if (values.postalCode && values.postalCode.length > 20) {
      temp.postalCode = 'Customer.Validation.PostalCodeMaxLength';
      isValid = false;
    }

    if (values.invoiceAddress && values.invoiceAddress.length > 250) {
      temp.invoiceAddress = 'Customer.Validation.InvoiceAddressMaxLength';
      isValid = false;
    }

    if (values.website && values.website.length > 255) {
      temp.website = 'Customer.Validation.WebsiteMaxLenght';
      isValid = false;
    }

    if (values.vatNumber && values.vatNumber.length > 30) {
      temp.vatNumber = 'Customer.Validation.VatNumberMaxLength';
      isValid = false;
    }

    if (values.eori && values.eori.length > 30) {
      temp.eori = 'Customer.Validation.EORIMaxLength';
      isValid = false;
    }

    if (values.telephone && values.telephone.length > 20) {
      temp.telephone = 'Customer.Validation.TelephoneMaxLength';
      isValid = false;
    }

    if (selectedStatus.value === defaultValue.value) {
      temp.status = 'Customer.Validation.StatusRequired';
      isValid = false;
    }

    if (values.email && values.email.length > 255) {
      temp.email = 'Customer.Validation.EmailMaxLength';
      isValid = false;
    } else if (values.email && !/$^|.+@.+..+/.test(values.email)) {
      temp.email = 'Customer.Validation.InvalidEmail';
      isValid = false;
    }

    if (values.invoiceEmail && values.invoiceEmail.length > 255) {
      temp.invoiceEmail = 'Customer.Validation.InvoiceEmailMaxLength';
      isValid = false;
    } else if (
      values.invoiceEmail &&
      !/$^|.+@.+..+/.test(values.invoiceEmail)
    ) {
      temp.invoiceEmail = 'Customer.Validation.InvalidEmail';
      isValid = false;
    }

    if (!values.countryId || values.countryId == defaultDropDownValue.value) {
      temp.country = i18nMessages.FieldRequired;
      isValid = false;
    }
    if (!values.cityId || values.cityId == defaultDropDownValue.value) {
      temp.city = i18nMessages.FieldRequired;
      isValid = false;
    }

    if (!values.postalCode) {
      temp.postalCode = 'Common.FieldRequired';
      isValid = false;
    }

    if (!values.street) {
      temp.street = i18nMessages.FieldRequired;
      isValid = false;
    }

    if (!values.vatNumber) {
      temp.VATNumber = i18nMessages.FieldRequired;
      isValid = false;
    }

    if (
      !values.billingCurrencyId ||
      values.billingCurrencyId === defaultValue.value
    ) {
      temp.billingCurrency = i18nMessages.commonFieldIsRequired;
      isValid = false;
    }
    if (
      !values.invoicingCycleId ||
      values.invoicingCycleId === defaultValue.value
    ) {
      temp.invoicingCycle = i18nMessages.commonFieldIsRequired;
      isValid = false;
    }
    if (
      !values.customerTypeId ||
      values.customerTypeId === defaultValue.value
    ) {
      temp.customerType = i18nMessages.commonFieldIsRequired;
      isValid = false;
    }

    if (values.vatPercentage.toString() === '') {
      temp.vatPercentage = i18nMessages.FieldRequired;
      isValid = false;
    } else if (
      values.vatPercentage.toString() != '0' &&
      values.vatPercentage.toString() != '21'
    ) {
      temp.vatPercentage = i18nMessages.invalidvatPercentage;
      isValid = false;
    }

    if (isInvoiceAddress) {
      if (!values.invoiceStreet) {
        temp.invoiceStreet = i18nMessages.commonFieldIsRequired;
        isValid = false;
      }

      if (
        !values.invoiceCityId ||
        values.invoiceCityId == defaultDropDownValue.value
      ) {
        temp.invoiceCity = i18nMessages.commonFieldIsRequired;
        isValid = false;
      }

      if (
        !values.invoiceCountryId ||
        values.invoiceCountryId == defaultDropDownValue.value
      ) {
        temp.invoiceCountry = i18nMessages.commonFieldIsRequired;
        isValid = false;
      }

      if (
        !values.invoicePostalCode.trim() ||
        values.invoicePostalCode.length > 20
      ) {
        temp.invoicePostalCode =
          values.invoicePostalCode && values.invoicePostalCode.trim()
            ? values.invoicePostalCode.length > 20
              ? i18nMessages.invoicePostalCodeMaxLength
              : ''
            : i18nMessages.commonFieldIsRequired;
        isValid = false;
      }
    }

    if (!values.taggedTo || values.taggedTo == defaultDropDownValue.value) {
      temp.taggedTo = i18nMessages.FieldRequired;
      isValid = false;
    }

    if (
      (values.taggedTo == CustomerTags.Shipping.toString() ||
        values.taggedTo == CustomerTags.Both.toString()) &&
      values.category.value == defaultValue.value
    ) {
      temp.category = i18nMessages.FieldRequired;
      isValid = false;
    }

    setErrors({
      ...temp,
    });

    return isValid;
  };

  const getCustomerData = async (id: string | undefined) => {
    const apiUrl = new URL(environment.api.baseUrl + 'Customer/' + id);
    try {
      const result: any = await http.get(apiUrl.toString());

      if (result) {
        if (result.data.eori !== null && result.data.eori !== '') {
          values.sameVat = true;
        } else {
          values.sameVat = false;
        }
        setValues({
          ...values,
          ...result.data,
          billingCurrency: result.data.billingCurrencyId
            ? {
                value: result.data.billingCurrencyId,
                label: result.data.billingCurrencyValue,
              }
            : defaultDropDownValue,
          customerType: result.data.customerTypeId
            ? {
                value: result.data.customerTypeId,
                label: result.data.customerTypeValue,
              }
            : defaultDropDownValue,
          invoicingCycle: result.data.invoicingCycleId
            ? {
                value: result.data.invoicingCycleId,
                label: result.data.invoicingCycleValue,
              }
            : defaultDropDownValue,
          category: result.data.categoryId
            ? {
                value: result.data.categoryId,
                label: result.data.categoryName,
              }
            : defaultDropDownValue,
        });
        if (result.data.cityId) {
          setSelectedCity({
            value: result.data.cityId,
            label: result.data.city,
          });
        } else {
          setSelectedCity(defaultDropDownValue);
        }

        if (result.data.countryId) {
          setSelectedCountry({
            value: result.data.countryId,
            label: result.data.country,
          });
        } else {
          setSelectedCountry(defaultDropDownValue);
        }
        setSelectedStatus({
          value: result.data.status,
          label: result.data.statusDescription,
        });

        if (result.data.taggedTo) {
          setSelectedTag({
            value: result.data.taggedTo,
            label: result.data.taggedToDescription,
          });
        } else {
          setSelectedTag(defaultDropDownValue);
        }

        setIsInvoiceAddress(result.data.isInvoiceAddress);

        if (result.data.invoiceCityId) {
          setSelectedInvoiceCity({
            value: result.data.invoiceCityId,
            label: result.data.invoiceCity,
          });
        } else {
          setSelectedInvoiceCity(defaultDropDownValue);
        }

        if (result.data.invoiceCountryId) {
          setSelectedInvoiceCountry({
            value: result.data.invoiceCountryId,
            label: result.data.invoiceCountry,
          });
        } else {
          setSelectedInvoiceCountry(defaultDropDownValue);
        }
        setExistingPostCode(result.data.postalCode);

        handleSpecialPricing(result.data.hasSpecialPricing, result.data.name);
      }
    } catch (error: any) {}
  };

  const handleSaveUpload = (files: any) => {
    if (files && files.length > 0) {
      setFiles(files);
    }
  };

  const handleFileDelete = (file: any) => {
    var a = files.filter((x: any) => {
      return x.lastModified !== file.lastModified;
    });

    setFiles(a);
  };

  const handelLogoEdit = () => {
    setLogoEdit(!isLogoEdit);
  };
  const handleLogoExpandCollapseClick = () => {
    setlogoExpanded(!logoExpanded);
  };

  const handelDetailsEdit = () => {
    setDetailsEdit(!isDetailsEdit);
  };
  const handleDetailsExpandCollapseClick = () => {
    setDetailsExpanded(!detailsExpanded);
  };

  const handelFinanceEdit = () => {
    setFinanceEdit(!financeEdit);
  };
  const handelFinanceExpandCollapseClick = () => {
    setFinanceExpanded(!financeExpanded);
  };

  const handelContactDetailsEdit = () => {
    setContactDetailsEdit(!contactDetailsEdit);
  };
  const handelContactDetailsCollapseClick = () => {
    setContactDetailsExpanded(!contactDetailsExpanded);
  };

  const handelStatusEdit = () => {
    setStatusEdit(!statusEdit);
  };
  const handelStatusCollapseClick = () => {
    setStatusExpanded(!statusExpanded);
  };

  const handelInvoiceEdit = () => {
    setInvoiceEdit(!invoiceEdit);
  };
  const handelInvoiceCollapseClick = () => {
    setInvoiceExpanded(!invoiceExpanded);
  };

  const handelTagEdit = () => {
    setTagEdit(!tagEdit);
  };
  const handelTagCollapseClick = () => {
    setTagExpanded(!tagExpanded);
  };

  const replaceImage = (error: any) => {
    error.target.src = DefualtLogo;
  };

  //load countries
  const [isAddCityModalOpen, setIsAddCityModal] = useState(false);
  const [locationData, setLocationData] = useState({
    country: defaultDropDownValue,
    postcode: '',
    place: defaultDropDownValue,
    invoicePlace: defaultDropDownValue,
  });

  //Autocomplete
  const autocompleteHandleChange = async (
    value: string,
    selectedPlace: any
  ) => {
    const autoSelectValues = {
      country: {
        value: values.countryId,
        label: values.country,
      } as BaseModel,
      postcode: values.postalCode,
      place: {
        value: values.cityId,
        label: values.city,
      } as BaseModel,
    };
    //Country
    if (selectedPlace.country && selectedPlace.country !== undefined) {
      let selectedCountry = countryList.find(
        (option: any) => option.label === selectedPlace.country
      );
      autoSelectValues.country = selectedCountry
        ? selectedCountry
        : defaultDropDownValue;

      setLocationData({
        ...locationData,
        country: autoSelectValues.country,
      });

      setSelectedCountry({
        value: autoSelectValues.country?.value,
        label: autoSelectValues.country?.label,
      });
    }
    //City or Place
    if (selectedPlace.place && selectedPlace.place !== undefined) {
      let selectedCity = cityList.find(
        (option: any) => option.label === selectedPlace.place
      );

      //Add new city in db
      if (!selectedCity || selectedCity === undefined) {
        selectedCity = await addNewCity(selectedPlace.place);
      }

      autoSelectValues.place = autoSelectValues.place = selectedCity
        ? selectedCity
        : defaultDropDownValue;

      setLocationData({
        ...locationData,
        place: selectedCity
          ? selectedCity
          : ({ label: selectedPlace.place, value: '0' } as BaseModel),
      });

      setSelectedCity({
        value: autoSelectValues.place?.value,
        label: autoSelectValues.place?.label,
      });
    }
    //Postal code
    if (selectedPlace.postcode && selectedPlace.postcode !== undefined) {
      autoSelectValues.postcode = selectedPlace.postcode;

      setLocationData({
        ...locationData,
        postcode: autoSelectValues.postcode,
      });
    }

    setValues({
      ...values,
      address: value,
      street: selectedPlace.number ? selectedPlace.number : values.street,
      postalCode: autoSelectValues.postcode,
      country:
        autoSelectValues.country?.label === defaultDropDownValue.label
          ? null
          : autoSelectValues.country?.label,
      countryId:
        autoSelectValues.country?.value === defaultDropDownValue.value
          ? null
          : autoSelectValues.country?.value,
      city:
        autoSelectValues.place?.label === defaultDropDownValue.label
          ? null
          : autoSelectValues.place?.label,
      cityId:
        autoSelectValues.place?.value === defaultDropDownValue.value
          ? null
          : autoSelectValues.place?.value,
      latitude: selectedPlace.latitude
        ? selectedPlace.latitude
        : values.latitude,
      longitude: selectedPlace.longitude
        ? selectedPlace.longitude
        : values.longitude,
    });
  };

  //Add City
  useEffect(() => {
    if (!isAddCityModalOpen) {
      setCityByLocationData();
    }
  }, [isAddCityModalOpen]);

  const onAddCity = useCallback(() => {
    setIsAddCityModal(true);
  }, []);

  const onCloseAddCity = () => {
    setIsAddCityModal(false);
  };

  const onSubmitCity = async (cityModel: any) => {
    await getCities();
    onCloseAddCity();
  };

  //Autocomplete Invoice
  const autocompleteHandleChangeInvoice = async (
    value: string,
    selectedPlace: any
  ) => {
    const autoSelectValues = {
      invoiceCountry: {
        value: values.invoiceCountryId,
        label: values.invoiceCountry,
      } as BaseModel,
      invoicePostcode: values?.invoicePostalCode,
      invoicePlace: {
        value: values.invoiceCityId,
        label: values.invoiceCity,
      } as BaseModel,
    };
    //Country
    if (selectedPlace.country && selectedPlace.country !== undefined) {
      let selectedCountry = countryList.find(
        (option: any) => option.label === selectedPlace.country
      );
      autoSelectValues.invoiceCountry = selectedCountry
        ? selectedCountry
        : defaultDropDownValue;

      setLocationData({
        ...locationData,
        country: autoSelectValues.invoiceCountry,
      });

      setSelectedInvoiceCountry({
        value: autoSelectValues.invoiceCountry?.value,
        label: autoSelectValues.invoiceCountry?.label,
      });
    }
    //City or Place
    if (selectedPlace.place && selectedPlace.place !== undefined) {
      let selectedCity = cityList.find(
        (option: any) => option.label === selectedPlace.place
      );

      //Add new city in db
      if (!selectedCity || selectedCity === undefined) {
        selectedCity = await addNewCity(selectedPlace.place);
      }

      autoSelectValues.invoicePlace = autoSelectValues.invoicePlace =
        selectedCity ? selectedCity : defaultDropDownValue;

      setLocationData({
        ...locationData,
        invoicePlace: selectedCity
          ? selectedCity
          : ({ label: selectedPlace.place, value: '0' } as BaseModel),
        place: selectedCity
          ? selectedCity
          : ({ label: selectedPlace.place, value: '0' } as BaseModel),
      });
      setSelectedInvoiceCity({
        value: autoSelectValues.invoicePlace?.value,
        label: autoSelectValues.invoicePlace?.label,
      });
    }
    //Postal code
    if (selectedPlace.postcode && selectedPlace.postcode !== undefined) {
      autoSelectValues.invoicePostcode = selectedPlace.postcode;

      setLocationData({
        ...locationData,
        postcode: autoSelectValues.invoicePostcode,
      });
    }

    setValues({
      ...values,
      invoiceAddress: value,
      invoiceStreet: selectedPlace.number
        ? selectedPlace.number
        : values.invoiceStreet,
      invoicePostalCode: autoSelectValues.invoicePostcode,
      invoiceCountry:
        autoSelectValues.invoiceCountry?.label === defaultDropDownValue.label
          ? null
          : autoSelectValues.invoiceCountry?.label,
      invoiceCountryId:
        autoSelectValues.invoiceCountry?.value === defaultDropDownValue.value
          ? null
          : autoSelectValues.invoiceCountry?.value,
      invoiceCity:
        autoSelectValues.invoicePlace?.label === defaultDropDownValue.label
          ? null
          : autoSelectValues.invoicePlace?.label,
      invoiceCityId:
        autoSelectValues.invoicePlace?.value === defaultDropDownValue.value
          ? null
          : autoSelectValues.invoicePlace?.value,
    });
  };

  const setCityByLocationData = () => {
    if (locationData.place && locationData.place !== undefined) {
      let selectedCity = cityList.find(
        (option: any) => option.label === locationData.place.label
      );
      if (selectedCity && selectedCity !== undefined) {
        setValues({
          ...values,
          city:
            selectedCity?.label === defaultDropDownValue.label
              ? null
              : selectedCity?.label,
          cityId:
            selectedCity?.value === defaultDropDownValue.value
              ? null
              : selectedCity?.value,
        });
        setSelectedCity({
          value: selectedCity?.value,
          label: selectedCity?.label,
        });
      }
    }
    if (locationData.invoicePlace && locationData.invoicePlace !== undefined) {
      let selectedCity = cityList.find(
        (option: any) => option.label === locationData.invoicePlace.label
      );
      if (
        selectedCity &&
        selectedCity !== undefined &&
        selectedCity.label !== i18nMessages.Select
      ) {
        setValues({
          ...values,
          invoiceCity:
            selectedCity?.label === defaultDropDownValue.label
              ? null
              : selectedCity?.label,
          invoiceCityId:
            selectedCity?.value === defaultDropDownValue.value
              ? null
              : selectedCity?.value,
        });
        setSelectedInvoiceCity({
          value: selectedCity?.value,
          label: selectedCity?.label,
        });
      }
    }
  };

  const addNewCity = async (value: string) => {
    const name = {
      code: value,
      name: value,
      otherComments: value,
      statusId: 1,
    };
    const newOption: any = await PostCityDropDown(
      name,
      i18nMessages.CityCommonSaveSuccessMsg,
      i18nMessages.CommonErrOccuredMsg
    );

    return newOption;
  };

  const onBlurZipCode = async (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    triggerFrom: ZipCodeTriggeredFrom
  ) => {
    const apiUrl = new URL(
      environment.api.baseUrl +
        'Locations/GetLocationByZipCode/' +
        String(event.target.value)
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result) {
        if (result.data) {
          const city: BaseModel = {
            label: String(result.data.city),
            value: String(result.data.cityId),
          };
          const country: BaseModel = {
            label: String(result.data.country),
            value: String(result.data.countryId),
          };
          if (triggerFrom === ZipCodeTriggeredFrom.CustomerDetails) {
            setValues({
              ...values,
              city: city.label,
              cityId: city.value,
              country: country.label,
              countryId: country.value,
            });
            setSelectedCity(city);
            setSelectedCountry(country);
          } else if (triggerFrom === ZipCodeTriggeredFrom.CustomerInvoice) {
            setValues({
              ...values,
              invoiceCity: city.label,
              invoiceCityId: city.value,
              invoiceCountry: country.label,
              invoiceCountryId: country.value,
            });
            setSelectedInvoiceCity(city);
            setSelectedInvoiceCountry(country);
          }
        } else {
        }
      } else {
        toast.error(I18n('Common.ErrorOccurred'));
      }
    } catch (error: any) {
      if (triggerFrom === ZipCodeTriggeredFrom.CustomerDetails) {
        setValues({
          ...values,
          city: '',
          cityId: '',
          country: '',
          countryId: '',
        });
        setSelectedCity(defaultDropDownValue);
        setSelectedCountry(defaultDropDownValue);
      } else if (triggerFrom === ZipCodeTriggeredFrom.CustomerInvoice) {
        setValues({
          ...values,
          invoiceCity: '',
          invoiceCityId: '',
          invoiceCountry: '',
          invoiceCountryId: '',
        });
        setSelectedInvoiceCity(defaultDropDownValue);
        setSelectedInvoiceCountry(defaultDropDownValue);
      }
    }
  };

  const LogoData = () => {
    return (
      <>
        <Grid item lg={12} md={12}>
          {!isLogoEdit ? (
            <img
              src={environment.api.uploadedFileUrl + '/' + values.logo}
              onError={replaceImage}
              alt="Logo"
              height={'100px'}
              width={'100px'}
            />
          ) : (
            <FileUpload
              filesLimit={1}
              validatePixel={true}
              onSave={handleSaveUpload}
              showPreviews={true}
              maxFileSize={2097152}
              onDelete={handleFileDelete}
              errorValue={I18n(errors.fileUpload)}
              acceptedFiles={[
                'image/jpeg',
                'image/png',
                'image/jpg',
                'image/bmp',
              ]}
            />
          )}
        </Grid>
      </>
    );
  };

  const loadCitiesOptions = async (
    search: string,
    loadedOptions: any,
    { page }: any
  ) => {
    const response = await getCities(search, page);
    const hasMore = Math.ceil(response.TotalRecords / 500) > page;

    return {
      options: response.Values.map((x) => {
        return {
          value: x.value.toString(),
          label: x.label,
        } as BaseModel;
      }),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const onCreateOption = async (inputValue: any, isInvoice: boolean) => {
    const name = {
      code: inputValue,
      name: inputValue,
      otherComments: inputValue,
      statusId: 1,
    };
    const newOption: any = await PostCityDropDown(
      name,
      i18nMessages.CityCommonSaveSuccessMsg,
      i18nMessages.CommonErrOccuredMsg
    );
    const increaseUniq = (uniq: any) => uniq + 1;
    setCacheUniq(increaseUniq);
    if (isInvoice) {
      onInvoiceCityChange(newOption);
    } else {
      onCityChange(newOption);
    }

    getCities('', 1);
  };

  const onCategoryCreateOption = async (inputValue: any) => {
    let name = {};
    name['name'] = inputValue;
    name['entityType'] = MasterCategoryEnumType.AllowedOrderCategory;
    const newOption: any = await PostCatagoryDropDown(
      name,
      i18nMessages.CommonSaveSuccessMsg,
      i18nMessages.CommonErrOccuredMsg
    );
    const increaseUniq = (uniq: any) => uniq + 1;
    setCacheUniq(increaseUniq);
    setValues({ ...values, category: newOption });
  };

  const getSettingsList = async () => {
    let responseCustomerTypes = await getSettings(
      '',
      1,
      SettingsType.CustomerTypes
    );
    let responseInvoicingCycles = await getSettings(
      '',
      1,
      SettingsType.InvoicingCycles
    );
    let responseCurrencyMaster = await getSettings(
      '',
      1,
      SettingsType.CurrencyMaster
    );

    let options = responseCustomerTypes.Values.map((x: any) => {
      return {
        value: x.id.toString(),
        label: x.value,
        key: x.key,
      } as KeyValueBaseModel;
    });
    setCustomerTypeList(options);

    options = responseInvoicingCycles.Values.map((x: any) => {
      return {
        value: x.id.toString(),
        label: x.value,
        key: x.key,
      } as KeyValueBaseModel;
    });
    setInvoicingCycleList(options);

    options = responseCurrencyMaster.Values.map((x: any) => {
      return {
        value: x.id.toString(),
        label: x.value,
        key: x.key,
      } as KeyValueBaseModel;
    });
    setCurrencyList(options);
  };

  const Details = () => {
    return (
      <>
        <Grid item md={4} sm={6} xs={12}>
          <CustomizedInputs
            margin="normal"
            displayLabel={I18n('Customer.Name')}
            name="name"
            id="name"
            inputType="text"
            inputProps={{ maxLength: 50 }}
            errorValue={I18n(errors.name)}
            errorMessage={I18n(errors.name)}
            handleChange={onValueChange}
            value={values?.name}
            readOnly={!isDetailsEdit}
            required={true}
          />
        </Grid>
        <Grid item md={8} sm={6} xs={12}>
          <AddressAutocompleteInputs
            name="address"
            inputProps={{ maxLength: 250 }}
            multiline={true}
            className={`${!isDetailsEdit ? 'autocomplete-readonly' : ''}`}
            displayLabel={I18n('Customer.SearchAddress')}
            handleChange={autocompleteHandleChange}
            readOnly={!isDetailsEdit}
          ></AddressAutocompleteInputs>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <FormControl
            margin="normal"
            displayLabel={I18n('CustomerLocation.Street')}
            id="Street"
            inputType="text"
            inputProps={{ maxLength: 250 }}
            handleChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) =>
              setValues({
                ...values,
                street: event.target.value,
              })
            }
            value={values.street}
            multiline={false}
            readOnly={!isDetailsEdit}
            required={true}
            errorValue={errors.street}
            errorMessage={errors.street}
          ></FormControl>
        </Grid>
        <Grid item md={8} sm={6} xs={12}>
          <FormControl
            margin="normal"
            displayLabel={I18n('CustomerLocation.Address2')}
            id="Address2"
            inputType="text"
            inputProps={{ maxLength: 250 }}
            handleChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) =>
              setValues({
                ...values,
                address2: event.target.value,
              })
            }
            value={values.address2}
            multiline={false}
            readOnly={!isDetailsEdit}
          ></FormControl>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <CustomizedInputs
            margin="normal"
            displayLabel={i18nMessages.postalCode}
            name="postalCode"
            id="postalCode"
            inputType="text"
            errorValue={I18n(errors.postalCode)}
            errorMessage={I18n(errors.postalCode)}
            inputProps={{ maxLength: 20 }}
            handleChange={onValueChange}
            value={values?.postalCode}
            readOnly={!isDetailsEdit}
            required={true}
            handleBlur={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => onBlurZipCode(event, ZipCodeTriggeredFrom.CustomerDetails)}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          {isDetailsEdit ? (
            <div className="add-button-address">
              <CustomSelectPaginateAdd
                key={JSON.stringify(selectedCity)}
                additional={defaultAdditional}
                value={selectedCity}
                loadOptions={loadCitiesOptions}
                handleChange={(newValue: BaseModel) => onCityChange(newValue)}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                }}
                placeholder={defaultDropDownValue.label}
                isSearchable={true}
                displayLabel={I18n('CustomerLocation.City')}
                errorValue={errors.city}
                readOnly={false}
                debounceTimeout={500}
                isMultiSelect={false}
                cacheUniqs={cacheUniq}
                onCreateOption={(inputValue: any) =>
                  onCreateOption(inputValue, false)
                }
                required={true}
                errorMessage={errors.city}
              ></CustomSelectPaginateAdd>
            </div>
          ) : (
            <FormControl
              margin="normal"
              displayLabel={i18nMessages.city}
              id="cityId"
              inputType="text"
              multiline={false}
              handleChange={onValueChange}
              value={values?.city}
              readOnly={!isDetailsEdit}
              required={true}
            />
          )}
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          {isDetailsEdit ? (
            <CustomizedSelect
              placeholder={defaultDropDownValue.label}
              options={countryList}
              isSearchable={true}
              displayLabel={i18nMessages.country}
              handleChange={(newValue: BaseModel) => onCountryChange(newValue)}
              value={selectedCountry}
              errorValue={errors.country}
              required={true}
              errorMessage={errors.country}
            ></CustomizedSelect>
          ) : (
            <FormControl
              margin="normal"
              displayLabel={i18nMessages.country}
              id="name"
              multiline={false}
              inputType="text"
              handleChange={onValueChange}
              value={values?.country}
              readOnly={!isDetailsEdit}
              required={true}
            />
          )}
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <CustomizedInputs
            margin="normal"
            displayLabel={I18n('Customer.Website')}
            name="webSite"
            id="webSite"
            inputType="text"
            errorValue={I18n(errors.webSite)}
            errorMessage={I18n(errors.webSite)}
            handleChange={onValueChange}
            inputProps={{ maxLength: 255 }}
            value={values?.webSite}
            readOnly={!isDetailsEdit}
          />
        </Grid>
      </>
    );
  };

  const FinanceBasic = () => {
    return (
      <>
        <Grid item md={12} sm={4} xs={12}>
          <CustomizedInputs
            margin="normal"
            displayLabel={I18n('Customer.VatNumber')}
            name="vatNumber"
            id="vatNumber"
            inputType="text"
            value={values?.vatNumber}
            inputProps={{ maxLength: 30 }}
            readOnly={!financeEdit}
            handleChange={onValueChange}
            required={true}
            errorValue={errors.VATNumber}
            errorMessage={errors.VATNumber}
          />
        </Grid>
        <Grid item lg={6} md={12} sm={4} xs={12}>
          <CustomizedInputs
            margin="normal"
            displayLabel={I18n('Customer.EORI')}
            name="eori"
            id="eori"
            inputType="text"
            value={values?.eori}
            readOnly={!financeEdit}
            inputProps={{ maxLength: 30 }}
            errorValue={I18n(errors.eori)}
            errorMessage={I18n(errors.eori)}
            handleChange={onValueChange}
          />
        </Grid>
        <Grid item lg={6} md={12} sm={4} xs={12}>
          <FormControlLabel
            labelPlacement="end"
            control={
              <Checkbox
                name="SameAsVat"
                checked={values?.sameVat}
                onChange={onSameAsVaTChange}
                inputProps={{ 'aria-label': 'controlled' }}
                disabled={!financeEdit}
              />
            }
            label={I18n('Customer.SameAsVat')}
          />
        </Grid>
        <Grid item lg={6}>
          <CustomizedSelect
            placeholder={defaultValue.label}
            options={customerTypeList}
            isSearchable={true}
            displayLabel={i18nMessages.customerType}
            handleChange={(newValue: KeyValueBaseModel) => {
              setValues({
                ...values,
                customerType: newValue,
                customerTypeId: newValue.value,
                vatPercentage: newValue.key === CustomerTypes.Belgium ? 21 : 0,
              });
            }}
            value={values.customerType}
            menuPlacement={'auto'}
            errorValue={true}
            errorMessage={errors.customerType}
            required={true}
            readOnly={!financeEdit}
          ></CustomizedSelect>
        </Grid>
        <Grid item lg={6}>
          <CustomizedSelect
            placeholder={defaultValue.label}
            options={currencyList}
            isSearchable={true}
            displayLabel={i18nMessages.billingCurrency}
            handleChange={(newValue: KeyValueBaseModel) => {
              setValues({
                ...values,
                billingCurrency: newValue,
                billingCurrencyId: newValue.value,
              });
            }}
            value={values.billingCurrency}
            menuPlacement={'auto'}
            errorValue={true}
            errorMessage={errors.billingCurrency}
            required={true}
            readOnly={!financeEdit}
          ></CustomizedSelect>
        </Grid>
        <Grid item xs={6}>
          <NumericInput
            displayLabel={i18nMessages.vatPercentage}
            value={values.vatPercentage}
            name="vatPercentage"
            id="vatPercentage"
            inputProps={{ min: 0 }}
            errorValue={true}
            errorMessage={errors.vatPercentage}
            handleChange={(event: any) => {
              setValues({ ...values, vatPercentage: event.target.value });
            }}
            required={true}
            allowNegative={false}
            readOnly={!financeEdit}
            decimalScale={2}
          />
        </Grid>
      </>
    );
  };

  const ContactDetails = () => {
    return (
      <>
        <Grid item lg={6} md={12} sm={6} xs={12}>
          {contactDetailsEdit ? (
            <CustomizedPhone
              title={I18n('Customer.GeneralTelephone')}
              value={values?.telephone}
              name="telephone"
              errorValue={I18n(errors.telephone)}
              errorMessage={I18n(errors.telephone)}
              onChange={(phone: any) => phoneOnChange(phone)}
            ></CustomizedPhone>
          ) : (
            <CustomizedInputs
              margin="normal"
              displayLabel={I18n('Customer.GeneralTelephone')}
              name="telephone"
              id="telephone"
              inputType="text"
              value={values?.telephone}
              readOnly={!contactDetailsEdit}
            />
          )}
        </Grid>

        <Grid item lg={6} md={12} sm={6} xs={12}>
          <CustomizedInputs
            margin="normal"
            displayLabel={I18n('Customer.GeneralEmail')}
            name="email"
            id="email"
            inputType="text"
            value={values?.email}
            readOnly={!contactDetailsEdit}
            errorValue={I18n(errors.email)}
            errorMessage={I18n(errors.email)}
            inputProps={{ maxLength: 255 }}
            handleChange={onValueChange}
          />
        </Grid>
      </>
    );
  };

  const StatusDetails = () => {
    return (
      <>
        <Grid item xs={12}>
          {statusEdit ? (
            <CustomizedSelect
              placeholder={defaultValue.label}
              options={entityStatusList}
              isSearchable={false}
              displayLabel={I18n('Common.Status')}
              handleChange={(newValue: BaseModel) => onStatusChange(newValue)}
              value={selectedStatus}
              errorValue={I18n(errors.status)}
            ></CustomizedSelect>
          ) : (
            <FormControl
              margin="normal"
              displayLabel={I18n('Common.Status')}
              id="status"
              inputType="text"
              multiline={false}
              value={values?.statusDescription}
              readOnly={!statusEdit}
            />
          )}
        </Grid>
      </>
    );
  };

  const TagDetails = () => {
    return (
      <>
        <Grid item xs={12}>
          {tagEdit ? (
            <CustomizedSelect
              placeholder={defaultDropDownValue.label}
              options={customerTagsList}
              isSearchable={false}
              displayLabel={i18nMessages.tag}
              handleChange={(newValue: BaseModel) => onTagChange(newValue)}
              value={selectedTag}
              errorValue={errors.taggedTo}
              required={true}
              errorMessage={errors.taggedTo}
            ></CustomizedSelect>
          ) : (
            <CustomizedInputs
              margin="normal"
              displayLabel={i18nMessages.tag}
              name="taggedTo"
              id="taggedTo"
              inputType="text"
              readOnly={true}
              value={values.taggedToDescription}
            />
          )}
        </Grid>

        {selectedTag.value == CustomerTags.Shipping.toString() ||
        selectedTag.value == CustomerTags.Both.toString() ? (
          <Grid item lg={12}>
            {tagEdit ? (
              <CustomSelectPaginateAdd
                required
                key={JSON.stringify(values.category)}
                additional={defaultAdditional}
                value={values.category}
                loadOptions={(search: string, prevOptions: any, page: any) =>
                  loadCategoryOptions(
                    MasterCategoryEnumType.AllowedOrderCategory,
                    search,
                    prevOptions,
                    page
                  )
                }
                handleChange={(newValue: BaseModel) =>
                  setValues({
                    ...values,
                    category: newValue,
                    categoryId: newValue.value,
                  })
                }
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                }}
                placeholder={defaultDropDownValue.label}
                isSearchable={true}
                displayLabel={i18nMessages.offerCategory}
                errorValue={true}
                errorMessage={errors.category}
                debounceTimeout={500}
                isMultiSelect={false}
                cacheUniqs={cacheUniq}
                onCreateOption={onCategoryCreateOption}
              ></CustomSelectPaginateAdd>
            ) : (
              <CustomizedInputs
                margin="normal"
                displayLabel={i18nMessages.offerCategory}
                name="categoryName"
                id="categoryName"
                inputType="text"
                readOnly={true}
                errorValue={true}
                errorMessage={errors.category}
                value={values.categoryName}
              />
            )}
          </Grid>
        ) : (
          <></>
        )}
      </>
    );
  };

  const InvoiceDetails = () => {
    return (
      <>
        <Grid item sm={6} xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="isInvoiceAddress"
                onChange={onInvoiceAddressChange}
                checked={values?.isInvoiceAddress}
                inputProps={{ 'aria-label': 'controlled' }}
                disabled={!invoiceEdit}
              />
            }
            label={i18nMessages.isAddressDifferent}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <CustomizedInputs
            margin="normal"
            displayLabel={i18nMessages.invoiceEmail}
            name="invoiceEmail"
            id="invoiceEmail"
            inputType="text"
            value={values?.invoiceEmail}
            readOnly={!invoiceEdit}
            handleChange={onValueChange}
            inputProps={{ maxLength: 255 }}
            errorValue={I18n(errors.invoiceEmail)}
            errorMessage={I18n(errors.invoiceEmail)}
          />
        </Grid>
        <Grid item lg={12}>
          <CustomizedSelect
            placeholder={defaultValue.label}
            options={invoicingCycleList}
            isSearchable={true}
            displayLabel={i18nMessages.invoicingCycle}
            handleChange={(newValue: KeyValueBaseModel) => {
              setValues({
                ...values,
                invoicingCycle: newValue,
                invoicingCycleId: newValue.value,
              });
            }}
            value={values.invoicingCycle}
            menuPlacement={'auto'}
            errorValue={true}
            errorMessage={errors.invoicingCycle}
            required={true}
            readOnly={!invoiceEdit}
          ></CustomizedSelect>
        </Grid>
        {isInvoiceAddress ? (
          <>
            <Grid item lg={12} md={12}>
              <AddressAutocompleteInputs
                name="invoiceAddress"
                inputProps={{ maxLength: 250 }}
                className={`${!invoiceEdit ? 'autocomplete-readonly' : ''}`}
                multiline={true}
                displayLabel={i18nMessages.searchAddress}
                handleChange={autocompleteHandleChangeInvoice}
                readOnly={!invoiceEdit}
              ></AddressAutocompleteInputs>
            </Grid>
            <Grid item lg={6} md={6}>
              <CustomizedInputs
                margin="normal"
                displayLabel={i18nMessages.Street}
                name="invoiceStreet"
                id="invoiceStreet"
                inputType="text"
                inputProps={{ maxLength: 20 }}
                handleChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ) =>
                  setValues({
                    ...values,
                    invoiceStreet: event.target.value,
                  })
                }
                value={values.invoiceStreet}
                readOnly={!invoiceEdit}
                errorValue={true}
                errorMessage={errors.invoiceStreet}
                required={true}
              />
            </Grid>
            <Grid item lg={6} md={6}>
              <CustomizedInputs
                margin="normal"
                displayLabel={i18nMessages.Address2}
                name="invoiceAddress2"
                id="invoiceAddress2"
                inputType="text"
                inputProps={{ maxLength: 250 }}
                handleChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ) =>
                  setValues({
                    ...values,
                    invoiceAddress2: event.target.value,
                  })
                }
                value={values.invoiceAddress2}
                readOnly={!invoiceEdit}
              />
            </Grid>
            <Grid item lg={6} md={6}>
              <CustomizedInputs
                margin="normal"
                displayLabel={i18nMessages.postalCode}
                name="invoicePostalCode"
                id="invoicePostalCode"
                inputType="text"
                handleChange={onValueChange}
                inputProps={{ maxLength: 20 }}
                value={values?.invoicePostalCode}
                readOnly={!invoiceEdit}
                errorValue={true}
                errorMessage={errors.invoicePostalCode}
                required={true}
                handleBlur={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ) => onBlurZipCode(event, ZipCodeTriggeredFrom.CustomerInvoice)}
              />
            </Grid>
            <Grid item lg={6} md={6}>
              {invoiceEdit ? (
                <div className="add-button-address">
                  <CustomSelectPaginateAdd
                    key={JSON.stringify(selectedInvoiceCity)}
                    additional={defaultAdditional}
                    value={selectedInvoiceCity}
                    loadOptions={loadCitiesOptions}
                    handleChange={(newValue: BaseModel) =>
                      onInvoiceCityChange(newValue)
                    }
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                    }}
                    placeholder={defaultDropDownValue.label}
                    isSearchable={true}
                    displayLabel={i18nMessages.city}
                    readOnly={false}
                    debounceTimeout={500}
                    isMultiSelect={false}
                    cacheUniqs={cacheUniq}
                    onCreateOption={(inputValue: any) =>
                      onCreateOption(inputValue, true)
                    }
                    menuPlacement={'top'}
                    errorValue={true}
                    errorMessage={errors.invoiceCity}
                    required={true}
                  ></CustomSelectPaginateAdd>
                </div>
              ) : (
                <CustomizedInputs
                  margin="normal"
                  displayLabel={i18nMessages.city}
                  name="invoiceCity"
                  id="invoiceCity"
                  inputType="text"
                  handleChange={onValueChange}
                  value={values?.invoiceCity}
                  readOnly={!invoiceEdit}
                  errorValue={true}
                  errorMessage={errors.invoiceCity}
                  required={true}
                />
              )}
            </Grid>
            <Grid item lg={6} md={6}>
              {invoiceEdit ? (
                <CustomizedSelect
                  placeholder={defaultDropDownValue.label}
                  options={countryList}
                  isSearchable={true}
                  displayLabel={i18nMessages.country}
                  handleChange={(newValue: BaseModel) =>
                    onInvoiceCountryChange(newValue)
                  }
                  value={selectedInvoiceCountry}
                  menuPlacement={'top'}
                  errorValue={true}
                  errorMessage={errors.invoiceCountry}
                  required={true}
                ></CustomizedSelect>
              ) : (
                <CustomizedInputs
                  margin="normal"
                  displayLabel={i18nMessages.country}
                  name="invoiceCountry"
                  id="invoiceCountry"
                  inputType="text"
                  handleChange={onValueChange}
                  value={values?.invoiceCountry}
                  readOnly={!invoiceEdit}
                  errorValue={true}
                  errorMessage={errors.invoiceCountry}
                  required={true}
                />
              )}
            </Grid>
          </>
        ) : (
          <></>
        )}
      </>
    );
  };

  useEffect(() => {
    getCountries();
    getCities();
    getCustomerData(params.entityId);
    getSettingsList();
  }, []);

  return (
    <>
      <Grid container spacing={2} className="grid-wrap">
        <Grid item md={3} xs={12}>
          <FormAccordian
            showActionButton={canEdit}
            title={'Logo'}
            details={LogoData()}
            expanded={logoExpanded}
            isEdit={isLogoEdit}
            handleEditClick={handelLogoEdit}
            handleExpandCollapseClick={handleLogoExpandCollapseClick}
            isSaveButton={isLogoEdit}
            onSaveClick={handleFileUpload}
          ></FormAccordian>
        </Grid>
        <Grid item md={9} xs={12}>
          <FormAccordian
            title={I18n('Customer.Details')}
            details={Details()}
            showActionButton={canEdit}
            expanded={detailsExpanded}
            isEdit={isDetailsEdit}
            handleEditClick={handelDetailsEdit}
            handleExpandCollapseClick={handleDetailsExpandCollapseClick}
            isSaveButton={isDetailsEdit}
            onSaveClick={handleSubmit}
          ></FormAccordian>
        </Grid>
        <Grid item md={4} xs={12}>
          <FormAccordian
            title={I18n('Customer.FinancialBasic')}
            showActionButton={canEdit}
            details={FinanceBasic()}
            expanded={financeExpanded}
            isEdit={financeEdit}
            handleEditClick={handelFinanceEdit}
            handleExpandCollapseClick={handelFinanceExpandCollapseClick}
            isSaveButton={financeEdit}
            onSaveClick={handleSubmit}
          ></FormAccordian>
        </Grid>
        <Grid item md={4} xs={12}>
          <FormAccordian
            title={I18n('Customer.ContactDetails')}
            showActionButton={canEdit}
            details={ContactDetails()}
            expanded={contactDetailsExpanded}
            isEdit={contactDetailsEdit}
            handleEditClick={handelContactDetailsEdit}
            handleExpandCollapseClick={handelContactDetailsCollapseClick}
            isSaveButton={contactDetailsEdit}
            onSaveClick={handleSubmit}
          ></FormAccordian>
        </Grid>
        <Grid item md={4} xs={12}>
          <FormAccordian
            title={I18n('Customer.Status')}
            showActionButton={canEdit}
            details={StatusDetails()}
            expanded={statusExpanded}
            isEdit={statusEdit}
            handleEditClick={handelStatusEdit}
            handleExpandCollapseClick={handelStatusCollapseClick}
            isSaveButton={statusEdit}
            onSaveClick={handleSubmit}
          ></FormAccordian>
        </Grid>

        <Grid item md={4} xs={12}>
          <FormAccordian
            title={i18nMessages.invoicing}
            showActionButton={canEdit}
            details={InvoiceDetails()}
            expanded={invoiceExpanded}
            isEdit={invoiceEdit}
            handleEditClick={handelInvoiceEdit}
            handleExpandCollapseClick={handelInvoiceCollapseClick}
            isSaveButton={invoiceEdit}
            onSaveClick={handleSubmit}
          ></FormAccordian>
        </Grid>

        <Grid item md={4} xs={12}>
          <FormAccordian
            title={i18nMessages.tag}
            showActionButton={canEdit}
            details={TagDetails()}
            expanded={tagExpanded}
            isEdit={tagEdit}
            handleEditClick={handelTagEdit}
            handleExpandCollapseClick={handelTagCollapseClick}
            isSaveButton={tagEdit}
            onSaveClick={handleSubmit}
          ></FormAccordian>
        </Grid>
      </Grid>
      {isAddCityModalOpen && (
        <AddCity
          isOpen={isAddCityModalOpen}
          onClose={onCloseAddCity}
          onSubmit={onSubmitCity}
          title={i18nMessages.AddCity}
          locationData={locationData}
        ></AddCity>
      )}
    </>
  );
}

export default EditCustomerDetails;
