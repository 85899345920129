import { Breadcrumbs, Button, Grid, Link, Typography } from '@mui/material';
import { CustomizedInputs } from 'common/formControl/formControl';
import environment from 'environment';
import { DnPWorkOrders } from 'models/deck-pack-orders';
import {
  BaseModel,
  DGDDescription,
  ActivityOwner,
  OrderType,
  Language,
} from 'models/pagination-model';
import RouteEnum from 'models/RouteEnum';
import { Permission } from 'Permissions';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import I18n from 'utilities/i18n';
import http from '../../../utilities/httpService';
import { getDecPackById } from '../DeckPackApiService';
import DecPackShippingMaterial from '../DecPackShippingMaterial';
import WO10SendEmailPopup from './WO10SendEmailPopup';
import FormAccordianSave from 'common/formControl/formAccordianSave';
import { hasPermission } from 'utilities/protectedRoute';
import ConfirmationPopup from 'common/confirmationPopup/confirmationPopup';
import DecpackStatus from './DecpackStatus';
import CreatedByandUpdatedBy from 'common/CreatedByandUpdatedBy/CreatedByandUpdatedBy';

function WO10(props: any) {
  const { woId, id } = useParams();
  const [status, setStatus] = useState('');
  let navigate = useNavigate();
  const [details, setDetails] = useState<any>({
    createdBy: '',
    createdDate: '',
    updatedBy: '',
    updatedDate: '',
  });

  const [assigneeData, setAssigneeData] = useState<any>({
    assignedToId: '',
    assignedToName: '',
    assignNextToId: '',
    assignNextToName: '',
  });
  const [isMobile, setIsMobile] = useState(false);
  const breadcrumbs = [
    <Typography key="3" color="text.primary">
      {I18n('Nav.Order')}
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={() => navigate(RouteEnum.DecPackOrdersList)}
        className="cpointer"
      >
        {I18n('Nav.DecPackOrders')}
      </Link>
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={() => navigate(RouteEnum.ViewDecPackOrders + '/' + id + '/1')}
        className="cpointer"
      >
        {I18n('ShippingOrders.WorkOrders')}
      </Link>
    </Typography>,
  ];
  const defaultValue = {
    value: '0',
    label: I18n('Placeholders.Select'),
  } as BaseModel;

  const i18nMessages = {
    title: I18n('DecPackOrders.WorkOrder.ApprovalBillOfMaterial'),
    detailsTitle: I18n('WorkOrder.ApprovalInvoice'),
    approveInvoiceBtn: I18n('WorkOrder.ApproveInvoice'),
    updatedSuccessfully: I18n('WorkOrder.UpdatedSuccessfully'),
    errorOccurred: I18n('WorkOrder.ErrorOccurred'),
    SendEmailTitle: I18n('SendEmail.SendEmailTitle'),
    InfoRemindContactsSubTitle: I18n('WorkOrder.InfoRemindCustomerRef'),
    sendProformaInvoice: I18n('WorkOrder.SendProformaInvoice'),
    SendAnyway: I18n('WorkOrder.SendAnyway'),
    ApproveAnyway: I18n('WorkOrder.ApproveAnyway'),
    sendSuccessfully: I18n('WorkOrder.SendSuccessfully'),
    Notes: I18n('DecPackOrders.WorkOrder.InvoiceNotes'),
    ProformaNotes: I18n('DecPackOrders.WorkOrder.ProformaNotes'),
    SendInvoice: I18n('WorkOrder.SendProformaInvoice'),
    sellingPriceLessThanCostPrice: I18n(
      'ShippingMaterials.SellingPriceLessThanCostPrice'
    ),
    sendEmailSuccessfully: I18n('WorkOrder.SendEmailSuccessfully'),
    invoiceApprovedSuccess: I18n('WorkOrder.InvoiceApprovedSuccess'),
    save: I18n('Common.Save'),
    saveNext: I18n('Common.SaveNext'),
    specialInstructions: I18n('DecPackOrders.WorkOrder.SpecialInstruction'),
  };

  const [isSendEmailModalOpen, setIsSendEmailModal] = useState(false);
  const [isConfirmed, setIsConfirmedModal] = useState(false);
  const [isConfirApproveInvoice, setIsConfirApproveInvoice] = useState(false);
  const [type, setType] = useState(0);

  const [approvedBOM, setApprovedBOM] = useState<boolean>(true);

  const [customerEmail, setCustomerEmail] = useState('');
  const [customerContactsEmail, setCustomerContactsEmail] = useState('');
  const [customerReference, setCustomerReference] = useState('');
  const [isSaveNext, setIsSaveNext] = useState<boolean>(true);
  const [woNotes, setWoNotes] = useState({
    Wo10Notes: '',
    Wo10ProformaNotes: '',
  });
  const [isStatusSaveCallApi, setIsStatusSaveCallApi] = useState(false); // To call Submit APi Status from Status Component
  const [validateStatus, setValidateStatus] = useState<boolean>(false); // validateStatus - To validate Status Dropdown from Status Component
  const [canEdit, setcanEdit] = useState(
    hasPermission(Permission.canEditDecPackOrders)
  );
  const [isCustomerReference, setIsCustomerReference] =
    useState<boolean>(false);
  const [instruction, setInstruction] = useState();
  const [instruction2, setInstruction2] = useState();
  let entityWorkOrderStatusList: Array<BaseModel> = [];
  const getStatusList = () => {
    entityWorkOrderStatusList = [defaultValue];
    DGDDescription.forEach((value: string, key: number) => {
      entityWorkOrderStatusList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getStatusList();
  useEffect(() => {
    getWOStatus();
    getDecPackByDetailsId();
    getInstruction();
    getInstruction2();
  }, []);

  const getInstruction = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl +
        'DecPackCheckDocumentation/' +
        id +
        '/' +
        localStorage.getItem('DocumentId')
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result && result.data) {
        setInstruction(result.data.instruction);
      }
    } catch (error) {}
  };
  const getInstruction2 = async () => {
    const apiUrl = new URL(environment.api.baseUrl + `DecPackOrder/${id}`);
    try {
      const result = await http.get(apiUrl.toString());
      if (result && result.data) {
        setInstruction2(result.data.specialInstructions);
      }
    } catch (error) {}
  };

  const getWOStatus = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl +
        'DecPackOrder/GetDecPackWO/' +
        id +
        '/' +
        DnPWorkOrders.WO10
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result) {
        setStatus(result.data.status);
        setDetails({
          ...details,
          createdBy: result.data.createdBy,
          updatedBy: result.data.updatedBy,
          createdDate: result.data.createdDate,
          updatedDate: result.data.updatedDate,
        });

        setAssigneeData({
          ...assigneeData,
          assignedToId: result.data.assignedToId,
          assignedToName: result.data.assignedToName,
          assignNextToId: result.data.assignedNextToId,
          assignNextToName: result.data.assignedNextToName,
        });

        setIsMobile(result.data.isMobile);
        return result.data.status;
      }
    } catch (error) {}
  };

  const handleSentClick = async (val: any) => {
    if (type === 1) {
      await handleInvoiceApproval(val);
    } else {
      await handleSendProformaInvoice(val);
    }
  };

  const saveWorkOrderDetails = async () => {
    try {
      const apiUrl = new URL(
        environment.api.baseUrl + 'DecPackOrder/UpdateW10'
      );
      var data = {
        id: Number(id),
        Wo10Notes: woNotes.Wo10Notes,
        Wo10ProformaNotes: woNotes.Wo10ProformaNotes,
      };
      const result: any = await http.put(apiUrl.toString(), data);
      if (!result) {
        toast.error(i18nMessages.errorOccurred);
      }
    } catch (error: any) {
      toast.error(i18nMessages.errorOccurred);
    }
  };

  const handleSendProformaInvoice = async (val: any) => {
    await saveWorkOrderDetails();
    try {
      const apiUrl = new URL(
        environment.api.baseUrl + 'DecPackOrder/SendPerformaInvoice'
      );

      var data = {
        id: Number(id),
        approvedBOM: false,
        language: Number(val.language),
      };

      const result: any = await http.post(apiUrl.toString(), data);

      if (result) {
        toast.success(i18nMessages.sendSuccessfully);
        setIsSendEmailModal(false);
      } else {
        toast.error(i18nMessages.errorOccurred);
      }
    } catch (error: any) {
      toast.error(i18nMessages.errorOccurred);
    }
  };

  const handleInvoiceApproval = async (val: any) => {
    await saveWorkOrderDetails();
    try {
      const apiUrl = new URL(
        environment.api.baseUrl + 'DecPackOrder/UpdateBOM'
      );

      var data = {
        id: Number(id),
        approvedBOM: true,
        language: Number(val.language),
      };

      const result: any = await http.put(apiUrl.toString(), data);

      if (result) {
        if (val.isCustomerReferenceAvailable) {
          toast.success(i18nMessages.invoiceApprovedSuccess);
        } else {
          toast.success(i18nMessages.sendEmailSuccessfully);
        }
        setIsSendEmailModal(false);
      } else {
        toast.error(i18nMessages.errorOccurred);
      }
    } catch (error: any) {
      toast.error(i18nMessages.errorOccurred);
    }
  };

  const SpecialInstructionData = () => {
    return (
      <>
        <Grid item lg={12} md={12}>
          <CustomizedInputs
            placeholder={defaultValue.label}
            displayLabel={i18nMessages.specialInstructions + ' 1'}
            value={instruction}
            readOnly={true}
          ></CustomizedInputs>
          <CustomizedInputs
            placeholder={defaultValue.label}
            displayLabel={i18nMessages.specialInstructions + ' 2'}
            value={instruction2}
            readOnly={true}
          ></CustomizedInputs>
        </Grid>
      </>
    );
  };

  const NotesData = () => {
    return (
      <>
        <Grid item lg={12} md={12}>
          <CustomizedInputs
            placeholder={defaultValue.label}
            displayLabel={i18nMessages.Notes}
            handleChange={(newValue: any) => {
              setWoNotes({
                ...woNotes,
                Wo10Notes: newValue.target.value,
              });
            }}
            value={woNotes.Wo10Notes}
            readOnly={!canEdit}
          ></CustomizedInputs>
        </Grid>
      </>
    );
  };

  const ProformaNotesData = () => {
    return (
      <>
        <Grid item lg={12} md={12}>
          <CustomizedInputs
            placeholder={defaultValue.label}
            displayLabel={i18nMessages.ProformaNotes}
            handleChange={(newValue: any) => {
              setWoNotes({
                ...woNotes,
                Wo10ProformaNotes: newValue.target.value,
              });
            }}
            value={woNotes.Wo10ProformaNotes}
            readOnly={!canEdit}
          ></CustomizedInputs>
        </Grid>
      </>
    );
  };

  const approveButtonEvent = async (event: any) => {
    if (totalSellingPrice < totalCostPrice) {
      setIsConfirApproveInvoice(true);
    } else {
      setIsConfirApproveInvoice(false);
      if (!customerReference || customerReference === '') {
        setType(1);
        setIsSendEmailModal(true);
      } else {
        await handleInvoiceApproval({
          language: Language.En, // Default sent in English
          isCustomerReferenceAvailable: true,
        });
      }
    }
  };

  const performaInvoiceEvent = async (event: any) => {
    if (totalSellingPrice < totalCostPrice) {
      setIsConfirmedModal(true);
    } else {
      setType(2);
      setIsSendEmailModal(true);
      setIsConfirmedModal(false);
    }
  };

  const onCloseSendEmail = () => {
    setIsSendEmailModal(false);
  };

  const getDecPackByDetailsId = async () => {
    const response: any = await getDecPackById(id ? +id : 0);
    if (response) {
      if (response.selectedContacts?.length > 0) {
        let emails = response.selectedContacts
          .map(function (c: any) {
            return c.email;
          })
          .join(', ');
        setCustomerContactsEmail(emails);
      }
      setCustomerEmail(response.customerEmail);
      setCustomerReference(response.CustomerReference);
      setApprovedBOM(response.approvedBOM);
      setWoNotes({
        Wo10Notes: response.wo10Notes,
        Wo10ProformaNotes: response.wo10ProformaNotes,
      });
      setIsCustomerReference(response.CustomerReference ? true : false);
    }
  };

  const handleNotesClick = async (event: any) => {
    event.preventDefault();
    setIsSaveNext(false);
    setValidateStatus(true);
  };

  // Validate Status Dropdown Success call from Status Component
  const handleSuccessStatusSave = async () => {
    setIsStatusSaveCallApi(true);
    try {
      const apiUrl = new URL(
        environment.api.baseUrl + 'DecPackOrder/UpdateW10'
      );
      var data = {
        id: Number(id),
        Wo10Notes: woNotes.Wo10Notes,
        Wo10ProformaNotes: woNotes.Wo10ProformaNotes,
      };
      const result: any = await http.put(apiUrl.toString(), data);
      if (result) {
        toast.success(i18nMessages.updatedSuccessfully);
        handleErrorStatusSave(); // To set Validation and Submit APi Status False
      } else {
        toast.error(i18nMessages.errorOccurred);
      }
    } catch (error: any) {
      toast.error(i18nMessages.errorOccurred);
    }
  };

  // Validate Status Dropdown Error call from Status Component
  const handleErrorStatusSave = () => {
    setValidateStatus(false);
    setIsStatusSaveCallApi(false);
  };

  const [totalSellingPrice, setTotalSellingPrice] = useState(false);
  const [totalCostPrice, setTotalCostPrice] = useState(false);

  const handleCloseForConfirmation = () => {
    setIsConfirmedModal(false);
  };

  const handleOkForConfirmation = () => {
    setType(2);
    setIsSendEmailModal(true);
    setIsConfirmedModal(false);
  };

  const handleCloseApproveConfirmation = () => {
    setIsConfirApproveInvoice(false);
  };

  const handleOkApproveConfirmation = async () => {
    if (!customerReference || customerReference === '') {
      setType(1);
      setIsSendEmailModal(true);
    } else {
      await handleInvoiceApproval({
        language: Language.En, // Default sent in English
        isCustomerReferenceAvailable: true,
      });
    }
    setIsConfirApproveInvoice(false);
  };
  const sendData = (data: any) => {
    if (data) {
      setTotalSellingPrice(data.data.item2);
      setTotalCostPrice(data.data.item3);
    }
  };

  const onSaveNextClick = async (event: any) => {
    event.preventDefault();
    setValidateStatus(true);
    setIsSaveNext(true);
  };
  const refreshData = async () => {
    handleErrorStatusSave();
    getWOStatus();
  };

  return (
    <>
      <div className="heading-section">
        <div className="heading-section-left">
          <Typography variant="h3" sx={{ mb: 3 }}>
            {i18nMessages.title}
          </Typography>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>
      </div>
      <div className="main-content-section">
        <DecPackShippingMaterial
          shippingOrderId={id ? id : 0}
          isAddShippingMaterial={false}
          showAlertMessage={true}
          showFileUpload={false}
          hideCostPrice={false}
          sendData={(data: any) => sendData(data)}
          approvedBOM={approvedBOM}
          isFromWO={true}
        ></DecPackShippingMaterial>

        <Grid item lg={6} md={6}>
          <FormAccordianSave
            title={i18nMessages.specialInstructions}
            details={SpecialInstructionData()}
            className="inner-heading"
          ></FormAccordianSave>
        </Grid>

        <Grid item lg={6} md={6}>
          <FormAccordianSave
            title={i18nMessages.Notes}
            details={NotesData()}
            className="inner-heading"
          ></FormAccordianSave>
        </Grid>

        <Grid item lg={6} md={6}>
          <FormAccordianSave
            title={i18nMessages.ProformaNotes}
            details={ProformaNotesData()}
            className="inner-heading"
          ></FormAccordianSave>
        </Grid>

        <Grid item lg={12} md={12}>
          <DecpackStatus
            permission={Permission.canEditDecPackOrders}
            woId={DnPWorkOrders.WO10}
            activityOwner={ActivityOwner.BackOffice}
            orderId={id}
            statusValue={status}
            orderType={OrderType.DecPackOrder}
            isStatusSaveCallApi={isStatusSaveCallApi}
            validateStatus={validateStatus}
            handleSuccessStatusSave={handleSuccessStatusSave}
            handleErrorStatusSave={handleErrorStatusSave}
            isSaveNext={isSaveNext}
            isMobile={isMobile}
            workOrderNo={DnPWorkOrders.WO10}
            isCustomerReferenceAvailable={isCustomerReference}
            showAssigned={false}
            assigneeData={assigneeData}
            handleStatusChange={(value: number) => setStatus(value.toString())}
          />
        </Grid>
        <div className="btn-wrap form-btn-wrap">
          {canEdit ? (
            <>
              <Button
                color="primary"
                disableElevation
                variant="contained"
                onClick={performaInvoiceEvent}
              >
                {i18nMessages.sendProformaInvoice}
              </Button>
              <Button
                color="primary"
                disableElevation
                variant="contained"
                onClick={approveButtonEvent}
              >
                {i18nMessages.approveInvoiceBtn}
              </Button>
            </>
          ) : (
            <></>
          )}
          {canEdit && !isCustomerReference ? (
            <Button
              color="primary"
              disableElevation
              variant="contained"
              onClick={onSaveNextClick}
            >
              {i18nMessages.saveNext}
            </Button>
          ) : (
            <></>
          )}
          &nbsp; &nbsp; &nbsp;
          {canEdit ? (
            <Button
              type="submit"
              color="primary"
              disableElevation
              variant="contained"
              onClick={handleNotesClick}
            >
              {i18nMessages.save}
            </Button>
          ) : (
            <></>
          )}
        </div>
        <CreatedByandUpdatedBy
          createdBy={details.createdBy}
          createdDate={details.createdDate}
          updatedBy={details.updatedBy}
          updatedDate={details.updatedDate}
        ></CreatedByandUpdatedBy>
      </div>
      {isSendEmailModalOpen && (
        <WO10SendEmailPopup
          isOpen={isSendEmailModalOpen}
          onClose={onCloseSendEmail}
          onSubmit={handleSentClick}
          title={
            type === 2 ? i18nMessages.SendInvoice : i18nMessages.SendEmailTitle
          }
          infoSubTitle={
            type === 1 ? i18nMessages.InfoRemindContactsSubTitle : ''
          }
          type={type}
          recipientEmail={customerContactsEmail}
        ></WO10SendEmailPopup>
      )}

      {isConfirmed && (
        <ConfirmationPopup
          isOpen={isConfirmed}
          message={i18nMessages.sellingPriceLessThanCostPrice}
          handleClose={handleCloseForConfirmation}
          handleOk={handleOkForConfirmation}
          continueButtonText={i18nMessages.SendAnyway}
        ></ConfirmationPopup>
      )}

      {isConfirApproveInvoice && (
        <ConfirmationPopup
          isOpen={isConfirApproveInvoice}
          message={i18nMessages.sellingPriceLessThanCostPrice}
          handleClose={handleCloseApproveConfirmation}
          handleOk={handleOkApproveConfirmation}
          continueButtonText={i18nMessages.ApproveAnyway}
        ></ConfirmationPopup>
      )}
    </>
  );
}

export default WO10;
