import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import I18n from 'utilities/i18n';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import {
  BaseModel,
  ImageUploadViewModel,
  MasterCategoryEnumType,
} from 'models/pagination-model';
import CustomizedSelectPaginate from 'common/CustomSelect/CustomSelectPaginate';
import { loadCategoryOptions } from 'components/deckpack-orders/DeckPackApiService';
import CustomImageEditor from 'common/CustomImageEditor/CustomImageEditor';
import icona from 'tui-image-editor/dist/svg/icon-a.svg';
import iconb from 'tui-image-editor/dist/svg/icon-b.svg';
import iconc from 'tui-image-editor/dist/svg/icon-c.svg';
import icond from 'tui-image-editor/dist/svg/icon-d.svg';
import { Utilities } from 'utilities/Utilities';

export default function EditImage(props: any) {
  const defaultDropDownValue = {
    value: '0',
    label: I18n('Placeholders.Select'),
  } as BaseModel;
  const [image, setImage] = useState<ImageUploadViewModel>();
  const [getImageDataUrl, setImageDataUrl] = useState<boolean>(false);
  const [blobDataUrl, setImageBlobDataUrl] = useState<any>('');
  const i18nMessages = {
    MaxAllowed: I18n('Common.MaxAllowed'),
    documentTag: I18n('Common.DocumentTag'),
  };
  useEffect(() => {
    setImage(props.image);
    setImageBlobDataUrl(props.image.previewUrl);
  }, []);

  const submit = async () => {
    setImageDataUrl(true);
  };

  const onSaveImage = (imageFile: File | undefined) => {
    if (validate()) {
      if (imageFile) {
        props.onSave(imageFile, image);
      } else {
        setImageDataUrl(false);
      }
    }
  };

  const validate = () => {
    let localImage: ImageUploadViewModel = {
      ...image,
      documentTag:
        image && image?.documentTag ? image?.documentTag : ({} as BaseModel),
      path: image && image?.path ? image.path : '',
      componentTempId:
        image && image?.componentTempId
          ? image.componentTempId
          : Utilities.createUUID(),
      error: '',
      previewUrl: image && image?.previewUrl ? image.previewUrl : '',
      isEdited: image && image?.isEdited ? image.isEdited : false,
      id: image && image?.id ? image.id : 0,
      imageError: false,
      imageUrl: '',
      sendToCustomer: false,
    };

    setImage({
      ...localImage,
    });
    return !(localImage.error && localImage.error?.length > 0);
  };

  return (
    <div>
      <Dialog
        open={props.isOpen}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        disableEnforceFocus={true}
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className="grid-wrap">
            <Grid
              item
              xs={12}
              alignItems="center"
              justifyContent="center"
              sx={{
                minHeight: '400px',
                height: '600px',
              }}
            >
              <CustomImageEditor
                isSaveImage={getImageDataUrl}
                onSave={onSaveImage}
                includeUI={{
                  loadImage: {
                    path: blobDataUrl,
                    name: props.image.previewUrl,
                  },
                  theme: {
                    'header.display': 'none',
                    'menu.backgroundColor': 'white',
                    'common.backgroundColor': '#151515',
                    'menu.normalIcon.path': icond,
                    'menu.activeIcon.path': iconb,
                    'menu.disabledIcon.path': icona,
                    'menu.hoverIcon.path': iconc,
                  },
                  menu: ['draw', 'shape', 'text', 'filter'],
                  initMenu: 'draw',
                  // uiSize: {
                  //   width: '1000px',
                  //   height: '700px',
                  // },
                  menuBarPosition: 'bottom',
                }}
                selectionStyle={{
                  cornerSize: 1,
                }}
                usageStatistics={true}
              />
              <CustomizedSelectPaginate
                additional={{
                  page: 1,
                }}
                value={image?.documentTag}
                loadOptions={(search: string, prevOptions: any, page: any) =>
                  loadCategoryOptions(
                    MasterCategoryEnumType.DocumentCategory,
                    search,
                    prevOptions,
                    page
                  )
                }
                handleChange={(newValue: BaseModel) => {
                  if (image) {
                    setImage({ ...image, documentTag: newValue });
                  }
                }}
                placeholder={defaultDropDownValue.label}
                isSearchable={true}
                displayLabel={i18nMessages.documentTag}
                debounceTimeout={500}
                isMultiSelect={false}
                name="documentTag"
              />

              {props.sendToCustomer ? (
                <FormControlLabel
                  control={
                    <Checkbox
                      name="sendToCustomer"
                      onChange={(e) => {
                        if (image) {
                          setImage({
                            ...image,
                            sendToCustomer: e.target.checked,
                          });
                        }
                      }}
                      checked={image?.sendToCustomer}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label={I18n('Common.SendToCustomer')}
                />
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={props.handleClose}
            color="neutral"
            variant="contained"
            disableElevation
          >
            {I18n('Common.Cancel')}
          </Button>
          <Button
            onClick={submit}
            autoFocus
            color="primary"
            variant="contained"
            disableElevation
          >
            {I18n('Common.Save')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
