import I18n from 'utilities/i18n';
import { useEffect, useState } from 'react';
import http from '../../utilities/httpService';
import environment from 'environment';
import FormAccordianSave from 'common/formControl/formAccordianSave';
import FormAccordian from 'common/formControl/formAccordian';
import PrintIcon from '@mui/icons-material/Print';
import { hasPermission } from 'utilities/protectedRoute';
import CustomSelectPaginateAdd from 'common/CustomSelect/CustomSelectPaginateAdd';
import { PostCityDropDown } from 'common/DropDownAddAPI/DropDownApiService';
import CustomizedPhone from 'common/formControl/formPhone';

import {
  Box,
  Button,
  Card,
  Collapse,
  Grid,
  List,
  ListItemButton,
  ListItemText,
} from '@mui/material';

import {
  BaseModel,
  CreateLabelOption,
  CreateLabelLayout,
  EntityCreateLabelLayoutDescription,
  EntityCreateLabelOptionDescription,
  EntityTypeEnum,
  TypeOfService,
  OrderByDirection,
  PaginationResponseModel,
  ResponseType,
} from 'models/pagination-model';

import { DnPWorkOrders } from 'models/deck-pack-orders';

import CustomizedSelect from 'common/CustomSelect/CustomSelect';
import CustomizedInputs from 'common/formControl/formControl';
import { toast } from 'react-toastify';

import './PreviewLabel.scss';
import { getDGPlusData } from '../../components/deckpack-orders/DeckPackApiService';
import NumericInput from 'common/NumericInput/NumericInput';
import {
  loadShipperLabelOptions,
  loadConsigneeLabelOptions,
  getLabelById,
  LabelAddressSegment,
  LabelEntity,
  saveLabelDetail,
  LabelMasterModel,
} from 'components/labelMaster/LabelApiService';
import LabelDetail from 'components/labelMaster/LabelDetail';
import { ZipCodeTriggeredFrom } from 'models/location';

function CreateLabels(props: any) {
  const {
    woId,
    orderId,
    isWarehouse,
    isAccordian,
    handleLabelStatusSave,
    handleErrorStatusSave,
    permission,
    workOrderNo = DnPWorkOrders.WOIBO,
    entityType = EntityTypeEnum.DecPackOrders,
  } = props;
  const [canEdit, setCanEdit] = useState(hasPermission(permission));

  const defaultValue = {
    value: '0',
    label: I18n('Placeholders.SelectLayoutType'),
  } as BaseModel;
  const defaultConsigneeValue = {
    value: '0',
    label: I18n('Placeholders.SelectConsignee'),
  } as BaseModel;

  const defaultShipperValue = {
    value: '0',
    label: I18n('Placeholders.SelectShipper'),
  } as BaseModel;

  const defaultPsnValue = {
    value: '0',
    label: I18n('DecPackOrders.CheckDocumentation.SelectPSN'),
  } as BaseModel;

  const defaultDropDownValue = {
    value: '0',
    label: I18n('Placeholders.Select'),
  } as BaseModel;

  const i18nMessages = {
    commonFieldIsRequired: I18n('Common.FieldIsRequired'),
    createLabels: I18n('DecPackOrders.CheckDocumentation.CreateLabels'),
    layoutType: I18n('DecPackOrders.CheckDocumentation.LayoutType'),
    consignee: I18n('DecPackOrders.CheckDocumentation.Consignee'),
    shipper: I18n('DecPackOrders.CheckDocumentation.Shipper'),
    unNumber: I18n('DecPackOrders.CheckDocumentation.UnNumber'),
    technicalName: I18n('DecPackOrders.CheckDocumentation.TechnicalName'),
    netQuantity: I18n('DecPackOrders.CheckDocumentation.NetQuantity'),
    overPackId: I18n('DecPackOrders.CheckDocumentation.OverPackId'),
    totalQuantity: I18n('DecPackOrders.CheckDocumentation.TotalQuantity'),
    otherLabelFreeText: I18n(
      'DecPackOrders.CheckDocumentation.OtherLabelFreeText'
    ),
    addSuccess: I18n('DecPackOrders.CheckDocumentation.AddSuccess'),
    somethingWentWrong: I18n(
      'DecPackOrders.CheckDocumentation.SomethingWentWrong'
    ),
    updateSuccess: I18n('DecPackOrders.CheckDocumentation.UpdateSuccess'),
    myHazGoRef: I18n('DecPackOrders.CheckDocumentation.MyHazGoRef'),
    customerRef: I18n('DecPackOrders.CheckDocumentation.CustomerRef'),
    numberOfPieces: I18n('DecPackOrders.CheckDocumentation.NumberOfPieces'),
    extractDGPlus: I18n('DecPackOrders.WorkOrder.ExtractDGPlus'),
    addPartner: I18n('Partner.AddPartner'),
    addCustomer: I18n('Customer.AddCustomer'),
    PSN: I18n('DecPackOrders.CheckDocumentation.PSN'),
    SelectPSN: I18n('DecPackOrders.CheckDocumentation.SelectPSN'),
    DecPackCustomerRef: I18n('DecPack.CustomerReference'),
    DecPackConsignee: I18n('DecPack.Consignee'),
    DecPackCustomer: I18n('DecPack.Customer'),
    FinalDestination: I18n('DecPack.FinalDestination'),

    AddCity: I18n('Cities.AddDialogTitle'),
    CommonSaveSuccessMsg: I18n('Common.SavedSuccessfully'),
    CommonErrOccuredMsg: I18n('Common.ErrorOccurred'),
    Save: I18n('Common.Save'),
    Cancel: I18n('Common.Cancel'),
    Update: I18n('Common.Update'),
    SearchAddress: I18n('Customer.SearchAddress'),
    CityCommonSaveSuccessMsg: I18n('Common.CitySavedSuccessfully'),
    LabelSaveSuccess: I18n('LabelMaster.LabelSaveSuccess'),
    LabelUpdateSuccess: I18n('LabelMaster.LabelUpdateSuccess'),
    City: I18n('LabelMaster.City'),
    Country: I18n('LabelMaster.Country'),
    Consignee: I18n('LabelMaster.Consignee'),
    Address2: I18n('LabelMaster.Address2'),
    PostalCode: I18n('LabelMaster.PostalCode'),
    Address: I18n('LabelMaster.Address'),
    Shipper: I18n('LabelMaster.Shipper'),
    ShipperDetailHeader: I18n('LabelMaster.ShipperDetail'),
    ConsigneeDetailHeader: I18n('LabelMaster.ConsigneeDetail'),
    OtherDetailHeader: I18n('LabelMaster.OtherDetail'),
    EmergencyPhoneNumber: I18n('LabelMaster.EmergencyPhoneNumber'),
    ShipperRequired: I18n('LabelMaster.Validation.ShipperRequired'),
    ShipperMaxLength: I18n('LabelMaster.Validation.ShipperMaxLength'),
    CountryRequired: I18n('LabelMaster.Validation.CountryRequired'),
    CityRequired: I18n('LabelMaster.Validation.CityRequired'),
    PostalCodeRequired: I18n('LabelMaster.Validation.PostalCodeRequired'),
    PostalCodeMaxLength: I18n('LabelMaster.Validation.PostalCodeMaxLength'),
    AddressRequired: I18n('LabelMaster.Validation.AddressRequired'),
    AddressMaxLength: I18n('LabelMaster.Validation.AddressMaxLength'),
    ConsigneeRequired: I18n('LabelMaster.Validation.ConsigneeRequired'),
    ConsigneeMaxLength: I18n('LabelMaster.Validation.ConsigneeMaxLength'),
  };

  let entityCreateLabelOptions: Array<BaseModel> = [];
  const getCreateLabelOptions = () => {
    EntityCreateLabelOptionDescription.forEach((value: string, key: number) => {
      if (!isWarehouse && key !== CreateLabelOption.WarehouseLabel) {
        entityCreateLabelOptions.push({
          value: key.toString(),
          label: I18n(value),
        } as BaseModel);
      }
    });
    if (isWarehouse) {
      entityCreateLabelOptions.push({
        value: CreateLabelOption.WarehouseLabel.toString(),
        label: I18n(
          EntityCreateLabelOptionDescription.get(
            CreateLabelOption.WarehouseLabel
          )
        ),
      } as BaseModel);
    }
  };
  getCreateLabelOptions();

  let entityCreateLabelLayouts: Array<BaseModel> = [];
  const getCreateLabelLayouts = () => {
    entityCreateLabelLayouts = [defaultValue];
    EntityCreateLabelLayoutDescription.forEach((value: string, key: number) => {
      entityCreateLabelLayouts.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getCreateLabelLayouts();

  const getShippingNames = async () => {
    let resp = [];
    var unNumber = localStorage.getItem('unNumber');
    if (values.unNumber) {
      let respPsnApi = await getDGPlusData(values.unNumber);
      if (respPsnApi && respPsnApi.result == true) {
        resp = respPsnApi.data;
      }
    } else {
      if (unNumber) {
        let respPsnApi = await getDGPlusData(unNumber);
        if (respPsnApi && respPsnApi.result == true) {
          resp = respPsnApi.data;
        }
        localStorage.removeItem('unNumber');
      }
    }
    return resp;
  };

  const onBlurZipCode = async (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    triggerFrom: ZipCodeTriggeredFrom
  ) => {
    const apiUrl = new URL(
      environment.api.baseUrl +
        'Locations/GetLocationByZipCode/' +
        String(event.target.value)
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result) {
        if (result.data) {
          const city: BaseModel = {
            label: String(result.data.city),
            value: String(result.data.cityId),
          };
          const country: BaseModel = {
            label: String(result.data.country),
            value: String(result.data.countryId),
          };
          if (triggerFrom == ZipCodeTriggeredFrom.Shipper) {
            setValues({
              ...values,
              shipperPostalCode: event.target.value,
              shipperCity: city,
              shipperCountry: country,
            });
          } else {
            setValues({
              ...values,
              consigneePostalCode: event.target.value,
              consigneeCity: city,
              consigneeCountry: country,
            });
          }
        }
      } else {
        toast.error(I18n('Common.ErrorOccurred'));
      }
    } catch (error: any) {
      if (triggerFrom == ZipCodeTriggeredFrom.Shipper) {
        setValues({
          ...values,
          shipperCity: defaultDropDownValue,
          shipperCountry: defaultDropDownValue,
        });
      } else {
        setValues({
          ...values,
          consigneeCity: defaultDropDownValue,
          consigneeCountry: defaultDropDownValue,
        });
      }
    }
  };

  let psnOptionsList: Array<BaseModel> = [];
  const getPsnOptionsList = async () => {
    psnOptionsList = [defaultPsnValue];

    let psnList = await getShippingNames();
    if (psnList.length > 0) {
      psnList.forEach((value: any, key: number) => {
        psnOptionsList.push({
          value: value.id.toString(),
          label: value.name,
        } as BaseModel);
      });
    }
    setPsnOptions(psnOptionsList);
  };

  const [isCreateLabelEdit, setIsCreateLabelEdit] = useState<boolean>(false);
  const [expandedCreateLabel, setCreateLabelExpanded] = useState<boolean>(true);
  const [createLabelsId, setCreateLabelsId] = useState<number>();
  const [isNew, setIsNew] = useState<boolean>(true);

  const [showCreateLabelOptions, setShowCreateLabelOptions] =
    useState<boolean>(false);

  const [psnOptions, setPsnOptions] = useState<any>();

  const [errors, setErrors] = useState({
    layoutType: '',
    shipper: '',
    consignee: '',
    unNumber: '',
    technicalName: '',
    netQuantity: '',
    otherLabelText: '',
    myHazGoRef: '',
    customerRef: '',
    address: '',
    labelShipper: '',
    shipperAddress: '',
    shipperAddress2: '',
    shipperCountry: '',
    shipperCity: '',
    shipperPostalCode: '',
    shipperStreet: '',
    shipperEmergencyPhoneNumber: '',
    labelConsignee: '',
    consigneeAddress: '',
    consigneeAddress2: '',
    consigneeCountry: '',
    consigneeCity: '',
    consigneePostalCode: '',
    consigneeStreet: '',
    consigneeEmergencyPhoneNumber: '',
  });

  const [values, setValues] = useState<any>({
    id: 0,
    decPackInitialOrderId: orderId,
    decPackWorkOrdersId: woId,
    labelType: '',
    layoutType: '',
    shipper: '',
    shipperData: '',
    customer: '',
    customerData: '',
    finalDestinationName: '',
    unNumber: '',
    technicalName: '',
    netQuantity: '',
    overPackId: '',
    totalQuantity: '',
    otherLabelText: '',
    myHazGoRef: '',
    customerRef: '',
    numberOfPieces: '',
    psn: {
      value: '0',
      label: I18n('DecPackOrders.CheckDocumentation.SelectPSN'),
    } as BaseModel,
    address: '',
    shipperId: 0,
    labelShipperId: 0,
    labelShipper: defaultShipperValue,
    labelConsigneeId: 0,
    labelConsignee: defaultConsigneeValue,
    shipperAddress: '',
    shipperAddress2: '',
    shipperCountry: defaultDropDownValue,
    shipperCity: defaultDropDownValue,
    shipperCityId: '',
    shipperCountryId: '',
    shipperPostalCode: '',
    shipperStreet: '',
    shipperEmergencyPhoneNumber: '',
    consigneeId: 0,
    consigneeAddress: '',
    consigneeAddress2: '',
    consigneeCountry: defaultDropDownValue,
    consigneeCity: defaultDropDownValue,
    consigneeCityId: '',
    consigneeCountryId: '',
    consigneePostalCode: '',
    consigneeStreet: '',
    consigneeEmergencyPhoneNumber: '',
  });

  const [cacheConsigneeUniq, setCacheConsigneeUniq] = useState(0);
  const [addLabelModalOpen, setAddLabelModalOpen] = useState({
    isAddConsigneeModalOpen: false,
    isAddShipperModalOpen: false,
    newConsigneeName: '',
    newShipperName: '',
  });
  const defaultAdditional: any = {
    page: 1,
  };
  const [decPackOrderDetails, setDecPackOrderDetails] = useState<any>({});
  const [showFinalDestination, setShowFinalDestination] = useState(false);

  const validate = (fieldValues: any = values) => {
    let temp = { ...errors };

    if ('layoutType' in fieldValues) {
      if (fieldValues.layoutType.value === '0') {
        temp.layoutType = i18nMessages.commonFieldIsRequired;
      } else {
        temp.layoutType = fieldValues.layoutType
          ? ''
          : i18nMessages.commonFieldIsRequired;
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === '');
    }
  };

  const validateShipperLabel = (fieldValues: any = values) => {
    let temp: any = {};
    let isValid = true;

    if (values.shipperCountry.value === defaultDropDownValue.value) {
      temp.shipperCountry = i18nMessages.CountryRequired;
      isValid = false;
    }
    if (values.shipperCity.value === defaultDropDownValue.value) {
      temp.shipperCity = i18nMessages.CityRequired;
      isValid = false;
    }

    if (!values.shipperPostalCode) {
      temp.shipperPostalCode = i18nMessages.PostalCodeRequired;
      isValid = false;
    } else if (values.shipperPostalCode.length > 20) {
      temp.shipperPostalCode = i18nMessages.PostalCodeMaxLength;
    }

    if (!values.shipperStreet) {
      temp.shipperStreet = i18nMessages.AddressRequired;
      isValid = false;
    } else if (values.shipperStreet.length > 250) {
      temp.shipperStreet = i18nMessages.AddressMaxLength;
    }

    if (
      !values.labelShipper ||
      values.labelShipper?.value == defaultShipperValue.value
    ) {
      temp.labelShipper = i18nMessages.ShipperRequired;
      isValid = false;
    }

    setErrors({
      ...temp,
    });

    if (isValid) {
      return true;
    } else {
      return false;
    }
  };

  const validateConsigneeLabel = (fieldValues: any = values) => {
    let temp: any = {};
    let isValid = true;

    if (values.consigneeCountry.value === defaultDropDownValue.value) {
      temp.consigneeCountry = i18nMessages.CountryRequired;
      isValid = false;
    }
    if (values.consigneeCity.value === defaultDropDownValue.value) {
      temp.consigneeCity = i18nMessages.CityRequired;
      isValid = false;
    }

    if (!values.consigneePostalCode) {
      temp.consigneePostalCode = i18nMessages.PostalCodeRequired;
      isValid = false;
    } else if (values.consigneePostalCode.length > 20) {
      temp.consigneePostalCode = i18nMessages.PostalCodeMaxLength;
    }

    if (!values.consigneeStreet) {
      temp.consigneeStreet = i18nMessages.AddressRequired;
      isValid = false;
    } else if (values.consigneeStreet.length > 250) {
      temp.consigneeStreet = i18nMessages.AddressMaxLength;
    }

    if (
      !values.labelConsignee ||
      values.labelConsignee?.value == defaultConsigneeValue.value
    ) {
      temp.labelConsignee = i18nMessages.ConsigneeRequired;
      isValid = false;
    }

    setErrors({
      ...temp,
    });

    if (isValid) {
      return true;
    } else {
      return false;
    }
  };

  const handleCreateLabelEditClick = (event: React.SyntheticEvent) => {
    setIsCreateLabelEdit(!isCreateLabelEdit);
  };

  const handleCreateLabelExpandCollapseClick = (
    event: React.SyntheticEvent
  ) => {
    setCreateLabelExpanded(!expandedCreateLabel);
  };

  const handleCreateLabelClick = async () => {
    setShowCreateLabelOptions(!showCreateLabelOptions);
  };

  const handleListItemClick = (item: any) => {
    setShowCreateLabelOptions(false);
    if (item.value == CreateLabelOption.Shipping) {
      setValues({
        ...values,
        labelType: { value: 1, label: 'Shipping Label' },
        layoutType: '',
      });
    } else if (item.value == CreateLabelOption.Overpack) {
      setValues({
        ...values,
        labelType: { value: 2, label: 'Overpack Label' },
        layoutType: { value: 2, label: 'ZEBRA' },
      });
    } else if (item.value == CreateLabelOption.Other) {
      setValues({
        ...values,
        labelType: { value: 3, label: 'Other' },
        layoutType: { value: 1, label: 'DYMO' },
      });
    } else if (item.value == CreateLabelOption.WarehouseLabel) {
      setValues({
        ...values,
        labelType:
          entityCreateLabelOptions &&
          entityCreateLabelOptions.some(
            (x) => +x.value === CreateLabelOption.WarehouseLabel
          )
            ? entityCreateLabelOptions.find(
                (x) => +x.value === CreateLabelOption.WarehouseLabel
              )
            : ({ value: '', label: '' } as BaseModel),
        layoutType:
          entityCreateLabelLayouts &&
          entityCreateLabelLayouts.some(
            (x) => +x.value === CreateLabelLayout.Zebra
          )
            ? entityCreateLabelLayouts.find(
                (x) => +x.value === CreateLabelLayout.Zebra
              )
            : defaultValue,
      });
    }
  };

  const onLayoutTypeChange = (data: any) => {
    setValues({
      ...values,
      layoutType: { value: Number(data?.value), label: data.label },
    });
  };

  const onPsnValueChange = (data: any) => {
    setValues({
      ...values,
      psn: { value: Number(data?.value), label: data.label },
    });
  };

  const handleCustomerChange = async (data: any) => {
    getCustomerDetail(data.value);
  };

  const getCustomerDetail = async (id: number) => {
    const apiUrl = new URL(environment.api.baseUrl + 'Customer/' + id);
    try {
      const result: any = await http.get(apiUrl.toString());
      if (result) {
        return result.data;
      }
    } catch (error: any) {}
  };

  const handlePrintClick = async () => {
    var content = '';
    if (values.labelType !== '' && values.layoutType !== '') {
      if (
        values.labelType.value == CreateLabelOption.Shipping &&
        values.layoutType.value == CreateLabelLayout.Dymo
      ) {
        content = createShipperDymoContent();
      }
      if (
        values.labelType.value == CreateLabelOption.Shipping &&
        values.layoutType.value == CreateLabelLayout.Zebra
      ) {
        content = createShipperZebraContent();
      }
      if (
        values.labelType.value == CreateLabelOption.WarehouseLabel &&
        values.layoutType.value == CreateLabelLayout.Zebra
      ) {
        content = createWarehouseZebraContent();
      }
      if (
        values.labelType.value == CreateLabelOption.Overpack &&
        values.layoutType.value == CreateLabelLayout.Zebra
      ) {
        content = createOverpackZebraContent();
      }
      if (
        values.labelType.value == CreateLabelOption.Other &&
        values.layoutType.value == CreateLabelLayout.Dymo
      ) {
        content = createOtherDymoContent();
      }
    }

    var printer = document.getElementById('printMe') as HTMLIFrameElement;
    if (content !== null) {
      printer.contentWindow?.document.open();
      printer.contentWindow?.document.write(content);
      printer.contentWindow?.document.close();
      printer.contentWindow?.focus();
      printer.contentWindow?.print();
    }
  };

  const updateShipperLabelDetail = async () => {
    if (validateShipperLabel()) {
      var label = {
        id: Number(values.labelShipper.value),
        emergencyPhoneNumber: values.shipperEmergencyPhoneNumber,
        address2: values.shipperAddress2
          ? String(values.shipperAddress2).trim()
          : '',
        street: values.shipperStreet ? String(values.shipperStreet).trim() : '',
        postalCode: values.shipperPostalCode,
        cityId: Number(values.shipperCity.value),
        countryId: Number(values.shipperCountry.value),
        updateLocation: true,
      } as LabelMasterModel;

      saveLabelDetail(label).then((response) => {
        if (response.isSuccess) {
          if (response.responseType === ResponseType.AddSuccess) {
            toast.success(i18nMessages.LabelSaveSuccess);
          }
          if (response.responseType === ResponseType.UpdateSuccess) {
            toast.success(i18nMessages.LabelUpdateSuccess);
          }
        } else {
          toast.success(i18nMessages.CommonErrOccuredMsg);
        }
      });
    }
  };

  const updateConsigneeLabelDetail = async () => {
    if (validateConsigneeLabel()) {
      var label = {
        id: Number(values.labelConsignee.value),
        emergencyPhoneNumber: values.consigneeEmergencyPhoneNumber,
        address2: values.consigneeAddress2
          ? String(values.consigneeAddress2).trim()
          : '',
        street: values.consigneeStreet
          ? String(values.consigneeStreet).trim()
          : '',
        postalCode: values.consigneePostalCode,
        cityId: Number(values.consigneeCity.value),
        countryId: Number(values.consigneeCountry.value),
        updateLocation: true,
      } as LabelMasterModel;

      saveLabelDetail(label).then((response) => {
        if (response.isSuccess) {
          if (response.responseType === ResponseType.AddSuccess) {
            toast.success(i18nMessages.LabelSaveSuccess);
          }
          if (response.responseType === ResponseType.UpdateSuccess) {
            toast.success(i18nMessages.LabelUpdateSuccess);
          }
        } else {
          toast.success(i18nMessages.CommonErrOccuredMsg);
        }
      });
    }
  };

  const handleSaveClick = async () => {
    if (validate()) {
      const finalValue = {
        id: createLabelsId,
        decPackWorkOrdersId: Number(woId),
        decPackInitialOrderId: Number(orderId),
        labelTypeId: values.labelType.value,
        layoutTypeId: values.layoutType.value,
        shipperId: values.shipper?.value != '0' ? values.shipper.value : null,
        consigneeId:
          values.customer?.value != '0' ? values.customer.value : null,
        shipper: values.shipper ? values.shipper : '',
        unNumber: values.unNumber !== '' ? +values.unNumber : null,
        technicalName: values.technicalName,
        netQuantity: values.netQuantity !== '' ? values.netQuantity : null,
        overPackId: values.overPackId !== '' ? +values.overPackId : null,
        totalQuantity:
          values.totalQuantity !== '' ? values.totalQuantity : null,
        otherLabelText: values.otherLabelText,
        myHazGoRef: values.myHazGoRef,
        customerRef: values.customerRef,
        noOfPieces: values.numberOfPieces ? Number(values.numberOfPieces) : 0,
        psnNumber: values.psn.value,
        address: values.address,
        labelShipperId:
          values.labelShipper?.value != defaultShipperValue.value
            ? values.labelShipper?.value
            : null,
        labelConsigneeId:
          values.labelConsignee?.value != defaultConsigneeValue.value
            ? values.labelConsignee?.value
            : null,
        shipperAddress2: values.shipperAddress2,
        shipperStreet: values.shipperStreet,
        shipperPostalCode: values.shipperPostalCode,
        shipperCityId:
          values.shipperCity?.value != defaultValue.value
            ? values.shipperCity?.value
            : null,
        shipperCountryId:
          values.shipperCountry?.value != defaultValue.value
            ? values.shipperCountry?.value
            : null,
        shipperEmergencyPhoneNumber: values.shipperEmergencyPhoneNumber,
        consigneeAddress2: values.consigneeAddress2,
        consigneeStreet: values.consigneeStreet,
        consigneePostalCode: values.consigneePostalCode,
        consigneeCityId:
          values.consigneeCity?.value != defaultValue.value
            ? values.consigneeCity?.value
            : null,
        consigneeCountryId:
          values.consigneeCountry?.value != defaultValue.value
            ? values.consigneeCountry?.value
            : null,
        consigneeEmergencyPhoneNumber: values.consigneeEmergencyPhoneNumber,
      };

      if (isNew) {
        const apiUrl = new URL(environment.api.baseUrl + 'CreateLabels');
        try {
          const result: any = await http.post(apiUrl.toString(), finalValue);
          if (result) {
            //toast.success(i18nMessages.addSuccess);
            setIsCreateLabelEdit(false);
            //getCreateLabels();
          }
        } catch (error: any) {
          toast.error(i18nMessages.somethingWentWrong);
        }
      } else {
        const apiUrl = new URL(
          environment.api.baseUrl + 'CreateLabels/' + createLabelsId
        );
        try {
          const result: any = await http.put(apiUrl.toString(), finalValue);
          if (result) {
            //toast.success(i18nMessages.updateSuccess);
            setIsCreateLabelEdit(false);
            //getCreateLabels();
          }
        } catch (error: any) {
          toast.error(i18nMessages.somethingWentWrong);
        }
      }

      // Update "Number Of pieces" in DecPackOrder API
      const apiUrl = new URL(
        environment.api.baseUrl + `DecPackOrder/${orderId}`
      );
      try {
        let decPackParamObj = decPackOrderDetails;
        decPackParamObj.piecesNo = String(values.numberOfPieces);
        const result: any = await http.put(apiUrl.toString(), decPackParamObj);
      } catch (error: any) {
        toast.error(i18nMessages.somethingWentWrong);
      }
    }
  };

  const getCreateLabels = async () => {
    let decPackDetails = await getDecPackOrderDetails();
    setDecPackOrderDetails(decPackDetails);
    setShowFinalDestination(
      decPackDetails.serviceType &&
        (decPackDetails.serviceType == TypeOfService.DGCheck ||
          decPackDetails.serviceType == TypeOfService.DGDCreation)
    );
    let objCustomerDetails = await getCustomerDetail(decPackDetails.customerId);

    const apiUrl = new URL(
      environment.api.baseUrl + 'CreateLabels/' + woId + '/' + orderId
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result && result.data) {
        setIsNew(false);
        setIsCreateLabelEdit(false);
        setCreateLabelsId(result.data.id);

        result.data.labelTypeId = result.data.labelTypeId - 1;
        let labelData = entityCreateLabelOptions[result.data.labelTypeId];
        const layoutData = entityCreateLabelLayouts[result.data.layoutTypeId];

        if (result.data.unNumber !== null) {
          localStorage.setItem('unNumber', result.data.unNumber);
          getPsnOptionsList();
        }
        setValues({
          ...values,
          decPackWorkOrdersId: result.data.decPackWorkOrdersId,
          decPackInitialOrderId: result.data.decPackInitialOrderId,
          labelType: labelData,
          layoutType: layoutData,
          shipper: decPackDetails?.shipper ?? '',
          address: result.data.address,
          customer: decPackDetails?.customerId
            ? {
                value: decPackDetails?.customerId,
                label: decPackDetails?.customerName,
              }
            : defaultConsigneeValue,
          customerData: objCustomerDetails,
          finalDestinationName: decPackDetails?.finalDestinationName,
          unNumber: result.data.unNumber,
          technicalName: result.data.technicalName,
          netQuantity: result.data.netQuantity,
          overPackId: result.data.overPackId,
          totalQuantity: result.data.totalQuantity,
          otherLabelText: result.data.otherLabelText,
          psn: {
            value: result.data.psnNumber,
            label: result.data.psnNumberValue,
          },
          myHazGoRef: decPackDetails?.hazgoReference ?? '',
          customerRef: decPackDetails?.customerReference ?? '',
          numberOfPieces: decPackDetails?.piecesNo ?? 0,
          labelConsignee: result.data?.labelConsigneeId
            ? {
                value: result.data?.labelConsigneeId,
                label: result.data?.labelConsigneeName,
              }
            : defaultConsigneeValue,
          labelShipper: result.data?.labelShipperId
            ? {
                value: result.data?.labelShipperId,
                label: result.data?.labelShipperName,
              }
            : defaultShipperValue,
          shipperAddress2: result.data?.shipperAddress2,
          shipperStreet: result.data?.shipperStreet,
          shipperPostalCode: result.data?.shipperPostalCode,
          shipperCityId: result.data?.shipperCityId,
          shipperCountryId: result.data?.shipperCountryId,
          shipperEmergencyPhoneNumber: result.data?.shipperEmergencyPhoneNumber,
          labelShipperId: result.data?.labelShipperId,
          labelConsigneeId: result.data?.labelConsigneeId,
          consigneeAddress2: result.data?.consigneeAddress2,
          consigneeStreet: result.data?.consigneeStreet,
          consigneePostalCode: result.data?.consigneePostalCode,
          consigneeCityId: result.data?.consigneeCityId,
          consigneeCountryId: result.data?.consigneeCountryId,
          consigneeEmergencyPhoneNumber:
            result.data?.consigneeEmergencyPhoneNumber,

          consigneeCity: result.data?.consigneeCityId
            ? {
                value: result.data?.consigneeCityId,
                label: result.data?.consigneeCityName,
              }
            : defaultDropDownValue,
          consigneeCountry: result.data?.consigneeCountryId
            ? {
                value: result.data?.consigneeCountryId,
                label: result.data?.consigneeCountryName,
              }
            : defaultDropDownValue,
          shipperCity: result.data?.shipperCityId
            ? {
                value: result.data?.shipperCityId,
                label: result.data?.shipperCityName,
              }
            : defaultDropDownValue,
          shipperCountry: result.data?.shipperCountryId
            ? {
                value: result.data?.shipperCountryId,
                label: result.data?.shipperCountryName,
              }
            : defaultDropDownValue,
          ...setValuesIfWarehouse(),
        });
      } else {
        setIsNew(true);
        setIsCreateLabelEdit(true);
        setValues({
          ...values,
          myHazGoRef: decPackDetails?.hazgoReference,
          shipper: decPackDetails?.shipper,
          customer:
            decPackDetails?.customerId && decPackDetails?.customerName
              ? {
                  value: decPackDetails?.customerId,
                  label: decPackDetails?.customerName,
                }
              : defaultConsigneeValue,
          customerRef: decPackDetails?.customerReference,
          numberOfPieces: decPackDetails?.piecesNo ?? 0,
          customerData: objCustomerDetails,
          finalDestinationName: decPackDetails?.finalDestinationName,
          ...setValuesIfWarehouse(),
        });
      }
    } catch (error) {}
  };

  const getDecPackOrderDetails = async () => {
    const apiUrl = new URL(environment.api.baseUrl + `DecPackOrder/${orderId}`);
    try {
      const result = await http.get(apiUrl.toString());
      if (result && result.data) {
        return result.data;
      }
    } catch (error) {
      toast.error(i18nMessages.somethingWentWrong);
    }
  };

  const setValuesIfWarehouse = () => {
    if (isWarehouse) {
      return {
        labelType:
          entityCreateLabelOptions &&
          entityCreateLabelOptions.some(
            (x) => +x.value === CreateLabelOption.WarehouseLabel
          )
            ? entityCreateLabelOptions.find(
                (x) => +x.value === CreateLabelOption.WarehouseLabel
              )
            : ({ value: '', label: '' } as BaseModel),
        layoutType:
          entityCreateLabelLayouts &&
          entityCreateLabelLayouts.some(
            (x) => +x.value === CreateLabelLayout.Zebra
          )
            ? entityCreateLabelLayouts.find(
                (x) => +x.value === CreateLabelLayout.Zebra
              )
            : defaultValue,
      };
    } else {
      return {};
    }
  };

  useEffect(() => {
    getCreateLabels();
    getDecPackOrderDetails();
  }, [isWarehouse]);

  useEffect(() => {
    if (props.isLabelSaveCallApi) {
      handleSaveClick();
    }
  }, [props.isLabelSaveCallApi]);

  useEffect(() => {
    if (props.validateLabel) {
      if (validate()) {
        handleLabelStatusSave();
      } else {
        handleErrorStatusSave();
      }
    }
  }, [props.validateLabel]);

  // Create New Label
  const onCreateConsigneeOption = async (inputValue: any) => {
    const increaseUniq = (uniq: any) => uniq + 1;
    setCacheConsigneeUniq(increaseUniq);
    setAddLabelModalOpen({
      isAddConsigneeModalOpen: true,
      newConsigneeName: inputValue,
      isAddShipperModalOpen: false,
      newShipperName: '',
    });
  };

  const onCreateShipperOption = async (inputValue: any) => {
    const increaseUniq = (uniq: any) => uniq + 1;
    setCacheConsigneeUniq(increaseUniq);
    setAddLabelModalOpen({
      isAddConsigneeModalOpen: false,
      newConsigneeName: '',
      isAddShipperModalOpen: true,
      newShipperName: inputValue,
    });
  };

  const handleCloseAddlabel = () => {
    setAddLabelModalOpen({
      isAddConsigneeModalOpen: false,
      newConsigneeName: '',
      isAddShipperModalOpen: false,
      newShipperName: '',
    });
  };

  const OnSave = async (response: any, triggerFrom: LabelEntity) => {
    if (response) {
      if (triggerFrom === LabelEntity.Consignee) {
        setValues({
          ...values,
          labelConsignee: {
            value: response.id,
            label: response.consignee,
          },
          consigneeCity: response.selectedCity,
          consigneeCountry: response.selectedCountry,
          consigneeStreet: response.street,
          consigneeAddress2: response.address2,
          consigneePostalCode: response.postalCode,
          consigneeEmergencyPhoneNumber: response.emergencyPhoneNumber,
        });
      } else {
        setValues({
          ...values,
          labelShipper: {
            value: response.id,
            label: response.shipper,
          },
          shipperCity: response.selectedCity,
          shipperCountry: response.selectedCountry,
          shipperStreet: response.street,
          shipperAddress2: response.address2,
          shipperPostalCode: response.postalCode,
          shipperEmergencyPhoneNumber: response.emergencyPhoneNumber,
        });
      }
    }
    handleCloseAddlabel();
  };

  // City Option
  const [cacheUniq, setCacheUniq] = useState(0);

  const [cityList, setCityList] = useState<BaseModel[]>([]);
  const [countryList, setCountryList] = useState<BaseModel[]>([]);

  let countryLst: Array<BaseModel> = [];
  const getCountryList = (countries: any[]) => {
    countryLst = [defaultDropDownValue];
    countries.forEach((getCountryList: any) => {
      countryLst.push({
        value: getCountryList.key,
        label: getCountryList.value,
      } as BaseModel);
    });

    return countryLst;
  };

  const getCountries = async () => {
    let countryLst: any[] = [];

    const apiUrl = new URL(environment.api.baseUrl + 'Country');
    try {
      apiUrl.searchParams.set('$orderby', 'name');
      apiUrl.searchParams.set('$skip', '0');
      apiUrl.searchParams.set('$top', '5000');
      apiUrl.searchParams.set(
        '$orderbydirection',
        OrderByDirection.Ascending.toString()
      );

      const result = await http.get(apiUrl.toString());
      if (result) {
        result.data.values.map(function (item: any) {
          countryLst.push({
            key: item['id'],
            value: item['name'],
          });
        });

        let data = getCountryList(countryLst);
        setCountryList(data);
      }
    } catch (error) {}
  };

  let cityLst: Array<BaseModel> = [];
  const getCityList = (cities: any[]) => {
    cityLst = [defaultDropDownValue];
    cities.forEach((city: any) => {
      cityLst.push({
        value: city.key,
        label: city.value,
      } as BaseModel);
    });

    return cityLst;
  };

  const loadCitiesOptions = async (
    search: string,
    loadedOptions: any,
    { page }: any
  ) => {
    const response = await getCities(search, page);
    const hasMore = Math.ceil(response.TotalRecords / 500) > page;

    return {
      options: response.Values.map((x) => {
        return {
          value: x.value.toString(),
          label: x.label,
        } as BaseModel;
      }),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const getCities = async (search: string = '', pageNo: number = 1) => {
    let cityLst: any[] = [];
    let response: PaginationResponseModel<BaseModel>;
    const apiUrl = new URL(environment.api.baseUrl + 'Cities');
    try {
      if (search && search !== undefined) {
        var searchExpression = '';
        searchExpression += search ? `name~like~${search}` : '';
        apiUrl.searchParams.set('$filter', searchExpression);
      }
      apiUrl.searchParams.set('$orderby', 'name');
      if (pageNo > 1) {
        apiUrl.searchParams.set('$skip', ((pageNo - 1) * 500).toString());
        apiUrl.searchParams.set('$top', '500');
      } else {
        apiUrl.searchParams.set('$top', '10000');
        apiUrl.searchParams.set('$skip', '0');
      }

      apiUrl.searchParams.set(
        '$orderbydirection',
        OrderByDirection.Ascending.toString()
      );

      const result = await http.get(apiUrl.toString());
      if (result) {
        result.data.values.map((item: any) => {
          cityLst.push({
            key: item['id'],
            value: item['name'],
          });
        });

        let data = getCityList(cityLst);
        setCityList(data);

        response = {
          TotalRecords: result.data.totalCount,
          Values: data,
        } as PaginationResponseModel<BaseModel>;
      } else {
        response = {
          TotalRecords: 0,
          Values: [],
        } as PaginationResponseModel<BaseModel>;
      }
    } catch (error) {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<BaseModel>;
    }
    return response;
  };

  const onCreateShipperCityOption = async (inputValue: any) => {
    const name = {
      code: '',
      name: inputValue,
      otherComments: '',
      statusId: 1,
    };
    const newOption: any = await PostCityDropDown(
      name,
      i18nMessages.CityCommonSaveSuccessMsg,
      i18nMessages.CommonErrOccuredMsg
    );
    const increaseUniq = (uniq: any) => uniq + 1;
    setCacheUniq(increaseUniq);
    onShipperCityChange(newOption);
    getCities('', 1);
  };

  const onShipperCityChange = (data: any) => {
    let cityLabel =
      data?.label === defaultDropDownValue.label ? null : data?.label;
    let cityValue =
      data?.value === defaultDropDownValue.value ? null : data?.value;
    setValues({
      ...values,
      shipperCityId: cityValue,
      shipperCity: { value: cityValue, label: cityLabel },
    });
  };

  const onCreateConsigneeCityOption = async (inputValue: any) => {
    const name = {
      code: '',
      name: inputValue,
      otherComments: '',
      statusId: 1,
    };
    const newOption: any = await PostCityDropDown(
      name,
      i18nMessages.CityCommonSaveSuccessMsg,
      i18nMessages.CommonErrOccuredMsg
    );
    const increaseUniq = (uniq: any) => uniq + 1;
    setCacheUniq(increaseUniq);
    onConsigneeCityChange(newOption);
    getCities('', 1);
  };

  const onConsigneeCityChange = (data: any) => {
    let cityLabel =
      data?.label === defaultDropDownValue.label ? null : data?.label;
    let cityValue =
      data?.value === defaultDropDownValue.value ? null : data?.value;
    setValues({
      ...values,
      consigneeCityId: cityValue,
      consigneeCity: { value: cityValue, label: cityLabel },
    });
  };

  const AddressLabelPreview = (props: any) => {
    const {
      street,
      address2,
      postalCode,
      city,
      country,
      emergencyPhoneNumber,
    } = props;
    return (
      <>
        {street ? <div>{street}</div> : ''}{' '}
        {address2 ? <div>{address2}</div> : ''}
        <div>
          {postalCode}
          {city && city.value != defaultDropDownValue.value
            ? ' ' + city.label
            : ''}
        </div>
        {country && country.value != defaultDropDownValue.value ? (
          <div>{country.label}</div>
        ) : (
          ''
        )}
        {emergencyPhoneNumber ? <div>{emergencyPhoneNumber}</div> : ''}
      </>
    );
  };

  // Create HTML Conent to Print

  const addressLabelPrint = (parms: LabelAddressSegment) => {
    const {
      street,
      address2,
      postalCode,
      city,
      country,
      emergencyPhoneNumber,
    } = parms;

    let content = '';
    content += `${street ? `<div>${street}</div>` : ''}`;
    content += `${address2 ? `<div>${address2}</div>` : ''}`;
    content += `<div>
                  ${postalCode}
                  ${
                    city && city.value != defaultDropDownValue.value
                      ? ' ' + city.label
                      : ''
                  }</div>`;
    content += `${
      country && country.value != defaultDropDownValue.value
        ? `<div>${country.label}</div>`
        : ''
    }`;
    content += `${
      emergencyPhoneNumber ? `<div>${emergencyPhoneNumber}</div>` : ''
    }`;

    return content;
  };

  // SHIPPER DYMO TEMPLATE CONTENT
  const wo4ShipperDymoContent = () => {
    let content = `<div style="font-size: 12px;font-family: 'Sofia Pro';font-weight: 900;margin: 16px;">`;
    if (
      values.labelShipper &&
      values.labelShipper.value != defaultShipperValue.value
    ) {
      content += `<div>${i18nMessages.shipper}:</div>`;
      content += `<div>${values.labelShipper.label}</div>`;
      const addressFields = {
        street: values.shipperStreet,
        address2: values.shipperAddress2,
        postalCode: values.shipperPostalCode,
        city: values.shipperCity,
        country: values.shipperCountry,
        emergencyPhoneNumber: values.shipperEmergencyPhoneNumber,
      } as LabelAddressSegment;
      content += addressLabelPrint(addressFields);
    }

    if (
      values.labelConsignee &&
      values.labelConsignee.value != defaultShipperValue.value
    ) {
      content += `<div>${i18nMessages.DecPackConsignee}:</div>`;
      content += `<div>${values.labelConsignee.label}</div>`;
      const addressFields = {
        street: values.consigneeStreet,
        address2: values.consigneeAddress2,
        postalCode: values.consigneePostalCode,
        city: values.consigneeCity,
        country: values.consigneeCountry,
        emergencyPhoneNumber: values.consigneeEmergencyPhoneNumber,
      } as LabelAddressSegment;
      content += addressLabelPrint(addressFields);
    }

    content +=
      values.unNumber && values.unNumber !== ''
        ? `<div>
        UN${values.unNumber} - 
        ${values?.psn?.value != '0' ? values?.psn?.label : ''} 
        ${values.technicalName !== '' ? '(' + values.technicalName + ')' : ''}
        -
        ${
          values.netQuantity !== '' && values.netQuantity !== undefined
            ? values.netQuantity
            : ''
        }
      </div>`
        : '';

    return content;
  };

  const woIboShipperDymoContent = () => {
    return `<div style="font-size: 12px;font-family: 'Sofia Pro';font-weight: 900;margin: 16px;">
      <div>${i18nMessages.shipper}:</div>
      <div>${values.shipper}</div>
      <div>${i18nMessages.DecPackCustomer}:</div>
      <div>${values.customerData.name}</div>
      <div>${values.customerData.telephone}</div>
      ${
        showFinalDestination
          ? '<br/>' +
            i18nMessages.FinalDestination +
            ':' +
            '<div>' +
            values.finalDestinationName +
            '</div>'
          : ''
      }
      <div>UN${values.unNumber} - ${
      values?.psn?.value != '0' ? values?.psn?.label : ''
    } ${
      values.technicalName !== '' ? '(' + values.technicalName + ')' : ''
    } - ${values.netQuantity}</div>
      </div>`;
  };

  const createShipperDymoContent = () => {
    if (workOrderNo === DnPWorkOrders.WO4) {
      return wo4ShipperDymoContent();
    } else if (workOrderNo === DnPWorkOrders.WOIBO) {
      return woIboShipperDymoContent();
    }
    return '';
  };

  // SHIPPER ZEBRA TEMPLATE CONTENT
  const wo4ShipperZebraContent = () => {
    let content = `<div style="font-size: 14px;font-family: 'Sofia Pro';font-weight: 900;margin: 16px;">`;
    if (
      values.labelShipper &&
      values.labelShipper.value != defaultShipperValue.value
    ) {
      content += `<div>${i18nMessages.shipper}:</div>`;
      content += `<div>${values.labelShipper.label}</div>`;
      const addressFields = {
        street: values.shipperStreet,
        address2: values.shipperAddress2,
        postalCode: values.shipperPostalCode,
        city: values.shipperCity,
        country: values.shipperCountry,
        emergencyPhoneNumber: values.shipperEmergencyPhoneNumber,
      } as LabelAddressSegment;
      content += addressLabelPrint(addressFields);
      content += `<br /><br />`;
    }

    if (
      values.labelConsignee &&
      values.labelConsignee.value != defaultShipperValue.value
    ) {
      content += `<div>${i18nMessages.DecPackConsignee}:</div>`;
      content += `<div>${values.labelConsignee.label}</div>`;
      const addressFields = {
        street: values.consigneeStreet,
        address2: values.consigneeAddress2,
        postalCode: values.consigneePostalCode,
        city: values.consigneeCity,
        country: values.consigneeCountry,
        emergencyPhoneNumber: values.consigneeEmergencyPhoneNumber,
      } as LabelAddressSegment;
      content += addressLabelPrint(addressFields);
    }

    content += `<div style="font-size: 66px;font-weight: 500;margin-top: 32px;">UN ${values.unNumber}</div>`;
    content += `<div>${values?.psn?.value != '0' ? values?.psn?.label : ''} ${
      values.technicalName !== '' ? '(' + values.technicalName + ')' : ''
    }</div>`;
    content += `<div>${i18nMessages.netQuantity}: ${values.netQuantity}</div>`;
    content += `</div>`;

    return content;
  };

  const woIboShipperZebraContent = () => {
    return `<div style="font-size: 14px;font-family: 'Sofia Pro';font-weight: 900;margin: 16px;">
    <div>${i18nMessages.shipper}:</div>
    <div>${values.shipper}</div>
    <br/><br/>
    <div>${i18nMessages.DecPackCustomer}:</div>
    <div>${values.customerData.name}</div>
    <div>${values.customerData.telephone}</div>
    ${
      showFinalDestination
        ? '<br/>' +
          i18nMessages.FinalDestination +
          ':' +
          '<div>' +
          values.finalDestinationName +
          '</div>'
        : ''
    }
    <div style="font-size: 66px;font-weight: 500;margin-top: 32px;">UN ${
      values.unNumber
    }</div>
    <div>${values?.psn?.value != '0' ? values?.psn?.label : ''} ${
      values.technicalName !== '' ? '(' + values.technicalName + ')' : ''
    }</div>
    <div>${i18nMessages.netQuantity}:  ${values.netQuantity}</div>
    </div>`;
  };

  const createShipperZebraContent = () => {
    if (workOrderNo === DnPWorkOrders.WO4) {
      return wo4ShipperZebraContent();
    } else if (workOrderNo === DnPWorkOrders.WOIBO) {
      return woIboShipperZebraContent();
    }
    return '';
  };

  // SHIPPER WAREHOUSE TEMPLATE CONTENT

  const wo4WarehouseZebraContent = () => {
    return `<div style="font-size: 14px;font-family: 'Sofia Pro';font-weight: 900;margin: 16px;">
    <div>myHazGo Ref: ${values.myHazGoRef}</div>
    <br/>
    <div>${i18nMessages.shipper}:</div>
    <div>${values.shipper}</div>
    <br/><br/>
    <div>${i18nMessages.DecPackCustomer}:</div>
    <div>${values.customerData.name}</div>
    <div>${values.customerData.telephone}</div>
    ${
      showFinalDestination
        ? '<br/>' +
          i18nMessages.FinalDestination +
          ':' +
          '<div>' +
          values.finalDestinationName +
          '</div>'
        : ''
    }
    <br/>
    <div>Customer Ref: ${values.customerRef}</div>
    <div>Number of Pieces: ${values.numberOfPieces}</div>
    </div>`;
  };

  const woIboWarehouseZebraContent = () => {
    return `<div style="font-size: 14px;font-family: 'Sofia Pro';font-weight: 900;margin: 16px;">
    <div>myHazGo Ref: ${values.myHazGoRef}</div>
    <br/>
    <div>${i18nMessages.shipper}:</div>
    <div>${values.shipper}</div>
    <br/><br/>
    <div>${i18nMessages.DecPackCustomer}:</div>
    <div>${values.customerData.name}</div>
    <div>${values.customerData.telephone}</div>
    ${
      showFinalDestination
        ? '<br/>' +
          i18nMessages.FinalDestination +
          ':' +
          '<div>' +
          values.finalDestinationName +
          '</div>'
        : ''
    }
    <br/>
    <div>Customer Ref: ${values.customerRef}</div>
    <div>Number of Pieces: ${values.numberOfPieces}</div>
    </div>`;
  };

  const createWarehouseZebraContent = () => {
    if (workOrderNo === DnPWorkOrders.WO4) {
      return wo4WarehouseZebraContent();
    } else if (workOrderNo === DnPWorkOrders.WOIBO) {
      return woIboWarehouseZebraContent();
    }
    return '';
  };

  const createOverpackZebraContent = () => {
    return `<div
    style="font-size: 30px;font-family: 'Sofia Pro';font-weight: 900;margin: 16px;"
    >
    <div style="font-size: 66px;font-weight: 500;margin-top: 96px;">OVERPACK</div>
    <div>#${values.overPackId}</div>
    <div>Total Quantity: ${values.totalQuantity}</div>
    </div>`;
  };

  const createOtherDymoContent = () => {
    return `<div
    style="font-size: 24px;font-family: 'Sofia Pro';font-weight: 900;margin: 16px;"
    >
    ${values.otherLabelText}
    </div>`;
  };

  // Shipping Label Preview For DecPack WO 4(Check Doc)
  const DymoShippingLabelPreview = () => {
    return values.labelType.value == CreateLabelOption.Shipping ? (
      <Box sx={{ fontSize: 12, fontWeight: 900 }} m={2}>
        {values.labelShipper &&
        values.labelShipper.value != defaultShipperValue.value ? (
          <Box>
            <div>{i18nMessages.shipper}:</div>
            <div>{values.labelShipper.label}</div>
            <AddressLabelPreview
              street={values.shipperStreet}
              address2={values.shipperAddress2}
              postalCode={values.shipperPostalCode}
              city={values.shipperCity}
              country={values.shipperCountry}
              emergencyPhoneNumber={values.shipperEmergencyPhoneNumber}
            />
          </Box>
        ) : (
          <></>
        )}

        {values.labelConsignee &&
        values.labelConsignee.value != defaultConsigneeValue.value ? (
          <Box>
            <div>{i18nMessages.DecPackConsignee}:</div>
            <div>{values.labelConsignee.label}</div>
            <AddressLabelPreview
              street={values.consigneeStreet}
              address2={values.consigneeAddress2}
              postalCode={values.consigneePostalCode}
              city={values.consigneeCity}
              country={values.consigneeCountry}
              emergencyPhoneNumber={values.consigneeEmergencyPhoneNumber}
            />
          </Box>
        ) : (
          <></>
        )}

        {values.unNumber !== '' && values.unNumber !== undefined ? (
          <Box>
            <div>
              UN{values.unNumber} -{' '}
              {values?.psn?.value != '0' ? values?.psn?.label : ''}{' '}
              {values.technicalName !== ''
                ? '(' + values.technicalName + ')'
                : ''}
              -
              {values.netQuantity !== '' && values.netQuantity !== undefined
                ? values.netQuantity
                : ''}
            </div>
          </Box>
        ) : (
          <></>
        )}
      </Box>
    ) : (
      <></>
    );
  };

  const ZebraShippingLabelPreview = () => {
    return values.labelType.value == CreateLabelOption.Shipping ? (
      <Box sx={{ fontWeight: 900 }} m={2}>
        {values.labelShipper &&
        values.labelShipper.value != defaultShipperValue.value ? (
          <Box mt={2}>
            <div>{i18nMessages.shipper}:</div>
            <div>{values.labelShipper.label}</div>
            <AddressLabelPreview
              street={values.shipperStreet}
              address2={values.shipperAddress2}
              postalCode={values.shipperPostalCode}
              city={values.shipperCity}
              country={values.shipperCountry}
              emergencyPhoneNumber={values.shipperEmergencyPhoneNumber}
            />
          </Box>
        ) : (
          <></>
        )}

        {values.labelConsignee &&
        values.labelConsignee.value != defaultConsigneeValue.value ? (
          <Box mt={3}>
            <div>{i18nMessages.DecPackConsignee}:</div>
            <div>{values.labelConsignee.label}</div>
            <AddressLabelPreview
              street={values.consigneeStreet}
              address2={values.consigneeAddress2}
              postalCode={values.consigneePostalCode}
              city={values.consigneeCity}
              country={values.consigneeCountry}
              emergencyPhoneNumber={values.consigneeEmergencyPhoneNumber}
            />
          </Box>
        ) : (
          <></>
        )}

        <Box>
          {values.unNumber !== '' ? (
            <Box sx={{ fontSize: 66, fontWeight: 500 }} mt={4}>
              UN {values.unNumber}
            </Box>
          ) : (
            ''
          )}
          {values.unNumber !== '' ? (
            <div>
              {values?.psn?.value != '0' ? values?.psn?.label : ''}{' '}
              {values.technicalName !== ''
                ? '(' + values.technicalName + ')'
                : ''}
            </div>
          ) : (
            ''
          )}
          {values.netQuantity !== '' ? (
            <div>
              {i18nMessages.netQuantity}: {values.netQuantity}
            </div>
          ) : (
            ''
          )}
        </Box>
      </Box>
    ) : (
      <></>
    );
  };

  const ShipperDetail = () => {
    return (
      <>
        <Grid item lg={10} md={10}>
          <CustomSelectPaginateAdd
            required
            key={values.labelShipper.value}
            additional={defaultAdditional}
            value={values.labelShipper}
            loadOptions={(search: string, prevOptions: any, page: any) =>
              loadShipperLabelOptions(search, prevOptions, page)
            }
            handleChange={async (newValue: any) => {
              let labelDetail = await getLabelById(Number(newValue.value));

              setValues({
                ...values,
                labelShipper: newValue,
                shipperEmergencyPhoneNumber: labelDetail?.data
                  ?.emergencyPhoneNumber
                  ? labelDetail.data.emergencyPhoneNumber
                  : '',
                shipperAddress2: labelDetail?.data?.address2
                  ? labelDetail.data.address2
                  : '',
                shipperStreet: labelDetail?.data?.street
                  ? labelDetail.data.street
                  : '',
                shipperCity: labelDetail?.data?.cityId
                  ? {
                      value: labelDetail.data.cityId,
                      label: labelDetail.data.city,
                    }
                  : defaultDropDownValue,
                shipperCountry: labelDetail?.data?.countryId
                  ? {
                      value: labelDetail.data.countryId,
                      label: labelDetail.data.country,
                    }
                  : defaultDropDownValue,
                shipperPostalCode: labelDetail?.data?.postalCode
                  ? labelDetail.data.postalCode
                  : '',
              });
            }}
            readOnly={!canEdit}
            isSearchable={true}
            placeholder={defaultShipperValue.label}
            displayLabel={i18nMessages.shipper}
            errorValue={true}
            errorMessage={errors.labelShipper}
            debounceTimeout={500}
            isMultiSelect={false}
            cacheUniqs={cacheUniq}
            onCreateOption={onCreateShipperOption}
          />
        </Grid>
        {canEdit ? (
          <Grid item lg={2} md={2}>
            <Button
              variant="contained"
              onClick={updateShipperLabelDetail}
              disabled={!canEdit}
              className="ml-2"
              sx={{ mt: 2.5 }}
            >
              {i18nMessages.Update}
            </Button>
          </Grid>
        ) : (
          <></>
        )}
        <Grid item xs={6}>
          <CustomizedPhone
            title={i18nMessages.EmergencyPhoneNumber}
            value={values.shipperEmergencyPhoneNumber}
            name="shipperEmergencyPhoneNumber"
            onChange={(phone: any) =>
              setValues({
                ...values,
                shipperEmergencyPhoneNumber: phone?.replace(/[^\d\+]/g, ''),
              })
            }
            disabled={!canEdit}
          ></CustomizedPhone>
        </Grid>
        <Grid item xs={12}>
          <CustomizedInputs
            margin="normal"
            displayLabel={i18nMessages.Address}
            id="shipperAddress"
            inputType="text"
            inputProps={{ maxLength: 500 }}
            handleChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) =>
              setValues({
                ...values,
                shipperStreet: event.target.value,
              })
            }
            value={values.shipperStreet}
            multiline={false}
            errorValue={errors.shipperStreet}
            errorMessage={errors.shipperStreet}
            required={true}
            readOnly={!canEdit}
          ></CustomizedInputs>
        </Grid>
        <Grid item xs={12}>
          <CustomizedInputs
            margin="normal"
            displayLabel={i18nMessages.Address2}
            id="shipperAddress2"
            inputType="text"
            inputProps={{ maxLength: 250 }}
            handleChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) =>
              setValues({
                ...values,
                shipperAddress2: event.target.value,
              })
            }
            value={values.shipperAddress2}
            multiline={false}
            readOnly={!canEdit}
          ></CustomizedInputs>
        </Grid>
        <Grid item xs={6}>
          <CustomizedInputs
            margin="normal"
            displayLabel={i18nMessages.PostalCode}
            id="shipperPostalCode"
            inputType="text"
            inputProps={{ maxLength: 20 }}
            handleChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) =>
              setValues({
                ...values,
                shipperPostalCode: event.target.value,
              })
            }
            handleBlur={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => onBlurZipCode(event, ZipCodeTriggeredFrom.Shipper)}
            value={values.shipperPostalCode}
            multiline={false}
            errorValue={errors.shipperPostalCode}
            errorMessage={errors.shipperPostalCode}
            required={true}
            readOnly={!canEdit}
          ></CustomizedInputs>
        </Grid>
        <Grid item xs={6}>
          <div className="add-button-address">
            <CustomSelectPaginateAdd
              additional={defaultAdditional}
              loadOptions={loadCitiesOptions}
              handleChange={(newValue: BaseModel) =>
                setValues({
                  ...values,
                  shipperCity: newValue,
                })
              }
              value={values.shipperCity}
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base: any) => ({
                  ...base,
                  zIndex: 9999,
                }),
              }}
              placeholder={defaultDropDownValue.label}
              isSearchable={true}
              displayLabel={i18nMessages.City}
              errorValue={true}
              errorMessage={errors.shipperCity}
              readOnly={!canEdit}
              debounceTimeout={500}
              isMultiSelect={false}
              cacheUniqs={cacheUniq}
              onCreateOption={(inputValue: any) =>
                onCreateShipperCityOption(inputValue)
              }
              required={true}
            ></CustomSelectPaginateAdd>
          </div>
        </Grid>
        <Grid item xs={6}>
          <CustomizedSelect
            name="shipperCountry"
            placeholder={defaultDropDownValue.label}
            options={countryList}
            isSearchable={true}
            displayLabel={i18nMessages.Country}
            handleChange={(newValue: BaseModel) =>
              setValues({
                ...values,
                shipperCountry: newValue,
              })
            }
            value={values.shipperCountry}
            errorValue={true}
            errorMessage={errors.shipperCountry}
            required={true}
            readOnly={!canEdit}
          ></CustomizedSelect>
        </Grid>
      </>
    );
  };

  const ConsigneeDetail = () => {
    return (
      <>
        <Grid item lg={10} md={10}>
          <CustomSelectPaginateAdd
            required
            key={values.labelConsignee.value}
            additional={defaultAdditional}
            value={values.labelConsignee}
            loadOptions={(search: string, prevOptions: any, page: any) =>
              loadConsigneeLabelOptions(search, prevOptions, page)
            }
            handleChange={async (newValue: any) => {
              let labelDetail = await getLabelById(Number(newValue.value));
              setValues({
                ...values,
                labelConsignee: newValue,
                consigneeEmergencyPhoneNumber: labelDetail?.data
                  ?.emergencyPhoneNumber
                  ? labelDetail.data.emergencyPhoneNumber
                  : '',
                consigneeAddress2: labelDetail?.data?.address2
                  ? labelDetail.data.address2
                  : '',
                consigneeStreet: labelDetail?.data?.street
                  ? labelDetail.data.street
                  : '',
                consigneeCity: labelDetail?.data?.cityId
                  ? {
                      value: labelDetail.data.cityId,
                      label: labelDetail.data.city,
                    }
                  : defaultDropDownValue,
                consigneeCountry: labelDetail?.data?.countryId
                  ? {
                      value: labelDetail.data.countryId,
                      label: labelDetail.data.country,
                    }
                  : defaultDropDownValue,
                consigneePostalCode: labelDetail?.data?.postalCode
                  ? labelDetail.data.postalCode
                  : '',
              });
            }}
            placeholder={defaultConsigneeValue.label}
            displayLabel={i18nMessages.DecPackConsignee}
            isSearchable={true}
            errorValue={true}
            errorMessage={errors.labelConsignee}
            debounceTimeout={500}
            isMultiSelect={false}
            cacheUniqs={cacheConsigneeUniq}
            onCreateOption={onCreateConsigneeOption}
            readOnly={!canEdit}
          />
        </Grid>
        {canEdit ? (
          <Grid item lg={2} md={2}>
            <Button
              variant="contained"
              onClick={updateConsigneeLabelDetail}
              disabled={!canEdit}
              className="ml-2"
              sx={{ mt: 2.5 }}
            >
              {i18nMessages.Update}
            </Button>
          </Grid>
        ) : (
          <></>
        )}
        <Grid item xs={6}>
          <CustomizedPhone
            title={i18nMessages.EmergencyPhoneNumber}
            value={values.consigneeEmergencyPhoneNumber}
            name="consigneeEmergencyPhoneNumber"
            onChange={(phone: any) =>
              setValues({
                ...values,
                consigneeEmergencyPhoneNumber: phone?.replace(/[^\d\+]/g, ''),
              })
            }
            disabled={!canEdit}
          ></CustomizedPhone>
        </Grid>
        <Grid item xs={12}>
          <CustomizedInputs
            margin="normal"
            displayLabel={i18nMessages.Address}
            id="consigneeAddress"
            inputType="text"
            inputProps={{ maxLength: 500 }}
            handleChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) =>
              setValues({
                ...values,
                consigneeStreet: event.target.value,
              })
            }
            value={values.consigneeStreet}
            multiline={false}
            errorValue={errors.consigneeStreet}
            errorMessage={errors.consigneeStreet}
            required={true}
            readOnly={!canEdit}
          ></CustomizedInputs>
        </Grid>
        <Grid item xs={12}>
          <CustomizedInputs
            margin="normal"
            displayLabel={i18nMessages.Address2}
            id="consigneeAddress2"
            inputType="text"
            inputProps={{ maxLength: 250 }}
            handleChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) =>
              setValues({
                ...values,
                consigneeAddress2: event.target.value,
              })
            }
            value={values.consigneeAddress2}
            multiline={false}
            readOnly={!canEdit}
          ></CustomizedInputs>
        </Grid>
        <Grid item xs={6}>
          <CustomizedInputs
            margin="normal"
            displayLabel={i18nMessages.PostalCode}
            id="consigneePostalCode"
            inputType="text"
            inputProps={{ maxLength: 20 }}
            handleChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) =>
              setValues({
                ...values,
                consigneePostalCode: event.target.value,
              })
            }
            handleBlur={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => onBlurZipCode(event, ZipCodeTriggeredFrom.Consignee)}
            value={values.consigneePostalCode}
            multiline={false}
            errorValue={errors.consigneePostalCode}
            errorMessage={errors.consigneePostalCode}
            required={true}
            readOnly={!canEdit}
          ></CustomizedInputs>
        </Grid>
        <Grid item xs={6}>
          <div className="add-button-address">
            <CustomSelectPaginateAdd
              additional={defaultAdditional}
              loadOptions={loadCitiesOptions}
              handleChange={(newValue: BaseModel) =>
                setValues({
                  ...values,
                  consigneeCity: newValue,
                })
              }
              value={values.consigneeCity}
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base: any) => ({
                  ...base,
                  zIndex: 9999,
                }),
              }}
              placeholder={defaultDropDownValue.label}
              isSearchable={true}
              displayLabel={i18nMessages.City}
              errorValue={true}
              errorMessage={errors.consigneeCity}
              readOnly={!canEdit}
              debounceTimeout={500}
              isMultiSelect={false}
              cacheUniqs={cacheUniq}
              onCreateOption={(inputValue: any) =>
                onCreateConsigneeCityOption(inputValue)
              }
              required={true}
            ></CustomSelectPaginateAdd>
          </div>
        </Grid>
        <Grid item xs={6}>
          <CustomizedSelect
            name="consigneeCountry"
            placeholder={defaultDropDownValue.label}
            options={countryList}
            isSearchable={true}
            displayLabel={i18nMessages.Country}
            handleChange={(newValue: BaseModel) =>
              setValues({
                ...values,
                consigneeCountry: newValue,
              })
            }
            value={values.consigneeCountry}
            errorValue={true}
            errorMessage={errors.consigneeCountry}
            required={true}
            readOnly={!canEdit}
          ></CustomizedSelect>
        </Grid>
      </>
    );
  };

  const OtherDetail = () => {
    return (
      <>
        <Grid item lg={8} md={8}>
          <CustomizedInputs
            displayLabel={i18nMessages.unNumber}
            handleChange={(newValue: any) => {
              setValues({
                ...values,
                unNumber: newValue.target.value,
              });
            }}
            value={values.unNumber}
            errorValue={true}
            errorMessage={errors.unNumber}
            readOnly={!canEdit}
          ></CustomizedInputs>
        </Grid>
        {canEdit ? (
          <Grid item lg={4} md={4}>
            <Box sx={{ mt: 2.5 }}>
              <Button
                variant="contained"
                onClick={() => {
                  getPsnOptionsList();
                  setValues({
                    ...values,
                    psn: {
                      value: '0',
                      label: I18n('DecPackOrders.CheckDocumentation.SelectPSN'),
                    },
                  });
                }}
                disabled={!canEdit}
                className="ml-2"
              >
                {i18nMessages.extractDGPlus}
              </Button>
            </Box>
          </Grid>
        ) : (
          <></>
        )}
        <Grid item lg={12} md={12}>
          <CustomizedSelect
            placeholder={i18nMessages.SelectPSN}
            options={psnOptions}
            isSearchable={false}
            displayLabel={i18nMessages.PSN}
            value={values.psn}
            handleChange={(newValue: BaseModel) => {
              onPsnValueChange(newValue);
              if (newValue.value !== '0') {
                setErrors({
                  ...errors,
                  layoutType: '',
                });
              }
            }}
            readOnly={!canEdit}
          ></CustomizedSelect>
        </Grid>
        <Grid item lg={12} md={12}>
          <CustomizedInputs
            displayLabel={i18nMessages.technicalName}
            handleChange={(newValue: any) => {
              setValues({
                ...values,
                technicalName: newValue.target.value,
              });
            }}
            value={values.technicalName}
            errorValue={true}
            errorMessage={errors.technicalName}
            readOnly={!canEdit}
          ></CustomizedInputs>
        </Grid>
        <Grid item lg={12} md={12}>
          <CustomizedInputs
            displayLabel={i18nMessages.netQuantity}
            inputProps={{ maxLength: 10 }}
            name="netQuantity"
            handleChange={(event: any) => {
              setValues({
                ...values,
                netQuantity: event.target.value,
              });
            }}
            errorValue={true}
            value={values.netQuantity}
            readOnly={!canEdit}
          ></CustomizedInputs>
        </Grid>
      </>
    );
  };

  const CreateLabelData = () => {
    return (
      <>
        {!isWarehouse ? (
          <Grid lg={3} md={3}>
            <List>
              <ListItemButton
                sx={{
                  border: '1px solid black',
                  borderRadius: 2,
                }}
                onClick={handleCreateLabelClick}
                disabled={!canEdit}
              >
                <ListItemText
                  sx={{ textAlign: 'center' }}
                  primary={i18nMessages.createLabels}
                />
              </ListItemButton>
              <Collapse
                in={showCreateLabelOptions}
                timeout="auto"
                unmountOnExit
              >
                <List sx={{ pl: 1 }} disablePadding>
                  {entityCreateLabelOptions.map((item) => (
                    <ListItemButton
                      key={item.value}
                      onClick={() => handleListItemClick(item)}
                    >
                      <ListItemText primary={item.label} />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            </List>
          </Grid>
        ) : (
          ''
        )}
        <Grid container spacing={2}>
          <Grid item lg={6} md={6}>
            {values.labelType?.value == CreateLabelOption.Shipping ? (
              <Grid container spacing={2} className="grid-wrap two-col">
                <Grid item lg={12} md={12}>
                  <CustomizedSelect
                    placeholder={defaultValue.label}
                    options={entityCreateLabelLayouts}
                    value={values.layoutType}
                    isSearchable={false}
                    displayLabel={i18nMessages.layoutType}
                    handleChange={(newValue: BaseModel) => {
                      onLayoutTypeChange(newValue);
                      if (newValue.value !== '0') {
                        setErrors({
                          ...errors,
                          layoutType: '',
                        });
                      }
                    }}
                    errorValue={true}
                    errorMessage={errors.layoutType}
                    required
                    readOnly={!canEdit}
                  ></CustomizedSelect>
                </Grid>
                {workOrderNo === DnPWorkOrders.WO4 ? (
                  <>
                    <Grid item lg={12} md={12}>
                      <FormAccordian
                        title={i18nMessages.ShipperDetailHeader}
                        details={ShipperDetail()}
                        expanded={true}
                        addMode={true}
                      ></FormAccordian>
                    </Grid>
                    <Grid item lg={12} md={12}>
                      <FormAccordian
                        title={i18nMessages.ConsigneeDetailHeader}
                        details={ConsigneeDetail()}
                        expanded={true}
                        addMode={true}
                      ></FormAccordian>
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
                {workOrderNo === DnPWorkOrders.WOIBO ? (
                  <Grid container>
                    <Grid item lg={10} md={10}>
                      <CustomizedInputs
                        displayLabel={i18nMessages.DecPackCustomer}
                        value={values.customer.label}
                        readOnly={true}
                      ></CustomizedInputs>
                    </Grid>
                    {showFinalDestination ? (
                      <Grid container>
                        <CustomizedInputs
                          displayLabel={i18nMessages.FinalDestination}
                          value={values.finalDestinationName}
                          readOnly={true}
                        ></CustomizedInputs>
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </Grid>
                ) : (
                  <></>
                )}
                <Grid item lg={12} md={12}>
                  <FormAccordian
                    title={i18nMessages.OtherDetailHeader}
                    details={OtherDetail()}
                    expanded={true}
                    addMode={true}
                  ></FormAccordian>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
            {values.labelType.value == CreateLabelOption.WarehouseLabel ? (
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} mt={2}>
                  <CustomizedInputs
                    displayLabel={i18nMessages.myHazGoRef}
                    handleChange={(newValue: any) => {
                      setValues({
                        ...values,
                        myHazGoRef: newValue.target.value,
                      });
                    }}
                    value={values.myHazGoRef}
                    errorValue={true}
                    errorMessage={errors.myHazGoRef}
                    readOnly={true}
                  ></CustomizedInputs>
                </Grid>
                <Grid item lg={12} md={12}>
                  <CustomizedInputs
                    displayLabel={i18nMessages.shipper}
                    value={values.shipper}
                    readOnly={true}
                  ></CustomizedInputs>
                </Grid>
                <Grid item lg={12} md={12}>
                  <Grid item lg={10} md={10}>
                    <CustomizedInputs
                      displayLabel={i18nMessages.DecPackCustomer}
                      value={values.customer.label}
                      readOnly={true}
                    ></CustomizedInputs>
                  </Grid>
                </Grid>
                {showFinalDestination ? (
                  <Grid item lg={12} md={12}>
                    <CustomizedInputs
                      displayLabel={i18nMessages.FinalDestination}
                      value={values.finalDestinationName}
                      readOnly={true}
                    ></CustomizedInputs>
                  </Grid>
                ) : (
                  <></>
                )}
                <Grid item lg={12} md={12}>
                  <CustomizedInputs
                    displayLabel={i18nMessages.DecPackCustomerRef}
                    handleChange={(newValue: any) => {
                      setValues({
                        ...values,
                        customerRef: newValue.target.value,
                      });
                    }}
                    value={values.customerRef}
                    errorValue={true}
                    errorMessage={errors.customerRef}
                    readOnly={true}
                  ></CustomizedInputs>
                </Grid>
                <Grid item lg={12} md={12}>
                  <NumericInput
                    displayLabel={i18nMessages.numberOfPieces}
                    handleChange={(newValue: any) => {
                      setValues({
                        ...values,
                        numberOfPieces: newValue.target.value,
                      });
                    }}
                    value={values.numberOfPieces}
                    errorValue={true}
                    disabled={!canEdit}
                    allowNegative={false}
                  />
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
            {values.labelType.value == CreateLabelOption.Overpack ? (
              <Grid item lg={12} md={12}>
                <NumericInput
                  displayLabel={i18nMessages.overPackId}
                  inputProps={{ maxLength: 10 }}
                  name="overPackId"
                  handleChange={(newValue: any) => {
                    setValues({ ...values, overPackId: newValue.target.value });
                  }}
                  value={values.overPackId}
                  errorValue={true}
                  readOnly={!canEdit}
                ></NumericInput>
                <CustomizedInputs
                  displayLabel={i18nMessages.totalQuantity}
                  inputProps={{ maxLength: 20 }}
                  name="totalQuantity"
                  handleChange={(event: any) => {
                    setValues({
                      ...values,
                      totalQuantity: event.target.value,
                    });
                  }}
                  value={values.totalQuantity}
                  errorValue={true}
                  readOnly={!canEdit}
                ></CustomizedInputs>
              </Grid>
            ) : (
              ''
            )}
            {values.labelType.value == CreateLabelOption.Other ? (
              <Grid item lg={12} md={12}>
                <CustomizedInputs
                  displayLabel={i18nMessages.otherLabelFreeText}
                  handleChange={(newValue: any) => {
                    setValues({
                      ...values,
                      otherLabelText: newValue.target.value,
                    });
                  }}
                  value={values.otherLabelText}
                  errorValue={true}
                  errorMessage={errors.otherLabelText}
                  readOnly={!canEdit}
                ></CustomizedInputs>
              </Grid>
            ) : (
              ''
            )}
          </Grid>
          <Grid item lg={1} md={1} />
          <Grid item lg={4} md={4}>
            {values.layoutType.value == CreateLabelLayout.Zebra ? (
              <Card sx={{ border: 2 }}>
                <Grid>
                  {values.labelType.value == CreateLabelOption.Overpack ? (
                    <Box mr={3} ml={3} sx={{ fontWeight: 900 }}>
                      <Box sx={{ fontSize: 66, fontWeight: 500 }} mt={12}>
                        <div>OVERPACK</div>
                      </Box>
                      <Box sx={{ fontSize: 30 }} mt={4}>
                        {values.overPackId ? (
                          <div>#{values.overPackId}</div>
                        ) : (
                          ''
                        )}
                        {values.totalQuantity ? (
                          <div>
                            {i18nMessages.totalQuantity}: {values.totalQuantity}
                          </div>
                        ) : (
                          ''
                        )}
                      </Box>
                    </Box>
                  ) : (
                    ''
                  )}

                  {values.labelType.value == CreateLabelOption.WarehouseLabel &&
                  workOrderNo !== DnPWorkOrders.WO4 ? (
                    <Box sx={{ fontWeight: 900 }} m={2}>
                      {values.labelType.value ==
                        CreateLabelOption.WarehouseLabel &&
                      values.myHazGoRef !== '' ? (
                        <Box>
                          {i18nMessages.myHazGoRef}: {values.myHazGoRef}
                        </Box>
                      ) : (
                        ''
                      )}
                      {values.shipper !== '' && values.shipper !== undefined ? (
                        <Box mt={2}>
                          <div>{i18nMessages.shipper}:</div>
                          <div>{values.shipper}</div>
                        </Box>
                      ) : (
                        ''
                      )}
                      {values.customerData !== '' &&
                      values.customerData !== undefined ? (
                        <Box mt={3}>
                          <div>{i18nMessages.DecPackCustomer}:</div>
                          <div>{values.customerData.name}</div>
                          <div>{values.customerData.telephone}</div>
                          {workOrderNo === DnPWorkOrders.WOIBO &&
                          showFinalDestination ? (
                            <div>
                              <br />
                              {i18nMessages.FinalDestination}:
                              <div>{values.finalDestinationName}</div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </Box>
                      ) : (
                        ''
                      )}
                      {values.labelType.value ==
                        CreateLabelOption.WarehouseLabel &&
                      values.customerRef !== '' ? (
                        <Box mt={2}>
                          {i18nMessages.customerRef}: {values.customerRef}
                        </Box>
                      ) : (
                        ''
                      )}
                      {values.labelType.value ==
                        CreateLabelOption.WarehouseLabel &&
                      values.numberOfPieces !== '' ? (
                        <Box mt={2}>
                          {i18nMessages.numberOfPieces}: {values.numberOfPieces}
                        </Box>
                      ) : (
                        ''
                      )}
                    </Box>
                  ) : (
                    <ZebraShippingLabelPreview />
                  )}
                </Grid>
              </Card>
            ) : (
              ''
            )}

            {values.layoutType.value == CreateLabelLayout.Dymo ? (
              <Card sx={{ border: 2 }}>
                <Grid>
                  {values.labelType.value == CreateLabelOption.Other ? (
                    values.otherLabelText ? (
                      <Box sx={{ fontSize: 24, fontWeight: 900 }} m={2}>
                        <div>{values.otherLabelText}</div>
                      </Box>
                    ) : (
                      ''
                    )
                  ) : (
                    ''
                  )}
                  {values.labelType.value == CreateLabelOption.Shipping &&
                  workOrderNo !== DnPWorkOrders.WO4 ? (
                    <Box sx={{ fontSize: 12, fontWeight: 900 }} m={2}>
                      {values.shipper !== '' && values.shipper !== undefined ? (
                        <Box>
                          <div>{i18nMessages.shipper}:</div>
                          <div>{values.shipper}</div>
                        </Box>
                      ) : (
                        ''
                      )}
                      {values.customerData !== '' &&
                      values.customerData !== undefined ? (
                        <Box>
                          <div>{i18nMessages.DecPackCustomer}:</div>
                          <div>{values.customerData.name}</div>
                          <div>{values.customerData.telephone}</div>
                          {workOrderNo === DnPWorkOrders.WOIBO &&
                          showFinalDestination ? (
                            <div>
                              <br />
                              {i18nMessages.FinalDestination}:
                              <div>{values.finalDestinationName}</div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </Box>
                      ) : (
                        ''
                      )}
                      {values.unNumber !== '' &&
                      values.unNumber !== undefined ? (
                        <Box>
                          <div>
                            UN{values.unNumber} -{' '}
                            {values?.psn?.value != '0'
                              ? values?.psn?.label
                              : ''}{' '}
                            {values.technicalName !== ''
                              ? '(' + values.technicalName + ')'
                              : ''}
                            -
                            {values.netQuantity !== '' &&
                            values.netQuantity !== undefined
                              ? values.netQuantity
                              : ''}
                          </div>
                        </Box>
                      ) : (
                        ''
                      )}
                    </Box>
                  ) : (
                    <DymoShippingLabelPreview />
                  )}
                </Grid>
              </Card>
            ) : (
              ''
            )}

            <Box m={2} display="flex" justifyContent="center">
              {values.labelType !== '' && values.layoutType !== '' ? (
                <Button
                  variant="contained"
                  startIcon={<PrintIcon />}
                  onClick={handlePrintClick}
                >
                  Print
                </Button>
              ) : (
                ''
              )}
            </Box>
          </Grid>
        </Grid>
      </>
    );
  };

  useEffect(() => {
    getCountries();
    getCities();
  }, []);

  return (
    <div>
      {isAccordian ? (
        <FormAccordianSave
          title="Create Label"
          className="inner-heading"
          details={CreateLabelData()}
          expanded={expandedCreateLabel}
          isEdit={canEdit}
          handleEditClick={handleCreateLabelEditClick}
          handleExpandCollapseClick={handleCreateLabelExpandCollapseClick}
        ></FormAccordianSave>
      ) : (
        <FormAccordian
          className="inner-heading"
          details={CreateLabelData()}
          expanded={expandedCreateLabel}
          isEdit={isCreateLabelEdit}
          handleEditClick={handleCreateLabelEditClick}
          handleExpandCollapseClick={handleCreateLabelExpandCollapseClick}
          isSaveButton={isCreateLabelEdit}
          onSaveClick={handleSaveClick}
        ></FormAccordian>
      )}

      <iframe
        id="printMe"
        title="printMe"
        style={{ display: 'none', fontFamily: 'Sofia Pro' }}
      ></iframe>
      <LabelDetail
        isOpen={
          addLabelModalOpen.isAddConsigneeModalOpen ||
          addLabelModalOpen.isAddShipperModalOpen
        }
        newConsignee={addLabelModalOpen.newConsigneeName}
        newShipper={addLabelModalOpen.newShipperName}
        OnSave={OnSave}
        handleClose={handleCloseAddlabel}
        isHidden={
          addLabelModalOpen.isAddConsigneeModalOpen ||
          addLabelModalOpen.isAddShipperModalOpen
        }
        triggerFrom={
          addLabelModalOpen.isAddConsigneeModalOpen
            ? LabelEntity.Consignee
            : LabelEntity.Shipper
        }
      ></LabelDetail>
    </div>
  );
}

export default CreateLabels;
