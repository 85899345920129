import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import I18n from 'utilities/i18n';
import './ServiceCatalogue.scss';
import { useEffect, MouseEvent, useCallback, useState } from 'react';
import http from '../../utilities/httpService';
import {
  BaseModel,
  EntityStatus,
  EntityStatusDescription,
  MasterCategoryEnumType,
  OrderByDirection,
  PaginationResponseModel,
} from 'models/pagination-model';
import {
  Button,
  Fade,
  Grid,
  IconButton,
  Paper,
  Popper,
  PopperPlacementType,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faPlus,
  faFilter,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import DeleteIcon from '@mui/icons-material/Delete';
import { Customer, ServiceCatalogues } from 'models/service-catalogue';
import InputAdornment from '@mui/material/InputAdornment';
import CustomizedInputs from 'common/formControl/formControl';
import CustomDataGrid from 'common/datagrid/datagrid';
import {
  GridActionsCellItem,
  GridColumns,
  GridEventListener,
  GridPaginationInitialState,
  GridRowParams,
  GridSortingInitialState,
  GridSortModel,
} from '@mui/x-data-grid';
import environment from 'environment';
import CustomizedSelect from 'common/CustomSelect/CustomSelect';
import DecimalInput from 'common/DecimalInput/DecimalInput';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
import RouteEnum from 'models/RouteEnum';
import ConfirmationPopup from 'common/confirmationPopup/confirmationPopup';
import { toast } from 'react-toastify';
import { Permission } from 'Permissions';
import { hasPermission } from 'utilities/protectedRoute';
import CustomSelectPaginate from 'common/CustomSelect/CustomSelectPaginate';

function ServiceCatalogue() {
  let navigate = useNavigate();
  const breadcrumbs = [
    <Typography key="3" color="text.primary">
      {I18n('Nav.Configuration')}
    </Typography>,
    <Typography key="3" color="text.primary">
      {I18n('Nav.ServiceCatalogue')}
    </Typography>,
  ];
  const [gridData, setGridData] = useState({
    isLoading: true,
    sortOrder: OrderByDirection.Descending,
    sortBy: 'serviceName',
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [10, 20, 50, 100],
    pageSize: 10,
    page: 0,
    searchExpression: '',
  });
  const defaultValue = {
    value: '0',
    label: I18n('Placeholders.SelectStatus'),
  } as BaseModel;
  const defaultCategoryValue = {
    value: '',
    label: I18n('Placeholders.SelectCategory'),
  } as BaseModel;
  const [serviceCodeFilter, setServiceCodeFilter] = useState('');
  const [categoryFilter, setCategoryFilter] =
    useState<BaseModel>(defaultCategoryValue);
  const [descriptionFilter, setDescriptionFilter] = useState('');
  const [serviceNameFilter, setServiceNameFilter] = useState('');
  const [shippingStandardPriceFromFilter, setShippingStandardPriceFromFilter] =
    useState('');
  const [shippingStandardPriceToFilter, setShippingStandardPriceToFilter] =
    useState('');
  const [decPackStandardPriceFromFilter, setDecPackStandardPriceFromFilter] =
    useState('');
  const [decPackStandardPriceToFilter, setDecPackStandardPriceToFilter] =
    useState('');
  const [statusFilter, setStatusFilter] = useState(defaultValue);
  const [advanceSearchExpression, setAdvanceSearchExpression] = useState('');
  const [basicSearchExpression, setBasicSearchExpression] = useState('');
  const [isFilterEnabled, setIsFilterEnabled] = useState(false);
  const [selectedUserID, setUserId] = useState();
  const [isDeleteModalOpen, setDeleteModal] = useState(false);

  const i18nMessages = {
    deleteServiceCatalougeSuccess: I18n('ServiceCatalogues.DeleteSuccess'),
    deleteServiceCatalougeCofirmation: I18n(
      'ServiceCatalogues.DeleteConfirmation'
    ),
  };

  const deleteUserData = useCallback(
    (id: any) => () => {
      setDeleteModal(true);
      setUserId(id);
    },
    []
  );

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  const handleOkDeleteModal = () => {
    setDeleteModal(false);
    deleteServiceCatalouge(selectedUserID);
  };

  const deleteServiceCatalouge = async (id: any) => {
    updateGridData('isLoading', true);
    const apiUrl = new URL(environment.api.baseUrl + 'ServiceCatalogues/' + id);
    try {
      const result: any = await http.delete(apiUrl.toString());
      if (result) {
        toast.success(i18nMessages.deleteServiceCatalougeSuccess);
        updateGridData('isLoading', false);
        getServiceCatalogues();
      }
    } catch (error: any) {}
  };

  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));

  const getServiceCatalogues = async () => {
    updateGridData('isLoading', true);
    const apiUrl = new URL(environment.api.baseUrl + 'ServiceCatalogues');
    try {
      let finalSearchTerm = '';
      if (basicSearchExpression && advanceSearchExpression) {
        finalSearchTerm = `${basicSearchExpression} or ${advanceSearchExpression}`;
      } else if (basicSearchExpression && !advanceSearchExpression) {
        finalSearchTerm = basicSearchExpression;
      } else if (!basicSearchExpression && advanceSearchExpression) {
        finalSearchTerm = advanceSearchExpression;
      }
      apiUrl.searchParams.set('$filter', finalSearchTerm);
      apiUrl.searchParams.set('$orderby', gridData.sortBy);
      apiUrl.searchParams.set(
        '$skip',
        (gridData.page * gridData.pageSize).toString()
      );
      apiUrl.searchParams.set('$top', gridData.pageSize.toString());
      apiUrl.searchParams.set(
        '$orderbydirection',
        gridData.sortOrder.toString()
      );
      const result = await http.get(apiUrl.toString());
      if (result) {
        updateGridData('totalRows', result.data.totalCount);
        updateGridData('rows', result.data.values);
        updateGridData('isLoading', false);
      }
    } catch (error) {}
  };

  const manageFilterStatus = () => {
    setIsFilterEnabled(
      serviceCodeFilter ||
        serviceNameFilter ||
        categoryFilter ||
        descriptionFilter ||
        +shippingStandardPriceFromFilter ||
        +shippingStandardPriceToFilter ||
        +decPackStandardPriceFromFilter ||
        +decPackStandardPriceToFilter ||
        +statusFilter.value !== 0
        ? true
        : false
    );
  };

  useEffect(() => {
    getServiceCatalogues();
    manageFilterStatus();
  }, [
    gridData.page,
    gridData.pageSize,
    gridData.sortBy,
    gridData.sortOrder,
    gridData.searchExpression,
    advanceSearchExpression,
    basicSearchExpression,
  ]);

  const handleChangePage = (pageNo: number) => {
    updateGridData('page', pageNo);
  };

  const handleChangeBasicSearch = debounce(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (event.target.value) {
        setBasicSearchExpression(
          `ServiceCode~like~${event.target.value} or ServiceName~like~${event.target.value} or MasterCategory.Name~like~${event.target.value}`
        );
      } else {
        setBasicSearchExpression(``);
      }
    },
    500
  );
  const handleChangePageSize = (pageSize: number) => {
    updateGridData('pageSize', pageSize);
  };
  type Row = (typeof gridData.rows)[number];

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState<PopperPlacementType>();
  const columns: GridColumns<Row> = [
    {
      field: 'serviceCode',
      headerName: I18n('ServiceCatalogues.ServiceCode'),
      flex: 1,
    },
    {
      field: 'categoryName',
      headerName: I18n('ServiceCatalogues.Category'),
      flex: 1,
    },
    {
      field: 'serviceName',
      headerName: I18n('ServiceCatalogues.ServiceName'),
      flex: 1,
    },
    {
      field: 'description',
      headerName: I18n('ServiceCatalogues.Description'),
      minWidth: 200,
    },
    {
      field: 'shippingStandardPrice',
      headerName: I18n('ServiceCatalogues.ShippingStandardPrice'),
      flex: 1,
    },
    {
      field: 'decPackStandardPrice',
      headerName: I18n('ServiceCatalogues.DecPackStandardPrice'),
      flex: 1,
    },
    {
      field: 'statusId',
      headerName: I18n('Common.Status'),
      flex: 1,
      renderCell: (params: any) => {
        return params.formattedValue === EntityStatus.Active
          ? I18n('Common.Active')
          : I18n('Common.Inactive');
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: I18n('Common.Actions'),
      width: 100,
      getActions: (params: GridRowParams<ServiceCatalogues>) => {
        const actionButtons = [];
        actionButtons.push(
          <>
            {hasPermission(Permission.canDeleteServiceCatalogue) &&
            params.row.statusId !== EntityStatus.Active ? (
              <GridActionsCellItem
                onPointerEnterCapture={false}
                onPointerLeaveCapture={false}
                icon={<DeleteIcon />}
                placeholder={''}
                label="Delete"
                disabled={params.row.isStorage}
                onClick={deleteUserData(params.id)}
              />
            ) : (
              <></>
            )}
          </>
        );
        return actionButtons;
      },
    },
  ];

  let entityStatusList: Array<BaseModel> = [];
  const getStatusList = () => {
    entityStatusList = [defaultValue];
    EntityStatusDescription.forEach((value: string, key: number) => {
      entityStatusList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getStatusList();
  const popperHandleClick =
    (newPlacement: PopperPlacementType) =>
    (event: MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setOpen((prev) => placement !== newPlacement || !prev);
      setPlacement(newPlacement);
    };

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    if (sortModel && sortModel.length) {
      if (sortModel[0].field === 'categoryName') {
        updateGridData('sortBy', 'MasterCategory.Name');
      } else {
        updateGridData('sortBy', sortModel[0].field);
      }
      updateGridData(
        'sortOrder',
        sortModel[0].sort === 'asc'
          ? OrderByDirection.Ascending
          : OrderByDirection.Descending
      );
    }
  }, []);

  const handleOnRowClick: GridEventListener<'rowClick'> = (
    params: { id: any }, // GridRowParams
    event: any, // MuiEvent<React.MouseEvent<HTMLElement>>
    details: any // GridCallbackDetails
  ) => {
    navigate(`${RouteEnum.ViewServiceCatalogue}/${params.id}`);
  };

  const handleResetClick = () => {
    setServiceCodeFilter('');
    setServiceNameFilter('');
    setDescriptionFilter('');
    setShippingStandardPriceFromFilter('');
    setShippingStandardPriceToFilter('');
    setDecPackStandardPriceFromFilter('');
    setDecPackStandardPriceToFilter('');
    setCategoryFilter(defaultCategoryValue);
    setStatusFilter(defaultValue);
    setAdvanceSearchExpression('');
    setOpen(false);
  };
  const appendAdvanceSearchExpressions = (
    searchExpression: string,
    type: string
  ) => {
    let searchExpressionLocal: string = searchExpression;
    switch (type) {
      case 'and':
        searchExpressionLocal = searchExpressionLocal ? ` and ` : ``;
        break;
      case 'or':
        searchExpressionLocal = searchExpressionLocal ? ` or ` : ``;
        break;
      default:
        break;
    }
    return searchExpressionLocal;
  };
  const handleAdvanceSearchFilterClick = () => {
    let searchExpression = '';
    searchExpression += serviceCodeFilter
      ? `ServiceCode~like~${serviceCodeFilter}`
      : '';
    searchExpression += serviceNameFilter
      ? appendAdvanceSearchExpressions(searchExpression, 'and') +
        `ServiceName~like~${serviceNameFilter}`
      : '';
    searchExpression += descriptionFilter
      ? appendAdvanceSearchExpressions(searchExpression, 'and') +
        `Description~like~${descriptionFilter}`
      : '';
    searchExpression += categoryFilter.value
      ? appendAdvanceSearchExpressions(searchExpression, 'and') +
        `CategoryId~=~${categoryFilter.value}`
      : '';
    searchExpression += shippingStandardPriceToFilter
      ? appendAdvanceSearchExpressions(searchExpression, 'and') +
        `ShippingStandardPrice~<~${shippingStandardPriceToFilter}`
      : '';
    searchExpression += decPackStandardPriceToFilter
      ? appendAdvanceSearchExpressions(searchExpression, 'and') +
        `DecPackStandardPrice~<~${decPackStandardPriceToFilter}`
      : '';
    searchExpression += +statusFilter.value
      ? appendAdvanceSearchExpressions(searchExpression, 'and') +
        `StatusId~=~${statusFilter.value}`
      : '';
    setAdvanceSearchExpression(searchExpression);
    setOpen(false);
  };

  const addServiceCatalougeClicked = () => {
    navigate(RouteEnum.ServiceCatalogueAdd);
  };

  const getOrderCategories = async (search: string, pageNo: number) => {
    let response: PaginationResponseModel<Customer>;
    const apiUrl = new URL(
      environment.api.baseUrl + 'Dropdown/GetMasterCategories'
    );
    try {
      let searchExpression = `entityType~=~${MasterCategoryEnumType.ServiceCatalogue}`;
      searchExpression += search ? ` and name~like~${search}` : '';
      apiUrl.searchParams.set('$filter', searchExpression);
      apiUrl.searchParams.set('$orderby', 'name');
      apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
      apiUrl.searchParams.set('$top', '10');
      apiUrl.searchParams.set('$orderbydirection', 'desc');
      const result = await http.get(apiUrl.toString());
      if (result) {
        response = {
          TotalRecords: result.data.totalCount,
          Values: result.data.values,
        } as PaginationResponseModel<Customer>;
      } else {
        response = {
          TotalRecords: 0,
          Values: [],
        } as PaginationResponseModel<Customer>;
      }
    } catch (error) {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<Customer>;
    }
    return response;
  };

  const loadCategoryOptions = async (
    search: string,
    loadedOptions: any,
    { page }: any
  ) => {
    const response = await getOrderCategories(search, page);
    const hasMore = Math.ceil(response.TotalRecords / 10) > page;

    return {
      options: response.Values.map((x) => {
        return {
          value: x.id.toString(),
          label: x.name,
        } as BaseModel;
      }),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };
  const defaultAdditional: any = {
    page: 1,
  };
  return (
    <div>
      <div className="heading-section">
        <div className="heading-section-left">
          <Typography variant="h3" sx={{ mb: 3 }}>
            {I18n('Nav.ServiceCatalogue')}
          </Typography>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>
        <div className="heading-section-right">
          <div className="search-control-outer">
            <CustomizedInputs
              margin="normal"
              displayLabel=""
              required
              inputType="text"
              placeholderText="Search"
              className="search-control small-form-control"
              handleChange={handleChangeBasicSearch}
              adornmentValue={
                <InputAdornment position="start">
                  <FontAwesomeIcon icon={faSearch} />
                </InputAdornment>
              }
            ></CustomizedInputs>
          </div>
          <div className="header-btn-wrap">
            <Popper
              open={open}
              anchorEl={anchorEl}
              placement={placement}
              transition
              className="filter-popper"
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper>
                    <Typography variant="h5" className="filter-heading">
                      Filter
                      <IconButton
                        aria-label="delete"
                        onClick={popperHandleClick('bottom-end')}
                      >
                        <FontAwesomeIcon
                          icon={faTimes}
                          className="fa-sm close-icon"
                        />
                      </IconButton>
                    </Typography>
                    <div className="filter-content form-small-gap">
                      <Grid container spacing={2} className="grid-wrap two-col">
                        <Grid item xs={6}>
                          <CustomizedInputs
                            margin="normal"
                            displayLabel={I18n('ServiceCatalogues.ServiceName')}
                            id="ServiceName"
                            inputType="text"
                            inputProps={{ maxLength: 30 }}
                            handleChange={(
                              event: React.ChangeEvent<
                                HTMLTextAreaElement | HTMLInputElement
                              >
                            ) => setServiceNameFilter(event.target.value)}
                            value={serviceNameFilter}
                            multiline={false}
                          ></CustomizedInputs>
                        </Grid>
                        <Grid item xs={6}>
                          <CustomizedInputs
                            margin="normal"
                            displayLabel={I18n('ServiceCatalogues.ServiceCode')}
                            id="ServiceCode"
                            inputType="text"
                            inputProps={{ maxLength: 30 }}
                            multiline={false}
                            handleChange={(
                              event: React.ChangeEvent<
                                HTMLTextAreaElement | HTMLInputElement
                              >
                            ) => setServiceCodeFilter(event.target.value)}
                            value={serviceCodeFilter}
                          ></CustomizedInputs>
                        </Grid>
                        <Grid item xs={6}>
                          <CustomSelectPaginate
                            additional={defaultAdditional}
                            value={categoryFilter}
                            loadOptions={loadCategoryOptions}
                            handleChange={(newValue: BaseModel) =>
                              setCategoryFilter(newValue)
                            }
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base: any) => ({
                                ...base,
                                zIndex: 9999,
                              }),
                            }}
                            placeholder={defaultCategoryValue.label}
                            isSearchable={true}
                            displayLabel={I18n('ServiceCatalogues.Category')}
                            readOnly={false}
                            debounceTimeout={500}
                            isMultiSelect={false}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <CustomizedSelect
                            placeholder={defaultValue.label}
                            options={entityStatusList}
                            isSearchable={false}
                            displayLabel={I18n('Common.Status')}
                            handleChange={(newValue: BaseModel) =>
                              setStatusFilter(newValue)
                            }
                            value={statusFilter}
                          ></CustomizedSelect>
                        </Grid>
                        <Grid item xs={6}>
                          <DecimalInput
                            displayLabel={I18n(
                              'ServiceCatalogues.ShippingStandardPriceFrom'
                            )}
                            inputProps={{ maxLength: 10 }}
                            handleChange={(event: any) => {
                              setShippingStandardPriceFromFilter(
                                event.target.value
                              );
                            }}
                            value={shippingStandardPriceFromFilter}
                          ></DecimalInput>
                        </Grid>
                        <Grid item xs={6}>
                          <DecimalInput
                            displayLabel={I18n(
                              'ServiceCatalogues.ShippingStandardPriceTo'
                            )}
                            inputProps={{ maxLength: 10 }}
                            handleChange={(event: any) =>
                              setShippingStandardPriceToFilter(
                                event.target.value
                              )
                            }
                            value={shippingStandardPriceToFilter}
                          ></DecimalInput>
                        </Grid>

                        <Grid item xs={6}>
                          <DecimalInput
                            displayLabel={I18n(
                              'ServiceCatalogues.DecPackStandardPriceFrom'
                            )}
                            inputProps={{ maxLength: 10 }}
                            handleChange={(event: any) => {
                              setDecPackStandardPriceFromFilter(
                                event.target.value
                              );
                            }}
                            value={decPackStandardPriceFromFilter}
                          ></DecimalInput>
                        </Grid>
                        <Grid item xs={6}>
                          <DecimalInput
                            displayLabel={I18n(
                              'ServiceCatalogues.DecPackStandardPriceTo'
                            )}
                            inputProps={{ maxLength: 10 }}
                            handleChange={(event: any) =>
                              setDecPackStandardPriceToFilter(
                                event.target.value
                              )
                            }
                            value={decPackStandardPriceToFilter}
                          ></DecimalInput>
                        </Grid>
                        <Grid item xs={12}>
                          <CustomizedInputs
                            margin="normal"
                            displayLabel={I18n('ServiceCatalogues.Description')}
                            id="Description"
                            inputType="text"
                            inputProps={{ maxLength: 150 }}
                            multiline={true}
                            handleChange={(
                              event: React.ChangeEvent<
                                HTMLTextAreaElement | HTMLInputElement
                              >
                            ) => setDescriptionFilter(event.target.value)}
                            value={descriptionFilter}
                          ></CustomizedInputs>
                        </Grid>
                      </Grid>
                      <div className="btn-wrap">
                        <Button
                          color="neutral"
                          disableElevation
                          variant="contained"
                          onClick={handleResetClick}
                        >
                          {I18n('Common.Reset')}
                        </Button>
                        <Button
                          color="primary"
                          disableElevation
                          variant="contained"
                          onClick={handleAdvanceSearchFilterClick}
                        >
                          {I18n('Common.Apply')}
                        </Button>
                      </div>
                    </div>
                  </Paper>
                </Fade>
              )}
            </Popper>
            <Button
              color="info"
              onClick={popperHandleClick('bottom-end')}
              disableElevation
              variant="outlined"
              className={`icon-btn ${isFilterEnabled ? 'is-filtered' : ''}`}
            >
              <FontAwesomeIcon icon={faFilter} className="fa-lg" />
            </Button>

            {hasPermission(Permission.canAddServiceCatalogue) ? (
              <Button
                color="info"
                className="small-btn"
                disableElevation
                variant="contained"
                onClick={addServiceCatalougeClicked}
              >
                <FontAwesomeIcon icon={faPlus} className="fa-md mr-2" />
                Add
              </Button>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div className="main-content-section">
        <div className="content-block table-fix  ">
          <CustomDataGrid
            rowCount={gridData.totalRows}
            data={gridData.rows}
            columns={columns}
            pageNo={gridData.page}
            pageSize={gridData.pageSize}
            onSortModelChange={handleSortModelChange}
            onPageChange={handleChangePage}
            onPageSizeChange={handleChangePageSize}
            rowsPerPageOptions={gridData.rowsPerPageOptions}
            initialState={{
              sorting: {
                sortModel: [{ field: 'serviceName', sort: 'desc' }],
              } as GridSortingInitialState,
              pagination: {
                page: gridData.page,
                pageSize: gridData.pageSize,
              } as GridPaginationInitialState,
            }}
            onRowClick={handleOnRowClick}
          ></CustomDataGrid>
          <ConfirmationPopup
            isOpen={isDeleteModalOpen}
            data={selectedUserID}
            message={i18nMessages.deleteServiceCatalougeCofirmation}
            handleClose={handleCloseDeleteModal}
            handleOk={handleOkDeleteModal}
          ></ConfirmationPopup>
        </div>
      </div>
    </div>
  );
}

export default ServiceCatalogue;
