import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import I18n from 'utilities/i18n';

export default function ConfirmationPopup(props: any) {
  const {
    isOpen,
    handleClose,
    handleOk,
    message,
    title,
    hasCancel = true,
    cancelButtonText = '',
    continueButtonText = '',
  } = props;

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {hasCancel ? (
            <Button onClick={handleClose}>
              {cancelButtonText || I18n('UserList.DeleteModal.Cancel')}
            </Button>
          ) : (
            <></>
          )}
          <Button onClick={handleOk} autoFocus>
            {continueButtonText || I18n('UserList.DeleteModal.Ok')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
