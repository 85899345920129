import { useSelector } from 'react-redux';
import _ from 'lodash';
import Constants from 'i18nConstants/constants';

export const I18n = (input: any) => {
  try {
    const activeLanguage = useSelector((state: any) => state.selectedLanguage);
    let selectedFile = Constants[activeLanguage];
    return _.get(selectedFile, input);
  } catch {
    const activeLanguage = localStorage.getItem('selectedLanguage')
      ? String(localStorage.getItem('selectedLanguage'))
      : 'en';
    let selectedFile = Constants[activeLanguage];
    return _.get(selectedFile, input);
  }
  return input;
};

export default I18n;
