import { BaseModel, OrderCreatedFrom } from './pagination-model';

export interface ShippingOrdersStateModel {
  customer: BaseModel;
  customerLocation: BaseModel;
  customerAddress: AddressSegments;
  orderReference: string;
  category: BaseModel;
  subCategory: string;
  service: BaseModel;
  pickUpCustomer: BaseModel;
  pickUpCustomerLocation: BaseModel;
  pickupCustomerAddress: AddressSegments;
  expectedPickupDateTime: string | Date;
  deliveryCustomer: BaseModel;
  deliveryLocation: BaseModel;
  deliveryCustomerAddress: AddressSegments;
  expectedDeliveryDateTime: string | Date;
  type: BaseModel;
  autogeneratedReferenceNo?: string;
  autogeneratedOfferReferenceNo?: string;
  totalNoOfPieces?: string;
  totalWeight?: string;
  shipmentContent?: string;
  status: BaseModel;
  isSameAsCustomer: boolean;
  selectedContacts: any;
  deliveryContacts: any;
  pickupContacts: any;
  internalTrackingNumber: string;
  isShippingOfferConverted: boolean;
  offerStatus?: number;
}

export interface ShippingCustomsDetailsModel {
  importer: BaseModel;
  exporter: any;
  deliveryPayment: any;
}

export interface WorkOrderInboundModel {
  id: number;
  assignmentType: BaseModel;
  assignmentTo: BaseModel | string;
  instructions: string;
  actualInboundDateTime: string | Date;
  remarks: string;
  isInboundDone: boolean;
  images: string[];
  shippingWorkOrderId: number;
  createdBy: string;
  createdDate: string | Date;
  updatedBy: string;
  updatedDate: string | Date;
  mobileRemarks: string;
  waitTime: number;
}

export interface ShippingOrderDTO extends ShippingOrderBaseDTO {
  id: number;
}
export interface ShippingOrderBaseDTO {
  orderDate: string;
  offerExpiryDate: string;
  customerId: number;
  customerName: string;
  customerCurrency: string;
  customerEmail: string;
  customerLocationId: number;
  customerLocationName: string;
  customerContactId: number;
  customerContactName: string;
  shippingOrderContacts: [];
  orderReference: string;
  categoryId: number;
  categoryName: string;
  serviceId: number;
  serviceName: string;
  autogeneratedReferenceNo: string;
  totalNoOfPieces?: string;
  totalWeight?: string;
  pickUpCustomerId: number;
  pickUpCustomerName: string;
  pickUpCustomerLocationId: number;
  pickUpCustomerLocationName: string;
  pickUpContactId: number;
  pickUpContactName: string;
  expectedPickupDateTime: string;
  deliveryCustomerId: number;
  deliveryCustomerName: string;
  deliveryLocationId: number;
  deliveryLocationName: string;
  deliveryContactId: number;
  deliveryContactName: string;
  expectedDeliveryDateTime: string;
  totalWorkOrder: number;
  completedWorkOrder: number;
  status: ShippingOrderStatus;
  type: ShippingType;
  shipmentContent: string;
  workOrderInProgressCount: number;
  isSameAsCustomer: boolean;
  selectedContacts: any;
  pickupContacts: [];
  deliveryContacts: any;
  totalWorkOrdersCount: number;
  importerId: number;
  exporterId: number;
  deliveryPaymentId: number;
  internalTrackingNumber: string;
  createdFrom: OrderCreatedFrom;
  bomNotes: string;
  autogeneratedOfferReferenceNo: string;
  isOfferRejected: boolean;
  isShippingOfferConverted: boolean;
  notificationEmail: string;
  rejectionMasterName: string;
  offerStatus: number;
  subCategory: string;
}

export enum WizardSubmitType {
  Previous = 1,
  Submit = 2,
  Next = 3,
  Cancel = 4,
}
export enum ShippingWorkOrders {
  WO1 = 1,
  WO2 = 2,
  WO3 = 3,
  WO4 = 4,
  WO5 = 5,
  WO6 = 6,
  WO7 = 7,
  WO8 = 8,
  WO9 = 9,
}

export const ShippingWorkOrdersDescription = new Map<number, string>([
  [ShippingWorkOrders.WO1, 'Common.Booking'],
  [ShippingWorkOrders.WO2, 'Common.WODocumentation'],
  [ShippingWorkOrders.WO3, 'Common.WOPickup'],
  [ShippingWorkOrders.WO4, 'Common.Inbound'],
  [ShippingWorkOrders.WO5, 'Common.Packaging'],
  [ShippingWorkOrders.WO6, 'Common.Outbound'],
  [ShippingWorkOrders.WO7, 'Common.WODelivery'],
  [ShippingWorkOrders.WO8, 'Common.DocumentCheck'],
  [ShippingWorkOrders.WO9, 'Common.PhysicalCheck'],
]);
export enum ShippingOrderStatus {
  New = 1,
  InProcess = 2,
  Done = 3,
  Invoiced = 4,
  SentForApproval = 6,
  InvoiceApproved = 7,
  Closed = 8,
  Cancelled = 9,
}
export const ShippingOrderStatusDescription = new Map<number, string>([
  [ShippingOrderStatus.New, 'Common.New'],
  [ShippingOrderStatus.Cancelled, 'Common.Cancelled'],
  [ShippingOrderStatus.InProcess, 'Common.InProcess'],
  [ShippingOrderStatus.Done, 'Common.OrderCompleted'],
  [ShippingOrderStatus.Invoiced, 'Common.Invoiced'],
  [ShippingOrderStatus.SentForApproval, 'Common.SentForApproval'],
  [ShippingOrderStatus.InvoiceApproved, 'Common.InvoiceApproved'],
  [ShippingOrderStatus.Closed, 'Common.Closed'],
]);

export enum DecPackOrderStatus {
  New = 1,
  InProcess = 2,
  Done = 3,
  Invoiced = 4,
  OnHold = 5,
  SentForApproval = 6,
  InvoiceApproved = 7,
  Closed = 8,
  Cancelled = 9,
}
export const DecPackOrderStatusDescription = new Map<number, string>([
  [DecPackOrderStatus.New, 'Common.New'],
  [DecPackOrderStatus.Cancelled, 'Common.Cancelled'],
  [DecPackOrderStatus.InProcess, 'Common.InProcess'],
  [DecPackOrderStatus.Done, 'Common.OrderCompleted'],
  [DecPackOrderStatus.Invoiced, 'Common.Invoiced'],
  [DecPackOrderStatus.OnHold, 'Common.OnHold'],
  [DecPackOrderStatus.SentForApproval, 'Common.SentForApproval'],
  [DecPackOrderStatus.InvoiceApproved, 'Common.InvoiceApproved'],
  [DecPackOrderStatus.Closed, 'Common.Closed'],
]);

export enum ExecutionTime {
  Am = 1,
  Pm = 2,
  SpecificTime = 3,
  None = 4,
}
export const ExecutionTimeDescription = new Map<number, string>([
  [ExecutionTime.Am, 'DecPack.Am'],
  [ExecutionTime.Pm, 'DecPack.Pm'],
  [ExecutionTime.SpecificTime, 'DecPack.SpecificTime'],
  [ExecutionTime.None, 'DecPack.None'],
]);

export enum ShippingType {
  InOrder = 1,
  InOffer = 2,
}
export const ShippingOrderTypeDescription = new Map<number, string>([
  [ShippingType.InOrder, 'ShippingOrders.InOrder'],
  [ShippingType.InOffer, 'ShippingOrders.InOffer'],
]);

export enum OfferStatus {
  Accepted = 0,
  Rejected = 1,
  Open = 2,
  OfferSent = 3,
}
export const OfferStatusDescription = new Map<number, string>([
  [OfferStatus.Accepted, 'ShippingOrders.Accepted'],
  [OfferStatus.Rejected, 'ShippingOrders.Rejected'],
  [OfferStatus.Open, 'ShippingOrders.Open'],
  [OfferStatus.OfferSent, 'ShippingOrders.OfferSent'],
]);

export enum Validity {
  Expired = 1,
  NotExpired = 2,
}
export const ValidityDescription = new Map<number, string>([
  [Validity.Expired, 'ShippingOrders.Expired'],
  [Validity.NotExpired, 'ShippingOrders.NotExpired'],
]);

export enum DecPackServiceType {
  DGCheck = 1,
  DGDCreation = 2,
  NonDGOrder = 3,
  Shop = 4,
}

export const DecPackServiceTypeDescription = new Map<number, string>([
  [DecPackServiceType.DGCheck, 'DecPackOrders.DGCheck'],
  [DecPackServiceType.DGDCreation, 'DecPackOrders.DGDCreation'],
  [DecPackServiceType.NonDGOrder, 'DecPackOrders.NonDGOrder'],
  [DecPackServiceType.Shop, 'DecPackOrders.Shop'],
]);

export interface ShippingOrderListDTO extends ShippingOrderBaseDTO {
  id: number;
  fromTo: string;
  isGeneralCargoCheck: Boolean;
  isTemperatureControlCheck: Boolean;
  isDangerousGoodsCheck: Boolean;
  workOrderProgressCount: number;
}
export enum AssignmentType {
  Driver = 1,
  Partner = 2,
  WareHouseStaff = 3,
}
export const WorkOrderInboundAssignmentType = new Map<number, string>([
  [AssignmentType.Driver, 'WorkOrder.Driver'],
  [AssignmentType.WareHouseStaff, 'WorkOrder.WarehouseStaff'],
  [AssignmentType.Partner, 'WorkOrder.Partner'],
]);

export interface AddressSegments {
  country: string;
  city: string;
  postalCode: string;
  address: string;
  street: string;
  address2: string;
}

export interface ShippingNotificationDetailsModel {
  notificationEmail: string;
  language: string;
}
