import {
  Breadcrumbs,
  Button,
  Fade,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Popper,
  PopperPlacementType,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import {
  GridActionsCellItem,
  GridSortModel,
  GridColumns,
  GridRowParams,
  GridEventListener,
  GridSortingInitialState,
  GridPaginationInitialState,
} from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  copyShippingOrder,
  getShippingOffers,
  loadCategoryOptions,
  loadRejectionMasterOptions,
  rejectShippingOffer,
} from '../shipping-orders/ShippingApiService';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CustomDataGrid from 'common/datagrid/datagrid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  BaseModel,
  CommonListRequestModel,
  CommonPaginationResponseModel,
  CopyState,
  FilterScreens,
  MasterCategoryEnumType,
  OrderByDirection,
} from 'models/pagination-model';
import {
  OfferStatus,
  OfferStatusDescription,
  ShippingOrderListDTO,
  ShippingOrderStatus,
  ShippingOrderStatusDescription,
  ValidityDescription,
} from 'models/shipping-orders';
import FormControl from 'common/formControl/formControl';
import { Permission } from 'Permissions';
import RouteEnum from 'models/RouteEnum';
import { useNavigate } from 'react-router-dom';
import I18n from 'utilities/i18n';
import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { hasPermission } from 'utilities/protectedRoute';
import {
  faFilter,
  faPlus,
  faSearch,
  faTimes,
  faWarning,
  faFan,
  faSnowflake,
  faTemperature1,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { toast } from 'react-toastify';
import environment from 'environment';
import http from '../../utilities/httpService';
import CustomDateRangePicker from 'common/dateRange/dateRange';
import { addDays } from 'date-fns';
import CustomSelectPaginate from 'common/CustomSelect/CustomSelectPaginate';
import CustomizedSelect from 'common/CustomSelect/CustomSelect';
import { Tooltip } from '@material-ui/core';
import CircularProgressWithLabel from 'common/CircularProgress/CircularProgress';
import RejectionPopup from './RejectionPopup';
import axios from 'axios';
import CustomRangeSlider from 'common/CustomRangeSlider/CustomRangeSlider';
import ConfirmationPopup from 'common/confirmationPopup/confirmationPopup';

export default function ShippingOfferList() {
  let navigate = useNavigate();
  const breadcrumbs = [
    <Typography key="3" color="text.primary">
      {I18n('Customer.Shipping')}
    </Typography>,
    <Typography key="3" color="text.primary">
      {I18n('Nav.Offer')}
    </Typography>,
  ];
  const [canEdit, setCanEdit] = useState(
    hasPermission(Permission.canEditShippingOffers)
  );
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [gridData, setGridData] = useState<
    CommonPaginationResponseModel<ShippingOrderListDTO>
  >({
    isLoading: true,
    sortOrder: OrderByDirection.Descending,
    sortBy: 'orderDate',
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [10, 20, 50, 100],
    pageSize: 10,
    page: 0,
    searchExpression: '',
  });

  const [isPast, setIsPast] = useState(false);
  const [isToday, setIsToday] = useState(false);
  const [isFuture, setIsFuture] = useState(false);
  const [isIgnoreDateFilter, setIsIgnoreDateFilter] = useState<boolean>(false);
  const [isIgnorePriceFilter, setIsIgnorePriceFilter] =
    useState<boolean>(false);
  const [refreshGrid, setRefreshGrid] = useState(false);
  const [orderId, setOrderId] = useState<number>(0);
  const dateFormat = 'YYYY-MM-DDTHH:mm:ss[Z]';
  const [advanceSearchExpression, setAdvanceSearchExpression] = useState(
    `expectedPickupDateTime~>~${moment(addDays(new Date(), -7))
      .set({ h: 0, m: 0, s: 1 })
      .format(dateFormat)} and expectedPickupDateTime~<~${moment(new Date())
      .set({ h: 23, m: 59, s: 59 })
      .format(dateFormat)}`
  );
  const [rowsSelected, setRowsSelected] = useState([]);
  const [basicSearchExpression, setBasicSearchExpression] = useState('');
  const [fromDate, setFromDate] = useState(
    moment(addDays(new Date(), -7)).set({ h: 0, m: 0, s: 1 }).format(dateFormat)
  );
  const [toDate, setToDate] = useState(
    moment(new Date()).set({ h: 23, m: 59, s: 59 }).format(dateFormat)
  );
  const [validityId, setValidityId] = useState([]);
  const [categoryId, setCategoryId] = useState([]);
  const [serviceId, setServiceId] = useState([]);
  const [offerStatusId, setOfferStatusId] = useState([]);
  const [rejectionId, setRejectionId] = useState([]);
  const [isFilterEnabled, setIsFilterEnabled] = useState(false);
  const [isRejectModalOpen, setRejectModal] = useState(false);
  const selectedIds = (selectedRows: any) => {
    setRowsSelected(selectedRows.map((x: any) => x.id));
  };
  const defaultOrderValidtyValue = {
    value: '',
    label: I18n('Placeholders.SelectValidity'),
  } as BaseModel;
  const [selectedValidity, setSelectedValidity] = useState([]);
  const defaultOfferStatusValue = {
    value: '',
    label: I18n('Placeholders.SelectOfferStatus'),
  } as BaseModel;
  const [priceRangeMin, setPriceRangeMin] = useState(0);
  const [priceRangeMax, setPriceRangeMax] = useState(0);
  const [selectedOfferStatus, setSelectedOfferStatus] = useState([]);
  const defaultRejectionValue = {
    value: '',
    label: I18n('Placeholders.SelectRejection'),
  } as BaseModel;
  const [selectedRejection, setSelectedRejection] = useState([]);
  const defaultStatusValue = {
    value: '',
    label: I18n('Placeholders.Select'),
  } as BaseModel;
  const defaultOrderCategoryValue = {
    value: '',
    label: I18n('Placeholders.SelectCategory'),
  } as BaseModel;
  const [selectedCategory, setSelectedCategory] = useState([]);
  const defaultServiceValue = {
    value: '',
    label: I18n('Placeholders.SelectService'),
  } as BaseModel;
  const [selectedService, setSelectedService] = useState([]);
  const [isCopyModalOpen, setCopyModalOpen] = useState(false);
  let offerStatusList: Array<BaseModel> = [];
  const getOfferStatus = () => {
    offerStatusList = [];
    OfferStatusDescription.forEach((value: string, key: number) => {
      offerStatusList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getOfferStatus();

  let validityList: Array<BaseModel> = [];
  const getValidity = () => {
    validityList = [];
    ValidityDescription.forEach((value: string, key: number) => {
      validityList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getValidity();

  let statusList: Array<BaseModel> = [];
  const getShippingStatus = () => {
    statusList = [];
    ShippingOrderStatusDescription.forEach((value: string, key: number) => {
      statusList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getShippingStatus();

  const i18nMessages = {
    rejectOrderSuccess: I18n('ShippingOrders.RejectOfferSuccess'),
    rejectOrderCofirmation: I18n('ShippingOrders.RejectOfferConfirmation'),
    errorOccured: I18n('ShippingOrders.ErrorOccurred'),
    noRowsSelected: I18n('Customer.NoRowsSelected'),
    savedSuccessfully: I18n('Common.SavedSuccessfully'),
    orderCopySuccessful: I18n('ShippingOrders.OrderCopySuccessful'),
    RejectOffer: I18n('DecPackOrders.RejectOffer'),
    copyConfirmation: I18n('ShippingOrders.CopyConfirmation'),
  };
  const deleteOrderData = useCallback(
    (id: any) => () => {
      setRejectModal(true);
      setOrderId(id);
    },
    []
  );
  const handleCloseRejectModal = () => {
    setRejectModal(false);
  };
  const handleOkRejectModal = async (rejectionId: number) => {
    setRejectModal(false);
    var result = await rejectShippingOffer(orderId, rejectionId);

    if (result.result) {
      toast.success(i18nMessages.rejectOrderSuccess);
      getFilters();
    }
  };
  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));

  const getDateFilter = (dateFilters: any) => {
    if (dateFilters.isPast && dateFilters.isToday && dateFilters.isFuture) {
      return '';
    } else if (
      !dateFilters.isPast &&
      dateFilters.isToday &&
      dateFilters.isFuture
    ) {
      return `expectedPickupDateTime~>=~${moment(new Date())
        .set({ h: 0, m: 0, s: 0 })
        .format(dateFormat)}`;
    } else if (
      dateFilters.isPast &&
      !dateFilters.isToday &&
      dateFilters.isFuture
    ) {
      return `expectedPickupDateTime~<~${moment(new Date())
        .set({ h: 0, m: 0, s: 0 })
        .format(dateFormat)} or expectedPickupDateTime~>~${moment(new Date())
        .set({ h: 23, m: 59, s: 59 })
        .format(dateFormat)}`;
    } else if (
      dateFilters.isPast &&
      dateFilters.isToday &&
      !dateFilters.isFuture
    ) {
      return `expectedPickupDateTime~<=~${moment(new Date())
        .set({ h: 23, m: 59, s: 59 })
        .format(dateFormat)}`;
    } else if (
      dateFilters.isPast &&
      !dateFilters.isToday &&
      !dateFilters.isFuture
    ) {
      return `expectedPickupDateTime~<~${moment(new Date())
        .set({ h: 0, m: 0, s: 0 })
        .format(dateFormat)}`;
    } else if (
      !dateFilters.isPast &&
      dateFilters.isToday &&
      !dateFilters.isFuture
    ) {
      return `expectedPickupDateTime~>~${moment(new Date())
        .set({ h: 0, m: 0, s: 0 })
        .format(dateFormat)} and expectedPickupDateTime~<~${moment(new Date())
        .set({ h: 23, m: 59, s: 59 })
        .format(dateFormat)}`;
    } else if (
      !dateFilters.isPast &&
      !dateFilters.isToday &&
      dateFilters.isFuture
    ) {
      return `expectedPickupDateTime~>~${moment(new Date())
        .set({ h: 23, m: 59, s: 59 })
        .format(dateFormat)}`;
    } else {
      return '';
    }
  };

  const manageFilterStatus = () => {
    setIsFilterEnabled(
      selectedValidity.length > 0 ||
        selectedCategory.length > 0 ||
        selectedService.length > 0 ||
        selectedOfferStatus.length > 0 ||
        selectedRejection.length > 0 ||
        isIgnoreDateFilter ||
        isIgnorePriceFilter ||
        priceRangeMin > 0 ||
        priceRangeMax > 0 ||
        (!isPast && !isToday && !isFuture)
        ? true
        : false
    );
  };

  const getShippingOfferList = (rawFilters?: any) => {
    updateGridData('isLoading', true);
    var dateFilter = rawFilters != undefined ? getDateFilter(rawFilters) : '';
    var isDateFilterApplicable =
      rawFilters != undefined
        ? rawFilters.isPast || rawFilters.isToday || rawFilters.isFuture
        : false;
    var filterData: CommonListRequestModel<ShippingOrderListDTO> = {
      advanceSearchExpression: isDateFilterApplicable
        ? dateFilter
        : rawFilters != undefined && rawFilters.isIgnoreDateFilter
        ? ''
        : advanceSearchExpression,
      basicSearchExpression,
      gridData,
    };
    var validityFilter =
      rawFilters != undefined && rawFilters.validityFilter.length > 0
        ? rawFilters.validityFilter.join(',')
        : '';
    var offerStatusFilter =
      rawFilters != undefined && rawFilters.offerStatusFilter.length > 0
        ? rawFilters.offerStatusFilter.join(',')
        : '';
    var rejectionFilter =
      rawFilters != undefined && rawFilters.rejectionFilter.length > 0
        ? rawFilters.rejectionFilter.join(',')
        : '';

    var categoryFilter =
      rawFilters != undefined && rawFilters.categoryFilter.length > 0
        ? rawFilters.categoryFilter.join(',')
        : '';

    var serviceFilter =
      rawFilters != undefined && rawFilters.serviceFilter.length > 0
        ? rawFilters.serviceFilter.join(',')
        : '';

    var statusFilter = '';
    var priceRangeFilter =
      rawFilters != undefined &&
      !rawFilters.isIgnorePriceFilter &&
      rawFilters.priceRangeMin != undefined &&
      rawFilters.priceRangeMax != undefined
        ? rawFilters.priceRangeMin + ',' + rawFilters.priceRangeMax
        : '';

    getShippingOffers(
      filterData,
      validityFilter,
      offerStatusFilter,
      rejectionFilter,
      statusFilter,
      categoryFilter,
      serviceFilter,
      priceRangeFilter
    ).then((x) => {
      if (x) {
        updateGridData('totalRows', x?.gridData.totalRows);
        updateGridData('rows', x?.gridData.rows);
        updateGridData('isLoading', false);
        manageFilterStatus();
      }
    });
  };

  const setRawFilters = (uiFilters: string) => {
    var filters = uiFilters != undefined ? uiFilters.split('|') : [];
    if (filters.length > 0) {
      var validityFilter: any = [];
      var offerStatusFilter: any = [];
      var rejectionFilter: any = [];
      var categoryFilter: any = [];
      var serviceFilter: any = [];
      var isIgnoreDateFilter = false;
      var isIgnorePriceFilter = false;
      var priceRangeMin = 0;
      var priceRangeMax = 0;
      var isPast = false;
      var isToday = false;
      var isFuture = false;
      filters.forEach((element: any) => {
        if (element.includes('ValidityId')) {
          var filterPart1 = element.split('&');
          validityFilter.push({
            value: filterPart1[0].split('=')[1],
            label: filterPart1[1].split('=')[1],
          });
        } else if (element.includes('OfferStatusId')) {
          var filterPart2 = element.split('&');
          offerStatusFilter.push({
            value: filterPart2[0].split('=')[1],
            label: filterPart2[1].split('=')[1],
          });
        } else if (element.includes('RejectionId')) {
          var filterPart3 = element.split('&');
          rejectionFilter.push({
            value: filterPart3[0].split('=')[1],
            label: filterPart3[1].split('=')[1],
          });
        } else if (element.includes('isIgnoreDateFilter')) {
          var filterPart5 = element.split('=');
          setIsIgnoreDateFilter(filterPart5[1] === '1' ? true : false);
          isIgnoreDateFilter = filterPart5[1] === '1' ? true : false;
        } else if (element.includes('isPast')) {
          var filterPart6 = element.split('=');
          setIsPast(filterPart6[1] === '1' ? true : false);
          isPast = filterPart6[1] === '1' ? true : false;
        } else if (element.includes('isToday')) {
          var filterPart7 = element.split('=');
          setIsToday(filterPart7[1] === '1' ? true : false);
          isToday = filterPart7[1] === '1' ? true : false;
        } else if (element.includes('isFuture')) {
          var filterPart8 = element.split('=');
          setIsFuture(filterPart8[1] === '1' ? true : false);
          isFuture = filterPart8[1] === '1' ? true : false;
        } else if (element.includes('CategoryId')) {
          var filterPart9 = element.split('&');
          categoryFilter.push({
            value: filterPart9[0].split('=')[1],
            label: filterPart9[1].split('=')[1],
          });
        } else if (element.includes('ServiceId')) {
          var filterPart10 = element.split('&');
          serviceFilter.push({
            value: filterPart10[0].split('=')[1],
            label: filterPart10[1].split('=')[1],
          });
        } else if (element.includes('PriceRangeMin')) {
          var filterPart11 = element.split('&');
          priceRangeMin = filterPart11[0].split('=')[1];
          priceRangeMax = filterPart11[1].split('=')[1];
        } else if (element.includes('isIgnorePriceFilter')) {
          var filterPart12 = element.split('=');
          setIsIgnorePriceFilter(filterPart12[1] === '1' ? true : false);
          isIgnorePriceFilter = filterPart12[1] === '1' ? true : false;
        }
      });
      setSelectedValidity(validityFilter);

      setSelectedOfferStatus(offerStatusFilter);

      setSelectedRejection(rejectionFilter);

      setSelectedCategory(categoryFilter);

      setSelectedService(serviceFilter);

      setValidityId(
        validityFilter.map((x: any) => {
          return x.value;
        })
      );

      setOfferStatusId(
        offerStatusFilter.map((x: any) => {
          return x.value;
        })
      );

      setRejectionId(
        rejectionFilter.map((x: any) => {
          return x.value;
        })
      );

      setCategoryId(
        categoryFilter.map((x: any) => {
          return x.value;
        })
      );

      setServiceId(
        serviceFilter.map((x: any) => {
          return x.value;
        })
      );

      setPriceRangeMin(Number(priceRangeMin));

      setPriceRangeMax(Number(priceRangeMax));

      var dropdownFilters = {
        validityFilter: validityFilter.map((x: any) => {
          return x.value;
        }),
        offerStatusFilter: offerStatusFilter.map((x: any) => {
          return x.value;
        }),
        rejectionFilter: rejectionFilter.map((x: any) => {
          return x.value;
        }),
        categoryFilter: categoryFilter.map((x: any) => {
          return x.value;
        }),
        serviceFilter: serviceFilter.map((x: any) => {
          return x.value;
        }),
        isIgnoreDateFilter,
        isIgnorePriceFilter,
        isPast,
        isToday,
        isFuture,
        priceRangeMin,
        priceRangeMax,
      };
      return dropdownFilters;
    }
  };

  const getFilters = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl +
        'CustomizedFilter?filterScreen=' +
        FilterScreens.ShippingOfferList
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result) {
        var rawFilters = setRawFilters(result.data.uiFilter);
        await getShippingOfferList(rawFilters);
      }
    } catch {}
  };

  const handleConvertToOrder = async () => {
    if (!(rowsSelected && rowsSelected.length > 0)) {
      toast.error(i18nMessages.noRowsSelected);
      return;
    }

    setIsLoading(true);

    for (let i = 0; i < rowsSelected.length; i++) {
      const shippingOfferId = rowsSelected[i];
      var token = localStorage.getItem('token');
      if (token) {
        const userToken = JSON.parse(token);
        const convertOrderInstance = axios.create();
        await convertOrderInstance
          .put(
            environment.api.baseUrl +
              `ShippingOffers/ShippingOfferConvert/${shippingOfferId}`,
            null,
            { headers: { Authorization: `Bearer ${userToken}` } }
          )
          .then(() => {
            setProgress(((i + 1) / rowsSelected.length) * 100);
          })
          .catch();
      }
    }
    setTimeout(() => {
      setIsLoading(false);
      setProgress(0);
      getFilters();
      setRowsSelected([]);
      toast.success(i18nMessages.savedSuccessfully);
    }, 1000);
  };

  useEffect(() => {
    getFilters();
  }, [
    gridData.page,
    gridData.pageSize,
    gridData.sortBy,
    gridData.sortOrder,
    gridData.searchExpression,
    basicSearchExpression,
    refreshGrid,
  ]);

  const handleChangePage = (pageNo: number) => {
    updateGridData('page', pageNo);
  };

  const handleChangePageSize = (pageSize: number) => {
    updateGridData('pageSize', pageSize);
  };
  type Row = (typeof gridData.rows)[number];
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState<PopperPlacementType>();
  const columns: GridColumns<Row> = [
    {
      field: 'attention',
      type: 'actions',
      headerName: I18n('ShippingOrders.Attention'),
      flex: 1,
      sortable: false,
      getActions: (params: GridRowParams<ShippingOrderListDTO>) => {
        const actionButtons = [];
        actionButtons.push(
          <>
            {Math.abs(
              moment(params.row.offerExpiryDate).diff(
                moment(params.row.orderDate),
                'days'
              )
            ) > 30 ? (
              <FontAwesomeIcon
                icon={faWarning}
                className="fa-md"
                title={I18n('ShippingOrders.OfferExpired')}
              />
            ) : (
              <></>
            )}
            {params.row.isTemperatureControlCheck ? (
              <FontAwesomeIcon
                icon={faTemperature1}
                className="fa-md"
                title={I18n(
                  'ShippingOrders.ShippingPackage.TemperatureControlled'
                )}
              />
            ) : (
              <></>
            )}
            {params.row.isGeneralCargoCheck ? (
              <FontAwesomeIcon
                icon={faFan}
                className="fa-md"
                title={I18n('ShippingOrders.ShippingPackage.GeneralCargo')}
              />
            ) : (
              <></>
            )}
            {params.row.isDangerousGoodsCheck ? (
              <FontAwesomeIcon
                icon={faSnowflake}
                className="fa-md"
                title={I18n('ShippingOrders.ShippingPackage.DangerousGoods')}
              />
            ) : (
              <></>
            )}
          </>
        );
        return actionButtons;
      },
    },
    {
      field: 'orderDate',
      headerName: I18n('ShippingOrders.OfferDate'),
      valueFormatter: (params) => moment(params?.value).format('DD/MM/YYYY'),
      flex: 1,
    },
    {
      field: 'customerName',
      headerName: I18n('ShippingOrders.Customer'),
      flex: 1,
    },
    {
      field: 'categoryName',
      headerName: I18n('ShippingOrders.Category'),
      flex: 1,
    },
    {
      field: 'autogeneratedOfferReferenceNo',
      headerName: I18n('ShippingOrders.OfferReference'),
      flex: 1,
    },
    {
      field: 'orderReference',
      headerName: I18n('ShippingOrders.OrderReference'),
      flex: 1,
    },
    {
      field: 'type',
      headerName: I18n('ShippingOrders.OfferStatus'),
      flex: 1,
      sortable: false,
      renderCell: (params: any) => {
        return params.row.isShippingOfferConverted
          ? offerStatusList[OfferStatus.Accepted].label
          : params.row.isOfferRejected
          ? offerStatusList[OfferStatus.Rejected].label
          : params.row.offerStatus == OfferStatus.Open
          ? offerStatusList[OfferStatus.Open].label
          : params.row.offerStatus == OfferStatus.OfferSent
          ? offerStatusList[OfferStatus.OfferSent].label
          : '';
      },
    },
    {
      field: 'fromTo',
      headerName: I18n('ShippingOrders.FromTo'),
      flex: 1,
    },
    {
      field: 'serviceName',
      headerName: I18n('ShippingOrders.Service'),
      flex: 1,
    },
    {
      field: 'totalWeight',
      headerName: I18n('ShippingOrders.TotalWeight'),
      flex: 1,
      sortable: false,
    },
    {
      field: 'totalNoOfPieces',
      headerName: I18n('ShippingOrders.TotalNoOfPackages'),
      flex: 1,
      sortable: false,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: I18n('Common.Actions'),
      width: 80,
      align: 'right',
      getActions: (params: GridRowParams<ShippingOrderListDTO>) => {
        const actionButtons = [];
        actionButtons.push(
          <>
            {hasPermission(Permission.canDeleteShippingOffers) &&
            params.row.status === ShippingOrderStatus.New &&
            params.row.isOfferRejected === false &&
            params.row.isShippingOfferConverted === false ? (
              <Tooltip title={i18nMessages.RejectOffer}>
                <GridActionsCellItem
                  onPointerEnterCapture={false}
                  onPointerLeaveCapture={false}
                  icon={<DeleteIcon />}
                  placeholder={''}
                  label="Delete"
                  onClick={deleteOrderData(params.id)}
                />
              </Tooltip>
            ) : (
              <></>
            )}
          </>
        );
        actionButtons.push(
          <>
            {hasPermission(Permission.canAddShippingOffers) ? (
              <Tooltip title={'Copy'}>
                <GridActionsCellItem
                  onPointerEnterCapture={false}
                  onPointerLeaveCapture={false}
                  icon={<ContentCopyIcon />}
                  placeholder={''}
                  label="Copy"
                  onClick={copyHandler(params.id)}
                />
              </Tooltip>
            ) : (
              <></>
            )}
          </>
        );
        return actionButtons;
      },
    },
  ];
  const popperHandleClick =
    (newPlacement: PopperPlacementType) =>
    (event: MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setOpen((prev) => placement !== newPlacement || !prev);
      setPlacement(newPlacement);
    };
  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    if (sortModel && sortModel.length) {
      if (sortModel[0].field === 'customerName') {
        updateGridData('sortBy', 'Customer.Name');
      } else if (sortModel[0].field === 'categoryName') {
        updateGridData('sortBy', 'Category.Name');
      } else if (sortModel[0].field === 'fromTo') {
        updateGridData('sortBy', 'PickUpCustomerLocation.Country.Name');
      } else {
        updateGridData('sortBy', sortModel[0].field);
      }
      updateGridData(
        'sortOrder',
        sortModel[0].sort === 'asc'
          ? OrderByDirection.Ascending
          : OrderByDirection.Descending
      );
    }
  }, []);
  const handleOnRowClick: GridEventListener<'rowClick'> = (
    params: { id: any }, // GridRowParams
    event: any, // MuiEvent<React.MouseEvent<HTMLElement>>
    details: any // GridCallbackDetails
  ) => {
    navigate(`${RouteEnum.ViewShippingOffers}/${params.id}`);
  };
  const handleResetClick = () => {
    setSelectedCategory([]);
    setSelectedService([]);
    setSelectedValidity([]);
    setSelectedOfferStatus([]);
    setSelectedRejection([]);
    setIsIgnoreDateFilter(false);
    setIsIgnorePriceFilter(false);
    setPriceRangeMin(0);
    setPriceRangeMax(100000);
    setAdvanceSearchExpression('');
  };
  const appendAdvanceSearchExpressions = (
    searchExpression: string,
    type: string
  ) => {
    let searchExpressionLocal: string = searchExpression;
    switch (type) {
      case 'and':
        searchExpressionLocal = searchExpressionLocal ? ` and ` : ``;
        break;
      case 'or':
        searchExpressionLocal = searchExpressionLocal ? ` or ` : ``;
        break;
      case '|':
        searchExpressionLocal = searchExpressionLocal ? `|` : ``;
        break;
      default:
        break;
    }
    return searchExpressionLocal;
  };

  const copyHandler = useCallback(
    (id: any) => () => {
      setCopyModalOpen(true);
      setOrderId(id);
    },
    []
  );

  const handleCloseCopyModal = () => {
    setCopyModalOpen(false);
  };
  const handleOkCopyModal = async () => {
    await copyOrder(orderId);
    setCopyModalOpen(false);
  };

  const copyOrder = async (id: any) => {
    const res = await copyShippingOrder(id, CopyState.Standard);
    if (res.result) {
      toast.success(i18nMessages.orderCopySuccessful);
      navigate(RouteEnum.AddShippingOffers + `/${res.data}`);
    } else {
      toast.error(i18nMessages.errorOccured);
    }
  };

  const handleAdvanceSearchFilterClick = () => {
    let searchExpression = '';
    var validityFilter: any = [];
    var offerStatusFilter: any = [];
    var rejectionFilter: any = [];
    if (selectedValidity && selectedValidity.length > 0) {
      selectedValidity.forEach((element: any) => {
        validityFilter.push(element.value);
      });
    }
    if (selectedOfferStatus && selectedOfferStatus.length > 0) {
      selectedOfferStatus.forEach((element: any) => {
        offerStatusFilter.push(element.value);
      });
    }
    if (selectedRejection && selectedRejection.length > 0) {
      selectedRejection.forEach((element: any) => {
        rejectionFilter.push(element.value);
      });
    }
    if (isIgnoreDateFilter) {
      searchExpression = '';
    } else if (fromDate && toDate) {
      setAdvanceSearchExpression(
        `expectedPickupDateTime~>~${fromDate} and expectedPickupDateTime~<~${toDate}`
      );
    }
    setValidityId(validityFilter);
    setOfferStatusId(offerStatusFilter);
    setRejectionId(rejectionFilter);
    setOpen(false);
    var rawFilter = getRawFilter(isPast, isToday, isFuture);
    CustomizedFilterChange(searchExpression, rawFilter);
  };

  const handlePastClick = (event: any) => {
    setIsPast(event.target.checked);
    var rawFilter = getRawFilter(event.target.checked, isToday, isFuture);
    CustomizedFilterChange('', rawFilter);
  };

  const handleTodayClick = (event: any) => {
    setIsToday(event.target.checked);
    var rawFilter = getRawFilter(isPast, event.target.checked, isFuture);
    CustomizedFilterChange('', rawFilter);
  };

  const handleFutureClick = (event: any) => {
    setIsFuture(event.target.checked);
    var rawFilter = getRawFilter(isPast, isToday, event.target.checked);
    CustomizedFilterChange('', rawFilter);
  };

  const getRawFilter = (
    isPast: boolean,
    isToday: boolean,
    isFuture: boolean
  ) => {
    var rawsearchExpression = '';
    if (selectedValidity && selectedValidity.length > 0) {
      selectedValidity.forEach((element: any) => {
        rawsearchExpression += element.value
          ? appendAdvanceSearchExpressions(rawsearchExpression, '|') +
            `ValidityId=${element.value}&ValidityName=${element.label}`
          : '';
      });
    }
    if (selectedOfferStatus && selectedOfferStatus.length > 0) {
      selectedOfferStatus.forEach((element: any) => {
        rawsearchExpression += element.value
          ? appendAdvanceSearchExpressions(rawsearchExpression, '|') +
            `OfferStatusId=${element.value}&OfferStatusName=${element.label}`
          : '';
      });
    }
    if (selectedRejection && selectedRejection.length > 0) {
      selectedRejection.forEach((element: any) => {
        rawsearchExpression += element.value
          ? appendAdvanceSearchExpressions(rawsearchExpression, '|') +
            `RejectionId=${element.value}&RejectionName=${element.label}`
          : '';
      });
    }

    if (selectedCategory && selectedCategory.length > 0) {
      selectedCategory.forEach((element: any) => {
        rawsearchExpression += element.value
          ? appendAdvanceSearchExpressions(rawsearchExpression, '|') +
            `CategoryId=${element.value}&CategoryName=${element.label}`
          : '';
      });
    }

    if (selectedService && selectedService.length > 0) {
      selectedService.forEach((element: any) => {
        rawsearchExpression += element.value
          ? appendAdvanceSearchExpressions(rawsearchExpression, '|') +
            `ServiceId=${element.value}&ServiceName=${element.label}`
          : '';
      });
    }
    if (priceRangeMin != undefined && priceRangeMax != undefined) {
      rawsearchExpression +=
        priceRangeMin != undefined && priceRangeMax != undefined
          ? appendAdvanceSearchExpressions(rawsearchExpression, '|') +
            `PriceRangeMin=${priceRangeMin}&PriceRangeMax=${priceRangeMax}`
          : '';
    }

    rawsearchExpression +=
      appendAdvanceSearchExpressions(rawsearchExpression, '|') +
      `isPast=${isPast ? 1 : 0}`;

    rawsearchExpression +=
      appendAdvanceSearchExpressions(rawsearchExpression, '|') +
      `isToday=${isToday ? 1 : 0}`;

    rawsearchExpression +=
      appendAdvanceSearchExpressions(rawsearchExpression, '|') +
      `isFuture=${isFuture ? 1 : 0}`;

    rawsearchExpression +=
      appendAdvanceSearchExpressions(rawsearchExpression, '|') +
      `isIgnoreDateFilter=${isIgnoreDateFilter ? 1 : 0}`;

    rawsearchExpression +=
      appendAdvanceSearchExpressions(rawsearchExpression, '|') +
      `isIgnorePriceFilter=${isIgnorePriceFilter ? 1 : 0}`;

    return rawsearchExpression;
  };

  const addShippingOrderClicked = () => {
    navigate(`${RouteEnum.AddShippingOffers}/0`);
  };
  const defaultAdditional: any = {
    page: 1,
  };

  const CustomizedFilterChange = async (
    rawFilter: string,
    uiFilter: string
  ) => {
    var data = {
      RawFilter: rawFilter,
      UIFilter: uiFilter,
      FilterScreens: FilterScreens.ShippingOfferList,
    };
    const apiUrl = new URL(environment.api.baseUrl + 'CustomizedFilter');
    try {
      await http.post(apiUrl.toString(), data);
      setRefreshGrid(!refreshGrid);
    } catch {}
  };

  const handleRangeSlider = (value1: number, value2: number) => {
    setPriceRangeMin(value1);
    setPriceRangeMax(value2);
  };

  const handleChangeDateFilterSearch = debounce(
    (fromDate: any, toDate: any) => {
      if (fromDate && toDate) {
        var from = moment(fromDate, 'ddd MMM D YYYY');
        from.set({ h: 0, m: 0, s: 1 });
        var to = moment(toDate, 'ddd MMM D YYYY');
        to.set({ h: 23, m: 59, s: 59 });

        setFromDate(from.format(dateFormat));
        setToDate(to.format(dateFormat));
      }
      setIsIgnoreDateFilter(false);
    },
    500
  );

  const handleChangeBasicSearch = debounce((searchExpression: any) => {
    var expression = '';
    if (searchExpression.target.value) {
      expression = 'Customer.Name~like~' + searchExpression.target.value;
      expression +=
        expression !== ''
          ? ' or OrderReference~like~' + searchExpression.target.value
          : 'OrderReference~like~' + searchExpression.target.value;
      expression +=
        expression !== ''
          ? ' or AutogeneratedOfferReferenceNo~like~' +
            searchExpression.target.value
          : 'AutogeneratedOfferReferenceNo~like~' +
            searchExpression.target.value;
    }
    setBasicSearchExpression(expression);
  }, 500);

  return (
    <div>
      <div className="heading-section">
        <div className="heading-section-left">
          <Typography variant="h3" sx={{ mb: 3 }}>
            {I18n('Nav.ShippingOffers')}
          </Typography>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>
        <div className="heading-section-right">
          <div className="search-control-outer">
            <FormControl
              margin="normal"
              displayLabel=""
              required
              inputType="text"
              placeholderText={I18n('Common.Search')}
              className="search-control small-form-control"
              handleChange={handleChangeBasicSearch}
              adornmentValue={
                <InputAdornment position="start">
                  <FontAwesomeIcon icon={faSearch} />
                </InputAdornment>
              }
            ></FormControl>
          </div>
          <div className="header-btn-wrap">
            <Popper
              open={open}
              anchorEl={anchorEl}
              placement={placement}
              transition
              className="filter-popper"
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper>
                    <Typography variant="h5" className="filter-heading">
                      Filter
                      <IconButton
                        aria-label="delete"
                        onClick={popperHandleClick('bottom-end')}
                      >
                        <FontAwesomeIcon
                          icon={faTimes}
                          className="fa-sm close-icon"
                        />
                      </IconButton>
                    </Typography>
                    <div className="filter-content form-small-gap">
                      <Grid
                        container
                        spacing={2}
                        className="grid-wrap two-col"
                      ></Grid>
                      <Grid item xs={12}>
                        <CustomSelectPaginate
                          additional={defaultAdditional}
                          value={selectedCategory}
                          loadOptions={(
                            search: string,
                            prevOptions: any,
                            page: any
                          ) =>
                            loadCategoryOptions(
                              MasterCategoryEnumType.AllowedOrderCategory,
                              search,
                              prevOptions,
                              page
                            )
                          }
                          handleChange={(newValue: any) =>
                            setSelectedCategory(newValue)
                          }
                          placeholder={defaultOrderCategoryValue.label}
                          isSearchable={true}
                          displayLabel={I18n('ShippingOrders.OrderCategory')}
                          errorValue={true}
                          debounceTimeout={500}
                          isMultiSelect={true}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <CustomSelectPaginate
                          additional={defaultAdditional}
                          value={selectedService}
                          loadOptions={(
                            search: string,
                            prevOptions: any,
                            page: any
                          ) =>
                            loadCategoryOptions(
                              MasterCategoryEnumType.ShippingService,
                              search,
                              prevOptions,
                              page
                            )
                          }
                          handleChange={(newValue: any) =>
                            setSelectedService(newValue)
                          }
                          placeholder={defaultServiceValue.label}
                          isSearchable={true}
                          displayLabel={I18n('ShippingOrders.Service')}
                          errorValue={true}
                          debounceTimeout={500}
                          isMultiSelect={true}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CustomizedSelect
                          placeholder={defaultOrderValidtyValue.label}
                          options={validityList}
                          isSearchable={false}
                          displayLabel={I18n('ShippingOrders.Validity')}
                          handleChange={(newValue: any) =>
                            setSelectedValidity(newValue)
                          }
                          value={selectedValidity}
                          isMulti={true}
                        ></CustomizedSelect>
                      </Grid>
                      <Grid item xs={12}>
                        <CustomizedSelect
                          placeholder={defaultOfferStatusValue.label}
                          options={offerStatusList}
                          isSearchable={false}
                          displayLabel={I18n('ShippingOrders.OfferStatus')}
                          handleChange={(newValue: any) =>
                            setSelectedOfferStatus(newValue)
                          }
                          value={selectedOfferStatus}
                          isMulti={true}
                        ></CustomizedSelect>
                      </Grid>
                      <Grid item xs={4}>
                        <CustomSelectPaginate
                          additional={defaultAdditional}
                          value={selectedRejection}
                          loadOptions={(
                            search: string,
                            prevOptions: any,
                            page: any
                          ) =>
                            loadRejectionMasterOptions(
                              search,
                              prevOptions,
                              page
                            )
                          }
                          handleChange={(newValue: any) =>
                            setSelectedRejection(newValue)
                          }
                          placeholder={defaultRejectionValue.label}
                          isSearchable={true}
                          displayLabel={I18n('ShippingOrders.RejectionCode')}
                          errorValue={true}
                          debounceTimeout={500}
                          isMultiSelect={true}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CustomRangeSlider
                          displayLabel={I18n('ShippingOrders.PriceRange')}
                          minRange={0}
                          maxRange={100000}
                          defaultMinValue={priceRangeMin}
                          defaultMaxValue={priceRangeMax}
                          handleChange={handleRangeSlider}
                        ></CustomRangeSlider>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          className="mt-0"
                          control={
                            <Checkbox
                              onChange={(event) =>
                                setIsIgnorePriceFilter(event.target.checked)
                              }
                              checked={isIgnorePriceFilter}
                            />
                          }
                          label={I18n('Common.IgnorePriceFilter')}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CustomDateRangePicker
                          displayLabel={I18n('Common.DateRange')}
                          fromDate={fromDate}
                          toDate={toDate}
                          handleChange={(fromDate: any, toDate: any) => {
                            handleChangeDateFilterSearch(fromDate, toDate);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          className="mt-0"
                          control={
                            <Checkbox
                              onChange={(event) =>
                                setIsIgnoreDateFilter(event.target.checked)
                              }
                              checked={isIgnoreDateFilter}
                            />
                          }
                          label={I18n('Common.IgnoreDateFilter')}
                        />
                      </Grid>
                      <div className="btn-wrap">
                        <Button
                          color="neutral"
                          disableElevation
                          variant="contained"
                          onClick={handleResetClick}
                        >
                          {I18n('Common.Reset')}
                        </Button>
                        <Button
                          color="primary"
                          disableElevation
                          variant="contained"
                          onClick={handleAdvanceSearchFilterClick}
                        >
                          {I18n('Common.Apply')}
                        </Button>
                      </div>
                    </div>
                  </Paper>
                </Fade>
              )}
            </Popper>
            <Button
              color="info"
              onClick={popperHandleClick('bottom-end')}
              disableElevation
              variant="outlined"
              className={`icon-btn ${isFilterEnabled ? 'is-filtered' : ''}`}
            >
              <FontAwesomeIcon icon={faFilter} className="fa-lg" />
            </Button>
            {hasPermission(Permission.canAddShippingOffers) && (
              <Button
                color="info"
                className="small-btn"
                disableElevation
                variant="contained"
                onClick={addShippingOrderClicked}
              >
                <FontAwesomeIcon icon={faPlus} className="fa-md mr-2" />
                {I18n('Common.Add')}
              </Button>
            )}
            {canEdit ? (
              <Button
                color="info"
                className="small-btn"
                disableElevation
                variant="contained"
                onClick={handleConvertToOrder}
              >
                {I18n('ShippingOrders.ConvertOrder')}
              </Button>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div className="checkbox-header-group">
        <FormControlLabel
          control={
            <Checkbox
              name="past"
              className="search-control small-form-control"
              onChange={handlePastClick}
              checked={isPast}
              inputProps={{ 'aria-label': 'controlled' }}
              sx={{
                color: 'white',
                '&.Mui-checked': {
                  color: 'white',
                },
              }}
            />
          }
          label={I18n('Common.Past')}
        />
        <FormControlLabel
          control={
            <Checkbox
              name="today"
              className="search-control small-form-control"
              onChange={handleTodayClick}
              checked={isToday}
              inputProps={{ 'aria-label': 'controlled' }}
              sx={{
                color: 'white',
                '&.Mui-checked': {
                  color: 'white',
                },
              }}
            />
          }
          label={I18n('Common.Today')}
        />
        <FormControlLabel
          control={
            <Checkbox
              name="future"
              className="search-control small-form-control"
              onChange={handleFutureClick}
              checked={isFuture}
              inputProps={{ 'aria-label': 'controlled' }}
              sx={{
                color: 'white',
                '&.Mui-checked': {
                  color: 'white',
                },
              }}
            />
          }
          label={I18n('Common.Future')}
        />
      </div>
      <div className="main-content-section">
        <div className="content-block table-fix">
          <CustomDataGrid
            isRowSelectable={(params: GridRowParams) =>
              params.row.isOfferRejected === false &&
              params.row.isShippingOfferConverted === false
            }
            checkboxSelection={canEdit}
            selectedValues={selectedIds}
            selectionModel={rowsSelected}
            rowCount={gridData.totalRows}
            data={gridData.rows}
            columns={columns}
            pageNo={gridData.page}
            pageSize={gridData.pageSize}
            onSortModelChange={handleSortModelChange}
            onPageChange={handleChangePage}
            onPageSizeChange={handleChangePageSize}
            rowsPerPageOptions={gridData.rowsPerPageOptions}
            initialState={{
              sorting: {
                sortModel: [{ field: 'orderDate', sort: 'desc' }],
              } as GridSortingInitialState,
              pagination: {
                page: gridData.page,
                pageSize: gridData.pageSize,
              } as GridPaginationInitialState,
            }}
            onRowClick={handleOnRowClick}
          ></CustomDataGrid>
          <RejectionPopup
            isOpen={isRejectModalOpen}
            data={orderId}
            message={i18nMessages.rejectOrderCofirmation}
            handleClose={handleCloseRejectModal}
            handleOk={handleOkRejectModal}
          ></RejectionPopup>

          <CircularProgressWithLabel value={progress} isVisible={isLoading} />
          <ConfirmationPopup
            isOpen={isCopyModalOpen}
            data={orderId}
            message={i18nMessages.copyConfirmation}
            handleClose={handleCloseCopyModal}
            handleOk={handleOkCopyModal}
          ></ConfirmationPopup>
        </div>
      </div>
    </div>
  );
}
