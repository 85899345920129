import { Button, Grid, Tooltip, Typography } from '@mui/material';
import ConfirmationPopup from 'common/confirmationPopup/confirmationPopup';
import environment from 'environment';
import { ShippingOrderStatus } from 'models/shipping-orders';
import { useCallback, useEffect, useState } from 'react';
import I18n from 'utilities/i18n';
import http from '../../utilities/httpService';
import { toast } from 'react-toastify';
import {
  BaseModel,
  CountryRegionDescription,
  EntityTypeEnum,
  OrderByDirection,
} from 'models/pagination-model';
import { Utilities } from 'utilities/Utilities';
import {
  GridColumns,
  GridPaginationInitialState,
  GridRowModel,
  GridRowModesModel,
  GridRowParams,
  GridSortModel,
  GridSortingInitialState,
  MuiEvent,
} from '@mui/x-data-grid';
import { hasPermission } from 'utilities/protectedRoute';
import { Permission } from 'Permissions';
import CustomDataGridInline from 'common/datagrid/CustomDataGridInline';
import CustomizedSelect from 'common/CustomSelect/CustomSelect';

export default function OrderApproval(props: any) {
  const { entityType, entityId, isOrderEnable, handleIsOrderEnable } = props;
  const [isEligibleForVat, setIsEligibleForVat] = useState(false);
  const [vatData, setVatData] = useState([]);
  const [customerVatPercent, setCustomerVatPercent] = useState(0);
  const [customerCurrency, setCustomerCurrency] = useState('');
  const [sentForApprovalByDetails, setSentForApprovalByDetails] = useState({
    name: '',
    dateTime: '',
  });
  const [approvedByDetails, setApprovedByDetails] = useState({
    name: '',
    dateTime: '',
  });
  const [confirmModal, setConfirmModal] = useState<any>({
    isOpen: false,
    statusToUpdate: 0,
    title: '',
    message: '',
    entityType,
    entityId,
  });
  const [isApprover, setIsApproved] = useState(
    entityType == EntityTypeEnum.ShippingOrders
      ? hasPermission(Permission.canApproveShippingOrders)
      : hasPermission(Permission.canApproveDecPackOrders)
  );
  const [totalProfitLoss, setTotalProfitLoss] = useState(0);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [rows, setRows] = useState([]);
  const [gridData, setGridData] = useState({
    isLoading: true,
    sortOrder: OrderByDirection.Descending,
    sortBy: 'itemDescription',
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [10, 20, 50, 100],
    pageSize: 10,
    page: 0,
    searchExpression: '',
  });
  const [vatOptions, setVatOptions] = useState<BaseModel[]>();
  const [selectedVatOption, setSelectedVatOption] = useState<BaseModel>();
  const [vatPercent, setVatPercent] = useState(0);
  const [errorVat, setErrorVat] = useState('');

  let countryRegionList: Array<BaseModel> = [];
  const countryRegionListData = () => {
    CountryRegionDescription.forEach((value: string, key: number) => {
      countryRegionList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  countryRegionListData();

  type Row = (typeof gridData.rows)[number];

  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    if (sortModel && sortModel.length) {
      updateGridData('sortBy', sortModel[0].field);
      updateGridData(
        'sortOrder',
        sortModel[0].sort === 'asc'
          ? OrderByDirection.Ascending
          : OrderByDirection.Descending
      );
    }
  }, []);

  const handleChangePage = (pageNo: number) => {
    updateGridData('page', pageNo);
  };

  const handleChangePageSize = (pageSize: number) => {
    updateGridData('pageSize', pageSize);
  };

  const i18nMessages = {
    CommonSaveSuccessMsg: I18n('Common.SavedSuccessfully'),
    CommonErrOccuredMsg: I18n('Common.ErrorOccurred'),
    OrderOwner: I18n('DecPackOrders.OrderOwner'),
    SendForApproval: I18n('Approval.SendForApproval'),
    SentForApproval: I18n('Approval.SentForApproval'),
    Approver: I18n('Approval.Approver'),
    ItemDescription: I18n('ShippingOrders.ShippingPackage.ItemDescription'),
    EstimatedCost: I18n('ShippingMaterials.EstimatedCost'),
    ActualCost: I18n('ShippingMaterials.ActualCost'),
    SellingPrice: I18n('ShippingMaterials.SellingPrice'),
    Approve: I18n('Approval.Approve'),
    SentBy: I18n('Approval.SentBy'),
    ApprovedBy: I18n('Approval.ApprovedBy'),
    SendForApprovalConfirmMesssage: I18n(
      'Approval.SendForApprovalConfirmation'
    ),
    SendForApprovalConfirmTitle: I18n(
      'Approval.SendForApprovalConfirmationDetails'
    ),
    ApprovalConfirmMesssage: I18n('Approval.ApprovalConfirmMessage'),
    ApprovalConfirmationTitle: I18n('Approval.ApprovalConfirmTitle'),
    Save: I18n('Common.Save'),
    CommonErrFieldRequired: I18n('Common.FieldRequired'),
    InvalidVatPercent: I18n('Approval.InvalidVatPercent'),
  };

  const handleClose = () => {
    setConfirmModal({
      ...confirmModal,
      isOpen: false,
      statusToUpdate: 0,
      title: '',
      message: '',
    });
  };

  const handleOk = () => {
    handleUpdateSentForApproval(confirmModal.statusToUpdate);
    setConfirmModal({
      ...confirmModal,
      isOpen: false,
      statusToUpdate: 0,
      title: '',
      message: '',
    });
  };

  const handleSendForApproval = (status: ShippingOrderStatus) => {
    setConfirmModal({
      ...confirmModal,
      isOpen: true,
      statusToUpdate: status,
      message:
        status === ShippingOrderStatus.SentForApproval
          ? i18nMessages.SendForApprovalConfirmMesssage
          : i18nMessages.ApprovalConfirmMesssage,
      title:
        status === ShippingOrderStatus.SentForApproval
          ? i18nMessages.SendForApprovalConfirmTitle
          : i18nMessages.ApprovalConfirmationTitle,
    });
  };

  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    if (approvedByDetails.name) {
      event.defaultMuiPrevented = true;
    }
  };

  const columns: GridColumns<Row> = [
    {
      field: 'itemDescription',
      headerName: i18nMessages.ItemDescription,
      flex: 1,
      sortable: false,
    },
    {
      field: 'estimatedCost',
      headerName:
        i18nMessages.EstimatedCost +
        (customerCurrency ? ' (' + customerCurrency + ')' : ''),
      flex: 1,
      sortable: false,
    },
    {
      field: 'actualCost',
      headerName:
        i18nMessages.ActualCost +
        (customerCurrency ? ' (' + customerCurrency + ')' : ''),
      flex: 1,
      sortable: false,
      editable: true,
    },
    {
      field: 'sellingPrice',
      headerName:
        i18nMessages.SellingPrice +
        (customerCurrency ? ' (' + customerCurrency + ')' : ''),
      flex: 1,
      sortable: false,
    },
  ];

  const handleUpdateSentForApproval = async (status: any) => {
    if (validateVatPercent(status)) {
      let apiUrl: any = null;
      if (entityType == EntityTypeEnum.DecPackOrders) {
        apiUrl = new URL(
          environment.api.baseUrl +
            `DecPackOrder/UpdateOrderStatus/${entityId}/${status}`
        );
      } else if (entityType == EntityTypeEnum.ShippingOrders) {
        apiUrl = new URL(
          environment.api.baseUrl +
            `Shipping/UpdateOrderStatus/${entityId}/${status}`
        );
      }
      try {
        if (apiUrl != null) {
          await http.put(apiUrl.toString());
          toast.success(i18nMessages.CommonSaveSuccessMsg);
          handleIsOrderEnable(false);
          getOrderStatus();
        }
      } catch {
        toast.error(i18nMessages.CommonErrOccuredMsg);
      }
    }
  };

  const validateVatPercent = (status: any) => {
    if (status == ShippingOrderStatus.InvoiceApproved) {
      if (+customerVatPercent != 0 && +customerVatPercent != 21) {
        toast.error(i18nMessages.InvalidVatPercent);
        return false;
      }
    }
    return true;
  };

  const getDecPackOrders = async (id: number) => {
    const apiUrl = new URL(environment.api.baseUrl + `DecPackOrder/${id}`);
    try {
      const result = await http.get(apiUrl.toString());
      if (result && result.data) {
        setSentForApprovalByDetails({
          name: result.data.sentForApprovalByName,
          dateTime: result.data.sentForApprovalDateTime,
        });
        setApprovedByDetails({
          name: result.data.approvedByName,
          dateTime: result.data.approvedDateTime,
        });
        setCustomerVatPercent(result.data.customerVATPercent);
        setCustomerCurrency(result.data.customerCurrency);
      }
    } catch {
      toast.error(i18nMessages.CommonErrOccuredMsg);
    }
  };

  const getShippingOrders = async (id: number) => {
    const apiUrl = new URL(environment.api.baseUrl + `Shipping/${id}`);
    try {
      const result = await http.get(apiUrl.toString());
      if (result && result.data) {
        setSentForApprovalByDetails({
          name: result.data.sentForApprovalByName,
          dateTime: result.data.sentForApprovalDateTime,
        });
        setApprovedByDetails({
          name: result.data.approvedByName,
          dateTime: result.data.approvedDateTime,
        });
        setCustomerVatPercent(result.data.customerVATPercent);
        setCustomerCurrency(result.data.customerCurrency);
      }
    } catch {
      toast.error(i18nMessages.CommonErrOccuredMsg);
    }
  };

  const getShippingOrdersVATDetails = async (id: number) => {
    const apiUrl = new URL(
      environment.api.baseUrl + `Shipping/GetVatCalculationRules/${id}`
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result && result.data) {
        if (result.data.item1 && result.data.item1.length > 0) {
          let vatOptions: BaseModel[] = [];
          result.data.item1.forEach((element: any) => {
            vatOptions.push({
              label:
                element.customerTypeName +
                '-' +
                countryRegionList.find((x) => x.value == element.transportTo)
                  ?.label +
                '-' +
                element.description,
              value: element.id,
            });
          });
          setVatOptions(vatOptions);
          setVatData(result.data.item1);
        }
        if (result.data.item3) {
          setVatPercent(result.data.item3);
        }
        if (result.data.item2 && result.data.item2.id > 0) {
          setSelectedVatOption({
            label:
              result.data.item2.customerTypeName +
              '-' +
              countryRegionList.find(
                (x) => x.value == result.data.item2.transportTo
              )?.label +
              '-' +
              result.data.item2.description,
            value: result.data.item2.id,
          });
          setIsEligibleForVat(result.data.item2.isEligibleForVat);
        }
      }
    } catch {
      toast.error(i18nMessages.CommonErrOccuredMsg);
    }
  };

  const getDecPackOrdersVATDetails = async (id: number) => {
    const apiUrl = new URL(
      environment.api.baseUrl + `DecPackOrder/GetVatCalculationRules/${id}`
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result && result.data) {
        if (result.data.item1 && result.data.item1.length > 0) {
          let vatOptions: BaseModel[] = [];
          result.data.item1.forEach((element: any) => {
            vatOptions.push({
              label:
                element.customerTypeName +
                '-' +
                countryRegionList.find((x) => x.value == element.transportTo)
                  ?.label +
                '-' +
                element.description,
              value: element.id,
            });
          });
          setVatOptions(vatOptions);
          setVatData(result.data.item1);
        }
        if (result.data.item3) {
          setVatPercent(result.data.item3);
        }

        if (result.data.item2 && result.data.item2.id > 0) {
          setSelectedVatOption({
            label:
              result.data.item2.customerTypeName +
              '-' +
              countryRegionList.find(
                (x) => x.value == result.data.item2.transportTo
              )?.label +
              '-' +
              result.data.item2.description,
            value: result.data.item2.id,
          });
          setIsEligibleForVat(result.data.item2.isEligibleForVat);
        }
      }
    } catch {
      toast.error(i18nMessages.CommonErrOccuredMsg);
    }
  };

  const getMaterialForApproval = async () => {
    let apiUrl: any = null;
    if (entityType == EntityTypeEnum.ShippingOrders) {
      apiUrl = new URL(
        environment.api.baseUrl +
          `ShippingMaterial/ShippingMaterialForApproval/${entityId}`
      );
    } else {
      apiUrl = new URL(
        environment.api.baseUrl +
          `DecPackShippingMaterial/DecPackShippingMaterialForApproval/${entityId}`
      );
    }
    try {
      const result = await http.get(apiUrl.toString());
      if (result && result.data) {
        if (
          entityType == EntityTypeEnum.ShippingOrders &&
          result.data.shippingMaterialApprovalDto
        ) {
          updateGridData(
            'totalRows',
            result.data.shippingMaterialApprovalDto.length
          );
          updateGridData('rows', result.data.shippingMaterialApprovalDto);
          setTotalProfitLoss(result.data.totalProfitLoss);
        } else {
          if (result.data.decPackShippingMaterialApprovalDto) {
            updateGridData(
              'totalRows',
              result.data.decPackShippingMaterialApprovalDto.length
            );
            updateGridData(
              'rows',
              result.data.decPackShippingMaterialApprovalDto
            );
            setTotalProfitLoss(result.data.totalProfitLoss);
          }
        }
      }
    } catch {
      toast.error(i18nMessages.CommonErrOccuredMsg);
    }
  };

  const validate = () => {
    if (!selectedVatOption || selectedVatOption?.value == '') {
      setErrorVat(i18nMessages.CommonErrFieldRequired);
      return false;
    } else {
      setErrorVat('');
      return true;
    }
  };

  const saveVatDetails = async () => {
    if (validate()) {
      let apiUrl: any;
      if (entityType == EntityTypeEnum.ShippingOrders) {
        apiUrl = new URL(
          environment.api.baseUrl +
            `Shipping/UpdateVatCalculationRules/${entityId}/${Number(
              selectedVatOption?.value
            )}/${vatPercent}`
        );
      } else {
        apiUrl = new URL(
          environment.api.baseUrl +
            `DecPackOrder/UpdateVatCalculationRules/${entityId}/${Number(
              selectedVatOption?.value
            )}/${vatPercent}`
        );
      }
      try {
        const result = await http.put(apiUrl.toString());
        if (result) {
          toast.success(i18nMessages.CommonSaveSuccessMsg);
        }
      } catch {
        toast.error(i18nMessages.CommonErrOccuredMsg);
      }
    }
  };

  const getOrderStatus = () => {
    if (entityType == EntityTypeEnum.DecPackOrders) {
      getDecPackOrders(entityId);
      getDecPackOrdersVATDetails(entityId);
    } else if (entityType == EntityTypeEnum.ShippingOrders) {
      getShippingOrders(entityId);
      getShippingOrdersVATDetails(entityId);
    }
  };

  const setModels = (newModel: any) => {
    if (newModel[0] === undefined) {
      setRowModesModel(newModel);
    } else if (
      newModel[0] !== undefined &&
      newModel[0].cellToFocusAfter === 'right'
    ) {
      updateGridData(
        'rows',
        gridData.rows.filter(function (row: any) {
          return row.id !== 0;
        })
      );
      setRowModesModel(newModel);
    }
  };

  const handleProcessRowUpdateError = useCallback((error: Error) => {
    //toast.error(error.message);
  }, []);

  const save = async (values: any) => {
    let apiUrl: any;
    if (entityType == EntityTypeEnum.ShippingOrders) {
      apiUrl = new URL(
        environment.api.baseUrl +
          `ShippingMaterial/UpdateShippingMaterialApproval`
      );
    } else {
      apiUrl = new URL(
        environment.api.baseUrl +
          `DecPackShippingMaterial/UpdateDecPackShippingMaterialApproval`
      );
    }
    try {
      var finalValue = {
        shippingMaterialId: values.id,
        actualCost: values.actualCost,
      };
      const result = await http.put(apiUrl.toString(), finalValue);
      if (result) {
        toast.success(i18nMessages.CommonSaveSuccessMsg);
        return true;
      }
    } catch {
      toast.error(i18nMessages.CommonErrOccuredMsg);
    }

    return false;
  };

  async function handleProcessRowUpdate(newRow: GridRowModel) {
    let updatedRow: any = {};
    if (newRow.id === undefined) {
      updatedRow = { ...newRow, isNew: false, id: 0 };
    } else {
      updatedRow = { ...newRow, isNew: false };
    }
    var isSaved = await save(updatedRow);

    if (isSaved) {
      if (newRow.id === undefined) {
        var temp: any = [];
        for (var i = 0; i < gridData.rows.length; i++) {
          temp.push(gridData.rows[i]);
        }
        temp.push(updatedRow);
        updateGridData('rows', temp);
        updateGridData(
          'searchExpression',
          (Math.random() + 1).toString(36).substring(7)
        );
        return updatedRow;
      } else {
        updateGridData(
          'rows',
          gridData.rows.map((row: any) =>
            row.id === newRow.id ? updatedRow : row
          )
        );
        updateGridData(
          'searchExpression',
          (Math.random() + 1).toString(36).substring(7)
        );
        return updatedRow;
      }
    }
    return updatedRow;
  }

  const handleSelectedVatOption = (newValue: BaseModel) => {
    setSelectedVatOption(newValue);
    let vatRule: any = vatData.find((x: any) => x.id == newValue.value);
    if (vatRule) {
      setIsEligibleForVat(vatRule.isEligibleForVat);
      if (!vatRule.isEligibleForVat) {
        setVatPercent(0);
      } else {
        setVatPercent(customerVatPercent);
      }
    }
  };

  useEffect(() => {
    getOrderStatus();
  }, [entityId, entityType]);

  useEffect(() => {
    getMaterialForApproval();
  }, [
    gridData.page,
    gridData.pageSize,
    gridData.sortBy,
    gridData.sortOrder,
    gridData.searchExpression,
  ]);

  return (
    <>
      <Grid container spacing={2} className="grid-wrap">
        <Grid item lg={3} md={3}>
          {i18nMessages.OrderOwner}
        </Grid>
        <Grid item lg={9} md={9}>
          {!isApprover ? (
            <Button
              color="primary"
              disableElevation
              variant="contained"
              onClick={() =>
                handleSendForApproval(ShippingOrderStatus.SentForApproval)
              }
              disabled={!isOrderEnable}
            >
              {isOrderEnable
                ? i18nMessages.SendForApproval
                : i18nMessages.SentForApproval}
            </Button>
          ) : (
            <></>
          )}
          {sentForApprovalByDetails.name ? (
            <div>
              <span>
                {`${i18nMessages.SentBy}: `} {sentForApprovalByDetails.name}{' '}
                {Utilities.convertHazgoDataTimeFormat(
                  sentForApprovalByDetails.dateTime
                )}
              </span>
            </div>
          ) : (
            <></>
          )}
        </Grid>

        {isApprover ? (
          <>
            <Grid item lg={3} md={3}>
              {I18n('Approval.Approver')}
            </Grid>
            <Grid item lg={9} md={9}>
              <Button
                color="primary"
                disableElevation
                variant="contained"
                onClick={() =>
                  handleSendForApproval(ShippingOrderStatus.InvoiceApproved)
                }
                disabled={!isOrderEnable}
              >
                {I18n('Approval.Approve')}
              </Button>
              {approvedByDetails.name ? (
                <div>
                  <span>
                    {`${i18nMessages.ApprovedBy}: `} {approvedByDetails.name}{' '}
                    {Utilities.convertHazgoDataTimeFormat(
                      approvedByDetails.dateTime
                    )}
                  </span>
                </div>
              ) : (
                <></>
              )}
            </Grid>
            <Grid item lg={12} md={12}>
              <Typography variant="h5" className="inner-heading has-button">
                {I18n('ShippingOrders.Tabs.BillableItems')}
              </Typography>
              <CustomDataGridInline
                rowCount={gridData.totalRows}
                data={gridData.rows}
                columns={columns}
                onSortModelChange={handleSortModelChange}
                pageNo={gridData.page}
                pageSize={gridData.pageSize}
                rowModesModel={rowModesModel}
                onRowModesModelChange={(newModel: any) => {
                  setModels(newModel);
                }}
                onPageChange={handleChangePage}
                onPageSizeChange={handleChangePageSize}
                onRowEditStart={handleRowEditStart}
                rowsPerPageOptions={gridData.rowsPerPageOptions}
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'updatedDate', sort: 'desc' }],
                  } as GridSortingInitialState,
                  pagination: {
                    page: gridData.page,
                    pageSize: gridData.pageSize,
                  } as GridPaginationInitialState,
                }}
                componentsProps={{
                  toolbar: { setRows, setRowModesModel },
                }}
                processRowUpdate={handleProcessRowUpdate}
                onProcessRowUpdateError={handleProcessRowUpdateError}
              ></CustomDataGridInline>
            </Grid>
            <Grid item lg={2} md={2}>
              {I18n('Approval.TotalProfitLoss')}
            </Grid>
            <Grid item lg={10} md={10}>
              {totalProfitLoss > 0 ? (
                <label>{totalProfitLoss}</label>
              ) : (
                <label style={{ color: 'red' }}>{totalProfitLoss}</label>
              )}
            </Grid>
            <br />
            <br />
            <Grid item lg={12} md={12}>
              <Typography variant="h5" className="inner-heading has-button">
                {I18n('Approval.VATDetails')}
              </Typography>
            </Grid>
            <Grid item lg={8} md={8}>
              <CustomizedSelect
                options={vatOptions}
                isSearchable={false}
                displayLabel={I18n('Approval.VAT')}
                handleChange={(newValue: any) =>
                  handleSelectedVatOption(newValue)
                }
                value={selectedVatOption}
                errorValue={true}
                errorMessage={errorVat}
                readOnly={!isOrderEnable}
              ></CustomizedSelect>
            </Grid>
            <Grid item lg={12} md={12}>
              <Button
                color="primary"
                className="small-btn"
                disableElevation
                variant="contained"
                disabled={!isOrderEnable}
                onClick={saveVatDetails}
                sx={{
                  right: 8,
                }}
              >
                {i18nMessages.Save}
              </Button>
            </Grid>
          </>
        ) : (
          <></>
        )}
      </Grid>

      <ConfirmationPopup
        isOpen={confirmModal.isOpen}
        data={entityId}
        title={confirmModal.title}
        handleClose={handleClose}
        handleOk={handleOk}
        message={confirmModal.message}
      ></ConfirmationPopup>
    </>
  );
}
