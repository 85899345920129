import { PaginationResponseModel } from 'models/pagination-model';
import environment from 'environment';
import http from '../../utilities/httpService';
import { SettingsKeyValue, SettingsType } from 'models/customer';

export const getSettings = async (
  search: string = '',
  pageNo: number = 1,
  type: SettingsType = SettingsType.CustomerTypes
) => {
  let response: PaginationResponseModel<SettingsKeyValue>;
  let apiUrl;

  switch (type) {
    case SettingsType.CurrencyMaster:
      apiUrl = new URL(environment.api.baseUrl + 'Customer/GetCurrencyMaster');
      break;
    case SettingsType.InvoicingCycles:
      apiUrl = new URL(environment.api.baseUrl + 'Customer/GetInvoicingCycles');
      break;
    case SettingsType.CustomerTypes:
    default:
      apiUrl = new URL(environment.api.baseUrl + 'Customer/GetCustomerTypes');
      break;
  }

  try {
    let searchExpression = '';
    searchExpression += search ? `value~like~${search}` : '';
    apiUrl.searchParams.set('$filter', searchExpression);
    apiUrl.searchParams.set('$orderby', 'value');
    apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
    apiUrl.searchParams.set('$top', '1000');
    apiUrl.searchParams.set('$orderbydirection', 'desc');
    const result = await http.get(apiUrl.toString());
    if (result) {
      response = {
        TotalRecords: result.data.totalCount,
        Values: result.data.values,
      } as PaginationResponseModel<SettingsKeyValue>;
    } else {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<SettingsKeyValue>;
    }
  } catch (error) {
    response = {
      TotalRecords: 0,
      Values: [],
    } as PaginationResponseModel<SettingsKeyValue>;
  }
  return response;
};
