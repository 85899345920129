import {
  Breadcrumbs,
  Button,
  Fade,
  Grid,
  IconButton,
  Paper,
  Popper,
  PopperPlacementType,
  Typography,
  Checkbox,
  FormControlLabel,
  InputAdornment,
} from '@mui/material';
import {
  GridActionsCellItem,
  GridSortModel,
  GridColumns,
  GridRowParams,
  GridSortingInitialState,
  GridPaginationInitialState,
} from '@mui/x-data-grid';
import CustomDataGrid from 'common/datagrid/datagrid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  BaseModel,
  CommonListRequestModel,
  CommonPaginationResponseModel,
  OrderByDirection,
  WorkOrderStatus,
  ActivityOwner,
  EntityWorkOrderStatusDescription,
  ActivityOwnerDescription,
  FilterScreens,
} from 'models/pagination-model';
import {
  ShippingOrderListDTO,
  ShippingWorkOrdersDescription,
} from 'models/shipping-orders';
import FormControl from 'common/formControl/formControl';
import { Permission } from 'Permissions';
import RouteEnum from 'models/RouteEnum';
import { useNavigate } from 'react-router-dom';
import I18n from 'utilities/i18n';
import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { hasPermission } from 'utilities/protectedRoute';
import { faFilter, faTimes, faSearch } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import environment from 'environment';
import http from '../../utilities/httpService';
import CustomDateRangePicker from 'common/dateRange/dateRange';
import { addDays } from 'date-fns';
import CustomizedSelect from 'common/CustomSelect/CustomSelect';
import EditIcon from '@mui/icons-material/Edit';

import {
  ExportAllShippingOrders,
  getAllShippingOrders,
} from './ShippingApiService';
import { toast } from 'react-toastify';

export default function ShipppingAllOrdersListing() {
  let navigate = useNavigate();
  const breadcrumbs = [
    <Typography key="3" color="text.primary">
      {I18n('Customer.Shipping')}
    </Typography>,
    <Typography key="3" color="text.primary">
      {I18n('Nav.Order')}
    </Typography>,
  ];

  const [gridData, setGridData] = useState<CommonPaginationResponseModel<any>>({
    isLoading: true,
    sortOrder: OrderByDirection.Descending,
    sortBy: 'shippingOrders.orderDate',
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [10, 20, 50, 100],
    pageSize: 10,
    page: 0,
    searchExpression: '',
  });

  const dateFormat = 'YYYY-MM-DDTHH:mm:ss[Z]';
  const [advanceSearchExpression, setAdvanceSearchExpression] = useState(
    `shippingOrders.expectedPickupDateTime~>~${moment(addDays(new Date(), -7))
      .set({ h: 0, m: 0, s: 1 })
      .format(dateFormat)} and shippingOrders.expectedPickupDateTime~<~${moment(
      new Date()
    )
      .set({ h: 23, m: 59, s: 59 })
      .format(dateFormat)}`
  );
  const [basicSearchExpression, setBasicSearchExpression] = useState('');
  const [fromDate, setFromDate] = useState(
    moment(addDays(new Date(), -7)).set({ h: 0, m: 0, s: 1 }).format(dateFormat)
  );
  const [toDate, setToDate] = useState(
    moment(new Date()).set({ h: 23, m: 59, s: 59 }).format(dateFormat)
  );
  const [isFilterEnabled, setIsFilterEnabled] = useState(false);

  const defaultStatusValue = {
    value: '',
    label: I18n('Placeholders.Select'),
  } as BaseModel;

  const [selectedActivityOwner, setSelectedActivityOwner] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedWorkOrderType, setSelectedWorkOrderType] = useState([]);
  const [customerReferenceFilter, setCustomerReferenceFilter] = useState<any>();
  const [isIgnoreDateFilter, setIsIgnoreDateFilter] = useState<boolean>(false);
  const [WODate, setWODate] = useState<any>('');
  const [lastUpdatedDate, setLastUpdatedDate] = useState<any>('');
  const [isPast, setIsPast] = useState(false);
  const [isToday, setIsToday] = useState(false);
  const [isFuture, setIsFuture] = useState(false);
  const [refreshGrid, setRefreshGrid] = useState(false);
  const [typeId, setTypeId] = useState([]);
  const [activityOwnerId, setActivityOwnerId] = useState([]);
  const [statusId, setStatusId] = useState([]);

  let workOrderTypeList: Array<BaseModel> = [];
  const getWorkOrderTypes = () => {
    workOrderTypeList = [];
    ShippingWorkOrdersDescription.forEach((value: string, key: number) => {
      workOrderTypeList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getWorkOrderTypes();

  let statusList: Array<BaseModel> = [];
  const getDecPackStatus = () => {
    statusList = [];
    EntityWorkOrderStatusDescription.forEach((value: string, key: number) => {
      statusList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getDecPackStatus();

  let activityOwner: Array<BaseModel> = [];
  const getActivityOwner = () => {
    activityOwner = [];
    ActivityOwnerDescription.forEach((value: string, key: number) => {
      activityOwner.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getActivityOwner();

  const i18nMessages = {
    deleteOrderSuccess: I18n('DecPackOrders.DeleteSuccess'),
    deleteOrderCofirmation: I18n('DecPackOrders.DeleteConfirmation'),
    errorOccured: I18n('ShippingOrders.ErrorOccurred'),
    OrderDate: I18n('DecPackOrders.Table.OrderDate'),
    Customer: I18n('DecPackOrders.Table.Customer'),
    ServiceType: I18n('DecPackOrders.Table.ServiceType'),
    HazGoReference: I18n('DecPackOrders.Table.HazGoReference'),
    Location: I18n('DecPackOrders.Table.Location'),
    WoDoneVsTotal: I18n('DecPackOrders.Table.WoDoneVsTotal'),
    Type: I18n('DecPackOrders.Table.Type'),
    Pickup: I18n('DecPackOrders.Table.Pickup'),
    FinalDestination: I18n('DecPackOrders.Table.FinalDestination'),
    Status: I18n('DecPackOrders.Table.Status'),
    Attention: I18n('DecPackOrders.Table.Attention'),
    Actions: I18n('Common.Actions'),
    CustomerReference: I18n('DecPackOrders.Table.CustomerReference'),
    DGDCreation: I18n('WorkOrder.DGDCreation'),
    SecurityCheckRA: I18n('WorkOrder.SecurityCheckRA'),
    Inboundprocess: I18n('WorkOrder.Inboundprocess'),
    Collectingshipmentdata: I18n('WorkOrder.Collectingshipmentdata'),
    CheckDocumentation: I18n('WorkOrder.CheckDocumentation'),
    Material: I18n('WorkOrder.Material'),
    Executionorder: I18n('WorkOrder.Executionorder'),
    Deliveryorderassigning: I18n('WorkOrder.Deliveryorderassigning'),
    Deliveryorder: I18n('WorkOrder.Deliveryorder'),
    Irregularitycontactclient: I18n('WorkOrder.Irregularitycontactclient'),
    BillofMaterial: I18n('WorkOrder.BillofMaterial'),
    PhotoCheck: I18n('WorkOrder.PhotoCheck'),
    Outboundprocess: I18n('WorkOrder.Outboundprocess'),
    ApprovalBillofMaterial: I18n('WorkOrder.ApprovalBillofMaterial'),
    InputmissingCustomerreference: I18n(
      'WorkOrder.InputmissingCustomerreference'
    ),
    ApprovalInvoice: I18n('WorkOrder.ApprovalInvoice'),
  };

  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));
  const manageFilterStatus = () => {
    setIsFilterEnabled(
      selectedWorkOrderType.length > 0 ||
        selectedActivityOwner.length > 0 ||
        selectedStatus.length > 0 ||
        isIgnoreDateFilter ||
        (!isPast && !isToday && !isFuture)
        ? true
        : false
    );
  };

  const getDateFilter = (dateFilters: any) => {
    if (dateFilters.isPast && dateFilters.isToday && dateFilters.isFuture) {
      return '';
    } else if (
      !dateFilters.isPast &&
      dateFilters.isToday &&
      dateFilters.isFuture
    ) {
      return `shippingOrders.expectedPickupDateTime~>=~${moment(new Date())
        .set({ h: 0, m: 0, s: 0 })
        .format(dateFormat)}`;
    } else if (
      dateFilters.isPast &&
      !dateFilters.isToday &&
      dateFilters.isFuture
    ) {
      return `shippingOrders.expectedPickupDateTime~<~${moment(new Date())
        .set({ h: 0, m: 0, s: 0 })
        .format(
          dateFormat
        )} or shippingOrders.expectedPickupDateTime~>~${moment(new Date())
        .set({ h: 23, m: 59, s: 59 })
        .format(dateFormat)}`;
    } else if (
      dateFilters.isPast &&
      dateFilters.isToday &&
      !dateFilters.isFuture
    ) {
      return `shippingOrders.expectedPickupDateTime~<=~${moment(new Date())
        .set({ h: 23, m: 59, s: 59 })
        .format(dateFormat)}`;
    } else if (
      dateFilters.isPast &&
      !dateFilters.isToday &&
      !dateFilters.isFuture
    ) {
      return `shippingOrders.expectedPickupDateTime~<~${moment(new Date())
        .set({ h: 0, m: 0, s: 0 })
        .format(dateFormat)}`;
    } else if (
      !dateFilters.isPast &&
      dateFilters.isToday &&
      !dateFilters.isFuture
    ) {
      return `shippingOrders.expectedPickupDateTime~>~${moment(new Date())
        .set({ h: 0, m: 0, s: 0 })
        .format(
          dateFormat
        )} and shippingOrders.expectedPickupDateTime~<~${moment(new Date())
        .set({ h: 23, m: 59, s: 59 })
        .format(dateFormat)}`;
    } else if (
      !dateFilters.isPast &&
      !dateFilters.isToday &&
      dateFilters.isFuture
    ) {
      return `shippingOrders.expectedPickupDateTime~>~${moment(new Date())
        .set({ h: 23, m: 59, s: 59 })
        .format(dateFormat)}`;
    } else {
      return '';
    }
  };

  const getShippingOrderList = (rawFilters?: any) => {
    updateGridData('isLoading', true);
    var dateFilter = rawFilters != undefined ? getDateFilter(rawFilters) : '';
    var isDateFilterApplicable =
      rawFilters != undefined
        ? rawFilters.isPast || rawFilters.isToday || rawFilters.isFuture
        : false;
    var filterData: CommonListRequestModel<ShippingOrderListDTO> = {
      advanceSearchExpression: isDateFilterApplicable
        ? dateFilter
        : rawFilters != undefined && rawFilters.isIgnoreDateFilter
        ? ''
        : advanceSearchExpression,
      basicSearchExpression,
      gridData,
    };
    var typeFilter =
      rawFilters != undefined && rawFilters.typeFilter.length > 0
        ? rawFilters.typeFilter.join(',')
        : '';
    var activtyOwnerFilter =
      rawFilters != undefined && rawFilters.activtyOwnerFilter.length > 0
        ? rawFilters.activtyOwnerFilter.join(',')
        : '';

    var statusFilter;
    if (rawFilters !== undefined) {
      if (rawFilters.isPast || rawFilters.isToday || rawFilters.isFuture) {
        statusFilter = WorkOrderStatus.ToDo + ',' + WorkOrderStatus.InProgress;
      } else if (rawFilters.statusFilter.length > 0) {
        statusFilter = rawFilters.statusFilter.join(',');
      } else {
        statusFilter = '';
      }
    } else {
      statusFilter = '';
    }

    getAllShippingOrders(
      filterData,
      typeFilter,
      activtyOwnerFilter,
      statusFilter
    ).then((x: any) => {
      if (x) {
        updateGridData('totalRows', x?.gridData.totalRows);
        updateGridData('rows', x?.gridData.rows);
        updateGridData('isLoading', false);
        manageFilterStatus();
      }
    });
  };

  const getFilters = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl +
        'CustomizedFilter?filterScreen=' +
        FilterScreens.ShippingWOs
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result) {
        var rawFilters = setRawFilters(result.data.uiFilter);

        await getShippingOrderList(rawFilters);
      }
    } catch {}
  };

  const setRawFilters = (uiFilters: string) => {
    var filters = uiFilters != undefined ? uiFilters.split('|') : [];
    if (filters.length > 0) {
      var typeFilter: any = [];
      var activtyOwnerFilter: any = [];
      var statusFilter: any = [];
      var isIgnoreDateFilter = false;
      var isPast = false;
      var isToday = false;
      var isFuture = false;
      filters.forEach((element: any) => {
        if (element.includes('TypeId')) {
          var filterPart2 = element.split('&');
          typeFilter.push({
            value: filterPart2[0].split('=')[1],
            label: filterPart2[1].split('=')[1],
          });
        } else if (element.includes('ActivityId')) {
          var filterPart3 = element.split('&');
          activtyOwnerFilter.push({
            value: filterPart3[0].split('=')[1],
            label: filterPart3[1].split('=')[1],
          });
        } else if (element.includes('StatusId')) {
          var filterPart4 = element.split('&');
          statusFilter.push({
            value: filterPart4[0].split('=')[1],
            label: filterPart4[1].split('=')[1],
          });
        } else if (element.includes('isIgnoreDateFilter')) {
          var filterPart5 = element.split('=');
          setIsIgnoreDateFilter(filterPart5[1] === '1' ? true : false);
          isIgnoreDateFilter = filterPart5[1] === '1' ? true : false;
        } else if (element.includes('isPast')) {
          var filterPart6 = element.split('=');
          setIsPast(filterPart6[1] === '1' ? true : false);
          isPast = filterPart6[1] === '1' ? true : false;
        } else if (element.includes('isToday')) {
          var filterPart7 = element.split('=');
          setIsToday(filterPart7[1] === '1' ? true : false);
          isToday = filterPart7[1] === '1' ? true : false;
        } else if (element.includes('isFuture')) {
          var filterPart8 = element.split('=');
          setIsFuture(filterPart8[1] === '1' ? true : false);
          isFuture = filterPart8[1] === '1' ? true : false;
        }
      });

      setSelectedWorkOrderType(typeFilter);

      setSelectedActivityOwner(activtyOwnerFilter);

      setSelectedStatus(statusFilter);

      setTypeId(
        typeFilter.map((x: any) => {
          return x.value;
        })
      );

      setActivityOwnerId(
        activtyOwnerFilter.map((x: any) => {
          return x.value;
        })
      );

      setStatusId(
        statusFilter.map((x: any) => {
          return x.value;
        })
      );

      var dropdownFilters = {
        typeFilter: typeFilter.map((x: any) => {
          return x.value;
        }),
        activtyOwnerFilter: activtyOwnerFilter.map((x: any) => {
          return x.value;
        }),
        statusFilter: statusFilter.map((x: any) => {
          return x.value;
        }),
        isIgnoreDateFilter,
        isPast,
        isToday,
        isFuture,
      };
      return dropdownFilters;
    }
  };

  useEffect(() => {
    getFilters();
  }, [
    gridData.page,
    gridData.pageSize,
    gridData.sortBy,
    gridData.sortOrder,
    gridData.searchExpression,
    advanceSearchExpression,
    basicSearchExpression,
    refreshGrid,
  ]);
  const handleChangePage = (pageNo: number) => {
    updateGridData('page', pageNo);
  };

  const handleChangePageSize = (pageSize: number) => {
    updateGridData('pageSize', pageSize);
  };

  type Row = (typeof gridData.rows)[number];
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState<PopperPlacementType>();

  const editWO = useCallback(
    (data: any) => () => {
      data.wONo = Number(data.row.workOrderNo);
      if (data.wONo === 1) {
        navigate(
          RouteEnum.ViewShippingOrders +
            '/' +
            data.row.shippingOrderId +
            '/Booking/' +
            data.id
        );
      }
      if (data.wONo === 2) {
        navigate(
          RouteEnum.ViewShippingOrders +
            '/' +
            data.row.shippingOrderId +
            '/DGD/' +
            data.row.workOrderNo
        );
      }
      if (data.wONo === 3) {
        navigate(
          RouteEnum.ViewShippingOrders +
            '/' +
            data.row.shippingOrderId +
            '/pickup/' +
            data.row.workOrderNo +
            '/' +
            data.id
        );
      }
      if (data.wONo === 6) {
        navigate(
          RouteEnum.ViewShippingOrders +
            '/' +
            data.row.shippingOrderId +
            '/outbound/' +
            data.row.workOrderNo +
            '/' +
            data.id
        );
      }
      if (data.wONo === 4) {
        navigate(
          RouteEnum.ViewShippingOrders +
            '/' +
            data.row.shippingOrderId +
            '/inbound/' +
            data.row.workOrderNo +
            '/' +
            data.id
        );
      }
      if (data.wONo === 5) {
        navigate(
          RouteEnum.ViewShippingOrders +
            '/' +
            data.row.shippingOrderId +
            '/Packaging/' +
            data.row.workOrderNo +
            '/' +
            data.id
        );
      }
      if (data.wONo === 7) {
        navigate(
          RouteEnum.ViewShippingOrders +
            '/' +
            data.row.shippingOrderId +
            '/Delivery/' +
            data.row.workOrderNo +
            '/' +
            data.id
        );
      }
      if (data.wONo === 8) {
        navigate(
          RouteEnum.ViewShippingOrders +
            '/' +
            data.row.shippingOrderId +
            '/document-check/' +
            data.row.workOrderNo +
            '/' +
            data.id
        );
      }
      if (data.wONo === 9) {
        navigate(
          RouteEnum.ViewShippingOrders +
            '/' +
            data.row.shippingOrderId +
            '/physical-check/' +
            data.row.workOrderNo +
            '/' +
            data.id
        );
      }
    },
    []
  );

  const columns: GridColumns<Row> = [
    {
      field: 'orderReference',
      headerName: i18nMessages.CustomerReference,
      flex: 1,
      sortable: true,
      renderCell: (params) => params?.row?.shippingOrders?.orderReference,
    },
    {
      field: 'workOrderNo',
      headerName: I18n('DecPackOrders.WorkOrder.WONo'),
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return 'WO' + params.formattedValue;
      },
    },
    {
      field: 'type',
      headerName: I18n('DecPackOrders.WorkOrder.Type'),
      width: 260,
      sortable: false,
      renderCell: (params) => {
        return params.formattedValue;
      },
    },
    {
      field: 'workOrderDate',
      headerName: I18n('DecPackOrders.WorkOrder.WODate'),
      flex: 1,
      sortable: true,
      valueFormatter: (params) =>
        params.value ? moment(params?.value).format('DD/MM/YYYY') : '',
    },
    {
      field: 'activityOwner',
      headerName: I18n('DecPackOrders.WorkOrder.ActivityOwner'),
      width: 150,
      sortable: true,
      renderCell: (params: any) => {
        if (params.formattedValue === ActivityOwner.BackOffice) {
          return I18n('DecPackOrders.WorkOrder.BackOffice');
        } else if (params.formattedValue === ActivityOwner.Driver) {
          return I18n('DecPackOrders.WorkOrder.Driver');
        } else if (params.formattedValue === ActivityOwner.Partner) {
          return I18n('DecPackOrders.WorkOrder.Partner');
        } else if (params.formattedValue === ActivityOwner.DecNPack) {
          return I18n('ShippingOrders.WorkOrder.DecNPack');
        }
      },
    },
    {
      field: 'assignedToName',
      headerName: I18n('DecPackOrders.WorkOrder.AssignedTo'),
      width: 120,
      sortable: true,
    },
    {
      field: 'updatedDate',
      headerName: I18n('DecPackOrders.WorkOrder.LastUpdateOn'),
      width: 125,
      sortable: true,
      valueFormatter: (params) => moment(params?.value).format('DD/MM/YYYY'),
    },
    {
      field: 'status',
      headerName: I18n('DecPackOrders.WorkOrder.Status'),
      flex: 1,
      sortable: true,
      renderCell: (params: any) => {
        if (params.formattedValue === WorkOrderStatus.ToDo) {
          return I18n('Common.ToDo');
        } else if (params.formattedValue === WorkOrderStatus.InProgress) {
          return I18n('Common.InProgress');
        } else if (params.formattedValue === WorkOrderStatus.Done) {
          return I18n('Common.OrderCompleted');
        } else {
          return '';
        }
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: I18n('Common.Actions'),
      width: 80,
      getActions: (params: GridRowParams) => {
        const actionButtons = [];

        actionButtons.push(
          <GridActionsCellItem
            onPointerEnterCapture={false}
            onPointerLeaveCapture={false}
            icon={<EditIcon />}
            placeholder={''}
            label="Edit"
            onClick={editWO(params)}
          />
        );

        return actionButtons;
      },
    },
  ];

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    if (sortModel && sortModel.length) {
      if (sortModel[0].field === 'orderReference') {
        updateGridData('sortBy', 'ShippingOrders.OrderReference');
      } else {
        updateGridData('sortBy', sortModel[0].field);
      }
      updateGridData(
        'sortOrder',
        sortModel[0].sort === 'asc'
          ? OrderByDirection.Ascending
          : OrderByDirection.Descending
      );
    }
  }, []);

  const handleResetClick = () => {
    setCustomerReferenceFilter('');
    setSelectedActivityOwner([]);
    setSelectedWorkOrderType([]);
    setSelectedStatus([]);
    setAdvanceSearchExpression('');
    setWODate('');
    setLastUpdatedDate('');
    setIsIgnoreDateFilter(false);
  };
  const appendAdvanceSearchExpressions = (
    searchExpression: string,
    type: string
  ) => {
    let searchExpressionLocal: string = searchExpression;
    switch (type) {
      case 'and':
        searchExpressionLocal = searchExpressionLocal ? ` and ` : ``;
        break;
      case 'or':
        searchExpressionLocal = searchExpressionLocal ? ` or ` : ``;
        break;
      case '|':
        searchExpressionLocal = searchExpressionLocal ? `|` : ``;
        break;
      default:
        break;
    }
    return searchExpressionLocal;
  };

  const handleExportClick = async () => {
    const filterApiUrl = new URL(
      environment.api.baseUrl +
        'CustomizedFilter?filterScreen=' +
        FilterScreens.ShippingWOs
    );
    try {
      const result = await http.get(filterApiUrl.toString());
      if (result) {
        var rawFilters = setRawFilters(result.data.uiFilter);

        var dateFilter =
          rawFilters != undefined ? getDateFilter(rawFilters) : '';
        var isDateFilterApplicable =
          rawFilters != undefined
            ? rawFilters.isPast || rawFilters.isToday || rawFilters.isFuture
            : false;
        var filterData: CommonListRequestModel<ShippingOrderListDTO> = {
          advanceSearchExpression: isDateFilterApplicable
            ? dateFilter
            : rawFilters != undefined && rawFilters.isIgnoreDateFilter
            ? ''
            : advanceSearchExpression,
          basicSearchExpression,
          gridData,
        };
        var typeFilter =
          rawFilters != undefined && rawFilters.typeFilter.length > 0
            ? rawFilters.typeFilter.join(',')
            : '';
        var activtyOwnerFilter =
          rawFilters != undefined && rawFilters.activtyOwnerFilter.length > 0
            ? rawFilters.activtyOwnerFilter.join(',')
            : '';
        var statusFilter =
          rawFilters != undefined && rawFilters.statusFilter.length > 0
            ? rawFilters.statusFilter.join(',')
            : '';

        var resultData = await ExportAllShippingOrders(
          filterData,
          typeFilter,
          activtyOwnerFilter,
          statusFilter
        );
        if (resultData) {
          const file = new Blob([resultData.data], {
            type: 'application/vnd.ms-excel',
          }); //Build a URL from the file
          const fileURL = window.URL.createObjectURL(file); //Open the URL on new Window
          const link = document.createElement('a');
          link.href = fileURL;
          link.setAttribute('download', `Export.xls`);
          link.click();
          document.body.appendChild(link);
        }
      }
    } catch {
      toast.error(i18nMessages.errorOccured);
    }
  };

  const handleAdvanceSearchFilterClick = () => {
    let searchExpression = '';
    var typeFilter: any = [];
    var activityOwnerFilter: any = [];
    var statusFilter: any = [];
    if (selectedWorkOrderType && selectedWorkOrderType.length > 0) {
      selectedWorkOrderType.forEach((element: any) => {
        typeFilter.push(element.value);
      });
    }

    if (selectedActivityOwner && selectedActivityOwner.length > 0) {
      selectedActivityOwner.forEach((element: any) => {
        activityOwnerFilter.push(element.value);
      });
    }

    if (statusFilter && statusFilter.length > 0) {
      statusFilter.forEach((element: any) => {
        statusFilter.push(element.value);
      });
    }

    if (isIgnoreDateFilter) {
      searchExpression = '';
    } else if (fromDate && toDate) {
      setAdvanceSearchExpression(
        `shippingOrders.expectedPickupDateTime~>~${fromDate} and shippingOrders.expectedPickupDateTime~<~${toDate}`
      );
    }
    setTypeId(typeFilter);
    setActivityOwnerId(activityOwnerFilter);
    setStatusId(statusFilter);
    setOpen(false);
    var rawFilter = getRawFilter(isPast, isToday, isFuture);
    CustomizedFilterChange(searchExpression, rawFilter);
  };

  const popperHandleClick =
    (newPlacement: PopperPlacementType) =>
    (event: MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setOpen((prev) => placement !== newPlacement || !prev);
      setPlacement(newPlacement);
    };

  const handleChangeDateFilterSearch = debounce(
    (fromDate: any, toDate: any) => {
      if (fromDate && toDate) {
        var from = moment(fromDate, 'ddd MMM D YYYY');
        from.set({ h: 0, m: 0, s: 1 });
        var to = moment(toDate, 'ddd MMM D YYYY');
        to.set({ h: 23, m: 59, s: 59 });

        setFromDate(from.format(dateFormat));
        setToDate(to.format(dateFormat));
      }
      setIsIgnoreDateFilter(false);
    },
    500
  );

  const handleChangeBasicSearch = debounce((searchExpression: any) => {
    var expression = '';
    if (searchExpression.target.value) {
      expression =
        'ShippingOrders.OrderReference~like~' + searchExpression.target.value;
    }
    setBasicSearchExpression(expression);
  }, 500);

  const getRawFilter = (
    isPast: boolean,
    isToday: boolean,
    isFuture: boolean
  ) => {
    var rawsearchExpression = '';
    if (selectedWorkOrderType && selectedWorkOrderType.length > 0) {
      selectedWorkOrderType.forEach((element: any) => {
        rawsearchExpression += element.value
          ? appendAdvanceSearchExpressions(rawsearchExpression, '|') +
            `TypeId=${element.value}&TypeName=${element.label}`
          : '';
      });
    }
    if (selectedActivityOwner && selectedActivityOwner.length > 0) {
      selectedActivityOwner.forEach((element: any) => {
        rawsearchExpression += element.value
          ? appendAdvanceSearchExpressions(rawsearchExpression, '|') +
            `ActivityId=${element.value}&ActivityName=${element.label}`
          : '';
      });
    }

    if (selectedStatus && selectedStatus.length > 0) {
      selectedStatus.forEach((element: any) => {
        rawsearchExpression += element.value
          ? appendAdvanceSearchExpressions(rawsearchExpression, '|') +
            `StatusId=${element.value}&StatusName=${element.label}`
          : '';
      });
    }
    rawsearchExpression +=
      appendAdvanceSearchExpressions(rawsearchExpression, '|') +
      `isPast=${isPast ? 1 : 0}`;

    rawsearchExpression +=
      appendAdvanceSearchExpressions(rawsearchExpression, '|') +
      `isToday=${isToday ? 1 : 0}`;

    rawsearchExpression +=
      appendAdvanceSearchExpressions(rawsearchExpression, '|') +
      `isFuture=${isFuture ? 1 : 0}`;

    rawsearchExpression +=
      appendAdvanceSearchExpressions(rawsearchExpression, '|') +
      `isIgnoreDateFilter=${isIgnoreDateFilter ? 1 : 0}`;

    return rawsearchExpression;
  };

  const handlePastClick = (event: any) => {
    setIsPast(event.target.checked);
    var rawFilter = getRawFilter(event.target.checked, isToday, isFuture);
    CustomizedFilterChange('', rawFilter);
  };

  const handleTodayClick = (event: any) => {
    setIsToday(event.target.checked);
    var rawFilter = getRawFilter(isPast, event.target.checked, isFuture);
    CustomizedFilterChange('', rawFilter);
  };

  const handleFutureClick = (event: any) => {
    setIsFuture(event.target.checked);
    var rawFilter = getRawFilter(isPast, isToday, event.target.checked);
    CustomizedFilterChange('', rawFilter);
  };

  const CustomizedFilterChange = async (
    rawFilter: string,
    uiFilter: string
  ) => {
    var data = {
      RawFilter: rawFilter,
      UIFilter: uiFilter,
      FilterScreens: FilterScreens.ShippingWOs,
    };
    const apiUrl = new URL(environment.api.baseUrl + 'CustomizedFilter');
    try {
      await http.post(apiUrl.toString(), data);
      setRefreshGrid(!refreshGrid);
    } catch {}
  };

  return (
    <div>
      <div className="heading-section">
        <div className="heading-section-left">
          <Typography variant="h3" sx={{ mb: 3 }}>
            {I18n('Nav.ShippingOrders')}
          </Typography>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>
        <div className="heading-section-right">
          <div className="search-control-outer">
            <FormControl
              margin="normal"
              displayLabel=""
              required
              inputType="text"
              placeholderText={I18n('Common.Search')}
              className="search-control small-form-control"
              handleChange={handleChangeBasicSearch}
              adornmentValue={
                <InputAdornment position="start">
                  <FontAwesomeIcon icon={faSearch} />
                </InputAdornment>
              }
            ></FormControl>
          </div>
          <div className="header-btn-wrap">
            <Popper
              open={open}
              anchorEl={anchorEl}
              placement={placement}
              transition
              className="filter-popper"
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper>
                    <Typography variant="h5" className="filter-heading">
                      Filter
                      <IconButton
                        aria-label="delete"
                        onClick={popperHandleClick('bottom-end')}
                      >
                        <FontAwesomeIcon
                          icon={faTimes}
                          className="fa-sm close-icon"
                        />
                      </IconButton>
                    </Typography>
                    <div className="filter-content form-small-gap">
                      <Grid
                        container
                        spacing={2}
                        className="grid-wrap two-col"
                      ></Grid>

                      <Grid item xs={12}>
                        <CustomizedSelect
                          placeholder={defaultStatusValue.label}
                          options={workOrderTypeList}
                          isSearchable={false}
                          displayLabel={I18n('DecPackOrders.WorkOrder.Type')}
                          handleChange={(newValue: any) =>
                            setSelectedWorkOrderType(newValue)
                          }
                          value={selectedWorkOrderType}
                          isMulti={true}
                        ></CustomizedSelect>
                      </Grid>

                      <Grid item xs={12}>
                        <CustomizedSelect
                          placeholder={defaultStatusValue.label}
                          options={activityOwner}
                          isSearchable={false}
                          displayLabel={I18n(
                            'DecPackOrders.WorkOrder.ActivityOwner'
                          )}
                          handleChange={(newValue: any) =>
                            setSelectedActivityOwner(newValue)
                          }
                          value={selectedActivityOwner}
                          isMulti={true}
                        ></CustomizedSelect>
                      </Grid>

                      <Grid item xs={12}>
                        <CustomizedSelect
                          placeholder={defaultStatusValue.label}
                          options={statusList}
                          isSearchable={false}
                          displayLabel={I18n('DecPackOrders.WorkOrder.Status')}
                          handleChange={(newValue: any) =>
                            setSelectedStatus(newValue)
                          }
                          value={selectedStatus}
                          isMulti={true}
                        ></CustomizedSelect>
                      </Grid>
                      <Grid item xs={12}>
                        <CustomDateRangePicker
                          displayLabel={I18n('Common.DateRange')}
                          fromDate={fromDate}
                          toDate={toDate}
                          handleChange={(fromDate: any, toDate: any) => {
                            handleChangeDateFilterSearch(fromDate, toDate);
                          }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <FormControlLabel
                          className="mt-0"
                          control={
                            <Checkbox
                              onChange={(event) =>
                                setIsIgnoreDateFilter(event.target.checked)
                              }
                              checked={isIgnoreDateFilter}
                            />
                          }
                          label={I18n('Common.IgnoreDateFilter')}
                        />
                      </Grid>

                      <div className="btn-wrap">
                        <Button
                          color="neutral"
                          disableElevation
                          variant="contained"
                          onClick={handleResetClick}
                        >
                          {I18n('Common.Reset')}
                        </Button>
                        <Button
                          color="primary"
                          disableElevation
                          variant="contained"
                          onClick={handleAdvanceSearchFilterClick}
                        >
                          {I18n('Common.Apply')}
                        </Button>
                      </div>
                    </div>
                  </Paper>
                </Fade>
              )}
            </Popper>
            <Button
              color="info"
              onClick={popperHandleClick('bottom-end')}
              disableElevation
              variant="outlined"
              className={`icon-btn ${isFilterEnabled ? 'is-filtered' : ''}`}
            >
              <FontAwesomeIcon icon={faFilter} className="fa-lg" />
            </Button>
            {hasPermission(Permission.canViewShippingOrders) ? (
              <>
                <Button
                  color="info"
                  className="small-btn"
                  disableElevation
                  variant="contained"
                  onClick={handleExportClick}
                >
                  {I18n('Common.Export')}
                </Button>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div className="checkbox-header-group">
        <FormControlLabel
          control={
            <Checkbox
              name="past"
              className="search-control small-form-control"
              onChange={handlePastClick}
              checked={isPast}
              inputProps={{ 'aria-label': 'controlled' }}
              sx={{
                color: 'white',
                '&.Mui-checked': {
                  color: 'white',
                },
              }}
            />
          }
          label={I18n('Common.Past')}
        />
        <FormControlLabel
          control={
            <Checkbox
              name="today"
              className="search-control small-form-control"
              onChange={handleTodayClick}
              checked={isToday}
              inputProps={{ 'aria-label': 'controlled' }}
              sx={{
                color: 'white',
                '&.Mui-checked': {
                  color: 'white',
                },
              }}
            />
          }
          label={I18n('Common.Today')}
        />
        <FormControlLabel
          control={
            <Checkbox
              name="future"
              className="search-control small-form-control"
              onChange={handleFutureClick}
              checked={isFuture}
              inputProps={{ 'aria-label': 'controlled' }}
              sx={{
                color: 'white',
                '&.Mui-checked': {
                  color: 'white',
                },
              }}
            />
          }
          label={I18n('Common.Future')}
        />
      </div>
      <div className="main-content-section">
        <div className="content-block table-fix">
          <CustomDataGrid
            rowCount={gridData.totalRows}
            data={gridData.rows}
            columns={columns}
            onSortModelChange={handleSortModelChange}
            pageNo={gridData.page}
            pageSize={gridData.pageSize}
            onPageChange={handleChangePage}
            onPageSizeChange={handleChangePageSize}
            rowsPerPageOptions={gridData.rowsPerPageOptions}
            initialState={{
              sorting: {
                sortModel: [{ field: 'updatedDate', sort: 'desc' }],
              } as GridSortingInitialState,
              pagination: {
                page: gridData.page,
                pageSize: gridData.pageSize,
              } as GridPaginationInitialState,
            }}
          ></CustomDataGrid>
        </div>
      </div>
    </div>
  );
}
