import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Breadcrumbs, Chip, Stack } from '@mui/material';
import I18n from 'utilities/i18n';
import Link from '@mui/material/Link';
import { useNavigate, useParams } from 'react-router-dom';
import RouteEnum from 'models/RouteEnum';
import Note from 'common/notes/Notes';
import DocumentList from '../../common/Document/DocumentList';
import { DocumentEntity, EntityTypeEnum } from 'models/pagination-model';
import ShippingOfferDetails from './ShippingOfferDetails';
import ShippingMaterial from '../shipping-orders/ShippingMaterials';
import ShippingPackageList from '../shipping-orders/ShippingPackage/ShippingPackageList';
import OrderTrackings from 'common/OrderTracking/OrderTrackings';
import { ProductCatalogueAssignmentType } from 'models/product-catalogue';
import { Permission } from 'Permissions';
import environment from 'environment';
import http from '../../utilities/httpService';
import { useEffect, useState } from 'react';
export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function ShippingOrderTabs() {
  const i18nMessages = {
    notes: I18n('ShippingOrders.Tabs.Notes'),
  };
  let { id, tabId } = useParams();
  let navigate = useNavigate();
  const handleListClick = () => {
    navigate(RouteEnum.ShippingOffersList);
  };
  const breadcrumbs = [
    <Typography key="3" color="text.primary">
      {I18n('Nav.Offer')}
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={handleListClick}
        className="cpointer"
      >
        {I18n('Nav.ShippingOffers')}
      </Link>
    </Typography>,
    <Typography key="3" color="text.primary">
      {I18n('ShippingOrders.OfferDetails')}
    </Typography>,
  ];
  const [value, setValue] = React.useState(tabId ? Number(tabId) : 0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [totalDocumentValue, settotalDocumentValue] = React.useState(0);
  const [totalNotesValue, settotalNotesValue] = React.useState(0);

  const [externalDocumentValue, setexternalDocumentValue] = React.useState(0);
  const [externalNotesValue, setexternalNotesValue] = React.useState(0);

  const [isPackageExist, setIsPackageExist] = React.useState(false);
  const [IsOfferConverted, setIsOfferConverted] = useState(false);

  const getDocumentsValues = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl +
        `Shipping/GetDocumentShippingCount/${id}/${EntityTypeEnum.ShippingOrders}`
    );
    const result = await http.get(apiUrl.toString());
    if (result) {
      settotalDocumentValue(result.data.item1);
      setexternalDocumentValue(result.data.item2);
    }
  };

  const getNotesShippingCount = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl +
        `Shipping/GetNotesShippingCount/${id}/${EntityTypeEnum.ShippingOrders}`
    );
    const result = await http.get(apiUrl.toString());
    if (result) {
      settotalNotesValue(result.data.item1);
      setexternalNotesValue(result.data.item2);
    }
  };

  const RefreshDocumentCount = async () => {
    await getDocumentsValues();
  };

  const RefreshNotesCount = async () => {
    await getNotesShippingCount();
  };

  const getPackageDetailsCount = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl + `Shipping/GetPackageDetailsCount/${id}`
    );
    const result = await http.get(apiUrl.toString());
    if (result) {
      setIsPackageExist(result.data);
    }
  };

  const handleOfferConverted = (IsOfferConverted: boolean) => {
    setIsOfferConverted(IsOfferConverted);
  };

  useEffect(() => {
    getDocumentsValues();
    getNotesShippingCount();
    getPackageDetailsCount();
  }, []);

  return (
    <div>
      <div className="heading-section">
        <div className="heading-section-left">
          <Typography variant="h3" sx={{ mb: 3 }}>
            {I18n('ShippingOrders.OfferDetails')}
          </Typography>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>
      </div>
      <div className="main-content-section width-100">
        <Box>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            className="tab-bordered"
          >
            <Tab
              label={I18n('ShippingOrders.Tabs.Details')}
              {...a11yProps(0)}
            />
            <Tab
              label={I18n('ShippingOrders.Tabs.Packages')}
              {...a11yProps(1)}
            />
            <Tab
              label={I18n('ShippingOrders.Tabs.BillableItems')}
              {...a11yProps(2)}
            />
            <Tab
              label={
                <React.Fragment>
                  <Stack direction="row" spacing={2}>
                    {I18n('ShippingOrders.Tabs.Documents')}
                    <Chip
                      label={totalDocumentValue}
                      color="primary"
                      className="tabBadge"
                    />
                    <Chip
                      label={externalDocumentValue}
                      color="error"
                      className="tabBadge"
                    />
                  </Stack>
                </React.Fragment>
              }
              {...a11yProps(3)}
            />
            <Tab
              label={
                <React.Fragment>
                  <Stack direction="row" spacing={2}>
                    {i18nMessages.notes}
                    <Chip
                      label={totalNotesValue}
                      color="primary"
                      className="tabBadge"
                    />
                    <Chip
                      label={externalNotesValue}
                      color="error"
                      className="tabBadge"
                    />
                  </Stack>
                </React.Fragment>
              }
              {...a11yProps(4)}
            />
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <ShippingOfferDetails
            IsOfferConverted={handleOfferConverted}
          ></ShippingOfferDetails>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ShippingPackageList isSearchable={true}></ShippingPackageList>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ShippingMaterial
            shippingOrderId={id ? id : 0}
            isAddShippingMaterial={false}
            permission={Permission.canEditShippingOffers}
            isOffer={true}
          ></ShippingMaterial>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <DocumentList
            permission={Permission.canEditShippingOffers}
            addPermission={Permission.canAddShippingOffers}
            deletePermission={Permission.canDeleteShippingOffers}
            entityType={EntityTypeEnum.ShippingOrders}
            paramsId={id}
            documentEntityType={DocumentEntity.ShippingOrders}
            refreshCount={RefreshDocumentCount}
            isViewOnly={IsOfferConverted}
            isSendOfferEmail={true}
          />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Note
            permission={Permission.canEditShippingOffers}
            addPermission={Permission.canAddShippingOffers}
            deletePermission={Permission.canDeleteShippingOffers}
            paramsId={id}
            entityType={EntityTypeEnum.ShippingOrders}
            refreshCount={RefreshNotesCount}
            isViewOnly={IsOfferConverted}
          />
        </TabPanel>
      </div>
    </div>
  );
}
