import I18n from 'utilities/i18n';
import { useEffect, useCallback, useState } from 'react';
import http from '../../../utilities/httpService';
import { OrderByDirection } from 'models/pagination-model';
import { hasPermission } from 'utilities/protectedRoute';
import { Permission } from 'Permissions';
import { Button, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import EditIcon from '@mui/icons-material/Edit';
import CustomDataGrid from 'common/datagrid/datagrid';
import { useParams } from 'react-router-dom';

import {
  GridActionsCellItem,
  GridCellParams,
  GridColumns,
  GridPaginationInitialState,
  GridRowParams,
  GridSortingInitialState,
  GridSortModel,
} from '@mui/x-data-grid';
import environment from 'environment';
import AddAssignment from './AddAssignment';
import {
  ProductCatalogueAssignmentResponseDto,
  ProductCatalogueAssignmentType,
} from 'models/product-catalogue';
import { Utilities } from 'utilities/Utilities';

function AssignmentList() {
  const { entityId } = useParams();

  const [isAddModalOpen, setAddModal] = useState(false);
  const [editData, setEditData] = useState(null);

  const [gridData, setGridData] = useState({
    sortOrder: OrderByDirection.Descending,
    sortBy: 'date',
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [10, 20, 50, 100],
    pageSize: 10,
    page: 0,
    searchExpression: '',
  });

  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));
  const getAssignments = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl + 'ProductCatalogueAssignment'
    );
    try {
      apiUrl.searchParams.set('$filter', `ProductCatalogueId~=~${entityId}`);
      apiUrl.searchParams.set('$orderby', 'assignDate');
      apiUrl.searchParams.set(
        '$skip',
        (gridData.page * gridData.pageSize).toString()
      );
      apiUrl.searchParams.set('$top', gridData.pageSize.toString());
      apiUrl.searchParams.set(
        '$orderbydirection',
        OrderByDirection.Descending.toString()
      );
      const result = await http.get(apiUrl.toString());
      if (result) {
        updateGridData('totalRows', result.data.totalCount);
        updateGridData('rows', result.data.values);
      }
    } catch (error) {}
  };

  const i18nMessages = {
    editedSuccessfully: I18n(
      'ProductCatalogues.RemoveStock.EditedSuccessfully'
    ),
    errorOccurred: I18n('ProductCatalogues.RemoveStock.ErrorOccurred'),
    addAssignmentTitle: I18n('ProductCatalogues.Assignments.Add'),
    editAssignmentTitle: I18n('ProductCatalogues.Assignments.Edit'),
    warehouseType: I18n('ProductCatalogues.Assignments.Warehouse'),
    shippingOrderType: I18n('ProductCatalogues.Assignments.ShippingOrder'),
    AssignmentTabTitle: I18n('ProductCatalogues.Tabs.Assignment'),
    decPackOrderType: I18n('ProductCatalogues.Assignments.DecPackOrder'),
  };

  useEffect(() => {
    //RemoveStockListing API comes here
    getAssignments();
  }, [
    gridData.page,
    gridData.pageSize,
    gridData.sortBy,
    gridData.sortOrder,
    gridData.searchExpression,
  ]);

  const handleChangePage = (pageNo: number) => {
    updateGridData('page', pageNo);
  };

  const handleChangePageSize = (pageSize: number) => {
    updateGridData('pageSize', pageSize);
  };

  type Row = (typeof gridData.rows)[number];

  const addProductCatalogueAssignment = useCallback(() => {
    setEditData(null);
    setAddModal(true);
  }, []);

  const editProductCatalogueAssignment = useCallback(
    (data: any) => () => {
      setEditData(data);
      setAddModal(true);
    },
    []
  );

  const refreshData = async () => {
    handleCloseForAdd();
    await getAssignments();
  };

  const getAssignmentType = (
    assignmentValue: ProductCatalogueAssignmentType
  ) => {
    switch (assignmentValue) {
      case ProductCatalogueAssignmentType.ShippingOrder:
        return i18nMessages.shippingOrderType;
      case ProductCatalogueAssignmentType.DecPackOrder:
        return i18nMessages.decPackOrderType;
      case ProductCatalogueAssignmentType.Warehouse:
        return i18nMessages.warehouseType;
    }
  };

  const columns: GridColumns<Row> = [
    {
      field: 'assignDate',
      headerName: I18n('ProductCatalogues.Assignments.AssignedDate'),
      flex: 1,
      valueFormatter: (params) =>
        Utilities.convertHazgoDataTimeFormat(params.value),
      sortable: false,
    },
    {
      field: 'assignedByType',
      headerName: I18n('ProductCatalogues.Assignments.AssignedByType'),
      renderCell: (
        params: GridCellParams<any, ProductCatalogueAssignmentResponseDto, any>
      ) => {
        return getAssignmentType(params.value);
      },
      flex: 1,
      sortable: false,
    },
    {
      field: 'assignedByName',
      headerName: I18n('ProductCatalogues.Assignments.AssignedBy'),
      renderCell: (
        params: GridCellParams<any, ProductCatalogueAssignmentResponseDto, any>
      ) => {
        return (params &&
          params.row &&
          params.row.assignedByType &&
          params.row.assignedByType ===
            ProductCatalogueAssignmentType.ShippingOrder) ||
          params.row.assignedByType ===
            ProductCatalogueAssignmentType.DecPackOrder
          ? params.value
          : I18n('ProductCatalogues.Assignments.Warehouse');
      },
      flex: 1,
      sortable: false,
    },
    {
      field: 'assignedToType',
      headerName: I18n('ProductCatalogues.Assignments.AssignedToType'),
      renderCell: (
        params: GridCellParams<any, ProductCatalogueAssignmentResponseDto, any>
      ) => {
        return getAssignmentType(params.value);
      },
      flex: 1,
      sortable: false,
    },
    {
      field: 'assignedToName',
      headerName: I18n('ProductCatalogues.Assignments.AssignedTo'),
      renderCell: (
        params: GridCellParams<any, ProductCatalogueAssignmentResponseDto, any>
      ) => {
        return params &&
          params.row &&
          params.row.assignedToType &&
          params.row.assignedToType === ProductCatalogueAssignmentType.Warehouse
          ? I18n('ProductCatalogues.Assignments.Warehouse')
          : params.value;
      },
      flex: 1,
      sortable: false,
    },

    {
      field: 'quantity',
      headerName: I18n('ProductCatalogues.RemoveStock.Quantity'),
      flex: 1,
      sortable: false,
    },
    {
      field: 'remarks',
      headerName: I18n('ProductCatalogues.RemoveStock.Remarks'),
      flex: 1,
      sortable: false,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: I18n('Common.Actions'),
      width: 150,
      getActions: (params: GridRowParams) => {
        const actionButtons = [];
        actionButtons.push(
          <>
            {hasPermission(Permission.canEditProductCatalogueManagement) &&
            params.row.isManual ? (
              <GridActionsCellItem
                onPointerEnterCapture={false}
                onPointerLeaveCapture={false}
                placeholder={''}
                icon={<EditIcon />}
                label={I18n('Common.Edit')}
                onClick={editProductCatalogueAssignment(params.row)}
              />
            ) : (
              <></>
            )}
          </>
        );
        return actionButtons;
      },
    },
  ];

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    if (sortModel && sortModel.length) {
      updateGridData('sortBy', sortModel[0].field);
      updateGridData(
        'sortOrder',
        sortModel[0].sort === 'asc'
          ? OrderByDirection.Ascending
          : OrderByDirection.Descending
      );
    }
  }, []);

  const handleCloseForAdd = () => {
    setAddModal(false);
  };

  return (
    <div>
      <Typography variant="h5" className="inner-heading has-button">
        {i18nMessages.AssignmentTabTitle}
        {hasPermission(Permission.canAddProductCatalogueManagement) ? (
          <div className="inner-btn-wrap">
            <Button
              color="primary"
              className="small-btn"
              disableElevation
              onClick={addProductCatalogueAssignment}
              variant="contained"
            >
              <FontAwesomeIcon icon={faPlus} className="fa-md" />
            </Button>
          </div>
        ) : (
          <></>
        )}
      </Typography>

      <div className="">
        <div className="content-block table-fix">
          <CustomDataGrid
            rowCount={gridData.totalRows}
            data={gridData.rows}
            columns={columns}
            pageNo={gridData.page}
            pageSize={gridData.pageSize}
            onSortModelChange={handleSortModelChange}
            onPageChange={handleChangePage}
            onPageSizeChange={handleChangePageSize}
            rowsPerPageOptions={gridData.rowsPerPageOptions}
            initialState={{
              sorting: {
                sortModel: [{ field: 'assignedDate', sort: 'desc' }],
              } as GridSortingInitialState,
              pagination: {
                page: gridData.page,
                pageSize: gridData.pageSize,
              } as GridPaginationInitialState,
            }}
          ></CustomDataGrid>
          {isAddModalOpen && (
            <AddAssignment
              isOpen={isAddModalOpen}
              editData={editData}
              entityId={entityId}
              handleClose={handleCloseForAdd}
              title={
                editData == null
                  ? i18nMessages.addAssignmentTitle
                  : i18nMessages.editAssignmentTitle
              }
              refreshData={refreshData}
            ></AddAssignment>
          )}
        </div>
      </div>
    </div>
  );
}

export default AssignmentList;
