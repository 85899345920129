import I18n from 'utilities/i18n';
import { useEffect, useCallback, useState, MouseEvent } from 'react';
import http from '../../utilities/httpService';
import {
  ApprovalStatus,
  BaseModel,
  EntityStatus,
  EntityStatusDescription,
  OrderByDirection,
  CustomerTags,
  CustomerTypesList,
  CustomerTagsDescription,
} from 'models/pagination-model';
import {
  Breadcrumbs,
  Button,
  Fade,
  Grid,
  IconButton,
  Paper,
  Popper,
  PopperPlacementType,
  Typography,
  Tooltip,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faPlus,
  faFilter,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import DeleteIcon from '@mui/icons-material/Delete';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from 'common/formControl/formControl';
import CustomDataGrid from 'common/datagrid/datagrid';
import { useNavigate } from 'react-router-dom';
import {
  GridActionsCellItem,
  GridColumns,
  GridEventListener,
  GridPaginationInitialState,
  GridRowId,
  GridRowParams,
  GridSortingInitialState,
  GridSortModel,
} from '@mui/x-data-grid';
import environment from 'environment';
import CustomizedSelect from 'common/CustomSelect/CustomSelect';
import ConfirmationPopup from 'common/confirmationPopup/confirmationPopup';
import { toast } from 'react-toastify';
import { Permission } from 'Permissions';
import { hasPermission } from 'utilities/protectedRoute';
import RouteEnum from 'models/RouteEnum';
import RestorePageIcon from '@mui/icons-material/RestorePage';
import { Utilities } from 'utilities/Utilities';

function CustomerList() {
  const breadcrumbs = [
    <Typography key="3" color="text.primary">
      {I18n('Nav.Reference')}
    </Typography>,
    <Typography key="3" color="text.primary">
      {I18n('Nav.Customers')}
    </Typography>,
  ];
  const [gridData, setGridData] = useState({
    sortOrder: OrderByDirection.Descending,
    sortBy: 'UpdatedDate',
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [10, 20, 50, 100],
    pageSize: 10,
    page: 0,
    searchExpression: '',
  });
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isDeleteModalOpen, setDeleteModal] = useState(false);
  const [selectedId, setId] = useState();
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState<PopperPlacementType>();
  const [basicSearchExpression, setBasicSearchExpression] = useState('');
  const [advanceSearchExpression, setAdvanceSearchExpression] = useState('');
  const defaultCountryValue = {
    value: '0',
    label: I18n('Placeholders.Select'),
  } as BaseModel;
  const [countryList, setCountryList] = useState<BaseModel[]>([]);
  const defaultValue = {
    value: '0',
    label: I18n('Placeholders.SelectStatus'),
  } as BaseModel;
  const [statusFilter, setStatusFilter] = useState(defaultValue);
  const [countryFilter, setCountryFilter] =
    useState<BaseModel>(defaultCountryValue);
  const [vatFilter, setVatFilter] = useState('');
  const [tagFilter, setTagFilter] = useState(defaultCountryValue);
  let navigate = useNavigate();
  const customerPortalUser = Utilities.getIsCPUser();
  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));
  const getCustomers = async () => {
    const apiUrl = new URL(environment.api.baseUrl + 'Customer');
    try {
      let finalSearchTerm = '';
      if (basicSearchExpression && advanceSearchExpression) {
        finalSearchTerm = `${basicSearchExpression} and ${advanceSearchExpression}`;
      } else if (basicSearchExpression && !advanceSearchExpression) {
        finalSearchTerm = basicSearchExpression;
      } else if (!basicSearchExpression && advanceSearchExpression) {
        finalSearchTerm = advanceSearchExpression;
      }

      apiUrl.searchParams.set('$filter', finalSearchTerm);
      apiUrl.searchParams.set('$orderby', gridData.sortBy);
      apiUrl.searchParams.set(
        '$skip',
        (gridData.page * gridData.pageSize).toString()
      );
      apiUrl.searchParams.set('$top', gridData.pageSize.toString());
      apiUrl.searchParams.set(
        '$orderbydirection',
        gridData.sortOrder.toString()
      );
      const result = await http.get(apiUrl.toString());
      if (result) {
        updateGridData('totalRows', result.data.totalCount);
        updateGridData('rows', result.data.values);
      }
    } catch (error) {}
  };

  const getCountries = async () => {
    let countryLst: string[] = [];

    const apiUrl = new URL(environment.api.baseUrl + 'Country');
    try {
      apiUrl.searchParams.set('$orderby', 'name');
      apiUrl.searchParams.set('$skip', '0');
      apiUrl.searchParams.set('$top', '5000');
      apiUrl.searchParams.set(
        '$orderbydirection',
        OrderByDirection.Ascending.toString()
      );

      const result = await http.get(apiUrl.toString());
      if (result) {
        result.data.values.map(function (item: any) {
          countryLst.push(item['name']);
        });

        let data = getCountryList(countryLst);
        setCountryList(data);
      }
    } catch (error) {}
  };

  const i18nMessages = {
    deletedSuccessfully: I18n('Customer.DeletedSuccessfully'),
    errorOccurred: I18n('Customer.ErrorOccurred'),
    ResendForApproval: I18n('Customer.ResendForApproval'),
    CustomerName: I18n('Customer.Name'),
    VatNumber: I18n('Customer.VatNumber'),
    EORI: I18n('Customer.EORI'),
    Address: I18n('Customer.Address'),
    PCCity: I18n('Customer.PCCity'),
    Country: I18n('Customer.Country'),
    Tag: I18n('Customer.Tag'),
    Both: I18n('Customer.DecPack') + ',' + I18n('Customer.Shipping'),
    DecPack: I18n('Customer.DecPack'),
    Shipping: I18n('Customer.Shipping'),
    Approved: I18n('Customer.Approved'),
    Status: I18n('Customer.Status'),
    Actions: I18n('Common.Actions'),
    ResendForApprovalToolTip: I18n('Customer.ToolTips.ResendForApproval'),
  };

  let customerTagsList: Array<BaseModel> = [];
  const getCustomerTagsList = () => {
    customerTagsList = [defaultValue];
    CustomerTagsDescription.forEach((value: string, key: number) => {
      customerTagsList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };

  getCustomerTagsList();
  useEffect(() => {
    getCustomers();
    getCountries();
  }, [
    gridData.page,
    gridData.pageSize,
    gridData.sortBy,
    gridData.sortOrder,
    gridData.searchExpression,
    advanceSearchExpression,
    basicSearchExpression,
  ]);

  const handleChangePage = (pageNo: number) => {
    updateGridData('page', pageNo);
  };

  const handleChangeBasicSearch = (searchExpression: any) => {
    setBasicSearchExpression('name~like~' + searchExpression.target.value);
  };
  const handleChangePageSize = (pageSize: number) => {
    updateGridData('pageSize', pageSize);
  };

  const handleResend = async (params: any) => {
    const apiUrl = new URL(
      environment.api.baseUrl + 'Customer/' + params.row.id
    );
    try {
      const result: any = await http.get(apiUrl.toString());

      if (
        result.data &&
        result.data.approvalStatus === ApprovalStatus.Rejected
      ) {
        result.data.approvalStatus = ApprovalStatus.Pending;

        var response = await http.put(apiUrl.toString(), result.data);
        if (response) {
          toast.success(i18nMessages.ResendForApproval);
          getCustomers();
        } else {
          toast.error(i18nMessages.errorOccurred);
        }
      }
    } catch (error: any) {}
  };

  type Row = (typeof gridData.rows)[number];
  const deleteCustomer = useCallback(
    (id: any) => () => {
      setDeleteModal(true);
      setId(id);
    },
    []
  );

  const columns: GridColumns<Row> = [
    {
      field: 'name',
      headerName: i18nMessages.CustomerName,
      flex: 1,
    },
    {
      field: 'vatNumber',
      headerName: i18nMessages.VatNumber,
      flex: 1,
    },
    {
      field: 'eori',
      headerName: i18nMessages.EORI,
      flex: 1,
    },
    {
      field: 'address',
      headerName: i18nMessages.Address,
      flex: 1,
    },
    {
      field: 'postalCode',
      headerName: i18nMessages.PCCity,
      flex: 1,
    },
    {
      field: 'country',
      headerName: i18nMessages.Country,
      flex: 1,
    },
    {
      field: 'taggedToDescription',
      headerName: i18nMessages.Tag,
      flex: 1,
      renderCell: (params: any) => {
        let respStr = '';
        if (params.formattedValue !== '') {
          respStr =
            params.formattedValue === 'Both'
              ? i18nMessages.Both
              : params.formattedValue === 'DecPack'
              ? i18nMessages.DecPack
              : i18nMessages.Shipping;
        }
        return (
          <Tooltip title={respStr}>
            <div>{respStr}</div>
          </Tooltip>
        );
      },
    },
    {
      field: 'approvalStatusDescription',
      headerName: i18nMessages.Approved,
      flex: 1,
    },
    {
      field: 'statusDescription',
      headerName: i18nMessages.Status,
      maxWidth: 80,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: i18nMessages.Actions,
      width: 100,
      getActions: (params: GridRowParams) => {
        const actionButtons = [];
        if (params.row.status !== EntityStatus.Active) {
          actionButtons.push(
            <>
              {hasPermission(Permission.canDeleteCustomer) ? (
                <GridActionsCellItem
                  onPointerEnterCapture={false}
                  onPointerLeaveCapture={false}
                  placeholder={''}
                  icon={<DeleteIcon />}
                  label="Delete"
                  onClick={deleteCustomer(params.id)}
                />
              ) : (
                <></>
              )}
            </>
          );
        }
        if (params.row.approvalStatus === ApprovalStatus.Rejected) {
          actionButtons.push(
            <Tooltip title={i18nMessages.ResendForApprovalToolTip} arrow>
              <GridActionsCellItem
                onPointerEnterCapture={false}
                onPointerLeaveCapture={false}
                placeholder={''}
                icon={<RestorePageIcon />}
                label="ResendForApproval"
                onClick={(data) => handleResend(params)}
              />
            </Tooltip>
          );
        }
        return actionButtons;
      },
    },
  ];

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    if (sortModel && sortModel.length) {
      if (sortModel[0].field === 'city') {
        updateGridData('sortBy', 'city.name');
      } else if (sortModel[0].field === 'country') {
        updateGridData('sortBy', 'country.name');
      } else if (sortModel[0].field === 'statusDescription') {
        updateGridData('sortBy', 'status');
      } else if (sortModel[0].field === 'approvalStatusDescription') {
        updateGridData('sortBy', 'approvalStatus');
      } else if (sortModel[0].field === 'taggedToDescription') {
        updateGridData('sortBy', 'taggedTo');
      } else {
        updateGridData('sortBy', sortModel[0].field);
      }
      updateGridData(
        'sortOrder',
        sortModel[0].sort === 'asc'
          ? OrderByDirection.Ascending
          : OrderByDirection.Descending
      );
    }
  }, []);

  const handleOnRowClick = (params: { id: string }) => {
    navigate(
      '/customer/' + params.id + '/' + CustomerTypesList.ApprovedCustomer
    );
  };

  const popperHandleClick =
    (newPlacement: PopperPlacementType) =>
    (event: MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setOpen((prev) => placement !== newPlacement || !prev);
      setPlacement(newPlacement);
    };

  let countryLst: Array<BaseModel> = [];
  const getCountryList = (countries: string[]) => {
    countryLst = [defaultCountryValue];
    countries.forEach((value: string) => {
      countryLst.push({
        value,
        label: value,
      } as BaseModel);
    });

    return countryLst;
  };

  let entityStatusList: Array<BaseModel> = [];
  const getStatusList = () => {
    entityStatusList = [defaultValue];
    EntityStatusDescription.forEach((value: string, key: number) => {
      entityStatusList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getStatusList();

  const handleResetClick = () => {
    setVatFilter('');
    setCountryFilter(defaultCountryValue);
    setStatusFilter(defaultValue);
    setAdvanceSearchExpression('');
    setTagFilter(defaultValue);
    setOpen(false);
  };

  const handleAdvanceSearchFilterClick = () => {
    let searchExpression = '';
    searchExpression += vatFilter ? `VatNumber~like~${vatFilter}` : '';
    searchExpression +=
      countryFilter && countryFilter?.value && countryFilter?.value !== '0'
        ? appendAdvanceSearchExpressions(searchExpression, 'and') +
          `Country.Name~like~${countryFilter.value}`
        : '';
    searchExpression += +statusFilter.value
      ? appendAdvanceSearchExpressions(searchExpression, 'and') +
        `Status~=~${statusFilter.value}`
      : '';

    searchExpression += +tagFilter.value
      ? appendAdvanceSearchExpressions(searchExpression, 'and') +
        `taggedTo~=~${tagFilter.value}`
      : '';
    setAdvanceSearchExpression(searchExpression);
    setOpen(false);
  };

  const appendAdvanceSearchExpressions = (
    searchExpression: string,
    type: string
  ) => {
    let searchExpressionLocal: string = searchExpression;
    switch (type) {
      case 'and':
        searchExpressionLocal = searchExpressionLocal ? ` and ` : ``;
        break;
      case 'or':
        searchExpressionLocal = searchExpressionLocal ? ` or ` : ``;
        break;
      default:
        break;
    }
    return searchExpressionLocal;
  };
  const handleClose = () => {
    setDeleteModal(false);
  };

  const handleOk = () => {
    setDeleteModal(false);
    deleteData(selectedId);
  };

  const deleteData = async (id: any) => {
    const apiUrl = new URL(environment.api.baseUrl + 'Customer/' + id);
    try {
      const result: any = await http.delete(apiUrl.toString());
      if (result != null && result.data != null && result.data.success) {
        toast.success(i18nMessages.deletedSuccessfully);
        getCustomers();
      } else {
        toast.error(i18nMessages.errorOccurred);
      }
    } catch (error: any) {}
  };

  const addClicked = () => {
    navigate(RouteEnum.CustomerAdd);
  };

  return (
    <div>
      <div className="heading-section">
        <div className="heading-section-left">
          <Typography variant="h3" sx={{ mb: 3 }}>
            {I18n('Nav.Customers')}
          </Typography>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>
        <div className="heading-section-right">
          <div className="search-control-outer">
            <FormControl
              margin="normal"
              displayLabel=""
              required
              inputType="text"
              placeholderText={I18n('Customer.SearchbyName')}
              className="search-control small-form-control"
              handleChange={handleChangeBasicSearch}
              adornmentValue={
                <InputAdornment position="start">
                  <FontAwesomeIcon icon={faSearch} />
                </InputAdornment>
              }
            ></FormControl>
          </div>
          <div className="header-btn-wrap">
            <Popper
              open={open}
              anchorEl={anchorEl}
              placement={placement}
              transition
              className="filter-popper"
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper>
                    <Typography variant="h5" className="filter-heading">
                      Filter
                      <IconButton
                        aria-label="delete"
                        onClick={popperHandleClick('bottom-end')}
                      >
                        <FontAwesomeIcon
                          icon={faTimes}
                          className="fa-sm close-icon"
                        />
                      </IconButton>
                    </Typography>
                    <div className="filter-content form-small-gap">
                      <Grid container spacing={2} className="grid-wrap two-col">
                        <Grid item xs={12}>
                          <FormControl
                            margin="normal"
                            displayLabel={I18n('Customer.VatNumber')}
                            id="VatNumber"
                            inputType="text"
                            inputProps={{ maxLength: 30 }}
                            handleChange={(
                              event: React.ChangeEvent<
                                HTMLTextAreaElement | HTMLInputElement
                              >
                            ) => setVatFilter(event.target.value)}
                            value={vatFilter}
                            multiline={false}
                          ></FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <CustomizedSelect
                            placeholder={defaultCountryValue.label}
                            options={countryList}
                            isSearchable={false}
                            displayLabel={I18n('Customer.Country')}
                            handleChange={(newValue: any) =>
                              setCountryFilter(newValue)
                            }
                            value={countryFilter}
                          ></CustomizedSelect>
                        </Grid>
                        <Grid item xs={12}>
                          <CustomizedSelect
                            placeholder={defaultValue.label}
                            options={entityStatusList}
                            isSearchable={false}
                            displayLabel={I18n('Common.Status')}
                            handleChange={(newValue: BaseModel) =>
                              setStatusFilter(newValue)
                            }
                            value={statusFilter}
                          ></CustomizedSelect>
                        </Grid>

                        <Grid item lg={12}>
                          <CustomizedSelect
                            placeholder={defaultValue.label}
                            options={customerTagsList}
                            isSearchable={false}
                            displayLabel={i18nMessages.Tag}
                            handleChange={(newValue: BaseModel) =>
                              setTagFilter(newValue)
                            }
                            value={tagFilter}
                          ></CustomizedSelect>
                        </Grid>
                      </Grid>
                      <div className="btn-wrap">
                        <Button
                          color="neutral"
                          disableElevation
                          variant="contained"
                          onClick={handleResetClick}
                        >
                          {I18n('Common.Reset')}
                        </Button>
                        <Button
                          color="primary"
                          disableElevation
                          variant="contained"
                          onClick={handleAdvanceSearchFilterClick}
                        >
                          {I18n('Common.Apply')}
                        </Button>
                      </div>
                    </div>
                  </Paper>
                </Fade>
              )}
            </Popper>
            <Button
              color="info"
              onClick={popperHandleClick('bottom-end')}
              disableElevation
              variant="outlined"
              className={`icon-btn ${
                advanceSearchExpression ? 'is-filtered' : ''
              }`}
            >
              <FontAwesomeIcon icon={faFilter} className="fa-lg" />
            </Button>
            {hasPermission(Permission.canAddCustomer) && !customerPortalUser ? (
              <Button
                color="info"
                className="small-btn"
                disableElevation
                variant="contained"
                onClick={addClicked}
              >
                <FontAwesomeIcon icon={faPlus} className="fa-md mr-2" />
                Add
              </Button>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div className="main-content-section">
        <div className="content-block table-fix">
          <CustomDataGrid
            rowCount={gridData.totalRows}
            data={gridData.rows}
            columns={columns}
            pageNo={gridData.page}
            pageSize={gridData.pageSize}
            onSortModelChange={handleSortModelChange}
            onPageChange={handleChangePage}
            onPageSizeChange={handleChangePageSize}
            rowsPerPageOptions={gridData.rowsPerPageOptions}
            onRowClick={handleOnRowClick}
            initialState={{
              sorting: {
                sortModel: [{ field: 'UpdatedDate', sort: 'desc' }],
              } as GridSortingInitialState,
              pagination: {
                page: gridData.page,
                pageSize: gridData.pageSize,
              } as GridPaginationInitialState,
            }}
          ></CustomDataGrid>

          <ConfirmationPopup
            isOpen={isDeleteModalOpen}
            data={selectedId}
            message={I18n('Customer.DeleteMessage')}
            handleClose={handleClose}
            handleOk={handleOk}
            title={I18n('Customer.DeleteCustomer')}
          ></ConfirmationPopup>
        </div>
      </div>
    </div>
  );
}

export default CustomerList;
