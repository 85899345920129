import React, { useEffect, useState } from 'react';
import Pricing from './Pricing';
import environment from 'environment';
import http from '../../../utilities/httpService';
import { useParams } from 'react-router-dom';

const TrainPricingTab = (props: any) => {
  const { entityId } = useParams();
  const [baseRateResponse, setBaseRateResponse] = useState();
  const [transportZone, setTransportZone] = useState();
  const [transportZoneSpecialPrice, setTransportZoneSpecialPrice] = useState();

  const getBaseRate = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl +
        `TransportBaseRate/${entityId}/${props.transportTypeId}`
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result && result.data) {
        setBaseRateResponse(result.data);
      }
    } catch (error) {}
  };
  const getZoneResponse = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl +
        `TransportZonePrice/${entityId}/${props.transportTypeId}`
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result && result.data) {
        setTransportZone(result.data);
      }
    } catch (error) {}
  };

  const getZoneSpecialPriceResponse = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl +
        `TransportZoneSpecialPrice/${entityId}/${props.transportTypeId}/0`
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result && result.data) {
        setTransportZoneSpecialPrice(result.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getBaseRate();
    getZoneResponse();
    getZoneSpecialPriceResponse();
  }, []);
  return (
    <div>
      <Pricing
        BaseRateResponse={baseRateResponse}
        ZoneResponse={transportZone}
        SpecialPrice={transportZoneSpecialPrice}
        transportTypeId={3}
        zonesName={props.zonesName}
      ></Pricing>
    </div>
  );
};

export default TrainPricingTab;
