import Typography from '@mui/material/Typography';
import I18n from 'utilities/i18n';
import { useEffect, useCallback, useState } from 'react';
import http from '../../utilities/httpService';
import {
  OrderByDirection,
  CommonProps,
  CommonListRequestModel,
  CommonPaginationResponseModel,
} from 'models/pagination-model';
import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faPlus } from '@fortawesome/free-solid-svg-icons';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InputAdornment from '@mui/material/InputAdornment';
import CustomizedInputs from 'common/formControl/formControl';
import CustomDataGrid from 'common/datagrid/datagrid';
import { useParams } from 'react-router-dom';
import environment from 'environment';
import { toast } from 'react-toastify';
import {
  GridActionsCellItem,
  GridCellParams,
  GridColumns,
  GridPaginationInitialState,
  GridRowParams,
  GridSortingInitialState,
  GridSortModel,
} from '@mui/x-data-grid';
import ConfirmationPopup from 'common/confirmationPopup/confirmationPopup';
import AddProductConsumption from './AddProductConsumption';
import moment from 'moment';
import { hasPermission } from 'utilities/protectedRoute';
import { Permission } from 'Permissions';
import { getProductConsumptions } from './ProductConsumptionApiService';
import {
  ProductCatalogueAssignmentType,
  ProductConsumptionResponseDto,
} from 'models/product-catalogue';

function ProductConsumptionList(props: CommonProps) {
  let { entityId } = useParams();

  const [isDeleteModalOpen, setDeleteModal] = useState(false);
  const [isAddModalOpen, setAddModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [selectedId, setId] = useState();
  const [canEdit, setCanEdit] = useState(hasPermission(props.permission));

  const [gridData, setGridData] = useState<
    CommonPaginationResponseModel<ProductConsumptionResponseDto>
  >({
    sortOrder: OrderByDirection.Descending,
    sortBy: 'date',
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [10, 20, 50, 100],
    pageSize: 10,
    page: 0,
    searchExpression: '',
    isLoading: false,
  });

  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));

  const i18nMessages = {
    editedSuccessfully: I18n(
      'ProductCatalogues.ProductConsumptions.EditedSuccessfully'
    ),
    deletedSuccessfully: I18n(
      'ProductCatalogues.ProductConsumptions.DeletedSuccessfully'
    ),
    errorOccurred: I18n('ProductCatalogues.ProductConsumptions.ErrorOccurred'),
    addProductConsumption: I18n(
      'ProductCatalogues.ProductConsumptions.AddProductConsumption'
    ),
    editProductConsumption: I18n(
      'ProductCatalogues.ProductConsumptions.EditProductConsumption'
    ),
    shippingOrderType: I18n('ProductCatalogues.Assignments.ShippingOrder'),
    decPackOrderType: I18n('ProductCatalogues.Assignments.DecPackOrder'),
  };

  useEffect(() => {
    getProductConsumptionList();
  }, [
    gridData.page,
    gridData.pageSize,
    gridData.sortBy,
    gridData.sortOrder,
    gridData.searchExpression,
  ]);

  const handleChangePage = (pageNo: number) => {
    updateGridData('page', pageNo);
  };

  const handleChangeBasicSearch = (searchExpression: any) => {
    updateGridData('searchExpression', searchExpression.target.value);
  };

  const handleChangePageSize = (pageSize: number) => {
    updateGridData('pageSize', pageSize);
  };

  type Row = (typeof gridData.rows)[number];
  const deleteProductConsumption = useCallback(
    (id: any) => () => {
      setDeleteModal(true);
      setId(id);
    },
    []
  );

  const addProductConsumption = useCallback(() => {
    setEditData(null);
    setAddModal(true);
  }, []);

  const editProductConsumption = useCallback(
    (data: any) => () => {
      setEditData(data);
      setAddModal(true);
    },
    []
  );

  const getProductConsumptionList = () => {
    updateGridData('isLoading', true);
    getProductConsumptions(entityId && entityId !== '0' ? +entityId : 0, {
      gridData,
      basicSearchExpression:
        gridData.searchExpression !== ''
          ? `ProductCatalogueId~=~${entityId} and Customer.Name~like~${gridData.searchExpression}`
          : `ProductCatalogueId~=~${entityId}`,
    } as CommonListRequestModel<ProductConsumptionResponseDto>).then((x) => {
      if (x) {
        updateGridData('totalRows', x?.gridData.totalRows);
        updateGridData('rows', x?.gridData.rows);
        updateGridData('isLoading', false);
      }
    });
  };

  const refreshData = async () => {
    handleCloseForAdd();
    await getProductConsumptionList();
  };

  const columns: GridColumns<Row> = [
    {
      field: 'date',
      headerName: I18n('ProductCatalogues.ProductConsumptions.Date'),
      minWidth: 150,
      valueFormatter: (params) => moment(params?.value).format('DD/MM/YYYY'),
    },
    {
      field: 'quantity',
      headerName: I18n('ProductCatalogues.ProductConsumptions.Quantity'),
      minWidth: 150,
    },
    {
      field: 'orderType',
      headerName: I18n('Order.OrderType'),
      flex: 1,
      renderCell: (
        params: GridCellParams<any, ProductConsumptionResponseDto, any>
      ) => {
        return +params.value === ProductCatalogueAssignmentType.ShippingOrder
          ? i18nMessages.shippingOrderType
          : i18nMessages.decPackOrderType;
      },
    },
    {
      field: 'orderNumber',
      headerName: I18n('ProductCatalogues.ProductConsumptions.OrderNumber'),
      flex: 1,
    },
    {
      field: 'customerName',
      headerName: I18n('ProductCatalogues.ProductConsumptions.CustomerName'),
      flex: 1,
    },
    {
      field: 'sellingPrice',
      headerName: I18n('ProductCatalogues.ProductConsumptions.SellingPrice'),
      flex: 1,
      renderCell: (params: any) => {
        return params.value.toString().replaceAll('.', ',');
      },
    },
    {
      field: 'remarks',
      headerName: I18n('ProductCatalogues.ProductPurchases.Remarks'),
      flex: 1,
    },
    {
      field: 'createdBy',
      headerName: I18n('ProductCatalogues.ProductConsumptions.CreatedBy'),
      width: 150,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: I18n('Common.Actions'),
      width: 150,
      getActions: (params: GridRowParams) => {
        const actionButtons = [];
        actionButtons.push(
          <>
            {hasPermission(Permission.canEditProductCatalogueManagement) &&
            params.row.isManual ? (
              <GridActionsCellItem
                onPointerEnterCapture={false}
                onPointerLeaveCapture={false}
                placeholder={''}
                icon={<EditIcon />}
                label={I18n('Common.Edit')}
                onClick={editProductConsumption(params.row)}
              />
            ) : (
              <></>
            )}
            {hasPermission(Permission.canDeleteProductCatalogueManagement) &&
            params.row.isManual ? (
              <GridActionsCellItem
                onPointerEnterCapture={false}
                onPointerLeaveCapture={false}
                placeholder={''}
                icon={<DeleteIcon />}
                label="Delete"
                onClick={deleteProductConsumption(params.id)}
              />
            ) : (
              <></>
            )}
          </>
        );
        return actionButtons;
      },
    },
  ];

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    if (sortModel && sortModel.length) {
      updateGridData('sortBy', sortModel[0].field);
      updateGridData(
        'sortOrder',
        sortModel[0].sort === 'asc'
          ? OrderByDirection.Ascending
          : OrderByDirection.Descending
      );
    }
  }, []);

  const handleCloseForDelete = () => {
    setDeleteModal(false);
  };

  const handleOkForDelete = () => {
    setDeleteModal(false);
    deleteData(selectedId);
  };

  const handleCloseForAdd = () => {
    setEditData(null);
    setAddModal(false);
  };

  const deleteData = async (id: any) => {
    const apiUrl = new URL(
      environment.api.baseUrl + 'ProductConsumptions/' + id
    );
    try {
      const result: any = await http.delete(apiUrl.toString());
      if (result.data) {
        getProductConsumptionList();
        toast.success(i18nMessages.deletedSuccessfully);
      } else {
        toast.error(i18nMessages.errorOccurred);
      }
    } catch (error: any) {}
  };

  return (
    <div>
      <Typography variant="h5" className="inner-heading has-button">
        {I18n('ProductCatalogues.Tabs.Consumptions')}
        <div className="inner-btn-wrap">
          <div className="text-right container-search">
            <div className="search-control-outer">
              <CustomizedInputs
                margin="normal"
                displayLabel=""
                inputType="text"
                placeholderText="Search"
                handleChange={handleChangeBasicSearch}
                className="search-control small-form-control"
                adornmentValue={
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={faSearch} />
                  </InputAdornment>
                }
              />
            </div>
          </div>
          {hasPermission(Permission.canAddProductCatalogueManagement) ? (
            <Button
              color="primary"
              className="small-btn"
              disableElevation
              onClick={addProductConsumption}
              variant="contained"
            >
              <FontAwesomeIcon icon={faPlus} className="fa-md" />
            </Button>
          ) : (
            <></>
          )}
        </div>
      </Typography>
      <div className="">
        <div className="content-block table-fix">
          <CustomDataGrid
            rowCount={gridData.totalRows}
            data={gridData.rows}
            columns={columns}
            pageNo={gridData.page}
            pageSize={gridData.pageSize}
            onSortModelChange={handleSortModelChange}
            onPageChange={handleChangePage}
            onPageSizeChange={handleChangePageSize}
            rowsPerPageOptions={gridData.rowsPerPageOptions}
            initialState={{
              sorting: {
                sortModel: [{ field: 'date', sort: 'desc' }],
              } as GridSortingInitialState,
              pagination: {
                page: gridData.page,
                pageSize: gridData.pageSize,
              } as GridPaginationInitialState,
            }}
          ></CustomDataGrid>

          <ConfirmationPopup
            isOpen={isDeleteModalOpen}
            data={selectedId}
            message={I18n(
              'ProductCatalogues.ProductConsumptions.DeleteMessage'
            )}
            handleClose={handleCloseForDelete}
            handleOk={handleOkForDelete}
            title={I18n('ProductCatalogues.ProductConsumptions.DeleteMessage')}
          ></ConfirmationPopup>
          {isAddModalOpen && (
            <AddProductConsumption
              isOpen={isAddModalOpen}
              editData={editData}
              entityId={entityId}
              handleClose={handleCloseForAdd}
              title={
                editData == null
                  ? i18nMessages.addProductConsumption
                  : i18nMessages.editProductConsumption
              }
              refreshData={refreshData}
            ></AddProductConsumption>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProductConsumptionList;
