import {
  Checkbox,
  FormControlLabel,
  InputLabel,
  Grid,
  Typography,
  Link,
  Breadcrumbs,
  Button,
} from '@mui/material';
import DocumentList from 'common/Document/DocumentList';
import FormAccordianSave from 'common/formControl/formAccordianSave';
import {
  ActivityOwner,
  BaseModel,
  DocumentEntity,
  EntityTypeEnum,
  ImageUploadViewModel,
  OrderType,
  ViewLevel,
  WorkOrderStatus,
} from 'models/pagination-model';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import I18n from 'utilities/i18n';
import Status from 'common/WoStatus/Status';
import http from '../../../utilities/httpService';
import environment from 'environment';
import { toast } from 'react-toastify';
import ImageUploadPreview from 'common/ImageUploadPreview/ImageUploadPreview';
import {
  DecPackWOCollectionShipmentDataImagesDTO,
  DnPWorkOrders,
} from 'models/deck-pack-orders';
import RouteEnum from 'models/RouteEnum';
import CustomImageEditor from 'common/CustomImageEditor/CustomImageEditor';
import icona from 'tui-image-editor/dist/svg/icon-a.svg';
import iconb from 'tui-image-editor/dist/svg/icon-b.svg';
import iconc from 'tui-image-editor/dist/svg/icon-c.svg';
import icond from 'tui-image-editor/dist/svg/icon-d.svg';
import DgdBackgroundImage from '../../../assets/images/WorkOrder/dgd-background.png';
import {
  getDeckPackCollectingShipmentDataImage,
  getWorkOrderImages,
  saveShipmentDataImages,
  saveWorkOrderImages,
} from 'common/ImageUploadPreview/WorkOrderImagesApiService';
import FormControl from 'common/formControl/formControl';
import { Permission } from 'Permissions';
import { hasPermission } from 'utilities/protectedRoute';
import { Utilities } from 'utilities/Utilities';
import ApiUrlConstants from 'constants/api.constants';
import DecpackStatus from './DecpackStatus';
import CreatedByandUpdatedBy from 'common/CreatedByandUpdatedBy/CreatedByandUpdatedBy';
import MobileDetails from 'common/MobileDetails/MobileDetails';

const DecPackWOCollectionData = () => {
  const { woId, id } = useParams();
  let navigate = useNavigate();
  const [canEdit, setCanEdit] = useState(
    hasPermission(Permission.canEditDecPackOrders)
  );

  const [isStatusSaveCallApi, setIsStatusSaveCallApi] =
    useState(false); /* To call Submit APi Status from Status Component */
  const [validateStatus, setValidateStatus] = useState<boolean>(false);
  const [details, setDetails] = useState<any>({
    createdBy: '',
    createdDate: '',
    updatedBy: '',
    updatedDate: '',
  });

  const [assigneeData, setAssigneeData] = useState<any>({
    assignedToId: '',
    assignedToName: '',
    assignNextToId: '',
    assignNextToName: '',
  });
  const breadcrumbs = [
    <Typography key="3" color="text.primary">
      {I18n('Nav.Order')}
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={() => navigate(RouteEnum.DecPackOrdersList)}
        className="cpointer"
      >
        {I18n('Nav.DecPackOrders')}
      </Link>
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={() => navigate(RouteEnum.ViewDecPackOrders + '/' + id + '/1')}
        className="cpointer"
      >
        {I18n('ShippingOrders.WorkOrders')}
      </Link>
    </Typography>,
  ];

  const [isMobile, setIsMobile] = useState(false);
  const [expanded, setExpanded] = useState<boolean>(true);
  const [isSaveNext, setIsSaveNext] = useState<boolean>(true);
  const [isNew, setIsNew] = useState<boolean>(true);
  const [wOCollectionDataId, setWOCollectionDataId] = useState<number>();
  const [getImageDataUrl, setImageDataUrl] = useState<boolean>(false);
  const [existingImageDataUrl, setExistingImageDataUrl] =
    useState<string>(DgdBackgroundImage);
  const [specialInstruction, setSpecialInstruction] = useState('');
  const i18nMessages = {
    Title: I18n('WOCollectionData.Title'),
    Document: I18n('WOCollectionData.Document'),
    ShipmentData: I18n('WOCollectionData.ShipmentData'),
    Photos: I18n('WOCollectionData.Photos'),
    Shipment: I18n('WOCollectionData.Shipment'),
    MoveToWarehouse: I18n('WOCollectionData.MoveToWarehouse'),
    FieldRequired: I18n('WOCollectionData.FieldRequired'),
    AddSuccess: I18n('Common.SavedSuccessfully'),
    SomethingWentWrong: I18n('WOCollectionData.SomethingWentWrong'),
    UpdateSuccess: I18n('WorkOrder.UpdatedSuccessfully'),
    ShipmentDataUpdateSuccess: I18n(
      'WOCollectionData.ShipmentDataUpdateSuccess'
    ),
    ShipmentDataUpdateFail: I18n('WOCollectionData.ShipmentDataUpdateFail'),
    SpecialInstructions: I18n('DecPack.SpecialInstructions'),
    NoShippingDocsAvailable: I18n(
      'DecPackOrders.DecPackQuestions.WOExecution.NoDocsAvailable'
    ),
    noImagesSelected: I18n('Common.NoImagesSelectedToUpload'),
    imageSaveSuccess: I18n('Common.ImageSaveSuccess'),
    somethingWentWrong: I18n('Common.SomethingWentWrong'),
  };

  const [values, setValues] = useState<any>({
    decPackWorkOrderId: 0,
    decPackInitialOrderId: 0,
    isMoveToWarehouse: false,
    images: [] as any,
    signatures: [] as any,
    status: 0,
    mobileRemarks: '',
    waitTime: 0,
  });

  const [errors, setErrors] = useState({});

  const [images, setImages] = useState<ImageUploadViewModel[]>([]);

  const validate = (fieldValues: any = values) => {
    let temp = { ...errors };

    setErrors({
      ...temp,
    });
    if (images.length === 0) {
      toast.error(i18nMessages.noImagesSelected);
      return false;
    }
    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === '');
    }
  };

  const onSaveImage = async (imageFile: File | undefined) => {
    if (imageFile) {
      const response = await saveShipmentDataImages(imageFile, {
        decPackInitialOrderId: id ? +id : 0,
        decPackWorkOrderId: woId ? +woId : 0,
        documentEntityType: DocumentEntity.DecPackCollectionShipmentData,
      } as DecPackWOCollectionShipmentDataImagesDTO);
      if (response && response.result) {
        getDeckPackCollectingShipmentDataImageApi();
      } else {
        toast.error(i18nMessages.ShipmentDataUpdateFail);
      }
      setImageDataUrl(false);
    }
  };

  const onMoveToWarehouseCheckBoxChange = (event: any) => {
    setValues({ ...values, isMoveToWarehouse: event.target.checked });
  };

  const fileDataFunction = (data: any) => {
    setImages([...images, ...data]);
  };

  const deleteFileFunction = (index: number) => {
    const localImages: any[] = [...images];
    localImages.splice(index, 1);
    setImages(localImages);
  };

  const editedFileDataFunction = (updatedImages: ImageUploadViewModel[]) => {
    setImages([...updatedImages]);
  };

  const UploadFile = async (file: any) => {
    if (file && file.length > 0) {
      for (let index = 0; index < file.length; index++) {
        if (file[index].path) {
          const element = file[index];

          const apiUrl = new URL(
            environment.api.baseUrl + 'Documents/12/upload'
          );
          const data = new FormData();
          data.append('file', element);
          const response = await http.post(apiUrl.toString(), data);
          values.images.push(response.data);
        }
      }

      return true;
    }
  };

  const handlePhotoSaveClick = async () => {
    const imagesResult = await saveWorkOrderImages(
      images,
      EntityTypeEnum.DecPackOrders,
      DocumentEntity.DecPackCollectionImages,
      false,
      id ? +id : 0,
      woId ? +woId : 0
    );
    if (imagesResult && imagesResult.result) {
    } else {
      toast.error(i18nMessages.somethingWentWrong);
    }
  };

  const getWOStatus = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl +
        'DecPackOrder/GetDecPackWO/' +
        id +
        '/' +
        DnPWorkOrders.WO3
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result) {
        setDetails({
          ...details,
          createdBy: result.data.createdBy,
          updatedBy: result.data.updatedBy,
          createdDate: result.data.createdDate,
          updatedDate: result.data.updatedDate,
        });

        setAssigneeData({
          ...assigneeData,
          assignedToId: result.data.assignedToId,
          assignedToName: result.data.assignedToName,
          assignNextToId: result.data.assignedNextToId,
          assignNextToName: result.data.assignedNextToName,
        });

        setIsMobile(result.data.isMobile);
        return result.data.status;
      }
    } catch (error) {}
  };

  const getWOCollectionData = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl + 'DecPackWOCollectionData/' + woId + '/' + id
    );
    try {
      const result = await http.get(apiUrl.toString());
      var status = await getWOStatus();

      if (result && result.data) {
        setIsNew(false);
        setWOCollectionDataId(result.data.id);

        var modifiedSigns = [] as any;
        result.data.signatureUrls.map((element: any) => {
          modifiedSigns.push(element);
        });

        getPictures();

        setValues({
          ...values,
          decPackWorkOrderId: result.data.decPackWorkOrderId,
          decPackInitialOrderId: result.data.decPackInitialOrderId,
          isMoveToWarehouse: result.data.isMoveToWarehouse,
          signatures: modifiedSigns,
          status,
          mobileRemarks: result.data.mobileRemarks,
          waitTime: result.data.waitTime,
        });
      } else {
        setIsNew(true);
        setValues({
          ...values,
          status,
        });
      }
    } catch (error) {}
  };

  const getDeckPackCollectingShipmentDataImageApi = async () => {
    const result = await getDeckPackCollectingShipmentDataImage(
      woId ? +woId : 0,
      id ? +id : 0,
      DocumentEntity.DecPackCollectionShipmentData
    );
    if (result && result.data) {
      setExistingImageDataUrl(
        environment.api.baseUrl +
          ApiUrlConstants.DownloadImageUrl +
          result.data.imageUrl
      );
    }
  };
  useEffect(() => {
    getWOCollectionData();
    getDeckPackCollectingShipmentDataImageApi();
  }, []);

  const DocumentData = () => {
    return (
      <Grid xl={12} md={12}>
        <DocumentList
          permission={Permission.canEditDecPackOrders}
          addPermission={Permission.canAddDecPackOrders}
          deletePermission={Permission.canDeleteDecPackOrders}
          entityType={EntityTypeEnum.DecPackOrders}
          paramsId={id}
          isHeaderHide={true}
          documentEntityType={DocumentEntity.DecPackCollectionData}
          isViewOnly={true}
          viewLevel={ViewLevel.InternalOperational}
        />
      </Grid>
    );
  };

  const ShipmentData = () => {
    return (
      <>
        <Grid
          item
          lg={12}
          md={12}
          sx={
            canEdit && {
              minHeight: '400px',
              height: '600px',
            }
          }
        >
          {canEdit && (
            <CustomImageEditor
              isSaveImage={getImageDataUrl}
              onSave={onSaveImage}
              includeUI={{
                loadImage: {
                  path: existingImageDataUrl,
                  name: 'dgd-signature.png',
                },
                theme: {
                  'header.display': 'none',
                  'menu.normalIcon.path': icond,
                  'menu.activeIcon.path': iconb,
                  'menu.disabledIcon.path': icona,
                  'menu.hoverIcon.path': iconc,
                  'submenu.normalIcon.path': icond,
                  'submenu.activeIcon.path': iconb,
                  'common.backgroundColor': '#fff',
                  'common.border': '1px solid #c1c1c1',
                  'Common.bisize.height': '21px',
                  'common.bisize.width': '251px',
                  'common.bi.image':
                    'https://uicdn.toast.com/toastui/img/tui-image-editor-bi.png',
                },
                menu: ['draw', 'text'],
                initMenu: 'draw',
                uiSize: {
                  width: window.innerWidth,
                  height: window.innerHeight,
                },
                menuBarPosition: 'bottom',
              }}
              cssMaxHeight={window.innerHeight}
              cssMaxWidth={window.innerWidth}
              selectionStyle={{
                cornerSize: 2,
              }}
              usageStatistics={true}
            />
          )}
          {!canEdit && (
            <img
              src={existingImageDataUrl}
              alt={i18nMessages.noImagesSelected}
            />
          )}
        </Grid>
      </>
    );
  };

  const getPictures = () => {
    getWorkOrderImages(
      EntityTypeEnum.DecPackOrders,
      woId ? +woId : 0,
      id ? +id : 0,
      DocumentEntity.DecPackCollectionImages
    ).then((x) => {
      if (x.result) {
        /* Object conversion from backend viewModel to ImagePreview component Object */
        setImages(
          x.data.map((y) => {
            return {
              documentTag: {
                label: y.documentTagName,
                value: y.documentTag?.toString(),
              } as BaseModel,
              error: '',
              id: y.id,
              previewUrl:
                environment.api.baseUrl +
                ApiUrlConstants.DownloadImageUrl +
                y.imageUrl,
              path: y.imageUrl,
            } as ImageUploadViewModel;
          })
        );
      }
    });
  };

  const PhotosSection = () => {
    return (
      <>
        <Grid
          sx={{ mb: 2 }}
          container
          spacing={1}
          className="grid-wrap two-col mb-2"
        >
          <Grid item lg={12}>
            <ImageUploadPreview
              readOnly={!canEdit}
              disableKeyboardNav={true}
              limit={10}
              fileData={fileDataFunction}
              fileRemove={deleteFileFunction}
              editedFileData={editedFileDataFunction}
              images={images}
            />
          </Grid>
        </Grid>
        {!(images && images.length) && (
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item lg={12}>
              {i18nMessages.NoShippingDocsAvailable}
            </Grid>
          </Grid>
        )}
      </>
    );
  };

  const Shipment = () => {
    return (
      <>
        <Grid item lg={12} md={12}>
          <FormControlLabel
            labelPlacement="end"
            control={
              <Checkbox
                name="MoveToWarehouse"
                checked={values.isMoveToWarehouse}
                onChange={onMoveToWarehouseCheckBoxChange}
                inputProps={{ 'aria-label': 'controlled' }}
                disabled={!canEdit}
              />
            }
            label={i18nMessages.MoveToWarehouse}
          />
        </Grid>
      </>
    );
  };

  const InstructionsSection = () => {
    return (
      <>
        <Grid item lg={12} md={12}>
          <FormControl
            margin="normal"
            id="SpecialInstructions"
            name="SpecialInstructions"
            inputType="text"
            inputProps={{ maxLength: 30 }}
            readOnly={true}
            value={specialInstruction}
            multiline={true}
          ></FormControl>
        </Grid>
      </>
    );
  };
  const getDecPackById = async () => {
    const apiUrl = new URL(environment.api.baseUrl + `DecPackOrder/${id}`);
    try {
      const result = await http.get(apiUrl.toString());
      if (result && result.data) {
        setSpecialInstruction(result.data.specialInstructions);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getDecPackById();
  }, []);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setValidateStatus(true);
    setIsSaveNext(false);
  };

  const handleSuccessStatusSave = async (imageFile: File) => {
    let isValid = values.status == WorkOrderStatus.Done ? validate() : true;
    if (isValid) {
      setImageDataUrl(true);
      handlePhotoSaveClick();
      if (values && values.signatures && values.signatures.length > 0) {
        await UploadFile(values.signatures);
      }

      const finalValue = {
        decPackWorkOrderId: Number(woId),
        decPackInitialOrderId: Number(id),
        isMoveToWarehouse: values.isMoveToWarehouse,
        imageUrls: values.images.filter(
          (item: any) => typeof item !== 'object'
        ),
        signatureUrls: values.signatures.filter(
          (item: any) => typeof item !== 'object'
        ),
        id: wOCollectionDataId,
        mobileRemarks: values.mobileRemarks,
        waitTime: values.waitTime,
      };
      if (isNew) {
        const apiUrl = new URL(
          environment.api.baseUrl + 'DecPackWOCollectionData'
        );
        try {
          const result: any = await http.post(apiUrl.toString(), finalValue);
          setIsStatusSaveCallApi(true);
          if (result) {
            toast.success(i18nMessages.AddSuccess);
            await getWOCollectionData();
            handleErrorStatusSave(false);
          }
        } catch (error: any) {
          toast.error(i18nMessages.SomethingWentWrong);
          handleErrorStatusSave(false);
        }
      } else {
        const apiUrl = new URL(
          environment.api.baseUrl +
            'DecPackWOCollectionData/' +
            wOCollectionDataId
        );
        try {
          const result: any = await http.put(apiUrl.toString(), finalValue);
          setIsStatusSaveCallApi(true);
          if (result) {
            toast.success(i18nMessages.UpdateSuccess);
            await getWOCollectionData();
            handleErrorStatusSave(false);
          }
        } catch (error: any) {
          toast.error(i18nMessages.SomethingWentWrong);
          handleErrorStatusSave(false);
        }
      }
    } else {
      handleErrorStatusSave(false);
    }
  };

  /* Validate Status Dropdown Error call from Status Component */
  const handleErrorStatusSave = (data: any) => {
    setValidateStatus(false);
    setIsStatusSaveCallApi(false);
    setImageDataUrl(false);
  };

  const onSaveNextClick = async (event: any) => {
    event.preventDefault();
    setValues({ ...values, status: WorkOrderStatus.Done });
    setValidateStatus(true);
    setIsSaveNext(true);
  };

  const refreshData = async () => {
    handleErrorStatusSave(false);
    getWOStatus();
  };

  return (
    <div>
      <div className="heading-section">
        <div className="heading-section-left">
          <Typography variant="h3" sx={{ mb: 6 }}>
            {i18nMessages.Title}
          </Typography>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>
      </div>
      <div className="main-content-section">
        <FormAccordianSave
          title={i18nMessages.Document}
          className="inner-heading"
          details={DocumentData()}
        ></FormAccordianSave>
        <FormAccordianSave
          title={i18nMessages.ShipmentData}
          className="inner-heading"
          details={ShipmentData()}
        ></FormAccordianSave>
        <FormAccordianSave
          title={i18nMessages.Photos}
          className="inner-heading"
          details={PhotosSection()}
        ></FormAccordianSave>
        <FormAccordianSave
          title={i18nMessages.Shipment}
          className="inner-heading"
          details={Shipment()}
        ></FormAccordianSave>
        <FormAccordianSave
          title={i18nMessages.SpecialInstructions}
          className="inner-heading"
          details={InstructionsSection()}
        ></FormAccordianSave>
        <MobileDetails
          mobileRemarks={values.mobileRemarks}
          waitTime={values.waitTime}
        ></MobileDetails>
        <DecpackStatus
          permission={Permission.canEditDecPackOrders}
          woId={DnPWorkOrders.WO3}
          activityOwner={ActivityOwner.Driver}
          orderId={id}
          statusValue={values.status}
          orderType={OrderType.DecPackOrder}
          isStatusSaveCallApi={isStatusSaveCallApi}
          validateStatus={validateStatus}
          handleSuccessStatusSave={handleSuccessStatusSave}
          handleErrorStatusSave={handleErrorStatusSave}
          isSaveNext={isSaveNext}
          isMobile={isMobile}
          workOrderNo={DnPWorkOrders.WO3}
          assigneeData={assigneeData}
          handleStatusChange={(value: number) =>
            setValues({ ...values, status: value })
          }
        />
        <div className="btn-wrap form-btn-wrap">
          {canEdit ? (
            <Button
              color="primary"
              disableElevation
              variant="contained"
              onClick={onSaveNextClick}
            >
              {I18n('Common.SaveNext')}
            </Button>
          ) : (
            <></>
          )}
          &nbsp; &nbsp; &nbsp;
          {canEdit ? (
            <Button
              type="submit"
              color="primary"
              disableElevation
              variant="contained"
              onClick={handleSubmit}
            >
              {I18n('Common.Save')}
            </Button>
          ) : (
            <></>
          )}
        </div>
        <CreatedByandUpdatedBy
          createdBy={details.createdBy}
          createdDate={details.createdDate}
          updatedBy={details.updatedBy}
          updatedDate={details.updatedDate}
        ></CreatedByandUpdatedBy>
      </div>
    </div>
  );
};

export default DecPackWOCollectionData;
