import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CustomizedSelect from 'common/CustomSelect/CustomSelect';
import CustomSelectPaginate from 'common/CustomSelect/CustomSelectPaginate';
import { loadRejectionMasterOptions } from 'components/shipping-orders/ShippingApiService';
import { BaseModel, CopyStateDescription } from 'models/pagination-model';
import { useState } from 'react';
import I18n from 'utilities/i18n';

export default function OrderCopyPopup(props: any) {
  const {
    isOpen,
    handleClose,
    handleOk,
    message,
    title,
    hasCancel = true,
  } = props;

  const defaultValue = {
    value: '',
    label: I18n('Placeholders.Select'),
  } as BaseModel;

  const [error, setError] = useState('');

  let copyStateList: Array<BaseModel> = [];
  const getcopyStateList = () => {
    CopyStateDescription.forEach((value: string, key: number) => {
      copyStateList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getcopyStateList();
  const [copyState, setCopyState] = useState<any>(copyStateList[0]);

  const handleOkClick = () => {
    if (copyState) {
      setError('');
      handleOk(copyState.value);
    } else {
      setError(I18n('Common.FieldRequired'));
    }
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
          <br></br>

          <CustomizedSelect
            placeholder={defaultValue.label}
            options={copyStateList}
            isSearchable={false}
            displayLabel={I18n('ShippingOrders.CopyLevel')}
            handleChange={(newValue: BaseModel) => setCopyState(newValue)}
            value={copyState}
          ></CustomizedSelect>
        </DialogContent>
        <DialogActions>
          {hasCancel ? (
            <Button onClick={handleClose}>
              {I18n('UserList.DeleteModal.Cancel')}
            </Button>
          ) : (
            <></>
          )}
          <Button onClick={handleOkClick} autoFocus>
            {I18n('UserList.DeleteModal.Ok')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
