import { Navigate, useLocation, Outlet } from 'react-router-dom';
import auth from '../auth/authentication';

import AccessDenied from '../common/accessDenied/accessDenied';
import environment from 'environment';
import http from '../utilities/httpService';
import { useEffect, useState } from 'react';
import { cpUserPermittedModules } from 'models/pagination-model';
import { Utilities } from './Utilities';

const customerPortalUser = Utilities.getIsCPUser();
const ProtectedRoute = (props: any) => {
  let location = useLocation();
  const [permissions, setPermissions] = useState<string>(
    localStorage.getItem('permissions') || ''
  );

  useEffect(() => {
    if (permissions === '') {
      fetchPermissions().then((data) => {
        setPermissions(data?.toString());
      });
    }
  }, [permissions]);

  // Check login session
  const isAuthenticated = auth.getCurrentUser() ? true : false;

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  // Check permisson if any
  if (props.permission && !hasPermission(props.permission)) {
    return <AccessDenied />;
  }

  const token = localStorage.getItem('token') || '';

  if (token) {
    if (props.children) {
      return props.children;
    }
    return <Outlet />;
  }
};

const fetchPermissions = async () => {
  const apiUrl = new URL(
    environment.api.baseUrl + 'Roles/GetRoleModulePermissionsForUser'
  );
  try {
    const result = await http.get(apiUrl.toString());
    localStorage.setItem('permissions', result.data);
    return result.data;
  } catch {}
};

export const hasPermission = (permission: string) => {
  let permissions: any = localStorage.getItem('permissions') || '';
  permissions = permissions?.split(',');
  if (customerPortalUser) {
    return permissions?.includes(permission) && getCPUserPermission(permission);
  } else {
    return permissions?.includes(permission);
  }
};

export const hasMenuPermission = async (permission: string) => {
  let permissions: any = (await localStorage.getItem('permissions')) || '';
  return permissions?.includes(permission);
};

export const getCPUserPermission = (permission: string) => {
  return cpUserPermittedModules.includes(permission);
};

export default ProtectedRoute;
