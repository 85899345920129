import FileUpload from 'common/fileupload/fileupload';
import { useCallback, useEffect, useState } from 'react';
import ImageViewer from 'react-simple-image-viewer';
import environment from 'environment';
import { BaseModel, ImageUploadViewModel } from 'models/pagination-model';
import ImageUploadAdd from './ImageUploadAdd';
import I18n from 'utilities/i18n';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Checkbox, FormControlLabel, IconButton } from '@mui/material';
import FormControl from 'common/formControl/formControl';
import EditIcon from '@mui/icons-material/Edit';
import EditImage from './EditImage';
import { Utilities } from 'utilities/Utilities';
import ApiUrlConstants from 'constants/api.constants';
import NotFoundImage from '../../assets/images/404.jpg';

function ImageUploadPreview(props: any) {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [images, setImages] = useState<ImageUploadViewModel[]>([]);
  const [selectedImage, setSelectedImage] = useState<ImageUploadViewModel>();
  const [imageURLs, setImageURLs] = useState<string[]>([]);
  const [dropzoneKey, setDropzoneKey] = useState(0);
  const [previewImages, setPreviewImages] = useState<ImageUploadViewModel[]>(
    []
  );
  const i18nMessages = {
    Picture: I18n('Packaging.Picture'),
    EditPicture: I18n('Common.EditPicture'),
    documentTag: I18n('Common.DocumentTag'),
  };

  useEffect(() => {
    const newImageUrls: string[] = [];
    props.images.forEach((item: ImageUploadViewModel) => {
      props.isImageOnly
        ? newImageUrls.push(environment.api.uploadedFileUrl + item.imageUrl)
        : newImageUrls.push(
            item && item.image && !item.id
              ? URL.createObjectURL(item.image)
              : environment.api.baseUrl +
                  ApiUrlConstants.DownloadImageUrl +
                  item.path
          );
    });
    setImageURLs([...newImageUrls]);
    setPreviewImages([...props?.images]);
  }, [props.images]);

  useEffect(() => {
    if (images && images.length) {
      setIsPopupOpen(true);
      setDropzoneKey(dropzoneKey + 1);
    }
  }, [images]);

  const handleSaveUpload = async (files: File[]) => {
    if (files && files.length > 0) {
      setImages([
        ...images,
        ...files.map((x) => {
          return {
            documentTag: {} as BaseModel,
            sendToCustomer: false,
            id: 0,
            previewUrl: URL.createObjectURL(x),
            image: x,
            componentTempId: Utilities.createUUID(),
          } as ImageUploadViewModel;
        }),
      ]);
    }
  };

  const handleEditIconClick = useCallback(
    (image: ImageUploadViewModel) => () => {
      setSelectedImage(image);
      setIsEditPopupOpen(true);
    },
    []
  );

  const openImageViewer = useCallback((index: any) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  const handleFileDelete = (index: number) => {
    props.fileRemove(index);
  };
  const handleLocalFileDelete = (index: number) => {
    if (images[index]) {
      const localImages: any[] = [...images];
      localImages.splice(index, 1);
      setImages(localImages);
    }
  };
  const onClosePopup = (event: any) => {
    setImages([]);
    setIsPopupOpen(false);
  };
  const onCloseImageEditPopup = (event: any) => {
    setIsEditPopupOpen(false);
  };
  const handleOnEditPopupSaveImages = (
    imageFile: File,
    image: ImageUploadViewModel
  ) => {
    image.image = imageFile;
    image.previewUrl = URL.createObjectURL(imageFile);
    image.isEdited = true;
    const localImages = [...props.images];
    if (+image.id) {
      const imageIndex = (props.images as ImageUploadViewModel[]).findIndex(
        (x) => x.id === image.id
      );
      if (~imageIndex) {
        localImages[imageIndex] = image;
      }
    } else {
      const imageIndex = (props.images as ImageUploadViewModel[]).findIndex(
        (x) => x.componentTempId === image.componentTempId
      );
      if (~imageIndex) {
        localImages[imageIndex] = image;
      }
    }
    props.editedFileData(localImages);
    setIsEditPopupOpen(false);
  };
  const handleOnSaveImages = (images: ImageUploadViewModel[]) => {
    props.fileData([...images]);
    setImages([]);
    setIsPopupOpen(false);
  };
  return (
    <div>
      {!props.readOnly && (
        <FileUpload
          filesLimit={props.limit}
          onSave={handleSaveUpload}
          showPreviews={false}
          maxFileSize={10485760}
          acceptedFiles={['image/jpeg', 'image/png', 'image/jpg', 'image/bmp']}
          dropzoneKey={dropzoneKey}
        />
      )}
      {isPopupOpen && (
        <ImageUploadAdd
          isOpen={isPopupOpen}
          images={images}
          handleClose={onClosePopup}
          title={props.title ? props.title : i18nMessages.Picture}
          onSave={handleOnSaveImages}
          handleFileDelete={handleLocalFileDelete}
          sendToCustomer={props.sendToCustomer}
        ></ImageUploadAdd>
      )}
      <Box display="flex" flexWrap="wrap">
        {previewImages.map((src: ImageUploadViewModel, index: number) => (
          <Box key={index}>
            <div className="image-card">
              <Box
                position="relative"
                display="inline-block"
                className="image-outer"
              >
                <img
                  src={
                    props.isImageOnly
                      ? environment.api.uploadedFileUrl + src.imageUrl
                      : src.previewUrl
                  }
                  onClick={() => {
                    if (!src.imageError) {
                      openImageViewer(index);
                    }
                  }}
                  key={index}
                  style={{ margin: '2px' }}
                  alt=""
                  className="text-center"
                  onError={(event: any) => {
                    event.currentTarget.src = NotFoundImage;
                    src.imageError = true;
                  }}
                />
                {!props.readOnly && (
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    className="btn-right1"
                    component="label"
                    onClick={handleEditIconClick(src)}
                    disabled={src.imageError}
                  >
                    <EditIcon />
                  </IconButton>
                )}
                {!props.readOnly && (
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    className="btn-right2"
                    component="label"
                    onClick={() => handleFileDelete(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </Box>
              {props.hideTags ? (
                <></>
              ) : (
                <FormControl
                  margin="normal"
                  displayLabel={i18nMessages.documentTag}
                  id={index}
                  inputType="text"
                  multiline={false}
                  value={src?.documentTag?.label}
                  readOnly={true}
                />
              )}
              {props.sendToCustomer ? (
                <FormControlLabel
                  control={
                    <Checkbox
                      name="sendToCustomer"
                      checked={src?.sendToCustomer}
                      onChange={(e) => {
                        const temporaryImages = [...previewImages];
                        temporaryImages[index].sendToCustomer =
                          e.target.checked;
                        setPreviewImages(temporaryImages);
                      }}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label={I18n('Common.SendToCustomer')}
                />
              ) : (
                <></>
              )}
            </div>
          </Box>
        ))}
      </Box>
      {isViewerOpen && (
        <ImageViewer
          src={imageURLs}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: 'rgba(0,0,0,0.9)',
          }}
          closeOnClickOutside={true}
        />
      )}
      {isEditPopupOpen && (
        <EditImage
          isOpen={isEditPopupOpen}
          image={selectedImage}
          handleClose={onCloseImageEditPopup}
          title={i18nMessages.EditPicture}
          onSave={handleOnEditPopupSaveImages}
          sendToCustomer={props.sendToCustomer}
        ></EditImage>
      )}
    </div>
  );
}

export default ImageUploadPreview;
