import {
  ApiResponse,
  BaseModel,
  EntityStatus,
  ApprovalStatus,
  PaginationResponseModel,
  ApiResponseModel,
} from 'models/pagination-model';
import { Customer } from 'models/service-catalogue';
import environment from 'environment';
import http from '../../utilities/httpService';
import { Location as EntityLocation } from 'models/location';

const getCustomers = async (search: string, pageNo: number, id: number) => {
  let response: PaginationResponseModel<Customer>;
  const apiUrl = new URL(environment.api.baseUrl + 'Customer');
  try {
    let searchExpression;
    if (id !== undefined && id) {
      searchExpression =
        'TaggedTo~=~1 OR TaggedTo~=~3 and Status~=~' +
        EntityStatus.Active +
        ' and ApprovalStatus~=~' +
        ApprovalStatus.Approved +
        ` OR id~=~` +
        id;
    } else {
      searchExpression =
        'TaggedTo~=~1 OR TaggedTo~=~3 and Status~=~' +
        EntityStatus.Active +
        ' and ApprovalStatus~=~' +
        ApprovalStatus.Approved;
    }

    searchExpression += search ? ` and name~like~${search}` : '';
    apiUrl.searchParams.set('$filter', searchExpression);
    apiUrl.searchParams.set('$orderby', 'name');
    apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
    apiUrl.searchParams.set('$top', '10');
    apiUrl.searchParams.set('$orderbydirection', 'desc');
    const result = await http.get(apiUrl.toString());
    if (result) {
      response = {
        TotalRecords: result.data.totalCount,
        Values: result.data.values,
      } as PaginationResponseModel<Customer>;
    } else {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<Customer>;
    }
  } catch (error) {
    response = {
      TotalRecords: 0,
      Values: [],
    } as PaginationResponseModel<Customer>;
  }
  return response;
};

export async function loadCustomerOptions(
  search: string,
  loadedOptions: any,
  { page }: any,
  id?: any
) {
  const response = await getCustomers(search, page, id);
  const hasMore = Math.ceil(response.TotalRecords / 10) > page;

  return {
    options: response.Values.map((x) => {
      return {
        value: x.id.toString(),
        label: x.name,
      } as BaseModel;
    }),
    hasMore,
    additional: {
      page: page + 1,
    },
  };
}

export const getLocations = async (
  customerId: number,
  search: string,
  pageNo: number
) => {
  let response: PaginationResponseModel<EntityLocation>;
  const apiUrl = new URL(environment.api.baseUrl + 'LabelMaster');
  try {
    let searchExpression = '';
    searchExpression += search
      ? `Shipper~like~${search} or Consignee~like~${search} or Address2~like~${search} or Street~like~${search} or PostalCode~like~${search} or City.name~like~${search} or Country.name~like~${search} and`
      : '';

    apiUrl.searchParams.set('$filter', searchExpression);
    apiUrl.searchParams.set('$orderby', 'address');
    apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
    apiUrl.searchParams.set('$top', '10');
    apiUrl.searchParams.set('$orderbydirection', 'desc');
    const result = await http.get(apiUrl.toString());
    if (result) {
      response = {
        TotalRecords: result.data.totalCount,
        Values: result.data.values,
      } as PaginationResponseModel<EntityLocation>;
    } else {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<EntityLocation>;
    }
  } catch (error) {
    response = {
      TotalRecords: 0,
      Values: [],
    } as PaginationResponseModel<EntityLocation>;
  }
  return response;
};

export const getShippers = async (search: string, pageNo: number) => {
  let response: PaginationResponseModel<EntityLabelMaster>;
  const apiUrl = new URL(environment.api.baseUrl + 'LabelMaster');
  try {
    let searchExpression = `Shipper~!=~null and Shipper~!=~''`;
    searchExpression += search ? ` and Shipper~like~${search}` : '';

    apiUrl.searchParams.set('$filter', searchExpression);
    apiUrl.searchParams.set('$orderby', 'shipper');
    apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
    apiUrl.searchParams.set('$top', '10');
    apiUrl.searchParams.set('$orderbydirection', 'desc');
    const result = await http.get(apiUrl.toString());
    if (result) {
      response = {
        TotalRecords: result.data.totalCount,
        Values: result.data.values,
      } as PaginationResponseModel<EntityLabelMaster>;
    } else {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<EntityLabelMaster>;
    }
  } catch (error) {
    response = {
      TotalRecords: 0,
      Values: [],
    } as PaginationResponseModel<EntityLabelMaster>;
  }
  return response;
};

export async function loadShipperLabelOptions(
  search: string,
  loadedOptions: any,
  { page }: any
) {
  const response = await getShippers(search, page);
  const hasMore = Math.ceil(response.TotalRecords / 10) > page;
  return {
    options: response.Values.filter(
      (x) => x.shipper && x.shipper !== undefined
    ).map((x) => {
      return {
        value: x.id.toString(),
        label: `${x.shipper}`,
      } as BaseModel;
    }),
    hasMore,
    additional: {
      page: page + 1,
    },
  };
}

export const getConsignees = async (search: string, pageNo: number) => {
  let response: PaginationResponseModel<EntityLabelMaster>;
  const apiUrl = new URL(environment.api.baseUrl + 'LabelMaster');
  try {
    let searchExpression = `Consignee~!=~null and Consignee~!=~''`;
    searchExpression += search ? ` and Consignee~like~${search}` : '';

    apiUrl.searchParams.set('$filter', searchExpression);
    apiUrl.searchParams.set('$orderby', 'consignee');
    apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
    apiUrl.searchParams.set('$top', '10');
    apiUrl.searchParams.set('$orderbydirection', 'desc');
    const result = await http.get(apiUrl.toString());
    if (result) {
      response = {
        TotalRecords: result.data.totalCount,
        Values: result.data.values,
      } as PaginationResponseModel<EntityLabelMaster>;
    } else {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<EntityLabelMaster>;
    }
  } catch (error) {
    response = {
      TotalRecords: 0,
      Values: [],
    } as PaginationResponseModel<EntityLabelMaster>;
  }
  return response;
};

export async function loadConsigneeLabelOptions(
  search: string,
  loadedOptions: any,
  { page }: any
) {
  const response = await getConsignees(search, page);
  const hasMore = Math.ceil(response.TotalRecords / 10) > page;
  return {
    options: response.Values.filter(
      (x) => x.consignee && x.consignee !== undefined
    ).map((x) => {
      return {
        value: x.id.toString(),
        label: `${x.consignee}`,
      } as BaseModel;
    }),
    hasMore,
    additional: {
      page: page + 1,
    },
  };
}

export async function getLabelById(id: number) {
  const apiUrl = new URL(environment.api.baseUrl + 'LabelMaster/' + id);
  try {
    const result: any = await http.get(apiUrl.toString());
    if (result) {
      if (result && result.data) {
        return {
          result: true,
          data: result.data,
        } as ApiResponse<any>;
      } else {
        return {
          result: false,
          data: null,
        } as ApiResponse<any>;
      }
    }
  } catch (error: any) {
    return {
      result: false,
      data: null,
    } as ApiResponse<any>;
  }
}

export interface EntityLabelMaster {
  id: number;
  country: string;
  city: string;
  postalCode: string;
  address2: string;
  street: string;
  shipper: string;
  consignee: string;
  emergencyPhoneNumber: string;
}

export async function saveLabelDetail(requestModel: LabelMasterModel) {
  const apiUrl = new URL(environment.api.baseUrl + 'LabelMaster');
  try {
    var result: any = {};
    if (requestModel.id && requestModel.id > 0) {
      result = await http.put(apiUrl.toString(), requestModel);
    } else {
      result = await http.post(apiUrl.toString(), requestModel);
    }
    if (result.data !== '') {
      var response: any = {
        responseType: result.data.responseType,
        data: result.data.result,
        isSuccess: result.data.isSuccess,
      } as ApiResponseModel<LabelMasterModel>;
      return response;
    }
  } catch (error: any) {}
  return {
    isSuccess: false,
    data: null,
  } as ApiResponseModel<null>;
}

export interface LabelMasterModel {
  id: number;
  shipper: string;
  consignee: string;
  emergencyPhoneNumber: string;
  address2: string;
  street: string;
  postalCode: string;
  cityId: number;
  countryId: number;
  city: string;
  country: number;
  updateLocation: boolean;
}

export interface LabelAddressSegment {
  country: BaseModel;
  city: BaseModel;
  postalCode: string;
  address: string;
  address2: string;
  street: string;
  emergencyPhoneNumber: string;
}

export enum LabelEntity {
  Shipper = 1,
  Consignee,
}
