import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { RadioGroupBaseModel } from 'models/pagination-model';
import { RadioGroup } from '@mui/material';
import { FormHelperText } from '@mui/material';

export const RadioGroupInput = (props: any) => {
  const {
    displayLabel,
    handleChange,
    options,
    value,
    readOnly,
    name,
    defaultValue,
    errorValue,
    errorMessage,
    className = '',
  } = props;
  return (
    <FormControl
      variant="standard"
      fullWidth
      sx={{ mb: 2 }}
      error={errorMessage}
    >
      <FormLabel>{displayLabel}</FormLabel>
      <RadioGroup
        row
        name={name}
        value={value}
        onChange={handleChange}
        defaultValue={defaultValue}
      >
        {options.map((object: RadioGroupBaseModel) => (
          <FormControlLabel
            key={object.value}
            disabled={readOnly || object.disabled}
            value={object.value}
            control={<Radio />}
            label={object.label}
            className={className + ' mt-0'}
          />
        ))}
      </RadioGroup>
      {errorValue ? <FormHelperText>{errorMessage}</FormHelperText> : ''}
    </FormControl>
  );
};
export default RadioGroupInput;
