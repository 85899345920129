import { useCallback, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import I18n from 'utilities/i18n';
import { Grid, Tooltip, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {
  AirlineRestrictionModuleDescription,
  BaseModel,
  OrderByDirection,
  StateRestrictionModuleDescription,
} from 'models/pagination-model';
import environment from 'environment';
import { toast } from 'react-toastify';
import http from '../../utilities/httpService';
import CustomizedInputs from 'common/formControl/formControl';
import CustomDataGridInline from 'common/datagrid/CustomDataGridInline';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  GridActionsCellItem,
  GridColumns,
  GridPaginationInitialState,
  GridRenderEditCellParams,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridRowsProp,
  GridSortingInitialState,
  GridSortModel,
  GridToolbarContainer,
  MuiEvent,
  useGridApiContext,
} from '@mui/x-data-grid';
import { hasPermission } from 'utilities/protectedRoute';
import { Permission } from 'Permissions';
import CustomizedSelect from 'common/CustomSelect/CustomSelect';
import { CustomTooltip } from 'common/CustomTooltip/CustomTooltip';

export default function AirlinePopup(props: any) {
  const { isOpen, handleClose, title, data } = props;
  const [gridData, setGridData] = useState({
    sortOrder: OrderByDirection.Descending,
    sortBy: 'CreatedDate',
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [10, 20, 50, 100],
    pageSize: 10,
    page: 0,
    searchExpression: '',
  });
  const defaultValue = {
    value: '',
    label: I18n('Placeholders.Select'),
  } as BaseModel;
  type Row = (typeof gridData.rows)[number];
  const [rows, setRows] = useState([]);
  const [editdata, setData] = useState(data);
  const [errors, setErrors] = useState<any>({});
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  var uniqueRowId =
    gridData.rows && gridData.rows.length ? gridData.rows.length + 1 : 0;

  const validateRows = (fieldViews: any) => {
    if ('restriction' in fieldViews && fieldViews.restriction === '') {
      return 'Restriction ' + i18nMessages.fieldRequired;
    }
    if ('applicableTo' in fieldViews && fieldViews.applicableTo === '') {
      return 'Applicable to ' + i18nMessages.fieldRequired;
    }

    return '';
  };

  const validate = (fieldViews: any = editdata) => {
    let temp: any = {};
    let isValid = true;

    if (Object.keys(fieldViews).length == 0) {
      temp.name = i18nMessages.fieldRequired;
      isValid = false;
    }

    if ('name' in fieldViews) {
      if (fieldViews.name === '') {
        temp.name = i18nMessages.fieldRequired;
        isValid = false;
      }
    }

    for (let i = 0; i < gridData.rows.length; i++) {
      const row: any = gridData.rows[i];
      if (row.restriction === '' || row.applicableTo === '') {
        isValid = false;
        toast.error(i18nMessages.ProperDataValidate);
        break;
      }
    }

    setErrors({
      ...temp,
    });

    if (isValid) {
      return true;
    } else {
      return false;
    }
  };

  const getAirlineRestrictions = () => {
    var airlineRestriction = [];
    for (let i = 0; i < gridData.rows.length; i++) {
      var row: any = gridData.rows[i];

      airlineRestriction.push({
        restriction: row.restriction,
        applicableTo: +row.applicableTo.value,
        airlineId: editdata.id ? editdata.id : 0,
      });
    }
    return airlineRestriction;
  };

  const submit = async () => {
    var ids: any[] = [];
    for (var i = 0; i < gridData.rows.length; i++) {
      ids.push(gridData.rows[i]['id']);
    }

    ids.forEach((id) => {
      setTimeout(() => {
        setRowModesModel({
          ...rowModesModel,
          [id]: {
            mode: GridRowModes.View,
            ignoreModifications: true,
          },
        });
      }, 200);
    });

    if (validate(editdata)) {
      var airline = {
        id: editdata.id ? editdata.id : 0,
        name: editdata.name,
        airlineRestriction: getAirlineRestrictions(),
      };

      let apiUrl;
      try {
        let result: any;
        if (airline.id === 0) {
          apiUrl = new URL(environment.api.baseUrl + 'Airline/');
          result = await http.post(apiUrl.toString(), airline);
        } else {
          apiUrl = new URL(environment.api.baseUrl + 'Airline/' + editdata.id);
          result = await http.put(apiUrl.toString(), airline);
        }
        if (result) {
          if (result.data) {
            toast.success(i18nMessages.CommonSaveSuccessMsg);
            resetAddEvent();
            props.OnSave(result.data);
          }
        } else {
          toast.error(i18nMessages.CommonErrOccuredMsg);
        }
      } catch (error: any) {}
    }
  };

  const keydown = (event: any) => {
    if (event.altKey && event.keyCode == 65) {
      var addButton = document.getElementById('addRecord');
      addButton?.click();
    }
  };

  const handleCloseClick = () => {
    resetAddEvent();
    handleClose();
  };

  const i18nMessages = {
    Name: I18n('Airline.Name'),
    CommonSaveSuccessMsg: I18n('Common.SavedSuccessfully'),
    CommonErrOccuredMsg: I18n('Common.ErrorOccurred'),
    fieldRequired: I18n('Airline.FieldRequired'),
    ProperDataValidate: I18n('Airline.ProperDataValidate'),
  };

  const onValueChange = (event: any) => {
    const { name, value } = event.target;
    setData({
      ...editdata,
      [name.toString()]: value,
    });
  };

  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
    const editedRow: any = gridData.rows.find((row: any) => row.id === id);
    if (editedRow!.isNew) {
      updateGridData(
        'rows',
        gridData.rows.filter((row: any) => row.id !== id)
      );
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'restriction' },
    });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    updateGridData(
      'rows',
      gridData.rows.filter(function (row: any) {
        return row.id !== id;
      })
    );
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    if (sortModel && sortModel.length) {
      updateGridData('sortBy', sortModel[0].field);
      updateGridData(
        'sortOrder',
        sortModel[0].sort === 'asc'
          ? OrderByDirection.Ascending
          : OrderByDirection.Descending
      );
    }
  }, []);

  const handleChangePage = (pageNo: number) => {
    updateGridData('page', pageNo);
  };

  const handleChangePageSize = (pageSize: number) => {
    updateGridData('pageSize', pageSize);
  };

  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    setRowModesModel({
      ...rowModesModel,
      [params.id]: { mode: GridRowModes.Edit, fieldToFocus: 'restriction' },
    });
  };

  let typeList: Array<BaseModel> = [];
  const ApplicableToList = () => {
    AirlineRestrictionModuleDescription.forEach(
      (value: string, key: number) => {
        typeList.push({
          value: key.toString(),
          label: I18n(value),
        } as BaseModel);
      }
    );
  };
  ApplicableToList();

  function ApplicableToEditComponent(props: GridRenderEditCellParams) {
    const { id, field } = props;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const apiRef = useGridApiContext();
    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event;
      apiRef.current.setEditCellValue({ id, field, value: newValue });
    };
    return (
      <CustomizedSelect
        key={props?.row?.applicableTo}
        placeholder={defaultValue.label}
        options={typeList}
        isSearchable={false}
        handleChange={handleValueChange}
        errorValue={true}
        value={props?.row?.applicableTo}
        isDisplayLabelClass={false}
      ></CustomizedSelect>
    );
  }

  const columns: GridColumns<Row> = [
    {
      field: 'restriction',
      headerName: I18n('Airline.Restriction'),
      flex: 1,
      sortable: false,
      editable: true,
      renderCell: (params) => {
        return (
          <CustomTooltip
            title={<Typography fontSize={14}>{params.value}</Typography>}
            placement="bottom-start"
          >
            <div>{params.value}</div>
          </CustomTooltip>
        );
      },
    },
    {
      field: 'applicableTo',
      headerName: I18n('Airline.ApplicableTo'),
      sortable: false,
      editable: true,
      width: 150,
      renderEditCell: (params) => {
        return <ApplicableToEditComponent {...params} />;
      },
      renderCell: (params: any) => {
        return params.row.applicableToDescription;
      },
    },
    {
      field: 'actions',
      headerName: I18n('Common.Actions'),
      type: 'actions',
      width: 150,
      getActions: (params: any) => {
        const actionButtons = [];

        const isInEditMode =
          rowModesModel[params.id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          if (
            hasPermission(Permission.canAddAirline) ||
            hasPermission(Permission.canEditAirline)
          ) {
            actionButtons.push(
              <GridActionsCellItem
                onPointerEnterCapture={false}
                onPointerLeaveCapture={false}
                placeholder={''}
                icon={<SaveIcon />}
                label="Save"
                onClick={handleSaveClick(params.id)}
              />
            );
          }
          actionButtons.push(
            <GridActionsCellItem
              onPointerEnterCapture={false}
              onPointerLeaveCapture={false}
              placeholder={''}
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(params.id)}
              color="inherit"
            />
          );
        } else {
          if (hasPermission(Permission.canEditAirline)) {
            actionButtons.push(
              <GridActionsCellItem
                onPointerEnterCapture={false}
                onPointerLeaveCapture={false}
                placeholder={''}
                icon={<EditIcon />}
                label="Edit"
                className="textPrimary"
                onClick={handleEditClick(params.id)}
                color="inherit"
              />
            );
          }
          if (hasPermission(Permission.canDeleteAirline)) {
            actionButtons.push(
              <GridActionsCellItem
                onPointerEnterCapture={false}
                onPointerLeaveCapture={false}
                placeholder={''}
                icon={<DeleteIcon />}
                label="Delete"
                onClick={handleDeleteClick(params.id)}
                color="inherit"
              />
            );
          }
        }
        return actionButtons;
      },
    },
  ];

  interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (
      newModel: (oldModel: GridRowModesModel) => GridRowModesModel
    ) => void;
  }

  function EditToolbar(props: EditToolbarProps) {
    const handleClick = () => {
      var existingRow = gridData.rows.find((x: any) => x.id === uniqueRowId);
      if (existingRow === undefined) {
        const id = uniqueRowId;
        var temp: any = [];
        for (let i = 0; i < gridData.rows.length; i++) {
          temp.push(gridData.rows[i]);
        }
        temp.push({
          id: uniqueRowId,
          restriction: '',
          applicableTo: '',
          isNew: true,
        });
        setGridData({ ...gridData, rows: temp, totalRows: temp.length });
        setRowModesModel((oldModel) => ({
          ...oldModel,
          [id]: {
            mode: GridRowModes.Edit,
            fieldToFocus: 'restriction',
          },
        }));
        uniqueRowId += 1;
      }
    };

    return (
      <GridToolbarContainer>
        {hasPermission(Permission.canAddAirline) ? (
          <Button
            color="primary"
            id="addRecord"
            startIcon={<AddIcon />}
            onClick={handleClick}
          >
            {I18n('Common.Add')}
          </Button>
        ) : (
          <></>
        )}
      </GridToolbarContainer>
    );
  }

  function handleProcessRowUpdate(newRow: GridRowModel) {
    let updatedRow: any = {};
    if (newRow.id === undefined) {
      updatedRow = { ...newRow, isNew: false, id: uniqueRowId };
    } else {
      updatedRow = { ...newRow, isNew: false };
    }
    let validateMessage = validateRows(updatedRow);
    if (validateMessage === '') {
      updatedRow.applicableToDescription = updatedRow.applicableTo.label;

      if (newRow.id === undefined) {
        var temp: any = [];
        for (var i = 0; i < gridData.rows.length; i++) {
          temp.push(gridData.rows[i]);
        }
        temp.push(updatedRow);

        updateGridData('rows', temp);
        updateGridData('totalRows', temp.length);
        return updatedRow;
      } else {
        updateGridData(
          'rows',
          gridData.rows.map((row: any) =>
            row.id === newRow.id ? updatedRow : row
          )
        );
        return updatedRow;
      }
    } else {
      if (updatedRow.id === uniqueRowId) {
        var temp1: any = [];
        for (let i = 0; i < gridData.rows.length; i++) {
          temp1.push(gridData.rows[i]);
        }

        var existingRecord = {
          ...updatedRow,
          isNew: true,
        };
        updatedRow.isNew = true;
        updateGridData('rows', temp1);
        updateGridData('totalRows', temp1.length);
        setTimeout(() => {
          var oldData: any = [];
          for (var i = 0; i < temp1.length; i++) {
            if (temp1[i].id == uniqueRowId) {
              oldData.push(existingRecord);
            } else {
              oldData.push(temp1[i]);
            }
          }
          updateGridData('rows', oldData);

          setTimeout(() => {
            setRowModesModel({
              ...rowModesModel,
              uniqueRowId: {
                mode: GridRowModes.Edit,
                fieldToFocus: 'restriction',
              },
            });
          }, 200);
        }, 200);
      } else {
        setTimeout(() => {
          setRowModesModel({
            ...rowModesModel,
            [updatedRow.id]: {
              mode: GridRowModes.Edit,
              fieldToFocus: 'restriction',
            },
          });
        }, 500);
      }
      toast.error(validateMessage);
      return updatedRow;
    }
  }

  const handleProcessRowUpdateError = useCallback((error: Error) => {
    //toast.error(error.message);
  }, []);

  const setModels = (newModel: any) => {
    // to stop set Row Models from  type dropdown Change
    var result = false;
    Object.keys(newModel).forEach(function (key) {
      var value = newModel[key];
      if (
        (value.field === 'restriction' || value.field === 'applicableTo') &&
        value.cellToFocusAfter === undefined
      ) {
        result = true;
      }
    });
    if (result) {
      return;
    }

    if (newModel[0] === undefined) {
      setRowModesModel(newModel);
    } else if (
      newModel[0] !== undefined &&
      newModel[0].cellToFocusAfter === 'right'
    ) {
      setRowModesModel(newModel);
    }
  };

  const resetAddEvent = () => {
    document.removeEventListener('keydown', keydown);
  };

  useEffect(() => {
    const element = document.getElementById('keydown');
    element?.addEventListener('keydown', keydown, true);
    if (data) {
      setData(data);
      if (data.airlineRestriction) {
        for (let i = 0; i < data.airlineRestriction.length; i++) {
          const element = data.airlineRestriction[i];
          element.applicableTo = {
            value: element.applicableTo,
            label: typeList.find((x) => x.value == element.applicableTo)?.label,
          };
        }
        updateGridData('rows', data.airlineRestriction);
        updateGridData(
          'totalRows',
          data && data.airlineRestriction ? data.airlineRestriction.length : 0
        );
      } else {
        updateGridData('rows', []);
        updateGridData('totalRows', 0);
      }
    }
  }, [data]);

  return (
    <div onKeyDown={keydown} id="keydown">
      <Dialog
        open={isOpen}
        onClose={handleCloseClick}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={'lg'}
        fullWidth={true}
      >
        <DialogTitle>
          {title}
          <IconButton
            aria-label="close"
            onClick={handleCloseClick}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className="grid-wrap two-col">
            <Grid item xs={12}>
              <CustomizedInputs
                margin="normal"
                displayLabel={i18nMessages.Name}
                name="name"
                id="name"
                inputType="text"
                value={editdata?.name}
                readOnly={false}
                required
                errorValue={errors.name}
                errorMessage={errors.name}
                multiline={false}
                handleChange={onValueChange}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomDataGridInline
                rowCount={gridData.totalRows}
                data={gridData.rows}
                columns={columns}
                onSortModelChange={handleSortModelChange}
                pageNo={gridData.page}
                pageSize={gridData.pageSize}
                rowModesModel={rowModesModel}
                onRowModesModelChange={(newModel: any) => {
                  setModels(newModel);
                }}
                onPageChange={handleChangePage}
                onPageSizeChange={handleChangePageSize}
                onRowEditStart={handleRowEditStart}
                //onRowEditStop={handleRowEditStop}
                rowsPerPageOptions={gridData.rowsPerPageOptions}
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'updatedDate', sort: 'desc' }],
                  } as GridSortingInitialState,
                  pagination: {
                    page: gridData.page,
                    pageSize: gridData.pageSize,
                  } as GridPaginationInitialState,
                }}
                components={{
                  Toolbar: EditToolbar,
                }}
                componentsProps={{
                  toolbar: { setRows, setRowModesModel },
                }}
                processRowUpdate={handleProcessRowUpdate}
                onProcessRowUpdateError={handleProcessRowUpdateError}
              ></CustomDataGridInline>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="btn-wrap">
          <Button
            color="neutral"
            disableElevation
            variant="contained"
            onClick={handleCloseClick}
          >
            {' '}
            {I18n('Common.Cancel')}
          </Button>
          <Button
            onClick={submit}
            autoFocus
            color="primary"
            disableElevation
            variant="contained"
          >
            {I18n('Common.Save')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
