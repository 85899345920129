import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import I18n from 'utilities/i18n';
import { Grid } from '@mui/material';
import FormControl from 'common/formControl/formControl';
import CustomizedSelect from 'common/CustomSelect/CustomSelect';
import {
  BaseModel,
  EntityStatus,
  EntityStatusDescription,
} from 'models/pagination-model';
import environment from 'environment';
import http from '../../../utilities/httpService';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';

export default function TariffCustomDailog(props: any) {
  const { isOpen, handleClose, title, newCode, apiRef, triggerFrom, gridId } =
    props;
  const defaultValue = {
    value: '0',
    label: I18n('Placeholders.Select'),
  } as BaseModel;
  const defaultValueForStatus = {
    value: EntityStatus.Active.toString(),
    label: I18n('Common.Active'),
  } as BaseModel;
  const [errors, setErrors] = useState({
    cnKey: '',
    cn: '',
    cnLevel: '',
    description: '',
  });
  const [values, setValues] = useState({
    cnKey: '',
    cn: '',
    cnLevel: '',
    t_SU_SU: '',
    description: '',
    parentCNName: '',
    status: defaultValueForStatus,
  });

  let entityStatusList: Array<BaseModel> = [];
  const getStatusList = () => {
    EntityStatusDescription.forEach((value: string, key: number) => {
      entityStatusList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getStatusList();

  const validate = (fieldValues: any = values) => {
    let temp = { ...errors };

    temp.cn = fieldValues.cn ? '' : 'This field is required.';
    temp.description = fieldValues.description ? '' : 'This field is required.';

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === '');
    }
  };

  const resetForm = () => {
    setValues({
      cnKey: '',
      cn: '',
      cnLevel: '',
      t_SU_SU: '',
      description: '',
      parentCNName: '',
      status: defaultValueForStatus,
    });
  };

  const handleCloseHsn = () => {
    resetForm();
    handleClose();
  };

  const handleSubmit = async () => {
    if (validate()) {
      const apiUrl = new URL(environment.api.baseUrl + 'TariffCustoms');

      try {
        var tariffInput = {
          cnKey: values.cn,
          description: values.description,
          status: values.status.value,
        };
        const result: any = await http.post(apiUrl.toString(), tariffInput);

        if (result !== null && result.data !== null && result.status == 200) {
          toast.success('Saved Successfully');
          resetForm();
          props.OnSave(result, apiRef, gridId, triggerFrom);
        } else {
          toast.error(I18n('Common.ErrorOccurred'));
        }
      } catch (error: any) {}
    }
  };

  useEffect(() => {
    if (newCode && newCode !== undefined) {
      setValues({
        ...values,
        cn: newCode
          .substring(0, 10)
          .replace(/[^0-9]/g, '')
          .replace(
            /(\d{4})(\d{4})(\d{0,2})/,
            (_: any, p1: any, p2: any, p3: any) => {
              let formatted = p1;
              if (p2) {
                formatted += `.${p2}`;
              }
              if (p3) {
                formatted += `.${p3}`;
              }
              return formatted;
            }
          ),
      });
    }
  }, [newCode]);

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleCloseHsn}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={'lg'}
        fullWidth={true}
      >
        <DialogTitle>
          {title}
          <IconButton
            aria-label="close"
            onClick={handleCloseHsn}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className="grid-wrap two-col">
            <Grid item lg={4} md={6}>
              <FormControl
                margin="normal"
                displayLabel={I18n('AddTariff.cn')}
                id="cn"
                inputType="text"
                inputProps={{ maxLength: 12 }}
                errorValue={errors.cn}
                errorMessage={errors.cn}
                handleChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ) =>
                  setValues({
                    ...values,
                    cn: event.target.value
                      .replace(/[^0-9]/g, '')
                      .replace(/(\d{4})(\d{4})(\d{0,2})/, (_, p1, p2, p3) => {
                        let formatted = p1;
                        if (p2) {
                          formatted += `.${p2}`;
                        }
                        if (p3) {
                          formatted += `.${p3}`;
                        }
                        return formatted;
                      }),
                  })
                }
                value={values.cn}
                multiline={false}
                required={true}
              ></FormControl>
            </Grid>
            <Grid item lg={6}>
              <FormControl
                margin="normal"
                displayLabel={I18n('AddTariff.description')}
                id="description"
                inputType="text"
                inputProps={{ maxLength: 50 }}
                errorValue={errors.description}
                errorMessage={errors.description}
                handleChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ) => setValues({ ...values, description: event.target.value })}
                value={values.description}
                multiline={false}
                required={true}
              ></FormControl>
            </Grid>
            <Grid item lg={6}>
              <CustomizedSelect
                placeholder={defaultValue.label}
                options={entityStatusList}
                isSearchable={false}
                displayLabel={I18n('AddTariff.status')}
                handleChange={(newValue: BaseModel) =>
                  setValues({ ...values, status: newValue })
                }
                value={values.status}
                required={true}
              ></CustomizedSelect>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="btn-wrap">
          <Button
            onClick={handleCloseHsn}
            color="neutral"
            disableElevation
            variant="contained"
          >
            {' '}
            {I18n('Common.Cancel')}
          </Button>
          <Button
            onClick={handleSubmit}
            autoFocus
            color="primary"
            disableElevation
            variant="contained"
          >
            {I18n('Common.Save')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
