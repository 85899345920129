export interface SettingsKeyValue {
  id: number;
  key: string;
  value: string;
}

export enum SettingsType {
  CustomerTypes,
  InvoicingCycles,
  CurrencyMaster,
}

export enum CustomerTypes {
  Belgium = 'BLCU',
  European = 'ERCU',
  Export = 'EXCU',
}
