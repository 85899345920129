import { DataGrid, GridSortModel } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';

export default function CustomDataGridInline(props: any) {
  const {
    pageNo,
    pageSize,
    data,
    rowCount,
    onPageChange,
    columns,
    onSortModelChange,
    onPageSizeChange,
    loading,
    initialState,
    rowsPerPageOptions,
    onRowClick,
    checkboxSelection,
    selectedValues,
    selectionModel,
    paginationMode = 'server',
    sortingMode = 'server',
    columnVisibilityModel,
    handleColumnVisibilityModelChange,
    hideFooter,
    processRowUpdate,
    rowModesModel,
    onRowModesModelChange,
    onRowEditStart,
    onRowEditStop,
    components,
    componentsProps,
    onProcessRowUpdateError,
  } = props;

  const [rowCountState, setRowCountState] = useState(rowCount || 0);

  useEffect(() => {
    setRowCountState((prevRowCountState: number) =>
      rowCount !== undefined ? rowCount : prevRowCountState
    );
  }, [rowCount, setRowCountState]);

  return (
    <div>
      <DataGrid
        autoHeight
        checkboxSelection={checkboxSelection}
        selectionModel={selectionModel}
        onSelectionModelChange={(ids: any) => {
          if (checkboxSelection) {
            const selectedIDs = new Set(ids);
            const selectedRowData = data.filter((row: any) =>
              selectedIDs.has(row.id)
            );
            selectedValues(selectedRowData);
          }
        }}
        editMode="row"
        rows={data}
        columns={columns}
        rowCount={rowCountState}
        loading={loading}
        rowsPerPageOptions={rowsPerPageOptions}
        pagination
        page={pageNo}
        pageSize={pageSize}
        paginationMode={paginationMode}
        sortingMode={sortingMode}
        onPageSizeChange={(newPageSize: any) => onPageSizeChange(newPageSize)}
        onPageChange={(newPage: any) => onPageChange(newPage)}
        onSortModelChange={(sortModel: GridSortModel) =>
          onSortModelChange(sortModel)
        }
        initialState={initialState}
        onRowClick={onRowClick}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
        hideFooter={hideFooter}
        rowModesModel={rowModesModel}
        onRowModesModelChange={onRowModesModelChange}
        onRowEditStart={onRowEditStart}
        onRowEditStop={onRowEditStop}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={onProcessRowUpdateError}
        components={components}
        componentsProps={componentsProps}
        experimentalFeatures={{ newEditingApi: true }}
      />
    </div>
  );
}
