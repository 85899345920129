import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import I18n from 'utilities/i18n';
import { useEffect, MouseEvent, useCallback, useState } from 'react';
import http from '../../utilities/httpService';
import {
  BaseModel,
  EntityStatus,
  EntityStatusDescription,
  MasterCategoryEnumType,
  OrderByDirection,
  PaginationResponseModel,
  ProductCatalogueType,
  ProductCatalogueTypeDescription,
} from 'models/pagination-model';
import {
  Button,
  Fade,
  Grid,
  IconButton,
  Paper,
  Popper,
  PopperPlacementType,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faPlus,
  faFilter,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import DeleteIcon from '@mui/icons-material/Delete';
import { Customer } from 'models/service-catalogue';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from 'common/formControl/formControl';
import CustomDataGrid from 'common/datagrid/datagrid';
import {
  GridActionsCellItem,
  GridColumns,
  GridEventListener,
  GridPaginationInitialState,
  GridRowParams,
  GridSortingInitialState,
  GridSortModel,
} from '@mui/x-data-grid';
import environment from 'environment';
import CustomizedSelect from 'common/CustomSelect/CustomSelect';
import DecimalInput from 'common/DecimalInput/DecimalInput';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
import RouteEnum from 'models/RouteEnum';
import ConfirmationPopup from 'common/confirmationPopup/confirmationPopup';
import { toast } from 'react-toastify';
import { Permission } from 'Permissions';
import { hasPermission } from 'utilities/protectedRoute';
import CustomSelectPaginate from 'common/CustomSelect/CustomSelectPaginate';
import { ProductCatalogues } from 'models/product-catalogue';

function ProductCatalogueList() {
  let navigate = useNavigate();
  const breadcrumbs = [
    <Typography key="3" color="text.primary">
      {I18n('Nav.Configuration')}
    </Typography>,
    <Typography key="3" color="text.primary">
      {I18n('Nav.Products')}
    </Typography>,
    <Typography key="3" color="text.primary">
      {I18n('Nav.ProductCatalogue')}
    </Typography>,
  ];
  const [gridData, setGridData] = useState({
    isLoading: true,
    sortOrder: OrderByDirection.Descending,
    sortBy: 'name',
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [10, 20, 50, 100],
    pageSize: 10,
    page: 0,
    searchExpression: '',
  });
  const defaultValue = {
    value: '0',
    label: I18n('Placeholders.SelectStatus'),
  } as BaseModel;
  const defaultCategoryValue = {
    value: '0',
    label: I18n('Placeholders.SelectCategory'),
  } as BaseModel;
  const defualtType = {
    value: '0',
    label: I18n('Placeholders.selectProductType'),
  } as BaseModel;

  const [productCodeFilter, setProductCodeFilter] = useState('');
  const [categoryFilter, setCategoryFilter] = useState<BaseModel>();
  const [availableinWHFilter, setAvailableinWHFilter] = useState('');
  const [whQuantityAdvanceFilter, setWhQuantityAdvanceFilter] = useState('');
  const [productNameFilter, setProductNameFilter] = useState('');
  const [shippingStandardPriceFilter, setShippingStandardPriceFilter] =
    useState('');
  const [decPackStandardPriceFilter, setDecPackStandardPriceFilter] =
    useState('');
  const [statusFilter, setStatusFilter] = useState(defaultValue);
  const [typeFilter, setTypeFilter] = useState<BaseModel>(defualtType);
  const [advanceSearchExpression, setAdvanceSearchExpression] = useState('');
  const [basicSearchExpression, setBasicSearchExpression] = useState('');
  const [isFilterEnabled, setIsFilterEnabled] = useState(false);
  const [selectedUserID, setUserId] = useState();
  const [isDeleteModalOpen, setDeleteModal] = useState(false);

  const i18nMessages = {
    deleteProductCatalougeSuccess: I18n('ProductCatalogues.DeleteSuccess'),
    deleteProductCatalougeCofirmation: I18n(
      'ProductCatalogues.DeleteConfirmation'
    ),
    deleteValidation: I18n('ProductCatalogues.DeleteValidation'),
  };

  const deleteProductData = useCallback(
    (id: any) => () => {
      setDeleteModal(true);
      setUserId(id);
    },
    []
  );

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  const handleOkDeleteModal = () => {
    setDeleteModal(false);
    deleteProductCatalouge(selectedUserID);
  };

  const deleteProductCatalouge = async (id: any) => {
    updateGridData('isLoading', true);
    const apiUrl = new URL(environment.api.baseUrl + 'ProductCatalogues/' + id);
    try {
      const result: any = await http.delete(apiUrl.toString());
      if (result) {
        toast.success(i18nMessages.deleteProductCatalougeSuccess);
        updateGridData('isLoading', false);
        getProductCatalogues();
      }
    } catch (error: any) {
      if (error.response) {
        if (error.response.data) {
          for (const item of error.response.data.errors) {
            if (
              item.field === 'Id' &&
              item.errorMessage === 'IsDeletableProductCatalogue'
            ) {
              toast.error(i18nMessages.deleteValidation);
              updateGridData('isLoading', false);
            }
          }
        }
      }
    }
  };

  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));

  const getProductCatalogues = async () => {
    updateGridData('isLoading', true);
    const apiUrl = new URL(environment.api.baseUrl + 'ProductCatalogues');
    try {
      let finalSearchTerm = '';
      if (basicSearchExpression && advanceSearchExpression) {
        finalSearchTerm = `${basicSearchExpression} or ${advanceSearchExpression}`;
      } else if (basicSearchExpression && !advanceSearchExpression) {
        finalSearchTerm = basicSearchExpression;
      } else if (!basicSearchExpression && advanceSearchExpression) {
        finalSearchTerm = advanceSearchExpression;
      }
      apiUrl.searchParams.set('$filter', finalSearchTerm);
      apiUrl.searchParams.set('$orderby', gridData.sortBy);
      apiUrl.searchParams.set(
        '$skip',
        (gridData.page * gridData.pageSize).toString()
      );
      apiUrl.searchParams.set('$top', gridData.pageSize.toString());
      apiUrl.searchParams.set(
        '$orderbydirection',
        gridData.sortOrder.toString()
      );
      apiUrl.searchParams.set(
        '$quantitySearch',
        whQuantityAdvanceFilter && whQuantityAdvanceFilter !== ''
          ? whQuantityAdvanceFilter
          : ''
      );
      const result = await http.get(apiUrl.toString());
      if (result) {
        updateGridData('totalRows', result.data.totalCount);
        updateGridData('rows', result.data.values);
        updateGridData('isLoading', false);
      }
    } catch (error) {}
  };

  const manageFilterStatus = () => {
    setIsFilterEnabled(
      productCodeFilter ||
        productNameFilter ||
        categoryFilter ||
        availableinWHFilter ||
        +shippingStandardPriceFilter ||
        +decPackStandardPriceFilter ||
        +statusFilter.value !== 0 ||
        +typeFilter.value !== 0
        ? true
        : false
    );
  };

  useEffect(() => {
    getProductCatalogues();
    manageFilterStatus();
  }, [
    gridData.page,
    gridData.pageSize,
    gridData.sortBy,
    gridData.sortOrder,
    gridData.searchExpression,
    advanceSearchExpression,
    basicSearchExpression,
    whQuantityAdvanceFilter,
  ]);

  const handleChangePage = (pageNo: number) => {
    updateGridData('page', pageNo);
  };

  const handleChangeBasicSearch = debounce(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (event.target.value) {
        setBasicSearchExpression(
          `productCode~like~${event.target.value} or name~like~${event.target.value} or MasterCategory.Name~like~${event.target.value}`
        );
      } else {
        setBasicSearchExpression(``);
      }
    },
    500
  );
  const handleChangePageSize = (pageSize: number) => {
    updateGridData('pageSize', pageSize);
  };
  type Row = (typeof gridData.rows)[number];

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState<PopperPlacementType>();
  const columns: GridColumns<Row> = [
    {
      field: 'productCode',
      headerName: I18n('ProductCatalogues.ProductCode'),
      flex: 1,
    },
    {
      field: 'productCatalogueCategoryName',
      headerName: I18n('ProductCatalogues.Category'),
      minWidth: 200,
    },
    {
      field: 'name',
      headerName: I18n('ProductCatalogues.ProductName'),
      flex: 1,
    },
    {
      field: 'dimensions',
      headerName: I18n('ProductCatalogues.Dimentions'),
      renderCell: (params: any) => {
        let data = params.row;
        return (
          data.dimension1 + ' X ' + data.dimension2 + ' X ' + data.dimension3
        );
      },
      sortable: false,
    },
    {
      field: 'shippingStandardPrice',
      headerName: I18n('ProductCatalogues.ShippingStandardPrice'),
      flex: 1,
    },
    {
      field: 'decPackStandardPrice',
      headerName: I18n('ProductCatalogues.DecPackStandardPrice'),
      flex: 1,
    },
    {
      field: 'productCatalogueType',
      headerName: I18n('ProductCatalogues.Type'),
      flex: 1,
      renderCell: (params: any) => {
        return params.formattedValue === ProductCatalogueType.SingleUse
          ? I18n('Common.SingleUse')
          : I18n('Common.Reusable');
      },
    },
    {
      field: 'warehouseQuantity',
      headerName: I18n('ProductCatalogues.AvailableinWH'),
      flex: 1,
      sortable: false,
    },

    {
      field: 'status',
      headerName: I18n('Common.Status'),
      flex: 1,
      renderCell: (params: any) => {
        return params.formattedValue === EntityStatus.Active
          ? I18n('Common.Active')
          : I18n('Common.Inactive');
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: I18n('Common.Actions'),
      width: 80,
      getActions: (params: GridRowParams<ProductCatalogues>) => {
        const actionButtons = [];
        actionButtons.push(
          <>
            {hasPermission(Permission.canDeleteProductCatalogueManagement) ? (
              <GridActionsCellItem
                onPointerEnterCapture={false}
                onPointerLeaveCapture={false}
                placeholder={''}
                icon={<DeleteIcon />}
                disabled={
                  params.row.status === EntityStatus.Active ||
                  params.row.isDryIce
                }
                label="Delete"
                onClick={deleteProductData(params.id)}
              />
            ) : (
              <></>
            )}
          </>
        );
        return actionButtons;
      },
    },
  ];

  let entityStatusList: Array<BaseModel> = [];
  const getStatusList = () => {
    entityStatusList = [defaultValue];
    EntityStatusDescription.forEach((value: string, key: number) => {
      entityStatusList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getStatusList();

  let ProductCatalogueList: Array<BaseModel> = [];
  const getProductCatalogueType = () => {
    ProductCatalogueList = [];
    ProductCatalogueTypeDescription.forEach((value: string, key: number) => {
      ProductCatalogueList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getProductCatalogueType();
  const popperHandleClick =
    (newPlacement: PopperPlacementType) =>
    (event: MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setOpen((prev) => placement !== newPlacement || !prev);
      setPlacement(newPlacement);
    };

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    if (sortModel && sortModel.length) {
      if (sortModel[0].field === 'productCatalogueCategoryName') {
        updateGridData('sortBy', 'MasterCategory.Name');
      } else {
        updateGridData('sortBy', sortModel[0].field);
      }
      updateGridData(
        'sortOrder',
        sortModel[0].sort === 'asc'
          ? OrderByDirection.Ascending
          : OrderByDirection.Descending
      );
    }
  }, []);

  const handleOnRowClick: GridEventListener<'rowClick'> = (
    params: { id: any }, // GridRowParams
    event: any, // MuiEvent<React.MouseEvent<HTMLElement>>
    details: any // GridCallbackDetails
  ) => {
    navigate(`${RouteEnum.ViewProductCatalogue}/${params.id}`);
  };

  const handleResetClick = () => {
    setProductCodeFilter('');
    setAvailableinWHFilter('');
    setProductNameFilter('');
    setShippingStandardPriceFilter('');
    setDecPackStandardPriceFilter('');
    setCategoryFilter(defaultCategoryValue);
    setStatusFilter(defaultValue);
    setTypeFilter(defualtType);
    setAdvanceSearchExpression('');
    setWhQuantityAdvanceFilter('');
    setOpen(false);
  };
  const appendAdvanceSearchExpressions = (
    searchExpression: string,
    type: string
  ) => {
    let searchExpressionLocal: string = searchExpression;
    switch (type) {
      case 'and':
        searchExpressionLocal = searchExpressionLocal ? ` and ` : ``;
        break;
      case 'or':
        searchExpressionLocal = searchExpressionLocal ? ` or ` : ``;
        break;
      default:
        break;
    }
    return searchExpressionLocal;
  };
  const handleAdvanceSearchFilterClick = () => {
    let searchExpression = '';
    searchExpression += productCodeFilter
      ? `ProductCode~like~${productCodeFilter}`
      : '';
    searchExpression += productNameFilter
      ? appendAdvanceSearchExpressions(searchExpression, 'and') +
        `name~like~${productNameFilter}`
      : '';
    searchExpression +=
      categoryFilter && +categoryFilter.value
        ? appendAdvanceSearchExpressions(searchExpression, 'and') +
          `productCatalogueCategoryId~=~${categoryFilter.value}`
        : '';
    searchExpression += shippingStandardPriceFilter
      ? appendAdvanceSearchExpressions(searchExpression, 'and') +
        `shippingStandardPrice~=~${shippingStandardPriceFilter}`
      : '';
    searchExpression += decPackStandardPriceFilter
      ? appendAdvanceSearchExpressions(searchExpression, 'and') +
        `decPackStandardPrice~=~${decPackStandardPriceFilter}`
      : '';
    searchExpression += +statusFilter.value
      ? appendAdvanceSearchExpressions(searchExpression, 'and') +
        `status~=~${statusFilter.value}`
      : '';
    searchExpression += +typeFilter.value
      ? appendAdvanceSearchExpressions(searchExpression, 'and') +
        `productCatalogueType~=~${typeFilter.value}`
      : '';
    setWhQuantityAdvanceFilter(availableinWHFilter ? availableinWHFilter : '');
    setAdvanceSearchExpression(searchExpression);
    setOpen(false);
  };

  const addProductCatalougeClicked = () => {
    navigate(RouteEnum.ProductCatalogueAdd);
  };

  const getOrderCategories = async (search: string, pageNo: number) => {
    let response: PaginationResponseModel<Customer>;
    const apiUrl = new URL(
      environment.api.baseUrl + 'Dropdown/GetMasterCategories'
    );
    try {
      let searchExpression = `entityType~=~${MasterCategoryEnumType.ProductCatalogue}`;
      searchExpression += search ? ` and name~like~${search}` : '';
      apiUrl.searchParams.set('$filter', searchExpression);
      apiUrl.searchParams.set('$orderby', 'name');
      apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
      apiUrl.searchParams.set('$top', '10');
      apiUrl.searchParams.set('$orderbydirection', 'desc');
      const result = await http.get(apiUrl.toString());
      if (result) {
        response = {
          TotalRecords: result.data.totalCount,
          Values: result.data.values,
        } as PaginationResponseModel<Customer>;
      } else {
        response = {
          TotalRecords: 0,
          Values: [],
        } as PaginationResponseModel<Customer>;
      }
    } catch (error) {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<Customer>;
    }
    return response;
  };

  const loadCategoryOptions = async (
    search: string,
    loadedOptions: any,
    { page }: any
  ) => {
    const response = await getOrderCategories(search, page);
    const hasMore = Math.ceil(response.TotalRecords / 10) > page;

    return {
      options: response.Values.map((x) => {
        return {
          value: x.id.toString(),
          label: x.name,
        } as BaseModel;
      }),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };
  const defaultAdditional: any = {
    page: 1,
  };
  return (
    <div>
      <div className="heading-section">
        <div className="heading-section-left">
          <Typography variant="h3" sx={{ mb: 3 }}>
            {I18n('Nav.ProductCatalogue')}
          </Typography>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>
        <div className="heading-section-right">
          <div className="search-control-outer">
            <FormControl
              margin="normal"
              displayLabel=""
              required
              inputType="text"
              placeholderText="Search"
              className="search-control small-form-control"
              handleChange={handleChangeBasicSearch}
              adornmentValue={
                <InputAdornment position="start">
                  <FontAwesomeIcon icon={faSearch} />
                </InputAdornment>
              }
            ></FormControl>
          </div>
          <div className="header-btn-wrap">
            <Popper
              open={open}
              anchorEl={anchorEl}
              placement={placement}
              transition
              className="filter-popper"
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper>
                    <Typography variant="h5" className="filter-heading">
                      Filter
                      <IconButton
                        aria-label="delete"
                        onClick={popperHandleClick('bottom-end')}
                      >
                        <FontAwesomeIcon
                          icon={faTimes}
                          className="fa-sm close-icon"
                        />
                      </IconButton>
                    </Typography>
                    <div className="filter-content form-small-gap">
                      <Grid container spacing={2} className="grid-wrap two-col">
                        <Grid item xs={6}>
                          <FormControl
                            margin="normal"
                            displayLabel={I18n('ProductCatalogues.ProductName')}
                            id="ProductName"
                            inputType="text"
                            inputProps={{ maxLength: 30 }}
                            handleChange={(
                              event: React.ChangeEvent<
                                HTMLTextAreaElement | HTMLInputElement
                              >
                            ) => setProductNameFilter(event.target.value)}
                            value={productNameFilter}
                            multiline={false}
                          ></FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl
                            margin="normal"
                            displayLabel={I18n('ProductCatalogues.ProductCode')}
                            id="ProductCode"
                            inputType="text"
                            inputProps={{ maxLength: 30 }}
                            multiline={false}
                            handleChange={(
                              event: React.ChangeEvent<
                                HTMLTextAreaElement | HTMLInputElement
                              >
                            ) => setProductCodeFilter(event.target.value)}
                            value={productCodeFilter}
                          ></FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <CustomSelectPaginate
                            additional={defaultAdditional}
                            value={categoryFilter}
                            loadOptions={loadCategoryOptions}
                            handleChange={(newValue: BaseModel) =>
                              setCategoryFilter(newValue)
                            }
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base: any) => ({
                                ...base,
                                zIndex: 9999,
                              }),
                            }}
                            placeholder={defaultCategoryValue.label}
                            isSearchable={true}
                            displayLabel={I18n('ServiceCatalogues.Category')}
                            readOnly={false}
                            debounceTimeout={500}
                            isMultiSelect={false}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <CustomizedSelect
                            placeholder={defaultValue.label}
                            options={entityStatusList}
                            isSearchable={false}
                            displayLabel={I18n('Common.Status')}
                            handleChange={(newValue: BaseModel) =>
                              setStatusFilter(newValue)
                            }
                            value={statusFilter}
                          ></CustomizedSelect>
                        </Grid>
                        <Grid item xs={6}>
                          <DecimalInput
                            displayLabel={I18n(
                              'ProductCatalogues.ShippingStandardPrice'
                            )}
                            inputProps={{ maxLength: 10 }}
                            handleChange={(event: any) => {
                              setShippingStandardPriceFilter(
                                event.target.value
                              );
                            }}
                            value={shippingStandardPriceFilter}
                          ></DecimalInput>
                        </Grid>
                        <Grid item xs={6}>
                          <DecimalInput
                            displayLabel={I18n(
                              'ProductCatalogues.DecPackStandardPrice'
                            )}
                            inputProps={{ maxLength: 10 }}
                            handleChange={(event: any) => {
                              setDecPackStandardPriceFilter(event.target.value);
                            }}
                            value={decPackStandardPriceFilter}
                          ></DecimalInput>
                        </Grid>
                        <Grid item xs={6}>
                          <CustomizedSelect
                            placeholder={defaultValue.label}
                            options={ProductCatalogueList}
                            isSearchable={false}
                            displayLabel={I18n(
                              'ProductCatalogues.ProductCatalogueType'
                            )}
                            handleChange={(newValue: BaseModel) =>
                              setTypeFilter(newValue)
                            }
                            value={typeFilter}
                          ></CustomizedSelect>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl
                            margin="normal"
                            displayLabel={I18n(
                              'ProductCatalogues.AvailableinWH'
                            )}
                            id="AvailableinWH"
                            inputType="text"
                            inputProps={{ maxLength: 30 }}
                            multiline={false}
                            handleChange={(
                              event: React.ChangeEvent<
                                HTMLTextAreaElement | HTMLInputElement
                              >
                            ) => setAvailableinWHFilter(event.target.value)}
                            value={availableinWHFilter}
                          ></FormControl>
                        </Grid>
                      </Grid>
                      <div className="btn-wrap">
                        <Button
                          color="neutral"
                          disableElevation
                          variant="contained"
                          onClick={handleResetClick}
                        >
                          {I18n('Common.Reset')}
                        </Button>
                        <Button
                          color="primary"
                          disableElevation
                          variant="contained"
                          onClick={handleAdvanceSearchFilterClick}
                        >
                          {I18n('Common.Apply')}
                        </Button>
                      </div>
                    </div>
                  </Paper>
                </Fade>
              )}
            </Popper>
            <Button
              color="info"
              onClick={popperHandleClick('bottom-end')}
              disableElevation
              variant="outlined"
              className={`icon-btn ${isFilterEnabled ? 'is-filtered' : ''}`}
            >
              <FontAwesomeIcon icon={faFilter} className="fa-lg" />
            </Button>

            {hasPermission(Permission.canAddProductCatalogueManagement) ? (
              <Button
                color="info"
                className="small-btn"
                disableElevation
                variant="contained"
                onClick={addProductCatalougeClicked}
              >
                <FontAwesomeIcon icon={faPlus} className="fa-md mr-2" />
                Add
              </Button>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div className="main-content-section">
        <div className="content-block table-fix  ">
          <CustomDataGrid
            rowCount={gridData.totalRows}
            data={gridData.rows}
            columns={columns}
            pageNo={gridData.page}
            pageSize={gridData.pageSize}
            onSortModelChange={handleSortModelChange}
            onPageChange={handleChangePage}
            onPageSizeChange={handleChangePageSize}
            rowsPerPageOptions={gridData.rowsPerPageOptions}
            initialState={{
              sorting: {
                sortModel: [{ field: 'serviceName', sort: 'desc' }],
              } as GridSortingInitialState,
              pagination: {
                page: gridData.page,
                pageSize: gridData.pageSize,
              } as GridPaginationInitialState,
            }}
            onRowClick={handleOnRowClick}
          ></CustomDataGrid>
          <ConfirmationPopup
            isOpen={isDeleteModalOpen}
            data={selectedUserID}
            message={i18nMessages.deleteProductCatalougeCofirmation}
            handleClose={handleCloseDeleteModal}
            handleOk={handleOkDeleteModal}
          ></ConfirmationPopup>
        </div>
      </div>
    </div>
  );
}

export default ProductCatalogueList;
