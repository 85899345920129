import { BaseModel } from 'models/pagination-model';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Grid, Tooltip } from '@mui/material';
import CustomizedSelect from 'common/CustomSelect/CustomSelect';
import CustomizedInputs from 'common/formControl/formControl';
import I18n from 'utilities/i18n';

export default function WO10SendEmailPopup(props: any) {
  const {
    isOpen,
    onClose,
    onSubmit,
    title,
    recipientEmail,
    infoSubTitle = '',
  } = props;
  const defaultLanguage: BaseModel = { value: '1', label: 'English' };
  const languageOptions: Array<BaseModel> = [
    { value: '2', label: 'Dutch' },
    { value: '3', label: 'French' },
  ];

  const i18nMessages = {
    fieldRequired: I18n('Notes.FieldRequired'),
    invalidEmail: I18n('Common.InvalidEmail'),
  };

  const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage);

  const [values, setValues] = useState({
    id: 0,
    name: '',
    email: recipientEmail,
    language: '1',
  });

  const [errors, setErrors] = useState({
    email: '',
  });

  const handleSelectLanguage = (value: BaseModel) => {
    if (value) {
      setSelectedLanguage(value);
      setValues({ ...values, language: value.value });
    }
  };

  const onValueChange = (event: any) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name.toString()]: value,
    });
  };

  const close = () => {
    onClose();
  };

  const submit = async () => {
    onSubmit(values);
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={'sm'}
        fullWidth={true}
      >
        <DialogTitle>
          {title}{' '}
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className="grid-wrap two-col">
            {infoSubTitle !== '' ? (
              <Grid item xs={12}>
                <label className="sendMailLabel">{infoSubTitle}</label>
              </Grid>
            ) : (
              <></>
            )}
            <Tooltip title={values.email}>
              <Grid item xs={12}>
                <CustomizedInputs
                  required
                  margin="normal"
                  displayLabel={I18n('SendEmail.Email')}
                  name="email"
                  id="email"
                  inputType="text"
                  value={recipientEmail}
                  multiline={false}
                  inputProps={{ maxLength: 100 }}
                  handleChange={onValueChange}
                  readOnly={true}
                />
              </Grid>
            </Tooltip>
            <Grid item xs={12}>
              <CustomizedSelect
                displayLabel={I18n('SendEmail.Language')}
                options={[defaultLanguage, ...languageOptions]}
                isSearchable={false}
                handleChange={handleSelectLanguage}
                defaultValue={selectedLanguage}
              ></CustomizedSelect>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="btn-wrap">
          <Button
            onClick={close}
            color="neutral"
            disableElevation
            variant="contained"
          >
            {' '}
            {I18n('SendEmail.Cancel')}
          </Button>
          <Button
            onClick={submit}
            autoFocus
            color="primary"
            disableElevation
            variant="contained"
          >
            {I18n('SendEmail.Send')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
