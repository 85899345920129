import I18n from 'utilities/i18n';
import { useEffect, useState } from 'react';
import http from '../../utilities/httpService';

import {
  BaseModel,
  DnPWorkOrderStatusDescription,
  EntityWorkOrderStatusDescription,
  OrderType,
  WorkOrderStatus,
} from 'models/pagination-model';
import { Grid } from '@mui/material';
import FormAccordianSave from 'common/formControl/formAccordianSave';
import environment from 'environment';

import { toast } from 'react-toastify';

import CustomizedSelect from 'common/CustomSelect/CustomSelect';
import { hasPermission } from 'utilities/protectedRoute';

function Status(props: any) {
  const {
    woId,
    orderId,
    statusValue,
    activityOwner,
    assignedToName,
    type,
    orderType,
    permission,
    handleSuccessStatusSave,
    handleErrorStatusSave,
    AssignedNextToId,
    AssignedNextToName,
    handleStatusChange,
  } = props;

  const defaultValue = {
    value: '0',
    label: I18n('Placeholders.SelectStatus'),
  } as BaseModel;

  const [canEdit, setCanEdit] = useState(hasPermission(permission));
  const [anyWOAlreadyInProgress, setAnyWOAlreadyInProgress] = useState(false);

  let entityWorkOrderStatusList: Array<BaseModel> = [];
  const getStatusList = () => {
    entityWorkOrderStatusList = [defaultValue];
    if (orderType === OrderType.ShippingOrder) {
      EntityWorkOrderStatusDescription.forEach((value: string, key: number) => {
        entityWorkOrderStatusList.push({
          value: key.toString(),
          label: I18n(value),
        } as BaseModel);
      });
    } else {
      DnPWorkOrderStatusDescription.forEach((value: string, key: number) => {
        entityWorkOrderStatusList.push({
          value: key.toString(),
          label: I18n(value),
        } as BaseModel);
      });
    }
  };
  getStatusList();

  useEffect(() => {
    if (orderType === OrderType.DecPackOrder) {
      AnyWOAlreadyInProgress();
    }
  }, []);

  useEffect(() => {
    if (props.statusValue) {
      const selectedStatus = entityWorkOrderStatusList.find(
        (x) => x.value == props.statusValue
      );
      setValues({
        status: selectedStatus ? selectedStatus : values.status,
      });
    }
  }, [props.statusValue]);

  useEffect(() => {
    if (props.isStatusSaveCallApi) {
      handleSaveClick();
    }
  }, [props.isStatusSaveCallApi]);

  useEffect(() => {
    if (props.validateStatus) {
      if (validate()) {
        if (props.woId === 1) {
          handleSaveClick();
        }
        handleSuccessStatusSave();
      } else {
        handleErrorStatusSave();
      }
    }
  }, [props.validateStatus]);

  const [errors, setErrors] = useState({
    status: '',
  });

  const [values, setValues] = useState<any>({
    status: 0,
  });

  const onStatusChange = (data: any) => {
    setValues({
      ...values,
      status: { value: Number(data?.value), label: data.label },
    });
    handleStatusChange(Number(data?.value));
  };

  const i18nMessages = {
    commonFieldIsRequired: I18n('Common.FieldIsRequired'),
    statusSavedSuccess: I18n('Booking.StatusSavedSuccess'),
    Status: I18n('Booking.Status'),
    ToDo: I18n('Common.ToDo'),
    InProgress: I18n('Common.InProgress'),
    WOAlreadyInProgress: I18n('Common.WOAlreadyInProgress'),
  };

  const validate = (fieldValues: any = values) => {
    let temp = { ...errors };
    if (fieldValues.status.value === 0) {
      fieldValues.status = 0;
    }
    if ('status' in fieldValues) {
      temp.status = fieldValues.status
        ? ''
        : i18nMessages.commonFieldIsRequired;
    }

    if (
      temp.status === '' &&
      fieldValues.status &&
      fieldValues.status.value == WorkOrderStatus.InProgress &&
      orderType === OrderType.DecPackOrder
    ) {
      temp.status = anyWOAlreadyInProgress
        ? i18nMessages.WOAlreadyInProgress
        : '';
    }

    setErrors({
      ...temp,
    });
    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === '');
    }
  };

  const AnyWOAlreadyInProgress = async () => {
    let isWOAlreadyInProgress = false;
    const apiUrl = new URL(
      environment.api.baseUrl + 'DecPackOrder/GetDecPackWO/' + orderId
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result) {
        isWOAlreadyInProgress = result.data.decPackWorkOrdersDto.some(
          (item: any) =>
            item.status == WorkOrderStatus.InProgress &&
            item.workOrderNo != woId
        );
      }
      setAnyWOAlreadyInProgress(isWOAlreadyInProgress);
    } catch (error) {
      isWOAlreadyInProgress = false;
    }
    return isWOAlreadyInProgress;
  };

  const handleSaveClick = async () => {
    let finalValue;
    let apiUrl;
    if (orderType === OrderType.ShippingOrder) {
      finalValue = {
        workOrderNo: Number(woId),
        status: Number(values.status.value),
        shippingOrderId: Number(orderId),
        woDate: new Date().toISOString(),
        activityOwner: activityOwner ? activityOwner : null,
        assignedToId: type?.toString() ? type?.toString() : '',
        assignedToName: assignedToName?.toString()
          ? assignedToName?.toString()
          : '',
        assignedNextToId: AssignedNextToId?.toString()
          ? AssignedNextToId?.toString()
          : '',
        assignedNextToName: AssignedNextToName?.toString()
          ? AssignedNextToName?.toString()
          : '',
      };

      apiUrl = new URL(environment.api.baseUrl + 'Shipping/UpdateWOStatus');
    } else {
      finalValue = {
        workOrderNo: Number(woId),
        status: Number(values.status.value),
        decPackInitialOrderId: Number(orderId),
        woDate: new Date().toISOString(),
        assignedToId: type?.toString() ? type?.toString() : '',
        assignedToName: assignedToName?.toString()
          ? assignedToName?.toString()
          : '',
      };

      if (activityOwner) {
        finalValue['activityOwner'] = activityOwner;
      }
      apiUrl = new URL(environment.api.baseUrl + 'DecPackOrder/UpdateWOStatus');
    }

    try {
      const result = await http.put(apiUrl.toString(), finalValue);
    } catch (error: any) {
      if (error.response) {
        if (error.response.data) {
          for (const item of error.response.data.errors) {
            console.log(item.errorMessage);
            if (item.errorMessage === 'An error occured: WOAlreadyInProgress') {
              toast.error(i18nMessages.WOAlreadyInProgress);
            }
          }
        }
      }
    }
  };

  const StatusInformation = () => {
    return (
      <>
        <Grid item lg={4} md={8}>
          <CustomizedSelect
            placeholder={defaultValue.label}
            options={entityWorkOrderStatusList}
            value={values.status}
            isSearchable={false}
            displayLabel={i18nMessages.Status}
            readOnly={!canEdit}
            handleChange={(newValue: BaseModel) => {
              onStatusChange(newValue);
              if (newValue.value !== '0') {
                setErrors({
                  ...errors,
                  status: '',
                });
              }
            }}
            errorValue={true}
            errorMessage={errors.status}
          ></CustomizedSelect>
        </Grid>
      </>
    );
  };

  return (
    <div>
      <FormAccordianSave
        title={'Status'}
        className="inner-heading"
        details={StatusInformation()}
        showActionButton={!canEdit}
      ></FormAccordianSave>
    </div>
  );
}

export default Status;
