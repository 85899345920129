import {
  Typography,
  Breadcrumbs,
  Link,
  Grid,
  Box,
  Button,
} from '@mui/material';
import RouteEnum from 'models/RouteEnum';
import React, { BaseSyntheticEvent, useEffect, useState } from 'react';
import I18n from 'utilities/i18n';
import { useNavigate, useParams } from 'react-router-dom';
import FormAccordian from 'common/formControl/formAccordian';
import environment from 'environment';
import http from '../../../utilities/httpService';
import {
  ActivityOwner,
  DocumentEntity,
  EntityTypeEnum,
  ImageUploadViewModel,
  OrderByDirection,
  OrderType,
  BaseModel,
} from 'models/pagination-model';
import RadioGroupInput from 'common/RadioGroup/RadioGroupInput';
import { toast } from 'react-toastify';
import FormControl from '@mui/material/FormControl';
import { hasPermission } from 'utilities/protectedRoute';
import Status from 'common/WoStatus/Status';
import { DnPWorkOrders } from 'models/deck-pack-orders';
import { Permission } from 'Permissions';
import ApiUrlConstants from 'constants/api.constants';
import ImageUploadPreview from 'common/ImageUploadPreview/ImageUploadPreview';
import {
  getDeckPackWorkOrderDetailsByWoNumberAsync,
  getWorkOrderImages,
  saveWorkOrderImages,
} from 'common/ImageUploadPreview/WorkOrderImagesApiService';
import FormAccordianSave from 'common/formControl/formAccordianSave';
import DecpackStatus from './DecpackStatus';
import CreatedByandUpdatedBy from 'common/CreatedByandUpdatedBy/CreatedByandUpdatedBy';

function PhotoCheck() {
  const { woId, id } = useParams();
  let navigate = useNavigate();
  const [photoCheck, setPhotoCheck] = useState(false);
  const [details, setDetails] = useState<any>({
    createdBy: '',
    createdDate: '',
    updatedBy: '',
    updatedDate: '',
  });
  const [assigneeData, setAssigneeData] = useState<any>({
    assignedToId: '',
    assignedToName: '',
    assignNextToId: '',
    assignNextToName: '',
  });
  const [isMobile, setIsMobile] = useState(false);

  const [status, setStatus] = useState('');
  const [imagesData, setImagesData] = useState<ImageUploadViewModel[]>([]);
  const [DGDDocument, setDGDDocument] = useState('');
  const [isStatusSaveCallApi, setIsStatusSaveCallApi] = useState(false); // To call Submit APi Status from Status Component
  const [validateStatus, setValidateStatus] = useState<boolean>(false); // validateStatus - To validate Status Dropdown from Status Component
  const [instruction, setInstruction] = useState();
  const [instruction2, setInstruction2] = useState();
  const [isSaveNext, setIsSaveNext] = useState<boolean>(true);
  const [DGDName, setDGDName] = useState();
  const [woExecutionId, setWoExecutionId] = useState<Number>();

  const [photoCheckExistingId, setPhotoExistingId] = useState(0);
  const [canEdit, setCanEdit] = useState(
    hasPermission(Permission.canEditDecPackOrders)
  );
  const i18nMessages = {
    title: I18n('DecPackOrders.WorkOrder.PhotoCheck'),
    Photos: I18n('DecPackOrders.PhotoCheck.Photos'),
    DGD: I18n('DecPackOrders.PhotoCheck.DGD'),
    SpecialInstruction: I18n('DecPackOrders.PhotoCheck.SpecialInstruction'),
    PhotoCheck: I18n('DecPackOrders.PhotoCheck.PhotoCheck'),
    savedSuccessfully: I18n('ContactPerson.SavedSuccessfully'),
    ViewDocument: I18n('DecPackOrders.PhotoCheck.ViewDocument'),
    Instruction1: I18n('DecPackOrders.PhotoCheck.Instruction1'),
    Instruction2: I18n('DecPackOrders.PhotoCheck.Instruction2'),
  };

  const getWOStatus = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl +
        'DecPackOrder/GetDecPackWO/' +
        id +
        '/' +
        DnPWorkOrders.WO9
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result) {
        setStatus(result.data.status);
        setDetails({
          ...details,
          createdBy: result.data.createdBy,
          updatedBy: result.data.updatedBy,
          createdDate: result.data.createdDate,
          updatedDate: result.data.updatedDate,
        });

        setAssigneeData({
          ...assigneeData,
          assignedToId: result.data.assignedToId,
          assignedToName: result.data.assignedToName,
          assignNextToId: result.data.assignedNextToId,
          assignNextToName: result.data.assignedNextToName,
        });

        setIsMobile(result.data.isMobile);
        return result.data.status;
      }
    } catch (error) {}
  };

  const getDocuments = async () => {
    const apiUrl = new URL(environment.api.baseUrl + 'Documents');
    try {
      apiUrl.searchParams.set(
        '$filter',
        'EntityType~=~' + 9 + ' and EntityId~=~' + id
      );
      apiUrl.searchParams.set('$orderby', 'name');
      apiUrl.searchParams.set('$top', '10');
      apiUrl.searchParams.set(
        '$orderbydirection',
        OrderByDirection.Descending.toString()
      );
      const result = await http.get(apiUrl.toString());
      if (result) {
        result.data.values.map((item: any) => {
          setDGDName(item.name);
          setDGDDocument(item.documentUrl);
        });
      }
    } catch (error) {}
  };
  const getWoExecutionPictures = (executionWoId: number) => {
    getWorkOrderImages(
      EntityTypeEnum.DecPackOrders,
      executionWoId ? +executionWoId : 0,
      id ? +id : 0,
      DocumentEntity.DecPackWOExecution
    ).then((x) => {
      if (x.result) {
        /* Object conversion from backend viewModel to ImagePreview component Object */
        setImagesData(
          x.data.map((y) => {
            return {
              documentTag: {
                label: y.documentTagName,
                value: y.documentTag?.toString(),
              } as BaseModel,
              error: '',
              id: y.id,
              previewUrl:
                environment.api.baseUrl +
                ApiUrlConstants.DownloadImageUrl +
                y.imageUrl,
              path: y.imageUrl,
              sendToCustomer: y.sendToCustomer,
            } as ImageUploadViewModel;
          })
        );
      }
    });
  };
  const getInstruction = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl +
        'DecPackCheckDocumentation/' +
        id +
        '/' +
        localStorage.getItem('DocumentId')
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result && result.data) {
        setInstruction(result.data.instruction);
      }
    } catch (error) {}
  };
  const getInstruction2 = async () => {
    const apiUrl = new URL(environment.api.baseUrl + `DecPackOrder/${id}`);
    try {
      const result = await http.get(apiUrl.toString());
      if (result && result.data) {
        setInstruction2(result.data.specialInstructions);
      }
    } catch (error) {}
  };
  const OnSaveButtonClick = async (event: any) => {
    event.preventDefault();
    setValidateStatus(true);
    setIsSaveNext(false);
  };
  const getPhotoCheck = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl + `DecPackWOPhotoCheck/${id}/${woId}`
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result && result.data) {
        setPhotoExistingId(result.data.id);
        setPhotoCheck(result.data.photoCheck);
      }
    } catch (error) {}
  };

  const handlePhotoSaveClick = async () => {
    await saveWorkOrderImages(
      imagesData,
      EntityTypeEnum.DecPackOrders,
      DocumentEntity.DecPackWOExecution,
      false,
      id ? +id : 0,
      woExecutionId ? +woExecutionId : 0
    );
  };

  // Validate Status Dropdown Success call from Status Component
  const handleSuccessStatusSave = async () => {
    await handlePhotoSaveClick();
    const apiUrl = new URL(environment.api.baseUrl + `DecPackWOPhotoCheck`);
    try {
      let values = {
        photoCheck,
        decPackInitialOrderId: Number(id),
        decPackWorkOrdersId: Number(woId),
      };
      let result;
      if (photoCheckExistingId && photoCheckExistingId > 0) {
        result = await http.put(apiUrl.toString(), values);
        handleErrorStatusSave(); // To set Validation and Submit APi Status False
      } else {
        result = await http.post(apiUrl.toString(), values);
        handleErrorStatusSave(); // To set Validation and Submit APi Status False
      }
      if (result && result.data) {
        setIsStatusSaveCallApi(true);
        toast.success(i18nMessages.savedSuccessfully);
      }
    } catch (error) {}
  };

  // Validate Status Dropdown Error call from Status Component
  const handleErrorStatusSave = () => {
    setValidateStatus(false);
    setIsStatusSaveCallApi(false);
  };

  useEffect(() => {
    getDocuments();
    getPhotoCheck();
    getDeckPackWorkOrderDetailsByWoNumberAsync(
      id ? +id : 0,
      DnPWorkOrders.WO6
    ).then((x) => {
      if (x && x.result && x.data) {
        getWoExecutionPictures(x.data.id);
        setWoExecutionId(x.data.id);
      }
    });

    getInstruction();
    getInstruction2();
    getWOStatus();
  }, []);
  const breadcrumbs = [
    <Typography key="3" color="text.primary">
      {I18n('Nav.Order')}
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={() => navigate(RouteEnum.DecPackOrdersList)}
        className="cpointer"
      >
        {I18n('Nav.DecPackOrders')}
      </Link>
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={() => navigate(RouteEnum.ViewDecPackOrders + '/' + id + '/1')}
        className="cpointer"
      >
        {I18n('ShippingOrders.WorkOrders')}
      </Link>
    </Typography>,
  ];
  const PhotoData = () => {
    return (
      <ImageUploadPreview
        readOnly={true}
        disableKeyboardNav={true}
        limit={10}
        images={imagesData}
        sendToCustomer={true}
      />
    );
  };

  let answerList: Array<any> = [];
  const getAnswerList = () => {
    answerList.push({
      value: true,
      label: I18n('Common.Yes'),
    });
    answerList.push({
      value: false,
      label: I18n('Common.No'),
    });
  };
  getAnswerList();

  const DGDData = () => {
    let extension = DGDDocument.split('.')[1];
    return (
      <>
        <div>{i18nMessages.ViewDocument}</div>
        {DGDDocument && (
          <Link
            underline="hover"
            key="1"
            onClick={() =>
              window.open(
                environment.api.uploadedFileUrl + '/' + DGDDocument,
                '_blank',
                'toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=4000,height=4000'
              )
            }
            className="cpointer"
          >
            {DGDName + '.' + extension}
          </Link>
        )}
      </>
    );
  };
  const SpecialInstructionData = () => {
    return (
      <FormControl variant="standard" fullWidth sx={{ mb: 2 }}>
        <Grid container spacing={2} className="grid-wrap">
          <Grid item lg={12} md={12}>
            Instruction 1 : {instruction2}
          </Grid>
          <Grid item lg={12} md={12}>
            Instruction 2 : {instruction}
          </Grid>
        </Grid>
      </FormControl>
    );
  };
  const PhotoCheckData = () => {
    return (
      <>
        <Grid item lg={6}>
          <RadioGroupInput
            name={'PhotoCheck'}
            handleChange={(event: BaseSyntheticEvent) => {
              let data = event.target.value === 'true' ? true : false;
              setPhotoCheck(data);
            }}
            value={photoCheck}
            options={answerList}
            readOnly={!canEdit}
          ></RadioGroupInput>
        </Grid>
      </>
    );
  };

  const onSaveNextClick = async (event: any) => {
    event.preventDefault();
    setValidateStatus(true);
    setIsSaveNext(true);
  };

  const refreshData = async () => {
    handleErrorStatusSave();
    getWOStatus();
  };
  return (
    <>
      <div className="heading-section">
        <div className="heading-section-left">
          <Typography variant="h3" sx={{ mb: 3 }}>
            {i18nMessages.title}
          </Typography>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>
      </div>
      <div className="main-content-section">
        <Grid container spacing={2} className="grid-wrap">
          <Grid item lg={6} md={6}>
            <FormAccordianSave
              title={i18nMessages.Photos}
              details={PhotoData()}
              showActionButton={!canEdit}
              className="inner-heading"
            ></FormAccordianSave>
          </Grid>
          <Grid item lg={6} md={6}>
            <FormAccordianSave
              title={i18nMessages.DGD}
              details={DGDData()}
              showActionButton={!canEdit}
              className="inner-heading"
            ></FormAccordianSave>
          </Grid>
        </Grid>
        <Grid container spacing={2} className="grid-wrap">
          <Grid item lg={6} md={6}>
            <FormAccordianSave
              title={i18nMessages.SpecialInstruction}
              details={SpecialInstructionData()}
              showActionButton={!canEdit}
              className="inner-heading"
            ></FormAccordianSave>
          </Grid>
          <Grid item lg={6} md={6}>
            <FormAccordianSave
              title={i18nMessages.PhotoCheck}
              details={PhotoCheckData()}
              showActionButton={!canEdit}
              className="inner-heading"
            ></FormAccordianSave>
          </Grid>
        </Grid>
        <Grid item lg={12} md={12}>
          <DecpackStatus
            permission={Permission.canEditDecPackOrders}
            woId={DnPWorkOrders.WO9}
            activityOwner={ActivityOwner.BackOffice}
            orderId={id}
            statusValue={status}
            orderType={OrderType.DecPackOrder}
            isStatusSaveCallApi={isStatusSaveCallApi}
            validateStatus={validateStatus}
            handleSuccessStatusSave={handleSuccessStatusSave}
            handleErrorStatusSave={handleErrorStatusSave}
            isSaveNext={isSaveNext}
            isMobile={isMobile}
            workOrderNo={DnPWorkOrders.WO9}
            assigneeData={assigneeData}
            handleStatusChange={(value: number) => setStatus(value.toString())}
          />
        </Grid>
        <div className="btn-wrap form-btn-wrap">
          {canEdit ? (
            <Button
              color="primary"
              disableElevation
              variant="contained"
              onClick={onSaveNextClick}
            >
              {I18n('Common.SaveNext')}
            </Button>
          ) : (
            <></>
          )}
          &nbsp; &nbsp; &nbsp;
          {canEdit ? (
            <Button
              type="submit"
              color="primary"
              disableElevation
              variant="contained"
              onClick={OnSaveButtonClick}
            >
              {I18n('Common.Save')}
            </Button>
          ) : (
            <></>
          )}
        </div>
        <CreatedByandUpdatedBy
          createdBy={details.createdBy}
          createdDate={details.createdDate}
          updatedBy={details.updatedBy}
          updatedDate={details.updatedDate}
        ></CreatedByandUpdatedBy>
      </div>
    </>
  );
}

export default PhotoCheck;
