import {
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  Button,
  Breadcrumbs,
  Link,
} from '@mui/material';
import DocumentList from 'common/Document/DocumentList';
import {
  ActivityOwner,
  BaseModel,
  DocumentEntity,
  EntityTypeEnum,
  ImageUploadViewModel,
  OrderType,
  PaginationResponseModel,
  WorkOrderStatus,
} from 'models/pagination-model';
import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import I18n from 'utilities/i18n';
import Status from '../../../common/WoStatus/Status';
import http from '../../../utilities/httpService';
import environment from 'environment';
import FormControl from 'common/formControl/formControl';
import { Moment } from 'moment';
import DateTimePickerInput from 'common/DatetimePicker/DateTimePickerInput';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import WODeliverySendEmail from './WorkorderDeliverySendEmail';
import RouteEnum from 'models/RouteEnum';
import { hasPermission } from 'utilities/protectedRoute';
import { Permission } from 'Permissions';
import FormAccordianSave from 'common/formControl/formAccordianSave';
import moment from 'moment';
import CustomSelectPaginate from 'common/CustomSelect/CustomSelectPaginate';
import { Customer } from 'models/service-catalogue';
import CreatedByandUpdatedBy from 'common/CreatedByandUpdatedBy/CreatedByandUpdatedBy';
import ImageUploadPreview from 'common/ImageUploadPreview/ImageUploadPreview';
import ApiUrlConstants from 'constants/api.constants';
import MobileDetails from 'common/MobileDetails/MobileDetails';

const WorkOrderDelivery = () => {
  const { woId, id, shippingWoId } = useParams();
  let navigate = useNavigate();
  const defaultValue = {
    value: '0',
    label: I18n('Placeholders.Select'),
  } as BaseModel;
  const defaultAdditional: any = {
    page: 1,
  };
  const [selectedAssignmentTo, setSelectedAssignmentTo] =
    useState(defaultValue);
  const [mobileImages, setMobileImages] = useState([]);
  const breadcrumbs = [
    <Typography key="3" color="text.primary">
      {I18n('Nav.Order')}
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={() => navigate(RouteEnum.ShippingOrdersList)}
        className="cpointer"
      >
        {I18n('Nav.ShippingOrders')}
      </Link>
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={() => navigate(RouteEnum.ViewShippingOrders + '/' + id + '/2')}
        className="cpointer"
      >
        {I18n('ShippingOrders.WorkOrders')}
      </Link>
    </Typography>,
  ];
  const [isNew, setIsNew] = useState<boolean>(true);
  const today = new Date().toISOString();
  const [woDeliveryId, setWODeliveryId] = useState<number>();
  const [isSendEmailModalOpen, setIsSendEmailModal] = useState(false);
  const [canEdit, setCanEdit] = useState(
    hasPermission(Permission.canEditShippingOrders)
  );
  const [isStatusSaveCallApi, setIsStatusSaveCallApi] = useState(false); // To call Submit APi Status from Status Component
  const [validateStatus, setValidateStatus] = useState<boolean>(false); // validateStatus - To validate Status Dropdown from Status Component
  const [isPODSendEmailModalOpen, setIsPODSendEmailModal] = useState(false);
  const [podExpanded, setPODExpanded] = useState<boolean>(true);
  const [isPODEdit, setIsPODEdit] = useState<boolean>(false);

  const [clientNotAvailable, setClientNotAvailable] = useState(false);
  const [clientNotReady, setClientNotReady] = useState(false);
  const [signatureUrl, setSignatureUrl] = useState<ImageUploadViewModel[]>([]);

  const [details, setDetails] = useState<any>({
    createdBy: '',
    createdDate: '',
    updatedBy: '',
    updatedDate: '',
  });

  const i18nMessages = {
    Title: I18n('Delivery.Title'),
    Document: I18n('Delivery.Document'),
    DeliveryDetails: I18n('Delivery.DeliveryDetails'),
    DeliveryDone: I18n('Delivery.DeliveryDone'),
    Remarks: I18n('Delivery.Remarks'),
    ActualDeliveryDateTime: I18n('Delivery.ActualDeliveryDateTime'),
    AssignedTo: I18n('Delivery.AssignedTo'),
    Instructions: I18n('Delivery.Instructions'),
    fieldRequired: I18n('Delivery.FieldRequired'),
    AddSuccess: I18n('Delivery.AddSuccess'),
    SomethingWentWrong: I18n('Delivery.SomethingWentWrong'),
    UpdateSuccess: I18n('Delivery.UpdateSuccess'),
    sendEmailSuccessfully: I18n('SendEmail.SendSuccessfully'),
    errorOccurred: I18n('SendEmail.ErrorOccurred'),
    SendEmailTitle: I18n('SendEmail.SendEmailTitle'),
    POD: I18n('Delivery.POD'),
    SendPOD: I18n('Delivery.SendPOD'),
    commonFieldIsRequired: I18n('Common.FieldIsRequired'),
    InvalidDate: I18n('Common.InvalidDate'),
    ClientNotAvailable: I18n('Common.ClientNotAvailable'),
    ClientNotReady: I18n('Common.ClientNotReady'),
    Signature: I18n('Common.Signature'),
  };

  const [values, setValues] = useState<any>({
    assignTo: '',
    instructions: '',
    isDeliveryDone: false,
    actualDeliveryDateTime: new Date(),
    remarks: '',
    isPODChecked: false,
    mobileRemarks: '',
  });
  const [finalStatus, setFinalStatus] = useState(0);
  const [errors, setErrors] = useState({
    assignTo: '',
    actualDeliveryDateTime: '',
  });

  const [WOEmailHistory, setWOEmailHistory] = useState({
    emailSendDateTime: '',
    emailSendBy: '',
    emailSendTo: '',
    workOrderNo: 0,
    shippingWorkOrderId: 0,
    shippingOrderId: 0,
  });

  const validate = (fieldValues: any = values) => {
    let temp = { ...errors };

    if ('actualDeliveryDateTime' in fieldValues) {
      temp.actualDeliveryDateTime = fieldValues.actualDeliveryDateTime
        ? ''
        : i18nMessages.commonFieldIsRequired;

      if (temp.actualDeliveryDateTime == '') {
        let isDateValid = moment(fieldValues.actualDeliveryDateTime).isValid();
        if (!isDateValid) {
          temp.actualDeliveryDateTime = i18nMessages.InvalidDate;
        }
      }
    }

    setErrors({
      ...temp,
    });
    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === '');
    }
  };
  const getMobileImages = async () => {
    var orderId = Number(id);
    var workOrderId = Number(shippingWoId);

    const apiUrl = new URL(
      environment.api.baseUrl +
        `Documents/GetWorkOrderImagesAsync/${EntityTypeEnum.ShippingOrders}/${DocumentEntity.WODelivery}/${woId}/${orderId}`
    );

    const result = await http.get(apiUrl.toString());
    if (result && result.data) {
      setMobileImages(result.data);
    }
  };

  const UpdateStatusAPICall = async () => {
    const finalValue = {
      workOrderNo: Number(woId),
      shippingOrderId: Number(id),
      activityOwner: ActivityOwner.Driver,
      status: WorkOrderStatus.Done,
      woDate: new Date().toISOString(),
      assignedToName: values.assignTo,
    };
    const apiUrl = new URL(environment.api.baseUrl + 'Shipping/UpdateWOStatus');
    try {
      const result: any = await http.put(apiUrl.toString(), finalValue);
      if (result) {
        setValues({ ...values, status: result.data.status });
      }
    } catch (error: any) {}
  };

  const handleSaveClick = async (event: any) => {
    event.preventDefault();
    setValidateStatus(true);
  };

  const DocumentData = () => {
    return (
      <Grid xl={12} md={12}>
        <DocumentList
          permission={Permission.canEditShippingOrders}
          addPermission={Permission.canAddShippingOrders}
          deletePermission={Permission.canDeleteShippingOrders}
          entityType={EntityTypeEnum.WODelivery}
          paramsId={id}
          isHeaderHide={true}
          documentEntityType={DocumentEntity.WODelivery}
        />
      </Grid>
    );
  };

  const getShippingWO = async () => {
    var shippingOrderId = Number(id);
    var WOID = Number(woId);

    const apiUrl = new URL(
      environment.api.baseUrl +
        'Shipping/GetShippingWO/' +
        shippingOrderId +
        '/' +
        WOID
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result) {
        setDetails({
          ...details,
          createdBy: result.data.createdBy,
          updatedBy: result.data.updatedBy,
          createdDate: result.data.createdDate,
          updatedDate: result.data.updatedDate,
        });
        return result.data;
      }
    } catch (error) {}
  };

  const onDeliveryCheckBoxChange = (event: any) => {
    setValues({ ...values, isDeliveryDone: event.target.checked });
    if (event.target.checked === true) {
      setFinalStatus(WorkOrderStatus.Done);
    }
  };

  const onPODClick = (event: any) => {
    setIsPODSendEmailModal(true);
  };

  const getWODelivery = async () => {
    const apiUrl = new URL(environment.api.baseUrl + 'ShippingWODelivery');
    try {
      var finalSearchTerm = `shippingWorkOrderId~=~${woId} and shippingOrderId~=~${id}`;
      apiUrl.searchParams.set('$filter', finalSearchTerm);
      apiUrl.searchParams.set('$skip', '0');
      apiUrl.searchParams.set('$top', '10');

      var shippingWOData = await getShippingWO();
      if (shippingWOData) {
        setFinalStatus(shippingWOData.status);
      }

      const result = await http.get(apiUrl.toString());
      if (result) {
        if (result.data.values.length > 0) {
          setIsNew(false);
          result.data.values.map(async function (item: any) {
            setWODeliveryId(item.id);
            setValues({
              ...values,
              assignTo: item.assignedTo,
              instructions: item.instructions,
              isDeliveryDone: item.isDeliveryDone,
              actualDeliveryDateTime: item.actualDeliveryDateTime,
              remarks: item.remarks,
              mobileRemarks: item.mobileRemarks,
              waitTime: item.waitTime,
            });
            setClientNotAvailable(item.clientNotAvailable);
            setClientNotReady(item.clientNotReady);
            if (item.signatureURL) {
              setSignatureUrl([
                {
                  documentTag: {
                    label: '',
                    value: '',
                  } as BaseModel,
                  error: '',
                  previewUrl:
                    environment.api.baseUrl +
                    ApiUrlConstants.DownloadImageUrl +
                    item.signatureURL,
                  path: item.signatureURL,
                  imageUrl: item.signatureURL,
                } as ImageUploadViewModel,
              ]);
            }
            if (item.assignedTo) {
              const apiUrl = new URL(
                environment.api.baseUrl + `Users/${item.assignedTo}`
              );
              const resultForUser = await http.get(apiUrl.toString());
              if (resultForUser && resultForUser.data) {
                setSelectedAssignmentTo({
                  label: `${resultForUser.data.firstName} ${resultForUser.data.lastName}`,
                  value: resultForUser.data.id,
                });
              }
            }
          });
        } else {
          setIsNew(true);
          if (shippingWOData.assignedToId) {
            setSelectedAssignmentTo({
              value: shippingWOData.assignedToId,
              label: shippingWOData.assignedToName,
            });
            setValues({
              ...values,
              assignedTo: shippingWOData.assignedToId,
            });
          }
        }
      }
    } catch (error) {
      if (shippingWOData.assignedToId) {
        setSelectedAssignmentTo({
          value: shippingWOData.assignedToId,
          label: shippingWOData.assignedToName,
        });
        setValues({
          ...values,
          assignedTo: shippingWOData.assignedToId,
        });
      }
    }
  };

  const getWOEmailHistory = async () => {
    var orderId = Number(id);
    var workOrderId = Number(shippingWoId);
    var woNumber = Number(woId);

    const apiUrl = new URL(
      environment.api.baseUrl +
        'ShippingWOEmailHistory/' +
        orderId +
        '/' +
        workOrderId +
        '/' +
        woNumber
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result) {
        setWOEmailHistory({
          ...result.data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onSendEmail = useCallback(() => {
    setIsSendEmailModal(true);
  }, []);

  const onCloseSendEmail = () => {
    setIsSendEmailModal(false);
  };

  const onSubmitSendEmail = async (emailModel: any) => {
    const sendEmailPartner = {
      shippingWODeliveryId: woDeliveryId ? Number(woDeliveryId) : 0,
      shippingOrderId: Number(id),
      shippingWorkOrderId: Number(shippingWoId),
      preferredLanguage: Number(emailModel.language),
      emailSendTo: emailModel.email,
    };
    const apiUrl = new URL(
      environment.api.baseUrl +
        'ShippingWODelivery/SendWODeliveryDetailsEmail/' +
        sendEmailPartner +
        '/' +
        getTimeZone()
    );

    const result: any = await http.post(apiUrl.toString(), sendEmailPartner);
    if (result !== null && result.data != null) {
      toast.success(i18nMessages.sendEmailSuccessfully);
      getWOEmailHistory();
    } else {
      toast.error(i18nMessages.errorOccurred);
    }
    onCloseSendEmail();
  };

  const onPODCloseSendEmail = () => {
    setIsPODSendEmailModal(false);
    setValues({ ...values, isPODChecked: false });
  };

  const getTimeZone = () => {
    var offset = new Date().getTimezoneOffset(),
      o = Math.abs(offset);
    return (offset > 0 ? '-' : '') + o;
  };

  const onPODSubmitSendEmail = async (emailModel: any) => {
    const sendEmailData = {
      shippingWODeliveryId: woDeliveryId ? Number(woDeliveryId) : 0,
      shippingOrderId: Number(id),
      shippingWorkOrderId: Number(shippingWoId),
      preferredLanguage: Number(emailModel.language),
      emailSendTo: emailModel.email,
    };
    const apiUrl = new URL(
      environment.api.baseUrl +
        'ShippingWODelivery/SendPODWODeliveryDetailsEmail/' +
        getTimeZone()
    );
    const result: any = await http.post(apiUrl.toString(), sendEmailData);
    if (result !== null && result.data != null) {
      toast.success(i18nMessages.sendEmailSuccessfully);
    } else {
      toast.error(i18nMessages.errorOccurred);
    }
    onPODCloseSendEmail();
  };

  const getParsedDate = (dateStr: string) => {
    let strSplitDate = dateStr
      ? String(dateStr).split(' ')
      : new Date().toISOString().split(' ');
    let date = new Date(strSplitDate[0]);
    let dd = date.getDate();
    let mm = date.getMonth() + 1; //January is 0!
    let yyyy = date.getFullYear();
    let hh = date.getHours();
    let min = date.getMinutes();
    return (
      (dd < 10 ? '0' + dd.toString() : dd.toString()) +
      '-' +
      (mm < 10 ? '0' + mm.toString() : mm.toString()) +
      '-' +
      yyyy +
      ' ' +
      hh +
      ':' +
      min
    );
  };

  // Validate Status Dropdown Success call from Status Component
  const handleSuccessStatusSave = async () => {
    if (validate()) {
      setIsStatusSaveCallApi(true);
      const finalValue = {
        shippingWorkOrderId: Number(woId),
        shippingOrderId: Number(id),
        assignedTo: selectedAssignmentTo.value.toString(),
        instructions: values.instructions,
        isDeliveryDone: values.isDeliveryDone,
        actualDeliveryDateTime: values.actualDeliveryDateTime,
        id: woDeliveryId,
        clientNotAvailable,
        clientNotReady,
        signatureURL:
          signatureUrl && signatureUrl.length > 0 ? signatureUrl[0].path : '',
        waitTime: values.waitTime,
        mobileRemarks: values.mobileRemarks,
      };

      if (isNew) {
        const apiUrl = new URL(environment.api.baseUrl + 'ShippingWODelivery');
        try {
          const result: any = await http.post(apiUrl.toString(), finalValue);
          if (result) {
            toast.success(i18nMessages.AddSuccess);
            handleErrorStatusSave(); // To set Validation and Submit APi Status False
            navigate(RouteEnum.ViewShippingOrders + '/' + id + '/2');
          }
        } catch (error: any) {
          toast.error(i18nMessages.SomethingWentWrong);
        }
      } else {
        const apiUrl = new URL(
          environment.api.baseUrl + 'ShippingWODelivery/' + woDeliveryId
        );
        try {
          const result: any = await http.put(apiUrl.toString(), finalValue);
          if (result) {
            toast.success(i18nMessages.UpdateSuccess);
            handleErrorStatusSave(); // To set Validation and Submit APi Status False
            navigate(RouteEnum.ViewShippingOrders + '/' + id + '/2');
          }
        } catch (error: any) {
          toast.error(i18nMessages.SomethingWentWrong);
        }
      }
    } else {
      handleErrorStatusSave(); /* To set Validation and Submit APi Status False */
    }
  };

  // Validate Status Dropdown Error call from Status Component
  const handleErrorStatusSave = () => {
    setValidateStatus(false);
    setIsStatusSaveCallApi(false);
  };

  const loadUserOptions = async (
    search: string,
    loadedOptions: any,
    { page }: any
  ) => {
    const response = await getUsers(search, page);
    const hasMore = Math.ceil(response.TotalRecords / 10) > page;

    return {
      options: response.Values.map((x) => {
        return {
          value: x.id.toString(),
          label: x.name,
        } as BaseModel;
      }),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };
  const getUsers = async (search: string, pageNo: number) => {
    let response: PaginationResponseModel<Customer>;
    const apiUrl = new URL(environment.api.baseUrl + 'Users/MobileUsers');
    try {
      let searchExpression = '';
      searchExpression += search
        ? ` firstName~like~${search} or lastName~like~${search}`
        : '';
      apiUrl.searchParams.set('$filter', searchExpression);
      apiUrl.searchParams.set('$orderby', 'firstName');
      apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
      apiUrl.searchParams.set('$top', '10');
      apiUrl.searchParams.set('$orderbydirection', 'asc');
      const result = await http.get(apiUrl.toString());
      if (result.data && result.data.values.length) {
        result.data.values.forEach((z: any) => {
          z.name = `${z.firstName} ${z.lastName}`;
        });
      }
      if (result) {
        response = {
          TotalRecords: result.data.totalCount,
          Values: result.data.values,
        } as PaginationResponseModel<Customer>;
      } else {
        response = {
          TotalRecords: 0,
          Values: [],
        } as PaginationResponseModel<Customer>;
      }
    } catch (error) {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<Customer>;
    }
    return response;
  };

  useEffect(() => {
    getWODelivery();
    getWOEmailHistory();
    getMobileImages();
  }, []);

  const DeliveryDetails = () => {
    return (
      <>
        <Grid item lg={2} md={4}>
          <FormControlLabel
            labelPlacement="end"
            control={
              <Checkbox
                name="SameAsVat"
                checked={values.isDeliveryDone}
                onChange={onDeliveryCheckBoxChange}
                inputProps={{ 'aria-label': 'controlled' }}
                disabled={!canEdit}
              />
            }
            label={i18nMessages.DeliveryDone}
          />
        </Grid>
        <Grid item lg={4} md={8}>
          <DateTimePickerInput
            readOnly={!canEdit}
            displayLabel={i18nMessages.ActualDeliveryDateTime}
            name="actualDeliveryDateTime"
            value={values.actualDeliveryDateTime}
            defaultValue={today}
            handleChange={(event: Moment) => {
              setValues({
                ...values,
                actualDeliveryDateTime: event != null ? event.toDate() : '',
              });
            }}
            errorValue={true}
            errorMessage={errors.actualDeliveryDateTime}
          ></DateTimePickerInput>
        </Grid>
      </>
    );
  };

  const SignatureDetails = () => {
    return (
      <>
        <Grid item lg={12} md={12}>
          {signatureUrl.length > 0 ? (
            <ImageUploadPreview
              readOnly={true}
              disableKeyboardNav={true}
              limit={10}
              isImageOnly={true}
              images={signatureUrl}
              hideTags={true}
            />
          ) : (
            <></>
          )}
        </Grid>

        {clientNotAvailable ? (
          <Grid item lg={2} md={2}>
            {i18nMessages.ClientNotAvailable}
          </Grid>
        ) : (
          <></>
        )}

        {clientNotReady ? (
          <Grid item lg={2} md={2}>
            {i18nMessages.ClientNotReady}
          </Grid>
        ) : (
          <></>
        )}
      </>
    );
  };

  const AssignedTo = () => {
    return (
      <>
        <Grid item lg={4} md={8}>
          <div className="sendEmail">
            <CustomSelectPaginate
              displayLabel={I18n('WorkorderPickup.AssignedTo')}
              name="assignmentTo"
              id="assignmentTo"
              key={+selectedAssignmentTo.value}
              additional={defaultAdditional}
              value={selectedAssignmentTo}
              loadOptions={loadUserOptions}
              handleChange={(newValue: BaseModel) => {
                setSelectedAssignmentTo(newValue);
                setValues({ ...values, assignedTo: newValue.value });
              }}
              isSearchable={true}
              errorValue={true}
              errorMessage={errors.assignTo}
              readOnly={!canEdit}
              debounceTimeout={500}
              isMultiSelect={false}
            />
            {canEdit && (
              <FontAwesomeIcon
                icon={faEnvelope}
                onClick={onSendEmail}
                className="fa-md icon-edit"
              />
            )}
          </div>
          {WOEmailHistory.emailSendDateTime && (
            <label className="sendMailLabel">
              {`Last email was sent on  ${getParsedDate(
                WOEmailHistory.emailSendDateTime
              )} to ${WOEmailHistory.emailSendTo} by ${
                WOEmailHistory.emailSendBy
              }`}
            </label>
          )}
        </Grid>
        <Grid item lg={8} md={8}>
          <FormControl
            readOnly={!canEdit}
            margin="normal"
            displayLabel={i18nMessages.Instructions}
            id="instructions"
            inputType="text"
            inputProps={{ maxLength: 250 }}
            placeholder={'Select'}
            handleChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => {
              setValues({ ...values, instructions: event.target.value });
            }}
            value={values.instructions}
            multiline={false}
          ></FormControl>
        </Grid>
      </>
    );
  };

  return (
    <div>
      <div className="heading-section">
        <div className="heading-section-left">
          <Typography variant="h3" sx={{ mb: 3 }}>
            {i18nMessages.Title}
          </Typography>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>
      </div>
      <div className="main-content-section">
        <FormAccordianSave
          title={i18nMessages.AssignedTo}
          details={AssignedTo()}
          showActionButton={!canEdit}
          className="inner-heading"
        ></FormAccordianSave>
        <FormAccordianSave
          title={i18nMessages.DeliveryDetails}
          details={DeliveryDetails()}
          showActionButton={!canEdit}
          className="inner-heading"
        ></FormAccordianSave>
        <FormAccordianSave
          title={i18nMessages.Document}
          details={DocumentData()}
          showActionButton={!canEdit}
          className="inner-heading"
        ></FormAccordianSave>

        <FormAccordianSave
          title={i18nMessages.Signature}
          details={SignatureDetails()}
          showActionButton={false}
          className="inner-heading"
        ></FormAccordianSave>

        <MobileDetails
          mobileRemarks={values.mobileRemarks}
          images={mobileImages}
          waitTime={values.waitTime}
        ></MobileDetails>
        <Grid item lg={12} md={12}>
          <Status
            permission={Permission.canEditShippingOrders}
            woId={woId}
            orderId={id}
            activityOwner={ActivityOwner.Driver}
            statusValue={finalStatus}
            type={selectedAssignmentTo.value}
            assignedTo={selectedAssignmentTo.value}
            assignedToName={selectedAssignmentTo.label}
            orderType={OrderType.ShippingOrder}
            isStatusSaveCallApi={isStatusSaveCallApi}
            validateStatus={validateStatus}
            handleSuccessStatusSave={handleSuccessStatusSave}
            handleErrorStatusSave={handleErrorStatusSave}
            handleStatusChange={(value: number) => setFinalStatus(value)}
          />
        </Grid>
        {canEdit ? (
          <div className="btn-wrap form-btn-wrap">
            <Button
              color="primary"
              disableElevation
              variant="contained"
              onClick={onPODClick}
            >
              {i18nMessages.SendPOD}
            </Button>
            <Button
              type="submit"
              color="primary"
              disableElevation
              variant="contained"
              onClick={handleSaveClick}
            >
              {I18n('Common.Save')}
            </Button>
          </div>
        ) : (
          <></>
        )}
        <CreatedByandUpdatedBy
          createdBy={details.createdBy}
          createdDate={details.createdDate}
          updatedBy={details.updatedBy}
          updatedDate={details.updatedDate}
        ></CreatedByandUpdatedBy>
      </div>
      {isSendEmailModalOpen && (
        <WODeliverySendEmail
          isOpen={isSendEmailModalOpen}
          onClose={onCloseSendEmail}
          onSubmit={onSubmitSendEmail}
          title={i18nMessages.SendEmailTitle}
        ></WODeliverySendEmail>
      )}
      {isPODSendEmailModalOpen && (
        <WODeliverySendEmail
          isOpen={isPODSendEmailModalOpen}
          onClose={onPODCloseSendEmail}
          onSubmit={onPODSubmitSendEmail}
          title={i18nMessages.SendEmailTitle}
        ></WODeliverySendEmail>
      )}
    </div>
  );
};

export default WorkOrderDelivery;
