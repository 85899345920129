import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { FormHelperText } from '@mui/material';
import Creatable from 'react-select/creatable';
import { withAsyncPaginate } from 'react-select-async-paginate';

const CreatableAsyncPaginate = withAsyncPaginate(Creatable);

export const CustomSelectPaginateAdd = (props) => {
  const {
    key,
    displayLabel,
    options,
    handleChange,
    defaultValue,
    isSearchable,
    placeholder,
    value,
    name,
    errorValue,
    required,
    readOnly,
    loadOptions,
    additional,
    errorMessage,
    debounceTimeout,
    isMultiSelect,
    onCreateOption,
    cacheUniqs,
    autoFocus = false,
    menuPlacement = 'bottom',
    isNumber,
  } = props;

  const handleNumberOnlyInput = (e) => {
    var key = e.key;
    var regex = /^[0-9]+$/;
    if (key === 'Backspace' || regex.test(key)) {
      return true;
    } else {
      e.preventDefault();
      return false;
    }
  };
  return (
    <FormControl
      variant="standard"
      fullWidth
      sx={{ mb: 2 }}
      error={errorMessage}
    >
      <InputLabel shrink htmlFor="bootstrap-input">
        {displayLabel}
        <span> {required ? '*' : ''}</span>
      </InputLabel>
      <CreatableAsyncPaginate
        onKeyDown={isNumber ? handleNumberOnlyInput : ''}
        autoFocus={autoFocus}
        SelectComponent={Creatable}
        onCreateOption={onCreateOption}
        cacheUniqs={[cacheUniqs]}
        key={key}
        isMulti={isMultiSelect}
        additional={additional}
        loadOptions={loadOptions}
        onChange={handleChange}
        name={name}
        options={options}
        defaultValue={defaultValue}
        isSearchable={isSearchable}
        placeholder={placeholder}
        value={value}
        closeMenuOnSelect={!isMultiSelect}
        className="custom-select-wrap"
        classNamePrefix="custom-select"
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        }}
        isDisabled={readOnly}
        debounceTimeout={debounceTimeout}
        menuPlacement={menuPlacement}
      />
      {errorValue ? <FormHelperText>{errorMessage}</FormHelperText> : ''}
    </FormControl>
  );
};
export default CustomSelectPaginateAdd;
