import {
  ApiResponse,
  BaseModel,
  CommonListRequestModel,
  CommonPaginationResponseModel,
  OrderByDirection,
} from 'models/pagination-model';
import environment from 'environment';
import http from '../../utilities/httpService';
import {
  ProductConsumptionDto,
  ProductConsumptionResponseDto,
} from 'models/product-catalogue';
import { ShippingOrderListDTO } from 'models/shipping-orders';
import { getShippingOrders } from 'components/shipping-orders/ShippingApiService';
import { DecPackResponseDto } from 'models/deck-pack-orders';
import { getDecPackOrders } from 'components/deckpack-orders/DeckPackApiService';

export async function getProductConsumptions(
  entityId: number,
  request: CommonListRequestModel<ProductConsumptionResponseDto>
) {
  let basicSearchExpression = request.basicSearchExpression;
  let advanceSearchExpression = request.advanceSearchExpression;
  const apiUrl = new URL(environment.api.baseUrl + 'ProductConsumptions');
  try {
    let finalSearchTerm = '';
    if (basicSearchExpression && advanceSearchExpression) {
      finalSearchTerm = `${basicSearchExpression} or ${advanceSearchExpression}`;
    } else if (basicSearchExpression && !advanceSearchExpression) {
      finalSearchTerm = basicSearchExpression;
    } else if (!basicSearchExpression && advanceSearchExpression) {
      finalSearchTerm = advanceSearchExpression;
    }
    apiUrl.searchParams.set('$filter', finalSearchTerm);
    apiUrl.searchParams.set('$orderby', request.gridData.sortBy);
    apiUrl.searchParams.set(
      '$skip',
      (request.gridData.page * request.gridData.pageSize).toString()
    );
    apiUrl.searchParams.set('$top', request.gridData.pageSize.toString());
    apiUrl.searchParams.set(
      '$orderbydirection',
      request.gridData.sortOrder.toString()
    );
    const result = await http.get(apiUrl.toString());
    if (result) {
      request.gridData.rows = result.data.values;
      request.gridData.totalRows = result.data.totalCount;
    }
    return request;
  } catch (error) {}
}

export async function loadShippingOrdersOptions(
  search: string,
  loadedOptions: any,
  { page }: any
) {
  let searchExpression = '';
  searchExpression += search ? `AutogeneratedReferenceNo~like~${search}` : '';
  const response = await getShippingOrders({
    gridData: {
      page,
      pageSize: 10,
      sortBy: 'orderDate',
      sortOrder: OrderByDirection.Descending,
    } as CommonPaginationResponseModel<ShippingOrderListDTO>,
    basicSearchExpression: searchExpression,
    advanceSearchExpression: '',
  } as CommonListRequestModel<ShippingOrderListDTO>);
  let totalRows = 0;
  let rows: ShippingOrderListDTO[] = [];
  totalRows = response && response.gridData ? response?.gridData.totalRows : 0;
  rows = response && response.gridData ? response?.gridData.rows : [];
  const hasMore = Math.ceil(totalRows / 10) > page;

  return {
    options: rows.map((x) => {
      return {
        value: x.id.toString(),
        label: x.autogeneratedReferenceNo,
      } as BaseModel;
    }),
    hasMore,
    additional: {
      page: page + 1,
    },
  };
}

export async function loadDeckPackOrdersOptions(
  search: string,
  loadedOptions: any,
  { page }: any
) {
  let searchExpression = '';
  searchExpression += search ? `HazgoReference~like~${search}` : '';
  const response = await getDecPackOrders({
    gridData: {
      page,
      pageSize: 10,
      sortBy: 'orderDate',
      sortOrder: OrderByDirection.Descending,
    } as CommonPaginationResponseModel<DecPackResponseDto>,
    basicSearchExpression: searchExpression,
    advanceSearchExpression: '',
  } as CommonListRequestModel<DecPackResponseDto>);
  let totalRows = 0;
  let rows: DecPackResponseDto[] = [];
  totalRows = response && response.gridData ? response?.gridData.totalRows : 0;
  rows = response && response.gridData ? response?.gridData.rows : [];
  const hasMore = Math.ceil(totalRows / 10) > page;

  return {
    options: rows.map((x) => {
      return {
        value: x.id.toString(),
        label: x.hazgoReference,
      } as BaseModel;
    }),
    hasMore,
    additional: {
      page: page + 1,
    },
  };
}

export async function getAvailableWarehouseQuantity(
  productCatalogueId: number
) {
  let response: number = 0;
  const apiUrl = new URL(
    environment.api.baseUrl +
      'ProductCatalogues/GetWarehouseQuantityAsync?id=' +
      productCatalogueId
  );
  try {
    const result = await http.get(apiUrl.toString());
    if (result && result.data) {
      response = result.data;
    }
  } catch (error) {}
  return response;
}

export async function saveProductConsumption(
  id: number,
  productConsumption: ProductConsumptionDto
) {
  try {
    if (id && +id) {
      const apiUrl = new URL(
        environment.api.baseUrl + 'ProductConsumptions/' + id
      );
      const result: any = await http.put(apiUrl.toString(), productConsumption);
      if (result) {
        return {
          result: result.data,
          data: null,
        } as ApiResponse<null>;
      }
    } else {
      const apiUrl = new URL(environment.api.baseUrl + 'ProductConsumptions');
      const result: any = await http.post(
        apiUrl.toString(),
        productConsumption
      );
      if (result) {
        return {
          result: true,
          data: result.data as ProductConsumptionDto,
        } as ApiResponse<ProductConsumptionDto>;
      }
    }
  } catch (error: any) {
    throw error;
  }
  return {
    result: false,
    data: null,
  } as ApiResponse<null>;
}
