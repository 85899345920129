import {
  Typography,
  Breadcrumbs,
  Link,
  Grid,
  Button,
  TextField,
  Tooltip,
} from '@mui/material';
import React, {
  useCallback,
  useEffect,
  useState,
  BaseSyntheticEvent,
} from 'react';
import http from '../../utilities/httpService';
import environment from 'environment';
import I18n from 'utilities/i18n';
import FormControl from 'common/formControl/formControl';
import CustomizedSelect from 'common/CustomSelect/CustomSelect';
import {
  BaseModel,
  TypeOfService,
  TypeOfServiceDescription,
  CustomerLocation,
  CustomerLocationDescription,
  OrderByDirection,
  EntityTypeEnum,
  PaginationResponseModel,
  AddLocationEntity,
  RadioGroupBaseModel,
  ApiResponse,
} from 'models/pagination-model';
import CustomDatePicker from 'common/dateAdapter/dateAdapter';
import { Checkbox, FormControlLabel } from '@mui/material';
import CustomSelectPaginate from 'common/CustomSelect/CustomSelectPaginate';
import {
  loadCustomerOptions,
  loadLocationOptions,
  loadRemainingContactOptions,
  loadAirlineOptions,
  postAirLine,
} from './AddDecpackApiService';
import AddContactPerson from '../../common/contactPerson/add-contact-person';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CustomSelectPaginateAdd from 'common/CustomSelect/CustomSelectPaginateAdd';
import {
  AddressSegments,
  ShippingOrderTypeDescription,
  ShippingType,
  ExecutionTimeDescription,
  ExecutionTime,
} from 'models/shipping-orders';
import { toast } from 'react-toastify';
import AddressAutocompleteInputs from 'common/AddressAutocomplete/AddressAutocompleteInput';
import RouteEnum from 'models/RouteEnum';
import { useNavigate, useParams } from 'react-router-dom';
import NumericInput from 'common/NumericInput/NumericInput';
import { getLocationById } from 'components/shipping-orders/ShippingApiService';
import AddLocation from 'common/location/AddLocation';
import { Customer } from 'models/service-catalogue';
import { PostCountryDropDown } from 'common/DropDownAddAPI/DropDownApiService';
import AirlinePopup from 'components/Airline/AirlinePopup';
import moment from 'moment';
import TimePickerInput from 'common/TimePicker/TimePickerInput';
import InputLabel from '@mui/material/InputLabel';
import RadioGroupInput from 'common/RadioGroup/RadioGroupInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import ViewCustomerModal from 'common/customer/ViewCustomerModal';
import { Permission } from 'Permissions';
import { hasPermission } from 'utilities/protectedRoute';

function AddDecpack() {
  let navigate = useNavigate();

  const defaultValueForFieldType = {
    value: ShippingType.InOrder.toString(),
    label: I18n('ShippingOrders.InOrder'),
  } as BaseModel;

  const defaultValueForService = {
    value: '0',
    label: I18n('Placeholders.Select'),
  } as BaseModel;

  const defaultContactValue = {
    value: '',
    label: I18n('Placeholders.Select'),
  } as any;

  const defaultCustomerValue = {
    value: '0',
    label: I18n('Placeholders.SelectCustomer'),
  } as BaseModel;

  let entityLocationOptionsList: Array<BaseModel> = [];
  const getLocationOptionsList = () => {
    entityLocationOptionsList = [];
    CustomerLocationDescription.forEach((value: string, key: number) => {
      entityLocationOptionsList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getLocationOptionsList();

  const defaultAddressSegments = {
    country: '',
    city: '',
    postalCode: '',
    address: '',
  } as AddressSegments;
  const defaultAdditional: any = {
    page: 1,
  };
  const [isAddModalOpen, setAddModal] = useState(false);
  const [cacheUniq, setCacheUniq] = useState(0);
  const [cacheUniqCountry, setCacheUniqCountry] = useState(0);
  const [cacheUniqContact, setCacheUniqContact] = useState(0);
  const [values, setValues] = useState({
    TypeOfService: defaultValueForService,
    HighPriority: false,
    customer: defaultCustomerValue,
    Contact: defaultValueForService,
    Location: defaultValueForService,
    address: '',
    SecuredShipment: false,
    customerLocation: defaultValueForService,
    customerContact: defaultValueForService,
    Airline: defaultValueForService,
    FinalDestination: defaultValueForService,
    TransitDestination: defaultValueForService,
    CustomerReference: '',
    Shipper: '',
    DriverName: '',
    LicensePlate: '',
    Transporter: '',
    NumberofPieces: 0,
    Pickupaddress: '',
    ExpectedInboundDate: new Date().toISOString(),
    SpecialInstructions: '',
    EstimatedDuration: 0,
    Interventie: '',
    type: defaultValueForFieldType,
    deliveryLocation: defaultValueForService,
    deliveryCustomerAddress: defaultAddressSegments,
    customerAddress: defaultAddressSegments,
    selectedContacts: [] as any,
    executionTime: null,
    companyName: '',
    KcRaAgent: '',
    PickUpBy: '',
    DeliveryReference: '',
  });
  const [openAirline, setOpenAirline] = useState(false);
  const [rowDataState, setRowDataState] = useState({});
  const [rowDataAirline, setRowDataAirline] = useState({});
  const [AddContactModalOpen, setAddContactModalOpen] = useState({
    isAddModalOpen: false,
    newContact: '',
    triggerFrom: 0,
    entityId: '',
  });
  const [errors, setErrors] = useState({
    TypeOfService: '',
    customerLocation: '',
    Interventie: '',
    customerContact: '',
    ExecutionDate: '',
    deliveryLocation: '',
    Customer: '',
    CustomerReference: '',
    Shipper: '',
    Location: '',
    Airline: '',
    FinalDestination: '',
    selectedContacts: '',
    type: '',
    ExpectedInboundDate: '',
    ExecutionTime: '',
    StartTime: '',
    EndTime: '',
    KcRaAgent: '',
    PickUpBy: '',
    DriverName: '',
    LicensePlate: '',
    Transporter: '',
    NumberofPieces: '',
  });
  const { id } = useParams();
  const [date, setDate] = useState(new Date().toISOString());
  const [countryList, setCountryList] = useState<BaseModel[]>([]);
  const [startvalue, setStartValue]: any = React.useState('');
  const [endvalue, setEndValue]: any = React.useState('');
  const [AddLocationModalOpen, setAddLocationModalOpen] = useState({
    isAddLocationModalOpen: false,
    newLocation: '',
    triggerFrom: AddLocationEntity.Default,
    entityId: '',
    newDestination: '',
  });
  const [viewCustomerDetails, setViewCustomerDetails] = useState({
    isShowCustomerDetails: false,
    newCustomerName: '',
  });
  const i18nMessages = {
    SecuredShipment: I18n('DecPack.SecuredShipment'),
    DriverName: I18n('DecPack.DriverName'),
    LicensePlate: I18n('DecPack.LicensePlate'),
    Transporter: I18n('DecPack.Transporter'),
    NumberofPieces: I18n('DecPack.NumberofPieces'),
    Pickupaddress: I18n('DecPack.Pickupaddress'),
    KcRaAgent: I18n('DecPack.KcRaAgent'),
    PickUpBy: I18n('DecPack.PickUpBy'),
    ExpectedInboundDate: I18n('DecPack.ExpectedInboundDate'),
    DeliveryLocation: I18n('DecPack.DeliveryLocation'),
    fieldRequired: I18n('Common.FieldRequired'),
    Cancel: I18n('Common.Cancel'),
    Country: I18n('CustomerLocation.Country'),
    City: I18n('CustomerLocation.City'),
    ZipCode: I18n('CustomerLocation.ZipCode'),
    Add: I18n('Common.Add'),
    DetailsWarehouse: I18n('DecPack.DetailsWarehouse'),
    InboundDetails: I18n('DecPack.InboundDetails'),
    OutboundDetails: I18n('DecPack.OutboundDetails'),
    CommonSaveSuccessMsg: I18n('Common.SavedSuccessfully'),
    CommonErrOccuredMsg: I18n('Common.ErrorOccurred'),
    CustomerLocation: I18n('CustomerLocation.AddLocation'),
    AddContacts: I18n('ContactPerson.AddContactPerson'),
    SpecialInstruction: I18n('DecPack.SpecialInstructions'),
    EstimatedDuration: I18n('DecPack.EstimatedDuration'),
    TransitDestination: I18n('DecPack.TransitDestination'),
    FinalDestination: I18n('DecPack.FinalDestination'),
    AirLine: I18n('DecPack.Airline'),
    GeneralDetails: I18n('DecPack.GeneralDetails'),
    commonFieldIsRequired: I18n('Common.FieldIsRequired'),
    InvalidDate: I18n('Common.InvalidDate'),
    InvalidTime: I18n('Common.InvalidTime'),
    SomethingWentWrong: I18n('Common.SomethingWentWrong'),
    EndExecutionTime: I18n('DecPack.EndExecutionTime'),
    StartExecutionTime: I18n('DecPack.StartExecutionTime'),
    viewCustomer: I18n('Customer.ViewCustomer'),
    companyName: I18n('DecPack.CompanyName'),
    DeliveryReference: I18n('DecPack.DeliveryReference'),
  };

  const onCreateOption = async (inputValue: any) => {
    var data = {
      name: inputValue,
    };
    setRowDataAirline(data);
    setOpenAirline(true);
  };

  const handleCloseForEditAirline = () => {
    setOpenAirline(false);
  };

  const handleOnSaveAirline = (data: any) => {
    setOpenAirline(false);
    setValues({
      ...values,
      Airline: { value: data.id, label: data.name },
    });
  };

  const handleListClick = () => {
    navigate(`${RouteEnum.DecPackOrdersList}`);
  };

  const submit = async () => {
    if (validate()) {
      const finalData = {
        serviceType: Number(values.TypeOfService.value),
        executionDate: date,
        executionTime: Number(values.executionTime),
        customerReference: values.CustomerReference,
        shipper: values.Shipper,
        locationType: Number(values.Location.value),
        securedShipment: values.SecuredShipment,
        driverName: values.DriverName,
        licensePlate: values.LicensePlate,
        transporter: values.Transporter,
        piecesNo: Number(values.NumberofPieces),
        pickUpOriginAddress: values.Pickupaddress,
        kcRaAgent: values.KcRaAgent,
        pickUpBy: values.PickUpBy,
        expectedInboundDate: values.ExpectedInboundDate,
        interventie:
          Number(values.Location.value) == CustomerLocation.GroundHandler
            ? values.Interventie
            : '',
        specialInstructions: values.SpecialInstructions,
        isHighPriority: values.HighPriority,
        estimatedDuration: values.EstimatedDuration,
        status: 1,
        type:
          Number(values.type.value) !== 0 ? Number(values.type.value) : null,
        orderDate: new Date().toISOString(),
        customerId:
          Number(values.customer.value) !== 0
            ? Number(values.customer.value)
            : null,
        contactPersonId:
          Number(values.customerContact.value) !== 0
            ? Number(values.customerContact.value)
            : null,
        locationId:
          Number(values.customerLocation.value) !== 0
            ? Number(values.customerLocation.value)
            : null,
        deliveryLocationId:
          Number(values.deliveryLocation.value) !== 0
            ? Number(values.deliveryLocation.value)
            : null,
        airlineId:
          Number(values.Airline.value) !== 0
            ? Number(values.Airline.value)
            : null,
        finalDestinationId:
          Number(values.FinalDestination.value) !== 0
            ? Number(values.FinalDestination.value)
            : null,
        transitDestinationId:
          Number(values.TransitDestination.value) !== 0
            ? Number(values.TransitDestination.value)
            : null,
        selectedContacts: values.selectedContacts,
        companyName: values.companyName,
        deliveryReference: values.DeliveryReference,
      };

      if (Number(values.executionTime) === Number(ExecutionTime.SpecificTime)) {
        finalData['startTime'] = startvalue;
        finalData['endTime'] = endvalue;
      }

      if (finalData.selectedContacts?.length > 0) {
        var contacts: any = [];
        finalData.selectedContacts.map((x: any) => {
          contacts.push({
            contactPersonId: Number(x.value),
            contactPersonName: x.label,
          });
        });
        finalData.selectedContacts = contacts;
      } else {
        finalData.selectedContacts = [];
      }

      const apiUrl = new URL(environment.api.baseUrl + 'DecPackOrder');
      try {
        const result: any = await http.post(apiUrl.toString(), finalData);
        if (result !== null && result.data !== null && result.data.success) {
          toast.success('Saved Successfully');
          navigate(`${RouteEnum.ViewDecPackOrders}/${result.data.message}/1`);
        } else {
          toast.error(i18nMessages.SomethingWentWrong);
        }
      } catch (error: any) {
        toast.error(i18nMessages.SomethingWentWrong);
      }
    }
  };
  const breadcrumbs = [
    <Typography key="3" color="text.primary">
      {I18n('DecPackOrders.DeckPack')}
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={handleListClick}
        className="cpointer"
      >
        {I18n('Nav.DecPackOrders')}
      </Link>
    </Typography>,
    <Typography key="3" color="text.primary">
      {I18n('DecPack.AddDecPack')}
    </Typography>,
  ];

  const onValueChange = (event: any) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name.toString()]: value,
    });
  };

  let entityServiceOptionsList: Array<BaseModel> = [];
  const getServiceOptionsList = () => {
    entityServiceOptionsList = [defaultValueForService];
    TypeOfServiceDescription.forEach((value: string, key: number) => {
      entityServiceOptionsList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getServiceOptionsList();

  const getCountries = async (search: string, pageNo: number) => {
    let response: PaginationResponseModel<Customer>;
    const apiUrl = new URL(environment.api.baseUrl + 'Country');
    try {
      let searchExpression = '';
      searchExpression += search ? `name~like~${search}` : '';
      apiUrl.searchParams.set('$filter', searchExpression);
      apiUrl.searchParams.set('$orderby', 'name');
      apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
      apiUrl.searchParams.set('$top', '10');
      apiUrl.searchParams.set('$orderbydirection', 'desc');
      const result = await http.get(apiUrl.toString());
      if (result) {
        response = {
          TotalRecords: result.data.totalCount,
          Values: result.data.values,
        } as PaginationResponseModel<Customer>;
      } else {
        response = {
          TotalRecords: 0,
          Values: [],
        } as PaginationResponseModel<Customer>;
      }
    } catch (error) {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<Customer>;
    }
    return response;
  };

  let countryLst: Array<BaseModel> = [];
  const getCountryList = (countries: any[]) => {
    countryLst = [defaultValueForService];
    countries.forEach((getCountryList: any) => {
      countryLst.push({
        value: getCountryList.key,
        label: getCountryList.value,
      } as BaseModel);
    });

    return countryLst;
  };

  useEffect(() => {
    setValues({ ...values, Location: entityLocationOptionsList[0] });
    getCountries('', 1);
  }, []);

  const handleCloseForAdd = () => {
    setAddModal(false);
  };

  const refreshData = async () => {
    handleCloseForAdd();
  };
  const onSecureShipmentChange = () => {
    setValues({ ...values, SecuredShipment: !values.SecuredShipment });
  };

  let shippingTypeList: Array<BaseModel> = [];
  const getShippingTypes = () => {
    shippingTypeList = [defaultValueForService];
    ShippingOrderTypeDescription.forEach((value: string, key: number) => {
      shippingTypeList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getShippingTypes();

  let executionTimeList: Array<BaseModel> = [];
  const getExecutionTimeList = () => {
    ExecutionTimeDescription.forEach((value: string, key: number) => {
      executionTimeList.push({
        value: key.toString(),
        label: I18n(value),
      } as RadioGroupBaseModel);
    });
  };
  getExecutionTimeList();

  const validate = (fieldValues: any = values) => {
    let temp = { ...errors };
    if ('TypeOfService' in fieldValues) {
      if (fieldValues.TypeOfService.value === '0') {
        temp.TypeOfService = i18nMessages.fieldRequired;
      } else {
        temp.TypeOfService = fieldValues.TypeOfService
          ? ''
          : i18nMessages.fieldRequired;
      }

      if (
        fieldValues.TypeOfService.value === '1' ||
        fieldValues.TypeOfService.value === '2'
      ) {
        if ('FinalDestination' in fieldValues) {
          if (fieldValues.FinalDestination.value === '0') {
            temp.FinalDestination = i18nMessages.fieldRequired;
          } else {
            temp.FinalDestination = fieldValues.FinalDestination
              ? ''
              : i18nMessages.fieldRequired;
          }
        }
      } else {
        if ('FinalDestination' in fieldValues) {
          temp.FinalDestination = fieldValues.FinalDestination
            ? ''
            : i18nMessages.fieldRequired;
        }
      }
    }

    temp.ExecutionDate = date ? '' : i18nMessages.commonFieldIsRequired;
    if (temp.ExecutionDate == '') {
      let isDateValid = moment(date).isValid();
      if (!isDateValid) {
        temp.ExecutionDate = i18nMessages.InvalidDate;
      }
    }

    if ('customer' in fieldValues) {
      if (fieldValues.customer.value === '0') {
        temp.Customer = i18nMessages.fieldRequired;
      } else {
        temp.Customer = fieldValues.customer ? '' : i18nMessages.fieldRequired;
      }
    }

    if ('selectedContacts' in fieldValues) {
      if (fieldValues.selectedContacts.length === 0) {
        temp.selectedContacts = i18nMessages.fieldRequired;
      } else {
        temp.selectedContacts = fieldValues.selectedContacts
          ? ''
          : i18nMessages.fieldRequired;
      }
    }

    if ('Location' in fieldValues) {
      temp.Location = '';
      temp.Interventie = '';
      temp.ExpectedInboundDate = '';

      if (fieldValues.Location.value === '0') {
        temp.Location = i18nMessages.fieldRequired;
      } else if (
        fieldValues.Location.value == CustomerLocation.GroundHandler &&
        'Interventie' in fieldValues
      ) {
        temp.Interventie = fieldValues.Interventie
          ? ''
          : i18nMessages.fieldRequired;
      } else if (
        fieldValues.Location.value == CustomerLocation.WareHouse &&
        'ExpectedInboundDate' in fieldValues
      ) {
        temp.ExpectedInboundDate = fieldValues.ExpectedInboundDate
          ? ''
          : i18nMessages.commonFieldIsRequired;

        if (temp.ExpectedInboundDate == '') {
          let isDateValid = moment(fieldValues.ExpectedInboundDate).isValid();
          if (!isDateValid) {
            temp.ExpectedInboundDate = i18nMessages.InvalidDate;
          }
        }
      } else {
        temp.Location = fieldValues.Location ? '' : i18nMessages.fieldRequired;
      }
    }

    if ('type' in fieldValues) {
      if (fieldValues.type.value === '0') {
        temp.type = i18nMessages.fieldRequired;
      } else {
        temp.type = fieldValues.type ? '' : i18nMessages.fieldRequired;
      }
    }

    if ('executionTime' in fieldValues && !fieldValues.executionTime) {
    } else {
      temp.ExecutionTime = '';

      let executionStartTime = startvalue;
      temp.StartTime = '';
      if (Number(values.executionTime) === Number(ExecutionTime.SpecificTime)) {
        temp.StartTime = executionStartTime
          ? ''
          : i18nMessages.commonFieldIsRequired;

        if (temp.StartTime == '') {
          if (typeof executionStartTime == 'object') {
            if (executionStartTime == 'Invalid Date') {
              temp.StartTime = i18nMessages.InvalidTime;
            } else {
              let isDateValid = moment(executionStartTime.toDate()).isValid();
              if (!isDateValid) {
                temp.StartTime = i18nMessages.InvalidTime;
              }
            }
          }
        }
      }

      let executionEndTime = endvalue;
      temp.EndTime = '';
      if (Number(values.executionTime) === Number(ExecutionTime.SpecificTime)) {
        temp.EndTime = executionEndTime
          ? ''
          : i18nMessages.commonFieldIsRequired;

        if (temp.EndTime == '') {
          if (typeof executionEndTime == 'object') {
            if (executionEndTime == 'Invalid Date') {
              temp.EndTime = i18nMessages.InvalidTime;
            } else {
              let isDateValid = moment(executionEndTime.toDate()).isValid();
              if (!isDateValid) {
                temp.EndTime = i18nMessages.InvalidTime;
              }
            }
          }
        }
      }
    }

    if (
      fieldValues.SecuredShipment &&
      Number(fieldValues.Location.value) === CustomerLocation.WareHouse
    ) {
      if ('DriverName' in fieldValues) {
        temp.DriverName =
          fieldValues.DriverName && fieldValues.DriverName.trim()
            ? ''
            : i18nMessages.fieldRequired;
      }

      if ('LicensePlate' in fieldValues) {
        temp.LicensePlate =
          fieldValues.LicensePlate && fieldValues.LicensePlate.trim()
            ? ''
            : i18nMessages.fieldRequired;
      }

      if ('Transporter' in fieldValues) {
        temp.Transporter =
          fieldValues.Transporter && fieldValues.Transporter.trim()
            ? ''
            : i18nMessages.fieldRequired;
      }

      if ('NumberofPieces' in fieldValues) {
        temp.NumberofPieces = fieldValues.NumberofPieces
          ? ''
          : i18nMessages.fieldRequired;
      }

      if ('KcRaAgent' in fieldValues) {
        temp.KcRaAgent =
          fieldValues.KcRaAgent && fieldValues.KcRaAgent.trim()
            ? ''
            : i18nMessages.fieldRequired;
      }
    } else {
      temp.KcRaAgent = '';
      temp.DriverName = '';
      temp.LicensePlate = '';
      temp.Transporter = '';
      temp.NumberofPieces = '';
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === '');
    }
  };

  const onCreateCustomerLocation = async (inputValue: any) => {
    if (values?.customer?.value && Number(values.customer.value) > 0) {
      const increaseUniq = (uniq: any) => uniq + 1;
      setCacheUniq(increaseUniq);
      setAddLocationModalOpen({
        isAddLocationModalOpen: true,
        newLocation: inputValue,
        triggerFrom: AddLocationEntity.DecPackCustomerLocation,
        entityId: values.customer.value,
        newDestination: '',
      });
    }
  };

  const onCreateDeliveryLocation = async (inputValue: any) => {
    if (values?.customer?.value && Number(values.customer.value) > 0) {
      const increaseUniq = (uniq: any) => uniq + 1;
      setCacheUniq(increaseUniq);
      setAddLocationModalOpen({
        isAddLocationModalOpen: true,
        newLocation: inputValue,
        triggerFrom: AddLocationEntity.DecPackWarehouseLocation,
        entityId: values.customer.value,
        newDestination: '',
      });
    }
  };
  const onCreateContact = async (inputValue: any) => {
    const increaseUniq = (uniq: any) => uniq + 1;
    setCacheUniqContact(increaseUniq);
    setAddContactModalOpen({
      isAddModalOpen: true,
      newContact: inputValue,
      triggerFrom: 1,
      entityId: values.customer.value,
    });
  };

  const OnSaveContact = async (response: any, triggerFrom: number) => {
    if (response && response?.data) {
      if (triggerFrom === 1) {
        setValues({
          ...values,
          selectedContacts: [
            ...values.selectedContacts,
            {
              label: String(
                response.data.firstName + ' ' + response.data.lastName
              ),
              value: response.data.id.toString(),
            } as BaseModel,
          ],
        });
      }
    }
    handleCloseForAddContact();
  };

  const handleCloseForAddContact = () => {
    setAddContactModalOpen({
      isAddModalOpen: false,
      newContact: '',
      triggerFrom: 0,
      entityId: '',
    });
  };

  const handleCloseForAddLocation = () => {
    setAddLocationModalOpen({
      isAddLocationModalOpen: false,
      newLocation: '',
      triggerFrom: AddLocationEntity.Default,
      entityId: '',
      newDestination: '',
    });
  };

  const OnSave = async (response: any, triggerFrom: number) => {
    if (response && response?.data) {
      if (triggerFrom === AddLocationEntity.DecPackCustomerLocation) {
        // Added from customer location Dropdown
        setValues({
          ...values,
          customerLocation: {
            label: `${response.data.street ? `${response.data.street},` : ''}${
              response.data.address
            }${response.data.address2 ? `${response.data.address2},` : ''}${
              response.data.zipCode ? `${response.data.zipCode},` : ''
            }${response.data.city.label},${response.data.country.label}`,
            value: response.data.id,
          } as BaseModel,
          customerAddress:
            response.data.id && response.data.id !== undefined
              ? await getLocationData(Number(response.data.id))
              : defaultAddressSegments,
        });
      } else if (triggerFrom === AddLocationEntity.DecPackWarehouseLocation) {
        // Added from delivery location dropdown
        setValues({
          ...values,
          deliveryLocation: {
            label: `${response.data.street ? `${response.data.street},` : ''}${
              response.data.address
            }${response.data.address2 ? `${response.data.address2},` : ''}${
              response.data.zipCode ? `${response.data.zipCode},` : ''
            }${response.data.city.label},${response.data.country.label}`,
            value: response.data.id,
          } as BaseModel,
          deliveryCustomerAddress:
            response.data.id && response.data.id !== undefined
              ? await getLocationData(Number(response.data.id))
              : defaultAddressSegments,
        });
      }
    }
    handleCloseForAddLocation();
  };

  const getLocationData = async (id: number) => {
    let location = await getLocationById(Number(id));
    let addressSegments = defaultAddressSegments;
    if (location?.data) {
      addressSegments = {
        country: location?.data?.country ? location.data.country : '',
        city: location?.data?.city ? location.data.city : '',
        postalCode: location?.data?.zipCode ? location.data.zipCode : '',
        address: location?.data?.address ? location.data.address : '',
        address2: location?.data?.address2 ? location?.data?.address2 : '',
        street: location?.data?.street ? location?.data?.street : '',
      };
    }
    return addressSegments;
  };

  const myStyle = {
    position: 'relative' as 'relative',
    top: '20px',
  };

  const onCreateOptionCountry = async (inputValue: any, isTransit: boolean) => {
    let name = {};
    name['name'] = inputValue;
    const newOption: any = await PostCountryDropDown(
      name,
      i18nMessages.CommonSaveSuccessMsg,
      i18nMessages.CommonErrOccuredMsg
    );
    const increaseUniq = (uniq: any) => uniq + 1;
    setCacheUniqCountry(increaseUniq);
    if (isTransit) {
      setValues({ ...values, TransitDestination: newOption });
    } else {
      setValues({ ...values, FinalDestination: newOption });
    }

    getCountries('', 1);
  };

  const loadCountryOptions = async (
    search: string,
    loadedOptions: any,
    { page }: any,
    loadInstruction: boolean = false
  ) => {
    const response = await getCountries(search, page);
    const hasMore = Math.ceil(response.TotalRecords / 10) > page;

    return {
      options: response.Values.map((x: any) => {
        return {
          value: x.id.toString(),
          label: loadInstruction ? x.description : x.name,
        } as BaseModel;
      }),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const onCustomerDetails = () => {
    setViewCustomerDetails({
      isShowCustomerDetails: true,
      newCustomerName: '',
    });
  };

  const handleCloseForShowCustomerDetails = () => {
    setViewCustomerDetails({
      isShowCustomerDetails: false,
      newCustomerName: '',
    });
  };

  const getCustomerData = async (id: string) => {
    const apiUrl = new URL(
      environment.api.baseUrl + 'Locations/GetLocationByCustomerId/' + id
    );
    try {
      const result: any = await http.get(apiUrl.toString());
      if (result) {
        if (result && result.data) {
          return {
            result: true,
            data: result.data,
          } as ApiResponse<any>;
        } else {
          return {
            result: false,
            data: null,
          } as ApiResponse<any>;
        }
      }
    } catch (error: any) {}
  };
  return (
    <div>
      <div className="heading-section">
        <div className="heading-section-left">
          <Typography variant="h3" sx={{ mb: 3 }}>
            {I18n('DecPack.AddDecPack')}
          </Typography>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>
      </div>
      <div className="main-content-section">
        <Grid container spacing={2} className="grid-wrap">
          <Grid item lg={6} md={6}>
            <div className="inner-section">
              <Typography variant="h5" className="inner-heading">
                {I18n('DecPack.OrderBasics')}
              </Typography>
              <Grid container spacing={2} className="grid-wrap">
                <Grid item lg={6} md={6}>
                  <CustomizedSelect
                    required
                    placeholder={defaultValueForService.label}
                    options={entityServiceOptionsList}
                    isSearchable={false}
                    displayLabel={I18n('DecPack.TypeOfService')}
                    handleChange={(newValue: BaseModel) => {
                      const transitDestination =
                        newValue.value === String(TypeOfService.Shop) ||
                        newValue.value === String(TypeOfService.NonDGDOrder)
                          ? defaultValueForService
                          : values.TransitDestination;
                      setValues({
                        ...values,
                        TypeOfService: newValue,
                        TransitDestination: transitDestination,
                      });
                      if (newValue.value !== '0') {
                        setErrors({
                          ...errors,
                          TypeOfService: '',
                        });
                      }
                    }}
                    errorValue={errors.TypeOfService}
                    errorMessage={errors.TypeOfService}
                  ></CustomizedSelect>
                </Grid>
                <Grid item lg={6} md={6}>
                  <CustomDatePicker
                    handleChange={(newValue: any) => {
                      setDate(
                        newValue != null && moment(newValue).isValid()
                          ? newValue.toISOString()
                          : newValue
                      );
                      setStartValue(
                        newValue != null && moment(newValue).isValid()
                          ? newValue.toISOString()
                          : newValue
                      );
                      setEndValue(
                        newValue != null && moment(newValue).isValid()
                          ? newValue.toISOString()
                          : newValue
                      );
                    }}
                    displayLabel={I18n('DecPack.ExecutionDate')}
                    value={date}
                    id="ExecutionDate"
                    name="ExecutionDate"
                    required={true}
                    errorValue={errors.ExecutionDate}
                    errorMessage={errors.ExecutionDate}
                  />
                </Grid>
                <Grid item lg={12} md={12} className="dp-radioBtn-wrap">
                  <InputLabel shrink htmlFor="bootstrap-input">
                    {I18n('DecPack.SelectExecutionTime')}
                  </InputLabel>
                  <RadioGroupInput
                    name="executionTime"
                    options={executionTimeList}
                    handleChange={(event: BaseSyntheticEvent) => {
                      setValues({
                        ...values,
                        executionTime: event.target.value,
                      });
                      setStartValue('');
                      setEndValue('');
                    }}
                    errorValue={true}
                    errorMessage={errors.ExecutionTime}
                  ></RadioGroupInput>
                </Grid>
                {values.executionTime == ExecutionTime.SpecificTime ? (
                  <Grid item lg={12} md={12}>
                    <Grid container spacing={2} className="grid-wrap">
                      <Grid item lg={6} md={6}>
                        <TimePickerInput
                          displayLabel={i18nMessages.StartExecutionTime}
                          name="StartTime"
                          value={startvalue}
                          handleChange={(newValue: any) => {
                            setStartValue(
                              newValue != null &&
                                moment(newValue.toDate()).isValid()
                                ? moment(newValue.toDate())
                                : newValue
                            );
                          }}
                          required={true}
                          errorValue={errors.StartTime}
                          errorMessage={errors.StartTime}
                        ></TimePickerInput>
                      </Grid>
                      <Grid item lg={6} md={6}>
                        <TimePickerInput
                          displayLabel={i18nMessages.EndExecutionTime}
                          name="EndTime"
                          value={endvalue}
                          handleChange={(newValue: any) => {
                            setEndValue(
                              newValue != null &&
                                moment(newValue.toDate()).isValid()
                                ? moment(newValue.toDate())
                                : newValue
                            );
                          }}
                          required={true}
                          errorValue={errors.EndTime}
                          errorMessage={errors.EndTime}
                        ></TimePickerInput>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <></>
                )}
                <Grid item lg={6} md={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="HighPriority"
                        onChange={(event: any) =>
                          setValues({
                            ...values,
                            HighPriority: event.target.checked,
                          })
                        }
                        checked={values.HighPriority}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label={I18n('DecPack.HighPriority')}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item lg={6} md={6}>
            <div className="inner-section">
              <Typography variant="h5" className="inner-heading">
                {I18n('DecPack.CustomerDetails')}
              </Typography>
              <Grid container spacing={2} className="grid-wrap">
                <Grid item lg={6} md={6}>
                  <div className="DPCustomerDetails">
                    <CustomSelectPaginate
                      additional={defaultAdditional}
                      value={values.customer}
                      loadOptions={loadCustomerOptions}
                      handleChange={async (newValue: BaseModel) => {
                        let customerData = await getCustomerData(
                          newValue.value
                        );
                        setValues({
                          ...values,
                          customer: newValue,
                          deliveryLocation: defaultValueForService,
                          deliveryCustomerAddress: defaultAddressSegments,
                          customerLocation: {
                            label: customerData?.data?.address
                              ? customerData.data.address
                              : '',
                            value: customerData?.data?.id
                              ? customerData.data.id.toString()
                              : '',
                          } as BaseModel,
                          customerAddress: {
                            country: customerData?.data.country
                              ? customerData?.data.country
                              : '',
                            city: customerData?.data.city
                              ? customerData?.data.city
                              : '',
                            postalCode: customerData?.data.zipCode
                              ? customerData?.data.zipCode
                              : '',
                            address: customerData?.data?.address
                              ? customerData.data.address
                              : '',
                            address2: customerData?.data?.address2
                              ? customerData.data.address2
                              : '',
                            street: customerData?.data?.street
                              ? customerData.data.street
                              : '',
                          },
                          selectedContacts: [],
                        });
                        const increaseUniq = (uniq: any) => uniq + 1;
                        setCacheUniqContact(increaseUniq);
                        setCacheUniq(increaseUniq);
                        if (newValue.value !== '0') {
                          setErrors({
                            ...errors,
                            Customer: '',
                          });
                        }
                      }}
                      placeholder={defaultCustomerValue.label}
                      isSearchable={true}
                      displayLabel={I18n('DecPack.Customer')}
                      debounceTimeout={500}
                      isMultiSelect={false}
                      required
                      errorValue={errors.Customer}
                      errorMessage={errors.Customer}
                    />
                    {Number(values.customer.value) > 0 &&
                      hasPermission(Permission.canViewCustomer) && (
                        <Tooltip title={i18nMessages.viewCustomer}>
                          <FontAwesomeIcon
                            icon={faInfoCircle}
                            onClick={onCustomerDetails}
                            className={`fa-md icon-edit`}
                          />
                        </Tooltip>
                      )}
                  </div>
                </Grid>
                <Grid item lg={6} md={6}>
                  <FormControl
                    margin="normal"
                    displayLabel={I18n('DecPack.CustomerReference')}
                    id="CustomerReference"
                    name="CustomerReference"
                    inputType="text"
                    inputProps={{ maxLength: 30 }}
                    multiline={false}
                    handleChange={onValueChange}
                    errorValue={errors.CustomerReference}
                    errorMessage={errors.CustomerReference}
                  ></FormControl>
                </Grid>
                <Grid item lg={12} md={12}>
                  <div className="DPCustomerContact">
                    <CustomSelectPaginateAdd
                      value={values.selectedContacts}
                      key={cacheUniqContact + '_cc'}
                      additional={defaultAdditional}
                      loadOptions={(
                        search: string,
                        prevOptions: any,
                        page: any
                      ) =>
                        loadRemainingContactOptions(
                          values.customer && +values.customer.value
                            ? +values.customer.value
                            : 0,
                          search,
                          prevOptions,
                          page,
                          id ? id?.toString() : ''
                        )
                      }
                      handleChange={(newValue: any) => {
                        setValues({ ...values, selectedContacts: newValue });
                      }}
                      id="selectedContacts"
                      placeholder={defaultValueForService.label}
                      isSearchable={true}
                      displayLabel={I18n('ShippingOrders.Contact')}
                      required
                      errorValue={true}
                      errorMessage={errors.selectedContacts}
                      debounceTimeout={500}
                      isMultiSelect={true}
                      cacheUniq={cacheUniqContact}
                      onCreateOption={onCreateContact}
                    />
                  </div>
                </Grid>

                <Grid item lg={6} md={6}>
                  <FormControl
                    margin="normal"
                    displayLabel={I18n('DecPack.Shipper')}
                    id="Shipper"
                    name="Shipper"
                    inputType="text"
                    inputProps={{ maxLength: 30 }}
                    multiline={false}
                    handleChange={onValueChange}
                    errorValue={errors.Shipper}
                    errorMessage={errors.Shipper}
                  ></FormControl>
                </Grid>
                <Grid item lg={6} md={6}>
                  <CustomizedSelect
                    required
                    value={values.Location}
                    options={entityLocationOptionsList}
                    isSearchable={false}
                    displayLabel={I18n('DecPack.Location')}
                    handleChange={async (newValue: BaseModel) => {
                      if (newValue.value !== '0') {
                        setErrors({
                          ...errors,
                          Location: '',
                        });
                      }
                      if (
                        newValue.label === 'Ground Handler' ||
                        newValue.label === 'WareHouse'
                      ) {
                        setValues({
                          ...values,
                          customerLocation: defaultValueForService,
                          customerAddress: defaultAddressSegments,
                          Location: newValue,
                        });
                      } else {
                        if (values.customer.value !== '0') {
                          let customerData = await getCustomerData(
                            values.customer.value
                          );
                          setValues({
                            ...values,
                            customerLocation: {
                              label: customerData?.data?.address
                                ? customerData.data.address
                                : '',
                              value: customerData?.data?.id
                                ? customerData.data.id.toString()
                                : '',
                            } as BaseModel,
                            customerAddress: {
                              country: customerData?.data.country
                                ? customerData?.data.country
                                : '',
                              city: customerData?.data.city
                                ? customerData?.data.city
                                : '',
                              postalCode: customerData?.data.zipCode
                                ? customerData?.data.zipCode
                                : '',
                              address: customerData?.data?.address
                                ? customerData.data.address
                                : '',
                              address2: customerData?.data?.address2
                                ? customerData.data.address2
                                : '',
                              street: customerData?.data?.street
                                ? customerData.data.street
                                : '',
                            },
                            Location: newValue,
                          });
                        } else {
                          setValues({ ...values, Location: newValue });
                        }
                      }
                    }}
                    errorValue={errors.Location}
                    errorMessage={errors.Location}
                  ></CustomizedSelect>
                </Grid>
                <>
                  {values.Location.label === 'Ground Handler' ? (
                    <Grid item lg={6} md={6}>
                      <FormControl
                        margin="normal"
                        displayLabel={I18n('DecPack.Interventie')}
                        id="interventie"
                        name="Interventie"
                        inputType="text"
                        inputProps={{ maxLength: 30 }}
                        multiline={false}
                        handleChange={onValueChange}
                        required={true}
                        errorValue={true}
                        errorMessage={errors.Interventie}
                      ></FormControl>
                    </Grid>
                  ) : (
                    ''
                  )}
                  {values.Location.label === 'Customer' ? (
                    <>
                      <Grid item lg={12} md={12}>
                        <CustomSelectPaginateAdd
                          key={JSON.stringify(values.customerLocation)}
                          additional={defaultAdditional}
                          value={values.customerLocation}
                          handleChange={async (newValue: any) => {
                            let location = await getLocationById(
                              Number(newValue.value)
                            );
                            setValues({
                              ...values,
                              customerLocation: newValue,
                              customerAddress: {
                                country: location?.data.country
                                  ? location?.data.country
                                  : '',
                                city: location?.data.city
                                  ? location?.data.city
                                  : '',
                                postalCode: location?.data.zipCode
                                  ? location?.data.zipCode
                                  : '',
                                address: location?.data?.address
                                  ? location.data.address
                                  : '',
                                address2: location?.data?.address2
                                  ? location.data.address2
                                  : '',
                                street: location?.data?.street
                                  ? location.data.street
                                  : '',
                              },
                            });
                          }}
                          loadOptions={(
                            search: string,
                            prevOptions: any,
                            page: any
                          ) =>
                            loadLocationOptions(
                              values.customer && +values.customer.value
                                ? +values.customer.value
                                : 0,
                              search,
                              prevOptions,
                              page
                            )
                          }
                          isSearchable={true}
                          displayLabel={I18n('CustomerLocation.Address')}
                          errorValue={true}
                          errorMessage={errors.customerLocation}
                          debounceTimeout={500}
                          isMultiSelect={false}
                          cacheUniqs={cacheUniq}
                          onCreateOption={onCreateCustomerLocation}
                        />
                      </Grid>
                      <Grid item lg={6} md={6}>
                        <FormControl
                          margin="normal"
                          value={values.customerAddress.postalCode}
                          displayLabel={i18nMessages.ZipCode}
                          id="zipCode"
                          name="zipCode"
                          inputType="text"
                          inputProps={{ maxLength: 30 }}
                          multiline={false}
                          disabled
                        ></FormControl>
                      </Grid>
                      <Grid item lg={6} md={6}>
                        <FormControl
                          margin="normal"
                          value={values.customerAddress.city}
                          displayLabel={i18nMessages.City}
                          id="city"
                          name="city"
                          inputType="text"
                          inputProps={{ maxLength: 30 }}
                          multiline={false}
                          disabled
                        ></FormControl>
                      </Grid>
                      <Grid item lg={6} md={6}>
                        <FormControl
                          margin="normal"
                          value={values.customerAddress.country}
                          displayLabel={i18nMessages.Country}
                          id="country"
                          name="country"
                          inputType="text"
                          inputProps={{ maxLength: 30 }}
                          multiline={false}
                          disabled
                        ></FormControl>
                      </Grid>
                    </>
                  ) : (
                    ''
                  )}
                </>
              </Grid>
            </div>
          </Grid>
          <Grid item lg={12} md={12}>
            <div className="inner-section">
              <Typography variant="h5" className="inner-heading">
                {i18nMessages.GeneralDetails}
              </Typography>
              <Grid container spacing={2} className="grid-wrap">
                {values.TypeOfService.value === '1' ||
                values.TypeOfService.value === '2' ? (
                  <>
                    <Grid item lg={4} md={4}>
                      <CustomSelectPaginateAdd
                        key={JSON.stringify(values.Airline)}
                        additional={defaultAdditional}
                        value={values.Airline}
                        handleChange={(newValue: any) => {
                          setValues({ ...values, Airline: newValue });
                          if (newValue.value !== '0') {
                            setErrors({
                              ...errors,
                              Airline: '',
                            });
                          }
                        }}
                        loadOptions={(
                          search: string,
                          prevOptions: any,
                          page: any
                        ) => loadAirlineOptions(search, prevOptions, page)}
                        isSearchable={true}
                        displayLabel={i18nMessages.AirLine}
                        debounceTimeout={500}
                        isMultiSelect={false}
                        cacheUniqs={cacheUniq}
                        onCreateOption={onCreateOption}
                      ></CustomSelectPaginateAdd>
                    </Grid>
                    <Grid item lg={4} md={4}>
                      {
                        <CustomSelectPaginate
                          key={JSON.stringify(values.FinalDestination)}
                          additional={defaultAdditional}
                          value={values.FinalDestination}
                          loadOptions={loadCountryOptions}
                          handleChange={(newValue: BaseModel) => {
                            setValues({
                              ...values,
                              FinalDestination: newValue,
                            });
                            if (newValue.value !== '0') {
                              setErrors({
                                ...errors,
                                FinalDestination: '',
                              });
                            }
                          }}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base: any) => ({
                              ...base,
                              zIndex: 9999,
                            }),
                          }}
                          isSearchable={true}
                          displayLabel={i18nMessages.FinalDestination}
                          readOnly={false}
                          debounceTimeout={500}
                          isMultiSelect={false}
                          errorValue={errors.FinalDestination}
                          errorMessage={errors.FinalDestination}
                          required
                        ></CustomSelectPaginate>
                      }
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
                {values.TypeOfService.value !== String(TypeOfService.Shop) &&
                values.TypeOfService.value !==
                  String(TypeOfService.NonDGDOrder) ? (
                  <Grid item lg={4} md={4}>
                    <CustomSelectPaginate
                      key={JSON.stringify(values.TransitDestination)}
                      additional={defaultAdditional}
                      value={values.TransitDestination}
                      loadOptions={loadCountryOptions}
                      handleChange={(newValue: BaseModel) =>
                        setValues({ ...values, TransitDestination: newValue })
                      }
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                      }}
                      isSearchable={true}
                      displayLabel={i18nMessages.TransitDestination}
                      readOnly={false}
                      debounceTimeout={500}
                      isMultiSelect={false}
                    ></CustomSelectPaginate>
                  </Grid>
                ) : (
                  <></>
                )}
                <Grid item lg={4} md={4}>
                  <NumericInput
                    displayLabel={i18nMessages.EstimatedDuration}
                    id="EstimatedDuration"
                    name="EstimatedDuration"
                    inputProps={{ maxLength: 30 }}
                    multiline={false}
                    handleChange={onValueChange}
                    allowNegative={false}
                  />
                </Grid>
                <Grid item lg={8} md={8}>
                  <FormControl
                    margin="normal"
                    displayLabel={i18nMessages.SpecialInstruction}
                    id="SpecialInstructions"
                    name="SpecialInstructions"
                    inputType="text"
                    inputProps={{ maxLength: 250 }}
                    multiline={false}
                    handleChange={onValueChange}
                  ></FormControl>
                </Grid>
              </Grid>
            </div>
          </Grid>
          {values?.Location?.label === 'WareHouse' ? (
            <Grid item lg={12} md={12}>
              <div className="inner-section">
                <Typography variant="h5" className="inner-heading">
                  {i18nMessages.InboundDetails}
                </Typography>
                <Grid container spacing={2} className="grid-wrap">
                  <Grid item lg={12} md={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="SecuredShipment"
                          onChange={onSecureShipmentChange}
                          checked={values.SecuredShipment}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label={i18nMessages.SecuredShipment}
                    />
                  </Grid>
                  {/* ExpectedInboundDate */}
                  <Grid item lg={4} md={4}>
                    <CustomDatePicker
                      handleChange={(newValue: any) => {
                        setValues({
                          ...values,
                          ExpectedInboundDate:
                            newValue != null && moment(newValue).isValid()
                              ? newValue.toISOString()
                              : newValue,
                        });
                      }}
                      displayLabel={i18nMessages.ExpectedInboundDate}
                      value={values.ExpectedInboundDate}
                      id="ExpectedInboundDate"
                      required={true}
                      errorValue={errors.ExpectedInboundDate}
                      errorMessage={errors.ExpectedInboundDate}
                    />
                  </Grid>
                  {/* Transporter */}
                  <Grid item lg={4} md={4}>
                    <FormControl
                      margin="normal"
                      displayLabel={i18nMessages.Transporter}
                      id="Transporter"
                      name="Transporter"
                      inputType="text"
                      inputProps={{ maxLength: 30 }}
                      multiline={false}
                      required={values.SecuredShipment}
                      handleChange={onValueChange}
                      errorValue={true}
                      errorMessage={errors.Transporter}
                    ></FormControl>
                  </Grid>
                  {/* LicensePlate */}
                  {values.SecuredShipment ? (
                    <Grid item lg={4} md={4}>
                      <FormControl
                        margin="normal"
                        displayLabel={i18nMessages.LicensePlate}
                        id="LicensePlate"
                        name="LicensePlate"
                        inputType="text"
                        inputProps={{ maxLength: 30 }}
                        multiline={false}
                        required={values.SecuredShipment}
                        handleChange={onValueChange}
                        errorValue={true}
                        errorMessage={errors.LicensePlate}
                      ></FormControl>
                    </Grid>
                  ) : (
                    <></>
                  )}
                  {/* DriverName */}
                  {values.SecuredShipment ? (
                    <Grid item lg={4} md={4}>
                      <FormControl
                        margin="normal"
                        displayLabel={i18nMessages.DriverName}
                        id="DriverName"
                        name="DriverName"
                        inputType="text"
                        inputProps={{ maxLength: 30 }}
                        multiline={false}
                        required={values.SecuredShipment}
                        handleChange={onValueChange}
                        errorValue={true}
                        errorMessage={errors.DriverName}
                      ></FormControl>
                    </Grid>
                  ) : (
                    <></>
                  )}
                  {/* NumberofPieces */}
                  <Grid item lg={4} md={4}>
                    <NumericInput
                      displayLabel={i18nMessages.NumberofPieces}
                      id="NumberofPieces"
                      name="NumberofPieces"
                      inputProps={{ maxLength: 30 }}
                      multiline={false}
                      required={values.SecuredShipment}
                      handleChange={onValueChange}
                      allowNegative={false}
                      errorValue={true}
                      errorMessage={errors.NumberofPieces}
                    />
                  </Grid>
                  {/* KC / RA Agent : TODO*/}
                  {values.SecuredShipment ? (
                    <Grid item lg={4} md={4}>
                      <FormControl
                        margin="normal"
                        displayLabel={i18nMessages.KcRaAgent}
                        id="KcRaAgent"
                        name="KcRaAgent"
                        inputType="text"
                        inputProps={{ maxLength: 200 }}
                        multiline={false}
                        required={values.SecuredShipment}
                        handleChange={onValueChange}
                        errorValue={true}
                        errorMessage={errors.KcRaAgent}
                      ></FormControl>
                    </Grid>
                  ) : (
                    <></>
                  )}
                </Grid>
              </div>
              <div className="inner-section">
                <Typography variant="h5" className="inner-heading">
                  {i18nMessages.OutboundDetails}
                </Typography>
                <Grid container spacing={2} className="grid-wrap">
                  {/* PickUpBy */}
                  <Grid item lg={4} md={4}>
                    <FormControl
                      margin="normal"
                      displayLabel={i18nMessages.PickUpBy}
                      id="PickUpBy"
                      name="PickUpBy"
                      inputType="text"
                      inputProps={{ maxLength: 200 }}
                      multiline={false}
                      handleChange={onValueChange}
                    ></FormControl>
                  </Grid>
                  <Grid item lg={4} md={4}>
                    <FormControl
                      margin="normal"
                      id="DeliveryReference"
                      name="DeliveryReference"
                      inputType="text"
                      value={values.DeliveryReference}
                      displayLabel={i18nMessages.DeliveryReference}
                      inputProps={{ maxLength: 200 }}
                      multiline={false}
                      handleChange={onValueChange}
                    ></FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} className="grid-wrap">
                  <Grid item md={4} lg={4}>
                    <FormControl
                      margin="normal"
                      displayLabel={i18nMessages.companyName}
                      id="companyName"
                      name="companyName"
                      inputType="text"
                      inputProps={{ maxLength: 500 }}
                      multiline={false}
                      handleChange={onValueChange}
                      value={values.companyName}
                    ></FormControl>
                  </Grid>
                  <Grid item lg={8} md={8}>
                    <CustomSelectPaginateAdd
                      key={JSON.stringify(values.deliveryLocation)}
                      additional={defaultAdditional}
                      value={values.deliveryLocation}
                      loadOptions={(
                        search: string,
                        prevOptions: any,
                        page: any
                      ) =>
                        loadLocationOptions(
                          values.customer && +values.customer.value
                            ? +values.customer.value
                            : 0,
                          search,
                          prevOptions,
                          page
                        )
                      }
                      handleChange={async (newValue: any) => {
                        let location = await getLocationById(
                          Number(newValue.value)
                        );

                        setValues({
                          ...values,
                          deliveryLocation: newValue,
                          deliveryCustomerAddress: {
                            country: location?.data?.country
                              ? location.data.country
                              : '',
                            city: location?.data?.city
                              ? location.data.city
                              : '',
                            postalCode: location?.data?.zipCode
                              ? location.data.zipCode
                              : '',
                            address: location?.data?.address
                              ? location.data.address
                              : '',
                            address2: location?.data?.address2
                              ? location.data.address2
                              : '',
                            street: location?.data?.street
                              ? location.data.street
                              : '',
                          },
                        });
                      }}
                      placeholder={defaultCustomerValue.label}
                      isSearchable={true}
                      displayLabel={i18nMessages.DeliveryLocation}
                      errorValue={true}
                      errorMessage={errors.deliveryLocation}
                      debounceTimeout={500}
                      isMultiSelect={false}
                      cacheUniqs={cacheUniq}
                      onCreateOption={onCreateDeliveryLocation}
                    />
                  </Grid>
                  <Grid item lg={4} md={4}>
                    <FormControl
                      margin="normal"
                      value={values.deliveryCustomerAddress.postalCode}
                      displayLabel={i18nMessages.ZipCode}
                      id="zipCode"
                      name="zipCode"
                      inputType="text"
                      inputProps={{ maxLength: 30 }}
                      multiline={false}
                      disabled
                    ></FormControl>
                  </Grid>
                  <Grid item lg={4} md={4}>
                    <FormControl
                      margin="normal"
                      value={values.deliveryCustomerAddress.city}
                      displayLabel={i18nMessages.City}
                      id="city"
                      name="city"
                      inputType="text"
                      inputProps={{ maxLength: 30 }}
                      multiline={false}
                      disabled
                    ></FormControl>
                  </Grid>
                  <Grid item lg={4} md={4}>
                    <FormControl
                      margin="normal"
                      value={values.deliveryCustomerAddress.country}
                      displayLabel={i18nMessages.Country}
                      id="country"
                      name="country"
                      inputType="text"
                      inputProps={{ maxLength: 30 }}
                      multiline={false}
                      disabled
                    ></FormControl>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          ) : (
            ''
          )}
        </Grid>
        <div className="btn-wrap form-btn-wrap">
          <Button
            color="neutral"
            disableElevation
            variant="contained"
            onClick={() => navigate(`${RouteEnum.DecPackOrdersList}`)}
          >
            {i18nMessages.Cancel}
          </Button>
          <Button
            type="submit"
            color="primary"
            disableElevation
            variant="contained"
            onClick={() => {
              submit();
            }}
          >
            {i18nMessages.Add}
          </Button>
        </div>
        <AddContactPerson
          isOpen={AddContactModalOpen.isAddModalOpen}
          editData={null}
          entityType={EntityTypeEnum.Customer}
          entityId={AddContactModalOpen.entityId}
          handleClose={handleCloseForAddContact}
          title={i18nMessages.AddContacts}
          refreshData={refreshData}
          OnSave={OnSaveContact}
          triggerFrom={AddContactModalOpen.triggerFrom}
          newContact={AddContactModalOpen.newContact}
        ></AddContactPerson>
        <AddLocation
          isOpen={AddLocationModalOpen.isAddLocationModalOpen}
          entityType={EntityTypeEnum.Customer}
          entityId={AddLocationModalOpen.entityId}
          OnSave={OnSave}
          handleClose={handleCloseForAddLocation}
          title={i18nMessages.CustomerLocation}
          isHidden={true}
          newLocation={AddLocationModalOpen.newLocation}
          triggerFrom={AddLocationModalOpen.triggerFrom}
          newDestination={AddLocationModalOpen.newDestination}
        ></AddLocation>
      </div>
      <AirlinePopup
        isOpen={openAirline}
        handleClose={handleCloseForEditAirline}
        OnSave={handleOnSaveAirline}
        data={rowDataAirline}
        title={I18n('Airline.AddAirline')}
      ></AirlinePopup>
      <ViewCustomerModal
        isOpen={viewCustomerDetails.isShowCustomerDetails}
        entityId={values.customer.value}
        handleClose={handleCloseForShowCustomerDetails}
        title={i18nMessages.viewCustomer}
      />
    </div>
  );
}

export default AddDecpack;
