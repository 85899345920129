import { BaseSyntheticEvent, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import I18n from 'utilities/i18n';
import { Grid } from '@mui/material';
import CustomSelectPaginate from 'common/CustomSelect/CustomSelectPaginate';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { BaseModel, RadioGroupBaseModel } from 'models/pagination-model';
import { toast } from 'react-toastify';
import FormControl from 'common/formControl/formControl';
import DecimalInput from 'common/DecimalInput/DecimalInput';
import {
  ProductCatalogueConsumptionTypeDescription,
  ProductCatalogueAssignmentType,
  ProductConsumptionStateModel,
  ProductConsumptionDto,
} from 'models/product-catalogue';
import RadioGroupInput from 'common/RadioGroup/RadioGroupInput';
import { loadCustomerOptions } from 'components/shipping-orders/ShippingApiService';
import {
  getAvailableWarehouseQuantity,
  loadDeckPackOrdersOptions,
  loadShippingOrdersOptions,
  saveProductConsumption,
} from './ProductConsumptionApiService';

export default function AddProductConsumption(props: any) {
  const defaultCustomerValue = {
    value: '',
    label: I18n('Placeholders.SelectCustomer'),
  } as BaseModel;

  const defaultOrderValue = {
    value: '',
    label: I18n('Placeholders.SelectOrder'),
  } as BaseModel;

  const { isOpen, handleClose, title, entityId, editData } = props;

  const [values, setValues] = useState<ProductConsumptionStateModel>({
    productCatalogueId: entityId,
    date: new Date(),
    quantity: '',
    sellingPrice: '',
    order: defaultOrderValue,
    orderId: '',
    orderType: ProductCatalogueAssignmentType.ShippingOrder,
    orderNumber: '',
    customer: defaultCustomerValue,
    customerId: '',
    customerName: '',
    remarks: '',
    id: 0,
  });

  const [warehouseQuantity, setWarehouseQuantity] = useState(0);

  const showMessageRefresh = () => {
    toast.success(
      values.id
        ? i18nMessages.updatedSuccessfully
        : i18nMessages.savedSuccessfully
    );
    setDefaultValuesForControls();
    props.refreshData();
  };

  const setDefaultValuesForControls = () => {
    setValues({ ...values, customer: defaultCustomerValue });
    setValues({ ...values, order: defaultOrderValue });
    setValues({ ...values, quantity: '' });
    setValues({ ...values, sellingPrice: '' });
    setValues({ ...values, remarks: '' });
    setValues({
      ...values,
      orderType: ProductCatalogueAssignmentType.ShippingOrder,
    });
  };

  const [errors, setErrors] = useState({
    customer: '',
    order: '',
    quantity: '',
    sellingPrice: '',
    remarks: '',
  });

  const i18nMessages = {
    commonFieldIsRequired: I18n('Common.FieldIsRequired'),
    somethingWentWrong: I18n('Common.SomethingWentWrong'),
    commonInvalidQuantity: I18n('Common.EnteredQuantityIsNotValid'),
    updatedSuccessfully: I18n(
      'ProductCatalogues.ProductConsumptions.UpdatedSuccessfully'
    ),
    savedSuccessfully: I18n(
      'ProductCatalogues.ProductConsumptions.SavedSuccessfully'
    ),
  };

  const validate = (fieldValues: any = values) => {
    let temp = { ...errors };
    if ('customer' in fieldValues) {
      temp.customer = fieldValues.customer.value
        ? ''
        : i18nMessages.commonFieldIsRequired;
    }
    if ('orderNumber' in fieldValues) {
      temp.order = fieldValues.order.value
        ? ''
        : i18nMessages.commonFieldIsRequired;
    }
    if ('quantity' in fieldValues) {
      temp.quantity = fieldValues.quantity
        ? ''
        : i18nMessages.commonFieldIsRequired;
    }
    if ('sellingPrice' in fieldValues) {
      temp.sellingPrice = fieldValues.sellingPrice
        ? ''
        : i18nMessages.commonFieldIsRequired;
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === '');
    }
  };

  const submit = async () => {
    if (validate()) {
      try {
        const result = await saveProductConsumption(
          values && values.id ? values.id : 0,
          {
            id: values.id,
            productCatalogueId: +entityId,
            date: values.date,
            quantity: values && values.quantity ? +values.quantity : 0,
            orderId:
              values && values?.order && values?.order.value
                ? +values.order.value
                : 0,
            orderType: +values.orderType,
            customerId:
              values && values?.customer && values?.customer.value
                ? +values.customer.value
                : 0,
            sellingPrice: Number(values.sellingPrice.replace(/,/g, '.')),
            remarks: values.remarks,
            isManual: true,
            customerName: '',
            orderNumber: '',
          } as ProductConsumptionDto
        );

        if (editData == null && result.result && result.data) {
          showMessageRefresh();
        } else if (editData != null && result.result) {
          showMessageRefresh();
        } else {
          toast.error(i18nMessages.somethingWentWrong);
        }
      } catch (error: any) {
        if (error.response) {
          let temp = { ...errors };
          if (error.response.data) {
            for (const item of error.response.data.errors) {
              if (
                item.field === 'Quantity' &&
                item.errorMessage === 'ValidQuantity'
              ) {
                temp.quantity = i18nMessages.commonInvalidQuantity;
              }
            }
          }
          setErrors({
            ...temp,
          });
        }
      }
    }
  };

  const close = () => {
    setDefaultValuesForControls();
    props.handleClose();
  };

  useEffect(() => {
    if (editData) {
      setValues({
        productCatalogueId: editData.productCatalogueId,
        date: new Date(),
        quantity: editData.quantity,
        sellingPrice: editData.sellingPrice.toString().replaceAll('.', ','),
        order: {
          label: editData.orderNumber,
          value: editData.orderId.toString(),
        } as BaseModel,
        orderId: editData.orderId,
        orderNumber: editData.orderNumber,
        customer: {
          label: editData.customerName,
          value: editData.customerId.toString(),
        } as BaseModel,
        orderType: editData.orderType,
        customerId: editData.customerId,
        customerName: editData.customerName,
        remarks: editData.remarks,
        id: editData.id,
      });
    } else {
      setValues({
        productCatalogueId: entityId,
        date: new Date(),
        quantity: '',
        sellingPrice: '',
        order: defaultOrderValue,
        orderType: ProductCatalogueAssignmentType.ShippingOrder,
        orderId: '',
        orderNumber: '',
        customer: defaultCustomerValue,
        customerId: '',
        customerName: '',
        remarks: '',
        id: 0,
      });
    }
    getAvailableWarehouseQuantity(entityId).then((response) => {
      setWarehouseQuantity(response);
    });
  }, [editData]);
  const defaultAdditional: any = {
    page: 1,
  };

  let assignmentTypeList: Array<RadioGroupBaseModel> = [];
  const getAssignmentTypeList = () => {
    assignmentTypeList = [];
    ProductCatalogueConsumptionTypeDescription.forEach(
      (value: string, key: number) => {
        assignmentTypeList.push({
          value: key.toString(),
          label: I18n(value),
        } as RadioGroupBaseModel);
      }
    );
  };
  getAssignmentTypeList();

  const loadShippingOrderDropdwonData = () => {
    return (
      <CustomSelectPaginate
        additional={{
          page: 0,
        }}
        value={values.order}
        loadOptions={loadShippingOrdersOptions}
        handleChange={(newValue: any) =>
          setValues({ ...values, order: newValue })
        }
        placeholder={defaultOrderValue.label}
        isSearchable={true}
        displayLabel={I18n('ProductCatalogues.ProductConsumptions.OrderNumber')}
        errorValue={true}
        errorMessage={errors.order}
        readOnly={editData !== null}
        debounceTimeout={500}
        isMultiSelect={false}
        cacheUniqs={[values.orderType]}
      />
    );
  };

  const loadDeckPackOrderDropdwonData = () => {
    return (
      <CustomSelectPaginate
        additional={{
          page: 0,
        }}
        value={values.order}
        loadOptions={loadDeckPackOrdersOptions}
        handleChange={(newValue: any) =>
          setValues({ ...values, order: newValue })
        }
        placeholder={defaultOrderValue.label}
        isSearchable={true}
        displayLabel={I18n('ProductCatalogues.ProductConsumptions.OrderNumber')}
        errorValue={true}
        errorMessage={errors.order}
        readOnly={editData !== null}
        debounceTimeout={500}
        isMultiSelect={false}
        cacheUniqs={[values.orderType]}
      />
    );
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={'sm'}
        fullWidth={true}
      >
        <DialogTitle>
          {title}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className="grid-wrap two-col">
            <Grid item lg={12} md={12}>
              <DecimalInput
                displayLabel={
                  I18n('ProductCatalogues.ProductPurchases.Quantity') +
                  ' - ' +
                  I18n('ProductCatalogues.InWarehouse')
                }
                name="availablequantity"
                value={warehouseQuantity}
                readOnly={true}
              ></DecimalInput>
            </Grid>
            <Grid item xs={12}>
              <CustomSelectPaginate
                additional={defaultAdditional}
                value={values.customer}
                loadOptions={loadCustomerOptions}
                handleChange={(newValue: any) =>
                  setValues({ ...values, customer: newValue })
                }
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                }}
                placeholder={defaultCustomerValue.label}
                isSearchable={true}
                displayLabel={I18n(
                  'ProductCatalogues.ProductConsumptions.CustomerName'
                )}
                errorValue={true}
                errorMessage={errors.customer}
                readOnly={editData !== null}
                debounceTimeout={500}
                isMultiSelect={false}
              />
            </Grid>

            <Grid item xs={12}>
              <RadioGroupInput
                value={values.orderType}
                handleChange={(event: BaseSyntheticEvent) => {
                  setValues({ ...values, orderType: event.target.value });
                }}
                options={assignmentTypeList}
                readOnly={editData !== null}
              ></RadioGroupInput>
            </Grid>

            <Grid item xs={12}>
              {+values.orderType ===
              ProductCatalogueAssignmentType.ShippingOrder
                ? loadShippingOrderDropdwonData()
                : loadDeckPackOrderDropdwonData()}
            </Grid>
            <Grid item xs={12}>
              <DecimalInput
                displayLabel={I18n(
                  'ProductCatalogues.ProductConsumptions.Quantity'
                )}
                inputProps={{ maxLength: 10 }}
                name="quantity"
                handleChange={(event: any) => {
                  setValues({
                    ...values,
                    quantity: event.target.value,
                  });
                }}
                errorValue={true}
                errorMessage={errors.quantity}
                value={values.quantity}
              ></DecimalInput>
            </Grid>
            <Grid item xs={12}>
              <DecimalInput
                displayLabel={I18n(
                  'ProductCatalogues.ProductConsumptions.SellingPrice'
                )}
                inputProps={{ maxLength: 10 }}
                name="sellingPrice"
                handleChange={(event: any) => {
                  setValues({
                    ...values,
                    sellingPrice: event.target.value,
                  });
                }}
                errorValue={true}
                errorMessage={errors.sellingPrice}
                value={values.sellingPrice}
              ></DecimalInput>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                margin="normal"
                displayLabel={I18n(
                  'ProductCatalogues.ProductConsumptions.Remarks'
                )}
                id="Remarks"
                name="remarks"
                inputType="text"
                inputProps={{ maxLength: 150 }}
                multiline={false}
                handleChange={(event: any) => {
                  setValues({
                    ...values,
                    remarks: event.target.value,
                  });
                }}
                errorValue={true}
                errorMessage={errors.remarks}
                value={values.remarks}
              ></FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="btn-wrap">
          <Button
            onClick={close}
            color="neutral"
            disableElevation
            variant="contained"
          >
            {' '}
            {I18n('Common.Cancel')}
          </Button>
          <Button
            onClick={submit}
            autoFocus
            color="primary"
            disableElevation
            variant="contained"
          >
            {I18n('Common.Save')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
