enum RouteEnum {
  Root = '/',
  Signup = '/signup',
  Authorize = '/Authorize',
  Login = '/login',
  Logout = '/logout',
  ApiSample = '/api-sample',
  UsersList = '/users-list',
  UsersEdit = '/users-edit',
  AddUser = '/users-add',
  UserDetails = '/users-details',
  Home = '/home',
  Link1 = '/link1',
  Link2 = '/link2',
  NotFound = '/not-found',
  ServiceCatalogue = '/service-catalogue',
  OrderType = '/order-type',
  OrderTypeAdd = '/order-type/add',
  OrderTypeEdit = '/order-type/edit',
  ServiceCatalogueAdd = '/service-catalogue/add',
  ViewServiceCatalogue = '/service-catalogue/view',
  ProductCatalogue = '/product-catalogue',
  ProductCatalogueAdd = '/product-catalogue/add',
  ProductCatalogueView = '/product-catalogue/view',
  Location = '/location',
  Document = '/document',
  Customer = '/customer',
  CustomerApproval = '/customer-approval',
  Roles = '/roles',
  EditRole = '/editRole',
  CustomerAdd = '/customer-add',
  AddRole = '/add-role',
  Zones = '/zones',
  Order = '/order',
  AddOrder = '/order/add',
  OrderSetting = '/order-setting',
  ViewProductCatalogue = '/product-catalogue/view',
  PartnerList = '/partners',
  PartnerAdd = '/partner-add',
  OrderEdit = '/order/edit',
  ForgotPassword = '/forgot-password',
  ResetPassword = '/reset-password',
  TariffCustoms = '/tariff-customs',
  AddTariff = '/tariff-customs/add',
  Transport = '/transport-catalogue',
  TransportCatalogueAdd = '/transport-catalogue/add',
  TransportCatalogueView = '/transport-catalogue/view',
  ShippingOrdersList = '/shipping-orders',
  AddShippingOrders = '/shipping-orders/add',
  ViewShippingOrders = '/shipping-orders/view',
  Questionnaire = '/questionnaire',
  QuestionnaireDetail = '/questionnaire/detail',
  WorkorderPickup = '/workorder/pickup',
  WoBooking = '/shipping-orders/booking',
  DecPackOrdersList = '/decpack-orders',
  ViewDecPackOrders = '/decpack-orders/view',
  AddDeckPackOrders = '/decpack-orders/add',
  CreateWarehouseLabel = '/decpack-orders/create-warehouse-label',
  MyProfile = '/myprofile',
  AllDecPackOrdersList = '/all-decpack-orders',
  AllShippingOrders = 'all-shipping-orders',
  DeckPackIrregularityContactClient = 'decpack-irregularity-client',
  Airline = '/airline',
  State = '/state',
  LabelMasterList = '/labels',
  PublicTracking = '/track',
  ExchangeRates = '/exchange-rates',
  ShippingOffersList = '/shipping-offers',
  AddShippingOffers = '/shipping-offers/add',
  ViewShippingOffers = '/shipping-offers/view',
  DecPackOffersList = '/decpack-offers',
  AddDecPackOffers = '/decpack-offers/add',
  ViewDecPackOffers = '/decpack-offers/view',
}

export default RouteEnum;
