import React, { useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormHelperText from '@mui/material/FormHelperText';
import moment from 'moment';
export const CustomDatePicker = (props: any) => {
  const {
    displayLabel,
    handleChange,
    readOnly,
    errorValue,
    errorMessage,
    required = false,
  } = props;
  return (
    <FormControl
      variant="standard"
      fullWidth
      sx={{ mb: 2 }}
      error={errorMessage}
    >
      <InputLabel shrink htmlFor="bootstrap-input">
        {displayLabel}
        <span> {required ? '*' : ''}</span>
      </InputLabel>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          format="DD/MM/YYYY"
          readOnly={readOnly}
          value={props.value ? moment(props.value) : ''}
          onChange={(date, value) => {
            handleChange(date);
          }}
          slotProps={{ textField: { variant: 'outlined' } }}
        />
      </LocalizationProvider>
      {errorValue ? <FormHelperText>{errorMessage}</FormHelperText> : ''}
    </FormControl>
  );
};
export default CustomDatePicker;
