import { useCallback, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import I18n from 'utilities/i18n';
import { BaseModel, OrderByDirection } from 'models/pagination-model';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import CustomDataGrid from 'common/datagrid/datagrid';
import {
  GridColumns,
  GridPaginationInitialState,
  GridSortingInitialState,
  GridSortModel,
} from '@mui/x-data-grid';

export default function DGPlus(props: any) {
  const defaultDropDownValue = {
    value: '0',
    label: I18n('Placeholders.Select'),
  } as BaseModel;
  const { isOpen, handleClose, title, data } = props;
  const [errors, setErrors] = useState<any>({});
  const [gridData, setGridData] = useState({
    isLoading: true,
    sortOrder: OrderByDirection.Descending,
    sortBy: 'HSCODE',
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [10, 20, 50, 100],
    pageSize: 10,
    page: 0,
    searchExpression: '',
  });

  type Row = (typeof gridData.rows)[number];

  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));

  const columns: GridColumns<Row> = [
    {
      field: 'name',
      headerName: I18n('DecPackOrders.DGPlusDetails.Name'),
      flex: 1,
      minWidth: 200,
      sortable: false,
      renderCell: (params) => {
        return (
          <Tooltip title={params.value}>
            <span className="table-cell-truncate">{params.value}</span>
          </Tooltip>
        );
      },
    },
    {
      field: 'un',
      headerName: I18n('DecPackOrders.DGPlusDetails.UN'),
      flex: 1,
      sortable: false,
    },
    {
      field: 'class',
      headerName: I18n('DecPackOrders.DGPlusDetails.Class'),
      flex: 1,
      sortable: false,
    },
    {
      field: 'subRisk',
      headerName: I18n('DecPackOrders.DGPlusDetails.Subrisk'),
      flex: 1,
      sortable: false,
    },
    {
      field: 'pg',
      headerName: I18n('DecPackOrders.DGPlusDetails.Packinggroup'),
      flex: 1,
      sortable: false,
    },
    {
      field: 'exceptedquantities',
      headerName: I18n('DecPackOrders.DGPlusDetails.Exceptedquantities'),
      flex: 1,
      sortable: false,
    },
    {
      field: 'paxpackinginstruction',
      headerName: I18n('DecPackOrders.DGPlusDetails.Paxpackinginstruction'),
      flex: 1,
      sortable: false,
    },
    {
      field: 'paxmaxquantity',
      headerName: I18n('DecPackOrders.DGPlusDetails.Paxmaxquantity'),
      flex: 1,
      sortable: false,
    },
    {
      field: 'caopackinginstruction',
      headerName: I18n('DecPackOrders.DGPlusDetails.Caopackinginstruction'),
      flex: 1,
      sortable: false,
    },
    {
      field: 'caomaxquantity',
      headerName: I18n('DecPackOrders.DGPlusDetails.Caomaxquantity'),
      flex: 1,
      sortable: false,
    },
  ];

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    if (sortModel && sortModel.length) {
      updateGridData('sortBy', sortModel[0].field);
      updateGridData(
        'sortOrder',
        sortModel[0].sort === 'asc'
          ? OrderByDirection.Ascending
          : OrderByDirection.Descending
      );
    }
  }, []);

  const handleChangePage = (pageNo: number) => {
    updateGridData('page', pageNo);
  };

  const handleChangePageSize = (pageSize: number) => {
    updateGridData('pageSize', pageSize);
  };

  const handleCloseForm = () => {
    props.handleClose();
  };

  useEffect(() => {
    if (data.data) {
      updateGridData('totalRows', data.data.length);
      updateGridData('rows', data.data);
    }
  }, [data]);

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleCloseForm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={'lg'}
        fullWidth={true}
      >
        <DialogTitle>
          {title}
          <IconButton
            aria-label="close"
            onClick={handleCloseForm}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className="content-block table-fix">
            <CustomDataGrid
              rowCount={gridData.totalRows}
              data={gridData.rows}
              columns={columns}
              pageNo={gridData.page}
              pageSize={gridData.pageSize}
              onSortModelChange={handleSortModelChange}
              onPageChange={handleChangePage}
              onPageSizeChange={handleChangePageSize}
              rowsPerPageOptions={gridData.rowsPerPageOptions}
              paginationMode={'client'}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'hscode', sort: 'desc' }],
                } as GridSortingInitialState,
                pagination: {
                  page: gridData.page,
                  pageSize: gridData.pageSize,
                } as GridPaginationInitialState,
              }}
            ></CustomDataGrid>
          </div>
        </DialogContent>
        <DialogActions className="btn-wrap">
          <Button
            onClick={handleCloseForm}
            color="neutral"
            disableElevation
            variant="contained"
          >
            {' '}
            {I18n('Common.Cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
