import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import CustomizedInputs from 'common/formControl/formControl';
import CustomizedPhone from 'common/formControl/formPhone';
import Grid from '@mui/material/Grid';

import FormControl from 'common/formControl/formControl';

import environment from 'environment';
import I18n from 'utilities/i18n';
import {
  ApprovalStatus,
  BaseModel,
  CustomerTagsDescription,
  EntityStatus,
  EntityStatusDescription,
  OrderByDirection,
  PaginationResponseModel,
  PartnerType,
  PartnerTypeDescription,
  SystemAccessDescription,
} from 'models/pagination-model';
import http from '../../utilities/httpService';
import FormAccordian from 'common/formControl/formAccordian';
import CustomizedSelect from 'common/CustomSelect/CustomSelect';
import FileUpload from 'common/fileupload/fileupload';
import { Button, Checkbox, FormControlLabel } from '@mui/material';
import RouteEnum from 'models/RouteEnum';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import AddressAutocompleteInputs from 'common/AddressAutocomplete/AddressAutocompleteInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import AddCity from 'common/Cities/AddCity';
import CustomSelectPaginateAdd from 'common/CustomSelect/CustomSelectPaginateAdd';
import {
  PostCityDropDown,
  PostCountryDropDown,
  GetTransportTypeOption,
} from 'common/DropDownAddAPI/DropDownApiService';
import { Customer } from 'models/service-catalogue';
import { ZipCodeTriggeredFrom } from 'models/location';

function AddPartnerDetails(props: any) {
  const i18nMessages = {
    country: I18n('Partner.Country'),
    city: I18n('Partner.City'),
    address: I18n('Partner.SearchAddress'),
    postalCode: I18n('Partner.PostalCode'),
    save: I18n('Common.Save'),
    cancel: I18n('Common.Cancel'),
    invoiceEmail: I18n('Partner.InvoiceEmail'),
    isDifferent: I18n('Partner.IsDifferent'),
    invoicing: I18n('Partner.Invoicing'),
    commonFieldIsRequired: I18n('Common.FieldIsRequired'),
    postalCodeMaxLength: I18n('Partner.Validation.PostalCodeMaxLength'),
    invoiceAddressMaxLength: I18n('Partner.Validation.InvoiceAddressMaxLength'),
    invoicePostalCodeMaxLength: I18n(
      'Partner.Validation.InvoicePostalCodeMaxLength'
    ),
    AddCity: I18n('Cities.AddDialogTitle'),
    Select: I18n('Placeholders.Select'),
    UniqueName: I18n('Partner.Validation.UniqueName'),
    CommonSaveSuccessMsg: I18n('Common.SavedSuccessfully'),
    CommonErrOccuredMsg: I18n('Common.ErrorOccurred'),
    Address2: I18n('CustomerLocation.Address2'),
    Street: I18n('CustomerLocation.Street'),
    commonInvalidEmail: I18n('Common.InvalidEmail'),
    CityCommonSaveSuccessMsg: I18n('Common.CitySavedSuccessfully'),
  };
  const defaultAdditional: any = {
    page: 1,
  };
  const [cacheUniqCountry, setCacheUniqCountry] = useState(0);
  const [cacheUniq, setCacheUniq] = useState(0);
  const handleListClick = () => {
    navigate(RouteEnum.PartnerList);
  };
  const breadcrumbs = [
    <Typography key="3" color="text.primary">
      {I18n('Nav.Reference')}
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={handleListClick}
        className="cpointer"
      >
        {I18n('Nav.Partners')}
      </Link>
    </Typography>,
    <Typography key="3" color="text.primary">
      {I18n('Partner.AddPartner')}
    </Typography>,
  ];
  const defaultValue = {
    value: '0',
    label: I18n('Placeholders.Select'),
  } as BaseModel;
  const defaultPartnerTypeValue = {
    value: '0',
    label: I18n('Placeholders.SelectTracking'),
  } as BaseModel;
  const defaultTransportTypeValue = {
    value: '0',
    label: I18n('Placeholders.SelectPartnerType'),
  } as BaseModel;
  const defaultValueForStatus = {
    value: EntityStatus.Active.toString(),
    label: I18n('Common.Active'),
  } as BaseModel;

  const [cityList, setCityList] = useState<BaseModel[]>([]);
  const [countryList, setCountryList] = useState<BaseModel[]>([]);
  const [transportTypeList, setTransportTypeList] = useState<BaseModel[]>([]);
  const [files, setFiles] = useState<any>([]);
  const [values, setValues] = useState({
    transportType: defaultTransportTypeValue,
    transportTypeId: defaultTransportTypeValue.value,
    type: defaultPartnerTypeValue,
    logo: '',
    supplierName: '',
    address: '',
    address2: '',
    street: '',
    country: defaultValue,
    city: defaultValue,
    postalCode: '',
    website: '',
    generalTelephone: '',
    generalEmail: '',
    isDifferent: false,
    invoiceAddress: '',
    invoiceAddress2: '',
    invoiceStreet: '',
    invoiceCountry: defaultValue,
    invoiceCity: defaultValue,
    invoicePostalCode: '',
    invoiceEmail: '',
    VATNumber: '',
    EORI: '',
    sameVat: false,
    isDebtor: false,
    status: defaultValueForStatus,
    tag: defaultValue,
    adminEmail: '',
  });

  useState<boolean>(true);

  let entityStatusList: Array<BaseModel> = [];
  const getStatusList = () => {
    EntityStatusDescription.forEach((value: string, key: number) => {
      entityStatusList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };

  let customerTagsList: Array<BaseModel> = [];
  const getCustomerTagsList = () => {
    customerTagsList = [defaultValue];
    CustomerTagsDescription.forEach((value: string, key: number) => {
      customerTagsList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };

  let partnerTypeList: Array<BaseModel> = [];
  const getPartnerTypeList = () => {
    customerTagsList = [defaultPartnerTypeValue];
    PartnerTypeDescription.forEach((value: string, key: number) => {
      partnerTypeList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };

  let debtList: Array<BaseModel> = [];
  const getDebtList = () => {
    SystemAccessDescription.forEach((value: string, key: number) => {
      debtList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };

  const getCountries = async (search: string = '', pageNo: number = 1) => {
    let response: PaginationResponseModel<Customer>;
    const apiUrl = new URL(environment.api.baseUrl + 'Country');
    try {
      let searchExpression = '';
      searchExpression += search ? `name~like~${search}` : '';
      apiUrl.searchParams.set('$filter', searchExpression);
      apiUrl.searchParams.set('$orderby', 'name');
      apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
      apiUrl.searchParams.set('$top', '1000');
      apiUrl.searchParams.set('$orderbydirection', 'desc');
      const result = await http.get(apiUrl.toString());
      if (result) {
        response = {
          TotalRecords: result.data.totalCount,
          Values: result.data.values,
        } as PaginationResponseModel<Customer>;

        let options = result.data.values.map((x: any) => {
          return {
            value: x.id.toString(),
            label: x.name,
          } as BaseModel;
        });
        setCountryList(options);
      } else {
        response = {
          TotalRecords: 0,
          Values: [],
        } as PaginationResponseModel<Customer>;
      }
    } catch (error) {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<Customer>;
    }
    return response;
  };

  let cityLst: Array<BaseModel> = [];
  const getCityList = (cities: any[]) => {
    cityLst = [defaultValue];
    cities.forEach((city: any) => {
      cityLst.push({
        value: city.key,
        label: city.value,
      } as BaseModel);
    });

    return cityLst;
  };

  const getCities = async (search: string = '', pageNo: number = 1) => {
    let cityLst: any[] = [];
    let response: PaginationResponseModel<BaseModel>;
    const apiUrl = new URL(environment.api.baseUrl + 'Cities');
    try {
      if (search && search !== undefined) {
        var searchExpression = '';
        searchExpression += search ? `name~like~${search}` : '';
        apiUrl.searchParams.set('$filter', searchExpression);
      }
      apiUrl.searchParams.set('$orderby', 'name');
      if (pageNo > 1) {
        apiUrl.searchParams.set('$skip', ((pageNo - 1) * 500).toString());
        apiUrl.searchParams.set('$top', '500');
      } else {
        apiUrl.searchParams.set('$top', '10000');
        apiUrl.searchParams.set('$skip', '0');
      }
      apiUrl.searchParams.set(
        '$orderbydirection',
        OrderByDirection.Ascending.toString()
      );

      const result = await http.get(apiUrl.toString());
      if (result) {
        result.data.values.map(function (item: any) {
          cityLst.push({
            key: item['id'],
            value: item['name'],
          });
        });

        let data = getCityList(cityLst);
        setCityList(data);

        response = {
          TotalRecords: result.data.totalCount,
          Values: data,
        } as PaginationResponseModel<BaseModel>;
      } else {
        response = {
          TotalRecords: 0,
          Values: [],
        } as PaginationResponseModel<BaseModel>;
      }
    } catch (error) {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<BaseModel>;
    }
    return response;
  };

  const getTransportTypeDropDown = async () => {
    const response = await GetTransportTypeOption();
    var options = response.Values.map((x: any) => {
      return {
        value: x.id.toString(),
        label: x.transportType,
      } as BaseModel;
    });
    setTransportTypeList(options);
  };

  const params = useParams();

  let navigate = useNavigate();

  const phoneOnChange = (phone: any) => {
    const phoneNum: string = phone.replace(/[^\d\+]/g, '');
    setValues({ ...values, generalTelephone: phoneNum });
  };

  const onSameAsVaTChange = (event: any) => {
    oncheckboxchange(event);
    if (values.VATNumber && event.target.checked === true) {
      setValues({
        ...values,
        EORI: values.VATNumber,
        sameVat: true,
      });
    } else {
      setValues({
        ...values,
        EORI: '',
        sameVat: false,
      });
    }
  };
  const oncheckboxchange = (event: any) => {
    const { name, checked } = event.target;
    setValues({
      ...values,
      [name.toString()]: checked,
    });
  };

  const UploadFile = async (file: any) => {
    if (file && file.length > 0) {
      const apiUrl = new URL(environment.api.baseUrl + 'Documents/3/upload');
      const data = new FormData();
      data.append('file', file[0]);
      const response = await http.post(apiUrl.toString(), data);
      return response.data;
    }
  };

  const handleSaveUpload = (files: any) => {
    if (files && files.length > 0) {
      setFiles(files);
    }
  };

  const handleFileDelete = (file: any) => {
    var a = files.filter(function (x: any) {
      return x.lastModified !== file.lastModified;
    });

    setFiles(a);
  };
  const [errors, setErrors] = useState({
    name: '',
    email: '',
    adminEmail: '',
    type: '',
    transportType: '',
    address2: '',
    street: '',
    country: '',
    city: '',
    postalCode: '',
    invoiceEmail: '',
    invoiceAddress: '',
    invoiceStreet: '',
    invoicePostalCode: '',
    invoiceCountry: '',
    invoiceCity: '',
    vatNumber: '',
  });

  const validate = (fieldValues: any = values) => {
    let temp: any = {};
    if ('type' in fieldValues) {
      temp.type = +fieldValues.type.value
        ? ''
        : i18nMessages.commonFieldIsRequired;
    }
    if ('transportType' in fieldValues) {
      temp.transportType = +fieldValues.transportType.value
        ? ''
        : i18nMessages.commonFieldIsRequired;
    }
    if ('supplierName' in fieldValues) {
      temp.name =
        fieldValues.supplierName && fieldValues.supplierName.trim()
          ? ''
          : i18nMessages.commonFieldIsRequired;
    }
    if ('generalEmail' in fieldValues) {
      temp.email = /$^|.+@.+..+/.test(fieldValues.generalEmail)
        ? ''
        : i18nMessages.commonInvalidEmail;
    }
    if ('invoiceEmail' in fieldValues) {
      temp.invoiceEmail = /$^|.+@.+..+/.test(fieldValues.invoiceEmail)
        ? ''
        : i18nMessages.commonInvalidEmail;
    }
    if ('adminEmail' in fieldValues) {
      temp.invoiceEmail = /$^|.+@.+..+/.test(fieldValues.adminEmail)
        ? ''
        : i18nMessages.commonInvalidEmail;
    }
    if ('street' in fieldValues) {
      temp.street =
        fieldValues.street && fieldValues.street.trim()
          ? ''
          : i18nMessages.commonFieldIsRequired;
    }
    if ('country' in fieldValues) {
      temp.country =
        fieldValues.country.value === defaultValue.value
          ? i18nMessages.commonFieldIsRequired
          : '';
    }
    if ('city' in fieldValues) {
      temp.city =
        fieldValues.city.value === defaultValue.value
          ? i18nMessages.commonFieldIsRequired
          : '';
    }
    if ('postalCode' in fieldValues) {
      temp.postalCode =
        fieldValues.postalCode && fieldValues.postalCode.trim()
          ? fieldValues.postalCode.length > 20
            ? i18nMessages.postalCodeMaxLength
            : ''
          : i18nMessages.commonFieldIsRequired;
    }

    if ('isDifferent' in fieldValues && fieldValues.isDifferent) {
      if ('invoiceStreet' in fieldValues) {
        temp.invoiceStreet =
          fieldValues.invoiceStreet && fieldValues.invoiceStreet.trim()
            ? ''
            : i18nMessages.commonFieldIsRequired;
      }
      if ('invoiceCountry' in fieldValues) {
        temp.invoiceCountry =
          fieldValues.invoiceCountry.value === defaultValue.value
            ? i18nMessages.commonFieldIsRequired
            : '';
      }
      if ('invoiceCity' in fieldValues) {
        temp.invoiceCity =
          fieldValues.invoiceCity.value === defaultValue.value
            ? i18nMessages.commonFieldIsRequired
            : '';
      }
      if ('invoicePostalCode' in fieldValues) {
        temp.invoicePostalCode =
          fieldValues.invoicePostalCode && fieldValues.invoicePostalCode.trim()
            ? fieldValues.invoicePostalCode.length > 20
              ? i18nMessages.invoicePostalCodeMaxLength
              : ''
            : i18nMessages.commonFieldIsRequired;
      }
    }
    if ('VATNumber' in fieldValues) {
      temp.vatNumber =
        fieldValues.VATNumber && fieldValues.VATNumber.trim()
          ? ''
          : i18nMessages.commonFieldIsRequired;
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === '');
    }
  };

  const handleSubmit = async () => {
    if (validate()) {
      var filePath = '';
      if (files.length > 0) {
        filePath = await UploadFile(files);
      }
      const apiUrl = new URL(environment.api.baseUrl + 'Partners');
      try {
        const Partner = {
          PartnerType:
            values.type.value === defaultPartnerTypeValue.value
              ? null
              : +values.type.value,
          TransportTypeId:
            values.transportType.value === defaultTransportTypeValue.value
              ? null
              : +values.transportType.value,
          Name: values.supplierName,
          Address: values.address,
          Address2: values.address2,
          Street: values.street,
          PostalCode: values.postalCode,
          CityId: values.city.value === '0' ? null : +values.city.value,
          CountryId:
            values.country.value === '0' ? null : +values.country.value,
          WebSite: values.website,
          Logo: filePath,
          VatNumber: values.VATNumber,
          EORI: values.EORI,
          IsDebtor: values.isDebtor,
          Telephone: values.generalTelephone,
          Email: values.generalEmail,
          IsInvoiceAddress: values.isDifferent,
          InvoiceAddress: values.isDifferent
            ? values.invoiceAddress
            : values.address,
          InvoiceAddress2: values.isDifferent
            ? values.invoiceAddress2
            : values.address2,
          InvoiceStreet: values.isDifferent
            ? values.invoiceStreet
            : values.street,
          Status: +values.status.value,
          TaggedTo: values.tag.value === '0' ? null : +values.tag.value,
          InvoiceCityId: values.isDifferent
            ? values.invoiceCity.value === '0'
              ? null
              : +values.invoiceCity.value
            : values.city.value === '0'
            ? null
            : +values.city.value,
          InvoiceCountryId: values.isDifferent
            ? values.invoiceCountry.value === '0'
              ? null
              : +values.invoiceCountry.value
            : values.country.value === '0'
            ? null
            : +values.country.value,
          InvoicePostalCode: values.isDifferent
            ? values.invoicePostalCode
            : values.postalCode,
          ApprovalStatus: +ApprovalStatus.Pending,
          InvoiceEmail: values.invoiceEmail,
          AdminEmail: values.adminEmail,
        };
        const result: any = await http.post(apiUrl.toString(), Partner);
        if (result !== null && result.data !== null) {
          toast.success('Saved Successfully');
          navigate(RouteEnum.PartnerList);
        } else {
          toast.error(I18n('Common.ErrorOccurred'));
        }
      } catch (error: any) {
        if (error.response) {
          let temp = { ...errors };
          if (error.response.data) {
            for (const item of error.response.data.errors) {
              if (
                item.field === 'Name' &&
                item.errorMessage === 'UniqueSupplierName'
              ) {
                temp.name = i18nMessages.UniqueName;
              }
            }
          }
          setErrors({
            ...temp,
          });
        }
      }
    }
  };

  //Autocomplete
  const [isAddCityModalOpen, setIsAddCityModal] = useState(false);
  const [locationData, setLocationData] = useState({
    country: defaultValue,
    postcode: '',
    place: defaultValue,
    invoicePlace: defaultValue,
  });

  const autocompleteHandleChange = async (
    value: string,
    selectedPlace: any
  ) => {
    const autoSelectValues = {
      country: values.country,
      postcode: values?.postalCode,
      place: values.city,
    };
    //Country
    if (selectedPlace.country && selectedPlace.country !== undefined) {
      let selectedCountry = countryList.find(
        (option: any) => option.label === selectedPlace.country
      );
      autoSelectValues.country = selectedCountry
        ? selectedCountry
        : defaultValue;

      setLocationData({
        ...locationData,
        country: autoSelectValues.country,
      });
    }
    //City or Place
    if (selectedPlace.place && selectedPlace.place !== undefined) {
      let selectedCity = cityList.find(
        (option: any) => option.label === selectedPlace.place
      );

      //Add new city in db
      if (!selectedCity || selectedCity === undefined) {
        selectedCity = await addNewCity(selectedPlace.place);
      }

      autoSelectValues.place = selectedCity ? selectedCity : defaultValue;

      setLocationData({
        ...locationData,
        place: selectedCity
          ? selectedCity
          : ({ label: selectedPlace.place, value: '0' } as BaseModel),
      });
    }
    //Postal code
    if (selectedPlace.postcode && selectedPlace.postcode !== undefined) {
      autoSelectValues.postcode = selectedPlace.postcode;

      setLocationData({
        ...locationData,
        postcode: autoSelectValues.postcode,
      });
    }

    setValues({
      ...values,
      address: value,
      street: selectedPlace.number ? selectedPlace.number : values.street,
      country: autoSelectValues.country,
      postalCode: autoSelectValues.postcode,
      city: autoSelectValues.place,
    });
  };

  //Add City
  useEffect(() => {
    if (!isAddCityModalOpen) {
      setCityByLocationData();
    }
    window.scrollTo(0, 0);
  }, [isAddCityModalOpen]);

  const onAddCity = useCallback(() => {
    setIsAddCityModal(true);
  }, []);

  const onCloseAddCity = () => {
    setIsAddCityModal(false);
  };

  const onSubmitCity = async (cityModel: any) => {
    await getCities();
    onCloseAddCity();
  };

  const loadCountryOptions = async (
    search: string,
    loadedOptions: any,
    { page }: any,
    loadInstruction: boolean = false
  ) => {
    const response = await getCountries(search, page);
    const hasMore = Math.ceil(response.TotalRecords / 10) > page;

    return {
      options: response.Values.map((x: any) => {
        return {
          value: x.id.toString(),
          label: loadInstruction ? x.description : x.name,
        } as BaseModel;
      }),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const loadCitiesOptions = async (
    search: string,
    loadedOptions: any,
    { page }: any
  ) => {
    const response = await getCities(search, page);
    const hasMore = Math.ceil(response.TotalRecords / 500) > page;

    return {
      options: response.Values.map((x) => {
        return {
          value: x.value.toString(),
          label: x.label,
        } as BaseModel;
      }),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const setCityByLocationData = () => {
    if (locationData.place && locationData.place !== undefined) {
      let selectedCity = cityList.find(
        (option: any) => option.label === locationData.place.label
      );
      if (selectedCity && selectedCity !== undefined) {
        setValues({
          ...values,
          city: selectedCity,
        });
      }
    }
    if (locationData.invoicePlace && locationData.invoicePlace !== undefined) {
      let selectedCity = cityList.find(
        (option: any) => option.label === locationData.invoicePlace.label
      );
      if (
        selectedCity &&
        selectedCity !== undefined &&
        selectedCity.label !== i18nMessages.Select
      ) {
        setValues({
          ...values,
          invoiceCity: selectedCity,
        });
      }
    }
  };

  //Autocomplete Invoice
  const autocompleteHandleChangeInvoice = async (
    value: string,
    selectedPlace: any
  ) => {
    const autoSelectValues = {
      invoiceCountry: values.invoiceCountry,
      invoicePostcode: values?.invoicePostalCode,
      invoicePlace: values.invoiceCity,
    };
    //Country
    if (selectedPlace.country && selectedPlace.country !== undefined) {
      let selectedCountry = countryList.find(
        (option: any) => option.label === selectedPlace.country
      );
      autoSelectValues.invoiceCountry = selectedCountry
        ? selectedCountry
        : defaultValue;

      setLocationData({
        ...locationData,
        country: autoSelectValues.invoiceCountry,
      });
    }
    //City or Place
    if (selectedPlace.place && selectedPlace.place !== undefined) {
      let selectedCity = cityList.find(
        (option: any) => option.label === selectedPlace.place
      );

      //Add new city in db
      if (!selectedCity || selectedCity === undefined) {
        selectedCity = await addNewCity(selectedPlace.place);
      }

      autoSelectValues.invoicePlace = selectedCity
        ? selectedCity
        : defaultValue;

      setLocationData({
        ...locationData,
        invoicePlace: selectedCity
          ? selectedCity
          : ({ label: selectedPlace.place, value: '0' } as BaseModel),
        place: selectedCity
          ? selectedCity
          : ({ label: selectedPlace.place, value: '0' } as BaseModel),
      });
    }
    //Postal code
    if (selectedPlace.postcode && selectedPlace.postcode !== undefined) {
      autoSelectValues.invoicePostcode = selectedPlace.postcode;

      setLocationData({
        ...locationData,
        postcode: autoSelectValues.invoicePostcode,
      });
    }

    setValues({
      ...values,
      invoiceAddress: value,
      invoiceStreet: selectedPlace.number
        ? selectedPlace.number
        : values.street,
      invoiceCountry: autoSelectValues.invoiceCountry,
      invoicePostalCode: autoSelectValues.invoicePostcode,
      invoiceCity: autoSelectValues.invoicePlace,
    });
  };

  const addNewCity = async (value: string) => {
    const name = {
      code: value,
      name: value,
      otherComments: value,
      statusId: 1,
    };
    const newOption: any = await PostCityDropDown(
      name,
      i18nMessages.CityCommonSaveSuccessMsg,
      i18nMessages.CommonErrOccuredMsg
    );

    return newOption;
  };

  const onBlurZipCode = async (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    triggerFrom: ZipCodeTriggeredFrom
  ) => {
    const apiUrl = new URL(
      environment.api.baseUrl +
        'Locations/GetLocationByZipCode/' +
        String(event.target.value)
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result) {
        if (result.data) {
          const city: BaseModel = {
            label: String(result.data.city),
            value: String(result.data.cityId),
          };
          const country: BaseModel = {
            label: String(result.data.country),
            value: String(result.data.countryId),
          };
          if (triggerFrom === ZipCodeTriggeredFrom.PartnerDetails) {
            setValues({
              ...values,
              city,
              country,
            });
          } else if (triggerFrom === ZipCodeTriggeredFrom.PartnerInvoice) {
            setValues({
              ...values,
              invoiceCity: city,
              invoiceCountry: country,
            });
          }
        } else {
        }
      } else {
        toast.error(I18n('Common.ErrorOccurred'));
      }
    } catch (error: any) {
      if (triggerFrom === ZipCodeTriggeredFrom.PartnerDetails) {
        setValues({
          ...values,
          city: defaultValue,
          country: defaultValue,
        });
      } else if (triggerFrom === ZipCodeTriggeredFrom.PartnerInvoice) {
        setValues({
          ...values,
          invoiceCity: defaultValue,
          invoiceCountry: defaultValue,
        });
      }
    }
  };

  const LogoData = () => {
    return (
      <>
        <Grid item lg={12} md={12}>
          {
            <FileUpload
              validatePixel={true}
              filesLimit={1}
              onSave={handleSaveUpload}
              showPreviews={true}
              maxFileSize={2097152}
              onDelete={handleFileDelete}
              acceptedFiles={[
                'image/jpeg',
                'image/png',
                'image/jpg',
                'image/bmp',
              ]}
            />
          }
        </Grid>
      </>
    );
  };
  const onCreateOptionCountry = async (inputValue: any, isInvoice: boolean) => {
    let name = {};
    name['name'] = inputValue;
    const newOption: any = await PostCountryDropDown(
      name,
      i18nMessages.CommonSaveSuccessMsg,
      i18nMessages.CommonErrOccuredMsg
    );
    const increaseUniq = (uniq: any) => uniq + 1;
    setCacheUniqCountry(increaseUniq);
    if (isInvoice) {
      setValues({ ...values, invoiceCountry: newOption });
    } else {
      setValues({ ...values, country: newOption });
    }

    getCountries();
  };
  const onCreateOption = async (inputValue: any, isInvoice: boolean) => {
    const name = {
      code: inputValue,
      name: inputValue,
      otherComments: inputValue,
      statusId: 1,
    };
    const newOption: any = await PostCityDropDown(
      name,
      i18nMessages.CityCommonSaveSuccessMsg,
      i18nMessages.CommonErrOccuredMsg
    );
    const increaseUniq = (uniq: any) => uniq + 1;
    setCacheUniq(increaseUniq);
    if (isInvoice) {
      setValues({ ...values, invoiceCity: newOption });
    } else {
      setValues({ ...values, city: newOption });
    }

    getCities('', 1);
  };

  const Details = () => {
    return (
      <>
        <Grid item lg={4} md={4}>
          <CustomizedSelect
            placeholder={defaultTransportTypeValue.label}
            options={transportTypeList}
            isSearchable={false}
            displayLabel={I18n('Partner.Type')}
            handleChange={(newValue: BaseModel) => {
              setValues({
                ...values,
                transportType: newValue,
              });
            }}
            value={values.transportType}
            errorValue={errors.transportType}
            errorMessage={errors.transportType}
            required={true}
          ></CustomizedSelect>
        </Grid>
        <Grid item lg={4} md={4}>
          <FormControl
            margin="normal"
            displayLabel={I18n('Partner.SupplierName')}
            id="supplierName"
            inputType="text"
            inputProps={{ maxLength: 50 }}
            errorValue={errors.name}
            errorMessage={errors.name}
            handleChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setValues({ ...values, supplierName: event.target.value })}
            value={values.supplierName}
            multiline={false}
            required={true}
          ></FormControl>
        </Grid>
        <Grid item lg={4} md={4}>
          <CustomizedSelect
            placeholder={defaultPartnerTypeValue.label}
            options={partnerTypeList}
            isSearchable={false}
            displayLabel={I18n('Partner.Tracking')}
            handleChange={(newValue: BaseModel) => {
              setValues({
                ...values,
                type: newValue,
              });
            }}
            value={values.type}
            errorValue={errors.type}
            errorMessage={errors.type}
            required={true}
          ></CustomizedSelect>
        </Grid>
        <Grid item lg={8} md={8}>
          <AddressAutocompleteInputs
            name="address"
            // value={values?.address}
            inputProps={{ maxLength: 250 }}
            displayLabel={I18n('Partner.SearchAddress')}
            handleChange={autocompleteHandleChange}
            multiline={false}
          ></AddressAutocompleteInputs>
        </Grid>
        <Grid item lg={4} md={4}>
          <FormControl
            margin="normal"
            displayLabel={I18n('CustomerLocation.Street')}
            id="Street"
            inputType="text"
            inputProps={{ maxLength: 250 }}
            handleChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) =>
              setValues({
                ...values,
                street: event.target.value,
              })
            }
            value={values.street}
            multiline={false}
            required={true}
            errorValue={errors.street}
            errorMessage={errors.street}
          ></FormControl>
        </Grid>
        <Grid item lg={8} md={8}>
          <FormControl
            margin="normal"
            displayLabel={I18n('CustomerLocation.Address2')}
            id="address2"
            inputType="text"
            inputProps={{ maxLength: 250 }}
            handleChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) =>
              setValues({
                ...values,
                address2: event.target.value,
              })
            }
            value={values.address2}
            multiline={false}
          ></FormControl>
        </Grid>
        <Grid item lg={4} md={4}>
          <FormControl
            margin="normal"
            displayLabel={I18n('Partner.PostalCode')}
            id="PostalCode"
            inputType="text"
            inputProps={{ maxLength: 20 }}
            handleChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setValues({ ...values, postalCode: event.target.value })}
            value={values.postalCode}
            multiline={false}
            errorValue={true}
            errorMessage={errors.postalCode}
            required={true}
            handleBlur={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => onBlurZipCode(event, ZipCodeTriggeredFrom.PartnerDetails)}
          ></FormControl>
        </Grid>
        <Grid item lg={4} md={4}>
          <div className="add-button-address">
            <CustomSelectPaginateAdd
              key={JSON.stringify(values.city)}
              additional={defaultAdditional}
              value={values.city}
              loadOptions={loadCitiesOptions}
              handleChange={(newValue: BaseModel) =>
                setValues({ ...values, city: newValue })
              }
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
              }}
              placeholder={defaultValue.label}
              isSearchable={true}
              displayLabel={I18n('CustomerLocation.City')}
              errorValue={true}
              errorMessage={errors.city}
              required={true}
              readOnly={false}
              debounceTimeout={500}
              isMultiSelect={false}
              cacheUniqs={cacheUniq}
              onCreateOption={onCreateOption}
            ></CustomSelectPaginateAdd>
          </div>
        </Grid>
        <Grid item lg={4} md={4}>
          {
            <CustomizedSelect
              placeholder={defaultValue.label}
              options={countryList}
              isSearchable={true}
              displayLabel={i18nMessages.country}
              handleChange={(newValue: BaseModel) => {
                setValues({ ...values, country: newValue });
              }}
              value={values.country}
              errorValue={true}
              errorMessage={errors.country}
              required={true}
            ></CustomizedSelect>
          }
        </Grid>
        <Grid item lg={4} md={4}>
          <FormControl
            margin="normal"
            displayLabel={I18n('Partner.Website')}
            id="Website"
            inputType="text"
            inputProps={{ maxLength: 255 }}
            handleChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setValues({ ...values, website: event.target.value })}
            value={values.website}
            multiline={false}
          ></FormControl>
        </Grid>
      </>
    );
  };

  const FinanceBasic = () => {
    return (
      <>
        <Grid item lg={12}>
          <FormControl
            margin="normal"
            displayLabel={I18n('Partner.VatNumber')}
            id="VatNumber"
            inputType="text"
            inputProps={{ maxLength: 30 }}
            handleChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setValues({ ...values, VATNumber: event.target.value })}
            value={values.VATNumber}
            multiline={false}
            required={true}
            errorValue={errors.vatNumber}
            errorMessage={errors.vatNumber}
          ></FormControl>
        </Grid>
        <Grid item lg={6}>
          <FormControl
            margin="normal"
            displayLabel={I18n('Partner.EORI')}
            id="EORI"
            inputType="text"
            inputProps={{ maxLength: 30 }}
            handleChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setValues({ ...values, EORI: event.target.value })}
            value={values.EORI}
            multiline={false}
          ></FormControl>
        </Grid>
        <Grid item lg={6}>
          <FormControlLabel
            control={
              <Checkbox
                name="sameVat"
                onChange={onSameAsVaTChange}
                checked={values.sameVat}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label={I18n('Partner.SameAsVat')}
          />
        </Grid>
      </>
    );
  };

  const ContactDetails = () => {
    return (
      <>
        <Grid item xs={6}>
          <CustomizedPhone
            title={I18n('Partner.GeneralTelephone')}
            value={values.generalTelephone}
            name="generalTelephone"
            onChange={(generalTelephone: any) =>
              phoneOnChange(generalTelephone)
            }
          ></CustomizedPhone>
        </Grid>

        <Grid item lg={6}>
          <FormControl
            margin="normal"
            displayLabel={I18n('Partner.GeneralEmail')}
            id="Email"
            inputType="text"
            errorValue={errors.email}
            errorMessage={errors.email}
            inputProps={{ maxLength: 255 }}
            handleChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setValues({ ...values, generalEmail: event.target.value })}
            value={values.generalEmail}
            multiline={false}
          ></FormControl>
        </Grid>
        <Grid item lg={6}>
          <FormControl
            margin="normal"
            displayLabel={I18n('Partner.AdminEmail')}
            id="Email"
            inputType="text"
            errorValue={errors.adminEmail}
            errorMessage={errors.adminEmail}
            inputProps={{ maxLength: 255 }}
            handleChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setValues({ ...values, adminEmail: event.target.value })}
            value={values.adminEmail}
            multiline={false}
          ></FormControl>
        </Grid>
      </>
    );
  };

  const StatusDetails = () => {
    return (
      <>
        <Grid item lg={12}>
          <CustomizedSelect
            placeholder={defaultValue.label}
            options={entityStatusList}
            isSearchable={false}
            displayLabel={I18n('ContactPerson.Status')}
            handleChange={(newValue: BaseModel) =>
              setValues({ ...values, status: newValue })
            }
            value={values.status}
          ></CustomizedSelect>
        </Grid>
      </>
    );
  };

  const TagDetails = () => {
    return (
      <>
        <Grid item lg={12}>
          <CustomizedSelect
            placeholder={defaultValue.label}
            options={customerTagsList}
            isSearchable={false}
            displayLabel={I18n('Partner.Tag')}
            handleChange={(newValue: BaseModel) =>
              setValues({ ...values, tag: newValue })
            }
            value={values.tag}
          ></CustomizedSelect>
        </Grid>
      </>
    );
  };

  const InvoiceDetails = () => {
    return (
      <>
        <Grid item lg={6}>
          <FormControlLabel
            control={
              <Checkbox
                name="isDifferent"
                onChange={oncheckboxchange}
                checked={values.isDifferent}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label={i18nMessages.isDifferent}
          />
        </Grid>
        <Grid item lg={6}>
          <FormControl
            margin="normal"
            displayLabel={i18nMessages.invoiceEmail}
            id="InvoiceEmail"
            errorValue={errors.invoiceEmail}
            errorMessage={errors.invoiceEmail}
            inputType="text"
            inputProps={{ maxLength: 255 }}
            handleChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setValues({ ...values, invoiceEmail: event.target.value })}
            value={values.invoiceEmail}
            multiline={false}
          ></FormControl>
        </Grid>
        {values.isDifferent ? (
          <>
            <Grid item lg={12} md={12}>
              <AddressAutocompleteInputs
                name="invoiceAddress"
                // value={values.invoiceAddress}
                inputProps={{ maxLength: 250 }}
                multiline={false}
                displayLabel={i18nMessages.address}
                handleChange={autocompleteHandleChangeInvoice}
              ></AddressAutocompleteInputs>
            </Grid>
            <Grid item lg={6} md={6}>
              <CustomizedInputs
                margin="normal"
                displayLabel={i18nMessages.Street}
                name="invoiceStreet"
                id="invoiceStreet"
                inputType="text"
                inputProps={{ maxLength: 20 }}
                handleChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ) =>
                  setValues({
                    ...values,
                    invoiceStreet: event.target.value,
                  })
                }
                value={values.invoiceStreet}
                errorValue={true}
                errorMessage={errors.invoiceStreet}
                required={true}
              />
            </Grid>
            <Grid item lg={6} md={6}>
              <CustomizedInputs
                margin="normal"
                displayLabel={i18nMessages.Address2}
                name="invoiceAddress2"
                id="invoiceAddress2"
                inputType="text"
                inputProps={{ maxLength: 250 }}
                handleChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ) =>
                  setValues({
                    ...values,
                    invoiceAddress2: event.target.value,
                  })
                }
                value={values.invoiceAddress2}
              />
            </Grid>
            <Grid item lg={6} md={6}>
              <CustomizedInputs
                margin="normal"
                displayLabel={i18nMessages.postalCode}
                name="invoicePostalCode"
                id="invoicePostalCode"
                inputType="text"
                inputProps={{ maxLength: 20 }}
                handleChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ) =>
                  setValues({
                    ...values,
                    invoicePostalCode: event.target.value,
                  })
                }
                value={values.invoicePostalCode}
                errorValue={true}
                errorMessage={errors.invoicePostalCode}
                required={true}
                handleBlur={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ) => onBlurZipCode(event, ZipCodeTriggeredFrom.PartnerInvoice)}
              />
            </Grid>
            <Grid item lg={6} md={6}>
              {
                <div className="add-button-address">
                  <CustomSelectPaginateAdd
                    key={JSON.stringify(values.invoiceCity)}
                    additional={defaultAdditional}
                    value={values.invoiceCity}
                    loadOptions={loadCitiesOptions}
                    handleChange={(newValue: BaseModel) =>
                      setValues({ ...values, invoiceCity: newValue })
                    }
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                    }}
                    placeholder={defaultValue.label}
                    isSearchable={true}
                    displayLabel={i18nMessages.city}
                    readOnly={false}
                    debounceTimeout={500}
                    isMultiSelect={false}
                    cacheUniqs={cacheUniq}
                    onCreateOption={(inputValue: any) =>
                      onCreateOption(inputValue, true)
                    }
                    menuPlacement={'top'}
                    errorValue={true}
                    errorMessage={errors.invoiceCity}
                    required={true}
                  ></CustomSelectPaginateAdd>
                </div>
              }
            </Grid>
            <Grid item lg={6} md={6}>
              {
                <CustomizedSelect
                  placeholder={defaultValue.label}
                  options={countryList}
                  isSearchable={true}
                  displayLabel={i18nMessages.country}
                  handleChange={(newValue: BaseModel) => {
                    setValues({ ...values, invoiceCountry: newValue });
                  }}
                  value={values.invoiceCountry}
                  menuPlacement={'top'}
                  errorValue={true}
                  errorMessage={errors.invoiceCountry}
                  required={true}
                ></CustomizedSelect>
              }
            </Grid>
          </>
        ) : (
          <></>
        )}
      </>
    );
  };

  const close = () => {
    navigate(RouteEnum.PartnerList);
  };
  getStatusList();
  getCustomerTagsList();
  getDebtList();
  getPartnerTypeList();
  useEffect(() => {
    getCountries();
    getCities();
    getTransportTypeDropDown();
  }, []);

  return (
    <div>
      <div className="heading-section">
        <div className="heading-section-left">
          <Typography variant="h3" sx={{ mb: 3 }}>
            {I18n('Partner.AddPartner')}
          </Typography>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>
      </div>
      <div className="main-content-section">
        <Grid container spacing={2} className="grid-wrap">
          <Grid item lg={3} md={3}>
            <FormAccordian
              title={I18n('Partner.Logo')}
              details={LogoData()}
              expanded={true}
              addMode={true}
            ></FormAccordian>
          </Grid>
          <Grid item lg={9} md={9}>
            <FormAccordian
              title={I18n('Partner.Details')}
              details={Details()}
              expanded={true}
              addMode={true}
            ></FormAccordian>
          </Grid>
          <Grid item lg={4} md={4}>
            <FormAccordian
              title={I18n('Partner.FinancialBasic')}
              details={FinanceBasic()}
              expanded={true}
              addMode={true}
            ></FormAccordian>
          </Grid>
          <Grid item lg={4} md={4}>
            <FormAccordian
              title={I18n('Partner.ContactDetails')}
              details={ContactDetails()}
              expanded={true}
              addMode={true}
            ></FormAccordian>
          </Grid>
          <Grid item lg={4} md={4}>
            <FormAccordian
              title={I18n('Partner.Status')}
              details={StatusDetails()}
              expanded={true}
              addMode={true}
            ></FormAccordian>
          </Grid>

          <Grid item lg={4} md={4}>
            <FormAccordian
              title={i18nMessages.invoicing}
              details={InvoiceDetails()}
              expanded={true}
              addMode={true}
            ></FormAccordian>
          </Grid>
        </Grid>
        <div className="btn-wrap form-btn-wrap">
          <Button
            onClick={close}
            color="neutral"
            disableElevation
            variant="contained"
          >
            {i18nMessages.cancel}
          </Button>
          <Button
            onClick={handleSubmit}
            autoFocus
            color="primary"
            disableElevation
            variant="contained"
          >
            {i18nMessages.save}
          </Button>
        </div>
      </div>
      {isAddCityModalOpen && (
        <AddCity
          isOpen={isAddCityModalOpen}
          onClose={onCloseAddCity}
          onSubmit={onSubmitCity}
          title={i18nMessages.AddCity}
          locationData={locationData}
        ></AddCity>
      )}
    </div>
  );
}

export default AddPartnerDetails;
