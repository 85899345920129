import * as React from 'react';
import { createTheme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import clsx from 'clsx';

interface ProgressBarProps {
  completedCounts: any;
  totalCounts: any;
}
const defaultTheme = createTheme();
const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        border: `1px solid ${theme.palette.divider}`,
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        height: 26,
        borderRadius: 2,
      },
      value: {
        position: 'absolute',
        lineHeight: '24px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      },
      bar: {
        height: '100%',
        '&.low': {
          backgroundColor: '#f44336',
        },
        '&.medium': {
          backgroundColor: '#efbb5aa3',
        },
        '&.high': {
          backgroundColor: '#088208a3',
        },
      },
    }),
  { defaultTheme }
);

const CompletionProgressBar = React.memo(function ProgressBar(
  props: ProgressBarProps
) {
  const { completedCounts, totalCounts } = props;
  const valueInPercent = (completedCounts * 100) / totalCounts;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.value}>{`${completedCounts}/${totalCounts}`}</div>
      <div
        className={clsx(classes.bar, { high: valueInPercent > 0 })}
        style={{ maxWidth: `${valueInPercent}%` }}
      />
    </div>
  );
});

export default CompletionProgressBar;
