import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import I18n from 'utilities/i18n';
import './OrderTrackings.scss';
import { useEffect, MouseEvent, useCallback, useState } from 'react';
import http from '../../utilities/httpService';
import {
  BaseModel,
  CommonListRequestModel,
  CommonPaginationResponseModel,
  CommonProps,
  MasterCategoryEnumType,
  OrderByDirection,
  TabCommonProps,
} from 'models/pagination-model';
import { Button, Tooltip } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faSearch, faPlus } from '@fortawesome/free-solid-svg-icons';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InputAdornment from '@mui/material/InputAdornment';
import CustomizedInputs from 'common/formControl/formControl';
import CustomDataGrid from 'common/datagrid/datagrid';
import { toast } from 'react-toastify';
import {
  GridActionsCellItem,
  GridCellParams,
  GridColumns,
  GridPaginationInitialState,
  GridRenderEditCellParams,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridRowsProp,
  GridSortingInitialState,
  GridSortModel,
  GridToolbarContainer,
  MuiEvent,
  useGridApiContext,
} from '@mui/x-data-grid';
import ConfirmationPopup from 'common/confirmationPopup/confirmationPopup';
import moment, { Moment } from 'moment';
import {
  deleteOrderTrackingApi,
  getTrackings,
} from './OrderTrackingApiService';
import {
  OrderTrackingResponseDto,
  OrderTrackingStateModel,
  TrackingType,
  TrackingTypeDescription,
} from 'models/order-tracking';
import { ProductCatalogueAssignmentType } from 'models/product-catalogue';
import AddOrderTracking from './AddOrderTracking';
import { Utilities } from 'utilities/Utilities';
import { hasPermission } from 'utilities/protectedRoute';
import { Permission } from 'Permissions';
import DateTimePickerInput from 'common/DatetimePicker/DateTimePickerInput';
import AutocompleteInputs from 'common/Autocomplete/AutocompleteInput';
import CustomizedSelect from 'common/CustomSelect/CustomSelect';
import { getStatusSuggestions, saveTracking } from './OrderTrackingApiService';
import CustomDataGridInline from 'common/datagrid/CustomDataGridInline';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { reject } from 'lodash';
import { setAllDocuments } from '@cyntler/react-doc-viewer/dist/esm/store/actions';
import CustomSelectPaginateAdd from 'common/CustomSelect/CustomSelectPaginateAdd';
import { PostCatagoryDropDown } from 'common/DropDownAddAPI/DropDownApiService';
import { loadCategoryOptions } from 'components/shipping-orders/ShippingApiService';
interface TrackingProps<T> {
  entityType: T;
  entityId: number;
  redirect: string;
  addPermission: string;
  editPermission: string;
  deletePermission: string;
  isOrderEnable: boolean;
}

function OrderTrackings(props: TrackingProps<ProductCatalogueAssignmentType>) {
  const [canEdit, setCanEdit] = useState(hasPermission(props.editPermission));
  const [canAdd, setCanAdd] = useState(hasPermission(props.addPermission));
  const [canDelete, setCanDelete] = useState(
    hasPermission(props.deletePermission)
  );
  const customerPortalUser = Utilities.getIsCPUser();
  const [isDeleteModalOpen, setDeleteModal] = useState(false);
  const [isAddModalOpen, setAddModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [selectedId, setId] = useState(0);
  const [refreshGrid, setRefreshGrid] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [autoFocusOnStatus, setAutoFocusOnStatus] = useState(false);
  const [autoFocusOnType, setAutoFocusOnType] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [gridData, setGridData] = useState<
    CommonPaginationResponseModel<OrderTrackingResponseDto>
  >({
    sortOrder: OrderByDirection.Descending,
    sortBy: 'statusChangeDate',
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [10, 20, 50, 100],
    pageSize: 10,
    page: 0,
    searchExpression: '',
    isLoading: false,
  });
  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));

  const getTrackingList = () => {
    updateGridData('isLoading', true);
    getTrackings(props.entityId ? +props.entityId : 0, {
      gridData,
      basicSearchExpression:
        gridData.searchExpression !== ''
          ? `EntityType~=~${props.entityType} and EntityId~=~${props.entityId} and Status~like~${gridData.searchExpression}`
          : `EntityType~=~${props.entityType} and EntityId~=~${props.entityId}`,
    } as CommonListRequestModel<OrderTrackingResponseDto>).then((x) => {
      if (x) {
        for (var i = 0; i < x.gridData.rows.length; i++) {
          x.gridData.rows[i].selectedStatus = {
            value: x.gridData.rows[i].id.toString(),
            label: x.gridData.rows[i].status,
          };
        }

        updateGridData('totalRows', x?.gridData.totalRows);
        updateGridData('rows', x?.gridData.rows);
        updateGridData('isLoading', false);
        if (!props.isOrderEnable) {
          setCanAdd(false);
          setCanDelete(false);
          setCanEdit(false);
        }
      }
    });
  };

  useEffect(() => {
    document.addEventListener('keydown', keydown, false);
    getTrackingList();
  }, [
    gridData.page,
    gridData.pageSize,
    gridData.sortBy,
    gridData.sortOrder,
    gridData.searchExpression,
    refreshGrid,
  ]);

  useEffect(() => {
    if (customerPortalUser) {
      setCanAdd(false);
      setCanEdit(false);
      setCanDelete(false);
    }
  }, []);

  const handleChangePage = (pageNo: number) => {
    updateGridData('page', pageNo);
  };

  const handleChangeBasicSearch = (searchExpression: any) => {
    updateGridData('searchExpression', searchExpression.target.value);
  };
  const handleChangePageSize = (pageSize: number) => {
    updateGridData('pageSize', pageSize);
  };
  type Row = (typeof gridData.rows)[number];
  const deleteOrderTracking = (id: any) => {
    setDeleteModal(true);
    setId(id);
  };

  const addOrderTracking = useCallback(() => {
    setAddModal(true);
  }, []);

  const editOrderTracking = useCallback(
    (data: any) => () => {
      setEditData(data.row);
      setAddModal(true);
    },
    []
  );

  const refreshData = async () => {
    handleCloseForAdd();
    await getTrackingList();
  };

  const getTrackingType = (trackingType: TrackingType) => {
    switch (trackingType) {
      case TrackingType.Internal:
        return i18nMessages.internal;
      case TrackingType.External:
        return i18nMessages.external;
    }
  };

  const [rows, setRows] = useState([]);
  const today = new Date().toISOString();
  const [options, setOptions] = useState<readonly string[]>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const defaultTrackingTypeValue = {
    value: '',
    label: I18n('Placeholders.SelectTrackingType'),
  } as BaseModel;
  let trackingTypeList: Array<BaseModel> = [];
  const getTrackingTypeList = () => {
    trackingTypeList = [defaultTrackingTypeValue];
    TrackingTypeDescription.forEach((value: string, key: number) => {
      trackingTypeList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getTrackingTypeList();

  const [values, setValues] = useState<OrderTrackingStateModel>({
    id: 0,
    entityType: props.entityType,
    entityId: props.entityId,
    statusChangeDate: new Date(),
    status: '',
    type: defaultTrackingTypeValue,
    notes: '',
    isManualEntry: true,
  });

  function NoteEditComponent(props: GridRenderEditCellParams) {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value; // The new value entered by the user
      apiRef.current.setEditCellValue({ id, field, value: newValue });
    };
    return (
      <CustomizedInputs
        margin="normal"
        id="note"
        name="note"
        inputType="text"
        inputProps={{ maxLength: 50 }}
        multiline={true}
        handleChange={handleValueChange}
        value={props.row.notes}
      ></CustomizedInputs>
    );
  }

  const [cacheUniq, setCacheUniq] = useState(0);

  const defaultStatusValue = {
    value: '',
    label: I18n('Placeholders.Select'),
  } as BaseModel;

  const onCreateOption = async (
    inputValue: any,
    props: GridRenderEditCellParams,
    apiRef: any
  ) => {
    let name = {};
    name['name'] = inputValue;
    name['entityType'] = MasterCategoryEnumType.OrderTrackingStatusSuggesions;
    const newOption: any = await PostCatagoryDropDown(
      name,
      i18nMessages.CommonSaveSuccessMsg,
      i18nMessages.CommonErrOccuredMsg
    );
    const increaseUniq = (uniq: any) => uniq + 1;
    setCacheUniq(increaseUniq);
    SetValueForStatus(props, newOption, apiRef);
  };

  const defaultAdditional: any = {
    page: 1,
  };

  const SetValueForStatus = (
    props: GridRenderEditCellParams,
    newValue: string,
    apiRef: any
  ) => {
    const { id, value, field } = props;
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  function StatusEditComponent(props: GridRenderEditCellParams) {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();

    const handleValueChange = (newValue: string) => {
      apiRef.current.setEditCellValue({ id, field, value: newValue });
    };
    const onFocusedChange = () => {
      setAutoFocusOnStatus(true);
      setAutoFocusOnType(false);
      setOpen(true);
    };
    return (
      // <AutocompleteInputs
      //   value={props.row.status}
      //   inputProps={{ maxLength: 50 }}
      //   options={options}
      //   handleOptionChange={async (newValue: string) => {
      //     if (newValue) {
      //       setLoading(true);
      //       const response = await getStatusSuggestions(newValue);
      //       if (response) {
      //         setOptions(response);
      //         setLoading(false);
      //       }
      //     }
      //   }}
      //   handleChange={(newValue: string) => {
      //     handleValueChange(newValue);
      //   }}
      //   open={open}
      //   loading={loading}
      //   autoFocus={autoFocusOnStatus}
      //   onFocus={onFocusedChange}
      // ></AutocompleteInputs>

      <CustomSelectPaginateAdd
        required
        readOnly={!canEdit}
        additional={defaultAdditional}
        value={props.row.selectedStatus}
        loadOptions={(search: string, prevOptions: any, page: any) =>
          loadCategoryOptions(
            MasterCategoryEnumType.OrderTrackingStatusSuggesions,
            search,
            prevOptions,
            page
          )
        }
        isSearchable={true}
        placeholder={defaultStatusValue.label}
        handleChange={(newValue: any) => handleValueChange(newValue)}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
        }}
        debounceTimeout={500}
        isMultiSelect={false}
        cacheUniqs={cacheUniq}
        onCreateOption={(inputValue: any) =>
          onCreateOption(inputValue, props, apiRef)
        }
        errorValue={true}
        autoFocus={autoFocusOnStatus}
      ></CustomSelectPaginateAdd>
    );
  }

  function DateEditComponent(props: GridRenderEditCellParams) {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();
    const handleValueChange = (newValue: Date) => {
      apiRef.current.setEditCellValue({ id, field, value: newValue });
    };
    return (
      <DateTimePickerInput
        name="statusChangeDate"
        value={props.row.statusChangeDate}
        defaultValue={today}
        handleChange={(event: Moment) => {
          handleValueChange(event != null ? event.toDate() : event);
        }}
      ></DateTimePickerInput>
    );
  }

  const setEditStatusValue = (id: string) => {
    return {
      value: id,
      label: TrackingType[id],
    } as BaseModel;
  };

  function TypeEditComponent(props: GridRenderEditCellParams) {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();

    const handleValueChange = (newValue: BaseModel) => {
      apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    const onFocusedChange = () => {
      setAutoFocusOnStatus(false);
      setAutoFocusOnType(true);
      setOpen(false);
    };
    return (
      <div className="tracking-container">
        <CustomizedSelect
          defaultValue={trackingTypeList[0]}
          options={trackingTypeList}
          isSearchable={false}
          handleChange={(newValue: BaseModel) => handleValueChange(newValue)}
          value={
            props.row.type != '' && props.row.type.value === undefined
              ? setEditStatusValue(props.row.type)
              : props.row.type
          }
          onFocus={onFocusedChange}
          autoFocus={autoFocusOnType}
        ></CustomizedSelect>
      </div>
    );
  }

  interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (
      newModel: (oldModel: GridRowModesModel) => GridRowModesModel
    ) => void;
  }

  function EditToolbar(props: EditToolbarProps) {
    const handleClick = () => {
      setIsEdit(false);
      var existingRow = gridData.rows.find((x: any) => x.id === 0);
      if (existingRow === undefined) {
        const id = 0;
        var temp: any = [];
        for (let i = 0; i < gridData.rows.length; i++) {
          temp.push(gridData.rows[i]);
        }
        temp.push({
          id: 0,
          isManualEntry: true,
          notes: '',
          status: '',
          type: defaultTrackingTypeValue,
          statusChangeDate: moment(new Date()).utc().format(),
          isNew: true,
        });
        updateGridData('rows', temp);
        updateGridData('totalRows', temp.length);
        setRowModesModel((oldModel) => ({
          ...oldModel,
          [id]: {
            mode: GridRowModes.Edit,
            fieldToFocus: 'statusChangeDate',
          },
        }));
        setAutoFocusOnStatus(true);
      }
    };

    return (
      <GridToolbarContainer>
        {canAdd ? (
          <Button
            color="primary"
            id="addRecord"
            startIcon={<AddIcon />}
            onClick={handleClick}
          >
            {I18n('Common.Add')}
          </Button>
        ) : (
          <></>
        )}
      </GridToolbarContainer>
    );
  }

  const validate = (values: any) => {
    if ('type' in values) {
      if (values.type.value === undefined ? !values.type : !values.type.value) {
        return 'Type ' + i18nMessages.fieldRequired;
      }
    }
    if ('status' in values) {
      if (!values.status) {
        return 'Status ' + i18nMessages.fieldRequired;
      }
    }
    return '';
  };

  const submit = async (values: any) => {
    var validateMessage = '';
    validateMessage = validate(values);
    if (validateMessage === '') {
      try {
        const result = await saveTracking(values && values.id ? values.id : 0, {
          id: values.id,
          entityId: props.entityId,
          entityType: props.entityType,
          isManualEntry: true,
          notes: values.notes,
          status: values.selectedStatus.label,
          type:
            values && values?.type && values?.type.value
              ? +values.type.value
              : values?.type.value === undefined
              ? +values.type
              : 0,
          statusChangeDate: moment(values.statusChangeDate).utc().format(),
        } as OrderTrackingResponseDto);

        if (!isEdit && result.result) {
          showMessageRefresh();
        } else if (isEdit && result.result) {
          showMessageRefresh();
        } else {
          validateMessage = i18nMessages.errorOccurred;
        }
      } catch (error: any) {
        validateMessage = i18nMessages.errorOccurred;
      }
    }
    return validateMessage;
  };
  const showMessageRefresh = () => {
    toast.success(i18nMessages.savedSuccessfully);
  };

  async function handleProcessRowUpdate(newRow: GridRowModel) {
    let updatedRow: any = {};
    if (newRow.id === undefined) {
      updatedRow = { ...newRow, isNew: false, id: 0, isManualEntry: true };
    } else {
      updatedRow = { ...newRow, isNew: false, isManualEntry: true };
    }

    setAutoFocusOnStatus(false);
    setAutoFocusOnType(false);
    setOpen(false);

    var validation = await submit(updatedRow);

    if (validation === '') {
      if (newRow.id === undefined) {
        var temp: any = [];
        for (var i = 0; i < gridData.rows.length; i++) {
          temp.push(gridData.rows[i]);
        }
        temp.push(updatedRow);
        updateGridData('rows', temp);
        setRefreshGrid(!refreshGrid);
        return updatedRow;
      } else {
        updateGridData(
          'rows',
          gridData.rows.map((row: any) =>
            row.id === newRow.id ? updatedRow : row
          )
        );
        setRefreshGrid(!refreshGrid);
        return updatedRow;
      }
    } else {
      if (updatedRow.id === 0) {
        var temp1: any = [];
        for (let i = 0; i < gridData.rows.length; i++) {
          temp1.push(gridData.rows[i]);
        }

        var existingRecord = {
          ...updatedRow,
          isNew: true,
          isManual: true,
        };
        updatedRow.isNew = true;
        updatedRow.isManual = true;
        temp1.push(updatedRow);
        updateGridData('rows', temp1);
        updateGridData('totalRows', temp1.length);
        setTimeout(() => {
          var oldData: any = [];
          for (var i = 0; i < temp1.length; i++) {
            if (temp1[i].id == 0) {
              oldData.push(existingRecord);
            } else {
              oldData.push(temp1[i]);
            }
          }
          updateGridData('rows', oldData);

          setTimeout(() => {
            setRowModesModel({
              ...rowModesModel,
              0: {
                mode: GridRowModes.Edit,
                fieldToFocus: 'statusChangeDate',
              },
            });
          }, 500);
        }, 500);
      } else {
        setTimeout(() => {
          setRowModesModel({
            ...rowModesModel,
            [updatedRow.id]: {
              mode: GridRowModes.Edit,
              fieldToFocus: 'statusChangeDate',
            },
          });
        }, 500);
      }
      toast.error(validation);
      return updatedRow;
    }
  }

  const handleProcessRowUpdateError = (error: Error) => {};

  const keydown = (event: any) => {
    if (event.altKey && event.keyCode == 65) {
      var addButton = document.getElementById('addRecord');
      addButton?.click();
    }
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    deleteOrderTracking(id);
  };

  const handleSaveClick = (id: GridRowId) => () => {
    updateGridData(
      'rows',
      gridData.rows.filter(function (row: any) {
        return row.id !== 0;
      })
    );
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
    setAutoFocusOnStatus(false);
    setAutoFocusOnType(false);
    setOpen(false);

    const editedRow: any = gridData.rows.find((row: any) => row.id === id);
    if (editedRow!.isNew) {
      updateGridData(
        'rows',
        gridData.rows.filter((row: any) => row.id !== id)
      );
    }
    getTrackingList();
  };

  const handleEditClick = (id: GridRowId) => () => {
    setIsEdit(true);
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    setAutoFocusOnStatus(true);
  };

  const setModels = (newModel: any) => {
    // to stop set Row Models from  type dropdown Change
    var result = false;
    Object.keys(newModel).forEach(function (key) {
      var value = newModel[key];
      if (
        (value.field === 'type' || value.field === 'selectedStatus') &&
        value.cellToFocusAfter === undefined
      ) {
        result = true;
      }
    });
    if (result) {
      return;
    }

    if (newModel[0] === undefined) {
      setRowModesModel(newModel);
    } else if (
      newModel[0] !== undefined &&
      newModel[0].cellToFocusAfter === 'right'
    ) {
      updateGridData(
        'rows',
        gridData.rows.filter(function (row: any) {
          return row.id !== 0;
        })
      );
      setRowModesModel(newModel);
    }
  };
  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    if (!params.row.isManualEntry || !canEdit) {
      event.defaultMuiPrevented = true;
    }
  };

  const columns: GridColumns<Row> = [
    {
      field: 'statusChangeDate',
      headerName: I18n('Tracking.StatusChangeDate'),
      valueFormatter: (params) =>
        Utilities.convertHazgoDataTimeFormat(params.value),
      flex: 1,
      editable: true,
      renderEditCell: (params) => {
        return <DateEditComponent {...params} />;
      },
    },
    {
      field: 'selectedStatus',
      headerName: I18n('Tracking.Status'),
      width: 200,
      editable: true,
      renderEditCell: (params) => {
        return <StatusEditComponent {...params} />;
      },
      renderCell: (params: any) => {
        return (
          <Tooltip title={params.row.status}>
            <div>{params.row.status}</div>
          </Tooltip>
        );
      },
    },
    {
      field: 'type',
      headerName: I18n('Tracking.Type'),
      editable: true,
      renderCell: (
        params: GridCellParams<any, OrderTrackingResponseDto, any>
      ) => {
        return getTrackingType(params.value);
      },
      width: 200,
      renderEditCell: (params) => {
        return <TypeEditComponent {...params} />;
      },
    },
    {
      field: 'notes',
      headerName: I18n('Tracking.Notes'),
      editable: true,
      width: 200,
      renderEditCell: (params) => {
        return <NoteEditComponent {...params} />;
      },
    },
    {
      field: 'actions',
      headerName: I18n('Common.Actions'),
      type: 'actions',
      width: 150,
      getActions: (params: any) => {
        const actionButtons = [];
        if (params.row.isManualEntry) {
          const isInEditMode =
            rowModesModel[params.id]?.mode === GridRowModes.Edit;
          if (isInEditMode) {
            actionButtons.push(
              <GridActionsCellItem
                onPointerEnterCapture={false}
                onPointerLeaveCapture={false}
                placeholder={''}
                icon={<SaveIcon />}
                label="Save"
                onClick={handleSaveClick(params.id)}
              />
            );
            actionButtons.push(
              <GridActionsCellItem
                onPointerEnterCapture={false}
                onPointerLeaveCapture={false}
                placeholder={''}
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(params.id)}
                color="inherit"
              />
            );
          } else {
            if (canEdit) {
              actionButtons.push(
                <GridActionsCellItem
                  onPointerEnterCapture={false}
                  onPointerLeaveCapture={false}
                  placeholder={''}
                  icon={<EditIcon />}
                  label="Edit"
                  className="textPrimary"
                  onClick={handleEditClick(params.id)}
                  color="inherit"
                />
              );
            }
            if (canDelete) {
              actionButtons.push(
                <GridActionsCellItem
                  onPointerEnterCapture={false}
                  onPointerLeaveCapture={false}
                  placeholder={''}
                  icon={<DeleteIcon />}
                  label="Delete"
                  onClick={handleDeleteClick(params.id)}
                  color="inherit"
                />
              );
            }
          }
        }
        return actionButtons;
      },
    },
  ];

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    if (sortModel && sortModel.length) {
      updateGridData('sortBy', sortModel[0].field);
      updateGridData(
        'sortOrder',
        sortModel[0].sort === 'asc'
          ? OrderByDirection.Ascending
          : OrderByDirection.Descending
      );
    }
  }, []);

  const handleClose = () => {
    setDeleteModal(false);
  };

  const handleOk = () => {
    setDeleteModal(false);
    deleteData(selectedId);
  };

  const handleCloseForAdd = () => {
    setEditData(null);
    setAddModal(false);
  };

  const i18nMessages = {
    editedSuccessfully: I18n('Tracking.UpdateSuccess'),
    deletedSuccessfully: I18n('Tracking.DeleteSuccess'),
    errorOccurred: I18n('ProductCatalogues.ProductConsumptions.ErrorOccurred'),
    add: I18n('Tracking.Add'),
    edit: I18n('Tracking.Edit'),
    internal: I18n('Tracking.Internal'),
    external: I18n('Tracking.External'),
    fieldRequired: I18n('ContactPerson.FieldRequired'),
    savedSuccessfully: I18n('Common.SavedSuccessfully'),
    CommonSaveSuccessMsg: I18n('Common.SavedSuccessfully'),
    CommonErrOccuredMsg: I18n('Common.ErrorOccurred'),
  };

  const deleteData = async (id: number) => {
    try {
      const result = await deleteOrderTrackingApi(id);
      if (result.data) {
        getTrackingList();
        toast.success(i18nMessages.deletedSuccessfully);
      } else {
        toast.error(i18nMessages.errorOccurred);
      }
    } catch (error: any) {
      toast.error(i18nMessages.errorOccurred);
    }
  };

  return (
    <div>
      <Typography variant="h5" className="inner-heading has-button">
        {I18n('ShippingOrders.Tabs.Tracking')}
        <div className="inner-btn-wrap">
          <div className="text-right container-search">
            <div className="search-control-outer">
              <CustomizedInputs
                margin="normal"
                displayLabel=""
                inputType="text"
                placeholderText="Search"
                handleChange={handleChangeBasicSearch}
                className="search-control small-form-control"
                adornmentValue={
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={faSearch} />
                  </InputAdornment>
                }
              />
            </div>
          </div>
        </div>
      </Typography>
      <div className="">
        <div className="content-block table-fix">
          <CustomDataGridInline
            rowCount={gridData.totalRows}
            data={gridData.rows}
            columns={columns}
            onSortModelChange={handleSortModelChange}
            pageNo={gridData.page}
            pageSize={gridData.pageSize}
            rowModesModel={rowModesModel}
            onRowModesModelChange={(newModel: any) => {
              setModels(newModel);
            }}
            onPageChange={handleChangePage}
            onPageSizeChange={handleChangePageSize}
            onRowEditStart={handleRowEditStart}
            rowsPerPageOptions={gridData.rowsPerPageOptions}
            initialState={{
              sorting: {
                sortModel: [{ field: 'updatedDate', sort: 'desc' }],
              } as GridSortingInitialState,
              pagination: {
                page: gridData.page,
                pageSize: gridData.pageSize,
              } as GridPaginationInitialState,
            }}
            components={{
              Toolbar: EditToolbar,
            }}
            componentsProps={{
              toolbar: { setRows, setRowModesModel },
            }}
            processRowUpdate={handleProcessRowUpdate}
            onProcessRowUpdateError={handleProcessRowUpdateError}
          ></CustomDataGridInline>

          <ConfirmationPopup
            isOpen={isDeleteModalOpen}
            data={selectedId}
            message={I18n('Tracking.DeleteMessage')}
            handleClose={handleClose}
            handleOk={handleOk}
            title={I18n('Tracking.DeleteTitle')}
          ></ConfirmationPopup>
          {isAddModalOpen && (
            <AddOrderTracking
              isOpen={isAddModalOpen}
              editData={editData}
              entityId={props.entityId}
              entityType={props.entityType}
              handleClose={handleCloseForAdd}
              title={editData == null ? i18nMessages.add : i18nMessages.edit}
              refreshData={refreshData}
            ></AddOrderTracking>
          )}
        </div>
      </div>
    </div>
  );
}

export default OrderTrackings;
