import {
  Box,
  Breadcrumbs,
  Button,
  FormControl,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import CreatedByandUpdatedBy from 'common/CreatedByandUpdatedBy/CreatedByandUpdatedBy';
import CustomSelectPaginate from 'common/CustomSelect/CustomSelectPaginate';
import FormAccordianSave from 'common/formControl/formAccordianSave';
import RadioGroupInput from 'common/RadioGroup/RadioGroupInput';
import Status from 'common/WoStatus/Status';
import environment from 'environment';
import {
  DecPackQuestions,
  DecPackQuestionsDescription,
  DecPackQuestionsDto,
  DnPWorkOrders,
} from 'models/deck-pack-orders';
import {
  BaseModel,
  DGDDescription,
  ActivityOwner,
  RadioGroupBaseModel,
  OrderType,
  WorkOrderStatus,
} from 'models/pagination-model';
import RouteEnum from 'models/RouteEnum';
import { Permission } from 'Permissions';
import React, { useState, useEffect, BaseSyntheticEvent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import I18n from 'utilities/i18n';
import { hasPermission } from 'utilities/protectedRoute';
import http from '../../../utilities/httpService';
import {
  getWorkOrderQuestions,
  getWorkOrderStatus,
} from '../DeckPackApiService';
import DecpackStatus from './DecpackStatus';
import { loadUserOptions } from '../DeckPackApiService';
function SecurityCheckRA() {
  const { woId, id } = useParams();
  let navigate = useNavigate();
  const [canEdit, setCanEdit] = useState(
    hasPermission(Permission.canEditDecPackOrders)
  );
  const [isStatusSaveCallApi, setIsStatusSaveCallApi] =
    useState(false); /* To call Submit APi Status from Status Component */
  const [validateStatus, setValidateStatus] = useState<boolean>(false);

  const [isSaveNext, setIsSaveNext] = useState<boolean>(true);
  const [details, setDetails] = useState<any>({
    createdBy: '',
    createdDate: '',
    updatedBy: '',
    updatedDate: '',
  });
  const [assigneeData, setAssigneeData] = useState<any>({
    assignedToId: '',
    assignedToName: '',
    assignNextToId: '',
    assignNextToName: '',
  });
  const [isMobile, setIsMobile] = useState(false);
  const breadcrumbs = [
    <Typography key="3" color="text.primary">
      {I18n('Nav.Order')}
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={() => navigate(RouteEnum.DecPackOrdersList)}
        className="cpointer"
      >
        {I18n('Nav.DecPackOrders')}
      </Link>
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={() => navigate(RouteEnum.ViewDecPackOrders + '/' + id + '/1')}
        className="cpointer"
      >
        {I18n('ShippingOrders.WorkOrders')}
      </Link>
    </Typography>,
  ];
  const defaultValue = {
    value: '0',
    label: I18n('Placeholders.Select'),
  } as BaseModel;
  const i18nMessages = {
    Information: I18n('ShippingOrders.Information'),
    InformationRequired: I18n('ShippingOrders.InformationRequired'),
    Status: I18n('ShippingOrders.Status'),
    DGPlus: I18n('ShippingOrders.DGPlus'),
    EuDatabase: I18n('ShippingOrders.EuDatabase'),
    statusSavedSuccess: I18n('Common.SavedSuccessfully'),
    commonFieldIsRequired: I18n('Common.FieldIsRequired'),
    somethingWentWrong: I18n('Common.SomethingWentWrong'),
    title: I18n('DecPackOrders.WorkOrder.SecurityCheckTitle'),
  };
  const [status, setStatus] = useState('');
  const [questions, setQuestions] = useState<string[]>([]);

  const defaultAdditional: any = {
    page: 1,
  };

  const [answers, setAnswers] = useState([
    {
      decPackQuestionId: 0,
      decPackQuestionKey: DecPackQuestions.WOSecurityCheckQA1,
      answer: false,
      decPackInitialOrderId: Number(id),
      decPackWorkOrdersId: Number(woId),
    },
    {
      decPackQuestionId: 0,
      decPackQuestionKey: DecPackQuestions.WOSecurityCheckQA2,
      answer: false,
      decPackInitialOrderId: Number(id),
      decPackWorkOrdersId: Number(woId),
    },
  ]);

  const [isAddMode, setIsAddMode] = useState(true);

  let entityWorkOrderStatusList: Array<BaseModel> = [];
  const getStatusList = () => {
    entityWorkOrderStatusList = [defaultValue];
    DGDDescription.forEach((value: string, key: number) => {
      entityWorkOrderStatusList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getStatusList();

  let masterQuestionListList: Array<BaseModel> = [];
  const getQuestionsList = () => {
    DecPackQuestionsDescription.forEach((value: string, key: number) => {
      masterQuestionListList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getQuestionsList();

  let answerList: Array<RadioGroupBaseModel> = [];
  const getAnswerList = () => {
    answerList.push({
      value: '1',
      label: I18n('Common.Yes'),
    } as RadioGroupBaseModel);
    answerList.push({
      value: '0',
      label: I18n('Common.No'),
    } as RadioGroupBaseModel);
  };
  getAnswerList();

  const setAnswerData = (value: boolean, questionId: number) => {
    var temp: any = [];
    for (var i = 0; i < answers.length; i++) {
      if (answers[i].decPackQuestionKey == questionId) {
        answers[i].answer = Boolean(Number(value));
        temp.push(answers[i]);
      } else {
        temp.push(answers[i]);
      }
    }
    setAnswers(temp);
  };

  const InformationData = () => {
    return (
      <>
        {questions.map((question: any) => (
          <RadioGroupInput
            displayLabel={question.name}
            name={`question${question.id}`}
            value={Number(
              answers.find((x) => x.decPackQuestionKey == question.id)?.answer
            )}
            handleChange={(event: BaseSyntheticEvent) => {
              setAnswerData(event.target.value, question.id);
            }}
            options={answerList}
            readOnly={!canEdit}
          ></RadioGroupInput>
        ))}
        <Grid item lg={4} md={8}>
          <FormControl>
            <Box sx={{ mt: 3 }}>
              <Button
                variant="contained"
                onClick={() =>
                  window.open(
                    'https://ecas.ec.europa.eu/cas',
                    '_blank',
                    'toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=4000,height=4000'
                  )
                }
                disabled={!canEdit}
              >
                {i18nMessages.EuDatabase}
              </Button>
            </Box>
          </FormControl>
        </Grid>
      </>
    );
  };

  const getDecPackSecurityCheckWO = async () => {
    let decPackInitialOrderId = Number(id);
    let decPackWorkOrdersId = Number(woId);

    const apiUrl = new URL(
      environment.api.baseUrl +
        'DecPackWOSecurityCheck/' +
        decPackInitialOrderId +
        '/' +
        decPackWorkOrdersId
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result && result.data.length > 0) {
        var temp: any = [];

        result.data.forEach((element: any) => {
          for (var i = 0; i < answers.length; i++) {
            if (answers[i].decPackQuestionKey == element.questionKey) {
              answers[i].answer = element.answer;
              temp.push(answers[i]);
              break;
            }
          }
        });
        setAnswers(temp);
        setIsAddMode(false);
      }
    } catch (error) {}
  };

  const getWOQuestions = async () => {
    try {
      const response = await getWorkOrderQuestions(2);
      if (response && response.result) {
        setQuestions(
          (response.data as Array<DecPackQuestionsDto>).map((x) => {
            const questionValue = masterQuestionListList.find(
              (y) => +y.value == x.questionKey
            );
            return {
              id: questionValue?.value,
              name: questionValue?.label,
            } as any;
          })
        );

        // Set Question Id to the Answers
        for (let i = 0; i < response.data.length; i++) {
          let answer = answers.find(
            (x) => x.decPackQuestionKey == response.data[i].questionKey
          );
          if (answer) {
            answer.decPackQuestionId = response.data[i].id;
          }
        }
        setAnswers(answers);
        getDecPackSecurityCheckWO();
      }
    } catch (error) {
      toast.error(i18nMessages.somethingWentWrong);
    }
  };

  const getWOStatus = async () => {
    try {
      const response = await getWorkOrderStatus(
        DnPWorkOrders.WOS,
        id ? +id : 0
      );
      if (response && response.result) {
        setStatus(response.data.status);
        setDetails({
          ...details,
          createdBy: response.data.createdBy,
          updatedBy: response.data.updatedBy,
          createdDate: response.data.createdDate,
          updatedDate: response.data.updatedDate,
        });

        setAssigneeData({
          ...assigneeData,
          assignedToId: response.data.assignedToId,
          assignedToName: response.data.assignedToName,
          assignNextToId: response.data.assignedNextToId,
          assignNextToName: response.data.assignedNextToName,
        });

        setIsMobile(response.data.isMobile);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getWOQuestions();
    getWOStatus();
  }, []);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setValidateStatus(true);
    setIsSaveNext(false);
  };
  const validate = () => {
    let isNotValid = answers.some((x: any) => x.answer == undefined);
    if (isNotValid) {
      toast.error(i18nMessages.InformationRequired);
      return false;
    } else {
      return true;
    }
  };
  const handleSuccessStatusSave = async () => {
    let apiUrl;
    let result;
    try {
      let isValid =
        status == WorkOrderStatus.Done.toString() ? validate() : true;
      if (isValid) {
        if (isAddMode) {
          apiUrl = new URL(environment.api.baseUrl + 'DecPackWOSecurityCheck');
          result = await http.post(apiUrl.toString(), answers);
        } else {
          apiUrl = new URL(
            environment.api.baseUrl +
              'DecPackWOSecurityCheck/' +
              id +
              '/' +
              woId
          );
          result = await http.put(apiUrl.toString(), answers);
        }
        if (result) {
          // Make a call to change status
          setIsStatusSaveCallApi(true);

          toast.success(i18nMessages.statusSavedSuccess);
          handleErrorStatusSave(false);
          getDecPackSecurityCheckWO();
        }
      } else {
        handleErrorStatusSave(false);
      }
    } catch (error: any) {
      handleErrorStatusSave(false);
    }
  };

  /* Validate Status Dropdown Error call from Status Component */
  const handleErrorStatusSave = (data: any) => {
    setValidateStatus(false);
    setIsStatusSaveCallApi(false);
  };
  const onSaveNextClick = async (event: any) => {
    event.preventDefault();
    setStatus(WorkOrderStatus.Done.toString());
    setValidateStatus(true);
    setIsSaveNext(true);
  };

  const refreshData = async () => {
    handleErrorStatusSave(false);
    getWOStatus();
  };

  return (
    <>
      <div className="heading-section">
        <div className="heading-section-left">
          <Typography variant="h3" sx={{ mb: 3 }}>
            {i18nMessages.title}
          </Typography>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>
      </div>
      <div className="main-content-section">
        <FormAccordianSave
          title={i18nMessages.Information}
          className="inner-heading"
          details={InformationData()}
        ></FormAccordianSave>
        <Grid item lg={12} md={12}>
          <DecpackStatus
            permission={Permission.canEditDecPackOrders}
            woId={2}
            activityOwner={ActivityOwner.BackOffice}
            orderId={id}
            statusValue={status}
            orderType={OrderType.DecPackOrder}
            isStatusSaveCallApi={isStatusSaveCallApi}
            validateStatus={validateStatus}
            handleSuccessStatusSave={handleSuccessStatusSave}
            handleErrorStatusSave={handleErrorStatusSave}
            isSaveNext={isSaveNext}
            isMobile={isMobile}
            workOrderNo={DnPWorkOrders.WOS}
            assigneeData={assigneeData}
            handleStatusChange={(value: number) => setStatus(value.toString())}
          />
        </Grid>
        <div className="btn-wrap form-btn-wrap">
          {canEdit ? (
            <Button
              color="primary"
              disableElevation
              variant="contained"
              onClick={onSaveNextClick}
            >
              {I18n('Common.SaveNext')}
            </Button>
          ) : (
            <></>
          )}
          &nbsp; &nbsp; &nbsp;
          {canEdit ? (
            <Button
              type="submit"
              color="primary"
              disableElevation
              variant="contained"
              onClick={handleSubmit}
            >
              {I18n('Common.Save')}
            </Button>
          ) : (
            <></>
          )}
        </div>
        <CreatedByandUpdatedBy
          createdBy={details.createdBy}
          createdDate={details.createdDate}
          updatedBy={details.updatedBy}
          updatedDate={details.updatedDate}
        ></CreatedByandUpdatedBy>
      </div>
    </>
  );
}

export default SecurityCheckRA;
