import I18n from 'utilities/i18n';
import { useEffect, useCallback, useState } from 'react';
import http from '../../utilities/httpService';
import {
  BaseModel,
  BillableMaterialTypeDescription,
  MasterCategoryEnumType,
  OrderByDirection,
  AirlineRestrictionModule,
  PaginationResponseModel,
  StateRestrictionModule,
  EntityStatus,
  PartnerType,
  CategorySelect,
  ShippingOrder,
  ActivityOwner,
  OrderType,
} from 'models/pagination-model';
import {
  Button,
  FormControl,
  Tooltip,
  Link,
  Breadcrumbs,
  Grid,
  Typography,
  Box,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CalculateIcon from '@mui/icons-material/Calculate';
import environment from 'environment';
import { toast } from 'react-toastify';
import {
  GridActionsCellItem,
  GridColumns,
  GridEventListener,
  GridPaginationInitialState,
  GridPreProcessEditCellProps,
  GridRenderEditCellParams,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridRowsProp,
  GridSortingInitialState,
  GridSortModel,
  GridToolbarContainer,
  MuiEvent,
  useGridApiContext,
} from '@mui/x-data-grid';
import ConfirmationPopup from 'common/confirmationPopup/confirmationPopup';
import AddshippingMaterial from './AddShippingMaterial';
import { hasPermission } from 'utilities/protectedRoute';
import { Permission } from 'Permissions';
import CustomDataGridInline from 'common/datagrid/CustomDataGridInline';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';
import CustomizedSelect from 'common/CustomSelect/CustomSelect';
import CustomSelectPaginate from 'common/CustomSelect/CustomSelectPaginate';
import NumericInput from 'common/NumericInput/NumericInput';
import DecimalInput from 'common/DecimalInput/DecimalInput';
import CustomizedInputs from 'common/formControl/formControl';
import { useNavigate, useParams } from 'react-router-dom';
import { Customer } from 'models/service-catalogue';
import RouteEnum from 'models/RouteEnum';
import Status from '../../common/WoStatus/Status';
import CreatedByandUpdatedBy from 'common/CreatedByandUpdatedBy/CreatedByandUpdatedBy';
import CustomSelectPaginateAdd from 'common/CustomSelect/CustomSelectPaginateAdd';
import {
  loadLocationOptions,
  loadAirlineOptions,
} from '../deckpack-orders/AddDecpackApiService';
import FormAccordianSave from 'common/formControl/formAccordianSave';
import AirlineRestrictionPopup from 'components/Airline/AirlineRestrictionPopup';
import StateRestrictionPopup from 'common/State/StateRestrictionPopup';

function ShippingOrderBooking(props: any) {
  let { woId, id } = useParams();
  let shippingId = id;
  let navigate = useNavigate();

  const breadcrumbs = [
    <Typography key="3" color="text.primary">
      {I18n('Nav.Order')}
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={() => navigate(RouteEnum.ShippingOrdersList)}
        className="cpointer"
      >
        {I18n('Nav.ShippingOrders')}
      </Link>
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={() => navigate(RouteEnum.ViewShippingOrders + '/' + id + '/2')}
        className="cpointer"
      >
        {I18n('ShippingOrders.WorkOrders')}
      </Link>
    </Typography>,
  ];

  let isAddShippingMaterial = false;
  const [isDeleteModalOpen, setDeleteModal] = useState(false);
  const [selectedId, setId] = useState();
  const [isAddModalOpen, setAddModal] = useState(false);
  const [rows, setRows] = useState([]);
  const [addRow, setAddRow] = useState<any>([]);
  const [editData, setEditData] = useState(null);
  const [isRestrictionSaveCallApi, setIsRestrictionSaveCallApi] =
    useState(false);
  const [isStatusSaveCallApi, setIsStatusSaveCallApi] = useState(false); // To call Submit APi Status from Status Component
  const [validateStatus, setValidateStatus] = useState<boolean>(false); // validateStatus - To validate Status Dropdown from Status Component
  const [gridData, setGridData] = useState({
    sortOrder: OrderByDirection.Descending,
    sortBy: 'CreatedDate',
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [10, 20, 50, 100],
    pageSize: 10,
    page: 0,
    searchExpression: '',
  });
  const [canEdit, setCanEdit] = useState(
    hasPermission(Permission.canEditShippingOrders)
  );
  const [showRestriction, setShowRestriction] = useState(false);

  const [details, setDetails] = useState<any>({
    createdBy: '',
    createdDate: '',
    updatedBy: '',
    updatedDate: '',
  });

  const defaultValue = {
    value: '',
    label: I18n('Placeholders.Select'),
  } as BaseModel;
  const defaultAdditional: any = {
    page: 1,
  };
  const [tranpostTypes, setTransportTypes] = useState(defaultValue);

  const defaultPartnerValue = {
    value: '',
    label: I18n('Placeholders.SelectPartner'),
  } as BaseModel;

  const defaultServiceValue = {
    value: '',
    label: I18n('Placeholders.SelectService'),
  } as BaseModel;

  const [values, setValues] = useState<any>({
    partner: defaultPartnerValue,
    service: defaultServiceValue,
    trackingNumber: '',
    cost: '',
  });
  const defaultValueForService = {
    value: '',
    label: I18n('Placeholders.Select'),
  } as BaseModel;

  var shippingOrderId = Number(id);
  var workOrderId = Number(woId);

  const [restrictions, setRestrictions] = useState<any>({
    airline: defaultValueForService,
    airlineId: defaultValueForService.value,
    transitDestination: defaultValueForService,
    transitDestinationId: defaultValueForService.value,
    destinationCountryName: '',
    destinationCountryId: null,
    shippingWorkOrderId: workOrderId,
    shippingOrderId,
  });
  const [isOpenAirline, setIsOpenAirline] = useState(false);
  const [isOpenState, setIsOpenState] = useState(false);
  const [stateIds, setStateIds] = useState<number[]>([]);

  const [cacheUniq, setCacheUniq] = useState(0);
  const [openState, setOpenState] = useState(false);
  const [openAirline, setOpenAirline] = useState(false);
  const [rowDataState, setRowDataState] = useState({});
  const [rowDataAirline, setRowDataAirline] = useState({});

  let langList: Array<BaseModel> = [];
  CategorySelect.forEach((value: string, key: number) => {
    langList.push({
      value: key.toString(),
      label: I18n(value),
    } as BaseModel);
  });

  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));

  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const getBookingList = async () => {
    const apiUrl = new URL(environment.api.baseUrl + 'ShippingWOBooking');
    try {
      var finalSearchTerm = `shippingWorkOrderId~=~${woId} and shippingOrderId~=~${id}`;
      apiUrl.searchParams.set('$filter', finalSearchTerm);
      apiUrl.searchParams.set(
        '$skip',
        (gridData.page * gridData.pageSize).toString()
      );
      apiUrl.searchParams.set('$top', gridData.pageSize.toString());
      apiUrl.searchParams.set(
        '$orderbydirection',
        gridData.sortOrder.toString()
      );

      const result = await http.get(apiUrl.toString());
      if (result) {
        for (var i = 0; i < result.data.values.length; i++) {
          result.data.values[i].selectedPartner = {
            value: result.data.values[i].partnerId,
            label: result.data.values[i].partnerName,
          };
          result.data.values[i].selectedService = {
            value: result.data.values[i].serviceCategoryId,
            label: getServiCategoryName(
              +result.data.values[i].serviceCategoryId
            ),
          };
        }
        updateGridData('totalRows', result.data.totalCount);
        updateGridData('rows', result.data.values);
        let showRestrictionSec: any = result.data.values.some(
          (row: any) => row.serviceCategoryId == 1 // Air
        );
        setShowRestriction(showRestrictionSec);
      } else {
        setShowRestriction(false);
      }
    } catch (error) {}
  };

  const keydown = (event: any) => {
    if (event.altKey && event.keyCode == 65) {
      var addButton = document.getElementById('addRecord');
      addButton?.click();
    }
  };

  const getShippingWO = async () => {
    var shippingOrderId = Number(id);
    var WOID = ShippingOrder.Booking;

    const apiUrl = new URL(
      environment.api.baseUrl +
        'Shipping/GetShippingWO/' +
        shippingOrderId +
        '/' +
        WOID
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result) {
        setValues({
          ...values,
          status: result.data.status,
        });
        setDetails({
          ...details,
          createdBy: result.data.createdBy,
          updatedBy: result.data.updatedBy,
          createdDate: result.data.createdDate,
          updatedDate: result.data.updatedDate,
        });
      }
    } catch (error) {}
  };

  useEffect(() => {
    getShippingWO();
    document.addEventListener('keydown', keydown, false);
    getBookingList();
    getBookingRestriction();
  }, [
    gridData.page,
    gridData.pageSize,
    gridData.sortBy,
    gridData.sortOrder,
    gridData.searchExpression,
    props.shippingOrderId,
  ]);

  const handleChangePage = (pageNo: number) => {
    updateGridData('page', pageNo);
  };

  const handleChangePageSize = (pageSize: number) => {
    updateGridData('pageSize', pageSize);
  };
  type Row = (typeof gridData.rows)[number];
  const deleteShippingMaterial = (id: any) => {
    setDeleteModal(true);
    setId(id);
  };

  async function handleProcessRowUpdate(newRow: GridRowModel) {
    let updatedRow: any = {};
    if (newRow.id === undefined) {
      updatedRow = { ...newRow, isNew: false, id: 0 };
    } else {
      updatedRow = { ...newRow, isNew: false };
    }

    var validation = await save(updatedRow);

    if (validation === '') {
      if (newRow.id === undefined) {
        var temp: any = [];
        for (var i = 0; i < gridData.rows.length; i++) {
          temp.push(gridData.rows[i]);
        }

        updatedRow.partnerName = updatedRow?.partnerName?.label;
        updatedRow.serviceCategoryId = updatedRow?.serviceCategoryId?.label;
        updatedRow.trackingNumber = updatedRow?.trackingNumber;
        updatedRow.totalCost = updatedRow?.totalCost;
        updatedRow.supplierCost = updatedRow?.supplierCost;

        temp.push(updatedRow);
        updateGridData('rows', temp);
        updateGridData(
          'searchExpression',
          (Math.random() + 1).toString(36).substring(7)
        );
        return updatedRow;
      } else {
        updateGridData(
          'rows',
          gridData.rows.map((row: any) =>
            row.id === newRow.id ? updatedRow : row
          )
        );

        updateGridData(
          'searchExpression',
          (Math.random() + 1).toString(36).substring(7)
        );
        return updatedRow;
      }
    } else {
      if (updatedRow.id === 0) {
        var temp1: any = [];
        for (let i = 0; i < gridData.rows.length; i++) {
          temp1.push(gridData.rows[i]);
        }

        var existingRecord = {
          ...updatedRow,
          isNew: true,
          isManual: true,
        };

        updatedRow.isNew = true;
        updatedRow.isManual = true;
        updatedRow.partnerName = updatedRow?.partnerName?.label;
        updatedRow.serviceCategoryId = updatedRow?.serviceCategoryId?.label;
        updatedRow.trackingNumber = updatedRow?.trackingNumber;
        updatedRow.totalCost = updatedRow?.totalCost;
        updatedRow.supplierCost = updatedRow?.supplierCost;

        temp1.push(updatedRow);
        updateGridData('rows', temp1);
        updateGridData('totalRows', temp1.length);
        setTimeout(() => {
          var oldData: any = [];
          for (var i = 0; i < temp1.length; i++) {
            if (temp1[i].id == 0) {
              oldData.push(existingRecord);
            } else {
              oldData.push(temp1[i]);
            }
          }
          updateGridData('rows', oldData);

          setTimeout(() => {
            setRowModesModel({
              ...rowModesModel,
              0: {
                mode: GridRowModes.Edit,
                fieldToFocus: 'materialTypeDescription',
              },
            });
          }, 500);
        }, 500);
      } else {
        setTimeout(() => {
          setRowModesModel({
            ...rowModesModel,
            [updatedRow.id]: {
              mode: GridRowModes.Edit,
              fieldToFocus: 'materialTypeDescription',
            },
          });
        }, 500);
      }
      toast.error(validation);
      return updatedRow;
    }
  }

  const handleProcessRowUpdateError = useCallback((error: Error) => {
    //toast.error(error.message);
  }, []);

  const handleEditClick = (row: any) => () => {
    setValues({
      ...values,
      partner: {
        value: row?.row?.partnerId.toString(),
        label: row?.row?.partnerName,
      },
    });
    setRowModesModel({
      ...rowModesModel,
      [row.id]: { mode: GridRowModes.Edit },
    });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    updateGridData(
      'rows',
      gridData.rows.filter(function (row: any) {
        return row.id !== 0;
      })
    );
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    deleteShippingMaterial(id);
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow: any = gridData.rows.find((row: any) => row.id === id);
    if (editedRow!.isNew) {
      updateGridData(
        'rows',
        gridData.rows.filter((row: any) => row.id !== id)
      );
    }
    getBookingList();
  };

  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    if (!params.row.isManual) {
      event.defaultMuiPrevented = true;
    }
  };

  interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (
      newModel: (oldModel: GridRowModesModel) => GridRowModesModel
    ) => void;
  }

  function EditToolbar(props: EditToolbarProps) {
    const handleClick = () => {
      var existingRow = gridData.rows.find((x: any) => x.id === 0);

      if (existingRow === undefined) {
        const id = 0;
        var temp: any = [];
        for (let i = 0; i < gridData.rows.length; i++) {
          temp.push(gridData.rows[i]);
        }
        temp.push({
          id: 0,
          partner: defaultPartnerValue,
          service: defaultServiceValue,
          trackingNumber: '',
          cost: '',
          isManual: true,
          isNew: true,
        });
        updateGridData('rows', temp);
        updateGridData('totalRows', temp.length);
        setRowModesModel((oldModel) => ({
          ...oldModel,
          [id]: {
            mode: GridRowModes.Edit,
            fieldToFocus: 'materialTypeDescription',
          },
        }));
      }
    };

    return (
      <GridToolbarContainer>
        {hasPermission(Permission.canAddShippingOrders) ? (
          <Button
            color="primary"
            id="addRecord"
            startIcon={<AddIcon />}
            onClick={handleClick}
          >
            {I18n('Common.Add')}
          </Button>
        ) : (
          <></>
        )}
      </GridToolbarContainer>
    );
  }

  const i18nMessages = {
    fieldRequired: I18n('ContactPerson.FieldRequired'),
    deletedSuccessfully: I18n('ContactPerson.DeletedSuccessfully'),
    errorOccurred: I18n('ContactPerson.ErrorOccurred'),
    notProcessed: I18n('ShippingMaterials.NotProcessed'),
    processedSuccessfully: I18n('ShippingMaterials.ProcessedSuccessfully'),
    savedSuccessfully: I18n('Common.SavedSuccessfully'),
    productQuantityNotAvailable: I18n(
      'ShippingMaterials.ProductQuantityNotAvailable'
    ),
    zeroNotAllowed: I18n('ShippingMaterials.ZeroNotAllowed'),
    BookingTitle: I18n('Booking.BookingTitle'),
    Air: I18n('Partner.CategoryTab.Air'),
    Road: I18n('Partner.CategoryTab.Road'),
    Train: I18n('Partner.CategoryTab.Train'),
    Information: I18n('Booking.Information'),
    Ship: I18n('Partner.CategoryTab.Ship'),
    Other: I18n('Partner.CategoryTab.Other'),
    Partner: I18n('Booking.Partner'),
    Service: I18n('Booking.Service'),
    TrackingNumber: I18n('Booking.TrackingNumber'),
    TotalCost: I18n('Booking.TotalCost'),
    TotalPrice: I18n('Booking.TotalPrice'),
    SupplierCost: I18n('Booking.SupplierCost'),
    Actions: I18n('Common.Actions'),
    Edit: I18n('Common.Edit'),
    Delete: I18n('Common.Delete'),
    EditBooking: I18n('Booking.EditBooking'),
    AddBooking: I18n('Booking.AddBooking'),
    deleteWorkOrderConformation: I18n('Booking.DeleteConfirmation'),
    deleteWorkOrderSuccess: I18n('Booking.DeleteSuccess'),
    deleteWorkOrderMessage: I18n('Booking.DeleteBooking'),
    WorkOrderAddSuccess: I18n('Booking.WorkOrderAddSuccess'),
    WorkOrderUpdateSuccess: I18n('Booking.WorkOrderUpdateSuccess'),
    UpdateSuccess: I18n('Booking.UpdateSuccess'),
    AddSuccess: I18n('Booking.AddSuccess'),
    dhlValue: I18n('Partner.DHL'),
    fedExValue: I18n('Partner.FedEx'),
    othersValue: I18n('Partner.Others'),
    calculate: I18n('Booking.Calculate'),
    Notes: I18n('Booking.Notes'),
    TransportType: I18n('Booking.TransportType'),
    TransitDestination: I18n('DecPack.TransitDestination'),
    FinalDestination: I18n('DecPack.FinalDestination'),
    Restrictions: I18n('Booking.Restrictions'),
    SelectPlaceHolder: I18n('Placeholders.Select'),
    Airline: I18n('Booking.Airline'),
    DestinationCountry: I18n('Booking.DestinationCountry'),
    TransitCountry: I18n('Booking.TransitCountry'),
    AirlineRestrictions: I18n('Booking.AirlineRestrictions'),
    CountryRestrictions: I18n('Booking.CountryRestrictions'),
    StateRestriction: I18n('State.StateRestrictions'),
  };

  function PartnerEditComponent(props: GridRenderEditCellParams) {
    const { id, value, field } = props;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const apiRef = useGridApiContext();

    const handleValueChange = async (newValue: BaseModel) => {
      setValues({
        ...values,
        partner: {
          value: newValue?.value,
          label: newValue?.label,
        },
      });
      apiRef.current.setEditCellValue({ id, field, value: newValue });

      var transportType = await loadServiceOptions(
        Number(newValue?.value),
        '',
        null,
        0
      );

      if (
        transportType &&
        transportType.options &&
        transportType.options.length == 1
      ) {
        setValues({
          ...values,
          service: transportType.options[0],
        });
        apiRef.current.setEditCellValue({
          id,
          field: 'selectedService',
          value: transportType.options[0],
        });
      } else {
        apiRef.current.setEditCellValue({
          id,
          field: 'selectedService',
          value: '',
        });
      }

      apiRef.current.setEditCellValue({
        id,
        field: 'trackingNumber',
        value: '',
      });
      apiRef.current.setEditCellValue({
        id,
        field: 'totalCost',
        value: '',
      });
      apiRef.current.setEditCellValue({
        id,
        field: 'supplierCost',
        value: '',
      });
    };
    var catergoryType: MasterCategoryEnumType;

    if (props?.row?.partnerName?.value) {
      catergoryType =
        MasterCategoryEnumType[
          props.row.partnerName.value as keyof typeof MasterCategoryEnumType
        ];
    }

    return (
      <>
        <CustomSelectPaginate
          autoFocus={true}
          key={props?.row?.partnerName?.value}
          additional={defaultAdditional}
          value={props?.row?.selectedPartner}
          isDisplayLabelClass={true}
          loadOptions={loadPartnerOptions}
          handleChange={handleValueChange}
          menuPortalTarget={document.body}
          styles={{
            menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
          }}
          placeholder={defaultValue.label}
          isSearchable={true}
          errorValue={true}
          debounceTimeout={500}
          isMultiSelect={false}
        />
      </>
    );
  }

  function ServiceEditComponent(props: GridRenderEditCellParams) {
    const { id, value, field } = props;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const apiRef = useGridApiContext();
    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue: any = event; // The new value entered by the user
      setValues({
        ...values,
        service: {
          value: newValue?.value,
          label: newValue?.label,
        },
      });
      apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    return (
      <CustomSelectPaginate
        key={props?.row?.serviceCategoryId?.value}
        additional={defaultAdditional}
        value={props?.row?.selectedService}
        loadOptions={(search: string, prevOptions: any, page: any) =>
          loadServiceOptions(
            values.partner && +values.partner.value ? +values.partner.value : 0,
            search,
            prevOptions,
            page
          )
        }
        handleChange={handleValueChange}
        isDisplayLabelClass={true}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
        }}
        placeholder={defaultValue.label}
        isSearchable={true}
        errorValue={true}
        debounceTimeout={500}
        isMultiSelect={false}
      />
    );
  }

  function TrackingNoEditComponent(props: GridRenderEditCellParams) {
    const { id, value, field } = props;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const apiRef = useGridApiContext();

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value; // The new value entered by the user
      apiRef.current.setEditCellValue({ id, field, value: newValue });
      // allow only in case of edit
    };
    return (
      <CustomizedInputs
        margin="normal"
        id="trackingNumber"
        name="trackingNumber"
        inputType="text"
        inputProps={{ maxLength: 50 }}
        multiline={true}
        handleChange={handleValueChange}
        value={props.row.trackingNumber}
      ></CustomizedInputs>
    );
  }

  function NoteComponent(props: GridRenderEditCellParams) {
    const { id, value, field } = props;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const apiRef = useGridApiContext();

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value; // The new value entered by the user
      apiRef.current.setEditCellValue({ id, field, value: newValue });
      // allow only in case of edit
    };
    return (
      <CustomizedInputs
        margin="normal"
        id="notes"
        name="notes"
        inputType="text"
        inputProps={{ maxLength: 50 }}
        multiline={true}
        handleChange={handleValueChange}
        value={props.row.notes}
      ></CustomizedInputs>
    );
  }

  function TotalCostEditComponent(props: GridRenderEditCellParams) {
    const { id, value, field } = props;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const apiRef = useGridApiContext();

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value; // The new value entered by the user
      apiRef.current.setEditCellValue({ id, field, value: newValue });
      // allow only in case of edit
    };

    const calculateCost = async () => {
      const catagoryId = props.row?.selectedService?.value;

      const apiUrl = new URL(
        environment.api.baseUrl +
          `Shipping/CalculateCost/${shippingId}/${catagoryId}`
      );
      try {
        const result = await http.get(apiUrl.toString());
        if (result) {
          apiRef.current.setEditCellValue({ id, field, value: result.data });
        }
      } catch (error) {
        apiRef.current.setEditCellValue({ id, field, value: 0 });
      }
    };

    return (
      <>
        <CustomizedInputs
          margin="normal"
          id="totalCost"
          name="totalCost"
          inputType="text"
          inputProps={{ maxLength: 50 }}
          multiline={true}
          handleChange={handleValueChange}
          value={props.row.totalCost}
        ></CustomizedInputs>
        {props.row?.selectedService ? (
          <Button onClick={calculateCost} title="Calculate Cost">
            <CalculateIcon />
          </Button>
        ) : (
          <></>
        )}
      </>
    );
  }

  function SupplierCostEditComponent(props: GridRenderEditCellParams) {
    const { id, value, field } = props;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const apiRef = useGridApiContext();

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value; // The new value entered by the user
      apiRef.current.setEditCellValue({ id, field, value: newValue });
      // allow only in case of edit
    };

    return (
      <>
        <CustomizedInputs
          margin="normal"
          id="supplierCost"
          name="supplierCost"
          inputType="text"
          inputProps={{ maxLength: 50 }}
          multiline={true}
          handleChange={handleValueChange}
          value={props.row.supplierCost}
        ></CustomizedInputs>
      </>
    );
  }

  const getPartnerTypeValue = (type: PartnerType) => {
    switch (type) {
      case PartnerType.DHL:
        return i18nMessages.dhlValue;
      case PartnerType.FedEx:
        return i18nMessages.fedExValue;
      default:
        break;
    }
  };

  const getPartnersByAllowedCategory = async (
    search: string,
    pageNo: number
  ) => {
    let response: PaginationResponseModel<Customer>;
    const apiUrl = new URL(
      environment.api.baseUrl + 'Partners/GetAllByCategoryTypes'
    );
    try {
      let searchExpression = 'Status~=~' + EntityStatus.Active;
      searchExpression += search ? ` and name~like~${search}` : '';
      apiUrl.searchParams.set('$filter', searchExpression);
      apiUrl.searchParams.set('$orderby', 'partnerType');
      apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
      apiUrl.searchParams.set('$top', '10');
      apiUrl.searchParams.set('$orderbydirection', 'asc');
      apiUrl.searchParams.set(
        'orderId',
        shippingId && shippingId !== undefined ? shippingId : '0'
      );
      const result = await http.get(apiUrl.toString());
      if (result.data && result.data.values.length) {
        result.data.values.forEach((z: any) => {
          var displayName =
            z.transportTypeId && +z.transportTypeId != 0
              ? z.transportTypeName + ' - '
              : '';
          displayName += z.name + ' - ';
          displayName +=
            +z.partnerType === PartnerType.Others && +z.partnerType !== 0
              ? ''
              : getPartnerTypeValue(+z.partnerType);
          z.name = displayName;
          // z.name =
          //   +z.partnerType === PartnerType.Others && +z.partnerType !== 0
          //     ? z.name
          //     : `${z.name} - ${getPartnerTypeValue(+z.partnerType)}`;
        });
      }
      if (result) {
        response = {
          TotalRecords: result.data.totalCount,
          Values: result.data.values,
        } as PaginationResponseModel<Customer>;
      } else {
        response = {
          TotalRecords: 0,
          Values: [],
        } as PaginationResponseModel<Customer>;
      }
    } catch (error) {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<Customer>;
    }
    return response;
  };
  const loadPartnerOptions = async (
    search: string,
    loadedOptions: any,
    { page }: any
  ) => {
    const response = await getPartnersByAllowedCategory(search, page);
    const hasMore = Math.ceil(response.TotalRecords / 10) > page;

    return {
      options: response.Values.map((x) => {
        return {
          value: x.id.toString(),
          label: x.name,
        } as BaseModel;
      }),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const getServices = async (
    partnerId: number,
    search: string,
    pageNo: number
  ) => {
    let response: PaginationResponseModel<Customer>;
    const apiUrl = new URL(environment.api.baseUrl + 'TransportCategories');
    try {
      let searchExpression = `PartnerId~=~` + partnerId;

      apiUrl.searchParams.set('$filter', searchExpression);
      apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
      apiUrl.searchParams.set('$top', '10');
      apiUrl.searchParams.set('$orderbydirection', 'desc');
      const result = await http.get(apiUrl.toString());
      if (result) {
        response = {
          TotalRecords: result.data.totalCount,
          Values: result.data.values,
        } as PaginationResponseModel<Customer>;
      } else {
        response = {
          TotalRecords: 0,
          Values: [],
        } as PaginationResponseModel<Customer>;
      }
    } catch (error) {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<Customer>;
    }
    return response;
  };

  const loadServiceOptions = async (
    partnerId: number,
    search: string,
    loadedOptions: any,
    { page }: any
  ) => {
    const cName = [] as any;
    const response = await getServices(partnerId, search, page);
    const hasMore = Math.ceil(response.TotalRecords / 10) > page;

    // apply text search on labels
    var searchResult = langList.filter(function (x: any) {
      return x.label.toLowerCase().includes(search.toLowerCase());
    });

    searchResult.forEach((x: any) => {
      response.Values.map((y: any) => {
        if (x.value == y.categoryId) {
          cName.push(x);
        }
      });
    });

    return {
      options: cName?.map((x: any) => {
        return {
          value: x.value.toString(),
          label: x.label,
        } as BaseModel;
      }),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const columns: GridColumns<Row> = [
    {
      field: 'selectedPartner',
      headerName: i18nMessages.Partner,
      sortable: false,
      editable: true,
      flex: 1,
      renderEditCell: (params) => {
        return <PartnerEditComponent {...params} />;
      },
      renderCell: (params: any) => {
        return params.row.partnerName;
      },
    },
    {
      field: 'selectedService',
      headerName: i18nMessages.TransportType,
      flex: 1,
      sortable: false,
      editable: true,
      renderCell: (params: any) => {
        return getServiCategoryName(+params.row.serviceCategoryId);
      },
      renderEditCell: (params) => {
        return rowModesModel[params.id]?.mode === GridRowModes.Edit ? (
          <ServiceEditComponent {...params} />
        ) : (
          getServiCategoryName(+params.row.serviceCategoryId)
        );
      },
    },
    {
      field: 'trackingNumber',
      headerName: i18nMessages.TrackingNumber,
      sortable: true,
      editable: true,
      flex: 1,
      renderEditCell: (params) => {
        return <TrackingNoEditComponent {...params} />;
      },
    },
    {
      field: 'totalCost',
      headerName: i18nMessages.TotalPrice,
      sortable: true,
      editable: true,
      flex: 1,
      renderEditCell: (params) => {
        return <TotalCostEditComponent {...params} />;
      },
    },
    {
      field: 'supplierCost',
      headerName: i18nMessages.SupplierCost,
      sortable: true,
      editable: true,
      flex: 1,
      renderEditCell: (params) => {
        return <SupplierCostEditComponent {...params} />;
      },
    },
    {
      field: 'notes',
      headerName: i18nMessages.Notes,
      sortable: true,
      editable: true,
      flex: 1,
      renderEditCell: (params) => {
        return <NoteComponent {...params} />;
      },
    },
    {
      field: 'actions',
      headerName: I18n('Common.Actions'),
      type: 'actions',
      width: 150,
      getActions: (params: any) => {
        const actionButtons = [];
        const isInEditMode =
          rowModesModel[params.id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          actionButtons.push(
            <GridActionsCellItem
              onPointerEnterCapture={false}
              onPointerLeaveCapture={false}
              icon={<SaveIcon />}
              placeholder={''}
              label="Save"
              onClick={handleSaveClick(params.id)}
            />
          );
          actionButtons.push(
            <GridActionsCellItem
              onPointerEnterCapture={false}
              onPointerLeaveCapture={false}
              icon={<CancelIcon />}
              placeholder={''}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(params.id)}
              color="inherit"
            />
          );
        } else {
          if (hasPermission(Permission.canEditShippingOrders)) {
            actionButtons.push(
              <GridActionsCellItem
                onPointerEnterCapture={false}
                onPointerLeaveCapture={false}
                icon={<EditIcon />}
                placeholder={''}
                label="Edit"
                className="textPrimary"
                onClick={handleEditClick(params)}
                color="inherit"
              />
            );
          }
          if (hasPermission(Permission.canDeleteShippingOrders)) {
            actionButtons.push(
              <GridActionsCellItem
                onPointerEnterCapture={false}
                onPointerLeaveCapture={false}
                icon={<DeleteIcon />}
                placeholder={''}
                label="Delete"
                onClick={handleDeleteClick(params.id)}
                color="inherit"
              />
            );
          }
        }
        return actionButtons;
      },
    },
  ];

  const getServiCategoryName = (serviceCategoryId: any) => {
    return serviceCategoryId === 1
      ? i18nMessages.Air
      : serviceCategoryId === 2
      ? i18nMessages.Road
      : serviceCategoryId === 3
      ? i18nMessages.Train
      : serviceCategoryId === 4
      ? i18nMessages.Ship
      : i18nMessages.Other;
  };

  const save = async (values: any) => {
    var validateMessage = '';
    validateMessage = validate(values);

    if (validateMessage === '') {
      const finalData = {
        shippingWorkOrderId: Number(woId),
        shippingOrderId: Number(id),
        partnerId: +values.selectedPartner.value,
        partnerName: values.selectedPartner.label,
        serviceCategoryId: values.selectedService.value,
        trackingNumber: values.trackingNumber,
        totalCost: Number(values.totalCost),
        supplierCost: Number(values.supplierCost),
        notes: values.notes,
      };
      const apiUrl =
        values.id === 0
          ? new URL(environment.api.baseUrl + 'ShippingWOBooking')
          : new URL(environment.api.baseUrl + 'ShippingWOBooking/' + values.id);

      try {
        const result: any =
          values.id === 0
            ? await http.post(apiUrl.toString(), finalData)
            : await http.put(apiUrl.toString(), finalData);

        if (result !== null && result.data != null) {
          toast.success(i18nMessages.savedSuccessfully);
          getBookingList();
        } else {
          validateMessage = i18nMessages.errorOccurred;
        }
      } catch (error: any) {
        validateMessage = i18nMessages.errorOccurred;
      }
    }
    return validateMessage;
  };

  const validate = (fieldValues: any) => {
    if ('selectedPartner' in fieldValues) {
      if (
        fieldValues.selectedPartner === undefined ||
        fieldValues.selectedPartner === ''
      ) {
        return 'Partner name ' + i18nMessages.fieldRequired;
      }
    }

    if ('selectedService' in fieldValues) {
      if (
        fieldValues.selectedService === undefined ||
        fieldValues.selectedService === ''
      ) {
        return 'Service  ' + i18nMessages.fieldRequired;
      }
    }

    if ('totalCost' in fieldValues) {
      if (fieldValues.totalCost === undefined || fieldValues.totalCost === '') {
        return 'Total cost ' + i18nMessages.fieldRequired;
      }
    }

    if ('supplierCost' in fieldValues) {
      if (
        fieldValues.supplierCost === undefined ||
        fieldValues.supplierCost === ''
      ) {
        return 'Supplier Cost ' + i18nMessages.fieldRequired;
      }
    }

    return '';
  };

  const handleClose = () => {
    setDeleteModal(false);
  };

  const handleCloseForAdd = () => {
    setEditData(null);
    setAddModal(false);
  };

  const handleOk = () => {
    setDeleteModal(false);
    deleteData(selectedId);
  };

  const addShippingMaterial = useCallback(() => {
    setAddModal(true);
  }, []);

  const deleteData = async (id: any) => {
    const apiUrl = new URL(environment.api.baseUrl + 'ShippingWOBooking/' + id);
    try {
      const result = await http.delete(apiUrl.toString());
      if (result) {
        toast.success(i18nMessages.deleteWorkOrderSuccess);
        getBookingList();
      }
    } catch (error) {}
  };

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    if (sortModel && sortModel.length) {
      updateGridData('sortBy', sortModel[0].field);
      updateGridData(
        'sortOrder',
        sortModel[0].sort === 'asc'
          ? OrderByDirection.Ascending
          : OrderByDirection.Descending
      );
    }
  }, []);

  const mystyle = {
    justifyContent: 'right',
    display: 'flex',
    fontWeight: 'bold',
  };

  const setModels = (newModel: any) => {
    var result = false;
    Object.keys(newModel).forEach(function (key) {
      var value = newModel[key];
      if (
        (value.field === 'selectedPartner' ||
          value.field === 'selectedService') &&
        value.cellToFocusAfter === undefined
      ) {
        result = true;
      }
    });
    if (result) {
      return;
    }
    if (newModel[0] === undefined) {
      setRowModesModel(newModel);
    } else if (
      newModel[0] !== undefined &&
      newModel[0].cellToFocusAfter === 'right'
    ) {
      updateGridData(
        'rows',
        gridData.rows.filter(function (row: any) {
          return row.id !== 0;
        })
      );
      setRowModesModel(newModel);
    }
  };
  const getBookingRestriction = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl +
        'ShippingWOBooking/GetBookingRestriction/' +
        shippingOrderId
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result) {
        var states: number[] = [];
        if (
          result.data.destinationCountryId &&
          result.data.destinationCountryId > 0
        ) {
          states.push(Number(result.data.destinationCountryId));
        }
        if (
          result.data.transitDestinationId &&
          result.data.transitDestinationId > 0
        ) {
          states.push(Number(result.data.transitDestinationId));
        }
        setStateIds(states);
        setRestrictions({
          ...restrictions,
          ...result.data,
          shippingOrderId:
            result.data.shippingOrderId && result.data.shippingOrderId > 0
              ? result.data.shippingOrderId
              : restrictions.shippingOrderId,
          shippingWorkOrderId:
            result.data.shippingWorkOrderId &&
            result.data.shippingWorkOrderId > 0
              ? result.data.shippingWorkOrderId
              : restrictions.shippingWorkOrderId,
          airline:
            result.data.airlineId && result.data.airlineId > 0
              ? ({
                  label: result.data.airlineName,
                  value: result.data.airlineId.toString(),
                } as BaseModel)
              : defaultValueForService,
          transitDestination:
            result.data.transitDestinationId &&
            result.data.transitDestinationId > 0
              ? ({
                  label: result.data.transitDestinationName,
                  value: result.data.transitDestinationId.toString(),
                } as BaseModel)
              : defaultValueForService,
        });
      }
    } catch (error) {}
  };

  const saveBookingRestriction = async () => {
    try {
      var result: any = {};
      var request: any = {
        id: restrictions.id ? restrictions.id : null,
        airlineId: restrictions.airlineId ? restrictions.airlineId : null,
        transitDestinationId: restrictions.transitDestinationId
          ? restrictions.transitDestinationId
          : null,
        shippingOrderId: restrictions.shippingOrderId,
        shippingWorkOrderId: restrictions.shippingWorkOrderId,
      };
      if (request.id && request.id > 0) {
        const apiUrl = new URL(
          environment.api.baseUrl + 'ShippingWOBooking/UpdateBookingRestriction'
        );
        result = await http.put(apiUrl.toString(), request);
      } else {
        const apiUrl = new URL(
          environment.api.baseUrl + 'ShippingWOBooking/CreateBookingRestriction'
        );
        result = await http.post(apiUrl.toString(), request);
      }
      if (result.data && result.data.isSuccess) {
        setValidateStatus(true);
      }
    } catch (error: any) {}
  };

  if (isRestrictionSaveCallApi) {
    saveBookingRestriction();
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (showRestriction) {
      saveBookingRestriction();
    } else {
      setValidateStatus(true);
    }
  };
  const handleSuccessStatusSave = async () => {
    try {
      toast.success(i18nMessages.UpdateSuccess);
      handleErrorStatusSave();
      setTimeout(() => {
        navigate(RouteEnum.ViewShippingOrders + '/' + id + '/2');
      }, 1000);
    } catch (error: any) {
      toast.error(i18nMessages.errorOccurred);
    }
  };

  /* Validate Status Dropdown Error call from Status Component */
  const handleErrorStatusSave = () => {
    setValidateStatus(false);
    setIsStatusSaveCallApi(false);
    setIsRestrictionSaveCallApi(false);
  };

  function BookingRestriction() {
    const onCreateOption = async (inputValue: any) => {
      var data = {
        name: inputValue,
      };
      setRowDataAirline(data);
      setOpenAirline(true);
    };

    const getCountries = async (search: string, pageNo: number) => {
      let response: PaginationResponseModel<Customer>;
      const apiUrl = new URL(environment.api.baseUrl + 'Country');
      try {
        let searchExpression = '';
        searchExpression += search ? `name~like~${search}` : '';
        apiUrl.searchParams.set('$filter', searchExpression);
        apiUrl.searchParams.set('$orderby', 'name');
        apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
        apiUrl.searchParams.set('$top', '10');
        apiUrl.searchParams.set('$orderbydirection', 'desc');
        const result = await http.get(apiUrl.toString());
        if (result) {
          response = {
            TotalRecords: result.data.totalCount,
            Values: result.data.values,
          } as PaginationResponseModel<Customer>;
        } else {
          response = {
            TotalRecords: 0,
            Values: [],
          } as PaginationResponseModel<Customer>;
        }
      } catch (error) {
        response = {
          TotalRecords: 0,
          Values: [],
        } as PaginationResponseModel<Customer>;
      }
      return response;
    };

    const loadCountryOptions = async (
      search: string,
      loadedOptions: any,
      { page }: any,
      loadInstruction: boolean = false
    ) => {
      const response = await getCountries(search, page);
      const hasMore = Math.ceil(response.TotalRecords / 10) > page;

      return {
        options: response.Values.map((x: any) => {
          return {
            value: x.id.toString(),
            label: loadInstruction ? x.description : x.name,
          } as BaseModel;
        }),
        hasMore,
        additional: {
          page: page + 1,
        },
      };
    };

    const handleAirlineClose = () => {
      setIsOpenAirline(false);
    };

    const handleAirlineOpen = () => {
      setIsOpenAirline(true);
    };

    const handleStateClose = () => {
      setIsOpenState(false);
    };

    const handleStateOpen = () => {
      setIsOpenState(true);
    };

    return (
      <>
        <Grid item lg={4} md={4}>
          <CustomSelectPaginateAdd
            key={'airline'}
            additional={defaultAdditional}
            value={restrictions.airline}
            handleChange={(newValue: BaseModel) =>
              setRestrictions({
                ...restrictions,
                airline: newValue,
                airlineId: newValue.value,
              })
            }
            loadOptions={(search: string, prevOptions: any, page: any) =>
              loadAirlineOptions(search, prevOptions, page)
            }
            isSearchable={true}
            displayLabel={i18nMessages.Airline}
            debounceTimeout={500}
            isMultiSelect={false}
            cacheUniqs={cacheUniq}
            onCreateOption={onCreateOption}
          ></CustomSelectPaginateAdd>
        </Grid>
        <Grid item lg={4} md={4}>
          <CustomizedInputs
            margin="normal"
            value={restrictions.destinationCountryName}
            displayLabel={i18nMessages.DestinationCountry}
            id="country"
            name="country"
            inputType="text"
            multiline={false}
            disabled
            readOnly={true}
          ></CustomizedInputs>
        </Grid>
        <Grid item lg={4} md={4}>
          <CustomSelectPaginate
            key={'transitDestination'}
            additional={defaultAdditional}
            value={restrictions.transitDestination}
            loadOptions={loadCountryOptions}
            handleChange={(newValue: BaseModel) => {
              setRestrictions({
                ...restrictions,
                transitDestination: newValue,
                transitDestinationId: newValue.value,
              });
              var states: number[] = [restrictions.destinationCountryId];
              if (newValue.value) {
                states.push(Number(newValue.value));
              }
              setStateIds(states);
            }}
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
            }}
            isSearchable={true}
            displayLabel={i18nMessages.TransitCountry}
            debounceTimeout={500}
            isMultiSelect={false}
          ></CustomSelectPaginate>
        </Grid>
        <Grid item lg={4} md={4}>
          <AirlineRestrictionPopup
            isOpen={isOpenAirline}
            handleClose={handleAirlineClose}
            title={i18nMessages.AirlineRestrictions}
            moduleRestriction={AirlineRestrictionModule.ShippingWOBooking}
            airlineId={restrictions.airlineId}
          />
          <FormControl>
            <Box sx={{ mt: 3 }}>
              <Button variant="contained" onClick={handleAirlineOpen}>
                {i18nMessages.AirlineRestrictions}
              </Button>
            </Box>
          </FormControl>
        </Grid>
        <Grid item lg={4} md={4}>
          <StateRestrictionPopup
            isOpen={isOpenState}
            handleClose={handleStateClose}
            title={i18nMessages.CountryRestrictions}
            moduleRestriction={StateRestrictionModule.ShippingWOBooking}
            stateIds={stateIds}
          />
          <FormControl>
            <Box sx={{ mt: 3 }}>
              <Button variant="contained" onClick={handleStateOpen}>
                {i18nMessages.CountryRestrictions}
              </Button>
            </Box>
          </FormControl>
        </Grid>
      </>
    );
  }

  return (
    <div>
      <div className="heading-section">
        <div className="heading-section-left">
          <Typography variant="h3" sx={{ mb: 3 }}>
            {i18nMessages.BookingTitle}
          </Typography>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>
      </div>
      <div className="main-content-section width-100">
        <Typography variant="h5" className="inner-heading has-button">
          {i18nMessages.Information}
        </Typography>

        <div className="content-block table-fix">
          <CustomDataGridInline
            rowCount={gridData.totalRows}
            data={gridData.rows}
            columns={columns}
            onSortModelChange={handleSortModelChange}
            pageNo={gridData.page}
            pageSize={gridData.pageSize}
            rowModesModel={rowModesModel}
            onRowModesModelChange={(newModel: any) => {
              setModels(newModel);
            }}
            onPageChange={handleChangePage}
            onPageSizeChange={handleChangePageSize}
            //onRowEditStart={handleRowEditStart}
            //onRowEditStop={handleRowEditStop}
            rowsPerPageOptions={gridData.rowsPerPageOptions}
            initialState={{
              sorting: {
                sortModel: [{ field: 'updatedDate', sort: 'desc' }],
              } as GridSortingInitialState,
              pagination: {
                page: gridData.page,
                pageSize: gridData.pageSize,
              } as GridPaginationInitialState,
            }}
            components={{
              Toolbar: EditToolbar,
            }}
            componentsProps={{
              toolbar: { setRows, setRowModesModel },
            }}
            processRowUpdate={handleProcessRowUpdate}
            onProcessRowUpdateError={handleProcessRowUpdateError}
          ></CustomDataGridInline>

          <ConfirmationPopup
            isOpen={isDeleteModalOpen}
            data={selectedId}
            message={I18n('Booking.DeleteConfirmation')}
            handleClose={handleClose}
            handleOk={handleOk}
            title={I18n('Booking.DeleteBooking')}
          ></ConfirmationPopup>
        </div>
        {showRestriction ? (
          <div className="mt-5">
            <FormAccordianSave
              title={i18nMessages.Restrictions}
              className="inner-heading"
              details={BookingRestriction()}
              showActionButton={true}
            ></FormAccordianSave>
          </div>
        ) : (
          <></>
        )}
        <div className="mt-5">
          <Status
            permission={Permission.canEditShippingOrders}
            woId={ShippingOrder.Booking}
            orderId={id}
            statusValue={values.status}
            activityOwner={ActivityOwner.BackOffice}
            orderType={OrderType.ShippingOrder}
            isStatusSaveCallApi={isStatusSaveCallApi}
            validateStatus={validateStatus}
            handleSuccessStatusSave={handleSuccessStatusSave}
            handleErrorStatusSave={handleErrorStatusSave}
            handleStatusChange={(value: number) =>
              setValues({ ...values, status: value })
            }
          />
        </div>
        <div className="btn-wrap form-btn-wrap">
          {canEdit ? (
            <Button
              type="submit"
              color="primary"
              disableElevation
              variant="contained"
              onClick={handleSubmit}
            >
              {I18n('Common.Save')}
            </Button>
          ) : (
            <></>
          )}
        </div>
        <CreatedByandUpdatedBy
          createdBy={details.createdBy}
          createdDate={details.createdDate}
          updatedBy={details.updatedBy}
          updatedDate={details.updatedDate}
        ></CreatedByandUpdatedBy>
      </div>
    </div>
  );
}

export default ShippingOrderBooking;
