import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { InputLabel } from '@mui/material';
import { useEffect, useState } from 'react';

const minDistance = 10;

interface CustomRangeSliderProps {
  displayLabel: string;
  maxRange: number;
  minRange: number;
  defaultMinValue: number;
  defaultMaxValue: number;
  handleChange: any;
}

export default function CustomRangeSlider(props: CustomRangeSliderProps) {
  const [value, setValue] = React.useState<number[]>([
    props.defaultMinValue > 0 ? props.defaultMinValue : props.minRange,
    props.defaultMaxValue > 0 ? props.defaultMaxValue : props.maxRange,
  ]);

  const handleChange = (
    event: Event,
    newValue: number | number[],
    activeThumb: number
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 100 - minDistance);
        setValue([clamped, clamped + minDistance]);
        props.handleChange(clamped, clamped + minDistance);
      } else {
        const clamped = Math.max(newValue[1], minDistance);
        setValue([clamped - minDistance, clamped]);
        props.handleChange(clamped - minDistance, clamped);
      }
    } else {
      setValue(newValue as number[]);
      props.handleChange(newValue[0], newValue[1]);
    }
  };

  //   useEffect(() => {
  //     setDefaultMinValue(props.defaultMinValue);
  //     setDefaultMaxValue(props.defaultMaxValue);
  //   }, [props.defaultMinValue, props.defaultMaxValue]);

  return (
    <Box>
      <InputLabel shrink htmlFor="bootstrap-input">
        {props.displayLabel}
      </InputLabel>
      <Slider
        min={props.minRange}
        max={props.maxRange}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        disableSwap
      />
    </Box>
  );
}
