import {
  Breadcrumbs,
  Button,
  Fade,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Popper,
  PopperPlacementType,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import {
  GridActionsCellItem,
  GridSortModel,
  GridColumns,
  GridRowParams,
  GridEventListener,
  GridSortingInitialState,
  GridPaginationInitialState,
  GridColumnVisibilityModel,
} from '@mui/x-data-grid';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  copyShippingOrder,
  deleteShippingOrder,
  ExportShippingOrders,
  getShippingOrders,
  loadCategoryOptions,
  updateOrderStatus,
} from './ShippingApiService';
import ConfirmationPopup from 'common/confirmationPopup/confirmationPopup';
import CustomDataGrid from 'common/datagrid/datagrid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  BaseModel,
  CommonListRequestModel,
  CommonPaginationResponseModel,
  CopyState,
  FilterScreens,
  MasterCategoryEnumType,
  OrderByDirection,
  OrderCreatedFrom,
  ShippingOrderColumns,
} from 'models/pagination-model';
import {
  ShippingOrderListDTO,
  ShippingOrderStatus,
  ShippingOrderStatusDescription,
  ShippingOrderTypeDescription,
} from 'models/shipping-orders';
import FormControl from 'common/formControl/formControl';
import { Permission } from 'Permissions';
import RouteEnum from 'models/RouteEnum';
import { useNavigate } from 'react-router-dom';
import I18n from 'utilities/i18n';
import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { hasPermission } from 'utilities/protectedRoute';
import {
  faFilter,
  faPlus,
  faSearch,
  faTimes,
  faWarning,
  faFan,
  faSnowflake,
  faUserLarge,
  faTemperature1,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { toast } from 'react-toastify';
import environment from 'environment';
import http from '../../utilities/httpService';
import CustomDateRangePicker from 'common/dateRange/dateRange';
import { addDays } from 'date-fns';
import CustomSelectPaginate from 'common/CustomSelect/CustomSelectPaginate';
import CustomizedSelect from 'common/CustomSelect/CustomSelect';
import CompletionProgressBar from '../../common/showCompletionProgressBar/CompletionProgressBar';
import { Tooltip } from '@material-ui/core';
import { Utilities } from 'utilities/Utilities';
import CircularProgressWithLabel from 'common/CircularProgress/CircularProgress';
import axios from 'axios';
import OrderCopyPopup from './OrderCopyPopup';

export default function ShippingOrderList() {
  let navigate = useNavigate();
  const breadcrumbs = [
    <Typography key="3" color="text.primary">
      {I18n('Customer.Shipping')}
    </Typography>,
    <Typography key="3" color="text.primary">
      {I18n('Nav.Order')}
    </Typography>,
  ];
  const [isApprover, setIsApproved] = useState(
    hasPermission(Permission.canApproveShippingOrders)
  );
  const [gridData, setGridData] = useState<
    CommonPaginationResponseModel<ShippingOrderListDTO>
  >({
    isLoading: true,
    sortOrder: OrderByDirection.Descending,
    sortBy: 'orderDate',
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [10, 20, 50, 100],
    pageSize: 10,
    page: 0,
    searchExpression: '',
  });
  const [rowsSelected, setRowsSelected] = useState([]);
  const selectedIds = (selectedRows: any) => {
    setRowsSelected(selectedRows.map((x: any) => x.id));
  };
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isPast, setIsPast] = useState(false);
  const [isToday, setIsToday] = useState(false);
  const [isFuture, setIsFuture] = useState(false);
  const [isIgnoreDateFilter, setIsIgnoreDateFilter] = useState<boolean>(false);
  const [refreshGrid, setRefreshGrid] = useState(false);
  const [orderId, setOrderId] = useState<number>(0);
  const dateFormat = 'YYYY-MM-DDTHH:mm:ss[Z]';
  const [advanceSearchExpression, setAdvanceSearchExpression] = useState(
    `expectedPickupDateTime~>~${moment(addDays(new Date(), -7))
      .set({ h: 0, m: 0, s: 1 })
      .format(dateFormat)} and expectedPickupDateTime~<~${moment(new Date())
      .set({ h: 23, m: 59, s: 59 })
      .format(dateFormat)}`
  );
  const [basicSearchExpression, setBasicSearchExpression] = useState('');
  const [fromDate, setFromDate] = useState(
    moment(addDays(new Date(), -7)).set({ h: 0, m: 0, s: 1 }).format(dateFormat)
  );
  const [toDate, setToDate] = useState(
    moment(new Date()).set({ h: 23, m: 59, s: 59 }).format(dateFormat)
  );
  const [categoryId, setCategoryId] = useState([]);
  const [typeId, setTypeId] = useState([]);
  const [serviceId, setServiceId] = useState([]);
  const [statusId, setStatusId] = useState([]);
  const [isFilterEnabled, setIsFilterEnabled] = useState(false);
  const [isCancelOrderModalOpen, setCancelOrderModal] = useState(false);
  const [isCopyModalOpen, setCopyModalOpen] = useState(false);
  const customerPortalUser = Utilities.getIsCPUser();
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({
      attention: false,
      orderDate: false,
      customerName: false,
      categoryName: false,
      autogeneratedReferenceNo: false,
      orderReference: false,
      woDoneVsTotal: false,
      type: false,
      partner: false,
      partnerTrackingNo: false,
      fromTo: false,
      serviceName: false,
      status: false,
      totalWeight: false,
      totalNoOfPieces: false,
    });
  const defaultOrderCategoryValue = {
    value: '',
    label: I18n('Placeholders.SelectCategory'),
  } as BaseModel;
  const [selectedCategory, setSelectedCategory] = useState([]);
  const defaultShippingTypeValue = {
    value: '',
    label: I18n('Placeholders.SelectShippingType'),
  } as BaseModel;
  const [selectedShippingType, setSelectedShippingType] = useState([]);
  const defaultServiceValue = {
    value: '',
    label: I18n('Placeholders.SelectService'),
  } as BaseModel;
  const [selectedService, setSelectedService] = useState([]);
  const defaultStatusValue = {
    value: '',
    label: I18n('Placeholders.Select'),
  } as BaseModel;
  const [selectedStatus, setSelectedStatus] = useState([]);
  let shippingTypeList: Array<BaseModel> = [];
  const getShippingTypes = () => {
    shippingTypeList = [];
    ShippingOrderTypeDescription.forEach((value: string, key: number) => {
      shippingTypeList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getShippingTypes();

  let statusList: Array<BaseModel> = [];
  const getShippingStatus = () => {
    statusList = [];
    ShippingOrderStatusDescription.forEach((value: string, key: number) => {
      statusList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getShippingStatus();

  const i18nMessages = {
    deleteOrderSuccess: I18n('ShippingOrders.DeleteSuccess'),
    deleteOrderCofirmation: I18n('ShippingOrders.DeleteConfirmation'),
    cancelOrderSuccess: I18n('ShippingOrders.CancelSuccess'),
    cancelOrderCofirmation: I18n('ShippingOrders.CancelConfirmation'),
    errorOccured: I18n('ShippingOrders.ErrorOccurred'),
    orderCopySuccessful: I18n('ShippingOrders.OrderCopySuccessful'),
    copyConfirmation: I18n('ShippingOrders.CopyConfirmation'),
    cancel: I18n('Common.Cancel'),
    noRowsSelected: I18n('Customer.NoRowsSelected'),
    savedSuccessfully: I18n('Common.SavedSuccessfully'),
  };
  const cancelOrderData = useCallback(
    (id: any) => () => {
      setCancelOrderModal(true);
      setOrderId(id);
    },
    []
  );
  const handleCloseCancelOrderModal = () => {
    setCancelOrderModal(false);
  };

  const handleOkCancelOrderModal = async () => {
    setCancelOrderModal(false);
    var result = await updateOrderStatus(
      orderId,
      ShippingOrderStatus.Cancelled.toString()
    );

    if (result) {
      toast.success(i18nMessages.cancelOrderSuccess);
      setRefreshGrid(!refreshGrid);
    }
  };
  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));

  const getDateFilter = (dateFilters: any) => {
    if (dateFilters.isPast && dateFilters.isToday && dateFilters.isFuture) {
      return '';
    } else if (
      !dateFilters.isPast &&
      dateFilters.isToday &&
      dateFilters.isFuture
    ) {
      return `expectedPickupDateTime~>=~${moment(new Date())
        .set({ h: 0, m: 0, s: 0 })
        .format(dateFormat)}`;
    } else if (
      dateFilters.isPast &&
      !dateFilters.isToday &&
      dateFilters.isFuture
    ) {
      return `expectedPickupDateTime~<~${moment(new Date())
        .set({ h: 0, m: 0, s: 0 })
        .format(dateFormat)} or expectedPickupDateTime~>~${moment(new Date())
        .set({ h: 23, m: 59, s: 59 })
        .format(dateFormat)}`;
    } else if (
      dateFilters.isPast &&
      dateFilters.isToday &&
      !dateFilters.isFuture
    ) {
      return `expectedPickupDateTime~<=~${moment(new Date())
        .set({ h: 23, m: 59, s: 59 })
        .format(dateFormat)}`;
    } else if (
      dateFilters.isPast &&
      !dateFilters.isToday &&
      !dateFilters.isFuture
    ) {
      return `expectedPickupDateTime~<~${moment(new Date())
        .set({ h: 0, m: 0, s: 0 })
        .format(dateFormat)}`;
    } else if (
      !dateFilters.isPast &&
      dateFilters.isToday &&
      !dateFilters.isFuture
    ) {
      return `expectedPickupDateTime~>~${moment(new Date())
        .set({ h: 0, m: 0, s: 0 })
        .format(dateFormat)} and expectedPickupDateTime~<~${moment(new Date())
        .set({ h: 23, m: 59, s: 59 })
        .format(dateFormat)}`;
    } else if (
      !dateFilters.isPast &&
      !dateFilters.isToday &&
      dateFilters.isFuture
    ) {
      return `expectedPickupDateTime~>~${moment(new Date())
        .set({ h: 23, m: 59, s: 59 })
        .format(dateFormat)}`;
    } else {
      return '';
    }
  };

  const manageFilterStatus = () => {
    setIsFilterEnabled(
      selectedCategory.length > 0 ||
        selectedShippingType.length > 0 ||
        selectedService.length > 0 ||
        selectedStatus.length > 0 ||
        isIgnoreDateFilter ||
        (!isPast && !isToday && !isFuture)
        ? true
        : false
    );
  };

  const getShippingOrderList = (rawFilters?: any) => {
    updateGridData('isLoading', true);
    var dateFilter = rawFilters != undefined ? getDateFilter(rawFilters) : '';
    var isDateFilterApplicable =
      rawFilters != undefined
        ? rawFilters.isPast || rawFilters.isToday || rawFilters.isFuture
        : false;
    var filterData: CommonListRequestModel<ShippingOrderListDTO> = {
      advanceSearchExpression: isDateFilterApplicable
        ? dateFilter
        : rawFilters != undefined && rawFilters.isIgnoreDateFilter
        ? ''
        : advanceSearchExpression,
      basicSearchExpression,
      gridData,
    };
    var categoryFilter =
      rawFilters != undefined && rawFilters.categoryFilter.length > 0
        ? rawFilters.categoryFilter.join(',')
        : '';
    var typeFilter =
      rawFilters != undefined && rawFilters.typeFilter.length > 0
        ? rawFilters.typeFilter.join(',')
        : '';
    var serviceFilter =
      rawFilters != undefined && rawFilters.serviceFilter.length > 0
        ? rawFilters.serviceFilter.join(',')
        : '';

    var statusFilter;
    if (rawFilters !== undefined) {
      if (rawFilters.isPast || rawFilters.isToday || rawFilters.isFuture) {
        statusFilter = ShippingOrderStatus.InProcess;
        if (rawFilters.statusFilter.length > 0) {
          statusFilter = statusFilter + `,` + rawFilters.statusFilter.join(',');
        }
      } else if (rawFilters.statusFilter.length > 0) {
        statusFilter = rawFilters.statusFilter.join(',');
      } else {
        statusFilter = '';
      }
    } else {
      statusFilter = '';
    }

    getShippingOrders(
      filterData,
      categoryFilter,
      typeFilter,
      serviceFilter,
      statusFilter
    ).then((x) => {
      if (x) {
        updateGridData('totalRows', x?.gridData.totalRows);
        updateGridData('rows', x?.gridData.rows);
        updateGridData('isLoading', false);
        manageFilterStatus();
      }
    });
  };

  const getShippingOrderColumns = async () => {
    updateGridData('isLoading', true);
    const apiUrl = new URL(
      environment.api.baseUrl + 'Shipping/ShippingOrderColumns'
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result && result.data.length > 0) {
        result.data.forEach((element: any) => {
          switch (element.columnId) {
            case ShippingOrderColumns.Attention:
              columnVisibilityModel.attention = true;
              break;
            case ShippingOrderColumns.OrderDate:
              columnVisibilityModel.orderDate = true;
              break;
            case ShippingOrderColumns.CustomerName:
              columnVisibilityModel.customerName = true;
              break;
            case ShippingOrderColumns.CategoryName:
              columnVisibilityModel.categoryName = true;
              break;
            case ShippingOrderColumns.AutogeneratedReferenceNo:
              columnVisibilityModel.autogeneratedReferenceNo = true;
              break;
            case ShippingOrderColumns.OrderReference:
              columnVisibilityModel.orderReference = true;
              break;
            case ShippingOrderColumns.WoDoneVsTotal:
              columnVisibilityModel.woDoneVsTotal = true;
              break;
            case ShippingOrderColumns.Partner:
              columnVisibilityModel.partner = true;
              break;
            case ShippingOrderColumns.PartnerTrackingNo:
              columnVisibilityModel.partnerTrackingNo = true;
              break;
            case ShippingOrderColumns.FromTo:
              columnVisibilityModel.fromTo = true;
              break;
            case ShippingOrderColumns.ServiceName:
              columnVisibilityModel.serviceName = true;
              break;
            case ShippingOrderColumns.Status:
              columnVisibilityModel.status = true;
              break;
            case ShippingOrderColumns.TotalWeight:
              columnVisibilityModel.totalWeight = true;
              break;
            case ShippingOrderColumns.TotalNoOfPieces:
              columnVisibilityModel.totalNoOfPieces = true;
              break;

            default:
              break;
          }
        });

        setColumnVisibilityModel(columnVisibilityModel);

        await getFilters();
      }
    } catch {}
  };

  const getFilters = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl +
        'CustomizedFilter?filterScreen=' +
        FilterScreens.ShippingList
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result) {
        var rawFilters = setRawFilters(result.data.uiFilter);
        await getShippingOrderList(rawFilters);
      }
    } catch {}
  };

  const setRawFilters = (uiFilters: string) => {
    var filters = uiFilters != undefined ? uiFilters.split('|') : [];
    if (filters.length > 0) {
      var categoryFilter: any = [];
      var typeFilter: any = [];
      var serviceFilter: any = [];
      var statusFilter: any = [];
      var isIgnoreDateFilter = false;
      var isPast = false;
      var isToday = false;
      var isFuture = false;
      filters.forEach((element: any) => {
        if (element.includes('CategoryId')) {
          var filterPart1 = element.split('&');
          categoryFilter.push({
            value: filterPart1[0].split('=')[1],
            label: filterPart1[1].split('=')[1],
          });
        } else if (element.includes('TypeId')) {
          var filterPart2 = element.split('&');
          typeFilter.push({
            value: filterPart2[0].split('=')[1],
            label: filterPart2[1].split('=')[1],
          });
        } else if (element.includes('ServiceId')) {
          var filterPart3 = element.split('&');
          serviceFilter.push({
            value: filterPart3[0].split('=')[1],
            label: filterPart3[1].split('=')[1],
          });
        } else if (element.includes('StatusId')) {
          var filterPart4 = element.split('&');
          statusFilter.push({
            value: filterPart4[0].split('=')[1],
            label: filterPart4[1].split('=')[1],
          });
        } else if (element.includes('isIgnoreDateFilter')) {
          var filterPart5 = element.split('=');
          setIsIgnoreDateFilter(filterPart5[1] === '1' ? true : false);
          isIgnoreDateFilter = filterPart5[1] === '1' ? true : false;
        } else if (element.includes('isPast')) {
          var filterPart6 = element.split('=');
          setIsPast(filterPart6[1] === '1' ? true : false);
          isPast = filterPart6[1] === '1' ? true : false;
        } else if (element.includes('isToday')) {
          var filterPart7 = element.split('=');
          setIsToday(filterPart7[1] === '1' ? true : false);
          isToday = filterPart7[1] === '1' ? true : false;
        } else if (element.includes('isFuture')) {
          var filterPart8 = element.split('=');
          setIsFuture(filterPart8[1] === '1' ? true : false);
          isFuture = filterPart8[1] === '1' ? true : false;
        }
      });
      setSelectedCategory(categoryFilter);

      setSelectedShippingType(typeFilter);

      setSelectedService(serviceFilter);

      setSelectedStatus(statusFilter);

      setCategoryId(
        categoryFilter.map((x: any) => {
          return x.value;
        })
      );

      setTypeId(
        typeFilter.map((x: any) => {
          return x.value;
        })
      );

      setServiceId(
        serviceFilter.map((x: any) => {
          return x.value;
        })
      );

      setStatusId(
        statusFilter.map((x: any) => {
          return x.value;
        })
      );

      var dropdownFilters = {
        categoryFilter: categoryFilter.map((x: any) => {
          return x.value;
        }),
        typeFilter: typeFilter.map((x: any) => {
          return x.value;
        }),
        serviceFilter: serviceFilter.map((x: any) => {
          return x.value;
        }),
        statusFilter: statusFilter.map((x: any) => {
          return x.value;
        }),
        isIgnoreDateFilter,
        isPast,
        isToday,
        isFuture,
      };
      return dropdownFilters;
    }
  };

  useEffect(() => {
    getShippingOrderColumns();
  }, [
    gridData.page,
    gridData.pageSize,
    gridData.sortBy,
    gridData.sortOrder,
    gridData.searchExpression,
    basicSearchExpression,
    refreshGrid,
  ]);

  const handleChangePage = (pageNo: number) => {
    updateGridData('page', pageNo);
  };

  const copyHandler = useCallback(
    (id: any) => () => {
      setCopyModalOpen(true);
      setOrderId(id);
    },
    []
  );

  const handleCloseCopyModal = () => {
    setCopyModalOpen(false);
  };
  const handleOkCopyModal = async (copyState: CopyState) => {
    await copyOrder(orderId, copyState);
    setCopyModalOpen(false);
  };

  const copyOrder = async (id: any, copyState: CopyState) => {
    const res = await copyShippingOrder(id, copyState);
    if (res.result) {
      toast.success(i18nMessages.orderCopySuccessful);
      navigate(RouteEnum.AddShippingOrders + `/${res.data}`);
    } else {
      toast.error(i18nMessages.errorOccured);
    }
  };

  const handleChangePageSize = (pageSize: number) => {
    updateGridData('pageSize', pageSize);
  };
  type Row = (typeof gridData.rows)[number];
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState<PopperPlacementType>();
  const columns: GridColumns<Row> = [
    {
      field: 'attention',
      type: 'actions',
      headerName: I18n('ShippingOrders.Attention'),
      flex: 1,
      sortable: false,
      getActions: (params: GridRowParams<ShippingOrderListDTO>) => {
        const actionButtons = [];
        actionButtons.push(
          <>
            {params.row.isTemperatureControlCheck ? (
              <FontAwesomeIcon
                icon={faTemperature1}
                className="fa-md"
                title={I18n(
                  'ShippingOrders.ShippingPackage.TemperatureControlled'
                )}
              />
            ) : (
              <></>
            )}
            {params.row.isGeneralCargoCheck ? (
              <FontAwesomeIcon
                icon={faFan}
                className="fa-md"
                title={I18n('ShippingOrders.ShippingPackage.GeneralCargo')}
              />
            ) : (
              <></>
            )}
            {params.row.isDangerousGoodsCheck ? (
              <FontAwesomeIcon
                icon={faSnowflake}
                className="fa-md"
                title={I18n('ShippingOrders.ShippingPackage.DangerousGoods')}
              />
            ) : (
              <></>
            )}
            {params.row.createdFrom &&
            params.row.createdFrom == OrderCreatedFrom.CustomerPortal &&
            !customerPortalUser ? (
              <FontAwesomeIcon
                icon={faUserLarge}
                className="fa-md"
                title={I18n('ShippingOrders.OrderCreatedFrom')}
              />
            ) : (
              <></>
            )}
          </>
        );
        return actionButtons;
      },
    },
    {
      field: 'orderDate',
      headerName: I18n('ShippingOrders.OrderDate'),
      valueFormatter: (params) => moment(params?.value).format('DD/MM/YYYY'),
      flex: 1,
    },
    {
      field: 'customerName',
      headerName: I18n('ShippingOrders.Customer'),
      flex: 1,
    },
    {
      field: 'categoryName',
      headerName: I18n('ShippingOrders.Category'),
      flex: 1,
    },
    {
      field: 'autogeneratedReferenceNo',
      headerName: I18n('ShippingOrders.HazgoReference'),
      flex: 1,
    },
    {
      field: 'orderReference',
      headerName: I18n('ShippingOrders.OrderReference'),
      flex: 1,
    },
    {
      field: 'woDoneVsTotal',
      headerName: I18n('ShippingOrders.WoDoneVsTotal'),
      flex: 1,
      sortable: false,
      renderCell: (params: any) => {
        return (
          <>
            <CompletionProgressBar
              totalCounts={params.row.totalWorkOrdersCount}
              completedCounts={params.row.workOrderProgressCount}
            />
          </>
        );
      },
    },
    {
      field: 'partner',
      headerName: I18n('ShippingOrders.Partner'),
      flex: 1,
      sortable: false,
    },
    {
      field: 'partnerTrackingNo',
      headerName: I18n('ShippingOrders.PartnerTrackingNo'),
      flex: 1,
      sortable: false,
    },
    {
      field: 'fromTo',
      headerName: I18n('ShippingOrders.FromTo'),
      flex: 1,
    },
    {
      field: 'serviceName',
      headerName: I18n('ShippingOrders.Service'),
      flex: 1,
    },
    {
      field: 'status',
      headerName: I18n('ShippingOrders.Status'),
      flex: 1,
      renderCell: (params: any) => {
        return +params.value > 0 ? (
          <Tooltip
            title={
              statusList?.find((x: any) => x.value == +params.value)?.label ??
              ''
            }
          >
            <span>
              {statusList.find((x: any) => x.value == +params.value)?.label}
            </span>
          </Tooltip>
        ) : (
          ''
        );
      },
    },
    {
      field: 'totalWeight',
      headerName: I18n('ShippingOrders.TotalWeight'),
      flex: 1,
      sortable: false,
    },
    {
      field: 'totalNoOfPieces',
      headerName: I18n('ShippingOrders.TotalNoOfPackages'),
      flex: 1,
      sortable: false,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: I18n('Common.Actions'),
      width: 80,
      align: 'right',
      getActions: (params: GridRowParams<ShippingOrderListDTO>) => {
        const actionButtons = [];
        actionButtons.push(
          <>
            {hasPermission(Permission.canEditShippingOrders) &&
            params.row.status === ShippingOrderStatus.New &&
            +params.row.workOrderProgressCount === 0 &&
            +params.row.workOrderInProgressCount === 0 ? (
              <Tooltip title={i18nMessages.cancel}>
                <GridActionsCellItem
                  onPointerEnterCapture={false}
                  onPointerLeaveCapture={false}
                  icon={<CancelIcon />}
                  placeholder={''}
                  label="Cancel"
                  onClick={cancelOrderData(params.id)}
                />
              </Tooltip>
            ) : (
              <></>
            )}
          </>
        );
        actionButtons.push(
          <>
            {hasPermission(Permission.canAddShippingOrders) ? (
              <Tooltip title={'Copy'}>
                <GridActionsCellItem
                  onPointerEnterCapture={false}
                  onPointerLeaveCapture={false}
                  icon={<ContentCopyIcon />}
                  placeholder={''}
                  label="Copy"
                  // onClick={copy(params.id)}
                  onClick={copyHandler(params.id)}
                />
              </Tooltip>
            ) : (
              <></>
            )}
          </>
        );
        return actionButtons;
      },
    },
  ];
  const popperHandleClick =
    (newPlacement: PopperPlacementType) =>
    (event: MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setOpen((prev) => placement !== newPlacement || !prev);
      setPlacement(newPlacement);
    };
  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    if (sortModel && sortModel.length) {
      if (sortModel[0].field === 'customerName') {
        updateGridData('sortBy', 'Customer.Name');
      } else if (sortModel[0].field === 'categoryName') {
        updateGridData('sortBy', 'Category.Name');
      } else if (sortModel[0].field === 'fromTo') {
        updateGridData('sortBy', 'PickUpCustomerLocation.Country.Name');
      } else {
        updateGridData('sortBy', sortModel[0].field);
      }
      updateGridData(
        'sortOrder',
        sortModel[0].sort === 'asc'
          ? OrderByDirection.Ascending
          : OrderByDirection.Descending
      );
    }
  }, []);
  const handleOnRowClick: GridEventListener<'rowClick'> = (
    params: { id: any }, // GridRowParams
    event: any, // MuiEvent<React.MouseEvent<HTMLElement>>
    details: any // GridCallbackDetails
  ) => {
    navigate(`${RouteEnum.ViewShippingOrders}/${params.id}`);
  };
  const handleResetClick = () => {
    setSelectedCategory([]);
    setSelectedShippingType([]);
    setSelectedService([]);
    setSelectedStatus([]);
    setIsIgnoreDateFilter(false);
    setAdvanceSearchExpression('');
  };
  const appendAdvanceSearchExpressions = (
    searchExpression: string,
    type: string
  ) => {
    let searchExpressionLocal: string = searchExpression;
    switch (type) {
      case 'and':
        searchExpressionLocal = searchExpressionLocal ? ` and ` : ``;
        break;
      case 'or':
        searchExpressionLocal = searchExpressionLocal ? ` or ` : ``;
        break;
      case '|':
        searchExpressionLocal = searchExpressionLocal ? `|` : ``;
        break;
      default:
        break;
    }
    return searchExpressionLocal;
  };

  const handleExportClick = async () => {
    const filterApiUrl = new URL(
      environment.api.baseUrl +
        'CustomizedFilter?filterScreen=' +
        FilterScreens.ShippingList
    );
    try {
      const result = await http.get(filterApiUrl.toString());
      if (result) {
        var rawFilters = setRawFilters(result.data.uiFilter);

        var dateFilter =
          rawFilters != undefined ? getDateFilter(rawFilters) : '';
        var isDateFilterApplicable =
          rawFilters != undefined
            ? rawFilters.isPast || rawFilters.isToday || rawFilters.isFuture
            : false;
        var filterData: CommonListRequestModel<ShippingOrderListDTO> = {
          advanceSearchExpression: isDateFilterApplicable
            ? dateFilter
            : rawFilters != undefined && rawFilters.isIgnoreDateFilter
            ? ''
            : advanceSearchExpression,
          basicSearchExpression,
          gridData,
        };
        var categoryFilter =
          rawFilters != undefined && rawFilters.categoryFilter.length > 0
            ? rawFilters.categoryFilter.join(',')
            : '';
        var typeFilter =
          rawFilters != undefined && rawFilters.typeFilter.length > 0
            ? rawFilters.typeFilter.join(',')
            : '';
        var serviceFilter =
          rawFilters != undefined && rawFilters.serviceFilter.length > 0
            ? rawFilters.serviceFilter.join(',')
            : '';
        var statusFilter;
        if (rawFilters !== undefined) {
          if (rawFilters.isPast || rawFilters.isToday || rawFilters.isFuture) {
            statusFilter = ShippingOrderStatus.InProcess;
            if (rawFilters.statusFilter.length > 0) {
              statusFilter =
                statusFilter + `,` + rawFilters.statusFilter.join(',');
            }
          } else if (rawFilters.statusFilter.length > 0) {
            statusFilter = rawFilters.statusFilter.join(',');
          } else {
            statusFilter = '';
          }
        } else {
          statusFilter = '';
        }

        var resultData = await ExportShippingOrders(
          filterData,
          categoryFilter,
          typeFilter,
          serviceFilter,
          statusFilter
        );
        if (resultData) {
          const file = new Blob([resultData.data], {
            type: 'application/vnd.ms-excel',
          }); //Build a URL from the file
          const fileURL = window.URL.createObjectURL(file); //Open the URL on new Window
          const link = document.createElement('a');
          link.href = fileURL;
          link.setAttribute('download', `Export.xls`);
          link.click();
          document.body.appendChild(link);
        }
      }
    } catch {
      toast.error(i18nMessages.errorOccured);
    }
  };

  const handleAdvanceSearchFilterClick = () => {
    let searchExpression = '';
    var categoryFilter: any = [];
    var typeFilter: any = [];
    var serviceFilter: any = [];
    var statusFilter: any = [];
    if (selectedCategory && selectedCategory.length > 0) {
      selectedCategory.forEach((element: any) => {
        categoryFilter.push(element.value);
      });
    }
    if (selectedShippingType && selectedShippingType.length > 0) {
      selectedShippingType.forEach((element: any) => {
        typeFilter.push(element.value);
      });
    }
    if (selectedService && selectedService.length > 0) {
      selectedService.forEach((element: any) => {
        serviceFilter.push(element.value);
      });
    }
    if (selectedStatus && selectedStatus.length > 0) {
      selectedStatus.forEach((element: any) => {
        statusFilter.push(element.value);
      });
    }
    if (isIgnoreDateFilter) {
      searchExpression = '';
    } else if (fromDate && toDate) {
      setAdvanceSearchExpression(
        `expectedPickupDateTime~>~${fromDate} and expectedPickupDateTime~<~${toDate}`
      );
    }
    setCategoryId(categoryFilter);
    setTypeId(typeFilter);
    setServiceId(serviceFilter);
    setStatusId(statusFilter);
    setOpen(false);
    var rawFilter = getRawFilter(isPast, isToday, isFuture);
    CustomizedFilterChange(searchExpression, rawFilter);
  };

  const handlePastClick = (event: any) => {
    setIsPast(event.target.checked);
    var rawFilter = getRawFilter(event.target.checked, isToday, isFuture);
    CustomizedFilterChange('', rawFilter);
  };

  const handleTodayClick = (event: any) => {
    setIsToday(event.target.checked);
    var rawFilter = getRawFilter(isPast, event.target.checked, isFuture);
    CustomizedFilterChange('', rawFilter);
  };

  const handleFutureClick = (event: any) => {
    setIsFuture(event.target.checked);
    var rawFilter = getRawFilter(isPast, isToday, event.target.checked);
    CustomizedFilterChange('', rawFilter);
  };

  const getRawFilter = (
    isPast: boolean,
    isToday: boolean,
    isFuture: boolean
  ) => {
    var rawsearchExpression = '';
    if (selectedCategory && selectedCategory.length > 0) {
      selectedCategory.forEach((element: any) => {
        rawsearchExpression += element.value
          ? appendAdvanceSearchExpressions(rawsearchExpression, '|') +
            `CategoryId=${element.value}&CategoryName=${element.label}`
          : '';
      });
    }
    if (selectedShippingType && selectedShippingType.length > 0) {
      selectedShippingType.forEach((element: any) => {
        rawsearchExpression += element.value
          ? appendAdvanceSearchExpressions(rawsearchExpression, '|') +
            `TypeId=${element.value}&TypeName=${element.label}`
          : '';
      });
    }
    if (selectedService && selectedService.length > 0) {
      selectedService.forEach((element: any) => {
        rawsearchExpression += element.value
          ? appendAdvanceSearchExpressions(rawsearchExpression, '|') +
            `ServiceId=${element.value}&ServiceName=${element.label}`
          : '';
      });
    }
    if (selectedStatus && selectedStatus.length > 0) {
      selectedStatus.forEach((element: any) => {
        rawsearchExpression += element.value
          ? appendAdvanceSearchExpressions(rawsearchExpression, '|') +
            `StatusId=${element.value}&StatusName=${element.label}`
          : '';
      });
    }

    rawsearchExpression +=
      appendAdvanceSearchExpressions(rawsearchExpression, '|') +
      `isPast=${isPast ? 1 : 0}`;

    rawsearchExpression +=
      appendAdvanceSearchExpressions(rawsearchExpression, '|') +
      `isToday=${isToday ? 1 : 0}`;

    rawsearchExpression +=
      appendAdvanceSearchExpressions(rawsearchExpression, '|') +
      `isFuture=${isFuture ? 1 : 0}`;

    rawsearchExpression +=
      appendAdvanceSearchExpressions(rawsearchExpression, '|') +
      `isIgnoreDateFilter=${isIgnoreDateFilter ? 1 : 0}`;

    return rawsearchExpression;
  };

  const addShippingOrderClicked = () => {
    navigate(`${RouteEnum.AddShippingOrders}/0`);
  };

  const handleApproveOrder = async () => {
    if (!(rowsSelected && rowsSelected.length > 0)) {
      toast.error(i18nMessages.noRowsSelected);
      return;
    }

    setIsLoading(true);

    for (let i = 0; i < rowsSelected.length; i++) {
      const shippingOrderId = rowsSelected[i];
      var token = localStorage.getItem('token');
      if (token) {
        const userToken = JSON.parse(token);
        const approveOrderInstance = axios.create();
        await approveOrderInstance
          .put(
            environment.api.baseUrl +
              `Shipping/UpdateOrderStatus/${shippingOrderId}/${ShippingOrderStatus.InvoiceApproved}`,
            null,
            { headers: { Authorization: `Bearer ${userToken}` } }
          )
          .then(() => {
            setProgress(((i + 1) / rowsSelected.length) * 100);
          })
          .catch();
      }
    }
    setTimeout(() => {
      setIsLoading(false);
      setProgress(0);
      getFilters();
      setRowsSelected([]);
      toast.success(i18nMessages.savedSuccessfully);
    }, 1000);
  };

  const defaultAdditional: any = {
    page: 1,
  };

  const CustomizedFilterChange = async (
    rawFilter: string,
    uiFilter: string
  ) => {
    var data = {
      RawFilter: rawFilter,
      UIFilter: uiFilter,
      FilterScreens: FilterScreens.ShippingList,
    };
    const apiUrl = new URL(environment.api.baseUrl + 'CustomizedFilter');
    try {
      await http.post(apiUrl.toString(), data);
      setRefreshGrid(!refreshGrid);
    } catch {}
  };

  const handleColumnVisibilityChange = async (newModel: any) => {
    setColumnVisibilityModel(newModel);

    var data = PrepareData(newModel);
    const apiUrl = new URL(
      environment.api.baseUrl + 'Shipping/ShippingOrderColumns'
    );
    try {
      await http.post(apiUrl.toString(), data);
    } catch {}
  };

  const PrepareData = (newModel: any) => {
    var data: any = [];
    if (Object.keys(newModel).length === 0) {
      data.push({ columnId: ShippingOrderColumns.Attention });
      data.push({ columnId: ShippingOrderColumns.OrderDate });
      data.push({ columnId: ShippingOrderColumns.CustomerName });
      data.push({ columnId: ShippingOrderColumns.CategoryName });
      data.push({ columnId: ShippingOrderColumns.AutogeneratedReferenceNo });
      data.push({ columnId: ShippingOrderColumns.OrderReference });
      data.push({ columnId: ShippingOrderColumns.WoDoneVsTotal });
      data.push({ columnId: ShippingOrderColumns.Partner });
      data.push({ columnId: ShippingOrderColumns.PartnerTrackingNo });
      data.push({ columnId: ShippingOrderColumns.FromTo });
      data.push({ columnId: ShippingOrderColumns.ServiceName });
      data.push({ columnId: ShippingOrderColumns.Status });
      data.push({ columnId: ShippingOrderColumns.TotalWeight });
      data.push({ columnId: ShippingOrderColumns.TotalNoOfPieces });
    } else {
      if (newModel.attention) {
        data.push({ columnId: ShippingOrderColumns.Attention });
      }
      if (newModel.orderDate) {
        data.push({ columnId: ShippingOrderColumns.OrderDate });
      }
      if (newModel.customerName) {
        data.push({ columnId: ShippingOrderColumns.CustomerName });
      }
      if (newModel.categoryName) {
        data.push({ columnId: ShippingOrderColumns.CategoryName });
      }
      if (newModel.autogeneratedReferenceNo) {
        data.push({ columnId: ShippingOrderColumns.AutogeneratedReferenceNo });
      }
      if (newModel.orderReference) {
        data.push({ columnId: ShippingOrderColumns.OrderReference });
      }
      if (newModel.woDoneVsTotal) {
        data.push({ columnId: ShippingOrderColumns.WoDoneVsTotal });
      }
      if (newModel.partner) {
        data.push({ columnId: ShippingOrderColumns.Partner });
      }
      if (newModel.partnerTrackingNo) {
        data.push({ columnId: ShippingOrderColumns.PartnerTrackingNo });
      }
      if (newModel.fromTo) {
        data.push({ columnId: ShippingOrderColumns.FromTo });
      }
      if (newModel.serviceName) {
        data.push({ columnId: ShippingOrderColumns.ServiceName });
      }
      if (newModel.status) {
        data.push({ columnId: ShippingOrderColumns.Status });
      }
      if (newModel.totalWeight) {
        data.push({ columnId: ShippingOrderColumns.TotalWeight });
      }
      if (newModel.totalNoOfPieces) {
        data.push({ columnId: ShippingOrderColumns.TotalNoOfPieces });
      }
    }

    return data;
  };

  const handleChangeDateFilterSearch = debounce(
    (fromDate: any, toDate: any) => {
      if (fromDate && toDate) {
        var from = moment(fromDate, 'ddd MMM D YYYY');
        from.set({ h: 0, m: 0, s: 1 });
        var to = moment(toDate, 'ddd MMM D YYYY');
        to.set({ h: 23, m: 59, s: 59 });

        setFromDate(from.format(dateFormat));
        setToDate(to.format(dateFormat));
      }
      setIsIgnoreDateFilter(false);
    },
    500
  );

  const handleChangeBasicSearch = debounce((searchExpression: any) => {
    var expression = '';
    if (searchExpression.target.value) {
      if (columnVisibilityModel.customerName) {
        expression = 'Customer.Name~like~' + searchExpression.target.value;
      }
      if (columnVisibilityModel.orderReference) {
        expression +=
          expression !== ''
            ? ' or OrderReference~like~' + searchExpression.target.value
            : 'OrderReference~like~' + searchExpression.target.value;
      }
      if (columnVisibilityModel.autogeneratedReferenceNo) {
        expression +=
          expression !== ''
            ? ' or AutogeneratedReferenceNo~like~' +
              searchExpression.target.value
            : 'AutogeneratedReferenceNo~like~' + searchExpression.target.value;
      }
    }
    setBasicSearchExpression(expression);
  }, 500);

  return (
    <div>
      <div className="heading-section">
        <div className="heading-section-left">
          <Typography variant="h3" sx={{ mb: 3 }}>
            {I18n('Nav.ShippingOrders')}
          </Typography>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>
        <div className="heading-section-right">
          <div className="search-control-outer">
            <FormControl
              margin="normal"
              displayLabel=""
              required
              inputType="text"
              placeholderText={I18n('Common.Search')}
              className="search-control small-form-control"
              handleChange={handleChangeBasicSearch}
              adornmentValue={
                <InputAdornment position="start">
                  <FontAwesomeIcon icon={faSearch} />
                </InputAdornment>
              }
            ></FormControl>
          </div>
          <div className="header-btn-wrap">
            <Popper
              open={open}
              anchorEl={anchorEl}
              placement={placement}
              transition
              className="filter-popper"
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper>
                    <Typography variant="h5" className="filter-heading">
                      Filter
                      <IconButton
                        aria-label="delete"
                        onClick={popperHandleClick('bottom-end')}
                      >
                        <FontAwesomeIcon
                          icon={faTimes}
                          className="fa-sm close-icon"
                        />
                      </IconButton>
                    </Typography>
                    <div className="filter-content form-small-gap">
                      <Grid
                        container
                        spacing={2}
                        className="grid-wrap two-col"
                      ></Grid>
                      <Grid item xs={12}>
                        <CustomSelectPaginate
                          additional={defaultAdditional}
                          value={selectedCategory}
                          loadOptions={(
                            search: string,
                            prevOptions: any,
                            page: any
                          ) =>
                            loadCategoryOptions(
                              MasterCategoryEnumType.AllowedOrderCategory,
                              search,
                              prevOptions,
                              page
                            )
                          }
                          handleChange={(newValue: any) =>
                            setSelectedCategory(newValue)
                          }
                          placeholder={defaultOrderCategoryValue.label}
                          isSearchable={true}
                          displayLabel={I18n('ShippingOrders.OrderCategory')}
                          errorValue={true}
                          debounceTimeout={500}
                          isMultiSelect={true}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CustomizedSelect
                          placeholder={defaultShippingTypeValue.label}
                          options={shippingTypeList}
                          isSearchable={false}
                          displayLabel={I18n('ShippingOrders.Type')}
                          handleChange={(newValue: any) =>
                            setSelectedShippingType(newValue)
                          }
                          value={selectedShippingType}
                          isMulti={true}
                        ></CustomizedSelect>
                      </Grid>
                      <Grid item xs={4}>
                        <CustomSelectPaginate
                          additional={defaultAdditional}
                          value={selectedService}
                          loadOptions={(
                            search: string,
                            prevOptions: any,
                            page: any
                          ) =>
                            loadCategoryOptions(
                              MasterCategoryEnumType.ShippingService,
                              search,
                              prevOptions,
                              page
                            )
                          }
                          handleChange={(newValue: any) =>
                            setSelectedService(newValue)
                          }
                          placeholder={defaultServiceValue.label}
                          isSearchable={true}
                          displayLabel={I18n('ShippingOrders.Service')}
                          errorValue={true}
                          debounceTimeout={500}
                          isMultiSelect={true}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CustomizedSelect
                          placeholder={defaultStatusValue.label}
                          options={statusList}
                          isSearchable={false}
                          displayLabel={I18n('ShippingOrders.Status')}
                          handleChange={(newValue: any) =>
                            setSelectedStatus(newValue)
                          }
                          value={selectedStatus}
                          isMulti={true}
                        ></CustomizedSelect>
                      </Grid>
                      <Grid item xs={12}>
                        <CustomDateRangePicker
                          displayLabel={I18n('Common.DateRange')}
                          fromDate={fromDate}
                          toDate={toDate}
                          handleChange={(fromDate: any, toDate: any) => {
                            handleChangeDateFilterSearch(fromDate, toDate);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          className="mt-0"
                          control={
                            <Checkbox
                              onChange={(event) =>
                                setIsIgnoreDateFilter(event.target.checked)
                              }
                              checked={isIgnoreDateFilter}
                            />
                          }
                          label={I18n('Common.IgnoreDateFilter')}
                        />
                      </Grid>
                      <div className="btn-wrap">
                        <Button
                          color="neutral"
                          disableElevation
                          variant="contained"
                          onClick={handleResetClick}
                        >
                          {I18n('Common.Reset')}
                        </Button>
                        <Button
                          color="primary"
                          disableElevation
                          variant="contained"
                          onClick={handleAdvanceSearchFilterClick}
                        >
                          {I18n('Common.Apply')}
                        </Button>
                      </div>
                    </div>
                  </Paper>
                </Fade>
              )}
            </Popper>
            <Button
              color="info"
              onClick={popperHandleClick('bottom-end')}
              disableElevation
              variant="outlined"
              className={`icon-btn ${isFilterEnabled ? 'is-filtered' : ''}`}
            >
              <FontAwesomeIcon icon={faFilter} className="fa-lg" />
            </Button>
            {hasPermission(Permission.canViewShippingOrders) && (
              <Button
                color="info"
                className="small-btn"
                disableElevation
                variant="contained"
                onClick={handleExportClick}
              >
                {I18n('Common.Export')}
              </Button>
            )}
            {hasPermission(Permission.canAddShippingOrders) && (
              <Button
                color="info"
                className="small-btn"
                disableElevation
                variant="contained"
                onClick={addShippingOrderClicked}
              >
                <FontAwesomeIcon icon={faPlus} className="fa-md mr-2" />
                {I18n('Common.Add')}
              </Button>
            )}
            {isApprover && (
              <Button
                color="info"
                className="small-btn"
                disableElevation
                variant="contained"
                onClick={handleApproveOrder}
              >
                {I18n('Common.ApproveOrder')}
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className="checkbox-header-group">
        <FormControlLabel
          control={
            <Checkbox
              name="past"
              className="search-control small-form-control"
              onChange={handlePastClick}
              checked={isPast}
              inputProps={{ 'aria-label': 'controlled' }}
              sx={{
                color: 'white',
                '&.Mui-checked': {
                  color: 'white',
                },
              }}
            />
          }
          label={I18n('Common.Past')}
        />
        <FormControlLabel
          control={
            <Checkbox
              name="today"
              className="search-control small-form-control"
              onChange={handleTodayClick}
              checked={isToday}
              inputProps={{ 'aria-label': 'controlled' }}
              sx={{
                color: 'white',
                '&.Mui-checked': {
                  color: 'white',
                },
              }}
            />
          }
          label={I18n('Common.Today')}
        />
        <FormControlLabel
          control={
            <Checkbox
              name="future"
              className="search-control small-form-control"
              onChange={handleFutureClick}
              checked={isFuture}
              inputProps={{ 'aria-label': 'controlled' }}
              sx={{
                color: 'white',
                '&.Mui-checked': {
                  color: 'white',
                },
              }}
            />
          }
          label={I18n('Common.Future')}
        />
      </div>
      <div className="main-content-section">
        <div className="content-block table-fix">
          <CustomDataGrid
            isRowSelectable={(params: GridRowParams) =>
              params.row.status === ShippingOrderStatus.SentForApproval
            }
            checkboxSelection={isApprover}
            selectedValues={selectedIds}
            selectionModel={rowsSelected}
            rowCount={gridData.totalRows}
            data={gridData.rows}
            columns={columns}
            pageNo={gridData.page}
            pageSize={gridData.pageSize}
            onSortModelChange={handleSortModelChange}
            onPageChange={handleChangePage}
            onPageSizeChange={handleChangePageSize}
            rowsPerPageOptions={gridData.rowsPerPageOptions}
            initialState={{
              sorting: {
                sortModel: [{ field: 'orderDate', sort: 'desc' }],
              } as GridSortingInitialState,
              pagination: {
                page: gridData.page,
                pageSize: gridData.pageSize,
              } as GridPaginationInitialState,
            }}
            onRowClick={handleOnRowClick}
            columnVisibilityModel={columnVisibilityModel}
            handleColumnVisibilityModelChange={handleColumnVisibilityChange}
            columnVisibilityToolbar={true}
            exportToolbar={false}
          ></CustomDataGrid>
          <ConfirmationPopup
            isOpen={isCancelOrderModalOpen}
            data={orderId}
            message={i18nMessages.cancelOrderCofirmation}
            handleClose={handleCloseCancelOrderModal}
            handleOk={handleOkCancelOrderModal}
          ></ConfirmationPopup>
          {/* <ConfirmationPopup
            isOpen={isCopyModalOpen}
            data={orderId}
            message={i18nMessages.copyConfirmation}
            handleClose={handleCloseCopyModal}
            handleOk={handleOkCopyModal}
          ></ConfirmationPopup> */}
          <OrderCopyPopup
            isOpen={isCopyModalOpen}
            data={orderId}
            message={i18nMessages.copyConfirmation}
            handleClose={handleCloseCopyModal}
            handleOk={handleOkCopyModal}
          ></OrderCopyPopup>
          <CircularProgressWithLabel value={progress} isVisible={isLoading} />
        </div>
      </div>
    </div>
  );
}
