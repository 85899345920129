import environment from 'environment';
import {
  DeckPackIrregularityContactClientDto,
  DeckPackWorkOrderHistoryDto,
  DecPackQuestionsDto,
  DecPackResponseDto,
  DecPackWOIBODTO,
  DnPWorkOrders,
  DecPackDetailsWODTO,
} from 'models/deck-pack-orders';
import {
  ApiResponse,
  BaseModel,
  CommonListRequestModel,
  MasterCategoryEnumType,
  PaginationResponseModel,
  CategoryBaseModel,
  CategoryResponseModel,
  MasterCategoryCode,
} from 'models/pagination-model';
import { Customer } from 'models/service-catalogue';
import http from '../../utilities/httpService';
import { DecPackOrderStatus } from 'models/shipping-orders';
import { hasPermission } from 'utilities/protectedRoute';
import { Permission } from 'Permissions';

export async function deleteDecPackOrder(id: number) {
  const apiUrl = new URL(environment.api.baseUrl + 'DecPackOrder/' + id);
  try {
    const result: any = await http.delete(apiUrl.toString());
    if (result && result.data) {
      return {
        result: result.data,
        data: null,
      } as ApiResponse<null>;
    } else {
      return {
        result: false,
        data: null,
      } as ApiResponse<null>;
    }
  } catch (error: any) {
    return {
      result: false,
      data: null,
    } as ApiResponse<null>;
  }
}

export async function updateOrderStatus(id: number, status: string) {
  const apiUrl = new URL(
    environment.api.baseUrl + `DecPackOrder/UpdateOrderStatus/${id}/${status}`
  );
  try {
    const result = await http.put(apiUrl.toString());
    if (result && result.data) {
      return result.data;
    }
    return false;
  } catch (error) {
    return false;
  }
}

export async function rejectDecPackOffer(id: number, rejectionId: number) {
  const apiUrl = new URL(
    environment.api.baseUrl +
      'DecPackOffer/DecPackOfferReject/' +
      id +
      '/' +
      rejectionId
  );
  try {
    const result: any = await http.put(apiUrl.toString());
    if (result && result.data) {
      return {
        result: result.data,
        data: null,
      } as ApiResponse<null>;
    } else {
      return {
        result: false,
        data: null,
      } as ApiResponse<null>;
    }
  } catch (error: any) {
    return {
      result: false,
      data: null,
    } as ApiResponse<null>;
  }
}

export async function getDecPackOrders(
  request: CommonListRequestModel<DecPackResponseDto>,
  decpackFilter: string = '',
  serviceTypeFilter: string = '',
  statusFilter: string = ''
) {
  let basicSearchExpression = request.basicSearchExpression;
  let advanceSearchExpression = request.advanceSearchExpression;
  const apiUrl = new URL(environment.api.baseUrl + 'DecPackOrder');
  try {
    let finalSearchTerm = '';

    if (basicSearchExpression && advanceSearchExpression) {
      finalSearchTerm = `${advanceSearchExpression} and ${basicSearchExpression}`;
    } else if (basicSearchExpression && !advanceSearchExpression) {
      finalSearchTerm = basicSearchExpression;
    } else if (!basicSearchExpression && advanceSearchExpression) {
      finalSearchTerm = advanceSearchExpression;
    }
    apiUrl.searchParams.set('$filter', finalSearchTerm);
    apiUrl.searchParams.set('$orderby', request.gridData.sortBy);
    apiUrl.searchParams.set(
      '$skip',
      (request.gridData.page * request.gridData.pageSize).toString()
    );
    apiUrl.searchParams.set('$top', request.gridData.pageSize.toString());
    apiUrl.searchParams.set(
      '$orderbydirection',
      request.gridData.sortOrder.toString()
    );
    apiUrl.searchParams.set(
      'decPackFilter',
      decpackFilter && decpackFilter?.toString()
    );
    apiUrl.searchParams.set('serviceTypeFilter', serviceTypeFilter);
    apiUrl.searchParams.set('statusFilter', statusFilter);

    const result = await http.get(apiUrl.toString());
    if (result) {
      request.gridData.rows = result.data.values;
      request.gridData.totalRows = result.data.totalCount;
    }
    return request;
  } catch (error) {}
}

export async function getDecPackOffers(
  request: CommonListRequestModel<DecPackResponseDto>,
  decpackFilter: string = '',
  serviceTypeFilter: string = '',
  statusFilter: string = '',
  validityFilter: string = '',
  offerStatusFilter: string = '',
  rejectionFilter: string = '',
  priceRangeFilter: string = ''
) {
  let basicSearchExpression = request.basicSearchExpression;
  let advanceSearchExpression = request.advanceSearchExpression;
  const apiUrl = new URL(environment.api.baseUrl + 'DecPackOffer');
  try {
    let finalSearchTerm = '';

    if (basicSearchExpression && advanceSearchExpression) {
      finalSearchTerm = `${advanceSearchExpression} and ${basicSearchExpression}`;
    } else if (basicSearchExpression && !advanceSearchExpression) {
      finalSearchTerm = basicSearchExpression;
    } else if (!basicSearchExpression && advanceSearchExpression) {
      finalSearchTerm = advanceSearchExpression;
    }
    apiUrl.searchParams.set('$filter', finalSearchTerm);
    apiUrl.searchParams.set('$orderby', request.gridData.sortBy);
    apiUrl.searchParams.set(
      '$skip',
      (request.gridData.page * request.gridData.pageSize).toString()
    );
    apiUrl.searchParams.set('$top', request.gridData.pageSize.toString());
    apiUrl.searchParams.set(
      '$orderbydirection',
      request.gridData.sortOrder.toString()
    );
    apiUrl.searchParams.set(
      'decPackFilter',
      decpackFilter && decpackFilter?.toString()
    );
    apiUrl.searchParams.set('serviceTypeFilter', serviceTypeFilter);
    apiUrl.searchParams.set('statusFilter', statusFilter);
    apiUrl.searchParams.set(
      'validityFilter',
      validityFilter && validityFilter?.toString()
    );
    apiUrl.searchParams.set('offerStatusFilter', offerStatusFilter);
    apiUrl.searchParams.set('rejectionFilter', rejectionFilter);
    apiUrl.searchParams.set('priceRangeFilter', priceRangeFilter);

    const result = await http.get(apiUrl.toString());
    if (result) {
      request.gridData.rows = result.data.values;
      request.gridData.totalRows = result.data.totalCount;
    }
    return request;
  } catch (error) {}
}

export async function GetAllWorkOrderListing(
  request: CommonListRequestModel<DecPackResponseDto>,
  activityOwnerFilter: string = '',
  workOrderTypeFilter: string = '',
  statusFilter: string = ''
) {
  let basicSearchExpression = request.basicSearchExpression;
  let advanceSearchExpression = request.advanceSearchExpression;
  const apiUrl = new URL(
    environment.api.baseUrl + 'DecPackOrder/GetAllWorkOrderListing'
  );
  try {
    let finalSearchTerm = '';
    if (basicSearchExpression && advanceSearchExpression) {
      finalSearchTerm = `${advanceSearchExpression} and ${basicSearchExpression}`;
    } else if (basicSearchExpression && !advanceSearchExpression) {
      finalSearchTerm = basicSearchExpression;
    } else if (!basicSearchExpression && advanceSearchExpression) {
      finalSearchTerm = advanceSearchExpression;
    }
    apiUrl.searchParams.set('$filter', finalSearchTerm);
    apiUrl.searchParams.set('$orderby', request.gridData.sortBy);
    apiUrl.searchParams.set(
      '$skip',
      (request.gridData.page * request.gridData.pageSize).toString()
    );
    apiUrl.searchParams.set('$top', request.gridData.pageSize.toString());
    apiUrl.searchParams.set(
      '$orderbydirection',
      request.gridData.sortOrder.toString()
    );

    apiUrl.searchParams.set(
      'activityOwnerFilter',
      activityOwnerFilter && activityOwnerFilter?.toString()
    );
    apiUrl.searchParams.set('workOrderTypeFilter', workOrderTypeFilter);
    apiUrl.searchParams.set('statusFilter', statusFilter);

    const result = await http.get(apiUrl.toString());
    if (result) {
      request.gridData.rows = result.data.values;
      request.gridData.totalRows = result.data.totalCount;
    }
    return request;
  } catch (error) {}
}

const getCategories = async (
  categoryType: MasterCategoryEnumType,
  search: string,
  pageNo: number
) => {
  let response: PaginationResponseModel<CategoryResponseModel>;
  const apiUrl = new URL(
    environment.api.baseUrl + 'Dropdown/GetMasterCategories'
  );
  try {
    let searchExpression = `entityType~=~${categoryType}`;
    searchExpression += search ? ` and name~like~${search}` : '';
    apiUrl.searchParams.set('$filter', searchExpression);
    apiUrl.searchParams.set('$orderby', 'name');
    apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
    apiUrl.searchParams.set('$top', '10');
    apiUrl.searchParams.set('$orderbydirection', 'desc');
    const result = await http.get(apiUrl.toString());
    if (result) {
      response = {
        TotalRecords: result.data.totalCount,
        Values: result.data.values,
      } as PaginationResponseModel<CategoryResponseModel>;
    } else {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<CategoryResponseModel>;
    }
  } catch (error) {
    response = {
      TotalRecords: 0,
      Values: [],
    } as PaginationResponseModel<CategoryResponseModel>;
  }
  return response;
};

export const getDefaultDocumentTag = async (
  categoryType: MasterCategoryEnumType,
  categoryCode: MasterCategoryCode,
  pageNo: number
) => {
  let response: PaginationResponseModel<CategoryResponseModel>;
  const apiUrl = new URL(
    environment.api.baseUrl + 'Dropdown/GetMasterCategories'
  );
  try {
    let searchExpression = `entityType~=~${categoryType}`;
    searchExpression += ` and code~=~${categoryCode}`;
    apiUrl.searchParams.set('$filter', searchExpression);
    apiUrl.searchParams.set('$orderby', 'name');
    apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
    apiUrl.searchParams.set('$top', '10');
    apiUrl.searchParams.set('$orderbydirection', 'desc');
    const result = await http.get(apiUrl.toString());
    if (result) {
      response = {
        TotalRecords: result.data.totalCount,
        Values: result.data.values,
      } as PaginationResponseModel<CategoryResponseModel>;
    } else {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<CategoryResponseModel>;
    }
  } catch (error) {
    response = {
      TotalRecords: 0,
      Values: [],
    } as PaginationResponseModel<CategoryResponseModel>;
  }
  return response;
};

export async function loadCategoryOptions(
  categoryType: MasterCategoryEnumType,
  search: string,
  loadedOptions: any,
  { page }: any
) {
  const response = await getCategories(categoryType, search, page);
  const hasMore = Math.ceil(response.TotalRecords / 10) > page;

  return {
    options: response.Values.map((x) => {
      return {
        value: x.id.toString(),
        label: x.name,
        code: x.code,
      } as CategoryBaseModel;
    }),
    hasMore,
    additional: {
      page: page + 1,
    },
  };
}

export async function getWorkOrderQuestions(workOrderNo: DnPWorkOrders) {
  const apiUrl = new URL(
    environment.api.baseUrl + `DecPackOrder/GetDecPackQuestions/${workOrderNo}`
  );
  try {
    const result = await http.get(apiUrl.toString());
    if (result) {
      return {
        data: result.data,
        result: result.status === 200,
      } as ApiResponse<DecPackQuestionsDto[]>;
    } else {
      return {
        data: [],
        result: false,
      } as ApiResponse<DecPackQuestionsDto[]>;
    }
  } catch (error) {
    return {
      data: [],
      result: false,
    } as ApiResponse<DecPackQuestionsDto[]>;
  }
}

export async function getWorkOrderStatus(
  workOrderNo: DnPWorkOrders,
  id: number
) {
  const apiUrl = new URL(
    environment.api.baseUrl + `DecPackOrder/GetDecPackWO/${id}/${workOrderNo}`
  );
  try {
    const result = await http.get(apiUrl.toString());
    if (result) {
      return {
        data: result.data,
        result: result.status === 200,
      } as ApiResponse<any>;
    } else {
      return {
        data: [],
        result: false,
      } as ApiResponse<any>;
    }
  } catch (error) {
    return {
      data: [],
      result: false,
    } as ApiResponse<any>;
  }
}

export async function getWorkOrderIBODetails(woId: number) {
  const apiUrl = new URL(
    environment.api.baseUrl + `DecPackIBOWorkOrder/${woId}`
  );
  try {
    const result = await http.get(apiUrl.toString());
    if (result) {
      return {
        data: result.data,
        result: result.status === 200,
      } as ApiResponse<DecPackWOIBODTO[]>;
    } else {
      return {
        data: [],
        result: false,
      } as ApiResponse<DecPackWOIBODTO[]>;
    }
  } catch (error) {
    return {
      data: [],
      result: false,
    } as ApiResponse<DecPackWOIBODTO[]>;
  }
}

export async function getDGPlusData(unnumber: string) {
  const apiUrl = new URL(environment.api.baseUrl + `DGPlus/${unnumber}`);
  try {
    const result = await http.get(apiUrl.toString());
    if (result) {
      return {
        data: result.data,
        result: result.status === 200,
      } as ApiResponse<any[]>;
    } else {
      return {
        data: [],
        result: false,
      } as ApiResponse<any[]>;
    }
  } catch (error) {
    return {
      data: [],
      result: false,
    } as ApiResponse<any[]>;
  }
}

export async function savedecPackWOIBOAnswers(
  decPackWOIBOData: DecPackWOIBODTO[]
) {
  try {
    const apiUrl = new URL(environment.api.baseUrl + 'DecPackIBOWorkOrder/');
    const result: any = await http.put(apiUrl.toString(), decPackWOIBOData);
    if (result) {
      return {
        result: result.data,
        data: null,
      } as ApiResponse<null>;
    } else {
      return {
        result: false,
        data: null,
      } as ApiResponse<null>;
    }
  } catch (error: any) {}
  return {
    result: false,
    data: null,
  } as ApiResponse<null>;
}

export async function getDecPackById(id: number) {
  const apiUrl = new URL(environment.api.baseUrl + `DecPackOrder/${id}`);
  try {
    const result = await http.get(apiUrl.toString());
    if (result && result.data) {
      const finalData = {
        HazgoReference: result.data.hazgoReference,
        autogeneratedOfferReferenceNo:
          result.data.autogeneratedOfferReferenceNo,
        serviceType: result.data.serviceType,
        CustomerReference: result.data.customerReference,
        Shipper: result.data.shipper,
        locationType: result.data.locationType,
        selectedContacts: result.data.selectedContacts,
        SecuredShipment: result.data.securedShipment,
        DriverName: result.data.driverName,
        LicensePlate: result.data.licensePlate,
        Transporter: result.data.transporter,
        NumberofPieces: result.data.piecesNo,
        Pickupaddress: result.data.pickUpOriginAddress,
        ExpectedInboundDate: result.data.expectedInboundDate,
        Interventie: result.data.interventie,
        SpecialInstructions: result.data.specialInstructions,
        HighPriority: result.data.isHighPriority,
        EstimatedDuration: result.data.estimatedDuration,
        isDecPackOfferConverted: result.data.isDecPackOfferConverted,
        status: result.data.status,
        type: result.data.type,
        orderDate: new Date().toISOString(),
        customer: {
          value: result.data.customerId,
          label: result.data.customerName,
        },
        customerContact: {
          value: result.data.contactPersonId,
          label: result.data.contactPersonName,
        },
        deliveryLocation: {
          value: result.data.deliveryLocationId,
          label: result.data.deliveryLocationAddress,
        },
        Airline: {
          value: result.data.airlineId,
          label: result.data.airlineName,
        },
        FinalDestination: {
          value: result.data.finalDestinationId,
          label: result.data.finalDestinationName,
        },
        TransitDestination: {
          value: result.data.transitDestinationId,
          label: result.data.transitDestinationName,
        },
        address: result.data.pickUpOriginAddress,
        customerLocation: {
          value: result.data.locationId,
          label: result.data.locationAddress,
        },
        TotalWorkOrder: result.data.totalWorkOrder,
        CompletedWorkOrder: result.data.completedWorkOrder,
        executionTime: result.data.executionTime,
        ExecutionDate: result.data.executionDate,
        StartTime: result.data.startTime,
        EndTime: result.data.endTime,
        KcRaAgent: result.data.kcRaAgent,
        PickUpBy: result.data.pickUpBy,
        locationId: result.data.locationId,
        deliveryLocationId: result.data.deliveryLocationId,
        customerEmail: result.data.customerEmail,
        approvedBOM: result.data.approvedBOM,
        wo10Notes: result.data.wo10Notes,
        wo10ProformaNotes: result.data.wo10ProformaNotes,
        wo8Notes: result.data.wo8Notes,
        bomNotes: result.data.bomNotes,
        companyName: result.data.companyName,
        rejectionMasterName: result.data.rejectionMasterName,
        isOfferRejected: result.data.isOfferRejected,
        DeliveryReference: result.data.deliveryReference,
        CustomerCurrency: result.data.customerCurrency,
        offerStatus: result.data.offerStatus,
      };
      return finalData;
    } else {
      return null;
    }
  } catch (error) {}
}

export async function getDeckpackWorkOrderHistory(
  skip: number,
  take: number,
  workOrderId: number
) {
  const apiUrl = new URL(
    environment.api.baseUrl +
      `DeckPackIrregularityContactClient/GetWorkOrderHistory`
  );
  try {
    apiUrl.searchParams.set('skip', skip.toString());
    apiUrl.searchParams.set('take', take.toString());
    apiUrl.searchParams.set('workOrderId', workOrderId.toString());
    const result = await http.get(apiUrl.toString());
    if (result) {
      return {
        data: result.data,
        result: result.status === 200,
      } as ApiResponse<DeckPackWorkOrderHistoryDto>;
    } else {
      return {
        data: null,
        result: false,
      } as ApiResponse<null>;
    }
  } catch (error) {
    return {
      data: null,
      result: false,
    } as ApiResponse<null>;
  }
}

export async function getContactCustomerViaEmailById(
  workOrderId: number,
  orderId: number
) {
  const apiUrl = new URL(
    environment.api.baseUrl +
      `DeckPackIrregularityContactClient/${workOrderId}/${orderId}`
  );
  try {
    const result = await http.get(apiUrl.toString());
    if (result) {
      return {
        data: result.data,
        result: result.status === 200,
      } as ApiResponse<DeckPackIrregularityContactClientDto>;
    } else {
      return {
        data: null,
        result: false,
      } as ApiResponse<null>;
    }
  } catch (error) {
    return {
      data: null,
      result: false,
    } as ApiResponse<null>;
  }
}

export async function saveContactCustomerViaEmail(
  deckPackIrregularityContactClientDto: DeckPackIrregularityContactClientDto
) {
  try {
    const apiUrl = new URL(
      environment.api.baseUrl + 'DeckPackIrregularityContactClient'
    );
    const result: any = await http.put(
      apiUrl.toString(),
      deckPackIrregularityContactClientDto
    );
    if (result) {
      return {
        result: result.data,
        data: null,
      } as ApiResponse<null>;
    } else {
      return {
        result: false,
        data: null,
      } as ApiResponse<null>;
    }
  } catch (error: any) {}
  return {
    result: false,
    data: null,
  } as ApiResponse<null>;
}
export async function ExportDecPackOrders(
  request: CommonListRequestModel<DecPackResponseDto>,
  decpackFilter: string = '',
  serviceTypeFilter: string = '',
  statusFilter: string = ''
) {
  let basicSearchExpression = request.basicSearchExpression;
  let advanceSearchExpression = request.advanceSearchExpression;
  const apiUrl = new URL(
    environment.api.baseUrl + 'DecPackOrder/ExportDecPackOrders'
  );
  try {
    let finalSearchTerm = '';
    if (basicSearchExpression && advanceSearchExpression) {
      finalSearchTerm = `${advanceSearchExpression} and ${basicSearchExpression}`;
    } else if (basicSearchExpression && !advanceSearchExpression) {
      finalSearchTerm = basicSearchExpression;
    } else if (!basicSearchExpression && advanceSearchExpression) {
      finalSearchTerm = advanceSearchExpression;
    }
    apiUrl.searchParams.set('$filter', finalSearchTerm);
    apiUrl.searchParams.set('$orderby', request.gridData.sortBy);
    apiUrl.searchParams.set(
      '$orderbydirection',
      request.gridData.sortOrder.toString()
    );
    apiUrl.searchParams.set(
      'decPackFilter',
      decpackFilter && decpackFilter?.toString()
    );
    apiUrl.searchParams.set('serviceTypeFilter', serviceTypeFilter);
    apiUrl.searchParams.set('statusFilter', statusFilter);

    const result = await http.get(apiUrl.toString(), {
      responseType: 'blob',
    });

    return result;
  } catch (error) {}
}
export async function ExportAllDecPacWorkkOrders(
  request: CommonListRequestModel<DecPackResponseDto>,
  activityOwnerFilter: string = '',
  workOrderTypeFilter: string = '',
  statusFilter: string = ''
) {
  let basicSearchExpression = request.basicSearchExpression;
  let advanceSearchExpression = request.advanceSearchExpression;
  const apiUrl = new URL(
    environment.api.baseUrl + 'DecPackOrder/GetExportAllWorkOrderListing'
  );
  try {
    let finalSearchTerm = '';
    if (basicSearchExpression && advanceSearchExpression) {
      finalSearchTerm = `${advanceSearchExpression} and ${basicSearchExpression}`;
    } else if (basicSearchExpression && !advanceSearchExpression) {
      finalSearchTerm = basicSearchExpression;
    } else if (!basicSearchExpression && advanceSearchExpression) {
      finalSearchTerm = advanceSearchExpression;
    }
    apiUrl.searchParams.set('$filter', finalSearchTerm);
    apiUrl.searchParams.set('$orderby', request.gridData.sortBy);
    apiUrl.searchParams.set(
      '$orderbydirection',
      request.gridData.sortOrder.toString()
    );
    apiUrl.searchParams.set(
      'activityOwnerFilter',
      activityOwnerFilter && activityOwnerFilter?.toString()
    );
    apiUrl.searchParams.set('workOrderTypeFilter', workOrderTypeFilter);
    apiUrl.searchParams.set('statusFilter', statusFilter);

    const result = await http.get(apiUrl.toString(), {
      responseType: 'blob',
    });

    return result;
  } catch (error) {}
}

export const loadUserOptions = async (
  search: string,
  loadedOptions: any,
  { page }: any
) => {
  const response = await getUsers(search, page);
  const hasMore = Math.ceil(response.TotalRecords / 10) > page;

  return {
    options: response.Values.map((x) => {
      return {
        value: x.id.toString(),
        label: x.name,
      } as BaseModel;
    }),
    hasMore,
    additional: {
      page: page + 1,
    },
  };
};
const getUsers = async (search: string, pageNo: number) => {
  let response: PaginationResponseModel<Customer>;
  const apiUrl = new URL(environment.api.baseUrl + 'Users/MobileUsers');
  try {
    let searchExpression = '';
    searchExpression += search
      ? ` firstName~like~${search} or lastName~like~${search}`
      : '';
    apiUrl.searchParams.set('$filter', searchExpression);
    apiUrl.searchParams.set('$orderby', 'firstName');
    apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
    apiUrl.searchParams.set('$top', '10');
    apiUrl.searchParams.set('$orderbydirection', 'asc');
    const result = await http.get(apiUrl.toString());
    if (result.data && result.data.values.length) {
      result.data.values.forEach((z: any) => {
        z.name = `${z.firstName} ${z.lastName}`;
      });
    }
    if (result) {
      response = {
        TotalRecords: result.data.totalCount,
        Values: result.data.values,
      } as PaginationResponseModel<Customer>;
    } else {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<Customer>;
    }
  } catch (error) {
    response = {
      TotalRecords: 0,
      Values: [],
    } as PaginationResponseModel<Customer>;
  }
  return response;
};

export async function getWorkOrderDetails(
  orderId: number,
  workOrderNo: number
) {
  const apiUrl = new URL(
    environment.api.baseUrl + `DecPackWODetails/${orderId}/${workOrderNo}`
  );
  try {
    const result = await http.get(apiUrl.toString());
    if (result) {
      return {
        data: result.data,
        result: result.status === 200,
      } as ApiResponse<DecPackDetailsWODTO>;
    } else {
      return {
        data: null,
        result: false,
      } as ApiResponse<null>;
    }
  } catch (error) {
    return {
      data: null,
      result: false,
    } as ApiResponse<null>;
  }
}

export const CheckOrderEnable = (status: DecPackOrderStatus) => {
  let isEditable = true;
  if (
    (status == DecPackOrderStatus.SentForApproval &&
      !hasPermission(Permission.canApproveDecPackOrders)) ||
    status == DecPackOrderStatus.InvoiceApproved ||
    status == DecPackOrderStatus.Closed ||
    status == DecPackOrderStatus.Cancelled
  ) {
    isEditable = false;
  }

  return isEditable;
};

export async function ConvertShippingOffer(orderId: number) {
  const apiUrl = new URL(
    environment.api.baseUrl + 'DecPackOffer/DecPackOfferConvert/' + orderId
  );
  try {
    const result: any = await http.put(apiUrl.toString());
    if (result && result.data) {
      return {
        result: result.data,
        data: null,
      } as ApiResponse<null>;
    } else {
      return {
        result: false,
        data: null,
      } as ApiResponse<null>;
    }
  } catch (error: any) {
    return {
      result: false,
      data: null,
    } as ApiResponse<null>;
  }
}
