export const Permission = {
  // Service Catelogue
  canViewServiceCatalogue: 'canViewServiceCatalogue',
  canEditServiceCatalogue: 'canEditServiceCatalogue',
  canAddServiceCatalogue: 'canAddServiceCatalogue',
  canDeleteServiceCatalogue: 'canDeleteServiceCatalogue',

  // Customer
  canViewCustomer: 'canViewCustomer',
  canAddCustomer: 'canAddCustomer',
  canEditCustomer: 'canEditCustomer',
  canDeleteCustomer: 'canDeleteCustomer',

  //User
  canViewUser: 'canViewUser',
  canAddUser: 'canAddUser',
  canEditUser: 'canEditUser',
  canDeleteUser: 'canDeleteUser',

  //Role
  canAddRole: 'canAddRole',
  canViewRole: 'canViewRole',
  canEditRole: 'canEditRole',
  canDeleteRole: 'canDeleteRole',

  // Customer
  canViewCustomerApproval: 'canViewCustomerApproval',
  canAddCustomerApproval: 'canAddCustomerApproval',
  canEditCustomerApproval: 'canEditCustomerApproval',
  canDeleteCustomerApproval: 'canDeleteCustomerApproval',

  // Product Catalogue
  canViewProductCatalogueManagement: 'canViewProductCatalogueManagement',
  canEditProductCatalogueManagement: 'canEditProductCatalogueManagement',
  canAddProductCatalogueManagement: 'canAddProductCatalogueManagement',
  canDeleteProductCatalogueManagement: 'canDeleteProductCatalogueManagement',

  // Partner
  canViewPartner: 'canViewPartner',
  canAddPartner: 'canAddPartner',
  canEditPartner: 'canEditPartner',
  canDeletePartner: 'canDeletePartner',
  //Order
  canDeleteOrder: 'canDeletePurchaseOrderManagement',
  canEditOrder: 'canEditPurchaseOrderManagement',
  canViewOrder: 'canViewPurchaseOrderManagement',
  canAddOrder: 'canAddPurchaseOrderManagement',

  //Zone
  canDeleteZone: 'canDeleteZone',
  canEditZone: 'canEditZone',
  canViewZone: 'canViewZone',
  canAddZone: 'canAddZone',

  //Order Type
  canDeleteOrderType: 'canDeletePurchaseOrderType',
  canEditOrderType: 'canEditPurchaseOrderType',
  canViewOrderType: 'canViewPurchaseOrderType',
  canAddOrderType: 'canAddPurchaseOrderType',

  //OrderSettings
  canDeletePurchaseOrderSettings: 'canDeletePurchaseOrderSettings',
  canEditPurchaseOrderSettings: 'canEditPurchaseOrderSettings',
  canViewPurchaseOrderSettings: 'canViewPurchaseOrderSettings',
  canAddPurchaseOrderSettings: 'canAddPurchaseOrderSettings',

  // Tariff Customs
  canViewTariffCustoms: 'canViewTariffCustoms',
  canAddTariffCustoms: 'canAddTariffCustoms',
  canEditTariffCustoms: 'canEditTariffCustoms',
  canDeleteTariffCustoms: 'canDeleteTariffCustoms',

  //TransportCatalouge
  canViewTransportCatalogue: 'canViewTransportCatalogue',
  canEditTransportCatalogue: 'canEditTransportCatalogue',
  canAddTransportCatalogue: 'canAddTransportCatalogue',
  canDeleteTransportCatalogue: 'canDeleteTransportCatalogue',

  // Shipping Orders
  canAddShippingOrders: 'canAddShippingOrders',
  canEditShippingOrders: 'canEditShippingOrders',
  canViewShippingOrders: 'canViewShippingOrders',
  canDeleteShippingOrders: 'canDeleteShippingOrders',
  canApproveShippingOrders: 'canApproveShippingOrders',

  // Questionnaire
  canAddQuestionnaire: 'canAddQuestionnaire',
  canEditQuestionnaire: 'canEditQuestionnaire',
  canViewQuestionnaire: 'canViewQuestionnaire',
  canDeleteQuestionnaire: 'canDeleteQuestionnaire',

  // D&P Orders
  canAddDecPackOrders: 'canAddDecPackOrders',
  canEditDecPackOrders: 'canEditDecPackOrders',
  canViewDecPackOrders: 'canViewDecPackOrders',
  canDeleteDecPackOrders: 'canDeleteDecPackOrders',
  canApproveDecPackOrders: 'canApproveDecPackOrders',

  // D&P Orders
  canAddDecPackOffers: 'canAddDecPackOffers',
  canEditDecPackOffers: 'canEditDecPackOffers',
  canViewDecPackOffers: 'canViewDecPackOffers',
  canDeleteDecPackOffers: 'canDeleteDecPackOffers',

  // Airline
  canViewAirline: 'canViewAirline',
  canAddAirline: 'canAddAirline',
  canEditAirline: 'canEditAirline',
  canDeleteAirline: 'canDeleteAirline',

  // State
  canViewState: 'canViewState',
  canAddState: 'canAddState',
  canEditState: 'canEditState',
  canDeleteState: 'canDeleteState',

  // Label Master
  canViewLabelMaster: 'canViewLabel',
  canAddLabelMaster: 'canAddLabel',
  canEditLabelMaster: 'canEditLabel',
  canDeleteLabelMaster: 'canDeleteLabel',

  // Label Master
  canViewExchangeRate: 'canViewExchangeRate',
  canAddExchangeRate: 'canAddExchangeRate',
  canEditExchangeRate: 'canEditExchangeRate',
  canDeleteExchangeRate: 'canDeleteExchangeRate',

  // Shipping Offers
  canAddShippingOffers: 'canAddShippingOffers',
  canEditShippingOffers: 'canEditShippingOffers',
  canViewShippingOffers: 'canViewShippingOffers',
  canDeleteShippingOffers: 'canDeleteShippingOffers',
};
