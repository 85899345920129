import axios from 'axios';
import environment from 'environment';
import RouteEnum from 'models/RouteEnum';
import { Utilities } from 'utilities/Utilities';

export async function login(loginObj: any) {
  const loginTokenInstance = axios.create();
  return loginTokenInstance
    .post(environment.api.baseUrl + 'Authenticate/login', loginObj)
    .then((response) => {
      if (response.data) {
        localStorage.setItem('token', JSON.stringify(response.data.token));
        localStorage.setItem('user', JSON.stringify(response.data.userName));
        localStorage.setItem('userId', JSON.stringify(response.data.userId));
        localStorage.setItem(
          'refreshToken',
          JSON.stringify(response.data.refreshToken)
        );
        localStorage.setItem(
          'cpUser',
          Utilities.encodeText(
            JSON.stringify(response.data.isCustomerPortalUser)
          )
        );
        var language = '';
        if (response.data.preferredLanguage === 1) {
          language = 'en';
        } else if (response.data.preferredLanguage === 2) {
          language = 'nl';
        } else if (response.data.preferredLanguage === 3) {
          language = 'fr';
        }
        localStorage.setItem('type', 'TRANSLATE_TO');
        localStorage.setItem('payload', language);
        localStorage.setItem('preferredLanguage', language);
        localStorage.setItem('selectedLanguage', language);
      }
      return response.data;
    });
}

export async function logout() {
  localStorage.removeItem('token');
  localStorage.removeItem('permissions');
  localStorage.removeItem('user');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('type');
  localStorage.removeItem('payload');
  localStorage.removeItem('preferredLanguage');
  localStorage.removeItem('selectedLanguage');
  localStorage.removeItem('cpUser');
  window.location.href = RouteEnum.Login;
}

export function refreshToken() {
  const token = localStorage.getItem('token');
  const refreshToken = localStorage.getItem('refreshToken');
  var tokenObj = {
    accessToken: token && JSON.parse(token),
    refreshToken: refreshToken && JSON.parse(refreshToken),
  };
  const refreshTokenInstance = axios.create();
  return refreshTokenInstance
    .post(environment.api.baseUrl + 'Authenticate/RefreshToken', tokenObj)
    .then((response) => {
      if (response.data) {
        localStorage.setItem('token', JSON.stringify(response.data.token));
        localStorage.setItem('user', JSON.stringify(response.data.userName));
        localStorage.setItem(
          'refreshToken',
          JSON.stringify(response.data.refreshToken)
        );
      }
      return response.data;
    });
}

export function getCurrentUser() {
  return localStorage.getItem('token') ? true : false;
}

const authentication = {
  login,
  logout,
  getCurrentUser,
};

export default authentication;
