import environment from 'environment';
import {
  DecPackWOCollectionShipmentDataImagesDTO,
  DecPackWorkOrdersDto,
  DnPWorkOrders,
  GetWorkOrderImagesDTO,
  UpdateWorkOrderImagesDTO,
  WorkOrderImagesDTO,
} from 'models/deck-pack-orders';
import {
  ApiResponse,
  DocumentEntity,
  EntityTypeEnum,
  ImageUploadViewModel,
} from 'models/pagination-model';
import http from '../../utilities/httpService';

async function saveWorkOrderImagesApi(
  updateWorkOrderImages: UpdateWorkOrderImagesDTO
) {
  try {
    const apiUrl = new URL(
      environment.api.baseUrl + 'Documents/SaveWorkOrderImagesAsync'
    );
    const result: any = await http.put(
      apiUrl.toString(),
      updateWorkOrderImages
    );
    if (result) {
      return {
        result: result.data,
        data: null,
      } as ApiResponse<null>;
    } else {
      return {
        result: false,
        data: null,
      } as ApiResponse<null>;
    }
  } catch (error: any) {}
  return {
    result: false,
    data: null,
  } as ApiResponse<null>;
}

async function uploadImages(
  images: ImageUploadViewModel[],
  entityType: EntityTypeEnum,
  documentEntityType: DocumentEntity,
  isSignature: boolean,
  orderId: number,
  workOrderId: number
) {
  const imageModel: WorkOrderImagesDTO[] = [];
  if (images && images.length > 0) {
    for (let index = 0; index < images.length; index++) {
      const element = images[index].image;
      if (
        (element && images[index].isEdited) ||
        (element && !images[index].id)
      ) {
        const apiUrl = new URL(
          environment.api.baseUrl +
            `Documents/${documentEntityType}/${
              images[index] && +images[index].id ? +images[index].id : 0
            }/uploadWorkOrderImage`
        );
        const data = new FormData();
        data.append('file', element);
        const response = await http.post(apiUrl.toString(), data);
        imageModel.push({
          documentTag: images[index].documentTag
            ? +images[index].documentTag.value
            : 0,
          sendToCustomer: images[index].sendToCustomer,
          entityType,
          isSignature,
          imageUrl: response.data,
          orderId,
          workOrderId,
          id: images[index] && images[index].id ? +images[index].id : 0,
          documentEntityType,
        } as WorkOrderImagesDTO);
      } else {
        imageModel.push({
          documentTag: images[index].documentTag
            ? +images[index].documentTag.value
            : 0,
          sendToCustomer: images[index].sendToCustomer,
          entityType,
          isSignature,
          imageUrl: images[index].path,
          orderId,
          workOrderId,
          id: images[index] && images[index].id ? +images[index].id : 0,
          documentEntityType,
        } as WorkOrderImagesDTO);
      }
    }
  }
  return [...imageModel];
}

export async function saveWorkOrderImages(
  images: ImageUploadViewModel[],
  entityType: EntityTypeEnum,
  documentEntityType: DocumentEntity,
  isSignature: boolean,
  orderId: number,
  workOrderId: number
) {
  let imageData: WorkOrderImagesDTO[] = [];
  if (images && images.length > 0) {
    imageData = await uploadImages(
      images,
      entityType,
      documentEntityType,
      isSignature,
      orderId,
      workOrderId
    );
    const imagesResult = await saveWorkOrderImagesApi({
      entityType,
      orderId,
      workOrderId,
      isSignature: false,
      workOrderImages: imageData,
      documentEntityType,
    } as UpdateWorkOrderImagesDTO);
    return imagesResult;
  } else {
    const imagesResult = await saveWorkOrderImagesApi({
      entityType,
      orderId,
      workOrderId,
      isSignature: false,
      workOrderImages: [],
      documentEntityType,
    } as UpdateWorkOrderImagesDTO);
    return imagesResult;
  }
}

export async function getWorkOrderImages(
  entityType: EntityTypeEnum,
  workOrderId: number,
  orderId: number,
  documentEntityType: DocumentEntity
) {
  const apiUrl = new URL(
    environment.api.baseUrl +
      `Documents/GetWorkOrderImagesAsync/${entityType}/${documentEntityType}/${workOrderId}/${orderId}`
  );
  try {
    const result = await http.get(apiUrl.toString());
    if (result) {
      return {
        data: result.data,
        result: result.status === 200,
      } as ApiResponse<GetWorkOrderImagesDTO[]>;
    } else {
      return {
        data: [],
        result: false,
      } as ApiResponse<GetWorkOrderImagesDTO[]>;
    }
  } catch (error) {
    return {
      data: [],
      result: false,
    } as ApiResponse<GetWorkOrderImagesDTO[]>;
  }
}

/* To Upload a Image File for DeckPack Collecting Shipment Data*/
async function uploadWoImage(image: File, documentEntityType: DocumentEntity) {
  try {
    const apiUrl = new URL(
      environment.api.baseUrl + `Documents/${documentEntityType}/upload`
    );
    const data = new FormData();
    data.append('file', image);
    const response = await http.post(apiUrl.toString(), data);
    return {
      result: response && response.data,
      data: response.data,
    } as ApiResponse<string>;
  } catch (error) {
    return {
      result: false,
      data: '',
    } as ApiResponse<string>;
  }
}

/* Method to upload DeckPack Collecting Shipment Data and save to API */
export async function saveShipmentDataImages(
  image: File,
  decPackWOCollectionShipmentDataImages: DecPackWOCollectionShipmentDataImagesDTO
) {
  try {
    const imageResult = await uploadWoImage(
      image,
      decPackWOCollectionShipmentDataImages.documentEntityType
    );
    if (imageResult && imageResult.result) {
      decPackWOCollectionShipmentDataImages.imageUrl = imageResult.data;
      const apiUrl = new URL(
        environment.api.baseUrl +
          `DecPackWOCollectionShipmentDataImages/SaveShipmentDataImagesAsync`
      );
      const result = await http.put(
        apiUrl.toString(),
        decPackWOCollectionShipmentDataImages
      );
      if (result) {
        return {
          data: result.data,
          result: result.status === 200,
        } as ApiResponse<boolean>;
      } else {
        return {
          data: false,
          result: false,
        } as ApiResponse<boolean>;
      }
    }
  } catch (error) {}
}

/* Method to Get DeckPack Collecting Shipment Data Images from API */
export async function getDeckPackCollectingShipmentDataImage(
  decPackWorkOrderId: number,
  decPackInitialOrderId: number,
  documentEntityType: DocumentEntity
) {
  try {
    const apiUrl = new URL(
      environment.api.baseUrl +
        `DecPackWOCollectionShipmentDataImages/${documentEntityType}/${decPackWorkOrderId}/${decPackInitialOrderId}/getById`
    );
    const result = await http.get(apiUrl.toString());
    if (result && result.data) {
      return {
        data: result.data as DecPackWOCollectionShipmentDataImagesDTO,
        result: result.status === 200,
      } as ApiResponse<DecPackWOCollectionShipmentDataImagesDTO>;
    } else {
      return {
        data: null,
        result: false,
      } as ApiResponse<null>;
    }
  } catch (error) {
    return {
      data: null,
      result: false,
    } as ApiResponse<null>;
  }
}

/* Method to Get getDeckPackWorkOrder Details By WoNumberAsync from API */
export async function getDeckPackWorkOrderDetailsByWoNumberAsync(
  decPackInitialOrderId: number,
  workOrderNo: DnPWorkOrders
) {
  try {
    const apiUrl = new URL(
      environment.api.baseUrl +
        `DecPackOrder/GetDeckPackWorkOrderDetailsByWoNumber/${decPackInitialOrderId}/${workOrderNo}`
    );
    const result = await http.get(apiUrl.toString());
    if (result && result.data) {
      return {
        data: result.data as DecPackWorkOrdersDto,
        result: result.status === 200,
      } as ApiResponse<DecPackWorkOrdersDto>;
    } else {
      return {
        data: null,
        result: false,
      } as ApiResponse<null>;
    }
  } catch (error) {}
}
