export enum OrderByDirection {
  Ascending,
  Descending,
}

export interface HeadCell<T> {
  disablePadding: boolean;
  id: keyof T;
  label: string;
  numeric: boolean;
}
export type Order = 'asc' | 'desc';

export enum EntityStatus {
  Active = 1,
  Inactive = 2,
}

export enum WorkOrderStatus {
  ToDo = 1,
  InProgress = 2,
  Done = 3,
  AwaitingAssignment = 4,
}

export enum ServiceOptions {
  Pickup = 1,
  Delivery = 2,
}

export enum AssignmentTypeOptions {
  Driver = 1,
  DecNPack = 2,
  Shipping = 3,
}

export enum Language {
  En = 1,
  NL = 2,
  FR = 3,
}

export enum LoginType {
  Email = 0,
  Mobile = 1,
}

export enum ViewLevel {
  InternalManagement = 1,
  InternalOperational = 2,
  InternalAll = 3,
  External = 4,
}

export enum CustomerTags {
  Shipping = 1,
  DecPack = 2,
  Both = 3,
}

export enum TypeOfService {
  DGCheck = 1,
  DGDCreation = 2,
  NonDGDOrder = 3,
  Shop = 4,
}

export enum CreateLabelOption {
  Shipping = 1,
  Overpack = 2,
  Other = 3,
  WarehouseLabel = 4,
}

export enum CreateLabelLayout {
  Dymo = 1,
  Zebra = 2,
}

export const TypeOfServiceDescription = new Map<number, string>([
  [TypeOfService.DGCheck, 'DecPack.DGCheck'],
  [TypeOfService.DGDCreation, 'DecPack.DGDCreation'],
  [TypeOfService.NonDGDOrder, 'DecPack.NonDGOrder'],
  [TypeOfService.Shop, 'DecPack.Shop'],
]);

export enum CustomerLocation {
  Customer = 1,
  WareHouse = 2,
  GroundHandler = 3,
}

export const CustomerLocationDescription = new Map<number, string>([
  [CustomerLocation.Customer, 'DecPack.Customer'],
  [CustomerLocation.WareHouse, 'DecPack.WareHouse'],
  [CustomerLocation.GroundHandler, 'DecPack.GroundHandler'],
]);

export const CustomerTagsDescription = new Map<number, string>([
  [CustomerTags.Shipping, 'Customer.Shipping'],
  [CustomerTags.DecPack, 'Customer.DecPack'],
  [CustomerTags.Both, 'Customer.Both'],
]);

export const EntityStatusDescription = new Map<number, string>([
  [EntityStatus.Active, 'Common.Active'],
  [EntityStatus.Inactive, 'Common.Inactive'],
]);

export const EntityWorkOrderStatusDescription = new Map<number, string>([
  [WorkOrderStatus.ToDo, 'Common.ToDo'],
  [WorkOrderStatus.InProgress, 'Common.InProgress'],
  [WorkOrderStatus.Done, 'Common.Done'],
]);

export const DnPWorkOrderStatusDescription = new Map<number, string>([
  [WorkOrderStatus.ToDo, 'Common.ToDo'],
  [WorkOrderStatus.InProgress, 'Common.InProgress'],
  [WorkOrderStatus.Done, 'Common.Done'],
  [WorkOrderStatus.AwaitingAssignment, 'Common.AwaitingAssignment'],
]);

export const EntityCreateLabelOptionDescription = new Map<number, string>([
  [
    CreateLabelOption.Shipping,
    'DecPackOrders.CheckDocumentation.ShippingLabel',
  ],
  [
    CreateLabelOption.Overpack,
    'DecPackOrders.CheckDocumentation.OverpackLabel',
  ],
  [CreateLabelOption.Other, 'DecPackOrders.CheckDocumentation.Other'],
  [CreateLabelOption.WarehouseLabel, 'DecPackOrders.WOIBO.WarehouseLabel'],
]);

export const EntityCreateLabelLayoutDescription = new Map<number, string>([
  [CreateLabelLayout.Dymo, 'DecPackOrders.CheckDocumentation.Dymo'],
  [CreateLabelLayout.Zebra, 'DecPackOrders.CheckDocumentation.Zebra'],
]);

export const EntityServiceOptionsDescription = new Map<number, string>([
  [ServiceOptions.Pickup, 'Common.Pickup'],
  [ServiceOptions.Delivery, 'Common.Delivery'],
]);

export const EntityAssignmentTypeOptionsDescription = new Map<number, string>([
  [AssignmentTypeOptions.Driver, 'Common.Driver'],
  [AssignmentTypeOptions.DecNPack, 'Common.DecNPack'],
  [AssignmentTypeOptions.Shipping, 'Common.Shipping'],
]);

export const LanguageDescription = new Map<number, string>([
  [Language.En, 'Common.En'],
  [Language.NL, 'Common.NL'],
  [Language.FR, 'Common.FR'],
]);

export const ViewLevelDescription = new Map<number, string>([
  [ViewLevel.InternalManagement, 'ViewLevel.Options.InternalManagement'],
  [ViewLevel.InternalOperational, 'ViewLevel.Options.InternalOperational'],
  [ViewLevel.InternalAll, 'ViewLevel.Options.InternalAll'],
  [ViewLevel.External, 'ViewLevel.Options.External'],
]);
export enum IsInternalOnly {
  Yes = 1,
  No = 0,
}

export const IsInternalOnlyDescription = new Map<number, string>([
  [IsInternalOnly.Yes, 'Common.Yes'],
  [IsInternalOnly.No, 'Common.No'],
]);

export enum SystemAccess {
  Yes = 1,
  No = 0,
}

export enum DGD {
  Yes = 1,
  No = 2,
}

export enum DocumentEntity {
  Customer = 1,
  Partner = 2,
  CustomerLogo = 3,
  ShippingOrders = 4,
  ServiceCatalogue = 5,
  ProductCatalogue = 6,
  TransportCatalogue = 7,
  WODGD = 8,
  WOInbound = 9,
  WODelivery = 10,
  DecPackOrders = 11,
  DecPackCollectionData = 12,
  DecPackWOCheckDocumentation = 13,
  DecPackWOExecution = 14,
  DeckPackIBODamage = 15,
  DeckPackIBOTransportDocuments = 16,
  DecPackCollectionShipmentData = 17,
  DecPackCollectionImages = 18,
  ShippingInboundImages = 19,
  ShippingPackagingImage = 20,
  UserProfilePicture = 21,
  DeckPackIBOTransportDocumentsHistory = 22,
  DeckPackExecutionDocumentsHistory = 23,
  ShippingWOPickup = 24,
  ShippingWOOutboundImages = 25,
}

export const SystemAccessDescription = new Map<number, string>([
  [SystemAccess.Yes, 'Common.Yes'],
  [SystemAccess.No, 'Common.No'],
]);

export const DGDDescription = new Map<number, string>([
  [DGD.Yes, 'Common.Yes'],
  [DGD.No, 'Common.No'],
]);

export enum ContactPersonFunction {
  Operations = 1,
  Accounting = 2,
  Helpdesk = 3,
}

export const ContactPersonFunctionDescription = new Map<number, string>([
  [ContactPersonFunction.Operations, 'Common.Operations'],
  [ContactPersonFunction.Accounting, 'Common.Accounting'],
  [ContactPersonFunction.Helpdesk, 'Common.Helpdesk'],
]);

export enum AccessLevel {
  All = 1,
  Own = 2,
  Location = 3,
}

export const AccessLevelDescription = new Map<number, string>([
  [AccessLevel.All, 'Common.All'],
  [AccessLevel.Own, 'Common.Own'],
  [AccessLevel.Location, 'Common.Location'],
]);

export interface QuestionSequenceModel {
  id: number;
  sequenceNumber: number;
}

export interface BaseModel {
  label: string;
  value: string;
}

export interface KeyValueBaseModel {
  key: string;
  label: string;
  value: string;
}
export interface CategoryResponseModel {
  id: number;
  name: string;
  code?: MasterCategoryCode;
}

export interface CategoryBaseModel extends BaseModel {
  code?: MasterCategoryCode;
}

export interface RadioGroupBaseModel extends BaseModel {
  disabled: boolean | null;
}

export interface AdvanceModel {
  label: string;
  value: string;
  description: string;
}

export enum Department {
  Shipping = 1,
  DecPack = 2,
  Both = 3,
}

export interface CustomerCategorModel extends BaseModel {
  categoryId?: number | undefined;
  categoryName?: string | undefined;
}

export interface DangerousGoodsMasterModel {
  id: number;
  name: string;
  isUNNumber: boolean;
  isPrimaryClass: boolean;
  isSecondaryClass: boolean;
  isCao: boolean;
  allowMultiple: boolean;
}

export interface DangerousGoodsMasterValueModel
  extends DangerousGoodsMasterModel {
  dangerousGoodsList: DangerousGoodsValueModel[];
}

export interface DangerousGoodsValueModel {
  id: number;
  shippingPackageId: number;
  dangerousGoodsMasterId: number;
  packagesNumber: number;
  unNumber: string;
  primaryClass: string;
  secondaryClass: string;
  cao: boolean;
  sortOrder: number;
}

export const DepartmentSelect = new Map<number, string>([
  [Department.Shipping, 'UsersAdd.DepartmentSelect.Shipping'],
  [Department.DecPack, 'UsersAdd.DepartmentSelect.DecPack'],
  [Department.Both, 'UsersAdd.DepartmentSelect.Both'],
]);

export enum UserType {
  All = 1,
  Web = 2,
  Mobile = 3,
}

export const UserTypeSelect = new Map<number, string>([
  [UserType.All, 'Common.All'],
  [UserType.Web, 'Common.Web'],
  [UserType.Mobile, 'Common.Mobile'],
]);

export enum ShippingAssignmentType {
  Driver = 1,
  Partner = 2,
}

export const ShippingAssignmentTypeSelect = new Map<number, string>([
  [ShippingAssignmentType.Driver, 'WorkorderPickup.Driver'],
  [ShippingAssignmentType.Partner, 'WorkorderPickup.Partner'],
]);

export enum PreferredLanguage {
  EN = 1,
  NL = 2,
  FR = 3,
}

export const PreferredLangSelect = new Map<number, string>([
  [PreferredLanguage.EN, 'Common.Language.EN'],
  [PreferredLanguage.FR, 'Common.Language.FR'],
  [PreferredLanguage.NL, 'Common.Language.NL'],
]);

export enum QuestioType {
  MultipleChoice = 1,
  Dropdown = 2,
  YesOrNo = 3,
  Text = 4,
}

export const QuestioTypeSelect = new Map<number, string>([
  [QuestioType.MultipleChoice, 'Questionnaire.QuestionType.MultipleChoice'],
  [QuestioType.Dropdown, 'Questionnaire.QuestionType.Dropdown'],
  [QuestioType.YesOrNo, 'Questionnaire.QuestionType.YesOrNo'],
  [QuestioType.Text, 'Questionnaire.QuestionType.Text'],
]);

export enum questionnaireSyncOptions {
  Workorder = 1,
  Type = 2,
}

export const questionnaireSyncOptionsText = new Map<number, string>([
  [questionnaireSyncOptions.Workorder, 'Workorder'],
  [questionnaireSyncOptions.Type, 'Type'],
]);

export enum Workorders {
  ShippingWODOC = 1,
  ShippingWOPhyCheck = 2,
  DecPackWOOrderExecution = 3,
}

export const WorkordersSelect = new Map<number, string>([
  [Workorders.ShippingWODOC, 'Shipping WO-Document Check'],
  [Workorders.ShippingWOPhyCheck, 'Shipping WO-Physical Check'],
  [Workorders.DecPackWOOrderExecution, 'Dec Pack WO-6 Order Execution'],
]);

export enum Category {
  Air = 1,
  Road = 2,
  Train = 3,
  Ship = 4,
  Other = 5,
}

export const CategorySelect = new Map<number, string>([
  [Category.Air, 'Partner.CategoryTab.Air'],
  [Category.Road, 'Partner.CategoryTab.Road'],
  [Category.Train, 'Partner.CategoryTab.Train'],
  [Category.Ship, 'Partner.CategoryTab.Ship'],
  [Category.Other, 'Partner.CategoryTab.Other'],
]);

export enum TransportType {
  Air = 1,
  Road = 2,
  Train = 3,
  Ship = 4,
  Own = 5,
}

export enum ActivityOwner {
  BackOffice = 1,
  Driver = 2,
  Partner = 3,
  DecNPack = 4,
  WareHouseStaff = 5,
}

export const ActivityOwnerDescription = new Map<number, string>([
  [ActivityOwner.BackOffice, 'DecPackOrders.WorkOrder.BackOffice'],
  [ActivityOwner.Driver, 'DecPackOrders.WorkOrder.Driver'],
  [ActivityOwner.Partner, 'DecPackOrders.WorkOrder.Partner'],
]);

export enum ShippingOrder {
  Booking = 1,
  Inbound = 4,
}

export enum EntityTypeEnum {
  Customer = 1,
  Partner = 2,
  ServiceCatalogue = 3,
  ProductCatalogue = 4,
  Category = 5,
  TransportCatalogue = 6,
  ShippingOrders = 7,
  DecPackOrders = 8,
  DecPackWODGD = 9,
  WODGD = 10,
  WODelivery = 11,
}

export enum ApprovalStatus {
  Pending = 1,
  Approved = 2,
  Rejected = 3,
  Cancelled = 4,
  Delivered = 5,
  // PartiallyDelivered = 6,
  // Draft = 7,
}

export const ApprovalStatusDescription = new Map<number, string>([
  [ApprovalStatus.Pending, 'Common.Pending'],
  [ApprovalStatus.Approved, 'Common.Approved'],
  [ApprovalStatus.Rejected, 'Common.Rejected'],
  [ApprovalStatus.Cancelled, 'Common.Cancelled'],
  [ApprovalStatus.Delivered, 'Common.Delivered'],
  // [ApprovalStatus.PartiallyDelivered, 'Common.PartiallyDelivered'],
  // [ApprovalStatus.Draft, 'Common.Draft'],
]);

export enum PricingType {
  Standard = 1,
  Special = 2,
}
export const PricingTypeDescription = new Map<number, string>([
  [PricingType.Standard, 'Common.Standard'],
  [PricingType.Special, 'Common.Special'],
]);

export enum ProductCatalogueType {
  SingleUse = 1,
  Reusable = 2,
}
export const ProductCatalogueTypeDescription = new Map<number, string>([
  [ProductCatalogueType.SingleUse, 'Common.SingleUse'],
  [ProductCatalogueType.Reusable, 'Common.Reusable'],
]);

export interface CommonProps {
  entityType: EntityTypeEnum;
  permission: string;
}

export interface TabCommonProps<T> {
  entityType: T;
  entityId: number;
  redirect: string;
}

export interface PaginationResponseModel<T> {
  TotalRecords: number;
  Values: T[];
}

export enum AllowedOrderCategoryType {
  All = 1,
  Specific = 2,
}
export const AllowedOrderCategoryTypeDescription = new Map<number, string>([
  [AllowedOrderCategoryType.All, 'Common.All'],
  [AllowedOrderCategoryType.Specific, 'Common.Specific'],
]);

export enum MasterCategoryEnumType {
  AllowedOrderCategory = 1,
  ServiceCatalogue = 2,
  ProductCatalogue = 3,
  ShippingService = 4,
  TransportCatalogue = 5,
  DocumentCategory = 6,
  OrderTrackingStatusSuggesions = 7,
}

export enum MasterCategoryCode {
  DirectDelivery = 1,
  ExternalTag,
  DGDDocument,
}

export const ShippingMaterialTypeDescription = new Map<number, string>([
  [
    MasterCategoryEnumType.ProductCatalogue,
    'ShippingMaterials.ProductCatalogue',
  ],
  [
    MasterCategoryEnumType.ServiceCatalogue,
    'ShippingMaterials.ServiceCatalogue',
  ],
]);

export const BillableMaterialTypeDescription = new Map<number, string>([
  [
    MasterCategoryEnumType.ProductCatalogue,
    'ShippingMaterials.ProductCatalogue',
  ],
  [
    MasterCategoryEnumType.ServiceCatalogue,
    'ShippingMaterials.ServiceCatalogue',
  ],
  [
    MasterCategoryEnumType.TransportCatalogue,
    'ShippingMaterials.TransportCatalogue',
  ],
]);

export interface ApiResponse<T> {
  result: boolean;
  data: T;
}

export interface CommonListRequestModel<T> {
  basicSearchExpression: string;
  advanceSearchExpression: string;
  gridData: CommonPaginationResponseModel<T>;
}

export interface CommonListRequestModelForDecPack<T> {
  basicSearchExpression: string;
  advanceSearchExpression: string;
  basicDateExpression: string;
  pastExpression: string;
  futureExpression: string;
  todayExpression: string;
  gridData: CommonPaginationResponseModel<T>;
}

export interface CommonPaginationResponseModel<T> {
  isLoading: boolean;
  sortOrder: OrderByDirection;
  sortBy: string;
  rows: T[];
  totalRows: number;
  rowsPerPageOptions: Array<number>;
  pageSize: number;
  page: number;
  searchExpression: string;
}

export enum ShippingOrderColumns {
  Attention = 1,
  OrderDate = 2,
  CustomerName = 3,
  CategoryName = 4,
  AutogeneratedReferenceNo = 5,
  OrderReference = 6,
  WoDoneVsTotal = 7,
  Type = 8,
  Partner = 9,
  PartnerTrackingNo = 10,
  FromTo = 11,
  ServiceName = 12,
  Status = 13,
  TotalWeight = 14,
  TotalNoOfPieces = 15,
}

export const ShippingOrderColumnsDescription = new Map<number, string>([
  [ShippingOrderColumns.Attention, 'attention'],
  [ShippingOrderColumns.OrderDate, 'orderDate'],
  [ShippingOrderColumns.CustomerName, 'customerName'],
  [ShippingOrderColumns.CategoryName, 'categoryName'],
  [ShippingOrderColumns.AutogeneratedReferenceNo, 'autogeneratedReferenceNo'],
  [ShippingOrderColumns.OrderReference, 'orderReference'],
  [ShippingOrderColumns.WoDoneVsTotal, 'woDoneVsTotal'],
  [ShippingOrderColumns.Type, 'type'],
  [ShippingOrderColumns.Partner, 'partner'],
  [ShippingOrderColumns.PartnerTrackingNo, 'partnerTrackingNo'],
  [ShippingOrderColumns.FromTo, 'fromTo'],
  [ShippingOrderColumns.ServiceName, 'serviceName'],
  [ShippingOrderColumns.Status, 'status'],
  [ShippingOrderColumns.TotalWeight, 'totalWeight'],
  [ShippingOrderColumns.TotalNoOfPieces, 'totalNoOfPieces'],
]);

export enum DeckPackOrderColumns {
  OrderDate = 1,
  ExecutionDate = 2,
  ExecutionTime = 3,
  Shipper = 4,
  CustomerName = 5,
  ServiceType = 6,
  HazgoReference = 7,
  LocationAddress = 8,
  WODoneVsTotal = 9,
  Type = 10,
  PickUpOriginAddress = 11,
  FinalDestinationName = 12,
  Status = 13,
  Attention = 14,
  CustomerReference = 15,
  WoInProgress = 16,
  InProgressWorkOrderAssignedTo = 17,
  CustomerContacts = 18,
}

export interface ResponseDTO {
  success: boolean;
  message: string;
}

export interface ApiResponseModel<T> {
  isSuccess: boolean;
  responseType: ResponseType;
  data: T;
}

export enum ResponseType {
  AddSuccess,
  UpdateSuccess,
  DeleteSuccess,
  Fail,
  NotExist,
  Duplicate,
  NotAllow,
  BadRequest,
}

export enum OrderType {
  ShippingOrder = 1,
  DecPackOrder = 2,
}

export enum PartnerType {
  DHL = 1,
  FedEx = 2,
  Others = 3,
}
export const PartnerTypeDescription = new Map<number, string>([
  [PartnerType.DHL, 'Partner.DHL'],
  [PartnerType.FedEx, 'Partner.FedEx'],
  [PartnerType.Others, 'Partner.Others'],
]);

export interface ImageUploadViewModel {
  image?: File;
  documentTag: BaseModel;
  path: string;
  error: string;
  previewUrl: string;
  imageUrl: string;
  id: number;
  componentTempId: string;
  isEdited: boolean;
  imageError: boolean;
  sendToCustomer: boolean;
}

export enum FilterScreens {
  ShippingList = 1,
  ShippingWOs = 2,
  DecPackList = 3,
  DecPackWOs = 4,
  ShippingOfferList = 5,
  DecPackOfferList = 6,
}

export enum PackageCheckList {
  GeneralCargo = 1,
  DangerousGoods = 2,
  None = 3,
}

export enum CustomerTypesList {
  ApprovedCustomer = 1,
  UnapprovedCustomer = 2,
}
export enum AddLocationEntity {
  Default = 0,
  ShippingCustomerLocation = 1,
  ShippingPickupLocation = 2,
  ShippingDeliveryLocation = 3,
  DecPackCustomerLocation = 4,
  DecPackWarehouseLocation = 5,
}
export enum StateRestrictionModule {
  All = 1,
  DecPackWO4 = 2,
  DecPackWO6 = 3,
  ShippingWOBooking = 4,
}
export const StateRestrictionModuleDescription = new Map<number, string>([
  [StateRestrictionModule.All, 'State.All'],
  [StateRestrictionModule.DecPackWO4, 'State.WO4'],
  [StateRestrictionModule.DecPackWO6, 'State.WO6'],
  [StateRestrictionModule.ShippingWOBooking, 'State.ShippingWOBooking'],
]);

export enum AirlineRestrictionModule {
  All = 1,
  DecPackWO4 = 2,
  DecPackWO6 = 3,
  ShippingWOBooking = 4,
}

export const AirlineRestrictionModuleDescription = new Map<number, string>([
  [AirlineRestrictionModule.All, 'Airline.All'],
  [AirlineRestrictionModule.DecPackWO4, 'Airline.WO4'],
  [AirlineRestrictionModule.DecPackWO6, 'Airline.WO6'],
  [AirlineRestrictionModule.ShippingWOBooking, 'Airline.ShippingWOBooking'],
]);

export enum TansportCategories {
  Air = 1,

  Road = 2,

  Train = 3,

  Ship = 4,

  Other = 5,
}

export const TansportCategoriesDescription = new Map<number, string>([
  [TansportCategories.Air, 'Partner.CategoryTab.Air'],
  [TansportCategories.Road, 'Partner.CategoryTab.Road'],
  [TansportCategories.Train, 'Partner.CategoryTab.Train'],
  [TansportCategories.Ship, 'Partner.CategoryTab.Ship'],
  [TansportCategories.Other, 'Partner.CategoryTab.Other'],
]);

export enum BOMDropdownType {
  Type = 1,
  Category = 2,
  Item = 3,
}
export enum CheckDocumentationType {
  No,
  Yes,
  NA,
}

export interface EntityPermissionProps {
  entityType: EntityTypeEnum;
  addPermission: string;
  editPermission: string;
  deletePermission: string;
}

export const CustomerPortalRoleName = 'CustomerPortalUser';

export const cpUserPermittedModules = [
  'canAddShippingOrders',
  'canViewShippingOrders',
  'canViewCustomer',
  'canAddCustomer',
];
export enum OrderCreatedFrom {
  Admin,
  CustomerPortal,
}

export enum CountryRegion {
  Base = 1,
  Europe = 2,
  Export = 3,
}

export const CountryRegionDescription = new Map<number, string>([
  [CountryRegion.Base, 'CountryRegion.Base'],
  [CountryRegion.Europe, 'CountryRegion.Europe'],
  [CountryRegion.Export, 'CountryRegion.Export'],
]);

export enum ShippingContactType {
  CustomerContact = 1,
  PickupContact = 2,
  DeliveryContact = 3,
}

export const TypeDescription = new Map<number, string>([
  [EntityTypeEnum.ProductCatalogue, 'ShippingMaterials.ProductCatalogue'],
  [EntityTypeEnum.ServiceCatalogue, 'ShippingMaterials.ServiceCatalogue'],
  [EntityTypeEnum.TransportCatalogue, 'ShippingMaterials.TransportCatalogue'],
]);

export enum CopyState {
  Standard = 1,
  Extensive = 2,
}
export const CopyStateDescription = new Map<number, string>([
  [CopyState.Standard, 'ShippingOrders.Standard'],
  [CopyState.Extensive, 'ShippingOrders.Extensive'],
]);
