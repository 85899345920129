const nlLabels = {
  Auth: {
    Email: 'E-mailadres',
    Password: 'Wachtwoord',
    LinkSent:
      'We hebben een e-mailbericht verstuurd zodat je je wachtwoord kunt vervangen.',
    NewPassword: 'Nieuwwachtwoord',
    ConfirmPassword: 'Bevestig wachtwoord',
    NewPasswordRequired: 'Nieuwwachtwoord is verplicht',
    ConfirmPasswordRequired: 'Bevestigen van wachtwoord is verplicht',
    PasswordMismatch: 'Wachtwoord is niet hetzelfde',
    PasswordChanged: 'Wachtwoord is veranderd.',
    ErrorOccurred: 'Er is iets misgegaan. Probeer het later opnieuw.',
    PasswordStrength:
      'Een sterk wachtwoord is verplicht (minimaal zes tekens lang, met een combinatie van letters, hoofdletters en cijfers.',
    UserNotExist: 'E-mailadres bestaat niet',
    ResetPassword: 'Wachtwoord vervangen',
    BackToLogin: 'Terug naar inlogscherm',
    LoginToMyHazgo: 'Inloggen op MijnHazgo',
    SendResetLink: 'Stuurwachtwoord e-mailadres opnieuw instellen',
    ForgotPassword: 'NieuwWachtwoord?',
    EnterEmail: 'E-mailadres',
    LoggedOut: 'U bent uitgelogd.',
    LinkExpired: 'De link om het wachtwoord te vervangen is vervallen.',
  },
  Zone: {
    AddZone: 'Zone toevoegen',
    EditZone: 'Zone bewerken',
    ZoneName: 'Zonenaam',
    Country: 'Selecteer landen',
    Add: 'Toevoegen',
    Cancel: 'Annuleren',
    Save: 'Opslaan',
    AddSuccess: 'Zone is toegevoegd.',
    ThisZoneIsDefault: 'Je kunt geen standaardzone meer toevoegen.',
    ZoneNameIsAlreadyThere: 'De zonenaam bestaat al.',
    UpdateSuccess: 'Zone is gewijzigd.',
  },
  Order: {
    DeleteOrder: 'Order verwijderen',
    DeleteMessage: 'Order werkelijk verwijderen?',
    OrderDetail: 'Orderinformatie',
    OrderId: 'Order-ID KAART',
    OrderTitle: 'Titel order',
    OrderDate: 'Besteldatum',
    OrderType: 'Ordertype',
    Supplier: 'Leverancier',
    Total: 'Totaal (incl. btw)',
    Status: 'Status',
    Search: 'Zoeken op productcode',
    DownloadSuccess: 'Gedownload',
    OrderDeleteMessage: 'Order is verwijderd',
    AddOrder: 'Order toevoegen',
    SupplierName: 'Leveranciernaam',
    AddSuccess: 'Order is opgeslagen.',
    OrderNumber: 'Ordernummer',
    ContactPerson: 'Ordercontactpersoon',
    ServiceOptions: 'Afleveren',
    Pickup: 'Wordt opgehaald',
    Delivery: 'Wordt afgeleverd',
    LicensePlate: 'Veld verwijderen',
    NumberOrQuantity: 'Aantal',
    CostCenter: 'Kostenplaats',
    Service: 'Dienst',
    OrderDescription: 'Order',
    Description: 'Omschrijving',
    Go: 'Zoeken',
    Reset: 'Resetten',
    Cancel: 'Annuleren',
    Save: 'Opslaan',
    ProductCode: 'Productcode',
    Name: 'Naam',
    StandardPrice: 'Standaardprijs',
    Id: 'ID',
    UnitPrice: 'Eenheidsprijs',
    TotalPrice: 'Totale prijs',
    SubTotalExlVat: 'Subtotaal (excl. btw)',
    BtwTax: 'Btw%',
    Btw: 'Btw',
    TotalIncBtw: 'Totaal (incl. btw)',
    AtleastAddProduct: 'Voeg ten minste 1 product toe',
    DuplicateProductsNotAllowed: 'Geen duplicaten toegestaan',
    QuantityAtAllLevels: 'Aantal is verplicht voor alle toegevoegde producten',
    UnitPriceAtAllLevels:
      'Eenheidsprijs is verplicht voor alle toegevoegde producten',
    QuantityCannotBeZero: 'Aantal kan niet nul zijn',
    UnitPriceCannotBeZero: 'Eenheidsprijs moet groter zijn dan 0',
    OrderSettingsMustExist: 'Instelling van orders is niet ingesteld',
    CreatedBy: 'Aangemaakt door',
    LastUpdated: 'Laatst gewijzigd op',
    UpdateOrderSuccess: 'Order is gewijzigd',
    Approve: 'Goedkeuren',
    Reject: 'Afkeuren',
    Deliver: 'Geleverd',
    CancelOrder: 'Order annuleren',
    StatusUpdatedSuccessfully: 'Status is gewijzigd',
    Notes: 'Opmerkingen',
    DuplicateOrderNoExists: 'Dit ordernummer bestaat al',
    OrderSettingsDoesNotExist: 'Instelling orders is niet ingesteld',
    PublicTrackingTitle: 'Tracking',
    PublicTrackingReference: 'Trackingreferentie',
    PublicTrackingDetails: 'Trackinginformatie',
  },
  Nav: {
    SignUp: 'Aanmelden',
    Login: 'Inloggen',
    Logout: 'Uitloggen',
    OrderSetting: 'Orderinstelling',
    OrderType: 'Ordertype',
    Order: 'Order',
    Offer: 'Offerte',
    Home: 'Startpagina',
    APISample: 'API-voorbeeld',
    Link1: 'Link 1',
    Link2: 'Link 2',
    Crud: 'Ruw',
    ServiceCatalogue: 'Dienstencatalogus',
    ProductCatalogue: 'Productcatalogus',
    Masters: 'Masters',
    TransportCatalogue: 'Vervoercatalogus',
    Customers: 'Klanten',
    Users: 'Gebruikers',
    Roles: 'Rollen',
    AddRole: 'Rol toevoegen',
    EditRole: 'Rol bewerken',
    Zones: 'Zones',
    TariffCustoms: 'Douane tarief',
    AddTariff: 'Tarief toevoegen',
    AddZone: 'Zonetoevoegen',
    Dashboard: 'Dashboard',
    UnApprovedCustomers: 'Niet goedgekeurde klanten',
    ProductPurchase: 'Productaankoop',
    Reference: 'Referentie',
    Partners: 'Partners',
    PurchaseOrder: 'Orderbonnen',
    PurchaseOrderSetting: 'Orderboninstellingen',
    ShippingOrders: 'Zendingorders',
    ShippingOffers: 'Zendingoffertes',
    Questionnaire: 'Vragenlijsten',
    Products: 'Producten',
    Configuration: 'Configuratie',
    Shipping: 'Zending',
    Orders: 'Orders',
    Offers: 'Offertes',
    DecPackOrders: 'Dec & Pack-orders',
    DecPackOffers: 'Dec- & Pack-offertes',
    MyProfile: 'Mijn profiel',
    DnpWorkOrders: 'D&P -werkorders',
    WorkOrders: 'Werkorders',
    ShippingWorkOrders: 'Zending -werkorders',
    Airline: 'Luchtvaartmaatschappij',
    State: 'Land',
    Restrictions: 'Restricties',
    Labels: 'Labels',
    ExchangeRates: 'Wisselkoersen',
    UserManagement: 'User Management-NL',
  },
  Users: {
    Users: 'Gebruikers',
  },
  OrderSetting: {
    UpdateFormat: 'Indeling ordernr. bijwerken',
    OrderNumberFormat: 'Indeling ordernr',
    OrderNoStartingNumber: 'Beginnr.  ordernummer',
    SetBTW: 'Btw(TAX)-% instellen',
    Save: 'Opslaan',
    UpdateSuccess: 'Orderinstelling is bijgewerkt .',
  },
  OrderType: {
    OrderTypeMustBeUnique: 'Dit ordertype bestaat al',
    OrderTypeMustBeUniqueError: 'Ordertype moet uniek zijn',
    ErrorOccurred: 'Er is een fout opgetreden bij het aanpassen van informatie',
    updateSuccess: 'Ordertype is gewijzigd.',
    ZeroSet: 'Nul of negatief getal is niet toegestaan',
    OrderTypeDetails: 'Ordertype-informatie',
    AddOrderType: 'Ordertype toevoegen',
    OrderTypes: 'Ordertype',
    AddSuccess: 'Ordertype is toegevoegd.',
    ApprovalLevel: 'Goedkeuringsniveaus',
    ThresholdApproval: 'Goedkeuringsdrempel',
    ThresholdAmount: 'Drempelwaarde',
    SameUser: 'Selecteer een andere gebruiker',
    AmountError: 'Waarde moet groter zijn dan de drempelwaarde',
    Level: 'Niveau',
    Status: 'Status',
    LastUpdatedBy: 'Laatst gewijzigd door',
    LastUpdatedOn: 'Laatst gewijzigd op',
    Action: 'Actie',
    AssignUser: 'Toegewezen gebruiker',
    DeleteModal: {
      Message: 'Wil je dit ordertypeverwijderen?',
      Heading: 'Bevestigen?',
      Cancel: 'Annuleren',
      Ok: 'OK',
    },
  },
  TransportCatalogue: {
    ErrorOccurred: 'Er is een fout opgetreden bij het aanpassen van informatie',
    DeleteSuccess: 'Transportcatalogus werd verwijderd.',
    DeleteConfirmation: 'Transportcatalogus werkelijk verwijderen?',
    TransCode: 'Transportcode',
    Category: 'Categorie',
    FromZone: 'Van zone',
    ToZone: 'Naar zone',
    TransportType: 'Transporttype',
    DeleteTransportCatalogue: 'Transportcatalogus verwijderen',
    AddTransportCatalogue: 'Transportcatalogus toevoegen',
    TransportCode: 'Transportcode',
    Details: 'Transportcatalogusinformatie',
    airPricing: 'Prijs vliegtuig',
    roadPricing: 'Prijs vrachtvervoer',
    trainPricing: 'Prijs treinvervoer',
    shipPricing: 'Prijs vervoerschip',
    baseRate: 'Basisprijs',
    deliveryBaseRate: 'Basisprijs aflevering ',
    fuelBaseRate: 'Basisprijs brandstof',
    Kg: 'kg',
    DeliveryPrice: 'Effectieve afleverprijs ',
    FuelPrice: 'Effectieve brandstofprijs',
    ToZoneCounries: 'Naar zone landen',
    Countries: 'Landen',
    AddSuccess: 'Transportcatalogus is toegevoegd.',
    FromZoneName: 'Van zone',
    ToZoneName: 'Naar zone',
    BothZoneSame: 'Van zone en naar Zone zijn identiek.',
    Notes: 'Opmerkingen',
    Documents: 'Documenten',
    Detail: 'Informatie',
    UpdateSuccess: 'Transportcatalogus is gewijzigd.',
    UniqueTransportCode: 'Vervoerscode moet uniek zijn .',
    UniqueZoneCombination: 'Deze combinatie bestaat al  .',
    OwnPricing: 'Eigen prijzen',
  },
  ServiceCatalogues: {
    ServiceCode: 'Dienstcode',
    Category: 'Categorie',
    ServiceName: 'Dienstnaam',
    Description: 'Beschrijving',
    StandardPrice: 'Standaardprijs',
    LastCostBuyPrice: 'Laatstekosten/inkoopprijs',
    ShippingStandardPriceFrom: 'Standaard zendingprijs (van)',
    ShippingStandardPriceTo: 'Standaard zendingprijs (naar)',
    DecPackStandardPriceFrom: 'Standaard Dec&Pack-prijs (van)',
    DecPackStandardPriceTo: 'Standaard Dec&Packprijs (naar)',
    AddServiceCatalouge: 'Dienstencatalogus toevoegen',
    AddSuccess: 'Dienstencatalogus is toegevoegd.',
    UpdateSuccess: 'Dienstencatalogus is gewijzigd.',
    DeleteSuccess: 'Dienstencatalogus is verwijderd.',
    DeleteConfirmation: 'Verwijderen uit dienstencatalogus?',
    PricingType: 'Prijstype',
    Details: 'Dienstencatalogusinformatie',
    Tabs: {
      Details: 'Informatie',
      SpecialPricing: 'Speciale prijs',
      AllowedOrderCategory: 'Toegestane ordercategorie',
      Documents: 'Documenten',
      Notes: 'Opmerkingen',
    },
    ShippingStandardPrice: 'Standaard zendingprijs',
    DecPackStandardPrice: 'Standaard Dec&Pack-prijs',
    Department: 'Afdeling',
  },
  LabelMaster: {
    Shipper: 'Verzender',
    Consignee: 'Ontvanger',
    Address: 'Adres',
    Address2: 'Adres 2',
    PostalCode: 'Postcode',
    EmergencyPhoneNumber: 'Alarmtelefoonnummer',
    City: 'Plaats',
    Country: 'Land',
    DeleteConfirmation: 'Weet u zeker dat u dit label wilt verwijderen  ?',
    LabelSaveSuccess: 'Labelinformatie opgeslagen',
    LabelUpdateSuccess: 'Label  bijgewerkt',
    LabelNotExist: 'Label bestaat  niet',
    LabelSaveFail: 'Er is iets misgegaan. Probeer het later opnieuw  !',
    AddLabel: 'Label toevoegen',
    EditLabel: 'Label bewerken',
    ShipperDetail: 'Verzenderinformatie',
    ConsigneeDetail: 'Ontvangerinformatie',
    OtherDetail: 'Overige informatie',
    Validation: {
      CountryRequired: 'Land is verplicht.',
      CityRequired: 'Stad is verplicht.',
      PostalCodeRequired: 'Postcode is verplicht.',
      PostalCodeMaxLength: 'Postcode mag maximaal 20tekens  lang zijn .',
      AddressRequired: 'Adres is verplicht.',
      AddressMaxLength: 'Adres mag maximaal 250 tekens lang zijn   .',
      ConsigneeRequired: 'Ontvanger  is verplicht',
      ConsigneeMaxLength: 'Ontvanger mag maximaal 250tekens    lang zijn',
      ShipperRequired: 'Verzender is verplicht',
      ShipperMaxLength: 'Verzender mag maximaal 250tekens    lang zijn',
      ConditionalRequired: 'Voer ten minste een veldwaarde in',
    },
  },
  ExchangeRate: {
    FromCurrency: 'Van  valuta',
    ToCurrency: 'Naar valuta',
    Rate: 'Koers',
    DeleteConfirmation: 'Weet u zeker dat u de wisselkoers wilt verwijderen  ?',
    DeleteSuccess: 'Wisselkoers  verwijderd',
    ExchangeRateSaveSuccess: 'Wisselkoers  opgeslagen',
    ExchangeRateUpdateSuccess: 'Wisselkoers   gewijzigd',
    ExchangeRateNotExist: 'Wisselkoers niet gevonden',
    ExchangeRateSaveFail: 'Er is iets misgegaan. Probeer het later opnieuw  !',
    AddExchangeRate: 'Wisselkoers toevoegen',
    EditExchangeRate: 'Wisselkoers bewerken',
    Validation: {
      FromCurrencyRequired: 'Van  valuta is verplicht',
      ToCurrencyRequired: 'Naar valuta is verplicht',
      RateRequired: 'Koers is verplicht',
      MappingAlreadyExist: 'De wisselkoers bestaat al  !',
    },
  },
  CustomerLocation: {
    Country: 'Land',
    City: 'Plaats',
    ZipCode: 'Postcode',
    Address: 'Adres',
    Address2: 'Adres 2',
    IsMainAddress: 'Is hoofdadres',
    AddLocation: 'Locatie toevoegen',
    EditLocation: 'Locatie bewerken',
    Search: 'Zoeken',
    Street: 'Straat',
    DestinationCompany: 'Afleveren bij bedrijf',
    PickupCompany: 'Ophalen bij bedrijf',
    DeleteConfirmation: 'Locatie werkelijk verwijderen?',
    Validation: {
      CountryRequired: 'Land is verplicht.',
      CityRequired: 'Plaats is verplicht.',
      ZipCodeRequired: 'Postcode is verplicht.',
      ZipCodeMaxLength: 'Postcode mag maximaal 20 tekens lang zijn.',
      AddressRequired: 'Adres is verplicht.',
      AddressMaxLength: 'Adres mag maximaal 250 tekens lang zijn.',
      DestinationCompanyRequired: 'Afleveren bij  bedrijf is verplicht',
      DestinationCompanyMaxLength:
        'Afleveren bij bedrijf mag    maximaal 250 tekens lang zijn',
      StreetCompanyRequired: 'Adres is verplicht',
      StreetCompanyMaxLength: 'Adres mag maximaal 250    tekens lang zijn',
      PickupCompanyRequired: 'Ophalen bij bedrijf is verplicht',
      PickupCompanyMaxLength:
        'Ophalen bij bedrijf mag maximaal    250 tekens lang zijn',
    },
  },
  Document: {
    Document: 'Document',
    Language: 'Taal',
    AddedBy: 'Toegevoegd door',
    AddedOn: 'Toegevoegd op',
    ViewLevel: 'Rechten',
    AddDocument: 'Document toevoegen',
    SaveSuccessMessage: 'Documentopgeslagen ',
    DeleteConfirmation: 'Document werkelijk verwijderen?',
    FileSizeExceeded: 'Bestand is te groot. Maximale bestandsgrootte is 10 MB.',
    FileNotDownloaded: 'Kan niet downloaden. Probeer het opnieuw    .',
    Validation: {
      DocumentTagRequired: 'Documentlabel is verplicht.',
      DocumentNameRequired: 'Documentnaam is verplicht.',
      DocumentNameMaxLength: 'Documentnaam mag maximaal 50 tekens lang zijn.',
      LanguageRequired: 'Taaldocument is verplicht.',
      ViewLevelRequired: 'Rechten toewijzen is verplicht.',
      FileRequired: 'Bestand is verplicht.',
    },
  },
  ViewLevel: {
    Label: 'Niveau bekijken',
    Options: {
      InternalManagement: 'Intern(beheer)',
      InternalOperational: 'Intern(operationeel)',
      InternalAll: 'Intern(alles)',
      External: 'Extern',
    },
    ExternalInfoMessage: 'Dit document zal zichtbaar zijn voor klanten    .',
  },
  Common: {
    IsMainAddressError: 'Gegevens met IsHoofdadres zijn al geselecteerd .',
    Driver: 'Chauffeur',
    DecNPack: 'Dec&Pack',
    ToDo: 'To Do',
    InProgress: 'In bewerking',
    Export: 'Exporteren',
    SingleUse: 'Eenmalig gebruik',
    Reusable: 'Herbruikbaar',
    Pickup: 'Ophalen',
    Delivery: 'Afleveren',
    Approved: 'Goedgekeurd',
    Pending: 'In afwachting',
    Actions: 'Acties',
    Status: 'Status',
    Active: 'Actief',
    Inactive: 'Inactief',
    Rejected: 'Afgewezen',
    Yes: 'Ja',
    No: 'Nee',
    Reset: 'Resetten',
    Apply: 'Toepassen',
    ErrorOccurred: 'Er is een fout opgetreden',
    Operations: 'Activiteiten',
    Accounting: 'Boekhouding',
    Helpdesk: 'Helpdesk',
    Details: 'Informatie',
    Save: 'Opslaan',
    Filter: 'Filteren',
    Add: 'Toevoegen',
    FieldRequired: 'Dit veld is verplicht.',
    InvalidEmail: 'E-mailadres is ongeldig',
    InvalidPhone: 'Telefoonnummer is onjuist',
    EmployeeCode: 'EmployeeCode',
    Email: 'E-mailadres',
    PhoneNumberVal: 'Telefoonnummer',
    DeleteRole: 'Rol verwijderen?',
    Language: {
      EN: 'NL',
      FR: 'FR',
      NL: 'NL',
    },
    Cancel: 'Annuleren',
    SavedSuccessfully: 'Opgeslagen',
    En: 'Engels',
    NL: 'Nederlands',
    FR: 'Frans',
    All: 'Alle',
    Internal: 'Intern',
    Search: 'Zoeken',
    SearchbyName: 'Zoeken op naam',
    Department: 'Afdeling',
    NoRecords: 'Geen resultaten',
    NoDocuments: 'Geen documenten',
    Download: 'Downloaden',
    NoDocumentPreview: 'Documentvoorbeeld is niet  beschikbaar!',
    FieldIsRequired: 'Dit veld is verplicht.',
    InvalidDate: 'Ongeldige datum',
    InvalidTime: 'Ongeldige tijd',
    Standard: 'Standaard',
    Special: 'Speciaal',
    SomethingWentWrong: 'Er is iets misgegaan. Probeer het later opnieuw.',
    Edit: 'Bewerken',
    Delete: 'Verwijderen',
    Allowed: 'Toegestaan',
    Specific: 'Specifiek',
    SpecificCategories: 'Specifieke categori n',
    InvalidQuantityDelete:
      'Beschikbaar aantal zal veranderen door dit  record.  ',
    EnteredQuantityIsNotValid: 'Aantal is niet correct.',
    UniqueServiceCode: 'Dienstcode moet uniek zijn.',
    UniqueServiceName: 'Dienstnaam moet uniek zijn.',
    InvalidOtp: 'SMS-code is onjuist',
    OtpExpired: 'SMS-code is vervallen. Vraag een nieuwe SMS-code aan',
    Cancelled: 'Geannuleerd',
    Delivered: 'Afgeleverd',
    PartiallyDelivered: 'Gedeeltelijk afgeleverd',
    Draft: 'Concept',
    Previous: 'Vorige',
    Next: 'Opslaan en volgende',
    Submit: 'Verzenden',
    New: 'Nieuw',
    InProcess: 'Inbewerking',
    Done: 'Gereed',
    OrderCompleted: 'Ordervoltooid',
    Invoiced: 'Gefactureerd',
    OnHold: 'Wachtend',
    SentForApproval: 'Verstuurd voorgoedkeuring',
    InvoiceApproved: 'Factuur goedgekeurd',
    Closed: 'Gesloten',
    fileSizeExceedErr:
      'Bestand is te groot. Maximale bestandsgrootte is 10 MB.',
    UploadFileExceedError:
      'Bestand is te groot. Maximale bestandsgrootte is #maxFileSize#.',
    ImageResolutionExceedError:
      'Upload een foto met een resolutie van 600 x 500 pixels',
    fileTypeErr: 'Ongeldige bestandsindelingUpload een geldig bestand.',
    UploadFileFormatError:
      'Ongeldige  Bestandsindeling  Upload een geldig #acceptedFiles#-bestand.',
    DuplicateRecord: 'Record bestaat al',
    IgnoreDateFilter: ' Datumfilter negeren',
    IgnorePriceFilter: 'Prijsfilter negeren',
    ZeroValidation: 'Voer een  waarde  in die groter is  dan 0.',
    MaxAllowed: 'Maximaal toegestane documenten  zijn ',
    DocumentTag: 'Documentlabel',
    NoImagesSelectedToUpload: 'Geen afbeeldingen geselecteerd  om te  uploaden',
    ImageSaveSuccess: 'Afbeelding is opgeslagen ',
    EditPicture: 'Afbeeldingbewerken',
    WOAlreadyInProgress: 'Andere werkorder wordt al  uitgevoerd',
    Booking: 'WO-boeken',
    DGDCreation: 'WO-DGDaanmaken',
    WODocumentation: 'WO-documentatie',
    WOPickup: 'WO ophalen',
    Inbound: 'WO inkomend',
    Packaging: 'WO verpakken',
    Outbound: 'WO uitgaand',
    WODelivery: 'WO afleveren',
    DocumentCheck: 'WO-documentcontrole',
    PhysicalCheck: 'WO-fysieke controle',
    Past: 'Eerder',
    Today: 'Vandaag',
    Future: 'Later',
    DateRange: 'Datumbereik',
    RequiredField: 'Vul de verplichte velden in',
    CitySavedSuccessfully: 'Nieuwestad toegevoegd',
    SaveNext: 'Opslaan en Volgende',
    FileUploadMessage:
      'Afmeting logo moet 300 x 300 pixels zijn; maximale bestandsgrootte is 2      MB',
    Web: 'Web',
    Mobile: 'Mobiel',
    AwaitingAssignment: 'Wacht op toewijzing',
    Attentions: {
      HighPriority: 'Hoge prioriteit',
      Secure: 'Beveiligd',
      AwaitingInfo: 'Wacht op informatie',
      OnHold: 'In de wacht',
      AwaitingInstructionFromClient: 'Wacht  op instructies van klant',
    },
    NA: 'N.v.t.',
    ClientNotAvailable: 'Klant niet beschikbaar',
    ClientNotReady: 'Klant niet klaar om te  tekenen',
    Signature: 'Handtekening',
    SignedOffBy: 'Afgetekend door',
    Update: 'Updaten',
    SentBy: 'Verstuurd door',
    ApprovedBy: 'Goedgekeurd  door',
    Own: 'Eigen',
    Location: 'Locatie',
    SameAsAbove: 'Zelfde als boven',
    ApproveOrder: 'Order goedkeuren',
    SendToCustomer: 'Versturen naar klant',
    InvalidFileFormat: 'Ongeldige bestandsindeling',
  },
  Approval: {
    OrderOwner: 'Ordereigenaar',
    SendForApproval: 'Versturen  ter  goedkeuring',
    SentForApproval: 'Verstuurd ter goedkeuring',
    Approver: 'Goedkeurder',
    Approve: 'Goedkeuren',
    Approved: 'Goedgekeurd',
    SentBy: 'Verstuurd door',
    ApprovedBy: 'Goedgekeurd  door',
    SendForApprovalConfirmation:
      'Weet u zeker dat u de order ter goedkeuring wilt versturen    ?',
    SendForApprovalConfirmationDetails: 'Order versturen ter goedkeuring',
    ApprovalConfirmMessage: 'Weet u zeker dat u wilt goedkeuren   ?',
    ApprovalConfirmTitle: 'Ordergoedkeuring',
    TotalProfitLoss: 'Totalewinst/verlies',
    VAT: 'Btw',
    VATPercent: 'Btw-percentage',
    VATDetails: 'Btw-informatie',
    InvalidVatPercent:
      'Wijzig het btw-percentage voor deze klant in de klantmodule in  0 of 21%',
  },
  Notes: {
    Description: 'Omschrijving',
    IsInternalOnly: 'Intern gebruik?',
    AddedOn: 'Toegevoegd op',
    AddedBy: 'Toegevoegd door',
    DeletedSuccessfully: 'Melding is verwijderd',
    DeleteMessage: 'Melding verwijderen?',
    DeleteNote: 'Melding verwijderen',
    AddNote: 'Melding toevoegen',
    EditNote: 'Melding bewerken',
    Save: 'Opslaan',
    Cancel: 'Annuleren',
    SavedSuccessfully: 'Opgeslagen',
    UpdatedSuccessfully: 'Gewijzigd',
    ErrorOccurred: 'Er is een fout opgetreden',
    FieldRequired: 'Veld is verplicht',
  },
  CreditNote: {
    Label: 'Creditfactuur',
    Description: 'Omschrijving',
    Note: 'Melding',
    Amount: 'Bedrag',
    DeletedSuccessfully: 'Verwijderd ',
    DeleteMessage: 'Weet u zeker dat u de melding wilt verwijderen  ?',
    DeleteNote: 'Melding verwijderen',
    AddNote: 'Melding toevoegen',
    EditNote: 'Melding bewerken',
    Save: 'Opslaan',
    Cancel: 'Annuleren',
    SavedSuccessfully: 'Opgeslagen ',
    UpdatedSuccessfully: 'Bijgewerkt ',
    ErrorOccurred: 'Er is een fout opgetreden',
    FieldRequired: 'Veld is verplicht',
    DescriptionFieldRequired: 'Omschrijving is verplicht',
    AmountFieldRequired: 'Bedrag is verplicht',
  },
  ContactPerson: {
    Name: 'Naam',
    FirstName: 'Voornaam',
    LastName: 'Achternaam',
    Function: 'Functie',
    Phone: 'Telefoonnummer',
    Mobile: 'Mobiel nummer',
    Email: 'E-mailadres',
    SiteInformation: 'Informatie over onderzoekslocatie',
    SystemAccess: 'Toegangsysteem',
    Status: 'Status',
    DeletedSuccessfully: 'Verwijderd',
    DeleteMessage: 'Contact verwijderen?',
    DeleteContactPerson: 'Contact verwijderen',
    ErrorOccurred: 'Er is een fout opgetreden',
    ContactPersonCannotBeDeleted: 'Contact kan niet worden verwijderd',
    SavedSuccessfully: 'Opgeslagen',
    AddContactPerson: 'Contact toevoegen',
    FieldRequired: 'Veld is verplicht',
    InvalidEmailAddress: 'E-mailadres is ongeldig',
    AccessLevel: 'Toegangsniveau',
    Location: 'Locatie',
  },
  Placeholders: {
    SelectAssignmentType: 'Type selecteren',
    SelectPartner: 'Partner selecteren',
    selectProductType: 'Type selecteren',
    SelectStatus: 'Status selecteren',
    Select: 'Selecteren',
    SelectDepartment: 'Afdeling',
    SelectCustomer: 'Klant selecteren',
    SelectLanguage: 'Taal selecteren',
    SelectUser: 'Gebruiker selecteren',
    SelectCategory: 'Categorie selecteren',
    SelectValidity: 'Geldigheid selecteren',
    SelectOrder: 'Order selecteren',
    SelectZone: 'Zone selecteren',
    SelectTransportType: 'Transporttype selecteren',
    SelectLocation: 'Locatie selecteren',
    SelectContact: 'Contactpersoon selecteren',
    SelectService: 'Dienst selecteren',
    SelectRejection: 'Reden voor afkeuren selecteren',
    SelectShippingType: 'Zendingtype selecteren',
    SelectOfferStatus: 'Offertestatus selecteren',
    SelectTrackingType: 'Type selecteren',
    SelectDecPackType: 'Dec&Pack-type selecteren',
    SelectPartnerType: 'Partnertype selecteren',
    SelectTracking: 'Tracking selecteren',
    SelectLabelType: 'Labeltype selecteren',
    SelectLayoutType: 'Indelingtype selecteren',
    SelectConsignee: 'Ontvanger selecteren',
    SelectShipper: 'Verzender selecteren',
    SelectDestinationCompany: 'Bestemmingbedrijf selecteren',
    SelectPickupCompany: 'Ophaalbedrijf selecteren',
  },
  UserList: {
    EmpCode: 'Gebruikerscode',
    Name: 'Naam',
    Role: 'Functie',
    Roles: 'Functies',
    Department: 'Afdeling',
    Mobile: 'Mobiel nummer',
    Email: 'E-mailadres',
    Language: 'Taal',
    Status: 'Status',
    DeleteModal: {
      Message: 'Gebruiker verwijderen?',
      Heading: 'Bevestigen?',
      Cancel: 'Annuleren',
      Ok: 'OK',
    },
  },
  UsersAdd: {
    AddUsers: 'Gebruikers toevoegen',
    AdditionalDetails: 'Aanvullende gegevens',
    UserDetails: 'Gebruikersinformatie',
    EmpCode: 'Gebruikerscode',
    Name: 'Naam',
    Email: 'E-mailadres',
    Role: 'Functie',
    Department: 'Afdeling',
    Mobile: 'Mobiel nummer',
    PreferredLanguage: 'Taal',
    Status: 'Status',
    Cancel: 'Annuleren',
    Add: 'Toevoegen',
    EmployeeCode: 'Werknemerscode',
    PhoneNumber: 'Telefoonnummer',
    DepartmentSelect: {
      Shipping: 'Zending',
      DecPack: 'Dec&Pack',
      Both: 'Beide',
    },
    FirstName: 'Voornaam',
    LastName: 'Achternaam',
    Phone: 'Telefoon',
    UserType: 'Type gebruiker',
    UserCreatedSuccss: 'Gebruikergemaakt ',
  },
  UsersEdit: {
    Save: 'Opslaan',
    UserDetails: 'Gebruikersinformatie',
    PreferredLanguage: 'Taal',
    Status: 'Status',
    RolesAssigned: 'Functie is toegewezen',
    RolesDeleted: 'Functie is verwijderd',
    RolesDetails: 'Functie-informatie',
    UserUpdatedSuccss: 'Gebruikerbijgewerkt ',
  },
  UsersProfile: {
    MyProfile: 'Mijn profiel',
    PreferredLanguage: 'Gewenste  taal',
    Status: 'Status',
    ProfilePicture: 'Profielfoto',
    FirstName: 'Voornaam',
    LastName: 'Achternaam',
    Phone: 'Telefoon',
    Email: 'E-mailadres',
    Mobile: 'Mobiel',
    ProfileUpdated: 'Profielbijgewerkt ',
    PasswordUpdatedLoginAgain:
      'Wachtwoord is veranderd . Log inmet het  nieuwe wachtwoord',
  },
  Customer: {
    Tabs: {
      Details: 'Informatie',
      ContactPerson: 'Contactpersoon',
      Location: 'Adresboek',
      Documents: 'Documenten',
      Notes: 'Opmerkingen',
      SpecialPricing: 'Speciale prijzen',
      PricingType: 'Prijstype',
    },
    Name: 'Naam',
    VatDeferment: 'Btw-opschortingrekening',
    VatNumber: 'Btw-nummer',
    EORI: 'EORI',
    Address: 'Adres',
    PCCity: 'Postcode, plaats',
    Country: 'Land',
    Tag: 'Afdeling',
    Approved: 'Goedgekeurd',
    Status: 'Status',
    SearchbyName: 'Zoeken op naam',
    Logo: 'Logo',
    Details: 'Informatie',
    FinancialBasic: 'Financi le informatie',
    ContactDetails: 'Contactinformatie',
    Invoicing: 'Facturering',
    PostalCode: 'Postcode',
    City: 'Plaats',
    Website: 'Website',
    SameAsVat: 'Idem als btw',
    IsDebtor: 'IsDebiteur',
    GeneralTelephone: 'Telefoonnummer',
    GeneralEmail: 'E-mailadres',
    InvoiceAddressDifferent: 'Facturatieadres wijkt af?',
    InvoiceEmail: 'Factuur-e-mailadres',
    InvoicingCycle: 'Facturering cyclus',
    BillingCurrency: 'Factuur valuta',
    CustomerType: 'Klanttype',
    VatPercentage: 'Btw-percentage',
    Shipping: 'Zending',
    DecPack: 'Dec&Pack',
    Both: 'Beide',
    IsDifferent: 'Is verschillend',
    CustomerName: 'Klantnaam',
    InvoicePostalCode: 'Postcode',
    InvoiceCity: 'Plaats',
    InvoiceCountry: 'Land',
    InvoiceAddress: 'Adres',
    Approve: 'Goedkeuren',
    Reject: 'Afkeuren',
    UpdatedSuccessfully: 'Gewijzigd',
    NoRowsSelected: 'Aantal geselecteerde rijen',
    ResendForApproval: 'Goedkeuringopnieuw aangevraagd',
    Validation: {
      CustomerNameRequired: 'Klantnaam is verplicht.',
      CustomerNameMaxLength: 'Klantnaam mag maximaal 50 tekens lang zijn.',
      AddressMaxLength: 'Adres mag maximaal 250 tekens lang zijn.',
      PostalCodeMaxLength: 'Postcode mag maximaal 20 tekens lang zijn.',
      InvoiceAddressMaxLength:
        'Factuuradres mag maximaal 250 tekens lang zijn.',
      InvoicePostalCodeMaxLength: 'Postcode mag maximaal 20 tekens lang zijn.',
      WebsiteMaxLenght: 'Website mag maximaal 255 tekens lang zijn.',
      VatNumberUnique: 'Btw-nummer moet uniek zijn .',
      VatDefermentMaxLength:
        'Btw-opschortingrekening mag maximaal 30 tekens lang zijn  .',
      VatNumberMaxLength: 'Btw-nummer mag maximaal 30 tekens lang zijn.',
      EORIMaxLength: 'EORI mag maximaal 30 tekens lang zijn.',
      TelephoneMaxLength: 'Telefoonnummer mag maximaal 20 tekens lang zijn.',
      StatusRequired: 'Status is verplicht.',
      EmailMaxLength: 'E-mailadres mag maximaal 255 tekens lang zijn.',
      InvalidEmail: 'E-mailadres is ongeldig',
      InvoiceEmailMaxLength: 'E-mailadres mag maximaal 255 tekens lang zijn.',
      CustomerFirstNameRequired: 'Klantnaam is verplicht.',
      CustomerFirstNameMaxLength:
        'Voornaam van klant mag maximaal 50 tekens lang zijn  .',
      CustomerLastNameRequired: 'Klantnaam is verplicht.',
      CustomerLastNameMaxLength:
        'Achternaam van de klant mag maximaal 50 tekens lang zijn  .',
      InvalidVatPercent: 'Wijzig het btw-percentage in ofwel  0 of 21%',
      InvalidCustomerSpecialPricing:
        'Speciale prijs voor entiteit bestaat al   .',
    },
    AddCustomer: 'Klant toevoegen',
    ViewCustomer: 'Klantinformatie',
    CustomerDetails: 'Klantengegevens',
    DeletedSuccessfully: 'Verwijderd',
    DeleteMessage: 'Klant verwijderen?',
    DeleteCustomer: 'Klant verwijderen',
    ErrorOccurred: 'Er is een fout opgetreden',
    FirstName: 'Voornaam',
    LastName: 'Achternaam',
    ToolTips: {
      ResendForApproval: 'Opnieuw versturen ter goedkeuring',
    },
    SearchAddress: 'Adres zoeken',
  },
  RoleList: {
    Name: 'Naam',
    Status: 'Status',
    DeleteMessage: 'Functie verwijderen?',
    DeleteRole: 'Functie verwijderen',
  },
  Role: {
    Module: 'Module',
    View: 'Weergeven',
    Add: 'Toevoegen',
    Edit: 'Bewerken',
    Delete: 'Verwijderen',
    Approver: 'Goedkeurder',
  },
  TariffCustomsList: {
    DeleteMessage: 'Weet u zeker dat u het tariefrecord wilt verwijderen ?',
    DeleteTariffTitle: 'Tariefinformatie verwijderen',
    choose_tariff_file: 'Tariefbestand  selecteren',
    Cancel: 'Annuleren',
    Save: 'Opslaan',
  },
  AddTariff: {
    cnKey: 'DNSleutel',
    cn: 'Artikelcode',
    cnLevel: 'Niveau',
    t_SU_SU: 'T SU SU',
    description: 'Omschrijving',
    parentCNName: 'Bovenliggend DN (Douane nummer)',
    parent: 'Bovenliggend',
    status: 'Status',
  },
  Questionnaire: {
    questionnaireDetail: 'Vragenlijstinformatie',
    add: 'Toevoegen',
    subject: 'Onderwerp',
    description: 'Omschrijving',
    Language: 'Taal',
    save: 'Opslaan',
    cancel: 'Annuleren',
    AddQuesionnaire: 'Vragenlijst toevoegen',
    EditQuesionnaire: 'Vragenlijst bewerken',
    preferredLanguage: 'Voorkeurtaal',
    status: 'Status',
    questionnaireAddMsg: 'Vragenlijsttoegevoegd ',
    questionnaireUpdateMsg: 'Vragenlijstbijgewerkt ',
    fieldRequired: 'Dit  veld is verplicht',
    chnageStatusErrMsg:
      'U kunt de status niet veranderen omdat die gekoppeld is met een      werkorder',
    OptionsFieldErrorMsg:
      'Voer alle opties correct in, of verwijder ze van het scherm  .',
    OptionsFieldMin2Err:
      'U moet ten minste twee opties invoeren in het geval van een keuzelijst of meerkeuze .',
    DeleteMessage: 'Weet u zeker dat u de vragenlijst wilt verwijderen  ?',
    DeleteQuestionnaireTitle: 'Vragenlijst verwijderen',
    DeletionSuccessMsg: 'Vragenlijstverwijderd ',
    CopySuccessMsg: 'Vragenlijstgekopieerd ',
    CopyQuestionnaireTitle: 'Vragenlijst kopi ren',
    CopyQuestionnaireMsg:
      'Weet u zeker dat u een duplicaat van de vragenlijst wilt aanmaken   ?',
    QueDeletionSuccessMsg: 'Vraagverwijderd ',
    DeleteQuestionConfirmationMessage:
      'Weet u zeker dat u de vraag wilt verwijderen  ?',
    DeleteQuestionConfirmationTitle: 'Vraag verwijderen',
    PrepareQuestionnaire: 'Vragenlijst voorbereiden',
    AddQuesion: 'Vraag toevoegen',
    EditQuesion: 'Vraag bewerken',
    AddYourQue: 'Uw vraag  toevoegen',
    AnswerType: 'Antwoordtype',
    Options: 'Opties',
    QuestionAddSuccessMsg: 'Vraagtoegevoegd ',
    QuestionUpdateSuccessMsg: 'Vraagbijgewerkt ',
    NoQuestionsFound: 'Geen vragen gevonden',
    QuestionType: {
      MultipleChoice: 'Meerkeuze',
      Dropdown: 'Keuzelijst',
      YesOrNo: 'Ja/nee',
      Text: 'Tekst',
    },
    QueWoSync: {
      QuestionnaireWOAssociation: 'Vragenlijst WO-koppeling',
      QuestionnaireName: 'Vragenlijstnaam',
      WorkorderName: 'Werkorder/naamtype',
      AddQueWOAsociation: 'Vragenlijst WO-koppeling toevoegen',
      EditQueWOAsociation: 'Vragenlijst WO-koppeling bewerken',
      SelectQuestionnaire: 'Vragenlijst selecteren',
      SelectWorkorder: 'Werkorder selecteren',
      SelectType: 'Type selecteren',
      Workorder: 'Werkorder',
      Type: 'Type',
      Questionnaire: 'Vragenlijst',
      MappingAddMsg: 'Koppeling informatietoegevoegd ',
      MappingUpdateMsg: 'Koppeling informatiebijgewerkt ',
      DeleteMappingModalMsg:
        'Weet u zeker dat u deze koppeling wilt verwijderen   ?',
      DeleteMappingModalTitle: 'Koppeling verwijderen',
      DeletionMappingMsg: 'Koppeling verwijderd ',
      DeletionMappingErrMsg:
        'U kunt deze vragenlijst niet verwijderen, omdat deze is gekoppeld aan een werkorder       .',
    },
  },
  Validations: {
    Required: ' is verplicht',
  },
  SpecialPricing: {
    Customer: 'Klant',
    SellingPrice: 'Speciale prijs',
    AddedOn: 'Toegevoegd op',
    AddedBy: 'Toegevoegd door',
    DeletedSuccessfully: 'Speciale prijs is verwijderd.',
    DeleteMessage: 'Speciale prijs verwijderen?',
    DeleteSpecialPricing: 'Speciale prijs verwijderen?',
    AddSpecialPricing: 'Speciale prijs toevoegen',
    EditSpecialPricing: 'Speciale prijs bewerken',
    Save: 'Opslaan',
    Cancel: 'Annuleren',
    SavedSuccessfully: 'Speciale prijs is opgeslagen.',
    UpdatedSuccessfully: 'Speciale prijs is gewijzigd.',
    ShippingSellingPrice: 'Verkoopprijs speciale zending',
    DecPackSellingPrice: 'Verkoopprijs Speciale Dec&Pack',
    PricingType: 'Prijstype',
  },
  ProductCatalogues: {
    AddProductCatalouge: 'Productcatalogus toevoegen',
    Tabs: {
      Details: 'Informatie',
      SpecialPricing: 'Speciale prijs',
      AllowedOrderCategory: 'Toegestane ordercategorie n',
      Documents: 'Documenten',
      Notes: 'Meldingen',
      Purchases: 'Orders',
      Consumptions: 'Verbruiken',
      CurrentCheckout: 'Huidig totaalbedrag',
      Assignment: 'Toegewezen',
      RemoveStock: 'Uit voorraad verwijderen',
    },
    ProductPurchases: {
      Date: 'Datum',
      Quantity: 'Aantal',
      Supplier: 'Besteld bij',
      PricePerUnit: 'Prijs per eenheid',
      Remarks: 'Opmerkingen',
      CreatedBy: 'Toegevoegd door',
      SearchBySupplier: 'Zoeken op leveancier',
      DeleteProductPurchase: 'Aankoop verwijderen',
      DeleteMessage: 'Aankoop verwijderen?',
      DeletedSuccessfully: 'Is verwijderd',
      ErrorOccurred: 'Er is een fout opgetreden',
      EditProductPurchase: 'Aankoop bewerken',
      EditedSuccessfully: 'Is gewijzigd',
      AddProductPurchase: 'Productaankoop toevoegen',
      AddedSuccessfully: 'Aankoop is toegevoegd',
      SavedSuccessfully: 'Aankoop is opgeslagen',
      UpdatedSuccessfully: 'Aankoop is gewijzigd',
      Validation: {
        QuantityRequired: 'Aantal is verplicht',
        PricePerUnitRequired: 'Eenheidsprijs is verplicht',
        SupplierRequired: 'Leverancier is verplicht',
        SupplierMaxLength: 'Leverancier mag maximaal 50 tekens lang zijn.',
        RemarksMaxLength: 'Melding mag maximaal 150 tekens lang zijn.',
      },
    },
    RemoveStock: {
      Date: 'Datum',
      Quantity: 'Aantal',
      CatalogueId: 'Catalogus -ID',
      Remarks: 'Opmerkingen',
      CreatedBy: 'Toegevoegd  door',
      DeleteRemoveStock: 'Wissen Verwijderen Voorraad',
      DeleteMessage: 'Weet u zeker dat u de voorraad wilt verwijderen  ?',
      DeletedSuccessfully: 'Verwijderd ',
      ErrorOccurred: 'Er is een fout opgetreden',
      EditRemoveStock: 'Bewerking Verwijderen Voorraad',
      EditedSuccessfully: 'Bewerkt ',
      AddRemoveStock: 'Toevoegen Verwijderen Voorraad',
      AddedSuccessfully: 'Toegevoegd ',
      SavedSuccessfully: 'Verwijderen voorraad is opgeslagen ',
      UpdatedSuccessfully: 'Verwijderen voorraad is bijgewerkt ',
    },
    ProductConsumptions: {
      Date: 'Datum',
      Quantity: 'Aantal',
      OrderNumber: 'Orderreferentienr.',
      CustomerName: 'Klant',
      SellingPrice: 'Verkoopprijs',
      Remarks: 'Opmerkingen',
      CreatedBy: 'Toegevoegd door',
      DeleteProductConsumption: 'Productverbruik verwijderen',
      DeleteMessage: 'Productverbruik verwijderen?',
      DeletedSuccessfully: 'Is verwijderd',
      ErrorOccurred: 'Er is een fout opgetreden',
      EditProductConsumption: 'Bewerk productverbruik',
      EditedSuccessfully: 'Is gewijzigd',
      AddProductConsumption: 'Productverbruik toevoegen',
      AddedSuccessfully: 'Is toegevoegd',
      SavedSuccessfully: 'Verbruik is opgeslagen',
      UpdatedSuccessfully: 'Verbruik is gewijzigd',
      Validation: {
        QuantityRequired: 'Aantal is verplicht',
        PricePerUnitRequired: 'Eenheidsprijs is verplicht',
        SupplierRequired: 'Leverancier is verplicht',
        RemarksMaxLength: 'Opmerking mag maximaal 150 tekens lang zijn.',
      },
    },
    CurrentCheckouts: {
      purchaseOrderId: 'Order-ID',
      OrderNumber: 'Ordernummer',
      Quantity: 'Aantal',
      RestoreMessage: 'Totaalbedrag corrigeren?',
      RestoreCurrentCheckout: 'Huidige totaalbedrag corrigeren',
    },
    Assignments: {
      AssignedDate: 'Datum toewijzing',
      AssignedBy: 'Toegewezen door',
      AssignedTo: 'Toegewezen aan',
      AssignedByType: 'Toegewezen door',
      AssignedToType: 'Toegewezen aan',
      Warehouse: 'Magazijn',
      Order: 'Order',
      ShippingOrder: 'Zendingorder',
      DecPackOrder: 'Dec&Pack-order',
      Add: 'Toewijzing toevoegen',
      Edit: 'Toewijzing aanpassen',
      Remarks: 'Opmerkingen',
      Quantity: 'Aantal',
      SavedSuccessfully: 'Toewijzing is bewaard',
      UpdatedSuccessfully: 'Toewijzing is gewijzigd',
      BothTypeNotSame:
        'Toegewezen door en Toegewezen aan mogen niet hetzelfde        zijn',
    },
    SingleUse: 'Eenmalig gebruik',
    Reusables: 'Meermalig gebruik',
    Details: 'Productcatalogusinformatie',
    AddProductCatalogue: 'Productcatalogus toevoegen',
    ProductCode: 'Productcode',
    ProductName: 'Productnaam',
    Dimentions: 'Afmetingen',
    ProductCatalogueType: 'Productcatalogustype',
    Type: 'Type',
    Category: 'Categorie',
    Description: 'Omschrijving',
    ThresholdLimit: 'Drempelwaarde',
    Weight: 'Gewicht',
    PricingType: 'Prijstype',
    AddSuccess: 'Productcatalogus is toegevoegd.',
    UpdateSuccess: 'Productcatalogus is gewijzigd.',
    DeleteSuccess: 'Productcatalogus is verwijderd.',
    Pricing: 'Prijs en voorraad',
    StandardPrice: 'Standaardprijs',
    LastCostBuyPrice: 'Laatstekosten/aankoopprijs',
    LastPurchasedFrom: 'Laatstgekocht van',
    InWarehouse: 'Beschikbaar in magazijn',
    AvailableinWH: 'Beschikbaar in magazijn',
    DeleteConfirmation: 'Productcatalogus verwijderen?',
    WarehouseQuantity: 'MagazijnAantal',
    InvalidQuantity: 'Ingevoerd aantal isniet geldig.',
    ResourceQuantity: 'BronAantal',
    UniqueProductCode: 'Productcode moet uniek zijn.',
    UniqueProductName: 'Productnaam moet uniek zijn.',
    DeleteValidation:
      'Productcatalogus wordt toegewezen aan orders; eerst opnieuw  toewijzen aan magazijn en dan verwijderen',
    ShowInDPBOM: 'Weergeven in D&P-materialenlijst',
    ShippingStandardPrice: 'Standaard zendingprijs',
    DecPackStandardPrice: 'Standaard Dec&Pack-prijs',
    Department: 'Afdeling',
  },
  Partner: {
    SearchAddress: 'Adres zoeken',
    Type: 'Partnertype',
    Tracking: 'Tracking',
    Name: 'Naam',
    VatNumber: 'Btw-nummer',
    Telephone: 'Telefoon',
    Category: 'Categorie',
    Email: 'E-mailadres',
    Country_City: 'Land - plaats',
    Location_Branches: 'Locatie/vestigingen',
    Status: 'Status',
    DeletedSuccessfully: 'is verwijderd',
    DeleteMessage: 'Partner verwijderen?',
    DeletePartner: 'Partner verwijderen',
    ErrorOccurred: 'Er is een fout opgetreden',
    ResendForApproval: 'Teruggestuurd ter goedkeuring',
    SearchbyName: 'Zoeken op naam',
    AddPartner: 'Partner toevoegen',
    SupplierName: 'Leveranciernaam',
    EORI: 'EORI',
    Address: 'Adres',
    PCCity: 'PC, plaats',
    Country: 'Land',
    Tag: 'Label',
    Approved: 'Goedgekeurd',
    Logo: 'Logo',
    Details: 'Informatie',
    FinancialBasic: 'Financi le basisinformatie',
    ContactDetails: 'Contactinformatie',
    Invoicing: 'Facturering',
    PostalCode: 'Postcode',
    City: 'Plaats',
    Website: 'Website',
    SameAsVat: 'Zelfde alsbtw-nummer',
    IsDebtor: 'Is debiteur',
    GeneralTelephone: 'Algemeen telefoonnummer',
    GeneralEmail: 'Algemeen e-mailadres',
    AdminEmail: 'E-mailadres voor Admin-portal',
    InvoiceAddressDifferent: 'Facturatieadres wijkt af?',
    InvoiceEmail: 'Factuur-e-mailadres',
    Shipping: 'Zending',
    DecPack: 'Dec&Pack',
    IsDifferent: 'Is verschillend',
    InvoicePostalCode: 'Factuurpostcode',
    InvoiceCity: 'Factuurplaats',
    InvoiceCountry: 'Factuurland',
    InvoiceAddress: 'Factuuradres',
    PartnerDetails: 'Partnerinformatie',
    DHL: 'DHL',
    FedEx: 'FedEx',
    Others: 'Overige',
    UpdatedSuccessfully: 'Partner is bijgewerkt ',
    TransportTypes: 'Transporttypen',
    Tabs: {
      Category: 'Categorie',
      Details: 'Informatie',
      ContactPerson: 'Contactpersoon',
      Location: 'Adresboek',
      Documents: 'Documenten',
      Notes: 'Opmerkingen',
      TransportTypes: 'Transporttypen',
      AllowedOrderCategory: 'Toegestane ordercategorie',
    },
    Validation: {
      PartnerNameRequired: 'Partnernaam is verplicht.',
      PartnerNameMaxLength: 'Partnernaam mag maximaal 50 tekens lang zijn.',
      PartnerNameMinLength: 'Partnernaam moet minimaal 3 tekens lang zijn  .',
      AddressMaxLength: 'Adres mag maximaal 250 tekens lang zijn.',
      PostalCodeMaxLength: 'Postcode mag maximaal 20 tekens lang zijn.',
      InvoiceAddressMaxLength:
        'Factuuradres mag maximaal 250 tekens lang zijn.',
      InvoicePostalCodeMaxLength:
        'Factuurpostcode mag maximaal 20 tekens lang zijn.',
      WebsiteMaxLenght: 'Website mag maximaal 250 tekens lang zijn.',
      VatNumberMaxLength: 'Btw-nummer mag maximaal 30 tekens lang zijn.',
      EORIMaxLength: 'EORI-nummer mag maximaal 30 tekens lang zijn.',
      TelephoneMaxLength: 'Telefoonnummer mag maximaal 20 tekens lang zijn.',
      StatusRequired: 'Status is verplicht.',
      EmailMaxLength: 'E-mailadres mag maximaal 250 tekens lang zijn.',
      InvalidEmail: 'Voer een correct e-mailadres in',
      InvoiceEmailMaxLength:
        'Factuur-e-mailadres mag maximaal 255 tekens lang zijn.',
      EmptyCatagory: 'Kan lege categorie niet  opslaan',
      UniqueName: 'Leveranciersnaam moet uniek zijn .',
    },
    CategoryTab: {
      Air: 'Vliegtuig',
      Road: 'Vrachtwagen',
      Train: 'Trein',
      Ship: 'Schip',
      Other: 'Ander',
    },
  },

  ShippingOrders: {
    CopyLevel: 'Kopieer niveau',
    Standard: 'Standaard',
    Extensive: 'Uitgebreid',
    Add: 'Zendingorder toevoegen',
    AddOffer: 'Zendingofferte  toevoegen',
    Edit: 'Zendingorder aanpassen',
    Details: 'Zendingorderinformatie',
    OfferDetails: 'Zendingofferte-informatie',
    HazGoReference: 'HazGo-referentie',
    OfferReference: 'Offertereferentie',
    OfferExpired: 'Offerte verlopen',
    RejectionCode: 'Afwijzingcode',
    SubCategory: 'Subcategorie',
    Accepted: 'Geaccepteerd',
    Rejected: 'Afgewezen',
    Open: 'Open',
    OfferSent: 'Aanbieding verzonden',
    RejectionReason: 'Reden voor  afwijzing',
    Expired: 'Verlopen',
    NotExpired: 'Niet verlopen',
    ReferenceNumber: 'Referentienummer',
    ConvertOrder: 'Omzetten naar  order',
    Totals: 'Totalen',
    OfferStatus: 'Offertestatus',
    InProgress: 'In bewerking',
    TotalNumOfPieces: 'Totaal aantal colli',
    ShipmentContent: 'Zendinginhoud',
    WorkOrders: 'Werkorders',
    CustomsDetails: 'Douane-informatie',
    TermsOfDeliveryAndPayment: 'Leveringsvoorwaarden en  betaling',
    Importer: 'Importeur',
    Exporter: 'Exporteur',
    AddTermsOfDeliveryAndPayment:
      'Leveringsvoorwaarden en betaling   toevoegen',
    AddImporter: 'Importeur  toevoegen',
    AddExporter: 'Exporteur  toevoegen',
    ViewImporter: 'Importeur weergeven',
    ViewExporter: 'Exporteur weergeven',
    NameMaxLength: 'Naam mag maximaal 30 tekens lang zijn  .',
    OrderCreatedFrom: 'Order isaangemaakt vanuit klantportal',
    InternalTrackingNumber: 'Intern trackingnummer',
    PriceRange: 'Prijsbereik',
    ConvertToOrderConfirmation:
      'Weet u zeker dat u deze offerte wilt omzetten naar een order    ?',
    OfferCategoryValidation: 'Update de aanbiedingscategorie voor deze klant',
    Tabs: {
      Details: 'Informatie',
      Packages: 'Pakketten',
      WorkOrder: 'Werkorder',
      BillableItems: 'Factureerbare  items',
      Tracking: 'Tracking',
      Documents: 'Documenten',
      Notes: 'Opmerkingen',
      Customs: 'Douane',
      NotificationDetails: 'Meldinginformatie',
      Approval: 'Goedkeuring',
      Labels: 'Etiketten',
    },
    Steps: {
      BasicDetails: 'Basisinformatie',
      PackageDetails: 'Pakketinformatie',
      Materials: 'Materialen',
      Documents: 'Documenten',
      Notes: 'Opmerkingen',
    },
    ShippingPackage: {
      BoxNo: 'Doosnr.',
      BoxType: 'Doostype',
      Dimension: 'Afmetingen (cm)',
      Height: 'Hoogte (cm)',
      Width: 'Breedte (cm)',
      Depth: 'Diepte (cm)',
      Items: 'Artikelen',
      Weight: 'Gewicht (kg)',
      GeneralCargo: 'Algemene lading',
      DangerousGoods: 'Gevaarlijke stoffen',
      None: 'Geen',
      TemperatureControlled: 'Temperatuurgeregeld',
      DeleteConfirmation: 'Verpakkinginformatie verwijderen?',
      AddShippingPackage: 'DOOS/PAKKET toevoegen',
      EditShippingPackage: 'DOOS/PAKKET bewerken',
      Box: 'DOOS (product)',
      CommodityCode: 'Artikelcode',
      HSCODE: 'HS-code*',
      Description: 'Omschrijving*',
      Quantity: 'Aantal*',
      ItemVaue: 'Artikelwaarde*',
      ValueCurrency: 'Waarde*',
      NetWeight: 'Nettogewicht*',
      GrossWeight: 'Brutogewicht*',
      CountryOfOrigin: 'Land van oorsprong*',
      Checks: 'Controles',
      NumberOfPackages: 'Aantal pakketten',
      UNNumber: 'VN-nummer',
      PrimaryClass: 'Eerste klas',
      SecondaryClass: 'Tweede klas',
      CAO: 'Alleen luchtvracht',
      KgOfDryIce: 'Aantal kg droog ijs',
      DataLogger: 'Dataloggerinformatie',
      DataLoggerNumber: 'Dataloggernummer',
      Commodity: 'Artikel toevoegen',
      ItemDescription: 'Artikelomschrijving',
      ItemValue: 'Artikelwaarde',
      Unit: 'Eenheid*',
      YourOwnPackage: 'Uw eigen pakket',
      PackagingRequired: 'Verpakken  verplicht?',
      Instruction: 'Instructie',
      ProductRequired: 'Minimaal 1 product is verplicht',
      ErrorOccurred: 'Er is een fout opgetreden',
      ProductQuantityNotAvailable:
        'Product verpakken of droogijs niet beschikbaar',
      Temperature: 'Temperatuur',
      InnerBox: 'Binnendoos',
      Product: 'Product',
      Remarks: 'Opmerkingen',
      DeleteInnerBox: 'Binnendoos verwijderen',
      DeleteInnerBoxDescription:
        'Weet u zeker dat u de binnendoos wilt verwijderen  ?',
      OuterBox: 'omdoos',
      InnerBoxCount: 'Aantal binnendozen',
    },
    WorkOrder: {
      WONo: 'WO-nr.',
      Type: 'Type',
      WODate: 'WO-datum',
      ActivityOwner: 'Activiteiteigenaar',
      LastUpdateOn: 'Laatste update op',
      Status: 'Status',
      BackOffice: 'BackOffice',
      Driver: 'Chauffeur',
      MobileUser: 'Mobile User-NL',
      Partner: 'Partner',
      WareHouse: 'magazijn',
      WarehouseStaff: 'Magazijnpersoneel',
      DecNPack: 'Dec&Pack',
      AssignedTo: 'Toegewezen  aan',
      DGDCreation: 'WO2 - DGDaanmaken',
      WorkorderOutbound: 'WO Uitgaand',
      OutboundDetails: 'Uitgaande informatie',
      WorkorderDocCheck: 'WO - documentcontrole',
      WorkorderPhysicalCheck: 'WO -fysieke controle',
      Checks: 'Controles',
      SelectAnyOption: 'Selecteer een optie',
      SelectMultipleOption: 'Selecteer een of meerdere opties',
      NoQuestionnaireMapMsg: 'Geen vragenlijstgekoppeld aan deze    werkorder',
      AnswersSubmittedSuccessMsg: 'Antwoorden voor vragenlijstingediend ',
      NotSubmittedErrMsg: 'Geen antwoord ontvangen op   vragenlijst',
      ExecutionNotRequired: 'Uitvoering niet verplicht',
      PhysicalCheckAddSuccessMsg: 'Fysieke controle werkorder is toegevoegd ',
      PhysicalCheckUpdateSuccessMsg:
        'Fysieke controle werkorder is bijgewerkt ',
      DocumentCheckAddSuccessMsg: 'Documentcontrole werkorder is toegevoegd ',
      DocumentCheckUpdateSuccessMsg:
        'Documentcontrole werkorder is bijgewerkt ',
      ActualDateTime: 'Huidige datum en tijd',
      ExpectedDateTime: 'Verwachte datum en tijd',
    },
    OrderDetails: 'Zendinginformatie',
    Location: 'Locatie',
    Contact: 'Contactpersoon',
    Cancel: 'Order annuleren',
    OrderReference: 'Klantreferentie',
    Service: 'Dienst',
    OrderCategory: 'Ordercategorie',
    OfferCategory: 'Offertecategorie',
    Validity: 'Geldigheid',
    PickupAddress: 'Ophaaladres',
    DeliveryAddress: 'Afleveradres',
    IsSameAsCustomer: 'Zelfde als klant',
    PickupFrom: 'Ophalenbij',
    DeliveryTo: 'Afleveren bij',
    ExpectedPickupDateTime: 'Verwachte ophaaldatum en -tijd',
    ExpectedDeliveryDateTime: 'Verwachte afleverdatum en -tijd',
    AddSuccess: 'Zendingorder is aangemaakt.',
    AddSuccessOffer: 'Zendingofferte is aangemaakt.',
    UpdateSuccessOffer: 'Zendingofferte isbijgewerkt .',
    InOrder: 'In order',
    InOffer: 'In offerte',
    Type: 'Type',
    DeleteMessage: 'Zendingorder verwijderen?',
    DeleteOfferMessage:
      'Weet u zeker dat u deze zendingofferte wilt verwijderen  ?',
    DeleteTitle: 'Zendingorder verwijderen',
    DeleteOfferTitle: 'Zendingofferte verwijderen',
    UpdateSuccess: 'Zendingorder is gewijzigd.',
    UpdateOfferSuccess: 'Zendingofferte is bijgewerkt .',
    DeleteSuccess: 'Zendingorder is verwijderd.',
    DeleteConfirmation: 'Zendingorder verwijderen?',
    CancelConfirmation:
      'Weet u zeker dat u deze zendingorder wilt verwijderen   ?',
    CancelSuccess: 'Zendingorder  geannuleerd',
    RejectOfferConfirmation:
      'Weet u zeker dat u deze zendingofferte wilt afkeuren     ?',
    RejectOfferSuccess: 'Zendingofferte is afgekeurd .',
    OrderDate: 'Zendingorderdatum',
    OfferDate: 'Offertedatum',
    Customer: 'Klant',
    Category: 'Categorie',
    Attention: 'Let op',
    HazgoReference: 'HazGo-referentie',
    WoDoneVsTotal: 'WO Klaar versus Totaal',
    Partner: 'Partner',
    PartnerTrackingNo: 'Partnertrackingnr.',
    FromTo: 'Van - naar',
    Status: 'Status',
    TotalWeight: 'Totaalgewicht',
    TotalNoOfPackages: 'Totaal aantal colli',
    ErrorOccurred: 'Er is een fout opgetreden',
    Information: 'Informatie',
    Document: 'Document',
    DGPlus: 'DG Plus',
    EuDatabase: 'EU-database',
    CreateDGD: 'DGD aanmaken',
    AllDataAvailable: 'Alle gegevensbeschikbaar om DGD te voltooien?',
    DGDTitle: 'WO-DGDaanmaken',
    ToolTip: {
      DownloadSampleFile: 'Voorbeeldbestand downloaden',
    },
    PickupDatetimeIsGreaterErr:
      'Verwachte afleverdatum en tijd moeten later  zijn dan de  ophaaldatum en -tijd',
    DestinationCompany: 'Bestemmingbedrijf',
    PickupCompany: 'Ophaalbedrijf',
    OrderCopySuccessful: 'Zendingofferte is gekopieerd .',
    CopyConfirmation: 'Actie kan niet ongedaan worden gemaakt   . Doorgaan?',
  },
  Booking: {
    BookingTitle: 'WO1 -boeken',
    Information: 'Informatie',
    Partner: 'Partner',
    Service: 'Dienst',
    TrackingNumber: 'Track&Trace-nummer',
    TotalCost: 'Totale kosten',
    TotalPrice: 'Totaalprijs',
    SupplierCost: 'Leverancier kosten',
    Cost: 'Kosten',
    Save: 'Opslaan',
    Cancel: 'Annuleren',
    Calculate: 'Berekenen',
    EditBooking: 'Boeking  bewerken',
    AddBooking: 'Boeking  toevoegen',
    Status: 'Status',
    StatusSavedSuccess: 'Status is opgeslagen .',
    DeleteBooking: 'Boekingorder verwijderen',
    DeleteConfirmation: 'Weet u zeker dat u deze boeking wilt verwijderen   ?',
    DeleteSuccess: 'Boeking werkorder isverwijderd .',
    UpdateSuccess: 'Boeking werkorder is bijgewerkt ',
    AddSuccess: 'Boeking werkorder is toegevoegd ',
    Notes: 'Opmerkingen',
    TransportType: 'Transporttype',
    Airline: 'Luchtvaartmaatschappij',
    DestinationCountry: 'Bestemming Land',
    TransitCountry: 'Transitland',
    AirlineRestrictions: 'Luchtvaartmaatschappijrestricties ',
    CountryRestrictions: 'Landrestricties',
    Restrictions: 'Beperkers',
  },

  Delivery: {
    Instructions: 'Instructies',
    AssignedTo: 'Toegewezen  aan',
    Title: 'WO afleveren',
    Remarks: 'Opmerkingen',
    Document: 'Document',
    DeliveryDetails: 'Afleverinformatie',
    DeliveryDone: 'Afgeleverd',
    ActualDeliveryDateTime: 'Huidige afleverdatum en -tijd',
    FieldRequired: 'Dit  veld is verplicht.',
    AddSuccess: 'Afgeleverd .',
    SomethingWentWrong: 'Er is iets misgegaan.',
    UpdateSuccess: 'Aflevering is bijgewerkt .',
    POD: 'Afleverpunt',
    SendPOD: 'Aflevermelding   aan klant versturen',
  },
  LedgerInfo: {
    Title: 'Grootboekinformatie',
    LedgerAccount: 'Grootboekrekening',
    LedgerAccountDescription: 'Omschrijving grootboekrekening',
  },
  ItemGroupInfo: {
    Title: 'Artikelgroepinformatie',
    ItemGroup: 'Artikelgroep',
  },
  Packaging: {
    ErrorWhileUpdatingStatus:
      'Er is iets misgegaan bij het bijwerken van de status.',
    SomethingWentWrong: 'Er is iets misgegaan.',
    AddSuccess: 'Verpakken is toegevoegd .',
    UpdateSuccess: 'Verpakken is bijgewerkt .',
    FieldRequired: 'Veld is verplicht',
    ActualPackagingDateTime: 'Huidige verpakkings datum en-tijd',
    Remarks: 'Opmerkingen',
    Title: 'WO verpakken',
    AssignedTo: 'Toegewezen  aan',
    AssignmentType: 'Toewijzingstype',
    Instructions: 'Instructies',
    PackagingDetails: 'Verpakkinginformatie',
    PackagingDone: 'Verpakken  gereed',
    Picture: 'Afbeelding',
  },

  ShippingMaterials: {
    Add: 'Materiaal toevoegen',
    Edit: 'Materiaal bewerken',
    Type: 'Type',
    Category: 'Categorie',
    ItemCode: 'Artikelcode',
    Name: 'Naam',
    Quantity: 'Aantal',
    Note: 'Notitie',
    SellingPrice: 'Verkoopprijs',
    Delete: 'Verwijderen',
    ProductCatalogue: 'Product',
    ServiceCatalogue: 'Dienst',
    TransportCatalogue: 'Transport',
    DeleteMessage: 'Materiaal verwijderen?',
    DeleteTitle: 'Materiaal verwijderen',
    BoxDetails: 'Doosinformatie',
    IsFromPackage: 'IsVanPakket',
    NotProcessed: 'Controleer het gedownloade bestand',
    ProcessedSuccessfully: 'Bestand  is verwerkt',
    ProductQuantityNotAvailable: 'Productaantal  niet beschikbaar',
    IncorrectTransportDetail: 'Vervoersinformatie isverplicht',
    ZeroNotAllowed: 'Nul niet toegestaan',
    CostPrice: 'Kostprijs',
    SellingPriceLessThanCostPrice: 'Verkoopprijs is lager dan de   kostprijs',
    SendProformaInvoice: ' Proforma factuur versturen',
    EmailNotVailable: 'E-mailadres is niet beschikbaar',
    QuantityDetails: 'Aantalinformatie',
    WareHouseQuantity: 'Magazijn Aantal',
    RequestedQuantity: 'Gevraagd  aantal',
    Delta: 'Verschil (huidig - gevraagd)',
    Threshold: 'Drempelwaarde Aantal',
    EstimatedCost: 'Geschatte kosten',
    ActualCost: 'Werkelijke kosten',
  },
  Tracking: {
    Add: 'Tracking toevoegen',
    Edit: 'Tracking bewerken',
    Internal: 'Intern',
    External: 'Extern',
    StatusChangeDate: 'Datum van statuswijziging',
    Status: 'Status',
    Type: 'Type',
    Notes: 'Opmerkingen',
    AddSuccess: 'Zendingorder is aangemaakt .',
    InOrder: 'In order',
    InOffer: 'In offerte',
    DeleteMessage: 'Weet u zeker dat u tracking wilt verwijderen  ?',
    DeleteTitle: 'Tracking verwijderen',
    UpdateSuccess: 'Tracking isbijgewerkt .',
    DeleteSuccess: 'Tracking isverwijderd .',
    DeleteConfirmation: 'Weet u zeker dat u deze tracking wilt verwijderen  ?',
  },
  DecPackOrders: {
    DeleteMessage: 'Weet u zeker dat u deze Dec&Pack-order wilt verwijderen  ?',
    DeleteTitle: 'Dec&Verpak-order verwijderen',
    Details: 'Informatie',
    DeckPack: 'Dec&Pack',
    RejectOfferConfirmation:
      'Weet u zeker dat u deze Dec&Pack-offerte wilt afkeuren     ?',
    OrderOwner: 'Ordereigenaar',
    SendForApproval: 'Versturen  ter  goedkeuring',
    SendForApprovalConfirmation:
      'Weet u zeker dat u de order ter goedkeuring wilt versturen    ?',
    SendForApprovalConfirmationDetails: 'Order versturen ter  goedkeuring',
    MobileRemarks: 'MobielOpmerkingen-FR',
    Table: {
      OfferReference: 'Offertereferentie',
      OrderDate: 'Orderdatum',
      OfferDate: 'Offertedatum',
      Customer: 'Klant',
      ServiceType: 'Diensttype',
      Reference: 'Referentie',
      Location: 'Locatie',
      WoDoneVsTotal: 'WO Klaar versus Totaal',
      Type: 'Type',
      Pickup: 'Ophalen',
      Contacts: 'Contactpunten',
      InProgressWO: 'In-bewerking WO',
      FinalDestination: 'Eindbestemming ',
      Status: 'Status',
      Attention: 'Let op',
      HazGoReference: 'HazGo- Referentie',
      CustomerReference: 'Klantreferentie',
      ExecutionDate: 'Uitvoeringsdatum',
      Shipper: 'Verzender',
      AssignedTo: 'Toegewezen  aan',
      ExecutionDateTime: 'Uitvoeringsdatum/-tijd',
      ExecutionTime: 'Uitvoeringtijd',
    },
    Tabs: {
      Details: 'Informatie',
      WorkOrder: 'Werkorder',
      BillableItems: 'Factureerbare  items',
      Documents: 'Documenten',
      Notes: 'Opmerkingen',
      Approval: 'Goedkeuring',
    },
    ToolTip: {
      DownloadSampleFile: 'Voorbeeldbestand downloaden',
    },
    DGCheck: 'DG-controle',
    DGDCreation: 'DGDaanmaken',
    NonDGOrder: 'Niet-DG-order',
    Shop: 'Winkel',
    DeleteSuccess: 'Verwijderd ',
    RejectOffer: 'Offerte afwijzen',
    OfferRejectSuccess: 'Offerteafgewezen ',
    DeleteConfirmation:
      'Weet u zeker dat u deze Dec&Pack-order wilt verwijderen   ?',
    CancelConfirmation:
      'Weet u zeker dat u deze Dec&Pack-order wilt annuleren   ?',
    CancelSuccess: 'Dec&Pack-order geannuleerd ',
    QuestionnaireAnswersSubmitted: 'Antwoorden voor vragenlijstingediend ',
    QuestionnaireAnswersMandatory: 'Vragenlijsten zijn verplicht',
    CheckQuestionsMandatory: 'Vragen zijn verplicht',
    WorkOrder: {
      WONo: 'WO-nr.',
      Type: 'Type',
      WODate: 'WO-datum',
      ActivityOwner: 'Activiteiteigenaar',
      LastUpdateOn: 'Laatste update op',
      Status: 'Status',
      BackOffice: 'BackOffice',
      Driver: 'Chauffeur',
      Partner: 'Partner',
      AssignedTo: 'Toegewezen  aan',
      DGDCreationTitle: 'WO 2 - DGDaanmaken',
      SecurityCheckTitle: 'WO S - beveiligingscontrole RA',
      CheckDocumentation: 'WO 4 - documentatie controleren',
      CustomerRefTitle: 'WO 11 - klantreferentie',
      Material: 'WO 5 - materiaal',
      PhotoCheck: 'WO 9 - fotocontrole',
      TypeCheck: 'Typecontrole',
      CheckQuestion: 'Controle',
      Details: 'Informatie',
      SpecialInstruction: 'Speciale Instructies',
      Assign: 'Toewijzen',
      UNNumberCheck: 'VN-nummercontrole',
      ExtractDGPlus: 'Afleiden uit DGPlus',
      Comment: 'Opmerking',
      ShowAirLine: 'Luchtvaartmaatschappijen weergeven',
      ShowCountry: 'Landen weergeven',
      Instruction: 'Instructie',
      FourDigitPin: 'Voer 4-cijferige UN in',
      BillOfMaterial: 'WO 8 - materialenlijst',
      ApprovalBillOfMaterial: 'WO 10 -goedkeuring materialenlijst',
      ApprovalInvoice: 'W0 12 -goedkeuring factuur',
      Execution: 'Uitvoering',
      OrderExecution: 'WO 6 - orderuitvoering',
      AirLine: 'Luchtvaartmaatschappij',
      Country: 'Land',
      ShipmentNotAvailableInWarehouse: 'Zending niet  beschikbaar  in magazijn',
      Date: 'Datum',
      Photos: "Foto's",
      requiredErrMsg: 'Dit  veld is verplicht',
      NoQuestionnaireMapMsg: 'Geen vragenlijst',
      detailsUpdateMsg: 'informatie bijgewerkt ',
      detailsAddMsg: 'Informatie toegevoegd ',
      invalidDateErrMsg: 'Ongeldige datum',
      WO6ltrDeliveryOrderTitle: 'Werkorder 6ter - aflevering van order',
      CustomeName: 'Klantnaam',
      DateTime: 'Datum/tijd',
      Delivery: 'Afleveren',
      WOOutboundProcess: 'WOuitgaand proces',
      OBO: 'WO OBO -uitgaand proces',
      DGDDocument: 'DGD-document',
      Questionnaire: 'Vragenlijst*',
      FileNames: {
        DGDDocPDF: 'DGD Document.pdf',
      },
      DGPlusData: 'DG Plus-informatie',
      WO3Details: 'WO3-informatie',
      ShowWO3Details: 'WO3-informatie weergeven',
      InvoiceNotes: 'Factuur opmerkingen',
      ProformaNotes: 'Proforma opmerkingen',
    },
    DGPlusDetails: {
      Name: 'Naam',
      UN: 'VN',
      Class: 'Klasse',
      Subrisk: 'Subrisico',
      Packinggroup: 'Verpakkingsgroep',
      Exceptedquantities: 'Uitgesloten hoeveelheden',
      Paxpackinginstruction: 'verpakkingsinstructies voor  passagiers',
      Paxmaxquantity: 'Max. aantal voor passagiers',
      Caopackinginstruction: 'Instructies voor verpakken van  lading',
      Caomaxquantity: 'Max. ladingaantal',
    },
    CheckDocumentation: {
      CreateLabels: 'Labels aanmaken',
      LayoutType: 'Indelingtype',
      ShippingLabel: 'Zendinglabel',
      OverpackLabel: 'Oververpaklabel',
      Other: 'Overig',
      Dymo: 'DYMO',
      Zebra: 'ZEBRA',
      OtherLabelFreeText: 'Vrije tekst',
      OverPackId: 'Oververpak -ID',
      TotalQuantity: 'Totaal aantal',
      Consignee: 'Ontvanger',
      Shipper: 'Verzender',
      UnNumber: 'UN-nummer',
      TechnicalName: 'Technische naam',
      NetQuantity: 'Aantal',
      AddSuccess: 'Label toegevoegd .',
      SomethingWentWrong: 'Er is iets misgegaan.',
      UpdateSuccess: 'Label bijgewerkt .',
      MyHazGoRef: 'mijnHazgo-ref.',
      CustomerRef: 'Klantref.',
      NumberOfPieces: 'Aantal colli',
      PSN: 'PSN',
      SelectPSN: 'PSN selecteren',
    },
    Validation: {
      CustomerNameRequired: 'Klantnaam is verplicht.',
      CommentRequired: 'Opmerking  is verplicht.',
      DateRequired: 'Datum is verplicht.',
    },
    WOMaterial: {
      MaterialNeeded: 'Benodigd  materiaal',
      SpecialInstruction: 'Speciale instructie',
      Assign: 'Toewijzen',
      Instruction: 'Instructie',
      AssignTo: 'Toegewezen  aan',
      StrongMaterial: 'Sterkoververpak materiaal',
      Openfield: 'Open veld',
      SpecificTypeOfPackaging: 'Specifiek type verpakking',
      Packaging: 'Verpakken',
      Quantity: 'Aantal',
      FieldRequired: 'Dit  veld is verplicht.',
      AddSuccess: 'Afgeleverd .',
      SomethingWentWrong: 'Er is iets misgegaan.',
      UpdateSuccess: 'Aflevering is bijgewerkt .',
      ProductQuantityNotAvailable: 'Productaantal niet beschikbaar',
      ProductAtAllLevels: 'Product moet geselecteerd zijn op alle  niveaus',
    },
    PhotoCheck: {
      Photos: "Foto's",
      DGD: 'DGD',
      SpecialInstruction: 'Speciale Instructies',
      PhotoCheck: 'Fotocontrole',
      ViewDocument: 'Klik hier om het document weer te geven:',
      Instruction1: 'Instructie 1:',
      Instruction2: 'Instructie 2:',
    },
    WOSecurityCheckQA1:
      "Is de vervoerder geregistreerd in de 'EU-gegevensbank betreffende de beveiliging van de toeleveringsketen' als RA of KC  ?",
    WOSecurityCheckQA2:
      'Is de persoon die de zending aflevert een opgeleid chauffeur van de RA of een chauffeur van een onderaannemende vervoerder die een 6E vervoerdersovereenkomst met de RA of KC heeft gesloten      ?',
    DecPackQuestions: {
      WOIBO: {
        SecureShipment: 'Beveiligde zending*',
        SecurityGeneral: 'Beveiliging - algemeen*',
        Shipment: 'Zending*',
        SecureShipmentQA1: 'Vervoerder: ',
        SecureShipmentQA2: 'Kentekennummer : ',
        SecureShipmentQA3: 'Chauffeurnaam: ',
        SecureShipmentQA4:
          'Is het voertuig verzegeld/vergrendeld voordat het wordt gelost  ?',
        SecurityGeneralQA1: 'Geen sporen van manipulatie van de zending  ? ',
        SecurityGeneralQA2:
          'Geen manipulatie van de verzegeling van de zending? ',
        ShipmentQA1: 'Aantal colli: ',
        ShipmentQA2: 'Niet beschadigd ? ',
        ShipmentQA3: 'Zending geaccepteerd',
        Title: 'WO - inkomend',
        SaveSuccess: 'WO - inkomend antwoorden zijn opgeslagen .',
        SaveError: 'WO - inkomend antwoorden zijn niet opgeslagen .',
        CMRNote: 'Maak aantekeningen op de CMR',
        AddDamagePictures: 'Foto van  beschadiging toevoegen',
        TransportDocument: 'Vervoersdocumenten',
        NoShippingDocsAvailable: 'Geen vervoersdocumenten beschikbaar  ',
        TransportDocumentSaveSuccess: 'Vervoersdocumenten zijn opgeslagen .',
        TransportDocumentSaveError: 'Vervoersdocumenten zijn niet Opgeslagen .',
        CreateWarehouseLabel: 'Magazijnlabel aanmaken',
        WarehouseLabel: 'Magazijnlabel aanmaken',
        DetailsRequired: 'Beveiligingsinformatie en zending verplicht',
        AllDetailsRequired:
          'Beveiligde zending, beveiligingsinformatie en zending verplicht',
      },
      WOCheckDocumentation: {
        WOCheckDocumentationQA1: 'DGD-controle OK?',
        WOCheckDocumentationQA2: 'AWB-controle OK?',
        WOCheckDocumentationQA3: 'Luchtvaartrestricties OK? ',
        WOCheckDocumentationQA4: 'Landrestricties OK?',
      },
      WOExecution: {
        WOExecutionQA1: 'DGD-controle OK?',
        WOExecutionQA2: 'AWB-controle OK?',
        WOExecutionQA3: 'Luchtvaartrestricties OK? ',
        WOExecutionQA4: 'Landrestricties OK?',
        WOExecutionQA5: 'Zending gereed voor transport ?',
        NoDocsAvailable: 'Geen documenten  beschikbaar',
      },
    },
    CustomerName: 'Klantnaam',
    DateTime: 'Datum/tijd',
  },
  WorkOrder: {
    Title: 'WO inkomend',
    AssignmentTo: 'Toegewezen  aan',
    AssignmentType: 'Toewijzingstype',
    InboundDetails: 'Inkomend informatie',
    Instructions: 'Instructies',
    Remarks: 'Opmerkingen',
    InboundDone: 'Inkomend  Gereed',
    ActualInboundDateTime: 'Huidige inkomende datum en -tijd',
    Driver: 'Chauffeur',
    Partner: 'Partner',
    WarehouseStaff: 'MagazijnPersoneel',
    FieldRequired: 'Veld is verplicht',
    AddSuccess: 'Werkorder inkomend is toegevoegd .',
    UpdateSuccess: 'Werkorder is bijgewerkt .',
    DGDCreation: 'DGDCreatie',
    SecurityCheckRA: 'Veiligheidscontrole RA',
    Inboundprocess: 'Inkomend proces',
    Collectingshipmentdata: 'Verzendinformatie verzamelen',
    CheckDocumentation: 'Documentatie controleren',
    Material: 'Materiaal',
    Executionorder: 'Uitvoeringorder',
    Deliveryorderassigning: 'Afleverorder toewijzing',
    Deliveryorder: 'Afleverorder',
    Irregularitycontactclient: 'Contactpersoon bij klant bij onregelmatigheid',
    BillofMaterial: 'Materialenlijst',
    PhotoCheck: 'Fotocontrole',
    Outboundprocess: 'Uitgaand proces',
    ApprovalBillofMaterial: 'Goedkeuring materialenlijst',
    InputmissingCustomerreference: 'Voer ontbrekende klantreferentie in',
    ApprovalInvoice: 'Goedkeuring factuur',
    ApproveInvoice: 'Factuur  goedkeuren',
    InfoRemindCustomerRef:
      'Herinnering versturen naar klant om referentienummer bij te  werken',
    UpdatedSuccessfully: 'Bijgewerkt ',
    ErrorOccurred: 'Er is een fout opgetreden',
    SendProformaInvoice: 'Proformafactuur versturen',
    SendAnyway: 'Hoe dan ook verzenden',
    ApproveAnyway: 'Hoe dan ook goedkeuren',
    SendSuccessfully: 'Proforma factuur verstuurd',
    InvoiceApprovedSuccess: 'Factuur goedgekeurd ',
    SendEmailSuccessfully: 'E-mailbericht verstuurd',
    Notes: 'Opmerkingen',
    OrderReference: 'Orderreferentie',
  },
  WorkorderPickup: {
    WorkorderPickup: 'Werkorder ophalen',
    Assigned: 'Toegewezen',
    PickupDetails: 'Ophaalinformatie',
    Status: 'Status',
    AssignmentType: 'Toewijzingstype',
    Instructions: 'Instructies',
    AssignedTo: 'Toegewezen  aan',
    requiredErrMsg: 'Dit  veld is verplicht',
    Driver: 'Chauffeur',
    Partner: 'Partner',
    PickupDone: 'Opgehaald',
    ActualPickDateTime: 'Werkelijke ophaaldatum en -tijd',
    Remarks: 'Opmerkingen',
    WaitTime: 'Wachttimer (s)',
    Packaging: 'Verpakken',
    PackagingDone: 'Verpakken op locatie bij de    klant',
    AddSuccess: 'Werkorder ophalen is toegevoegd .',
    UpdateSuccess: 'Werkorder ophalen is bijgewerkt .',
    AssignedNextTo: ' Volgende toegewezen WOaan',
  },
  WorkorderOutbound: {
    WorkorderOutbound: 'Werkorder uitgaand',
    AssignedSection: 'Toegewezen  aan',
    OutboundSection: 'Uitgaand tnformatie',
    AssignedTo: 'Toegewezen  aan',
    Instructions: 'Instructies',
    Remarks: 'Opmerkingen',
    ActualOutboundDateTime: 'Huidige  uitgaande datum en -tijd',
    requiredErrMsg: 'Dit  veld is verplicht',
    detailsUpdateMsg: 'Informatie bijgewerkt ',
    OutboundDone: 'Uitgaand  Gereed',
  },
  DecPack: {
    AddDecPack: 'Dec&Pack toevoegen',
    OrderBasics: 'Basisinformatie order',
    OfferBasics: 'Basisinformatie offerte',
    CustomerDetails: 'Klantinformatie',
    GeneralDetails: 'Algemene informatie',
    HazgoReference: 'HazGo-referentie',
    TypeOfService: 'Diebnsttype',
    DGCheck: 'DG-controle',
    DGDCreation: 'DGDaanmaken',
    NonDGOrder: 'Niet-DG-order',
    Shop: 'Winkel',
    ExecutionDate: 'Uitvoeringsdatum',
    StartExecutionTime: 'Begintijd uitvoering',
    EndExecutionTime: 'Eindtijd uitvoering',
    SelectExecutionTime: 'Uitvoeringtijd selecteren - AM/PM/Specifieke tijd',
    Am: 'AM (voormiddag)',
    Pm: 'PM (namiddag)',
    SpecificTime: 'Specifieke tijd',
    None: 'Geen',
    HighPriority: 'Hoge prioriteit',
    Customer: 'Klant',
    Contact: 'Contactpersoon',
    CustomerReference: 'Klantreferentie',
    Shipper: 'Verzender',
    Location: 'Locatie',
    WareHouse: 'Magazijn',
    GroundHandler: 'Grond afhandelaar',
    DetailsWarehouse: 'Informatie vanlocatie ',
    InboundDetails: 'Inkomende gegevens',
    OutboundDetails: 'Uitgaande gegevens',
    Name: 'Naam',
    SecuredShipment: 'Beveiligde zending?',
    DriverName: 'Vervoerderinformatie - chauffeurnaam',
    LicensePlate: 'Kenteken',
    Transporter: 'Vervoerder',
    NumberofPieces: 'Aantal colli',
    Pickupaddress: 'Ophaaladres',
    ExpectedInboundDate: 'Verwachte ontvangstdatum',
    DeliveryLocation: 'Afleveren - locatie',
    Airline: 'Luchtvaartmaatschappij',
    FinalDestination: 'Eindbestemming ',
    TransitDestination: 'Transit Bestemming',
    SpecialInstructions: 'Speciale Instructies',
    EstimatedDuration: 'Geschatte tijdsduur(min.)',
    Interventie: 'Interventie',
    Status: 'Status',
    WoDone: 'WO Klaar versus Totaal',
    Consignee: 'Ontvanger',
    CompanyName: 'Bedrijfsnaam',
    KcRaAgent: 'KC/RA-agent',
    PickUpBy: 'Ophalen langs',
    DeliveryReference: 'Leveringsreferentie',
  },
  WOCollectionData: {
    Title: 'WO 3 - verzendinformatie verzamelen ',
    Document: 'Document',
    ShipmentData: 'Zendinginformatie',
    Photos: "Foto's",
    Shipment: 'Zending',
    MoveToWarehouse: 'Aanvraag om zending  naar magazijn te verplaatsen.',
    FieldRequired: 'Dit  veld is verplicht.',
    AddSuccess: 'Afgeleverd .',
    SomethingWentWrong: 'Er is iets misgegaan.',
    UpdateSuccess: 'Aflevering is bijgewerkt .',
    ShipmentDataUpdateSuccess: 'Zendinginformatie is bijgewerkt .',
    ShipmentDataUpdateFail:
      'Zendingfoto niet opgeslagen.  Probeer het opnieuw  .',
  },
  DecPackWO6Bis: {
    Title: 'Werkorder 6bis - Afleveren van order (toewijzen)',
    Delivery: 'Afleveren',
    AssignedTo: 'Toegewezen  aan',
    Location: 'Locatie',
    SpecialInstructions: 'Speciale Instructies',
    FieldRequired: 'Dit  veld is verplicht.',
    AddSuccess: 'Afgeleverd .',
    SomethingWentWrong: 'Er is iets misgegaan.',
    UpdateSuccess: 'Aflevering is bijgewerkt .',
  },
  SendEmail: {
    SendEmailTitle: 'Stuur een e-mailbericht',
    Send: 'Versturen',
    Cancel: 'Annuleren',
    Email: 'E-mailadres',
    Language: 'Taal',
    SendSuccessfully: 'E-mailbericht is verstuurd',
    ErrorOccurred: 'Er is iets misgegaan. Probeer het opnieuw.',
  },

  Cities: {
    AddDialogTitle: 'Plaats toevoegen',
    City: 'Plaats',
    Validation: {
      CityRequired: 'Plaats is verplicht.',
    },
    AddedSuccessfully: 'Stad is toegevoegd .',
    AlreadyExist: 'Plaats bestaat al .',
    ErrorOccurred: 'Er is een fout opgetreden. Probeer het opnieuw  .',
  },
  IrregularityContactClient: {
    Title: 'Onregelmatigheid - contactpersoon bij klant',
    Wo4Comment: 'WO4 opmerkingen',
    ContactCustomerViaEmail: 'Contact opnemen met de klant via e-mail',
    ContactCustomerViaEmailSuccess:
      'Status contact opnemen met klant via e-mail is opgeslagen .',
    IboNoQuestions: 'Vragen van WO-IBO ',
    Wo3Caption: 'Aanvraag om zending  naar magazijn te verplaatsen ',
    Wo6Caption: 'Vragen van WO6 ',
    Required: 'Selecteer contact opnemen mate klant via e-mail',
    WosCaption: 'Vragen van WOS ',
    Wo6CaptionShipmentNA: 'Zending niet  beschikbaar  in magazijn ',
  },
  State: {
    Name: 'Naam',
    DeletedSuccessfully: 'Verwijderd ',
    DeleteMessage: 'Weet u zeker dat u dit land wilt verwijderen  ?',
    DeleteState: 'Land verwijderen',
    EditState: 'Landbewerken',
    AddState: 'Landtoevoegen',
    Restriction: 'Restrictie',
    ApplicableTo: 'Van toepassing op',
    All: 'Alle',
    WO4: 'WO4',
    WO6: 'WO6',
    ShippingWOBooking: 'Zending -WO-boeken',
    ProperDataValidate: 'Voer de juiste gegevens in',
    FieldRequired: 'Veld is verplicht',
    Country: 'Land',
    StateRestrictions: 'Landrestricties',
  },
  Airline: {
    Name: 'Naam',
    DeletedSuccessfully: 'Verwijderd ',
    DeleteMessage:
      'Weet u zeker dat u de luchtvaartmaatschappij wilt verwijderen  ?',
    DeleteAirline: 'Luchtvaartmaatschappijverwijderen',
    EditAirline: 'Luchtvaartmaatschappij bewerken',
    AddAirline: 'Luchtvaartmaatschappij toevoegen',
    Restriction: 'Restrictie',
    ApplicableTo: 'Van toepassing op',
    All: 'Alle',
    WO4: 'WO4',
    WO6: 'WO6',
    ShippingWOBooking: 'Zending-WO-boeken',
    ProperDataValidate: 'Voer de juiste gegevens in',
    FieldRequired: 'Veld is verplicht',
    AirlineRestrictions: 'Luchtvaartmaatschappijrestricties ',
  },
};

export default nlLabels;
