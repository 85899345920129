import { Breadcrumbs, Button, Grid, Link, Typography } from '@mui/material';
import RouteEnum from 'models/RouteEnum';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import I18n from 'utilities/i18n';
import CreateLabels from 'common/create-labels/CreateLabels';
import { hasPermission } from 'utilities/protectedRoute';
import { Permission } from 'Permissions';

export default function CreateWarehouseLabel() {
  const { woId, id } = useParams();
  let navigate = useNavigate();
  const breadcrumbs = [
    <Typography key="3" color="text.primary">
      {I18n('Nav.Order')}
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={() => navigate(RouteEnum.DecPackOrdersList)}
        className="cpointer"
      >
        {I18n('Nav.DecPackOrders')}
      </Link>
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={() => navigate(RouteEnum.ViewDecPackOrders + '/' + id + '/1')}
        className="cpointer"
      >
        {I18n('ShippingOrders.WorkOrders')}
      </Link>
    </Typography>,
  ];
  const i18nMessages = {
    Status: I18n('ShippingOrders.Status'),
    commonFieldIsRequired: I18n('Common.FieldIsRequired'),
    somethingWentWrong: I18n('Common.SomethingWentWrong'),
    title: I18n('DecPackOrders.DecPackQuestions.WOIBO.Title'),
    shipmentTitle: I18n('DecPackOrders.DecPackQuestions.WOIBO.Shipment'),
    secureShipmentTitle: I18n(
      'DecPackOrders.DecPackQuestions.WOIBO.SecureShipment'
    ),
    createWarehouseLabel: I18n(
      'DecPackOrders.DecPackQuestions.WOIBO.CreateWarehouseLabel'
    ),
    securityGeneralTitle: I18n(
      'DecPackOrders.DecPackQuestions.WOIBO.SecurityGeneral'
    ),
    iboSaveSuccess: I18n('DecPackOrders.DecPackQuestions.WOIBO.SaveSuccess'),
    iboSaveError: I18n('DecPackOrders.DecPackQuestions.WOIBO.SaveError'),
    commentCaption: I18n('DecPackOrders.WorkOrder.Comment'),
    CMRNoteTitle: I18n('DecPackOrders.DecPackQuestions.WOIBO.CMRNote'),
    Picture: I18n('Packaging.Picture'),
    addDamagePictures: I18n(
      'DecPackOrders.DecPackQuestions.WOIBO.AddDamagePictures'
    ),
    NoImagesSelected: I18n('Common.NoImagesSelectedToUpload'),
    transportDocumentsTitle: I18n(
      'DecPackOrders.DecPackQuestions.WOIBO.TransportDocument'
    ),
    NoShippingDocsAvailable: I18n(
      'DecPackOrders.DecPackQuestions.WOIBO.NoShippingDocsAvailable'
    ),
  };

  const [canEdit, setCanEdit] = useState(
    hasPermission(Permission.canEditDecPackOrders)
  );
  const [isLabelSaveCallApi, setIsLabelSaveCallApi] =
    useState(false); /* To call Submit APi Status from Status Component */
  const [validateLabel, setValidateLabel] = useState<boolean>(false);

  useEffect(() => {}, []);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setValidateLabel(true);
  };

  const handleLabelStatusSave = async () => {
    try {
      setIsLabelSaveCallApi(true);
    } catch (error: any) {
      handleErrorStatusSave(false);
    }
  };

  const handleErrorStatusSave = (data: any) => {
    setValidateLabel(false);
    setIsLabelSaveCallApi(false);
  };

  return (
    <>
      <div className="heading-section">
        <div className="heading-section-left">
          <Typography variant="h3" sx={{ mb: 3 }}>
            {i18nMessages.title}
          </Typography>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>
      </div>
      <div className="main-content-section">
        <Grid item lg={12} md={12}>
          {canEdit ? (
            <CreateLabels
              permission={Permission.canEditDecPackOrders}
              woId={woId}
              orderId={id}
              isWarehouse={true}
              isAccordian={true}
              isLabelSaveCallApi={isLabelSaveCallApi}
              validateLabel={validateLabel}
              handleLabelStatusSave={handleLabelStatusSave}
              handleErrorStatusSave={handleErrorStatusSave}
            />
          ) : (
            <></>
          )}
        </Grid>
        <div className="btn-wrap form-btn-wrap">
          {canEdit ? (
            <Button
              type="submit"
              color="primary"
              disableElevation
              variant="contained"
              onClick={handleSubmit}
            >
              {I18n('Common.Save')}
            </Button>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}
