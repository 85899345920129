import React, { useState, useEffect } from 'react';
import { Checkbox, Grid, Link, Typography } from '@mui/material';
import { DataGrid, GridColumns } from '@mui/x-data-grid';
import environment from 'environment';
import CustomizedSelect from 'common/CustomSelect/CustomSelect';
import FormAccordian from 'common/formControl/formAccordian';
import CustomizedInputs from 'common/formControl/formControl';
import I18n from 'utilities/i18n';
import http from '../../utilities/httpService';
import { EntityStatus, OrderByDirection } from 'models/pagination-model';
import { BaseModel, EntityStatusDescription } from 'models/pagination-model';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import RouteEnum from 'models/RouteEnum';
import CustomBreadcrumbs from 'common/Breadcrumbs/Breadcrumbs';
import { hasPermission } from 'utilities/protectedRoute';
import { Permission } from 'Permissions';
import { ProtectedRoles } from 'models/rolesListModel';

const EditRole = (props: any) => {
  const [values, setValues] = useState({
    name: '',
    status: 0,
  });
  const defaultValueForStatus = {
    value: EntityStatus.Active.toString(),
    label: I18n('Common.Active'),
  } as BaseModel;

  const [roleDetailsExpanded, setRoleDetailsExpanded] = useState<boolean>(true);
  const [isRolesDetailsEdit, setRolesDetailsIsEdit] = useState<boolean>(false);

  const [permissionExpanded, setPermissionExpanded] = useState<boolean>(true);
  const [ispermissionEdit, setPermissionIsEdit] = useState<boolean>(false);
  const [rowData, setRowData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(defaultValueForStatus);
  const [canEdit, setCanEdit] = useState(hasPermission(Permission.canEditRole));
  const params = useParams();
  let navigate = useNavigate();

  const i18nMessages = {
    ErrorOccurred: I18n('Common.ErrorOccurred'),
  };

  const onValueChange = (event: any) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name.toString()]: value,
    });
  };

  const defaultValue = {
    value: '0',
    label: I18n('Placeholders.SelectStatus'),
  } as BaseModel;

  useEffect(() => {
    getRoleDetails();
    getModuleData();
  }, []);

  const handelRolesDetailsEdit = () => {
    setRolesDetailsIsEdit(!isRolesDetailsEdit);
  };
  const handleRolesDetailsExpandCollapseClick = () => {
    setRoleDetailsExpanded(!roleDetailsExpanded);
  };
  const handelPermissionEdit = () => {
    setPermissionIsEdit(!ispermissionEdit);
  };

  const handelPermissionExpanded = () => {
    setPermissionExpanded(!permissionExpanded);
  };

  const breadcrumbs = [
    <Typography key="3" color="text.primary">
      {I18n('Nav.Configuration')}
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={() => navigate('/Roles')}
        className="cpointer"
      >
        {I18n('Nav.Roles')}
      </Link>
    </Typography>,
    <Typography key="3" color="text.primary">
      {I18n('Nav.EditRole')}
    </Typography>,
  ];

  let entityStatusList: Array<BaseModel> = [];
  const getStatusList = () => {
    entityStatusList = [];
    EntityStatusDescription.forEach((value: string, key: number) => {
      entityStatusList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getStatusList();

  const onStatusChange = (data: any) => {
    setValues({ ...values, status: data?.value });
    setSelectedStatus({ label: data?.label, value: data?.value });
  };

  const getRoleDetails = async () => {
    const apiUrl = new URL(environment.api.baseUrl + 'Roles/' + params.id);
    try {
      const result: any = await http.get(apiUrl.toString());
      var statusLabel = entityStatusList.find(
        (x) => x.value == result.data.status
      )?.label;
      setSelectedStatus({
        label: statusLabel ? statusLabel : '',
        value: result.data.status,
      });

      setValues(result.data);

      if (ProtectedRoles.includes(String(result.data.name).trim())) {
        setCanEdit(false);
      }
    } catch (error: any) {}
  };
  const handleSubmit = async (event: any) => {
    event.preventDefault();

    const apiUrl = new URL(environment.api.baseUrl + 'Roles/' + params.id);
    try {
      const result: any = await http.put(apiUrl.toString(), {
        name: values.name,
        status: +values.status,
        id: params.id,
      });

      if (result.data) {
        toast.success('Role Details Updated successfully');
        navigate(RouteEnum.Roles);
      } else {
        toast.error(i18nMessages.ErrorOccurred);
      }
    } catch (error: any) {}
  };

  const handlePermissionSubmit = async (event: any) => {
    event.preventDefault();

    const apiUrl = new URL(
      environment.api.baseUrl + 'Roles/UpdateRoleModulePermission/' + params.id
    );
    try {
      const result: any = await http.put(apiUrl.toString(), rowData);
      if (result) {
        toast.success('Role Details Updated successfully');
        navigate(RouteEnum.Roles);
      }
    } catch (error: any) {}
  };

  const RoleDetails = () => {
    return (
      <>
        <Grid item lg={4}>
          <CustomizedInputs
            margin="normal"
            displayLabel={I18n('RoleList.Name')}
            name="name"
            id="name"
            inputType="text"
            handleChange={onValueChange}
            value={values.name}
            readOnly={!isRolesDetailsEdit}
          />
        </Grid>
        <Grid item lg={4}>
          {isRolesDetailsEdit ? (
            <CustomizedSelect
              placeholder={defaultValue.label}
              options={entityStatusList}
              isSearchable={false}
              value={selectedStatus}
              displayLabel={I18n('Common.Status')}
              handleChange={(newValue: BaseModel) => onStatusChange(newValue)}
            ></CustomizedSelect>
          ) : (
            <CustomizedInputs
              margin="normal"
              displayLabel={I18n('Common.Status')}
              name="status"
              id="status"
              inputType="text"
              value={selectedStatus.label}
              readOnly={!isRolesDetailsEdit}
            />
          )}
        </Grid>
      </>
    );
  };

  const getModuleData = async () => {
    const apiUrl = new URL(environment.api.baseUrl + 'Roles/GetModuleListing');
    try {
      const result: any = await http.get(apiUrl.toString());
      let checkBoxData = await getCheckboxData();

      let grid: any = [];
      result.data.forEach((element: any) => {
        grid.push({
          id: element.id,
          roleId: params.id,
          moduleId: element.id,
          name: element.name,
          addRights: false,
          deleteRights: false,
          editRights: false,
          viewRights: false,
          approverRights: false,
        });
      });

      checkBoxData.values.forEach((element: any) => {
        var data = grid.filter(function (x: any) {
          return x.id === element.moduleId;
        });

        if (data && data.length > 0) {
          data[0].addRights = element.addRights;
          data[0].deleteRights = element.deleteRights;
          data[0].editRights = element.editRights;
          data[0].viewRights = element.viewRights;
          data[0].approverRights = element.approverRights;
        }
      });

      setRowData(grid);
    } catch (error: any) {}
  };

  const handleCheckboxChange = (params: any) => {
    var data: any = rowData.filter(function (x: any) {
      return x.id === params.row.id;
    });

    if (data && data.length > 0) {
      data[0].addRights =
        params.field === 'addRights'
          ? !params.row.addRights
          : params.row.addRights;
      data[0].deleteRights =
        params.field === 'deleteRights'
          ? !params.row.deleteRights
          : params.row.deleteRights;
      data[0].editRights =
        params.field === 'editRights'
          ? !params.row.editRights
          : params.row.editRights;
      data[0].viewRights =
        params.field === 'viewRights'
          ? !params.row.viewRights
          : params.row.viewRights;
      data[0].approverRights =
        params.field === 'approverRights'
          ? !params.row.approverRights
          : params.row.approverRights;
    }

    setRowData(rowData);
  };

  const getCheckboxData = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl + 'Roles/GetRoleModulePermissions/' + params.id
    );
    try {
      const result: any = await http.get(apiUrl.toString());
      return result.data;
    } catch (error: any) {}
  };

  const [gridData, setGridData] = React.useState({
    isLoading: true,
    sortOrder: OrderByDirection.Descending,
    sortBy: 'name',
    rows: rowData,
    totalRows: 0,
    rowsPerPageOptions: [10, 20, 50, 100],
    pageSize: 10,
    page: 0,
    searchExpression: '',
  });

  type Row = (typeof gridData.rows)[number];

  const columns: GridColumns<Row> = [
    {
      field: 'name',
      headerName: I18n('Role.Module'),
      flex: 1,
    },
    {
      field: 'viewRights',
      headerName: I18n('Role.View'),
      width: 150,
      renderCell: (params) => {
        return (
          <Checkbox
            checked={Boolean(params.formattedValue)}
            onChange={(data) => handleCheckboxChange(params)}
            disabled={!ispermissionEdit}
          />
        );
      },
    },
    {
      field: 'addRights',
      headerName: I18n('Role.Add'),
      width: 150,
      renderCell: (params) => {
        return (
          <Checkbox
            checked={Boolean(params.formattedValue)}
            onChange={(data) => handleCheckboxChange(params)}
            disabled={!ispermissionEdit}
          />
        );
      },
    },
    {
      field: 'editRights',
      headerName: I18n('Role.Edit'),
      width: 150,
      renderCell: (params) => {
        return (
          <Checkbox
            checked={Boolean(params.formattedValue)}
            onChange={(data) => handleCheckboxChange(params)}
            disabled={!ispermissionEdit}
          />
        );
      },
    },
    {
      field: 'deleteRights',
      headerName: I18n('Role.Delete'),
      width: 150,
      renderCell: (params) => {
        return (
          <Checkbox
            checked={Boolean(params.formattedValue)}
            onChange={(data) => handleCheckboxChange(params)}
            disabled={!ispermissionEdit}
          />
        );
      },
    },
    {
      field: 'approverRights',
      headerName: I18n('Role.Approver'),
      width: 150,
      renderCell: (params) => {
        return (
          <Checkbox
            checked={Boolean(params.formattedValue)}
            onChange={(data) => handleCheckboxChange(params)}
            disabled={!ispermissionEdit}
          />
        );
      },
    },
  ];

  const Permissions = () => {
    return (
      <>
        <Grid item lg={12} md={8}>
          <div style={{ display: 'flex', height: '400px' }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                rows={rowData}
                columns={columns}
                paginationMode={'client'}
                loading={false}
              />
            </div>
          </div>
        </Grid>
      </>
    );
  };

  return (
    <>
      <div className="heading-section">
        <div className="heading-section-left">
          <Typography variant="h3" sx={{ mb: 3 }}>
            {I18n('Nav.Roles')}
          </Typography>
          <CustomBreadcrumbs breadcrumbs={breadcrumbs} />
        </div>
      </div>
      <Grid container spacing={2} className="grid-wrap">
        <Grid item lg={12} md={10}>
          <FormAccordian
            title={'Role details'}
            details={RoleDetails()}
            showActionButton={canEdit}
            expanded={roleDetailsExpanded}
            isEdit={isRolesDetailsEdit}
            handleEditClick={handelRolesDetailsEdit}
            handleExpandCollapseClick={handleRolesDetailsExpandCollapseClick}
            isSaveButton={isRolesDetailsEdit}
            onSaveClick={handleSubmit}
          ></FormAccordian>
        </Grid>
        <Grid item lg={12} md={10}>
          <FormAccordian
            title={'Permissions'}
            details={Permissions()}
            showActionButton={canEdit}
            expanded={permissionExpanded}
            isEdit={ispermissionEdit}
            handleEditClick={handelPermissionEdit}
            handleExpandCollapseClick={handelPermissionExpanded}
            isSaveButton={ispermissionEdit}
            onSaveClick={handlePermissionSubmit}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default EditRole;
