import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import I18n from 'utilities/i18n';
import { Grid } from '@mui/material';
import CustomSelectPaginate from 'common/CustomSelect/CustomSelectPaginate';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {
  BaseModel,
  Department,
  DepartmentSelect,
  EntityTypeEnum,
  PaginationResponseModel,
} from 'models/pagination-model';
import environment from 'environment';
import { toast } from 'react-toastify';
import http from '../../utilities/httpService';
import DecimalInput from 'common/DecimalInput/DecimalInput';
import { Customer, SpecialPricing } from 'models/service-catalogue';
import CustomizedSelect from 'common/CustomSelect/CustomSelect';
import {
  loadSpecialPriceProducts,
  loadSpecialPricingServices,
} from 'components/shipping-orders/ShippingApiService';

export default function AddSpecialPricing(props: any) {
  const {
    isOpen,
    handleClose,
    title,
    entityType,
    entityId,
    editData,
    addData,
  } = props;
  const defaultValue = {
    value: '',
    label: I18n('Placeholders.SelectCustomer'),
  } as BaseModel;

  const defaultDropDownValue = {
    value: '',
    label: I18n('Placeholders.Select'),
  };

  const [spEntityId, setSPEntityId] = useState('');

  let departmentList: Array<BaseModel> = [];
  const getDepartmentList = () => {};
  DepartmentSelect.forEach((value: string, key: number) => {
    departmentList.push({
      value: key.toString(),
      label: I18n(value),
    } as BaseModel);
  });
  getDepartmentList();

  const [values, setValues] = useState<SpecialPricing>({
    customer: defaultValue,
    shippingSellingPrice: '',
    decPackSellingPrice: '',
    pricingType: defaultDropDownValue,
    id: 0,
  });

  const i18nMessages = {
    commonFieldIsRequired: I18n('Common.FieldIsRequired'),
    somethingWentWrong: I18n('Common.SomethingWentWrong'),
    specialPricingSavedSuccess: I18n('SpecialPricing.SavedSuccessfully'),
    specialPricingUpdatedSuccess: I18n('SpecialPricing.UpdatedSuccessfully'),
    InvalidCustomerSpecialPricing: I18n(
      'Customer.Validation.InvalidCustomerSpecialPricing'
    ),
  };

  const showMessageRefresh = () => {
    toast.success(
      values.id
        ? i18nMessages.specialPricingUpdatedSuccess
        : i18nMessages.specialPricingSavedSuccess
    );
    setDefaultValuesForControls();
    props.refreshData();
  };

  const [errors, setErrors] = useState({
    customer: '',
    shippingSellingPrice: '',
    decPackSellingPrice: '',
    pricingType: '',
    entity: '',
  });

  const validate = (fieldValues: any = values) => {
    let temp = { ...errors };
    if ('customer' in fieldValues) {
      temp.customer = fieldValues.customer.value
        ? ''
        : i18nMessages.commonFieldIsRequired;
    }
    if ('pricingType' in fieldValues) {
      temp.pricingType = fieldValues.pricingType.value
        ? ''
        : i18nMessages.commonFieldIsRequired;
    }
    if (
      +fieldValues.pricingType.value == Department.Both ||
      +fieldValues.pricingType.value == Department.Shipping
    ) {
      if ('shippingSellingPrice' in fieldValues) {
        temp.shippingSellingPrice = fieldValues.shippingSellingPrice
          ? ''
          : i18nMessages.commonFieldIsRequired;
      }
    }

    if (
      +fieldValues.pricingType.value == Department.Both ||
      +fieldValues.pricingType.value == Department.DecPack
    ) {
      if ('decPackSellingPrice' in fieldValues) {
        temp.decPackSellingPrice = fieldValues.decPackSellingPrice
          ? ''
          : i18nMessages.commonFieldIsRequired;
      }
    }

    if (
      addData &&
      (entityType == EntityTypeEnum.ProductCatalogue ||
        entityType == EntityTypeEnum.ServiceCatalogue) &&
      spEntityId == ''
    ) {
      temp.entity = i18nMessages.commonFieldIsRequired;
    } else {
      temp.entity = '';
    }

    setErrors({
      ...temp,
    });
    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === '');
    }
  };

  const setDefaultValuesForControls = () => {
    values.customer.label = defaultValue.label;
    values.customer.value = defaultValue.value;
    setValues({ ...values, customer: defaultValue });
    setValues({ ...values, shippingSellingPrice: '', decPackSellingPrice: '' });

    setErrors({
      customer: '',
      shippingSellingPrice: '',
      decPackSellingPrice: '',
      pricingType: '',
      entity: '',
    });
  };
  const submit = async () => {
    if (validate()) {
      const specialPricing = {
        id: values.id,
        entityType: +entityType,
        entityId: addData ? +spEntityId : +entityId,
        shippingSellingPrice: +values.shippingSellingPrice.replace(/,/g, '.'),
        decPackSellingPrice: +values.decPackSellingPrice.replace(/,/g, '.'),
        pricingType: +values.pricingType.value,
        customerId:
          values && values?.customer && values?.customer.value
            ? +values.customer.value
            : 0,
      };
      const apiUrl =
        editData === null
          ? new URL(environment.api.baseUrl + 'SpecialPricing')
          : new URL(environment.api.baseUrl + 'SpecialPricing/' + values.id);

      try {
        const result: any =
          editData === null
            ? await http.post(apiUrl.toString(), specialPricing)
            : await http.put(apiUrl.toString(), specialPricing);

        if (
          editData == null &&
          result !== null &&
          result.data != null &&
          result.data.success
        ) {
          showMessageRefresh();
        } else if (editData != null && result !== null && result.data) {
          showMessageRefresh();
        } else {
          toast.error(i18nMessages.somethingWentWrong);
        }
      } catch (error: any) {
        if (error.response) {
          let temp = { ...errors };
          if (error.response.data) {
            for (const item of error.response.data.errors) {
              if (
                item.field === 'CustomerName' &&
                item.errorMessage ===
                  'UniqueSpecialPricing,UniqueSpecialPricing'
              ) {
                toast.error(i18nMessages.InvalidCustomerSpecialPricing);
              }
            }
          }
        }
      }
    }
  };

  const close = () => {
    setDefaultValuesForControls();
    props.handleClose();
  };

  useEffect(() => {
    if (editData) {
      setValues({
        shippingSellingPrice: editData.row.shippingSellingPrice
          .toString()
          .replaceAll('.', ','),
        decPackSellingPrice: editData.row.decPackSellingPrice
          .toString()
          .replaceAll('.', ','),
        pricingType:
          editData.row.pricingType > 0
            ? ({
                label: editData.row.pricingTypeDescription,
                value: editData.row.pricingType.toString(),
              } as BaseModel)
            : defaultDropDownValue,
        customer: {
          label: editData.row.customerName,
          value: editData.row.customerId.toString(),
        } as BaseModel,
        id: editData.row.id,
      });
    } else if (addData) {
      setValues({ ...values, customer: addData });
    } else {
      setValues({
        customer: defaultValue,
        shippingSellingPrice: '',
        decPackSellingPrice: '',
        pricingType: defaultDropDownValue,
        id: 0,
      });
    }
  }, [editData, addData]);

  const defaultAdditional: any = {
    page: 1,
  };

  const getCustomers = async (search: string, pageNo: number) => {
    let response: PaginationResponseModel<Customer>;
    const apiUrl = new URL(
      environment.api.baseUrl +
        'Customer/GetSpecialPricingSpecificCustomerDropdownData'
    );
    try {
      let searchExpression = '';
      searchExpression += search ? `name~like~${search}` : '';
      apiUrl.searchParams.set('$filter', searchExpression);
      apiUrl.searchParams.set('$orderby', 'name');
      apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
      apiUrl.searchParams.set('$top', '10');
      apiUrl.searchParams.set('$orderbydirection', 'desc');
      apiUrl.searchParams.set('entityId', entityId);
      apiUrl.searchParams.set('entityType', entityType);
      const result = await http.get(apiUrl.toString());
      if (result) {
        response = {
          TotalRecords: result.data.totalCount,
          Values: result.data.values,
        } as PaginationResponseModel<Customer>;
      } else {
        response = {
          TotalRecords: 0,
          Values: [],
        } as PaginationResponseModel<Customer>;
      }
    } catch (error) {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<Customer>;
    }
    return response;
  };

  const loadCustomerOptions = async (
    search: string,
    loadedOptions: any,
    { page }: any
  ) => {
    const response = await getCustomers(search, page);
    const hasMore = Math.ceil(response.TotalRecords / 10) > page;

    return {
      options: response.Values.map((x) => {
        return {
          value: x.id.toString(),
          label: x.name,
        } as BaseModel;
      }),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={'sm'}
        fullWidth={true}
      >
        <DialogTitle>
          {title}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className="grid-wrap two-col">
            <Grid item xs={12}>
              <CustomSelectPaginate
                required
                additional={defaultAdditional}
                value={values.customer}
                loadOptions={loadCustomerOptions}
                handleChange={(newValue: any) =>
                  setValues({ ...values, customer: newValue })
                }
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                }}
                placeholder={defaultValue.label}
                isSearchable={true}
                displayLabel={I18n('SpecialPricing.Customer')}
                errorValue={true}
                errorMessage={errors.customer}
                readOnly={editData != null || addData != undefined}
                debounceTimeout={500}
                isMultiSelect={false}
              />
            </Grid>
            <Grid item xs={12}>
              {addData && entityType == EntityTypeEnum.ProductCatalogue ? (
                <CustomSelectPaginate
                  required
                  additional={defaultAdditional}
                  displayLabel={I18n('ShippingMaterials.ProductCatalogue')}
                  loadOptions={(search: string, prevOptions: any, page: any) =>
                    loadSpecialPriceProducts(search, prevOptions, page)
                  }
                  handleChange={(newValue: any) =>
                    setSPEntityId(newValue.value)
                  }
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                  }}
                  placeholder={defaultDropDownValue.label}
                  isSearchable={true}
                  errorValue={true}
                  errorMessage={errors.entity}
                  debounceTimeout={500}
                  isMultiSelect={false}
                />
              ) : (
                <></>
              )}
              {addData && entityType == EntityTypeEnum.ServiceCatalogue ? (
                <CustomSelectPaginate
                  required
                  additional={defaultAdditional}
                  displayLabel={I18n('ShippingMaterials.ServiceCatalogue')}
                  loadOptions={(search: string, prevOptions: any, page: any) =>
                    loadSpecialPricingServices(search, prevOptions, page)
                  }
                  handleChange={(newValue: any) =>
                    setSPEntityId(newValue.value)
                  }
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                  }}
                  placeholder={defaultDropDownValue.label}
                  isSearchable={true}
                  errorValue={true}
                  errorMessage={errors.entity}
                  debounceTimeout={500}
                  isMultiSelect={false}
                />
              ) : (
                <></>
              )}
            </Grid>
            <Grid item xs={12}>
              <CustomizedSelect
                required
                placeholder={defaultValue.label}
                options={departmentList}
                isSearchable={false}
                displayLabel={I18n('SpecialPricing.PricingType')}
                errorValue={errors.pricingType}
                errorMessage={errors.pricingType}
                handleChange={(newValue: BaseModel) =>
                  +newValue.value == Department.Both ||
                  +newValue.value == Department.Shipping
                    ? setValues({
                        ...values,
                        pricingType: newValue,
                        decPackSellingPrice: '0',
                      })
                    : +newValue.value == Department.Both ||
                      +newValue.value == Department.DecPack
                    ? setValues({
                        ...values,
                        pricingType: newValue,
                        shippingSellingPrice: '0',
                      })
                    : setValues({
                        ...values,
                        pricingType: newValue,
                      })
                }
                value={values.pricingType}
              ></CustomizedSelect>
            </Grid>
            {+values.pricingType.value == Department.Both ||
            +values.pricingType.value == Department.Shipping ? (
              <Grid item xs={12}>
                <DecimalInput
                  required
                  displayLabel={I18n('SpecialPricing.ShippingSellingPrice')}
                  inputProps={{ maxLength: 10 }}
                  name="shippingSellingPrice"
                  handleChange={(event: any) => {
                    setValues({
                      ...values,
                      shippingSellingPrice: event.target.value,
                    });
                  }}
                  errorValue={true}
                  errorMessage={errors.shippingSellingPrice}
                  value={values.shippingSellingPrice}
                ></DecimalInput>
              </Grid>
            ) : (
              <></>
            )}
            {+values.pricingType.value == Department.Both ||
            +values.pricingType.value == Department.DecPack ? (
              <Grid item xs={12}>
                <DecimalInput
                  required
                  displayLabel={I18n('SpecialPricing.DecPackSellingPrice')}
                  inputProps={{ maxLength: 10 }}
                  name="decPackSellingPrice"
                  handleChange={(event: any) => {
                    setValues({
                      ...values,
                      decPackSellingPrice: event.target.value,
                    });
                  }}
                  errorValue={true}
                  errorMessage={errors.decPackSellingPrice}
                  value={values.decPackSellingPrice}
                ></DecimalInput>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </DialogContent>
        <DialogActions className="btn-wrap">
          <Button
            onClick={close}
            color="neutral"
            disableElevation
            variant="contained"
          >
            {' '}
            {I18n('Notes.Cancel')}
          </Button>
          <Button
            onClick={submit}
            autoFocus
            color="primary"
            disableElevation
            variant="contained"
          >
            {I18n('Notes.Save')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
