import I18n from 'utilities/i18n';
import { useEffect, useState } from 'react';
import http from '../../utilities/httpService';
import environment from 'environment';
import { useParams } from 'react-router-dom';
import CustomizedSelect, {
  CustomizedSelect as StatusSelect,
} from 'common/CustomSelect/CustomSelect';
import { CustomizedSelect as PricingTypeSelect } from 'common/CustomSelect/CustomSelect';
import DimentionsInputs from 'common/formControl/formDimentions';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import {
  BaseModel,
  EntityStatusDescription,
  PricingTypeDescription,
  PaginationResponseModel,
  MasterCategoryEnumType,
  PricingType,
  DepartmentSelect,
} from 'models/pagination-model';
import FormControl from 'common/formControl/formControl';
import DecimalInput from 'common/DecimalInput/DecimalInput';
import { Customer } from 'models/service-catalogue';
import { ProductCatalogueStateModel } from 'models/product-catalogue';
import FormAccordian from 'common/formControl/formAccordian';
import { toast } from 'react-toastify';
import CustomSelectPaginateAdd from 'common/CustomSelect/CustomSelectPaginateAdd';
import {
  PostCatagoryDropDown,
  loadItemGroupOptions,
  loadLedgerOptions,
} from 'common/DropDownAddAPI/DropDownApiService';
import CustomSelectPaginate from 'common/CustomSelect/CustomSelectPaginate';
import { hasPermission } from 'utilities/protectedRoute';
import { Permission } from 'Permissions';

function ProductCatalogueDetails(props: any) {
  const { onSaveDetails } = props;
  const defaultDropdownValue = {
    value: '0',
    label: I18n('Placeholders.Select'),
  } as BaseModel;
  const defaultValue = {
    value: '0',
    label: I18n('Placeholders.SelectStatus'),
  } as BaseModel;
  const defaultCategoryValue = {
    value: '0',
    label: I18n('Placeholders.SelectCategory'),
  } as BaseModel;
  const defaultDepartmentValue = {
    value: '0',
    label: I18n('Placeholders.SelectDepartment'),
  } as BaseModel;

  const [canEdit, setCanEdit] = useState(
    hasPermission(Permission.canEditProductCatalogueManagement)
  );

  let pricingTypeList: Array<BaseModel> = [];
  const getPricingTypeList = () => {
    pricingTypeList = [];
    PricingTypeDescription.forEach((value: string, key: number) => {
      pricingTypeList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getPricingTypeList();
  const { entityId } = useParams();
  const [cacheUniq, setCacheUniq] = useState(0);
  const [expanded, setExpanded] = useState<boolean>(true);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [statusExpanded, setStatusExpanded] = useState<boolean>(true);
  const [isStatusEdit, setIsStatusEdit] = useState<boolean>(false);

  const [ledgerInfoExpanded, setLedgerInfoExpanded] = useState<boolean>(true);
  const [isLedgerInfoEdit, setIsLedgerInfoEdit] = useState<boolean>(false);

  const [pricingExpanded, setPricingExpanded] = useState<boolean>(true);
  const [isPricingEdit, setIsPricingEdit] = useState<boolean>(false);

  //   const [serviceCode, setServiceCode] = useState('');
  const [productCode, setProductCode] = useState('');
  const [category, setCategory] = useState<BaseModel>(defaultCategoryValue);
  const [shortDescription, setDescription] = useState('');
  const [weight, setWeight] = useState('');
  const [name, setName] = useState('');
  const [shippingStandardPrice, setShippingStandardPrice] = useState('');
  const [decPackStandardPrice, setDecPackStandardPrice] = useState('');
  const [lastCostBuyPrice, setLastCostBuyPrice] = useState('');
  const [lastPurchasedFrom, setLastPurchasedFrom] = useState('');
  const [dimension1, setdimension1] = useState('');
  const [dimension2, setdimension2] = useState('');
  const [dimension3, setdimension3] = useState('');
  const [warehouseQuantity, setInWarehouse] = useState('');
  const [thresholdLimit, setThresholdLimit] = useState('');
  const [status, setStatus] = useState<BaseModel>(defaultValue);
  const [pricingType, setPricingType] = useState<BaseModel>(pricingTypeList[0]);
  const [isDryIce, setIsDryIce] = useState(false);
  const [ledgerAccount, setLedgerAccount] = useState(defaultDropdownValue);
  const [itemGroup, setItemGroup] = useState(defaultDropdownValue);
  const [showInDPBOM, setShowInDPBOM] = useState(false);
  const [department, setDepartment] = useState<BaseModel>(
    defaultDepartmentValue
  );

  const i18nMessages = {
    updateproductCatalogueSuccess: I18n('ProductCatalogues.UpdateSuccess'),
    productCataloguesDetailsTitle: I18n('ProductCatalogues.Details'),
    accordionPricing: I18n('ProductCatalogues.Pricing'),
    accordionStatus: I18n('Common.Status'),
    commonFieldIsRequired: I18n('Common.FieldIsRequired'),
    uniqueProductCode: I18n('ProductCatalogues.UniqueProductCode'),
    uniqueProductName: I18n('ProductCatalogues.UniqueProductName'),
    zeroValidation: I18n('Common.ZeroValidation'),
    CommonSaveSuccessMsg: I18n('Common.SavedSuccessfully'),
    CommonErrOccuredMsg: I18n('Common.ErrorOccurred'),
    LedgerTitle: I18n('LedgerInfo.Title'),
    LedgerAccountDescription: I18n('LedgerInfo.LedgerAccountDescription'),
    LedgerAccount: I18n('LedgerInfo.LedgerAccount'),
    ItemGroup: I18n('ItemGroupInfo.ItemGroup'),
    ShowInDPBOM: I18n('ProductCatalogues.ShowInDPBOM'),
  };
  const defaultAdditional: any = {
    page: 1,
  };
  const [errors, setErrors] = useState({
    productCode: '',
    category: '',
    shortDescription: '',
    name: '',
    shippingStandardPrice: '',
    decPackStandardPrice: '',
    lastCostBuyPrice: '',
    lastPurchasedFrom: '',
    statusId: '',
    pricingType: '',
    dimensions: '',
    warehouseQuantity: '',
    thresholdLimit: '',
    ledgerAccount: '',
    itemGroups: '',
    department: '',
  });

  const validate = () => {
    let temp = { ...errors };
    temp.name = name ? '' : i18nMessages.commonFieldIsRequired;
    temp.category =
      category.value !== defaultCategoryValue.value
        ? ''
        : i18nMessages.commonFieldIsRequired;
    temp.productCode = productCode ? '' : i18nMessages.commonFieldIsRequired;
    temp.shippingStandardPrice = shippingStandardPrice
      ? ''
      : i18nMessages.commonFieldIsRequired;
    temp.shippingStandardPrice =
      !shippingStandardPrice || +shippingStandardPrice === 0
        ? i18nMessages.zeroValidation
        : '';
    temp.decPackStandardPrice = decPackStandardPrice
      ? ''
      : i18nMessages.commonFieldIsRequired;
    temp.decPackStandardPrice =
      !decPackStandardPrice || +decPackStandardPrice === 0
        ? i18nMessages.zeroValidation
        : '';
    temp.lastCostBuyPrice = lastCostBuyPrice
      ? ''
      : i18nMessages.commonFieldIsRequired;
    temp.statusId =
      status.value !== defaultValue.value
        ? ''
        : i18nMessages.commonFieldIsRequired;
    temp.shortDescription = shortDescription
      ? ''
      : i18nMessages.commonFieldIsRequired;
    temp.ledgerAccount =
      ledgerAccount.value == defaultDropdownValue.value
        ? i18nMessages.commonFieldIsRequired
        : '';
    temp.itemGroups =
      itemGroup.value == defaultDropdownValue.value
        ? i18nMessages.commonFieldIsRequired
        : '';
    temp.department =
      department.value == defaultDepartmentValue.value
        ? i18nMessages.commonFieldIsRequired
        : '';
    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === '');
  };

  const handleEditClick = (event: React.SyntheticEvent) => {
    setIsEdit(!isEdit);
  };
  const handleExpandCollapseClick = (event: React.SyntheticEvent) => {
    setExpanded(!expanded);
  };

  const handleStatusEditClick = (event: React.SyntheticEvent) => {
    setIsStatusEdit(!isStatusEdit);
  };
  const handleStatusExpandCollapseClick = (event: React.SyntheticEvent) => {
    setStatusExpanded(!statusExpanded);
  };

  const handlePricingEditClick = (event: React.SyntheticEvent) => {
    setIsPricingEdit(!isPricingEdit);
  };
  const handlePricingExpandCollapseClick = (event: React.SyntheticEvent) => {
    setPricingExpanded(!pricingExpanded);
  };

  const handleLedgerInfoEditClick = (event: React.SyntheticEvent) => {
    setIsLedgerInfoEdit(!isLedgerInfoEdit);
  };
  const handleLedgerInfoExpandCollapseClick = (event: React.SyntheticEvent) => {
    setLedgerInfoExpanded(!ledgerInfoExpanded);
  };

  const onStatusChange = (data: BaseModel) => {
    setStatus(data);
  };
  const onPricingTypeChange = (data: BaseModel) => {
    setPricingType(data);
  };

  const getOrderCategories = async (search: string, pageNo: number) => {
    let response: PaginationResponseModel<Customer>;
    const apiUrl = new URL(
      environment.api.baseUrl + 'Dropdown/GetMasterCategories'
    );
    try {
      let searchExpression = `entityType~=~${MasterCategoryEnumType.ProductCatalogue}`;
      searchExpression += search ? `name~like~${search}` : '';
      apiUrl.searchParams.set('$filter', searchExpression);
      apiUrl.searchParams.set('$orderby', 'name');
      apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
      apiUrl.searchParams.set('$top', '10');
      apiUrl.searchParams.set('$orderbydirection', 'desc');
      const result = await http.get(apiUrl.toString());
      if (result) {
        response = {
          TotalRecords: result.data.totalCount,
          Values: result.data.values,
        } as PaginationResponseModel<Customer>;
      } else {
        response = {
          TotalRecords: 0,
          Values: [],
        } as PaginationResponseModel<Customer>;
      }
    } catch (error) {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<Customer>;
    }
    return response;
  };

  const loadCategoryOptions = async (
    search: string,
    loadedOptions: any,
    { page }: any
  ) => {
    const response = await getOrderCategories(search, page);
    const hasMore = Math.ceil(response.TotalRecords / 10) > page;

    return {
      options: response.Values.map((x) => {
        return {
          value: x.id.toString(),
          label: x.name,
        } as BaseModel;
      }),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const handleSaveClick = () => {
    if (validate()) {
      saveProductCatalogue({
        id: entityId ? Number(entityId) : 0,
        productCode,
        category,
        productCatalogueCategoryId: Number(category.value),
        productCatalogueCategoryName: category.label ? category.label : '',
        shortDescription,
        name,
        shippingStandardPrice: shippingStandardPrice.replace(/,/g, '.'),
        decPackStandardPrice: decPackStandardPrice.replace(/,/g, '.'),
        lastCostBuyPrice: lastCostBuyPrice.replace(/,/g, '.'),
        lastPurchasedFrom,
        status: Number(status.value),
        pricingType: Number(pricingType.value),
        // productcatalogueType,
        weight: Number(weight),
        thresholdLimit: Number(thresholdLimit),
        warehouseQuantity: Number(warehouseQuantity),
        dimension1: Number(dimension1),
        dimension2: Number(dimension2),
        dimension3: Number(dimension3),
        departmentType: Number(department.value),
        ledgerAccount,
        itemGroup,
        showInDPBOM,
        department,
      } as ProductCatalogueStateModel);
    }
  };
  let entityStatusList: Array<BaseModel> = [];
  const getStatusList = () => {
    entityStatusList = [defaultValue];
    EntityStatusDescription.forEach((value: string, key: number) => {
      entityStatusList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getStatusList();

  let departmentList: Array<BaseModel> = [];
  const getDepartmentList = () => {};
  DepartmentSelect.forEach((value: string, key: number) => {
    departmentList.push({
      value: key.toString(),
      label: I18n(value),
    } as BaseModel);
  });
  getDepartmentList();

  const getProductCatalogueById = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl + `ProductCatalogues/${entityId}`
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result && result.data) {
        setProductCode(result.data.productCode);
        setName(result.data.name);
        setCategory({
          label: result.data.productCatalogueCategoryName,
          value: result.data.productCatalogueCategoryId,
        } as BaseModel);
        setDescription(result.data.shortDescription);
        setdimension1(result.data.dimension1);
        setdimension2(result.data.dimension2);
        setdimension3(result.data.dimension3);
        setIsDryIce(result.data.isDryIce);
        setThresholdLimit(result.data.thresholdLimit);
        setWeight(result.data.weight);
        setInWarehouse(result.data.warehouseQuantity);
        setShippingStandardPrice(
          result.data.shippingStandardPrice.toString().replaceAll('.', ',')
        );
        setDecPackStandardPrice(
          result.data.decPackStandardPrice.toString().replaceAll('.', ',')
        );
        setLastCostBuyPrice(
          result.data.lastCostBuyPrice.toString().replaceAll('.', ',')
        );
        setLastPurchasedFrom(result.data.lastPurchasedFrom);
        setStatus({
          label: EntityStatusDescription.get(result.data.status) || '',
          value: Number(result.data.status).toString(),
        } as BaseModel);
        var departmentLabel = departmentList.find(
          (x) => x.value == result.data.departmentType
        )?.label;
        setDepartment({
          label: departmentLabel || '',
          value: Number(result.data.departmentType).toString(),
        } as BaseModel);
        setPricingType({
          label: EntityStatusDescription.get(result.data.pricingType) || '',
          value: Number(result.data.pricingType).toString(),
        } as BaseModel);
        setLedgerAccount({
          label: result.data.ledgerAccountCode,
          value: result.data.ledgerAccountId.toString(),
        });
        setItemGroup({
          label: result.data.itemGroupCode,
          value: result.data.itemGroupId.toString(),
        });
        setShowInDPBOM(result.data.showInDPBOM);
      }
    } catch (error) {}
  };
  const saveProductCatalogue = async (
    productCatalogue: ProductCatalogueStateModel
  ) => {
    const apiUrl = new URL(
      environment.api.baseUrl + 'ProductCatalogues/' + entityId
    );
    try {
      var finalObject: any = productCatalogue;
      finalObject.ledgerAccountId = +productCatalogue.ledgerAccount.value;
      finalObject.itemGroupId = +productCatalogue.itemGroup.value;
      const result: any = await http.put(apiUrl.toString(), productCatalogue);
      if (result) {
        toast.success(i18nMessages.updateproductCatalogueSuccess);
        window.location.reload();
        if (productCatalogue.pricingType === PricingType.Special) {
          onSaveDetails(true);
        }
      }
    } catch (error: any) {
      if (error.response) {
        let temp = { ...errors };
        if (error.response.data) {
          for (const item of error.response.data.errors) {
            if (
              item.field === 'ProductCode' &&
              item.errorMessage === 'UniqueProductCode'
            ) {
              temp.productCode = i18nMessages.uniqueProductCode;
            }
            if (
              item.field === 'Name' &&
              item.errorMessage === 'UniqueProductName'
            ) {
              temp.name = i18nMessages.uniqueProductName;
            }
          }
        }
        setErrors({
          ...temp,
        });
      }
    }
  };
  const onCreateOption = async (inputValue: any) => {
    let name = {};
    name['name'] = inputValue;
    name['entityType'] = MasterCategoryEnumType.ProductCatalogue;
    const newOption: any = await PostCatagoryDropDown(
      name,
      i18nMessages.CommonSaveSuccessMsg,
      i18nMessages.CommonErrOccuredMsg
    );
    const increaseUniq = (uniq: any) => uniq + 1;
    setCacheUniq(increaseUniq);
    setCategory(newOption);
    getOrderCategories('', 1);
  };
  const ProductCatalogueData = () => {
    return (
      <>
        <Grid item lg={4} md={8}>
          <FormControl
            margin="normal"
            displayLabel={I18n('ProductCatalogues.ProductCode')}
            id="ProductCode"
            name="productCode"
            inputType="text"
            inputProps={{ maxLength: 10 }}
            multiline={false}
            handleChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setProductCode(event.target.value)}
            errorValue={true}
            value={productCode}
            errorMessage={errors.productCode}
            readOnly={!isEdit}
          ></FormControl>
        </Grid>
        <Grid item lg={4} md={8}>
          <FormControl
            margin="normal"
            displayLabel={I18n('ProductCatalogues.ProductName')}
            id="ProductName"
            name="name"
            inputType="text"
            inputProps={{ maxLength: 30 }}
            handleChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setName(event.target.value)}
            multiline={false}
            errorValue={true}
            errorMessage={errors.name}
            value={name}
            readOnly={!isEdit || isDryIce}
          ></FormControl>
        </Grid>
        <Grid item lg={4} md={8}>
          <FormControl
            margin="normal"
            displayLabel={I18n('ProductCatalogues.Description')}
            id="Description"
            inputType="text"
            inputProps={{ maxLength: 50 }}
            multiline={true}
            handleChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setDescription(event.target.value)}
            value={shortDescription}
            readOnly={!isEdit}
            errorValue={true}
            errorMessage={errors.shortDescription}
          ></FormControl>
        </Grid>
        <Grid item lg={4} md={8}>
          <CustomSelectPaginateAdd
            key={JSON.stringify(category)}
            additional={defaultAdditional}
            value={category}
            loadOptions={loadCategoryOptions}
            isSearchable={true}
            displayLabel={I18n('ProductCatalogues.Category')}
            placeholder={defaultCategoryValue.label}
            handleChange={(newValue: BaseModel) => {
              setCategory(newValue);
            }}
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
            }}
            debounceTimeout={500}
            isMultiSelect={false}
            cacheUniqs={cacheUniq}
            onCreateOption={onCreateOption}
            errorValue={true}
            errorMessage={errors.category}
            readOnly={!isEdit}
          ></CustomSelectPaginateAdd>
        </Grid>

        <Grid item lg={4} md={8}>
          {isEdit ? (
            <DimentionsInputs
              margin="normal"
              displayLabel={I18n('ProductCatalogues.Dimentions')}
              id="dimensions"
              name="dimensions"
              inputType="text"
              value1={dimension1}
              value2={dimension2}
              value3={dimension3}
              handleChange1={(event: any) => {
                setdimension1(event.target.value);
              }}
              handleChange2={(event: any) => {
                setdimension2(event.target.value);
              }}
              handleChange3={(event: any) => {
                setdimension3(event.target.value);
              }}
              inputProps={{ maxLength: 30 }}
              errorValue={true}
              errorMessage={errors.dimensions}
            ></DimentionsInputs>
          ) : (
            <FormControl
              margin="normal"
              displayLabel={I18n('ProductCatalogues.Dimentions')}
              id="dimensions"
              name="dimensions"
              inputType="text"
              inputProps={{ maxLength: 30 }}
              multiline={false}
              value={dimension1 + ' X ' + dimension2 + ' X ' + dimension3}
              readOnly={!isEdit}
            ></FormControl>
          )}
        </Grid>

        <Grid item lg={4} md={8}>
          <DecimalInput
            displayLabel={I18n('ProductCatalogues.Weight')}
            inputProps={{ maxLength: 10 }}
            name="weight"
            handleChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setWeight(event.target.value)}
            value={weight}
            readOnly={!isEdit}
          ></DecimalInput>
        </Grid>

        <Grid item lg={4} md={8}>
          <CustomizedSelect
            placeholder={department.label}
            options={departmentList}
            value={department}
            isSearchable={false}
            displayLabel={I18n('ProductCatalogues.Department')}
            errorValue={errors.department}
            errorMessage={errors.department}
            handleChange={(newValue: BaseModel) => setDepartment(newValue)}
            readOnly={!isEdit}
          ></CustomizedSelect>
        </Grid>
      </>
    );
  };
  const PricingData = () => {
    return (
      <>
        <Grid item lg={4} md={8}>
          <PricingTypeSelect
            defaultValue={pricingTypeList[0]}
            options={pricingTypeList}
            isSearchable={false}
            displayLabel={I18n('ProductCatalogues.PricingType')}
            handleChange={(newValue: BaseModel) =>
              onPricingTypeChange(newValue)
            }
            readOnly={!isPricingEdit}
            value={pricingTypeList.find((x) => x.value === pricingType.value)}
          ></PricingTypeSelect>
        </Grid>
        <Grid item lg={4} md={8}>
          {!isPricingEdit ? (
            <FormControl
              margin="normal"
              displayLabel={I18n('ProductCatalogues.ShippingStandardPrice')}
              id="shippingStandardPrice"
              name="shippingStandardPrice"
              inputType="text"
              inputProps={{ maxLength: 10 }}
              multiline={false}
              value={shippingStandardPrice}
              readOnly={true}
              required={true}
            ></FormControl>
          ) : (
            <DecimalInput
              displayLabel={I18n('ProductCatalogues.ShippingStandardPrice')}
              inputProps={{ maxLength: 10 }}
              handleChange={(event: any) => {
                setShippingStandardPrice(event.target.value);
              }}
              value={shippingStandardPrice}
              readOnly={!isPricingEdit}
              errorValue={true}
              errorMessage={errors.shippingStandardPrice}
              required={true}
            ></DecimalInput>
          )}
        </Grid>
        <Grid item lg={4} md={8}>
          {!isPricingEdit ? (
            <FormControl
              margin="normal"
              displayLabel={I18n('ProductCatalogues.DecPackStandardPrice')}
              id="decPackStandardPrice"
              name="decPackStandardPrice"
              inputType="text"
              inputProps={{ maxLength: 10 }}
              multiline={false}
              value={decPackStandardPrice}
              readOnly={true}
              required={true}
            ></FormControl>
          ) : (
            <DecimalInput
              displayLabel={I18n('ProductCatalogues.DecPackStandardPrice')}
              inputProps={{ maxLength: 10 }}
              handleChange={(event: any) => {
                setDecPackStandardPrice(event.target.value);
              }}
              value={decPackStandardPrice}
              readOnly={!isPricingEdit}
              errorValue={true}
              errorMessage={errors.decPackStandardPrice}
              required={true}
            ></DecimalInput>
          )}
        </Grid>
        <Grid item lg={4} md={8}>
          {!isPricingEdit ? (
            <FormControl
              margin="normal"
              displayLabel={I18n('ProductCatalogues.LastCostBuyPrice')}
              id="lastCostBuyPrice"
              name="lastCostBuyPrice"
              inputType="text"
              inputProps={{ maxLength: 10 }}
              multiline={false}
              value={lastCostBuyPrice}
              readOnly={true}
            ></FormControl>
          ) : (
            <DecimalInput
              displayLabel={I18n('ProductCatalogues.LastCostBuyPrice')}
              inputProps={{ maxLength: 10 }}
              handleChange={(event: any) => {
                setLastCostBuyPrice(event.target.value);
              }}
              value={lastCostBuyPrice}
              readOnly={!isPricingEdit}
              errorValue={true}
              errorMessage={errors.lastCostBuyPrice}
            ></DecimalInput>
          )}
        </Grid>
        <Grid item lg={4} md={8}>
          <FormControl
            margin="normal"
            displayLabel={I18n('ProductCatalogues.LastPurchasedFrom')}
            id="lastPurchasedFrom"
            inputType="text"
            inputProps={{ maxLength: 50 }}
            multiline={true}
            handleChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setLastPurchasedFrom(event.target.value)}
            value={lastPurchasedFrom}
            readOnly={!isPricingEdit}
            errorValue={true}
            errorMessage={errors.lastPurchasedFrom}
          ></FormControl>
        </Grid>
        <Grid item lg={4} md={8}>
          <FormControl
            margin="normal"
            displayLabel={I18n('ProductCatalogues.InWarehouse')}
            id="warehouseQuantity"
            name="warehouseQuantity"
            inputType="text"
            multiline={false}
            value={warehouseQuantity}
            readOnly={true}
          ></FormControl>
        </Grid>
        <Grid item lg={4} md={8}>
          {!isPricingEdit ? (
            <FormControl
              margin="normal"
              displayLabel={I18n('ProductCatalogues.ThresholdLimit')}
              id="thresholdLimit"
              name="thresholdLimit"
              inputType="text"
              multiline={false}
              value={thresholdLimit}
              readOnly={true}
            ></FormControl>
          ) : (
            <DecimalInput
              displayLabel={I18n('ProductCatalogues.ThresholdLimit')}
              inputProps={{ maxLength: 10 }}
              name="thresholdLimit"
              handleChange={(event: any) => {
                setThresholdLimit(event.target.value);
              }}
              value={thresholdLimit}
              readOnly={!isPricingEdit}
            ></DecimalInput>
          )}
        </Grid>
        <Grid item lg={4} md={8}>
          <FormControlLabel
            control={
              <Checkbox
                name="showInDPBOM"
                onChange={(event: any) => {
                  setShowInDPBOM(event.target.checked);
                }}
                checked={showInDPBOM}
                inputProps={{ 'aria-label': 'controlled' }}
                disabled={!isPricingEdit}
              />
            }
            label={i18nMessages.ShowInDPBOM}
          />
        </Grid>
      </>
    );
  };

  const StatusData = () => {
    return (
      <>
        <Grid item lg={12} md={12}>
          <StatusSelect
            placeholder={defaultValue.label}
            options={entityStatusList}
            isSearchable={false}
            displayLabel={I18n('Common.Status')}
            handleChange={(newValue: BaseModel) => onStatusChange(newValue)}
            readOnly={!isStatusEdit}
            value={entityStatusList.find((x) => x.value === status.value)}
            errorValue={true}
            errorMessage={errors.statusId}
          ></StatusSelect>
        </Grid>
      </>
    );
  };

  const LedgerInfo = () => {
    return (
      <>
        <Grid item lg={12} md={12}>
          <CustomSelectPaginate
            required
            readOnly={!isLedgerInfoEdit}
            key={+ledgerAccount.value}
            additional={defaultAdditional}
            value={ledgerAccount}
            loadOptions={(search: string, prevOptions: any, page: any) =>
              loadLedgerOptions(search, prevOptions, page)
            }
            handleChange={async (newValue: any) => {
              setLedgerAccount(newValue);
            }}
            placeholder={defaultValue.label}
            isSearchable={true}
            displayLabel={i18nMessages.LedgerAccount}
            errorValue={true}
            errorMessage={errors.ledgerAccount}
            debounceTimeout={500}
            isMultiSelect={false}
          />
        </Grid>
        <Grid item lg={12} md={12}>
          <CustomSelectPaginate
            required
            readOnly={!isLedgerInfoEdit}
            key={+itemGroup.value}
            additional={defaultAdditional}
            value={itemGroup}
            loadOptions={(search: string, prevOptions: any, page: any) =>
              loadItemGroupOptions(search, prevOptions, page)
            }
            handleChange={async (newValue: any) => {
              setItemGroup(newValue);
            }}
            placeholder={defaultValue.label}
            isSearchable={true}
            displayLabel={i18nMessages.ItemGroup}
            errorValue={true}
            errorMessage={errors.itemGroups}
            debounceTimeout={500}
            isMultiSelect={false}
          />
        </Grid>
      </>
    );
  };

  useEffect(() => {
    getProductCatalogueById();
  }, []);
  return (
    <div>
      <Grid container spacing={2} className="grid-wrap">
        <Grid item lg={12} md={12}>
          <FormAccordian
            title={i18nMessages.productCataloguesDetailsTitle}
            className="inner-heading"
            details={ProductCatalogueData()}
            showActionButton={canEdit}
            expanded={expanded}
            isEdit={isEdit}
            handleEditClick={handleEditClick}
            handleExpandCollapseClick={handleExpandCollapseClick}
            isSaveButton={isEdit}
            onSaveClick={handleSaveClick}
          ></FormAccordian>
        </Grid>
        <Grid item lg={12} md={12}>
          <FormAccordian
            title={i18nMessages.accordionPricing}
            className="inner-heading"
            details={PricingData()}
            showActionButton={canEdit}
            expanded={pricingExpanded}
            isEdit={isPricingEdit}
            handleEditClick={handlePricingEditClick}
            handleExpandCollapseClick={handlePricingExpandCollapseClick}
            isSaveButton={isPricingEdit}
            onSaveClick={handleSaveClick}
          ></FormAccordian>
        </Grid>
        <Grid item lg={4} md={4}>
          <FormAccordian
            title={i18nMessages.LedgerTitle}
            className="inner-heading"
            details={LedgerInfo()}
            showActionButton={canEdit}
            expanded={ledgerInfoExpanded}
            isEdit={isLedgerInfoEdit}
            handleEditClick={handleLedgerInfoEditClick}
            handleExpandCollapseClick={handleLedgerInfoExpandCollapseClick}
            isSaveButton={isLedgerInfoEdit}
            onSaveClick={handleSaveClick}
          ></FormAccordian>
        </Grid>
        <Grid item lg={4} md={4}>
          <FormAccordian
            title={i18nMessages.accordionStatus}
            className="inner-heading"
            details={StatusData()}
            showActionButton={canEdit}
            expanded={statusExpanded}
            isEdit={isStatusEdit}
            handleEditClick={handleStatusEditClick}
            handleExpandCollapseClick={handleStatusExpandCollapseClick}
            isSaveButton={isStatusEdit}
            onSaveClick={handleSaveClick}
          ></FormAccordian>
        </Grid>
      </Grid>
    </div>
  );
}

export default ProductCatalogueDetails;
