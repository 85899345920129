import {
  Box,
  Breadcrumbs,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Link,
  Typography,
} from '@mui/material';
import CustomizedSelect from 'common/CustomSelect/CustomSelect';
import CustomDatePicker from 'common/dateAdapter/dateAdapter';
import DateTimePickerInput from 'common/DatetimePicker/DateTimePickerInput';
import FormAccordian from 'common/formControl/formAccordian';
import CustomizedInputs from 'common/formControl/formControl';
import Status from 'common/WoStatus/Status';
import {
  ActivityOwner,
  BaseModel,
  ImageUploadViewModel,
  OrderType,
  WorkOrderStatus,
} from 'models/pagination-model';
import { DnPWorkOrders } from 'models/deck-pack-orders';
import { Moment } from 'moment';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import I18n from 'utilities/i18n';
import http from '../../../utilities/httpService';
import RouteEnum from 'models/RouteEnum';
import { hasPermission } from 'utilities/protectedRoute';
import { Permission } from 'Permissions';
import FormAccordianSave from 'common/formControl/formAccordianSave';
import moment from 'moment';
import DecpackStatus from './DecpackStatus';
import CreatedByandUpdatedBy from 'common/CreatedByandUpdatedBy/CreatedByandUpdatedBy';
import environment from 'environment';
import ApiUrlConstants from 'constants/api.constants';
import ImageUploadPreview from 'common/ImageUploadPreview/ImageUploadPreview';
import MobileDetails from 'common/MobileDetails/MobileDetails';

function DecPackOBOWOCreation() {
  const { woId, id } = useParams();
  let navigate = useNavigate();
  const [canEdit, setCanEdit] = useState(
    hasPermission(Permission.canEditDecPackOrders)
  );
  const [details, setDetails] = useState<any>({
    createdBy: '',
    createdDate: '',
    updatedBy: '',
    updatedDate: '',
  });

  const [assigneeData, setAssigneeData] = useState<any>({
    assignedToId: '',
    assignedToName: '',
    assignNextToId: '',
    assignNextToName: '',
  });
  const [signatureUrl, setSignatureUrl] = useState<ImageUploadViewModel[]>([]);
  const breadcrumbs = [
    <Typography key="3" color="text.primary">
      {I18n('Nav.Order')}
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={() => navigate(RouteEnum.DecPackOrdersList)}
        className="cpointer"
      >
        {I18n('Nav.DecPackOrders')}
      </Link>
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={() => navigate(RouteEnum.ViewDecPackOrders + '/' + id + '/1')}
        className="cpointer"
      >
        {I18n('ShippingOrders.WorkOrders')}
      </Link>
    </Typography>,
  ];
  const i18nMessages = {
    Information: I18n('ShippingOrders.Information'),
    statusSavedSuccess: I18n('Common.SavedSuccessfully'),
    commonFieldIsRequired: I18n('Common.FieldIsRequired'),
    customerName: I18n('DecPackOrders.CustomerName'),
    dateTime: I18n('DecPackOrders.DateTime'),
    title: I18n('DecPackOrders.WorkOrder.WOOutboundProcess'),
    InvalidDate: I18n('Common.InvalidDate'),
    SignedOffBy: I18n('Common.SignedOffBy'),
  };

  const today = new Date();
  const [values, setValues] = useState<any>({
    id: 0,
    customerName: '',
    date: new Date(),
    status: 0,
    signedOffByURL: '',
    mobileRemarks: '',
    waitTime: 0,
  });

  const [errors, setErrors] = useState({
    date: '',
    customerName: '',
  });
  const [isStatusSaveCallApi, setIsStatusSaveCallApi] = useState(false); // To call Submit APi Status from Status Component
  const [validateStatus, setValidateStatus] = useState<boolean>(false); // validateStatus - To validate Status Dropdown from Status Component
  const [isSaveNext, setIsSaveNext] = useState<boolean>(true);
  const [isMobile, setIsMobile] = useState(false);
  const InformationData = () => {
    return (
      <>
        <Grid item lg={4} md={8}>
          <CustomizedInputs
            readOnly={!canEdit}
            margin="normal"
            required={true}
            displayLabel={i18nMessages.customerName}
            value={values.customerName}
            id="customerName"
            inputType="customerName"
            errorValue={true}
            errorMessage={errors.customerName}
            handleChange={(event: any) =>
              setValues({ ...values, customerName: event.target.value })
            }
          />
        </Grid>
        <Grid item lg={4} md={8}>
          <DateTimePickerInput
            readOnly={!canEdit}
            displayLabel={i18nMessages.dateTime}
            name="date"
            id="date"
            errorValue={true}
            errorMessage={errors.date}
            value={values.date}
            defaultValue={today}
            handleChange={(event: Moment) => {
              setValues({
                ...values,
                date: event != null ? event.toDate() : '',
              });
            }}
          ></DateTimePickerInput>
        </Grid>
      </>
    );
  };

  const validate = (fieldValues: any = values) => {
    let temp = { ...errors };
    if ('customerName' in fieldValues) {
      temp.customerName = fieldValues.customerName
        ? ''
        : i18nMessages.commonFieldIsRequired;
    }

    if ('date' in fieldValues) {
      temp.date = fieldValues.date ? '' : i18nMessages.commonFieldIsRequired;

      if (temp.date == '') {
        let isDateValid = moment(fieldValues.date).isValid();
        if (!isDateValid) {
          temp.date = i18nMessages.InvalidDate;
        }
      }
    }

    setErrors({
      ...temp,
    });
    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === '');
    }
  };

  const handleSaveClick = async (event: any) => {
    event.preventDefault();
    setValidateStatus(true);
    setIsSaveNext(false);
  };

  const getWOStatus = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl +
        'DecPackOrder/GetDecPackWO/' +
        id +
        '/' +
        DnPWorkOrders.WOOBO
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result) {
        setDetails({
          ...details,
          createdBy: result.data.createdBy,
          updatedBy: result.data.updatedBy,
          createdDate: result.data.createdDate,
          updatedDate: result.data.updatedDate,
        });

        setAssigneeData({
          ...assigneeData,
          assignedToId: result.data.assignedToId,
          assignedToName: result.data.assignedToName,
          assignNextToId: result.data.assignedNextToId,
          assignNextToName: result.data.assignedNextToName,
        });

        setIsMobile(result.data.isMobile);
        return result.data.status;
      }
    } catch (error) {}
  };

  const getDecPackOBOWO = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl + 'DecPackOBOWorkOrder/' + id + '/' + woId
    );
    try {
      const result = await http.get(apiUrl.toString());
      var status = await getWOStatus();

      if (result && result.data) {
        setValues({
          ...values,
          id: result.data.id,
          customerName: result.data.customerName,
          signedOffByURL: result.data.signedOffByURL,
          date: result.data.date,
          status,
          mobileRemarks: result.data.mobileRemarks,
          waitTime: result.data.waitTime,
        });

        if (result.data.signedOffByURL) {
          setSignatureUrl([
            {
              documentTag: {
                label: '',
                value: '',
              } as BaseModel,
              error: '',
              previewUrl:
                environment.api.baseUrl +
                ApiUrlConstants.DownloadImageUrl +
                result.data.signedOffByURL,
              path: result.data.signedOffByURL,
              imageUrl: result.data.signedOffByURL,
            } as ImageUploadViewModel,
          ]);
        }
      } else {
        setValues({
          ...values,
          status,
        });
      }
    } catch (error) {}
  };

  // Validate Status Dropdown Success call from Status Component
  const handleSuccessStatusSave = async () => {
    let isValid =
      values.status == WorkOrderStatus.Done ? validate(values) : true;
    if (isValid) {
      setIsStatusSaveCallApi(true);
      let apiUrl;
      let result;
      const finalValue = {
        id: values.id ? values.id : 0,
        decPackWorkOrdersId: Number(woId),
        customerName: values.customerName,
        decPackInitialOrderId: Number(id),
        date: values.date,
        signedOffByURL:
          signatureUrl && signatureUrl.length > 0 ? signatureUrl[0].path : '',
        waitTime: values.waitTime,
        mobileRemarks: values.mobileRemarks,
      };

      try {
        if (finalValue.id == 0) {
          apiUrl = new URL(environment.api.baseUrl + 'DecPackOBOWorkOrder');
          result = await http.post(apiUrl.toString(), finalValue);
        } else {
          apiUrl = new URL(
            environment.api.baseUrl + 'DecPackOBOWorkOrder/' + finalValue.id
          );
          result = await http.put(apiUrl.toString(), finalValue);
        }
        if (result) {
          toast.success(i18nMessages.statusSavedSuccess);
          handleErrorStatusSave(); // To set Validation and Submit APi Status False
        }
      } catch (error: any) {}
    } else {
      handleErrorStatusSave(); /* To set Validation and Submit APi Status False */
    }
  };

  // Validate Status Dropdown Error call from Status Component
  const handleErrorStatusSave = () => {
    setValidateStatus(false);
    setIsStatusSaveCallApi(false);
  };

  const onSaveNextClick = async (event: any) => {
    event.preventDefault();
    setValues({ ...values, status: WorkOrderStatus.Done });
    setValidateStatus(true);
    setIsSaveNext(true);
  };

  useEffect(() => {
    getDecPackOBOWO();
  }, []);

  const refreshData = async () => {
    handleErrorStatusSave();
    getWOStatus();
  };

  const SignatureDetails = () => {
    return (
      <>
        <Grid item lg={12} md={12}>
          {signatureUrl.length > 0 ? (
            <ImageUploadPreview
              readOnly={true}
              disableKeyboardNav={true}
              limit={10}
              isImageOnly={true}
              images={signatureUrl}
              hideTags={true}
            />
          ) : (
            <></>
          )}
        </Grid>
      </>
    );
  };

  return (
    <>
      <div className="heading-section">
        <div className="heading-section-left">
          <Typography variant="h3" sx={{ mb: 3 }}>
            {i18nMessages.title}
          </Typography>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>
      </div>
      <div className="main-content-section">
        <FormAccordianSave
          title={i18nMessages.Information}
          details={InformationData()}
          showActionButton={!canEdit}
          className="inner-heading"
        ></FormAccordianSave>
        <FormAccordianSave
          title={i18nMessages.SignedOffBy}
          details={SignatureDetails()}
          showActionButton={false}
          className="inner-heading"
        ></FormAccordianSave>
        <MobileDetails
          mobileRemarks={values.mobileRemarks}
          waitTime={values.waitTime}
        ></MobileDetails>
        <Grid item lg={12} md={12}>
          <DecpackStatus
            permission={Permission.canEditDecPackOrders}
            woId={DnPWorkOrders.WOOBO}
            activityOwner={ActivityOwner.Driver}
            orderId={id}
            statusValue={values.status}
            orderType={OrderType.DecPackOrder}
            isStatusSaveCallApi={isStatusSaveCallApi}
            validateStatus={validateStatus}
            handleSuccessStatusSave={handleSuccessStatusSave}
            handleErrorStatusSave={handleErrorStatusSave}
            isSaveNext={isSaveNext}
            isMobile={isMobile}
            workOrderNo={DnPWorkOrders.WOOBO}
            assigneeData={assigneeData}
            handleStatusChange={(value: number) =>
              setValues({ ...values, status: value })
            }
          />
        </Grid>
        <div className="btn-wrap form-btn-wrap">
          {canEdit ? (
            <Button
              color="primary"
              disableElevation
              variant="contained"
              onClick={onSaveNextClick}
            >
              {I18n('Common.SaveNext')}
            </Button>
          ) : (
            <></>
          )}
          &nbsp; &nbsp; &nbsp;
          {canEdit ? (
            <Button
              type="submit"
              color="primary"
              disableElevation
              variant="contained"
              onClick={handleSaveClick}
            >
              {I18n('Common.Save')}
            </Button>
          ) : (
            <></>
          )}
        </div>
        <CreatedByandUpdatedBy
          createdBy={details.createdBy}
          createdDate={details.createdDate}
          updatedBy={details.updatedBy}
          updatedDate={details.updatedDate}
        ></CreatedByandUpdatedBy>
      </div>
    </>
  );
}

export default DecPackOBOWOCreation;
