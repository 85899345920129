import 'tui-image-editor/dist/tui-image-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import ImageEditor from 'tui-image-editor';
import React, { useState } from 'react';
import { createRef, useEffect } from 'react';
import { Utilities } from 'utilities/Utilities';
export default function CustomImageEditor(props: any) {
  const rootEl = createRef<any>();
  const [imageEditorInst, setImageEditorInst] = useState<any>();
  useEffect(() => {
    if (props.isSaveImage) {
      const mimeType = Utilities.base64MimeType(imageEditorInst.toDataURL());
      if (imageEditorInst.toDataURL()) {
        Utilities.urltoFile(
          imageEditorInst.toDataURL(),
          `${Utilities.randomNameGenerator()}.png`,
          mimeType ? mimeType : ''
        ).then((response) => {
          if (response) {
            props.onSave(response);
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isSaveImage]);
  useEffect(() => {
    if (imageEditorInst) {
      imageEditorInst.destroy();
    }
    setImageEditorInst(
      new ImageEditor(rootEl.current, {
        ...props,
      } as tuiImageEditor.IOptions)
    );
  }, [props.includeUI.loadImage.path]);
  return <div ref={rootEl} />;
}
