import {
  ActivityOwner,
  CustomerLocation,
  DocumentEntity,
  EntityTypeEnum,
  WorkOrderStatus,
} from './pagination-model';
import { ShippingType } from './shipping-orders';

export interface DecPackResponseDto extends DecPackDTO {
  customerName: string;
  contactPersonName: string;
  locationAddress: string;
  deliveryLocationAddress: string;
  airlineName: string;
  finalDestinationName: string;
  transitDestinationName: string;
  selectedContacts: any;
}

export interface DecPackDTO extends DecPackBaseDTO {
  id: number;
}

export interface DecPackBaseDTO {
  hazgoReference: string;
  serviceType: DecPackServiceType;
  executionDate: string;
  startTime: string;
  endTime: string;
  customerReference: string;
  shipper: string;
  locationType: LocationType;
  securedShipment: boolean;
  driverName: string;
  licensePlate: string;
  transporter: string;
  piecesNo: number;
  pickUpOriginAddress: string;
  expectedInboundDate: string;
  interventie: string;
  specialInstructions: string;
  isHighPriority: boolean;
  estimatedDuration: number;
  status: ShippingOrderStatus;
  totalWorkOrder: number;
  completedWorkOrder: number;
  type: ShippingType;
  orderDate: string;
  customerId: number | null;
  contactPersonId: number | null;
  locationId: number | null;
  deliveryLocationId: number | null;
  airlineId: number | null;
  finalDestinationId: number | null;
  transitDestinationId: number | null;
  nextWorkOrder: DnPWorkOrders | null;
  selectedContacts: any;
}

export enum LocationType {
  Standard = 1,
  Warehouse = 2,
  Groundhandler = 3,
}

export enum DnPWorkOrders {
  WO2 = 1,
  WOS = 2,
  WOIBO = 3,
  WO3 = 4,
  WO4 = 5,
  WO5 = 6,
  WO6 = 7,
  WO6bis = 8,
  Wo6ter = 9,
  WO7 = 10,
  WO8 = 11,
  WO9 = 12,
  WOOBO = 13,
  WO10 = 14,
  WO11 = 15,
  WO12 = 16,
}

export const DnPWorkOrdersTypeDescription = new Map<number, string>([
  [DnPWorkOrders.WO2, 'WorkOrder.DGDCreation'],
  [DnPWorkOrders.WOS, 'WorkOrder.SecurityCheckRA'],
  [DnPWorkOrders.WOIBO, 'WorkOrder.Inboundprocess'],
  [DnPWorkOrders.WO3, 'WorkOrder.Collectingshipmentdata'],
  [DnPWorkOrders.WO4, 'WorkOrder.CheckDocumentation'],
  [DnPWorkOrders.WO5, 'WorkOrder.Material'],
  [DnPWorkOrders.WO6, 'WorkOrder.Executionorder'],
  [DnPWorkOrders.WO6bis, 'WorkOrder.Deliveryorderassigning'],
  [DnPWorkOrders.Wo6ter, 'WorkOrder.Deliveryorder'],
  [DnPWorkOrders.WO7, 'WorkOrder.Irregularitycontactclient'],
  [DnPWorkOrders.WO8, 'WorkOrder.BillofMaterial'],
  [DnPWorkOrders.WO9, 'WorkOrder.PhotoCheck'],
  [DnPWorkOrders.WOOBO, 'WorkOrder.Outboundprocess'],
  [DnPWorkOrders.WO10, 'WorkOrder.ApprovalBillofMaterial'],
  [DnPWorkOrders.WO11, 'WorkOrder.InputmissingCustomerreference'],
]);

export enum ShippingOrderStatus {
  New = 1,
  InProcess = 2,
  Done = 3,
  Invoiced = 4,
}

export enum DecPackServiceType {
  DGCheck = 1,
  DGDCreation = 2,
  NonDGOrder = 3,
  Shop = 4,
}

export interface DecPackQuestionsDto {
  id: number;
  sequenceNo: number;
  questionKey: DecPackQuestions;
  questionType: QuestionType | null;
  workOrderNo: DnPWorkOrders;
}

export interface DecPackWOIBOBaseDTO {
  decPackQuestionsId: number;
  decPackWorkOrdersId: number;
  answer: number;
  comment: string;
}

export interface WorkOrderImagesDTO {
  id: number;
  entityType: number;
  orderId: number;
  workOrderId: number;
  imageUrl: string;
  documentTag: number;
  isSignature: boolean;
  sendToCustomer: boolean;
  documentEntityType: DocumentEntity;
}

export interface GetWorkOrderImagesDTO extends WorkOrderImagesDTO {
  documentTagName: string;
}

export interface UpdateWorkOrderImagesDTO {
  workOrderImages: WorkOrderImagesDTO[];
  entityType: EntityTypeEnum;
  documentEntityType: DocumentEntity;
  workOrderId: number;
  orderId: number;
  isSignature: boolean;
}

export interface DecPackWOIBODTO extends DecPackWOIBOBaseDTO {
  id: number;
}

export interface DecPackQuestionsDtoStateModel extends DecPackQuestionsDto {
  questionName: string;
  error: string;
}

export enum DecPackQuestions {
  WOSecurityCheckQA1 = 1,
  WOSecurityCheckQA2 = 2,
  WOIBOSecureShipmentQA1 = 3,
  WOIBOSecureShipmentQA2 = 4,
  WOIBOSecureShipmentQA3 = 5,
  WOIBOSecureShipmentQA4 = 6,
  WOIBOSecurityGeneralQA1 = 7,
  WOIBOSecurityGeneralQA2 = 8,
  WOIBOShipmentQA1 = 9,
  WOIBOShipmentQA2 = 10,
  WOIBOShipmentQA3 = 11,
  WOCheckDocumentationQA1 = 12,
  WOCheckDocumentationQA2 = 13,
  WOCheckDocumentationQA3 = 14,
  WOCheckDocumentationQA4 = 15,
  WOExecutionQA1 = 16,
  WOExecutionQA2 = 17,
  WOExecutionQA3 = 18,
  WOExecutionQA4 = 19,
  WOExecutionQA5 = 20,
}

export const DecPackQuestionsDescription = new Map<number, string>([
  [DecPackQuestions.WOSecurityCheckQA1, 'DecPackOrders.WOSecurityCheckQA1'],
  [DecPackQuestions.WOSecurityCheckQA2, 'DecPackOrders.WOSecurityCheckQA2'],
  [
    DecPackQuestions.WOIBOSecureShipmentQA1,
    'DecPackOrders.DecPackQuestions.WOIBO.SecureShipmentQA1',
  ],
  [
    DecPackQuestions.WOIBOSecureShipmentQA2,
    'DecPackOrders.DecPackQuestions.WOIBO.SecureShipmentQA2',
  ],
  [
    DecPackQuestions.WOIBOSecureShipmentQA3,
    'DecPackOrders.DecPackQuestions.WOIBO.SecureShipmentQA3',
  ],
  [
    DecPackQuestions.WOIBOSecureShipmentQA4,
    'DecPackOrders.DecPackQuestions.WOIBO.SecureShipmentQA4',
  ],
  [
    DecPackQuestions.WOIBOSecurityGeneralQA1,
    'DecPackOrders.DecPackQuestions.WOIBO.SecurityGeneralQA1',
  ],
  [
    DecPackQuestions.WOIBOSecurityGeneralQA2,
    'DecPackOrders.DecPackQuestions.WOIBO.SecurityGeneralQA2',
  ],
  [
    DecPackQuestions.WOIBOShipmentQA1,
    'DecPackOrders.DecPackQuestions.WOIBO.ShipmentQA1',
  ],
  [
    DecPackQuestions.WOIBOShipmentQA2,
    'DecPackOrders.DecPackQuestions.WOIBO.ShipmentQA2',
  ],
  [
    DecPackQuestions.WOIBOShipmentQA3,
    'DecPackOrders.DecPackQuestions.WOIBO.ShipmentQA3',
  ],
  [
    DecPackQuestions.WOCheckDocumentationQA1,
    'DecPackOrders.DecPackQuestions.WOCheckDocumentation.WOCheckDocumentationQA1',
  ],
  [
    DecPackQuestions.WOCheckDocumentationQA2,
    'DecPackOrders.DecPackQuestions.WOCheckDocumentation.WOCheckDocumentationQA2',
  ],
  [
    DecPackQuestions.WOCheckDocumentationQA3,
    'DecPackOrders.DecPackQuestions.WOCheckDocumentation.WOCheckDocumentationQA3',
  ],
  [
    DecPackQuestions.WOCheckDocumentationQA4,
    'DecPackOrders.DecPackQuestions.WOCheckDocumentation.WOCheckDocumentationQA4',
  ],
  [
    DecPackQuestions.WOExecutionQA1,
    'DecPackOrders.DecPackQuestions.WOExecution.WOExecutionQA1',
  ],
  [
    DecPackQuestions.WOExecutionQA2,
    'DecPackOrders.DecPackQuestions.WOExecution.WOExecutionQA2',
  ],
  [
    DecPackQuestions.WOExecutionQA3,
    'DecPackOrders.DecPackQuestions.WOExecution.WOExecutionQA3',
  ],
  [
    DecPackQuestions.WOExecutionQA4,
    'DecPackOrders.DecPackQuestions.WOExecution.WOExecutionQA4',
  ],
  [
    DecPackQuestions.WOExecutionQA5,
    'DecPackOrders.DecPackQuestions.WOExecution.WOExecutionQA5',
  ],
]);

export enum QuestionType {
  WOIBOSecureShipment = 1,
  WOIBOSecurityGeneral = 2,
  WOIBOShipment = 3,
}

export interface DeckPackSecureShipmentDetails {
  DriverName: string;
  LicensePlate: string;
  SecuredShipment: boolean;
  Transporter: string;
  locationType: CustomerLocation;
  NumberofPieces: number;
}

export interface DecPackWOCollectionShipmentDataImagesDTO {
  decPackWorkOrderId: number;
  decPackInitialOrderId: number;
  imageUrl: string;
  documentEntityType: DocumentEntity;
}

export interface DecPackWorkOrdersDto {
  id: number;
  decPackInitialOrderId: number;
  workOrderNo: DnPWorkOrders;
  workOrderDate: string | null;
  activityOwner: ActivityOwner | null;
  type: string;
  status: WorkOrderStatus;
  assignedToId: string;
  shortDescription: string;
  assignedToName: string;
  workOrderNoName: string;
  isAwaitingInfo: boolean;
  isOnHold: boolean;
}

export enum FieldMasterType {
  Question = 1,
  TextField = 2,
  Checkbox = 3,
}

export interface DeckPackWorkOrderHistoryDto {
  totalRecords: number;
  deckPackWoHistoryGroupedMasterValues: DeckPackWoHistoryGroupedMasterValuesDto[];
}

export interface DeckPackWoHistoryGroupedMasterValuesDto {
  historyId: string;
  deckPackWoHistoryMasterValues: DeckPackWoHistoryMasterValuesDto[];
  woIboImages: GetWorkOrderImagesDTO[];
  woExecutionImages: GetWorkOrderImagesDTO[];
}
export interface DeckPackWoHistoryMasterValuesDto {
  deckPackWoHistoryMasterId: number;
  deckPackWorkOrderId: number;
  answer: string;
  comment: string;
  historyId: string;
  workOrderNo: DnPWorkOrders;
  decPackQuestionId: number | null;
  fieldType: FieldMasterType;
  createdDate: Date | string;
}

export interface DeckPackIrregularityContactClientDto {
  decPackWorkOrderId: number;
  decPackInitialOrderId: number;
  isContactCustomerViaMail: boolean;
}

export interface DecPackDetailsWODTO {
  id: number;
  orderId: number;
  workOrderNo: number;
  waitTime: number;
  mobileRemarks: string;
}
