import TabList from '@mui/lab/TabList';
import TabContext from '@mui/lab/TabContext';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import RouteEnum from 'models/RouteEnum';
import { Link } from '@mui/material';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import I18n from 'utilities/i18n';
import { Breadcrumbs } from '@mui/material';
import TabPanel from '@mui/lab/TabPanel';
import Note from 'common/notes/Notes';
import ServiceCatalogueDetails from './ServiceCatalogueDetails';
import {
  DocumentEntity,
  EntityTypeEnum,
  PricingType,
} from 'models/pagination-model';
import SpecialPricing from 'common/special-pricing/SpecialPricing';
import environment from 'environment';
import http from '../../utilities/httpService';
import AllowedOrderCategory from 'common/AllowedOrderCategory/AllowedOrderCategory';
import DocumentList from 'common/Document/DocumentList';
import { Permission } from 'Permissions';
export default function ServiceCatalogueTabs() {
  let navigate = useNavigate();
  const handleListClick = () => {
    navigate(RouteEnum.ServiceCatalogue);
  };
  const [pricingType, setPricingType] = React.useState(PricingType.Standard);
  const { entityId } = useParams();
  const getServiceCatalogueById = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl + `ServiceCatalogues/${entityId}`
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result && result.data) {
        setPricingType(result.data.pricingType);
      }
    } catch (error) {}
  };
  const i18nMessages = {
    noteTabTitle: I18n('ServiceCatalogues.Tabs.Notes'),
    documentTabTitle: I18n('ServiceCatalogues.Tabs.Documents'),
    allowedOrderCategoryTabTitle: I18n(
      'ServiceCatalogues.Tabs.AllowedOrderCategory'
    ),
    detailsTabTitle: I18n('ServiceCatalogues.Tabs.Details'),
    specialPricingTabTitle: I18n('ServiceCatalogues.Tabs.SpecialPricing'),
  };
  React.useEffect(() => {
    getServiceCatalogueById();
  }, []);
  const breadcrumbs = [
    <Typography key="3" color="text.primary">
      {I18n('Nav.Configuration')}
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={handleListClick}
        className="cpointer"
      >
        {I18n('Nav.ServiceCatalogue')}
      </Link>
    </Typography>,
    <Typography key="3" color="text.primary">
      {I18n('ServiceCatalogues.Details')}
    </Typography>,
  ];
  const [value, setValue] = React.useState<string>('1');
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <div>
      <div className="heading-section">
        <div className="heading-section-left">
          <Typography variant="h3" sx={{ mb: 3 }}>
            {I18n('Nav.ServiceCatalogue')}
          </Typography>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>
      </div>
      <div className="main-content-section width-100">
        <TabContext value={value}>
          <Box>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              className="tab-bordered"
            >
              <Tab label={i18nMessages.detailsTabTitle} value="1" />
              {pricingType === PricingType.Special && (
                <Tab label={i18nMessages.specialPricingTabTitle} value="2" />
              )}

              <Tab
                label={i18nMessages.allowedOrderCategoryTabTitle}
                value="3"
              />
              <Tab label={i18nMessages.documentTabTitle} value="4" />
              <Tab label={i18nMessages.noteTabTitle} value="5" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <ServiceCatalogueDetails />
          </TabPanel>
          {pricingType === PricingType.Special && (
            <TabPanel value="2">
              <SpecialPricing
                editPermission={Permission.canEditServiceCatalogue}
                addPermission={Permission.canAddServiceCatalogue}
                deletePermission={Permission.canDeleteServiceCatalogue}
                entityType={EntityTypeEnum.ServiceCatalogue}
              />
            </TabPanel>
          )}
          <TabPanel value="3">
            <AllowedOrderCategory
              permission={Permission.canEditServiceCatalogue}
              redirect={RouteEnum.ServiceCatalogue}
              entityType={EntityTypeEnum.ServiceCatalogue}
            />
          </TabPanel>
          <TabPanel value="4">
            <DocumentList
              permission={Permission.canEditServiceCatalogue}
              addPermission={Permission.canAddServiceCatalogue}
              deletePermission={Permission.canDeleteServiceCatalogue}
              entityType={EntityTypeEnum.ServiceCatalogue}
              paramsId={entityId}
              documentEntityType={DocumentEntity.ServiceCatalogue}
            />
          </TabPanel>
          <TabPanel value="5">
            <Note
              permission={Permission.canEditServiceCatalogue}
              addPermission={Permission.canAddServiceCatalogue}
              deletePermission={Permission.canDeleteServiceCatalogue}
              paramsId={entityId}
              entityType={EntityTypeEnum.ServiceCatalogue}
            />
          </TabPanel>
        </TabContext>
      </div>
    </div>
  );
}
