import { BaseSyntheticEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import I18n from 'utilities/i18n';
import {
  Grid,
  Checkbox,
  FormControlLabel,
  InputLabel,
  Typography,
  Link,
  Breadcrumbs,
  Button,
} from '@mui/material';
import FormControl, { CustomizedInputs } from 'common/formControl/formControl';
import FormAccordianSave from 'common/formControl/formAccordianSave';
import CustomizedSelect from 'common/CustomSelect/CustomSelect';
import {
  ActivityOwner,
  BaseModel,
  DocumentEntity,
  EntityTypeEnum,
  ImageUploadViewModel,
  EntityStatus,
  OrderType,
  PaginationResponseModel,
  PartnerType,
  WorkOrderStatus,
} from 'models/pagination-model';
import DateTimePickerInput from 'common/DatetimePicker/DateTimePickerInput';
import ImageUploadPreview from 'common/ImageUploadPreview/ImageUploadPreview';
import {
  AssignmentType,
  WorkOrderInboundAssignmentType,
  WorkOrderInboundModel,
} from 'models/shipping-orders';
import { ShippingOrder } from 'models/pagination-model';
import environment from 'environment';
import http from '../../../utilities/httpService';
import CustomizedSelectPaginate from 'common/CustomSelect/CustomSelectPaginate';
import { Moment } from 'moment';

import { toast } from 'react-toastify';
import Status from 'common/WoStatus/Status';
import RouteEnum from 'models/RouteEnum';
import {
  getWorkOrderImages,
  saveWorkOrderImages,
} from 'common/ImageUploadPreview/WorkOrderImagesApiService';
import { Customer } from 'models/service-catalogue';
import { hasPermission } from 'utilities/protectedRoute';
import { Permission } from 'Permissions';
import ApiUrlConstants from 'constants/api.constants';
import moment from 'moment';
import CustomSelectPaginate from 'common/CustomSelect/CustomSelectPaginate';
import CreatedByandUpdatedBy from 'common/CreatedByandUpdatedBy/CreatedByandUpdatedBy';
import MobileDetails from 'common/MobileDetails/MobileDetails';

const WorkOrderInbound = () => {
  const defaultValue = {
    value: '0',
    label: I18n('Placeholders.Select'),
  } as BaseModel;
  const defaultAdditional: any = {
    page: 1,
  };
  const [selectedAssignmentTo, setSelectedAssignmentTo] =
    useState(defaultValue);

  const { woId, id } = useParams();
  const today = new Date().toISOString();
  const [isNew, setIsNew] = useState<boolean>(true);
  const [canEdit, setCanEdit] = useState(
    hasPermission(Permission.canEditShippingOrders)
  );
  const [details, setDetails] = useState<any>({
    createdBy: '',
    createdDate: '',
    updatedBy: '',
    updatedDate: '',
  });

  const [values, setValues] = useState<WorkOrderInboundModel>({
    id: 0,
    assignmentType: defaultValue,
    assignmentTo: '',
    actualInboundDateTime: new Date(),
    remarks: '',
    instructions: '',
    isInboundDone: false,
    images: [],
    shippingWorkOrderId: 0,
    createdBy: '',
    createdDate: '',
    updatedBy: '',
    updatedDate: '',
    mobileRemarks: '',
    waitTime: 0,
  });

  const [finalStatus, setFinalStatus] = useState(0);
  const [mobileImages, setMobileImages] = useState([]);

  const [errors, setErrors] = useState({
    assignmentType: '',
    assignmentTo: '',
    actualInboundDateTime: '',
    remarks: '',
    instructions: '',
    status: '',
    isInboundDone: '',
    images: '',
  });
  const [images, setImages] = useState<ImageUploadViewModel[]>([]);
  const [isStatusSaveCallApi, setIsStatusSaveCallApi] =
    useState(false); /* To call Submit APi Status from Status Component */
  const [validateStatus, setValidateStatus] = useState<boolean>(false);
  /* validateStatus - To validate Status Dropdown from Status Component */
  let navigate = useNavigate();
  const breadcrumbs = [
    <Typography key="3" color="text.primary">
      {I18n('Nav.Order')}
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={() => navigate(RouteEnum.ShippingOrdersList)}
        className="cpointer"
      >
        {I18n('Nav.ShippingOrders')}
      </Link>
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={() => navigate(RouteEnum.ViewShippingOrders + '/' + id + '/2')}
        className="cpointer"
      >
        {I18n('ShippingOrders.WorkOrders')}
      </Link>
    </Typography>,
  ];

  const loadUserOptions = async (
    search: string,
    loadedOptions: any,
    { page }: any
  ) => {
    const response = await getUsers(search, page);
    const hasMore = Math.ceil(response.TotalRecords / 10) > page;

    return {
      options: response.Values.map((x) => {
        return {
          value: x.id.toString(),
          label: x.name,
        } as BaseModel;
      }),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };
  const getUsers = async (search: string, pageNo: number) => {
    let response: PaginationResponseModel<Customer>;
    const apiUrl = new URL(environment.api.baseUrl + 'Users/MobileUsers');
    try {
      let searchExpression = '';
      searchExpression += search
        ? ` firstName~like~${search} or lastName~like~${search}`
        : '';
      apiUrl.searchParams.set('$filter', searchExpression);
      apiUrl.searchParams.set('$orderby', 'firstName');
      apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
      apiUrl.searchParams.set('$top', '10');
      apiUrl.searchParams.set('$orderbydirection', 'asc');
      const result = await http.get(apiUrl.toString());
      if (result.data && result.data.values.length) {
        result.data.values.forEach((z: any) => {
          z.name = `${z.firstName} ${z.lastName}`;
        });
      }
      if (result) {
        response = {
          TotalRecords: result.data.totalCount,
          Values: result.data.values,
        } as PaginationResponseModel<Customer>;
      } else {
        response = {
          TotalRecords: 0,
          Values: [],
        } as PaginationResponseModel<Customer>;
      }
    } catch (error) {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<Customer>;
    }
    return response;
  };

  useEffect(() => {
    getInboundDetails();
    getMobileImages();
  }, []);
  const i18nMessages = {
    Title: I18n('WorkOrder.Title'),
    fieldRequired: I18n('WorkOrder.FieldRequired'),
    Picture: I18n('Packaging.Picture'),
    Driver: I18n('WorkOrder.Driver'),
    Partner: I18n('WorkOrder.Partner'),
    WareHouse: I18n('WorkOrder.WarehouseStaff'),
    AddSuccess: I18n('WorkOrder.AddSuccess'),
    UpdateSuccess: I18n('WorkOrder.UpdateSuccess'),
    NoShippingDocsAvailable: I18n(
      'DecPackOrders.DecPackQuestions.WOExecution.NoDocsAvailable'
    ),
    noImagesSelected: I18n('Common.NoImagesSelectedToUpload'),
    imageSaveSuccess: I18n('Common.ImageSaveSuccess'),
    somethingWentWrong: I18n('Common.SomethingWentWrong'),
    dhlValue: I18n('Partner.DHL'),
    fedExValue: I18n('Partner.FedEx'),
    othersValue: I18n('Partner.Others'),
    commonFieldIsRequired: I18n('Common.FieldIsRequired'),
    InvalidDate: I18n('Common.InvalidDate'),
  };

  const getPartnerTypeValue = (type: PartnerType) => {
    switch (type) {
      case PartnerType.DHL:
        return i18nMessages.dhlValue;
      case PartnerType.FedEx:
        return i18nMessages.fedExValue;
      default:
        break;
    }
  };

  const loadPartnerOptions = async (
    search: string,
    loadedOptions: any,
    { page }: any
  ) => {
    const response = await getPartners(search, page);
    const hasMore = Math.ceil(response.TotalRecords / 10) > page;

    return {
      options: response.Values.map((x) => {
        return {
          value: x.id.toString(),
          label: x.name,
        } as BaseModel;
      }),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };
  const getPartners = async (search: string, pageNo: number) => {
    let response: PaginationResponseModel<Customer>;
    const apiUrl = new URL(environment.api.baseUrl + 'Partners');
    try {
      let searchExpression = 'Status~=~' + EntityStatus.Active;
      searchExpression += search ? ` and name~like~${search}` : '';
      apiUrl.searchParams.set('$filter', searchExpression);
      apiUrl.searchParams.set('$orderby', 'partnerType');
      apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
      apiUrl.searchParams.set('$top', '10');
      apiUrl.searchParams.set('$orderbydirection', 'asc');
      const result = await http.get(apiUrl.toString());
      if (result.data && result.data.values.length) {
        result.data.values.forEach((z: any) => {
          z.name =
            +z.partnerType === PartnerType.Others && +z.partnerType !== 0
              ? z.name
              : `${z.name} - ${getPartnerTypeValue(+z.partnerType)}`;
        });
      }
      if (result) {
        response = {
          TotalRecords: result.data.totalCount,
          Values: result.data.values,
        } as PaginationResponseModel<Customer>;
      } else {
        response = {
          TotalRecords: 0,
          Values: [],
        } as PaginationResponseModel<Customer>;
      }
    } catch (error) {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<Customer>;
    }
    return response;
  };

  const getMobileImages = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl +
        `Documents/GetWorkOrderImagesAsync/${EntityTypeEnum.ShippingOrders}/${
          DocumentEntity.ShippingInboundImages
        }/${4}/${id}`
    );

    const result = await http.get(apiUrl.toString());
    if (result && result.data) {
      setMobileImages(result.data);
    }
  };

  const validate = (fieldValues: any = values) => {
    let temp = { ...errors };
    if ('assignmentType' in fieldValues) {
      temp.assignmentType =
        fieldValues.assignmentType.value !== '0'
          ? ''
          : i18nMessages.fieldRequired;
    }
    if ('actualInboundDateTime' in fieldValues) {
      temp.actualInboundDateTime = fieldValues.actualInboundDateTime
        ? ''
        : i18nMessages.commonFieldIsRequired;

      if (temp.actualInboundDateTime == '') {
        let isDateValid = moment(fieldValues.actualInboundDateTime).isValid();
        if (!isDateValid) {
          temp.actualInboundDateTime = i18nMessages.InvalidDate;
        }
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === '');
    }
  };

  let assignmentTypeList: Array<BaseModel> = [];
  const getAsssignmentTypes = () => {
    assignmentTypeList = [defaultValue];
    WorkOrderInboundAssignmentType.forEach((value: string, key: number) => {
      assignmentTypeList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getAsssignmentTypes();

  const getInboundDetails = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl +
        `ShippingWOInbound/GetDataByShippingId?shippingOrderId=${id}&shippingWorkOrderId=${woId}`
    );
    try {
      let shippingWOData = await getShippingWO();
      setFinalStatus(shippingWOData.status);

      const result = await http.get(apiUrl.toString());
      if (result.data.id > 0) {
        setIsNew(false);
        if (result) {
          setValues({
            ...values,
            id: result.data.id,
            assignmentType: {
              value: result.data.assignmentType.toString(),
              label:
                result.data.assignmentType === 1
                  ? i18nMessages.Driver
                  : result.data.assignmentType === 2
                  ? i18nMessages.Partner
                  : result.data.assignmentType === 3
                  ? i18nMessages.WareHouse
                  : defaultValue.label,
            } as BaseModel,
            assignmentTo:
              result.data.assignmentToId > 0
                ? {
                    value: Number(result.data.assignmentToId),
                    label: result.data.assignmentTo,
                  }
                : result.data.assignmentTo,
            instructions: result.data.instructions,
            isInboundDone: result.data.isInboundDone,
            actualInboundDateTime: result.data.actualInboundDateTime,
            remarks: result.data.remarks,
            shippingWorkOrderId: result.data.shippingWorkOrderId,
            mobileRemarks: result.data.mobileRemarks,
            waitTime: result.data.waitTime,
          });

          if (result.data.assignmentTo > 0) {
            if (result.data.assignmentType === AssignmentType.Partner) {
              const apiUrl = new URL(
                environment.api.baseUrl + `Partners/${result.data.assignmentTo}`
              );
              const resultForPartner = await http.get(apiUrl.toString());
              if (resultForPartner && resultForPartner.data) {
                setSelectedAssignmentTo({
                  label:
                    +resultForPartner.data.partnerType === PartnerType.Others &&
                    +resultForPartner.data.partnerType !== 0
                      ? resultForPartner.data.name
                      : `${resultForPartner.data.name} - ${getPartnerTypeValue(
                          +resultForPartner.data.partnerType
                        )}`,
                  value: resultForPartner.data.id,
                });
              }
            } else {
              const apiUrl = new URL(
                environment.api.baseUrl + `Users/${result.data.assignmentTo}`
              );
              const resultForUser = await http.get(apiUrl.toString());
              if (resultForUser && resultForUser.data) {
                setSelectedAssignmentTo({
                  label: `${resultForUser.data.firstName} ${resultForUser.data.lastName}`,
                  value: resultForUser.data.id,
                });
              }
            }
          }
        } else {
          setIsNew(true);
        }
        getPictures();
      } else {
        var driverLabel = assignmentTypeList.find(
          (x) => x.value === '1'
        )?.label;
        if (shippingWOData.assignedToId) {
          setValues({
            ...values,
            assignmentType: {
              label: driverLabel ? driverLabel : '',
              value: '1',
            },
            assignmentTo: shippingWOData.assignedToId,
          });
          setSelectedAssignmentTo({
            value: shippingWOData.assignedToId,
            label: shippingWOData.assignedToName,
          });
        } else {
          setValues({
            ...values,
            assignmentType: {
              label: driverLabel ? driverLabel : '',
              value: '1',
            },
          });
        }
      }
    } catch (error) {}
  };

  const handleSaveClick = async (data: any) => {
    setValidateStatus(true);
  };

  const getShippingWO = async () => {
    let shippingOrderId = Number(id);
    let WOID = Number(woId);

    const apiUrl = new URL(
      environment.api.baseUrl +
        'Shipping/GetShippingWO/' +
        shippingOrderId +
        '/' +
        WOID
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result) {
        setDetails({
          ...details,
          createdBy: result.data.createdBy,
          updatedBy: result.data.updatedBy,
          createdDate: result.data.createdDate,
          updatedDate: result.data.updatedDate,
        });
        return result.data;
      }
    } catch (error) {}
  };

  const fileDataFunction = (data: any) => {
    setImages([...images, ...data]);
  };
  const deleteFileFunction = (index: number) => {
    const localImages: any[] = [...images];
    localImages.splice(index, 1);
    setImages(localImages);
  };

  const editedFileDataFunction = (updatedImages: ImageUploadViewModel[]) => {
    setImages([...updatedImages]);
  };

  const handleCheckboxChange = (event: BaseSyntheticEvent) => {
    setValues({
      ...values,
      isInboundDone: event.target.checked,
    });
    if (event.target.checked === true) {
      setFinalStatus(WorkOrderStatus.Done);
    }
  };

  const handlePhotoSaveClick = async () => {
    await saveWorkOrderImages(
      images,
      EntityTypeEnum.ShippingOrders,
      DocumentEntity.ShippingInboundImages,
      false,
      id ? +id : 0,
      woId ? +woId : 0
    );
  };

  const getPictures = () => {
    getWorkOrderImages(
      EntityTypeEnum.ShippingOrders,
      woId ? +woId : 0,
      id ? +id : 0,
      DocumentEntity.ShippingInboundImages
    ).then((x) => {
      if (x.result) {
        /* Object conversion from backend viewModel to ImagePreview component Object */
        setImages(
          x.data.map((y) => {
            return {
              documentTag: {
                label: y.documentTagName,
                value: y.documentTag?.toString(),
              } as BaseModel,
              error: '',
              id: y.id,
              previewUrl:
                environment.api.baseUrl +
                ApiUrlConstants.DownloadImageUrl +
                y.imageUrl,
              path: y.imageUrl,
              sendToCustomer: y.sendToCustomer,
            } as ImageUploadViewModel;
          })
        );
      }
    });
  };

  /* Validate Status Dropdown Success call from Status Component */
  const handleSuccessStatusSave = async () => {
    let isValid = finalStatus == WorkOrderStatus.Done ? validate() : true;
    if (isValid) {
      handlePhotoSaveClick();
      setIsStatusSaveCallApi(true);
      const workOrderInboundData = {
        id: values.id > 0 ? values.id : 0,
        assignmentType: Number(values.assignmentType.value),
        assignmentTo:
          typeof values.assignmentTo === 'object'
            ? values.assignmentTo?.label
            : values.assignmentTo,
        assignmentToId:
          typeof values.assignmentTo === 'object'
            ? Number(values.assignmentTo?.value)
            : 0,
        actualInboundDateTime: values.actualInboundDateTime,
        instructions: values.instructions,
        isInboundDone: values.isInboundDone,
        imageUrls: values.images.filter(
          (item: any) => typeof item !== 'object'
        ),
        shippingOrderId: Number(id),
        shippingWorkOrderId: Number(woId),
        mobileRemarks: values.mobileRemarks,
        waitTime: values.waitTime,
      };
      if (!isNew) {
        const apiUrl = new URL(
          environment.api.baseUrl +
            'ShippingWOInbound/' +
            workOrderInboundData.id
        );
        try {
          const result = await http.put(
            apiUrl.toString(),
            workOrderInboundData
          );
          if (result) {
            toast.success(i18nMessages.UpdateSuccess);
            handleErrorStatusSave(false);
            navigate(RouteEnum.ViewShippingOrders + '/' + id + '/2');
            /* To set Validation and Submit APi Status False */
          }
        } catch (error) {
          toast.error(i18nMessages.somethingWentWrong);
        }
      } else {
        const apiUrl = new URL(environment.api.baseUrl + 'ShippingWOInbound');
        try {
          const result = await http.post(
            apiUrl.toString(),
            workOrderInboundData
          );
          if (result) {
            toast.success(i18nMessages.AddSuccess);
            handleErrorStatusSave(false);
            /* To set Validation and Submit APi Status False */
            setIsNew(false);
            setValues({ ...values, id: result.data.id });
            values.id = result.data.id;
            navigate(RouteEnum.ViewShippingOrders + '/' + id + '/2');
          }
        } catch (error) {
          handleErrorStatusSave(false);
          toast.error(i18nMessages.somethingWentWrong);
        }
      }
    } else {
      handleErrorStatusSave(false);
    }
  };

  /* Validate Status Dropdown Error call from Status Component */
  const handleErrorStatusSave = (status: boolean = false) => {
    setValidateStatus(false);
    setIsStatusSaveCallApi(false);
  };

  const AssignedToData = () => {
    return (
      <>
        <Grid item lg={4}>
          <CustomizedSelect
            placeholder={defaultValue.label}
            options={assignmentTypeList}
            isSearchable={false}
            displayLabel={I18n('WorkOrder.AssignmentType')}
            handleChange={(newValue: any) => {
              setValues({
                ...values,
                assignmentType: newValue,
                assignmentTo: '',
              });
              setSelectedAssignmentTo(defaultValue);
            }}
            value={values.assignmentType}
            errorValue={true}
            errorMessage={errors.assignmentType}
            required={true}
            readOnly={!canEdit}
            key={+Math.random().toString(4)}
          ></CustomizedSelect>
        </Grid>
        <Grid item lg={4}>
          {canEdit ? (
            Number(values?.assignmentType.value) === AssignmentType.Partner ? (
              <CustomSelectPaginate
                displayLabel={I18n('WorkorderPickup.AssignedTo')}
                name="assignmentTo"
                id="assignmentTo"
                key={+selectedAssignmentTo.value}
                additional={defaultAdditional}
                value={selectedAssignmentTo}
                loadOptions={loadPartnerOptions}
                handleChange={(newValue: BaseModel) => {
                  setSelectedAssignmentTo(newValue);
                  setValues({ ...values, assignmentTo: newValue.value });
                }}
                isSearchable={true}
                errorValue={true}
                errorMessage={errors.assignmentTo}
                readOnly={!canEdit}
                debounceTimeout={500}
                isMultiSelect={false}
              />
            ) : (
              <CustomSelectPaginate
                displayLabel={I18n('WorkorderPickup.AssignedTo')}
                name="assignmentTo"
                id="assignmentTo"
                key={+Math.random().toString(4)}
                additional={defaultAdditional}
                value={selectedAssignmentTo}
                loadOptions={loadUserOptions}
                handleChange={(newValue: BaseModel) => {
                  setSelectedAssignmentTo(newValue);
                  setValues({ ...values, assignmentTo: newValue.value });
                }}
                isSearchable={true}
                errorValue={true}
                errorMessage={errors.assignmentTo}
                readOnly={!canEdit}
                debounceTimeout={500}
                isMultiSelect={false}
              />
            )
          ) : (
            <CustomizedInputs
              margin="normal"
              displayLabel={I18n('WorkorderPickup.AssignedTo')}
              name="assignmentTo"
              id="assignmentTo"
              inputType="text"
              errorValue={errors.assignmentTo}
              errorMessage={errors.assignmentTo}
              value={selectedAssignmentTo.label}
              readOnly={!canEdit}
            />
          )}
        </Grid>
        <Grid item lg={4}>
          <FormControl
            margin="normal"
            displayLabel={I18n('WorkOrder.Instructions')}
            inputType="text"
            inputProps={{ maxLength: 250 }}
            multiline={false}
            value={values.instructions}
            id="instructions"
            name="instructions"
            handleChange={(event: BaseSyntheticEvent) => {
              setValues({ ...values, instructions: event.target.value });
            }}
            readOnly={!canEdit}
            disabled={!canEdit}
          ></FormControl>
        </Grid>
      </>
    );
  };

  const InboundDetailsData = () => {
    return (
      <>
        <Grid item lg={4} md={4}>
          <FormControlLabel
            control={
              <Checkbox
                name="isInboundDone"
                id="isInboundDone"
                checked={values.isInboundDone}
                onChange={handleCheckboxChange}
                inputProps={{ 'aria-label': 'controlled' }}
                readOnly={!canEdit}
                disabled={!canEdit}
              />
            }
            label={I18n('WorkOrder.InboundDone')}
          />
        </Grid>
        <Grid item lg={4} md={4}>
          <DateTimePickerInput
            displayLabel={I18n('WorkOrder.ActualInboundDateTime')}
            name="actualInboundDateTime"
            value={values.actualInboundDateTime}
            defaultValue={today}
            handleChange={(event: Moment) => {
              setValues({
                ...values,
                actualInboundDateTime: event != null ? event.toDate() : '',
              });
            }}
            errorValue={true}
            errorMessage={errors.actualInboundDateTime}
            readOnly={!canEdit}
          ></DateTimePickerInput>
        </Grid>
        <>
          <Grid
            sx={{ mb: 2 }}
            container
            marginTop={2}
            paddingLeft={2}
            className="grid-wrap two-col mb-2"
          >
            <Grid item lg={12}>
              <InputLabel shrink htmlFor="bootstrap-input">
                {i18nMessages.Picture}
              </InputLabel>
              <ImageUploadPreview
                disableKeyboardNav={true}
                limit={10}
                fileData={fileDataFunction}
                fileRemove={deleteFileFunction}
                editedFileData={editedFileDataFunction}
                images={images}
                readOnly={!canEdit}
                sendToCustomer={true}
              />
            </Grid>
          </Grid>
          {!(images && images.length) && (
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item lg={12}>
                {i18nMessages.NoShippingDocsAvailable}
              </Grid>
            </Grid>
          )}
        </>
      </>
    );
  };

  return (
    <div>
      <div className="heading-section">
        <div className="heading-section-left">
          <Typography variant="h3" sx={{ mb: 3 }}>
            {i18nMessages.Title}
          </Typography>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>
      </div>
      <Grid container spacing={2} className="grid-wrap">
        <Grid item lg={12}>
          <div className="main-content-section width-100">
            <FormAccordianSave
              title={I18n('WorkOrder.AssignmentTo')}
              className="inner-heading"
              details={AssignedToData()}
              showActionButton={canEdit}
            ></FormAccordianSave>
            <FormAccordianSave
              title={I18n('WorkOrder.InboundDetails')}
              showActionButton={canEdit}
              className="inner-heading"
              details={InboundDetailsData()}
            ></FormAccordianSave>
            <MobileDetails
              mobileRemarks={values.mobileRemarks}
              waitTime={values.waitTime}
            ></MobileDetails>
            <Status
              permission={Permission.canEditShippingOrders}
              woId={ShippingOrder.Inbound}
              orderId={id}
              statusValue={finalStatus}
              assignedToId={selectedAssignmentTo?.value}
              assignedToName={selectedAssignmentTo?.label}
              type={selectedAssignmentTo?.value}
              activityOwner={
                Number(values.assignmentType.value) === 1
                  ? ActivityOwner.Driver
                  : Number(values.assignmentType.value) === 3
                  ? ActivityOwner.WareHouseStaff
                  : Number(values.assignmentType.value) === 2
                  ? ActivityOwner.Partner
                  : ''
              }
              orderType={OrderType.ShippingOrder}
              isStatusSaveCallApi={isStatusSaveCallApi}
              validateStatus={validateStatus}
              handleSuccessStatusSave={handleSuccessStatusSave}
              handleErrorStatusSave={handleErrorStatusSave}
              handleStatusChange={(value: number) => setFinalStatus(value)}
            />
            <div className="btn-wrap form-btn-wrap">
              {canEdit ? (
                <Button
                  type="submit"
                  color="primary"
                  disableElevation
                  variant="contained"
                  onClick={handleSaveClick}
                >
                  {I18n('Common.Save')}
                </Button>
              ) : (
                <></>
              )}
            </div>
            <CreatedByandUpdatedBy
              createdBy={details.createdBy}
              createdDate={details.createdDate}
              updatedBy={details.updatedBy}
              updatedDate={details.updatedDate}
            ></CreatedByandUpdatedBy>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default WorkOrderInbound;
