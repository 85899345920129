import { Button, Grid, Typography } from '@mui/material';
import FormControl from 'common/formControl/formControl';
import {
  getBasicDetailsById,
  getContactByUserId,
  saveNotificationDetails,
} from 'components/shipping-orders/ShippingApiService';
import { useEffect, useCallback, useState, BaseSyntheticEvent } from 'react';
import { Utilities } from 'utilities/Utilities';
import I18n from 'utilities/i18n';
import environment from 'environment';
import http from '../../utilities/httpService';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import RouteEnum from 'models/RouteEnum';
import { ShippingNotificationDetailsModel } from 'models/shipping-orders';

function NotificationDetails(props: any) {
  const { id } = useParams();
  const {
    shippingOrderId,
    isSubmit,
    setIsSubmit,
    isAddShipping,
    handleSubmitAdd,
  } = props;
  const [values, setValues] = useState<ShippingNotificationDetailsModel>({
    notificationEmail: '',
    language: '1',
  });
  const [errors, setErrors] = useState({
    notificationEmail: '',
  });
  const customerPortalUser = Utilities.getIsCPUser();
  const loggedInUserId = localStorage.getItem('userId');
  const i18nMessages = {
    notificationDetails: I18n('ShippingOrders.Tabs.NotificationDetails'),
    commonFieldIsRequired: I18n('Common.FieldIsRequired'),
    email: I18n('Common.Email'),
    commonInvalidEmail: I18n('Common.InvalidEmail'),
    send: I18n('SendEmail.Send'),
    sendEmailSuccessfully: I18n('SendEmail.SendSuccessfully'),
    errorOccurred: I18n('SendEmail.ErrorOccurred'),
    UpdateSuccess: I18n('ShippingOrders.UpdateSuccess'),
    somethingWentWrong: I18n('Common.SomethingWentWrong'),
    CommonSaveSuccessMsg: I18n('Common.SavedSuccessfully'),
  };
  const navigate = useNavigate();

  useEffect(() => {
    getDetails();
  }, []);

  useEffect(() => {
    if (isSubmit !== undefined && isSubmit) {
      handleSaveClick();
    }
  }, [isSubmit]);

  const getContactByUser = async () => {
    if (loggedInUserId) {
      const contactPerson = await getContactByUserId(Number(loggedInUserId));
      if (contactPerson?.result) {
        setValues({ ...values, notificationEmail: contactPerson.data.email });
      }
    }
  };

  const validate = (fieldValues: any = values) => {
    let temp: any = {};

    if ('notificationEmail' in fieldValues) {
      if (!fieldValues.notificationEmail) {
        temp.notificationEmail = i18nMessages.commonFieldIsRequired;
      } else if (
        fieldValues.notificationEmail &&
        !/$^|.+@.+..+/.test(fieldValues.notificationEmail)
      ) {
        temp.notificationEmail = i18nMessages.commonInvalidEmail;
      } else {
        temp.notificationEmail = '';
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === '');
    }
  };

  const handleSubmit = async () => {
    if (validate()) {
      await onSubmitSendEmail();
    }
  };

  const onSubmitSendEmail = async () => {
    const sendEmail = {
      shippingOrderId: Number(shippingOrderId),
      preferredLanguage: Number(values.language),
      emailSendTo: values.notificationEmail,
      shippingWODeliveryId: 0,
      shippingWorkOrderId: 0,
    };
    const apiUrl = new URL(
      environment.api.baseUrl +
        'ShippingWODelivery/SendPODWODeliveryDetailsEmail/' +
        Utilities.getTimeZone()
    );

    const result: any = await http.post(apiUrl.toString(), sendEmail);
    if (result !== null && result.data != null) {
      toast.success(i18nMessages.sendEmailSuccessfully);
      //   getWOEmailHistory();
    } else {
      toast.error(i18nMessages.errorOccurred);
    }
  };

  const handleSaveClick = () => {
    if (validate()) {
      saveNotificationDetails(Number(id), values).then(
        (response: { result: any }) => {
          if (response && response.result) {
            toast.success(i18nMessages.CommonSaveSuccessMsg);
          } else {
            toast.error(i18nMessages.somethingWentWrong);
          }
          if (isSubmit) {
            setIsSubmit(false);
            handleSubmitAdd(response);
          }
        }
      );
    } else {
      setIsSubmit(false);
    }
  };

  const getDetails = async () => {
    getBasicDetailsById(Number(id)).then(async (response: any) => {
      if (response) {
        if (
          response.notificationEmail !== undefined &&
          response.notificationEmail
        ) {
          setValues({
            notificationEmail: response.notificationEmail,
            language: '1',
          });
        } else if (customerPortalUser) {
          getContactByUser();
        }
      }
    });
  };

  return (
    <div>
      <Typography variant="h5" className="inner-heading has-button">
        {i18nMessages.notificationDetails}
      </Typography>
      <div className="inner-section">
        <Grid container spacing={2} className="grid-wrap ">
          <Grid item xs={4}>
            <FormControl
              margin="normal"
              value={values.notificationEmail}
              displayLabel={i18nMessages.email}
              id="notificationEmail"
              name="notificationEmail"
              inputType="text"
              inputProps={{ maxLength: 255 }}
              multiline={false}
              handleChange={(event: BaseSyntheticEvent) =>
                setValues({ ...values, notificationEmail: event.target.value })
              }
              errorValue={errors.notificationEmail}
              errorMessage={errors.notificationEmail}
              required={true}
            ></FormControl>
          </Grid>
        </Grid>
        {!isAddShipping && !customerPortalUser && (
          <div className="btn-wrap form-btn-wrap">
            <Button
              color="neutral"
              disableElevation
              variant="contained"
              onClick={() => navigate(RouteEnum.ShippingOrdersList)}
            >
              {I18n('Common.Cancel')}
            </Button>
            <Button
              type="submit"
              color="primary"
              disableElevation
              variant="contained"
              onClick={handleSaveClick}
            >
              {I18n('Common.Save')}
            </Button>
          </div>
        )}
        {/* <Grid item xs={4}>
            <Button
              onClick={handleSubmit}
              autoFocus
              color="primary"
              disableElevation
              variant="contained"
              sx={{ mt: 2.5 }}
            >
              {i18nMessages.send}
            </Button>
          </Grid> */}
      </div>
    </div>
  );
}

export default NotificationDetails;
