import { BaseModel } from 'models/pagination-model';
import {
  OrderTrackingResponseDto,
  OrderTrackingStateModel,
  TrackingTypeDescription,
} from 'models/order-tracking';
import { getStatusSuggestions, saveTracking } from './OrderTrackingApiService';
import { BaseSyntheticEvent, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Grid } from '@mui/material';
import I18n from 'utilities/i18n';
import { toast } from 'react-toastify';
import DateTimePickerInput from 'common/DatetimePicker/DateTimePickerInput';
import moment, { Moment } from 'moment';
import FormControl from 'common/formControl/formControl';
import { CustomizedSelect } from 'common/CustomSelect/CustomSelect';
import AutocompleteInputs from 'common/Autocomplete/AutocompleteInput';
import { Utilities } from 'utilities/Utilities';

export default function AddOrderTracking(props: any) {
  const { isOpen, handleClose, title, entityType, entityId, editData } = props;
  const [options, setOptions] = useState<readonly string[]>([]);
  const today = new Date().toISOString();
  const defaultTrackingTypeValue = {
    value: '',
    label: I18n('Placeholders.SelectTrackingType'),
  } as BaseModel;
  let trackingTypeList: Array<BaseModel> = [];
  const getTrackingTypeList = () => {
    trackingTypeList = [defaultTrackingTypeValue];
    TrackingTypeDescription.forEach((value: string, key: number) => {
      trackingTypeList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getTrackingTypeList();

  const [values, setValues] = useState<OrderTrackingStateModel>({
    id: 0,
    entityType,
    entityId,
    statusChangeDate: new Date(),
    status: '',
    type: defaultTrackingTypeValue,
    notes: '',
    isManualEntry: true,
  });

  const i18nMessages = {
    fieldRequired: I18n('Common.FieldRequired'),
    errorOccurred: I18n('Notes.ErrorOccurred'),
    updatedSuccessfully: I18n('Tracking.UpdateSuccess'),
    createdSuccessfully: I18n('Tracking.AddSuccess'),
  };

  const showMessageRefresh = () => {
    toast.success(
      values.id
        ? i18nMessages.updatedSuccessfully
        : i18nMessages.createdSuccessfully
    );
    setDefaultValuesForControls();
    props.refreshData();
  };

  const [errors, setErrors] = useState({
    status: '',
    type: '',
  });

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ('type' in fieldValues) {
      temp.type = fieldValues.type.value ? '' : i18nMessages.fieldRequired;
    }
    if ('status' in fieldValues) {
      temp.status = fieldValues.status ? '' : i18nMessages.fieldRequired;
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === '');
    }
  };

  const setDefaultValuesForControls = () => {
    setValues({
      entityType,
      entityId,
      statusChangeDate: new Date(),
      status: '',
      type: defaultTrackingTypeValue,
      notes: '',
      isManualEntry: true,
      id: 0,
    });
  };
  const submit = async () => {
    if (validate()) {
      try {
        const result = await saveTracking(values && values.id ? values.id : 0, {
          id: values.id,
          entityId,
          entityType,
          isManualEntry: values.isManualEntry,
          notes: values.notes,
          status: values.status,
          type:
            values && values?.type && values?.type.value
              ? +values.type.value
              : 0,
          statusChangeDate: moment(values.statusChangeDate).utc().format(),
        } as OrderTrackingResponseDto);

        if (editData == null && result.result && result.data) {
          showMessageRefresh();
        } else if (editData != null && result.result) {
          showMessageRefresh();
        } else {
          toast.error(i18nMessages.errorOccurred);
        }
      } catch (error: any) {
        toast.error(i18nMessages.errorOccurred);
      }
    }
  };

  const close = () => {
    setDefaultValuesForControls();
    props.handleClose();
  };

  useEffect(() => {
    if (editData) {
      setValues({
        id: editData.id,
        entityType,
        entityId,
        statusChangeDate: editData.statusChangeDate,
        status: editData.status,
        type: {
          label:
            trackingTypeList &&
            trackingTypeList.length &&
            trackingTypeList.some((x) => +x.value === editData.type)
              ? (trackingTypeList.find((x) => +x.value === editData.type)
                  ?.label as string)
              : '',
          value: Number(editData.type).toString(),
        } as BaseModel,
        notes: editData.notes,
        isManualEntry: true,
      });
    } else {
      setValues({
        id: 0,
        entityType,
        entityId,
        statusChangeDate: new Date(),
        status: '',
        type: defaultTrackingTypeValue,
        notes: '',
        isManualEntry: true,
      });
    }
  }, [editData]);
  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={'sm'}
        fullWidth={true}
      >
        <DialogTitle>
          {title}{' '}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className="grid-wrap two-col">
            <Grid item xs={12}>
              <DateTimePickerInput
                displayLabel={I18n('Tracking.StatusChangeDate')}
                name="statusChangeDate"
                value={values.statusChangeDate}
                defaultValue={today}
                handleChange={(event: Moment) => {
                  setValues({
                    ...values,
                    statusChangeDate: event.toDate(),
                  });
                }}
              ></DateTimePickerInput>
            </Grid>
            <Grid item xs={12}>
              <AutocompleteInputs
                value={values.status}
                inputProps={{ maxLength: 50 }}
                options={options}
                displayLabel={I18n('Tracking.Status')}
                handleOptionChange={async (newValue: string) => {
                  if (newValue) {
                    const response = await getStatusSuggestions(newValue);
                    if (response) {
                      setOptions(response);
                    }
                  }
                }}
                handleChange={(newValue: string) => {
                  setValues({
                    ...values,
                    status: newValue,
                  });
                }}
                errorValue={errors.status}
                errorMessage={errors.status}
              ></AutocompleteInputs>
            </Grid>
            <Grid item xs={12}>
              <CustomizedSelect
                defaultValue={trackingTypeList[0]}
                options={trackingTypeList}
                isSearchable={false}
                displayLabel={I18n('Tracking.Type')}
                handleChange={(newValue: BaseModel) =>
                  setValues({
                    ...values,
                    type: newValue,
                  })
                }
                value={values.type}
              ></CustomizedSelect>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                margin="normal"
                displayLabel={I18n('Tracking.Notes')}
                id="notes"
                name="notes"
                inputType="text"
                inputProps={{ maxLength: 150 }}
                multiline={false}
                handleChange={(event: BaseSyntheticEvent) => {
                  setValues({
                    ...values,
                    notes: event.target.value,
                  });
                }}
                value={values.notes}
              ></FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="btn-wrap">
          <Button
            onClick={close}
            color="neutral"
            disableElevation
            variant="contained"
          >
            {' '}
            {I18n('Notes.Cancel')}
          </Button>
          <Button
            onClick={submit}
            autoFocus
            color="primary"
            disableElevation
            variant="contained"
          >
            {I18n('Notes.Save')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
