import { BaseModel } from './pagination-model';
import { ProductCatalogueAssignmentType } from './product-catalogue';

export interface OrderTrackingStateModel {
  id: number;
  entityType: ProductCatalogueAssignmentType;
  entityId: number;
  statusChangeDate: Date;
  status: string;
  type: BaseModel;
  notes: string;
  isManualEntry: boolean;
}

export enum TrackingType {
  Internal = 1,
  External = 2,
}

export const TrackingTypeDescription = new Map<number, string>([
  [TrackingType.Internal, 'Tracking.Internal'],
  [TrackingType.External, 'Tracking.External'],
]);

export interface OrderTrackingBaseDTO {
  entityType: ProductCatalogueAssignmentType;
  entityId: number;
  statusChangeDate: Date | string;
  status: string;
  type: TrackingType;
  notes: string;
  isManualEntry: boolean;
}

export interface OrderTrackingDTO extends OrderTrackingBaseDTO {
  id: number;
  selectedStatus: BaseModel;
}

export interface OrderTrackingResponseDto extends OrderTrackingDTO {
  createdBy: string;
  createdDate: string;
  updatedBy: string;
  updatedDate: string;
}
