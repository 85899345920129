import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Breadcrumbs } from '@mui/material';
import I18n from '../../utilities/i18n';
import Link from '@mui/material/Link';
import { useNavigate, useParams } from 'react-router-dom';
import RouteEnum from '../../models/RouteEnum';
import Note from '../../common/notes/Notes';
import DocumentList from '../../common/Document/DocumentList';
import { DocumentEntity, EntityTypeEnum } from '../../models/pagination-model';
import DeckPackWorkOrderList from './DeckPackWorkOrderList';
import DecPackShippingMaterial from './DecPackShippingMaterial';
import DecpackDetails from './DecPackDetails';
import { Permission } from 'Permissions';
import CreditNote from 'common/CreditNote/CreditNote';
import OrderApproval from 'components/OrderApproval/OrderApproval';
import { useState } from 'react';

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function DeckPackOrderTabs() {
  let { id, tabId } = useParams();
  let navigate = useNavigate();
  const handleListClick = () => {
    navigate(RouteEnum.DecPackOrdersList);
  };
  const breadcrumbs = [
    <Typography key="3" color="text.primary">
      {I18n('DecPackOrders.DeckPack')}
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={handleListClick}
        className="cpointer"
      >
        {I18n('Nav.DecPackOrders')}
      </Link>
    </Typography>,
    <Typography key="3" color="text.primary">
      {I18n('DecPackOrders.Details')}
    </Typography>,
  ];
  const [value, setValue] = React.useState(tabId ? Number(tabId) : 0);
  const [isOrderEnable, setIsOrderEnable] = useState(true);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleOrderStatus = (value: boolean) => {
    setIsOrderEnable(value);
  };

  return (
    <div>
      <div className="heading-section">
        <div className="heading-section-left">
          <Typography variant="h3" sx={{ mb: 3 }}>
            {I18n('DecPackOrders.Details')}
          </Typography>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>
      </div>
      <div className="main-content-section width-100">
        <Box>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            className="tab-bordered"
          >
            <Tab label={I18n('DecPackOrders.Tabs.Details')} {...a11yProps(0)} />
            <Tab
              label={I18n('DecPackOrders.Tabs.WorkOrder')}
              {...a11yProps(1)}
            />
            <Tab
              label={I18n('DecPackOrders.Tabs.BillableItems')}
              {...a11yProps(2)}
            />
            <Tab
              label={I18n('DecPackOrders.Tabs.Documents')}
              {...a11yProps(3)}
            />
            <Tab label={I18n('DecPackOrders.Tabs.Notes')} {...a11yProps(4)} />
            <Tab
              label={I18n('DecPackOrders.Tabs.Approval')}
              {...a11yProps(5)}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <DecpackDetails
            handleOrderStatus={handleOrderStatus}
          ></DecpackDetails>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <DeckPackWorkOrderList
            isOrderEnable={isOrderEnable}
            handleOrderStatus={handleOrderStatus}
          ></DeckPackWorkOrderList>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <DecPackShippingMaterial
            shippingOrderId={id ? id : 0}
            isAddShippingMaterial={false}
          ></DecPackShippingMaterial>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <DocumentList
            permission={Permission.canEditDecPackOrders}
            addPermission={Permission.canAddDecPackOrders}
            deletePermission={Permission.canDeleteDecPackOrders}
            entityType={EntityTypeEnum.DecPackOrders}
            paramsId={id}
            documentEntityType={DocumentEntity.DecPackOrders}
            isViewOnly={!isOrderEnable}
          />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Note
            permission={Permission.canEditDecPackOrders}
            addPermission={Permission.canAddDecPackOrders}
            deletePermission={Permission.canDeleteDecPackOrders}
            paramsId={id}
            entityType={EntityTypeEnum.DecPackOrders}
            isViewOnly={!isOrderEnable}
          />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <OrderApproval
            approvalPermission={Permission.canApproveDecPackOrders}
            entityType={EntityTypeEnum.DecPackOrders}
            entityId={id}
            isOrderEnable={isOrderEnable}
            handleIsOrderEnable={handleOrderStatus}
          />
        </TabPanel>
      </div>
    </div>
  );
}
