import enLabels from 'i18nConstants/en.constants';
import frLabels from 'i18nConstants/fr.constants';
import nlLabels from 'i18nConstants/nl.constants';

const Constants = {
  en: enLabels,
  fr: frLabels,
  nl: nlLabels,
};

export default Constants;
