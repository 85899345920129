import TabList from '@mui/lab/TabList';
import TabContext from '@mui/lab/TabContext';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import RouteEnum from 'models/RouteEnum';
import { Link } from '@mui/material';
import { useEffect, useState, SyntheticEvent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import I18n from 'utilities/i18n';
import { Breadcrumbs } from '@mui/material';
import TabPanel from '@mui/lab/TabPanel';
import Note from 'common/notes/Notes';
import {
  DocumentEntity,
  EntityTypeEnum,
  PricingType,
  ProductCatalogueType,
} from 'models/pagination-model';
import SpecialPricing from 'common/special-pricing/SpecialPricing';
import environment from 'environment';
import http from '../../utilities/httpService';
import AllowedOrderCategory from 'common/AllowedOrderCategory/AllowedOrderCategory';
import ProductCatalogueDetails from './ProductCatalogueDetails';
import CurrentCheckoutList from 'components/product-catalogue/product-catalogue-current-checkout/CurrentCheckoutList';
import ProductPurchaseList from 'components/product-catalogue-purchase/ProductPurchaseList';
import RemoveStockList from './product-catalouge-remove-stock/RemoveStockList';
import ProductConsumptionList from 'components/product-catalogue-consumption/ProductConsumptionList';
import AssignmentList from './product-catalouge-assignment/AssignmentList';
import DocumentList from 'common/Document/DocumentList';
import { Permission } from 'Permissions';
export default function ProductCatalogueTabs() {
  let navigate = useNavigate();
  const handleListClick = () => {
    navigate(RouteEnum.ProductCatalogue);
  };
  const breadcrumbs = [
    <Typography key="3" color="text.primary">
      {I18n('Nav.Configuration')}
    </Typography>,
    <Typography key="3" color="text.primary">
      {I18n('Nav.Products')}
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={handleListClick}
        className="cpointer"
      >
        {I18n('Nav.ProductCatalogue')}
      </Link>
    </Typography>,
    <Typography key="3" color="text.primary">
      {I18n('ProductCatalogues.Details')}
    </Typography>,
  ];
  const i18nMessages = {
    noteTabTitle: I18n('ProductCatalogues.Tabs.Notes'),
    documentTabTitle: I18n('ProductCatalogues.Tabs.Documents'),
    allowedOrderCategoryTabTitle: I18n(
      'ProductCatalogues.Tabs.AllowedOrderCategory'
    ),
    detailsTabTitle: I18n('ProductCatalogues.Tabs.Details'),
    specialPricingTabTitle: I18n('ProductCatalogues.Tabs.SpecialPricing'),
    purchasesTabTitle: I18n('ProductCatalogues.Tabs.Purchases'),
    consumptionsTabTitle: I18n('ProductCatalogues.Tabs.Consumptions'),
    currentCheckoutTabTitle: I18n('ProductCatalogues.Tabs.CurrentCheckout'),
    assignmentTabTitle: I18n('ProductCatalogues.Tabs.Assignment'),
    removeStockTabTitle: I18n('ProductCatalogues.Tabs.RemoveStock'),
    singleUseTypeTitle: I18n('ProductCatalogues.SingleUse'),
    reusableTypeTitle: I18n('ProductCatalogues.Reusables'),
  };
  const [productCatalogueType, setProductCatalogueType] = useState(0);
  const [pricingType, setPricingType] = useState(PricingType.Standard);

  const { entityId } = useParams();
  const getProductCatalogueById = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl + `ProductCatalogues/${entityId}`
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result && result.data) {
        setProductCatalogueType(result.data.productCatalogueType);
        setPricingType(result.data.pricingType);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getProductCatalogueById();
  }, []);

  const [value, setValue] = useState<string>('1');
  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const handleSaveDetails = (status: boolean) => {};
  return (
    <div>
      <div className="heading-section">
        <div className="heading-section-left">
          <Typography variant="h3" sx={{ mb: 3 }}>
            {I18n('ProductCatalogues.Details')}&nbsp;
            {productCatalogueType &&
              `(${
                productCatalogueType === ProductCatalogueType.SingleUse
                  ? i18nMessages.singleUseTypeTitle
                  : i18nMessages.reusableTypeTitle
              })`}
          </Typography>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>
      </div>
      <div className="main-content-section width-100">
        {(productCatalogueType === ProductCatalogueType.SingleUse && (
          <TabContext value={value}>
            <Box>
              <TabList onChange={handleChange}>
                <Tab label={i18nMessages.detailsTabTitle} value="1" />
                {pricingType === PricingType.Special && (
                  <Tab label={i18nMessages.specialPricingTabTitle} value="2" />
                )}

                <Tab
                  label={i18nMessages.allowedOrderCategoryTabTitle}
                  value="3"
                />
                <Tab label={i18nMessages.purchasesTabTitle} value="4" />
                <Tab label={i18nMessages.consumptionsTabTitle} value="5" />
                <Tab label={i18nMessages.documentTabTitle} value="6" />
                <Tab label={i18nMessages.noteTabTitle} value="7" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <ProductCatalogueDetails onSaveDetails={handleSaveDetails} />
            </TabPanel>
            {pricingType === PricingType.Special && (
              <TabPanel value="2">
                <SpecialPricing
                  editPermission={Permission.canEditProductCatalogueManagement}
                  addPermission={Permission.canAddProductCatalogueManagement}
                  deletePermission={
                    Permission.canDeleteProductCatalogueManagement
                  }
                  entityType={EntityTypeEnum.ProductCatalogue}
                />
              </TabPanel>
            )}
            <TabPanel value="3">
              <AllowedOrderCategory
                permission={Permission.canEditProductCatalogueManagement}
                entityType={EntityTypeEnum.ProductCatalogue}
              />
            </TabPanel>
            <TabPanel value="4">
              <ProductPurchaseList
                permission={Permission.canEditProductCatalogueManagement}
                entityType={EntityTypeEnum.ProductCatalogue}
              />
            </TabPanel>
            <TabPanel value="5">
              <ProductConsumptionList
                permission={Permission.canEditProductCatalogueManagement}
                entityType={EntityTypeEnum.ProductCatalogue}
              />
            </TabPanel>
            <TabPanel value="6">
              <DocumentList
                permission={Permission.canEditProductCatalogueManagement}
                addPermission={Permission.canAddProductCatalogueManagement}
                deletePermission={
                  Permission.canDeleteProductCatalogueManagement
                }
                entityType={EntityTypeEnum.ProductCatalogue}
                paramsId={entityId}
                documentEntityType={DocumentEntity.ProductCatalogue}
              />
            </TabPanel>
            <TabPanel value="7">
              <Note
                permission={Permission.canEditProductCatalogueManagement}
                addPermission={Permission.canAddProductCatalogueManagement}
                deletePermission={
                  Permission.canDeleteProductCatalogueManagement
                }
                paramsId={entityId}
                entityType={EntityTypeEnum.ProductCatalogue}
              />
            </TabPanel>
          </TabContext>
        )) ||
          (productCatalogueType === ProductCatalogueType.Reusable && (
            <TabContext value={value}>
              <Box>
                <TabList onChange={handleChange}>
                  <Tab label={i18nMessages.detailsTabTitle} value="1" />
                  {pricingType === PricingType.Special && (
                    <Tab
                      label={i18nMessages.specialPricingTabTitle}
                      value="2"
                    />
                  )}

                  <Tab
                    label={i18nMessages.allowedOrderCategoryTabTitle}
                    value="3"
                  />
                  <Tab label={i18nMessages.currentCheckoutTabTitle} value="4" />
                  <Tab label={i18nMessages.assignmentTabTitle} value="5" />
                  <Tab label={i18nMessages.purchasesTabTitle} value="6" />
                  <Tab label={i18nMessages.removeStockTabTitle} value="7" />
                  <Tab label={i18nMessages.documentTabTitle} value="8" />
                  <Tab label={i18nMessages.noteTabTitle} value="9" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <ProductCatalogueDetails />
              </TabPanel>
              {pricingType === PricingType.Special && (
                <TabPanel value="2">
                  <SpecialPricing
                    editPermission={
                      Permission.canEditProductCatalogueManagement
                    }
                    addPermission={Permission.canAddProductCatalogueManagement}
                    deletePermission={
                      Permission.canDeleteProductCatalogueManagement
                    }
                    entityType={EntityTypeEnum.ProductCatalogue}
                  />
                </TabPanel>
              )}
              <TabPanel value="3">
                <AllowedOrderCategory
                  permission={Permission.canEditProductCatalogueManagement}
                  redirect={RouteEnum.ProductCatalogue}
                  entityType={EntityTypeEnum.ProductCatalogue}
                />
              </TabPanel>
              <TabPanel value="4">
                <CurrentCheckoutList
                  entityType={EntityTypeEnum.ProductCatalogue}
                  permission={''}
                />
              </TabPanel>
              <TabPanel value="5">
                <AssignmentList></AssignmentList>
              </TabPanel>
              <TabPanel value="6">
                <ProductPurchaseList
                  permission={Permission.canEditProductCatalogueManagement}
                  entityType={EntityTypeEnum.ProductCatalogue}
                />
              </TabPanel>
              <TabPanel value="7">
                <RemoveStockList
                  entityType={EntityTypeEnum.ProductCatalogue}
                  permission={''}
                />
              </TabPanel>
              <TabPanel value="8">
                <DocumentList
                  permission={Permission.canEditProductCatalogueManagement}
                  addPermission={Permission.canAddProductCatalogueManagement}
                  deletePermission={
                    Permission.canDeleteProductCatalogueManagement
                  }
                  entityType={EntityTypeEnum.ProductCatalogue}
                  paramsId={entityId}
                  documentEntityType={DocumentEntity.ProductCatalogue}
                />
              </TabPanel>
              <TabPanel value="9">
                <Note
                  permission={Permission.canEditProductCatalogueManagement}
                  addPermission={Permission.canAddProductCatalogueManagement}
                  deletePermission={
                    Permission.canDeleteProductCatalogueManagement
                  }
                  paramsId={entityId}
                  entityType={EntityTypeEnum.ProductCatalogue}
                />
              </TabPanel>
            </TabContext>
          ))}
      </div>
    </div>
  );
}
