import I18n from 'utilities/i18n';
import { useEffect, useCallback, useState } from 'react';
import http from '../../utilities/httpService';
import { OrderByDirection } from 'models/pagination-model';
import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faPlus } from '@fortawesome/free-solid-svg-icons';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Location as EntityLocation } from 'models/location';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from 'common/formControl/formControl';
import CustomDataGrid from 'common/datagrid/datagrid';
import { useParams } from 'react-router-dom';
import CustomizedInputs from 'common/formControl/formControl';
import Typography from '@mui/material/Typography';
import {
  GridActionsCellItem,
  GridColumns,
  GridPaginationInitialState,
  GridRowId,
  GridRowParams,
  GridSortingInitialState,
  GridSortModel,
} from '@mui/x-data-grid';
import environment from 'environment';
import ConfirmationPopup from 'common/confirmationPopup/confirmationPopup';
import { toast } from 'react-toastify';
import { Permission } from 'Permissions';
import { hasPermission } from 'utilities/protectedRoute';
import { Breadcrumbs } from '@mui/material';
import LabelDetail from './LabelDetail';

function LabelList() {
  const breadcrumbs = [
    <Typography key="3" color="text.primary">
      {I18n('Nav.Reference')}
    </Typography>,
    <Typography key="3" color="text.primary">
      {I18n('Nav.Labels')}
    </Typography>,
  ];

  const [isDeleteModalOpen, setDeleteModal] = useState(false);
  const [isAddModalOpen, setAddModal] = useState(false);
  const [selectedLabelId, setLabelId] = useState(0);
  const [gridData, setGridData] = useState({
    isLoading: true,
    sortOrder: OrderByDirection.Descending,
    sortBy: '',
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [10, 20, 50, 100],
    pageSize: 10,
    page: 0,
    searchExpression: '',
  });
  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));
  const getLabels = async () => {
    updateGridData('isLoading', true);

    const apiUrl = new URL(environment.api.baseUrl + 'LabelMaster');
    try {
      apiUrl.searchParams.set('$filter', gridData.searchExpression);
      apiUrl.searchParams.set('$orderby', gridData.sortBy);
      apiUrl.searchParams.set(
        '$skip',
        (gridData.page * gridData.pageSize).toString()
      );
      apiUrl.searchParams.set('$top', gridData.pageSize.toString());
      apiUrl.searchParams.set(
        '$orderbydirection',
        gridData.sortOrder.toString()
      );
      const result = await http.get(apiUrl.toString());
      if (result) {
        updateGridData('totalRows', result.data.totalCount);
        updateGridData('rows', result.data.values);
        updateGridData('isLoading', false);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getLabels();
  }, [
    gridData.page,
    gridData.pageSize,
    gridData.sortBy,
    gridData.sortOrder,
    gridData.searchExpression,
  ]);

  const handleChangePage = (pageNo: number) => {
    updateGridData('page', pageNo);
  };

  const handleChangeBasicSearch = (searchExpression: any) => {
    updateGridData(
      'searchExpression',
      'city.name~like~' +
        searchExpression.target.value +
        ' or country.name~like~' +
        searchExpression.target.value +
        ' or postalcode~like~' +
        searchExpression.target.value +
        ' or address2~like~' +
        searchExpression.target.value +
        ' or shipper~like~' +
        searchExpression.target.value +
        ' or consignee~like~' +
        searchExpression.target.value
    );
  };
  const handleChangePageSize = (pageSize: number) => {
    updateGridData('pageSize', pageSize);
  };
  type Row = (typeof gridData.rows)[number];
  const deleteSelectedLabel = useCallback(
    (id: any) => () => {
      setDeleteModal(true);
      setLabelId(id);
    },
    []
  );

  const openSelectedLabel = useCallback(
    (id: any) => () => {
      setAddModal(true);
      setLabelId(id);
    },
    []
  );

  const columns: GridColumns<Row> = [
    {
      field: 'shipper',
      headerName: I18n('LabelMaster.Shipper'),
      width: 168,
    },
    {
      field: 'consignee',
      headerName: I18n('LabelMaster.Consignee'),
      width: 168,
    },
    {
      field: 'emergencyPhoneNumber',
      headerName: I18n('LabelMaster.EmergencyPhoneNumber'),
      flex: 1,
    },
    {
      field: 'street',
      headerName: I18n('LabelMaster.Address'),
      flex: 1,
    },
    {
      field: 'address2',
      headerName: I18n('LabelMaster.Address2'),
      flex: 1,
    },
    {
      field: 'postalCode',
      headerName: I18n('Partner.PostalCode'),
      flex: 1,
    },
    {
      field: 'city',
      headerName: I18n('LabelMaster.City'),
      flex: 1,
    },
    {
      field: 'country',
      headerName: I18n('LabelMaster.Country'),
      flex: 1,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: I18n('Common.Actions'),
      width: 100,
      getActions: (params: GridRowParams<EntityLocation>) => {
        const actionButtons = [];
        if (hasPermission(Permission.canEditLabelMaster)) {
          actionButtons.push(
            <GridActionsCellItem
              onPointerEnterCapture={false}
              onPointerLeaveCapture={false}
              placeholder={''}
              icon={<EditIcon />}
              label="Edit"
              onClick={openSelectedLabel(params.id)}
            />
          );
        }
        if (hasPermission(Permission.canDeleteLabelMaster)) {
          actionButtons.push(
            <GridActionsCellItem
              onPointerEnterCapture={false}
              onPointerLeaveCapture={false}
              placeholder={''}
              icon={<DeleteIcon />}
              label="Delete"
              onClick={deleteSelectedLabel(params.id)}
            />
          );
        }

        return actionButtons;
      },
    },
  ];

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    if (sortModel && sortModel.length) {
      if (sortModel[0].field === 'city') {
        updateGridData('sortBy', 'city.name');
      } else if (sortModel[0].field === 'country') {
        updateGridData('sortBy', 'country.name');
      } else {
        updateGridData('sortBy', sortModel[0].field);
      }
      updateGridData(
        'sortOrder',
        sortModel[0].sort === 'asc'
          ? OrderByDirection.Ascending
          : OrderByDirection.Descending
      );
    }
  }, []);

  const addLabel = useCallback(() => {
    setAddModal(true);
  }, []);

  const handleClose = () => {
    setAddModal(false);
    setLabelId(0);
  };

  const OnSave = async () => {
    handleClose();
    await getLabels();
  };

  const handleCloseForDelete = async () => {
    setDeleteModal(false);
  };

  const handleOkForDelete = () => {
    setDeleteModal(false);
    deleteLabel(selectedLabelId);
  };

  const deleteLabel = async (id: any) => {
    setLabelId(0);
    updateGridData('isLoading', true);
    const apiUrl = new URL(environment.api.baseUrl + 'LabelMaster/' + id);
    try {
      const result: any = await http.delete(apiUrl.toString());
      if (result) {
        toast.success('Label deleted successfully');
        updateGridData('isLoading', false);
        await getLabels();
      }
    } catch (error: any) {}
  };

  return (
    <div>
      <div className="heading-section">
        <div className="heading-section-left">
          <Typography variant="h3" sx={{ mb: 3 }}>
            {I18n('Nav.Labels')}
          </Typography>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>

        <div className="heading-section-right">
          <div className="search-control-outer">
            <FormControl
              margin="normal"
              displayLabel=""
              required
              inputType="text"
              placeholderText={I18n('Common.Search')}
              className="search-control small-form-control"
              handleChange={handleChangeBasicSearch}
              adornmentValue={
                <InputAdornment position="start">
                  <FontAwesomeIcon icon={faSearch} />
                </InputAdornment>
              }
            ></FormControl>
          </div>
          <div className="header-btn-wrap">
            {hasPermission(Permission.canAddLabelMaster) ? (
              <Button
                color="info"
                className="small-btn"
                disableElevation
                variant="contained"
                onClick={addLabel}
              >
                <FontAwesomeIcon icon={faPlus} className="fa-md mr-2" />
                Add
              </Button>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>

      <div className="main-content-section">
        <div className="content-block table-fix">
          <CustomDataGrid
            rowCount={gridData.totalRows}
            data={gridData.rows}
            columns={columns}
            pageNo={gridData.page}
            pageSize={gridData.pageSize}
            onSortModelChange={handleSortModelChange}
            onPageChange={handleChangePage}
            onPageSizeChange={handleChangePageSize}
            rowsPerPageOptions={gridData.rowsPerPageOptions}
            initialState={{
              sorting: {
                sortModel: [{ field: 'country.name', sort: 'desc' }],
              } as GridSortingInitialState,
              pagination: {
                page: gridData.page,
                pageSize: gridData.pageSize,
              } as GridPaginationInitialState,
            }}
          ></CustomDataGrid>
        </div>

        <LabelDetail
          isOpen={isAddModalOpen}
          OnSave={OnSave}
          handleClose={handleClose}
          title={I18n('LabelMaster.AddLabel')}
          selectedLabelId={selectedLabelId}
        ></LabelDetail>

        <ConfirmationPopup
          isOpen={isDeleteModalOpen}
          data={selectedLabelId}
          message={I18n('LabelMaster.DeleteConfirmation')}
          handleClose={handleCloseForDelete}
          handleOk={handleOkForDelete}
        ></ConfirmationPopup>
      </div>
    </div>
  );
}

export default LabelList;
