import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CustomSelectPaginate from 'common/CustomSelect/CustomSelectPaginate';
import { loadRejectionMasterOptions } from 'components/shipping-orders/ShippingApiService';
import { BaseModel } from 'models/pagination-model';
import { useState } from 'react';
import I18n from 'utilities/i18n';

export default function RejectionPopup(props: any) {
  const {
    isOpen,
    handleClose,
    handleOk,
    message,
    title,
    hasCancel = true,
  } = props;

  const [rejectionMasterId, setRejectionMasterId] = useState<any>();
  const [error, setError] = useState('');

  const defaultRejectionValue = {
    value: '',
    label: I18n('Placeholders.SelectRejection'),
  } as BaseModel;

  const defaultAdditional: any = {
    page: 1,
  };

  const handleOkClick = () => {
    if (rejectionMasterId) {
      setError('');
      handleOk(rejectionMasterId.value);
    } else {
      setError(I18n('Common.FieldRequired'));
    }
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
          <br></br>

          <CustomSelectPaginate
            additional={defaultAdditional}
            loadOptions={(search: string, prevOptions: any, page: any) =>
              loadRejectionMasterOptions(search, prevOptions, page)
            }
            handleChange={(newValue: any) => setRejectionMasterId(newValue)}
            placeholder={defaultRejectionValue.label}
            isSearchable={true}
            displayLabel={I18n('ShippingOrders.RejectionCode')}
            errorValue={true}
            debounceTimeout={500}
            isMultiSelect={false}
            errorMessage={error}
          />
        </DialogContent>
        <DialogActions>
          {hasCancel ? (
            <Button onClick={handleClose}>
              {I18n('UserList.DeleteModal.Cancel')}
            </Button>
          ) : (
            <></>
          )}
          <Button onClick={handleOkClick} autoFocus>
            {I18n('UserList.DeleteModal.Ok')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
