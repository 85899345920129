import I18n from 'utilities/i18n';
import { useEffect, useState } from 'react';
import http from '../../../utilities/httpService';

import {
  BaseModel,
  DnPWorkOrderStatusDescription,
  EntityWorkOrderStatusDescription,
  OrderType,
  WorkOrderStatus,
} from 'models/pagination-model';
import { Grid } from '@mui/material';
import FormAccordianSave from 'common/formControl/formAccordianSave';
import environment from 'environment';

import { toast } from 'react-toastify';

import CustomizedSelect from 'common/CustomSelect/CustomSelect';
import { hasPermission } from 'utilities/protectedRoute';
import { useNavigate, useParams } from 'react-router-dom';
import RouteEnum from 'models/RouteEnum';
import { DnPWorkOrders } from 'models/deck-pack-orders';
import CustomSelectPaginate from 'common/CustomSelect/CustomSelectPaginate';
import { loadUserOptions } from '../DeckPackApiService';
function DecpackStatus(props: any) {
  const {
    woId,
    orderId,
    statusValue,
    activityOwner,
    orderType,
    permission,
    handleSuccessStatusSave,
    handleErrorStatusSave,
    handleStatusChange,
    isSaveNext,
    showAssigned = true,
    isMobile = false,
    workOrderNo,
    isCustomerReferenceAvailable = false,
  } = props;
  let navigate = useNavigate();
  const defaultValue = {
    value: '0',
    label: I18n('Placeholders.SelectStatus'),
  } as BaseModel;
  const defaultAdditional: any = {
    page: 1,
  };

  const defaultValueForAssignment = {
    value: '0',
    label: I18n('Placeholders.Select'),
  } as BaseModel;
  const [selectAssignment, setSelectAssignment] = useState(
    defaultValueForAssignment
  );

  const [canEdit, setCanEdit] = useState(hasPermission(permission));
  const [anyWOAlreadyInProgress, setAnyWOAlreadyInProgress] = useState(false);

  let entityWorkOrderStatusList: Array<BaseModel> = [];
  const getStatusList = () => {
    entityWorkOrderStatusList = [defaultValue];

    DnPWorkOrderStatusDescription.forEach((value: string, key: number) => {
      entityWorkOrderStatusList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getStatusList();

  const getWOStatus = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl +
        'DecPackOrder/GetDecPackWO/' +
        orderId +
        '/' +
        workOrderNo
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result && result.data) {
        if (
          result.data.isMobile &&
          result.data.assignedToId &&
          result.data.assignedToName
        ) {
          setSelectAssignment({
            value: result.data.assignedToId.toString(),
            label: result.data.assignedToName,
          });
        }

        if (
          !result.data.isMobile &&
          result.data.assignedNextToId &&
          result.data.assignedNextToName
        ) {
          setSelectAssignment({
            value: result.data.assignedNextToId.toString(),
            label: result.data.assignedNextToName,
          });
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (orderType === OrderType.DecPackOrder) {
      AnyWOAlreadyInProgress();
    }
    //getWOStatus();
  }, []);

  useEffect(() => {
    if (props.statusValue) {
      const selectedStatus = entityWorkOrderStatusList.find(
        (x) => x.value == props.statusValue
      );
      setValues({
        status: selectedStatus ? selectedStatus : values.status,
      });
    }
  }, [props.statusValue]);

  // useEffect(() => {
  //   if (
  //     isMobile &&
  //     props.assigneeData.assignedToId &&
  //     props.assigneeData.assignedToName
  //   ) {
  //     setSelectAssignment({
  //       value: props.assigneeData.assignedToId,
  //       label: props.assigneeData.assignedToName,
  //     });
  //   }

  //   if (
  //     !isMobile &&
  //     props.assigneeData.assignNextToId &&
  //     props.assigneeData.assignNextToName
  //   ) {
  //     setSelectAssignment({
  //       value: props.assigneeData.assignNextToId,
  //       label: props.assigneeData.assignNextToName,
  //     });
  //   }
  // }, [props.assigneeData]);

  useEffect(() => {
    if (props.isStatusSaveCallApi) {
      handleSaveClick();
    }
  }, [props.isStatusSaveCallApi]);

  useEffect(() => {
    if (props.validateStatus) {
      if (validate()) {
        if (props.woId === 1) {
          handleSaveClick();
        }
        handleSuccessStatusSave();
      } else {
        handleErrorStatusSave();
      }
    }
  }, [props.validateStatus]);

  const [errors, setErrors] = useState({
    status: '',
  });

  const [values, setValues] = useState<any>({
    status: 0,
  });

  const onStatusChange = (data: any) => {
    setValues({
      ...values,
      status: { value: Number(data?.value), label: data.label },
    });
    handleStatusChange(Number(data?.value));
  };

  const i18nMessages = {
    commonFieldIsRequired: I18n('Common.FieldIsRequired'),
    statusSavedSuccess: I18n('Booking.StatusSavedSuccess'),
    Status: I18n('Booking.Status'),
    ToDo: I18n('Common.ToDo'),
    InProgress: I18n('Common.InProgress'),
    WOAlreadyInProgress: I18n('Common.WOAlreadyInProgress'),
    errorOccurred: I18n('WorkOrder.ErrorOccurred'),
    assignedTo: I18n('WorkorderPickup.AssignedTo'),
    assignedNextTo: I18n('WorkorderPickup.AssignedNextTo'),
  };

  const validate = (fieldValues: any = values) => {
    let temp = { ...errors };
    if (fieldValues.status.value === 0) {
      fieldValues.status = 0;
    }
    if ('status' in fieldValues) {
      temp.status = fieldValues.status
        ? ''
        : i18nMessages.commonFieldIsRequired;
    }

    if (
      temp.status === '' &&
      fieldValues.status &&
      fieldValues.status.value == WorkOrderStatus.InProgress &&
      orderType === OrderType.DecPackOrder
    ) {
      temp.status = anyWOAlreadyInProgress
        ? i18nMessages.WOAlreadyInProgress
        : '';
    }

    setErrors({
      ...temp,
    });
    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === '');
    }
  };

  const AnyWOAlreadyInProgress = async () => {
    let isWOAlreadyInProgress = false;
    const apiUrl = new URL(
      environment.api.baseUrl + 'DecPackOrder/GetDecPackWO/' + orderId
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result) {
        isWOAlreadyInProgress = result.data.decPackWorkOrdersDto.some(
          (item: any) =>
            item.status == WorkOrderStatus.InProgress &&
            item.workOrderNo != woId
        );
      }
      setAnyWOAlreadyInProgress(isWOAlreadyInProgress);
    } catch (error) {
      isWOAlreadyInProgress = false;
    }
    return isWOAlreadyInProgress;
  };

  const setAssigneeData = () => {
    let assignedData: any = {};
    if (selectAssignment.value == defaultValueForAssignment.value) {
      assignedData = isMobile
        ? {
            value: props.assigneeData.assignedToId,
            label: props.assigneeData.assignedToName,
          }
        : {
            value: props.assigneeData.assignNextToId,
            label: props.assigneeData.assignNextToName,
          };
    } else {
      assignedData = selectAssignment;
    }

    return assignedData;
  };

  const getDecPackNextWorkOrder = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl +
        `DecPackOrder/GetDecPackNextWorkOrder/${orderId}`
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result && result.data) {
        // Commented code to update awaiting assignment for 862.
        // let assignedData = setAssigneeData();
        // let isAssignedToApplicable = !isMobile
        //   ? assignedData.value == '0' || assignedData.value == undefined
        //     ? true
        //     : false
        //   : false;
        // if (isAssignedToApplicable && result.data.isMobile) {
        //   let finalValue: any = {
        //     workOrderNo: result.data.workOrderNo,
        //     status: WorkOrderStatus.AwaitingAssignment,
        //     decPackInitialOrderId: Number(orderId),
        //     woDate: new Date().toISOString(),
        //     assignedToId: null,
        //   };
        //   let apiUrl = new URL(
        //     environment.api.baseUrl + 'DecPackOrder/UpdateWOStatus'
        //   );
        //   const response = await http.put(apiUrl.toString(), finalValue);
        //   if (response) {
        //   }
        // }

        redirectData(result);
      }
    } catch (error: any) {
      toast.error(i18nMessages.errorOccurred);
    }
  };

  const handleSaveClick = async () => {
    let finalValue;
    let apiUrl;

    let assignedData = setAssigneeData();
    let isNextMobileWorkOrder = false;

    // change api end point to fetch next work order
    const nextWorkorderResponse = await http.get(
      new URL(
        environment.api.baseUrl +
          `DecPackOrder/GetNextWorkOrderDetails/${orderId}/${workOrderNo}`
      ).toString()
    );
    if (nextWorkorderResponse && nextWorkorderResponse.data) {
      isNextMobileWorkOrder = nextWorkorderResponse.data.isMobile;
    }

    if (isSaveNext) {
      values.status.value =
        !isMobile &&
        (Number(assignedData.value) === 0 ||
          assignedData.value === undefined) &&
        isNextMobileWorkOrder // For 862
          ? WorkOrderStatus.AwaitingAssignment.toString()
          : WorkOrderStatus.Done.toString();
    }
    if (
      !isSaveNext &&
      (values.status.value == WorkOrderStatus.ToDo.toString() ||
        values.status.value == WorkOrderStatus.InProgress.toString()) &&
      workOrderNo != DnPWorkOrders.WO11
    ) {
      values.status.value =
        workOrderNo == DnPWorkOrders.WO10 && isCustomerReferenceAvailable
          ? WorkOrderStatus.Done.toString()
          : // for 862 : web wo and assigned next to 0 and next wo is mobile
          !isMobile &&
            (Number(assignedData.value) === 0 ||
              assignedData.value === undefined) &&
            isNextMobileWorkOrder
          ? WorkOrderStatus.AwaitingAssignment.toString()
          : WorkOrderStatus.InProgress.toString();
    } else if (workOrderNo == DnPWorkOrders.WO11) {
      values.status.value = WorkOrderStatus.Done.toString();
    }
    finalValue = {
      workOrderNo: Number(woId),
      status: Number(values.status.value),
      decPackInitialOrderId: Number(orderId),
      woDate: new Date().toISOString(),
      assignedToId: isMobile
        ? Number(assignedData.value) === 0
          ? null
          : Number(assignedData.value)
        : null,
      assignedNextToId: !isMobile
        ? Number(assignedData.value) === 0
          ? null
          : Number(assignedData.value)
        : null,
    };

    if (activityOwner) {
      finalValue['activityOwner'] = activityOwner;
    }
    apiUrl = new URL(environment.api.baseUrl + 'DecPackOrder/UpdateWOStatus');
    try {
      const result = await http.put(apiUrl.toString(), finalValue);
      if (result) {
        if (!isSaveNext) {
          navigate(RouteEnum.ViewDecPackOrders + '/' + orderId + '/1');
        }
        if (isSaveNext) {
          if (nextWorkorderResponse && nextWorkorderResponse.data) {
            redirectData(nextWorkorderResponse);
          } else {
            navigate(RouteEnum.ViewDecPackOrders + '/' + orderId + '/1');
          }
        }
      }
    } catch (error: any) {
      if (error.response) {
        if (error.response.data) {
          for (const item of error.response.data.errors) {
            if (item.errorMessage === 'An error occured: WOAlreadyInProgress') {
              toast.error(i18nMessages.WOAlreadyInProgress);
            }
          }
        }
      }
    }
  };
  const redirectData = (result: any) => {
    if (result.data.isMobile) {
      navigate(RouteEnum.ViewDecPackOrders + '/' + orderId + '/1');
    }

    if (
      result.data.workOrderNo === DnPWorkOrders.WO2 &&
      !result.data.isMobile
    ) {
      navigate(
        RouteEnum.ViewDecPackOrders +
          '/' +
          orderId +
          '/DGDCreation/' +
          result.data.id
      );
    }
    if (
      result.data.workOrderNo === DnPWorkOrders.WOS &&
      !result.data.isMobile
    ) {
      navigate(
        RouteEnum.ViewDecPackOrders +
          '/' +
          orderId +
          '/SecurityCheckRA/' +
          result.data.id
      );
    }
    if (
      result.data.workOrderNo === DnPWorkOrders.WOIBO &&
      !result.data.isMobile
    ) {
      navigate(
        RouteEnum.ViewDecPackOrders +
          '/' +
          orderId +
          '/InBound/' +
          result.data.id
      );
    }
    if (
      result.data.workOrderNo === DnPWorkOrders.WO3 &&
      !result.data.isMobile
    ) {
      navigate(
        RouteEnum.ViewDecPackOrders +
          '/' +
          orderId +
          '/DecPackWOCollectionData/' +
          result.data.id
      );
    }
    if (
      result.data.workOrderNo === DnPWorkOrders.WO4 &&
      !result.data.isMobile
    ) {
      navigate(
        RouteEnum.ViewDecPackOrders +
          '/' +
          orderId +
          '/CheckDocumentations/' +
          result.data.id
      );
    }
    if (
      result.data.workOrderNo === DnPWorkOrders.WO5 &&
      !result.data.isMobile
    ) {
      navigate(
        RouteEnum.ViewDecPackOrders +
          '/' +
          orderId +
          '/DecPackWOMaterial/' +
          result.data.id
      );
    }
    if (
      result.data.workOrderNo === DnPWorkOrders.WO6 &&
      !result.data.isMobile
    ) {
      navigate(
        RouteEnum.ViewDecPackOrders +
          '/' +
          orderId +
          '/Execution/' +
          result.data.id
      );
    }
    if (
      result.data.workOrderNo === DnPWorkOrders.WO6bis &&
      !result.data.isMobile
    ) {
      navigate(
        RouteEnum.ViewDecPackOrders +
          '/' +
          orderId +
          '/DecPackWO6Bis/' +
          result.data.id
      );
    }
    if (
      result.data.workOrderNo === DnPWorkOrders.Wo6ter &&
      !result.data.isMobile
    ) {
      navigate(
        RouteEnum.ViewDecPackOrders +
          '/' +
          orderId +
          '/DecPackWOOrderDelivery/' +
          result.data.id
      );
    }
    if (
      result.data.workOrderNo === DnPWorkOrders.WO7 &&
      !result.data.isMobile
    ) {
      navigate(
        `${RouteEnum.ViewDecPackOrders}/${orderId}/${RouteEnum.DeckPackIrregularityContactClient}/${result.data.id}`
      );
    }
    if (
      result.data.workOrderNo === DnPWorkOrders.WO8 &&
      !result.data.isMobile
    ) {
      navigate(
        RouteEnum.ViewDecPackOrders +
          '/' +
          orderId +
          '/WOEIGHT/' +
          result.data.id
      );
    }
    if (
      result.data.workOrderNo === DnPWorkOrders.WO9 &&
      !result.data.isMobile
    ) {
      navigate(
        RouteEnum.ViewDecPackOrders +
          '/' +
          orderId +
          '/PhotoCheck/' +
          result.data.id
      );
    }
    if (
      result.data.workOrderNo === DnPWorkOrders.WOOBO &&
      !result.data.isMobile
    ) {
      navigate(
        RouteEnum.ViewDecPackOrders + '/' + orderId + '/OBOWO/' + result.data.id
      );
    }
    if (
      result.data.workOrderNo === DnPWorkOrders.WO10 &&
      !result.data.isMobile
    ) {
      navigate(
        RouteEnum.ViewDecPackOrders + '/' + orderId + '/WOTEN/' + result.data.id
      );
    }
    if (
      result.data.workOrderNo === DnPWorkOrders.WO11 &&
      !result.data.isMobile
    ) {
      navigate(
        RouteEnum.ViewDecPackOrders +
          '/' +
          orderId +
          '/CustomerReference/' +
          result.data.id
      );
    }
  };
  const StatusInformation = () => {
    let assignedData = setAssigneeData();
    return (
      <>
        {showAssigned ? (
          <Grid item lg={4} md={8}>
            <CustomSelectPaginate
              displayLabel={
                isMobile ? i18nMessages.assignedTo : i18nMessages.assignedNextTo
              }
              name="assignmentTo"
              id="assignmentTo"
              //key={+selectAssignment.value}
              additional={defaultAdditional}
              value={assignedData}
              loadOptions={loadUserOptions}
              handleChange={(newValue: BaseModel) => {
                setSelectAssignment(newValue);
              }}
              isSearchable={true}
              readOnly={!canEdit}
              debounceTimeout={500}
              isMultiSelect={false}
            />
          </Grid>
        ) : (
          <></>
        )}
        <Grid item lg={4} md={8}>
          <CustomizedSelect
            placeholder={defaultValue.label}
            options={entityWorkOrderStatusList}
            value={values.status}
            isSearchable={false}
            displayLabel={i18nMessages.Status}
            readOnly={!canEdit}
            handleChange={(newValue: BaseModel) => {
              onStatusChange(newValue);
              if (newValue.value !== '0') {
                setErrors({
                  ...errors,
                  status: '',
                });
              }
            }}
            errorValue={true}
            errorMessage={errors.status}
          ></CustomizedSelect>
        </Grid>
      </>
    );
  };

  return (
    <div>
      <FormAccordianSave
        title={'Status'}
        className="inner-heading"
        details={StatusInformation()}
        showActionButton={!canEdit}
      ></FormAccordianSave>
    </div>
  );
}

export default DecpackStatus;
