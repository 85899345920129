import { Breadcrumbs, Button, Grid, Link, Typography } from '@mui/material';
import { CustomizedInputs } from 'common/formControl/formControl';

import environment from 'environment';
import { DnPWorkOrders } from 'models/deck-pack-orders';
import {
  BaseModel,
  DGDDescription,
  ActivityOwner,
  OrderType,
} from 'models/pagination-model';
import RouteEnum from 'models/RouteEnum';
import { Permission } from 'Permissions';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import I18n from 'utilities/i18n';
import http from '../../../utilities/httpService';
import { getDecPackById, getWorkOrderDetails } from '../DeckPackApiService';
import DecPackShippingMaterial from '../DecPackShippingMaterial';
import FormAccordianSave from 'common/formControl/formAccordianSave';
import { hasPermission } from 'utilities/protectedRoute';
import DecpackStatus from './DecpackStatus';
import CreatedByandUpdatedBy from 'common/CreatedByandUpdatedBy/CreatedByandUpdatedBy';
import MobileDetails from 'common/MobileDetails/MobileDetails';

function WO8() {
  const { woId, id } = useParams();
  const [status, setStatus] = useState('');
  const [details, setDetails] = useState<any>({
    createdBy: '',
    createdDate: '',
    updatedBy: '',
    updatedDate: '',
  });

  const [assigneeData, setAssigneeData] = useState<any>({
    assignedToId: '',
    assignedToName: '',
    assignNextToId: '',
    assignNextToName: '',
  });

  const [mobileData, setMobileData] = useState<any>({
    mobileRemarks: '',
    waitTime: 0,
  });

  const [isMobile, setIsMobile] = useState(false);
  let navigate = useNavigate();
  const breadcrumbs = [
    <Typography key="3" color="text.primary">
      {I18n('Nav.Order')}
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={() => navigate(RouteEnum.DecPackOrdersList)}
        className="cpointer"
      >
        {I18n('Nav.DecPackOrders')}
      </Link>
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={() => navigate(RouteEnum.ViewDecPackOrders + '/' + id + '/1')}
        className="cpointer"
      >
        {I18n('ShippingOrders.WorkOrders')}
      </Link>
    </Typography>,
  ];
  const defaultValue = {
    value: '0',
    label: I18n('Placeholders.Select'),
  } as BaseModel;

  const i18nMessages = {
    title: I18n('DecPackOrders.WorkOrder.BillOfMaterial'),
    updatedSuccessfully: I18n('WorkOrder.UpdatedSuccessfully'),
    errorOccurred: I18n('WorkOrder.ErrorOccurred'),
    sendSuccessfully: I18n('WorkOrder.SendSuccessfully'),
    Notes: I18n('WorkOrder.Notes'),
    SaveNext: I18n('WorkOrder.SaveNext'),
  };

  const [notes, setNotes] = useState('');
  const [approvedBOM, setApprovedBOM] = useState<boolean>(true);

  const [isSaveNext, setIsSaveNext] = useState<boolean>(true);

  const [values, setValues] = useState<any>({
    id: 0,
    availableData: 0,
    status: 0,
    assignedTo: '',
  });
  const [canEdit, setCanEdit] = useState(
    hasPermission(Permission.canEditDecPackOrders)
  );
  const [isStatusSaveCallApi, setIsStatusSaveCallApi] = useState(false); // To call Submit APi Status from Status Component
  const [validateStatus, setValidateStatus] = useState<boolean>(false); // validateStatus - To validate Status Dropdown from Status Component

  let entityWorkOrderStatusList: Array<BaseModel> = [];
  const getStatusList = () => {
    entityWorkOrderStatusList = [defaultValue];
    DGDDescription.forEach((value: string, key: number) => {
      entityWorkOrderStatusList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getStatusList();

  const getWOStatus = async () => {
    const apiUrl = new URL(
      environment.api.baseUrl +
        'DecPackOrder/GetDecPackWO/' +
        id +
        '/' +
        DnPWorkOrders.WO8
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result) {
        setDetails({
          ...details,
          createdBy: result.data.createdBy,
          updatedBy: result.data.updatedBy,
          createdDate: result.data.createdDate,
          updatedDate: result.data.updatedDate,
        });

        setAssigneeData({
          ...assigneeData,
          assignedToId: result.data.assignedToId,
          assignedToName: result.data.assignedToName,
          assignNextToId: result.data.assignedNextToId,
          assignNextToName: result.data.assignedNextToName,
        });

        setIsMobile(result.data.isMobile);
        setStatus(result.data.status);
        return result.data.status;
      }
    } catch (error) {}
  };
  const NotesData = () => {
    return (
      <>
        <Grid item lg={12} md={12}>
          <CustomizedInputs
            placeholder={defaultValue.label}
            displayLabel={i18nMessages.Notes}
            handleChange={(newValue: any) => {
              setNotes(newValue.target.value);
            }}
            value={notes}
            readOnly={!canEdit}
          ></CustomizedInputs>
        </Grid>
      </>
    );
  };
  const handleNotesClick = async (event: any) => {
    event.preventDefault();
    setValidateStatus(true);
    setIsSaveNext(false);
  };
  const getDecPackByDetailsId = async () => {
    const response: any = await getDecPackById(id ? +id : 0);
    if (response) {
      if (response.wo8Notes) {
        setNotes(response.wo8Notes);
      }
      setApprovedBOM(response.approvedBOM);
    }

    const mobileDataRes = await getWorkOrderDetails(
      id ? +id : 0,
      DnPWorkOrders.WO8
    );
    if (mobileDataRes) {
      setMobileData({
        ...mobileData,
        mobileRemarks: mobileDataRes.data?.mobileRemarks,
        waitTime: mobileDataRes.data?.waitTime,
      });
    }
  };

  // Validate Status Dropdown Success call from Status Component
  const handleSuccessStatusSave = async () => {
    try {
      const apiUrl = new URL(
        environment.api.baseUrl + 'DecPackOrder/UpdateWO8'
      );
      var data = {
        id: Number(id),
        Wo8Notes: notes,
      };
      const result: any = await http.put(apiUrl.toString(), data);
      if (result) {
        setIsStatusSaveCallApi(true);
        toast.success(i18nMessages.updatedSuccessfully);
      } else {
        toast.error(i18nMessages.errorOccurred);
        getWOStatus();
      }
    } catch (error: any) {
      toast.error(i18nMessages.errorOccurred);
      getWOStatus();
    }
  };

  const refreshData = async () => {
    handleErrorStatusSave();
    getWOStatus();
  };

  // Validate Status Dropdown Error call from Status Component
  const handleErrorStatusSave = () => {
    setValidateStatus(false);
    setIsStatusSaveCallApi(false);
  };

  const onSaveNextClick = async (event: any) => {
    event.preventDefault();
    setValidateStatus(true);
    setIsSaveNext(true);
  };

  useEffect(() => {
    getWOStatus();
    getDecPackByDetailsId();
  }, []);

  return (
    <>
      <div className="heading-section">
        <div className="heading-section-left">
          <Typography variant="h3" sx={{ mb: 3 }}>
            {i18nMessages.title}
          </Typography>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>
      </div>
      <div className="main-content-section">
        <DecPackShippingMaterial
          shippingOrderId={id ? id : 0}
          isAddShippingMaterial={false}
          showFileUpload={false}
          approvedBOM={approvedBOM}
          isFromWO={true}
        ></DecPackShippingMaterial>

        <Grid item lg={6} md={6}>
          <FormAccordianSave
            title={i18nMessages.Notes}
            details={NotesData()}
            showActionButton={!canEdit}
            className="inner-heading"
          ></FormAccordianSave>
        </Grid>
        <MobileDetails
          mobileRemarks={mobileData.mobileRemarks}
          waitTime={mobileData.waitTime}
        ></MobileDetails>
        <Grid item lg={12} md={12}>
          <DecpackStatus
            permission={Permission.canEditDecPackOrders}
            woId={DnPWorkOrders.WO8}
            activityOwner={ActivityOwner.Driver}
            orderId={id}
            statusValue={status}
            orderType={OrderType.DecPackOrder}
            isStatusSaveCallApi={isStatusSaveCallApi}
            validateStatus={validateStatus}
            handleSuccessStatusSave={handleSuccessStatusSave}
            handleErrorStatusSave={handleErrorStatusSave}
            isSaveNext={isSaveNext}
            isMobile={isMobile}
            workOrderNo={DnPWorkOrders.WO8}
            assigneeData={assigneeData}
            handleStatusChange={(value: number) => setStatus(value.toString())}
          />
        </Grid>
        <div className="btn-wrap form-btn-wrap">
          {canEdit ? (
            <Button
              color="primary"
              disableElevation
              variant="contained"
              onClick={onSaveNextClick}
            >
              {I18n('Common.SaveNext')}
            </Button>
          ) : (
            <></>
          )}
          &nbsp; &nbsp; &nbsp;
          {canEdit ? (
            <Button
              type="submit"
              color="primary"
              disableElevation
              variant="contained"
              onClick={handleNotesClick}
            >
              {I18n('Common.Save')}
            </Button>
          ) : (
            <></>
          )}
        </div>
        <CreatedByandUpdatedBy
          createdBy={details.createdBy}
          createdDate={details.createdDate}
          updatedBy={details.updatedBy}
          updatedDate={details.updatedDate}
        ></CreatedByandUpdatedBy>
      </div>
    </>
  );
}

export default WO8;
