import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import FormControl from 'common/formControl/formControl';
import environment from 'environment';
import I18n from 'utilities/i18n';
import {
  BaseModel,
  EntityStatus,
  EntityStatusDescription,
} from 'models/pagination-model';
import http from '../../utilities/httpService';
import FormAccordian from 'common/formControl/formAccordian';
import CustomizedSelect from 'common/CustomSelect/CustomSelect';
import { Link, Typography } from '@mui/material';
import RouteEnum from 'models/RouteEnum';
import CustomBreadcrumbs from 'common/Breadcrumbs/Breadcrumbs';

function AddRoles(props: any) {
  const nav = useNavigate();
  const defaultValue = {
    value: '0',
    label: I18n('Placeholders.Select'),
  } as BaseModel;

  const defaultValueForStatus = {
    value: EntityStatus.Active.toString(),
    label: I18n('Common.Active'),
  } as BaseModel;

  const breadcrumbs = [
    <Typography key="3" color="text.primary">
      {I18n('Nav.Configuration')}
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={() => nav('/Roles')}
        className="cpointer"
      >
        {I18n('Nav.Roles')}
      </Link>
    </Typography>,
    <Typography key="3" color="text.primary">
      {I18n('Nav.AddRole')}
    </Typography>,
  ];

  const [values, setValues] = useState({
    roleName: '',
    status: defaultValueForStatus,
  });

  useState<boolean>(true);

  let entityStatusList: Array<BaseModel> = [];
  const getStatusList = () => {
    EntityStatusDescription.forEach((value: string, key: number) => {
      entityStatusList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };

  let navigate = useNavigate();

  const [errors, setErrors] = useState({
    name: '',
  });

  const i18nMessages = {
    ErrorOccurred: I18n('Common.ErrorOccurred'),
    SavedSuccessfully: I18n('Common.SavedSuccessfully'),
    DuplicateRecord: I18n('Common.DuplicateRecord'),
  };

  const validate = (fieldValues: any = values) => {
    let temp = { ...errors };

    if ('roleName' in fieldValues) {
      temp.name = fieldValues.roleName ? '' : 'This field is required.';
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === '');
    }
  };

  const handleSubmit = async () => {
    if (validate()) {
      const apiUrl = new URL(environment.api.baseUrl + 'Roles');
      try {
        var roles = {
          Name: values.roleName,
          Status: +values.status.value,
        };
        const result: any = await http.post(apiUrl.toString(), roles);
        if (result !== null && result.data !== null && result.data.success) {
          toast.success(i18nMessages.SavedSuccessfully);
          navigate(RouteEnum.Roles);
        } else if (
          !result.data.success &&
          result.data.message === 'DuplicateRoleName'
        ) {
          toast.error(i18nMessages.DuplicateRecord);
        } else {
          toast.error(i18nMessages.ErrorOccurred);
        }
      } catch (error: any) {}
    }
  };

  const StatusDetails = () => {
    return (
      <>
        <Grid item lg={6}>
          <FormControl
            margin="normal"
            displayLabel={I18n('RoleList.Name')}
            id="roleName"
            inputType="text"
            inputProps={{ maxLength: 50 }}
            errorValue={errors.name}
            errorMessage={errors.name}
            handleChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setValues({ ...values, roleName: event.target.value })}
            value={values.roleName}
            multiline={false}
            required={true}
          ></FormControl>
        </Grid>
        <Grid item lg={6}>
          <CustomizedSelect
            placeholder={defaultValue.label}
            options={entityStatusList}
            isSearchable={false}
            displayLabel={I18n('ContactPerson.Status')}
            handleChange={(newValue: BaseModel) =>
              setValues({ ...values, status: newValue })
            }
            value={values.status}
          ></CustomizedSelect>
        </Grid>
      </>
    );
  };

  const close = () => {
    navigate(RouteEnum.Roles);
  };
  getStatusList();

  useEffect(() => {}, []);

  return (
    <>
      <div className="heading-section">
        <div className="heading-section-left">
          <Typography variant="h3" sx={{ mb: 3 }}>
            {I18n('Nav.Roles')}
          </Typography>
          <CustomBreadcrumbs breadcrumbs={breadcrumbs} />
        </div>
      </div>
      <Grid container spacing={2} className="grid-wrap">
        <Grid item lg={12} md={12}>
          <FormAccordian
            title={I18n('Customer.Details')}
            details={StatusDetails()}
            expanded={true}
            addMode={true}
            isSaveButton={true}
            onSaveClick={handleSubmit}
            isCancelButton={true}
            onCancelClick={close}
          ></FormAccordian>
        </Grid>
      </Grid>
    </>
  );
}

export default AddRoles;
