import environment from 'environment';
import http from '../../utilities/httpService';
import { toast } from 'react-toastify';
import I18n from 'utilities/i18n';
import { BaseModel, PaginationResponseModel } from 'models/pagination-model';

const i18nMessages = {
  saveSuccessfully: I18n('Common.SavedSuccessfully'),
  errorOccured: I18n('Common.ErrorOccurred'),
};
export const PostCatagoryDropDown: any = async (
  Datavalue: any,
  strSuccessMsg: any,
  strErrMsg: any
) => {
  const apiUrl = new URL(environment.api.baseUrl + 'Dropdown');
  try {
    const result: any = await http.post(apiUrl.toString(), Datavalue);
    if (result) {
      toast.success(strSuccessMsg);
      const newOption = {
        label: result.data.name,
        value: result.data.id,
      };
      return newOption;
    } else {
      toast.error(strErrMsg);
    }
  } catch (error: any) {}
};

export const PostCountryDropDown: any = async (
  Datavalue: any,
  strSuccessMsg: any,
  strErrMsg: any
) => {
  const apiUrl = new URL(environment.api.baseUrl + 'Country');
  try {
    const result: any = await http.post(apiUrl.toString(), Datavalue);
    if (result) {
      toast.success(strSuccessMsg);
      const newOption = {
        label: result.data.name,
        value: result.data.id,
      };
      return newOption;
    } else {
      toast.error(strErrMsg);
    }
  } catch (error: any) {}
};

export const PostUnitDropDown: any = async (
  Datavalue: any,
  strSuccessMsg: any,
  strErrMsg: any
) => {
  const apiUrl = new URL(
    environment.api.baseUrl + 'ShippingPackage/UnitMaster'
  );
  try {
    const result: any = await http.post(apiUrl.toString(), Datavalue);
    if (result) {
      toast.success(strSuccessMsg);
      const newOption = {
        label: result.data.name,
        value: result.data.id,
      };
      return newOption;
    } else {
      toast.error(strErrMsg);
    }
  } catch (error: any) {}
};

export const PostCurrencyDropDown: any = async (
  Datavalue: any,
  strSuccessMsg: any,
  strErrMsg: any
) => {
  const apiUrl = new URL(
    environment.api.baseUrl + 'ShippingPackage/CurrencyMaster'
  );
  try {
    const result: any = await http.post(apiUrl.toString(), Datavalue);
    if (result) {
      toast.success(strSuccessMsg);
      const newOption = {
        label: result.data.name,
        value: result.data.id,
      };
      return newOption;
    } else {
      toast.error(strErrMsg);
    }
  } catch (error: any) {}
};

export const PostCityDropDown: any = async (
  Datavalue: any,
  strSuccessMsg: any,
  strErrMsg: any
) => {
  const apiUrl = new URL(environment.api.baseUrl + 'Cities');
  try {
    const result: any = await http.post(apiUrl.toString(), Datavalue);
    if (result) {
      toast.success(strSuccessMsg);
      const newOption = {
        label: result.data.name,
        value: result.data.id,
      };
      return newOption;
    } else {
      toast.error(strErrMsg);
    }
  } catch (error: any) {}
};

export const PostTypeCheckDropDown: any = async (
  Datavalue: any,
  strSuccessMsg: any,
  strErrMsg: any
) => {
  const apiUrl = new URL(
    environment.api.baseUrl + 'DecPackCheckDocumentation/DecPackTypeCheckMaster'
  );
  try {
    const result: any = await http.post(apiUrl.toString(), Datavalue);
    if (result) {
      toast.success(strSuccessMsg);
      const newOption = {
        label: result.data.name,
        value: result.data.id,
      };
      return newOption;
    } else {
      toast.error(strErrMsg);
    }
  } catch (error: any) {}
};
export const GetLatLongByPostalCode: any = async (
  PostalCode: string,
  Place: string,
  latitude: string,
  longitude: string
) => {
  let coordinates = { longitude, latitude };
  try {
    if (PostalCode && PostalCode !== undefined) {
      const apiUrl = new URL(`https://api.mapbox.com/geocoding/v5/mapbox.places/
     ${PostalCode},${Place}.json?`);
      apiUrl.searchParams.set('autocomplete', 'true');
      apiUrl.searchParams.set('language', 'nl,en');
      apiUrl.searchParams.set('types', 'address,postcode');
      apiUrl.searchParams.set('limit', '10');
      apiUrl.searchParams.set('access_token', `${environment.api.mapboxToken}`);
      const response = await http.get(apiUrl.toString());
      if (response && response.data.features && response.data.features.length) {
        let locationIndex = -1;
        if (response.data.features.length > 1) {
          response.data.features.forEach((element: any, index: number) => {
            element.context.forEach((v: any, i: number) => {
              if (v.id.indexOf('place') >= 0) {
                if (
                  String(v.text_en)
                    .toLowerCase()
                    .trim()
                    .indexOf(String(Place).toLowerCase().trim()) >= 0
                ) {
                  locationIndex = index;
                }
              }
            });
          });
        }

        if (locationIndex === -1) {
          const firstPostcode = response.data.features.findIndex(
            (x: any) => !x.id.indexOf('postcode')
          );
          locationIndex = firstPostcode >= 0 ? firstPostcode : locationIndex;
        }

        const geoData = response.data.features[locationIndex];
        if (geoData.geometry?.coordinates) {
          coordinates.longitude =
            longitude === String(geoData.geometry?.coordinates[0])
              ? longitude
              : String(geoData.geometry?.coordinates[0]);
          coordinates.latitude =
            latitude === String(geoData.geometry?.coordinates[1])
              ? latitude
              : String(geoData.geometry?.coordinates[1]);
        }
      }
    }
  } catch (error) {}
  return coordinates;
};

export const loadLedgerOptions = async (
  search: string,
  loadedOptions: any,
  { page }: any
) => {
  const response = await getLedgerAccounts(search, page);
  const hasMore = Math.ceil(response.TotalRecords / 500) > page;
  return {
    options: response.Values.map((x) => {
      return {
        value: x.id.toString(),
        label: x.code,
      } as BaseModel;
    }),
    hasMore,
    additional: {
      page: page + 1,
    },
  };
};

const getLedgerAccounts = async (search: string = '', pageNo: number = 1) => {
  let response: PaginationResponseModel<any>;
  const apiUrl = new URL(environment.api.baseUrl + 'LedgerAccounts');
  try {
    let searchExpression = ``;
    searchExpression += search ? `code~like~${search}` : '';
    apiUrl.searchParams.set('$filter', searchExpression);
    apiUrl.searchParams.set('$orderby', 'code');
    apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
    apiUrl.searchParams.set('$top', '10');
    apiUrl.searchParams.set('$orderbydirection', 'desc');
    const result = await http.get(apiUrl.toString());
    if (result) {
      response = {
        TotalRecords: result.data.totalCount,
        Values: result.data.values,
      } as PaginationResponseModel<any>;
    } else {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<any>;
    }
  } catch (error) {
    response = {
      TotalRecords: 0,
      Values: [],
    } as PaginationResponseModel<any>;
  }
  return response;
};

export const loadItemGroupOptions = async (
  search: string,
  loadedOptions: any,
  { page }: any
) => {
  const response = await getItemGroups(search, page);
  const hasMore = Math.ceil(response.TotalRecords / 500) > page;
  return {
    options: response.Values.map((x) => {
      return {
        value: x.id.toString(),
        label: x.code,
      } as BaseModel;
    }),
    hasMore,
    additional: {
      page: page + 1,
    },
  };
};

const getItemGroups = async (search: string = '', pageNo: number = 1) => {
  let response: PaginationResponseModel<any>;
  const apiUrl = new URL(environment.api.baseUrl + 'ItemGroups');
  try {
    let searchExpression = ``;
    searchExpression += search ? `code~like~${search}` : '';
    apiUrl.searchParams.set('$filter', searchExpression);
    apiUrl.searchParams.set('$orderby', 'code');
    apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
    apiUrl.searchParams.set('$top', '10');
    apiUrl.searchParams.set('$orderbydirection', 'desc');
    const result = await http.get(apiUrl.toString());
    if (result) {
      response = {
        TotalRecords: result.data.totalCount,
        Values: result.data.values,
      } as PaginationResponseModel<any>;
    } else {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<any>;
    }
  } catch (error) {
    response = {
      TotalRecords: 0,
      Values: [],
    } as PaginationResponseModel<any>;
  }
  return response;
};

export const loadTransportTypeOption = async (
  search: string,
  loadedOptions: any,
  { page }: any
) => {
  const response = await GetTransportTypeOption(search, page);
  const hasMore = Math.ceil(response.TotalRecords / 500) > page;
  return {
    options: response.Values.map((x: any) => {
      return {
        value: x.id.toString(),
        label: x.code,
      } as BaseModel;
    }),
    hasMore,
    additional: {
      page: page + 1,
    },
  };
};

export const GetTransportTypeOption: any = async () => {
  let response: PaginationResponseModel<any>;
  const apiUrl = new URL(environment.api.baseUrl + 'Partners/GetPartnerType');
  try {
    const result = await http.get(apiUrl.toString());
    if (result) {
      response = {
        TotalRecords: result.data.totalCount,
        Values: result.data.values,
      } as PaginationResponseModel<any>;
    } else {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<any>;
    }
  } catch (error) {
    response = {
      TotalRecords: 0,
      Values: [],
    } as PaginationResponseModel<any>;
  }
  return response;
};
