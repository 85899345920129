import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import I18n from 'utilities/i18n';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { Grid } from '@mui/material';
import CustomizedInputs from 'common/formControl/formControl';
import environment from 'environment';
import http from '../../utilities/httpService';
import { toast } from 'react-toastify';

export default function AddCity(props: any) {
  const { isOpen, onClose, onSubmit, title, locationData } = props;

  const i18nMessages = {
    AddedSuccessfully: I18n('Cities.AddedSuccessfully'),
    ErrorOccurred: I18n('Cities.ErrorOccurred'),
    City: I18n('Cities.City'),
    RequiredErrMsg: I18n('Cities.Validation.CityRequired'),
    AlreadyExist: I18n('Cities.AlreadyExist'),
  };

  const [errors, setErrors] = useState({
    name: '',
  });

  const [values, setValues] = useState({
    id: 0,
    name: '',
  });

  useEffect(() => {
    if (locationData.place.label !== 'Select') {
      setValues({ ...values, name: locationData.place.label });
    }
  }, []);

  const validate = (name: string) => {
    let temp = { ...errors };

    if (!name) {
      temp.name = i18nMessages.RequiredErrMsg;
    } else {
      temp.name = '';
    }

    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === '');
  };

  const onValueChange = (event: any) => {
    const { name, value } = event.target;
    validate(value);
    setValues({
      ...values,
      [name.toString()]: value,
    });
  };

  const close = () => {
    onClose();
  };

  const submit = async () => {
    if (validate(values.name)) {
      const apiUrl = new URL(environment.api.baseUrl + `Cities`);
      const addCityReq = {
        code: '',
        name: values.name,
        otherComments: '',
        statusId: 1,
      };
      const result: any = await http.post(apiUrl.toString(), addCityReq);
      if (result !== null && result.data) {
        toast.success(i18nMessages.AddedSuccessfully);
        onSubmit(result.data);
      } else {
        toast.error(i18nMessages.ErrorOccurred);
      }
    }
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={'sm'}
        fullWidth={true}
      >
        <DialogTitle>
          {title}{' '}
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className="grid-wrap two-col">
            <Grid item xs={12}>
              <CustomizedInputs
                margin="normal"
                displayLabel={i18nMessages.City}
                name="name"
                id="name"
                inputType="text"
                value={values.name}
                readOnly={false}
                required
                errorValue={errors.name}
                errorMessage={errors.name}
                multiline={false}
                inputProps={{ maxLength: 150 }}
                handleChange={onValueChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="btn-wrap">
          <Button
            onClick={close}
            color="neutral"
            disableElevation
            variant="contained"
          >
            {' '}
            {I18n('Common.Cancel')}
          </Button>
          <Button
            onClick={submit}
            autoFocus
            color="primary"
            disableElevation
            variant="contained"
          >
            {I18n('Common.Save')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
