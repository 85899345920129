import { Grid, Button, Tooltip } from '@mui/material';
import React, { useEffect, useState, BaseSyntheticEvent } from 'react';
import http from '../../utilities/httpService';
import environment from 'environment';
import I18n from 'utilities/i18n';
import FormControl from 'common/formControl/formControl';
import CustomizedSelect from 'common/CustomSelect/CustomSelect';
import {
  BaseModel,
  TypeOfServiceDescription,
  CustomerLocationDescription,
  CustomerLocation,
  EntityTypeEnum,
  PaginationResponseModel,
  AddLocationEntity,
  RadioGroupBaseModel,
  TypeOfService,
  ApiResponse,
} from 'models/pagination-model';
import CustomDatePicker from 'common/dateAdapter/dateAdapter';
import { Checkbox, FormControlLabel } from '@mui/material';
import CustomSelectPaginate from 'common/CustomSelect/CustomSelectPaginate';
import {
  loadCustomerOptions,
  loadLocationOptions,
  loadRemainingContactOptions,
  loadAirlineOptions,
} from '../deckpack-orders/AddDecpackApiService';
import AddContactPerson from '../../common/contactPerson/add-contact-person';
import CustomSelectPaginateAdd from 'common/CustomSelect/CustomSelectPaginateAdd';
import {
  AddressSegments,
  DecPackOrderStatusDescription,
  ShippingOrderTypeDescription,
  ExecutionTimeDescription,
  ExecutionTime,
  OfferStatusDescription,
  OfferStatus,
} from 'models/shipping-orders';
import { toast } from 'react-toastify';
import AddressAutocompleteInputs from 'common/AddressAutocomplete/AddressAutocompleteInput';
import RouteEnum from 'models/RouteEnum';
import { useNavigate, useParams } from 'react-router-dom';
import NumericInput from 'common/NumericInput/NumericInput';
import {
  ConvertShippingOffer,
  getDecPackById,
} from '../deckpack-orders/DeckPackApiService';
import { getLocationById } from 'components/shipping-orders/ShippingApiService';
import AddLocation from 'common/location/AddLocation';
import { PostCountryDropDown } from 'common/DropDownAddAPI/DropDownApiService';
import { Customer } from 'models/service-catalogue';
import { hasPermission } from 'utilities/protectedRoute';
import { Permission } from 'Permissions';
import FormAccordianSave from 'common/formControl/formAccordianSave';
import StatePopup from 'common/State/StatePopup';
import AirlinePopup from 'components/Airline/AirlinePopup';
import moment from 'moment';
import TimePickerInput from 'common/TimePicker/TimePickerInput';
import InputLabel from '@mui/material/InputLabel';
import RadioGroupInput from 'common/RadioGroup/RadioGroupInput';
import ConfirmationPopup from 'common/confirmationPopup/confirmationPopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import ViewCustomerModal from 'common/customer/ViewCustomerModal';

function DecPackOfferDetails(props: any) {
  let navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const defaultValueForService = {
    value: '0',
    label: I18n('Placeholders.Select'),
  } as BaseModel;

  const defaultContactValue = {
    value: '',
    label: I18n('Placeholders.Select'),
  } as any;

  const defaultCustomerValue = {
    value: '0',
    label: I18n('Placeholders.SelectCustomer'),
  } as BaseModel;
  const defaultAddressSegments = {
    country: '',
    city: '',
    postalCode: '',
  } as AddressSegments;
  const defaultAdditional: any = {
    page: 1,
  };
  const [isDeleteModalOpen, setDeleteModal] = useState(false);
  const [isAddModalOpen, setAddModal] = useState(false);
  const [cacheUniq, setCacheUniq] = useState(0);
  const [cacheUniqCountry, setCacheUniqCountry] = useState(0);
  const [cacheUniqState, setCacheUniqState] = useState(0);
  const [cacheUniqContact, setCacheUniqContact] = useState(0);
  // const [status, setStatus] = useState('');

  const [isStatusSaveCallApi, setIsStatusSaveCallApi] = useState(false); // To call Submit APi Status from Status Component
  const [validateStatus, setValidateStatus] = useState<boolean>(false); // validateStatus - To validate Status Dropdown from Status Component
  const [canEdit, setCanEdit] = useState(
    hasPermission(Permission.canEditDecPackOffers)
  );
  const [openState, setOpenState] = useState(false);
  const [openAirline, setOpenAirline] = useState(false);
  const [rowDataState, setRowDataState] = useState({});
  const [rowDataAirline, setRowDataAirline] = useState({});
  const [isOfferConverted, setIsOfferConverted] = useState(false);
  const [isOfferRejected, setIsOfferRejected] = useState(false);
  const [offerRejectedReason, setOfferRejectedReason] = useState('');

  const [values, setValues] = useState({
    HazgoReference: '',
    TypeOfService: defaultValueForService,
    HighPriority: false,
    customer: defaultCustomerValue,
    Contact: defaultValueForService,
    Location: defaultValueForService,
    address: '',
    SecuredShipment: false,
    customerLocation: defaultValueForService,
    customerContact: defaultValueForService,
    Airline: defaultValueForService,
    FinalDestination: defaultValueForService,
    TransitDestination: defaultValueForService,
    CustomerReference: '',
    Shipper: '',
    DriverName: '',
    LicensePlate: '',
    Transporter: '',
    NumberofPieces: 0,
    Pickupaddress: '',
    ExpectedInboundDate: new Date().toISOString(),
    SpecialInstructions: '',
    EstimatedDuration: 0,
    Interventie: '',
    type: defaultValueForService,
    deliveryLocation: defaultValueForService,
    ExecutionDate: new Date().toISOString(),
    StartTime: '',
    EndTime: '',
    deliveryCustomerAddress: defaultAddressSegments,
    customerAddress: defaultAddressSegments,
    selectedContacts: [] as any,
    CompletedWorkOrder: 0,
    TotalWorkOrder: 0,
    status: defaultValueForService,
    executionTime: null,
    autogeneratedOfferReferenceNo: '',
    isDecPackOfferConverted: false,
    offerStatus: 0,
  });
  const [AddContactModalOpen, setAddContactModalOpen] = useState({
    isAddModalOpen: false,
    newContact: '',
    triggerFrom: 0,
    entityId: '',
  });
  const [errors, setErrors] = useState({
    TypeOfService: '',
    customerLocation: '',
    Interventie: '',
    customerContact: '',
    ExecutionDate: '',
    deliveryLocation: '',
    Customer: '',
    CustomerReference: '',
    Shipper: '',
    Location: '',
    Airline: '',
    FinalDestination: '',
    selectedContacts: '',
    type: '',
    status: '',
    ExpectedInboundDate: '',
    ExecutionTime: '',
    StartTime: '',
    EndTime: '',
    SpecialInstructon: '',
  });

  const [countryList, setCountryList] = useState<BaseModel[]>([]);
  const [viewCustomerDetails, setViewCustomerDetails] = useState({
    isShowCustomerDetails: false,
    newCustomerName: '',
  });
  let offerStatusList: Array<BaseModel> = [];
  const getOfferStatus = () => {
    offerStatusList = [];
    OfferStatusDescription.forEach((value: string, key: number) => {
      offerStatusList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getOfferStatus();
  const { id } = useParams();
  const i18nMessages = {
    SecuredShipment: I18n('DecPack.SecuredShipment'),
    DriverName: I18n('DecPack.DriverName'),
    LicensePlate: I18n('DecPack.LicensePlate'),
    Transporter: I18n('DecPack.Transporter'),
    NumberofPieces: I18n('DecPack.NumberofPieces'),
    Pickupaddress: I18n('DecPack.Pickupaddress'),
    ExpectedInboundDate: I18n('DecPack.ExpectedInboundDate'),
    DeliveryLocation: I18n('DecPack.DeliveryLocation'),
    fieldRequired: I18n('Common.FieldRequired'),
    Cancel: I18n('Common.Cancel'),
    somethingWentWrong: I18n('Common.SomethingWentWrong'),
    Country: I18n('CustomerLocation.Country'),
    City: I18n('CustomerLocation.City'),
    ZipCode: I18n('CustomerLocation.ZipCode'),
    AddContactPerson: I18n('ContactPerson.AddContactPerson'),
    DetailsWarehouse: I18n('DecPack.DetailsWarehouse'),
    EstimatedDuration: I18n('DecPack.EstimatedDuration'),
    SpecialInstructions: I18n('DecPack.SpecialInstructions'),
    TransitDestination: I18n('DecPack.TransitDestination'),
    FinalDestination: I18n('DecPack.FinalDestination'),
    Airline: I18n('DecPack.Airline'),
    Status: I18n('DecPack.Status'),
    WoDone: I18n('DecPack.WoDone'),
    CommonSaveSuccessMsg: I18n('Common.SavedSuccessfully'),
    CommonErrOccuredMsg: I18n('Common.ErrorOccurred'),
    CommonSave: I18n('Common.Save'),
    StatusCommon: I18n('Common.Status'),
    Addlocation: I18n('CustomerLocation.AddLocation'),
    GeneralDetailsTitle: I18n('DecPack.GeneralDetails'),
    CustomerDetailsTitle: I18n('DecPack.CustomerDetails'),
    OrderBasicsTitle: I18n('DecPack.OfferBasics'),
    AddState: I18n('State.AddState'),
    AddAirline: I18n('Airline.AddAirline'),
    commonFieldIsRequired: I18n('Common.FieldIsRequired'),
    InvalidDate: I18n('Common.InvalidDate'),
    InvalidTime: I18n('Common.InvalidTime'),
    CLAddress: I18n('CustomerLocation.Address'),
    deletedSuccessfully: I18n('ContactPerson.DeletedSuccessfully'),
    errorOccurred: I18n('ContactPerson.ErrorOccurred'),
    EndExecutionTime: I18n('DecPack.EndExecutionTime'),
    StartExecutionTime: I18n('DecPack.StartExecutionTime'),
    viewCustomer: I18n('Customer.ViewCustomer'),
    ConvertToOrder: I18n('ShippingOrders.ConvertOrder'),
    ConvertToOrderConfirmation: I18n(
      'ShippingOrders.ConvertToOrderConfirmation'
    ),
    Accepted: I18n('ShippingOrders.Accepted'),
    Rejected: I18n('ShippingOrders.Rejected'),
    RejectionReason: I18n('ShippingOrders.RejectionReason'),
    OfferStatus: I18n('ShippingOrders.OfferStatus'),
  };
  const [AddLocationModalOpen, setAddLocationModalOpen] = useState({
    isAddLocationModalOpen: false,
    newLocation: '',
    triggerFrom: AddLocationEntity.Default,
    entityId: '',
    newDestination: '',
  });

  const onCreateOption = async (inputValue: any) => {
    var data = {
      name: inputValue,
    };
    setRowDataAirline(data);
    setOpenAirline(true);
  };

  const handleConvertToOrder = () => {
    setIsOpen(true);
  };
  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleOkModal = async () => {
    setIsOpen(false);
    var result = await ConvertShippingOffer(Number(id));

    if (result.result) {
      toast.success(i18nMessages.CommonSaveSuccessMsg);
      navigate(RouteEnum.DecPackOffersList);
    }
  };

  const handleSaveClick = async (event: any) => {
    event.preventDefault();
    setValidateStatus(true);
    handleSuccessStatusSave();
  };

  const onValueChange = (event: any) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name.toString()]: value,
    });
  };

  let entityServiceOptionsList: Array<BaseModel> = [];
  const getServiceOptionsList = () => {
    entityServiceOptionsList = [defaultValueForService];
    TypeOfServiceDescription.forEach((value: string, key: number) => {
      entityServiceOptionsList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getServiceOptionsList();

  let entityLocationOptionsList: Array<BaseModel> = [];
  const getLocationOptionsList = () => {
    entityLocationOptionsList = [];
    CustomerLocationDescription.forEach((value: string, key: number) => {
      entityLocationOptionsList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getLocationOptionsList();

  const getCountries = async (search: string, pageNo: number) => {
    let response: PaginationResponseModel<Customer>;
    const apiUrl = new URL(environment.api.baseUrl + 'Country');
    try {
      let searchExpression = '';
      searchExpression += search ? `name~like~${search}` : '';
      apiUrl.searchParams.set('$filter', searchExpression);
      apiUrl.searchParams.set('$orderby', 'name');
      apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
      apiUrl.searchParams.set('$top', '10');
      apiUrl.searchParams.set('$orderbydirection', 'desc');
      const result = await http.get(apiUrl.toString());
      if (result) {
        response = {
          TotalRecords: result.data.totalCount,
          Values: result.data.values,
        } as PaginationResponseModel<Customer>;
      } else {
        response = {
          TotalRecords: 0,
          Values: [],
        } as PaginationResponseModel<Customer>;
      }
    } catch (error) {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<Customer>;
    }
    return response;
  };

  const getStates = async (search: string, pageNo: number) => {
    let response: PaginationResponseModel<Customer>;
    const apiUrl = new URL(environment.api.baseUrl + 'State');
    try {
      let searchExpression = '';
      searchExpression += search ? `name~like~${search}` : '';
      apiUrl.searchParams.set('$filter', searchExpression);
      apiUrl.searchParams.set('$orderby', 'name');
      apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
      apiUrl.searchParams.set('$top', '10');
      apiUrl.searchParams.set('$orderbydirection', 'desc');
      const result = await http.get(apiUrl.toString());
      if (result) {
        response = {
          TotalRecords: result.data.totalCount,
          Values: result.data.values,
        } as PaginationResponseModel<Customer>;
      } else {
        response = {
          TotalRecords: 0,
          Values: [],
        } as PaginationResponseModel<Customer>;
      }
    } catch (error) {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<Customer>;
    }
    return response;
  };

  let countryLst: Array<BaseModel> = [];
  const getCountryList = (countries: any[]) => {
    countryLst = [defaultValueForService];
    countries.forEach((getCountryList: any) => {
      countryLst.push({
        value: getCountryList.key,
        label: getCountryList.value,
      } as BaseModel);
    });

    return countryLst;
  };

  const getCustomerData = async (id: string) => {
    const apiUrl = new URL(
      environment.api.baseUrl + 'Locations/GetLocationByCustomerId/' + id
    );
    try {
      const result: any = await http.get(apiUrl.toString());
      if (result) {
        if (result && result.data) {
          return {
            result: true,
            data: result.data,
          } as ApiResponse<any>;
        } else {
          return {
            result: false,
            data: null,
          } as ApiResponse<any>;
        }
      }
    } catch (error: any) {}
  };
  // Validate Status Dropdown Success call from Status Component
  const handleSuccessStatusSave = async () => {
    if (validate()) {
      setIsStatusSaveCallApi(true);
      const finalData = {
        hazgoReference: values.HazgoReference,
        autogeneratedOfferReferenceNo: values.autogeneratedOfferReferenceNo,
        serviceType: Number(values.TypeOfService.value),
        executionDate: values.ExecutionDate,
        executionTime: Number(values.executionTime),
        customerReference: values.CustomerReference,
        shipper: values.Shipper,
        locationType: Number(values.Location.value),
        securedShipment: values.SecuredShipment,
        driverName: values.DriverName,
        licensePlate: values.LicensePlate,
        transporter: values.Transporter,
        piecesNo: values.NumberofPieces,
        pickUpOriginAddress: values.Pickupaddress,
        expectedInboundDate: values.ExpectedInboundDate,
        interventie:
          Number(values.Location.value) == CustomerLocation.GroundHandler
            ? values.Interventie
            : '',
        specialInstructions: values.SpecialInstructions,
        isHighPriority: values.HighPriority,
        estimatedDuration: values.EstimatedDuration,
        type:
          Number(values.type.value) !== 0 ? Number(values.type.value) : null,
        orderDate: new Date().toISOString(),
        customerId:
          Number(values.customer.value) !== 0
            ? Number(values.customer.value)
            : null,
        contactPersonId:
          Number(values.customerContact.value) !== 0
            ? Number(values.customerContact.value)
            : null,
        locationId:
          Number(values.customerLocation.value) !== 0
            ? Number(values.customerLocation.value)
            : null,
        deliveryLocationId:
          Number(values.deliveryLocation.value) !== 0
            ? Number(values.deliveryLocation.value)
            : null,
        airlineId:
          Number(values.Airline.value) !== 0
            ? Number(values.Airline.value)
            : null,
        finalDestinationId:
          Number(values.FinalDestination.value) !== 0
            ? Number(values.FinalDestination.value)
            : null,
        transitDestinationId:
          Number(values.TransitDestination.value) !== 0
            ? Number(values.TransitDestination.value)
            : null,
        id: Number(id),
        selectedContacts: values.selectedContacts,
        status:
          Number(values.status.value) !== 0
            ? Number(values.status.value)
            : null,
      };

      if (Number(values.executionTime) === Number(ExecutionTime.SpecificTime)) {
        finalData['startTime'] = values.StartTime;
        finalData['endTime'] = values.EndTime;
      }

      const apiUrl = new URL(environment.api.baseUrl + `DecPackOffer/${id}`);
      if (finalData.selectedContacts?.length > 0) {
        var contacts: any = [];
        finalData.selectedContacts.map((x: any) => {
          contacts.push({
            contactPersonId: Number(x.value),
            contactPersonName: x.label,
          });
        });
        finalData.selectedContacts = contacts;
      } else {
        finalData.selectedContacts = [];
      }
      try {
        const result: any = await http.put(apiUrl.toString(), finalData);
        if (result !== null) {
          toast.success('Saved Successfully');
          handleErrorStatusSave(); // To set Validation and Submit APi Status False
          navigate(`${RouteEnum.DecPackOffersList}`);
        } else {
          toast.error(i18nMessages.somethingWentWrong);
        }
      } catch (error: any) {
        toast.error(i18nMessages.somethingWentWrong);
      }
    }
    handleErrorStatusSave(); /* To set Validation and Submit APi Status False */
  };

  // Validate Status Dropdown Error call from Status Component
  const handleErrorStatusSave = () => {
    setValidateStatus(false);
    setIsStatusSaveCallApi(false);
  };

  useEffect(() => {
    getCountries('', 1);
  }, []);

  const handleCloseForAdd = () => {
    setAddModal(false);
  };

  const refreshData = async () => {
    handleCloseForAdd();
  };
  const onSecureShipmentChange = () => {
    setValues({ ...values, SecuredShipment: !values.SecuredShipment });
  };

  let shippingTypeList: Array<BaseModel> = [];
  const getShippingTypes = () => {
    shippingTypeList = [defaultValueForService];
    ShippingOrderTypeDescription.forEach((value: string, key: number) => {
      shippingTypeList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getShippingTypes();

  let executionTimeList: Array<BaseModel> = [];
  const getExecutionTimeList = () => {
    ExecutionTimeDescription.forEach((value: string, key: number) => {
      executionTimeList.push({
        value: key.toString(),
        label: I18n(value),
      } as RadioGroupBaseModel);
    });
  };
  getExecutionTimeList();

  const validate = (fieldValues: any = values) => {
    let temp = { ...errors };

    if ('TypeOfService' in fieldValues) {
      if (fieldValues.TypeOfService.value === '0') {
        temp.TypeOfService = i18nMessages.fieldRequired;
      } else {
        temp.TypeOfService = fieldValues.TypeOfService
          ? ''
          : i18nMessages.fieldRequired;
      }
    }

    if ('ExecutionDate' in fieldValues) {
      temp.ExecutionDate = fieldValues.ExecutionDate
        ? ''
        : i18nMessages.commonFieldIsRequired;

      if (temp.ExecutionDate == '') {
        let isDateValid = moment(fieldValues.ExecutionDate).isValid();
        if (!isDateValid) {
          temp.ExecutionDate = i18nMessages.InvalidDate;
        }
      }
    }

    if ('customer' in fieldValues) {
      if (fieldValues.customer.value === '0') {
        temp.Customer = i18nMessages.fieldRequired;
      } else {
        temp.Customer = fieldValues.customer ? '' : i18nMessages.fieldRequired;
      }
    }

    if ('selectedContacts' in fieldValues) {
      if (fieldValues.selectedContacts.length === 0) {
        temp.customerContact = i18nMessages.fieldRequired;
      } else {
        temp.customerContact = fieldValues.selectedContacts
          ? ''
          : i18nMessages.fieldRequired;
      }
    }

    if ('Location' in fieldValues) {
      temp.Location = '';
      temp.Interventie = '';
      temp.ExpectedInboundDate = '';

      if (fieldValues.Location.value === '0') {
        temp.Location = i18nMessages.fieldRequired;
      } else if (
        fieldValues.Location.value == CustomerLocation.GroundHandler &&
        'Interventie' in fieldValues
      ) {
        temp.Interventie = fieldValues.Interventie
          ? ''
          : i18nMessages.fieldRequired;
      } else if (
        fieldValues.Location.value == CustomerLocation.WareHouse &&
        'ExpectedInboundDate' in fieldValues
      ) {
        temp.ExpectedInboundDate = fieldValues.ExpectedInboundDate
          ? ''
          : i18nMessages.commonFieldIsRequired;

        if (temp.ExpectedInboundDate == '') {
          let isDateValid = moment(fieldValues.ExpectedInboundDate).isValid();
          if (!isDateValid) {
            temp.ExpectedInboundDate = i18nMessages.InvalidDate;
          }
        }
      } else {
        temp.Location = fieldValues.Location ? '' : i18nMessages.fieldRequired;
      }
    }

    if ('type' in fieldValues) {
      if (fieldValues.type.value === '0') {
        temp.type = i18nMessages.fieldRequired;
      } else {
        temp.type = fieldValues.type ? '' : i18nMessages.fieldRequired;
      }
    }

    if ('StartTime' in fieldValues) {
      let executionStartTime = fieldValues.StartTime;
      temp.StartTime = '';
      if (Number(values.executionTime) === Number(ExecutionTime.SpecificTime)) {
        temp.StartTime = executionStartTime
          ? ''
          : i18nMessages.commonFieldIsRequired;

        if (temp.StartTime == '') {
          if (typeof executionStartTime == 'object') {
            if (executionStartTime == 'Invalid Date') {
              temp.StartTime = i18nMessages.InvalidTime;
            } else {
              let isDateValid = moment(executionStartTime.toDate()).isValid();
              if (!isDateValid) {
                temp.StartTime = i18nMessages.InvalidTime;
              }
            }
          }
        }
      }
    }

    if ('EndTime' in fieldValues) {
      let executionEndTime = fieldValues.EndTime;
      temp.EndTime = '';
      if (Number(values.executionTime) === Number(ExecutionTime.SpecificTime)) {
        temp.EndTime = executionEndTime
          ? ''
          : i18nMessages.commonFieldIsRequired;

        if (temp.EndTime == '') {
          if (typeof executionEndTime == 'object') {
            if (executionEndTime == 'Invalid Date') {
              temp.EndTime = i18nMessages.InvalidTime;
            } else {
              let isDateValid = moment(executionEndTime.toDate()).isValid();
              if (!isDateValid) {
                temp.EndTime = i18nMessages.InvalidTime;
              }
            }
          }
        }
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === '');
    }
  };

  const handleCancelOrder = () => {
    const isDNPCopyOrder = localStorage.getItem('isDNPCopyOrder');
    if (isDNPCopyOrder === 'true') {
      setDeleteModal(true);
    } else {
      navigate(RouteEnum.DecPackOffersList);
    }
  };

  const handleCloseForDelete = () => {
    setDeleteModal(false);
  };

  const handleOkForDelete = () => {
    setDeleteModal(false);
    deleteData(id);
  };

  const deleteData = async (id: any) => {
    const apiUrl = new URL(environment.api.baseUrl + 'DecPackOffer/' + id);
    try {
      const result: any = await http.delete(apiUrl.toString());
      if (result != null && result.data != null && result.data.success) {
        localStorage.removeItem('isDNPCopyOrder');
        navigate(RouteEnum.DecPackOffersList);
      } else {
        toast.error(i18nMessages.somethingWentWrong);
      }
    } catch (error: any) {}
  };

  const getDecPackByDetailsId = async () => {
    const response: any = await getDecPackById(id ? +id : 0);
    if (response) {
      response.selectedContacts = response.selectedContacts.map((x: any) => {
        return {
          label: x.contactPersonName,
          value: x.contactPersonId + '',
        } as BaseModel;
      });
      response.TypeOfService = entityServiceOptionsList[response.serviceType];
      response.type = shippingTypeList[response.type];
      if (
        response &&
        response.locationType &&
        entityLocationOptionsList.some(
          (x) => +x.value === response.locationType
        )
      ) {
        response.Location = entityLocationOptionsList.find(
          (x) => +x.value === response.locationType
        );
      }
      response.customerAddress =
        response.locationId && response.locationId !== undefined
          ? await getLocationData(Number(response.locationId))
          : defaultAddressSegments;
      response.deliveryCustomerAddress =
        response.deliveryLocationId && response.deliveryLocationId !== undefined
          ? await getLocationData(Number(response.deliveryLocationId))
          : defaultAddressSegments;
      response.FinalDestination =
        response.FinalDestination &&
        response.FinalDestination.value !== undefined
          ? response.FinalDestination
          : defaultValueForService;
      response.TransitDestination =
        response.TransitDestination &&
        response.TransitDestination.value !== undefined
          ? response.TransitDestination
          : defaultValueForService;
      response.status = {
        label:
          entityStatusList &&
          entityStatusList.length &&
          entityStatusList.some((x) => +x.value === response.status)
            ? (entityStatusList.find((x) => +x.value === response.status)
                ?.label as string)
            : '',
        value: Number(response.status).toString(),
      } as BaseModel;
      setValues({ ...values, ...response });
      if (response.isDecPackOfferConverted) {
        setCanEdit(false);
      }
      props.IsOfferConverted(response.isDecPackOfferConverted);
      setIsOfferConverted(response.isDecPackOfferConverted);
      setIsOfferRejected(response.isOfferRejected);
      setOfferRejectedReason(response.rejectionMasterName);
    } else {
      toast.error(i18nMessages.somethingWentWrong);
    }
  };
  useEffect(() => {
    getDecPackByDetailsId();
  }, []);

  const getLocationData = async (id: number) => {
    let location = await getLocationById(Number(id));
    let addressSegments = defaultAddressSegments;
    if (location?.data) {
      addressSegments = {
        country: location?.data?.country ? location.data.country : '',
        city: location?.data?.city ? location.data.city : '',
        postalCode: location?.data?.zipCode ? location.data.zipCode : '',
        address: location?.data?.address ? location.data.address : '',
        address2: location?.data?.address2 ? location?.data?.address2 : '',
        street: location?.data?.street ? location?.data?.street : '',
      };
    }
    return addressSegments;
  };

  const onCreateCustomerLocation = async (inputValue: any) => {
    if (values?.customer?.value && Number(values.customer.value) > 0) {
      const increaseUniq = (uniq: any) => uniq + 1;
      setCacheUniq(increaseUniq);
      setAddLocationModalOpen({
        isAddLocationModalOpen: true,
        newLocation: inputValue,
        triggerFrom: AddLocationEntity.DecPackCustomerLocation,
        entityId: values.customer.value,
        newDestination: '',
      });
    }
  };

  const onCreateDeliveryLocation = async (inputValue: any) => {
    if (values?.customer?.value && Number(values.customer.value) > 0) {
      const increaseUniq = (uniq: any) => uniq + 1;
      setCacheUniq(increaseUniq);
      setAddLocationModalOpen({
        isAddLocationModalOpen: true,
        newLocation: inputValue,
        triggerFrom: AddLocationEntity.DecPackWarehouseLocation,
        entityId: values.customer.value,
        newDestination: '',
      });
    }
  };

  const onCreateContact = async (inputValue: any) => {
    const increaseUniq = (uniq: any) => uniq + 1;
    setCacheUniqContact(increaseUniq);
    setAddContactModalOpen({
      isAddModalOpen: true,
      newContact: inputValue,
      triggerFrom: 1,
      entityId: values.customer.value,
    });
  };

  const OnSaveContact = async (response: any, triggerFrom: number) => {
    if (response && response?.data) {
      if (triggerFrom === 1) {
        setValues({
          ...values,
          selectedContacts: [
            ...values.selectedContacts,
            {
              label: String(
                response.data.firstName + ' ' + response.data.lastName
              ),
              value: response.data.id.toString(),
            } as BaseModel,
          ],
        });
      }
    }
    handleCloseForAddContact();
  };

  const handleCloseForAddContact = () => {
    setAddContactModalOpen({
      isAddModalOpen: false,
      newContact: '',
      triggerFrom: 0,
      entityId: '',
    });
  };
  const handleCloseForAddLocation = () => {
    setAddLocationModalOpen({
      isAddLocationModalOpen: false,
      newLocation: '',
      triggerFrom: AddLocationEntity.Default,
      entityId: '',
      newDestination: '',
    });
  };

  const onCreateOptionCountry = async (inputValue: any, isTransit: boolean) => {
    let name = {};
    name['name'] = inputValue;
    const newOption: any = await PostCountryDropDown(
      name,
      i18nMessages.CommonSaveSuccessMsg,
      i18nMessages.CommonErrOccuredMsg
    );
    const increaseUniq = (uniq: any) => uniq + 1;
    setCacheUniqCountry(increaseUniq);
    if (isTransit) {
      setValues({ ...values, TransitDestination: newOption });
    } else {
      setValues({ ...values, FinalDestination: newOption });
    }

    getCountries('', 1);
  };

  const onCreateOptionState = (inputValue: any) => {
    var data = {
      name: inputValue,
    };
    const increaseUniq = (uniq: any) => uniq + 1;
    setCacheUniqState(increaseUniq);
    setRowDataState(data);
    setOpenState(true);
  };

  const loadCountryOptions = async (
    search: string,
    loadedOptions: any,
    { page }: any,
    loadInstruction: boolean = false
  ) => {
    const response = await getCountries(search, page);
    const hasMore = Math.ceil(response.TotalRecords / 10) > page;

    return {
      options: response.Values.map((x: any) => {
        return {
          value: x.id.toString(),
          label: loadInstruction ? x.description : x.name,
        } as BaseModel;
      }),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const loadStateOptions = async (
    search: string,
    loadedOptions: any,
    { page }: any
  ) => {
    const response = await getStates(search, page);
    const hasMore = Math.ceil(response.TotalRecords / 10) > page;

    return {
      options: response.Values.map((x: any) => {
        return {
          value: x.id.toString(),
          label: x.name,
        } as BaseModel;
      }),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const OnSave = async (response: any, triggerFrom: number) => {
    if (response && response?.data) {
      if (triggerFrom === AddLocationEntity.DecPackCustomerLocation) {
        // Added from customer location Dropdown
        setValues({
          ...values,
          customerLocation: {
            label: `${response.data.street ? `${response.data.street},` : ''}${
              response.data.address
            }${response.data.address2 ? `${response.data.address2},` : ''}${
              response.data.zipCode ? `${response.data.zipCode},` : ''
            }${response.data.city.label},${response.data.country.label}`,
            value: response.data.id,
          } as BaseModel,
          customerAddress:
            response.data.id && response.data.id !== undefined
              ? await getLocationData(Number(response.data.id))
              : defaultAddressSegments,
        });
      } else if (triggerFrom === AddLocationEntity.DecPackWarehouseLocation) {
        // Added from delivery location dropdown
        setValues({
          ...values,
          deliveryLocation: {
            label: `${response.data.street ? `${response.data.street},` : ''}${
              response.data.address
            }${response.data.address2 ? `${response.data.address2},` : ''}${
              response.data.zipCode ? `${response.data.zipCode},` : ''
            }${response.data.city.label},${response.data.country.label}`,
            value: response.data.id,
          } as BaseModel,
          deliveryCustomerAddress:
            response.data.id && response.data.id !== undefined
              ? await getLocationData(Number(response.data.id))
              : defaultAddressSegments,
        });
      }
    }
    handleCloseForAddLocation();
  };

  let entityStatusList: Array<BaseModel> = [];
  const getStatusList = () => {
    entityStatusList = [defaultValueForService];
    DecPackOrderStatusDescription.forEach((value: string, key: number) => {
      entityStatusList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getStatusList();

  const handleCloseForEditState = () => {
    setOpenState(false);
  };

  const handleCloseForEditAirline = () => {
    setOpenAirline(false);
  };

  const handleOnSaveState = (data: any) => {
    setOpenState(false);
    setValues({
      ...values,
      FinalDestination: { value: data.id, label: data.name },
    });
  };

  const handleOnSaveAirline = (data: any) => {
    setOpenAirline(false);
    setValues({
      ...values,
      Airline: { value: data.id, label: data.name },
    });
  };

  const onCustomerDetails = () => {
    setViewCustomerDetails({
      isShowCustomerDetails: true,
      newCustomerName: '',
    });
  };

  const handleCloseForShowCustomerDetails = () => {
    setViewCustomerDetails({
      isShowCustomerDetails: false,
      newCustomerName: '',
    });
  };

  const StatusData = () => {
    return (
      <Grid container spacing={2} className="grid-wrap two-col">
        <Grid item xs={6}>
          <FormControl
            margin="normal"
            value={
              isOfferConverted
                ? i18nMessages.Accepted
                : isOfferRejected
                ? i18nMessages.Rejected
                : values.offerStatus == OfferStatus.Open
                ? offerStatusList[OfferStatus.Open].label
                : values.offerStatus == OfferStatus.OfferSent
                ? offerStatusList[OfferStatus.OfferSent].label
                : '-'
            }
            displayLabel={i18nMessages.Status}
            id="status"
            name="status"
            inputType="text"
            multiline={false}
            readOnly={true}
            disabled
          ></FormControl>
        </Grid>
        <Grid item xs={6}>
          {isOfferRejected ? (
            <FormControl
              margin="normal"
              value={offerRejectedReason}
              displayLabel={i18nMessages.RejectionReason}
              id="rejectionReason"
              name="rejectionReason"
              inputType="text"
              multiline={false}
              readOnly={true}
              disabled
            ></FormControl>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    );
  };

  const OrderDetailsData = () => {
    return (
      <div className="inner-section">
        <Grid container spacing={2} className="grid-wrap">
          <Grid item lg={6} md={6}>
            <FormControl
              readOnly={true}
              margin="normal"
              displayLabel={I18n('DecPackOrders.Table.OfferReference')}
              value={values.autogeneratedOfferReferenceNo}
            ></FormControl>
          </Grid>
          <Grid item lg={6} md={6}>
            <CustomizedSelect
              readOnly={!canEdit}
              required
              placeholder={defaultValueForService.label}
              options={entityServiceOptionsList}
              isSearchable={false}
              value={values?.TypeOfService}
              displayLabel={I18n('DecPack.TypeOfService')}
              handleChange={(newValue: BaseModel) => {
                const transitDestination =
                  newValue.value === String(TypeOfService.Shop) ||
                  newValue.value === String(TypeOfService.NonDGDOrder)
                    ? defaultValueForService
                    : values.TransitDestination;
                setValues({
                  ...values,
                  TypeOfService: newValue,
                  TransitDestination: transitDestination,
                });
                if (newValue.value !== '0') {
                  setErrors({
                    ...errors,
                    TypeOfService: '',
                  });
                }
              }}
              errorValue={errors.TypeOfService}
              errorMessage={errors.TypeOfService}
            ></CustomizedSelect>
          </Grid>
          <Grid item lg={12} md={12}>
            <CustomDatePicker
              readOnly={!canEdit}
              handleChange={(newValue: any) => {
                setValues({
                  ...values,
                  ExecutionDate:
                    newValue != null && moment(newValue).isValid()
                      ? newValue.toISOString()
                      : newValue,
                  StartTime:
                    newValue != null && moment(newValue).isValid()
                      ? newValue.toISOString()
                      : newValue,
                  EndTime:
                    newValue != null && moment(newValue).isValid()
                      ? newValue.toISOString()
                      : newValue,
                });
              }}
              displayLabel={I18n('DecPack.ExecutionDate')}
              value={values.ExecutionDate}
              id="ExecutionDate"
              name="ExecutionDate"
              required={true}
              errorValue={errors.ExecutionDate}
              errorMessage={errors.ExecutionDate}
            />
          </Grid>
          <Grid item lg={12} md={12} className="dp-radioBtn-wrap">
            <InputLabel shrink htmlFor="bootstrap-input">
              {I18n('DecPack.SelectExecutionTime')}
            </InputLabel>
            <RadioGroupInput
              value={values.executionTime}
              name="executionTime"
              options={executionTimeList}
              handleChange={(event: BaseSyntheticEvent) => {
                setValues({
                  ...values,
                  StartTime: '',
                  EndTime: '',
                  executionTime: event.target.value,
                });
              }}
              errorValue={true}
              errorMessage={errors.ExecutionTime}
              readOnly={!canEdit}
            ></RadioGroupInput>
          </Grid>
          {values.executionTime == ExecutionTime.SpecificTime ? (
            <Grid item lg={12} md={12}>
              <Grid container spacing={2} className="grid-wrap">
                <Grid item lg={6} md={6}>
                  <TimePickerInput
                    readOnly={!canEdit}
                    displayLabel={i18nMessages.StartExecutionTime}
                    name="StartTime"
                    value={values.StartTime}
                    handleChange={(newValue: any) => {
                      setValues({
                        ...values,
                        StartTime:
                          newValue != null &&
                          moment(newValue.toDate()).isValid()
                            ? newValue.toISOString()
                            : newValue,
                      });
                    }}
                    required={true}
                    errorValue={errors.StartTime}
                    errorMessage={errors.StartTime}
                  ></TimePickerInput>
                </Grid>
                <Grid item lg={6} md={6}>
                  <TimePickerInput
                    readOnly={!canEdit}
                    displayLabel={i18nMessages.EndExecutionTime}
                    name="EndTime"
                    value={values.EndTime}
                    handleChange={(newValue: any) => {
                      setValues({
                        ...values,
                        EndTime:
                          newValue != null &&
                          moment(newValue.toDate()).isValid()
                            ? newValue.toISOString()
                            : newValue,
                      });
                    }}
                    required={true}
                    errorValue={errors.EndTime}
                    errorMessage={errors.EndTime}
                  ></TimePickerInput>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
          <Grid item lg={6} md={6}>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={!canEdit}
                  name="HighPriority"
                  onChange={(event: any) =>
                    setValues({
                      ...values,
                      HighPriority: event.target.checked,
                    })
                  }
                  checked={values.HighPriority}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label={I18n('DecPack.HighPriority')}
            />
          </Grid>
        </Grid>
      </div>
    );
  };

  const CustomerDetailsData = () => {
    return (
      <div className="inner-section">
        <Grid container spacing={2} className="grid-wrap">
          <Grid item lg={6} md={6}>
            <div className="DPCustomerDetails">
              <CustomSelectPaginate
                readOnly={!canEdit}
                additional={defaultAdditional}
                value={values.customer}
                loadOptions={(search: any, prevOptions: any, page: any) =>
                  loadCustomerOptions(
                    search,
                    prevOptions,
                    page,
                    values.customer.value
                  )
                }
                handleChange={async (newValue: BaseModel) => {
                  let customerData: any = await getCustomerData(newValue.value);
                  setValues({
                    ...values,
                    customer: newValue,
                    deliveryLocation: defaultValueForService,
                    deliveryCustomerAddress: defaultAddressSegments,
                    customerLocation: {
                      label: customerData?.data?.address
                        ? customerData.data.address
                        : '',
                      value: customerData?.data?.id
                        ? customerData.data.id.toString()
                        : '',
                    } as BaseModel,
                    //customerAddress: defaultAddressSegments,
                    customerAddress: {
                      country: customerData?.data.country
                        ? customerData?.data.country
                        : '',
                      city: customerData?.data.city
                        ? customerData?.data.city
                        : '',
                      postalCode: customerData?.data.zipCode
                        ? customerData?.data.zipCode
                        : '',
                      address: customerData?.data?.address
                        ? customerData.data.address
                        : '',
                      address2: customerData?.data?.address2
                        ? customerData.data.address2
                        : '',
                      street: customerData?.data?.street
                        ? customerData.data.street
                        : '',
                    },
                    selectedContacts: [],
                  });
                  const increaseUniq = (uniq: any) => uniq + 1;
                  setCacheUniqContact(increaseUniq);
                  setCacheUniq(increaseUniq);
                  if (newValue.value !== '0') {
                    setErrors({
                      ...errors,
                      Customer: '',
                    });
                  }
                }}
                placeholder={defaultCustomerValue.label}
                isSearchable={true}
                displayLabel={I18n('DecPack.Customer')}
                debounceTimeout={500}
                isMultiSelect={false}
                required
                errorValue={errors.Customer}
                errorMessage={errors.Customer}
              />
              {Number(values.customer.value) > 0 &&
                hasPermission(Permission.canViewCustomer) && (
                  <Tooltip title={i18nMessages.viewCustomer}>
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      onClick={onCustomerDetails}
                      className={`fa-md ${canEdit ? `icon-edit` : `icon-view`}`}
                    />
                  </Tooltip>
                )}
            </div>
          </Grid>
          <Grid item lg={6} md={6}>
            <FormControl
              readOnly={!canEdit}
              margin="normal"
              displayLabel={I18n('DecPack.CustomerReference')}
              id="CustomerReference"
              name="CustomerReference"
              inputType="text"
              inputProps={{ maxLength: 30 }}
              multiline={false}
              handleChange={onValueChange}
              value={values.CustomerReference}
              errorValue={errors.CustomerReference}
              errorMessage={errors.CustomerReference}
            ></FormControl>
          </Grid>
          <Grid item lg={12} md={12}>
            <div className="DPCustomerContact">
              <CustomSelectPaginateAdd
                readOnly={!canEdit}
                key={cacheUniqContact + '_cc'}
                additional={defaultAdditional}
                value={values.selectedContacts}
                loadOptions={(search: string, prevOptions: any, page: any) =>
                  loadRemainingContactOptions(
                    values.customer && +values.customer.value
                      ? +values.customer.value
                      : 0,
                    search,
                    prevOptions,
                    page,
                    id ? id?.toString() : ''
                  )
                }
                handleChange={(newValue: any) => {
                  setValues({ ...values, selectedContacts: newValue });
                }}
                id="selectedContacts"
                placeholder={defaultContactValue.label}
                isSearchable={true}
                displayLabel={I18n('ShippingOrders.Contact')}
                required
                errorValue={true}
                errorMessage={errors.customerContact}
                debounceTimeout={500}
                isMultiSelect={true}
                cacheUniqs={cacheUniqContact}
                onCreateOption={onCreateContact}
              />
            </div>
          </Grid>
          <Grid item lg={6} md={6}>
            <FormControl
              readOnly={!canEdit}
              margin="normal"
              displayLabel={I18n('DecPack.Shipper')}
              value={values.Shipper}
              id="Shipper"
              name="Shipper"
              inputType="text"
              inputProps={{ maxLength: 30 }}
              multiline={false}
              handleChange={onValueChange}
              errorValue={errors.Shipper}
              errorMessage={errors.Shipper}
            ></FormControl>
          </Grid>
          <Grid item lg={6} md={6}>
            <CustomizedSelect
              required
              readOnly={!canEdit}
              options={entityLocationOptionsList}
              isSearchable={false}
              displayLabel={I18n('DecPack.Location')}
              handleChange={async (newValue: BaseModel) => {
                if (
                  newValue.label === 'Ground Handler' ||
                  newValue.label === 'WareHouse'
                ) {
                  setValues({
                    ...values,
                    customerLocation: defaultValueForService,
                    customerAddress: defaultAddressSegments,
                    Location: newValue,
                  });
                } else {
                  if (values.customer.value !== '0') {
                    let customerData = await getCustomerData(
                      values.customer.value
                    );
                    setValues({
                      ...values,
                      customerLocation: {
                        label: customerData?.data?.address
                          ? customerData.data.address
                          : '',
                        value: customerData?.data?.id
                          ? customerData.data.id.toString()
                          : '',
                      } as BaseModel,
                      customerAddress: {
                        country: customerData?.data.country
                          ? customerData?.data.country
                          : '',
                        city: customerData?.data.city
                          ? customerData?.data.city
                          : '',
                        postalCode: customerData?.data.zipCode
                          ? customerData?.data.zipCode
                          : '',
                        address: customerData?.data?.address
                          ? customerData.data.address
                          : '',
                        address2: customerData?.data?.address2
                          ? customerData.data.address2
                          : '',
                        street: customerData?.data?.street
                          ? customerData.data.street
                          : '',
                      },
                      Location: newValue,
                    });
                  } else {
                    setValues({ ...values, Location: newValue });
                  }
                }
                if (newValue.value !== '0') {
                  setErrors({
                    ...errors,
                    Location: '',
                  });
                }
              }}
              value={values.Location}
              errorValue={errors.Location}
              errorMessage={errors.Location}
            ></CustomizedSelect>
          </Grid>
          <>
            {Number(values?.Location?.value) ===
            CustomerLocation.GroundHandler ? (
              <Grid item lg={6} md={6}>
                <FormControl
                  readOnly={!canEdit}
                  margin="normal"
                  displayLabel={I18n('DecPack.Interventie')}
                  id="interventie"
                  name="Interventie"
                  inputType="text"
                  value={values.Interventie}
                  inputProps={{ maxLength: 30 }}
                  multiline={false}
                  handleChange={onValueChange}
                  required={true}
                  errorValue={true}
                  errorMessage={errors.Interventie}
                ></FormControl>
              </Grid>
            ) : (
              ''
            )}
            {Number(values?.Location?.value) === CustomerLocation.Customer ? (
              <>
                <Grid item lg={12} md={12}>
                  <CustomSelectPaginateAdd
                    readOnly={!canEdit}
                    key={JSON.stringify(values.customerLocation)}
                    additional={defaultAdditional}
                    value={values.customerLocation}
                    handleChange={async (newValue: any) => {
                      let location = await getLocationById(
                        Number(newValue.value)
                      );
                      setValues({
                        ...values,
                        customerLocation: newValue,
                        customerAddress: {
                          country: location?.data.country
                            ? location?.data.country
                            : '',
                          city: location?.data.city ? location?.data.city : '',
                          postalCode: location?.data.zipCode
                            ? location?.data.zipCode
                            : '',
                          address: location?.data?.address
                            ? location.data.address
                            : '',
                          address2: location?.data?.address2
                            ? location.data.address2
                            : '',
                          street: location?.data?.street
                            ? location.data.street
                            : '',
                        },
                      });
                    }}
                    loadOptions={(
                      search: string,
                      prevOptions: any,
                      page: any
                    ) =>
                      loadLocationOptions(
                        values.customer && +values.customer.value
                          ? +values.customer.value
                          : 0,
                        search,
                        prevOptions,
                        page
                      )
                    }
                    isSearchable={true}
                    displayLabel={i18nMessages.CLAddress}
                    errorValue={true}
                    errorMessage={errors.customerLocation}
                    debounceTimeout={500}
                    isMultiSelect={false}
                    cacheUniqs={cacheUniq}
                    onCreateOption={onCreateCustomerLocation}
                  />
                </Grid>
                <Grid item lg={6} md={6}>
                  <FormControl
                    margin="normal"
                    value={values.customerAddress.postalCode}
                    displayLabel={i18nMessages.ZipCode}
                    id="zipCode"
                    name="zipCode"
                    inputType="text"
                    inputProps={{ maxLength: 30 }}
                    multiline={false}
                    disabled
                    readOnly={!canEdit}
                  ></FormControl>
                </Grid>
                <Grid item lg={6} md={6}>
                  <FormControl
                    margin="normal"
                    value={values.customerAddress.city}
                    displayLabel={i18nMessages.City}
                    id="city"
                    name="city"
                    inputType="text"
                    inputProps={{ maxLength: 30 }}
                    multiline={false}
                    disabled
                    readOnly={!canEdit}
                  ></FormControl>
                </Grid>
                <Grid item lg={6} md={6}>
                  <FormControl
                    margin="normal"
                    value={values.customerAddress.country}
                    displayLabel={i18nMessages.Country}
                    id="country"
                    name="country"
                    inputType="text"
                    inputProps={{ maxLength: 30 }}
                    multiline={false}
                    disabled
                    readOnly={!canEdit}
                  ></FormControl>
                </Grid>
              </>
            ) : (
              ''
            )}
          </>
        </Grid>
      </div>
    );
  };

  const GeneralDetailsData = () => {
    return (
      <Grid container spacing={2} className="grid-wrap">
        {Number(values.TypeOfService.value) === TypeOfService.DGCheck ||
        Number(values.TypeOfService.value) === TypeOfService.DGDCreation ? (
          <>
            <Grid item lg={4} md={4}>
              <CustomSelectPaginateAdd
                readOnly={!canEdit}
                key={JSON.stringify(values.Airline)}
                additional={defaultAdditional}
                value={values.Airline}
                handleChange={(newValue: any) => {
                  setValues({ ...values, Airline: newValue });
                  if (newValue.value !== '0') {
                    setErrors({
                      ...errors,
                      Airline: '',
                    });
                  }
                }}
                loadOptions={(search: string, prevOptions: any, page: any) =>
                  loadAirlineOptions(search, prevOptions, page)
                }
                isSearchable={true}
                displayLabel={i18nMessages.Airline}
                debounceTimeout={500}
                isMultiSelect={false}
                cacheUniqs={cacheUniq}
                onCreateOption={onCreateOption}
              ></CustomSelectPaginateAdd>
            </Grid>

            <Grid item lg={4} md={4}>
              {
                <CustomSelectPaginate
                  key={JSON.stringify(values.FinalDestination)}
                  readOnly={!canEdit}
                  additional={defaultAdditional}
                  value={values.FinalDestination}
                  loadOptions={loadCountryOptions}
                  handleChange={(newValue: BaseModel) => {
                    setValues({ ...values, FinalDestination: newValue });
                    if (newValue.value !== '0') {
                      setErrors({
                        ...errors,
                        FinalDestination: '',
                      });
                    }
                  }}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                  }}
                  isSearchable={true}
                  displayLabel={i18nMessages.FinalDestination}
                  debounceTimeout={500}
                  isMultiSelect={false}
                  errorValue={errors.FinalDestination}
                  errorMessage={errors.FinalDestination}
                ></CustomSelectPaginate>
              }
            </Grid>
          </>
        ) : (
          <></>
        )}
        {values.TypeOfService.value !== String(TypeOfService.Shop) &&
        values.TypeOfService.value !== String(TypeOfService.NonDGDOrder) ? (
          <Grid item lg={4} md={4}>
            <CustomSelectPaginate
              readOnly={!canEdit}
              key={JSON.stringify(values.TransitDestination)}
              additional={defaultAdditional}
              value={values.TransitDestination}
              loadOptions={loadCountryOptions}
              handleChange={(newValue: BaseModel) =>
                setValues({ ...values, TransitDestination: newValue })
              }
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
              }}
              isSearchable={true}
              displayLabel={i18nMessages.TransitDestination}
              debounceTimeout={500}
              isMultiSelect={false}
            ></CustomSelectPaginate>
          </Grid>
        ) : (
          <></>
        )}
        <Grid item lg={8} md={8}>
          <FormControl
            readOnly={!canEdit}
            margin="normal"
            displayLabel={i18nMessages.SpecialInstructions}
            id="SpecialInstructions"
            name="SpecialInstructions"
            inputType="text"
            inputProps={{ maxLength: 250 }}
            multiline={false}
            handleChange={onValueChange}
            value={values.SpecialInstructions}
            errorMessage={errors.SpecialInstructon}
            errorValue={errors.SpecialInstructon}
          ></FormControl>
        </Grid>
        <Grid item lg={4} md={4}>
          <NumericInput
            readOnly={!canEdit}
            displayLabel={i18nMessages.EstimatedDuration}
            id="EstimatedDuration"
            name="EstimatedDuration"
            inputProps={{ maxLength: 30 }}
            multiline={false}
            value={values.EstimatedDuration}
            handleChange={onValueChange}
            allowNegative={false}
          />
        </Grid>
      </Grid>
    );
  };

  const WarehouseDetailsData = () => {
    return (
      <Grid container spacing={2} className="grid-wrap">
        <Grid item lg={12} md={12}>
          <FormControlLabel
            control={
              <Checkbox
                disabled={!canEdit}
                name="SecuredShipment"
                onChange={onSecureShipmentChange}
                checked={values.SecuredShipment}
                inputProps={{ 'aria-label': 'controlled' }}
                required
              />
            }
            label={i18nMessages.SecuredShipment}
          />
        </Grid>
        {values.SecuredShipment === true ? (
          <>
            <Grid item lg={4} md={4}>
              <FormControl
                readOnly={!canEdit}
                margin="normal"
                displayLabel={i18nMessages.DriverName}
                id="DriverName"
                name="DriverName"
                inputType="text"
                inputProps={{ maxLength: 30 }}
                multiline={false}
                handleChange={onValueChange}
                value={values.DriverName}
              ></FormControl>
            </Grid>
            <Grid item lg={4} md={4}>
              <FormControl
                readOnly={!canEdit}
                margin="normal"
                displayLabel={i18nMessages.LicensePlate}
                id="LicensePlate"
                name="LicensePlate"
                inputType="text"
                inputProps={{ maxLength: 30 }}
                multiline={false}
                handleChange={onValueChange}
                value={values.LicensePlate}
              ></FormControl>
            </Grid>
            <Grid item lg={4} md={4}>
              <FormControl
                readOnly={!canEdit}
                margin="normal"
                displayLabel={i18nMessages.Transporter}
                id="Transporter"
                name="Transporter"
                inputType="text"
                inputProps={{ maxLength: 30 }}
                multiline={false}
                handleChange={onValueChange}
                value={values.Transporter}
              ></FormControl>
            </Grid>
            <Grid item lg={4} md={4}>
              <NumericInput
                readOnly={!canEdit}
                displayLabel={i18nMessages.NumberofPieces}
                id="NumberofPieces"
                name="NumberofPieces"
                inputProps={{ maxLength: 30 }}
                multiline={false}
                handleChange={onValueChange}
                value={values.NumberofPieces}
                allowNegative={false}
              />
            </Grid>
            <Grid item lg={4} md={4}>
              <AddressAutocompleteInputs
                readOnly={!canEdit}
                name="address"
                value={values?.address}
                inputProps={{ maxLength: 250 }}
                displayLabel={i18nMessages.Pickupaddress}
                handleChange={(value: string) => {
                  setValues({
                    ...values,
                    Pickupaddress: value,
                  });
                }}
                multiline={false}
              ></AddressAutocompleteInputs>
            </Grid>
          </>
        ) : (
          ''
        )}

        <Grid item lg={4} md={4}>
          <CustomDatePicker
            readOnly={!canEdit}
            handleChange={(newValue: any) => {
              setValues({
                ...values,
                ExpectedInboundDate:
                  newValue != null && moment(newValue).isValid()
                    ? newValue.toISOString()
                    : newValue,
              });
            }}
            displayLabel={i18nMessages.ExpectedInboundDate}
            value={values.ExpectedInboundDate}
            id="ExpectedInboundDate"
            required={true}
            errorValue={errors.ExpectedInboundDate}
            errorMessage={errors.ExpectedInboundDate}
          />
        </Grid>
        <Grid item lg={8} md={8}>
          <CustomSelectPaginateAdd
            readOnly={!canEdit}
            key={JSON.stringify(values.deliveryLocation)}
            additional={defaultAdditional}
            value={values.deliveryLocation}
            loadOptions={(search: string, prevOptions: any, page: any) =>
              loadLocationOptions(
                values.customer && +values.customer.value
                  ? +values.customer.value
                  : 0,
                search,
                prevOptions,
                page
              )
            }
            handleChange={async (newValue: any) => {
              let location = await getLocationById(Number(newValue.value));

              setValues({
                ...values,
                deliveryLocation: newValue,
                deliveryCustomerAddress: {
                  country: location?.data?.country ? location.data.country : '',
                  city: location?.data?.city ? location.data.city : '',
                  postalCode: location?.data?.zipCode
                    ? location.data.zipCode
                    : '',
                  address: location?.data?.address ? location.data.address : '',
                  address2: location?.data?.address2
                    ? location.data.address2
                    : '',
                  street: location?.data?.street ? location.data.street : '',
                },
              });
            }}
            placeholder={defaultCustomerValue.label}
            isSearchable={true}
            displayLabel={i18nMessages.DeliveryLocation}
            errorValue={true}
            errorMessage={errors.deliveryLocation}
            debounceTimeout={500}
            isMultiSelect={false}
            cacheUniqs={cacheUniq}
            onCreateOption={onCreateDeliveryLocation}
          />
        </Grid>
        <Grid item lg={4} md={4}>
          <FormControl
            margin="normal"
            value={values.deliveryCustomerAddress.postalCode}
            displayLabel={i18nMessages.ZipCode}
            id="zipCode"
            name="zipCode"
            inputType="text"
            inputProps={{ maxLength: 30 }}
            multiline={false}
            disabled
            readOnly={!canEdit}
          ></FormControl>
        </Grid>
        <Grid item lg={4} md={4}>
          <FormControl
            margin="normal"
            value={values.deliveryCustomerAddress.city}
            displayLabel={i18nMessages.City}
            id="city"
            name="city"
            inputType="text"
            inputProps={{ maxLength: 30 }}
            multiline={false}
            disabled
            readOnly={!canEdit}
          ></FormControl>
        </Grid>
        <Grid item lg={4} md={4}>
          <FormControl
            margin="normal"
            value={values.deliveryCustomerAddress.country}
            displayLabel={i18nMessages.Country}
            id="country"
            name="country"
            inputType="text"
            inputProps={{ maxLength: 30 }}
            multiline={false}
            disabled
            readOnly={!canEdit}
          ></FormControl>
        </Grid>
      </Grid>
    );
  };

  return (
    <div>
      <Grid container spacing={2} className="grid-wrap">
        <Grid item lg={6} md={6}>
          <FormAccordianSave
            title={i18nMessages.OrderBasicsTitle}
            details={OrderDetailsData()}
            showActionButton={!canEdit}
            className="inner-heading"
          ></FormAccordianSave>
        </Grid>
        <Grid item lg={6} md={6}>
          <FormAccordianSave
            title={i18nMessages.CustomerDetailsTitle}
            details={CustomerDetailsData()}
            showActionButton={!canEdit}
            className="inner-heading"
          ></FormAccordianSave>
        </Grid>
        <Grid item lg={12} md={12}>
          <FormAccordianSave
            title={i18nMessages.GeneralDetailsTitle}
            details={GeneralDetailsData()}
            showActionButton={!canEdit}
            className="inner-heading"
          ></FormAccordianSave>
        </Grid>
        {values?.Location?.label === 'WareHouse' ? (
          <Grid item lg={12} md={12}>
            <FormAccordianSave
              title={i18nMessages.DetailsWarehouse}
              details={WarehouseDetailsData()}
              showActionButton={!canEdit}
              className="inner-heading"
            ></FormAccordianSave>
          </Grid>
        ) : (
          ''
        )}
      </Grid>
      <Grid item lg={12} md={12}>
        <FormAccordianSave
          title={i18nMessages.OfferStatus}
          className="inner-heading"
          details={StatusData()}
        ></FormAccordianSave>
      </Grid>
      <AddContactPerson
        isOpen={AddContactModalOpen.isAddModalOpen}
        editData={null}
        entityType={EntityTypeEnum.Customer}
        entityId={AddContactModalOpen.entityId}
        handleClose={handleCloseForAddContact}
        title={i18nMessages.AddContactPerson}
        refreshData={refreshData}
        OnSave={OnSaveContact}
        triggerFrom={AddContactModalOpen.triggerFrom}
        newContact={AddContactModalOpen.newContact}
      ></AddContactPerson>
      <AddLocation
        isOpen={AddLocationModalOpen.isAddLocationModalOpen}
        entityType={EntityTypeEnum.Customer}
        entityId={AddLocationModalOpen.entityId}
        OnSave={OnSave}
        handleClose={handleCloseForAddLocation}
        title={i18nMessages.Addlocation}
        isHidden={true}
        newLocation={AddLocationModalOpen.newLocation}
        triggerFrom={AddLocationModalOpen.triggerFrom}
        newDestination={AddLocationModalOpen.newDestination}
      ></AddLocation>
      {canEdit ? (
        <div className="btn-wrap form-btn-wrap">
          <Button
            color="neutral"
            disableElevation
            variant="contained"
            onClick={handleCancelOrder}
          >
            {i18nMessages.Cancel}
          </Button>
          {!isOfferRejected ? (
            <Button
              type="submit"
              color="primary"
              disableElevation
              variant="contained"
              onClick={handleConvertToOrder}
            >
              {i18nMessages.ConvertToOrder}
            </Button>
          ) : (
            <></>
          )}

          <Button
            type="submit"
            color="primary"
            disableElevation
            variant="contained"
            onClick={handleSaveClick}
          >
            {i18nMessages.CommonSave}
          </Button>
        </div>
      ) : (
        <></>
      )}
      <StatePopup
        isOpen={openState}
        handleClose={handleCloseForEditState}
        OnSave={handleOnSaveState}
        data={rowDataState}
        title={i18nMessages.AddState}
      ></StatePopup>

      <AirlinePopup
        isOpen={openAirline}
        handleClose={handleCloseForEditAirline}
        OnSave={handleOnSaveAirline}
        data={rowDataAirline}
        title={i18nMessages.AddAirline}
      ></AirlinePopup>

      <ConfirmationPopup
        isOpen={isDeleteModalOpen}
        data={id}
        message={I18n('DecPackOrders.DeleteMessage')}
        handleClose={handleCloseForDelete}
        handleOk={handleOkForDelete}
        title={I18n('DecPackOrders.DeleteTitle')}
      ></ConfirmationPopup>
      <ViewCustomerModal
        isOpen={viewCustomerDetails.isShowCustomerDetails}
        entityId={values.customer.value}
        handleClose={handleCloseForShowCustomerDetails}
        title={i18nMessages.viewCustomer}
      />
      <ConfirmationPopup
        isOpen={isOpen}
        message={i18nMessages.ConvertToOrderConfirmation}
        handleClose={handleCloseModal}
        handleOk={handleOkModal}
      ></ConfirmationPopup>
    </div>
  );
}

export default DecPackOfferDetails;
