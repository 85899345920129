import FormControl from '@mui/material/FormControl';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import moment from 'moment';

export const DateTimePickerInput = (props: any) => {
  const {
    displayLabel,
    handleChange,
    value,
    readOnly,
    errorValue,
    errorMessage,
    required,
  } = props;
  return (
    <FormControl
      variant="standard"
      fullWidth
      sx={{ mb: 2 }}
      error={errorMessage}
    >
      <InputLabel shrink htmlFor="bootstrap-input">
        {displayLabel}
        <span> {required ? '*' : ''}</span>
      </InputLabel>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DateTimePicker
          format="DD/MM/YYYY HH:mm"
          value={value ? moment(value) : ''}
          onChange={handleChange}
          disabled={readOnly}
          slotProps={{ textField: { variant: 'outlined' } }}
        />
      </LocalizationProvider>
      {errorValue ? <FormHelperText>{errorMessage}</FormHelperText> : ''}
    </FormControl>
  );
};
export default DateTimePickerInput;
