import { NumericFormat } from 'react-number-format';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { Grid } from '@mui/material';
import { TextField } from '@mui/material';

export const DimentionsInputs = (props: any) => {
  const {
    displayLabel,
    inputProps,
    handleChange1,
    handleChange2,
    handleChange3,
    value1,
    value2,
    value3,
    readOnly,
    name,
    errorMessage,
    errorValue,
    required,
  } = props;
  return (
    <Grid container spacing={2} className="grid-wrap">
      <Grid item md={4} sm={6} xs={12}>
        <FormControl
          variant="standard"
          fullWidth
          sx={{ mb: 2 }}
          error={errorMessage}
        >
          <InputLabel shrink htmlFor="bootstrap-input">
            {displayLabel}
            {required ? '*' : ''}
          </InputLabel>
          <NumericFormat
            name={name}
            error={errorValue}
            customInput={TextField}
            decimalSeparator=","
            valueIsNumericString={false}
            inputProps={inputProps}
            onChange={(e) => handleChange1(e, 'dimention1')}
            id={displayLabel}
            value={value1}
            readOnly={readOnly}
          />
          {errorValue ? <FormHelperText>{errorMessage}</FormHelperText> : ''}
        </FormControl>
      </Grid>
      <Grid item md={4} sm={6} xs={12}>
        <FormControl
          variant="standard"
          fullWidth
          sx={{ mb: 2 }}
          error={errorMessage}
        >
          <NumericFormat
            name={name}
            error={errorValue}
            customInput={TextField}
            decimalSeparator=","
            valueIsNumericString={false}
            inputProps={inputProps}
            onChange={(e) => handleChange2(e, 'dimention2')}
            id={displayLabel}
            value={value2}
            readOnly={readOnly}
          />
        </FormControl>
      </Grid>
      <Grid item md={4} sm={6} xs={12}>
        <FormControl
          variant="standard"
          fullWidth
          sx={{ mb: 2 }}
          error={errorMessage}
        >
          <NumericFormat
            name={name}
            error={errorValue}
            customInput={TextField}
            decimalSeparator=","
            valueIsNumericString={false}
            inputProps={inputProps}
            onChange={(e) => handleChange3(e, 'dimention3')}
            id={displayLabel}
            value={value3}
            readOnly={readOnly}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default DimentionsInputs;
