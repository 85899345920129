import I18n from 'utilities/i18n';
import { useEffect, useCallback, useState } from 'react';
import http from '../../utilities/httpService';
import { EntityStatus, OrderByDirection } from 'models/pagination-model';
import { Breadcrumbs, Button, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faPlus } from '@fortawesome/free-solid-svg-icons';
import DeleteIcon from '@mui/icons-material/Delete';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from 'common/formControl/formControl';
import CustomDataGrid from 'common/datagrid/datagrid';
import {
  GridActionsCellItem,
  GridColumns,
  GridPaginationInitialState,
  GridRowParams,
  GridSortingInitialState,
  GridSortModel,
} from '@mui/x-data-grid';
import environment from 'environment';
import ConfirmationPopup from 'common/confirmationPopup/confirmationPopup';
import { toast } from 'react-toastify';
import { Permission } from 'Permissions';
import { hasPermission } from 'utilities/protectedRoute';
import { debounce } from 'lodash';
import AirlinePopup from './AirlinePopup';
import EditIcon from '@mui/icons-material/Edit';

function AirlineList() {
  const breadcrumbs = [
    <Typography key="3" color="text.primary">
      {I18n('Nav.Configuration')}
    </Typography>,
    <Typography key="3" color="text.primary">
      {I18n('Nav.Airline')}
    </Typography>,
  ];
  const [gridData, setGridData] = useState({
    sortOrder: OrderByDirection.Descending,
    sortBy: 'UpdatedDate',
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [10, 20, 50, 100],
    pageSize: 10,
    page: 0,
    searchExpression: '',
  });
  const [isDeleteModalOpen, setDeleteModal] = useState(false);
  const [selectedId, setId] = useState();
  const [open, setOpen] = useState(false);
  const [rowData, setRowData] = useState({});
  const [basicSearchExpression, setBasicSearchExpression] = useState('');

  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));
  const getAirline = async () => {
    const apiUrl = new URL(environment.api.baseUrl + 'Airline');
    try {
      apiUrl.searchParams.set('$filter', basicSearchExpression);
      apiUrl.searchParams.set('$orderby', gridData.sortBy);
      apiUrl.searchParams.set(
        '$skip',
        (gridData.page * gridData.pageSize).toString()
      );
      apiUrl.searchParams.set('$top', gridData.pageSize.toString());
      apiUrl.searchParams.set(
        '$orderbydirection',
        gridData.sortOrder.toString()
      );
      const result = await http.get(apiUrl.toString());
      if (result) {
        updateGridData('totalRows', result.data.totalCount);
        updateGridData('rows', result.data.values);
      }
    } catch (error) {}
  };

  const i18nMessages = {
    Name: I18n('Airline.Name'),
    deletedSuccessfully: I18n('Airline.DeletedSuccessfully'),
    errorOccurred: I18n('Common.ErrorOccurred'),
    Actions: I18n('Common.Actions'),
  };

  useEffect(() => {
    getAirline();
  }, [
    gridData.page,
    gridData.pageSize,
    gridData.sortBy,
    gridData.sortOrder,
    gridData.searchExpression,
    basicSearchExpression,
  ]);

  const handleChangePage = (pageNo: number) => {
    updateGridData('page', pageNo);
  };

  const handleChangeBasicSearch = debounce((searchExpression: any) => {
    setBasicSearchExpression('name~like~' + searchExpression.target.value);
  }, 500);
  const handleChangePageSize = (pageSize: number) => {
    updateGridData('pageSize', pageSize);
  };

  type Row = (typeof gridData.rows)[number];
  const deleteAirline = useCallback(
    (id: any) => () => {
      setDeleteModal(true);
      setId(id);
    },
    []
  );

  const columns: GridColumns<Row> = [
    {
      field: 'name',
      headerName: i18nMessages.Name,
      flex: 1,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: i18nMessages.Actions,
      width: 100,
      getActions: (params: GridRowParams) => {
        const actionButtons = [];
        if (params.row.status !== EntityStatus.Active) {
          actionButtons.push(
            <>
              {hasPermission(Permission.canEditAirline) ? (
                <GridActionsCellItem
                  onPointerEnterCapture={false}
                  onPointerLeaveCapture={false}
                  placeholder={''}
                  icon={<EditIcon />}
                  label="Edit"
                  onClick={() => handleOnRowClick(params)}
                />
              ) : (
                <></>
              )}
              {hasPermission(Permission.canDeleteAirline) ? (
                <GridActionsCellItem
                  onPointerEnterCapture={false}
                  onPointerLeaveCapture={false}
                  placeholder={''}
                  icon={<DeleteIcon />}
                  label="Delete"
                  onClick={deleteAirline(params.id)}
                />
              ) : (
                <></>
              )}
            </>
          );
        }
        return actionButtons;
      },
    },
  ];

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    if (sortModel && sortModel.length) {
      updateGridData('sortBy', sortModel[0].field);
      updateGridData(
        'sortOrder',
        sortModel[0].sort === 'asc'
          ? OrderByDirection.Ascending
          : OrderByDirection.Descending
      );
    }
  }, []);

  const handleOnRowClick = (params: any) => {
    setRowData(params.row);
    setOpen(true);
  };

  const handleClose = () => {
    setDeleteModal(false);
  };

  const handleOk = () => {
    setDeleteModal(false);
    deleteData(selectedId);
  };

  const deleteData = async (id: any) => {
    const apiUrl = new URL(environment.api.baseUrl + 'Airline/' + id);
    try {
      const result: any = await http.delete(apiUrl.toString());
      if (result != null && result.data != null && result.data) {
        toast.success(i18nMessages.deletedSuccessfully);
        getAirline();
      } else {
        toast.error(i18nMessages.errorOccurred);
      }
    } catch (error: any) {}
  };

  const addClicked = () => {
    setRowData({});
    setOpen(true);
  };

  const handleCloseForEdit = () => {
    setOpen(false);
    setRowData({});
    getAirline();
  };

  const handleOnSave = () => {
    setOpen(false);
    setRowData({});
    getAirline();
  };

  return (
    <div>
      <div className="heading-section">
        <div className="heading-section-left">
          <Typography variant="h3" sx={{ mb: 3 }}>
            {I18n('Nav.Airline')}
          </Typography>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>
        <div className="heading-section-right">
          <div className="search-control-outer">
            <FormControl
              margin="normal"
              displayLabel=""
              required
              inputType="text"
              placeholderText={I18n('Common.Search')}
              className="search-control small-form-control"
              handleChange={handleChangeBasicSearch}
              adornmentValue={
                <InputAdornment position="start">
                  <FontAwesomeIcon icon={faSearch} />
                </InputAdornment>
              }
            ></FormControl>
          </div>
          <div className="header-btn-wrap">
            {hasPermission(Permission.canAddAirline) ? (
              <Button
                color="info"
                className="small-btn"
                disableElevation
                variant="contained"
                onClick={addClicked}
              >
                <FontAwesomeIcon icon={faPlus} className="fa-md mr-2" />
                Add
              </Button>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div className="main-content-section">
        <div className="content-block table-fix">
          <CustomDataGrid
            rowCount={gridData.totalRows}
            data={gridData.rows}
            columns={columns}
            pageNo={gridData.page}
            pageSize={gridData.pageSize}
            onSortModelChange={handleSortModelChange}
            onPageChange={handleChangePage}
            onPageSizeChange={handleChangePageSize}
            rowsPerPageOptions={gridData.rowsPerPageOptions}
            initialState={{
              sorting: {
                sortModel: [{ field: 'UpdatedDate', sort: 'desc' }],
              } as GridSortingInitialState,
              pagination: {
                page: gridData.page,
                pageSize: gridData.pageSize,
              } as GridPaginationInitialState,
            }}
          ></CustomDataGrid>

          <AirlinePopup
            isOpen={open}
            handleClose={handleCloseForEdit}
            OnSave={handleOnSave}
            data={rowData}
            title={
              Object.keys(rowData).length > 0
                ? I18n('Airline.EditAirline')
                : I18n('Airline.AddAirline')
            }
          ></AirlinePopup>

          <ConfirmationPopup
            isOpen={isDeleteModalOpen}
            data={selectedId}
            message={I18n('Airline.DeleteMessage')}
            handleClose={handleClose}
            handleOk={handleOk}
            title={I18n('Airline.DeleteAirline')}
          ></ConfirmationPopup>
        </div>
      </div>
    </div>
  );
}

export default AirlineList;
