import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { NumericFormat } from 'react-number-format';
import { FormHelperText, TextField } from '@mui/material';
export const NumericInput = (props: any) => {
  const {
    displayLabel,
    inputProps,
    handleChange,
    value,
    readOnly,
    name,
    errorMessage,
    errorValue,
    allowNegative,
    disabled,
    required,
    autoFocus = false,
    decimalScale,
  } = props;
  return (
    <FormControl
      variant="standard"
      fullWidth
      sx={{ mb: 2 }}
      error={errorMessage}
    >
      <InputLabel shrink htmlFor="bootstrap-input">
        {displayLabel}
        <span> {required ? '*' : ''}</span>
      </InputLabel>
      <NumericFormat
        autoFocus={autoFocus}
        name={name}
        error={errorValue}
        customInput={TextField}
        valueIsNumericString={false}
        inputProps={inputProps}
        onChange={handleChange}
        id={displayLabel}
        value={value}
        decimalScale={decimalScale ? decimalScale : 0}
        readOnly={readOnly}
        allowNegative={allowNegative}
        disabled={disabled}
      />
      {errorValue ? <FormHelperText>{errorMessage}</FormHelperText> : ''}
    </FormControl>
  );
};
export default NumericInput;
