import Typography from '@mui/material/Typography';
import I18n from 'utilities/i18n';
import './ContactPerson.scss';
import { useEffect, useCallback, useState } from 'react';
import http from '../../utilities/httpService';
import { EntityStatus, OrderByDirection } from 'models/pagination-model';
import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faPlus } from '@fortawesome/free-solid-svg-icons';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import InputAdornment from '@mui/material/InputAdornment';
import CustomDataGrid from 'common/datagrid/datagrid';
import { useParams } from 'react-router-dom';
import environment from 'environment';
import { toast } from 'react-toastify';
import CustomizedInputs from 'common/formControl/formControl';

import {
  GridActionsCellItem,
  GridColumns,
  GridPaginationInitialState,
  GridRowParams,
  GridSortingInitialState,
  GridSortModel,
} from '@mui/x-data-grid';
import { ContactPersonModel } from 'models/contactPersonModel';
import ConfirmationPopup from 'common/confirmationPopup/confirmationPopup';
import AddContactPerson from './add-contact-person';
import { hasPermission } from 'utilities/protectedRoute';
import { Utilities } from 'utilities/Utilities';

interface propss {
  entityType: number;
  isHidden?: boolean;
  addPermission: string;
  editPermission: string;
  deletePermission: string;
}

function ContactPerson(props: propss) {
  let { entityId } = useParams();
  const isHidden = props.isHidden;
  const [isDeleteModalOpen, setDeleteModal] = useState(false);
  const [selectedId, setId] = useState();
  const [isAddModalOpen, setAddModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [gridData, setGridData] = useState({
    sortOrder: OrderByDirection.Descending,
    sortBy: 'CreatedDate',
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [10, 20, 50, 100],
    pageSize: 10,
    page: 0,
    searchExpression:
      'EntityType~=~' + props.entityType + ' and EntityId~=~' + entityId,
  });
  const [canAdd, setCanAdd] = useState(hasPermission(props.addPermission));
  const [canEdit, setCanEdit] = useState(hasPermission(props.editPermission));
  const [canDelete, setCanDelete] = useState(
    hasPermission(props.deletePermission)
  );
  const customerPortalUser = Utilities.getIsCPUser();
  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));
  const getContactPerson = async () => {
    const apiUrl = new URL(environment.api.baseUrl + 'ContactPerson');
    try {
      apiUrl.searchParams.set('$filter', gridData.searchExpression);
      apiUrl.searchParams.set('$orderby', gridData.sortBy);
      apiUrl.searchParams.set(
        '$skip',
        (gridData.page * gridData.pageSize).toString()
      );
      apiUrl.searchParams.set('$top', gridData.pageSize.toString());
      apiUrl.searchParams.set(
        '$orderbydirection',
        gridData.sortOrder.toString()
      );
      const result = await http.get(apiUrl.toString());
      if (result) {
        updateGridData('totalRows', result.data.totalCount);
        updateGridData('rows', result.data.values);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getContactPerson();
  }, [
    gridData.page,
    gridData.pageSize,
    gridData.sortBy,
    gridData.sortOrder,
    gridData.searchExpression,
  ]);

  const refreshData = async () => {
    handleCloseForAdd();
    await getContactPerson();
  };
  const handleChangePage = (pageNo: number) => {
    updateGridData('page', pageNo);
  };

  const handleChangeBasicSearch = (searchExpression: any) => {
    updateGridData(
      'searchExpression',
      'FirstName~like~' +
        searchExpression.target.value +
        ' or LastName~like~' +
        searchExpression.target.value +
        ' and EntityType~=~' +
        props.entityType +
        ' and EntityId~=~' +
        entityId
    );
  };
  const handleChangePageSize = (pageSize: number) => {
    updateGridData('pageSize', pageSize);
  };
  type Row = (typeof gridData.rows)[number];
  const deleteContactPerson = useCallback(
    (id: any) => () => {
      setDeleteModal(true);
      setId(id);
    },
    []
  );
  const editContactPerson = useCallback(
    (data: any) => () => {
      setEditData(data);
      setAddModal(true);
    },
    []
  );

  const i18nMessages = {
    deletedSuccessfully: I18n('ContactPerson.DeletedSuccessfully'),
    errorOccurred: I18n('ContactPerson.ErrorOccurred'),
    phone: I18n('ContactPerson.Phone'),
    functionDesc: I18n('ContactPerson.Function'),
    siteInformation: I18n('ContactPerson.SiteInformation'),
    Location: I18n('ContactPerson.Location'),
  };

  const columns: GridColumns<Row> = [
    {
      field: 'name',
      headerName: I18n('ContactPerson.Name'),
      flex: 1,
    },
    {
      field: 'functionIdDescription',
      headerName: i18nMessages.functionDesc,
      flex: 1,
      hide: isHidden,
    },
    {
      field: 'phone',
      headerName: i18nMessages.phone,
      flex: 1,
      hide: isHidden,
    },
    {
      field: 'mobile',
      headerName: I18n('ContactPerson.Mobile'),
      flex: 1,
    },
    {
      field: 'email',
      headerName: I18n('ContactPerson.Email'),
      flex: 1,
    },
    {
      field: 'siteInformation',
      headerName: i18nMessages.siteInformation,
      flex: 1,
      hide: isHidden,
    },
    {
      field: 'locations',
      headerName: i18nMessages.Location,
      flex: 1,
      hide: true,
    },
    {
      field: 'systemAccess',
      headerName: I18n('ContactPerson.SystemAccess'),
      flex: 1,
      hide: customerPortalUser,
      renderCell: (params) => {
        return params.formattedValue ? I18n('Common.Yes') : I18n('Common.No');
      },
    },
    {
      field: 'actions',
      headerName: I18n('Common.Actions'),
      type: 'actions',
      width: 100,
      getActions: (params: GridRowParams<ContactPersonModel>) => {
        const actionButtons = [];
        if (canEdit) {
          actionButtons.push(
            <GridActionsCellItem
              onPointerEnterCapture={false}
              onPointerLeaveCapture={false}
              placeholder={''}
              icon={<EditIcon />}
              label="Edit"
              onClick={editContactPerson(params)}
            />
          );
        }
        if (canDelete && params.row.status !== EntityStatus.Active) {
          actionButtons.push(
            <GridActionsCellItem
              onPointerEnterCapture={false}
              onPointerLeaveCapture={false}
              placeholder={''}
              icon={<DeleteIcon />}
              label="Delete"
              onClick={deleteContactPerson(params.id)}
            />
          );
        }

        if (canEdit && !params.row.systemAccess) {
          actionButtons.push(
            <GridActionsCellItem
              onPointerEnterCapture={false}
              onPointerLeaveCapture={false}
              placeholder={''}
              icon={<ArrowOutwardIcon />}
              label="GiveAccess"
              onClick={editContactPerson(params)}
            />
          );
        }

        return actionButtons;
      },
    },
  ];

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    if (sortModel && sortModel.length) {
      updateGridData('sortBy', sortModel[0].field);
      updateGridData(
        'sortOrder',
        sortModel[0].sort === 'asc'
          ? OrderByDirection.Ascending
          : OrderByDirection.Descending
      );
    }
  }, []);
  const OnSave = () => {
    setEditData(null);
    setAddModal(false);
  };

  const handleClose = () => {
    setDeleteModal(false);
  };

  const handleCloseForAdd = () => {
    setEditData(null);
    setAddModal(false);
  };

  const handleOk = () => {
    setDeleteModal(false);
    deleteData(selectedId);
  };

  const addContactPerson = useCallback(() => {
    setAddModal(true);
  }, []);

  const deleteData = async (id: any) => {
    const apiUrl = new URL(environment.api.baseUrl + 'ContactPerson/' + id);
    try {
      const result: any = await http.delete(apiUrl.toString());
      if (result != null && result.data != null && result.data.success) {
        toast.success(i18nMessages.deletedSuccessfully);

        getContactPerson();
      } else {
        toast.error(i18nMessages.errorOccurred);
      }
    } catch (error: any) {}
  };

  return (
    <div>
      <Typography variant="h5" className="inner-heading has-button">
        {I18n('Customer.Tabs.ContactPerson')}
        <div className="inner-btn-wrap">
          <div className="text-right container-search">
            <div className="search-control-outer">
              <CustomizedInputs
                margin="normal"
                displayLabel=""
                inputType="text"
                placeholderText="Search"
                handleChange={handleChangeBasicSearch}
                className="search-control small-form-control"
                adornmentValue={
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={faSearch} />
                  </InputAdornment>
                }
              />
            </div>
          </div>
          {canAdd ? (
            <Button
              color="primary"
              className="small-btn"
              disableElevation
              onClick={addContactPerson}
              variant="contained"
            >
              <FontAwesomeIcon icon={faPlus} className="fa-md" />
            </Button>
          ) : (
            <></>
          )}
        </div>
      </Typography>
      <div className="">
        <div className="content-block table-fix">
          <CustomDataGrid
            rowCount={gridData.totalRows}
            data={gridData.rows}
            columns={columns}
            pageNo={gridData.page}
            pageSize={gridData.pageSize}
            onSortModelChange={handleSortModelChange}
            onPageChange={handleChangePage}
            onPageSizeChange={handleChangePageSize}
            rowsPerPageOptions={gridData.rowsPerPageOptions}
            initialState={{
              sorting: {
                sortModel: [{ field: 'updatedDate', sort: 'desc' }],
              } as GridSortingInitialState,
              pagination: {
                page: gridData.page,
                pageSize: gridData.pageSize,
              } as GridPaginationInitialState,
            }}
          ></CustomDataGrid>

          <ConfirmationPopup
            isOpen={isDeleteModalOpen}
            data={selectedId}
            message={I18n('ContactPerson.DeleteMessage')}
            handleClose={handleClose}
            handleOk={handleOk}
            title={I18n('ContactPerson.DeleteContactPerson')}
          ></ConfirmationPopup>

          <AddContactPerson
            isOpen={isAddModalOpen}
            editData={editData}
            entityType={props.entityType}
            entityId={entityId}
            handleClose={handleCloseForAdd}
            title={I18n('ContactPerson.AddContactPerson')}
            refreshData={refreshData}
            isHidden={isHidden}
            OnSave={OnSave}
          ></AddContactPerson>
        </div>
      </div>
    </div>
  );
}

export default ContactPerson;
