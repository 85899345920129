import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import I18n from 'utilities/i18n';
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import CustomSelectPaginate from 'common/CustomSelect/CustomSelectPaginate';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import {
  BaseModel,
  PaginationResponseModel,
  TansportCategoriesDescription,
} from 'models/pagination-model';
import environment from 'environment';
import { toast } from 'react-toastify';
import http from '../../../utilities/httpService';
import { Customer } from 'models/service-catalogue';
import FormControl from 'common/formControl/formControl';
import PricingChip from 'components/transport-catalogue/pricing/PricingChip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import CustomizedSelect from 'common/CustomSelect/CustomSelect';
import { loadSpecialPricingTransportCatalogues } from 'components/shipping-orders/ShippingApiService';

export default function AddTransportSpecialPricing(props: any) {
  const {
    isOpen,
    handleClose,
    title,
    entityType,
    entityId,
    editData,
    addData,
  } = props;
  const defaultValue = {
    value: '',
    label: I18n('Placeholders.SelectCustomer'),
  } as BaseModel;

  const defaultDropDownValue = {
    value: '',
    label: I18n('Placeholders.Select'),
  };

  const [specialPricingRows, setSpecialPricingRows] = useState<any>([]);
  const [transportTypeList, setTransportTypeList] = useState<any>([]);

  const [values, setValues] = useState<any>({
    customer: defaultValue,
    transportCatalogue: defaultDropDownValue,
    transportType: defaultDropDownValue,
    id: 0,
  });

  const i18nMessages = {
    commonFieldIsRequired: I18n('Common.FieldIsRequired'),
    somethingWentWrong: I18n('Common.SomethingWentWrong'),
    specialPricingSavedSuccess: I18n('SpecialPricing.SavedSuccessfully'),
    specialPricingUpdatedSuccess: I18n('SpecialPricing.UpdatedSuccessfully'),
    InvalidCustomerSpecialPricing: I18n(
      'Customer.Validation.InvalidCustomerSpecialPricing'
    ),
  };

  const showMessageRefresh = () => {
    toast.success(
      values.id
        ? i18nMessages.specialPricingUpdatedSuccess
        : i18nMessages.specialPricingSavedSuccess
    );
    setDefaultValuesForControls();
    props.refreshData();
  };

  const [errors, setErrors] = useState({
    customer: '',
    transportCatalogue: '',
    transportType: '',
  });

  const handleSpecialPricingZoneChange = (idx: any) => (e: any) => {
    const { name, value } = e.target;
    const finalrows: any = [...specialPricingRows];
    finalrows[idx][name] = value;
    setSpecialPricingRows(finalrows);
  };

  const handleSpecialPricingRemoveSpecificRow = (idx: any) => () => {
    const rowsdata = [...specialPricingRows];
    rowsdata.splice(idx, 1);
    setSpecialPricingRows(rowsdata);
  };

  const specialPricingChipClicked = (
    selectedId: any,
    name: any,
    value: any
  ) => {
    const finalrows: any = [...specialPricingRows];
    finalrows[selectedId][name] = finalrows[selectedId][name] + value;
    setSpecialPricingRows(finalrows);
  };

  const validate = () => {
    let temp = { ...errors };
    temp.customer = values.customer.value
      ? ''
      : i18nMessages.commonFieldIsRequired;

    temp.transportCatalogue = values.transportCatalogue.value
      ? ''
      : i18nMessages.commonFieldIsRequired;

    temp.transportType = values.transportType.value
      ? ''
      : i18nMessages.commonFieldIsRequired;

    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === '');
  };

  const setDefaultValuesForControls = () => {
    values.customer.label = defaultValue.label;
    values.customer.value = defaultValue.value;
    setValues({
      ...values,
      customer: defaultValue,
      transportCatalogue: defaultDropDownValue,
      transportType: defaultDropDownValue,
      id: 0,
    });
    setSpecialPricingRows([]);
  };
  const submit = async () => {
    if (validate()) {
      const apiUrl = new URL(
        environment.api.baseUrl + `TransportZoneSpecialPrice`
      );
      try {
        specialPricingRows.forEach((element: any) => {
          element.customerId = +element.customerId;
        });

        var finalObj = {
          transportZoneSpecialPriceList: specialPricingRows,
          transportCatalogueId: Number(values.transportCatalogue.value),
          transportTypeId: Number(values.transportType.value),
          customerId: Number(values.customer.value),
        };

        const result: any = await http.post(apiUrl.toString(), finalObj);
        if (result) {
          showMessageRefresh();
        } else {
          toast.error(I18n('Common.ErrorOccurred'));
        }
      } catch (error: any) {}
    }
  };

  const close = () => {
    setDefaultValuesForControls();
    props.handleClose();
  };

  useEffect(() => {
    if (editData) {
      setValues({
        customer: {
          label: editData.row.customerName,
          value: editData.row.customerId.toString(),
        } as BaseModel,
        transportType: {
          label: editData.row.transportTypeName,
          value: editData.row.transportTypeId.toString(),
        } as BaseModel,
        transportCatalogue: {
          label: editData.row.transportCatalogueName,
          value: editData.row.transportCatalogueId.toString(),
        } as BaseModel,
        id: editData.row.id,
      });
      getSpecialPrice(editData.row.transportTypeId, editData.row.customerId);
    } else if (addData) {
      setValues({ ...values, customer: addData });
      setSpecialPricingRows([]);
    } else {
      setDefaultValuesForControls();
    }
  }, [editData, addData]);

  const defaultAdditional: any = {
    page: 1,
  };

  const getCustomers = async (search: string, pageNo: number) => {
    let response: PaginationResponseModel<Customer>;
    const apiUrl = new URL(
      environment.api.baseUrl +
        'Customer/GetSpecialPricingSpecificCustomerDropdownData'
    );
    try {
      let searchExpression = '';
      searchExpression += search ? `name~like~${search}` : '';
      apiUrl.searchParams.set('$filter', searchExpression);
      apiUrl.searchParams.set('$orderby', 'name');
      apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
      apiUrl.searchParams.set('$top', '10');
      apiUrl.searchParams.set('$orderbydirection', 'desc');
      apiUrl.searchParams.set('entityId', entityId);
      apiUrl.searchParams.set('entityType', entityType);
      const result = await http.get(apiUrl.toString());
      if (result) {
        response = {
          TotalRecords: result.data.totalCount,
          Values: result.data.values,
        } as PaginationResponseModel<Customer>;
      } else {
        response = {
          TotalRecords: 0,
          Values: [],
        } as PaginationResponseModel<Customer>;
      }
    } catch (error) {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<Customer>;
    }
    return response;
  };

  const loadCustomerDropdownOptions = async (
    search: string,
    loadedOptions: any,
    { page }: any
  ) => {
    const response = await getCustomers(search, page);
    const hasMore = Math.ceil(response.TotalRecords / 10) > page;

    return {
      options: response.Values.map((x) => {
        return {
          value: x.id.toString(),
          label: x.name,
        } as BaseModel;
      }),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const handleSpecialPricingAddRow = (e: any) => {
    e.preventDefault();
    const item = {
      customerId: values.customer.value,
      customerName: values.customer.label,
      kg1: '',
      kg2: '',
      deliveryRate: '',
      fuelRate: '',
      transportCatalogueId: Number(values.transportCatalogue.value),
      transportTypeId: Number(values.transportType.value),
    };
    setSpecialPricingRows([...specialPricingRows, item]);
  };

  const getSpecialPrice = async (transportTypeId: any, customerId: any) => {
    const apiUrl = new URL(
      environment.api.baseUrl +
        `TransportZoneSpecialPrice/${entityId}/${transportTypeId}/${customerId}`
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result && result.data) {
        setSpecialPricingRows(result.data);
      }
    } catch (error) {}
  };

  const handleTransportCatalogueChange = (value: any) => {
    getTransportCatalogueById(value.value);
  };

  const getTransportCatalogueById = async (id: any) => {
    const apiUrl = new URL(
      environment.api.baseUrl + `TransportCatalogues/${id}`
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result && result.data) {
        let transportType = result?.data?.transportTypes?.map((x: any) => {
          return {
            label: x.name.toString(),
            value: x.id.toString(),
          };
        });
        setTransportTypeList(transportType);
      }
    } catch (error) {}
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={'lg'}
        fullWidth={true}
      >
        <DialogTitle>
          {title}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className="grid-wrap two-col">
            <Grid item xs={4}>
              <CustomSelectPaginate
                required
                additional={defaultAdditional}
                value={values.customer}
                loadOptions={loadCustomerDropdownOptions}
                handleChange={(newValue: any) =>
                  setValues({ ...values, customer: newValue })
                }
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                }}
                placeholder={defaultValue.label}
                isSearchable={true}
                displayLabel={I18n('SpecialPricing.Customer')}
                errorValue={true}
                errorMessage={errors.customer}
                readOnly={true}
                debounceTimeout={500}
                isMultiSelect={false}
              />
            </Grid>
            <Grid item xs={4}>
              <CustomSelectPaginate
                required
                additional={defaultAdditional}
                value={values.transportCatalogue}
                loadOptions={loadSpecialPricingTransportCatalogues}
                handleChange={(newValue: any) => {
                  setValues({ ...values, transportCatalogue: newValue });
                  handleTransportCatalogueChange(newValue);
                }}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                }}
                placeholder={defaultDropDownValue.label}
                isSearchable={true}
                displayLabel={I18n('Nav.TransportCatalogue')}
                errorValue={true}
                errorMessage={errors.transportCatalogue}
                readOnly={!addData}
                debounceTimeout={500}
                isMultiSelect={false}
              />
            </Grid>
            <Grid item xs={4}>
              <CustomizedSelect
                options={transportTypeList}
                isSearchable={false}
                readOnly={!addData}
                value={values.transportType}
                handleChange={(newValue: any) =>
                  setValues({ ...values, transportType: newValue })
                }
                errorValue={true}
                errorMessage={errors.transportType}
                displayLabel={I18n('Partner.TransportTypes')}
                isDisplayLabelClass={true}
                required
              ></CustomizedSelect>
            </Grid>
            <Grid item xs={12}>
              <Table className="basic-table" id="tab_logic" width="100%">
                <TableHead>
                  <TableRow>
                    <TableCell align="left" width={'30%'}>
                      {I18n('TransportCatalogue.Kg')}
                    </TableCell>
                    <TableCell align="left">
                      {I18n('TransportCatalogue.DeliveryPrice')}
                    </TableCell>
                    <TableCell align="left">
                      {I18n('TransportCatalogue.FuelPrice')}
                    </TableCell>
                    <TableCell align="left">
                      <Button
                        className="icon-btn"
                        disableElevation
                        onClick={handleSpecialPricingAddRow}
                      >
                        <AddIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {specialPricingRows?.map((item: any, idx: any) => (
                    <TableRow key={idx}>
                      <TableCell>
                        <Box
                          display="flex"
                          alignItems="center"
                          className="input-55"
                        >
                          <FormControl
                            className="no-label small-input-control"
                            margin="normal"
                            name="kg1"
                            inputType="text"
                            value={item?.kg1}
                            handleChange={handleSpecialPricingZoneChange(idx)}
                            readOnly={false}
                          />
                          <div className="text-center text-55"> - </div>
                          <FormControl
                            className="no-label small-input-control"
                            margin="normal"
                            name="kg2"
                            inputType="text"
                            value={item?.kg2}
                            handleChange={handleSpecialPricingZoneChange(idx)}
                            readOnly={false}
                          />
                        </Box>
                      </TableCell>
                      <TableCell>
                        <PricingChip
                          name="deliveryRate"
                          selectedId={idx}
                          handleClick={specialPricingChipClicked}
                        ></PricingChip>

                        <FormControl
                          className="no-label small-input-control"
                          margin="normal"
                          inputType="text"
                          name="deliveryRate"
                          value={item.deliveryRate}
                          handleChange={handleSpecialPricingZoneChange(idx)}
                          readOnly={false}
                        />
                      </TableCell>
                      <TableCell>
                        <PricingChip
                          name="fuelRate"
                          handleClick={specialPricingChipClicked}
                          selectedId={idx}
                        ></PricingChip>

                        <FormControl
                          className="no-label small-input-control"
                          margin="normal"
                          inputType="text"
                          name="fuelRate"
                          handleChange={handleSpecialPricingZoneChange(idx)}
                          value={item.fuelRate}
                          readOnly={false}
                        />
                      </TableCell>
                      <TableCell>
                        <Button
                          color="info"
                          className="small-btn"
                          disableElevation
                          variant="contained"
                          onClick={handleSpecialPricingRemoveSpecificRow(idx)}
                        >
                          <FontAwesomeIcon
                            icon={faTrash}
                            className="fa-md mr-2"
                          />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="btn-wrap">
          <Button
            onClick={close}
            color="neutral"
            disableElevation
            variant="contained"
          >
            {' '}
            {I18n('Notes.Cancel')}
          </Button>
          <Button
            onClick={submit}
            autoFocus
            color="primary"
            disableElevation
            variant="contained"
          >
            {I18n('Notes.Save')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
