import { BaseSyntheticEvent, useCallback, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import I18n from 'utilities/i18n';
import { CustomizedInputs } from 'common/formControl/formControl';
import { Grid, Typography } from '@mui/material';
import CustomizedSelect from 'common/CustomSelect/CustomSelect';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {
  BaseModel,
  EntityStatus,
  OrderByDirection,
  PaginationResponseModel,
  AdvanceModel,
  RadioGroupBaseModel,
  PackageCheckList,
  Department,
  DangerousGoodsMasterModel,
  DangerousGoodsMasterValueModel,
  DangerousGoodsValueModel,
} from 'models/pagination-model';
import environment from 'environment';
import { toast } from 'react-toastify';
import http from '../../../utilities/httpService';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { hasPermission } from 'utilities/protectedRoute';
import { Permission } from 'Permissions';
import {
  GridActionsCellItem,
  GridColumns,
  GridPaginationInitialState,
  GridRowId,
  GridRowParams,
  GridSortingInitialState,
  GridSortModel,
  GridRowModes,
  GridRowModesModel,
  GridRowModel,
  MuiEvent,
  GridEventListener,
  GridToolbarContainer,
  GridRowsProp,
  GridRenderEditCellParams,
  useGridApiContext,
} from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import Commodity from './Commodity';
import value from 'environment';
import NumericInput from 'common/NumericInput/NumericInput';
import CustomDataGridInline from 'common/datagrid/CustomDataGridInline';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import CustomSelectPaginateAdd from 'common/CustomSelect/CustomSelectPaginateAdd';
import TariffCustomDailog from './TariffCustomDailog';
import {
  PostUnitDropDown,
  PostCountryDropDown,
  PostCurrencyDropDown,
} from 'common/DropDownAddAPI/DropDownApiService';
import { Customer, TariffCustoms } from 'models/service-catalogue';
import RadioGroupInput from 'common/RadioGroup/RadioGroupInput';
import DecimalInput from 'common/DecimalInput/DecimalInput';
import { InnerBox } from './InnerBox';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';

export default function ShippingPackage(props: any) {
  const defaultDropDownValue = {
    value: '0',
    label: I18n('Placeholders.Select'),
  } as BaseModel;
  const { isOpen, handleClose, title, data } = props;
  const [errors, setErrors] = useState<any>({});
  const [gridData, setGridData] = useState({
    isLoading: true,
    sortOrder: OrderByDirection.Descending,
    sortBy: 'HSCODE',
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [10, 20, 50, 100],
    pageSize: 10,
    page: 0,
    searchExpression: '',
  });
  const [addHSCodeModalOpen, setAddHSCodeModalOpen] = useState({
    isAddHSCodeModalOpen: false,
    newCode: '',
    triggerFrom: 0,
    gridId: {},
    apiRef: {},
  });

  const [values, setValues] = useState({
    id: 0,
    productCatalogueId: '',
    shippingOrderId: props.shippingOrderId,
    productName: '',
    weight: '',
    dimension1: '',
    dimension2: '',
    dimension3: '',
    generalCheck: false,
    isDangerousGoodsCheck: false,
    isTemperatureControlCheck: false,
    shippingPackageDangerousGoods: [] as DangerousGoodsValueModel[],
    shippingPackageTemperatureControl: [],
    shippingCommodity: [],
    dryIceWeight: 0,
    instruction: '',
    packagingRequired: false,
    IsNoneCheck: false,
  });

  const [canAdd, setCanAdd] = useState(
    hasPermission(Permission.canAddShippingOffers) ||
      hasPermission(Permission.canAddShippingOrders)
  );

  const [canEdit, setCanEdit] = useState(
    hasPermission(Permission.canEditShippingOffers) ||
      hasPermission(Permission.canEditShippingOrders)
  );

  const [canDelete, setCanDelete] = useState(
    hasPermission(Permission.canDeleteShippingOffers) ||
      hasPermission(Permission.canDeleteShippingOrders)
  );

  const [dangerousValues, setDangerousValues] = useState<
    DangerousGoodsValueModel[]
  >([]);
  const [temperatureValues, setTemperatureValues] = useState<any>([]);
  const [dangerousGoodsMaster, setDangerousGoodsMaster] = useState<
    DangerousGoodsMasterModel[]
  >([]);
  const [dangerousGoodsValueMaster, setDangerousGoodsValueMaster] = useState<
    DangerousGoodsMasterValueModel[]
  >([]);

  const [defaultDangerousGoodsValue, setDefaultDangerousGoodsValue] =
    useState<DangerousGoodsValueModel>({
      id: 0,
      shippingPackageId: 0,
      dangerousGoodsMasterId: 0,
      packagesNumber: 0,
      unNumber: '',
      primaryClass: '',
      secondaryClass: '',
      cao: false,
      sortOrder: 1,
    });
  const [
    defaultDangerousGoodsValueMaster,
    setDefaultDangerousGoodsValueMaster,
  ] = useState<DangerousGoodsMasterValueModel[]>([]);
  const [products, setProducts] = useState<any>([]);
  const [temperatureControlledOptions, setTemperatureControlledOptions] =
    useState<any>([]);
  const [selectedProduct, setSelectedProduct] = useState(defaultDropDownValue);
  const [temperatureControllerMaster, setTemperatureControllerMaster] =
    useState<any>([defaultDropDownValue]);
  const [dangerousGoodsChecked, setDangerousGoodsChecked] =
    useState<boolean>(false);
  const [temperatureControllChecked, setTemperatureControlledChecked] =
    useState<boolean>(false);
  const [isAddModalOpen, setAddModal] = useState(false);
  const [masterProducts, setMasterProducts] = useState([]);
  const [ownPackageChecked, setOwnPackageChecked] = useState(false);
  const [packagingRequired, setPackagingRequired] = useState(false);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [rows, setRows] = useState([]);
  const [addRow, setAddRow] = useState<any>([]);
  const [cacheUniq, setCacheUniq] = useState(0);
  const [cacheUniqUnit, setCacheUniqUnit] = useState(0);
  const [cacheUniqCurrency, setCacheUniqCurrency] = useState(0);
  const [cacheUniqCountry, setCacheUniqCountry] = useState(0);
  const [isOpenHSN, setOpenHSN] = useState(false);

  const defaultValue = {
    value: '',
    label: I18n('Placeholders.Select'),
  } as BaseModel;
  const defaultAdditional: any = {
    page: 1,
  };

  type Row = (typeof gridData.rows)[number];

  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));

  const validate = () => {
    let temp: any = {};
    let isValid = true;

    if (
      !ownPackageChecked &&
      selectedProduct.value === defaultDropDownValue.value
    ) {
      temp.product = 'Common.FieldRequired';
      isValid = false;
    }
    if (values.weight === '' || Number(values.weight) === 0) {
      temp.weight = 'Common.FieldRequired';
      isValid = false;
    }
    if (values.dimension1 === '') {
      temp.dimension1 = 'Common.FieldRequired';
      isValid = false;
    }
    if (values.dimension2 === '') {
      temp.dimension2 = 'Common.FieldRequired';
      isValid = false;
    }
    if (values.dimension3 === '') {
      temp.dimension3 = 'Common.FieldRequired';
      isValid = false;
    }

    if (
      values.isTemperatureControlCheck &&
      temperatureControlValues.temperatureMasterId.value === '0'
    ) {
      temp.temperatureMasterId = i18nMessages.fieldRequired;
      isValid = false;
    }

    var validationMessage = '';
    gridData.rows.forEach((element: any) => {
      validationMessage = validateCommodity({
        hscode: element.hscode,
        description: element.description,
        quantity: element.quantity,
        unitId: element.unitId,
        currencyId: element.currencyId,
        itemValue: element.itemValue,
        netWeight: element.netWeight,
        grossWeight: element.grossWeight,
        originCountryId: element.originCountryId,
      });

      if (validationMessage !== '') {
        toast.error(validationMessage);
        isValid = false;
      }
    });

    setErrors({
      ...temp,
    });

    if (isValid) {
      return true;
    } else {
      return false;
    }
  };

  const validateCommodity = (fieldValues: any) => {
    if (
      'selectedHSCode' in fieldValues &&
      fieldValues.selectedHSCode.value === defaultDropDownValue.value &&
      'hscode' in fieldValues &&
      fieldValues.hscode === ''
    ) {
      return i18nMessages.UpdateRequired;
    }
    if ('description' in fieldValues && fieldValues.description === '') {
      return i18nMessages.UpdateRequired;
    }
    if (
      'quantity' in fieldValues &&
      (fieldValues.quantity === 0 || fieldValues.quantity == '')
    ) {
      return i18nMessages.UpdateRequired;
    }
    if (
      'itemValue' in fieldValues &&
      (fieldValues.itemValue === 0 || fieldValues.itemValue == '')
    ) {
      return i18nMessages.UpdateRequired;
    }
    if (
      'netWeight' in fieldValues &&
      (fieldValues.netWeight === 0 || fieldValues.netWeight == '')
    ) {
      return i18nMessages.UpdateRequired;
    }
    if (
      'grossWeight' in fieldValues &&
      (fieldValues.grossWeight === 0 || fieldValues.grossWeight == '')
    ) {
      return i18nMessages.UpdateRequired;
    }
    if (
      'selectedUnit' in fieldValues &&
      fieldValues.selectedUnit.value === defaultDropDownValue.value
    ) {
      return i18nMessages.UpdateRequired;
    }
    if (
      'selectedCurrency' in fieldValues &&
      fieldValues.selectedCurrency.value === defaultDropDownValue.value
    ) {
      return i18nMessages.UpdateRequired;
    }
    if (
      'selectedCountry' in fieldValues &&
      fieldValues.selectedCountry.value === defaultDropDownValue.value
    ) {
      return i18nMessages.UpdateRequired;
    }

    return '';
  };

  interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (
      newModel: (oldModel: GridRowModesModel) => GridRowModesModel
    ) => void;
  }

  const handleCloseForAddHSN = () => {
    setOpenHSN(false);
    setAddHSCodeModalOpen({
      isAddHSCodeModalOpen: false,
      newCode: '',
      triggerFrom: 0,
      gridId: {},
      apiRef: {},
    });
  };

  // UNIT LOAD-CREATE

  const getUnitMaster = async (search: string, pageNo: number) => {
    let response: PaginationResponseModel<Customer>;
    const apiUrl = new URL(
      environment.api.baseUrl + 'ShippingPackage/UnitMaster'
    );
    try {
      let searchExpression = '';
      searchExpression += search ? `name~like~${search}` : '';
      apiUrl.searchParams.set('$filter', searchExpression);
      apiUrl.searchParams.set('$orderby', 'name');
      apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
      apiUrl.searchParams.set('$top', '10');
      apiUrl.searchParams.set('$orderbydirection', 'desc');
      const result = await http.get(apiUrl.toString());
      if (result) {
        response = {
          TotalRecords: result.data.totalCount,
          Values: result.data.values,
        } as PaginationResponseModel<Customer>;
      } else {
        response = {
          TotalRecords: 0,
          Values: [],
        } as PaginationResponseModel<Customer>;
      }
    } catch (error) {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<Customer>;
    }
    return response;
  };

  const loadUnitOptions = async (
    search: string,
    loadedOptions: any,
    { page }: any
  ) => {
    const response = await getUnitMaster(search, page);
    const hasMore = Math.ceil(response.TotalRecords / 10) > page;

    return {
      options: response.Values.map((x: any) => {
        return {
          value: x.id.toString(),
          label: x.name,
        } as BaseModel;
      }),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  // CURRENCY LOAD-CREATE
  const getCurrencyMaster = async (search: string, pageNo: number) => {
    let response: PaginationResponseModel<Customer>;
    const apiUrl = new URL(
      environment.api.baseUrl + 'ShippingPackage/CurrencyMaster'
    );
    try {
      let searchExpression = '';
      searchExpression += search ? `name~like~${search}` : '';
      apiUrl.searchParams.set('$filter', searchExpression);
      apiUrl.searchParams.set('$orderby', 'name');
      apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
      apiUrl.searchParams.set('$top', '10');
      apiUrl.searchParams.set('$orderbydirection', 'desc');
      const result = await http.get(apiUrl.toString());
      if (result) {
        response = {
          TotalRecords: result.data.totalCount,
          Values: result.data.values,
        } as PaginationResponseModel<Customer>;
      } else {
        response = {
          TotalRecords: 0,
          Values: [],
        } as PaginationResponseModel<Customer>;
      }
    } catch (error) {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<Customer>;
    }
    return response;
  };

  const loadCurrencyOptions = async (
    search: string,
    loadedOptions: any,
    { page }: any
  ) => {
    const response = await getCurrencyMaster(search, page);
    const hasMore = Math.ceil(response.TotalRecords / 10) > page;

    return {
      options: response.Values.map((x: any) => {
        return {
          value: x.id.toString(),
          label: x.name,
        } as BaseModel;
      }),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  // COUNTRY LOAD-CREATE
  const getCountries = async (search: string, pageNo: number) => {
    let response: PaginationResponseModel<Customer>;
    const apiUrl = new URL(environment.api.baseUrl + 'Country');
    try {
      let searchExpression = '';
      searchExpression += search ? `name~like~${search}` : '';
      apiUrl.searchParams.set('$filter', searchExpression);
      apiUrl.searchParams.set('$orderby', 'name');
      apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
      apiUrl.searchParams.set('$top', '10');
      apiUrl.searchParams.set('$orderbydirection', 'desc');
      const result = await http.get(apiUrl.toString());
      if (result) {
        response = {
          TotalRecords: result.data.totalCount,
          Values: result.data.values,
        } as PaginationResponseModel<Customer>;
      } else {
        response = {
          TotalRecords: 0,
          Values: [],
        } as PaginationResponseModel<Customer>;
      }
    } catch (error) {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<Customer>;
    }
    return response;
  };

  const loadCountryOptions = async (
    search: string,
    loadedOptions: any,
    { page }: any,
    loadInstruction: boolean = false
  ) => {
    const response = await getCountries(search, page);
    const hasMore = Math.ceil(response.TotalRecords / 10) > page;

    return {
      options: response.Values.map((x: any) => {
        return {
          value: x.id.toString(),
          label: loadInstruction ? x.description : x.name,
        } as BaseModel;
      }),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  // HS CODE LOAD-CREATE

  const getTariffCustoms = async (search: string, pageNo: number) => {
    let response: PaginationResponseModel<TariffCustoms>;
    const apiUrl = new URL(environment.api.baseUrl + 'TariffCustoms');
    try {
      let searchExpression = ``;
      searchExpression += search ? `cnKey~like~${search}` : '';
      apiUrl.searchParams.set('$filter', searchExpression);
      apiUrl.searchParams.set('$orderby', 'cnKey');
      apiUrl.searchParams.set('$skip', ((pageNo - 1) * 10).toString());
      apiUrl.searchParams.set('$top', '10');
      apiUrl.searchParams.set(
        '$orderbydirection',
        OrderByDirection.Descending.toString()
      );
      const result = await http.get(apiUrl.toString());
      if (result) {
        response = {
          TotalRecords: result.data.totalCount,
          Values: result.data.values,
        } as PaginationResponseModel<TariffCustoms>;
      } else {
        response = {
          TotalRecords: 0,
          Values: [],
        } as PaginationResponseModel<TariffCustoms>;
      }
    } catch (error) {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<TariffCustoms>;
    }
    return response;
  };

  const loadTariffCustomsOptions = async (
    search: string,
    loadedOptions: any,
    { page }: any
  ) => {
    const response = await getTariffCustoms(search, page);
    const hasMore = Math.ceil(response.TotalRecords / 10) > page;
    return {
      options: response.Values.map((x) => {
        return {
          value: x.id.toString(),
          label: x.cnKey,
          description: x.description,
        } as AdvanceModel;
      }),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const OnSaveCNCode = async (
    response: any,
    apiRef: any,
    id: any,
    triggerFrom: number
  ) => {
    if (response && response?.data) {
      let newOption = {
        value: response?.data.id,
        label: response?.data.cnKey,
        description: response?.data.description,
      };

      apiRef.current.setEditCellValue({
        id,
        field: 'selectedHSCode',
        value: newOption,
      });
      apiRef.current.setEditCellValue({
        id,
        field: 'description',
        value: newOption.description,
      });
      apiRef.current.setEditCellValue({
        id,
        field: 'hscode',
        value: newOption.label,
      });
    }
    handleCloseForAddHSN();
  };

  function HSCodeEditComponent(props: GridRenderEditCellParams) {
    const { id, value, field } = props;
    const [focused, setFocused] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const apiRef = useGridApiContext();

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue: any = event; // The new value entered by the user
      apiRef.current.setEditCellValue({ id, field, value: newValue });
      apiRef.current.setEditCellValue({
        id,
        field: 'description',
        value: newValue.description,
      });
      apiRef.current.setEditCellValue({
        id,
        field: 'hscode',
        value: newValue.label,
      });
      setFocused(true);
    };

    const onCreateOptionHSCode = async (inputValue: any) => {
      const increaseUniq = (uniq: any) => uniq + 1;
      setCacheUniq(increaseUniq);
      setOpenHSN(true);
      setAddHSCodeModalOpen({
        isAddHSCodeModalOpen: true,
        newCode: inputValue,
        triggerFrom: 1,
        gridId: id,
        apiRef,
      });
      setFocused(true);
    };
    return (
      <>
        <CustomSelectPaginateAdd
          isNumber={true}
          autoFocus={focused}
          key={props?.row?.selectedHSCode.label}
          additional={defaultAdditional}
          value={props?.row?.selectedHSCode}
          loadOptions={(search: string, prevOptions: any, page: any) =>
            loadTariffCustomsOptions(search, prevOptions, page)
          }
          handleChange={handleValueChange}
          menuPortalTarget={document.body}
          styles={{
            menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
          }}
          placeholder={defaultDropDownValue.label}
          isSearchable={true}
          errorValue={true}
          errorMessage={I18n(errors.hscode)}
          readOnly={false}
          debounceTimeout={500}
          isMultiSelect={false}
          required={true}
          cacheUniqs={cacheUniq}
          onCreateOption={(inputValue: any) => onCreateOptionHSCode(inputValue)}
        ></CustomSelectPaginateAdd>
      </>
    );
  }

  function ItemDescriptionComponent(props: GridRenderEditCellParams) {
    const { id, value, field } = props;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const apiRef = useGridApiContext();

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value; // The new value entered by the user
      apiRef.current.setEditCellValue({ id, field, value: newValue });
      // allow only in case of edit
    };
    return (
      <CustomizedInputs
        name="description"
        id="description"
        inputType="text"
        inputProps={{ maxLength: 200 }}
        handleChange={handleValueChange}
        value={props?.row?.description}
        required={true}
      ></CustomizedInputs>
    );
  }

  function QuantityEditComponent(props: GridRenderEditCellParams) {
    const { id, value, field } = props;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const apiRef = useGridApiContext();

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value; // The new value entered by the user
      apiRef.current.setEditCellValue({ id, field, value: newValue });
      // allow only in case of edit
    };
    return (
      <NumericInput
        name="quantity"
        id="quantity"
        inputProps={{ min: 0 }}
        handleChange={handleValueChange}
        value={props?.row?.quantity}
        required={true}
        allowNegative={false}
        decimalScale={2}
      />
    );
  }

  function ItemValueEditComponent(props: GridRenderEditCellParams) {
    const { id, value, field } = props;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const apiRef = useGridApiContext();

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value; // The new value entered by the user
      apiRef.current.setEditCellValue({ id, field, value: newValue });
      // allow only in case of edit
    };
    return (
      <NumericInput
        name="itemValue"
        id="itemValue"
        inputProps={{ min: 0 }}
        handleChange={handleValueChange}
        value={props.row.itemValue}
        required={true}
        allowNegative={false}
        decimalScale={2}
      />
    );
  }

  function NetWeightEditComponent(props: GridRenderEditCellParams) {
    const { id, value, field } = props;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const apiRef = useGridApiContext();

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value; // The new value entered by the user
      apiRef.current.setEditCellValue({ id, field, value: newValue });
      // allow only in case of edit
    };
    return (
      <NumericInput
        name="netWeight"
        id="netWeight"
        inputProps={{ min: 0 }}
        handleChange={handleValueChange}
        value={props?.row?.netWeight}
        required={true}
        allowNegative={false}
        decimalScale={2}
      />
    );
  }

  function GrossWeightEditComponent(props: GridRenderEditCellParams) {
    const { id, value, field } = props;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const apiRef = useGridApiContext();

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value; // The new value entered by the user
      apiRef.current.setEditCellValue({ id, field, value: newValue });
      // allow only in case of edit
    };
    return (
      <NumericInput
        name="grossWeight"
        id="grossWeight"
        inputProps={{ min: 0 }}
        handleChange={handleValueChange}
        value={props?.row?.grossWeight}
        required={true}
        allowNegative={false}
        decimalScale={2}
      />
    );
  }

  function UnitEditComponent(props: GridRenderEditCellParams) {
    const { id, value, field } = props;
    const [focused, setFocused] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const apiRef = useGridApiContext();

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event; // The new value entered by the user
      apiRef.current.setEditCellValue({
        id,
        field,
        value: newValue,
      });
      setFocused(true);
      // allow only in case of edit
    };

    const onCreateOptionUnit = async (inputValue: any) => {
      let name = {};
      name['name'] = inputValue;
      const newOption: any = await PostUnitDropDown(
        name,
        i18nMessages.saveSuccessfully,
        i18nMessages.CommonErrOccuredMsg
      );
      const increaseUniq = (uniq: any) => uniq + 1;
      setCacheUniqUnit(increaseUniq);
      apiRef.current.setEditCellValue({
        id,
        field,
        value: newOption,
      });
      getUnitMaster('', 1);
      setFocused(true);
    };

    return (
      <>
        <CustomSelectPaginateAdd
          key={props?.row?.selectedUnit?.value}
          additional={defaultAdditional}
          value={props?.row?.selectedUnit}
          loadOptions={loadUnitOptions}
          handleChange={handleValueChange}
          menuPortalTarget={document.body}
          styles={{
            menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
          }}
          placeholder={defaultDropDownValue.label}
          isSearchable={true}
          errorValue={true}
          errorMessage={I18n(errors.unit)}
          readOnly={false}
          debounceTimeout={500}
          isMultiSelect={false}
          cacheUniqs={cacheUniqUnit}
          required={true}
          onCreateOption={(inputValue: any) => onCreateOptionUnit(inputValue)}
          autoFocus={focused}
        ></CustomSelectPaginateAdd>
      </>
    );
  }

  function CurrencyEditComponent(props: GridRenderEditCellParams) {
    const { id, value, field } = props;
    const [focused, setFocused] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const apiRef = useGridApiContext();

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event; // The new value entered by the user
      apiRef.current.setEditCellValue({ id, field, value: newValue });
      setFocused(true);
      // allow only in case of edit
    };

    const onCreateOptionCurrency = async (inputValue: any) => {
      let name = {};
      name['name'] = inputValue;
      const newOption: any = await PostCurrencyDropDown(
        name,
        i18nMessages.saveSuccessfully,
        i18nMessages.CommonErrOccuredMsg
      );
      const increaseUniq = (uniq: any) => uniq + 1;
      setCacheUniqCurrency(increaseUniq);
      apiRef.current.setEditCellValue({
        id,
        field,
        value: newOption,
      });
      getCurrencyMaster('', 1);
      setFocused(true);
    };

    return (
      <CustomSelectPaginateAdd
        key={props?.row?.selectedCurrency.value}
        additional={defaultAdditional}
        value={props?.row?.selectedCurrency}
        loadOptions={loadCurrencyOptions}
        handleChange={handleValueChange}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
        }}
        placeholder={defaultDropDownValue.label}
        isSearchable={true}
        errorValue={true}
        errorMessage={I18n(errors.unit)}
        readOnly={false}
        debounceTimeout={500}
        isMultiSelect={false}
        cacheUniqs={cacheUniqCurrency}
        required={true}
        onCreateOption={(inputValue: any) => onCreateOptionCurrency(inputValue)}
        autoFocus={focused}
      ></CustomSelectPaginateAdd>
    );
  }

  function CountryEditComponent(props: GridRenderEditCellParams) {
    const { id, value, field } = props;
    const [focused, setFocused] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const apiRef = useGridApiContext();

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event; // The new value entered by the user
      apiRef.current.setEditCellValue({ id, field, value: newValue });
      setFocused(true);
      // allow only in case of edit
    };

    const onCreateOptionCountry = async (inputValue: any) => {
      let name = {};
      name['name'] = inputValue;
      const newOption: any = await PostCountryDropDown(
        name,
        i18nMessages.saveSuccessfully,
        i18nMessages.CommonErrOccuredMsg
      );
      const increaseUniq = (uniq: any) => uniq + 1;
      setCacheUniqCountry(increaseUniq);
      apiRef.current.setEditCellValue({
        id,
        field,
        value: newOption,
      });
      getCountries('', 1);
      setFocused(true);
    };

    return (
      <CustomSelectPaginateAdd
        key={props?.row?.selectedCountry.value}
        additional={defaultAdditional}
        value={props?.row?.selectedCountry}
        loadOptions={loadCountryOptions}
        handleChange={handleValueChange}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
        }}
        placeholder={defaultDropDownValue.label}
        isSearchable={true}
        errorValue={true}
        readOnly={false}
        debounceTimeout={500}
        isMultiSelect={false}
        cacheUniqs={cacheUniqCountry}
        onCreateOption={(inputValue: any) => onCreateOptionCountry(inputValue)}
        autoFocus={focused}
      ></CustomSelectPaginateAdd>
    );
  }

  function EditToolbar(props: EditToolbarProps) {
    var uniqueRowId = gridData.rows.length ? gridData.rows.length + 1 : 0;

    const handleClick = (event: any) => {
      var existingRow = gridData.rows.find((x: any) => x.id === uniqueRowId);
      if (existingRow === undefined) {
        const id = uniqueRowId;
        var temp: any = [];
        for (let i = 0; i < gridData.rows.length; i++) {
          temp.push(gridData.rows[i]);
        }
        temp.push({
          id: uniqueRowId,
          hscode: '',
          description: '',
          quantity: 0,
          unitId: 0,
          unit: '',
          currencyId: 0,
          itemValue: 0,
          netWeight: 0,
          grossWeight: 0,
          currency: '',
          country: '',
          selectedUnit: defaultDropDownValue,
          selectedCountry: defaultDropDownValue,
          selectedCurrency: defaultDropDownValue,
          selectedHSCode: defaultDropDownValue,
          originCountryId: 0,
          isNew: true,
          isManual: true,
        });
        updateGridData('rows', temp);
        updateGridData('totalRows', temp.length);
        setRowModesModel((oldModel) => ({
          ...oldModel,
          [id]: {
            mode: GridRowModes.Edit,
            fieldToFocus: 'selectedHSCode',
          },
        }));

        uniqueRowId += 1;
      }
    };

    return (
      <GridToolbarContainer>
        {canAdd ? (
          <Button
            color="primary"
            id="addRecord"
            startIcon={<AddIcon />}
            onClick={(e) => handleClick(e)}
          >
            {I18n('Common.Add')}
          </Button>
        ) : (
          <></>
        )}
      </GridToolbarContainer>
    );
  }

  const i18nMessages = {
    productRequired: I18n('ShippingOrders.ShippingPackage.ProductRequired'),
    productQuantityNotAvailable: I18n(
      'ShippingOrders.ShippingPackage.ProductQuantityNotAvailable'
    ),
    saveSuccessfully: I18n('Common.SavedSuccessfully'),
    CommonErrOccuredMsg: I18n('Common.ErrorOccurred'),
    fieldRequired: I18n('ContactPerson.FieldRequired'),
    UpdateRequired: I18n('Common.UpdateRequiredField'),
    HSCODE: I18n('ShippingOrders.ShippingPackage.HSCODE'),
    Description: I18n('ShippingOrders.ShippingPackage.Description'),
    Quantity: I18n('ShippingOrders.ShippingPackage.Quantity'),
    Unit: I18n('ShippingOrders.ShippingPackage.Unit'),
    ItemVaue: I18n('ShippingOrders.ShippingPackage.ItemVaue'),
    ValueCurrency: I18n('ShippingOrders.ShippingPackage.ValueCurrency'),
    NetWeight: I18n('ShippingOrders.ShippingPackage.NetWeight'),
    GrossWeight: I18n('ShippingOrders.ShippingPackage.GrossWeight'),
    CountryOfOrigin: I18n('ShippingOrders.ShippingPackage.CountryOfOrigin'),
    Actions: I18n('Common.Actions'),
    GeneralCargo: I18n('ShippingOrders.ShippingPackage.GeneralCargo'),
    DangerousGoods: I18n('ShippingOrders.ShippingPackage.DangerousGoods'),
    None: I18n('ShippingOrders.ShippingPackage.None'),
    YourOwnPackage: I18n('ShippingOrders.ShippingPackage.YourOwnPackage'),
    PackagingRequired: I18n('ShippingOrders.ShippingPackage.PackagingRequired'),
    Instruction: I18n('ShippingOrders.ShippingPackage.Instruction'),
    OuterBox: I18n('ShippingOrders.ShippingPackage.OuterBox'),
    Box: I18n('ShippingOrders.ShippingPackage.Box'),
    Weight: I18n('ShippingOrders.ShippingPackage.Weight'),
    Height: I18n('ShippingOrders.ShippingPackage.Height'),
    Width: I18n('ShippingOrders.ShippingPackage.Width'),
    Depth: I18n('ShippingOrders.ShippingPackage.Depth'),
    InnerBox: I18n('ShippingOrders.ShippingPackage.InnerBox'),
    CommodityCode: I18n('ShippingOrders.ShippingPackage.CommodityCode'),
  };

  const columns: GridColumns<Row> = [
    {
      field: 'originCountryId',
      hide: true,
      flex: 1,
      editable: false,
      renderCell: (params: any) => {
        return params.formattedValue.label;
      },
    },
    {
      field: 'currencyId',
      hide: true,
      flex: 1,
      editable: false,
      renderCell: (params: any) => {
        return params.formattedValue.label;
      },
    },
    {
      field: 'unitId',
      hide: true,
      flex: 1,
      editable: false,
      renderCell: (params: any) => {
        return params.formattedValue.label;
      },
    },
    {
      field: 'selectedHSCode',
      headerName: i18nMessages.HSCODE,
      width: 150,
      sortable: false,
      editable: true,
      renderEditCell: (params) => {
        return <HSCodeEditComponent {...params} />;
      },
      renderCell: (params: any) => {
        return params.formattedValue.label;
      },
    },
    {
      field: 'description',
      headerName: i18nMessages.Description,
      width: 150,
      sortable: false,
      editable: true,
      renderEditCell: (params) => {
        return <ItemDescriptionComponent {...params} />;
      },
    },
    {
      field: 'quantity',
      headerName: i18nMessages.Quantity,
      width: 80,
      sortable: false,
      editable: true,
      renderEditCell: (params) => {
        return <QuantityEditComponent {...params} />;
      },
    },
    {
      field: 'selectedCountry',
      headerName: i18nMessages.CountryOfOrigin,
      flex: 1,
      sortable: false,
      editable: true,
      renderEditCell: (params) => {
        return <CountryEditComponent {...params} />;
      },
      renderCell: (params: any) => {
        return params.formattedValue.label;
      },
    },
    {
      field: 'selectedUnit',
      headerName: i18nMessages.Unit,
      flex: 1,
      sortable: false,
      editable: true,
      renderEditCell: (params) => {
        return <UnitEditComponent {...params} />;
      },
      renderCell: (params: any) => {
        return params.formattedValue.label;
      },
    },
    {
      field: 'netWeight',
      headerName: i18nMessages.NetWeight,
      flex: 1,
      sortable: false,
      editable: true,
      renderEditCell: (params) => {
        return <NetWeightEditComponent {...params} />;
      },
    },
    {
      field: 'grossWeight',
      headerName: i18nMessages.GrossWeight,
      flex: 1,
      sortable: false,
      editable: true,
      renderEditCell: (params) => {
        return <GrossWeightEditComponent {...params} />;
      },
    },
    {
      field: 'itemValue',
      headerName: i18nMessages.ItemVaue,
      flex: 1,
      sortable: false,
      editable: true,
      renderEditCell: (params) => {
        return <ItemValueEditComponent {...params} />;
      },
    },
    {
      field: 'selectedCurrency',
      headerName: i18nMessages.ValueCurrency,
      flex: 1,
      sortable: false,
      editable: true,
      renderEditCell: (params) => {
        return <CurrencyEditComponent {...params} />;
      },
      renderCell: (params: any) => {
        return params.formattedValue.label;
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: i18nMessages.Actions,
      width: 100,
      getActions: (params: GridRowParams<any>) => {
        const actionButtons = [];
        const isInEditMode =
          rowModesModel[params.id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          actionButtons.push(
            <GridActionsCellItem
              onPointerEnterCapture={false}
              onPointerLeaveCapture={false}
              icon={<SaveIcon />}
              placeholder={''}
              label="Save"
              onClick={handleSaveClick(params.id)}
            />
          );
          actionButtons.push(
            <GridActionsCellItem
              onPointerEnterCapture={false}
              onPointerLeaveCapture={false}
              icon={<CancelIcon />}
              placeholder={''}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(params.id)}
              color="inherit"
            />
          );
        } else {
          if (canDelete) {
            actionButtons.push(
              <GridActionsCellItem
                onPointerEnterCapture={false}
                onPointerLeaveCapture={false}
                icon={<DeleteIcon />}
                placeholder={''}
                label="Delete"
                onClick={() => deleteCommodity(params)}
              />
            );
          }
          if (canEdit) {
            actionButtons.push(
              <GridActionsCellItem
                onPointerEnterCapture={false}
                onPointerLeaveCapture={false}
                icon={<EditIcon />}
                placeholder={''}
                label="Edit"
                className="textPrimary"
                onClick={handleEditClick(params.id)}
                color="inherit"
              />
            );
          }
        }
        return actionButtons;
      },
    },
  ];

  const submit = async () => {
    if (validate()) {
      values.dryIceWeight = temperatureControlValues.showDryIce
        ? Number(values.dryIceWeight)
        : 0;
      values.productCatalogueId = selectedProduct.value;
      values.productName =
        selectedProduct.value === '0' ? 'Own Package' : selectedProduct.label;

      values.shippingPackageDangerousGoods = dangerousGoodsValueMaster.flatMap(
        (item) =>
          item.dangerousGoodsList
            .filter((good) => {
              // Add your conditions to filter individual items in the list
              return (
                good.packagesNumber !== 0 ||
                good.unNumber !== '' ||
                good.primaryClass !== '' ||
                good.secondaryClass !== ''
              );
            })
            .map((good, i) => ({
              ...good,
              sortOrder: i + 1, // Adding 1 to start sortOrder from 1
            }))
      );

      let temperatureValues: any = [];
      temperatureValues.push({
        dataLoggerNumber: temperatureControlValues.dataLoggerNumber,
        dryIceWeight: 0,
        isDataLogger: temperatureControlValues.isDataLogger,
        shippingPackageId: values.id,
        temperatureControlledMasterId:
          temperatureControlValues.temperatureMasterId.value,
      });
      values.shippingPackageTemperatureControl =
        values.isTemperatureControlCheck ? temperatureValues : [];

      let shippingCommodity: any = [];
      gridData.rows.forEach((element: any) => {
        shippingCommodity.push({
          hscode: element.hscode,
          description: element.description,
          quantity: Number(element.quantity),
          unitId: Number(element.unitId),
          currencyId: Number(element.currencyId),
          itemValue: Number(element.itemValue),
          netWeight: Number(element.netWeight),
          grossWeight: Number(element.grossWeight),
          originCountryId: Number(element.originCountryId),
        });
      });

      values.shippingCommodity = shippingCommodity;
      values.packagingRequired = packagingRequired;
      let apiUrl = new URL(environment.api.baseUrl + 'ShippingPackage');
      try {
        let result: any;
        if (values.id === 0) {
          result = await http.post(apiUrl.toString(), values);
        } else {
          apiUrl = new URL(
            environment.api.baseUrl + 'ShippingPackage/' + values.id
          );
          result = await http.put(apiUrl.toString(), values);
        }

        if (result) {
          toast.success(i18nMessages.saveSuccessfully);
          resetForm();
          props.OnSave();
        } else {
          toast.error(i18nMessages.CommonErrOccuredMsg);
        }
      } catch (error: any) {
        if (error.response) {
          if (error.response.data) {
            for (const item of error.response.data.errors) {
              if (
                item.errorMessage ===
                'An error occured: ProductQuantityNotAvailable'
              ) {
                toast.error(i18nMessages.productQuantityNotAvailable);
              } else {
                toast.error(i18nMessages.CommonErrOccuredMsg);
              }
            }
          }
        }
      }
    }
  };

  const onValueChange = (event: any) => {
    let { name, value } = event.target;
    setValues({
      ...values,
      [name.toString()]: value,
    });
  };

  const onDangerousGoodValueChange = (
    sortOrder: number,
    vindex: number,
    event: any,
    masterModel: DangerousGoodsMasterValueModel
  ) => {
    const { name, value, checked } = event.target;
    // Update the dangerousGoodsList for the specific masterModel
    setDangerousGoodsValueMaster((prevDangerousGoodsValueMaster) => {
      return prevDangerousGoodsValueMaster.map(
        (item: DangerousGoodsMasterValueModel) => {
          if (item.id === masterModel.id) {
            // Update the dangerousGoodsList for the matching masterModel
            return {
              ...item,
              dangerousGoodsList: item.dangerousGoodsList.map(
                (listItem, index) => {
                  let val = name === 'packagesNumber' ? Number(value) : value;
                  if (
                    (listItem.sortOrder > 0 &&
                      listItem.sortOrder === sortOrder) ||
                    (listItem.sortOrder === 0 && index === vindex)
                  ) {
                    // Update the specific item in dangerousGoodsList
                    if (name !== 'cao') {
                      // Update the specific item in dangerousGoodsList
                      return {
                        ...listItem,
                        [name]: val,
                      };
                    } else {
                      return {
                        ...listItem,
                        [name]: checked,
                      };
                    }
                  }
                  return listItem;
                }
              ),
            };
          }
          return item;
        }
      );
    });
  };

  const onTemperatureValueChange = (index: number, event: any) => {
    const { name, value } = event.target;
    var temp = [];
    for (let i = 0; i < temperatureValues.length; i++) {
      if (i === index) {
        if (name === 'dataLoggerNumber') {
          temperatureValues[index][name.toString()] = value.toString();
        } else {
          temperatureValues[index][name.toString()] = value;
        }
      }
      temp.push(temperatureValues[i]);
    }

    setTemperatureValues(temp);
  };

  const deleteCommodity = (params: any) => {
    if (gridData.rows && gridData.rows.length > 0) {
      let temp: any = [];

      for (let i = 0; i < gridData.rows.length; i++) {
        const index = gridData.rows.findIndex((x: any) => x.id === params.id);
        if (i !== index) {
          temp.push(gridData.rows[i]);
        }
      }

      updateGridData('totalRows', temp ? temp.length : 0);
      updateGridData('rows', temp ? temp : []);
    }
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow: any = gridData.rows.find((row: any) => row.id === id);
    if (editedRow!.isNew) {
      updateGridData(
        'rows',
        gridData.rows.filter((row: any) => row.id !== id)
      );
    }
  };

  const handleProcessRowUpdate = async (newRow: GridRowModel) => {
    let updatedRow: any = {};
    if (newRow.id === undefined) {
      updatedRow = { ...newRow, isNew: false, id: 0 };
    } else {
      updatedRow = { ...newRow, isNew: false };
    }
    let validateMessage = validateCommodity(updatedRow);
    if (validateMessage === '') {
      updatedRow.hscode = updatedRow.selectedHSCode?.label
        ? updatedRow.selectedHSCode?.label
        : updatedRow.hscode;

      updatedRow.currency = updatedRow.selectedCurrency?.label
        ? updatedRow.selectedCurrency?.label
        : updatedRow.currency;
      updatedRow.currencyId = updatedRow.selectedCurrency?.value
        ? updatedRow.selectedCurrency?.value
        : updatedRow.currencyId;

      updatedRow.unit = updatedRow.selectedUnit?.label
        ? updatedRow.selectedUnit?.label
        : updatedRow.unit;
      updatedRow.unitId = updatedRow.selectedUnit?.value
        ? updatedRow.selectedUnit?.value
        : updatedRow.unitId;

      updatedRow.country = updatedRow.selectedCountry?.label
        ? updatedRow.selectedCountry?.label
        : updatedRow.country;
      updatedRow.originCountryId = updatedRow.selectedCountry?.value
        ? updatedRow.selectedCountry?.value
        : updatedRow.originCountryId;

      if (newRow.id === undefined) {
        var temp: any = [];
        for (var i = 0; i < gridData.rows.length; i++) {
          temp.push(gridData.rows[i]);
        }

        temp.push(updatedRow);

        updateGridData('rows', temp);
        updateGridData(
          'searchExpression',
          (Math.random() + 1).toString(36).substring(7)
        );
        return updatedRow;
      } else {
        updateGridData(
          'rows',
          gridData.rows.map((row: any) =>
            row.id === newRow.id ? updatedRow : row
          )
        );
        updateGridData(
          'searchExpression',
          (Math.random() + 1).toString(36).substring(7)
        );
        return updatedRow;
      }
    } else {
      // Retain values
      updatedRow.hscode = updatedRow.selectedHSCode?.label
        ? updatedRow.selectedHSCode?.label
        : updatedRow.hscode;

      updatedRow.currency = updatedRow.selectedCurrency?.label
        ? updatedRow.selectedCurrency?.label
        : updatedRow.currency;
      updatedRow.currencyId = updatedRow.selectedCurrency?.value
        ? updatedRow.selectedCurrency?.value
        : updatedRow.currencyId;

      updatedRow.unit = updatedRow.selectedUnit?.label
        ? updatedRow.selectedUnit?.label
        : updatedRow.unit;
      updatedRow.unitId = updatedRow.selectedUnit?.value
        ? updatedRow.selectedUnit?.value
        : updatedRow.unitId;

      updatedRow.country = updatedRow.selectedCountry?.label
        ? updatedRow.selectedCountry?.label
        : updatedRow.country;
      updatedRow.originCountryId = updatedRow.selectedCountry?.value
        ? updatedRow.selectedCountry?.value
        : updatedRow.originCountryId;

      if (updatedRow.id === 0) {
        var temp1: any = [];
        for (let i = 0; i < gridData.rows.length; i++) {
          temp1.push(gridData.rows[i]);
        }

        var existingRecord = {
          ...updatedRow,
          isNew: true,
          isManual: true,
        };

        updatedRow.isNew = true;
        updatedRow.isManual = true;

        temp1.push(updatedRow);

        updateGridData('rows', temp1);
        updateGridData('totalRows', temp1.length);

        setTimeout(() => {
          var oldData: any = [];
          for (var i = 0; i < temp1.length; i++) {
            if (temp1[i].id == 0) {
              oldData.push(existingRecord);
            } else {
              oldData.push(temp1[i]);
            }
          }
          updateGridData('rows', oldData);

          setTimeout(() => {
            setRowModesModel({
              ...rowModesModel,
              0: {
                mode: GridRowModes.Edit,
                fieldToFocus: 'selectedHSCode',
              },
            });
          }, 200);
        }, 200);
      } else {
        setTimeout(() => {
          setRowModesModel({
            ...rowModesModel,
            [updatedRow.id]: {
              mode: GridRowModes.Edit,
              fieldToFocus: 'selectedHSCode',
            },
          });
        }, 500);
      }
      toast.error(validateMessage);
      return updatedRow;
    }
  };

  const handleProcessRowUpdateError = useCallback((error: Error) => {
    toast.error(error.message);
  }, []);

  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    if (!params.row.isManual) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (
    params,
    event
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    updateGridData(
      'rows',
      gridData.rows.filter(function (row: any) {
        return row.id !== 0;
      })
    );
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    if (sortModel && sortModel.length) {
      updateGridData('sortBy', sortModel[0].field);
      updateGridData(
        'sortOrder',
        sortModel[0].sort === 'asc'
          ? OrderByDirection.Ascending
          : OrderByDirection.Descending
      );
    }
  }, []);

  const handleChangePage = (pageNo: number) => {
    updateGridData('page', pageNo);
  };

  const handleChangePageSize = (pageSize: number) => {
    updateGridData('pageSize', pageSize);
  };

  const getDangerousGoodsMaster = async () => {
    updateGridData('isLoading', true);

    const apiUrl = new URL(
      environment.api.baseUrl + 'ShippingPackage/DangerousGoodsMaster'
    );
    try {
      apiUrl.searchParams.set('$top', '100');
      const result = await http.get(apiUrl.toString());
      if (result) {
        setDangerousGoodsMaster(result.data.values);
        updateGridData('isLoading', false);

        if (!data) {
          var tempMasterList: DangerousGoodsMasterValueModel[] = [];

          result.data.values.forEach((element: any) => {
            tempMasterList.push({
              id: element.id,
              name: element.name,
              isUNNumber: element.isUNNumber,
              isPrimaryClass: element.isPrimaryClass,
              isSecondaryClass: element.isSecondaryClass,
              isCao: element.isCao,
              allowMultiple: element.allowMultiple,
              dangerousGoodsList: [
                {
                  ...defaultDangerousGoodsValue,
                  dangerousGoodsMasterId: element.id,
                },
              ],
            });
          });

          setDefaultDangerousGoodsValueMaster(tempMasterList);
          setDangerousGoodsValueMaster(tempMasterList);
        }
      }
    } catch (error) {}
  };

  const getProductsMaster = async () => {
    updateGridData('isLoading', true);
    let apiUrl = new URL(
      environment.api.baseUrl + 'Dropdown/GetMasterCategories'
    );
    apiUrl.searchParams.set('$filter', `isDefault~=~${true}`);
    apiUrl.searchParams.set('$top', '1');
    try {
      const packagingCategory = await http.get(apiUrl.toString());
      if (packagingCategory && packagingCategory.data.values.length > 0) {
        apiUrl = new URL(environment.api.baseUrl + 'ProductCatalogues');

        let searchExpression = `departmentType~=~${Department.Shipping} or departmentType~=~${Department.Both}`;
        searchExpression += ` and productCatalogueCategoryId~=~${packagingCategory.data.values[0].id} and Status~=~${EntityStatus.Active}`;

        apiUrl.searchParams.set('$filter', searchExpression);
        apiUrl.searchParams.set('$top', '100');
        const result = await http.get(apiUrl.toString());
        if (result) {
          setMasterProducts(result.data.values);
          var temp: BaseModel[] = [];

          result.data.values.forEach((element: any) => {
            temp.push({
              label: element.name,
              value: element.id,
            });
          });
          setProducts(temp);
          updateGridData('isLoading', false);
        }
      }
    } catch (error) {}
  };

  const onDangerousGoodsCheck = (event: any) => {
    setDangerousGoodsChecked(event.target.checked);
    setValues({ ...values, isDangerousGoodsCheck: event.target.checked });
  };

  const onTemperatureControlledCheck = (event: any) => {
    setTemperatureControlledChecked(event.target.checked);
    setValues({
      ...values,
      isTemperatureControlCheck: event.target.checked,
      dryIceWeight: event.target.checked ? values.dryIceWeight : 0,
    });

    if (!event.target.checked) {
      setTemperatureControlValues({
        dataLoggerNumber: '',
        isDataLogger: false,
        showDryIce: false,
        temperatureMasterId: defaultDropDownValue,
      });
    }
  };

  const addCommodity = useCallback(() => {
    setAddModal(true);
  }, []);

  const OnSave = async (values: any) => {
    var temp: any = [];
    if (gridData.rows && gridData.rows.length > 0) {
      gridData.rows.forEach((element: any) => {
        temp.push(element);
      });

      temp.push({
        id: temp.length + 1,
        hscode: values.hscode.label,
        description: values.description,
        quantity: values.quantity,
        unitId: values.unitId,
        currencyId: values.currencyId,
        itemValue: values.itemValue,
        netWeight: values.netWeight,
        grossWeight: values.grossWeight,
        originCountryId: values.originCountryId,
        country: values.countryOfOrigin,
        currency: values.currency,
        packagingRequired,
        instruction: values.instruction,
      });
    } else {
      temp.push({
        id: 1,
        hscode: values.hscode.label,
        description: values.description,
        quantity: values.quantity,
        unitId: values.unitId,
        currencyId: values.currencyId,
        itemValue: values.itemValue,
        netWeight: values.netWeight,
        grossWeight: values.grossWeight,
        originCountryId: values.originCountryId,
        country: values.countryOfOrigin,
        currency: values.currency,
        packagingRequired,
        instruction: values.instruction,
      });
    }
    setGridData({ ...gridData, rows: temp, totalRows: temp.length });
    handleCloseForAdd();
  };

  const handleCloseForAdd = () => {
    setAddModal(false);
  };

  const onGeneralChecked = (event: any) => {
    setValues({
      ...values,
      generalCheck: event.target.checked,
    });
  };

  const onOwnPackage = (event: any) => {
    setOwnPackageChecked(event.target.checked);
    if (event.target.checked) {
      setSelectedProduct(defaultDropDownValue);
      setValues({
        ...values,
        weight: '',
        dimension1: '',
        dimension2: '',
        dimension3: '',
      });
    }
  };

  const onPackagingRequiredCheck = (event: any) => {
    setPackagingRequired(event.target.checked);
  };

  const setProduct = (value: any) => {
    setSelectedProduct(value);
    var product: any = masterProducts.find((x: any) => x.id === value.value);
    if (product) {
      setOwnPackageChecked(false);
      setValues({
        ...values,
        dimension1: product.dimension1.toString(),
        dimension2: product.dimension2.toString(),
        dimension3: product.dimension3.toString(),
      });
    }
  };

  const resetForm = () => {
    setValues({
      id: 0,
      productCatalogueId: '',
      shippingOrderId: props.shippingOrderId,
      productName: '',
      weight: '',
      dimension1: '',
      dimension2: '',
      dimension3: '',
      generalCheck: false,
      shippingPackageDangerousGoods: [],
      shippingPackageTemperatureControl: [],
      shippingCommodity: [],
      isDangerousGoodsCheck: false,
      isTemperatureControlCheck: false,
      dryIceWeight: 0,
      instruction: '',
      packagingRequired: false,
      IsNoneCheck: false,
    });
    setTemperatureControlValues({
      isDataLogger: false,
      showDryIce: false,
      dataLoggerNumber: '',
      temperatureMasterId: defaultDropDownValue,
    });
    setRadioCheck(0);

    setGridData({ ...gridData, rows: [], totalRows: 0 });
    setTemperatureControlledChecked(false);
    setDangerousGoodsChecked(false);
    setSelectedProduct(defaultDropDownValue);
    setOwnPackageChecked(false);
    setPackagingRequired(false);
    setDangerousGoodsValueMaster(defaultDangerousGoodsValueMaster);

    setRowModesModel({});
    document.removeEventListener('keydown', keydown);
  };

  const handleCloseForm = async () => {
    await removeInnerBox();
    resetForm();
    props.handleClose();
  };

  const removeInnerBox = async () => {
    if (values.id == 0) {
      const apiUrl = new URL(
        environment.api.baseUrl +
          'ShippingPackage/DeleteShippingPackageInnerBox/' +
          props.shippingOrderId
      );
      try {
        const result = await http.delete(apiUrl.toString());
      } catch {}
    }
  };

  const addNewDangerousGoods = (masterId: number) => {
    // Find the master in dangerousGoodsValueMaster
    const masterIndex = dangerousGoodsValueMaster.findIndex(
      (master) => master.id === masterId
    );

    // Step 1: Extract SortOrder values
    const sortOrderValues = dangerousGoodsValueMaster[
      masterIndex
    ].dangerousGoodsList.map((item) => item.sortOrder);
    // Step 2: Find the maximum value
    const maxSortOrder = Math.max(...sortOrderValues);

    // Step 3: Calculate the next sort order
    const nextSortOrder = maxSortOrder + 1;

    // Create a new item for dangerousGoodsList
    const newItem = {
      ...defaultDangerousGoodsValue,
      dangerousGoodsMasterId: masterId,
      sortOrder: nextSortOrder,
    };

    if (masterIndex !== -1) {
      // Add the new item to dangerousGoodsList
      setDangerousGoodsValueMaster((prev) => {
        const updatedMasterList = [...prev];
        updatedMasterList[masterIndex].dangerousGoodsList.push(newItem);
        return updatedMasterList;
      });
    }
  };

  const deleteDangerousGoods = (masterId: number, sortOrder: number) => {
    // Find the master in dangerousGoodsValueMaster
    const masterIndex = dangerousGoodsValueMaster.findIndex(
      (master) => master.id === masterId
    );

    if (masterIndex !== -1) {
      // Remove the item from dangerousGoodsList
      setDangerousGoodsValueMaster((prev) => {
        const updatedMasterList = [...prev];
        const itemIndex = updatedMasterList[
          masterIndex
        ].dangerousGoodsList.findIndex((item) => item.sortOrder === sortOrder);
        if (itemIndex !== -1) {
          updatedMasterList[masterIndex].dangerousGoodsList.splice(
            itemIndex,
            1
          );
        }
        return updatedMasterList;
      });
    }
  };

  const I18nText = {
    ShippingPackageCommodity: I18n('ShippingOrders.ShippingPackage.Commodity'),
    DataLoggerNumber: I18n('ShippingOrders.ShippingPackage.DataLoggerNumber'),
    DataLogger: I18n('ShippingOrders.ShippingPackage.DataLogger'),
    KgOfDryIce: I18n('ShippingOrders.ShippingPackage.KgOfDryIce'),
    TemperatureControlled: I18n(
      'ShippingOrders.ShippingPackage.TemperatureControlled'
    ),
    CAO: I18n('ShippingOrders.ShippingPackage.CAO'),
    SecondaryClass: I18n('ShippingOrders.ShippingPackage.SecondaryClass'),
    PrimaryClass: I18n('ShippingOrders.ShippingPackage.PrimaryClass'),
    UNNumber: I18n('ShippingOrders.ShippingPackage.UNNumber'),
    NumberOfPackages: I18n('ShippingOrders.ShippingPackage.NumberOfPackages'),
    Temperature: I18n('ShippingOrders.ShippingPackage.Temperature'),
  };

  const keydown = (event: any) => {
    if (event.altKey && event.keyCode == 65) {
      var addButton = document.getElementById('addRecord');
      addButton?.click();
    }
  };

  useEffect(() => {
    const element = document.getElementById('keydown');
    element?.addEventListener('keydown', keydown, true);
    getDangerousGoodsMaster();
    getTemperatureControllerMaster();
    getProductsMaster();
    if (data) {
      setValues(data);
      if (data.productCatalogueId) {
        setSelectedProduct({
          value: data.productCatalogueId,
          label: data.productName,
        });
      } else {
        setOwnPackageChecked(true);
      }
      data.shippingCommodity.map((item: any) => {
        item['selectedUnit'] = { value: item.unitId, label: item.unit };
        item['selectedCurrency'] = {
          value: item.currencyId,
          label: item.currency,
        };
        item['selectedCountry'] = {
          value: item.originCountryId,
          label: item.country,
        };
        item['selectedHSCode'] = {
          value: '0',
          label: item.hscode,
        };
        return item;
      });

      updateGridData('totalRows', data.shippingCommodity.length);
      updateGridData('rows', data.shippingCommodity);
      setPackagingRequired(data.packagingRequired);
      setDangerousValues(data.shippingPackageDangerousGoods);

      let updatedMasterList = _.cloneDeep(defaultDangerousGoodsValueMaster);

      // Set Good Values
      if (
        data.shippingPackageDangerousGoods &&
        data.shippingPackageDangerousGoods.length > 0
      ) {
        data.shippingPackageDangerousGoods.forEach(
          (element: DangerousGoodsValueModel) => {
            // Find the corresponding master in tempMasterList
            let masterIndex = updatedMasterList.findIndex(
              (master) => master.id === element.dangerousGoodsMasterId
            );

            if (masterIndex !== -1) {
              // If element.id is 0 or null, filter it from the list
              updatedMasterList[masterIndex].dangerousGoodsList =
                updatedMasterList[masterIndex].dangerousGoodsList.filter(
                  (dg) => dg.id !== 0
                );

              // If master found, update or push to dangerousGoodsList
              const existingDangerousGoodsIndex = updatedMasterList[
                masterIndex
              ].dangerousGoodsList.findIndex((dg) => dg.id === element.id);

              if (existingDangerousGoodsIndex !== -1) {
                // If dangerousGoodsList element found, update it
                updatedMasterList[masterIndex].dangerousGoodsList[
                  existingDangerousGoodsIndex
                ] = {
                  ...updatedMasterList[masterIndex].dangerousGoodsList[
                    existingDangerousGoodsIndex
                  ],
                  shippingPackageId: element.shippingPackageId,
                  dangerousGoodsMasterId: element.dangerousGoodsMasterId,
                  packagesNumber: element.packagesNumber,
                  unNumber: element.unNumber,
                  primaryClass: element.primaryClass,
                  secondaryClass: element.secondaryClass,
                  cao: element.cao,
                };
              } else {
                // If not found, push a new element to dangerousGoodsList
                updatedMasterList[masterIndex].dangerousGoodsList.push({
                  id: element.id,
                  shippingPackageId: element.shippingPackageId,
                  dangerousGoodsMasterId: element.dangerousGoodsMasterId,
                  packagesNumber: element.packagesNumber,
                  unNumber: element.unNumber,
                  primaryClass: element.primaryClass,
                  secondaryClass: element.secondaryClass,
                  cao: element.cao,
                  sortOrder: element.sortOrder,
                });
              }
            }
          }
        );
      }

      setDangerousGoodsValueMaster(updatedMasterList);

      setTemperatureValues(data.shippingPackageTemperatureControl);
      setDangerousGoodsChecked(data.isDangerousGoodsCheck);
      setTemperatureControlledChecked(data.isTemperatureControlCheck);
      setRadioCheck(
        data.isDangerousGoodsCheck
          ? PackageCheckList.DangerousGoods
          : data.generalCheck
          ? PackageCheckList.GeneralCargo
          : data.isNoneCheck
          ? PackageCheckList.None
          : 0
      );
    }
  }, [data]);

  const myStyle = {
    position: 'relative' as 'relative',
    top: '30px',
  };

  const setModels = (newModel: any) => {
    // to stop set Row Models from  type dropdown Change
    var result = false;
    Object.keys(newModel).forEach(function (key) {
      var value = newModel[key];
      if (
        (value.field === 'selectedHSCode' || value.field === 'selectedUnit') &&
        value.cellToFocusAfter === undefined
      ) {
        result = true;
      }
    });
    if (result) {
      return;
    }

    if (newModel[0] === undefined) {
      setRowModesModel(newModel);
    } else if (
      newModel[0] !== undefined &&
      newModel[0].cellToFocusAfter === 'right'
    ) {
      updateGridData(
        'rows',
        gridData.rows.filter(function (row: any) {
          return row.id !== 0;
        })
      );
      setRowModesModel(newModel);
    }
  };

  const [radioCheck, setRadioCheck] = useState(0);

  let checksList: Array<RadioGroupBaseModel> = [];
  const getCheckList = () => {
    checksList.push({
      value: PackageCheckList.GeneralCargo.toString(),
      label: i18nMessages.GeneralCargo,
    } as RadioGroupBaseModel);
    checksList.push({
      value: PackageCheckList.DangerousGoods.toString(),
      label: i18nMessages.DangerousGoods,
    } as RadioGroupBaseModel);
    checksList.push({
      value: PackageCheckList.None.toString(),
      label: i18nMessages.None,
    } as RadioGroupBaseModel);
  };
  getCheckList();

  const setCheck = (data: any) => {
    setRadioCheck(data);
    if (data == PackageCheckList.GeneralCargo) {
      setValues({
        ...values,
        generalCheck: true,
        isDangerousGoodsCheck: false,
        IsNoneCheck: false,
      });
      setDangerousGoodsChecked(false);
    }
    if (data == PackageCheckList.DangerousGoods) {
      setDangerousGoodsChecked(true);
      setValues({
        ...values,
        isDangerousGoodsCheck: true,
        generalCheck: false,
        IsNoneCheck: false,
      });
    }
    if (data == PackageCheckList.None) {
      setValues({
        ...values,
        generalCheck: false,
        isDangerousGoodsCheck: false,
        IsNoneCheck: true,
      });
    }
  };

  const onTemperatureDataLoggerChange = (newTemperatureOption: any) => {
    var showDryIce = temperatureControlledOptions.filter(
      (x: any) => x.value == newTemperatureOption.value
    );
    if (showDryIce && showDryIce.length > 0) {
      setTemperatureControlValues({
        ...temperatureControlValues,
        temperatureMasterId: newTemperatureOption,
        showDryIce: showDryIce[0].isDryIce,
        isDataLogger: false,
        dataLoggerNumber: '',
      });
    } else {
      setTemperatureControlValues({
        ...temperatureControlValues,
        temperatureMasterId: newTemperatureOption,
        showDryIce: false,
        isDataLogger: false,
        dataLoggerNumber: '',
      });
      setValues({ ...values, dryIceWeight: 0 });
    }
  };
  const [temperatureControlValues, setTemperatureControlValues] = useState({
    temperatureMasterId: defaultDropDownValue,
    isDataLogger: false,
    dataLoggerNumber: '',
    showDryIce: false,
  });

  const getTemperatureControllerMaster = async () => {
    updateGridData('isLoading', true);

    const apiUrl = new URL(
      environment.api.baseUrl + 'ShippingPackage/TemperatureControllerMaster'
    );
    try {
      apiUrl.searchParams.set('$top', '100');
      const result = await http.get(apiUrl.toString());
      if (result) {
        updateGridData('isLoading', false);
        var dropdownOptions: any[] = [];
        result.data.values.forEach((element: any) => {
          dropdownOptions.push({
            label: element.temperature,
            value: element.id,
            isDryIce: element.isDryIce,
          });
        });
        setTemperatureControlledOptions(dropdownOptions);

        if (data) {
          data.shippingPackageTemperatureControl.map((item: any) => {
            var baseModel = dropdownOptions.filter(
              (x) => x.value == item.temperatureControlledMasterId
            );
            if (baseModel && baseModel.length > 0) {
              if (baseModel[0].isDryIce) {
                setTemperatureControlValues({
                  ...temperatureControlValues,
                  temperatureMasterId: {
                    value: baseModel[0].value,
                    label: baseModel[0].label,
                  },
                  isDataLogger: item.isDataLogger,
                  dataLoggerNumber: item.dataLoggerNumber,
                  showDryIce: true,
                });
              } else {
                setTemperatureControlValues({
                  ...temperatureControlValues,
                  temperatureMasterId: {
                    value: baseModel[0].value,
                    label: baseModel[0].label,
                  },
                  isDataLogger: item.isDataLogger,
                  dataLoggerNumber: item.dataLoggerNumber,
                  showDryIce: false,
                });
              }
            }
          });
        }
      }
    } catch (error) {}
  };
  return (
    <div onKeyDown={keydown} id="keydown">
      <Dialog
        open={isOpen}
        onClose={handleCloseForm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={'lg'}
        fullWidth={true}
      >
        <DialogTitle>
          {title}
          <IconButton
            aria-label="close"
            onClick={handleCloseForm}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className="grid-wrap two-col">
            <Grid item xs={12}>
              <div style={{ display: 'flex' }}>
                <FormControlLabel
                  className="add-box-checkbox"
                  control={
                    <Checkbox
                      onChange={onOwnPackage}
                      inputProps={{ 'aria-label': 'controlled' }}
                      checked={ownPackageChecked}
                      disabled={packagingRequired}
                    />
                  }
                  label={i18nMessages.YourOwnPackage}
                />
                <FormControlLabel
                  className="add-box-checkbox"
                  control={
                    <Checkbox
                      onChange={onPackagingRequiredCheck}
                      inputProps={{ 'aria-label': 'controlled' }}
                      checked={packagingRequired}
                      disabled={ownPackageChecked}
                    />
                  }
                  label={i18nMessages.PackagingRequired}
                />
              </div>
              {packagingRequired ? (
                <CustomizedInputs
                  margin="normal"
                  displayLabel={i18nMessages.Instruction}
                  value={values.instruction}
                  name="instruction"
                  id="instruction"
                  inputType="text"
                  inputProps={{ maxLength: 250 }}
                  handleChange={onValueChange}
                  disabled={false}
                />
              ) : (
                ''
              )}
            </Grid>
            {!ownPackageChecked ? (
              <Grid item xs={12}>
                <Typography variant="h5">{i18nMessages.OuterBox}</Typography>
              </Grid>
            ) : (
              <></>
            )}
            <Grid item xs={3}>
              <CustomizedSelect
                placeholder={defaultDropDownValue.label}
                options={products}
                isSearchable={true}
                displayLabel={i18nMessages.Box}
                handleChange={(newValue: BaseModel) => setProduct(newValue)}
                value={selectedProduct}
                errorValue={I18n(errors.product)}
                errorMessage={I18n(errors.product)}
                readOnly={ownPackageChecked}
                required
              ></CustomizedSelect>
            </Grid>

            <Grid item xs={2}>
              <DecimalInput
                required
                displayLabel={i18nMessages.Weight}
                inputProps={{ maxLength: 10 }}
                name="weight"
                handleChange={onValueChange}
                errorValue={I18n(errors.weight)}
                errorMessage={I18n(errors.weight)}
                value={values.weight}
              ></DecimalInput>
            </Grid>
            <Grid item xs={2}>
              <DecimalInput
                required
                displayLabel={i18nMessages.Height}
                inputProps={{ maxLength: 10 }}
                name="dimension1"
                handleChange={onValueChange}
                errorValue={I18n(errors.dimension1)}
                errorMessage={I18n(errors.dimension1)}
                value={values.dimension1}
                disabled={!ownPackageChecked}
                allowNegativeValue={false}
              ></DecimalInput>
            </Grid>
            <Grid item xs={2}>
              <DecimalInput
                required
                displayLabel={i18nMessages.Width}
                inputProps={{ maxLength: 10 }}
                name="dimension2"
                handleChange={onValueChange}
                errorValue={I18n(errors.dimension2)}
                errorMessage={I18n(errors.dimension2)}
                value={values.dimension2}
                disabled={!ownPackageChecked}
                allowNegativeValue={false}
              ></DecimalInput>
            </Grid>
            <Grid item xs={2}>
              <DecimalInput
                required
                displayLabel={i18nMessages.Depth}
                inputProps={{ maxLength: 10 }}
                name="dimension3"
                handleChange={onValueChange}
                errorValue={I18n(errors.dimension3)}
                errorMessage={I18n(errors.dimension3)}
                value={values.dimension3}
                disabled={!ownPackageChecked}
                allowNegativeValue={false}
              ></DecimalInput>
            </Grid>
          </Grid>
          <br />
          {!ownPackageChecked ? (
            <div>
              <Typography variant="h5" className="inner-heading has-button">
                {i18nMessages.InnerBox}
              </Typography>

              <div className="content-block table-fix">
                <Grid container spacing={2} className="grid-wrap two-col">
                  <Grid item xs={1}></Grid>
                  <Grid item xs={11}>
                    <InnerBox
                      canDelete={canDelete}
                      canEdit={canEdit}
                      canAdd={canAdd}
                      shippingOrderId={props.shippingOrderId}
                      shippingPackageId={values.id}
                      ownPackageChecked={ownPackageChecked}
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
          ) : (
            <></>
          )}
          <br />
          <div>
            <Typography variant="h5" className="inner-heading has-button">
              {i18nMessages.CommodityCode}
            </Typography>
            <div className="content-block table-fix">
              <CustomDataGridInline
                rowCount={gridData.totalRows}
                data={gridData.rows}
                columns={columns}
                onSortModelChange={handleSortModelChange}
                pageNo={gridData.page}
                pageSize={gridData.pageSize}
                rowModesModel={rowModesModel}
                onRowModesModelChange={(newModel: any) => {
                  setModels(newModel);
                }}
                onPageChange={handleChangePage}
                onPageSizeChange={handleChangePageSize}
                onRowEditStart={handleRowEditStart}
                //onRowEditStop={handleRowEditStop}
                rowsPerPageOptions={gridData.rowsPerPageOptions}
                paginationMode={'client'}
                initialState={{
                  pagination: {
                    page: gridData.page,
                    pageSize: gridData.pageSize,
                  } as GridPaginationInitialState,
                }}
                components={{
                  Toolbar: EditToolbar,
                }}
                componentsProps={{
                  toolbar: { setRows, setRowModesModel },
                }}
                processRowUpdate={handleProcessRowUpdate}
                onProcessRowUpdateError={handleProcessRowUpdateError}
              ></CustomDataGridInline>
            </div>
          </div>
          <Grid container spacing={2} className="grid-wrap two-col">
            <Grid item lg={12} mt={'30px'}>
              <label>{I18n('ShippingOrders.ShippingPackage.Checks')}</label>
              <RadioGroupInput
                className="addPackageRadioButton"
                handleChange={(event: BaseSyntheticEvent) => {
                  setCheck(event.target.value);
                }}
                value={radioCheck}
                options={checksList}
              ></RadioGroupInput>
            </Grid>

            {values.isDangerousGoodsCheck ? (
              <>
                <Grid item lg={3}></Grid>
                <Grid item lg={2}>
                  <div>{I18nText.NumberOfPackages}</div>
                </Grid>
                <Grid item lg={2}>
                  <div>{I18nText.UNNumber}</div>
                </Grid>
                <Grid item lg={2}>
                  <div>{I18nText.PrimaryClass}</div>
                </Grid>
                <Grid item lg={2}>
                  <div>{I18nText.SecondaryClass}</div>
                </Grid>
                <Grid item lg={1}>
                  <div>{I18nText.CAO}</div>
                </Grid>
                <Grid item lg={12}>
                  {dangerousGoodsValueMaster &&
                    dangerousGoodsValueMaster?.map(
                      (element: DangerousGoodsMasterValueModel, index: any) => (
                        <Grid
                          container
                          spacing={2}
                          className="grid-wrap two-col"
                          key={`grid${index}`}
                        >
                          {element.dangerousGoodsList &&
                            element.dangerousGoodsList
                              ?.slice() // Create a shallow copy to avoid mutating the original array
                              .sort((a, b) => a.sortOrder - b.sortOrder) // Sort by sortOrder in ascending order
                              .map(
                                (
                                  value: DangerousGoodsValueModel,
                                  vindex: any
                                ) => {
                                  if (!element.allowMultiple && vindex > 0) {
                                    return null;
                                  }
                                  return (
                                    <>
                                      <Grid item lg={2}>
                                        {vindex === 0 ? (
                                          <div className="label-adjust">
                                            {element.name}
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                      </Grid>
                                      <Grid item lg={1}>
                                        <FormControlLabel
                                          sx={{ ml: 0 }}
                                          control={
                                            <>
                                              {element.allowMultiple &&
                                              vindex === 0 ? (
                                                <GridActionsCellItem
                                                  onPointerEnterCapture={false}
                                                  onPointerLeaveCapture={false}
                                                  icon={
                                                    <FontAwesomeIcon
                                                      icon={faPlusCircle}
                                                      size="sm"
                                                    />
                                                  }
                                                  label="Add"
                                                  sx={{ mr: 1 }}
                                                  placeholder={''}
                                                  onClick={() =>
                                                    addNewDangerousGoods(
                                                      element.id
                                                    )
                                                  }
                                                />
                                              ) : element.allowMultiple &&
                                                element.dangerousGoodsList
                                                  .length > 1 ? (
                                                <GridActionsCellItem
                                                  onPointerEnterCapture={false}
                                                  onPointerLeaveCapture={false}
                                                  icon={
                                                    <FontAwesomeIcon
                                                      icon={faMinusCircle}
                                                      size="sm"
                                                    />
                                                  }
                                                  label="Remove"
                                                  sx={{ mr: 1 }}
                                                  placeholder={''}
                                                  onClick={() =>
                                                    deleteDangerousGoods(
                                                      element.id,
                                                      value.sortOrder
                                                    )
                                                  }
                                                />
                                              ) : (
                                                <></>
                                              )}
                                            </>
                                          }
                                          label={''}
                                        />
                                      </Grid>
                                      <Grid item lg={2}>
                                        <NumericInput
                                          name="packagesNumber"
                                          id="packagesNumber"
                                          inputProps={{ min: 0 }}
                                          errorValue={errors.packagesNumber}
                                          errorMessage={errors.packagesNumber}
                                          value={value.packagesNumber}
                                          handleChange={(event: any) =>
                                            onDangerousGoodValueChange(
                                              value.sortOrder,
                                              vindex,
                                              event,
                                              element
                                            )
                                          }
                                          allowNegative={false}
                                        />
                                      </Grid>
                                      <Grid item lg={2}>
                                        {element.isUNNumber ? (
                                          <CustomizedInputs
                                            margin="normal"
                                            name="unNumber"
                                            id="unNumber"
                                            inputType="text"
                                            inputProps={{ maxLength: 10 }}
                                            errorValue={errors.unNumber}
                                            errorMessage={errors.unNumber}
                                            value={value.unNumber}
                                            handleChange={(event: any) =>
                                              onDangerousGoodValueChange(
                                                value.sortOrder,
                                                vindex,
                                                event,
                                                element
                                              )
                                            }
                                          />
                                        ) : (
                                          <></>
                                        )}
                                      </Grid>
                                      <Grid item lg={2}>
                                        {element.isPrimaryClass ? (
                                          <CustomizedInputs
                                            margin="normal"
                                            name="primaryClass"
                                            id="primaryClass"
                                            inputType="text"
                                            inputProps={{ maxLength: 10 }}
                                            errorValue={errors.primaryClass}
                                            errorMessage={errors.primaryClass}
                                            value={value.primaryClass}
                                            handleChange={(event: any) =>
                                              onDangerousGoodValueChange(
                                                value.sortOrder,
                                                vindex,
                                                event,
                                                element
                                              )
                                            }
                                          />
                                        ) : (
                                          <></>
                                        )}
                                      </Grid>
                                      <Grid item lg={2}>
                                        {element.isSecondaryClass ? (
                                          <CustomizedInputs
                                            margin="normal"
                                            name="secondaryClass"
                                            id="secondaryClass"
                                            inputType="text"
                                            inputProps={{ maxLength: 10 }}
                                            errorValue={errors.secondaryClass}
                                            errorMessage={errors.secondaryClass}
                                            value={value.secondaryClass}
                                            handleChange={(event: any) =>
                                              onDangerousGoodValueChange(
                                                value.sortOrder,
                                                vindex,
                                                event,
                                                element
                                              )
                                            }
                                          />
                                        ) : (
                                          <></>
                                        )}
                                      </Grid>
                                      <Grid item lg={1}>
                                        {element.isCao ? (
                                          <FormControlLabel
                                            labelPlacement="end"
                                            control={
                                              <Checkbox
                                                name="cao"
                                                id="cao"
                                                onChange={(event: any) =>
                                                  onDangerousGoodValueChange(
                                                    value.sortOrder,
                                                    vindex,
                                                    event,
                                                    element
                                                  )
                                                }
                                                inputProps={{
                                                  'aria-label': 'controlled',
                                                }}
                                                checked={value.cao}
                                              />
                                            }
                                            label={''}
                                          />
                                        ) : (
                                          <></>
                                        )}
                                      </Grid>
                                    </>
                                  );
                                }
                              )}
                        </Grid>
                      )
                    )}
                </Grid>
              </>
            ) : (
              <></>
            )}
            <Grid item lg={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={onTemperatureControlledCheck}
                    checked={temperatureControllChecked}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label={I18nText.TemperatureControlled}
              />
            </Grid>
            {temperatureControllChecked ? (
              <>
                <Grid item lg={3}>
                  {I18nText.Temperature}
                </Grid>
                <Grid item lg={2}>
                  <div>{I18nText.DataLogger}</div>
                </Grid>
                <Grid item lg={3}>
                  <div>{I18nText.DataLoggerNumber}</div>
                </Grid>
                <Grid item lg={3}>
                  {temperatureControlValues.showDryIce && (
                    <div>{I18nText.KgOfDryIce}</div>
                  )}
                </Grid>
                <Grid item lg={12}>
                  <Grid container spacing={2} className="grid-wrap two-col">
                    <Grid item lg={3}>
                      <CustomizedSelect
                        placeholder={defaultDropDownValue.label}
                        options={temperatureControlledOptions}
                        isSearchable={true}
                        displayLabel={' '}
                        handleChange={(newValue: BaseModel) =>
                          onTemperatureDataLoggerChange(newValue)
                        }
                        value={temperatureControlValues.temperatureMasterId}
                        errorValue={errors.temperatureMasterId}
                        errorMessage={errors.temperatureMasterId}
                        menuPlacement={'top'}
                      ></CustomizedSelect>
                    </Grid>
                    <Grid item lg={2}>
                      <Checkbox
                        style={{
                          display: 'flex',
                          marginTop: '15px',
                          justifyContent: 'left',
                        }}
                        onChange={(event: any) =>
                          setTemperatureControlValues({
                            ...temperatureControlValues,
                            isDataLogger: event.target.checked,
                          })
                        }
                        checked={temperatureControlValues.isDataLogger}
                        inputProps={{ 'aria-label': 'controlled' }}
                        disabled={
                          temperatureControlValues.temperatureMasterId.value ==
                          '0'
                        }
                      />
                    </Grid>
                    <Grid item lg={3}>
                      <CustomizedInputs
                        name="dataLoggerNumber"
                        id="dataLoggerNumber"
                        inputProps={{ min: 0 }}
                        value={temperatureControlValues.dataLoggerNumber}
                        handleChange={(event: any) =>
                          setTemperatureControlValues({
                            ...temperatureControlValues,
                            dataLoggerNumber: event.target.value,
                          })
                        }
                        disabled={
                          temperatureControlValues.temperatureMasterId.value ==
                            '0' || !temperatureControlValues.isDataLogger
                        }
                      ></CustomizedInputs>
                    </Grid>
                    <Grid item lg={3}>
                      {temperatureControlValues.showDryIce && (
                        <NumericInput
                          name="dryIceWeight"
                          id="dryIceWeight"
                          inputProps={{ min: 0 }}
                          errorValue={errors.dryIceWeight}
                          errorMessage={errors.dryIceWeight}
                          handleChange={onValueChange}
                          value={values.dryIceWeight}
                          allowNegative={false}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : (
              <></>
            )}
          </Grid>

          <Commodity
            isOpen={isAddModalOpen}
            OnSave={OnSave}
            handleClose={handleCloseForAdd}
            title={I18nText.ShippingPackageCommodity}
          ></Commodity>
        </DialogContent>
        <DialogActions className="btn-wrap">
          <Button
            onClick={handleCloseForm}
            color="neutral"
            disableElevation
            variant="contained"
          >
            {' '}
            {I18n('Common.Cancel')}
          </Button>
          <Button
            onClick={submit}
            autoFocus
            color="primary"
            disableElevation
            variant="contained"
          >
            {I18n('Common.Save')}
          </Button>
        </DialogActions>
      </Dialog>
      <TariffCustomDailog
        isOpen={isOpenHSN}
        handleClose={handleCloseForAddHSN}
        OnSave={OnSaveCNCode}
        newCode={addHSCodeModalOpen.newCode}
        apiRef={addHSCodeModalOpen.apiRef}
        triggerFrom={addHSCodeModalOpen.triggerFrom}
        gridId={addHSCodeModalOpen.gridId}
        title={I18n('Nav.AddTariff')}
      ></TariffCustomDailog>
    </div>
  );
}
