import { useCallback, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import I18n from 'utilities/i18n';
import FormControl from 'common/formControl/formControl';
import { Grid } from '@mui/material';
import CustomizedSelect from 'common/CustomSelect/CustomSelect';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/material/Checkbox';
import {
  BaseModel,
  OrderByDirection,
  PaginationResponseModel,
} from 'models/pagination-model';
import environment from 'environment';
import { toast } from 'react-toastify';
import http from '../../utilities/httpService';
import AddressAutocompleteInputs from 'common/AddressAutocomplete/AddressAutocompleteInput';
import AddCity from 'common/Cities/AddCity';
import CustomSelectPaginateAdd from 'common/CustomSelect/CustomSelectPaginateAdd';
import {
  GetLatLongByPostalCode,
  PostCityDropDown,
} from 'common/DropDownAddAPI/DropDownApiService';

export default function EditLocation(props: any) {
  const defaultValue = {
    value: '',
    label: I18n('Placeholders.Select'),
  } as BaseModel;

  const defaultDropDownValue = {
    value: '0',
    label: I18n('Placeholders.Select'),
  } as BaseModel;

  const [cityList, setCityList] = useState<BaseModel[]>([]);
  const [countryList, setCountryList] = useState<BaseModel[]>([]);
  const {
    isOpen,
    handleClose,
    title,
    entityType,
    entityId,
    data,
    isHidden,
    isfromShippingPickupCompany = false,
  } = props;
  const [editdata, setData] = useState(data);
  const [selectedCity, setSelectedCity] = useState(defaultDropDownValue);
  const [selectedCountry, setSelectedCountry] = useState(defaultDropDownValue);
  const [errors, setErrors] = useState<any>({});
  const [cacheUniq, setCacheUniq] = useState(0);

  let countryLst: Array<BaseModel> = [];
  const getCountryList = (countries: any[]) => {
    countryLst = [defaultDropDownValue];
    countries.forEach((getCountryList: any) => {
      countryLst.push({
        value: getCountryList.key,
        label: getCountryList.value,
      } as BaseModel);
    });

    return countryLst;
  };
  const [values, setValues] = useState({
    address: '',
    address2: '',
    country: defaultValue,
    city: defaultValue,
    cityId: '',
    postalCode: '',
    street: '',
    destinationCompany: '',
    pickupCompany: '',
    isMainAddress: false,
    isFromDetailScreen: false,
  });

  const defaultAdditional: any = {
    page: 1,
  };

  const loadCitiesOptions = async (
    search: string,
    loadedOptions: any,
    { page }: any
  ) => {
    const response = await getCities(search, page);
    const hasMore = Math.ceil(response.TotalRecords / 500) > page;

    return {
      options: response.Values.map((x) => {
        return {
          value: x.value.toString(),
          label: x.label,
        } as BaseModel;
      }),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };
  const getCountries = async () => {
    let countryLst: any[] = [];

    const apiUrl = new URL(environment.api.baseUrl + 'Country');
    try {
      apiUrl.searchParams.set('$orderby', 'name');
      apiUrl.searchParams.set('$skip', '0');
      apiUrl.searchParams.set('$top', '5000');
      apiUrl.searchParams.set(
        '$orderbydirection',
        OrderByDirection.Ascending.toString()
      );

      const result = await http.get(apiUrl.toString());
      if (result) {
        result.data.values.map(function (item: any) {
          countryLst.push({
            key: item['id'],
            value: item['name'],
          });
        });

        let data = getCountryList(countryLst);
        setCountryList(data);
      }
    } catch (error) {}
  };

  let cityLst: Array<BaseModel> = [];
  const getCityList = (cities: any[]) => {
    cityLst = [defaultDropDownValue];
    cities.forEach((city: any) => {
      cityLst.push({
        value: city.key,
        label: city.value,
      } as BaseModel);
    });

    return cityLst;
  };

  const getCities = async (search: string = '', pageNo: number = 1) => {
    let cityLst: any[] = [];
    let response: PaginationResponseModel<BaseModel>;
    const apiUrl = new URL(environment.api.baseUrl + 'Cities');
    try {
      if (search && search !== undefined) {
        var searchExpression = '';
        searchExpression += search ? `name~like~${search}` : '';
        apiUrl.searchParams.set('$filter', searchExpression);
      }
      apiUrl.searchParams.set('$orderby', 'name');
      if (pageNo > 1) {
        apiUrl.searchParams.set('$skip', ((pageNo - 1) * 500).toString());
        apiUrl.searchParams.set('$top', '500');
      } else {
        apiUrl.searchParams.set('$top', '10000');
        apiUrl.searchParams.set('$skip', '0');
      }

      apiUrl.searchParams.set(
        '$orderbydirection',
        OrderByDirection.Ascending.toString()
      );

      const result = await http.get(apiUrl.toString());
      if (result) {
        result.data.values.map((item: any) => {
          cityLst.push({
            key: item['id'],
            value: item['name'],
          });
        });

        let data = getCityList(cityLst);
        setCityList(data);

        response = {
          TotalRecords: result.data.totalCount,
          Values: data,
        } as PaginationResponseModel<BaseModel>;
      } else {
        response = {
          TotalRecords: 0,
          Values: [],
        } as PaginationResponseModel<BaseModel>;
      }
    } catch (error) {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<BaseModel>;
    }
    return response;
  };

  const onCreateOption = async (inputValue: any) => {
    const name = {
      code: '',
      name: inputValue,
      otherComments: '',
      statusId: 1,
    };
    const newOption: any = await PostCityDropDown(
      name,
      i18nMessages.CityCommonSaveSuccessMsg,
      i18nMessages.CommonErrOccuredMsg
    );
    const increaseUniq = (uniq: any) => uniq + 1;
    setCacheUniq(increaseUniq);
    onCityChange(newOption);
    getCities('', 1);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setData({ ...editdata, isMainAddress: event.target.checked });
  };

  const onIsMainAddressChange = async (event: any) => {
    oncheckboxchange(event);
  };

  const onCityChange = (data: any) => {
    setData({
      ...editdata,
      city: data?.label === defaultDropDownValue.label ? null : data?.label,
      cityId: data?.value === defaultDropDownValue.value ? null : data?.value,
    });

    setSelectedCity({ value: data?.value, label: data?.label });
  };

  const oncheckboxchange = (event: any) => {
    const { checked } = event.target;
    setData({
      ...editdata,
      isMainAddress: checked,
    });
  };

  const validate = () => {
    let temp: any = {};
    let isValid = true;
    if (selectedCountry.value === defaultDropDownValue.value) {
      temp.country = 'CustomerLocation.Validation.CountryRequired';
      isValid = false;
    }
    if (selectedCity.value === defaultDropDownValue.value) {
      temp.city = 'CustomerLocation.Validation.CityRequired';
      isValid = false;
    }
    if (!editdata.zipCode) {
      temp.zipCode = 'CustomerLocation.Validation.ZipCodeRequired';
      isValid = false;
    } else if (editdata.zipCode.length > 20) {
      temp.zipCode = 'CustomerLocation.Validation.ZipCodeMaxLength';
    }

    if (editdata.address && editdata.address.length > 250) {
      temp.address = 'CustomerLocation.Validation.AddressMaxLength';
    }

    if (!editdata.street) {
      temp.street = 'CustomerLocation.Validation.StreetCompanyRequired';
      isValid = false;
    } else if (editdata.street.length > 250) {
      temp.street = 'CustomerLocation.Validation.StreetCompanyMaxLength';
    }

    if (!isHidden) {
      if (editdata.destinationCompany?.length > 250) {
        isValid = false;
        temp.destinationCompany =
          'CustomerLocation.Validation.DestinationCompanyMaxLength';
      }
    }

    if (!isHidden) {
      if (isfromShippingPickupCompany && editdata.pickupCompany == '') {
        isValid = false;
        temp.pickupCompany = 'Common.FieldRequired';
      }
      if (editdata.pickupCompany?.length > 250) {
        isValid = false;
        temp.pickupCompany =
          'CustomerLocation.Validation.PickupCompanyMaxLength';
      }
    }

    setErrors({
      ...temp,
    });

    if (isValid) {
      return true;
    } else {
      return false;
    }
  };

  const submit = async () => {
    if (validate()) {
      let coordinates = null;
      if (existingPostCode && existingPostCode !== editdata.zipCode) {
        coordinates = await GetLatLongByPostalCode(
          editdata.zipCode,
          editdata.city,
          editdata.latitude,
          editdata.longitude
        );
      }

      var location = {
        id: editdata.id,
        cityId: selectedCity.value,
        countryId: selectedCountry.value,
        zipCode: editdata.zipCode,
        address: editdata.address ? String(editdata.address).trim() : '',
        address2: editdata.address2 ? String(editdata.address2).trim() : '',
        street: editdata.street ? String(editdata.street).trim() : '',
        destinationCompany: editdata.destinationCompany
          ? String(editdata.destinationCompany).trim()
          : '',
        pickupCompany: editdata.pickupCompany
          ? String(editdata.pickupCompany).trim()
          : '',
        isMainAddress: editdata.isMainAddress,
        entityId,
        entityType,
        latitude:
          coordinates != null ? coordinates.latitude : editdata.latitude,
        longitude:
          coordinates != null ? coordinates.longitude : editdata.longitude,
      };

      const apiUrl = new URL(
        environment.api.baseUrl + 'Locations/' + location.id
      );
      try {
        const result: any = await http.put(apiUrl.toString(), location);
        if (result) {
          if (result.data === true) {
            toast.success('Saved Successfully');
            props.OnSave();
          } else {
            toast.error(i18nMessages.IsMainAddressError);
          }
        } else {
          toast.error(I18n('Common.ErrorOccurred'));
        }
      } catch (error: any) {}
    }
  };

  useEffect(() => {
    getCountries();
    getCities();
    if (data) {
      setData(data);
      setSelectedCity({
        value: data.cityId,
        label: data.city,
      });
      setSelectedCountry({
        value: data.countryId,
        label: data.country,
      });
      setExistingPostCode(data.zipCode);
    }
  }, [data]);

  const i18nMessages = {
    AddCity: I18n('Cities.AddDialogTitle'),
    CommonSaveSuccessMsg: I18n('Common.SavedSuccessfully'),
    CommonErrOccuredMsg: I18n('Common.ErrorOccurred'),
    IsMainAddressError: I18n('Common.IsMainAddressError'),
    PickupCompany: I18n('CustomerLocation.PickupCompany'),
    CityCommonSaveSuccessMsg: I18n('Common.CitySavedSuccessfully'),
  };

  const [isAddCityModalOpen, setIsAddCityModal] = useState(false);
  const [locationData, setLocationData] = useState({
    country: defaultDropDownValue,
    postcode: '',
    place: defaultDropDownValue,
  });
  const [existingPostCode, setExistingPostCode] = useState('');

  //Autocomplete
  const autocompleteHandleChange = async (
    value: string,
    selectedPlace: any
  ) => {
    const autoSelectValues = {
      country: selectedCountry,
      postcode: editdata.zipCode,
      place: selectedCity,
    };
    //Country
    if (selectedPlace.country && selectedPlace.country !== undefined) {
      let selectedCountry = countryList.find(
        (option: any) => option.label === selectedPlace.country
      );
      autoSelectValues.country = selectedCountry
        ? selectedCountry
        : defaultDropDownValue;
      setLocationData({
        ...locationData,
        country: autoSelectValues.country,
      });
      setSelectedCountry(autoSelectValues.country);
    }
    //City or Place
    if (selectedPlace.place && selectedPlace.place !== undefined) {
      let selectedCity = cityList.find(
        (option: any) => option.label === selectedPlace.place
      );

      //Add new city in db
      if (!selectedCity || selectedCity === undefined) {
        selectedCity = await addNewCity(selectedPlace.place);
      }

      autoSelectValues.place = autoSelectValues.place = selectedCity
        ? selectedCity
        : defaultDropDownValue;

      setLocationData({
        ...locationData,
        place: selectedCity
          ? selectedCity
          : ({ label: selectedPlace.place, value: '0' } as BaseModel),
      });
      setSelectedCity(autoSelectValues.place);
    }
    //Postal code
    if (selectedPlace.postcode && selectedPlace.postcode !== undefined) {
      autoSelectValues.postcode = selectedPlace.postcode;
      setLocationData({
        ...locationData,
        postcode: autoSelectValues.postcode,
      });
    }
    setData({
      ...editdata,
      address: value,
      street: selectedPlace.number ? selectedPlace.number : values.street,
      zipCode: autoSelectValues.postcode,
      latitude: selectedPlace.latitude
        ? selectedPlace.latitude
        : editdata.latitude,
      longitude: selectedPlace.longitude
        ? selectedPlace.longitude
        : editdata.longitude,
    });
  };

  const addNewCity = async (value: string) => {
    const name = {
      code: value,
      name: value,
      otherComments: value,
      statusId: 1,
    };
    const newOption: any = await PostCityDropDown(
      name,
      i18nMessages.CityCommonSaveSuccessMsg,
      i18nMessages.CommonErrOccuredMsg
    );

    return newOption;
  };

  const onAddCity = useCallback(() => {
    setIsAddCityModal(true);
  }, []);

  const onCloseAddCity = () => {
    setIsAddCityModal(false);
  };

  const onSubmitCity = async (cityModel: any) => {
    await getCities();
    onCloseAddCity();
  };

  //Add City
  useEffect(() => {
    if (!isAddCityModalOpen) {
      setCityByLocationData();
    }
  }, [isAddCityModalOpen]);

  const setCityByLocationData = () => {
    if (locationData.place && locationData.place !== undefined) {
      let selectedCity = cityList.find(
        (option: any) => option.label === locationData.place.label
      );
      if (selectedCity && selectedCity !== undefined) {
        setSelectedCity(selectedCity);
      }
    }
  };

  const ResetForm = () => {
    setValues({
      ...values,
      address: '',
      address2: '',
      country: defaultValue,
      city: defaultValue,
      cityId: '',
      postalCode: '',
      street: '',
      destinationCompany: '',
      pickupCompany: '',
      isMainAddress: false,
      isFromDetailScreen: false,
    });
    setErrors({});
  };

  const resetClose = () => {
    ResetForm();
    handleClose();
  };

  const onBlurZipCode = async (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const apiUrl = new URL(
      environment.api.baseUrl +
        'Locations/GetLocationByZipCode/' +
        String(event.target.value)
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result) {
        if (result.data) {
          const city: BaseModel = {
            label: String(result.data.city),
            value: String(result.data.cityId),
          };
          const country: BaseModel = {
            label: String(result.data.country),
            value: String(result.data.countryId),
          };
          setData({
            ...editdata,
            city: city.label,
            cityId: city.value,
            country: country.label,
            countryId: country.value,
          });
          setSelectedCity(city);
          setSelectedCountry(country);
        } else {
        }
      } else {
        toast.error(I18n('Common.ErrorOccurred'));
      }
    } catch (error: any) {
      setData({
        ...editdata,
        city: '',
        cityId: '',
        country: '',
        countryId: '',
      });
      setSelectedCity(defaultDropDownValue);
      setSelectedCountry(defaultDropDownValue);
    }
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={resetClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={'sm'}
        fullWidth={true}
      >
        <DialogTitle>
          {title}
          <IconButton
            aria-label="close"
            onClick={resetClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className="grid-wrap two-col">
            <Grid item xs={12}>
              <AddressAutocompleteInputs
                inputProps={{ maxLength: 250 }}
                multiline={true}
                displayLabel={I18n('Customer.SearchAddress')}
                handleChange={autocompleteHandleChange}
                readOnly={editdata?.isFromDetailScreen}
                // value={editdata?.address}
              ></AddressAutocompleteInputs>
            </Grid>
            {!isHidden ? (
              <Grid item xs={6}>
                <FormControl
                  margin="normal"
                  displayLabel={i18nMessages.PickupCompany}
                  id="pickupCompany"
                  inputType="text"
                  inputProps={{ maxLength: 500 }}
                  handleChange={(
                    event: React.ChangeEvent<
                      HTMLTextAreaElement | HTMLInputElement
                    >
                  ) =>
                    setData({
                      ...editdata,
                      pickupCompany: event.target.value,
                    })
                  }
                  value={editdata?.pickupCompany}
                  multiline={false}
                  errorValue={I18n(errors.pickupCompany)}
                  errorMessage={I18n(errors.pickupCompany)}
                  required={isfromShippingPickupCompany}
                ></FormControl>
              </Grid>
            ) : (
              <></>
            )}
            {!isHidden ? (
              <Grid item xs={6}>
                <FormControl
                  margin="normal"
                  displayLabel={I18n('CustomerLocation.DestinationCompany')}
                  id="destinationCompany"
                  inputType="text"
                  inputProps={{ maxLength: 500 }}
                  handleChange={(
                    event: React.ChangeEvent<
                      HTMLTextAreaElement | HTMLInputElement
                    >
                  ) =>
                    setData({
                      ...editdata,
                      destinationCompany: event.target.value,
                    })
                  }
                  value={editdata?.destinationCompany}
                  multiline={false}
                  errorValue={I18n(errors.destinationCompany)}
                  errorMessage={I18n(errors.destinationCompany)}
                ></FormControl>
              </Grid>
            ) : (
              <></>
            )}

            {/* <Grid item xs={6}>
              <FormControl
                margin="normal"
                displayLabel={I18n('CustomerLocation.Address')}
                id="Address"
                inputType="text"
                inputProps={{ maxLength: 250 }}
                handleChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ) =>
                  setData({
                    ...editdata,
                    address: event.target.value,
                  })
                }
                value={editdata?.address}
                multiline={false}
                errorValue={I18n(errors.address)}
                errorMessage={I18n(errors.address)}
                readOnly={editdata?.isFromDetailScreen}
              ></FormControl>
            </Grid> */}
            <Grid item xs={12}>
              <FormControl
                margin="normal"
                displayLabel={I18n('CustomerLocation.Address')}
                id="address"
                inputType="text"
                inputProps={{ maxLength: 500 }}
                handleChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ) =>
                  setData({
                    ...editdata,
                    street: event.target.value,
                  })
                }
                value={editdata?.street}
                multiline={false}
                readOnly={editdata?.isFromDetailScreen}
                errorValue={I18n(errors.street)}
                errorMessage={I18n(errors.street)}
                required={true}
              ></FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                margin="normal"
                displayLabel={I18n('CustomerLocation.Address2')}
                id="Address2"
                inputType="text"
                inputProps={{ maxLength: 250 }}
                handleChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ) =>
                  setData({
                    ...editdata,
                    address2: event.target.value,
                  })
                }
                value={editdata?.address2}
                multiline={false}
                readOnly={editdata?.isFromDetailScreen}
                errorValue={I18n(errors.address)}
                errorMessage={I18n(errors.address)}
              ></FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl
                margin="normal"
                displayLabel={I18n('Partner.PostalCode')}
                id="ZipCode"
                inputType="text"
                inputProps={{ maxLength: 20 }}
                handleChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ) =>
                  setData({
                    ...editdata,
                    zipCode: event.target.value,
                  })
                }
                value={editdata?.zipCode}
                multiline={false}
                errorValue={I18n(errors.zipCode)}
                errorMessage={I18n(errors.zipCode)}
                required={true}
                readOnly={editdata?.isFromDetailScreen}
                handleBlur={onBlurZipCode}
              ></FormControl>
            </Grid>
            <Grid item xs={6}>
              <div className="add-button-address">
                <CustomSelectPaginateAdd
                  additional={defaultAdditional}
                  value={selectedCity}
                  loadOptions={loadCitiesOptions}
                  handleChange={(newValue: BaseModel) =>
                    setSelectedCity(newValue)
                  }
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                  }}
                  placeholder={defaultDropDownValue.label}
                  isSearchable={true}
                  displayLabel={I18n('CustomerLocation.City')}
                  errorValue={true}
                  errorMessage={I18n(errors.city)}
                  debounceTimeout={500}
                  isMultiSelect={false}
                  cacheUniqs={cacheUniq}
                  onCreateOption={(inputValue: any) =>
                    onCreateOption(inputValue)
                  }
                  readOnly={editdata?.isFromDetailScreen}
                  required={true}
                ></CustomSelectPaginateAdd>
              </div>
            </Grid>
            <Grid item xs={6}>
              <CustomizedSelect
                placeholder={defaultDropDownValue.label}
                options={countryList}
                isSearchable={true}
                displayLabel={I18n('CustomerLocation.Country')}
                handleChange={(newValue: BaseModel) =>
                  setSelectedCountry(newValue)
                }
                value={selectedCountry}
                errorValue={true}
                errorMessage={I18n(errors.country)}
                required={true}
                readOnly={editdata?.isFromDetailScreen}
              ></CustomizedSelect>
            </Grid>
            {!isHidden ? (
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={editdata?.isMainAddress}
                      onChange={onIsMainAddressChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label={I18n('CustomerLocation.IsMainAddress')}
                />
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </DialogContent>
        <DialogActions className="btn-wrap">
          <Button
            color="neutral"
            disableElevation
            variant="contained"
            onClick={resetClose}
          >
            {' '}
            {I18n('Common.Cancel')}
          </Button>
          <Button
            onClick={submit}
            autoFocus
            color="primary"
            disableElevation
            variant="contained"
          >
            {I18n('Common.Save')}
          </Button>
        </DialogActions>
      </Dialog>
      {isAddCityModalOpen && (
        <AddCity
          isOpen={isAddCityModalOpen}
          onClose={onCloseAddCity}
          onSubmit={onSubmitCity}
          title={i18nMessages.AddCity}
          locationData={locationData}
        ></AddCity>
      )}
    </>
  );
}
