import I18n from 'utilities/i18n';
import { useEffect, useState } from 'react';
import http from '../../utilities/httpService';
import environment from 'environment';
import PrintIcon from '@mui/icons-material/Print';
import { hasPermission } from 'utilities/protectedRoute';
import CustomSelectPaginateAdd from 'common/CustomSelect/CustomSelectPaginateAdd';
import { PostCityDropDown } from 'common/DropDownAddAPI/DropDownApiService';
import CustomizedPhone from 'common/formControl/formPhone';
import FormAccordian from 'common/formControl/formAccordian';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  Collapse,
  Grid,
  List,
  ListItemButton,
  ListItemText,
} from '@mui/material';

import {
  BaseModel,
  CreateLabelOption,
  CreateLabelLayout,
  EntityCreateLabelLayoutDescription,
  EntityCreateLabelOptionDescription,
  TypeOfService,
  OrderByDirection,
  PaginationResponseModel,
} from 'models/pagination-model';

import CustomizedSelect from 'common/CustomSelect/CustomSelect';
import CustomizedInputs from 'common/formControl/formControl';
import { toast } from 'react-toastify';

import './PreviewLabel.scss';
import { getDGPlusData } from '../../components/deckpack-orders/DeckPackApiService';
import NumericInput from 'common/NumericInput/NumericInput';
import { LabelAddressSegment } from 'components/labelMaster/LabelApiService';
import { getLocationById } from 'components/shipping-orders/ShippingApiService';
import RouteEnum from 'models/RouteEnum';
import { ZipCodeTriggeredFrom } from 'models/location';

function ShippingLabels(props: any) {
  const { orderId, permission, isOrderEnable } = props;
  const [canEdit, setCanEdit] = useState(
    hasPermission(permission) && isOrderEnable
  );
  const navigate = useNavigate();
  let returnWoNo = localStorage.getItem('ReturnWoNo');

  const defaultValue = {
    value: '0',
    label: I18n('Placeholders.SelectLayoutType'),
  } as BaseModel;
  const defaultConsigneeValue = {
    value: '0',
    label: I18n('Placeholders.SelectConsignee'),
  } as BaseModel;

  const defaultPsnValue = {
    value: '0',
    label: I18n('DecPackOrders.CheckDocumentation.SelectPSN'),
  } as BaseModel;

  const defaultDropDownValue = {
    value: '0',
    label: I18n('Placeholders.Select'),
  } as BaseModel;

  const defaultAddressSegments = {
    country: defaultDropDownValue,
    city: defaultDropDownValue,
    postalCode: '',
    address: '',
    address2: '',
    street: '',
  };

  const i18nMessages = {
    commonFieldIsRequired: I18n('Common.FieldIsRequired'),
    createLabels: I18n('DecPackOrders.CheckDocumentation.CreateLabels'),
    layoutType: I18n('DecPackOrders.CheckDocumentation.LayoutType'),
    consignee: I18n('DecPackOrders.CheckDocumentation.Consignee'),
    shipper: I18n('DecPackOrders.CheckDocumentation.Shipper'),
    unNumber: I18n('DecPackOrders.CheckDocumentation.UnNumber'),
    technicalName: I18n('DecPackOrders.CheckDocumentation.TechnicalName'),
    netQuantity: I18n('DecPackOrders.CheckDocumentation.NetQuantity'),
    overPackId: I18n('DecPackOrders.CheckDocumentation.OverPackId'),
    totalQuantity: I18n('DecPackOrders.CheckDocumentation.TotalQuantity'),
    otherLabelFreeText: I18n(
      'DecPackOrders.CheckDocumentation.OtherLabelFreeText'
    ),
    addSuccess: I18n('DecPackOrders.CheckDocumentation.AddSuccess'),
    somethingWentWrong: I18n(
      'DecPackOrders.CheckDocumentation.SomethingWentWrong'
    ),
    updateSuccess: I18n('DecPackOrders.CheckDocumentation.UpdateSuccess'),
    myHazGoRef: I18n('DecPackOrders.CheckDocumentation.MyHazGoRef'),
    customerRef: I18n('DecPackOrders.CheckDocumentation.CustomerRef'),
    numberOfPieces: I18n('DecPackOrders.CheckDocumentation.NumberOfPieces'),
    extractDGPlus: I18n('DecPackOrders.WorkOrder.ExtractDGPlus'),
    addPartner: I18n('Partner.AddPartner'),
    addCustomer: I18n('Customer.AddCustomer'),
    PSN: I18n('DecPackOrders.CheckDocumentation.PSN'),
    SelectPSN: I18n('DecPackOrders.CheckDocumentation.SelectPSN'),
    DecPackCustomerRef: I18n('DecPack.CustomerReference'),
    DecPackConsignee: I18n('DecPack.Consignee'),
    DecPackCustomer: I18n('DecPack.Customer'),
    FinalDestination: I18n('DecPack.FinalDestination'),

    AddCity: I18n('Cities.AddDialogTitle'),
    CommonSaveSuccessMsg: I18n('Common.SavedSuccessfully'),
    CommonErrOccuredMsg: I18n('Common.ErrorOccurred'),
    Save: I18n('Common.Save'),
    Cancel: I18n('Common.Cancel'),
    Update: I18n('Common.Update'),
    SearchAddress: I18n('Customer.SearchAddress'),
    CityCommonSaveSuccessMsg: I18n('Common.CitySavedSuccessfully'),
    LabelSaveSuccess: I18n('LabelMaster.LabelSaveSuccess'),
    LabelUpdateSuccess: I18n('LabelMaster.LabelUpdateSuccess'),
    City: I18n('LabelMaster.City'),
    Country: I18n('LabelMaster.Country'),
    Consignee: I18n('LabelMaster.Consignee'),
    Address2: I18n('LabelMaster.Address2'),
    PostalCode: I18n('LabelMaster.PostalCode'),
    Address: I18n('LabelMaster.Address'),
    Shipper: I18n('LabelMaster.Shipper'),
    ShipperDetailHeader: I18n('LabelMaster.ShipperDetail'),
    ConsigneeDetailHeader: I18n('LabelMaster.ConsigneeDetail'),
    OtherDetailHeader: I18n('LabelMaster.OtherDetail'),
    EmergencyPhoneNumber: I18n('LabelMaster.EmergencyPhoneNumber'),
    ShipperRequired: I18n('LabelMaster.Validation.ShipperRequired'),
    ShipperMaxLength: I18n('LabelMaster.Validation.ShipperMaxLength'),
    CountryRequired: I18n('LabelMaster.Validation.CountryRequired'),
    CityRequired: I18n('LabelMaster.Validation.CityRequired'),
    PostalCodeRequired: I18n('LabelMaster.Validation.PostalCodeRequired'),
    PostalCodeMaxLength: I18n('LabelMaster.Validation.PostalCodeMaxLength'),
    AddressRequired: I18n('LabelMaster.Validation.AddressRequired'),
    AddressMaxLength: I18n('LabelMaster.Validation.AddressMaxLength'),
    ConsigneeRequired: I18n('LabelMaster.Validation.ConsigneeRequired'),
    ConsigneeMaxLength: I18n('LabelMaster.Validation.ConsigneeMaxLength'),
  };

  let entityCreateLabelOptions: Array<BaseModel> = [];
  const getCreateLabelOptions = () => {
    EntityCreateLabelOptionDescription.forEach((value: string, key: number) => {
      if (key !== CreateLabelOption.WarehouseLabel) {
        entityCreateLabelOptions.push({
          value: key.toString(),
          label: I18n(value),
        } as BaseModel);
      }
    });
  };
  getCreateLabelOptions();

  let entityCreateLabelLayouts: Array<BaseModel> = [];
  const getCreateLabelLayouts = () => {
    entityCreateLabelLayouts = [defaultValue];
    EntityCreateLabelLayoutDescription.forEach((value: string, key: number) => {
      entityCreateLabelLayouts.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getCreateLabelLayouts();

  const getShippingNames = async () => {
    let resp = [];
    var unNumber = localStorage.getItem('unNumber');
    if (values.unNumber) {
      let respPsnApi = await getDGPlusData(values.unNumber);
      if (respPsnApi && respPsnApi.result == true) {
        resp = respPsnApi.data;
      }
    } else {
      if (unNumber) {
        let respPsnApi = await getDGPlusData(unNumber);
        if (respPsnApi && respPsnApi.result == true) {
          resp = respPsnApi.data;
        }
        localStorage.removeItem('unNumber');
      }
    }
    return resp;
  };

  let psnOptionsList: Array<BaseModel> = [];
  const getPsnOptionsList = async () => {
    psnOptionsList = [defaultPsnValue];

    let psnList = await getShippingNames();
    if (psnList.length > 0) {
      psnList.forEach((value: any, key: number) => {
        psnOptionsList.push({
          value: value.id.toString(),
          label: value.name,
        } as BaseModel);
      });
    }
    setPsnOptions(psnOptionsList);
  };

  const [createLabelsId, setCreateLabelsId] = useState<number>();
  const [isNew, setIsNew] = useState<boolean>(true);

  const [showCreateLabelOptions, setShowCreateLabelOptions] =
    useState<boolean>(false);

  const [psnOptions, setPsnOptions] = useState<any>();

  const [errors, setErrors] = useState({
    layoutType: '',
    shipper: '',
    consignee: '',
    unNumber: '',
    technicalName: '',
    netQuantity: '',
    otherLabelText: '',
    myHazGoRef: '',
    customerRef: '',
    address: '',
    labelShipper: '',
    shipperAddress: '',
    shipperAddress2: '',
    shipperCountry: '',
    shipperCity: '',
    shipperPostalCode: '',
    shipperStreet: '',
    shipperEmergencyPhoneNumber: '',
    labelConsignee: '',
    consigneeAddress: '',
    consigneeAddress2: '',
    consigneeCountry: '',
    consigneeCity: '',
    consigneePostalCode: '',
    consigneeStreet: '',
    consigneeEmergencyPhoneNumber: '',
  });

  const [values, setValues] = useState<any>({
    id: 0,
    OrderId: orderId,
    labelType: '',
    layoutType: '',
    shipper: '',
    shipperData: '',
    customer: '',
    customerData: '',
    finalDestinationName: '',
    unNumber: '',
    technicalName: '',
    netQuantity: '',
    overPackId: '',
    totalQuantity: '',
    otherLabelText: '',
    myHazGoRef: '',
    customerRef: '',
    numberOfPieces: '',
    psn: {
      value: '0',
      label: I18n('DecPackOrders.CheckDocumentation.SelectPSN'),
    } as BaseModel,
    address: '',
    shipperId: 0,
    labelShipperName: '',
    labelConsigneeName: '',
    shipperAddress: '',
    shipperAddress2: '',
    shipperCountry: defaultDropDownValue,
    shipperCity: defaultDropDownValue,
    shipperCityId: '',
    shipperCountryId: '',
    shipperPostalCode: '',
    shipperStreet: '',
    shipperEmergencyPhoneNumber: '',
    consigneeId: 0,
    consigneeAddress: '',
    consigneeAddress2: '',
    consigneeCountry: defaultDropDownValue,
    consigneeCity: defaultDropDownValue,
    consigneeCityId: '',
    consigneeCountryId: '',
    consigneePostalCode: '',
    consigneeStreet: '',
    consigneeEmergencyPhoneNumber: '',
  });

  const defaultAdditional: any = {
    page: 1,
  };
  const [orderDetails, setOrderDetails] = useState<any>({});
  const [showFinalDestination, setShowFinalDestination] = useState(false);

  const validate = (fieldValues: any = values) => {
    let temp = { ...errors };

    if ('layoutType' in fieldValues) {
      if (fieldValues.layoutType.value === '0') {
        temp.layoutType = i18nMessages.commonFieldIsRequired;
      } else {
        temp.layoutType = fieldValues.layoutType
          ? ''
          : i18nMessages.commonFieldIsRequired;
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === '');
    }
  };

  const handleCreateLabelClick = async () => {
    setShowCreateLabelOptions(!showCreateLabelOptions);
  };

  const handleListItemClick = (item: any) => {
    setShowCreateLabelOptions(false);
    if (item.value == CreateLabelOption.Shipping) {
      setValues({
        ...values,
        labelType: { value: 1, label: 'Shipping Label' },
        layoutType: '',
      });
    } else if (item.value == CreateLabelOption.Overpack) {
      setValues({
        ...values,
        labelType: { value: 2, label: 'Overpack Label' },
        layoutType: { value: 2, label: 'ZEBRA' },
      });
    } else if (item.value == CreateLabelOption.Other) {
      setValues({
        ...values,
        labelType: { value: 3, label: 'Other' },
        layoutType: { value: 1, label: 'DYMO' },
      });
    } else if (item.value == CreateLabelOption.WarehouseLabel) {
      setValues({
        ...values,
        labelType:
          entityCreateLabelOptions &&
          entityCreateLabelOptions.some(
            (x) => +x.value === CreateLabelOption.WarehouseLabel
          )
            ? entityCreateLabelOptions.find(
                (x) => +x.value === CreateLabelOption.WarehouseLabel
              )
            : ({ value: '', label: '' } as BaseModel),
        layoutType:
          entityCreateLabelLayouts &&
          entityCreateLabelLayouts.some(
            (x) => +x.value === CreateLabelLayout.Zebra
          )
            ? entityCreateLabelLayouts.find(
                (x) => +x.value === CreateLabelLayout.Zebra
              )
            : defaultValue,
      });
    }
  };

  const onLayoutTypeChange = (data: any) => {
    setValues({
      ...values,
      layoutType: { value: Number(data?.value), label: data.label },
    });
  };

  const onPsnValueChange = (data: any) => {
    setValues({
      ...values,
      psn: { value: Number(data?.value), label: data.label },
    });
  };

  const getCustomerDetail = async (id: number) => {
    const apiUrl = new URL(environment.api.baseUrl + 'Customer/' + id);
    try {
      const result: any = await http.get(apiUrl.toString());
      if (result) {
        return result.data;
      }
    } catch (error: any) {}
  };

  const handlePrintClick = async () => {
    var content = '';
    if (values.labelType !== '' && values.layoutType !== '') {
      if (
        values.labelType.value == CreateLabelOption.Shipping &&
        values.layoutType.value == CreateLabelLayout.Dymo
      ) {
        content = createShipperDymoContent();
      }
      if (
        values.labelType.value == CreateLabelOption.Shipping &&
        values.layoutType.value == CreateLabelLayout.Zebra
      ) {
        content = createShipperZebraContent();
      }
      if (
        values.labelType.value == CreateLabelOption.WarehouseLabel &&
        values.layoutType.value == CreateLabelLayout.Zebra
      ) {
        content = createWarehouseZebraContent();
      }
      if (
        values.labelType.value == CreateLabelOption.Overpack &&
        values.layoutType.value == CreateLabelLayout.Zebra
      ) {
        content = createOverpackZebraContent();
      }
      if (
        values.labelType.value == CreateLabelOption.Other &&
        values.layoutType.value == CreateLabelLayout.Dymo
      ) {
        content = createOtherDymoContent();
      }
    }

    var printer = document.getElementById('printMe') as HTMLIFrameElement;
    if (content !== null) {
      printer.contentWindow?.document.open();
      printer.contentWindow?.document.write(content);
      printer.contentWindow?.document.close();
      printer.contentWindow?.focus();
      printer.contentWindow?.print();
    }
  };

  const onBlurZipCode = async (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    triggerFrom: ZipCodeTriggeredFrom
  ) => {
    const apiUrl = new URL(
      environment.api.baseUrl +
        'Locations/GetLocationByZipCode/' +
        String(event.target.value)
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result) {
        if (result.data) {
          const city: BaseModel = {
            label: String(result.data.city),
            value: String(result.data.cityId),
          };
          const country: BaseModel = {
            label: String(result.data.country),
            value: String(result.data.countryId),
          };
          if (triggerFrom == ZipCodeTriggeredFrom.Shipper) {
            setValues({
              ...values,
              shipperPostalCode: event.target.value,
              shipperCity: city,
              shipperCountry: country,
            });
          } else {
            setValues({
              ...values,
              consigneePostalCode: event.target.value,
              consigneeCity: city,
              consigneeCountry: country,
            });
          }
        }
      } else {
        toast.error(I18n('Common.ErrorOccurred'));
      }
    } catch (error: any) {
      if (triggerFrom == ZipCodeTriggeredFrom.Shipper) {
        setValues({
          ...values,
          shipperCity: defaultDropDownValue,
          shipperCountry: defaultDropDownValue,
        });
      } else {
        setValues({
          ...values,
          consigneeCity: defaultDropDownValue,
          consigneeCountry: defaultDropDownValue,
        });
      }
    }
  };

  const handleSaveClick = async () => {
    if (validate()) {
      const finalValue = {
        id: createLabelsId,
        OrderId: Number(orderId),
        labelTypeId: values.labelType.value,
        layoutTypeId: values.layoutType.value,
        shipperId: values.shipper?.value != '0' ? values.shipper.value : null,
        consigneeId:
          values.customer?.value != '0' ? values.customer.value : null,
        shipper: values.shipper ? values.shipper : '',
        unNumber: values.unNumber !== '' ? +values.unNumber : null,
        technicalName: values.technicalName,
        netQuantity: values.netQuantity !== '' ? values.netQuantity : null,
        overPackId: values.overPackId !== '' ? +values.overPackId : null,
        totalQuantity:
          values.totalQuantity !== '' ? values.totalQuantity : null,
        otherLabelText: values.otherLabelText,
        myHazGoRef: values.myHazGoRef,
        customerRef: values.customerRef,
        noOfPieces: values.numberOfPieces ? Number(values.numberOfPieces) : 0,
        psnNumber: values.psn.value,
        address: values.address,
        labelShipperName: values.labelShipperName
          ? values.labelShipperName
          : '',
        labelConsigneeName: values.labelConsigneeName
          ? values.labelConsigneeName
          : '',
        shipperAddress2: values.shipperAddress2,
        shipperStreet: values.shipperStreet,
        shipperPostalCode: values.shipperPostalCode,
        shipperCityId:
          values.shipperCity?.value != defaultValue.value
            ? values.shipperCity?.value
            : null,
        shipperCountryId:
          values.shipperCountry?.value != defaultValue.value
            ? values.shipperCountry?.value
            : null,
        shipperEmergencyPhoneNumber: values.shipperEmergencyPhoneNumber,
        consigneeAddress2: values.consigneeAddress2,
        consigneeStreet: values.consigneeStreet,
        consigneePostalCode: values.consigneePostalCode,
        consigneeCityId:
          values.consigneeCity?.value != defaultValue.value
            ? values.consigneeCity?.value
            : null,
        consigneeCountryId:
          values.consigneeCountry?.value != defaultValue.value
            ? values.consigneeCountry?.value
            : null,
        consigneeEmergencyPhoneNumber: values.consigneeEmergencyPhoneNumber,
      };

      if (isNew) {
        const apiUrl = new URL(environment.api.baseUrl + 'ShippingLabels');
        try {
          const result: any = await http.post(apiUrl.toString(), finalValue);
          if (result) {
            toast.success(i18nMessages.CommonSaveSuccessMsg);
          }
        } catch (error: any) {
          toast.error(i18nMessages.somethingWentWrong);
        }
      } else {
        const apiUrl = new URL(
          environment.api.baseUrl + 'ShippingLabels/' + createLabelsId
        );
        try {
          const result: any = await http.put(apiUrl.toString(), finalValue);
          if (result) {
            toast.success(i18nMessages.CommonSaveSuccessMsg);
          }
        } catch (error: any) {
          toast.error(i18nMessages.somethingWentWrong);
        }
      }

      // Update "Number Of pieces" in DecPackOrder API
      const apiUrl = new URL(environment.api.baseUrl + `Shipping/${orderId}`);
      try {
        let decPackParamObj = orderDetails;
        decPackParamObj.piecesNo = String(values.numberOfPieces);
        const result: any = await http.put(apiUrl.toString(), decPackParamObj);
      } catch (error: any) {
        toast.error(i18nMessages.somethingWentWrong);
      }

      if (returnWoNo) {
        localStorage.removeItem('ReturnWoNo');
        if (returnWoNo == '2') {
          navigate(
            RouteEnum.ViewShippingOrders + '/' + orderId + '/DGD/' + returnWoNo
          );
        }
      }
    }
  };

  const getCreateLabels = async () => {
    let orderDetails = await getOrderDetails();
    setOrderDetails(orderDetails);
    setShowFinalDestination(
      orderDetails.serviceType &&
        (orderDetails.serviceType == TypeOfService.DGCheck ||
          orderDetails.serviceType == TypeOfService.DGDCreation)
    );
    let objCustomerDetails = await getCustomerDetail(orderDetails.customerId);

    const apiUrl = new URL(
      environment.api.baseUrl + 'ShippingLabels/' + orderId
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result && result.data) {
        setIsNew(false);
        setCreateLabelsId(result.data.id);

        result.data.labelTypeId = result.data.labelTypeId - 1;
        let labelData = entityCreateLabelOptions[result.data.labelTypeId];
        const layoutData = entityCreateLabelLayouts[result.data.layoutTypeId];

        if (result.data.unNumber !== null) {
          localStorage.setItem('unNumber', result.data.unNumber);
          getPsnOptionsList();
        }
        setValues({
          ...values,
          orderId: result.data.orderId,
          labelType: labelData,
          layoutType: layoutData,
          shipper: orderDetails?.shipper ?? '',
          address: result.data.address,
          customer: orderDetails?.customerId
            ? {
                value: orderDetails?.customerId,
                label: orderDetails?.customerName,
              }
            : defaultConsigneeValue,
          customerData: objCustomerDetails,
          finalDestinationName: orderDetails?.finalDestinationName,
          unNumber: result.data.unNumber,
          technicalName: result.data.technicalName,
          netQuantity: result.data.netQuantity,
          overPackId: result.data.overPackId,
          totalQuantity: result.data.totalQuantity,
          otherLabelText: result.data.otherLabelText,
          psn: {
            value: result.data.psnNumber,
            label: result.data.psnNumberValue,
          },
          myHazGoRef: orderDetails?.hazgoReference ?? '',
          customerRef: orderDetails?.customerReference ?? '',
          numberOfPieces: orderDetails?.piecesNo ?? 0,
          labelConsigneeName: result.data?.labelConsigneeName
            ? result.data?.labelConsigneeName
            : '',
          labelShipperName: result.data?.labelShipperName
            ? result.data?.labelShipperName
            : '',
          shipperAddress2: result.data?.shipperAddress2,
          shipperStreet: result.data?.shipperStreet,
          shipperPostalCode: result.data?.shipperPostalCode,
          shipperCityId: result.data?.shipperCityId,
          shipperCountryId: result.data?.shipperCountryId,
          shipperEmergencyPhoneNumber: result.data?.shipperEmergencyPhoneNumber,
          consigneeAddress2: result.data?.consigneeAddress2,
          consigneeStreet: result.data?.consigneeStreet,
          consigneePostalCode: result.data?.consigneePostalCode,
          consigneeCityId: result.data?.consigneeCityId,
          consigneeCountryId: result.data?.consigneeCountryId,
          consigneeEmergencyPhoneNumber:
            result.data?.consigneeEmergencyPhoneNumber,

          consigneeCity: result.data?.consigneeCityId
            ? {
                value: result.data?.consigneeCityId,
                label: result.data?.consigneeCityName,
              }
            : defaultDropDownValue,
          consigneeCountry: result.data?.consigneeCountryId
            ? {
                value: result.data?.consigneeCountryId,
                label: result.data?.consigneeCountryName,
              }
            : defaultDropDownValue,
          shipperCity: result.data?.shipperCityId
            ? {
                value: result.data?.shipperCityId,
                label: result.data?.shipperCityName,
              }
            : defaultDropDownValue,
          shipperCountry: result.data?.shipperCountryId
            ? {
                value: result.data?.shipperCountryId,
                label: result.data?.shipperCountryName,
              }
            : defaultDropDownValue,
        });
      } else {
        setIsNew(true);
        var pickupLocation = orderDetails?.pickUpCustomerLocationId
          ? await getLocationData(Number(orderDetails.pickUpCustomerLocationId))
          : defaultAddressSegments;

        var deliveryLocation = orderDetails?.deliveryLocationId
          ? await getLocationData(Number(orderDetails.deliveryLocationId))
          : defaultAddressSegments;
        setValues({
          ...values,
          myHazGoRef: orderDetails?.autogeneratedReferenceNo,
          shipper: orderDetails?.pickUpCustomerLocationName,
          labelShipperName: orderDetails?.pickUpCustomerLocationName,
          customer:
            orderDetails?.customerId && orderDetails?.customerName
              ? {
                  value: orderDetails?.customerId,
                  label: orderDetails?.customerName,
                }
              : defaultConsigneeValue,
          customerRef: orderDetails?.orderReference,
          numberOfPieces: orderDetails?.piecesNo ?? 0,
          customerData: objCustomerDetails,
          finalDestinationName: orderDetails?.finalDestinationName,
          shipperCity: pickupLocation.city,
          shipperCountry: pickupLocation.country,
          address: pickupLocation.address,
          shipperAddress2: pickupLocation.address2,
          shipperStreet: pickupLocation.street,
          shipperPostalCode: pickupLocation.postalCode,
          shipperCityId: pickupLocation.city.value,
          shipperCountryId: pickupLocation.country.value,
          labelConsigneeName: orderDetails?.deliveryLocationName,
          consigneeCountry: deliveryLocation.country,
          consigneeCity: deliveryLocation.city,
          consigneeCountryId: deliveryLocation.country.value,
          consigneeCityId: deliveryLocation.city.value,
          consigneePostalCode: deliveryLocation.postalCode,
          consigneeStreet: deliveryLocation.street,
          consigneeAddress2: deliveryLocation.address2,
        });
      }
    } catch (error) {}
  };

  const getLocationData = async (id: number) => {
    let location = await getLocationById(Number(id));
    let addressSegments = defaultAddressSegments;
    if (location?.data) {
      addressSegments = {
        country: location?.data?.country
          ? ({
              label: location.data.country,
              value: location.data.countryId,
            } as BaseModel)
          : defaultDropDownValue,
        city: location?.data?.city
          ? ({
              label: location.data.city,
              value: location.data.cityId,
            } as BaseModel)
          : defaultDropDownValue,
        postalCode: location?.data?.zipCode ? location.data.zipCode : '',
        address: `${
          location?.data?.street ? `${location?.data?.street},` : ''
        }${location?.data?.zipCode ? `${location?.data?.zipCode},` : ''}${
          location?.data?.city
        },${location?.data?.country}`,
        address2: location?.data?.address2 ? location?.data?.address2 : '',
        street: location?.data?.street ? location?.data?.street : '',
      };
    }
    return addressSegments;
  };

  const getOrderDetails = async () => {
    const apiUrl = new URL(environment.api.baseUrl + `Shipping/${orderId}`);
    try {
      const result = await http.get(apiUrl.toString());
      if (result && result.data) {
        return result.data;
      }
    } catch (error) {
      toast.error(i18nMessages.somethingWentWrong);
    }
  };

  useEffect(() => {
    getCreateLabels();
  }, []);

  // City Option
  const [cacheUniq, setCacheUniq] = useState(0);

  const [cityList, setCityList] = useState<BaseModel[]>([]);
  const [countryList, setCountryList] = useState<BaseModel[]>([]);

  let countryLst: Array<BaseModel> = [];
  const getCountryList = (countries: any[]) => {
    countryLst = [defaultDropDownValue];
    countries.forEach((getCountryList: any) => {
      countryLst.push({
        value: getCountryList.key,
        label: getCountryList.value,
      } as BaseModel);
    });

    return countryLst;
  };

  const getCountries = async () => {
    let countryLst: any[] = [];

    const apiUrl = new URL(environment.api.baseUrl + 'Country');
    try {
      apiUrl.searchParams.set('$orderby', 'name');
      apiUrl.searchParams.set('$skip', '0');
      apiUrl.searchParams.set('$top', '5000');
      apiUrl.searchParams.set(
        '$orderbydirection',
        OrderByDirection.Ascending.toString()
      );

      const result = await http.get(apiUrl.toString());
      if (result) {
        result.data.values.map(function (item: any) {
          countryLst.push({
            key: item['id'],
            value: item['name'],
          });
        });

        let data = getCountryList(countryLst);
        setCountryList(data);
      }
    } catch (error) {}
  };

  let cityLst: Array<BaseModel> = [];
  const getCityList = (cities: any[]) => {
    cityLst = [defaultDropDownValue];
    cities.forEach((city: any) => {
      cityLst.push({
        value: city.key,
        label: city.value,
      } as BaseModel);
    });

    return cityLst;
  };

  const loadCitiesOptions = async (
    search: string,
    loadedOptions: any,
    { page }: any
  ) => {
    const response = await getCities(search, page);
    const hasMore = Math.ceil(response.TotalRecords / 500) > page;

    return {
      options: response.Values.map((x) => {
        return {
          value: x.value.toString(),
          label: x.label,
        } as BaseModel;
      }),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const getCities = async (search: string = '', pageNo: number = 1) => {
    let cityLst: any[] = [];
    let response: PaginationResponseModel<BaseModel>;
    const apiUrl = new URL(environment.api.baseUrl + 'Cities');
    try {
      if (search && search !== undefined) {
        var searchExpression = '';
        searchExpression += search ? `name~like~${search}` : '';
        apiUrl.searchParams.set('$filter', searchExpression);
      }
      apiUrl.searchParams.set('$orderby', 'name');
      if (pageNo > 1) {
        apiUrl.searchParams.set('$skip', ((pageNo - 1) * 500).toString());
        apiUrl.searchParams.set('$top', '500');
      } else {
        apiUrl.searchParams.set('$top', '10000');
        apiUrl.searchParams.set('$skip', '0');
      }

      apiUrl.searchParams.set(
        '$orderbydirection',
        OrderByDirection.Ascending.toString()
      );

      const result = await http.get(apiUrl.toString());
      if (result) {
        result.data.values.map((item: any) => {
          cityLst.push({
            key: item['id'],
            value: item['name'],
          });
        });

        let data = getCityList(cityLst);
        setCityList(data);

        response = {
          TotalRecords: result.data.totalCount,
          Values: data,
        } as PaginationResponseModel<BaseModel>;
      } else {
        response = {
          TotalRecords: 0,
          Values: [],
        } as PaginationResponseModel<BaseModel>;
      }
    } catch (error) {
      response = {
        TotalRecords: 0,
        Values: [],
      } as PaginationResponseModel<BaseModel>;
    }
    return response;
  };

  const onCreateShipperCityOption = async (inputValue: any) => {
    const name = {
      code: '',
      name: inputValue,
      otherComments: '',
      statusId: 1,
    };
    const newOption: any = await PostCityDropDown(
      name,
      i18nMessages.CityCommonSaveSuccessMsg,
      i18nMessages.CommonErrOccuredMsg
    );
    const increaseUniq = (uniq: any) => uniq + 1;
    setCacheUniq(increaseUniq);
    onShipperCityChange(newOption);
    getCities('', 1);
  };

  const onShipperCityChange = (data: any) => {
    let cityLabel =
      data?.label === defaultDropDownValue.label ? null : data?.label;
    let cityValue =
      data?.value === defaultDropDownValue.value ? null : data?.value;
    setValues({
      ...values,
      shipperCityId: cityValue,
      shipperCity: { value: cityValue, label: cityLabel },
    });
  };

  const onCreateConsigneeCityOption = async (inputValue: any) => {
    const name = {
      code: '',
      name: inputValue,
      otherComments: '',
      statusId: 1,
    };
    const newOption: any = await PostCityDropDown(
      name,
      i18nMessages.CityCommonSaveSuccessMsg,
      i18nMessages.CommonErrOccuredMsg
    );
    const increaseUniq = (uniq: any) => uniq + 1;
    setCacheUniq(increaseUniq);
    onConsigneeCityChange(newOption);
    getCities('', 1);
  };

  const onConsigneeCityChange = (data: any) => {
    let cityLabel =
      data?.label === defaultDropDownValue.label ? null : data?.label;
    let cityValue =
      data?.value === defaultDropDownValue.value ? null : data?.value;
    setValues({
      ...values,
      consigneeCityId: cityValue,
      consigneeCity: { value: cityValue, label: cityLabel },
    });
  };

  const AddressLabelPreview = (props: any) => {
    const {
      street,
      address2,
      postalCode,
      city,
      country,
      emergencyPhoneNumber,
    } = props;
    return (
      <>
        {street ? <div>{street}</div> : ''}{' '}
        {address2 ? <div>{address2}</div> : ''}
        <div>
          {postalCode}
          {city && city.value != defaultDropDownValue.value
            ? ' ' + city.label
            : ''}
        </div>
        {country && country.value != defaultDropDownValue.value ? (
          <div>{country.label}</div>
        ) : (
          ''
        )}
        {emergencyPhoneNumber ? <div>{emergencyPhoneNumber}</div> : ''}
      </>
    );
  };

  // Create HTML Conent to Print

  const addressLabelPrint = (parms: LabelAddressSegment) => {
    const {
      street,
      address2,
      postalCode,
      city,
      country,
      emergencyPhoneNumber,
    } = parms;

    let content = '';
    content += `${street ? `<div>${street}</div>` : ''}`;
    content += `${address2 ? `<div>${address2}</div>` : ''}`;
    content += `<div>
                  ${postalCode}
                  ${
                    city && city.value != defaultDropDownValue.value
                      ? ' ' + city.label
                      : ''
                  }</div>`;
    content += `${
      country && country.value != defaultDropDownValue.value
        ? `<div>${country.label}</div>`
        : ''
    }`;
    content += `${
      emergencyPhoneNumber ? `<div>${emergencyPhoneNumber}</div>` : ''
    }`;

    return content;
  };

  // SHIPPER DYMO TEMPLATE CONTENT
  const ShipperDymoContent = () => {
    let content = `<div style="font-size: 12px;font-family: 'Sofia Pro';font-weight: 900;margin: 16px;">`;
    if (values.labelShipperName) {
      content += `<div>${i18nMessages.shipper}:</div>`;
      content += `<div>${values.labelShipperName}</div>`;
      const addressFields = {
        street: values.shipperStreet,
        address2: values.shipperAddress2,
        postalCode: values.shipperPostalCode,
        city: values.shipperCity,
        country: values.shipperCountry,
        emergencyPhoneNumber: values.shipperEmergencyPhoneNumber,
      } as LabelAddressSegment;
      content += addressLabelPrint(addressFields);
    }

    if (values.labelConsigneeName) {
      content += `<div>${i18nMessages.DecPackConsignee}:</div>`;
      content += `<div>${values.labelConsigneeName}</div>`;
      const addressFields = {
        street: values.consigneeStreet,
        address2: values.consigneeAddress2,
        postalCode: values.consigneePostalCode,
        city: values.consigneeCity,
        country: values.consigneeCountry,
        emergencyPhoneNumber: values.consigneeEmergencyPhoneNumber,
      } as LabelAddressSegment;
      content += addressLabelPrint(addressFields);
    }

    content +=
      values.unNumber && values.unNumber !== ''
        ? `<div>
        UN${values.unNumber} - 
        ${values?.psn?.value != '0' ? values?.psn?.label : ''} 
        ${values.technicalName !== '' ? '(' + values.technicalName + ')' : ''}
        -
        ${
          values.netQuantity !== '' && values.netQuantity !== undefined
            ? values.netQuantity
            : ''
        }
      </div>`
        : '';

    return content;
  };

  const createShipperDymoContent = () => {
    return ShipperDymoContent();
  };

  // SHIPPER ZEBRA TEMPLATE CONTENT
  const ShipperZebraContent = () => {
    let content = `<div style="font-size: 14px;font-family: 'Sofia Pro';font-weight: 900;margin: 16px;">`;
    if (values.labelShipperName) {
      content += `<div>${i18nMessages.shipper}:</div>`;
      content += `<div>${values.labelShipperName}</div>`;
      const addressFields = {
        street: values.shipperStreet,
        address2: values.shipperAddress2,
        postalCode: values.shipperPostalCode,
        city: values.shipperCity,
        country: values.shipperCountry,
        emergencyPhoneNumber: values.shipperEmergencyPhoneNumber,
      } as LabelAddressSegment;
      content += addressLabelPrint(addressFields);
      content += `<br /><br />`;
    }

    if (values.labelConsigneeName) {
      content += `<div>${i18nMessages.DecPackConsignee}:</div>`;
      content += `<div>${values.labelConsigneeName}</div>`;
      const addressFields = {
        street: values.consigneeStreet,
        address2: values.consigneeAddress2,
        postalCode: values.consigneePostalCode,
        city: values.consigneeCity,
        country: values.consigneeCountry,
        emergencyPhoneNumber: values.consigneeEmergencyPhoneNumber,
      } as LabelAddressSegment;
      content += addressLabelPrint(addressFields);
    }

    content += `<div style="font-size: 66px;font-weight: 500;margin-top: 32px;">UN ${values.unNumber}</div>`;
    content += `<div>${values?.psn?.value != '0' ? values?.psn?.label : ''} ${
      values.technicalName !== '' ? '(' + values.technicalName + ')' : ''
    }</div>`;
    content += `<div>${i18nMessages.netQuantity}: ${values.netQuantity}</div>`;
    content += `</div>`;

    return content;
  };

  const createShipperZebraContent = () => {
    return ShipperZebraContent();
  };

  // SHIPPER WAREHOUSE TEMPLATE CONTENT

  const WarehouseZebraContent = () => {
    return `<div style="font-size: 14px;font-family: 'Sofia Pro';font-weight: 900;margin: 16px;">
    <div>myHazGo Ref: ${values.myHazGoRef}</div>
    <br/>
    <div>${i18nMessages.shipper}:</div>
    <div>${values.shipper}</div>
    <br/><br/>
    <div>${i18nMessages.DecPackCustomer}:</div>
    <div>${values.customerData.name}</div>
    <div>${values.customerData.telephone}</div>
    ${
      showFinalDestination
        ? '<br/>' +
          i18nMessages.FinalDestination +
          ':' +
          '<div>' +
          values.finalDestinationName +
          '</div>'
        : ''
    }
    <br/>
    <div>Customer Ref: ${values.customerRef}</div>
    <div>Number of Pieces: ${values.numberOfPieces}</div>
    </div>`;
  };

  const createWarehouseZebraContent = () => {
    return WarehouseZebraContent();
  };

  const createOverpackZebraContent = () => {
    return `<div
    style="font-size: 30px;font-family: 'Sofia Pro';font-weight: 900;margin: 16px;"
    >
    <div style="font-size: 66px;font-weight: 500;margin-top: 96px;">OVERPACK</div>
    <div>#${values.overPackId}</div>
    <div>Total Quantity: ${values.totalQuantity}</div>
    </div>`;
  };

  const createOtherDymoContent = () => {
    return `<div
    style="font-size: 24px;font-family: 'Sofia Pro';font-weight: 900;margin: 16px;"
    >
    ${values.otherLabelText}
    </div>`;
  };

  // Shipping Label Preview For DecPack WO 4(Check Doc)
  const DymoShippingLabelPreview = () => {
    return values.labelType.value == CreateLabelOption.Shipping ? (
      <Box sx={{ fontSize: 12, fontWeight: 900 }} m={2}>
        {values.labelShipperName ? (
          <Box>
            <div>{i18nMessages.shipper}:</div>
            <div>{values.labelShipperName}</div>
            <AddressLabelPreview
              street={values.shipperStreet}
              address2={values.shipperAddress2}
              postalCode={values.shipperPostalCode}
              city={values.shipperCity}
              country={values.shipperCountry}
              emergencyPhoneNumber={values.shipperEmergencyPhoneNumber}
            />
          </Box>
        ) : (
          <></>
        )}

        {values.labelConsigneeName ? (
          <Box>
            <div>{i18nMessages.DecPackConsignee}:</div>
            <div>{values.labelConsigneeName}</div>
            <AddressLabelPreview
              street={values.consigneeStreet}
              address2={values.consigneeAddress2}
              postalCode={values.consigneePostalCode}
              city={values.consigneeCity}
              country={values.consigneeCountry}
              emergencyPhoneNumber={values.consigneeEmergencyPhoneNumber}
            />
          </Box>
        ) : (
          <></>
        )}

        {values.unNumber !== '' && values.unNumber !== undefined ? (
          <Box>
            <div>
              UN{values.unNumber} -{' '}
              {values?.psn?.value != '0' ? values?.psn?.label : ''}{' '}
              {values.technicalName !== ''
                ? '(' + values.technicalName + ')'
                : ''}
              -
              {values.netQuantity !== '' && values.netQuantity !== undefined
                ? values.netQuantity
                : ''}
            </div>
          </Box>
        ) : (
          <></>
        )}
      </Box>
    ) : (
      <></>
    );
  };

  const ZebraShippingLabelPreview = () => {
    return values.labelType.value == CreateLabelOption.Shipping ? (
      <Box sx={{ fontWeight: 900 }} m={2}>
        {values.labelShipperName ? (
          <Box mt={2}>
            <div>{i18nMessages.shipper}:</div>
            <div>{values.labelShipperName}</div>
            <AddressLabelPreview
              street={values.shipperStreet}
              address2={values.shipperAddress2}
              postalCode={values.shipperPostalCode}
              city={values.shipperCity}
              country={values.shipperCountry}
              emergencyPhoneNumber={values.shipperEmergencyPhoneNumber}
            />
          </Box>
        ) : (
          <></>
        )}

        {values.labelConsigneeName ? (
          <Box mt={3}>
            <div>{i18nMessages.DecPackConsignee}:</div>
            <div>{values.labelConsigneeName}</div>
            <AddressLabelPreview
              street={values.consigneeStreet}
              address2={values.consigneeAddress2}
              postalCode={values.consigneePostalCode}
              city={values.consigneeCity}
              country={values.consigneeCountry}
              emergencyPhoneNumber={values.consigneeEmergencyPhoneNumber}
            />
          </Box>
        ) : (
          <></>
        )}

        <Box>
          {values.unNumber !== '' ? (
            <Box sx={{ fontSize: 66, fontWeight: 500 }} mt={4}>
              UN {values.unNumber}
            </Box>
          ) : (
            ''
          )}
          {values.unNumber !== '' ? (
            <div>
              {values?.psn?.value != '0' ? values?.psn?.label : ''}{' '}
              {values.technicalName !== ''
                ? '(' + values.technicalName + ')'
                : ''}
            </div>
          ) : (
            ''
          )}
          {values.netQuantity !== '' ? (
            <div>
              {i18nMessages.netQuantity}: {values.netQuantity}
            </div>
          ) : (
            ''
          )}
        </Box>
      </Box>
    ) : (
      <></>
    );
  };

  const ShipperDetail = () => {
    return (
      <>
        <Grid item lg={12} md={12}>
          <CustomizedInputs
            margin="normal"
            displayLabel={i18nMessages.shipper}
            id="labelShipperName"
            inputType="text"
            inputProps={{ maxLength: 500 }}
            handleChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) =>
              setValues({
                ...values,
                labelShipperName: event.target.value,
              })
            }
            value={values.labelShipperName}
            multiline={false}
            errorValue={errors.shipper}
            errorMessage={errors.shipper}
            required={true}
            readOnly={!canEdit}
          ></CustomizedInputs>
        </Grid>
        <Grid item xs={6}>
          <CustomizedPhone
            title={i18nMessages.EmergencyPhoneNumber}
            value={values.shipperEmergencyPhoneNumber}
            name="shipperEmergencyPhoneNumber"
            onChange={(phone: any) =>
              setValues({
                ...values,
                shipperEmergencyPhoneNumber: phone?.replace(/[^\d\+]/g, ''),
              })
            }
            disabled={!canEdit}
          ></CustomizedPhone>
        </Grid>
        <Grid item xs={12}>
          <CustomizedInputs
            margin="normal"
            displayLabel={i18nMessages.Address}
            id="shipperAddress"
            inputType="text"
            inputProps={{ maxLength: 500 }}
            handleChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) =>
              setValues({
                ...values,
                shipperStreet: event.target.value,
              })
            }
            value={values.shipperStreet}
            multiline={false}
            errorValue={errors.shipperStreet}
            errorMessage={errors.shipperStreet}
            required={true}
            readOnly={!canEdit}
          ></CustomizedInputs>
        </Grid>
        <Grid item xs={12}>
          <CustomizedInputs
            margin="normal"
            displayLabel={i18nMessages.Address2}
            id="shipperAddress2"
            inputType="text"
            inputProps={{ maxLength: 250 }}
            handleChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) =>
              setValues({
                ...values,
                shipperAddress2: event.target.value,
              })
            }
            value={values.shipperAddress2}
            multiline={false}
            readOnly={!canEdit}
          ></CustomizedInputs>
        </Grid>
        <Grid item xs={6}>
          <CustomizedInputs
            margin="normal"
            displayLabel={i18nMessages.PostalCode}
            id="shipperPostalCode"
            inputType="text"
            inputProps={{ maxLength: 20 }}
            handleChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) =>
              setValues({
                ...values,
                shipperPostalCode: event.target.value,
              })
            }
            handleBlur={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => onBlurZipCode(event, ZipCodeTriggeredFrom.Shipper)}
            value={values.shipperPostalCode}
            multiline={false}
            errorValue={errors.shipperPostalCode}
            errorMessage={errors.shipperPostalCode}
            required={true}
            readOnly={!canEdit}
          ></CustomizedInputs>
        </Grid>
        <Grid item xs={6}>
          <div className="add-button-address">
            <CustomSelectPaginateAdd
              additional={defaultAdditional}
              loadOptions={loadCitiesOptions}
              handleChange={(newValue: BaseModel) =>
                setValues({
                  ...values,
                  shipperCity: newValue,
                })
              }
              value={values.shipperCity}
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base: any) => ({
                  ...base,
                  zIndex: 9999,
                }),
              }}
              placeholder={defaultDropDownValue.label}
              isSearchable={true}
              displayLabel={i18nMessages.City}
              errorValue={true}
              errorMessage={errors.shipperCity}
              readOnly={!canEdit}
              debounceTimeout={500}
              isMultiSelect={false}
              cacheUniqs={cacheUniq}
              onCreateOption={(inputValue: any) =>
                onCreateShipperCityOption(inputValue)
              }
              required={true}
            ></CustomSelectPaginateAdd>
          </div>
        </Grid>
        <Grid item xs={6}>
          <CustomizedSelect
            name="shipperCountry"
            placeholder={defaultDropDownValue.label}
            options={countryList}
            isSearchable={true}
            displayLabel={i18nMessages.Country}
            handleChange={(newValue: BaseModel) =>
              setValues({
                ...values,
                shipperCountry: newValue,
              })
            }
            value={values.shipperCountry}
            errorValue={true}
            errorMessage={errors.shipperCountry}
            required={true}
            readOnly={!canEdit}
          ></CustomizedSelect>
        </Grid>
      </>
    );
  };

  const ConsigneeDetail = () => {
    return (
      <>
        <Grid item lg={12} md={12}>
          <CustomizedInputs
            margin="normal"
            displayLabel={i18nMessages.Consignee}
            id="labelConsigneeName"
            inputType="text"
            handleChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) =>
              setValues({
                ...values,
                labelConsigneeName: event.target.value,
              })
            }
            value={values.labelConsigneeName}
            multiline={false}
            errorValue={errors.labelConsignee}
            errorMessage={errors.labelConsignee}
            required={true}
            readOnly={!canEdit}
          ></CustomizedInputs>
        </Grid>
        <Grid item xs={6}>
          <CustomizedPhone
            title={i18nMessages.EmergencyPhoneNumber}
            value={values.consigneeEmergencyPhoneNumber}
            name="consigneeEmergencyPhoneNumber"
            onChange={(phone: any) =>
              setValues({
                ...values,
                consigneeEmergencyPhoneNumber: phone?.replace(/[^\d\+]/g, ''),
              })
            }
            disabled={!canEdit}
          ></CustomizedPhone>
        </Grid>
        <Grid item xs={12}>
          <CustomizedInputs
            margin="normal"
            displayLabel={i18nMessages.Address}
            id="consigneeAddress"
            inputType="text"
            inputProps={{ maxLength: 500 }}
            handleChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) =>
              setValues({
                ...values,
                consigneeStreet: event.target.value,
              })
            }
            value={values.consigneeStreet}
            multiline={false}
            errorValue={errors.consigneeStreet}
            errorMessage={errors.consigneeStreet}
            required={true}
            readOnly={!canEdit}
          ></CustomizedInputs>
        </Grid>
        <Grid item xs={12}>
          <CustomizedInputs
            margin="normal"
            displayLabel={i18nMessages.Address2}
            id="consigneeAddress2"
            inputType="text"
            inputProps={{ maxLength: 250 }}
            handleChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) =>
              setValues({
                ...values,
                consigneeAddress2: event.target.value,
              })
            }
            value={values.consigneeAddress2}
            multiline={false}
            readOnly={!canEdit}
          ></CustomizedInputs>
        </Grid>
        <Grid item xs={6}>
          <CustomizedInputs
            margin="normal"
            displayLabel={i18nMessages.PostalCode}
            id="consigneePostalCode"
            inputType="text"
            inputProps={{ maxLength: 20 }}
            handleChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) =>
              setValues({
                ...values,
                consigneePostalCode: event.target.value,
              })
            }
            handleBlur={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => onBlurZipCode(event, ZipCodeTriggeredFrom.Consignee)}
            value={values.consigneePostalCode}
            multiline={false}
            errorValue={errors.consigneePostalCode}
            errorMessage={errors.consigneePostalCode}
            required={true}
            readOnly={!canEdit}
          ></CustomizedInputs>
        </Grid>
        <Grid item xs={6}>
          <div className="add-button-address">
            <CustomSelectPaginateAdd
              additional={defaultAdditional}
              loadOptions={loadCitiesOptions}
              handleChange={(newValue: BaseModel) =>
                setValues({
                  ...values,
                  consigneeCity: newValue,
                })
              }
              value={values.consigneeCity}
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base: any) => ({
                  ...base,
                  zIndex: 9999,
                }),
              }}
              placeholder={defaultDropDownValue.label}
              isSearchable={true}
              displayLabel={i18nMessages.City}
              errorValue={true}
              errorMessage={errors.consigneeCity}
              readOnly={!canEdit}
              debounceTimeout={500}
              isMultiSelect={false}
              cacheUniqs={cacheUniq}
              onCreateOption={(inputValue: any) =>
                onCreateConsigneeCityOption(inputValue)
              }
              required={true}
            ></CustomSelectPaginateAdd>
          </div>
        </Grid>
        <Grid item xs={6}>
          <CustomizedSelect
            name="consigneeCountry"
            placeholder={defaultDropDownValue.label}
            options={countryList}
            isSearchable={true}
            displayLabel={i18nMessages.Country}
            handleChange={(newValue: BaseModel) =>
              setValues({
                ...values,
                consigneeCountry: newValue,
              })
            }
            value={values.consigneeCountry}
            errorValue={true}
            errorMessage={errors.consigneeCountry}
            required={true}
            readOnly={!canEdit}
          ></CustomizedSelect>
        </Grid>
      </>
    );
  };

  const OtherDetail = () => {
    return (
      <>
        <Grid item lg={8} md={8}>
          <CustomizedInputs
            displayLabel={i18nMessages.unNumber}
            handleChange={(newValue: any) => {
              setValues({
                ...values,
                unNumber: newValue.target.value,
              });
            }}
            value={values.unNumber}
            errorValue={true}
            errorMessage={errors.unNumber}
            readOnly={!canEdit}
          ></CustomizedInputs>
        </Grid>
        {canEdit ? (
          <Grid item lg={4} md={4}>
            <Box sx={{ mt: 2.5 }}>
              <Button
                variant="contained"
                onClick={getPsnOptionsList}
                disabled={!canEdit}
                className="ml-2"
              >
                {i18nMessages.extractDGPlus}
              </Button>
            </Box>
          </Grid>
        ) : (
          <></>
        )}
        <Grid item lg={12} md={12}>
          <CustomizedSelect
            placeholder={i18nMessages.SelectPSN}
            options={psnOptions}
            isSearchable={false}
            displayLabel={i18nMessages.PSN}
            value={values.psn}
            handleChange={(newValue: BaseModel) => {
              onPsnValueChange(newValue);
              if (newValue.value !== '0') {
                setErrors({
                  ...errors,
                  layoutType: '',
                });
              }
            }}
            readOnly={!canEdit}
          ></CustomizedSelect>
        </Grid>
        <Grid item lg={12} md={12}>
          <CustomizedInputs
            displayLabel={i18nMessages.technicalName}
            handleChange={(newValue: any) => {
              setValues({
                ...values,
                technicalName: newValue.target.value,
              });
            }}
            value={values.technicalName}
            errorValue={true}
            errorMessage={errors.technicalName}
            readOnly={!canEdit}
          ></CustomizedInputs>
        </Grid>
        <Grid item lg={12} md={12}>
          <CustomizedInputs
            displayLabel={i18nMessages.netQuantity}
            inputProps={{ maxLength: 10 }}
            name="netQuantity"
            handleChange={(event: any) => {
              setValues({
                ...values,
                netQuantity: event.target.value,
              });
            }}
            errorValue={true}
            value={values.netQuantity}
            readOnly={!canEdit}
          ></CustomizedInputs>
        </Grid>
      </>
    );
  };

  useEffect(() => {
    getCountries();
    //getCities();
  }, []);

  return (
    <div>
      <>
        <Grid container spacing={2} className="grid-wrap">
          <Grid lg={3} md={3}>
            <List>
              <ListItemButton
                sx={{
                  border: '1px solid black',
                  borderRadius: 2,
                }}
                onClick={handleCreateLabelClick}
                disabled={!canEdit}
              >
                <ListItemText
                  sx={{ textAlign: 'center' }}
                  primary={i18nMessages.createLabels}
                />
              </ListItemButton>
              <Collapse
                in={showCreateLabelOptions}
                timeout="auto"
                unmountOnExit
              >
                <List sx={{ pl: 1 }} disablePadding>
                  {entityCreateLabelOptions.map((item) => (
                    <ListItemButton
                      key={item.value}
                      onClick={() => handleListItemClick(item)}
                    >
                      <ListItemText primary={item.label} />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            </List>
          </Grid>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6}>
              {values.labelType?.value == CreateLabelOption.Shipping ? (
                <Grid container spacing={2} className="grid-wrap two-col">
                  <Grid item lg={12} md={12}>
                    <CustomizedSelect
                      placeholder={defaultValue.label}
                      options={entityCreateLabelLayouts}
                      value={values.layoutType}
                      isSearchable={false}
                      displayLabel={i18nMessages.layoutType}
                      handleChange={(newValue: BaseModel) => {
                        onLayoutTypeChange(newValue);
                        if (newValue.value !== '0') {
                          setErrors({
                            ...errors,
                            layoutType: '',
                          });
                        }
                      }}
                      errorValue={true}
                      errorMessage={errors.layoutType}
                      required
                      readOnly={!canEdit}
                    ></CustomizedSelect>
                  </Grid>
                  <Grid item lg={12} md={12}>
                    <FormAccordian
                      title={i18nMessages.ShipperDetailHeader}
                      details={ShipperDetail()}
                      expanded={true}
                      addMode={true}
                    ></FormAccordian>
                  </Grid>
                  <Grid item lg={12} md={12}>
                    <FormAccordian
                      title={i18nMessages.ConsigneeDetailHeader}
                      details={ConsigneeDetail()}
                      expanded={true}
                      addMode={true}
                    ></FormAccordian>
                  </Grid>
                  <Grid item lg={12} md={12}>
                    <FormAccordian
                      title={i18nMessages.OtherDetailHeader}
                      details={OtherDetail()}
                      expanded={true}
                      addMode={true}
                    ></FormAccordian>
                  </Grid>
                </Grid>
              ) : (
                <></>
              )}
              {values.labelType.value == CreateLabelOption.WarehouseLabel ? (
                <Grid container spacing={2}>
                  <Grid item lg={12} md={12} mt={2}>
                    <CustomizedInputs
                      displayLabel={i18nMessages.myHazGoRef}
                      handleChange={(newValue: any) => {
                        setValues({
                          ...values,
                          myHazGoRef: newValue.target.value,
                        });
                      }}
                      value={values.myHazGoRef}
                      errorValue={true}
                      errorMessage={errors.myHazGoRef}
                      readOnly={true}
                    ></CustomizedInputs>
                  </Grid>
                  <Grid item lg={12} md={12}>
                    <CustomizedInputs
                      displayLabel={i18nMessages.shipper}
                      value={values.shipper}
                      readOnly={true}
                    ></CustomizedInputs>
                  </Grid>
                  <Grid item lg={12} md={12}>
                    <Grid item lg={10} md={10}>
                      <CustomizedInputs
                        displayLabel={i18nMessages.DecPackCustomer}
                        value={values.customer.label}
                        readOnly={true}
                      ></CustomizedInputs>
                    </Grid>
                  </Grid>
                  {showFinalDestination ? (
                    <Grid item lg={12} md={12}>
                      <CustomizedInputs
                        displayLabel={i18nMessages.FinalDestination}
                        value={values.finalDestinationName}
                        readOnly={true}
                      ></CustomizedInputs>
                    </Grid>
                  ) : (
                    <></>
                  )}
                  <Grid item lg={12} md={12}>
                    <CustomizedInputs
                      displayLabel={i18nMessages.DecPackCustomerRef}
                      handleChange={(newValue: any) => {
                        setValues({
                          ...values,
                          customerRef: newValue.target.value,
                        });
                      }}
                      value={values.customerRef}
                      errorValue={true}
                      errorMessage={errors.customerRef}
                      readOnly={true}
                    ></CustomizedInputs>
                  </Grid>
                  <Grid item lg={12} md={12}>
                    <NumericInput
                      displayLabel={i18nMessages.numberOfPieces}
                      handleChange={(newValue: any) => {
                        setValues({
                          ...values,
                          numberOfPieces: newValue.target.value,
                        });
                      }}
                      value={values.numberOfPieces}
                      errorValue={true}
                      disabled={!canEdit}
                      allowNegative={false}
                    />
                  </Grid>
                </Grid>
              ) : (
                <></>
              )}
              {values.labelType.value == CreateLabelOption.Overpack ? (
                <Grid item lg={12} md={12}>
                  <CustomizedInputs
                    displayLabel={i18nMessages.overPackId}
                    inputProps={{ maxLength: 10 }}
                    name="overPackId"
                    handleChange={(newValue: any) => {
                      setValues({
                        ...values,
                        overPackId: newValue.target.value,
                      });
                    }}
                    value={values.overPackId}
                    errorValue={true}
                    readOnly={!canEdit}
                  ></CustomizedInputs>
                  <CustomizedInputs
                    displayLabel={i18nMessages.totalQuantity}
                    inputProps={{ maxLength: 20 }}
                    name="totalQuantity"
                    handleChange={(event: any) => {
                      setValues({
                        ...values,
                        totalQuantity: event.target.value,
                      });
                    }}
                    value={values.totalQuantity}
                    errorValue={true}
                    readOnly={!canEdit}
                  ></CustomizedInputs>
                </Grid>
              ) : (
                ''
              )}
              {values.labelType.value == CreateLabelOption.Other ? (
                <Grid item lg={12} md={12}>
                  <CustomizedInputs
                    displayLabel={i18nMessages.otherLabelFreeText}
                    handleChange={(newValue: any) => {
                      setValues({
                        ...values,
                        otherLabelText: newValue.target.value,
                      });
                    }}
                    value={values.otherLabelText}
                    errorValue={true}
                    errorMessage={errors.otherLabelText}
                    readOnly={!canEdit}
                  ></CustomizedInputs>
                </Grid>
              ) : (
                ''
              )}
            </Grid>
            <Grid item lg={1} md={1} />
            <Grid item lg={4} md={4}>
              {values.layoutType.value == CreateLabelLayout.Zebra ? (
                <Card sx={{ border: 2 }}>
                  <Grid>
                    {values.labelType.value == CreateLabelOption.Overpack ? (
                      <Box mr={3} ml={3} sx={{ fontWeight: 900 }}>
                        <Box sx={{ fontSize: 66, fontWeight: 500 }} mt={12}>
                          <div>OVERPACK</div>
                        </Box>
                        <Box sx={{ fontSize: 30 }} mt={4}>
                          {values.overPackId ? (
                            <div>#{values.overPackId}</div>
                          ) : (
                            ''
                          )}
                          {values.totalQuantity ? (
                            <div>
                              {i18nMessages.totalQuantity}:{' '}
                              {values.totalQuantity}
                            </div>
                          ) : (
                            ''
                          )}
                        </Box>
                      </Box>
                    ) : (
                      ''
                    )}

                    <ZebraShippingLabelPreview />
                  </Grid>
                </Card>
              ) : (
                ''
              )}

              {values.layoutType.value == CreateLabelLayout.Dymo ? (
                <Card sx={{ border: 2 }}>
                  <Grid>
                    {values.labelType.value == CreateLabelOption.Other ? (
                      values.otherLabelText ? (
                        <Box sx={{ fontSize: 24, fontWeight: 900 }} m={2}>
                          <div>{values.otherLabelText}</div>
                        </Box>
                      ) : (
                        ''
                      )
                    ) : (
                      ''
                    )}
                    {values.labelType.value == CreateLabelOption.Shipping ? (
                      <>
                        <DymoShippingLabelPreview />
                      </>
                    ) : (
                      <DymoShippingLabelPreview />
                    )}
                  </Grid>
                </Card>
              ) : (
                ''
              )}

              <Box m={2} display="flex" justifyContent="center">
                {values.labelType !== '' && values.layoutType !== '' ? (
                  <Button
                    variant="contained"
                    startIcon={<PrintIcon />}
                    onClick={handlePrintClick}
                  >
                    Print
                  </Button>
                ) : (
                  ''
                )}
              </Box>
            </Grid>
            <Grid item lg={2} md={2}>
              {canEdit ? (
                <Button
                  color="primary"
                  className="small-btn"
                  disableElevation
                  variant="contained"
                  onClick={handleSaveClick}
                  sx={{
                    right: 8,
                  }}
                >
                  {i18nMessages.Save}
                </Button>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </Grid>
      </>

      <iframe
        id="printMe"
        title="printMe"
        style={{ display: 'none', fontFamily: 'Sofia Pro' }}
      ></iframe>
    </div>
  );
}

export default ShippingLabels;
