import {
  Box,
  Breadcrumbs,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Typography,
} from '@mui/material';
import DocumentList from 'common/Document/DocumentList';
import FormAccordianSave from 'common/formControl/formAccordianSave';
import environment from 'environment';
import {
  EntityTypeEnum,
  DocumentEntity,
  BaseModel,
  DGDDescription,
  ActivityOwner,
  OrderType,
} from 'models/pagination-model';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import I18n from 'utilities/i18n';
import http from '../../../utilities/httpService';
import Status from '../../../common/WoStatus/Status';
import Link from '@mui/material/Link';
import RouteEnum from 'models/RouteEnum';
import { hasPermission } from 'utilities/protectedRoute';
import { Permission } from 'Permissions';
import CreatedByandUpdatedBy from 'common/CreatedByandUpdatedBy/CreatedByandUpdatedBy';
import { Utilities } from 'utilities/Utilities';

function DGDCreation() {
  let navigate = useNavigate();
  const defaultAdditional: any = {
    page: 1,
  };
  const defaultValueForAssignment = {
    value: '0',
    label: I18n('Placeholders.Select'),
  } as BaseModel;
  const [selectAssignment, setSelectAssignment] = useState(
    defaultValueForAssignment
  );
  const customerPortalUser = Utilities.getIsCPUser();
  const breadcrumbs = [
    <Typography key="3" color="text.primary">
      {I18n('Nav.Order')}
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={() => navigate(RouteEnum.ShippingOrdersList)}
        className="cpointer"
      >
        {I18n('Nav.ShippingOrders')}
      </Link>
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={() => navigate(RouteEnum.ViewShippingOrders + '/' + id + '/2')}
        className="cpointer"
      >
        {I18n('ShippingOrders.WorkOrders')}
      </Link>
    </Typography>,
  ];
  const { woId, id } = useParams();
  const defaultValue = {
    value: '0',
    label: I18n('Placeholders.Select'),
  } as BaseModel;
  const i18nMessages = {
    Information: I18n('ShippingOrders.Information'),
    Document: I18n('ShippingOrders.Document'),
    Status: I18n('ShippingOrders.Status'),
    DGPlus: I18n('ShippingOrders.DGPlus'),
    CreateDGD: I18n('ShippingOrders.CreateDGD'),
    AllDataAvailable: I18n('ShippingOrders.AllDataAvailable'),
    statusSavedSuccess: I18n('Common.SavedSuccessfully'),
    commonFieldIsRequired: I18n('Common.FieldIsRequired'),
    Title: I18n('Common.WODocumentation'),
    assignedNextTo: I18n('WorkorderPickup.AssignedNextTo'),
  };
  const [canEdit, setCanEdit] = useState(
    hasPermission(Permission.canEditShippingOrders)
  );
  const [isStatusSaveCallApi, setIsStatusSaveCallApi] =
    useState(false); /* To call Submit APi Status from Status Component */
  const [validateStatus, setValidateStatus] = useState<boolean>(false);
  const [values, setValues] = useState<any>({
    hazGoReference: '',
    availableData: '',
    status: 0,
    createdBy: '',
    createdDate: '',
    updatedBy: '',
    updatedDate: '',
  });

  const [errors, setErrors] = useState({
    availableData: '',
  });

  let entityWorkOrderStatusList: Array<BaseModel> = [];
  const getStatusList = () => {
    entityWorkOrderStatusList = [defaultValue];
    DGDDescription.forEach((value: string, key: number) => {
      entityWorkOrderStatusList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getStatusList();

  const onAvailableChange = (data: any) => {
    setValues({ ...values, availableData: Number(data?.value) });
  };

  const openDgplusScreen = async (copyMe: any) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      window.open(
        'http://dgplus2.nl/',
        '_blank',
        'toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=4000,height=4000'
      );
    } catch (err) {
      console.log('Error in copy');
    }
  };

  const openLabelTab = async (copyMe: any) => {
    try {
      localStorage.setItem('ReturnWoNo', woId ? woId?.toString() : '');
      navigate(RouteEnum.ViewShippingOrders + '/' + id + '/6');
    } catch (err) {
      console.log('Error in copy');
    }
  };

  const InformationData = () => {
    return (
      <>
        <Grid item lg={3} md={6}>
          <FormControl>
            <InputLabel shrink htmlFor="bootstrap-input">
              {i18nMessages.CreateDGD}
            </InputLabel>
            <Box sx={{ mt: 3 }}>
              <Button
                variant="contained"
                onClick={() => openDgplusScreen(values.hazGoReference)}
                disabled={!canEdit}
              >
                {i18nMessages.DGPlus}
              </Button>
            </Box>
          </FormControl>
          {!customerPortalUser && (
            <FormControl>
              <InputLabel shrink htmlFor="bootstrap-input"></InputLabel>
              <Box sx={{ mt: 3, ml: 3 }}>
                <Button
                  variant="contained"
                  onClick={() => openLabelTab(values.hazGoReference)}
                  disabled={!canEdit}
                >
                  {I18n('ShippingOrders.Tabs.Labels')}
                </Button>
              </Box>
            </FormControl>
          )}
        </Grid>
      </>
    );
  };

  const DocumentData = () => {
    return (
      <Grid xl={12} md={12}>
        <DocumentList
          permission={Permission.canEditShippingOrders}
          addPermission={Permission.canAddShippingOrders}
          deletePermission={Permission.canDeleteShippingOrders}
          entityType={EntityTypeEnum.ShippingOrders}
          paramsId={id}
          isHeaderHide={true}
          documentEntityType={DocumentEntity.ShippingOrders}
        />
      </Grid>
    );
  };

  const validate = (fieldValues: any = values) => {
    let temp = { ...errors };
    if ('availableData' in fieldValues) {
      temp.availableData = fieldValues.availableData
        ? ''
        : i18nMessages.commonFieldIsRequired;
    }

    setErrors({
      ...temp,
    });
    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === '');
    }
  };

  const StatusAPICall = async () => {
    const finalValue = {
      workOrderNo: Number(woId),
      status: 3,
      shippingOrderId: Number(id),
      woDate: new Date().toISOString(),
      activityOwner: ActivityOwner.BackOffice,
    };
    const apiUrl = new URL(environment.api.baseUrl + 'Shipping/UpdateWOStatus');
    try {
      const result: any = await http.put(apiUrl.toString(), finalValue);
      if (result) {
        window.location.reload();
        // getShippingWO();
      }
    } catch (error: any) {}
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setValidateStatus(true);
  };

  const getShippingWO = async () => {
    let shippingOrderId = Number(id);
    let WOID = Number(woId);

    const apiUrl = new URL(
      environment.api.baseUrl +
        'Shipping/GetShippingWO/' +
        shippingOrderId +
        '/' +
        WOID
    );
    try {
      const result = await http.get(apiUrl.toString());
      if (result) {
        setValues({
          ...values,
          availableData: result.data.isDGCompleted === true ? 1 : 2,
          status: result.data.status,
          hazGoReference: result.data?.hazGoReference,
          createdBy: result.data.createdBy,
          updatedBy: result.data.updatedBy,
          createdDate: result.data.createdDate,
          updatedDate: result.data.updatedDate,
        });

        if (result.data.assignedNextToName && result.data.assignedNextToId) {
          let data = {
            label: result.data.assignedNextToName,
            value: result.data.assignedNextToId,
          };
          setSelectAssignment(data);
        }
      }
    } catch (error) {}
  };
  useEffect(() => {
    getShippingWO();
  }, []);

  const handleSuccessStatusSave = async () => {
    if (validate()) {
      setIsStatusSaveCallApi(true);

      const finalValue = {
        workOrderNo: Number(woId),
        isDGCompleted: values.availableData === 1 ? true : false,
        shippingOrderId: Number(id),
      };

      const apiUrl = new URL(environment.api.baseUrl + 'Shipping/UpdateWODGD');
      try {
        const result: any = await http.put(apiUrl.toString(), finalValue);
        if (result) {
          toast.success(i18nMessages.statusSavedSuccess);
          handleErrorStatusSave(false);
          navigate(RouteEnum.ViewShippingOrders + '/' + id + '/2');
        }
      } catch (error: any) {}
    } else {
      handleErrorStatusSave(false); // To set Validation and Submit APi Status False
    }
  };

  /* Validate Status Dropdown Error call from Status Component */
  const handleErrorStatusSave = (data: any) => {
    setValidateStatus(false);
    setIsStatusSaveCallApi(false);
  };

  return (
    <div>
      <div className="heading-section">
        <div className="heading-section-left">
          <Typography variant="h3" sx={{ mb: 3 }}>
            {i18nMessages.Title}
          </Typography>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>
      </div>

      <div className="main-content-section">
        <FormAccordianSave
          title={i18nMessages.Information}
          className="inner-heading"
          details={InformationData()}
        ></FormAccordianSave>
        <FormAccordianSave
          title={i18nMessages.Document}
          className="inner-heading"
          details={DocumentData()}
        ></FormAccordianSave>
        <Status
          permission={Permission.canEditShippingOrders}
          woId={woId}
          orderId={id}
          statusValue={values.status}
          assignedToId={''}
          assignedToName={values.assignedTo}
          activityOwner={ActivityOwner.BackOffice}
          orderType={OrderType.ShippingOrder}
          isStatusSaveCallApi={isStatusSaveCallApi}
          validateStatus={validateStatus}
          handleSuccessStatusSave={handleSuccessStatusSave}
          handleErrorStatusSave={handleErrorStatusSave}
          AssignedNextToId={
            selectAssignment.value != '0' ? selectAssignment?.value : ''
          }
          AssignedNextToName={
            selectAssignment.value != '0' ? selectAssignment?.label : ''
          }
          handleStatusChange={(value: number) =>
            setValues({ ...values, status: value })
          }
        />
        <div className="btn-wrap form-btn-wrap">
          {canEdit ? (
            <Button
              type="submit"
              color="primary"
              disableElevation
              variant="contained"
              onClick={handleSubmit}
            >
              {I18n('Common.Save')}
            </Button>
          ) : (
            <></>
          )}
        </div>
        <CreatedByandUpdatedBy
          createdBy={values.createdBy}
          createdDate={values.createdDate}
          updatedBy={values.updatedBy}
          updatedDate={values.updatedDate}
        ></CreatedByandUpdatedBy>
      </div>
    </div>
  );
}

export default DGDCreation;
