import { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import I18n from 'utilities/i18n';
import CustomizedInputs from 'common/formControl/formControl';
import { Grid } from '@mui/material';

export default function DecPackMaterialQuantityDetailsPopup(props: any) {
  const {
    isOpen,
    handleClose,
    title,
    wareHouseQuantity,
    requestedQuantity,
    threshold,
  } = props;

  const [values, setValues] = useState({
    wareHouseQuantity: '',
    requestedQuantity: '',
    deltaQuantity: '',
    threshold: '',
  });

  const i18nMessages = {
    Cancel: I18n('Common.Cancel'),
    WareHouseQuantity: I18n('ShippingMaterials.WareHouseQuantity'),
    RequestedQuantity: I18n('ShippingMaterials.RequestedQuantity'),
    Delta: I18n('ShippingMaterials.Delta'),
    Threshold: I18n('ShippingMaterials.Threshold'),
  };

  useEffect(() => {
    if (requestedQuantity != undefined && wareHouseQuantity != undefined) {
      setValues({
        ...values,
        wareHouseQuantity,
        requestedQuantity,
        threshold,
        deltaQuantity: (
          Number(wareHouseQuantity) - Number(requestedQuantity)
        ).toString(),
      });
    }
  }, [requestedQuantity, wareHouseQuantity]);

  const resetClose = () => {
    handleClose();
  };
  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={resetClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={'sm'}
        fullWidth={true}
      >
        <DialogTitle>
          {title}
          <IconButton
            aria-label="close"
            onClick={resetClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className="grid-wrap two-col">
            <Grid item xs={6}>
              <CustomizedInputs
                margin="normal"
                displayLabel={i18nMessages.WareHouseQuantity}
                name="wareHouseQuantity"
                id="wareHouseQuantity"
                inputType="text"
                value={values?.wareHouseQuantity}
                readOnly={true}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomizedInputs
                margin="normal"
                displayLabel={i18nMessages.RequestedQuantity}
                name="requestedQuantity"
                id="requestedQuantity"
                inputType="text"
                value={values?.requestedQuantity}
                readOnly={true}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomizedInputs
                margin="normal"
                displayLabel={i18nMessages.Delta}
                name="delta"
                id="delta"
                inputType="text"
                value={values?.deltaQuantity}
                readOnly={true}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomizedInputs
                margin="normal"
                displayLabel={i18nMessages.Threshold}
                name="threshold"
                id="threshold"
                inputType="text"
                value={values?.threshold}
                readOnly={true}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
