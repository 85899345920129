import Typography from '@mui/material/Typography';
import I18n from 'utilities/i18n';
import './Notes.scss';
import { useEffect, useCallback, useState } from 'react';
import http from '../../utilities/httpService';
import {
  BaseModel,
  EntityTypeEnum,
  IsInternalOnlyDescription,
  OrderByDirection,
} from 'models/pagination-model';
import { Button, Grid } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InputAdornment from '@mui/material/InputAdornment';
import CustomizedInputs from 'common/formControl/formControl';
import environment from 'environment';
import { toast } from 'react-toastify';
import {
  GridActionsCellItem,
  GridColumns,
  GridPaginationInitialState,
  GridRenderEditCellParams,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridRowsProp,
  GridSortingInitialState,
  GridSortModel,
  GridToolbarContainer,
  MuiEvent,
  useGridApiContext,
} from '@mui/x-data-grid';
import ConfirmationPopup from 'common/confirmationPopup/confirmationPopup';
import moment from 'moment';
import { hasPermission } from 'utilities/protectedRoute';
import CustomDataGridInline from 'common/datagrid/CustomDataGridInline';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import Checkbox from '@mui/material/Checkbox';
import { Utilities } from 'utilities/Utilities';

function Note(props: any) {
  const {
    addPermission = '',
    deletePermission = '',
    isViewOnly = false,
  } = props;
  const [noteDescription, setNoteDescription] = useState('');
  const [isInternal, setIsInternalOnly] = useState(false);
  const [isDeleteModalOpen, setDeleteModal] = useState(false);
  const [isAddModalOpen, setAddModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [selectedId, setId] = useState();
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [refreshGrid, setRefreshGrid] = useState(false);
  const [gridData, setGridData] = useState({
    sortOrder: OrderByDirection.Descending,
    sortBy: 'CreatedDate',
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [10, 20, 50, 100],
    pageSize: 10,
    page: 0,
    searchExpression: '',
  });
  const customerPortalUser = Utilities.getIsCPUser();
  const [canEdit, setCanEdit] = useState(hasPermission(props.permission));
  const [canAdd, setCanAdd] = useState(hasPermission(addPermission));
  const [canDelete, setCanDelete] = useState(hasPermission(deletePermission));
  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));
  const getNotes = async () => {
    const apiUrl = new URL(environment.api.baseUrl + 'Notes');
    try {
      apiUrl.searchParams.set(
        '$filter',
        gridData.searchExpression != ''
          ? `EntityId~=~${props.paramsId} and EntityType~=~${props.entityType} and Description~like~${gridData.searchExpression}`
          : `EntityId~=~${props.paramsId} and EntityType~=~${props.entityType}`
      );
      apiUrl.searchParams.set('$orderby', gridData.sortBy);
      apiUrl.searchParams.set(
        '$skip',
        (gridData.page * gridData.pageSize).toString()
      );
      apiUrl.searchParams.set('$top', gridData.pageSize.toString());
      apiUrl.searchParams.set(
        '$orderbydirection',
        gridData.sortOrder.toString()
      );
      const result = await http.get(apiUrl.toString());
      if (result) {
        updateGridData('totalRows', result.data.totalCount);
        updateGridData('rows', result.data.values);
      }
      if (
        props.entityType == EntityTypeEnum.ShippingOrders &&
        props.refreshCount
      ) {
        props.refreshCount();
      }
    } catch (error) {}
  };

  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    if (!params.row.isManual) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleSaveClick = (id: GridRowId) => () => {
    updateGridData(
      'rows',
      gridData.rows.filter(function (row: any) {
        return row.id !== 0;
      })
    );
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const keydown = (event: any) => {
    if (event.altKey && event.keyCode == 65) {
      var addButton = document.getElementById('addRecord');
      addButton?.click();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', keydown, false);
    getNotes();
  }, [
    gridData.page,
    gridData.pageSize,
    gridData.sortBy,
    gridData.sortOrder,
    gridData.searchExpression,
    refreshGrid,
  ]);

  const handleChangePage = (pageNo: number) => {
    updateGridData('page', pageNo);
  };

  const handleChangeBasicSearch = (searchExpression: any) => {
    updateGridData('searchExpression', searchExpression.target.value);
  };
  const handleChangePageSize = (pageSize: number) => {
    updateGridData('pageSize', pageSize);
  };
  type Row = (typeof gridData.rows)[number];

  const deleteNote = (id: any) => {
    setDeleteModal(true);
    setId(id);
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    deleteNote(id);
  };

  const columns: GridColumns<Row> = [
    {
      field: 'description',
      headerName: I18n('Notes.Description'),
      flex: 1,
      sortable: true,
      editable: true,
      renderEditCell: (params) => {
        return <NoteEditComponent {...params} />;
      },
    },
    {
      field: 'isInternalOnly',
      headerName: I18n('Notes.IsInternalOnly'),
      minWidth: 150,
      sortable: true,
      editable: true,
      hide: customerPortalUser,
      renderEditCell: (params) => {
        return <InternalEditComponent {...params} />;
      },
      renderCell: (params) => {
        return params.formattedValue === true ? 'Yes' : 'No';
      },
    },
    {
      field: 'updatedBy',
      headerName: I18n('Notes.AddedBy'),
      width: 200,
    },
    {
      field: 'updatedDate',
      headerName: I18n('Notes.AddedOn'),
      width: 200,
      valueFormatter: (params) => moment(params?.value).format('DD/MM/YYYY'),
    },
    {
      field: 'actions',
      headerName: I18n('Common.Actions'),
      type: 'actions',
      width: 150,
      getActions: (params: any) => {
        const actionButtons = [];

        const isInEditMode =
          rowModesModel[params.id]?.mode === GridRowModes.Edit;
        if (isInEditMode && !isViewOnly) {
          actionButtons.push(
            <GridActionsCellItem
              onPointerEnterCapture={false}
              onPointerLeaveCapture={false}
              placeholder={''}
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(params.id)}
            />
          );
          actionButtons.push(
            <GridActionsCellItem
              onPointerEnterCapture={false}
              onPointerLeaveCapture={false}
              placeholder={''}
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(params.id)}
              color="inherit"
            />
          );
        } else {
          if (canEdit && !isViewOnly) {
            actionButtons.push(
              <GridActionsCellItem
                onPointerEnterCapture={false}
                onPointerLeaveCapture={false}
                placeholder={''}
                icon={<EditIcon />}
                label="Edit"
                className="textPrimary"
                onClick={handleEditClick(params.id)}
                color="inherit"
              />
            );
          }
          if (canDelete && !isViewOnly) {
            actionButtons.push(
              <GridActionsCellItem
                onPointerEnterCapture={false}
                onPointerLeaveCapture={false}
                placeholder={''}
                icon={<DeleteIcon />}
                label="Delete"
                onClick={handleDeleteClick(params.id)}
                color="inherit"
              />
            );
          }
        }
        return actionButtons;
      },
    },
  ];

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  let entityStatusList: Array<BaseModel> = [];
  const getStatusList = () => {
    entityStatusList = [];
    IsInternalOnlyDescription.forEach((value: string, key: number) => {
      entityStatusList.push({
        value: key.toString(),
        label: I18n(value),
      } as BaseModel);
    });
  };
  getStatusList();
  function InternalEditComponent(props: GridRenderEditCellParams) {
    const { id, value, field } = props;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const apiRef = useGridApiContext();

    const oncheckboxchange = (event: any) => {
      const { name, checked } = event.target;
      const newValue = event.target.checked;
      apiRef.current.setEditCellValue({ id, field, value: newValue });
      setIsInternalOnly(checked);
    };

    return (
      <Checkbox
        name="yes"
        id="yes"
        value={isInternal}
        checked={props.row.isInternalOnly}
        onChange={oncheckboxchange}
        inputProps={{ 'aria-label': 'controlled' }}
      />
    );
  }

  function NoteEditComponent(props: GridRenderEditCellParams) {
    const { id, value, field } = props;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const apiRef = useGridApiContext();

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      apiRef.current.setEditCellValue({ id, field, value: newValue });
    };
    return (
      <CustomizedInputs
        margin="normal"
        autoFocus={true}
        required
        id="Description"
        inputType="text"
        inputProps={{ maxLength: 500 }}
        handleChange={handleValueChange}
        value={props.row.description}
        multiline={false}
      ></CustomizedInputs>
    );
  }

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow: any = gridData.rows.find((row: any) => row.id === id);
    if (editedRow!.isNew) {
      updateGridData(
        'rows',
        gridData.rows.filter((row: any) => row.id !== id)
      );
    }
    getNotes();
  };

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    if (sortModel && sortModel.length) {
      updateGridData('sortBy', sortModel[0].field);
      updateGridData(
        'sortOrder',
        sortModel[0].sort === 'asc'
          ? OrderByDirection.Ascending
          : OrderByDirection.Descending
      );
    }
  }, []);

  const handleClose = () => {
    setDeleteModal(false);
  };

  const handleOk = () => {
    setDeleteModal(false);
    deleteData(selectedId);
  };

  const handleCloseForAdd = () => {
    setEditData(null);
    setAddModal(false);
  };

  const i18nMessages = {
    deletedSuccessfully: I18n('Notes.DeletedSuccessfully'),
    errorOccurred: I18n('Notes.ErrorOccurred'),
    yes: I18n('Common.Yes'),
    no: I18n('Common.No'),
    fieldRequired: I18n('ContactPerson.FieldRequired'),
    savedSuccessfully: I18n('Notes.SavedSuccessfully'),
  };

  const setModels = (newModel: any) => {
    if (newModel[0] === undefined) {
      setRowModesModel(newModel);
    } else if (
      newModel[0] !== undefined &&
      newModel[0].cellToFocusAfter === 'right'
    ) {
      updateGridData(
        'rows',
        gridData.rows.filter(function (row: any) {
          return row.id !== 0;
        })
      );
      setRowModesModel(newModel);
    }
  };

  interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (
      newModel: (oldModel: GridRowModesModel) => GridRowModesModel
    ) => void;
  }

  function EditToolbar(props: EditToolbarProps) {
    const handleClick = () => {
      var existingRow = gridData.rows.find((x: any) => x.id === 0);
      if (existingRow === undefined) {
        const id = 0;
        var temp: any = [];
        for (let i = 0; i < gridData.rows.length; i++) {
          temp.push(gridData.rows[i]);
        }
        temp.push({
          id: 0,
          description: '',
          isInternalOnly: false,
        });
        updateGridData('rows', temp);
        updateGridData('totalRows', temp.length);
        setRowModesModel((oldModel) => ({
          ...oldModel,
          [id]: {
            mode: GridRowModes.Edit,
            fieldToFocus: 'description',
          },
        }));
      }
    };

    return (
      <GridToolbarContainer>
        {canAdd && !isViewOnly ? (
          <Button
            color="primary"
            id="addRecord"
            startIcon={<AddIcon />}
            onClick={handleClick}
          >
            {I18n('Common.Add')}
          </Button>
        ) : (
          <></>
        )}
      </GridToolbarContainer>
    );
  }

  const validate = (fieldValues: any) => {
    if ('description' in fieldValues) {
      if (fieldValues.description === '') {
        return 'Note description ' + i18nMessages.fieldRequired;
      }
    }
    return '';
  };

  const save = async (values: any) => {
    var validateMessage = '';
    validateMessage = validate(values);
    if (validateMessage === '') {
      const shippingMaterialDTO = {
        id: editData == null ? 0 : editData['id'],
        entityType: props.entityType,
        entityId: Number(props.paramsId),
        description: values.description,
        isInternalOnly: values.isInternalOnly,
      };

      const apiUrl =
        values.id === 0
          ? new URL(environment.api.baseUrl + 'Notes')
          : new URL(environment.api.baseUrl + 'Notes/' + values.id);
      try {
        const result: any =
          values.id === 0
            ? await http.post(apiUrl.toString(), shippingMaterialDTO)
            : await http.put(apiUrl.toString(), shippingMaterialDTO);

        if (result !== null && result.data != null) {
          showMessageRefresh();
        } else {
          validateMessage = i18nMessages.errorOccurred;
        }
      } catch (error: any) {
        validateMessage = i18nMessages.errorOccurred;
      }
    }
    return validateMessage;
  };

  const showMessageRefresh = () => {
    toast.success(i18nMessages.savedSuccessfully);
  };

  const setDefaultValuesForControls = () => {
    setNoteDescription('');
    setIsInternalOnly(false);
  };

  async function handleProcessRowUpdate(newRow: GridRowModel) {
    let updatedRow: any = {};
    if (newRow.id === undefined) {
      updatedRow = { ...newRow, isNew: false, id: 0 };
    } else {
      updatedRow = { ...newRow, isNew: false };
    }
    var validation = await save(updatedRow);

    if (validation === '') {
      if (newRow.id === undefined) {
        var temp: any = [];
        for (var i = 0; i < gridData.rows.length; i++) {
          temp.push(gridData.rows[i]);
        }

        temp.push(updatedRow);
        updateGridData('rows', temp);
        setRefreshGrid(!refreshGrid);
        return updatedRow;
      } else {
        updateGridData(
          'rows',
          gridData.rows.map((row: any) =>
            row.id === newRow.id ? updatedRow : row
          )
        );
        setRefreshGrid(!refreshGrid);
        return updatedRow;
      }
    } else {
      var temp1: any = [];
      for (let i = 0; i < gridData.rows.length; i++) {
        temp1.push(gridData.rows[i]);
      }

      var existingRecord = {
        ...updatedRow,
        isNew: true,
        isManual: true,
      };

      temp1.push(updatedRow);
      updateGridData('rows', temp1);
      updateGridData('totalRows', temp1.length);
      toast.error(validation);
      setTimeout(() => {
        var oldData: any = [];
        for (var i = 0; i < temp1.length; i++) {
          if (temp1[i].id == 0) {
            oldData.push(existingRecord);
          } else {
            oldData.push(temp1[i]);
          }
        }
        updateGridData('rows', oldData);

        setTimeout(() => {
          setRowModesModel({
            ...rowModesModel,
            0: {
              mode: GridRowModes.Edit,
              fieldToFocus: 'description',
            },
          });
        }, 500);
      }, 500);
      return updatedRow;
    }
  }

  const handleProcessRowUpdateError = (error: Error) => {};

  const deleteData = async (id: any) => {
    const apiUrl = new URL(environment.api.baseUrl + 'Notes/' + id);
    try {
      const result: any = await http.delete(apiUrl.toString());
      if (result.data) {
        getNotes();

        toast.success(i18nMessages.deletedSuccessfully);
      } else {
        toast.error(i18nMessages.errorOccurred);
      }
    } catch (error: any) {}
  };

  return (
    <div>
      <Typography variant="h5" className="inner-heading has-button">
        {I18n('Customer.Tabs.Notes')}
        <div className="inner-btn-wrap">
          <div className="text-right container-search">
            <div className="search-control-outer">
              <CustomizedInputs
                margin="normal"
                displayLabel=""
                inputType="text"
                placeholderText="Search"
                handleChange={handleChangeBasicSearch}
                className="search-control small-form-control"
                adornmentValue={
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={faSearch} />
                  </InputAdornment>
                }
              />
            </div>
          </div>
        </div>
      </Typography>
      <div className="">
        <div className="content-block table-fix">
          <CustomDataGridInline
            rowCount={gridData.totalRows}
            data={gridData.rows}
            columns={columns}
            onSortModelChange={handleSortModelChange}
            pageNo={gridData.page}
            pageSize={gridData.pageSize}
            rowModesModel={rowModesModel}
            onRowModesModelChange={(newModel: any) => {
              setModels(newModel);
            }}
            onPageChange={handleChangePage}
            onPageSizeChange={handleChangePageSize}
            onRowEditStart={handleRowEditStart}
            rowsPerPageOptions={gridData.rowsPerPageOptions}
            initialState={{
              sorting: {
                sortModel: [{ field: 'updatedDate', sort: 'desc' }],
              } as GridSortingInitialState,
              pagination: {
                page: gridData.page,
                pageSize: gridData.pageSize,
              } as GridPaginationInitialState,
            }}
            components={{
              Toolbar: EditToolbar,
            }}
            componentsProps={{
              toolbar: { setRows, setRowModesModel },
            }}
            processRowUpdate={handleProcessRowUpdate}
            onProcessRowUpdateError={handleProcessRowUpdateError}
          ></CustomDataGridInline>

          <ConfirmationPopup
            isOpen={isDeleteModalOpen}
            data={selectedId}
            message={I18n('Notes.DeleteMessage')}
            handleClose={handleClose}
            handleOk={handleOk}
            title={I18n('Notes.DeleteNote')}
          ></ConfirmationPopup>

          {/* <AddNotes
            isOpen={isAddModalOpen}
            editData={editData}
            entityType={props.entityType}
            entityId={props.paramsId}
            handleClose={handleCloseForAdd}
            title={I18n('Notes.AddNote')}
            refreshData={refreshData}
          ></AddNotes> */}
        </div>
      </div>
    </div>
  );
}

export default Note;
