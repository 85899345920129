import { useCallback, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import I18n from 'utilities/i18n';
import { Grid, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {
  AirlineRestrictionModule,
  OrderByDirection,
} from 'models/pagination-model';
import environment from 'environment';
import { toast } from 'react-toastify';
import http from '../../utilities/httpService';
import {
  GridColumns,
  GridPaginationInitialState,
  GridSortingInitialState,
  GridSortModel,
} from '@mui/x-data-grid';
import CustomDataGrid from 'common/datagrid/datagrid';
import { CustomTooltip } from 'common/CustomTooltip/CustomTooltip';

export default function AirlineRestrictionPopup(props: any) {
  const { isOpen, handleClose, title, moduleRestriction, airlineId } = props;
  const [airlineName, setAirlineName] = useState('');
  const [gridData, setGridData] = useState({
    sortOrder: OrderByDirection.Descending,
    sortBy: 'CreatedDate',
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [10, 20, 50, 100],
    pageSize: 10,
    page: 0,
    searchExpression: '',
  });
  type Row = (typeof gridData.rows)[number];

  const i18nMessages = {
    Name: I18n('Airline.Name'),
    CommonErrOccuredMsg: I18n('Common.ErrorOccurred'),
  };

  const columns: GridColumns<Row> = [
    {
      field: 'restriction',
      headerName: I18n('Airline.Restriction'),
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <CustomTooltip
            title={<Typography fontSize={14}>{params.value}</Typography>}
            placement="bottom-start"
          >
            <div>{params.value}</div>
          </CustomTooltip>
        );
      },
    },
  ];

  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    if (sortModel && sortModel.length) {
      updateGridData('sortBy', sortModel[0].field);
      updateGridData(
        'sortOrder',
        sortModel[0].sort === 'asc'
          ? OrderByDirection.Ascending
          : OrderByDirection.Descending
      );
    }
  }, []);

  const handleChangePage = (pageNo: number) => {
    updateGridData('page', pageNo);
  };

  const handleChangePageSize = (pageSize: number) => {
    updateGridData('pageSize', pageSize);
  };

  const fetchAirline = async (module: AirlineRestrictionModule) => {
    try {
      const apiUrl = new URL(environment.api.baseUrl + 'Airline');
      apiUrl.searchParams.set('$filter', 'id~=~' + airlineId);
      apiUrl.searchParams.set('$orderby', gridData.sortBy);
      apiUrl.searchParams.set(
        '$skip',
        (gridData.page * gridData.pageSize).toString()
      );
      apiUrl.searchParams.set('$top', gridData.pageSize.toString());
      apiUrl.searchParams.set(
        '$orderbydirection',
        gridData.sortOrder.toString()
      );
      apiUrl.searchParams.set('moduleRestriction', module.toString());

      const result = await http.get(apiUrl.toString());
      if (result && result.data.values.length > 0) {
        setAirlineName(result.data.values[0].name);
        updateGridData(
          'totalRows',
          result.data.values[0].airlineRestriction.length
        );
        updateGridData('rows', result.data.values[0].airlineRestriction);
      }
    } catch {
      toast.error(i18nMessages.CommonErrOccuredMsg);
    }
  };

  useEffect(() => {
    if (isOpen && airlineId) {
      fetchAirline(moduleRestriction);
    }
  }, [isOpen, airlineId]);

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={'sm'}
        fullWidth={true}
      >
        <DialogTitle>
          {title}
          {airlineName ? '  (' + airlineName + ')' : ''}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className="grid-wrap two-col">
            <Grid item xs={12}>
              <div className="content-block table-fix">
                <CustomDataGrid
                  rowCount={gridData.totalRows}
                  data={gridData.rows}
                  columns={columns}
                  pageNo={gridData.page}
                  pageSize={gridData.pageSize}
                  onSortModelChange={handleSortModelChange}
                  onPageChange={handleChangePage}
                  onPageSizeChange={handleChangePageSize}
                  rowsPerPageOptions={gridData.rowsPerPageOptions}
                  initialState={{
                    sorting: {
                      sortModel: [{ field: 'restriction', sort: 'desc' }],
                    } as GridSortingInitialState,
                    pagination: {
                      page: gridData.page,
                      pageSize: gridData.pageSize,
                    } as GridPaginationInitialState,
                  }}
                ></CustomDataGrid>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
